<!DOCTYPE html>
<html lang="en">
<head>
   <title>Contract Edit KPI</title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>
 <div class="card-pad">
     <div class="fr-flex__container sticky-sub-header">
        <div class="fr-flex__col-6">
           <h3>Edit KPI</h3>
        </div>
        <div class="fr-flex__col-6">  
         <div class="preview_section pull-right">
            <button  [class.previous]="currentIndex !== 0"  (click)="pageChangeConfirmation(2)" [disabled]="currentIndex === 0 ||buttonDisabled"><i class="pi pi-arrow-up" pTooltip="Preview" tooltipPosition="top"></i></button>
            <span class="numbers_list">{{getCurrentPositionText()}}</span>
            <button [class.next]="currentIndex !== listOfKpis.length - 1" (click)="pageChangeConfirmation(1)"  [disabled]="currentIndex === listOfKpis.length - 1 ||buttonDisabled"><i class="pi pi-arrow-down" pTooltip="Next" tooltipPosition="top"></i></button>
            </div>
         </div>
     </div>
     <div class="scroll-start-here-sub-tabs"> 
     <form [formGroup]="myContrctKPIForm">
     <div class="fr-flex__container m-t-20">
        <div class="fr-flex__col-4">
           <div class="fr-form__group">
              <label> KPI <em> * </em></label>
              <p-dropdown id="engEdiKpiDropdown" appendTo="body" class="read-only-dropdown" [filter]="true" formControlName="_KPIName"  [options]="kpiNameDataSource" [(ngModel)]="_contractKpi.kpiDetailId" optionLabel="name" optionValue="id" placeholder="Select"></p-dropdown>
              <small class="p-error" *ngIf="submitted && myContrctKPIForm?.get('_KPIName')?.hasError('required')">This field is required.</small>
           </div>
        </div>

       
       <div class="fr-flex__col-4">
         <div class="fr-form__group">
            <label> Service <em> * </em><span class="max-length"> {{_contractKpi.serviceName?.length}}/200 </span></label>
            <input class="form-control" formControlName="_serviceName" type="text" [maxlength]="200" pInputText [(ngModel)]="_contractKpi.serviceName" (ngModelChange)="OnServiceChange()" trimSpace/>
            <small class="p-error" *ngIf="submitted && myContrctKPIForm?.get('_serviceName')?.hasError('required')">This field is required.</small>
            <small class="p-error" *ngIf="submitted && myContrctKPIForm?.get('_serviceName')?.hasError('maxlength')">Max length is 200 characters.</small>
            <small class="p-error" *ngIf="myContrctKPIForm.get('_serviceName')?.hasError('onlySpaces')">Only spaces not allowed.</small>
            <small class="p-error" *ngIf="myContrctKPIForm.get('_serviceName')?.hasError('pattern')">Invalid input.'&' and '%' are not allowed.</small>
         </div>

      </div>


      <div class="fr-flex__col-4">
         <div class="fr-form__group">
            <label> KPI Name<em> * </em><span class="max-length"> {{_contractKpi.kpiName?.length}}/400 </span></label>
            <input class="form-control" formControlName="_kpiName" type="text" [maxlength]="400" pInputText [(ngModel)]="_contractKpi.kpiName" trimSpace />
            <small class="p-error" *ngIf="submitted && myContrctKPIForm?.get('_kpiName')?.hasError('required')">This field is required.</small>
            <small class="p-error" *ngIf="submitted && myContrctKPIForm?.get('_kpiName')?.hasError('minlength')"> Minimum length is 3 characters. </small>
            <small class="p-error" *ngIf="submitted && myContrctKPIForm?.get('_kpiName')?.hasError('maxlength')">Max length is 400 characters.</small>
            <small class="p-error" *ngIf="myContrctKPIForm.get('_kpiName')?.hasError('onlySpaces')">Only spaces not allowed.</small>
            <small class="p-error" *ngIf="myContrctKPIForm.get('_kpiName')?.hasError('pattern')">Invalid input.'&' and '%' are not allowed.</small>
            <label class="suggestion_label"> Suggestion : <span> {{ suggestedKPIName}} </span> </label>
         </div>

      </div>
     </div>
     <div class="fr-flex__col-12"> 
      <div class="m-b-20"> 
       <span class="table-small-card"> Current Health </span> 
         <span id="conEditKPIHealthInformation" class="table-small-card"> {{_readOnlySybmolSteadyStateThreshold=='%'?_overAllAverageKPI == null?'--':_overAllAverageKPI+_readOnlySybmolSteadyStateThreshold:_overAllAverageKPI == null?'--':_overAllAverageKPI+ ' ' + _readOnlySybmolSteadyStateThreshold}} 
             <span [ngClass]="{
                 'success__circle': _overallAverageHealth === 'Green',
                 'danger__circle': _overallAverageHealth === 'Red'
               }"></span>
     </span> 
   </div>
      <p-table id="conEditKPItableName" class="primeng-table-custom custom-cells__contractattribute" [resizableColumns]="true" columnResizeMode="expand" styleClass="p-datatable-striped p-datatable-gridlines" [scrollable]="true" [value]="_contractKpiDataCollections">
         <ng-template pTemplate="header">
             <tr>                
                 <th id="conEditKpiDataCollectionFV" pSortableColumn="dataCollectionFrequencyValue" pResizableColumn>Data For <p-sortIcon field="dataCollectionFrequencyValue"></p-sortIcon> </th>
                 <th id="conEditKpiAttributeOne" pSortableColumn="attribute1" pResizableColumn>Attribute 1 <p-sortIcon field="attribute1"></p-sortIcon><br> <span [pTooltip]="_readOnlyAttribute1!.length! > 30 ? _readOnlyAttribute1 : ''" tooltipPosition="top" *ngIf="_readOnlyAttribute1!=''">({{_readOnlyAttribute1}})</span></th>
                 <th id="conEditKpiAttributeTwo" pSortableColumn="attribute2" pResizableColumn>Attribute 2 <p-sortIcon field="attribute2"></p-sortIcon> <br> <span [pTooltip]="_readOnlyAttribute2!.length! > 30 ? _readOnlyAttribute2 : ''" tooltipPosition="top" *ngIf="_readOnlyAttribute2!=''">({{_readOnlyAttribute2}})</span></th>
                 <th id="conEditKpiAttributeThree" pSortableColumn="attribute3" pResizableColumn>Attribute 3 <p-sortIcon field="attribute3?"></p-sortIcon><br> <span [pTooltip]="_readOnlyAttribute3!.length! > 30 ? _readOnlyAttribute3 : ''" tooltipPosition="top" *ngIf="_readOnlyAttribute3!=''">({{_readOnlyAttribute3}})</span> </th>
                 <th id="conEditKpiCalculate" pSortableColumn="calculatedKPI" pResizableColumn>Calculated KPI <p-sortIcon field="calculatedKPI"></p-sortIcon></th>
                 <th id="conEditMitigationAction" pSortableColumn="findingsCount" pResizableColumn>Mitigation Actions <p-sortIcon field="findingsCount"></p-sortIcon> </th>
                 <th id="conEditKpiRemarks" pSortableColumn="remarks" pResizableColumn>Remarks <p-sortIcon field="remarks"></p-sortIcon></th>
                 <th id="conEditKpiEdit" class="last-child-width">Edit</th>
             </tr>
         </ng-template>
         <ng-template pTemplate="body" let-Kpidata> 
             <tr>
                 <td id="dataCollection"><a>{{ Kpidata.dataCollectionFrequencyValue}}</a></td>
                 <td id="attr1" class="grid-text__center">{{(Kpidata.attribute1==null||Kpidata.attribute1=="")?'--':getFormattedValue(Kpidata.attribute1,currencies)}}</td>
                 <td id="attr2" class="grid-text__center">{{(Kpidata.attribute2==null||Kpidata.attribute2=="")?'--':getFormattedValue(Kpidata.attribute2,currencies)}}</td>
                 <td id="attr3" class="grid-text__center">{{(Kpidata.attribute3==null||Kpidata.attribute3=="")?'--':getFormattedValue(Kpidata.attribute3,currencies)}} {{Kpidata.enddate}}</td>
                 <td id="calKpi" class="grid-text__center">{{ _readOnlySybmolSteadyStateThreshold=='%'?Kpidata.calculatedKPI==null?'--':Kpidata.calculatedKPI+''+_readOnlySybmolSteadyStateThreshold: Kpidata.calculatedKPI==null?'--':Kpidata.calculatedKPI+' '+_readOnlySybmolSteadyStateThreshold}}</td>
                 <td class="text-center">
                  <a class="table-active-link" (click)="showFindingList(Kpidata)">{{ Kpidata.findingsCount > 0 ? Kpidata.findingsCount : '0' }}</a>
               </td>	 
                 <td class="grid-icon-center"><span pInputText pTooltip="{{Kpidata.remarks}}" tooltipPosition="top" class="pi pi-info-circle"></span> </td>
                 <td id="conEditKPIEditIconTD" class="grid-icon-center"> <span (click)="onEditDataCollection(Kpidata)" class="pi pi-pencil"></span> </td>
             </tr>
         </ng-template>
         <ng-template pTemplate="emptymessage">
            <tr>
            <td colspan="7" class="text-center"> No Records</td>
            </tr>
            </ng-template>
     </p-table>
   </div>
     <div class="fr-flex__container m-t-30">
      <div class="fr-flex__col-4">
         <div class="fr-form__group">
            <label> Data Collection Frequency <em> * </em></label>
            <p-dropdown id="engEdiDataCollectionFrequencyDropdown" appendTo="body" class="read-only-dropdown" [filter]="true" formControlName="_kpiDataCollectionFrequency" [options]="kpiDataCollectionFrequencyDataSource" [(ngModel)]="_contractKpi.kpiDataCollectionFrequencyId" optionLabel="name" optionValue="id" placeholder="Select"></p-dropdown>
            <small class="p-error" *ngIf="submitted && myContrctKPIForm?.get('_kpiDataCollectionFrequency')?.hasError('required')">This field is required.</small>
         </div>
      </div>
        <div class="fr-flex__col-4">
           <div class="fr-form__group">
              <label> KPI Reporting Frequency <em> * </em>  <i pInputText pTooltip="Reporting Frequency can only be selected after Data Collection Frequency is selected." tooltipPosition="top" class="pi pi-info-circle"> </i></label>
              <p-dropdown id="engEdiKpiReportingFrequencyDropdown" appendTo="body" [filter]="true" formControlName="_kpiReportingFrequency" [options]="kpiReportingFrequencyDataSource" [(ngModel)]="_contractKpi.kpiReportingFrequencyId" optionLabel="name" optionValue="id" placeholder="Select"></p-dropdown>
              <small class="p-error" *ngIf="submitted && myContrctKPIForm?.get('_kpiReportingFrequency')?.hasError('required')">This field is required.</small>
           </div>
        </div>
     </div>
     <div class="fr-flex__container">
      <div class="fr-flex__col-4 custom-pad-zero">
         <div class="display-flex">
            <div class="fr-flex__col-6 custom-pad-right-zero"> 
            <div class="fr-form__group">
               <label>KPI Tracking  Start<em> * </em></label>
               <p-dropdown id="engEdiKpiTrackingStartDropdown" appendTo="body" formControlName="_frequencyValueforStartDate" [options]="frequencyData" optionLabel="name" optionValue="order" [(ngModel)]="_contractKpi.frequencyValueforStartDate" placeholder="Select" [disabled]="isFrequencyAnnual"></p-dropdown>
               <small class="p-error" *ngIf="submitted && myContrctKPIForm?.get('_frequencyValueforStartDate')?.hasError('required')">This field is required.</small>
               
               
             </div>
            </div>
            <div class="fr-flex__col-6">
               <div class="fr-form__group">
                  <label> &nbsp; </label>
                  <input type="text" formControlName="_kpiStartDateYear" [minlength]="4" [maxlength]="4" class="form-control" pInputText [(ngModel)]="_contractKpi.kpiStartDateYear" placeholder="YYYY" (input)="onNumericInput($event,'_kpiStartDateYear')" trimSpace/>
                
                <small class="p-error" *ngIf="submitted && myContrctKPIForm?.get('_kpiStartDateYear')?.hasError('required')">This field is required.</small>
                <small class="p-error" *ngIf="submitted && myContrctKPIForm?.get('_kpiStartDateYear')?.hasError('minlength')"> Min lenght is 4 characters.</small>
               </div>
            </div>
         </div>
         
      </div>
      <div class="fr-flex__col-4 custom-pad-zero">
         <div class="display-flex">
            <div class="fr-flex__col-6 custom-pad-right-zero">
               <div class="fr-form__group">
                  <label>KPI Tracking  End<em> * </em></label>
           <p-dropdown id="engEdiKpiTrackingDropdown" appendTo="body" formControlName="_frequencyValueforEndDate" [options]="frequencyData" optionLabel="name" optionValue="order" [(ngModel)]="_contractKpi.frequencyValueforEndDate" placeholder="Select" [disabled]="isFrequencyAnnual"></p-dropdown>
           <small class="p-error" *ngIf="submitted && myContrctKPIForm?.get('_frequencyValueforEndDate')?.hasError('required')">This field is required.</small>
               </div>
               </div>
               <div class="fr-flex__col-6">
                  <div class="fr-form__group">
                     <label> &nbsp; </label>
                     <input type="text" formControlName="_kpiEndDateYear" [minlength]="4" [maxlength]="4" class="form-control" pInputText [(ngModel)]="_contractKpi.kpiEndDateYear" placeholder="YYYY" (input)="onNumericInput($event,'_kpiEndDateYear')" trimSpace/>
           
            <small class="p-error" *ngIf="submitted && myContrctKPIForm?.get('_kpiEndDateYear')?.hasError('required')">This field is required.</small>
            <small class="p-error" *ngIf="submitted && myContrctKPIForm?.get('_kpiEndDateYear')?.hasError('minlength')"> Min lenght is 4 characters.</small>        
         </div>
               </div>
         </div>
      </div>
      <div class="fr-flex__col-4 custom-pad-zero">
         <div class="display-flex">
            <div class="fr-flex__col-12">
               
                  <div class="fr-form__group">
                     <label> Status <em> * </em></label>
                     <p-dropdown id="engEdiStatusDropdown" appendTo="body" [filter]="true" formControlName="_status" [options]="statusDataSource" [(ngModel)]="_contractKpi.statusMappingId" optionLabel="name" optionValue="id" placeholder="Select"></p-dropdown>
                     <small class="p-error" *ngIf="submitted && myContrctKPIForm?.get('_status')?.hasError('required')">This field is required.</small>
                  </div>
               
               </div>
         </div>
      </div>
     </div>
     


     <div class="fr-flex__container">
        <div class="fr-flex__col-4">
           <div class="fr-form__group">
              <label> Unit of Measure </label>
              <input [readonly]="true" type="text" formControlName="_unitOfMeasure" class="form-control read-only" pInputText [(ngModel)]="_readOnlyUnitofMeasure" trimSpace />
           </div>
        </div>
        
       
     </div>
 
     <div class="fr-flex__container">
       <div class="fr-flex__col-4">
          <div class="fr-form__group">
             <label> Hyper Care Target <em *ngIf="!_isNotApplicable"> * </em> <button type="button" class="addcondition" (click)="addRow(1)" [disabled]="(_dynamicKpiHyperCareThresholds.length==0 &&_isNotApplicable==false)?false:true"> Add Condition  </button></label>
             <div class="display-flex"> 
                <p-dropdown id="engEdiHyperCareTargetDropdown" appendTo="body" [readonly]="_isNotApplicable" class="fr-flex__col-4 custom-pad-left-zero custom-pad-right-zero" [class.hypercare-read-only]="!_kpiHyperCareThresholdDetail.mathematicalSymbolId" [filter]="true" formControlName="_hyperCareThreshold" [options]="hyperCareThresholdDataSource" [(ngModel)]="_kpiHyperCareThresholdDetail.mathematicalSymbolId" optionLabel="name" optionValue="id" placeholder="Select"></p-dropdown>
                <input type="text" [readonly]="_isNotApplicable" formControlName="_hyperCareThresholdValue" [maxlength]="3" class="form-control fr-flex__col-4 m-l-10" [class.read-only]="!_kpiHyperCareThresholdDetail.percentageValue" pInputText [(ngModel)]="_kpiHyperCareThresholdDetail.percentageValue" (input)="onNumericInput($event,'_hyperCareThresholdValue')" trimSpace/>
                <label> <span class="max-length kpi_length">  </span></label>
                <input type="text" [readonly]="true" formControlName="_hyperCareThresholdsymbol" class="form-control read-only fr-flex__col-4 m-l-10" pInputText [(ngModel)]="_readOnlySybmolHyperCareThreshold" trimSpace/>  
             </div>
                <small class="p-error" *ngIf="submitted && !_isNotApplicable && myContrctKPIForm?.get('_hyperCareThreshold')?.hasError('required')">This field is required.</small>
                <small class="p-error" *ngIf="submitted && _kpiHyperCareThresholdDetail.mathematicalSymbolId==0 && !_isNotApplicable">This field is required.</small>
                <small class="p-error" *ngIf="submitted && myContrctKPIForm?.get('_hyperCareThresholdValue')?.hasError('pattern')">Enter only numeric values.</small>
                <small class="p-error" *ngIf="submitted && !_isNotApplicable && myContrctKPIForm?.get('_hyperCareThresholdValue')?.hasError('required')"> This field is required.</small>
               <div *ngFor="let field of _dynamicKpiHyperCareThresholds; let i = index" class="display-flex m-t-10">
                <p-dropdown appendTo="body" class="fr-flex__col-4 custom-pad-left-zero custom-pad-right-zero" [filter]="true" formControlName="_dyhyperCareThreshold" [options]="hyperCareThresholdDataSource" [(ngModel)]="_dynamicKpiHyperCareThreshold.mathematicalSymbolId" optionLabel="name" optionValue="id" placeholder="Select"></p-dropdown>
                <input type="text" formControlName="_dyhyperCareThresholdValue" [maxlength]="3" class="form-control fr-flex__col-3 m-l-10" pInputText [(ngModel)]="_dynamicKpiHyperCareThreshold.percentageValue" (input)="onNumericInput($event,'_dyhyperCareThresholdValue')" trimSpace />
                <input type="text" [readonly]="true" formControlName="_dyhyperCareThresholdsymbol" class="form-control read-only fr-flex__col-4 m-l-10" pInputText [(ngModel)]="_readOnlySybmolHyperCareThreshold" trimSpace />
                <button pButton type="button" icon="pi pi-trash" (click)="removeRow(i,1)" class="p-button-danger" class="form-control"></button>
              </div>
              <small class="p-error" *ngIf="submitted && !_isNotApplicable && myContrctKPIForm?.get('_dyhyperCareThreshold')?.hasError('required')">This field is required.</small>
              <small class="p-error m-l-10" *ngIf="submitted && !_isNotApplicable && myContrctKPIForm?.get('_dyhyperCareThresholdValue')?.hasError('required')"> This field is required.</small>
              <div class="display-flex m-t-10">
                <p-checkbox formControlName="_isNotApplicable" [(ngModel)]="_isNotApplicable"  [disabled]="_iskpiEdit" [binary]="true" inputId="binary" ></p-checkbox>
                <label for="binary" class="label__alignment">Not Applicable</label>
              </div>
          </div>
       </div>
 
       <div class="fr-flex__col-4">
          <div class="fr-form__group">
             <label> Hyper Care Start Date <em *ngIf="!_isNotApplicable"> * </em></label>
             <p-calendar id="engEdiHyperCareStartDateCalendar" appendTo="body" class="form-control read-only-calendar" [class.hypercare-read-only]="!_contractKpi.hyperCareStartDate" [disabled]="_isNotApplicable" formControlName="_hyperCareStartDate"  [maxDate]="hyperCareMaxStartDate" [dateFormat]=_dateformart.CalenderDateFormat  [(ngModel)]="_contractKpi.hyperCareStartDate"  (ngModelChange)="StartDateSelection(_contractKpi.hyperCareStartDate,2)" [disabled]="true" [showIcon]="true" [readonlyInput]="true"></p-calendar>
             <small class="p-error" *ngIf="submitted &&  !_isNotApplicable && myContrctKPIForm?.get('_hyperCareStartDate')?.hasError('required')">This field is required.</small>
            </div>
       </div>
 
       <div class="fr-flex__col-4">
          <div class="fr-form__group">
             <label> Hyper Care End Date <em *ngIf="!_isNotApplicable"> * </em></label>
             <p-calendar id="engEdiHyperCareEndDateCalendar" appendTo="body" class="form-control read-only-calendar" [class.hypercare-read-only]="!_contractKpi.hyperCareEndDate" [disabled]="_isNotApplicable"  formControlName="_hyperCareEndDate"  [minDate]="hyperCareMinEndDate" [dateFormat]=_dateformart.CalenderDateFormat  [(ngModel)]="_contractKpi.hyperCareEndDate"  (ngModelChange)="EndDateSelection(_contractKpi.hyperCareEndDate,2)" [disabled]="true" [showIcon]="true" [readonlyInput]="true"></p-calendar>
             <small class="p-error" *ngIf="submitted && !_isNotApplicable && myContrctKPIForm?.get('_hyperCareEndDate')?.hasError('required')">This field is required.</small>
            </div>
       </div>
 
     </div>
 
     <div class="fr-flex__container">
        
        <div class="fr-flex__col-4">
           <div class="fr-form__group">
              <label> Steady State Target<em> *</em>  <button type="button" class="addcondition" (click)="addRow(2)" [disabled]="_dynamicKpiSteadyStateThresholds.length==0?false:true">  Add Condition  </button></label>
              <div class="display-flex"> 
                <p-dropdown id="engEdiSteadyStatetargetDropdown" appendTo="body" class="fr-flex__col-4 custom-pad-left-zero custom-pad-right-zero" [filter]="true" formControlName="_steadyStateThreshold" [options]="hyperCareThresholdDataSource" [(ngModel)]="_kpiSteadyStateThresholdDetail.mathematicalSymbolId" optionLabel="name" optionValue="id" placeholder="Select"></p-dropdown>
                 <input type="text" [maxlength]="3" formControlName="_steadyStateThresoldValue"  class="form-control fr-flex__col-4 m-l-10" pInputText [(ngModel)]="_kpiSteadyStateThresholdDetail.percentageValue" (input)="onNumericInput($event,'_steadyStateThresoldValue')" trimSpace />
                 <label> <span class="max-length kpi_length">  </span> </label>
                 <input type="text" [readonly]="true" formControlName="_steadyStateThresoldSymbol"  class="form-control read-only fr-flex__col-4 m-l-10" pInputText [(ngModel)]="_readOnlySybmolSteadyStateThreshold" trimSpace/> 
              </div>
              <small class="p-error" *ngIf="submitted && myContrctKPIForm?.get('_steadyStateThreshold')?.hasError('required')">This field is required.</small>
               <small class="p-error m-l-10" *ngIf="submitted && myContrctKPIForm?.get('_steadyStateThresoldValue')?.hasError('pattern')">Enter only numeric values.</small>
               <small class="p-error" *ngIf="submitted && myContrctKPIForm?.get('_steadyStateThresoldValue')?.hasError('required')"> This field is required.</small>
              <div *ngFor="let field of _dynamicKpiSteadyStateThresholds; let i = index" class="display-flex m-t-10">
                <p-dropdown appendTo="body" class="fr-flex__col-4 custom-pad-left-zero custom-pad-right-zero" [filter]="true" formControlName="_dysteadyStateThreshold" [options]="hyperCareThresholdDataSource" [(ngModel)]="_dynamicKpiSteadyStateThreshold.mathematicalSymbolId" optionLabel="name" optionValue="id" placeholder="Select"></p-dropdown>
                <input type="text" formControlName="_dysteadyStateThresoldValue" [maxlength]="3" class="form-control fr-flex__col-3 m-l-10" pInputText [(ngModel)]="_dynamicKpiSteadyStateThreshold.percentageValue" (input)="onNumericInput($event,'_dysteadyStateThresoldValue')" trimSpace />
                <input type="text" [readonly]="true" formControlName="_dysteadyStateThresoldSymbol" class="form-control read-only fr-flex__col-4 m-l-10" pInputText [(ngModel)]="_readOnlySybmolSteadyStateThreshold" trimSpace />
                <button pButton type="button"  icon="pi pi-trash" (click)="removeRow(i,2)" class="p-button-danger" class="form-control"></button>
              </div>
              <small class="p-error" *ngIf="submitted && myContrctKPIForm?.get('_dysteadyStateThreshold')?.hasError('required')">This field is required.</small>
              <small class="p-error m-l-10" *ngIf="submitted && myContrctKPIForm?.get('_dysteadyStateThresoldValue')?.hasError('required')">This field is required.</small>
           </div>
        </div>
     </div>
     
     <div class="fr-flex__container">
        <div class="fr-flex__col-4">
           <div class="fr-form__group">
              <label> Category  </label>
              <input id="categoryInput" formControlName="_category" [readonly]="true" type="text" class="form-control read-only" pInputText [(ngModel)]="_readOnlyCategory" trimSpace />
           </div>
        </div>
        <div class="fr-flex__col-4">
           <div class="fr-form__group">
              <label> Compliance Indicator  </label>
              <input id="complianceIndicatorInput" formControlName="_complianceIndicator" [readonly]="true" type="text" class="form-control read-only" pInputText [(ngModel)]="_readOnlyComplianceIndicator" trimSpace />
           </div>
        </div>
        <div class="fr-flex__col-4">
           <div class="fr-form__group">
              <label> Calculation​  </label>
              <input id="calculationInput" formControlName="_calculation" type="text" [readonly]="true" class="form-control read-only" pInputText [(ngModel)]="_readOnlyCalculation" trimSpace/>
           </div>
        </div>
     </div>
     <div class="fr-flex__container">
        <div class="fr-flex__col-4">
           <div class="fr-form__group">
              <label> Attribute 1  <em>*</em></label>
              <input id="attribute1Input" formControlName="_attribute1"  [readonly]="isAttribute1NotApplicable" [ngClass]="{'attribute-based__condition': isAttribute1NotApplicable}" type="text" class="form-control" pInputText [(ngModel)]="_readOnlyAttribute1" (blur)="CalculationFormulae();" trimSpace/>
              <small class="p-error" *ngIf="submitted && myContrctKPIForm?.get('_attribute1')?.hasError('required')">This field is required.</small>
              <small class="p-error" *ngIf="submitted && myContrctKPIForm?.get('_attribute1')?.hasError('minlength')">Min length is 3 characters.</small>
           </div>
        </div>
        <div class="fr-flex__col-4">
           <div class="fr-form__group">
              <label> Attribute 2  <em>*</em></label>
              <input id="attribute2Input" formControlName="_attribute2" [readonly]="isAttribute2NotApplicable" [ngClass]="{'attribute-based__condition': isAttribute2NotApplicable}" type="text" class="form-control" pInputText [(ngModel)]="_readOnlyAttribute2" (blur)="CalculationFormulae();" trimSpace/>
              <small class="p-error" *ngIf="submitted && myContrctKPIForm?.get('_attribute2')?.hasError('required')">This field is required.</small>
              <small class="p-error" *ngIf="submitted && myContrctKPIForm?.get('_attribute2')?.hasError('minlength')">Min length is 3 characters.</small>

            </div>
        </div>
        <div class="fr-flex__col-4">
           <div class="fr-form__group">
              <label> Attribute 3  </label>
              <input id="attribute3Input" formControlName="_attribute3" [readonly]="isAttribute3NotApplicable"  type="text" [readonly]="true" class="form-control read-only" pInputText [(ngModel)]="_readOnlyAttribute3" (blur)="CalculationFormulae();" trimSpace/>
           </div>
        </div>
     </div>
 
     <div class="fr-flex__container">
       <div class="fr-flex__col-12">
          <div class="fr-form__group">
             <label> KPI Description <span class="max-length"> {{_contractKpi.description?.length}}/300 </span></label>
             <textarea id="engEdiKpiDesTextarea" formControlName="_kpiDescription" [maxlength]="300" class="form-control" InputTextarea  [(ngModel)]="_contractKpi.description" trimSpace ></textarea>
          </div>
       </div>
    </div>
    <div class="fr-flex__container">
       <div class="fr-flex__col-3">
          <div class="fr-form__view">
             <label> Added By </label>
             <span> <em>  {{kpiCreatedUserName}} </em> </span>
          </div>
       </div>
       <div class="fr-flex__col-3">
          <div class="fr-form__view">
             <label> Added Date & Time </label>
             <span> <em> {{_contractKpi.createdDate | date: _dateformart.DateTimeWithZone}} {{_dateformart.DisplayUTC}} </em> </span>
          </div>
       </div>
       <div class="fr-flex__col-3">
          <div class="fr-form__view">
             <label> Modified By</label>
             <span> <em> {{kpiUpdatedUserName!=''? kpiUpdatedUserName:"--"}} </em> </span>
          </div>
       </div>
       <div class="fr-flex__col-3">
          <div class="fr-form__view">
             <label> Modified Date & Time</label>
             <span *ngIf="_contractKpi.updatedDate != null"> <em> {{ _contractKpi.updatedDate| date: _dateformart.DateTimeWithZone}} {{_dateformart.DisplayUTC}}</em></span>
             <span  *ngIf="_contractKpi.updatedDate == null"> <em> {{_contractKpi.updatedDate? _contractKpi.updatedDate:"--"}} </em> </span>
          </div>
       </div>
    </div>
  
 </form>
</div>
 <div class="card flex justify-content-center">
       <p-dialog  id="kpiDataCollectionDialog" appendTo="body" header="KPI Data Collection - {{_contractKpi.kpiName}}" [style]="{ width: '80vw' }" [(visible)]="visible" [modal]="true" [draggable]="false" [resizable]="false" [closable]="false">
          <div class="fr-flex__container m-b-20">
                <div class="fr-flex__col-12 custom-pad-zero custom-radio"> 
                 <p-radioButton id="contractEditKPICalculatedKPIRadioBtn"
                 name="KPIoption"
                 [disabled]="_readOnlyFormulaName=='N/A'"
                 [value]="1"
                 [(ngModel)]="_contractKpiDataCollection.inputAttributeValueType"
                 inputId="calculatedKPI"
                 (click)="onRadioButtonChange('1')"
               ></p-radioButton>
               <label for="calculatedKPI">Enter Calculated KPI</label>
               <p-radioButton
               name="KPIoption" id="contractEditKPIAttributeValueRadioBtn"
               [disabled]="_readOnlyFormulaName=='N/A'"
               [value]="2"
               [(ngModel)]="_contractKpiDataCollection.inputAttributeValueType"
               inputId="attributeValues"
               (click)="onRadioButtonChange('2')"
             ></p-radioButton>
             <label for="attributeValues">Enter Attribute Values</label>
             <p-radioButton
             name="KPIoption" id="contractEditKPINoVolumeRadioBtn"
             [disabled]="_readOnlyFormulaName=='N/A'"
             [value]="3"
             [(ngModel)]="_contractKpiDataCollection.inputAttributeValueType"
             inputId="novolume"
             (click)="onRadioButtonChange('3')"
           ></p-radioButton>
           <label for="novolume">No Volume</label>
            </div>
          </div>
          <form [formGroup]="kpiDataCollectionFrom">
         <table id="conEditKPIPopupTableName" class="table-custom" aria-describedby="contractEditKPI">
              <tr>                
                  <th id="conEditKPIDataForPopupwindow">Data For</th>
                  <th id="conEditKPIAttributeOnePopupwindow">Attribute 1 <br> <span [pTooltip]="_readOnlyAttribute1!.length! > 5 ? _readOnlyAttribute1 : ''" tooltipPosition="top" *ngIf="_readOnlyAttribute1!=''">({{_readOnlyAttribute1}})</span><em *ngIf="_contractKpiDataCollection.inputAttributeValueType==2 && _readOnlyAttribute1!=''"> * </em></th>
                  <th id="conEditKPIAttributeTwoPopupwindow">Attribute 2 <br> <span [pTooltip]="_readOnlyAttribute2!.length! > 5 ? _readOnlyAttribute2 : ''" tooltipPosition="top" *ngIf="_readOnlyAttribute2!=''">({{_readOnlyAttribute2}})</span><em  *ngIf="_contractKpiDataCollection.inputAttributeValueType==2 && _readOnlyAttribute2!=''"> * </em></th>
                  <th id="conEditKPIAttributeTherePopupwindow">Attribute 3 <br> <span [pTooltip]="_readOnlyAttribute3!.length! > 5 ? _readOnlyAttribute3 : ''" tooltipPosition="top" *ngIf="_readOnlyAttribute3!=''">({{_readOnlyAttribute3}})</span></th> 
                  <th id="conEditKPIEmptySpacePopupwindow">&nbsp;</th>
                  <th id="conEditKPICalculatedPopupwindow">Calculated KPI <em *ngIf="_contractKpiDataCollection.inputAttributeValueType==1" > *</em></th>
                  <th id="conEditKPIDisplayNonePopupwindow" class="display-none">&nbsp;</th>
                  
              </tr>
              <tr>
                
                <td> <span> {{_contractKpiDataCollection.dataCollectionFrequencyValue}} </span> </td>
                <td> 
                   
                   <p-inputNumber id="engEdiAttribute1InputNumber" *ngIf="_kpiAttributeTypeCode=='INT'" inputId="minmaxfraction" mode="decimal" [minFractionDigits]="2" class="form-control-inputNumber" [class.read-only-grid]="_contractKpiDataCollection.inputAttributeValueType!=2 || _readOnlyAttribute1==''" [readonly]="_contractKpiDataCollection.inputAttributeValueType==1 || _readOnlyAttribute1==''" formControlName="_attribute1" (input)="onNumericInputpopUp($event,'_attribute1')" [maxlength]="8" class="form-control"  pInputText [(ngModel)]="_contractKpiDataCollection.attribute1" trimSpace />
                   
                  <p-calendar id="engEdiAttribute1Calendar" appendTo="body" [disabled]="_contractKpiDataCollection.inputAttributeValueType!=2 || _readOnlyAttribute1==''" class="form-control read-only-calendar" [class.kpi-read-only-calendar]="_contractKpiDataCollection.inputAttributeValueType==1"   *ngIf="_kpiAttributeTypeCode=='DTE'" class="form-control"  formControlName="_attribute1"   [dateFormat]=_dateformart.CalenderDateFormat  [(ngModel)]="_contractKpiDataCollection.attribute1"  [showIcon]="true" (ngModelChange)="AttributeDateSelection(_contractKpiDataCollection.attribute1,1)"></p-calendar>
                   <small class="p-error custom-error text-center" *ngIf=" dataCollectionSubmitted &&  _readOnlyAttribute1!='' && kpiDataCollectionFrom?.get('_attribute1')?.hasError('required')">This field is required.</small>
                </td>
                <td> 
                   
                   <p-inputNumber id="engEdiAttribute2InputNumber" *ngIf="_kpiAttributeTypeCode=='INT'" inputId="minmaxfraction" mode="decimal" [minFractionDigits]="2" class="form-control-inputNumber" [class.read-only-grid]="_contractKpiDataCollection.inputAttributeValueType!=2 || _readOnlyAttribute2==''" [readonly]="_contractKpiDataCollection.inputAttributeValueType==1 || _readOnlyAttribute2==''" formControlName="_attribute2" (input)="onNumericInputpopUp($event,'_attribute2')" [maxlength]="8" class="form-control"  pInputText [(ngModel)]="_contractKpiDataCollection.attribute2" trimSpace/> 
                  <p-calendar id="engEdiAttribute2Calendar" appendTo="body" [disabled]="_contractKpiDataCollection.inputAttributeValueType!=2 || _readOnlyAttribute2==''" class="form-control read-only-calendar" [class.kpi-read-only-calendar]="_contractKpiDataCollection.inputAttributeValueType==1"   *ngIf="_kpiAttributeTypeCode=='DTE'" class="form-control"  formControlName="_attribute2"   [dateFormat]=_dateformart.CalenderDateFormat  [(ngModel)]="_contractKpiDataCollection.attribute2"  [showIcon]="true" (ngModelChange)="AttributeDateSelection(_contractKpiDataCollection.attribute2,2)"></p-calendar>
                   <small class="p-error custom-error text-center" *ngIf=" dataCollectionSubmitted && _readOnlyAttribute2!='' &&  kpiDataCollectionFrom?.get('_attribute2')?.hasError('required')">This field is required.</small>
                </td>
                <td> 
                  
                  
                  <p-inputNumber id="engEdiAttribute3InputNumber" *ngIf="_kpiAttributeTypeCode=='INT'" inputId="minmaxfraction" mode="decimal" [minFractionDigits]="2" class="form-control-inputNumber" [class.read-only-grid]="true" [readonly]="true" formControlName="_attribute3" (input)="onNumericInputpopUp($event,'_attribute3')" [maxlength]="8" class="form-control"  pInputText [(ngModel)]="_contractKpiDataCollection.attribute3" trimSpace/>
                  <p-calendar id="engEdiAttribute3Calendar" appendTo="body" [disabled]="true" class="form-control read-only-calendar" [class.kpi-read-only-calendar]="_contractKpiDataCollection.inputAttributeValueType==1 || _readOnlyAttribute3==''"   *ngIf="_kpiAttributeTypeCode=='DTE'" class="form-control"  formControlName="_attribute3"   [dateFormat]=_dateformart.CalenderDateFormat  [(ngModel)]="_contractKpiDataCollection.attribute3"  [showIcon]="true" (ngModelChange)="AttributeDateSelection(_contractKpiDataCollection.attribute3,3)"></p-calendar>
                   <small class="p-error custom-error text-center" *ngIf=" dataCollectionSubmitted && kpiDataCollectionFrom?.get('_attribute3')?.hasError('required')">This field is required.</small>
                </td>

              

               <td>
                  <button *ngIf="_kpiAttributeTypeCode=='INT'" [disabled]="!isAttributeValueSelected" class="btn btn-primary width110" (click)="btnCalculateKPI()"> <em> Calculate </em> <i class="pi pi-arrow-right"> </i> </button>
               </td>


                <td> <input type="text" class="form-control" [class.read-only-grid]="_contractKpiDataCollection.inputAttributeValueType!=1" [readonly]="_contractKpiDataCollection.inputAttributeValueType!=1" formControlName="_calculatedKPI" (input)="onNumericInputpopUp($event,'_calculatedKPI')" [maxlength]="8" class="form-control"  pInputText [(ngModel)]="_contractKpiDataCollection.calculatedKPI" trimSpace /> 
                   <small class="p-error custom-error text-center"  *ngIf=" dataCollectionSubmitted && _contractKpiDataCollection.inputAttributeValueType==1 && kpiDataCollectionFrom?.get('_calculatedKPI')?.hasError('required')">This field is required.</small>
                </td>
                <td><div>{{_readOnlySybmolSteadyStateThreshold}}</div></td>
              
              </tr>
              
       </table>
       
      <div class="fr-flex__container m-t-20">
       <div class="fr-flex__col-12">
          <div class="fr-form__group">
             <label> Remarks <em> * </em> <span class="max-length"> {{_contractKpiDataCollection.remarks==null?0:_contractKpiDataCollection.remarks.length}}/300 </span></label>
             <textarea id="engEdiRemarksTextarea" formControlName="_remarks" [maxlength]="300" class="form-control" InputTextarea  [(ngModel)]="_contractKpiDataCollection.remarks" trimSpace ></textarea>
             <small class="p-error" *ngIf=" dataCollectionSubmitted && kpiDataCollectionFrom?.get('_remarks')?.hasError('required')">This field is required.</small>
          </div>
       </div>
    </div>

    <div *ngIf="isAttributeValueSelected && _kpiAttributeTypeCode=='INT'" class="fr-flex__container">
      <div class="fr-flex__col-12">
         <div class="note">
             <em> <strong> Note : </strong> Please click on the “Calculate” button to see the updated KPI value, anytime there is a change in the Attribute values. </em>
         </div>
         
   </div>
   </div>


     </form>
    <div class="fr-flex__container footer_btns">
       <div class="fr-flex__col-12">
          <a  type="button" class="btn btn-primary btn-save pull-right" [class.disabled]="isSaveKpiDataCollectionPopupBtnDisable" (click)="isSaveKpiDataCollectionPopupBtnDisable ? null : saveDataCollection()"> Save </a>
          <a type="button" class="btn btn-secondary pull-right" (click)="cancelDialog()"> Cancel </a>
       </div>
    </div>
       </p-dialog>
  
 </div>
 
 
     <!-- Save & Cancel Footer buttons Start -->
     <div class="fr-flex__container footer_btns">
        <div class="fr-flex__col-12">
           <button [class.disabled]="isSaveBtnDisable" (click)="isSaveBtnDisable ? null : onUpdateContractKpi()" type="button" class="btn btn-primary btn-save pull-right"> Save </button>
           <button type="button" (click)="onCancelContractKpi()"  class="btn btn-secondary pull-right"> Cancel </button>
        </div>
     </div>
     <!-- Save & Cancel Footer buttons  End -->
      <!-- Mitigation Action Popup Start -->

    <div class="card flex justify-content-center">
      <p-dialog appendTo="body" header="Mitigation Actions" [(visible)]="displayMitigationListPopup" [modal]="true" [style]="{ width: '80vw' }" [draggable]="false">
        <!-- Table structure for displaying data -->
        <table aria-describedby="contractMitigationKPI" class="table-custom">
          <thead>
            <tr>
                <th id="conEditKPIDataForPopup">Data For</th>
              <th id="conEditKPIAttributeOnePopup">Attribute 1 <span [pTooltip]="_readOnlyAttribute1!.length! > 5 ? _readOnlyAttribute1 : ''" tooltipPosition="top" *ngIf="_readOnlyAttribute1!=''">({{_readOnlyAttribute1}})</span></th>
              <th id="conEditKPIAttributeTwoPopup">Attribute 2 <span [pTooltip]="_readOnlyAttribute2!.length! > 5 ? _readOnlyAttribute2 : ''" tooltipPosition="top" *ngIf="_readOnlyAttribute2!=''">({{_readOnlyAttribute2}})</span></th>
              <th id="conEditKPIAttributeThreePopup">Attribute 3 <span [pTooltip]="_readOnlyAttribute3!.length! > 5 ? _readOnlyAttribute3 : ''" tooltipPosition="top" *ngIf="_readOnlyAttribute3!=''">({{_readOnlyAttribute3}})</span></th>
              <th id="conEditKPICalculatedKPIPopup">Calculated KPI </th>
              <th id="conEditKPIRemarksPopup">Remarks </th>
          </tr>
          </thead>
          <tbody>
           
            <tr *ngIf="selectedKpiReportData">
              <td>{{selectedKpiReportData.dataCollectionFrequencyValue}}</td>

               <td class="grid-text__center">{{(selectedKpiReportData.attribute1==null||selectedKpiReportData.attribute1=="")?'--':selectedKpiReportData.attribute1}}</td>
               <td class="grid-text__center">{{(selectedKpiReportData.attribute2==null||selectedKpiReportData.attribute2=="")?'--':selectedKpiReportData.attribute2}}</td>
               <td class="grid-text__center">{{(selectedKpiReportData.attribute3==null||selectedKpiReportData.attribute3=="")?'--':selectedKpiReportData.attribute3}}</td>
              <td>
               {{ selectedKpiReportData.calculatedKPI || '--' }}
               <span *ngIf="selectedKpiReportData.calculatedKPI">{{ _readOnlySybmolSteadyStateThreshold }}</span>
             </td>
              <td>
               <ng-container *ngIf="!selectedKpiReportData.remarks || selectedKpiReportData.remarks.length === 0; else showRemarks">
                 <i class="pi pi-info-circle"></i>
               </ng-container>
               <ng-template #showRemarks>
                 {{ selectedKpiReportData.remarks }}
               </ng-template>
             </td>
            </tr>
          </tbody>
        </table>
        <div class="fr-flex__container">
        <div class="fr-flex__col-12 m-b-10 m-t-20">
         <a routerLinkActive="active" class="btn btn-primary pull-right"  (click)="CreateMitigationActions()"> Add Action </a>
     </div>
     </div>
                <div class="fr-flex__col-12 m-b-20"> 
                  <p-table class="primeng-table-custom custom-cells__supplierEditMeeting_action_popup" [scrollable]="true" [value]="visableMitigations" editMode="row">
                     <ng-template pTemplate="header">
                         <tr>                         
                             <th id="conEditKPIAction">Action<em> * </em> </th>
                             <th id="conEditKPIActionOwner">Owner</th>
                             <th id="conEditKPITargetDate">Target Date</th>
                             <th id="conEditKPIActionStatus">Status <em> * </em> </th>
                             <th id="conEditKPIClosureData">Closure Date</th>
                             <th class="last-child-width" id="conEditKPIDelete">Delete</th>
                            
                             
                         </tr>
                     </ng-template>
                     <ng-template pTemplate="body" let-addMitigationActionsData let-actionIndex="rowIndex"> 
                         
                         <tr>                    
                             <td>
                                 
                                 <textarea id="engEdiActionTextarea" class="form-control" [(ngModel)]="addMitigationActionsData.Action"  [required]="true" [maxlength]="300" name="Action" #ActionNameInput="ngModel" trimSpace ></textarea>
                                 <div *ngIf="ActionNameInput.errors?.['required'] && addMitigationSaved && addMitigationActionsData.Action== ''" class="text-danger highlight-red">This field is required.</div>  
                               </td>    
                             <td><p-dropdown id="engEdiOwnerDropdown" appendTo="body" [filter]="true" appendTo="body" [(ngModel)]="addMitigationActionsData.ActionOwner" [options]="combinedContacts" optionLabel="contactName" optionValue="id" placeholder="Select Action Owner"></p-dropdown></td>
                            
                             <td>                                     
                               <p-calendar id="engEdiTargetDateCalendar" appendTo="body" class="form-control" [showIcon]="true"  [(ngModel)]="addMitigationActionsData.TargetDate" [showIcon]="true" [dateFormat]=_dateformart.CalenderDateFormat></p-calendar>  
                              </td>        
                              <td>
                                 <p-dropdown id="engEdiStatusDropdown" appendTo="body" [filter]="true" [options]="actionStatus" appendTo="body" placeholder="Select Status" optionLabel="name" optionValue="id" [(ngModel)]="addMitigationActionsData.StatusMappingId" name="Status"></p-dropdown>                            
                                  <div  *ngIf="addMitigationSaved && addMitigationActionsData.StatusMappingId ==''" class="text-danger highlight-red">This field is required.                                                                
                                 </div>                                               
                               </td>                    
                               <td>
                                 <div *ngIf="isClosedStatus(addMitigationActionsData.StatusMappingId) && (!addMitigationActionsData.ClosureDate || closureDateInput.invalid)">
                                    <div class="text-danger highlight-red">*</div>
                                  </div>
                                  <p-calendar appendTo="body" class="form-control" [showIcon]="true" pInputText [(ngModel)]="addMitigationActionsData.ClosureDate" [dateFormat]="_dateformart.CalenderDateFormat" #closureDateInput="ngModel" [ngClass]="{'highlight-red': isClosedStatus(addMitigationActionsData.StatusMappingId) && closureDateInput.invalid}"></p-calendar>                      
                                 <div *ngIf="isClosedStatus(addMitigationActionsData.StatusMappingId) && (!addMitigationActionsData.ClosureDate || closureDateInput.invalid)">
                                    <div class="text-danger highlight-red">This field is required</div>
                                  </div>
                               </td>
                               
                             <td class="grid-icon-center"><span class="pi pi-trash" (click)="deleteAction(actionIndex)"></span></td>
                         </tr>
                     </ng-template>
                     <ng-template pTemplate="emptymessage">
                         <tr>
                         <td colspan="6" class="text-center"> No Records</td>
                         </tr>
                         </ng-template>
                 </p-table>
                   </div>
                   <a type="button" class="btn btn-primary btn-save pull-right" (click)="onSaveMitigationActions()">Save</a>
                <a type="button" class="btn btn-secondary pull-right" (click)="cancelMitigationDialog()"> Cancel </a>
      </p-dialog>
      
                  </div>
    
                  <!-- Mitigation Action Popup End -->
  </div>
</body>
</html>
