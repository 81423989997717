<!DOCTYPE html>
<html lang="en">
<head>
   <title> Associate View </title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>
   <div class="card-pad"> 
    <div class="fr-flex__container sticky-header">
       <div class="fr-flex__col-4">
          <h3>View Associate<span> <em class="fr-subtitle_view">   </em> </span></h3>
    </div>
    <div class="fr-flex__col-8">
      <select class="form-control selectpicker pull-right" [(ngModel)]="selectedOption"  (change)="onOptionChange()">
         <option> Actions </option>
         <option *ngIf="editPrivilege"> Edit </option>
         <option *ngIf="deletePrivilege"> Delete </option>
      </select>
      <div class="preview_section pull-right" *ngIf="currentIndex!==-1">
         <button  [class.previous]="currentIndex !== 0" (click)="previousRecord()" [disabled]="currentIndex === 0"><i class="pi pi-arrow-up" pTooltip="Preview" tooltipPosition="top"></i></button>
         <span class="numbers_list">{{getCurrentPositionText()}}</span>
         <button [class.next]="currentIndex !== listOfAssociates.length - 1" (click)="nextRecord()" [disabled]="currentIndex === listOfAssociates.length - 1"><i class="pi pi-arrow-down" pTooltip="Next" tooltipPosition="top"></i></button>
         </div>
      <a class="btn btn-primary-link backtolist pull-right" (click)="BackToList()">  <i class="pi pi-arrow-left"></i> Back to list </a>
   </div>
   </div>
   <div class="scroll-start-here">
    <div class="fr-flex__container">
       <div class="fr-flex__col-4">
          <div class="fr-form__view">
             <label> Associate Name <em> * </em></label>
             <span [pTooltip]="associateViewData?.associateName?.length > 40 ? associateViewData?.associateName : ''" tooltipPosition="top"> <em>{{associateViewData.associateName}} </em></span> 
          </div>
       </div>
       <div class="fr-flex__col-4">
          <div class="fr-form__view">
             <label> Contract <em> * </em></label>
             <span [pTooltip]="associateViewData?.contract?.length > 40 ? associateViewData?.contract : ''" tooltipPosition="top"> <em> {{associateViewData.contract}}  </em></span> 
          </div>
       </div>
       <div class="fr-flex__col-4">
          <div class="fr-form__view">
             <label> Allocation <em> * </em> </label>
             <span [pTooltip]="associateViewData?.allocation?.length > 40 ? associateViewData?.allocation : ''" tooltipPosition="top"> <em> {{associateViewData.allocation}} </em></span> 
          </div>
       </div>
    </div>
    <div class="fr-flex__container">
        <div class="fr-flex__col-4">
            <div class="fr-form__view">
               <label> Location <em> * </em></label>
               <span [pTooltip]="associateViewData?.location?.length > 40 ? associateViewData?.location : ''" tooltipPosition="top"> <em> {{associateViewData.location}} </em></span> 
            </div>
         </div>
       <div class="fr-flex__col-4">
          <div class="fr-form__view">
             <label> Email <em> * </em></label>
             <span [pTooltip]="associateViewData?.email?.length > 40 ? associateViewData?.email : ''" tooltipPosition="top"> <em> {{associateViewData.email}} </em></span> 
          </div>
       </div>
       <div class="fr-flex__col-4">
          <div class="fr-form__view">
             <label> Country Code & Phone Number   </label>
             <span>
                <em> {{associateViewData.countryCode?associateViewData.countryCode:"--"}} {{associateViewData.phoneNumber?associateViewData.phoneNumber:"--"}}  </em>
               </span> 
          </div>
       </div>
       
    </div>
    <div class="fr-flex__container">
       <div class="fr-flex__col-12">
          <div class="fr-form__view">
             <label> Address </label>
             <span class="full-width" *ngIf="address  == null || address == ''">--</span> <span class="full-width" [innerHTML]="address" *ngIf="address  != null"> {{address }} </span>
          </div>
       </div>
    </div>
    <div class="fr-flex__container"> 
    <div class="fr-flex__col-4">
        <div class="fr-form__view">
           <label> Status <em> * </em> </label>
           <span> <em> {{associateViewData.status}}  </em></span>
        </div>
     </div>
     <div class="fr-flex__col-4">
      <div class="fr-form__view">
         <label> Onboarding Date <em> * </em> </label>
         <span> <em > {{associateViewData?.onboardingDate  | date: _dateformart.GridDateFormat }}</em></span>
      </div>
   </div>
   <div class="fr-flex__col-4">
      <div class="fr-form__view">
         <label> Offboarding Date  </label>
         <span *ngIf="associateViewData?.offboardingDate!=null"> <em>{{associateViewData?.offboardingDate  | date: _dateformart.GridDateFormat }}</em></span>
         <span *ngIf="associateViewData?.offboardingDate==null"> <em> --</em></span>
      </div>
   </div>
    </div>
    <div class="fr-flex__container">
      <div class="fr-flex__col-12">
         <div class="fr-form__view">
            <label> Offboarding Reason </label>
            <span class="full-width" *ngIf="offboardingReason  == null || offboardingReason == '' ">--</span> <span class="full-width" [innerHTML]="offboardingReason" *ngIf="offboardingReason  != null"> {{offboardingReason }} </span>
         </div>
      </div>
   </div>
    <div class="fr-flex__container">
       <div class="fr-flex__col-4">
          <div class="fr-form__view">
             <label> Role <em> * </em> </label>
             <span [pTooltip]="associateViewData?.role?.length > 40 ? associateViewData?.role : ''" tooltipPosition="top"> <em>{{associateViewData.role}}  </em></span>
          </div>
       </div>
       <div class="fr-flex__col-4">
          <div class="fr-form__view">
             <label> Functional Head  </label>
             <span [pTooltip]="associateViewData.functionalHead?.length > 40 ? associateViewData.functionalHead : ''" tooltipPosition="top"> <em> {{associateViewData.functionalHead?associateViewData.functionalHead:"--"}}  </em></span>
          </div>
       </div>
       
    </div>
    <div class="fr-flex__container">
      <div class="fr-flex__col-4">
         <div class="fr-form__view">
            <label> Sponsor Username </label>
            <span [pTooltip]="associateViewData?.sponsorUsername?.length > 40 ? associateViewData?.sponsorUsername : ''" tooltipPosition="top"> <em> {{associateViewData.sponsorUsername?associateViewData.sponsorUsername:"--"}} </em></span>
         </div>
      </div>
       <div class="fr-flex__col-4">
         <div class="fr-form__view">
            <label> Supplier Username </label>
            <span [pTooltip]="associateViewData?.supplierUsername?.length > 40 ? associateViewData?.supplierUsername : ''" tooltipPosition="top"> <em> {{associateViewData.engagementUsername?associateViewData.engagementUsername:"--"}} </em></span>
         </div>
      </div>
      

    </div>
    <div class="fr-flex__container">
      <div class="fr-flex__col-4">
         <div class="fr-form__view">
            <label> Sponsor Asset  <em> * </em> </label>
            <span [pTooltip]="associateViewData?.sponsorAsset?.length > 40 ? associateViewData?.sponsorAsset : ''" tooltipPosition="top"> <em> {{associateViewData.sponsorsAsset?associateViewData.sponsorsAsset:"--"}}  </em></span>
         </div>
      </div>
      <div class="fr-flex__col-4">
         <div class="fr-form__view">
            <label>Supplier Asset </label>
            <span [pTooltip]="associateViewData?.supplierAsset?.length > 40 ? associateViewData?.supplierAsset : ''" tooltipPosition="top"> <em> {{associateViewData.supplierAsset?associateViewData.supplierAsset:"--"}}  </em></span>
         </div>
      </div>
      
    </div>
    <div class="fr-flex__container">
      <div class="fr-flex__col-12">
         <div class="fr-form__view">
            <label> Sponsor Asset Details  </label>
            <span class="full-width" *ngIf="SponsorAssetDetails  == null || SponsorAssetDetails == '' ">--</span> <span class="full-width" [innerHTML]="SponsorAssetDetails" *ngIf="SponsorAssetDetails!= null"> {{SponsorAssetDetails}} </span>
         </div>
      </div>
    
   </div>
    
    <div class="fr-flex__container">
       <div class="fr-flex__col-12">
          <div class="fr-form__view">
             <label> Notes </label>
             <span class="full-width" *ngIf="notes  == null || notes == '' ">--</span> <span class="full-width" [innerHTML]="notes" *ngIf="notes  != null"> {{notes }} </span>
          </div>
       </div>
    </div>
    <div class="fr-flex__container">
        <div class="fr-flex__col-3">
           <div class="fr-form__view">
              <label> Added By</label>
              <span> <em> {{createdBy}} </em></span>
           </div>
        </div>
        <div class="fr-flex__col-3">
           <div class="fr-form__view">
              <label> Added Date & Time </label>
              <span> <em>   {{associateViewData?.createdDate | date: _dateformart.DateTimeWithZone  }} {{_dateformart.DisplayUTC}}</em></span>
           </div>
        </div>
        <div class="fr-flex__col-3">
           <div class="fr-form__view">
              <label> Modified By</label>
              <span  *ngIf="updatedBy !=null"> <em>  {{updatedBy}} </em></span>
            <span *ngIf="updatedBy == null || updatedBy == undefined || updatedBy == ''"> <em> -- </em></span>
           </div>
        </div>
        <div class="fr-flex__col-3" >
           <div class="fr-form__view">
              <label> Modified Date & Time </label>
              <span  *ngIf="updatedBy !=null && updatedBy != undefined && updatedBy != ''"> <em> {{associateViewData?.updatedDate | date: _dateformart.DateTimeWithZone }} {{_dateformart.DisplayUTC}}</em></span>
               <span *ngIf="updatedBy ==null || updatedBy == undefined || updatedBy == ''"> <em> -- </em></span>
             </div>
        </div>
     </div>
 </div>
</div>
 </body>
</html>