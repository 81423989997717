import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ConfirmationService, MessageService,  MenuItem } from 'primeng/api';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';
import { Dateformat,breadcrumnsCodes, AuditIdentifier, AuditLogEntities, AuditOperations, AuditLogModule, FrequencyValues } from 'src/app/shared/constants/global.constants';
import { LoaderService } from 'src/app/Loader/loader.service';
import { } from 'src/app/shared/Guards/can-deactivate-guard.service';
import { Observable} from 'rxjs';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { ContractKPIService } from '../../Services/ContractKpi.Service';
import { ContractKpi, KPIThreshold } from '../../Model/contractKpi';
import { CodeListValues, MasterKpiData } from '../../../Engagement/Model/engagementKpi';
import { ContractService } from 'src/app/Contracts/Services/contract.service';
import { DatePipe,} from '@angular/common';
import { AuditLogService } from 'src/app/shared/ModelComparison/audit.log.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { CommonService } from 'src/app/shared/Service/common.service';

@Component({
  selector: 'app-con-create-kpi',
  templateUrl: './con-create-kpi.component.html',
  styleUrls: ['./con-create-kpi.component.css']
})
export class ConCreateKpiComponent implements OnInit  {
  @Input() contractDetailId: any;
  @Output() cancelKpitButtonClick = new EventEmitter<void>();
  @Output() saveKpiButtonClick = new EventEmitter<void>();
  @Output() viewKpiButtonClick: EventEmitter<any> = new EventEmitter<any>();
executeCanExist: boolean = true;
breadcrumbs: MenuItem[]=[];
_enumBreadcrumb :any;
_dateformart :any;
 isLoading=false;
kpiNameDataSource: CodeListValues[] | undefined;
kpiReportingFrequencyDataSource: CodeListValues[] | undefined;
kpiDataCollectionFrequencyDataSource: CodeListValues[] | undefined;
kpiReportingFrequencyMainDataSource: CodeListValues[] | undefined;
statusDataSource: CodeListValues[] | undefined;
hyperCareThresholdDataSource: CodeListValues[] | undefined;
steadyStateThresholdDataSource: CodeListValues[] | undefined;
masterKpiDataSource: MasterKpiData[] | undefined;
_contractKpi=new ContractKpi();
_kpiSteadyStateThreshold= new KPIThreshold();
_kpiSteadyStateThresholds: KPIThreshold[]=[];
_kpiHyperCareThreshold= new KPIThreshold();
_kpiHyperCareThresholds: KPIThreshold[]=[];
_dynamicKpiHyperCareThreshold= new KPIThreshold();
_dynamicKpiHyperCareThresholds: KPIThreshold[]=[];
_dynamicKpiSteadyStateThreshold= new KPIThreshold();
_dynamicKpiSteadyStateThresholds: KPIThreshold[]=[];
_readOnlyUnitofMeasure:string="";
_readOnlyCategory:string="";
_readOnlyComplianceIndicator:string="";
_readOnlyCalculation:string="";
_readOnlyAttribute1:string="";
_readOnlyAttribute2:string="";
_readOnlyAttribute3:string="";
_readOnlyFormulaName:string="";
_readOnlySybmolHyperCareThreshold:string="";
_readOnlySybmolSteadyStateThreshold:string="";
_isNotApplicable:boolean=false;
_unitCode:string="";
 submitted = false;
 isSaveBtnDisable = false;
  KpiMinEndDate:any;
  KpiMaxStartDate:any;
  hyperCareMaxEndDate:any;
  hyperCareMinEndDate:any;
  hyperCareMaxStartDate:any;
  myContractKPIForm: FormGroup;
  ContractId:any;
  ContractName:any;
  _oldContractKpi:any
  _hyperStartDatedisable:boolean=true;
  _dataCollectionCode:string="";
  suggestedKPIName:string="";
  frequencyData:any;

  constructor(private router: Router, 
    private fb: FormBuilder, 
    private confirmationService: ConfirmationService, 
    private messageService: MessageService, 
    private loaderService: LoaderService, 
    private breadcrumbService: BreadcrumbService,
    private masterDataService: SponserService,
    private service: ContractKPIService,
    private _contractService : ContractService,
    private activeRouter: ActivatedRoute,
    private datepipe:DatePipe,
    private auditLogService: AuditLogService,
    private privilegeService:PrivilegeService,
    private commonService:CommonService)
    {
    this.myContractKPIForm = this.fb.group({
      _KPIName:['', [Validators.required,this.validateStatus]],
      _kpiReportingFrequency:['', [Validators.required,this.validateStatus]],
      _kpiDataCollectionFrequency:['', [Validators.required,this.validateStatus]],
      //_kpiStartDate:['',[Validators.required]],
      //_kpiEndDate:['',[Validators.required]],
      _status:['', [Validators.required,this.validateStatus]],
      _unitOfMeasure: [{ value: '', disabled: true }],	

      _hyperCareThreshold: [null, this._isNotApplicable ? null : Validators.required],
      _hyperCareThresholdValue:['',[this._isNotApplicable ? null : Validators.required,Validators.minLength(1),Validators.maxLength(3),Validators.pattern(/^[0-9]*$/)]],
      _hyperCareThresholdsymbol:[{ value: '', disabled: true }],
      // _hyperCareStartDate:[{ value: '', disabled: true }],
      //_hyperCareStartDate:[{ value: ''}],
      _hyperCareStartDate:[null, this._isNotApplicable ? null : Validators.required],
      _hyperCareEndDate:[null, this._isNotApplicable ? null : Validators.required],

      _dyhyperCareThreshold: [''],
      _steadyStateThreshold:['', [Validators.required]],
      _dysteadyStateThreshold:[''],
      _dyhyperCareThresholdValue:[''],
      _dyhyperCareThresholdsymbol:[{ value: '', disabled: true }],	

      _steadyStateThresoldValue:['',[Validators.required,Validators.minLength(1),Validators.maxLength(3),Validators.pattern(/^[0-9]*$/)]],
      _steadyStateThresoldSymbol:[{ value: '', disabled: true }],	
      _dysteadyStateThresoldValue:[''],
      _dysteadyStateThresoldSymbol:[{ value: '', disabled: true }],	
      _category:[{ value: '', disabled: true }],	
      _complianceIndicator:[{ value: '', disabled: true }],	
      _calculation:[{ value: '', disabled: true }],	
      // _attribute1:[{ value: '', disabled: true }],	
      // _attribute2:[{ value: '', disabled: true }],	
      // _attribute3:[{ value: '', disabled: true }],	
      _attribute1:['', [Validators.required,Validators.minLength(3),this.noOnlySpacesValidator,Validators.pattern(/^[^&%]*$/)]],
      _attribute2:['', [Validators.required,Validators.minLength(3),this.noOnlySpacesValidator,Validators.pattern(/^[^&%]*$/)]],
      _attribute3:[{ value: ''}],	
      _kpiDescription:['',[Validators.maxLength(300)]],	
      _serviceName:['', [Validators.required,Validators.maxLength(200),this.noOnlySpacesValidator,Validators.pattern(/^[^&%]*$/)]],
      _kpiName:['', [Validators.required,Validators.minLength(3),Validators.maxLength(400),this.noOnlySpacesValidator,Validators.pattern(/^[^&%]*$/)]],
      _frequencyValueforStartDate:['', [Validators.required]],
      _kpiStartDateYear:['', [Validators.required,Validators.minLength(3)]],
      _frequencyValueforEndDate:['', [Validators.required]],
      _kpiEndDateYear:['', [Validators.required,Validators.minLength(3)]],
    });
  }; 
  
  noOnlySpacesValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value && control.value.trim().length === 0) {
      return { onlySpaces: true };
    }
    return null;
  }
  validateStatus(control: FormControl) {
    const status = control.value;
    if (status === undefined || status === 0) {
        return { required: true }; // Validation failed
    }
    return null; // Validation passed
}
  auditIdentifierDetails:any;
   auditEntities:any;
   auditOperations:any;
   auditModule:any;

  ngOnInit() {
    this.loaderService.showLoader();
    this.loaderService.hidefinalLoader();
  
    this.ContractId= this.contractDetailId;
    this._contractKpi.contractDetailId=this.ContractId;
    this.loaderService.hidefinalLoader();
    this.KpiMinEndDate=null;
    this.KpiMaxStartDate=null;
    this.hyperCareMinEndDate=null;
    this.hyperCareMaxStartDate=null;
    this._enumBreadcrumb=breadcrumnsCodes;
    this._dateformart=Dateformat;
    this.auditIdentifierDetails=AuditIdentifier;
    this.auditEntities=AuditLogEntities;
    this.auditOperations=AuditOperations;
    this.auditModule=AuditLogModule;

    this.GetAllKpiNames(7);
    this.GetAllKpiReportingFrequencies(9) ;
    this.GetAllkpiDataCollectionFrequenies(8);
    this.GetAllStatus(3);
    this.GetAllHyperCareThresholds(10);
    this.GetAllMasterKpis(12);
    this.GetContractDataById(this.ContractId)
    this.formOldContractKpi();
    this.oldContractKPIData=JSON.stringify(this._contractKpi);
}


GetAllKpiNames(type:number) {
  this.masterDataService.getCodeListData(type,'ContractKPI').subscribe(
    res=>{
      this.kpiNameDataSource=res.data;
    }
  );
};

GetAllKpiReportingFrequencies(type:number) {
  this.masterDataService.getCodeListData(type,'').subscribe(
    res=>{
      this.kpiReportingFrequencyMainDataSource=res.data;
    }
  );
};
GetAllkpiDataCollectionFrequenies(type:number) {
  this.masterDataService.getCodeListData(type,'').subscribe(
    res=>{
      this.kpiDataCollectionFrequencyDataSource=res.data;
    }
  );
};
GetAllStatus(type:number) {
  this.masterDataService.getCodeListData(type,'KPI Status').subscribe(
    res=>{
      this.statusDataSource=res.data;
    }
  );
};
GetAllHyperCareThresholds(type:number) {
  this.masterDataService.getCodeListData(type,'').subscribe(
    res=>{
      this.hyperCareThresholdDataSource=res.data;
      this.steadyStateThresholdDataSource=res.data;
    }
  );
};
GetAllMasterKpis(type:number) {
  this.masterDataService.GetAllSupplierMasterData(type).subscribe(
    res=>{
      this.masterKpiDataSource=res.data;
    }
  );
};

isAttribute1NotApplicable=false;
isAttribute2NotApplicable=false;
isAttribute3NotApplicable=false;
OnChangeKPIName(data:any){
  var selectedKpi =data.value;
  var filteContractMasterData = this.masterKpiDataSource?.filter(t=>t.kpiDetailId==selectedKpi);
  if(filteContractMasterData!=null)
  {
this._readOnlyUnitofMeasure=filteContractMasterData[0].unitOfMeasure;
this._readOnlyCategory=filteContractMasterData[0].category;
this._readOnlyComplianceIndicator=filteContractMasterData[0].complianceIndicator;
this._readOnlyCalculation=filteContractMasterData[0].calculation;

if(filteContractMasterData[0].attribute1 == 'NA')
  {
  this.isAttribute1NotApplicable=true;
  }
  else{
    this.isAttribute1NotApplicable=false;
  }
if(filteContractMasterData[0].attribute2 == 'NA')
  {
    this.isAttribute2NotApplicable=true;
  }
  else{
    this.isAttribute2NotApplicable=false;
  }
if(filteContractMasterData[0].attribute3 == 'NA')
  {
      this.isAttribute3NotApplicable=true;
  }
  else{
    this.isAttribute3NotApplicable=false;
  }


this._readOnlyAttribute1=filteContractMasterData[0].attribute1;
this._readOnlyAttribute2=filteContractMasterData[0].attribute2;
this._readOnlyAttribute3=filteContractMasterData[0].attribute3;
this._unitCode=filteContractMasterData[0].unitOfMeasureCode;
this._readOnlyFormulaName=filteContractMasterData[0].formulaName;
if(!this._isNotApplicable)
{
  this._readOnlySybmolHyperCareThreshold=this._unitCode;
}
else{
  this._readOnlySybmolHyperCareThreshold="";
}
this._readOnlySybmolSteadyStateThreshold=this._unitCode;

}
 else{
  this._readOnlyUnitofMeasure="";
  this._readOnlyCategory="";
  this._readOnlyComplianceIndicator="";
  this._readOnlyCalculation="";
  this._readOnlyAttribute1="";
  this._readOnlyAttribute2="";
  this._readOnlyAttribute3="";
  this._readOnlySybmolHyperCareThreshold="";
  this._readOnlySybmolSteadyStateThreshold="";
  this._unitCode="";
  this._readOnlyFormulaName="";
  }

  this.PrepareKPINameSuggestion();
}
chkChange(event: any) {
 if(event.checked)
 {
  this.myContractKPIForm.controls['_hyperCareStartDate'].setErrors(null);
  this.myContractKPIForm.controls['_hyperCareEndDate'].setErrors(null);
  this.myContractKPIForm.controls['_hyperCareThresholdValue'].setErrors(null);
  this._contractKpi.hyperCareEndDate=undefined;
  this._readOnlySybmolHyperCareThreshold="";
  this._readOnlySybmolHyperCareThreshold="";
  this._kpiHyperCareThreshold= new KPIThreshold();
  this._dynamicKpiHyperCareThreshold= new KPIThreshold();
  this._dynamicKpiHyperCareThresholds.splice(0, 1);
  this._isNotApplicable=true;
 }
 else{
  this.myContractKPIForm.controls['_hyperCareStartDate'].setErrors({required: true});
  this.myContractKPIForm.controls['_hyperCareEndDate'].setErrors({required: true});
  this.myContractKPIForm.controls['_hyperCareThresholdValue'].setErrors({required: true});
  this._readOnlySybmolHyperCareThreshold=this._unitCode
  this._isNotApplicable=false;
 }
}
onSaveContractKpi()
{
  if(this._isNotApplicable)
  {
    this.myContractKPIForm.controls['_hyperCareStartDate'].setErrors(null);
    this.myContractKPIForm.controls['_hyperCareEndDate'].setErrors(null);
    this.myContractKPIForm.controls['_hyperCareThresholdValue'].setErrors(null);
  }
  if(this.DataCollectionFrequency == "Annual"){
    this.myContractKPIForm.controls['_frequencyValueforStartDate'].setErrors(null);
    this.myContractKPIForm.controls['_frequencyValueforEndDate'].setErrors(null);
  }
  this.submitted = true;
  this.isSaveBtnDisable = true;
  setTimeout(() => {
    this.isSaveBtnDisable = false;
  }, 3000);
  this.updateValidators('_attribute1');
  this.updateValidators('_attribute2');
  if (this.myContractKPIForm.valid) {
    this.executeCanExist = false;
    this.loaderService.showLoader();
    if(!this._isNotApplicable)
    {
      this._kpiHyperCareThresholds.push(this._kpiHyperCareThreshold);
      if(this._dynamicKpiHyperCareThreshold.mathematicalSymbolId!=0)
      this._kpiHyperCareThresholds.push(this._dynamicKpiHyperCareThreshold)
    }
    this._kpiSteadyStateThresholds.push(this._kpiSteadyStateThreshold);
    if(this._dynamicKpiSteadyStateThreshold.mathematicalSymbolId!=0)
    this._kpiSteadyStateThresholds.push(this._dynamicKpiSteadyStateThreshold);
    this._contractKpi.KPIHyperCareThresholdDetail=this._kpiHyperCareThresholds;
    this._contractKpi.KPISteadyStateThresholdDetail=this._kpiSteadyStateThresholds;
    this._contractKpi.kpiReportingFrequencyCode=this._dataCollectionCode;

    this._contractKpi.attribute1=this._readOnlyAttribute1;
    this._contractKpi.attribute2=this._readOnlyAttribute2;
    this._contractKpi.attribute3=this._readOnlyAttribute3;
    this._contractKpi.calculation=this._readOnlyCalculation;

    this._contractKpi.createdBy=this.privilegeService.getLoginUserId()
    if(this._contractKpi.hyperCareEndDate != undefined && this._contractKpi.hyperCareEndDate != null)
    {
      this._contractKpi.hyperCareEndDate =  this.datepipe.transform(this._contractKpi.hyperCareEndDate, "MM/dd/yyyy");
    }
    if(this._contractKpi.hyperCareStartDate != undefined && this._contractKpi.hyperCareStartDate != null)
    {
      this._contractKpi.hyperCareStartDate = this.datepipe.transform(this._contractKpi.hyperCareStartDate, "MM/dd/yyyy");
    }     

    let frequency=this.kpiDataCollectionFrequencyDataSource?.filter(t=>t.id==this._contractKpi.kpiDataCollectionFrequencyId)[0].name;
    
    this._contractKpi.kpiStartDate=this.commonService.getCompleteDate(frequency,this._contractKpi.frequencyValueforStartDate,this._contractKpi.kpiStartDateYear);
    this._contractKpi.kpiEndDate=this.commonService.getCompleteDate(frequency,this._contractKpi.frequencyValueforEndDate,this._contractKpi.kpiEndDateYear);
    
    if(this._contractKpi.kpiStartDate>this._contractKpi.kpiEndDate)
    {
      this.messageService.add({  key: 'tc',severity: 'warn', summary: 'KPI Tracking End should be greater than KPI Tracking Start', detail: '' });  
      this.loaderService.hideLoader();    
    }
    else{
    this._contractKpi.kpiEndDate = this.datepipe.transform(this._contractKpi.kpiEndDate, "MM/dd/yyyy");
    this._contractKpi.kpiStartDate = this.datepipe.transform(this._contractKpi.kpiStartDate, "MM/dd/yyyy");
    this.service.AddContractKpi(this._contractKpi).subscribe(res => {
     if(res.status)
     {
      this.messageService.add({ key: 'tc', severity: 'success', summary: 'KPI has been added', detail: '' });
      this.loaderService.hideLoader();
      this.compareModels();
       const dataResult = {
        ContractId:this.ContractId,
        kpiId:parseInt(res.data)
      };
      this.viewKpiButtonClick.emit(dataResult);
     }
   });
  }
  }
  else{
    this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Please enter required information', detail: '' });  
  }
}
onCancelContractKpi()
{
 if ((this._oldContractKpi!=JSON.stringify(this._contractKpi)))
  {
      this.confirmationService.confirm({
        message: 'KPI will not be added, Do you want to Continue?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.cancelKpitButtonClick.emit();
          this.messageService.add({
            key: 'tc',
            severity: 'info',
            summary: 'KPI not added',
            detail: '',
        });
        },
        reject: () => {
        },
      });
  }
  else{
    this.cancelKpitButtonClick.emit();
  }
}


onHyperStartDateSelection(startDate:any,type:number){
    this.hyperCareMinEndDate= new Date(startDate);
    this.hyperCareMinEndDate.setDate(this.hyperCareMinEndDate.getDate() + 1);
}



onStartDateSelection(startDate:any,type:number){
  if(type==1)
  {
    this.KpiMinEndDate= new Date(startDate);
    this.KpiMinEndDate.setDate(this.KpiMinEndDate.getDate() + 1);
    this._contractKpi.hyperCareStartDate=startDate;
  }
  //else{
    this.hyperCareMinEndDate= new Date(startDate);
    this.hyperCareMinEndDate.setDate(this.hyperCareMinEndDate.getDate() + 1);
  //}
}
onEndDateSelection(endDate:any,type:number){
  if(type==1)
  {
    this.KpiMaxStartDate= new Date(endDate);
    this.KpiMaxStartDate.setDate(this.KpiMaxStartDate.getDate() - 1);

    this.hyperCareMaxEndDate= new Date(endDate);
    this.hyperCareMaxEndDate.setDate(this.hyperCareMaxEndDate.getDate());
  }
  else{
    this.hyperCareMaxStartDate= new Date(endDate);
    this.hyperCareMaxStartDate.setDate(this.hyperCareMaxStartDate.getDate() - 1);
  }
   
}
addRow(type:number) {
  if(type==1)
  {
  this._dynamicKpiHyperCareThresholds.push({
    Id:0,
    percentageValue: '',
    mathematicalSymbolId:0,
    contractKPIId:0,
  });
}
else{
  this._dynamicKpiSteadyStateThresholds.push({
    Id:0,
    percentageValue: '',
    mathematicalSymbolId:0,
    contractKPIId:0,
  });
}
}
removeRow(index: number,type:number) {
  if(type==1)
  {
    this._dynamicKpiHyperCareThreshold= new KPIThreshold();
    this._dynamicKpiHyperCareThresholds.splice(index, 1);
  }
  else{
    this._dynamicKpiSteadyStateThreshold= new KPIThreshold();
    this._dynamicKpiSteadyStateThresholds.splice(index, 1);
  }
}
GetContractDataById(ContractId:number){
 
  this._contractService.GetContractDtailsById(ContractId).subscribe(
    res=>{
      this.loaderService.hideLoader();
      this.ContractName=res.data.title;
      this.suggestedKPIName=this.ContractName;
    }
  );
}
canExist(): boolean | Promise<boolean> | Observable<boolean> {
  if (this.executeCanExist &&!(this._oldContractKpi==JSON.stringify(this._contractKpi)))
  {
    return new Promise<boolean>((resolve) => {
      this.confirmationService.confirm({
        message: 'KPI will not be added, Do you want to Continue?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.messageService.add({
            key: 'tc',
            severity: 'info',
            summary: 'KPI not added',
            detail: '',
        });
          resolve(true); 
        },
        reject: () => {
          resolve(false); 
        },
      });
    });
  }
  else{
    return true;
  }
  
}
generateBreadcrumb(component?:string,recordName?:string,tabName?:string,childRecName?:string,recordId?:number)
{
  
  this.breadcrumbService.generateBreadcrumb(component,recordName,tabName,childRecName,recordId);
}

getBreadcrumbs(): MenuItem[] {
  return this.breadcrumbService.getBreadcrumbs();
}
formOldContractKpi(){
  setTimeout(() => {
    this._oldContractKpi=JSON.stringify(this._contractKpi);
  }, 2000);
}
onNumericInput(event: Event,_controlname:string) {
  const inputElement = event.target as HTMLInputElement;
  const sanitizedValue = inputElement.value.replace(/\D/g, '');
  inputElement.value = sanitizedValue;
  this.myContractKPIForm.controls[_controlname].setValue(sanitizedValue);
}

isFrequencyAnnual=false;
DataCollectionFrequency:string="";
OnChangeDataCollection(data:any){
  var selectedItem =data.value;
  var filteDataCollection = this.kpiDataCollectionFrequencyDataSource?.filter(t=>t.id==selectedItem);
  if(filteDataCollection!=null)
  {
  this._dataCollectionCode=filteDataCollection[0].code;
  var selectedSubCode=filteDataCollection[0].sub_Code;
  this.DataCollectionFrequency=filteDataCollection[0].name;
 
  this.kpiReportingFrequencyDataSource=this.kpiReportingFrequencyMainDataSource?.filter(t=>t.sub_Code>=selectedSubCode); 
  if(filteDataCollection[0].name=="Annual"){
    this.isFrequencyAnnual=true;
  }
  else{
    this.isFrequencyAnnual=false;
  }
  //Get Frequency Data Here
  if(filteDataCollection[0].name=="Monthly")
    this.frequencyData=JSON.parse(FrequencyValues.Monthly);
  else if(filteDataCollection[0].name=="Quarterly")
    this.frequencyData=JSON.parse(FrequencyValues.Quarterly);
  else if(filteDataCollection[0].name=="Half-Year")
    this.frequencyData=JSON.parse(FrequencyValues.HalfYearly);
  else if(filteDataCollection[0].name=="Annual")
  this.frequencyData=JSON.parse(FrequencyValues.Annual);
  

}
  else
  {
    this._dataCollectionCode="";
  }
}


OnServiceChange(){
  this.PrepareKPINameSuggestion();
}

PrepareKPINameSuggestion(){
 
  if(this._contractKpi.kpiDetailId != null && this._contractKpi.kpiDetailId != undefined && this._contractKpi.serviceName != '' && this._contractKpi.serviceName != null && this._contractKpi.serviceName != undefined)
  {
    
    var kpi = this.kpiNameDataSource?.find((aa:any) => aa.id === this._contractKpi.kpiDetailId)?.name;
    this.suggestedKPIName=this.ContractName + '-' + kpi + '-' + this._contractKpi.serviceName;
  
  }
  else if(this._contractKpi.kpiDetailId != null && this._contractKpi.kpiDetailId != undefined && (this._contractKpi.serviceName == '' || this._contractKpi.serviceName == null || this._contractKpi.serviceName == undefined))
  {
    var kpi = this.kpiNameDataSource?.find((aa:any) => aa.id === this._contractKpi.kpiDetailId)?.name;
    this.suggestedKPIName=this.ContractName + '-' + kpi;
  
  }
  else if((this._contractKpi.kpiDetailId == null || this._contractKpi.kpiDetailId == undefined) && this._contractKpi.serviceName != '' && this._contractKpi.serviceName != null && this._contractKpi.serviceName != undefined)
  {
    this.suggestedKPIName=this.ContractName  + '-' + this._contractKpi.serviceName;
  }
  else{
    this.suggestedKPIName=this.ContractName;
  }
  
}

changedContractKPIBasicInfo:any;
oldContractKPIData:any;
compareModels() {
  this.changedContractKPIBasicInfo = this.auditLogService.compareModels(JSON.parse(this.oldContractKPIData),this._contractKpi,this.auditEntities.ContractKPIs,this.auditIdentifierDetails.ContractKPIs,this.auditOperations.Create,this.auditModule.GovernanceService);
 }



 getCalculationFormulae(){
  //this._readOnlyFormulaName
  var data=this._readOnlyFormulaName;
  data=data.replace('Attribute 1',this._readOnlyAttribute1);
  data=data.replace('Attribute 2',this._readOnlyAttribute2);
  data=data.replace('Attribute 3',this._readOnlyAttribute3);
  this._readOnlyCalculation=data;
 }
 updateValidators(controlName: string) {
  const control = this.myContractKPIForm.get(controlName);
  if (control) {
    if (control.value === 'NA') {
      control.clearValidators();
      control.setValidators([Validators.required, this.noOnlySpacesValidator, Validators.pattern(/^[^&%]*$/)]);
    } else {
      control.setValidators([Validators.required, Validators.minLength(3), this.noOnlySpacesValidator, Validators.pattern(/^[^&%]*$/)]);
    }
    control.updateValueAndValidity();
  }
}
}

