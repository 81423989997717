import { DatePipe, formatDate } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { } from 'primeng/calendar';
import { ContractService } from 'src/app/Contracts/Services/contract.service';
import { LoaderService } from 'src/app/Loader/loader.service';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';
import { AuditIdentifier, AuditLogEntities, AuditLogModule, AuditOperations, breadcrumnsCodes, CodeList, CodeListStatusTypes, Dateformat, FileExtension } from 'src/app/shared/constants/global.constants';

import { ContractMeetingAbsentees, ContractMeetingAttendees, ContractMeetingDetails, ContractMeetingOccurrenceAttachments,ContractMeetingOccurrences,ContractAddMeetingOccurenceActions, ContactMeetingContacts } from '../../Modals/ContractMeeting';
import { ConfirmationService, MessageService, MenuItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { ChecknulloremptyComponent } from 'src/app/shared/checknullorempty/checknullorempty.component';
import { ContractMeetingService } from '../../Services/MeetingServices';
import { AuditLogService } from 'src/app/shared/ModelComparison/audit.log.service';
import { AssociateService } from 'src/app/Team/teams/Services/associate.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { MasterSupplierService } from 'src/app/MasterData/supplier/Services/master.supplier.service';
import { EngagementService } from 'src/app/Engagement/Services/engagement.service';
import { FileUploadService } from 'src/app/Engagement/Services/fileupload.service';

@Component({
  selector: 'app-con-create-meeting',
  templateUrl: './con-create-meeting.component.html',
  styleUrls: ['./con-create-meeting.component.css']
})

export class ConCreateMeetingComponent {
  @Input() ContractId: any;
  @Input() EngagementId: any;
  @Input() supplierName: any;
  @Output() cancelMeetingButtonClick = new EventEmitter<void>();
  @Output() saveMeetingButtonClick = new EventEmitter<void>();
  @Output() viewMeetingButtonClick: EventEmitter<any> = new EventEmitter<any>();

  contractAddMeetingForm: FormGroup;
  recurring: any[] = [];
  options = ['Yes', 'No'];
  _dateformart: any;
  addMeetingSaved = false;
  addmeeting: any[] = [];
  

  value!: string;
  date: Date | undefined;
  contractData: any;
  contactData: any;
 
  organizerOptions: any[] = [];
  selectedItems: [] = [];
  attendeesValues: any = '';
  absenteesValues: any = '';
  newAttachment: any;
  uploadedFiles: File[] = [];
  uploadedFileName: any;
  allowedExtensions: any;
  currentFileSize: any;
  multiMeetingFileData: any[] = [];
  maxFileSize: any
  timeStamp: any;
  codeListValues:any;
  codeListStatusTypes:any;
  _enumBreadcrumb :any;
  formData = new FormData();
  uploadedMeetingFile: any;
  meetingStatus: any[] = [];
  _addActionsFieldsMandatory: boolean = false;
  addMeetingActionsData: any[] = [];
  isAddActionsStatusSelectedAsClosed: boolean = false;
  _contractmeetingActions = new ContractAddMeetingOccurenceActions();
  contractMeetingDetails = new ContractMeetingDetails();
  _contractMeetingOccurrences = new ContractMeetingOccurrences();
  _contractMeetingAttendees = new ContractMeetingAttendees();
  _contractMeetingAbsentees = new ContractMeetingAbsentees();
  _contractMeetingOccurrenceAttachments = new ContractMeetingOccurrenceAttachments();
  _contractMeetingOccurrenceDetails: ContractMeetingOccurrences[] = [];
  _contractMeetingAttendeesDetails:ContractMeetingAttendees[] = [];
  _contractMeetingAbsenteesDetails: ContractMeetingAbsentees[] = [];
  _contractMeetingOccurrenceAttachmentsDetails: ContractMeetingOccurrenceAttachments[] = [];
  _contractMeetingActionsDetails: ContractAddMeetingOccurenceActions[] = [];
  _PostAddActionsMeetingData:any;
  attendees: string[] = [];
  absentees: string[] = [];
  isUploadButtonDisabled:boolean= false;

  attendeesCount:number=0;
  absenteesCount: number = 0;
  _allAttendees: string[] = [];
  _finalAttendees: any;
  contactFormGroup:FormGroup;
  contactName: string = '';
  constructor(
    private contractMeetingService: ContractMeetingService,
    private messageService: MessageService,
    private loaderService: LoaderService,
    private masterDataService: SponserService,
    private contractDataService: ContractService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private _fileService: FileUploadService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private breadcrumbService: BreadcrumbService,
      private checkNullOrEmptyValuesService: ChecknulloremptyComponent,
      private _associateService: AssociateService,
    private auditLogService:AuditLogService,
    private privilegeService:PrivilegeService,
    private _mastersupplierService: MasterSupplierService,
    private engagementService: EngagementService) {

    this.contractAddMeetingForm = this.fb.group({
      addMeetingOccurenceDate: ['', [Validators.required]],
      addMeetingTitle: ['', [Validators.required, Validators.maxLength(50), Validators.minLength(3), this.noOnlySpacesValidator]],
      addMeetingOrganizer: ['', [Validators.required]],
      addMeetingRecurring: ['', [Validators.required]],
      addMeetingFileSource: [''], addMeetingFileName: '',
      addMeetingAttachments: [''],
      addMeetingAgenda: ['', [Validators.required]],
      addMeetinMeetingNotes: [''],
      addMeetingDecisions: [''],
      _attendent: [''],
      _absent: [''],
    });
    this.contactFormGroup=this.fb.group({
      contractName:['',[Validators.required,Validators.maxLength(30),Validators.minLength(3),Validators.pattern(/^[A-Za-z- ]{3,30}$/)]],
    });
  }
  auditIdentifierDetails:any;
  auditEntities:any;
  auditOperations:any;
  auditModule:any;

   ngOnInit() {
    this._enumBreadcrumb=breadcrumnsCodes;
    this._dateformart = Dateformat;
    this.codeListValues=CodeList;
    this.codeListStatusTypes=CodeListStatusTypes
    this.GetMeetingStatus(this.codeListValues.Tbl_Status, this.codeListStatusTypes.MASTS);
    this.GetEngagementDetailsById();
    this.formOldSupplierMeeting();
    this.auditIdentifierDetails= AuditIdentifier;
    this.auditEntities=AuditLogEntities;
    this.auditOperations=AuditOperations;
    this.auditModule=AuditLogModule;
    this.oldContractMeetingData=JSON.stringify(this.contractMeetingDetails);
  }
  
sponserId:number=0;
  engagementData:any;
  GetEngagementDetailsById() {
   this.loaderService.showLoader();
    this.engagementService.GetEngagementById(this.EngagementId)
      .subscribe(res => {    
        this.engagementData = res.data;
        this.sponserId=this.engagementData.sponsorId;
        this.GetSupplierContacts(res.data.supplierId);
        this.GetSponserDetailsById();
      });
  }
  GetSupplierContacts(Id:number){
 
    this._mastersupplierService.getSupplierContactsDataById(Id)
    .subscribe(res=>{
      this.contactData = res.data;
    })
  }
sponserData:any;
sponsorContactData:any;
  GetSponserDetailsById(){
    this.loaderService.showLoader();
    this.loaderService.hidefinalLoader();
   this.masterDataService.getSponserData(this.sponserId)
   .subscribe(res=>{
 
     this.sponserData=res.data.sponserInfo;
     this.sponsorContactData=res.data.sponserContacts;
     this.GetSupplierTeamAssociatesList(this.EngagementId,2)
    // this.GetTeamAssociatesList(this.ContractId,1)
   
   })
  }
  supplierassociateData:any;
  supplierTeams:any;
  GetSupplierTeamAssociatesList(conractIdOrSupplierId:any,teamType:number){
   
    this._associateService.GetTeamAssociatesList(conractIdOrSupplierId,teamType).subscribe((res:any)=>{
      if(res.status){
        this.GetTeamAssociatesList(this.ContractId,1)
      this.supplierassociateData=res.data;
      this.supplierassociateData = this.supplierassociateData?.filter((t:any) => t.contractName === 'Not Applicable');
      this.supplierTeams = this.supplierassociateData.map((contact: { associateName: any; }) => `${contact.associateName} ( Associate )`);
      this.loaderService.hideLoader();
      }
    });
  }


  associateData:any;
  GetTeamAssociatesList(conractIdOrSupplierId:any,teamType:number){
   
    this._associateService.GetTeamAssociatesList(conractIdOrSupplierId,teamType).subscribe((res:any)=>{
      if(res.status){
      this.associateData=res.data;
      this.updateOrganizerOptions();
      }
    });
  }

  updateOrganizerOptions() {
    if (this.contactData) {
      const supplierOptions = this.contactData.map((contact: { contactName: any; }) => `${contact.contactName} ( ${this.supplierName} )`);
      if (!this.organizerOptions) {
        this.organizerOptions = [];
      }
      this.organizerOptions = this.organizerOptions.concat(supplierOptions);
      this._allAttendees=supplierOptions;
    }
    if (this.sponserData != undefined && this.sponsorContactData != undefined) {
      const sponsorOptions = this.sponsorContactData.map((contact: { name: any; }) => `${contact.name} ( ${this.sponserData.name} )`);
      if (!this.organizerOptions) {
        this.organizerOptions = [];
      }
      this.organizerOptions = this.organizerOptions.concat(sponsorOptions);
      this._allAttendees=this._allAttendees.concat(sponsorOptions);
    }
     //added by vittal for associates
     if (this.associateData != undefined && this.associateData != null) {
      const associateOptions = this.associateData.map((contact: { associateName: any; }) => `${contact.associateName} ( Associate )`);
      if (!this.organizerOptions) {
        this.organizerOptions = [];
      }
      this.organizerOptions = this.organizerOptions.concat(associateOptions);
      this.organizerOptions = this.organizerOptions.concat(this.supplierTeams);
      this._allAttendees=this._allAttendees.concat(associateOptions);
      this._allAttendees=this._allAttendees.concat(this.supplierTeams);
    }

    let attendeesObjects: { name: string; code: number }[] = [];
    if (this._allAttendees.length > 0) {
        attendeesObjects = this._allAttendees.map((attendee, index) => ({
            name: attendee,
            code: index + 1, 
        }));
    }

    this._finalAttendees=attendeesObjects;
    this._allAttendees = attendeesObjects.map((attendee) => attendee.name);
    this.loaderService.hideLoader();
    this.loaderService.hidefinalLoader();
  }
  onRecurringChange(event: any) {
    this.contractMeetingDetails.Recurring = event.value === 'Yes' ? true : false;
  }
  isSaveBtnDisable = false;
  saveContractMeetingData() {  //Click on Main Save 
    this.loaderService.showLoader();
    this.executeCanExist=false;
    this.isSaveBtnDisable = true;
    setTimeout(() => {
      this.isSaveBtnDisable = false;
    }, 3000);
    this._addActionsFieldsMandatory = false;  
    
    this._addActionsFieldsMandatory = this.addMeetingActionsData.some((Actions) => {
      if (
          this.checkNullOrEmptyValuesService.isNullOrUndefinedOrEmpty(Actions.ActionName) || this.checkNullOrEmptyValuesService.isNullOrUndefinedOrEmpty(Actions.StatusMappingId) || (Actions.StatusMappingId.code === "CLD" &&
              (this.checkNullOrEmptyValuesService.isNullOrUndefinedOrEmpty(Actions.ClosureDate) || this.checkNullOrEmptyValuesService.isNullOrUndefinedOrEmpty(Actions.Remarks)))
      ) {
     
          return true;
      }
    
      return false;
  }); 
    this.addMeetingSaved = true
    
    if (this.contractAddMeetingForm.valid && !this._addActionsFieldsMandatory) {   
      this.loaderService.showLoader(); 
      if (this.multiMeetingFileData.length > 0) {
        this._fileService.UploadMultipleFile(this.formData).subscribe((response: any) => {        
          if (response) {
          
            this.meetingSaveData();
            
            
          }
          else {        
            this.loaderService.hideLoader();    
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Add Meeting File Failed to save', detail: '' });
          }
        })
      }
      else{
        this.meetingSaveData();
      }

    }
    else{
      this.loaderService.hideLoader();
      this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Please enter required information', detail: '' });
    }
  }
  meetingSaveData() {
    this.loaderService.showLoader();
    this._contractMeetingOccurrences.OccurrenceDate = this.datePipe.transform(this._contractMeetingOccurrences.OccurrenceDate, "MM/dd/yyyy");
    this._PostAddActionsMeetingData =this.addMeetingActionsData;
    this._PostAddActionsMeetingData.forEach((record:any) => {
      record.StatusMappingId = record.StatusMappingId.id;
      record.TargetDate = this.datePipe.transform(record.TargetDate, "MM/dd/yyyy");
      record.ClosureDate = this.datePipe.transform(record.ClosureDate, "MM/dd/yyyy");
      this._contractMeetingActionsDetails.push(record);
    }, this); 
    this.contractMeetingDetails.CreatedBy = this.privilegeService.getLoginUserId(); 
     
    this.contractMeetingDetails.ContractId = this.ContractId;
    
    if (this.attendeesValues !== "") {
      this._contractMeetingAttendees.Attendees = this.attendeesValues.map((attendee: { name: any; }) => attendee.name).join(',');
      this._contractMeetingAttendeesDetails.push(this._contractMeetingAttendees);
    }

    if (this.absenteesValues !== "") {
      this. _contractMeetingAbsentees.Absentees = this.absenteesValues.map((absentees: { name: any; }) => absentees.name).join(',');
      this. _contractMeetingAbsenteesDetails.push(this. _contractMeetingAbsentees);
    }

    this._contractMeetingOccurrenceDetails.push(this._contractMeetingOccurrences);
    this.contractMeetingDetails.ContractMeetingOccurrences = this._contractMeetingOccurrenceDetails;
    this._contractMeetingOccurrences.ContractMeetingAttendees = this._contractMeetingAttendeesDetails;
    this._contractMeetingOccurrences.ContractMeetingAbsentees = this. _contractMeetingAbsenteesDetails;
    this._contractMeetingOccurrences.ContractMeetingOccurrenceAttachments = this._contractMeetingOccurrenceAttachmentsDetails;
    this._contractMeetingOccurrences.ContractMeetingOccurrenceActions = this._contractMeetingActionsDetails;
    this.contractMeetingService.ContractMeetingData(this.contractMeetingDetails).subscribe(
      res => {

        if(res.status){
          this.loaderService.hideLoader();
          this.messageService.add({
            key: 'tc',
            severity: 'success',
            summary: 'Meeting has been added',
            detail: '',
          });
          this.compareModels();
         const dataResult = {
          contractId:this.ContractId,
          meetingId:parseInt(res.data)
        };
        this.viewMeetingButtonClick.emit(dataResult);
        this.loaderService.hideLoader(); 
        }
        
      }
      
    );
  }
  noOnlySpacesValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value && control.value.trim().length === 0) {
      return { onlySpaces: true };
    }
    return null;
  }

  onFileChange(event: any) {
    const files: FileList = event.target.files;
  
    const resetFormControls = (message: string, severity: string = 'error') => {
      this.contractAddMeetingForm.patchValue({
        addMeetingFileName: '',
        addMeetingFileSource: ''
      });
      this.messageService.add({ key: 'tc', severity, summary: message, detail: '' });
    };
  
    if (files.length > 1) {
      resetFormControls('Select and upload 1 file at a time.');
      return;
    }
  
    if (files.length > 0) {
      this.maxFileSize = this._fileService._fileSize; // 20 MB
      this.currentFileSize = files[0].size;
      this.uploadedFileName = files[0].name;
      this.allowedExtensions = [
        FileExtension.DOC, FileExtension.DOCX, FileExtension.PDF,
        FileExtension.XLS, FileExtension.XLSX, FileExtension.PPT, FileExtension.PPTX
      ];
      const uploadedExtension = this.uploadedFileName.split('.').pop().trim().toLowerCase();
  
      if (!this.allowedExtensions.includes(uploadedExtension)) {
        resetFormControls('Invalid file type. Only .pdf, .xls, .xlsx, .doc, .docx files are allowed.');
        return;
      }
  
      if (this.currentFileSize > this.maxFileSize) {
        resetFormControls('File Size limited to 20 Mb', 'warn');
        return;
      }
  
      if (this.uploadedFiles.length + files.length > 3) {
        resetFormControls('Maximum 3 files allowed.');
        return;
      }
  
      for (let i = 0; i < files.length; i++) {
        this.uploadedFiles.push(files[i]);
        this.contractAddMeetingForm.patchValue({
          addMeetingAttachments: files[0].name,
        });
        this.uploadedMeetingFile = files[0];
        this.multiMeetingFileData.push(this.uploadedMeetingFile);
  
        this.timeStamp = formatDate(
          new Date().toISOString(),
          this._dateformart.FiletimeStamp,
          this._dateformart.TimeStampZone
        );
  
        this.formData.append(
          "file",
          this.uploadedMeetingFile,
          `Governance/SupplierMeeting/${this.timeStamp}_${this.uploadedMeetingFile.name}`
        );
  
        this._contractMeetingOccurrenceAttachments.FileName = this.uploadedMeetingFile.name;
        this._contractMeetingOccurrenceAttachments.FilePath = `Governance/SupplierMeeting/${this.timeStamp}_${this._contractMeetingOccurrenceAttachments.FileName}`;
  
        this.newAttachment = {
          FileName: this._contractMeetingOccurrenceAttachments.FileName,
          FilePath: this._contractMeetingOccurrenceAttachments.FilePath,
        };  
        this._contractMeetingOccurrenceAttachmentsDetails.push(this.newAttachment);
      }
    }
  
    this.isUploadButtonDisabled = this.uploadedFiles.length >= 3;
  }
  

  removeFile(file: File) {
    const index = this.uploadedFiles.indexOf(file);
    if (index !== -1) {
      this.uploadedFiles.splice(index, 1);

      const attachmentIndex = this._contractMeetingOccurrenceAttachmentsDetails.findIndex(attachment => attachment.FileName === file.name);
      if (attachmentIndex !== -1) {
        this._contractMeetingOccurrenceAttachmentsDetails.splice(attachmentIndex, 1);
      }
    }
    if(this.uploadedFiles.length >= 3)
    {
      this.isUploadButtonDisabled = true;
    }
    else{
      this.isUploadButtonDisabled = false;
    }
  }
 // deleteProduct(addmeeting: any) {//Delete Add Action Meeting item form Add Action table
 deleteAction(currentActionIndex: number) {  
 this.confirmationService.confirm({
      header: 'Confirm',
      message: 'Are you sure you want to delete this action?',//+" "+ addmeeting.ActionName + '?',
      accept: () => {
       
      this.addMeetingActionsData.splice(currentActionIndex, 1);
      this.messageService.add({ severity: 'success', summary: 'Meeting Action Deleted', detail: '', });
      }
    });
  }
  AddMeetingActions() { //Click on Add Action button new row will be generated on top of the grid
    this.addMeetingActionsData.unshift({
      Id: 0,
      ActionName: '',
      Owner: "",
      TargetDate: null,
      StatusMappingId: '',
      ClosureDate: null,
      Remarks: '',
      SupplierMeetingOccurrenceId: 0,    
      IsDeleted: false
    });
  }
  GetMeetingStatus(enumId: number, type: string) { //Get Meeting Status's from code list for the Meetings
    this.masterDataService.getCodeListData(enumId, type).subscribe(
      res => {      
        if (res.data.length > 0) {
          this.meetingStatus = res.data;
        }
      }
    );
  };
generateBreadcrumb(component?:string,recordName?:string,tabName?:string,childRecName?:string,recordId?:number)
{
  
  this.breadcrumbService.generateBreadcrumb(component,recordName,tabName,childRecName,recordId);
}

getBreadcrumbs(): MenuItem[] {
  return this.breadcrumbService.getBreadcrumbs();
}
  executeCanExist: boolean = true;
  _oldContractMeeting:any
  canExist(): boolean | Promise<boolean> | Observable<boolean> {
  
    if (this.executeCanExist && (
      this._oldContractMeeting != JSON.stringify(this.contractMeetingDetails) ||
      this._oldContractOccurences != JSON.stringify(this._contractMeetingOccurrences) ||
      this._oldContractAttendees != JSON.stringify(this._contractMeetingAttendees) ||
      this._oldContractAbsentees != JSON.stringify(this._contractMeetingAbsentees) ||
      this._oldContractAttachments != JSON.stringify(this._contractMeetingOccurrenceAttachments) ||
      this._oldContractActions != JSON.stringify(this._contractmeetingActions)
  ))
    {
      return new Promise<boolean>((resolve) => {
        this.confirmationService.confirm({
          message: 'Meeting will not be added, Do you want to Continue?',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.messageService.add({
              key: 'tc',
              severity: 'info',
              summary: 'Meeting not added',
              detail: '',
          });
          
            resolve(true); 
          },
          reject: () => {
            resolve(false); 
          },
        });
      });
    }
    else{
      return true;
    }
    
  }
  onCancelContractMeeting()
{
  if (
    this._oldContractMeeting != JSON.stringify(this.contractMeetingDetails) ||
    this.attendeesValues != "" ||
    this.absenteesValues != "" ||
    this.addMeetingActionsData.length != 0 ||
    this._oldContractAttachments != JSON.stringify(this._contractMeetingOccurrenceAttachments))
  {
      this.confirmationService.confirm({
        message: 'Meeting will not be added, Do you want to Continue?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.cancelMeetingButtonClick.emit();
          this.messageService.add({
            key: 'tc',
            severity: 'info',
            summary: 'Meeting not added',
            detail: '',
        });
        },
        reject: () => {
        },
      });
  }
  else{
    this.cancelMeetingButtonClick.emit();
  }
}
  _oldContractOccurences:any;
  _oldContractAttendees:any;
  _oldContractAbsentees:any;
  _oldContractAttachments:any;
  _oldContractActions:any;
formOldSupplierMeeting(){
  setTimeout(() => {
    this._oldContractMeeting=JSON.stringify(this.contractMeetingDetails);
    this._oldContractOccurences=JSON.stringify(this._contractMeetingOccurrences);
    this._oldContractAttendees=JSON.stringify(this._contractMeetingAttendees);
    this._oldContractAbsentees=JSON.stringify(this. _contractMeetingAbsentees);
    this._oldContractAttachments=JSON.stringify(this._contractMeetingOccurrenceAttachments);
    this._oldContractActions=JSON.stringify(this._contractmeetingActions);
    }, 2000);
}

changedContractMeetingBasicInfo:any;
oldContractMeetingData:any;
compareModels() {
  this.changedContractMeetingBasicInfo = this.auditLogService.compareModels(JSON.parse(this.oldContractMeetingData),this.contractMeetingDetails,this.auditEntities.ContractMeetings,this.auditIdentifierDetails.ContractMeetings,this.auditOperations.Create,this.auditModule.GovernanceService);
}
get _attendees() {
  if(this._finalAttendees!=undefined)
  return this._finalAttendees.filter((a: any) => !this.absenteesValues.includes(a));
else
return [];
}
get _absentees() {
  if(this._finalAttendees!=undefined)
  return this._finalAttendees.filter((a: any) => !this.attendeesValues.includes(a));
else
return [];

}

contactHeader='';
contactSubmitted=false;
displayContractGroupDialog:boolean=false;
contractGroup:any;
currentField: any;
currentActionIndex:number=0;
openContractModel(field: string,actionIndex:number=0) {
  this.currentActionIndex=actionIndex;
  this.contactName='';
  this.contactHeader = 'Add Contact';
  this.currentField = field;
  this.contactFormGroup.reset();
  Object.keys(this.contactFormGroup.controls).forEach(key => {
    this.contactFormGroup?.get(key)?.setErrors(null);
  });
  this.contactSubmitted = false;
  this.contractGroup = { name: "" };
  this.displayContractGroupDialog = true;
}
  validateOnlyNumberandSpecialCharecters(control: AbstractControl): ValidationErrors | null{
    const alphaRegex = /[a-zA-Z]/; // Ensure at least one alphabetic character
    if (control.value && !alphaRegex.test(control.value)) {
      return { invalidNumberSpecialCharecters: true };
    }
    return null;
  }
newlyAddedContacts: any[] = [];
SaveMeetingContact() {
  this.contactSubmitted = true;

  if (this.contactFormGroup.valid) {
    const newContactName = this.contactFormGroup.value.contractName;
    const newCode = this._finalAttendees.length > 0 ? Math.max(...this._finalAttendees.map((contact: { code: any; }) => contact.code || 0)) + 1 : 1;

    const newContact: ContactMeetingContacts = {
      id: 0, 
      contactName: newContactName,
      designation: "",
      countryCode: "",
      phoneNumber: "",
      email: "",
      meetingId: this.contractMeetingDetails.Id ?? 0 
    };

    // Check for duplicates
    const isDuplicate= this.checkNameExists(newContactName)
    if (!isDuplicate) {
      switch (this.currentField) {
        case 'organizer':
          this.organizerOptions = [...this.organizerOptions, { label: newContact.contactName, value: newContact.contactName }];
          this.contractMeetingDetails.Organizer = newContact.contactName;
          const Attendee = { name: newContact.contactName, code: newCode };
          this._finalAttendees = [...this._finalAttendees, Attendee];
          this._attendees.push(Attendee);
          const Absentee = { name: newContact.contactName, code: newCode };
          this._attendees.push(Absentee);
          break;
        case 'attendees':
          const newAttendee = { name: newContact.contactName, code: newCode };
          this._finalAttendees = [...this._finalAttendees, newAttendee];
          this.attendeesValues = [...this.attendeesValues, newAttendee];
          this._attendees.push(newAttendee);
          this.organizerOptions = [...this.organizerOptions, { label: newContact.contactName, value: newContact.contactName }];
          break;
        case 'absentees':
          const newAbsentee = { name: newContact.contactName, code: newCode };
          this._finalAttendees = [...this._finalAttendees, newAbsentee];
          this.absenteesValues = [...this.absenteesValues, newAbsentee];
          this._attendees.push(newAbsentee);
          this.organizerOptions = [...this.organizerOptions, { label: newContact.contactName, value: newContact.contactName }];
          break;
        case 'owner':
          this.organizerOptions = [...this.organizerOptions, { label: newContact.contactName, value: newContact.contactName }];
          this.addMeetingActionsData[this.currentActionIndex].Owner = newContact.contactName;
          const Attendees = { name: newContact.contactName, code: newCode };
          this._finalAttendees = [...this._finalAttendees, Attendees];
          this._attendees.push(Attendees);
          const Absentees = { name: newContact.contactName, code: newCode };
          this._attendees.push(Absentees);
          break;
        default:
          break;
      }
      this.newlyAddedContacts.push(newContact);
      this.contractMeetingDetails.ContractMeetingContacts = [...this.contractMeetingDetails.ContractMeetingContacts ?? [], newContact];
    } else {
      this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Contact Name already exists. Please enter a unique Name', detail: '' });
    }

    this.displayContractGroupDialog = false;
  }
}

closeContactDialog(){
  this.displayContractGroupDialog = false;
  this.messageService.add({
    key: 'tc',
    severity: 'info',
    summary: 'Contact Information has not been saved',
    detail: '',
});
  
}
checkNameExists(name: string): boolean {
  return this._finalAttendees.some((item: { name: string; }) => item.name === name);
}
}
