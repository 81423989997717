import { Component, OnInit } from '@angular/core';

import { SponserService } from '../../Services/sponser.service';

import {ISponserData,ITblSponser,ITbl_SponserContacts} from '../../Models/sponser-data'
import { Router } from '@angular/router';
import { CodeListValues } from '../../Models/country-data';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ActiveInActiveStatus,Dateformat,breadcrumnsCodes, AuditIdentifier, AuditLogEntities, AuditOperations, AuditLogModule } from 'src/app/shared/constants/global.constants';
import { LoaderService } from 'src/app/Loader/loader.service';
import { IDeactivateGuard } from 'src/app/shared/Guards/can-deactivate-guard.service';

import { ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { AuditLogService } from '../../../shared/ModelComparison/audit.log.service';
import { ContractService } from '../../../Contracts/Services/contract.service';
import { AuditLogModel } from '../../../shared/ModelComparison/audit.log.response.model';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { MasterSupplierService } from '../../supplier/Services/master.supplier.service';
import { EngagementService } from 'src/app/Engagement/Services/engagement.service';
import { SuppliersMasterServiceAgreement } from '../../Services/supplier-profile';

@Component({
  selector: 'app-editsponser',
  templateUrl: './editsponser.component.html',
  styleUrls: ['./editsponser.component.css']
})
export class EditsponserComponent implements OnInit, IDeactivateGuard{
  unsavedChanges: boolean = false;
  displayDialog:boolean=false;
  cardData: any;
  myForm: FormGroup;
  dialogFormGroup: FormGroup;
localConatctId:number=0;
editContactFlag=false;
sponserData:ISponserData={};
  sponserInfo:ITblSponser={};
  sponserContact:ITbl_SponserContacts={};
 sponserContactList:ITbl_SponserContacts[]=[];
 countryCodes: CodeListValues[] | undefined;
CreatedbyUserName: string='';
 oldSponserData:string="";
 oldSponserContactData:string="";
 sponsorDetailBeforeUpdate:any;
 sponsorContactDetailListBeforeUpdate:any;
 blankSponsorContact:ITbl_SponserContacts={};

 submitted = false;
 isSaveBtnDisable = false;
 contactSubmitted=false;
 isDisabled: boolean = true;
 public weightConfirmed: boolean = false;
 sponserIdToUpdate:number=0;
 updatedSponserContactList:ITbl_SponserContacts[]=[];
 _dateformart :any;
 executeCanExist: boolean = true;
 _enumBreadcrumb :any;
 changedSponsorProperties:any[] = [];
 changedSponsorContactsProperties:any[] = [];
 selectedOption: string="";
  selectedsponserstatus: any[]=[];
  filterselectedsponserstatus: any[] =[];
  statusData: any[]=[];
  noOfSuppliersAreLinkedwithSponserId: any;
  defaultactivestatus: any[]=[];
  defaultselectedactivestatus: any[]=[];
  statusInactive: boolean=false;

  status: boolean =false;
  statusValue: string="";
  supplierStatusData: any[]=[];
  contractStatusData: any[]=[];
  defaultSupplierActiveStatus: any[]=[];
  supplierActiveStatus: any[]=[];
  supplierStatusMappingId: any;
  defaultContractActiveStatus: any[]=[];
  contractActiveStatus: any[]=[];
  contractStatusMappingId: any;
  sponserStatusMappingId: any;
  sponserStatusMappingValue: any[]=[];
  auditIdentifierDetails:any;
  contactHeader='Add Contact';
  uniqueSponsorContactNameMessage=false;
  uniqueSponsorNameMessage=false;
  allSponsordetails: any[] = [];
constructor(private _service:SponserService,private router:Router,
  private _SupplierSerice: MasterSupplierService,
  private _contractService: ContractService,
 private confirmationService: ConfirmationService,
  private messageService: MessageService,
  private fb: FormBuilder,
  private route: ActivatedRoute,
  private loaderService: LoaderService,private breadcrumbService: BreadcrumbService,
  private auditLogService:AuditLogService,
  private privilegeService: PrivilegeService,
  private engagementService:EngagementService,private activeRouter: ActivatedRoute)
  {

    this.myForm = this.fb.group({
      sponserName: ['', [Validators.required, Validators.maxLength(100), Validators.minLength(3),Validators.pattern(/^(?=(?:[^a-zA-Z]*[a-zA-Z]){3})/),this.notOnlySpacesValidator]],
      sponserAddress: ['', [Validators.maxLength(300)]],
      sponserId:[''],
      sponserNotesAndComments:['', [Validators.maxLength(300)]],
    });

    this.dialogFormGroup = this.fb.group({
      contactName: ['', [Validators.required,Validators.maxLength(30),Validators.pattern(/^[a-zA-Z\s]{1,30}$/),this.notOnlySpacesValidator]],
      contactDesignation:['', [Validators.maxLength(100),Validators.minLength(3),this.notOnlySpacesValidator]],
      countryCode: [''],
      phoneNumber:['',[Validators.minLength(7),Validators.maxLength(15)]],
      email:['',[Validators.required,Validators.maxLength(100)]]
    });

    

    this.isDisabled=true;
  
  }

  validateOnlyNumberandSpecialCharecters(control: AbstractControl): ValidationErrors | null{
    const alphaRegex = /[a-zA-Z]/; // Ensure at least one alphabetic character
    if (control.value && !alphaRegex.test(control.value)) {
      return { invalidNumberSpecialCharecters: true };
    }
    return null;
  }

  validateAlphaCharacters(control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    
    const pattern = /^[a-zA-Z\s]*$/;

    if (value && !pattern.test(value)) {
      return { invalidAlphaCharacters: true };
    }

    return null;
  }

  notOnlySpacesValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value && control.value.trim() === '') {
       return { 'onlySpaces': true };
     }
     return null;
   }
   auditEntities:any;
   auditOperations:any;
   auditModule:any;
  
  ngOnInit(): void {
    this._enumBreadcrumb=breadcrumnsCodes;
    this.generateBreadcrumb(this._enumBreadcrumb.EVSP);
    this._dateformart=Dateformat;
    this.auditIdentifierDetails=AuditIdentifier; 
    this.auditEntities=AuditLogEntities;
    this.auditOperations=AuditOperations;
    this.auditModule=AuditLogModule;

    localStorage.setItem('LoggedInUserName', 'TestUser');
    this.route.params.subscribe(params => {
      
      this.sponserIdToUpdate=+params['id'] //log the value of id
    });
    
    this._service.getCodeListData(0,'').subscribe(
      data=>{
        this.countryCodes=data.data;
        
      }
    );
    this.getAllEngagementsBySponsorId();
    
    this.loaderService.showLoader();
    
    this._service.getSponserData(this.sponserIdToUpdate).subscribe(
      res=>{
        this.loaderService.hideLoader();
        this.sponserInfo.name=res.data.sponserInfo?.name;
     
        this.sponserInfo.address=res.data.sponserInfo?.address;
        this.sponserInfo.notesAndComments=res.data.sponserInfo?.notesAndComments;
        this.sponserInfo.id=res.data.sponserInfo?.id;
        this.sponserInfo.sponserId=res.data.sponserInfo?.sponserId;
        this.CreatedbyUserName=this.privilegeService.getUserName(res.data.sponserInfo?.createdBy); 
        this.sponserInfo.createdDate=res.data.sponserInfo?.createdDate;
        
        this.sponserContactList=res.data.sponserContacts;
        this.generateBreadcrumb(this._enumBreadcrumb.EVSP,this.sponserInfo.name);


      
        this.oldSponserData=JSON.stringify(this.sponserInfo);

        
        this.sponsorDetailBeforeUpdate=JSON.stringify(this.sponserInfo);
        this.sponsorContactDetailListBeforeUpdate=JSON.stringify(this.sponserContactList);
        
        this.GetsponserStatus();
        this.sponserContactList.forEach(item => {
          item.localId=this.localConatctId;
          this.localConatctId=this.localConatctId+1;
          this.updatedSponserContactList.push(item);
        });
        

        this.sponserContactList=this.updatedSponserContactList;
        this.oldSponserContactData=JSON.stringify(this.sponserContactList);
       
      });
      this.GetSupplierStatus();
      this.GetContractStatus(); 
      this.loaderService.hidefinalLoader();// hide after 4 seconds even get any error  
      this.GetAllSponsors();
  }
  GetSuppliercount()
  {
    this._SupplierSerice.GetSuppliersLinkedwithSponserId(this.sponserInfo.id)
    .subscribe(res => {
      this.noOfSuppliersAreLinkedwithSponserId = res.data;
     }); 
  }
  updateSponserData(){
    this.GetSuppliercount();
    this.submitted = true;
    this.isSaveBtnDisable = true;
    setTimeout(() => {
      this.isSaveBtnDisable = false;
    }, 3000);

    if (this.myForm.valid && !this.uniqueSponsorNameMessage) {
      this.executeCanExist = false;
    this.sponserInfo.updatedBy=this.privilegeService.getLoginUserId();
    this.sponserInfo.updatedDate=new Date();
    if(this.sponserInfo.address == undefined){
      this.sponserInfo.address="";
    }
    if(this.sponserInfo.notesAndComments == undefined){
      this.sponserInfo.notesAndComments="";
    }
    this.sponserData["sponserInfo"]=this.sponserInfo;
    this.sponserData["sponserContacts"]=this.sponserContactList;

   
   
    this._service.updateSponserData(this.sponserData.sponserInfo.id,this.sponserData).subscribe(
      data=>{
 
        this.router.navigate(['/viewsponsor',this.sponserIdToUpdate]);
        this.messageService.add({
          key: 'tc',
          severity: 'success',
          summary: 'Sponsor Information has been saved',
          detail: '',
      });
        this.localConatctId=0;
       this.compareModels();
      }
      
    );
    }
    else{
      this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Please enter required information', detail: '' });
    }
  }
  
  openContactModel(){
    this.uniqueSponsorContactNameMessage=false;
    this.currentSponsorContactIndex=-1;
      this.dialogFormGroup.reset();
      Object.keys(this.dialogFormGroup.controls).forEach(key => {
          this.dialogFormGroup?.get(key)?.setErrors(null);
          
      });
      this.contactSubmitted=false;
      this.sponserContact.id=0;
      this.sponserContact.name="";
      this.sponserContact.designation="";
      this.sponserContact.countryCode="";
      this.sponserContact.phoneNumber="";
      this.sponserContact.email="";
      this.displayDialog = true;
      this.contactHeader='Add Contact';
      this.iscontactExist=false;
  
  }
  isSavePopupBtnDisable = false;
  SaveContact(){
    this.isSavePopupBtnDisable = true;
    setTimeout(() => {
      this.isSavePopupBtnDisable = false;
    }, 3000);
    this.contactSubmitted=true;
    this.trimInput();
    if (this.dialogFormGroup.valid && !this.uniqueSponsorContactNameMessage) {
      this.contactSubmitted=false;
    this.sponserContact.createdBy=this.privilegeService.getLoginUserId();
    this.sponserContact.createdDate=new Date();
    this.sponserContact.updatedBy=this.privilegeService.getLoginUserId();
    this.sponserContact.updatedDate=new Date();
    this.sponserContact.isDeleted=false;
   if(this.sponserContact.id == undefined){
    this.sponserContact.id=0;
   }

    if(!this.editContactFlag){
    this.sponserContact.localId=this.localConatctId;
    this.editContactFlag=false;
    }
   
    const index = this.sponserContactList.findIndex(model => model.localId === this.sponserContact.localId);
    if (index !== -1) 
    {
      this.sponserContactList[index] = this.sponserContact;
  
    } 
  else
  {
    this.sponserContactList.push(this.sponserContact);
  }
  
    
    this.sponserContact={};
    this.localConatctId=this.localConatctId+1;
    this.displayDialog = false;
    this.messageService.add({
      key: 'tc',
      severity: 'success',
      summary: 'Contact Information has been saved',
      detail: '',
  });
    
    }
    this.editContactFlag=false;
  }

  trimInput() {
    const control = this.dialogFormGroup.get('contactName');
    if (control && control.value) {
      control.setValue(control.value.trim());
    }
  }
  editContact(cardData:any,rowIndex:any){

    this.uniqueSponsorContactNameMessage=false;
    this.sponserContact.name=cardData?.name;
    this.sponserContact.designation=cardData?.designation;
    this.sponserContact.countryCode=cardData?.countryCode;
    this.sponserContact.phoneNumber=cardData?.phoneNumber;
    this.sponserContact.email=cardData?.email;
    this.sponserContact.id=cardData?.id;
    this.sponserContact.localId=cardData?.localId;
    this.editContactFlag=true;
    this.displayDialog = true;
    this.contactHeader='Edit Contact';
    this.currentSponsorContactIndex=rowIndex;
  }

  

  _supplierMSADetails: SuppliersMasterServiceAgreement[]=[];
  supplierdata:any;

  ContactIndex:any;
  selectedItem:any

  engagementDataSource:any;
   getAllEngagementsBySponsorId()
   {
    this.engagementService.getAllEngagementsBySponsorId(this.sponserIdToUpdate).subscribe(
      (res:any)=>{
      this.engagementDataSource=res.data;
     });
   }
  

  


  
       
  isContactExistOnMSA=false;
  isContactExistOnContract=false;

  confirm(item: any) {
    this.loaderService.showLoader();
    this.selectedItem = item;
    
        
       var msaIndex;
        if(this.sponserIdToUpdate!=0)
          {
        msaIndex = this.engagementDataSource.findIndex((engagement: any) => {
          return engagement.engagementMasterServiceAgreements.some((msa: any) => {
            return msa.sponsorContactId === item.id;
          });
        });
      }
      else{
        msaIndex=-1;
      }
  
      
       if(msaIndex==-1)
      {
        this.isContactExistOnMSA=false;
      }
     else{
       this.selectedItem=null;
       this.isContactExistOnMSA=true;
      }

      this.CheckContactOnEngagementsContracts(item.id);
}


engagementContractList:any;
    CheckContactOnEngagementsContracts(contactId:any){
      this._contractService.GetAllEngagementsContractsBySponsorId(this.sponserIdToUpdate).subscribe
      (res => {
          this.engagementContractList=res.data;


          let activeTitles: string[] = [];
          this.engagementContractList.forEach((contract:any) => {
  
            var isExist= this.engagementContractList.filter((aa:any)=>aa.sponserContactId==contactId);
            if (isExist.length>0) {
              activeTitles.push(contract.title);
             }
           
              });
  
         let commaSeparatedTitles="";
         if(activeTitles.length>0){
             commaSeparatedTitles = activeTitles.join(', ');
             this.isContactExistOnContract=true;
         }
         else{
          this.isContactExistOnContract=false;
         }


         
         if(this.isContactExistOnMSA==true &&  this.isContactExistOnContract==true)
          {
            this.messageService.add({  key: 'tc',severity: 'warn', life: 8000 ,summary: 'This contact is selected as the Owner on the Contract(s) "' + commaSeparatedTitles + '" and also on an MSA. It cannot be deleted', detail: '' })
            this.loaderService.hideLoader();
         }
         else if(this.isContactExistOnMSA==true &&  this.isContactExistOnContract==false)
          {
            this.messageService.add({  key: 'tc',severity: 'warn',life: 8000, summary: 'This contact is linked to an MSA and cannot be deleted.', detail: '' })
            this.loaderService.hideLoader();
         }
         else if(this.isContactExistOnMSA==false &&  this.isContactExistOnContract==true)
          {
            this.messageService.add({  key: 'tc',severity: 'warn', life: 8000, summary: 'This contact is selected as the Owner on the Contract(s) "' + commaSeparatedTitles + '" and cannot be deleted', detail: '' })
            this.loaderService.hideLoader();
         }
         else{
          this.loaderService.hideLoader();
         this.confirmationService.confirm({
            message: 'Are you sure you want to delete this contact from this Sponsor?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              this.deleteItem();
              this.messageService.add({
                                key: 'tc',
                                severity: 'success',
                                summary: 'Contact has been deleted',
                                detail: '',
                            });
            },
            reject: (type: any) => {
            
            }
        });
        }
      });
      }


  deleteContact(cardData: any) {
   this.ContactIndex = this.sponserContactList.findIndex(contact => contact.localId === cardData.localId);
   if(this.ContactIndex!=-1)
   {
    this.selectedItem = cardData;
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete this contact?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.deleteItem();
          this.messageService.add({
            key: 'tc',
            severity: 'success',
            summary: 'Contact has been deleted',
            detail: '',
        });
        },
        reject: (type: any) => {
          this.selectedItem = null; 
        }
    });
  }
 

}
deleteItem() {
  const contactIndex = this.sponserContactList.findIndex(item => item === this.selectedItem);
  if (contactIndex !== -1) {
    this.sponserContactList.splice(contactIndex, 1);
    this.selectedItem = null;
  }
}

  closeDialog(){
    this.displayDialog = false;
    this.selectedItem=null;
    this.editContactFlag=false;
    this.messageService.add({
      key: 'tc',
      severity: 'info',
      summary: 'Contact Information has not been saved',
      detail: '',
  });
    
  }

  BackToListPage(){
    this.router.navigate(['/viewsponsor',this.sponserIdToUpdate]);
    
  }
  ediSponcheckSponsorContactNameExist(contactDetails:any)
    {
      if(this.sponserContactList.length>0){
        if (this.isNameRepeated(contactDetails)) {
          this.uniqueSponsorContactNameMessage=true;
        } else {
          this.uniqueSponsorContactNameMessage=false;
        }
      }
     
    }
    currentSponsorContactIndex=-1;
    isNameRepeated(contactDetails: any): boolean {

      this.uniqueSponsorContactNameMessage=false;
          let nameCount = 0;
          const contactName = contactDetails.name.toLowerCase();
          if(this.currentSponsorContactIndex==-1) 
          {
            this.sponserContactList.forEach((obj: any, index: any) => {
              if (obj.name.toLowerCase() === contactName) {
                  nameCount++;
              }
          });
          }
          else if(this.currentSponsorContactIndex>=0){
            this.sponserContactList.forEach((obj: any, index: any) => {
              if (obj.name.toLowerCase() === contactName &&  index!=this.currentSponsorContactIndex) {
                  nameCount++;
              }
          });
          }
    
          return nameCount > 0;
      }
  canExist(): boolean | Promise<boolean> | Observable<boolean> {
    if (this.executeCanExist && !(this.oldSponserData==JSON.stringify(this.sponserInfo) && (this.oldSponserContactData==JSON.stringify(this.sponserContactList))))
    {
      return new Promise<boolean>((resolve) => {
        this.confirmationService.confirm({
          message: 'Changes will not be saved,  Do you want to continue?',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.messageService.add({
              key: 'tc',
              severity: 'info',
              summary: 'Changes not saved',
              detail: '',
          });
            resolve(true); 
          },
          reject: () => {
            resolve(false); 
          },
        });
      });
    }
    else{
      return true;
    }
  }
  generateBreadcrumb(component?:string,recordName?:string)
  {
    this.breadcrumbService.generateBreadcrumb(component,recordName);
  }
  getBreadcrumbs(): MenuItem[] {
    return this.breadcrumbService.getBreadcrumbs();
  }

  onOptionChange(event:any): void { 
    this.filterselectedsponserstatus=this.statusData;
    this.selectedsponserstatus=this.filterselectedsponserstatus.filter((x:any)=>(x.id==event.value));
    try {
      if ((this.selectedsponserstatus[0].name==ActiveInActiveStatus.Active)&& this.sponserInfo.sponserId != null && this.sponserInfo.sponserId != undefined) { //Sponser and all the suppliers are made inactive linked under this sponserid
         this.statusInactive=false;
      }
      else{
      if (!(this.selectedsponserstatus[0].name==ActiveInActiveStatus.Active)&& this.sponserInfo.sponserId != null && this.sponserInfo.sponserId != undefined) { //Sponser and all the suppliers are made inactive linked under this sponserid
        this._SupplierSerice.GetSuppliersLinkedwithSponserId(this.sponserInfo.id)
          .subscribe(res => {
            this.noOfSuppliersAreLinkedwithSponserId = res.data;
            this.confirmationService.confirm({
              header: 'Confirm Inactivate Sponsor',
              message: 'The Sponsor has ' + res.data.length + ' Supplier(s) linked under it, do you still want to make it inactive? This will result in making any Suppliers and contracts under it being made inactive as well.',
              accept: () => {
                this.statusInactive=true;
              },
              reject: () => {
                this.statusInactive=false;
                this.defaultactivestatus=this.statusData;
                this.defaultselectedactivestatus=this.defaultactivestatus.filter((x:any)=>x.name==ActiveInActiveStatus.Active)
               },
            });
          });
      }
    }

  }
  catch (error) {
      console.log(error);
    }
  }
  InActivateSponser(sponserId:any,StatusMappingId:any,status:any) //Inactivate sponser
  {

    if(status)
    this.statusValue="inactive";
    else
    this.statusValue="active";
    try{
    this._service.InActivateSponserById(sponserId,StatusMappingId,status)
    .subscribe(res => {
      if(res.data > 0){
        this.messageService.add({
          key: 'tc',
          severity: 'success',
          summary: 'Sponsor and underlying suppliers and contracts have been made' + '  '+this.statusValue +'.',
          detail: '',
        });
       
      }
    });
  }
  catch(error){
    console.log(error);
  }
  }

 

  GetsponserStatus(){
    this._service.getCodeListData(3,'Sponser').subscribe(
      data=>{
        this.statusData=data.data;
        
      }
    );
  }
  GetSupplierStatus(){
    this._service.getCodeListData(3,'Supplier').subscribe(
      data=>{
        this.supplierStatusData=data.data;
      }
    );
  }
  GetContractStatus() {
    this._service.getCodeListData(3, 'Contract').subscribe(
      data => {
        this.contractStatusData = data.data;

      }
    );
  }

  contactUniqueMessage:string="";
  iscontactExist=false;
IsCheckDuplicateContact(name: any): void {
  this.contactUniqueMessage="Contact Name already exists in this Sponsor. Please enter a unique Name";
  this.iscontactExist= this.sponserContactList.some(obj=>obj.name?.toLowerCase()===name.toLowerCase())

}

  //Audit payload
  changedSponsorBasicInfo:any;
  changedSponsorContactInfo:any;
  listChangedSponsorBasicContactInfo:any;
  auditModelForSponser=new AuditLogModel();
  auditModelForSponserContact=new AuditLogModel();
  compareModels() {
    
    //Getting Changes Properties Array
    this.changedSponsorBasicInfo = this.auditLogService.compareModels(JSON.parse(this.sponsorDetailBeforeUpdate),this.sponserInfo,this.auditEntities.SponsorDetails,this.auditIdentifierDetails.SponsorDetails,this.auditOperations.Update,this.auditModule.Sponsorservice);
   

    this.sponsorContactDetailListBeforeUpdate=JSON.parse(this.sponsorContactDetailListBeforeUpdate);
    this.sponserContactList.forEach((item:any) => {
     
      if(item.id != 0 && !(item.isDeleted))//Edit case
      {
          var oldContactInfo=this.sponsorContactDetailListBeforeUpdate.filter((aa:any)=>aa.id==item.id);
          this.changedSponsorContactInfo=this.auditLogService.compareModels(oldContactInfo[0],item,this.auditEntities.SponsorContacts,this.auditIdentifierDetails.SponsorContacts,this.auditOperations.Update,this.auditModule.Sponsorservice);
      }
      else if(item.id != 0 && item.isDeleted)//DeleteContact Case
      {
        this.changedSponsorContactInfo=this.auditLogService.compareModels(item,this.blankSponsorContact,this.auditEntities.SponsorContacts,this.auditIdentifierDetails.SponsorContacts,this.auditOperations.Delete,this.auditModule.Sponsorservice);
      }
      else//new contact
      {
        this.changedSponsorContactInfo=this.auditLogService.compareModels(this.blankSponsorContact,item,this.auditEntities.SponsorContacts,this.auditIdentifierDetails.SponsorContacts,this.auditOperations.Create,this.auditModule.Sponsorservice);
      }
    });
if (this.sponserContactList.length !== this.sponsorContactDetailListBeforeUpdate.length) {
    const deletedContacts = this.sponsorContactDetailListBeforeUpdate.filter((oldContact: { id: number; }) => 
        !this.sponserContactList.some(newContact => newContact.id === oldContact.id)
    );

    if (deletedContacts.length > 0) {
     
        this.changedSponsorContactInfo = this.auditLogService.compareModels(
            deletedContacts, [],this.auditEntities.SponsorContacts,this.auditIdentifierDetails.SponsorContacts,this.auditOperations.Update,this.auditModule.Sponsorservice
        );
    }
}

  }

  GetAllSponsors() {
    this._service.GetAllSponsers().subscribe(
      (    res: { data: any[]; })=>{
        this.allSponsordetails=res.data;
      } 
   );
  };
    checkSponsorNameExist()
    {
      this.uniqueSponsorNameMessage=false;
    const contactIndex = this.allSponsordetails.findIndex(item => item.name.toLowerCase() === (this.sponserInfo.name?.toLocaleLowerCase()));
     if(contactIndex !== -1 && this.sponserIdToUpdate!==this.allSponsordetails[contactIndex].id)
     {
        this.uniqueSponsorNameMessage=true;
     }
      else{
        this.uniqueSponsorNameMessage=false;
      }
    }
}
