import { filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { SponserService } from '../../Services/sponser.service';
import { ActivatedRoute } from '@angular/router';
import {ISponserData,ITblSponser,ITbl_SponserContacts} from '../../Models/sponser-data'
import { Router } from '@angular/router';
import { CodeListValues } from '../../Models/country-data';
import { AbstractControl, FormBuilder, FormGroup, NgForm, ValidationErrors, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { NgStyle } from '@angular/common';
import { LoaderService } from 'src/app/Loader/loader.service';
import { ActiveInActiveStatus,StatusCodes,Dateformat,breadcrumnsCodes } from 'src/app/shared/constants/global.constants';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { Privileges } from 'src/app/shared/constants/privileges.constants';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';


interface City {
  name: string;
  code: string;
}

@Component({
  selector: 'app-viewsponsor',
  templateUrl: './viewsponsor.component.html',
  styleUrls: ['./viewsponsor.component.css']
})
 
export class ViewsponsorComponent  implements OnInit {

 

  value:string="";
  sponserId:number=0;

  selectedCity1!: City[];
  selectedOption: string = 'Actions';
  noOfSuppliersAreLinkedwithSponserId: any;
  StatusMappingId: any;
  sponserListinfo:ITblSponser={};
  sponserstatusname: any;
  editPrivilege:boolean=false;
  constructor(
    private confirmationService: ConfirmationService, 
    private messageService: MessageService, 
    private service:SponserService,
    private route: ActivatedRoute,
    private router:Router,
    private privilegeService: PrivilegeService,
    private loaderService: LoaderService,private breadcrumbService: BreadcrumbService
    )

     {

     }

  _dateformart :any;
  SponserId:any;
  SponserName:any;
  Address:any;
  NotesandComments:any;
  sponserscontact:any[]=[];
  sponserContactList:ITbl_SponserContacts[]=[];
  createdBy: any;
  createdDateAndTime: any;
  UpdatedBy: any;
  UpdatedByAndTime: any;
  _enumBreadcrumb :any;
  statusData:any;
   

  ngOnInit() {
    this._enumBreadcrumb=breadcrumnsCodes;
    this.generateBreadcrumb(this._enumBreadcrumb.EVSP);
    this._dateformart=Dateformat;
    this.route.params.subscribe(params => {
      
      this.sponserId=+params['sponserId'] //log the value of id
    });
    
    this.GetSponserStatus();
    this.BindPrivileges();
    
    this.loaderService.hidefinalLoader();// hide after 4 seconds even get any error
   }
  
   sponserInfo :any[]=[]
   getSponserData(sponserId:number){
     this.loaderService.showLoader();
   this.service.getSponserData(sponserId).subscribe(
    res=>{
      this.loaderService.hideLoader();
      this.sponserInfo=res.data.sponserInfo;
      this.SponserId=res.data.sponserInfo.sponserId;
      this.SponserName=res.data.sponserInfo.name;
      this.Address=res.data.sponserInfo.address;
      this.StatusMappingId=res.data.sponserInfo.statusMappingId;
      this.Address = this.Address ? this.Address.replace(/\n/g, "<br>") : this.Address;
      var filteredStatus = this.statusData.filter((status: any) => status.id ==  this.StatusMappingId);
          if (filteredStatus != undefined && filteredStatus != null && filteredStatus.length > 0) {
              this.sponserstatusname = filteredStatus[0].name;
          }  
      this.NotesandComments=res.data.sponserInfo.notesAndComments;
      this.NotesandComments = this.NotesandComments ? this.NotesandComments.replace(/\n/g, "<br>") : this.NotesandComments;
      this.UpdatedByAndTime = res.data.sponserInfo.updatedDate;
      this.UpdatedBy =this.privilegeService.getUserName(res.data.sponserInfo.updatedBy) ;
      this.createdBy =this.privilegeService.getUserName(res.data.sponserInfo.createdBy); 
      this.createdDateAndTime = res.data.sponserInfo.createdDate;
      this.sponserContactList=res.data.sponserContacts;
      this.generateBreadcrumb(this._enumBreadcrumb.EVSP,this.SponserName)
         });
  }
  BindPrivileges(){
    const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);

    
this.editPrivilege = hasPermission(Privileges.EditSponsor);


  
    }
  GetSponserStatus() {
    this.service.getCodeListData(3, 'Sponser').subscribe(
      res => {
        if (res.data.length > 0) {//Matching the sponser Details status id with code list
          this.statusData=res.data;
          this.getSponserData(this.sponserId);
              
        }

      }
    );
  }
 
generateBreadcrumb(component?:string,recordName?:string)
{
  this.breadcrumbService.generateBreadcrumb(component,recordName);
}
getBreadcrumbs(): MenuItem[] {
  return this.breadcrumbService.getBreadcrumbs();
}

async onOptionChange(): Promise<void> {
  try {
    if (this.selectedOption === 'Edit') {
      await this.router.navigate(['/editsponsor', this.sponserId]);
    }
  } catch (error) {
    console.log(error);
  }
}

  BackToList() {
    this.router.navigate(['/sponsor']);
  }

}
