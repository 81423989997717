import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, booleanAttribute } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EngagementMeetingDetails, EngagementMeetingOccurrences, EngagementMeetingAttendees, EngagementMeetingAbsentees, EngagementMeetingOccurrenceAttachments, EngagementAddMultipleOccurrences, EngagementAddMeetingOccurenceActions,EngagementMeetingContacts} from '../../Modals/engagementmeeting';
import { ConfirmationService, MessageService, MenuItem } from 'primeng/api';
import { Dateformat, FileExtension, breadcrumnsCodes, CodeList, CodeListStatusTypes, AuditIdentifier, AuditLogEntities, AuditOperations, AuditLogModule } from 'src/app/shared/constants/global.constants';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';

import { DatePipe, formatDate } from '@angular/common';
import { GovernanceService } from '../../Services/governance.service';
import { LoaderService } from 'src/app/Loader/loader.service';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { ChecknulloremptyComponent } from 'src/app/shared/checknullorempty/checknullorempty.component';
import { Observable } from 'rxjs';
import { AssociateService } from 'src/app/Team/teams/Services/associate.service';
import { AuditLogService } from 'src/app/shared/ModelComparison/audit.log.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { EngagementService } from 'src/app/Engagement/Services/engagement.service';
import { MasterSupplierService } from 'src/app/MasterData/supplier/Services/master.supplier.service';
import { FileUploadService } from 'src/app/Engagement/Services/fileupload.service';
import { Privileges } from 'src/app/shared/constants/privileges.constants';
 

@Component({
  selector: 'app-eng-edit-meeting-rec-yes',
  templateUrl: './eng-edit-meeting-rec-yes.component.html',
  styleUrls: ['./eng-edit-meeting-rec-yes.component.css']
})
export class EngEditMeetingRecYesComponent {
  @Input() engagementId: any;
  @Input() meetingId: any;
  @Input() supplierName: any;
  @Output() cancelMeetingButtonClick = new EventEmitter<void>();
  @Output() saveMeetingButtonClick = new EventEmitter<void>();
  @Output() viewMeetingButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('fileInput') fileInput: ElementRef | undefined;
  engagementMeetingId:any;
  _enumBreadcrumb :any;
  engagementMeetingForm: FormGroup;
  executeCanExist: boolean = true;
  _oldEngagementMeetingDetails:any;
  AddMultipleOccurrences: any[] = [] ;
  value!: string;
  addMeetingOccurenceDate: Date | undefined;
  organizerOptions: any[] = [];
  engagementData: any;
  contactData: any;
  displayAddOccurrencesPopUP: boolean = false;
  EngagementId: any;

  attendeesValues: any = '';
  absenteesValues: any = '';

  attendees: string[] = [];
  absentees: string[] = [];
  meetingStatus: any[] = [];
  _dateformart: any;
  addMeetingActionsData: any[] = [];
  uploadedFiles: File[] = [];
  codeListValues:any;
  codeListStatusTypes:any;
  _uploadFileForEachOccurrences:File[]=[];
  maxFileSize: any
  timeStamp: any;
  currentFileSize: any;
  multiMeetingFileData: any[] = [];
  uploadedFileName: any;
  allowedExtensions: any;
  uploadedMeetingFile: any;
  newAttachment: any;
  submitted:boolean = false;
  _addActionsFieldsMandatory: boolean = false;
  formData = new FormData();
  engagementAddoccurrencesPopUpForm: FormGroup;
  myContractDetailsForm: FormGroup;
  isSaveBtnDisable:boolean=false;

  engagementMeetingDetails = new EngagementMeetingDetails();
 _engagementMeetingOccurrences = new EngagementMeetingOccurrences();
 _engagementMeetingAttendees = new EngagementMeetingAttendees();
 _engagementMeetingAbsentees = new EngagementMeetingAbsentees();
 _engagementMeetingOccurrenceAttachments = new EngagementMeetingOccurrenceAttachments();
 _engagementMeetingOccurrenceDetails: EngagementMeetingOccurrences[] = [];
 _engagementMeetingAttendeesDetails: EngagementMeetingAttendees[] = [];
 _engagementMeetingAbsenteesDetails: EngagementMeetingAbsentees[] = [];
 _engagementMeetingOccurrenceAttachmentsDetails: EngagementMeetingOccurrenceAttachments[] = [];
 _engagementMeetingActionsDetails: EngagementAddMeetingOccurenceActions[] = [];

 _engagementAddOccurrences = new EngagementAddMultipleOccurrences();
 _engagementAddMultipleOccurrences: EngagementAddMultipleOccurrences[] = [];

 

  _allAttendees: string[] = [];
  _finalAttendees:any;
  AddOccurrenceDialog: string = '';
  individualOccurrenceDetails: any;
  meetingCreatedByUsername:string='';
  meetingUpdatedByUsername:string='';
  isAddEditOccurrenceFileUploadButtonDisabled:boolean = false;
  contactFormGroup:FormGroup;
  contactName: string = '';
  deletePrivilege:boolean=false;
  constructor(
    private governanceService: GovernanceService,
    private messageService: MessageService,
    private masterDataService: SponserService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private _fileService: FileUploadService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private loaderService: LoaderService,  
    private breadcrumbService: BreadcrumbService,
    private checkNullOrEmptyValuesService: ChecknulloremptyComponent,
    private _associateService:AssociateService,
    private auditLogService: AuditLogService,
    private privilegeService:PrivilegeService,
    private engagementService: EngagementService,
    private _mastersupplierService: MasterSupplierService,   
    ) {
      this.engagementAddoccurrencesPopUpForm = this.fb.group({   
        addOcurrneceMeetingAbsentees: [''],
        addOcurrneceMeetingAttendees: [''],
        addOcurrneceMeetingOccurenceDate: [null, [Validators.required]],
        addOcurrneceMeetingFileSource: ['' ], addOcurrneceMeetingFileName: '',
        addOcurrneceMeetingAttachments: [''],
        addOcurrneceMeetingAgenda: [''],
        addOcurrneceMeetingNotes: [''],
        addOcurrneceMeetingDecisions: [''],     
      });
      this.myContractDetailsForm = this.fb.group({
        
      });


      this.engagementMeetingForm = this.fb.group({
        addMeetingTitle: ['', [Validators.required, Validators.maxLength(50), Validators.minLength(3), this.noOnlySpacesValidator]],
        addMeetingOrganizer: ['', [Validators.required]],
        addMeetingRecurring: [{ value: '', disabled: true }],
        addMeetingAgenda: ['', [Validators.required, Validators.maxLength(300)]],
      });
      this.contactFormGroup=this.fb.group({
        contractName:['',[Validators.required,Validators.maxLength(30),Validators.minLength(3),Validators.pattern(/^[A-Za-z- ]{3,30}$/)]],
      });
    }

    noOnlySpacesValidator(control: AbstractControl): ValidationErrors | null {
      if (control.value && control.value.trim().length === 0) {
        return { onlySpaces: true };
      }
      return null;
    }
    auditIdentifierDetails:any;
    auditEntities:any;
    auditOperations:any;
    auditModule:any;

  ngOnInit() {
    this._dateformart = Dateformat;
    this._enumBreadcrumb=breadcrumnsCodes;
    this.codeListValues=CodeList;
    this.codeListStatusTypes=CodeListStatusTypes;
    this.auditIdentifierDetails=AuditIdentifier; 
    this.engagementMeetingId =this.meetingId;
    this.EngagementId =this.engagementId;
    this.GetMeetingStatus(this.codeListValues.Tbl_Status, this.codeListStatusTypes.MASTS);
    this.GetEngagementDetailsById();
    this.GetEngagementMeetingById(this.engagementMeetingId);

    this.auditEntities=AuditLogEntities;
    this.auditOperations=AuditOperations;
    this.auditModule=AuditLogModule;

    this.BindPrivileges();
  }
  BindPrivileges(){
    const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);
  this.deletePrivilege=hasPermission(Privileges.DeleteEngagementMeeting);
    }
  options: any[] = [
    { name: 'Yes', value: true }, // Adjust these options as needed
    { name: 'No', value: false },
  ];
  meetingContacts:any;
  GetEngagementMeetingById(engagementMeetingId:any){
    this.loaderService.showLoader();
    this.governanceService.getEngagementMeetingsById(engagementMeetingId).subscribe(
      response=>{
       this.engagementMeetingDetails.Title=response.data.title;
       this.engagementMeetingDetails.Organizer=response.data.organizer;
       this.engagementMeetingDetails.Agenda=response.data.agenda;
       this.engagementMeetingDetails.Recurring=response.data.recurring;
       this.engagementMeetingDetails.EngagementId=response.data.engagementId;
       this.meetingCreatedByUsername=this.privilegeService.getUserName(response.data.createdBy);
       this.meetingUpdatedByUsername=this.privilegeService.getUserName(response.data.updatedBy);
       this.meetingContacts=response.data.engagementMeetingOccurrences[0]?.engagementMeetingContacts;
       this.engagementMeetingDetails.CreatedDate=response.data.createdDate;
    
       this.engagementMeetingDetails.UpdatedDate=response.data.updatedDate;
       this.engagementMeetingDetails.EngagementMeetingOccurrences = response.data.engagementMeetingOccurrences.map((e: any) => {
        return this.convertKeysToUppercase(e);
    });
       this._oldEngagementMeetingDetails=JSON.stringify(this.engagementMeetingDetails);
       this.oldEngagementMeetingData=JSON.stringify(this.engagementMeetingDetails);
       this.engagementMeetingDetailBeforeUpdate=JSON.stringify(this.engagementMeetingDetails);
      }     
    );
   
  }



  AddNewOccurrences() { // click on Add Occurrence Button
  this._engagementMeetingOccurrenceAttachments = new EngagementMeetingOccurrenceAttachments();
  this._engagementMeetingAttendees = new EngagementMeetingAttendees();
  this._engagementMeetingAbsentees = new EngagementMeetingAbsentees();
  this._engagementMeetingOccurrences = new EngagementMeetingOccurrences();
  this._engagementAddOccurrences = new EngagementAddMultipleOccurrences();
  this.addMeetingActionsData = []; 
  this._engagementMeetingAbsenteesDetails = [];
  this._engagementMeetingAttendeesDetails = [];
  this._engagementMeetingOccurrenceAttachmentsDetails = [];
  this._engagementAddMultipleOccurrences = [];
  this.uploadedFiles = [];
  this.submitted = false;
  this.displayAddOccurrencesPopUP = true; 
  this.AddOccurrenceDialog =  "Add Occurrence"
  this.engagementAddoccurrencesPopUpForm.reset();
  this.isEditTrue = false;
  this.isAddEditOccurrenceFileUploadButtonDisabled = false;
  
this._individualSupMeetingOccurDetailsBeforeAddOrEdit = JSON.stringify({
  engagementMeetingOccurrences: this._engagementMeetingOccurrences,
  addMeetingActionsData: this.addMeetingActionsData,
  engagementMeetingAbsentees:this._engagementMeetingAbsentees.Absentees,
  engagementMeetingAttendees: this._engagementMeetingAttendees.Attendees,
  uploadAttachement: this.uploadedFiles
});
}
sponserId:number=0;
// engagementData:any;
GetEngagementDetailsById() {
  this.engagementService.GetEngagementById(this.EngagementId)
    .subscribe(res => {    
      this.engagementData = res.data;
      this.sponserId=this.engagementData.sponsorId;
      this.GetSupplierContacts(res.data.supplierId);
      this.GetSponserDetailsById();
    });
}
GetSupplierContacts(Id:number){
  this._mastersupplierService.getSupplierContactsDataById(Id)
  .subscribe(res=>{
    this.contactData = res.data;
  })
}
sponserData:any;
sponsorContactData:any;
  GetSponserDetailsById(){
   this.masterDataService.getSponserData(this.sponserId)
   .subscribe(res=>{
     this.sponserData=res.data.sponserInfo;
     this.sponsorContactData=res.data.sponserContacts;
     this.GetTeamAssociatesList(this.engagementId,2)
   })
  }

  associateData:any;
  GetTeamAssociatesList(conractIdOrSupplierId:any,teamType:number){
   
    this._associateService.GetTeamAssociatesListByEngagementId(conractIdOrSupplierId,teamType).subscribe((res:any)=>{
      if(res.status){
      this.associateData=res.data;
      this.updateOrganizerOptions();
      }
    });
  }


  updateOrganizerOptions() {
    if (this.engagementData !== undefined && this.contactData !== undefined) {
        const engagementOptions = this.contactData.map((contact: { contactName: any; }) => `${contact.contactName} ( ${this.supplierName} )`);
      if (!this.organizerOptions) {
        this.organizerOptions = [];
      }
      this.organizerOptions = this.organizerOptions.concat(engagementOptions);
      this.attendees=engagementOptions;
      this.absentees=engagementOptions;
      this._allAttendees=engagementOptions;
    }
    if (this.sponserData !== undefined && this.sponsorContactData !== undefined) {
      const sponsorOptions = this.sponsorContactData.map((contact: { name: any; }) => `${contact.name} ( ${this.sponserData.name} )`);
      if (!this.organizerOptions) {
        this.organizerOptions = [];
      }
      if(this.organizerOptions!=undefined){
        this.organizerOptions = this.organizerOptions.concat(sponsorOptions);
        }
        if(this.attendees!=undefined){
          this.attendees=this.attendees.concat(sponsorOptions);
        }
        if(this.absentees!=undefined){
          this.absentees=this.absentees.concat(sponsorOptions);
        }
      this._allAttendees=this._allAttendees.concat(sponsorOptions);
    }
     //added by vittal for associates
     if (this.associateData !== undefined && this.associateData !== null && this.associateData.length!=0) {
      const associateOptions = this.associateData.map((contact: { associateName: any; }) => `${contact.associateName} ( Associate )`);
      if (!this.organizerOptions) {
        this.organizerOptions = [];
      }
      if(this.organizerOptions!=undefined){
      this.organizerOptions = this.organizerOptions.concat(associateOptions);
      }
      if(this.attendees!=undefined){
      this.attendees=this.attendees.concat(associateOptions);
      }
      if(this.absentees!=undefined){
      this.absentees=this.absentees.concat(associateOptions);
      }
      if(this._allAttendees!=undefined){
      this._allAttendees=this._allAttendees.concat(associateOptions);
      }
    }

     //added by vittal for Engagement MeetingContacts on 22-09-2024
     if (this.meetingContacts !== undefined && this.meetingContacts !== undefined) {
      const meetingContactsOptions = this.meetingContacts.map((contact: { contactName: any; }) => `${contact.contactName}`);
      if (!this.organizerOptions) {
        this.organizerOptions = [];
      }
      if(this.organizerOptions!=undefined){
        this.organizerOptions = this.organizerOptions.concat(meetingContactsOptions);
        
        }
        if(this.attendees!=undefined){
          this.attendees=this.attendees.concat(meetingContactsOptions);
        }
        if(this.absentees!=undefined){
          this.absentees=this.absentees.concat(meetingContactsOptions);
        }
      this._allAttendees=this._allAttendees.concat(meetingContactsOptions);
    }

    let attendeesObjects: { name: string; code: number }[] = [];
    if (this._allAttendees.length > 0) {
        attendeesObjects = this._allAttendees.map((attendee, index) => ({
            name: attendee,
            code: index + 1, 
        }));
  }
  this._finalAttendees=attendeesObjects;
  this._allAttendees = attendeesObjects.map((attendee) => attendee.name);
  this.loaderService.hideLoader();
  this.loaderService.hidefinalLoader();
  }

generateBreadcrumb(component?:string,recordName?:string,tabName?:string,childRecName?:string,recordId?:number)
{
  this.breadcrumbService.generateBreadcrumb(component,recordName,tabName,childRecName,recordId);
}

getBreadcrumbs(): MenuItem[] {
  return this.breadcrumbService.getBreadcrumbs();
}


onAttendeesChange(event: any) {
  const selectedItems = event.value;
  const existingAttendees = this.attendeesValues ? this.attendeesValues.split(',') : [];

  for (const existingItem of existingAttendees) {
    if (!selectedItems.includes(existingItem)) {
      const index = existingAttendees.indexOf(existingItem);
      if (index !== -1) {
        existingAttendees.splice(index, 1);
      }
    }
  }

  for (const item of selectedItems) {
    if (!existingAttendees.includes(item)) {
      existingAttendees.push(item);
    }
  }

  this.attendeesValues = existingAttendees.join(',');
  const selectedItemsArray =  this.attendeesValues.split(',');
  if(selectedItems.length!=0)
  {
  this.absentees=this.organizerOptions.filter(c=>!selectedItemsArray.includes(c))
  }
  else{
    this.attendeesValues="";
    this.absentees=this.organizerOptions;
  }
}
onAbsenteesChange(event: any) {

const selectedItems = event.value;
const existingAbsentees = this.absenteesValues ? this.absenteesValues.split(',') : [];

for (const existingItem of existingAbsentees) {
  if (!selectedItems.includes(existingItem)) {
    const index = existingAbsentees.indexOf(existingItem);
    if (index !== -1) {
      existingAbsentees.splice(index, 1);
    }
  }
}

for (const item of selectedItems) {
  if (!existingAbsentees.includes(item)) {
    existingAbsentees.push(item);
  }
}

this.absenteesValues = existingAbsentees.join(',');
const selectedItemsArray =  this.absenteesValues.split(',');
if(selectedItems.length!=0)
{
this.attendees=this.organizerOptions.filter(c=>!selectedItemsArray.includes(c))
}
else{
  this.absenteesValues="";
  this.attendees=this.organizerOptions;
}
}
GetMeetingStatus(enumId: number, type: string) { //Get Meeting Status's from code list for the Meetings
  this.masterDataService.getCodeListData(enumId, type).subscribe(
    res => {      
      if (res.data.length > 0) {
        this.meetingStatus = res.data;
      }
    }
  );
};
//Delete Add Action Meeting item form Add Action table
deleteAction(currentActionIndex: number) {  
  this.confirmationService.confirm({
       header: 'Confirm',
       message: 'Are you sure you want to delete this action?',
       accept: () => {
      
       this.addMeetingActionsData.splice(currentActionIndex, 1);
       this.messageService.add({ severity: 'success', summary: 'Meeting Action Deleted', detail: '', });
       }
     });
   }
AddMeetingActions() { //Click on Add Action button new row will be generated on top of the grid
  this.addMeetingActionsData.unshift({
    Id: 0,
    ActionName: '',
    Owner: "",
    TargetDate: null,
    StatusMappingId: '',
    ClosureDate: null,
    Remarks: '',
    EngagementMeetingOccurrenceId: 0,    
    IsDeleted: false
  });
}
onFileChange(event: any) {
  const files: FileList = event.target.files;
  if (files.length <= 1) {
    this.maxFileSize = this._fileService._fileSize; // 20 MB (value Bytes in binary)
    if (files.length > 0) {
      this.currentFileSize = files[0].size;
      this.uploadedFileName = files[0].name;
     this.allowedExtensions = [FileExtension.DOC, FileExtension.DOCX, FileExtension.PDF, FileExtension.XLS, FileExtension.XLSX,FileExtension.PPT,FileExtension.PPTX];
      var uploadedExtension = this.uploadedFileName.split('.').pop().trim().toLowerCase();
    } 
    // Check if the uploaded file's extension is allowed
    if (this.allowedExtensions.includes(uploadedExtension)) {
      if (this.currentFileSize <= this.maxFileSize) {
        if (this.uploadedFiles.length + files.length <= 3) {
          for (let i = 0; i < files.length; i++) {
            this.uploadedFiles.push(files[i]);
            this.engagementAddoccurrencesPopUpForm.patchValue({
              addMeetingAttachments: files[0].name,
            });
            this.uploadedMeetingFile = files[0];
            this.multiMeetingFileData.push(this.uploadedMeetingFile);
            this.timeStamp = "";
            var utcDateTimeNow = new Date().toISOString();
            this.timeStamp = formatDate(utcDateTimeNow, this._dateformart.FiletimeStamp, this._dateformart.TimeStampZone);
            //this.formData.append("files[]", file, fileName);
            this.formData.append("file", this.uploadedMeetingFile, "Governance/EngagementMeeting/" + this.timeStamp + "_" + this.uploadedMeetingFile.name);
            this._engagementMeetingOccurrenceAttachments.FileName = this.uploadedMeetingFile.name;
            this._engagementMeetingOccurrenceAttachments.FilePath = "Governance/EngagementMeeting/" + this.timeStamp + "_" + this._engagementMeetingOccurrenceAttachments.FileName;
            this.newAttachment = {
              FileName: this._engagementMeetingOccurrenceAttachments.FileName,
              filePath: this._engagementMeetingOccurrenceAttachments.FilePath
            };
            this._engagementMeetingOccurrenceAttachmentsDetails.push(this.newAttachment);
          }
        } else {
          this.engagementAddoccurrencesPopUpForm.patchValue({
            addMeetingFileName: '',
            addMeetingFileSource: ''

          });

          this.messageService.add({ key: 'tc', severity: 'error', summary: 'Maximum 3 files allowed.', detail: '' });
        }
      }
      else {
        this.engagementAddoccurrencesPopUpForm.patchValue({
          addMeetingFileName: '',
          addMeetingFileSource: ''
        });
        this.messageService.add({ key: 'tc', severity: 'warn', summary: 'File Size limited to 20 Mb', detail: '' });
      }
    }
    else {
      this.engagementAddoccurrencesPopUpForm.patchValue({
        addMeetingFileName: '',
        addMeetingFileSource: ''
      });
      this.messageService.add({ key: 'tc', severity: 'error', summary: 'Invalid file type. Only .pdf, .xls, .xlsx, .doc, .docx files are allowed.', detail: '' });
    }

  }
  else {
    this.engagementAddoccurrencesPopUpForm.patchValue({
      addMeetingFileName: '',
      addMeetingFileSource: ''
    });
    this.messageService.add({ key: 'tc', severity: 'error', summary: 'Select and upload 1 file at a time.', detail: '',});

  }
 if(this.uploadedFiles.length >= 3)
 {
  this.isAddEditOccurrenceFileUploadButtonDisabled = true;
 }else{
  this.isAddEditOccurrenceFileUploadButtonDisabled = false;
 }
}
 deleteUploadedFiles: any[] = [];
removeFile(file: File) { //Attachement file remove cross button click
  this._engagementAddOccurrences.EngagementMeetingAddOccurrenceOccurrenceAttachments ??= [];
  const index = this.uploadedFiles.indexOf(file);
  if (index !== -1) {
    this.uploadedFiles.splice(index, 1);
    if(this.fileInput){
      this.fileInput.nativeElement.value = ''; 
    }
    const attachmentIndex = this._engagementMeetingOccurrenceAttachmentsDetails.findIndex(attachment => attachment.FileName === file.name);
      if (attachmentIndex !== -1) {
        this._engagementMeetingOccurrenceAttachmentsDetails.splice(attachmentIndex, 1);
      }
      this._engagementAddOccurrences.EngagementMeetingAddOccurrenceOccurrenceAttachments = this._engagementAddOccurrences.EngagementMeetingAddOccurrenceOccurrenceAttachments.map(e => {
        return this.convertKeysToUppercase(e);
    });
    
    var isOldOccurrenceAttachement = this._engagementAddOccurrences.EngagementMeetingAddOccurrenceOccurrenceAttachments.some(item => item.hasOwnProperty('Id'));
      if(isOldOccurrenceAttachement){
      const editOccurremceAttachment= this._engagementAddOccurrences.EngagementMeetingAddOccurrenceOccurrenceAttachments.findIndex(s => s.FileName == file.name && s.Id != 0);
      if (editOccurremceAttachment !== -1) {    
        var filedetails = {
          "fileName":  this._engagementAddOccurrences.EngagementMeetingAddOccurrenceOccurrenceAttachments[editOccurremceAttachment].FileName,
          "filePath":  this._engagementAddOccurrences.EngagementMeetingAddOccurrenceOccurrenceAttachments[editOccurremceAttachment].FilePath
        };
        if (filedetails.fileName != "" && filedetails.filePath != "") {                    
              this.deleteUploadedFiles.push(filedetails);    
              this._engagementAddOccurrences.EngagementMeetingAddOccurrenceOccurrenceAttachments.splice(editOccurremceAttachment,1)                          
          }
      }   
    }
    else{
      if(this._engagementAddOccurrences.EngagementMeetingAddOccurrenceOccurrenceAttachments)
      {
        const index = this._engagementAddOccurrences.EngagementMeetingAddOccurrenceOccurrenceAttachments.findIndex(attachment => attachment.FileName === file.name);
        if (index !== -1) {      
          this._engagementAddOccurrences.EngagementMeetingAddOccurrenceOccurrenceAttachments.splice(index,1)
      }     
      }
    }
    
  }
  if(this.uploadedFiles.length >= 3)
 {
  this.isAddEditOccurrenceFileUploadButtonDisabled = true;
 }else{
  this.isAddEditOccurrenceFileUploadButtonDisabled = false;
 }
  }
addOccurrencesPopUpCancelButton() // click on cancal button on Add occurrences Popup
{
var occurreceData: string = JSON.stringify({
  engagementMeetingOccurrences: this._engagementMeetingOccurrences,
  addMeetingActionsData: this.addMeetingActionsData,
  engagementMeetingAbsentees: this._engagementMeetingAbsentees.Absentees,
  engagementMeetingAttendees: this._engagementMeetingAttendees.Attendees,
  uploadAttachement:this.uploadedFiles
});

if(this._individualSupMeetingOccurDetailsBeforeAddOrEdit != occurreceData)
{
  if(!this.isEditTrue)
  {
    this.confirmationService.confirm({
      header: 'Confirm',
      message: 'Occurrence will not be added, Do you want to Continue?',
      accept: () => {
        this.displayAddOccurrencesPopUP = false;
        this._individualSupMeetingOccurDetailsBeforeAddOrEdit = [];
        occurreceData = '{}';
        this.messageService.add({
          key: 'tc',
          severity: 'info',
          summary: 'Occurrence not be added',
          detail: '',
      });
      }
    });
  }
  else{
    this.confirmationService.confirm({
      header: 'Confirm',
      message: 'Changes will not be saved, Do you want to Continue?',
      accept: () => {
        this.displayAddOccurrencesPopUP = false;
        this._individualSupMeetingOccurDetailsBeforeAddOrEdit = [];
        occurreceData = '{}';
        this.messageService.add({
          key: 'tc',
          severity: 'info',
          summary: 'Changes not saved',
          detail: '',
      });
      }
    });
  }
  
}
else{
  this._individualSupMeetingOccurDetailsBeforeAddOrEdit = [];
  occurreceData = '{}';
  this.displayAddOccurrencesPopUP = false;
}
}
isSaveOccurrencesPopupBtnDisable = false;
SaveOccurrencesButtonClick() //click on save button in Add occurrences popup
{
  setTimeout(() => {
    this.isSaveOccurrencesPopupBtnDisable = false;
  }, 3000);
  this.submitted = true;
  this.isSaveOccurrencesPopupBtnDisable = true;

  this._addActionsFieldsMandatory = false;  
  this._addActionsFieldsMandatory = this.addMeetingActionsData.some((Actions) => {
    if (
        this.checkNullOrEmptyValuesService.isNullOrUndefinedOrEmpty(Actions.ActionName) ||
        this.checkNullOrEmptyValuesService.isNullOrUndefinedOrEmpty(Actions.StatusMappingId) ||
        (Actions.StatusMappingId.code === "CLD" &&
            (this.checkNullOrEmptyValuesService.isNullOrUndefinedOrEmpty(Actions.ClosureDate) || this.checkNullOrEmptyValuesService.isNullOrUndefinedOrEmpty(Actions.Remarks)))
    ) {
        return true;
    }
    return false;
});
  if (this._addActionsFieldsMandatory) {
    this.messageService.add({
      key: 'tc',
      severity: 'error',
      summary: 'Please fill add actions mandatory fields',
      detail: '',
    });    
  }  //After Add Occurrence Popup Form Valid preparing the data into single collection
   if (this.engagementAddoccurrencesPopUpForm.valid && !this._addActionsFieldsMandatory) {   
    if(this.isEditTrue &&  this._engagementMeetingOccurrences != undefined && this._engagementMeetingOccurrences.Id != 0) {
      if (Array.isArray(this._engagementMeetingAbsentees.Absentees) && this._engagementMeetingAbsentees.Absentees != undefined) {
        this._engagementMeetingAbsentees.Absentees = this._engagementMeetingAbsentees.Absentees.map((absentee: { name: any; }) => absentee.name).join(',');
        this._engagementMeetingAbsenteesDetails.push(this._engagementMeetingAbsentees);
        if (this._engagementMeetingOccurrences?.EngagementMeetingAbsentees && this._engagementMeetingAbsentees?.Absentees != undefined) {  
          if(this._engagementMeetingOccurrences.EngagementMeetingAbsentees.length > 0)    
          {
            this._engagementMeetingOccurrences.EngagementMeetingAbsentees[0].Absentees  = this._engagementMeetingAbsentees.Absentees;
          }     
          else{
            this._engagementMeetingOccurrences.EngagementMeetingAbsentees = this._engagementMeetingAbsenteesDetails;
          }
          
      }
      }
      if (Array.isArray(this._engagementMeetingAttendees.Attendees) && this._engagementMeetingAttendees.Attendees!= undefined) {
        this._engagementMeetingAttendees.Attendees = this._engagementMeetingAttendees.Attendees.map((attendee: { name: any; }) => attendee.name).join(',');
        this._engagementMeetingAttendeesDetails.push(this._engagementMeetingAttendees); 
        if (this._engagementMeetingOccurrences?.EngagementMeetingAttendees && this._engagementMeetingAttendees?.Attendees != undefined) {    
          if(this._engagementMeetingOccurrences.EngagementMeetingAttendees.length > 0)      
          {
            this._engagementMeetingOccurrences.EngagementMeetingAttendees[0].Attendees = this._engagementMeetingAttendees.Attendees;
          } 
         else{
          this._engagementMeetingOccurrences.EngagementMeetingAttendees = this._engagementMeetingAttendeesDetails;
         }
      }
      }      
  
      this._engagementAddOccurrences.EngagementMeetingAddOccurrenceAbsentees =this._engagementMeetingOccurrences.EngagementMeetingAbsentees;
      this._engagementAddOccurrences.EngagementMeetingAddOccurrenceAttendees = this._engagementMeetingOccurrences.EngagementMeetingAttendees;
      this._engagementAddOccurrences.EngagementMeetingAddOccurrenceDetails = [this._engagementMeetingOccurrences]; 
      this._engagementAddOccurrences.EngagementMeetingAddOccurrenceActions = this.addMeetingActionsData;
      this._engagementAddOccurrences.EngagementMeetingAddOccurrenceOccurrenceAttachments?.push(
        ...this._engagementMeetingOccurrenceAttachmentsDetails
    ); 
  }
  else
  {
    if (Array.isArray(this._engagementMeetingAbsentees.Absentees) && this._engagementMeetingAbsentees.Absentees!= undefined) {
        this._engagementMeetingAbsentees.Absentees = this._engagementMeetingAbsentees.Absentees.map((absentee: { name: any; }) => absentee.name).join(',');
        this._engagementMeetingAbsenteesDetails.push(this._engagementMeetingAbsentees);
    }
    if (Array.isArray(this._engagementMeetingAttendees.Attendees) && this._engagementMeetingAttendees.Attendees!= undefined) {
      this._engagementMeetingAttendees.Attendees = this._engagementMeetingAttendees.Attendees.map((attendee: { name: any; }) => attendee.name).join(',');
      this._engagementMeetingAttendeesDetails.push(this._engagementMeetingAttendees); 
    }           
      this._engagementAddOccurrences.EngagementMeetingAddOccurrenceAbsentees = this._engagementMeetingAbsenteesDetails;
      this._engagementAddOccurrences.EngagementMeetingAddOccurrenceAttendees = this._engagementMeetingAttendeesDetails;
      this._engagementAddOccurrences.EngagementMeetingAddOccurrenceDetails = [this._engagementMeetingOccurrences]; 
      this._engagementAddOccurrences.EngagementMeetingAddOccurrenceActions = this.addMeetingActionsData;
      if(this._engagementAddOccurrences.EngagementMeetingAddOccurrenceOccurrenceAttachments== undefined)
      {
        this._engagementAddOccurrences.EngagementMeetingAddOccurrenceOccurrenceAttachments = [];
        this._engagementAddOccurrences.EngagementMeetingAddOccurrenceOccurrenceAttachments?.push(
          ...this._engagementMeetingOccurrenceAttachmentsDetails
      ); 
        
      }
      else{
        this._engagementAddOccurrences.EngagementMeetingAddOccurrenceOccurrenceAttachments?.push(
          ...this._engagementMeetingOccurrenceAttachmentsDetails
      ); 
      }  
  }
          
      this.uploadedFiles = [];
      this._engagementAddMultipleOccurrences.unshift(this._engagementAddOccurrences);        
      const engagementMeetingOccurrencesArray: EngagementMeetingOccurrences[] = [];
    
for (const addMultipleOccurrence of this._engagementAddMultipleOccurrences) {
  const actionsArray = Array.isArray(addMultipleOccurrence.EngagementMeetingAddOccurrenceActions)
    ? addMultipleOccurrence.EngagementMeetingAddOccurrenceActions
    : [addMultipleOccurrence.EngagementMeetingAddOccurrenceActions];
    const meetingOccurrence: EngagementMeetingOccurrences = {
      Id :addMultipleOccurrence.EngagementMeetingAddOccurrenceDetails? addMultipleOccurrence.EngagementMeetingAddOccurrenceDetails[0].Id:0,
      OccurrenceDate: addMultipleOccurrence.EngagementMeetingAddOccurrenceDetails? addMultipleOccurrence.EngagementMeetingAddOccurrenceDetails[0].OccurrenceDate:undefined,
      MeetingNotes: addMultipleOccurrence.EngagementMeetingAddOccurrenceDetails? addMultipleOccurrence.EngagementMeetingAddOccurrenceDetails[0].MeetingNotes:"",
      Decisions: addMultipleOccurrence.EngagementMeetingAddOccurrenceDetails? addMultipleOccurrence.EngagementMeetingAddOccurrenceDetails[0].Decisions:"",
      EngagementMeetingId:  addMultipleOccurrence.EngagementMeetingAddOccurrenceDetails? addMultipleOccurrence.EngagementMeetingAddOccurrenceDetails[0].EngagementMeetingId:0, 
      CreatedBy: addMultipleOccurrence.EngagementMeetingAddOccurrenceDetails? addMultipleOccurrence.EngagementMeetingAddOccurrenceDetails[0].CreatedBy:undefined,
      CreatedDate: addMultipleOccurrence.EngagementMeetingAddOccurrenceDetails? addMultipleOccurrence.EngagementMeetingAddOccurrenceDetails[0].CreatedDate:undefined,
      UpdatedBy: addMultipleOccurrence.EngagementMeetingAddOccurrenceDetails? addMultipleOccurrence.EngagementMeetingAddOccurrenceDetails[0].UpdatedBy:undefined,
      UpdatedDate:addMultipleOccurrence.EngagementMeetingAddOccurrenceDetails? addMultipleOccurrence.EngagementMeetingAddOccurrenceDetails[0].UpdatedDate:undefined,
      IsDeleted: false,
      TotalActionsCount:addMultipleOccurrence.EngagementMeetingAddOccurrenceActions!= undefined? addMultipleOccurrence.EngagementMeetingAddOccurrenceActions.length : 0,
      OpenActionsCount:this.getOpenActionsCount(actionsArray),
      EngagementMeetingAttendees: addMultipleOccurrence.EngagementMeetingAddOccurrenceAttendees,
      EngagementMeetingAbsentees: addMultipleOccurrence.EngagementMeetingAddOccurrenceAbsentees,
      EngagementMeetingOccurrenceAttachments:addMultipleOccurrence.EngagementMeetingAddOccurrenceOccurrenceAttachments,
      EngagementMeetingOccurrenceActions: addMultipleOccurrence.EngagementMeetingAddOccurrenceActions
    };
    engagementMeetingOccurrencesArray.push(meetingOccurrence);
}
if(this.isEditTrue)
{
if (this.engagementMeetingDetails.EngagementMeetingOccurrences) {
  const index = this.editOccurrencePosition;
  this.engagementMeetingDetails.EngagementMeetingOccurrences = [
      ...this.engagementMeetingDetails.EngagementMeetingOccurrences.slice(0, index), 
      ...engagementMeetingOccurrencesArray, 
      ...this.engagementMeetingDetails.EngagementMeetingOccurrences.slice(index + 1) 
  ];
}
}else{
  this.engagementMeetingDetails.EngagementMeetingOccurrences?.unshift(...engagementMeetingOccurrencesArray);  
}     
if(this.isEditTrue)
{
  this.displayAddOccurrencesPopUP = false;
  this.isEditTrue = false;
  this.messageService.add({
      key: 'tc',
      severity: 'success',
      summary: 'Changes have been saved',
      detail: '',
 });     
 this.compareModels();
}
else{
  this.displayAddOccurrencesPopUP = false;
  this.isEditTrue = false;
  this.messageService.add({
      key: 'tc',
      severity: 'success',
      summary: 'Occurrence has been added',
      detail: '',
 });     
}
      
  }
}

UpdateMeetingDetails()//click on save button in edit meeting screen
{
  this.isSaveBtnDisable = true;
  setTimeout(() => {
    this.isSaveBtnDisable = false;
  }, 3000);
   this.loaderService.showLoader();
  if (this.engagementMeetingForm.valid) { 
    if (this.deleteUploadedFiles.length > 0 ) {
      this.deleteOccurrenceAttachment();
    }
    else{
        if (this.multiMeetingFileData.length > 0) {
    this._fileService.UploadMultipleFile(this.formData).subscribe((response: any) => {        
      if (response) {
        this.updateMeetingData();
      }
      else {
        this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Add Occurrence Meeting File Failed to save', detail: '' });
      }
    })
  } 
  else{
    this.updateMeetingData();
  }
    }
}
else{
  this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Please enter required information', detail: '' });  
  this.loaderService.hideLoader();
}
}
_PostAddActionsMeetingData:any;
updateMeetingData(){
      this.executeCanExist = false;
      this.engagementMeetingDetails.Id= this.engagementMeetingId;
      this.engagementMeetingDetails.UpdatedBy=this.privilegeService.getLoginUserId();
      const allOccurrences = [
        ...(this.engagementMeetingDetails.EngagementMeetingOccurrences || []),
        ...this.deletedOccurrencesList 
      ];
    
      allOccurrences.forEach((occurrence) => {
        if (occurrence.IsDeleted) {
          this.governanceService.DeleteEngagementMeeting(0, occurrence.Id).subscribe((res: { data: number; }) => {
            if (res.data > 0) {
            }
          });
        }
      })
      this.engagementMeetingDetails.EngagementMeetingOccurrences?.filter((occurrences)=>
       {
        occurrences.OccurrenceDate = this.datePipe.transform(occurrences.OccurrenceDate, "MM/dd/yyyy");
          if(occurrences.EngagementMeetingOccurrenceActions)
               {
                occurrences.EngagementMeetingOccurrenceActions = occurrences.EngagementMeetingOccurrenceActions?.map((e: any) => {
                  return this.convertKeysToUppercase(e);
                   });
                 this._PostAddActionsMeetingData= occurrences.EngagementMeetingOccurrenceActions;
                 occurrences.EngagementMeetingOccurrenceActions = [];
                 this._PostAddActionsMeetingData.forEach((record:any) => {
                  record.TargetDate = this.datePipe.transform(record.TargetDate, "MM/dd/yyyy");
                  record.ClosureDate = this.datePipe.transform(record.ClosureDate, "MM/dd/yyyy");
                  if(typeof record.StatusMappingId == 'object'){
                    record.StatusMappingId = record.StatusMappingId.id;
                  }
                 else{
                  record.StatusMappingId = record.StatusMappingId;
                 }                  
                   occurrences.EngagementMeetingOccurrenceActions?.push(record);
                   }, this);  
               }  
  });
      this.governanceService.EngagementUpdateMeetingData(this.engagementMeetingDetails).subscribe(
      res => {
        this.loaderService.hideLoader();
        if(res.status){
          this.messageService.add({
            key: 'tc',
            severity: 'success',
            summary: 'Changes have been saved',
            detail: '',
          });    
          this.compareModels();     
          const _dataResult = {
            engagementId:this.engagementId,
            meetingId:this.meetingId,
          };
          this.viewMeetingButtonClick.emit(_dataResult);
        }      
      }     
    );    
}
getOpenActionsCount(actions: any[]): number {
  return actions.filter(action => action.StatusMappingId.code === "OPN").length;
}
onCancelEngagementMeeting()
  {
   const dataResult = {
    engagementId:this.engagementId,
    meetingId:this.meetingId,
  };
  if (!(this._oldEngagementMeetingDetails == JSON.stringify(this.engagementMeetingDetails)))
   {
  
    this.confirmationService.confirm({
      message: 'Changes will not be saved, Do you want to Continue?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.viewMeetingButtonClick.emit(dataResult);
        this.messageService.add({
          key: 'tc',
          severity: 'info',
          summary: 'Changes not saved',
          detail: '',
      });
         },
         reject: () => {
         },
       });
   }
   else{
     this.viewMeetingButtonClick.emit(dataResult);
   }
  }

  canExist(): boolean | Promise<boolean> | Observable<boolean> {
    if (this.executeCanExist && !(this._oldEngagementMeetingDetails == JSON.stringify(this.engagementMeetingDetails))) {
      return new Promise<boolean>((resolve) => {
        this.confirmationService.confirm({
          message: 'Changes will not be saved, Do you want to Continue?',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.messageService.add({
              key: 'tc',
              severity: 'info',
              summary: 'Changes Not Saved',
              detail: '',
            });
            resolve(true);
          },
          reject: () => {
            resolve(false);
          },
        });
      });
    }
    else {
      return true;
    }

  }
myAction:any;
isEditTrue:boolean= false;
editOccurrencePosition:number=0;
// attndees: any;
// absentees:any
isEditedActionPrevious:boolean= false;
uploadedEachOccurrenceFiles: File[] = []; 
_individualSupMeetingOccurDetailsBeforeAddOrEdit: any;
EditOccurrenceDialog(meetingOccurrence: any, index: number) {
  this.editOccurrencePosition = index; 
  this._engagementMeetingOccurrenceAttachments = new EngagementMeetingOccurrenceAttachments();
  this._engagementMeetingAttendees = new EngagementMeetingAttendees();
  this._engagementMeetingAbsentees = new EngagementMeetingAbsentees();
  this._engagementMeetingOccurrences = new EngagementMeetingOccurrences();
  this._engagementAddOccurrences = new EngagementAddMultipleOccurrences();
  this._engagementAddMultipleOccurrences = [];
  this._engagementMeetingAbsenteesDetails = [];
  this._engagementMeetingAttendeesDetails = [];
  this._engagementMeetingOccurrenceAttachmentsDetails = [];
  this.displayAddOccurrencesPopUP = true;
  this.AddOccurrenceDialog =  "Edit Occurrence";
  this.engagementAddoccurrencesPopUpForm.reset();
  this.isEditTrue = true;
   
  this.individualOccurrenceDetails = this.engagementMeetingDetails?.EngagementMeetingOccurrences?.[index];

   if(typeof meetingOccurrence.OccurrenceDate == 'string')
   {
    meetingOccurrence.OccurrenceDate = new Date(meetingOccurrence.OccurrenceDate);
   }
  this._engagementMeetingOccurrences =  this.convertKeysToUppercase(meetingOccurrence);
 
  this._engagementMeetingOccurrences.EngagementMeetingAttendees = this._engagementMeetingOccurrences.EngagementMeetingAttendees?.map((e: any) => {
    return this.convertKeysToUppercase(e);
     });
     this._engagementMeetingOccurrences.EngagementMeetingAbsentees = this._engagementMeetingOccurrences.EngagementMeetingAbsentees?.map((e: any) => {
   return this.convertKeysToUppercase(e);
  });
  this._engagementMeetingOccurrences.EngagementMeetingOccurrenceAttachments = this._engagementMeetingOccurrences.EngagementMeetingOccurrenceAttachments?.map((e: any) => {
  return this.convertKeysToUppercase(e);
   });
   this._engagementMeetingOccurrences.EngagementMeetingOccurrenceActions = this._engagementMeetingOccurrences.EngagementMeetingOccurrenceActions?.map((e: any) => {
    return this.convertKeysToUppercase(e);
     });

     this.individualOccurrenceDetails.EngagementMeetingAttendees = this.individualOccurrenceDetails?.EngagementMeetingAttendees?.map((e: any) => {
      return this.convertKeysToUppercase(e);
       });

       this.individualOccurrenceDetails.EngagementMeetingAbsentees = this.individualOccurrenceDetails?.EngagementMeetingAbsentees?.map((e: any) => {
        return this.convertKeysToUppercase(e);
         });
   this.myAction = this._engagementMeetingOccurrences.EngagementMeetingOccurrenceActions 
   this.addMeetingActionsData = [];
  for (const action of this.myAction) {
    if(action.Id !=0 ){
      if(action.TargetDate != null){
        action.TargetDate = new Date(action.TargetDate);
      }
      if(action.ClosureDate != null)
      {
        action.ClosureDate = new Date(action.ClosureDate);
      }
      if(action.StatusMappingId == undefined)
      {       
          action.StatusMappingId=this.meetingStatus.find(status => status.id === action.StatusMappingId);         
      }
      if(typeof action.StatusMappingId == 'number' && action.StatusMappingId != 0)
      {
        action.StatusMappingId=this.meetingStatus.find(status => status.id === action.StatusMappingId );
      }
    this.addMeetingActionsData.push(action);
  }
  else{
    this.addMeetingActionsData.push(action);
  }
  }

    if(this.individualOccurrenceDetails.EngagementMeetingAbsentees && this.individualOccurrenceDetails.EngagementMeetingAbsentees[0]?.Absentees != undefined && this.individualOccurrenceDetails.EngagementMeetingAbsentees[0]?.Absentees != "")
     {
     this._engagementMeetingAbsentees.Absentees =  this.individualOccurrenceDetails.EngagementMeetingAbsentees[0]?.Absentees.split(',');
     if (this._engagementMeetingAbsentees.Absentees.length > 0) {
      this._engagementMeetingAbsentees.Absentees = this._finalAttendees.filter((absentee: { name: any; }) => this._engagementMeetingAbsentees.Absentees.includes(absentee.name));
    } 

     }

     if(this.individualOccurrenceDetails?.EngagementMeetingAttendees && this.individualOccurrenceDetails.EngagementMeetingAttendees[0]?.Attendees != undefined && this.individualOccurrenceDetails.EngagementMeetingAttendees[0]?.Attendees != "")
     {
     this._engagementMeetingAttendees.Attendees =  this.individualOccurrenceDetails?.EngagementMeetingAttendees[0]?.Attendees.split(',');  
     if (this._engagementMeetingAttendees.Attendees.length > 0) {
      this._engagementMeetingAttendees.Attendees = this._finalAttendees.filter((attendee: { name: any; }) => this._engagementMeetingAttendees.Attendees.includes(attendee.name));
    } 
     }   
  
    this.uploadedFiles = this._engagementMeetingOccurrences.EngagementMeetingOccurrenceAttachments?.map((attachment: EngagementMeetingOccurrenceAttachments) => {  
      const fileName = attachment?.FileName;
      if (fileName && attachment?.Id != 0) {
          return new File([fileName], fileName);
      } 
      return null;
  }).filter(file => file !== null) as File[];
  
    var oldAccurrenceFiles =  this.individualOccurrenceDetails.engagementMeetingOccurrenceAttachments? this.individualOccurrenceDetails.engagementMeetingOccurrenceAttachments:this.individualOccurrenceDetails.EngagementMeetingOccurrenceAttachments;  
                            if (!this._engagementAddOccurrences.EngagementMeetingAddOccurrenceOccurrenceAttachments) {
                                 this._engagementAddOccurrences.EngagementMeetingAddOccurrenceOccurrenceAttachments = [];
                                }
         // Push the oldAccurrenceFiles into the EngagementMeetingAddOccurrenceOccurrenceAttachments
        this._engagementAddOccurrences.EngagementMeetingAddOccurrenceOccurrenceAttachments.push(...oldAccurrenceFiles);
        this.onEditUpdateAbsenteesDropDownOptions(this._engagementMeetingAbsentees.Absentees);
        this.onEditAttendeesDropDownOptions(this._engagementMeetingAttendees.Attendees);
        if(this.uploadedFiles.length >=3)
        {
         this.isAddEditOccurrenceFileUploadButtonDisabled = true;
        } else
        {
          this.isAddEditOccurrenceFileUploadButtonDisabled = false;
        }
          this._individualSupMeetingOccurDetailsBeforeAddOrEdit = JSON.stringify({
              engagementMeetingOccurrences: this._engagementMeetingOccurrences,
              addMeetingActionsData: this.addMeetingActionsData,
              engagementMeetingAbsentees: this._engagementMeetingAbsentees.Absentees,
              engagementMeetingAttendees: this._engagementMeetingAttendees.Attendees,
              uploadAttachement: this.uploadedFiles
            });
}

 convertKeysToUppercase(obj: any): any {
  const convertedObject: any = {};

  for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
          const uppercaseKey = key.charAt(0).toUpperCase() + key.slice(1);
          convertedObject[uppercaseKey] = obj[key];
      }
  }

  return convertedObject;
}
deleteOccurrenceAttachment(){
this._fileService.DeleteFile(this.deleteUploadedFiles).subscribe(
  res => {

    this.UploadFilesAndSaveFinalData();  
  }
);
}
UploadFilesAndSaveFinalData()
{
  if (this.multiMeetingFileData.length > 0) {
    this._fileService.UploadMultipleFile(this.formData).subscribe((response: any) => {        
      if (response) {
        this.updateMeetingData();
      }
      else {
        this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Add Occurrence Meeting File Failed to save', detail: '' });
      }
    })
  } 
  else{
    this.updateMeetingData();
  }
}
onEditUpdateAbsenteesDropDownOptions(selectedOptions: any) { //On Edit Each Occurrence Update Absentees Dropdown Options 
  if(selectedOptions != undefined){
      const existingAbsentees = this.absenteesValues ? this.absenteesValues.split(',') : []; 
      for (const existingItem of existingAbsentees) {
        if (!selectedOptions.includes(existingItem)) {
          const index = existingAbsentees.indexOf(existingItem);
          if (index !== -1) {
            existingAbsentees.splice(index, 1);
          }
        }
      }    
      for (const item of selectedOptions) {
        if (!existingAbsentees.includes(item)) {
          existingAbsentees.push(item);
        }
      }
      this.absenteesValues = existingAbsentees;
      const selectedItemsArray =  this.absenteesValues.split(',');
      if(selectedOptions.length!=0)
      {
      this.attendees=this.organizerOptions.filter(c=>!selectedItemsArray.includes(c))
      }
      else{
        this.attendees=this.organizerOptions;
      }
    }
    }
    onEditAttendeesDropDownOptions(selectedValues: any) {//On Edit Each Occurrence Update Attendees Dropdown Options
     if(selectedValues != undefined){
      const existingAttendees = this.attendeesValues ? this.attendeesValues.split(',') : []; 
      for (const existingItem of existingAttendees) {
        if (!selectedValues.includes(existingItem)) {
          const index = existingAttendees.indexOf(existingItem);
          if (index !== -1) {
            existingAttendees.splice(index, 1);
          }
        }
      }  
      for (const item of selectedValues) {
        if (!existingAttendees.includes(item)) {
          existingAttendees.push(item);
        }
      }
      this.attendeesValues = existingAttendees.join(',');
      const selectedItemsArray =  this.attendeesValues.split(',');
      if(selectedValues.length!=0)
      {
      this.absentees=this.organizerOptions.filter(c=>!selectedItemsArray.includes(c))
      }
      else{
        this.absentees=this.organizerOptions;
      }
    }
  }
  changedEngagementMeetingBasicInfo:any;
    oldEngagementMeetingData:any;
    engagementMeetingDetailBeforeUpdate:any
    compareModels() {
      if(this.engagementMeetingDetailBeforeUpdate){
      this.changedEngagementMeetingBasicInfo = this.auditLogService.compareModels(JSON.parse(this.engagementMeetingDetailBeforeUpdate),this.engagementMeetingDetails,this.auditEntities.EngagementMeetings,this.auditIdentifierDetails.EngagementMeetings,this.auditOperations.Update,this.auditModule.GovernanceService);
      }
    }

    get _attendees() {
      return this._finalAttendees.filter((a: any) => !this._engagementMeetingAbsentees.Absentees.includes(a));
    }
    get _absentees() {
      return this._finalAttendees.filter((a: any) => !this._engagementMeetingAttendees.Attendees.includes(a));
    }

    contactHeader='';
    contactSubmitted=false;
    displayContractGroupDialog:boolean=false;
    contractGroup:any;
    currentField: any;
    actionIndex:number=0;
    openContractModel(field: string,rowIndex:number=0) {
      this.actionIndex=rowIndex;
      this.contactName='';
      this.contactHeader = 'Add Contact';
      this.currentField = field;
      this.contactFormGroup.reset();
      Object.keys(this.contactFormGroup.controls).forEach(key => {
        this.contactFormGroup?.get(key)?.setErrors(null);
      });
      this.contactSubmitted = false;
      this.contractGroup = { name: "" };
      this.displayContractGroupDialog = true;
    }
    newlyAddedContacts: any[] = [];
SaveMeetingContact() {
  this.contactSubmitted = true;

  if (this.contactFormGroup.valid) {
    const newContactName = this.contactFormGroup.value.contractName;
    const newCode = this._finalAttendees.length > 0 ? Math.max(...this._finalAttendees.map((contact: { code: any; }) => contact.code || 0)) + 1 : 1;
    const newContact: EngagementMeetingContacts = {
      id: 0, 
      contactName: newContactName,
      designation: "",
      countryCode: "",
      phoneNumber: "",
      email: "",
      meetingId: this.engagementMeetingDetails.Id ?? 0 
    };
    const isDuplicate= this.checkNameExists(newContactName)
    if (!isDuplicate) {
      switch (this.currentField) {
        case 'organizer':
          this.organizerOptions = [...this.organizerOptions, { label: newContact.contactName, value: newContact.contactName }];
          this.engagementMeetingDetails.Organizer = newContact.contactName;
          const Attendee = { name: newContact.contactName, code: newCode };
          this._finalAttendees = [...this._finalAttendees, Attendee];
          this._attendees.push(Attendee);
          const Absentee = { name: newContact.contactName, code: newCode };
          this._attendees.push(Absentee);
          break;
        case 'attendees':
          const newAttendee = { name: newContact.contactName, code: newCode };
          this._finalAttendees = [...this._finalAttendees, newAttendee];
          this._engagementMeetingAttendees.Attendees = [...this._engagementMeetingAttendees.Attendees, newAttendee];
          this._attendees.push(newAttendee);
          this.organizerOptions = [...this.organizerOptions, { label: newContact.contactName, value: newContact.contactName }];
          break;
        case 'absentees':
          const newAbsentee = { name: newContact.contactName, code: newCode };
          this._finalAttendees = [...this._finalAttendees, newAbsentee];
          this._engagementMeetingAbsentees.Absentees = [...this._engagementMeetingAbsentees.Absentees, newAbsentee];
          this._attendees.push(newAbsentee);
          this.organizerOptions = [...this.organizerOptions, { label: newContact.contactName, value: newContact.contactName }];
          break;
        case 'owner':
          this.organizerOptions = [...this.organizerOptions, { label: newContact.contactName, value: newContact.contactName }];
          this.addMeetingActionsData[this.actionIndex].Owner = newContact.contactName;
          const Attendees = { name: newContact.contactName, code: newCode };
          this._finalAttendees = [...this._finalAttendees, Attendees];
          this._attendees.push(Attendees);
          const Absentees = { name: newContact.contactName, code: newCode };
          this._attendees.push(Absentees);
          break;
        default:
          break;
      }
      this.newlyAddedContacts.push(newContact);
      this.engagementMeetingDetails.EngagementMeetingContacts = [...this.engagementMeetingDetails.EngagementMeetingContacts ?? [], newContact];
    } else {
      this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Contact Name already exists. Please enter a unique Name', detail: '' });
    }
    this.displayContractGroupDialog = false;
  }
}
closeContactDialog(){
  this.displayContractGroupDialog = false;
  this.messageService.add({
    key: 'tc',
    severity: 'info',
    summary: 'Contact Information has not been saved',
    detail: '',
});
  
}
checkNameExists(name: string): boolean {
  return this._finalAttendees.some((item: { name: string; }) => item.name === name);
}
deletedOccurrencesList: any[] = [];
deleteOccurrece(occurrenceId: number, index: number) {
  if (occurrenceId != undefined) {
    this.confirmationService.confirm({
      message: `Are you sure you want to delete this Meeting Occurrence?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (this.engagementMeetingDetails && this.engagementMeetingDetails.EngagementMeetingOccurrences) {
          const deletedOccurrence = this.engagementMeetingDetails.EngagementMeetingOccurrences[index];
          deletedOccurrence.IsDeleted = true;
          this.engagementMeetingDetails.EngagementMeetingOccurrences.splice(index, 1);
          this.deletedOccurrencesList.push(deletedOccurrence);
          this.showMessage('info', 'Meeting Occurrence is deleted successfully.');
        }
      },
      reject: () => {},
    });
  }
}
    showMessage(severity: string, summary: string, detail: string = ''): void {
      this.messageService.add({
        key: 'tc',
        severity: severity,
        summary: summary,
        detail: detail,
      });
    }
}
