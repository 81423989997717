import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { breadcrumnsCodes } from 'src/app/shared/constants/global.constants';
import { Privileges } from 'src/app/shared/constants/privileges.constants';

@Component({
  selector: 'app-dashboardhome',
  templateUrl: './dashboardhome.component.html',
  styleUrls: ['./dashboardhome.component.css']
})


export class DashboardhomeComponent {
  _enumBreadcrumb: any;
  EngtKpiDarshBoardPrivilage:boolean=false;
  ConKpiDarshBoardPrivilage:boolean=false;
  ViewFunctionsFinanceDashboard:boolean=false;
  ViewEngagementFinanceDashboard:boolean=false;
  ViewFunctionsTeamsDashboard:boolean=false;
  ViewEngagementTeamsDashboard:boolean=false;
  PrivilageMessage:string="Not authorized to these Dashboards."
  constructor(
    private breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute,
    private router: Router, 
    private privilegeService: PrivilegeService,
    private messageService: MessageService,
  ){
      
    }
  ngOnInit(){
    this._enumBreadcrumb = breadcrumnsCodes;
    this.breadcrumbService.generateBreadcrumb(this._enumBreadcrumb.DBH);
    this.BindPrivileges();
  }
  BindPrivileges()
  {
    const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);
    this.EngtKpiDarshBoardPrivilage=hasPermission(Privileges.ViewEngagementKPIDashboard);
    this.ConKpiDarshBoardPrivilage=hasPermission(Privileges.ViewContractKPIDashboard);
    this.ViewFunctionsFinanceDashboard=hasPermission(Privileges.ViewFunctionsFinanceDashboard);
    this.ViewEngagementFinanceDashboard=hasPermission(Privileges.ViewEngagementFinanceDashboard);
    this.ViewFunctionsTeamsDashboard=hasPermission(Privileges.ViewFunctionsTeamsDashboard);
    this.ViewEngagementTeamsDashboard=hasPermission(Privileges.ViewEngagementTeamsDashboard);
  }
  getBreadcrumbs(): MenuItem[] {
    return this.breadcrumbService.getBreadcrumbs();
  }
  showDashboard(dashboardGroup:any)
  {
        if(dashboardGroup=='KpiTreand'){
          if(!this.EngtKpiDarshBoardPrivilage && !this.ConKpiDarshBoardPrivilage )
            {
              this.messageService.add({  key: 'tc',severity: 'warn', summary: this.PrivilageMessage, detail: ''});
            }
            else{
              this.router.navigate(['/KPITrendDashboard']);
            }
     
   
  }
  if(dashboardGroup=='Finance'){
    if(!this.ViewFunctionsFinanceDashboard && !this.ViewEngagementFinanceDashboard )
      {
        this.messageService.add({  key: 'tc',severity: 'warn', summary: this.PrivilageMessage, detail: ''});
      }
      else{
        this.router.navigate(['/FinanceDashboard']);
      }

    
  }
  if(dashboardGroup=='Teams'){
    if(!this.ViewFunctionsTeamsDashboard && !this.ViewEngagementTeamsDashboard )
      {
        this.messageService.add({  key: 'tc',severity: 'warn', summary: this.PrivilageMessage, detail: ''});
      }
      else{
        this.router.navigate(['/TeamsDashboard']);
      }
   
  }

  }

}
