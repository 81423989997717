import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
export interface IDeactivateGuard
{
 canExist:()=>boolean|Promise<boolean>|Observable<boolean>;
}
export class CanDeactivateGuard implements CanDeactivate<IDeactivateGuard> {
  canDeactivate(
    component: IDeactivateGuard,
    Route: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): boolean|Promise<boolean>|Observable<boolean> {
    return component.canExist();
  }
}
