import { DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { ContractService } from 'src/app/Contracts/Services/contract.service';
import { LoaderService } from 'src/app/Loader/loader.service';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { breadcrumnsCodes, DashboardIds, StatusCodes } from 'src/app/shared/constants/global.constants';
import { DashboardDetails } from '../../Models/dashboard-details';
//import AWS from 'aws-sdk';

import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import { EmbeddingContext } from 'amazon-quicksight-embedding-sdk/dist';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EngagementKpiService } from '../../../Governance/kpis/Engagement/Services/engagement-kpi.service';
import { ContractKPIService } from '../../../Governance/kpis/Contract/Services/ContractKpi.Service';
import { DashboardService } from '../../Services/dashboard.service';
import { AuthCommonService } from 'src/app/shared/Authentication/auth.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { environment } from 'src/environments/environment';
import { concatMap, forkJoin, map, tap } from 'rxjs';
import { Paginator } from 'primeng/paginator';
import { EngagementService } from 'src/app/Engagement/Services/engagement.service';
import { Privileges } from 'src/app/shared/constants/privileges.constants';
//import { EmbeddingContext } from 'amazon-quicksight-embedding-sdk/dist';
//import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
//import * as html2pdf from 'html2pdf.js';
//import html2canvas from 'html2canvas';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent {

  dashboardForm: FormGroup;
  _enumBreadcrumb: any;
  isfromSupplier: any;
  dashboardApplyClicked: boolean = false;
  dashboardDetails = new DashboardDetails();
  selectedEngagementName: any;
  engagementdata: any;
  engagements: any;
  engagementId: any;
  contractId:any;
  contractdata: any;
  contracts: any;
  statusCodes:any;
  disableEngagement:boolean=false;
  dashboardIdsDetails:any;

  first:number=0;
  showPaginator=false;
  showBottomPaginator=false;
  pageNumber=0;
  disableApplyBtn=false;
  firstRecOfPage=0;
  lastRecOfPage=0;
  totalPages=0;
  kpiPerPage:any=0;
  EngtKpiDarshBoardPrivilage:boolean=false;
  ConKpiDarshBoardPrivilage:boolean=false;
  @ViewChild('paginator') paginator!: Paginator;
  @ViewChild('paginatorBottom') paginatorBottom!: Paginator;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private _engagementSerice: EngagementService,
    private _contractService: ContractService,
    private fb: FormBuilder,
    private router:Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private _engagementKPIService : EngagementKpiService,
    private _contractKPIServiceService : ContractKPIService,
    private _dashboaredService:DashboardService,
    private messageService: MessageService,
    private loaderService: LoaderService,
    private authService: AuthCommonService,
    private privilegeService: PrivilegeService) {
    this.dashboardForm = this.fb.group({
      dashboardEngagement: ['', [Validators.required]],
      dashboardContract: ['', [Validators.required]],
    });
  }

  placeholder:any;
  queryParams:any;
  contractqueryParams:any;
  engagementRecord:any
  contractRecord:any
  engagementdashboard:any;
  engagementsIds :any=0;


  ngOnInit() {
 this.kpiPerPage=environment?._kpiPerPageForDashboard;
  var userData=this.privilegeService.getUserData();
  this.showPaginator=false;
 // this.engagementsIds=userData.suppliers;
 this.queryParams = this.route.snapshot.queryParams['engagementId'];
 //this.engagementId="2";//this.queryParams
 this.contractqueryParams = this.route.snapshot.queryParams['contractDetailsId'];
 if(this.contractqueryParams!=undefined && this.contractqueryParams!=null ){
  this.contractId = this.contractqueryParams;
 }
 if(this.queryParams!=undefined && this.queryParams!=null ){
  this.engagementId = this.queryParams;
  //this.engagementId="2";
 }

 this.getAllEngagements();
   
    //setTimeout(() => {
      this.route.queryParams.subscribe(params => {
       
        if(params['engagementId']!=undefined && params['engagementId']!=null ){
          //this.engagementId = params['engagementId'];
          //this.engagementId="2";
         // this.GetContractList();    
          this.engagementdashboard=params['breadcrumb'];


          setTimeout(() => {
            if(this.engagementdashboard!=null && this.contractRecord!=undefined){
              this.breadcrumbService.generateBreadcrumb(this._enumBreadcrumb.SDAS,this.engagementRecord,'',this.contractRecord,this.engagementId,"");
            }
            else if(this.contractRecord==undefined){
              this.dashboardDetails.ContractId=this.contracts[0].value;
            }
            
          }, 1000);
          
        }
        else{
          this.placeholder= "Select Contract ";
        }
      });
   // }, 1000);
    if(this.contractqueryParams!=undefined && this.contractqueryParams!=null){
      this.GetContract();
    }
    this._enumBreadcrumb = breadcrumnsCodes;
    this.statusCodes=StatusCodes;
    this.breadcrumbService.generateBreadcrumb(this._enumBreadcrumb.KPIDB);
    
    
    this.BindPrivileges();
  }
 IsShowButton:boolean=true;
  BindPrivileges()
  {
    const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);
    this.EngtKpiDarshBoardPrivilage=hasPermission(Privileges.ViewEngagementKPIDashboard);
    this.ConKpiDarshBoardPrivilage=hasPermission(Privileges.ViewContractKPIDashboard);
    if(!this.EngtKpiDarshBoardPrivilage && !this.ConKpiDarshBoardPrivilage )
    {
      this.IsShowButton=false;
    }
  }

  generateBreadcrumb(component?:string,recordName?:string,tabName?:string,childRecName?:string,recordId?:number)
  {
    if((this.queryParams!=undefined && this.queryParams!=null) && (this.contractqueryParams==undefined && this.contractqueryParams==null)){
      this.breadcrumbService.generateBreadcrumb(this._enumBreadcrumb.KPISD,this.engagementRecord,"","",this.engagementId,"");
    }
    if((this.contractqueryParams!=undefined && this.contractqueryParams!=null) && (this.queryParams!=undefined && this.queryParams!=null)){
      this.breadcrumbService.generateBreadcrumb(this._enumBreadcrumb.KPICD,this.contractRecord,"","",this.contractId,"");
    }
  }

  getBreadcrumbs(): MenuItem[] {
    return this.breadcrumbService.getBreadcrumbs();
  }


  getAllEngagements() {
    this._engagementSerice.GetEngagementList()
      .subscribe(res => {
        this.engagementdata = res.data;

        if(this.engagementId!=undefined && this.engagementId!=null ){
        const selectedEngagement= this.engagementdata.find((engagement: { id: any }) => engagement.id.toString() === this.engagementId);
        this.engagementRecord=selectedEngagement.engagementName;
        this.GetContractList();   
        this.generateBreadcrumb();
        if (selectedEngagement) {
          this.disableEngagement = true; 
          this.disableApplyBtn=true;
          this.dashboardDetails.EngagementId = parseInt(this.engagementId);
        }
        }

        this.engagements = this.engagementdata.map((engagement: { id: any, engagementName: any }) => ({ label: engagement.engagementName, value: engagement.id }));    
      });

  }


  // GetEngagementsByGroups(supplierIds:any) {
  //   this._engagementSerice.GetEngagementsByGroups(supplierIds)
  //     .subscribe(res => {
  //       this.engagementdata = res.data;
  //       this.engagements = this.engagementdata.map((supplier: { id: any, name: any }) => ({ label: supplier.name, value: supplier.id }));    
  //     });

  // }

  GetContractList() {

    this.loaderService.showLoader();
    this._contractService.GetAllContractByEngagementId(this.engagementId).subscribe(
      res => {
        this.contractdata = res.data;
        this.contracts = this.contractdata.map((contract: { id: any, title: any }) => ({ label: contract.title, value: contract.id }));
        if (this.contracts && this.contracts.length > 0) {
          if(this.EngtKpiDarshBoardPrivilage){
          this.contracts.unshift({ label: this.statusCodes.NA, value: 'Not Applicable' });
          }
      } else {
        if(this.EngtKpiDarshBoardPrivilage){
          this.contracts = [{ label: this.statusCodes.NA, value: 'Not Applicable' }];
          this.dashboardDetails.ContractId=this.contracts[0].value;
      }
      }
        this.route.queryParams.subscribe(params => {
        if(this.contractqueryParams==undefined && this.queryParams != undefined){
           this.populateDashboard();
        }
      });
     this.loaderService.hideLoader();

      });
  }
  IsContractDisabled:boolean=false;
  onEngagementChange(event: any) {
    this.engagementId = event.value;
    this.disableApplyBtn=false;
    this.dashboardDetails.ContractId=null;
    this.dashboardApplyClicked = false;
    this.IsContractDisabled=false;
    if(this.ConKpiDarshBoardPrivilage)
    {
      this.GetContractList();
    }
    if(!this.ConKpiDarshBoardPrivilage && this.EngtKpiDarshBoardPrivilage )
    {
      this.contracts = [{ label: this.statusCodes.NA, value: 'Not Applicable' }];
      this.dashboardDetails.ContractId=this.contracts[0].value;
      this.IsContractDisabled=true;
    }
  }

  onContractChange(){
    this.disableApplyBtn=false;
  }

  _kPIListData:any;
_dashboardId:any;
  onApplyClick() {
    this.dashboardApplyClicked = true;
    this.showBottomPaginator=false;
    this.disableApplyBtn=true;
    this.first=0;
   
    if (this.paginator) {
      this.disableChangeEvent=false;
      this.paginator.changePage(0);
      this.disableChangeEvent=true;
      this.totalPages=this.paginator.getPageCount();
    }
    if(this.dashboardForm.valid)
    {
    this.populateDashboard();
    }
    else{
      this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Please select required information', detail: ''});
    }
  }

  populateDashboard()
  {
    this.loaderService.showLoader();
   
    var container = document.getElementById("dashboardContainer");

// Make the content of the container blank
if (container) {
  container.innerHTML = "";
}
    //Get KPI list here
    if(this.dashboardDetails.ContractId=='Not Applicable' || this.dashboardDetails.ContractId==undefined)
    {
     
     this._dashboardId=environment?._engagementDashboardId;
      //Get Supplier KPIs
      this._engagementKPIService.getEngagementKPIsByEngagementId(this.dashboardDetails.EngagementId).subscribe(
        response=>{
         this._kPIListData=response.data;


        

         if(this._kPIListData.length>0){
          this.showPaginator=true;
          this.pageNumber=0;
        
       
        this.frameKPIGroups(0);
         }
         else{
          this.loaderService.hideLoader();
          this.messageService.add({  key: 'tc',severity: 'warn', summary: 'No KPIs exist for selected Engagement', detail: ''});
         }
        });
    }
    else{
     
      this._dashboardId=environment?._contractDashboardId;
      //Get Contract KPIs
      this._contractKPIServiceService.GetAllContractKpis(this.dashboardDetails.ContractId).subscribe(
      data=>{
        this._kPIListData=data.data;
        if(this._kPIListData.length>0){
          //this.getEmbedURL();
          this.showPaginator=true;
         this.pageNumber=0;
       
          this.frameKPIGroups(0);
          
          }
          else{
            this.loaderService.hideLoader();
           this.messageService.add({  key: 'tc',severity: 'warn', summary: 'No KPIs exist for selected Contract', detail: ''});
          }
      });
      
    }
    this.loaderService.hideLoader();
  }

  contractData:any;
  GetContract() {
    this._contractService.GetAllContract(0).subscribe(
      res => {
        this.contractData = res.data;
        const filteredData = this.contractData.filter((item: { id: any; }) => item.id === parseInt(this.contractqueryParams));
        this.dashboardDetails.ContractId=filteredData[0].id;
        this.contractRecord=filteredData[0].title;
        this.populateDashboard();
        this.generateBreadcrumb();
      });
  }

firstEmdbedURL="";
commonEmbedURL="";

// GetReportingServicesEmbedURL() {
//   var model = {
//     "dashboardID": this._dashboardId,
//     "token": "r78r87vbbf3r7ry73sdvdfv48ergvfdg4y5t5ty8568jvhhuhvhjbjhjk7877vjhvjhherggt",//this.authService.getCookieValue('LOGIN_TOKEN'),
//     "privilege": [
//       {
//         "MenuName": "string",
//         "isAllDataAvailable": true,
//         "DataValues": []
//       }
//     ]
//   };

//   // Create an observable for the firstEmdbedURL
//   const firstEmdbedURLObservable = this._dashboaredService.getEmbedUrlData(model).pipe(
//     map((res: any) => res.data)
//   );

//   // Create an observable for the commonEmbedURL
//   const commonEmbedURLObservable = firstEmdbedURLObservable.pipe(
//     concatMap(() => this._dashboaredService.getEmbedUrlData(model).pipe(
//       map((res: any) => res.data)
//     ))
//   );

//   // Combine the observables using forkJoin
//   return forkJoin({
//     firstEmdbedURL: firstEmdbedURLObservable,
//     commonEmbedURL: commonEmbedURLObservable
//   }).pipe(
//     map(({ firstEmdbedURL, commonEmbedURL }) => {
//       // Save the URLs to class properties
//       this.firstEmdbedURL = firstEmdbedURL;
//       this.commonEmbedURL = commonEmbedURL;
//     })
//   );
// }




GetReportingServicesEmbedURL() {
  var model = {
    "dashboardID": this._dashboardId,
    "token": this.authService.getCookieValue('LOGIN_TOKEN'),
    "privilege": [
      {
        "MenuName": "string",
        "isAllDataAvailable": true,
        "DataValues": []
      }
    ]
  };

  // Create an observable for the firstEmdbedURL
  const firstEmdbedURLObservable = this._dashboaredService.getEmbedUrlData(model).pipe(
    map((res: any) => res.data)
  );

  // Combine the observables using forkJoin
  return firstEmdbedURLObservable.pipe(
    concatMap(firstEmdbedURL => {
      // Save the firstEmdbedURL to a class property
      this.firstEmdbedURL = firstEmdbedURL;
      // Now fetch the commonEmbedURL
      return this._dashboaredService.getEmbedUrlData(model).pipe(
        map((res: any) => res.data),
        tap((commonEmbedURL:any) => {
          // Save the commonEmbedURL to a class property
          this.commonEmbedURL = commonEmbedURL;
        })
      );
    })
  );
}





kpiCount=0;
loopCount=0;
kpisubGroup:any;
getEmbedURL() {
  // For local testing
  this.loaderService.showLoader();
  
  this.kpiCount=this.kpiListForPage.length-1;

  this.firstRecOfPage=this.first+1;
  this.lastRecOfPage=this.firstRecOfPage+this.kpiCount;
 
 
  // Call the method to get the embed URLs
  this.GetReportingServicesEmbedURL().subscribe(async () => {
  
      for (let i = 0; i < this.kpiListForPage.length; i++) {
      const kpi = this.kpiListForPage[i];

      if (i === 0 && this.pageNumber ==0) {
        this.loopCount=i;
        await this.executeDashboard(this.firstEmdbedURL, kpi.id, kpi.kpiName,this.kpiCount,this.loopCount);
      } 
      else if (i === 1) 
      {
        this.loopCount=i;

        if(this.pageNumber ==0)
        {
         await this.delay(6000);
        }
        await this.executeDashboard(this.commonEmbedURL, kpi.id, kpi.kpiName,this.kpiCount,this.loopCount);
       // await this.executeDashboard(this.firstEmdbedURL, kpi.id, kpi.kpiName,this.kpiCount,this.loopCount);
      } 
      else 
      {
        this.loopCount=i;
        await this.executeDashboard(this.commonEmbedURL, kpi.id, kpi.kpiName,this.kpiCount,this.loopCount);

       //await this.executeDashboard(this.firstEmdbedURL, kpi.id, kpi.kpiName,this.kpiCount,this.loopCount);

      }
    }
    
  });
}

kpiListForPage:any;
frameKPIGroups(pageNumber:number){
  // this._kPIListData = [
  //  // {"id": 3735, "kpiName": "testMSA-Cost Predictability -testingService-Invoice Timeliness"},
  //  // {"id": 3736, "kpiName": "testMSA-Cost Predictability -testingService-Cost Accuracy"},
  //   {"id": 535, "kpiName": "testMSA-Cost Predictability -testingService-Invoice Timeliness"},
  //   {"id": 535, "kpiName": "testMSA-Cost Predictability -testingService-Cost Accuracy"},
  //    {"id": 600, "kpiName": "testMSA-Cost Predictability -testingService-Invoice Timeliness"},
  //     {"id": 600, "kpiName": "testMSA-Cost Predictability -testingService-Cost Accuracy"},
  //   //   {"id": 535, "kpiName": "testMSA-Cost Predictability -testingService-Invoice Timeliness"},
   
  // ];

  


  var container = document.getElementById("dashboardContainer");

  // Make the content of the container blank
  if (container) {
    container.innerHTML = "";
  }
this.kpisubGroup=this.convertToSubgroups(this._kPIListData);
this.kpiListForPage=this.kpisubGroup[pageNumber];
this.getEmbedURL();

}

 // Function to convert array into subgroups of 10 with indexes 0 and 1
 convertToSubgroups(array: any[]): any[][] {
  const subgroups = [];
  let i = 0;

  while (i < array.length) {
    const subgroupLength = Math.min(this.kpiPerPage, array.length - i);
    subgroups.push(array.slice(i, i + subgroupLength));
    i += subgroupLength;
  }

  return subgroups;
}



async executeDashboard(embedURL: string, id: number, kpiName: string,kpiCount:number,loopCount:number)
 {
  if (this.paginator) {
    this.totalPages=this.paginator.getPageCount();
  }
  // Call Dashboard function
  this.Dashboard(embedURL, id, kpiName,kpiCount,loopCount);
}

delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}



quickSightUrls: string[]=[];
  public async Dashboard(embeddedURL:string,kpiid:number,kpiName:string,kpiCount:number,loopCount:number) {
    var containerDiv = document.getElementById("dashboardContainer") || '';
    const embeddingContext: EmbeddingContext = await createEmbeddingContext();
   
    const frameOptions = {
      url: embeddedURL + '&#p.kpiid=' + kpiid + '&p.KPIName='+ kpiName + '&p.Chart1Heading=KPI Values',
      container: containerDiv,
      height: "300px",
      width: "100%",
      resizeHeightOnSizeChangedEvent: true,
      onChange: (changeEvent:any, metadata:any) => {
        switch (changeEvent.eventName) {
          
            case 'FRAME_LOADED': {
              this.showBottomPaginator=true;
              if(kpiCount==loopCount)
              {
                 this.loaderService.hideLoader();
              }
                break;
            }
        }
    },
  }
    
   embeddingContext.embedDashboard(frameOptions);

  }

disableChangeEvent=true;

  onPageChange(event:any){
    this.pageNumber=event.page;
    this.first=event.first;
   this.showBottomPaginator=false;
    if(this.paginatorBottom && this.disableChangeEvent){
     this.disableChangeEvent=false;
      this.paginatorBottom.changePage(this.pageNumber);
     this.disableChangeEvent=true;
     this.frameKPIGroups(event.page);

    }

   

  }

  onBottomPageChange(event:any){
    this.pageNumber=event.page;
    this.first=event.first;
    this.showBottomPaginator=false;
    if(this.paginator && this.disableChangeEvent){
     this.disableChangeEvent=false;
      this.paginator.changePage(this.pageNumber);
     this.disableChangeEvent=true;
     this.frameKPIGroups(event.page);
    }

  }


  // async downloadPDF() {
  //   // Capture the embedded visual as an image
  //  // const image = await this.captureEmbeddedVisual();

  //   // Convert the image to base64 data URL
  //  // const imageData = image.src;
  //  //onst imageData = image;

  //   // Initialize html2pdf
  //   const element = document.createElement('dashboardContainer');
  //   //element.style.backgroundImage = `url(${imageData})`;
  //   const options = {
  //     filename: 'dashboard.pdf',
  //     image: { type: 'jpeg', quality: 0.98 },
  //     html2canvas: { scale: 2 },
  //     jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
  //   };
  //   html2pdf().from(element).set(options).save();
  // }



  // async captureEmbeddedVisual(): Promise<HTMLCanvasElement> {
  //   return new Promise<HTMLCanvasElement>((resolve, reject) => {
  //     const container = document.getElementById('dashboardContainer');
  //     if (!container) {
  //       reject(new Error("Container element not found"));
  //       return;
  //     }
  
  //     // Capture the embedded visual as an image using html2canvas or any other suitable method
  //     html2canvas(container).then(canvas => {
  //       resolve(canvas);
  //     }).catch(error => {
  //       reject(error);
  //     });
  //   });
  // }


  

}







