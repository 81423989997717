import { Component,ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { AuditIdentifier, AuditLogEntities, AuditLogModule, AuditOperations, Dateformat } from 'src/app/shared/constants/global.constants';
import { Router ,ActivatedRoute} from '@angular/router';
import { TabView } from 'primeng/tabview';
import { LoaderService } from 'src/app/Loader/loader.service';

import { EngagementAuditService } from '../../Services/engagement-audit-service.service';
import { } from 'typescript';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { Privileges } from 'src/app/shared/constants/privileges.constants';
import { EngagementService } from 'src/app/Engagement/Services/engagement.service';
import { MasterSupplierService } from 'src/app/MasterData/supplier/Services/master.supplier.service';
import { FileUploadService } from 'src/app/Engagement/Services/fileupload.service';
import { EngagementAudit } from '../../Model/EngagementAudit';
import { AuditLogService } from 'src/app/shared/ModelComparison/audit.log.service';

@Component({
  selector: 'app-eng-view-audit',
  templateUrl: './eng-view-audit.component.html',
  styleUrls: ['./eng-view-audit.component.css']
})
export class EngViewAuditComponent {
  @ViewChild('myTabView', { static: false }) tabView!: TabView;
  @Output() editButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelAuditButtonClick = new EventEmitter<void>();

  @Input() engagementId: any;
  @Input() supplierName: any;
  @Input() auditId: any;


  engagementAuditDetail:any;
  _dateformart :any;

breadcrumbs: MenuItem[]=[];
_enumBreadcrumb :any;

selectedOption: string = 'Actions';
displayFindingListPopup: boolean = false;
displayViewFindingPopup: boolean = false;
position: string = 'center';
editPrivilege:boolean=false;
deletePrivilege:boolean=false;
finding:any;
  createdBy: any;
  updatedBy: any;


constructor(private privilegeService: PrivilegeService,private engagementAuditService: EngagementAuditService,private fileService : FileUploadService, private router:Router,private route: ActivatedRoute,private loaderService: LoaderService, private engagementService: EngagementService,private _mastersupplierService: MasterSupplierService,
  private confirmationService: ConfirmationService,private messageService: MessageService,private auditLogService: AuditLogService,
)
{

}
  ngOnInit() {
    this._dateformart=Dateformat;
   this.GetEngagementDataById(this.engagementId);
    this.loaderService.hidefinalLoader();// hide after 4 seconds even get any error
    this.BindPrivileges();
    this.auditIdentifierDetails=AuditIdentifier; 
    this.auditEntities=AuditLogEntities;
    this.auditOperations=AuditOperations;
    this.auditModule=AuditLogModule;
  }
  BindPrivileges(){
    const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);
    this.editPrivilege = hasPermission(Privileges.EditSupplierAudit);
    this.deletePrivilege=hasPermission(Privileges.DeleteEngagementAudit);

    }
    description:any;
    findingDetail:any;
    engagementResponse:any;
    evidenceOfClosure:any;
    decisionOfReview:any;
  
  getEngagementAuditById(Id: number) {
    this.engagementAuditService.getEngagementAuditById(Id).subscribe(response => {
        this.engagementAuditDetail = response.data;
        this._oldEngagementAudit = response.data;
        this._engagementAudit = this.engagementAuditDetail;
        this.description = this.engagementAuditDetail.description ? this.engagementAuditDetail.description.replace(/\n/g, "<br>") : this.engagementAuditDetail.description;
        this.createdBy = this.privilegeService.getUserName(response.data.createdBy);
        this.updatedBy = this.privilegeService.getUserName(response.data.updatedBy);

        if (this.engagementDetail) {
            const engagementName = this.engagementDetail.engagementName;

            if (this.supplierContactData?.length > 0) {
                this.updateAuditeeName(engagementName);
                this.updateFindingOwnerNames(engagementName);
            }
        }
    });
}

updateAuditeeName(engagementName: string) {
    const auditeeContact = this.filterSupplierContactById(this.engagementAuditDetail.auditeeSPOC);
    if (auditeeContact) {
        this.engagementAuditDetail.auditee = `${auditeeContact.contactName} (${this.supplierName})`;
    }
}

updateFindingOwnerNames(engagementName: string) {
    this.engagementAuditDetail?.auditObservations?.forEach((observation: any) => {
        observation?.engagementAuditObservationFindingList.forEach((finding: any) => {
            const findingOwnerContact = this.filterSupplierContactById(finding.findingOwnerId);
            if (findingOwnerContact) {
                finding.findingOwnerName = `${findingOwnerContact.contactName} (${this.supplierName})`;
            }
        });
    });
}

filterSupplierContactById(id: any) {
    const contact = this.supplierContactData?.filter((contact: { id: any }) => contact.id === id);
    return contact?.length > 0 ? contact[0] : null;
}

  engagementDetail:any;
  GetEngagementDataById(engagementId:number){
    this.engagementService.GetEngagementById(this.engagementId).subscribe(
      res=>{
        this.engagementDetail=res.data;
        this.GetSupplierContacts(res.data.supplierId);
        this.getEngagementAuditById(this.auditId)
        this.loaderService.hideLoader();
      }
    );
    this.loaderService.hideLoader();
  }
  supplierContactData:any;
  GetSupplierContacts(Id:number){
   
    this._mastersupplierService.getSupplierContactsDataById(Id)
    .subscribe(res=>{
      this.supplierContactData = res.data;
    })
  }

onOptionChange(): void {//Click on Actions button navigate to edit  
    if (this.selectedOption === 'Edit') {
      var engagementId= this.engagementId;
      var auditId=this.auditId
      const data = {
        engagementId:this.engagementId,
        auditId:this.auditId
      };
      this.editButtonClick.emit(data);
    }
    if(this.selectedOption === 'Delete' && this.auditId){
      this.confirmationService.confirm({
        message: `Are you sure you want to delete this Audit?`,
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.engagementAuditService.DeleteEngagementAudit(this.auditId).subscribe(res => {
          if(res.data > 0)
          {
            this.messageService.add({
            key: 'tc',
            severity: 'info',
            summary: `Audit is deleted successfully`,
            detail: '',
        });
        this.compareModels();
        this.cancelAuditButtonClick.emit();
          }
      });
           },
           reject: () => {
            this.selectedOption = 'Actions';
           },
         });       
    }    
}
findingListDataSource:any;
selectedObservationId:any;

viewFindingList(index:any){
    this.findingListDataSource =null;
    this.selectedObservationId = "";

    this.findingListDataSource = this.engagementAuditDetail?.auditObservations[index]?.engagementAuditObservationFindingList;
    this.selectedObservationId = this.engagementAuditDetail?.auditObservations[index]?.observationId;
    this.showFindingListPopup();
}

showFindingListPopup() {
  this.displayFindingListPopup = true;
}
cancelFindingListPopup(){
this.displayFindingListPopup = false;
}

findingCreatedBy:any;
findingUpdatedBy:any;
viewFindingDetails(index:any){

      this.finding=null; 
      this.finding = this.findingListDataSource[index];
      
      if(this.finding){
        this.findingDetail = this.finding.findingDetail ? this.finding.findingDetail.replace(/\n/g, "<br>") : this.finding.findingDetail;
        this.engagementResponse = this.finding.engagementResponse ? this.finding.engagementResponse.replace(/\n/g, "<br>") : this.finding.engagementResponse;
        this.evidenceOfClosure = this.finding.evidenceOfClosure ? this.finding.evidenceOfClosure.replace(/\n/g, "<br>") : this.finding.evidenceOfClosure;
        this.decisionOfReview = this.finding.decisionOfReview ? this.finding.decisionOfReview.replace(/\n/g, "<br>") : this.finding.decisionOfReview;
        }
      this.findingCreatedBy=this.privilegeService.getUserName( this.finding.createdBy)
      this.findingUpdatedBy=this.privilegeService.getUserName( this.finding.updatedBy)
      this.showViewFindingPopup();
  }

  showViewFindingPopup() {
    this.displayViewFindingPopup = true;
  }
  
 cancelViewFindingPopup(){
  this.displayViewFindingPopup = false;
}

downloadAttachment(data:any)
{
this.downloadFile(data?.fileName, data?.filePath);

}

async downloadFile(fileName:any,filePath:any){
this.loaderService.showLoader();
var fileDetail = {
  "fileName":fileName,
  "filePath":filePath
};
this.fileService.DownloadFile(fileDetail).subscribe(
  async res=>{
      var blobData = this.fileService.dataURItoBlob(res);
      if(blobData.size>0){
         var response = await this.fileService.downloadBase64File(blobData,fileDetail.fileName);
         this.loaderService.hideLoader();
      }
      else{
        this.loaderService.hideLoader();
        
      }
  } 
);
}
BackToList()
{
  this.cancelAuditButtonClick.emit();
}
_engagementAudit=new EngagementAudit();
changedEngagementAuditBasicInfo:any;
auditIdentifierDetails:any;
auditEntities:any;
auditOperations:any;
auditModule:any;
_oldEngagementAudit: any;
compareModels() {
  this._oldEngagementAudit = JSON.parse(JSON.stringify(this._oldEngagementAudit));
  this._engagementAudit.isDeleted = true;
  this.changedEngagementAuditBasicInfo = this.auditLogService.compareModels((this._oldEngagementAudit),this._engagementAudit,this.auditEntities.EngagementAudits,this.auditIdentifierDetails.DeleteEngagementAudit,this.auditOperations.Delete,this.auditModule.GovernanceService);
}
}
