import { Component,ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ContractKPIService } from '../../Services/ContractKpi.Service';
import { Dateformat,breadcrumnsCodes, CodeList, CodeListStatusTypes, AuditIdentifier, AuditLogEntities, AuditOperations, AuditLogModule } from 'src/app/shared/constants/global.constants';
import { DatePipe } from '@angular/common';
import { Router ,ActivatedRoute} from '@angular/router';
import { TabView } from 'primeng/tabview';
import { } from '@angular/forms';
import { LoaderService } from 'src/app/Loader/loader.service';
import { } from 'src/app/shared/Guards/can-deactivate-guard.service';
import { } from 'rxjs';
import { ContractService } from 'src/app/Contracts/Services/contract.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { Privileges } from 'src/app/shared/constants/privileges.constants';
import { CommonService } from 'src/app/shared/Service/common.service';
import { CurrencyService } from 'src/app/Reusable/currency.service';
import { MasterSupplierService } from 'src/app/MasterData/supplier/Services/master.supplier.service';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';
import { SponsorContact, SupplierContact } from 'src/app/MasterData/Services/supplier-profile';
import { EngagementService } from 'src/app/Engagement/Services/engagement.service';
import { TabAndStateService } from 'src/app/shared/TabAndStateService/TabAndState.Service';
import { ContractKpi } from '../../Model/contractKpi';
import { AuditLogService } from 'src/app/shared/ModelComparison/audit.log.service';

@Component({
  selector: 'app-con-view-kpi',
  templateUrl: './con-view-kpi.component.html',
  styleUrls: ['./con-view-kpi.component.css']
})
export class ConViewKpiComponent {
  @ViewChild('myTabView', { static: false }) tabView!: TabView;
  constructor(  public privilegeService: PrivilegeService,private breadcrumbService: BreadcrumbService, private ContractKpiService: ContractKPIService,private datePipe: DatePipe,private route: ActivatedRoute,private router:Router,private loaderService: LoaderService,private auditLogService: AuditLogService,private tabAndStateService: TabAndStateService,
    private _contractService : ContractService,private commonService:CommonService,private currencyService: CurrencyService,private masterDataService: SponserService,private supplierService: MasterSupplierService,private engagementService: EngagementService,private confirmationService: ConfirmationService,private messageService: MessageService)
    {

    }
    @Output() editKpiButtonClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() cancelKpitButtonClick = new EventEmitter<void>();
    @Input() contractDetailId: any;
    @Input() kpiId: any;
codelist: any[] = [];
_viewContractKPIDetails:any;
_viewContractKPIHyperCareThresholdDetails:any[] =[];
_viewContractKPIteadyStedyStateThresholdDetails:any[] =[];
_matheMathicalData:any[] = [];
_kpiDetails:any;
_dateformart :any;
_kPIDataCollectionGridBindDetails:any;
checkBoxValue: boolean = true;
breadcrumbs: MenuItem[]=[];
_enumBreadcrumb :any;
_contractKPiId: any;
selectedContractName:string="";
ContractId:any;
_contractName:any;
selectedOption: string = 'Actions';
kpiReportDataCollection:any;
editPrivilege:boolean=false;
deletePrivilege:boolean=false;
KpiCreatedUserName:string='';
KpiUpdatedUserName:string='';
createdBy:any;
codeListValues:any;
codeListStatusTypes:any;
listOfKpis:any;
currentIndex: number = -1; 
ngOnInit() {
  this._dateformart=Dateformat;
  this._enumBreadcrumb=breadcrumnsCodes;     
  this._contractKPiId = this.kpiId
  this.ContractId = this.contractDetailId;
  this.GetContractKPIDetailsById(this._contractKPiId);
  this.BindPrivileges();
  this.codeListValues=CodeList;
  this.codeListStatusTypes=CodeListStatusTypes;
  this.auditIdentifierDetails=AuditIdentifier; 
  this.auditEntities=AuditLogEntities;
  this.auditOperations=AuditOperations;
  this.auditModule=AuditLogModule;
  this.GetMitigationStatus(this.codeListValues.Tbl_Status, this.codeListStatusTypes.MASTS);
  this.listOfKpis=this.tabAndStateService.getKpiData();
  this.currentIndex = this.listOfKpis.findIndex((user: { id: any; }) => user.id ==this._contractKPiId);
  setTimeout(() => {
    this.calculateMitigationActionCounts();
  }, 2000);
}
BindPrivileges()
  {
    const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);
    this.editPrivilege =hasPermission(Privileges.EditContractKPI);
    this.deletePrivilege=hasPermission(Privileges.DeleteContractKPI);
  }

  kpiStartFrequency:any;
    kpiEndFrequency:any;
    kpiDescription:any;
    findingsCounts: number[] = [];
GetContractKPIDetailsById(id:any) //Get ContractKPI Details By ContractKPIId
{
  this.loaderService.showLoader();
  // this.ContractKpiService.GetContractKPIDetailsById(id).subscribe((res: { data: string | any[]; }) => {
    this.ContractKpiService.GetContractKPIDetailsByIdForViewPage(id).subscribe((res: { data: string | any[]; }) => {
  if(res.data.length > 0)
    {
      this._viewContractKPIDetails =res.data[0];
     this._oldContractKpi= res.data[0];
     this._contractKpi=this._viewContractKPIDetails;

      this.kpiDescription=this._viewContractKPIDetails.description ? this._viewContractKPIDetails.description.replace(/\n/g, "<br>") : this._viewContractKPIDetails.description;
      const contractMitigationActions = this._viewContractKPIDetails.contractMitigationActions || [];
      this.findingsCounts = [];

      this._viewContractKPIDetails.dataCollectionList.forEach(
        (reportItem: any, index: number) => {
          const selectedKpiDataCollectionId = reportItem.id;
    
          const matchingActions = contractMitigationActions.filter(
            (action: any) => action.dataCollectionId === selectedKpiDataCollectionId
          );
    
          reportItem.findingsCount = matchingActions.length;
          this.findingsCounts[index] = matchingActions.length;
        }
      );

      this.kpiStartFrequency=this.commonService.getFrequencyFromDate(this._viewContractKPIDetails.kpiStartDate,this._viewContractKPIDetails.kpiDataCollectionFrequencyName);
      this.kpiEndFrequency=this.commonService.getFrequencyFromDate(this._viewContractKPIDetails.kpiEndDate,this._viewContractKPIDetails.kpiDataCollectionFrequencyName);


      this. _viewContractKPIHyperCareThresholdDetails = res.data[0].kpiHyperCareThresholdDetail != null? res.data[0].kpiHyperCareThresholdDetail:this. _viewContractKPIHyperCareThresholdDetails;
      this._viewContractKPIteadyStedyStateThresholdDetails = res.data[0].kpiSteadyStateThresholdDetail;
      this._matheMathicalData = res.data[0].matheMaticSymbolsForHyperAndStreadCareThreshold;
      this._kpiDetails = this._viewContractKPIDetails.kpiDetails[0];
      this._kPIDataCollectionGridBindDetails = this._viewContractKPIDetails.dataCollectionList;             
      this.kpiReportDataCollection=res.data[0].reportDataCollectionModelList;
   
    
      
      this.KpiCreatedUserName=this.privilegeService.getUserName(res.data[0].createdBy);
      this.KpiUpdatedUserName=this.privilegeService.getUserName(res.data[0].updatedBy);
      this.HyperCareAndSteadyStateThresholdData();
      this.GetContractDataById(this.ContractId)
      this.SetFrequencyValueforDataCollection();
    }
  });
}

SetFrequencyValueforDataCollection(){
       
  this.kpiReportDataCollection.forEach((item:any) => {
    item.kpiContractDataCollectionModelList.forEach((collectionRow:any) => {
      collectionRow.dataCollectionFrequencyValue=this.commonService.getFrequencyFromDate(collectionRow.dataCollectionDate,this._viewContractKPIDetails.kpiDataCollectionFrequencyName);
    });
   
  });
}

HyperCareAndSteadyStateThresholdData()//Mapping MatheMatical Symbols by with hyperCareSteadyStateThreshold Data
{  
  let hyperCare = 0; 

    this._viewContractKPIHyperCareThresholdDetails.forEach((kPIHyperDetails: any) => {  
    const filteredSymbol = this._matheMathicalData.filter((matheDetails: any) => matheDetails.id === kPIHyperDetails.mathematicalSymbolId);   

    if (filteredSymbol.length > 0) {   
        this._viewContractKPIHyperCareThresholdDetails[hyperCare].mathematicalSymbol = filteredSymbol[0].symbolName.split(' ')[0];
    }    
    hyperCare++;
});
let steadyState = 0; 
this._viewContractKPIteadyStedyStateThresholdDetails.forEach((kPISteadyStateThresholdDetails: any) => {  
  const filteredSymbol = this._matheMathicalData.filter((matheDetails: any) => matheDetails.id === kPISteadyStateThresholdDetails.mathematicalSymbolId);   

  if (filteredSymbol.length > 0) {   
    this._viewContractKPIteadyStedyStateThresholdDetails[steadyState].mathematicalSymbol = filteredSymbol[0].symbolName.split(' ')[0];
  }     
  steadyState++;
});

}
generateBreadcrumb(component?:string,recordName?:string,tabName?:string,childRecName?:string,recordId?:number)
{
 
  this.breadcrumbService.generateBreadcrumb(component,recordName,tabName,childRecName,recordId);
}

getBreadcrumbs(): MenuItem[] {
  return this.breadcrumbService.getBreadcrumbs();
}
GetContractDataById(ContractId:number){
 
  this._contractService.GetContractDtailsById(ContractId).subscribe(
    res=>{
      this._contractName=res.data.title;
      this.GetEngagementDataById(res.data.engagementId);
    }
  );
}
GetEngagementDataById(EngagementId:number){
  this.engagementService.GetEngagementById(EngagementId).subscribe(
    res=>{
      this.getCombinedContacts(res.data.supplierId,res.data.sponsorId);
    }
  );
}

onOptionChange(): void {//Click on Actions button navigate to edit    
    if (this.selectedOption === 'Edit') {
      var ContractId= this.ContractId;
      var kpiId=this.kpiId;
      const data = {
        ContractId:this.ContractId,
        kpiId:this.kpiId
      };
      this.editKpiButtonClick.emit(data);
    }
    if(this.selectedOption === 'Delete' && this.kpiId){
      this.confirmationService.confirm({
        message: `Are you sure you want to delete this KPI?`,
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
        this.ContractKpiService.DeleteContractKpi(this.kpiId).subscribe(res => {
          if(res.data > 0)
          {
            this.messageService.add({
            key: 'tc',
            severity: 'info',
            summary: `KPI is deleted successfully`,
            detail: '',
        });
        this.compareModels();
            this.cancelKpitButtonClick.emit();
          }
      });
           },
           reject: () => {
            this.selectedOption = 'Actions';
           },
         });       
    }
}
BackToList()
{
  this.cancelKpitButtonClick.emit();
}


currencies: any='USD';
  getFormattedValue(value: number, currencyName: string): string {
  let formattedValue = this.currencyService.getFormattedValue(value, currencyName);
 return this.handleCurruncy(formattedValue);
 }
 private handleCurruncy(formattedValue: string): string {
   return formattedValue.replace(/^[^\d]+/, '');
}
findingsCount:any;
displayMitigationListPopup: boolean = false;
displayViewMitigationPopup: boolean = false;
position: string = 'center';
selectedKpiReportData: any;
mitigationActions: any[] = [];
viewFindingList(index: number, kpiReportDataCollection: any) {
 this.selectedKpiReportData=kpiReportDataCollection.kpiContractDataCollectionModelList[index];
  
  const selectedKpiDataCollectionId = this.selectedKpiReportData.id;
  if (this._viewContractKPIDetails.contractMitigationActions) {
    const selectedMitigationActions = this._viewContractKPIDetails.contractMitigationActions.filter(
      (action: any) => action.dataCollectionId === selectedKpiDataCollectionId
    );
    this.mitigationActions = selectedMitigationActions;
  }
  this.displayMitigationListPopup = true;
}
cancelDialog(){
  this.displayMitigationListPopup = false;
}
actionStatus:any;
GetMitigationStatus(enumId: number, type: string) { //Get Meeting Status's from code list for the Meetings
  this.masterDataService.getCodeListData(enumId, type).subscribe(
    res => {      
      if (res.data.length > 0) {
        this.actionStatus = res.data;
      }
    }
  );
};
getStatusName(statusId: number): string {
  const status = this.actionStatus.find((s: any) => s.id === statusId);
  return status ? status.name : '--';
}
supplierContactsDataSource : SupplierContact[]=[];
sponsorContactsDataSource : SponsorContact[]=[];
combinedContacts: any[] = [];
getCombinedContacts(supplierId: number = 0, sponsorId: number = 0) {
  this.supplierService.GetSupplierById(supplierId).subscribe(supplierRes => {
      this.supplierContactsDataSource = supplierRes.data.supplierContacts;
      this.masterDataService.getSponserData(sponsorId).subscribe(sponsorRes => {
        this.sponsorContactsDataSource = sponsorRes.data.sponserContacts.map((contact: { id: any; name: any; countryCode: any; designation: any; phoneNumber: any; email: any; sponserId: any; createdBy: any; createdDate: any; updatedBy: any; updatedDate: any; isDeleted: any; }) => {
          return {
              id: contact.id,
              contactName: contact.name, 
              countryCode: contact.countryCode,
              designation: contact.designation,
              phoneNumber: contact.phoneNumber,
              email: contact.email,
              supplierProfileId: contact.sponserId, 
              createdBy: contact.createdBy,
              createdDate: contact.createdDate,
              updatedBy: contact.updatedBy,
              updatedDate: contact.updatedDate,
              isDeleted: contact.isDeleted
          };
      });
          this.combinedContacts = [...this.supplierContactsDataSource, ...this.sponsorContactsDataSource];
      });
  });
}
getContactName(actionOwnerId: number): string {
  const contact = this.combinedContacts.find((c: any) => c.id === actionOwnerId);
  return contact ? contact.contactName : '--';
}
navigateTo(index: number): void {
  if (index >= 0 && index < this.listOfKpis.length) {
    this._contractKPiId=this.listOfKpis[index].id;
    this.kpiId=this.listOfKpis[index].id;
    this.GetContractKPIDetailsById(this._contractKPiId);
    this.currentIndex=index;
    setTimeout(() => {
      this.calculateMitigationActionCounts();
    }, 1000);
  }
}
nextRecord(): void {
  this.navigateTo(this.currentIndex + 1);
}
previousRecord(): void {
  this.navigateTo(this.currentIndex - 1);
}
getCurrentPositionText(): string {
  return `${this.currentIndex + 1} of ${this.listOfKpis.length}`;
}

mitigationActionCounts:any;
calculateMitigationActionCounts() {
  const actionCount = new Map<number, number>();

  if (this._viewContractKPIDetails?.dataCollectionList) {
    this._viewContractKPIDetails.dataCollectionList.forEach((dataCollection: { id: number }) => {
      const mitigationActions = this._viewContractKPIDetails.contractMitigationActions?.filter(
        (action: { dataCollectionId: number }) => action.dataCollectionId === dataCollection.id
      ) || [];
      actionCount.set(dataCollection.id, mitigationActions.length);
    });
  }

  this.mitigationActionCounts = actionCount;
  this.loaderService.hideLoader();
  this.loaderService.hidefinalLoader();
}

getMitigationActionCount(dataCollectionId: number): number {
  return this.mitigationActionCounts.get(dataCollectionId) || 0;
}
_contractKpi=new ContractKpi();
changedContractKPIBasicInfo:any;
auditIdentifierDetails:any;
auditEntities:any;
auditOperations:any;
auditModule:any;
_oldContractKpi: any;
compareModels() {
  this._oldContractKpi = JSON.parse(JSON.stringify(this._oldContractKpi));
  this._contractKpi.isDeleted = true;
  this.changedContractKPIBasicInfo = this.auditLogService.compareModels((this._oldContractKpi),this._contractKpi,this.auditEntities.ContractKPIs,this.auditIdentifierDetails.DeleteContractKPI,this.auditOperations.Delete,this.auditModule.GovernanceService);
}
}
