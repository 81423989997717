import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GovernanceService } from '../../Services/governance.service';
import { LoaderService } from 'src/app/Loader/loader.service';
import { AuditIdentifier, AuditLogEntities, AuditLogModule, AuditOperations, Dateformat, breadcrumnsCodes } from 'src/app/shared/constants/global.constants';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { Privileges } from 'src/app/shared/constants/privileges.constants';
import { MasterSupplierService } from 'src/app/MasterData/supplier/Services/master.supplier.service';
import { EngagementService } from 'src/app/Engagement/Services/engagement.service';
import { FileUploadService } from 'src/app/Engagement/Services/fileupload.service';
import { EngagementMeetingDetails } from '../../Modals/engagementmeeting';
import { AuditLogService } from 'src/app/shared/ModelComparison/audit.log.service';

@Component({
  selector: 'app-eng-view-meeting',
  templateUrl: './eng-view-meeting.component.html',
  styleUrls: ['./eng-view-meeting.component.css']
})
export class EngViewMeetingComponent {
    @Output() editMeetingButtonClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() cancelMeetingButtonClick = new EventEmitter<void>();
    @Input() engagementId: any;
    @Input() meetingId: any;
    @Input() supplierName: any;
    engagementMeetingDetail:any;
    isRecurringMeeting:boolean=false;
    _dateformart :any;
    engagementMeetingId:number=0;
  
  breadcrumbs: MenuItem[]=[];
  _enumBreadcrumb :any;

  selectedOption: string = 'Actions';
  displayOccurrencePopup: boolean = false;
  position: string = 'center';
  attendeesVisible:boolean  = false;
  absenteesVisible:boolean  = false;
  occurrenceDetail:any;
  showAttendeesButton : boolean= false;
  showAbsenteesButton : boolean= false;
  buttonLabel:any;
  editPrivilege:boolean=false;
  deletePrivilege:boolean=false;
  MeetingCreatedByUserName:string='';
MeetingUpdatedByUserName:string='';
  constructor(private privilegeService: PrivilegeService,private governanceService: GovernanceService,
    private fileService : FileUploadService, 
    private router:Router,private route: ActivatedRoute, private loaderService: LoaderService,  private breadcrumbService: BreadcrumbService,  private _mastersupplierService: MasterSupplierService,private engagementService: EngagementService,
    private confirmationService: ConfirmationService,private messageService: MessageService,  private auditLogService: AuditLogService
  )
  {

  }
    ngOnInit() {
      this._dateformart=Dateformat;
      this._enumBreadcrumb=breadcrumnsCodes;
      this.engagementMeetingId =this.meetingId; 
      this.loaderService.showLoader();
      this.getEngagementMeetingsById(this.engagementMeetingId)
      this.loaderService.hidefinalLoader();// hide after 4 seconds even get any error
      this.BindPrivileges();
      this.auditIdentifierDetails=AuditIdentifier; 
      this.auditEntities=AuditLogEntities;
      this.auditOperations=AuditOperations;
      this.auditModule=AuditLogModule;
    }
     
    BindPrivileges(){
      const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);
     this.editPrivilege =hasPermission(Privileges.EditSupplierMeeting);
     this.deletePrivilege=hasPermission(Privileges.DeleteEngagementMeeting);

      }
    GetEngagementDataById(EngagementId:number){
      this.engagementService.GetEngagementById(EngagementId).subscribe(
        res=>{
          this.loaderService.hideLoader();
          var EngagementName=res.data.engagementName;
          this.generateBreadcrumb(this._enumBreadcrumb.EVMET,EngagementName,"Governacne","",EngagementId,);
        }
      );
    }
    agenda:any;
    meetingNotes:any;
    decision:any;
    remarks:any;
    occurrenceAction: any;  
    formattedActionName: any;

getEngagementMeetingsById(engagementMeetingId: any) {
  this.governanceService.getEngagementMeetingsById(engagementMeetingId).subscribe(
    response => {
      this.engagementMeetingDetail = response.data;
      this._oldEngagementMeeting = response.data;
      this._engagementMeeting = this.engagementMeetingDetail;
      this.agenda = response.data.agenda ? response.data.agenda.replace(/\n/g, "<br>") : response.data.agenda;
      this.MeetingCreatedByUserName = this.privilegeService.getUserName(response.data.createdBy);
      this.MeetingUpdatedByUserName = this.privilegeService.getUserName(response.data.updatedBy);

      // Initialize arrays to store meeting notes, decisions, and actions
      this.meetingNotes = [];
      this.decision = [];
      this.occurrenceAction = [];

      this.engagementMeetingDetail.engagementMeetingOccurrences.forEach((occurrence: any) => {
        occurrence.createdBy = this.privilegeService.getUserName(occurrence.createdBy);
        occurrence.updatedBy = this.privilegeService.getUserName(occurrence.updatedBy);

      
      });

      this.isRecurringMeeting = this.engagementMeetingDetail?.recurring;

      // Fetch additional engagement data if needed
      this.GetEngagementDataById(this.engagementMeetingDetail.engagementId);
    }
  );
}

  generateBreadcrumb(component?:string,recordName?:string,tabName?:string,childRecName?:string,recordId?:number)
  {
    this.breadcrumbService.generateBreadcrumb(component,recordName,tabName,childRecName,recordId);
  }
  
  getBreadcrumbs(): MenuItem[] {
    return this.breadcrumbService.getBreadcrumbs();
  }

  onOptionChange(): void {
    var engagementId= this.engagementId;
    var meetingId=this.meetingId;//Click on Actions button navigate to edit    
      if (this.selectedOption === 'Edit') {
        if(this.isRecurringMeeting){
        
          const data = {
            isRecurring:true,
            engagementId:this.engagementId,
            meetingId:this.meetingId
          };
          this.editMeetingButtonClick.emit(data);
        }
        else{
         
          const data = {
            isRecurring:false,
            engagementId:this.engagementId,
            meetingId:this.meetingId
          };
          this.editMeetingButtonClick.emit(data);
        } 
      }
      if(this.selectedOption === 'Delete' && this.meetingId){
        this.confirmationService.confirm({
          message: `Are you sure you want to delete this Meeting?`,
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.governanceService.DeleteEngagementMeeting(this.meetingId,0).subscribe(res => {
            if(res.data > 0)
            {
              this.messageService.add({
              key: 'tc',
              severity: 'info',
              summary: `Meeting is deleted successfully`,
              detail: '',
          });
          this.compareModels();
          this.cancelMeetingButtonClick.emit();
            }
        });
             },
             reject: () => {
              this.selectedOption = 'Actions';
             },
           });       
      }   
       
  }

viewOccurrence(index:any){
  this.occurrenceDetail=null;
  this.occurrenceDetail = this.engagementMeetingDetail?.engagementMeetingOccurrences[index]; 
  this.meetingNotes=(this.occurrenceDetail.meetingNotes ? this.occurrenceDetail.meetingNotes.replace(/\n/g, "<br>") : '--');
  this.decision=(this.occurrenceDetail.decisions ? this.occurrenceDetail.decisions.replace(/\n/g, "<br>") : '--');
  this.showOccurrencePopup();
}
showOccurrencePopup() {
    this.displayOccurrencePopup = true;
}

cancelDialog(){
  this.displayOccurrencePopup = false;
}

downloadAttachment(data:any)
{
  this.downloadFile(data?.fileName, data?.filePath);

}
async downloadFile(fileName:any,filePath:any){
  this.loaderService.showLoader();
 var fileDetail = {
    "fileName":fileName,
    "filePath":filePath
 };
 this.fileService.DownloadFile(fileDetail).subscribe(
    async res=>{
        var blobData = this.fileService.dataURItoBlob(res);
        if(blobData.size>0){
           var response = await this.fileService.downloadBase64File(blobData,fileDetail.fileName);
           this.loaderService.hideLoader();
        }
        else{
          this.loaderService.hideLoader();
          
        }
    } 
 );
}
showAttendeesDialog()
{
  this.attendeesVisible = !this.attendeesVisible
}

showAbsenteesDialog()
{
  this.absenteesVisible = !this.absenteesVisible
}
BackToList()
{
  this.cancelMeetingButtonClick.emit();
}
_engagementMeeting=new EngagementMeetingDetails();
changedEngagementMeetingBasicInfo:any;
auditIdentifierDetails:any;
auditEntities:any;
auditOperations:any;
auditModule:any;
_oldEngagementMeeting: any;
compareModels() {
  this._oldEngagementMeeting = JSON.parse(JSON.stringify(this._oldEngagementMeeting));
  this._oldEngagementMeeting.IsDeleted=false;
  this._engagementMeeting.IsDeleted = true;
  this.changedEngagementMeetingBasicInfo = this.auditLogService.compareModels((this._oldEngagementMeeting),this._engagementMeeting,this.auditEntities.EngagementMeetings,this.auditIdentifierDetails.DeleteEngagementMeeting,this.auditOperations.Delete,this.auditModule.GovernanceService);
}
}
