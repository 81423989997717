import { Component, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Dateformat, breadcrumnsCodes,AuditIdentifier, AuditLogEntities, AuditOperations, AuditLogModule } from 'src/app/shared/constants/global.constants';
import { LoaderService } from 'src/app/Loader/loader.service';
import { DatePipe } from '@angular/common';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api'
import { CodeList, UserRoles } from '../../Models/user';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {  } from 'date-fns/locale';
import { UserService } from '../../Services/user.service';
import { Observable } from 'rxjs';
import { IDeactivateGuard } from 'src/app/shared/Guards/can-deactivate-guard.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { AuthCommonService } from 'src/app/shared/Authentication/auth.service';
import { TabAndStateService } from 'src/app/shared/TabAndStateService/TabAndState.Service';
import { AuditLogService } from 'src/app/shared/ModelComparison/audit.log.service';


@Component({
  selector: 'app-edit-user-role-mapping',
  templateUrl: './edit-user-role-mapping.component.html',
  styleUrls: ['./edit-user-role-mapping.component.css']
})
export class EditUserRoleMappingComponent implements IDeactivateGuard {
  user:any;
  groupType: any;
  group: any;
  roles: any;
  userId:number=0;
  _enumBreadcrumb: any;
  _dateformart :any;
  selectedOption: string = 'Actions';
  GroupTypesDataSource: CodeList[] | undefined;
  GroupDataSource: CodeList[] | undefined;
  RolesDataSource: CodeList[] | undefined;
  SponsorDataSource: CodeList[] | undefined;
  supplierDataSource: CodeList[] | undefined;
  defaultDataSource: CodeList[] | undefined;
  superAdminDataSource: CodeList[] | undefined;
  RolesFilterDataSource: CodeList[] | undefined;
  _userRoles=new UserRoles();
  userRole: FormGroup;
  submitted = false;
  _oldUserRolesData:any;
  executeCanExist: boolean = true;
  updatedByUserName:string='';
  currentLoginEmailId:any;
  editLoginEmailId:any
  currentIndex: number = -1; 
  listUserData:any;
  oldUserDataModel:any;
  constructor(private _userService:UserService,
    private _loaderService: LoaderService, 
    private _router:Router,
    private _datePipe: DatePipe,
    private breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private privilegeService:PrivilegeService ,
    private authCommonService: AuthCommonService,
    private tabAndStateService: TabAndStateService,
    private auditLogService: AuditLogService,) {

      this.userRole = this.fb.group({
        _firstName:[''],
        _lastName:[''],
        _emailId:[''],
        _userName:[''],
        _groupType:['', [Validators.required]],
        _group:['', [Validators.required]],
        _roles: ['', [Validators.required]],
        _remarks:['',[Validators.maxLength(300)]],
      });

  }
 
  auditIdentifierDetails:any;
  auditEntities:any;
  auditOperations:any;
  auditModule:any;
   ngOnInit() {
    this.defaultDataSource = [
      {
        id: 0,
        name: 'Default',
        code: 'DFT',
        type: 0,
        relationId: 0
      },
    ];
     this.superAdminDataSource = [
      {
        id: -1,
        name: 'Freyr',
        code: 'FYR',
        type: 0,
        relationId: 0
      },
    ];
    let userData=this.privilegeService.getUserData();
    this.currentLoginEmailId=userData.emailId;
    this._dateformart=Dateformat;
    this._enumBreadcrumb = breadcrumnsCodes;
    this.generateBreadcrumb();
    this.route.params.subscribe(params => {
      this.userId = params['id']; //log the value of id
      
    });
    this.GetAllSponsors(3);
    this.GetAllSuppliers(4)
    this.GetAllGroupTypes(1);
    this.GetAllRoles(2);
    this.GetUserEditData(this.userId);
    this.listUserData=this.tabAndStateService.getPaginationDataSource();
      this.currentIndex = this.listUserData.findIndex((user: { id: any; }) => user.id ==this.userId);
      if(this.listUserData.length==0)
        {
          this._router.navigate(['/UserRoleMapping']);
        }
        this.auditIdentifierDetails=AuditIdentifier; 
        this.auditEntities=AuditLogEntities;
        this.auditOperations=AuditOperations;
        this.auditModule=AuditLogModule;
   }
formOldUserRoles()
{
  this._oldUserRolesData=JSON.stringify(this._userRoles);
}
   CancelRolePrivilege()
   {
    this.executeCanExist=false;
    if ((this._oldUserRolesData!=JSON.stringify(this._userRoles)))
    {
        this.confirmationService.confirm({
          message: 'Changes will not be saved, Do you want to Continue?',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.messageService.add({
              key: 'tc',
              severity: 'info',
              summary: 'Changes not saved',
              detail: '',
          });
          this._router.navigate(['/ViewUserRolesMapping',this.userId])
          },
          reject: () => {
          },
        });
    }
    else{
      this._router.navigate(['/ViewUserRolesMapping',this.userId])
    }
   }
   ViewlRolePrivilege()
   {
     this._router.navigate(['/ViewUserRolesMapping',this.userId])
   }
   
   GetUserEditData(id:number){
    this._loaderService.showLoader();
    this._userService.GetUserEidtDetailsById(id).subscribe((res:any)=>{
      if(res.status){
     
      this._userRoles=res.data;
      this.updatedByUserName=this.privilegeService.getUserName(res.data.updatedBy);
      this.editLoginEmailId=this._userRoles.emailId;
      setTimeout(() => {
        this.OnChangeGroupType(this._userRoles.groupType)
        this.formOldUserRoles();
        this._loaderService.hideLoader();
        this.oldUserDataModel=JSON.stringify(res.data);
      }, 2000);
      }
    });
  }
  generateBreadcrumb() {
    this.breadcrumbService.generateBreadcrumb(this._enumBreadcrumb.UU);
  }
  getBreadcrumbs(): MenuItem[] {
    return this.breadcrumbService.getBreadcrumbs();
  }
  GetAllGroupTypes(type:number) {
    this._userService.GetUserCodeList(type).subscribe(
      res=>{
        this.GroupTypesDataSource=res.data;
      }
    );
  };
  GetAllRoles(type:number) {
    this._userService.GetUserCodeList(type).subscribe(
      res=>{
        this.RolesDataSource=res.data;
      }
    );
  };
  GetAllSponsors(type:number) {
    this._userService.GetUserCodeList(type).subscribe(
      res=>{
        this.SponsorDataSource=res.data;
      }
    );
  };

  GetAllSuppliers(type:number) {
    this._userService.GetUserCodeList(type).subscribe(
      res=>{
        this.supplierDataSource=res.data;
      }
    );
  };
  IsDisableGroupType:boolean=true;
  OnChangeGroupType(data:any){
    let selectedgroupType =data.value;
    if(selectedgroupType==undefined)
    {
      selectedgroupType=data;
    }else{
      this._userRoles.roles=[];
      this._userRoles.group=[];
    }
  
    this.RolesFilterDataSource=this.RolesDataSource?.filter(t=>t.relationId==selectedgroupType);
    let filterData = this.GroupTypesDataSource?.filter(t=>t.id==selectedgroupType);
    if(filterData!=null)
    {
      let code=filterData[0].code;
      switch (code) {
        case 'SPO':
          this.GroupDataSource=this.SponsorDataSource;
          break;
        case 'SUP':
          this.GroupDataSource=this.supplierDataSource;
          break;
        case 'DFT':
          this.GroupDataSource=this.defaultDataSource;
          this.IsDisableGroupType=false;
          break;
        case 'FYR':
          this.GroupDataSource=this.superAdminDataSource;
          break;
      }
     }
   }
   isSaveBtnDisable = false;
   UpdateUserRoles()
   {
    this.submitted = true;
    this.isSaveBtnDisable = true;
    setTimeout(() => {
      this.isSaveBtnDisable = false;
    }, 3000);
    if(this.userRole.valid)
    {
      this.executeCanExist = false;
      this._userRoles.createdBy=this.privilegeService.getLoginUserId();
      this._userRoles.updatedBy=this.privilegeService.getLoginUserId();;
      this._loaderService.showLoader();
      this._loaderService.hidefinalLoader();
      this._userService.UpdateUserRoles(this._userRoles).subscribe(
        res => {
          if(res.status){
         
            this._loaderService.hideLoader();
            this.messageService.add({ key: 'tc', severity: 'success', summary: 'Changes have been saved', detail: '' });
            this._router.navigate(['/ViewUserRolesMapping', this.userId]);
            let auditResult = this.auditLogService.compareModels(JSON.parse(this.oldUserDataModel),this._userRoles,this.auditEntities.UpdateUser,this.auditIdentifierDetails.UpdateUser,this.auditOperations.Update,this.auditModule.AdministratorService);
            console.log(auditResult)
          }
        }
      );
    }
    else{
     this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Please enter required information', detail: '' });

    }

   }
   onUpdateUserRoles(){
    if(this.currentLoginEmailId!=this.editLoginEmailId || this._oldUserRolesData==JSON.stringify(this._userRoles))
    {
    this.UpdateUserRoles();
    }
    else
    {
      this.confirmationService.confirm({
        message: 'You will be logged out so that changes to your account and role can be applied. Please log in again.<br><br> Do you want to Continue?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
        this.UpdateUserRoles();
        setTimeout(() => {
          this.authCommonService.logOutUser()
        }, 3000);
      
        },
        reject: () => {
         
        },
      });
    }
    }
    canExist(): boolean | Promise<boolean> | Observable<boolean> {
      if (this.executeCanExist && (this._oldUserRolesData!=JSON.stringify(this._userRoles)) && this.listUserData.length!=0 )
      {
        return new Promise<boolean>((resolve) => {
          this.confirmationService.confirm({
            message: 'Changes will not be saved, Do you want to Continue?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              this.messageService.add({
                key: 'tc',
                severity: 'info',
                summary: 'Changes not saved',
                detail: '',
            });
              resolve(true); 
            },
            reject: () => {
              resolve(false); 
            },
          });
        });
      }
      else{
        return true;
      }
    }

    navigateTo(index: number): void {
      if (index >= 0 && index < this.listUserData.length) {
        this.GetUserEditData(this.listUserData[index].id);
        this.currentIndex=index;
        this.DisableNxtPreButton();
      }
    }
    nextRecord(): void {
      this.navigateTo(this.currentIndex + 1);
    }
    previousRecord(): void {
      this.navigateTo(this.currentIndex - 1);
    }
    getCurrentPositionText(): string {
      return `${this.currentIndex + 1} of ${this.listUserData.length}`;
    }
    pageChangeConfirmation(buttonType:number):void{
      if(this._oldUserRolesData==JSON.stringify(this._userRoles))
      {
        buttonType==1?this.nextRecord():this.previousRecord();
      }
      else{
        this.confirmationService.confirm({
          message: 'Changes will not be saved, Do you want to Continue?',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.messageService.add({
              key: 'tc',
              severity: 'info',
              summary: 'Changes not saved',
              detail: '',
          });
           buttonType==1?this.nextRecord():this.previousRecord();
          },
          reject: () => {
        
          },
        });
      }
    }
    buttonDisabled:boolean = false;
    DisableNxtPreButton()
    {
      this.buttonDisabled = true;
      setTimeout(() => {
        this.buttonDisabled = false;
      },2500); 
    }
}
