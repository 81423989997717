import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthCommonService } from 'src/app/shared/Authentication/auth.service';
import { environment } from 'src/environments/environment';
import { ContractGroupModel, EngagementPostModel, EngagementSponsorContact, EngagementSupplierContact } from '../Models/Engagement';

@Injectable({
  providedIn: 'root'
})
export class EngagementService 
{
readonly _engagementApiBaseURl: any | undefined;
constructor(private http: HttpClient,private authCommonService: AuthCommonService)
{
  this._engagementApiBaseURl = environment._engagementApiBaseURl;
}
httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };

GetEngagementList(): Observable<any>
{
  return this.http.get<any>(this._engagementApiBaseURl + '/Engagement/GetAllEngagements', this.httpOptions);
}
GetEngagementsByGroupTypes(groupIds:any): Observable<any>
{
  return this.http.post<any>(this._engagementApiBaseURl + '/Engagement/GetEngagementsByGroupTypes',groupIds, this.httpOptions);
}

AddEngagement(engagementPostModel: EngagementPostModel): Observable<any>
{

  return this.http.post<any>(this._engagementApiBaseURl + '/Engagement/AddEngagement', engagementPostModel, this.httpOptions);
}
UpdateEngagement(engagementPutModel: EngagementPostModel): Observable<any>
{

  return this.http.put<any>(this._engagementApiBaseURl + '/Engagement/UpdateEngagement', engagementPutModel, this.httpOptions);
}


ViewEngagementById(engagementId: number): Observable<any>
{
  return this.http.get<any>(this._engagementApiBaseURl + '/Engagement/GetEngagementViewDataById?engagementId=' + engagementId, this.httpOptions);
}
checkExist(engagementId?:any,id?:number,sponsorId?:number,supplierId?:number): Observable<any> {
  return this.http.get<any>(this._engagementApiBaseURl +  '/Engagement/CheckExist?engagementId=' + engagementId + '&id=' + id+ '&sponsorId=' + sponsorId+ '&supplierId=' + supplierId, this.httpOptions);
}

AddContractGroup(contractGroupModel: ContractGroupModel): Observable<any>
{

  return this.http.post<any>(this._engagementApiBaseURl + '/Engagement/AddContractGroup', contractGroupModel, this.httpOptions);
}

GetContractByEngagementId(engagementId:number): Observable<any>
{
  return this.http.get<any>(this._engagementApiBaseURl + '/Engagement/GetContractByEngagementId?EngagementId='+engagementId, this.httpOptions);
}



GetEngagementById(roleId: number): Observable<any>
{
  return this.http.get<any>(this._engagementApiBaseURl + '/Engagement/GetEngagementById?engagementId=' + roleId, this.httpOptions);
}

getAllEngagementsBySupplierId(supplierId: number): Observable<any> {
   
  const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
  return this.http.get<any>(this._engagementApiBaseURl +  '/Engagement/GetAllEngagementBySupplierId?supplierId=' + supplierId, httpOptions);
}

GetAllEngagementContractGroupsById(engagementId: number): Observable<any> {
   
  const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
  return this.http.get<any>(this._engagementApiBaseURl +  '/Engagement/GetAllEngagementContractGroupsById?engagementId=' + engagementId, httpOptions);
}

getAllEngagementsBySponsorId(sponsorId: number): Observable<any> {
   
  const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
  return this.http.get<any>(this._engagementApiBaseURl +  '/Engagement/GetAllEngagementBySponsorId?sponsorId=' + sponsorId, httpOptions);
}

AddEngagementSupplierContact(engagementSupplierContact: EngagementSupplierContact): Observable<any>
{

  return this.http.post<any>(this._engagementApiBaseURl + '/Engagement/AddEngagemenSupplierContact', engagementSupplierContact, this.httpOptions);
}
AddEngagementSponsorContact(engagementSponsorContact: EngagementSponsorContact): Observable<any>
{

  return this.http.post<any>(this._engagementApiBaseURl + '/Engagement/AddEngagemenSponsorContact', engagementSponsorContact, this.httpOptions);
}
GetContractGroupList(): Observable<any>
{
  return this.http.get<any>(this._engagementApiBaseURl + '/Engagement/GetAllContractGroups', this.httpOptions);
}
}
