import { Component,ViewChild,OnInit, Input ,Output, EventEmitter } from '@angular/core';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Dateformat , FileExtension, AuditIdentifier, AuditLogEntities, AuditOperations, AuditLogModule} from 'src/app/shared/constants/global.constants';
import { DatePipe, formatDate } from '@angular/common';
import { Router ,ActivatedRoute} from '@angular/router';
import { TabView } from 'primeng/tabview';
import { AbstractControl, FormBuilder, FormGroup, Validators,NgForm } from '@angular/forms';
import { LoaderService } from 'src/app/Loader/loader.service';
import {  } from 'src/app/shared/Guards/can-deactivate-guard.service';
import { } from 'rxjs';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';
import {  } from 'src/app/Governance/kpis/Engagement/Services/engagement-kpi.service';
import { CodeListValues, EngagementAudit, EngagementAuditAttachments, EngagementAuditObsvFinding, EngagementAuditObsvFindingAttachments } from '../../Model/EngagementAudit';

import { EngagementAuditService } from '../../Services/engagement-audit-service.service';
import { AuditLogService } from 'src/app/shared/ModelComparison/audit.log.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { EngagementService } from 'src/app/Engagement/Services/engagement.service';
import { MasterSupplierService } from 'src/app/MasterData/supplier/Services/master.supplier.service';
import { FileUploadService } from 'src/app/Engagement/Services/fileupload.service';


@Component({
  selector: 'app-eng-create-audit',
  templateUrl: './eng-create-audit.component.html',
  styleUrls: ['./eng-create-audit.component.css']
})
export class EngCreateAuditComponent implements  OnInit{

  firstLevelPopupVisible: boolean = false;
  secondLevelPopupVisible: boolean = false;
  thirdLevelPopupVisible: boolean = false;

  

  openSecondLevelPopup() {
    this.firstLevelPopupVisible = false; // Close the first popup
    this.secondLevelPopupVisible = true;
    this.isUpdatedFlag=false;
    this.header='Add Finding';
    this.isStatusClosedForSupplier=false;
    this.isStatusClosedForDecision=false;
    this.engagementAuditObsvFindingData=new EngagementAuditObsvFinding();
   
    this.findingSubmitFlag=false;
    this.engagementFindingForm.reset();
    this.engagementAuditObsvFindingAttachments=[];
    this.uploadedFindingFileData=[];
    this.editFindingIndex=-1;
    this.oldFindingModel=JSON.stringify(this.engagementAuditObsvFindingData);
  }

  openThirdLevelPopup() {
    this.secondLevelPopupVisible = false; // Close the second popup
    this.thirdLevelPopupVisible = true;
  }
  visible: boolean = false;

  showDialog() {
      this.visible = true;
  }
  date: Date | undefined;
  value: string | undefined;
  @ViewChild('myEngagementAuditForm') childForm: NgForm | undefined;
  @ViewChild('myTabView', { static: false }) tabView!: TabView;
  @Output() cancelAuditButtonClick = new EventEmitter<void>();
  @Output() saveAuditButtonClick = new EventEmitter<void>();
  @Output() viewButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() engagementId: any;
  @Input() supplierName: any;
  executeCanExist: boolean = true;
  breadcrumbs: MenuItem[]=[];
  _enumBreadcrumb :any;
  _dateformart :any;
  auditStatusDataSource: CodeListValues[] | undefined;
  auditAuditeeSPOCDataSource:any[]=[];
  observationStatusDataSource: CodeListValues[] | undefined;
  observationGradesDataSource: CodeListValues[] | undefined;
  
  myEngagementAuditForm: FormGroup;
  supplierData: any;
  supplierContactData: any;
  _engagementAudit=new EngagementAudit();
  _engagementAuditAttachment = new EngagementAuditAttachments();
  _lstEngagementAuditAttachments: EngagementAuditAttachments[] = [];
  submitted = false;
  uploadedFiles: File[] = [];
  maxFileSize: any
  timeStamp: any;
  formData = new FormData();
  uploadedAuditFile: any;
  newAttachment: any;
  uploadedFileName: any;
  allowedExtensions: any;
  currentFileSize: any;
  multiAuditFileData: any[] = [];
  auditMinEndDate:any;
  auditMaxStartDate:any;
  isUploadButtonDisabled:boolean = false;
  isAddFindingFileUploadButtonDisabled:boolean = false;
  


  //--Finding Variables-----------
  engagementFindingForm: FormGroup;
  // supplierAuditObsvFindingData=new SupplierAuditObsvFinding();
  // supplierFindingAttachmentItem = new SupplierAuditObsvFindingAttachments();
  // supplierAuditObsvFindingAttachments: SupplierAuditObsvFindingAttachments[] = [];
  // findingListDataSource:SupplierAuditObsvFinding[] = [];;
  // findingItem=new SupplierAuditObsvFinding();
  engagementAuditObsvFindingData=new EngagementAuditObsvFinding();
  engagementFindingAttachmentItem = new EngagementAuditObsvFindingAttachments();
  engagementAuditObsvFindingAttachments: EngagementAuditObsvFindingAttachments[] = [];
  findingListDataSource:EngagementAuditObsvFinding[] = [];;
  findingItem=new EngagementAuditObsvFinding();
findingSubmitFlag=false;
isStatusClosedForSupplier=false;
isStatusClosedForDecision=false;
todayDate:any;
uploadedFindingFile: any;
uploadedFindingFileData: any[] = [];
findingFormData = new FormData();
multiFindingFileData: any[] = [];
header:string='Add Finding';
oldFindingModel:any;
  _oldSupplierAudit:any;
  //observation grid
  _auditObservations: any[] = [];
  _addObservationFilesMandatory: boolean = false;
  isSaveBtnDisable:boolean=false;
  constructor(private router: Router, private fb: FormBuilder,
    
     private confirmationService: ConfirmationService, 
     private messageService: MessageService,
      private loaderService: LoaderService,
       private breadcrumbService: BreadcrumbService,private masterDataService: SponserService,
      private _engagementAuditService: EngagementAuditService,
      private activeRouter: ActivatedRoute,private _fileService: FileUploadService,private datePipe: DatePipe, private auditLogService: AuditLogService,private privilegeService: PrivilegeService,
      private engagementService: EngagementService,   private _mastersupplierService: MasterSupplierService){
   
const maxLength300 = Validators.maxLength(300);
const maxLength50 = Validators.maxLength(50);
const maxLength15 = Validators.maxLength(15);
const requiredValidator = Validators.required;
const noSpacesValidator = this.notOnlySpacesValidator;
const noWhitespacePattern = Validators.pattern(/^\S*$/);
const alphabetOnlyPattern = Validators.pattern(/^[a-zA-Z\s]{1,50}$/);

// Engagement Audit Form
this.myEngagementAuditForm = this.fb.group({
  _auditTitle: ['', [requiredValidator, maxLength50, noSpacesValidator]],
  _auditNumber: ['', [requiredValidator, maxLength15, noSpacesValidator, noWhitespacePattern]],
  _auditor: ['', [requiredValidator, alphabetOnlyPattern, noSpacesValidator]],
  _auditeeSPOC: ['', [requiredValidator]],
  _auditStartDate: ['', [requiredValidator]],
  _auditEndDate: ['', [requiredValidator]],
  _status: ['', [requiredValidator]],
  _description: ['', [maxLength300, noSpacesValidator]],	
  _auditFile: [''], 
  auditFileName: '',
});

// Engagement Finding Form
this.engagementFindingForm = this.fb.group({
  _findingId: ['', [requiredValidator, maxLength15, noSpacesValidator]],
  _findingAreaId: ['', [requiredValidator]],
  _findingDetail: ['', [requiredValidator, maxLength300]],
  _findingOwnerId: ['', [requiredValidator]],
  _targetDate: ['', [requiredValidator]],
  _supplierResponse: ['', [maxLength300]],
  _evidenceOfClosure: ['', [maxLength300]],
  _decisionOfReview: ['', [maxLength300]],	
  _statusMappingId: ['', [requiredValidator]],
  _findingFile: [''], 
  findingFileName: '',
  _findingAttachments: [''],
});
  }; 
  auditIdentifierDetails:any;
  auditEntities:any;
  auditOperations:any;
  auditModule:any;

  ngOnInit() {  
   
     this.loaderService.showLoader();
     this.loaderService.hidefinalLoader();
    this._dateformart=Dateformat;
    this.auditIdentifierDetails=AuditIdentifier; 
    this.auditEntities=AuditLogEntities;
    this.auditOperations=AuditOperations;
    this.auditModule=AuditLogModule;

    this.GetAllAuditStatus(3);
    this.GetAllObservationsStatus(3);
    this.GetAllAuditeeSPOCs();

    this.GetAllObservationGrades(14);

    this.formOldSupplierAudit();

    this.todayDate = new Date();
    this.GetFindingArea();
    this.GetFindingStatusStatus(3);
    this.oldSupplierAuditData=JSON.stringify(this._engagementAudit);
}


GetAllAuditStatus(type:number) {
  this.masterDataService.getCodeListData(type,'Audit Status').subscribe(
    res=>{
      this.auditStatusDataSource=res.data;
      this.loaderService.hideLoader();
    }
  );
};
GetAllObservationsStatus(type:number) {
  this.masterDataService.getCodeListData(type,'Observation Status').subscribe(
    res=>{
      this.observationStatusDataSource=res.data;
    }
  );
};
engagementData:any;
GetAllAuditeeSPOCs() {
  this.engagementService.GetEngagementById(this.engagementId)
    .subscribe(res => {  
      this.engagementData = res.data;  
      this.GetSupplierContacts(res.data.supplierId);
    
    });
}

GetSupplierContacts(Id:number){
 
  this._mastersupplierService.getSupplierContactsDataById(Id)
  .subscribe(res=>{
    this.supplierContactData = res.data;
    if (this.engagementData != undefined &&  this.supplierContactData != undefined) {
      this.auditAuditeeSPOCDataSource = this.supplierContactData.map((obj: {contactName: any; id: any; }) => ({ name: `${obj.contactName} ( ${this.supplierName} )`,id:obj.id }));
    }
  })
}

GetAllObservationGrades(type:number) {
  this.masterDataService.getCodeListData(type,'').subscribe(
    res=>{
      this.observationGradesDataSource=res.data;
    }
  );
};

resetForm() {
  this._engagementAudit=new EngagementAudit();
}

generateBreadcrumb(component?:string,recordName?:string,tabName?:string,childRecName?:string,recordId?:number)
{
  this.breadcrumbService.generateBreadcrumb(component,recordName,tabName,childRecName,recordId);
}

getBreadcrumbs(): MenuItem[] {
  return this.breadcrumbService.getBreadcrumbs();
}
onCancelEngagementAudit()
{
  
  if ((this._oldSupplierAudit!=JSON.stringify(this._engagementAudit)))
  {
      this.confirmationService.confirm({
        message: 'Audit will not be added, Do you want to Continue?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.cancelAuditButtonClick.emit();
          this.messageService.add({
            key: 'tc',
            severity: 'info',
            summary: 'Audit not added',
            detail: '',
        });
        },
        reject: () => {
        },
      });
  }
  else{
    this.cancelAuditButtonClick.emit();
  }

   
}

onSaveEngagementAudit(){
  this.isSaveBtnDisable = true;
  setTimeout(() => {
    this.isSaveBtnDisable = false;
  }, 3000);
this._addObservationFilesMandatory = this._auditObservations.some((Actions: any) => {
  if (Actions.observationId == "") {
    return true;       
  }
  return false;    
}); 
  this.submitted = true;
  

  if(this.myEngagementAuditForm.valid && !this._addObservationFilesMandatory)
  {
    this.loaderService.showLoader();

     
    this._fileService.UploadMultipleFile(this.formData).subscribe((response: any) => {        
      if (response) {
       
          //Call UploadMultipleFile method again for Finding form attachments
          this._fileService.UploadMultipleFile(this.findingFormData).subscribe((response: any) => {        
           if (response) 
            {
              this.SupplierAuditSave();
            }
            else 
            {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Add engagement audit observation finding file(s) failed to save', detail: '' });
            }
          })
      }
      else {
        this.loaderService.hideLoader();
        this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Add engaegment audit file(s) failed to save', detail: '' });
      }
    })
  }
  else{
    this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Please enter required information', detail: '', });  
  }
  
}
notOnlySpacesValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (control.value && control.value.trim() === '') {
     return { 'onlySpaces': true };
   }
   return null;
 }

onFileChange(event: any) {
  const files: FileList = event.target.files;
  if (files.length > 1) {
    this.resetFileControls('Select and upload 1 file at a time.', 'error');
    return;
  }

  if (files.length === 0) return;

  this.maxFileSize = this._fileService._fileSize; // 20 MB (value Bytes in binary)
  this.currentFileSize = files[0].size;
  this.uploadedFileName = files[0].name;
  this.allowedExtensions = [FileExtension.DOC, FileExtension.DOCX, FileExtension.PDF, FileExtension.XLS, FileExtension.XLSX];
  const uploadedExtension = this.uploadedFileName.split('.').pop().trim().toLowerCase();

  if (!this.allowedExtensions.includes(uploadedExtension)) {
    this.resetFileControls('Invalid file type. Only .pdf, .xls, .xlsx, .doc, .docx files are allowed.', 'error');
    return;
  }

  if (this.currentFileSize > this.maxFileSize) {
    this.resetFileControls('File Size limited to 20 Mb', 'warn');
    return;
  }

  if (this.uploadedFiles.length + files.length > 3) {
    this.resetFileControls('Maximum 3 files allowed.', 'error');
    return;
  }

  for (let i = 0; i < files.length; i++) {
    this.uploadedFiles.push(files[i]);
    this.myEngagementAuditForm.patchValue({ addMeetingAttachments: files[0].name });
    this.uploadedAuditFile = files[0];
    this.multiAuditFileData.push(this.uploadedAuditFile);
    this.timeStamp = formatDate(new Date().toISOString(), this._dateformart.FiletimeStamp, this._dateformart.TimeStampZone);
    const filePath = `Governance/Audit/${this.timeStamp}_${this.uploadedAuditFile.name}`;
    this.formData.append("file", this.uploadedAuditFile, filePath);
    
    this.newAttachment = { fileName: this.uploadedAuditFile.name, filePath };
    this._lstEngagementAuditAttachments.push(this.newAttachment);
  }

  this.isUploadButtonDisabled = this.uploadedFiles.length >= 3;
}

private resetFileControls(message: string, severity: 'warn' | 'error') {
  this.myEngagementAuditForm.patchValue({
    auditFileName: '',
    _auditFile: ''
  });
  this.messageService.add({ key: 'tc', severity, summary: message, detail: '' });
  this.isUploadButtonDisabled = this.uploadedFiles.length >= 3;
}


removeFile(file: File) {
  const index = this.uploadedFiles.indexOf(file);
  if (index !== -1) {
    this.uploadedFiles.splice(index, 1);

    const attachmentIndex = this._lstEngagementAuditAttachments.findIndex(attachment => attachment.fileName == file.name);
    if (attachmentIndex !== -1) {
      this._lstEngagementAuditAttachments.splice(attachmentIndex, 1);
    }

    this.myEngagementAuditForm.patchValue({
      auditFileName: '',
      _auditFile: ''
    }); 
  }
  if(this.uploadedFiles.length >= 3)
    {
      this.isUploadButtonDisabled = true;
    }
    else{
      this.isUploadButtonDisabled = false;
    }
}


SupplierAuditSave() {
  this._engagementAudit.createdBy=this.privilegeService.getLoginUserId();
  this._engagementAudit.auditAttachments = this._lstEngagementAuditAttachments;
  this._engagementAudit.auditObservations=this._auditObservations
  this._engagementAudit.engagementId = this.engagementId;
   this._engagementAudit.auditStartDate =  this.datePipe.transform(this._engagementAudit.auditStartDate, "MM/dd/yyyy");
  this._engagementAudit.auditEndDate =  this.datePipe.transform(this._engagementAudit.auditEndDate, "MM/dd/yyyy");
    this._engagementAuditService.AddEngagementAudit(this._engagementAudit).subscribe(
    res => {
      if(res.status){
        this.loaderService.hideLoader();
        this.messageService.add({ key: 'tc', severity: 'success', summary: 'Audit has been added', detail: ''});
        this.compareModels();
        this.saveAuditButtonClick.emit();
        const dataResult = {
          supplierId:this.engagementId,
          auditId:parseInt(res.data)
        };
        this.viewButtonClick.emit(dataResult);
      }
    }
  );
}

onStartDateSelection(startDate:any){

  this.auditMinEndDate= new Date(startDate);
  this.auditMinEndDate.setDate(this.auditMinEndDate.getDate() + 1);

}
onEndDateSelection(endDate:any){
  if(endDate!=null){
  this.auditMaxStartDate= new Date(endDate);
  this.auditMaxStartDate.setDate(this.auditMaxStartDate.getDate() - 1);
}
else{
  this.auditMaxStartDate="";
}
}


// ----------Start-------Namita------------------Finding popup related code---------------------
findingAreaData:any;
GetFindingArea() {
  this.masterDataService.getCodeListData(15,'').subscribe(
    res=>{
      this.findingAreaData=res.data;
    }
  );
};

findingStatusData:any;
GetFindingStatusStatus(type:number) {
  this.masterDataService.getCodeListData(type,'Finding Status').subscribe(
    res=>{
      this.findingStatusData=res.data;
    }
  );
};
isSaveFindingPopupBtnDisable = false;
saveFinding()
{
this.isSaveFindingPopupBtnDisable = true;
setTimeout(() => {
  this.isSaveFindingPopupBtnDisable = false;
}, 3000);
 this.findingSubmitFlag=true;
if(!this.isStatusClosedForSupplier){
  this.engagementFindingForm.controls['_supplierResponse'].setErrors(null);
}
else{
  this.engagementFindingForm.controls['_supplierResponse'].setErrors({required: true});
}


if(!this.isStatusClosedForDecision){
   
    this.engagementFindingForm.controls['_decisionOfReview'].setErrors(null);
  }
else{
   
    this.engagementFindingForm.controls['_decisionOfReview'].setErrors({required: true});
  }

  if(this.engagementFindingForm.valid)
  {
    this.saveFindingDetails();
  }
  else{
    this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Please enter required information', detail: '' });  
  }

}



saveFindingDetails()
{
    let statusName = this.findingStatusData.find((aa: EngagementAuditObsvFinding) => aa.id === this.engagementAuditObsvFindingData.statusMappingId)?.name;
    let OwnerName = this.auditAuditeeSPOCDataSource.find((aa: EngagementAuditObsvFinding) => aa.id === this.engagementAuditObsvFindingData.findingOwnerId)?.name;
    this.engagementAuditObsvFindingData.findingStatus=statusName;
    this.engagementAuditObsvFindingData.findingOwnerName=OwnerName;
    this.engagementAuditObsvFindingData.isDeleted=false;

    this.engagementAuditObsvFindingData.targetDate =  this.datePipe.transform(this.engagementAuditObsvFindingData.targetDate, "MM/dd/yyyy");


    this.engagementAuditObsvFindingData.observationFindingAttachment=this.engagementAuditObsvFindingAttachments;
        
    let existing=this.findingListDataSource[this.editFindingIndex];
    if(existing == undefined)//Add case
    {
      this.engagementAuditObsvFindingData.createdBy=this.privilegeService.getLoginUserId();
      this.engagementAuditObsvFindingData.createdDate=new Date();
      this.findingListDataSource.unshift(this.engagementAuditObsvFindingData);
    }
    else//Edit case
    {
      this.engagementAuditObsvFindingData.createdBy=this.privilegeService.getLoginUserId();
      this.engagementAuditObsvFindingData.updatedBy=this.privilegeService.getLoginUserId();
      this.engagementAuditObsvFindingData.updatedDate=new Date();
      this.engagementAuditObsvFindingData.id=existing.id;
      this.findingListDataSource[this.editFindingIndex]=this.engagementAuditObsvFindingData;

    }
    this.engagementAuditObsvFindingData=new EngagementAuditObsvFinding();

    this.secondLevelPopupVisible=false;

    this._auditObservations[this.selectedObservationIndex].engagementAuditObservationFindingList=this.findingListDataSource;
    this._auditObservations[this.selectedObservationIndex].numberofFidings=this._auditObservations[this.selectedObservationIndex]?.engagementAuditObservationFindingList?.length

    this.messageService.add({ key: 'tc', severity: 'success', summary: 'Finding has been added', detail: '' });
    this.loaderService.hideLoader();
}

cancelFinding(){
  if(this.oldFindingModel==JSON.stringify(this.engagementAuditObsvFindingData))
 {
  this.secondLevelPopupVisible=false;  
 }
 else{
  this.confirmationService.confirm({
    header: 'Confirm',
    message: 'Changes will not be saved, Do you want to Continue?',
    accept: () => {
      
      this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Finding not added', detail: '' });
      this.secondLevelPopupVisible=false;
    
    }
  });
}
 }

isStatusClosed:boolean=false;


findingStatusChangeClick(event: any) {
  const statusName = this.findingStatusData.find(
    (aa: EngagementAuditObsvFinding) => aa.id === this.engagementAuditObsvFindingData.statusMappingId
  )?.name;

  if (statusName === 'Closed') {
    this.isStatusClosed = true;
    this.isStatusClosedForSupplier = this.isEmpty(this.engagementAuditObsvFindingData.engagementResponse);
    this.isStatusClosedForDecision = this.isEmpty(this.engagementAuditObsvFindingData.decisionOfReview);
  } else {
    this.resetStatusFlags();
  }
}

private isEmpty(value: string | undefined): boolean {
  return value === "" || value === undefined;
}

private resetStatusFlags() {
  this.isStatusClosedForSupplier = false;
  this.isStatusClosedForDecision = false;
  this.isStatusClosed = false;
}



engagementResponseChange(){
  if(this.engagementAuditObsvFindingData.statusMappingId != undefined)
  {
    let statusName = this.findingStatusData.find((aa: EngagementAuditObsvFinding) => aa.id === this.engagementAuditObsvFindingData.statusMappingId)?.name;
  if(this.engagementAuditObsvFindingData.engagementResponse == "" && statusName == 'Closed'){
  this.isStatusClosedForSupplier=true;
}
else{
  this.isStatusClosedForSupplier=false;
}
  }
}


decisionReviewChange(){
  if(this.engagementAuditObsvFindingData.statusMappingId != undefined)
  {
    let statusName = this.findingStatusData.find((aa: EngagementAuditObsvFinding) => aa.id === this.engagementAuditObsvFindingData.statusMappingId)?.name;
  
  if(this.engagementAuditObsvFindingData.decisionOfReview == ""  && statusName == 'Closed')
  {
  this.isStatusClosedForDecision=true;
  }
  else{
  this.isStatusClosedForDecision=false;
  }
}
}


onFindingFileChange(event: any) {
  const files: FileList = event.target.files;
  if (files.length <= 1) {
    this.maxFileSize = this._fileService._fileSize; // 20 MB (value Bytes in binary)
    if (files.length > 0) {
      this.currentFileSize = files[0].size;
      this.uploadedFileName = files[0].name;
      var uploadedExtension = this.uploadedFileName.split('.').pop().trim().toLowerCase();
    }
    // Check if the uploaded file's extension is allowed
    if (this._fileService.allowedExtensions.includes(uploadedExtension)) {
      if (this.currentFileSize <= this.maxFileSize) {
        if (this.uploadedFindingFileData.length + files.length <= 3) {
          for (let i = 0; i < files.length; i++) {
            this.uploadedFindingFileData.push(files[i]);
            this.engagementFindingForm.patchValue({
              _findingAttachments: files[0].name,
            });
            this.uploadedFindingFile = files[0];
            this.multiFindingFileData.push(this.uploadedFindingFile);
           this.timeStamp = "";
           var utcDateTimeNow = new Date().toISOString();
           this.timeStamp = formatDate(utcDateTimeNow, this._dateformart.FiletimeStamp, this._dateformart.TimeStampZone);
            this.findingFormData.append("file", this.uploadedFindingFile, "Governance/Audit/Finding/" + this.timeStamp + "_" + this.uploadedFindingFile.name);
            this.engagementFindingAttachmentItem.fileName = this.uploadedFindingFile.name;
            this.engagementFindingAttachmentItem.filePath = "Governance/Audit/Finding/" + this.timeStamp + "_" + this.engagementFindingAttachmentItem.fileName;
           
            this.newAttachment = {
                    fileName: this.engagementFindingAttachmentItem.fileName,
                    filePath: this.engagementFindingAttachmentItem.filePath
                  };
            this.engagementAuditObsvFindingAttachments.push(this.newAttachment);
            this.engagementAuditObsvFindingData.observationFindingAttachment=this.engagementAuditObsvFindingAttachments;
          }
        } else {
          this.engagementFindingForm.patchValue({
            _findingFile: '',
            findingFileName: ''

          });

          this.messageService.add({ key: 'tc', severity: 'error', summary: 'EMaximum 3 files allowed.', detail: '' });
        }
      }
      else {
        this.engagementFindingForm.patchValue({
          findingFileName: '',
          _findingFile: ''
        });
        this.messageService.add({ key: 'tc', severity: 'warn', summary: 'File Size limited to 20 Mb', detail: '' });
      }
    }
    else {
      this.engagementFindingForm.patchValue({
        findingFileName: '',
          _findingFile: ''
      });
      this.messageService.add({ key: 'tc', severity: 'error', summary: 'Invalid file type. Only .pdf, .xls, .xlsx, .doc, .docx files are allowed.', detail: '' });
    }

  }
  else {
    this.engagementFindingForm.patchValue({
      findingFileName: '',
        _findingFile: ''
    });
    this.messageService.add({ key: 'tc', severity: 'error', summary: 'Select and upload 1 file at a time.', detail: '' });

  }
  if(this.uploadedFindingFileData.length >= 3)
  {
    this.isAddFindingFileUploadButtonDisabled = true;
  }
  else{
    this.isAddFindingFileUploadButtonDisabled = false;
  }
}
removeFindingFile(file: File) {
  const index = this.uploadedFindingFileData.indexOf(file);
  if (index !== -1) {
    this.uploadedFindingFileData.splice(index, 1);

    const attachmentIndex = this.engagementAuditObsvFindingAttachments.findIndex(attachment => attachment.fileName === file.name);
    if (attachmentIndex !== -1) {
      this.engagementAuditObsvFindingAttachments.splice(attachmentIndex, 1);
    }

    this.engagementAuditObsvFindingData.observationFindingAttachment=this.engagementAuditObsvFindingAttachments;
    this.engagementFindingForm.patchValue({
      _findingFile: '',
      findingFileName: ''
    })
  }
  if(this.uploadedFindingFileData.length >= 3)
    {
      this.isAddFindingFileUploadButtonDisabled = true;
    }
    else{
      this.isAddFindingFileUploadButtonDisabled = false;
    }
}

addFindingInObservation(){
  this._auditObservations[this.selectedObservationIndex].engagementAuditObservationFindingList=this.findingListDataSource;
  this._auditObservations[this.selectedObservationIndex].numberofFidings=this._auditObservations[this.selectedObservationIndex]?.engagementAuditObservationFindingList?.length
  this.visible = false;
  
}

cancelFindingList(){
  
  this.visible = false;
  this.findingListDataSource=[];
}

editFindingIndex:number=0;
isUpdatedFlag=false;
editFinding(editFindingIndex: number){
  this.header='Edit Finding';
  this.uploadedFindingFileData=[];
  this.engagementAuditObsvFindingAttachments=[];
  this.isUpdatedFlag=true;
  this.editFindingIndex=editFindingIndex;
  let findingRecord=this._auditObservations[this.selectedObservationIndex].engagementAuditObservationFindingList[editFindingIndex];
  this.engagementAuditObsvFindingData.createdBy=this.privilegeService.getUserName(findingRecord.createdBy);
  this.engagementAuditObsvFindingData.createdDate=findingRecord.createdDate;
  this.engagementAuditObsvFindingData.findingId=findingRecord.findingId;
  this.engagementAuditObsvFindingData.findingOwnerId=findingRecord.findingOwnerId;
  this.engagementAuditObsvFindingData.findingAreaId=findingRecord.findingAreaId;
  this.engagementAuditObsvFindingData.targetDate=new Date(findingRecord.targetDate);
  this.engagementAuditObsvFindingData.statusMappingId=findingRecord.statusMappingId;
  this.engagementAuditObsvFindingData.findingDetail=findingRecord.findingDetail;
  this.engagementAuditObsvFindingData.engagementResponse=findingRecord.supplierResponse;
  this.engagementAuditObsvFindingData.evidenceOfClosure=findingRecord.evidenceOfClosure;
  this.engagementAuditObsvFindingData.decisionOfReview=findingRecord.decisionOfReview;
  this.engagementAuditObsvFindingAttachments=findingRecord.observationFindingAttachment;
  if(findingRecord.observationFindingAttachment.length>0){
    let filedata;
    
    findingRecord.observationFindingAttachment.forEach((file:any) => {
      filedata = {
        name: file.fileName,
      };
      this.uploadedFindingFileData.push(filedata);
      
      
    });
    this.secondLevelPopupVisible = true;
  }
  else{
    this.secondLevelPopupVisible = true;
  }

  this.oldFindingModel=JSON.stringify(this.engagementAuditObsvFindingData);
  
}
// ----------End-------Namita------------------Finding popup related code---------------------

formOldSupplierAudit()
{
  setTimeout(() => {
    this._oldSupplierAudit=JSON.stringify(this._engagementAudit);
  }, 3000);
}

//abservation operations
AddObservations(){ 
  this._auditObservations.unshift({
    id: 0,
    observationId: '',
    numberofFidings:0,
    grade: 0,
    statusMappingId:0,
  
  });
}

deleteObservation(currentActionIndex: number) {  
  this.confirmationService.confirm({
       header: 'Confirm',
       message: 'Are you sure you want to delete this observation?',//+" "+ addmeeting.ActionName + '?',
       accept: () => {
       this._auditObservations.splice(currentActionIndex, 1);
       this.messageService.add({ severity: 'success', summary: 'Audit Observation deleted', detail: '',});
       }
     });
   }


   deleteFinding(currentFindingIndex: number) {  
    this.confirmationService.confirm({
         header: 'Confirm',
         message: 'Are you sure you want to delete this finding?',//+" "+ addmeeting.ActionName + '?',
         accept: () => {
        
         this._auditObservations[this.selectedObservationIndex].engagementAuditObservationFindingList.splice(currentFindingIndex, 1);
         this._auditObservations[this.selectedObservationIndex].numberofFidings=this._auditObservations[this.selectedObservationIndex]?.engagementAuditObservationFindingList?.length
         this.messageService.add({ severity: 'success', summary: 'Audit finding deleted', detail: '',});
         }
       });
     }

  selectedObservationIndex:any;
  observationId:any;

   GetFindinglist(selectedIndex:any)
   {
        this.selectedObservationIndex=selectedIndex;
        this.observationId=this._auditObservations[this.selectedObservationIndex].observationId;
        if(this._auditObservations[this.selectedObservationIndex]?.engagementAuditObservationFindingList?.length>0)
        this.findingListDataSource=this._auditObservations[this.selectedObservationIndex]?.engagementAuditObservationFindingList;
        else
        this.findingListDataSource=[];
        this.visible = true;
   }

   changedSupplierAuditBasicInfo:any;
oldSupplierAuditData:any;
compareModels() {
  this.changedSupplierAuditBasicInfo = this.auditLogService.compareModels(JSON.parse(this.oldSupplierAuditData),this._engagementAudit,this.auditEntities.EngagementAudits,this.auditIdentifierDetails.EngagementAudits,this.auditOperations.Create, this.auditModule.GovernanceService);
}
}
