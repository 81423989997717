import {Component} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MenuItem, } from 'primeng/api';
import { LoaderService } from 'src/app/Loader/loader.service';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { breadcrumnsCodes,StatusCodes } from 'src/app/shared/constants/global.constants';
import { EmbeddingContext } from 'amazon-quicksight-embedding-sdk/dist';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { AuthCommonService } from 'src/app/shared/Authentication/auth.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { EngagementService } from 'src/app/Engagement/Services/engagement.service';
import { Privileges } from 'src/app/shared/constants/privileges.constants';
import { DashboardService } from 'src/app/Dashboard/Services/dashboard.service';
import { } from 'src/app/Finance/Services/invoice.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-teamsdashboard',
  templateUrl: './teamsdashboard.component.html',
  styleUrls: ['./teamsdashboard.component.css']
})
export class TeamsdashboardComponent  {
  dashboardForm: FormGroup;
  _enumBreadcrumb: any;
  engagementdata: any;
  engagements: any;
  engagementId: any;
  statusCodes:any;
  listOfFunctions: any;
  SelectedFunction:string="";
  ViewFunctionsTeamsDashboard:boolean=false;
  ViewEngagementTeamsDashboard:boolean=false;
  dashboardApplyClicked: boolean = false;
  IsFunctionDisable:boolean=false;
  constructor(
    private breadcrumbService: BreadcrumbService,
    private _engagementSerice: EngagementService,
    private fb: FormBuilder,
    private loaderService: LoaderService,
    private authService: AuthCommonService,
    private privilegeService: PrivilegeService,
    private _dashboaredService: DashboardService,
   ) {
    this.dashboardForm = this.fb.group({
      dashboardEngagement: ['', [Validators.required]],
      dashboardFunction: ['', [Validators.required]],
    });
  }
  engagementsIds :any=0;
  entityId:number=0;
  functionEntityId:number=0;
  ngOnInit() {
  let userData=this.privilegeService.getUserData();
  this.engagementsIds=userData.engagements;
  this.getAllEngagements();
  this._enumBreadcrumb = breadcrumnsCodes;
  this.statusCodes=StatusCodes;
  this.breadcrumbService.generateBreadcrumb(this._enumBreadcrumb.TDB);
  this.BindPrivileges();
  }
  IsShowButton:boolean=true;
  BindPrivileges()
  {
    const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);
    this.ViewFunctionsTeamsDashboard=hasPermission(Privileges.ViewFunctionsTeamsDashboard);
    this.ViewEngagementTeamsDashboard=hasPermission(Privileges.ViewEngagementTeamsDashboard);
    if(!this.ViewFunctionsTeamsDashboard && !this.ViewEngagementTeamsDashboard )
    {
      this.IsShowButton=false;
    }
  }
  getBreadcrumbs(): MenuItem[] {
    return this.breadcrumbService.getBreadcrumbs();
  }
  getAllEngagements() {
    this._engagementSerice.GetEngagementsByGroupTypes(this.engagementsIds)
      .subscribe(res => {
        this.engagementdata = res.data;
        this.engagements = this.engagementdata.map((engagement: { id: any, engagementName: any }) => ({ label: engagement.engagementName, value: engagement.id }));    
      });
  }
  onTeamDashboardEngChange(event: any) {
    this.engagementId = event.value;
    this.entityId = this.engagementId;
    this.listOfFunctions = [];
    
    if (this.ViewFunctionsTeamsDashboard) {
        this.SelectedFunction = "";
        this.BindEngagementContractsGroups();
    } else {
        this.listOfFunctions.push({ label: this.statusCodes.NA, value: 'NotApplicable' });
        this.SelectedFunction = this.listOfFunctions[0].value;
        this.IsFunctionDisable = true;
    }
}

  onTeamDashboardFunctionChange(event: any){
 }
  functionsData: any;
  BindEngagementContractsGroups(){
    this.loaderService.showLoader();
    this._engagementSerice.GetAllEngagementContractGroupsById(this.engagementId).subscribe(
      res => {
        if (res.data) {
          this.loaderService.hideLoader();
          this.functionsData=res.data;
          this.listOfFunctions = this.functionsData.map((_function: { id: any, engagementGroupName: any }) => ({ label: _function.engagementGroupName, value: _function.id })); 
          if(this.ViewEngagementTeamsDashboard)
            {
            this.listOfFunctions.unshift({ label: this.statusCodes.NA, value: 'Not Applicable' }); 
            }  
        }
      }
    );
  }
reportingServiceURL="";
isShowLabel : boolean = false;
showTeamDashboard()
{
  this.isShowLabel = true;
  this.dashboardApplyClicked = true;
  if(this.dashboardForm.valid)
  {
  this.loaderService.showLoader();
  this.GetReportingServicesEmbedURL();
  }
}
_dashboradId:any;
public async GetReportingServicesEmbedURL() {
  if(this.SelectedFunction=='Not Applicable')
  {
  this._dashboradId=environment?._engagementTeamDashboardId;
  }
  else{
    this._dashboradId=environment?._contractGroupDashboardId;
  }
  let model = {
    "dashboardID": this._dashboradId,
    "token": this.authService.getCookieValue('LOGIN_TOKEN'),
    "privilege": [
      {
        "MenuName": "string",
        "isAllDataAvailable": true,
        "DataValues": []
      }
    ]
  };
this._dashboaredService.getEmbedUrlData(model).subscribe(
  async (res: any) => {
      this.reportingServiceURL=res.data;
      await this.Dashboard();
});
}
URL:string="";
public async Dashboard() {
  let containerDiv = document.getElementById("dashboardContainer") || '';
  if (containerDiv && containerDiv instanceof HTMLElement) {
    containerDiv.innerHTML = ''; // Clear the container
}
  const embeddingContext: EmbeddingContext = await createEmbeddingContext();
  if(this.SelectedFunction=='Not Applicable')
    {
      this.URL=this.reportingServiceURL + '&#p.EngagementId=' + this.entityId;
    }
    else
    {
      this.functionEntityId= parseInt(this.SelectedFunction);
      this.URL= this.reportingServiceURL + '&#p.ContractGroupId='+ this.functionEntityId; 
    }
  
  const frameOptions = {
    url: this.URL,
    container: containerDiv,
    height: "400px",
    width: "700px",
    resizeHeightOnSizeChangedEvent: true,
    onChange: (changeEvent:any, metadata:any) => {
      switch (changeEvent.eventName) {
          case 'FRAME_LOADED': {
               this.loaderService.hideLoader();
              break;
          }
      }
  },
}
 embeddingContext.embedDashboard(frameOptions);
}
}
