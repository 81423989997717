import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class CookieSecureService {

  constructor(private cookieService: CookieService) { }

  setCookie(name: string, value: string, expires?: number | Date, path?: string, domain?: string, secure?: boolean, sameSite?: 'Strict' | 'None') {
    // Check if served over HTTPS
    const isSecure = window.location.protocol === 'https:';

    // If served over HTTPS, set the secure flag
    if (isSecure) {
      secure = true;
    }

    this.cookieService.set('cookieName', 'cookieValue', 5000, '/','freyrkontractpro.com', true,'Strict');
  }
}
