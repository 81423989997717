import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Privileges } from '../shared/constants/privileges.constants';
import { PrivilegeService } from './privilege.service';
import { RoleService } from '../Admin/Authorization/RolesPrivileges/Services/role.service';
import { UserService } from '../Admin/Authorization/UserRoleMapping/Services/user.service';
import { UserData } from '../Admin/Authorization/UserRoleMapping/Models/user';
import { AuthCommonService } from '../shared/Authentication/auth.service';
@Injectable({
  providedIn: 'root'
})
export class AppInitializerService
{
  private privileges: Privileges[] = [];
  isLoginUrl: boolean = false;
  //userData: UserData | undefined;
  isLoggedIn: boolean = false;
   userData: UserData = new UserData();
  constructor(private http: HttpClient, private privilegeService: PrivilegeService, private userService: UserService, private authCommonService: AuthCommonService,)
  {

  }
  async initializeApp(): Promise<any>
  {
    this.isLoggedIn = await this.authCommonService.isUserLoggedIn();
    if (this.isLoggedIn)
    {
      return new Promise((resolve, reject) =>
      {
         //this code for only development purpose, Line number 31 to 37
         this.privileges = Object.values(Privileges);
       //  this.setStaticUserdata();//enable all privileges for development
    

       let UserObj:any ={
        "Id": 0,
        "FirstName": this.authCommonService?.userDetails?.firstName,
        "LastName":  this.authCommonService?.userDetails?.lastName,
        "EmailId": this.authCommonService?.userEmail,
        "UserName": this.authCommonService?.userDetails?.userName,
        "CreatedBy": 0,
      }
        this.userService.UserPrivilegesByEmail(UserObj).subscribe(res =>
          {
           
            if (res && res.status && res.data )
            {
              this.userData=res.data;
              this.privilegeService.setUserData(this.userData);
              this.privilegeService.setUsersList(res.data.usersList);
              if(res.data.privilegesDetails!=undefined && res.data.privilegesDetails.length>0 ){

              const userprivileges=  res.data.privilegesDetails.map((detail: any) => detail.privilege);
            // this.privilegeService.setPrivileges(this.privileges); //for  development giving all privileges
            this.privilegeService.setPrivileges(userprivileges);//for production giving specific role privileges
             
              }

              
            }
            resolve(true);
          });
       // }
      // resolve(true);
      });
      //}
    }
   
  }
  setStaticUserdata(){
   
    this.userData.groupType=1;
   this.userData.sponsers=[1,2,3];
   this.userData.suppliers=[1,2];
   this.privilegeService.setPrivileges(this.privileges);
   this.privilegeService.setUserData(this.userData);
  }
}

