import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ContractMeetingService } from '../../Services/MeetingServices';
import { ContractService } from '../../../../../Contracts/Services/contract.service';

import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from '../../../../../Loader/loader.service';
import { BreadcrumbService } from '../../../../../shared/Breadcrumb/breadcrumb.service';
import { AuditIdentifier, AuditLogEntities, AuditLogModule, AuditOperations, Dateformat, breadcrumnsCodes } from '../../../../../shared/constants/global.constants';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { Privileges } from 'src/app/shared/constants/privileges.constants';
import { FileUploadService } from 'src/app/Engagement/Services/fileupload.service';
import { AuditLogService } from 'src/app/shared/ModelComparison/audit.log.service';
import { ContractMeetingDetails } from '../../Modals/ContractMeeting';


@Component({
  selector: 'app-con-view-meeting',
  templateUrl: './con-view-meeting.component.html',
  styleUrls: ['./con-view-meeting.component.css']
})
export class ConViewMeetingComponent {
  @Output() editMeetingButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelMeetingButtonClick = new EventEmitter<void>();
  @Input() ContractId: any;
  @Input() meetingId: any;
  @Input() supplierName: any;
  contractMeetingDetail:any;
  isRecurringMeeting:boolean=false;
  _dateformart :any;
  contractMeetingId:number=0;

breadcrumbs: MenuItem[]=[];
_enumBreadcrumb :any;

selectedOption: string = 'Actions';
displayOccurrencePopup: boolean = false;
position: string = 'center';
attendeesVisible:boolean  = false;
absenteesVisible:boolean  = false;
occurrenceDetail:any;
showAttendeesButton : boolean= false;
showAbsenteesButton : boolean= false;
buttonLabel:any;
MeetingCreatedByUserName:string='';
MeetingUpdatedByUserName:string='';
editPrivilege:boolean=false;
deletePrivilege:boolean=false;
constructor(private privilegeService: PrivilegeService,private governanceService: ContractMeetingService,
  private _contractService : ContractService,
  private fileService : FileUploadService, 
  private router:Router,private route: ActivatedRoute, private loaderService: LoaderService,  private breadcrumbService: BreadcrumbService,
  private confirmationService: ConfirmationService,private messageService: MessageService, private auditLogService: AuditLogService,
  )
{

}


  ngOnInit() {
    this._dateformart=Dateformat;
    this._enumBreadcrumb=breadcrumnsCodes;
    this.contractMeetingId =this.meetingId; 
    this.loaderService.showLoader();
    this.getContractMeetingsById(this.contractMeetingId)
    this.loaderService.hidefinalLoader();// hide after 4 seconds even get any error
    this.BindPrivileges();
    this.auditIdentifierDetails=AuditIdentifier; 
    this.auditEntities=AuditLogEntities;
    this.auditOperations=AuditOperations;
    this.auditModule=AuditLogModule;
  }
   
  BindPrivileges(){
    const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);
  this.editPrivilege = hasPermission(Privileges.EditContractMeeting);
  this.deletePrivilege=hasPermission(Privileges.DeleteContractMeeting);
    }
  GetContractById(contractId:number){
    this._contractService.GetContractById(contractId).subscribe(
      res=>{
       var contractName=res.data.title;
        this.loaderService.hideLoader();
        
      }
    );
    
  }

  agenda:any;
  meetingNotes:any;
  decision:any;
  remarks:any;
  occurrenceAction: any;  
  formattedActionName: any;
  getContractMeetingsById(contractMeetingId:any){

    this.governanceService.getContractMeetingsById(contractMeetingId).subscribe(
      (response:any)=>{
       this.contractMeetingDetail=response.data;
       this._oldContractMeeting=response.data;
       this._contractMeeting=this.contractMeetingDetail;
       this.agenda = response.data.agenda ? response.data.agenda.replace(/\n/g, "<br>") : response.data.agenda;
      
       this.MeetingCreatedByUserName=this.privilegeService.getUserName(response.data.createdBy);
       this.MeetingUpdatedByUserName=this.privilegeService.getUserName(response.data.updatedBy);
       this.contractMeetingDetail.contractMeetingOccurrences.forEach((Occurrences:any) => {
        Occurrences.createdBy=this.privilegeService.getUserName(Occurrences.createdBy);
        Occurrences.updatedBy=this.privilegeService.getUserName(Occurrences.updatedBy);
        this.meetingNotes =  this.contractMeetingDetail.contractMeetingOccurrences[0].meetingNotes ?  this.contractMeetingDetail.contractMeetingOccurrences[0].meetingNotes.replace(/\n/g, "<br>") :  this.contractMeetingDetail.contractMeetingOccurrences[0].meetingNotes;
        this.decision =  this.contractMeetingDetail.contractMeetingOccurrences[0].decisions ?  this.contractMeetingDetail.contractMeetingOccurrences[0].decisions.replace(/\n/g, "<br>") :  this.contractMeetingDetail.contractMeetingOccurrences[0].decisions;
        this.occurrenceAction = this.contractMeetingDetail.contractMeetingOccurrences[0].contractMeetingOccurrenceActions[0];

        
       });
       this.isRecurringMeeting=this.contractMeetingDetail?.recurring;
       this.GetContractById(this.contractMeetingDetail.contractId)
        
      }
    );
}

generateBreadcrumb(component?:string,recordName?:string,tabName?:string,childRecName?:string,recordId?:number)
{
  this.breadcrumbService.generateBreadcrumb(component,recordName,tabName,childRecName,recordId);
}

getBreadcrumbs(): MenuItem[] {
  return this.breadcrumbService.getBreadcrumbs();
}

onOptionChange(): void {
  var contractId= this.ContractId;
  var meetingId=this.meetingId;//Click on Actions button navigate to edit    
    if (this.selectedOption === 'Edit') {
      if(this.isRecurringMeeting){
      
        const data = {
          isRecurring:true,
          contractId:this.ContractId,
          meetingId:this.meetingId
        };
        this.editMeetingButtonClick.emit(data);
      }
      else{
       
        const data = {
          isRecurring:false,
          contractId:this.ContractId,
          meetingId:this.meetingId
        };
        this.editMeetingButtonClick.emit(data);
      } 
    }
    if(this.selectedOption === 'Delete' && this.meetingId){
      this.confirmationService.confirm({
        message: `Are you sure you want to delete this Meeting?`,
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.governanceService.DeleteContractMeeting(this.meetingId,0).subscribe(res => {
          if(res.data > 0)
          {
            this.messageService.add({
            key: 'tc',
            severity: 'info',
            summary: `Meeting is deleted successfully`,
            detail: '',
        });
        this.compareModels();
        this.cancelMeetingButtonClick.emit();
          }
      });
           },
           reject: () => {
            this.selectedOption = 'Actions';
           },
         });       
    }  
     
}

viewOccurrence(index:any){
this.occurrenceDetail=null;
this.occurrenceDetail = this.contractMeetingDetail?.contractMeetingOccurrences[index]; 
this.meetingNotes=(this.occurrenceDetail.meetingNotes ? this.occurrenceDetail.meetingNotes.replace(/\n/g, "<br>") : '--');
  this.decision=(this.occurrenceDetail.decisions ? this.occurrenceDetail.decisions.replace(/\n/g, "<br>") : '--');

this.showOccurrencePopup();
}


showOccurrencePopup() {
  this.displayOccurrencePopup = true;
}

cancelDialog(){
this.displayOccurrencePopup = false;
}

downloadAttachment(data:any)
{
this.downloadFile(data?.fileName, data?.filePath);

}


async downloadFile(fileName:any,filePath:any){
this.loaderService.showLoader();
var fileDetail = {
  "fileName":fileName,
  "filePath":filePath
};
this.fileService.DownloadFile(fileDetail).subscribe(
  async (res:any)=>{
      var blobData = this.fileService.dataURItoBlob(res);
      if(blobData.size>0){
         var response = await this.fileService.downloadBase64File(blobData,fileDetail.fileName);
         this.loaderService.hideLoader();
      }
      else{
        this.loaderService.hideLoader();
        
      }
  } 
);

}
showAttendeesDialog()
{
this.attendeesVisible = !this.attendeesVisible
}

showAbsenteesDialog()
{
this.absenteesVisible = !this.absenteesVisible
}
BackToList()
{
  this.cancelMeetingButtonClick.emit();
}
deleteOccurrece(occurrenceId: number){
  if(occurrenceId){
    this.confirmationService.confirm({
      message: `Are you sure you want to delete this Meeting Occurrence?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.governanceService.DeleteContractMeeting(0,occurrenceId).subscribe(res => {
        if(res.data > 0)
        {
          this.messageService.add({
          key: 'tc',
          severity: 'info',
          summary: `Meeting Occurrence is deleted successfully`,
          detail: '',
      });
      this.getContractMeetingsById(this.contractMeetingId)
        }
    });
         },
         reject: () => {
         },
       });       
  }  
}
_contractMeeting=new ContractMeetingDetails();
changedContractMeetingBasicInfo:any;
auditIdentifierDetails:any;
auditEntities:any;
auditOperations:any;
auditModule:any;
_oldContractMeeting: any;
compareModels() {
  this._oldContractMeeting = JSON.parse(JSON.stringify(this._oldContractMeeting));
  this._oldContractMeeting.IsDeleted=false;
  this._contractMeeting.IsDeleted = true;
  this.changedContractMeetingBasicInfo = this.auditLogService.compareModels((this._oldContractMeeting),this._contractMeeting,this.auditEntities.ContractMeetings,this.auditIdentifierDetails.DeleteContractMeeting,this.auditOperations.Delete,this.auditModule.GovernanceService);
}

}

