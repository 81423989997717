<!DOCTYPE html>
<html lang="en">
<head>
    <title> Contract Edit Meeting</title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>
<div class="card-pad">
    <div class="fr-flex__container sticky-sub-header">
        <div class="fr-flex__col-8">
            <h3>Edit Meeting</h3>
        </div>
    </div>
    <div class="scroll-start-here-sub-tabs"> 
    <form [formGroup]="supplierAddMeetingForm">
        <div class="fr-flex__container m-t-20">
            <div class="fr-flex__col-4">
                <div class="fr-form__group"> <label> Title <em> * </em> <span class="max-length"> {{contractMeetingDetails.Title?.length || 0}}/50 </span></label> 
                <input type="text" class="form-control" pInputText [pTooltip]="selectedTitle!.length > 35 ? selectedTitle!: ''" [maxlength]="50" [(ngModel)]="contractMeetingDetails.Title" formControlName="addMeetingTitle" [minlength]="3" trimSpace/> 
                <small class="p-error" *ngIf="addMeetingSaved && supplierAddMeetingForm?.get('addMeetingTitle')?.hasError('required')">This field is required.</small>
                <small class="p-error" *ngIf="addMeetingSaved && supplierAddMeetingForm?.get('addMeetingTitle')?.hasError('maxlength')">Max length is 50 characters.</small>
                <small class="p-error" *ngIf="supplierAddMeetingForm.get('addMeetingTitle')?.hasError('onlySpaces')">Only spaces not allowed.</small>
                <small class="p-error" *ngIf="addMeetingSaved && supplierAddMeetingForm?.get('addMeetingTitle')?.hasError('minlength')">min length is 3 characters.</small>
    
            </div>
            </div>
            <div class="fr-flex__col-4">
                <div class="fr-form__group"> <label> Organizer <em> * </em>  <a class="btn btn-primary-link pull-right line-height-zero" (click)="openContractModel('organizer',0)" >Add New</a> </label>
                 <p-dropdown appendTo="body" id="conEdiMeetingOrganizerDropdown" [filter]="true" [options]="organizerOptions" [(ngModel)]="contractMeetingDetails.Organizer" formControlName="addMeetingOrganizer"></p-dropdown>
                 <small class="p-error" *ngIf="addMeetingSaved && supplierAddMeetingForm?.get('addMeetingOrganizer')?.hasError('required')">This field is required.</small>
                </div>
            </div>
            <div class="fr-flex__col-4">
                <div class="fr-form__group"> <label> Recurring <em> * </em></label>
                    <p-dropdown id="conEdiMeetingRecurringDropdown" appendTo="body" [filter]="true" [options]="options" optionLabel="name" optionValue="value" [(ngModel)]="contractMeetingDetails.Recurring" (onChange)="onRecurringChange($event)" formControlName="addMeetingRecurring"></p-dropdown>
                    <small class="p-error" *ngIf="addMeetingSaved && supplierAddMeetingForm?.get('addMeetingRecurring')?.hasError('required')">This field is required.</small>
                </div>
            </div>
        </div>
        <div class="fr-flex__container">
            <div class="fr-flex__col-4"> 
            <div class="fr-form__group">
                <label> Date <em> * </em></label>
                <p-calendar id="conEdiMeetingDateCalendar" appendTo="body" class="form-control" [showIcon]="true" [(ngModel)]="_contractMeetingOccurrences.OccurrenceDate"  formControlName="addMeetingOccurenceDate" [dateFormat]=_dateformart.CalenderDateFormat [readonlyInput]="true" ></p-calendar>
                <small class="p-error" *ngIf="addMeetingSaved && supplierAddMeetingForm?.get('addMeetingOccurenceDate')?.hasError('required')">This field is required.</small> 
            </div>
            </div>
            <div class="fr-flex__col-8">
                <div class="fr-form__group">
                <label> Attachments  <i  pInputText pTooltip="Maximum 3 Attachments allowed." tooltipPosition="top" class="pi pi-info-circle" > </i></label>
                <input type="file" accept=".pdf, .xls, .xlsx,.doc,.docx" multiple (change)="onFileChange($event)" class="form-control" formControlName="addMeetingFileSource" #fileInput style="display:none" trimSpace/>
                <button type="button" (click)="fileInput.click()" class="form-control" [disabled]="isUploadButtonDisabled">Upload Files</button>
                <div class="display-inline-block" *ngFor="let file of uploadedFiles">
                    <a class="form-list-active" (click)="downloadAttachment(file)">
                         <span [pTooltip]="file.name.length > 40 ? file.name : ''" tooltipPosition="top">  {{ file.name | truncate: 40 }} </span> 
                         <i pInputText pTooltip="Delete" tooltipPosition="top" (click)="removeFile(file)" class="pi pi-trash"></i>
                         </a>
                  </div>
                  
                </div>
                
            </div>
            
        </div>
        <div class="fr-flex__container">
            <div class="fr-flex__col-12">
                <div class="fr-form__group"> 
                <label> Agenda/Description <em> * </em> <span class="max-length"> {{contractMeetingDetails.Agenda?.length || 0}}/300 </span>  </label> 
                <textarea  class="form-control" [maxlength]="300" pInputTextarea [(ngModel)]="contractMeetingDetails.Agenda" formControlName="addMeetingAgenda" trimSpace ></textarea>
                <small class="p-error" *ngIf="addMeetingSaved && supplierAddMeetingForm?.get('addMeetingAgenda')?.hasError('required')">This field is required.</small> 
                </div>
            </div>
        </div>
        <div class="fr-flex__container">
            <div class="fr-flex__col-4">
                <div class="fr-form__group">

                     <label> Attendees <span class="max-length-multiple-selected"> </span>  <a class="btn btn-primary-link pull-right line-height-zero" (click)="openContractModel('attendees',0)" >Add New</a>  </label>
                     <p-multiSelect appendTo="body" formControlName="_attendent"  [options]="_attendees" optionLabel="name" [(ngModel)]="attendeesValues"  display="chip"  ></p-multiSelect>
                </div>
            </div>
            <div class="fr-flex__col-4">

                <div class="fr-form__group"> 
                         <label> Absentees <span class="max-length-multiple-selected">  </span> <a class="btn btn-primary-link pull-right line-height-zero" (click)="openContractModel('absentees',0)" >Add New</a>  </label>
                    <p-multiSelect appendTo="body" formControlName="_absent" [options]="_absentees" optionLabel="name" [(ngModel)]="absenteesValues" display="chip"  ></p-multiSelect>
                </div>
            </div>
        </div>
        <div class="fr-flex__container">
            <div class="fr-flex__col-12">
                <div class="fr-form__group"> 
                    <label> Meeting Notes <span class="max-length"> {{_contractMeetingOccurrences.MeetingNotes?.length || 0}}/500 </span></label> 
                    <textarea  class="form-control" [maxlength]="500" pInputTextarea [(ngModel)]="_contractMeetingOccurrences.MeetingNotes" formControlName="addMeetinMeetingNotes" trimSpace ></textarea>
                </div>
            </div>
        </div>
        <div class="fr-flex__container">
            <div class="fr-flex__col-12">
                <div class="fr-form__group"> 
                    <label> Decisions <span class="max-length"> {{_contractMeetingOccurrences.Decisions?.length || 0}}/500 </span></label> 
                    <textarea  class="form-control" [maxlength]="500" pInputTextarea [(ngModel)]="_contractMeetingOccurrences.Decisions" formControlName="addMeetingDecisions" trimSpace ></textarea>
                </div>
            </div>
        </div>


        <div class="fr-flex__container">
            <div class="fr-flex__col-3">
               <div class="fr-form__view">
                  <label> Added By </label>
                  <span> <em>  {{MeetingCreatedByUserName}} </em> </span>
               </div>
            </div>
            <div class="fr-flex__col-3">
               <div class="fr-form__view">
                  <label> Added Date & Time </label>
                  <span> <em> {{contractMeetingDetails.CreatedDate | date: _dateformart.DateTimeWithZone}} {{_dateformart.DisplayUTC}}</em> </span>
               </div>
            </div>
            <div class="fr-flex__col-3">
               <div class="fr-form__view">
                  <label> Modified By</label>
                  <span> <em> {{MeetingUpdatedByUserName!=''? MeetingUpdatedByUserName:"--"}} </em> </span>
               </div>
            </div>
            <div class="fr-flex__col-3">
               <div class="fr-form__view">
                  <label> Modified Date & Time</label>
                  <span *ngIf="contractMeetingDetails?.UpdatedDate!=null"> <em> {{ contractMeetingDetails.UpdatedDate| date: _dateformart.DateTimeWithZone}} {{_dateformart.DisplayUTC}}</em></span>
                  <span *ngIf="contractMeetingDetails?.UpdatedDate==null"> <em> -- </em></span>
               </div>
            </div>
         </div>

        <div class="fr-flex__container">
            <div class="fr-flex__col-8">
                <h3>Actions </h3>
            </div>
            <div class="fr-flex__col-4">
                <a routerLinkActive="active" class="btn btn-primary pull-right"  (click)="AddMeetingActions()"> Add Action </a>
            </div>
        </div>
        </form> 
    </div>
            <div class="fr-flex__col-12 m-b-20"> 
                <p-table id="contractEditMeetingActionTableName" class="primeng-table-custom custom-cells__supplierEditMeeting_action" [scrollable]="true" [value]="addMeetingActionsData" editMode="row">
                    <ng-template pTemplate="header">
                        <tr>                         
                            <th id="conEditMeetingAction">Action<em> * </em> </th>
                            <th id="conEditMeetingOwner">Owner</th>
                            <th id="conEditMeetingTargetDate">Target Date</th>
                            <th id="conEditMeetingStatus">Status <em> * </em> </th>
                            <th id="conEditMeetingClosureDate">Closure Date</th>
                            <th id="conEditMeetingRemarks">Remarks</th>
                            <th id="conEditMeetingDelete">Delete</th>
                           
                            
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-addMeetingActionsData let-actionIndex="rowIndex"> 
                        
                        <tr>                    
                            <td>
                                <textarea class="form-control" [(ngModel)]="addMeetingActionsData.actionName"  [required]="true" [maxlength]="300" name="ActionName" #ActionNameInput="ngModel" trimSpace ></textarea>
                                <div *ngIf="ActionNameInput.errors?.['required'] && addMeetingSaved && addMeetingActionsData.actionName== ''" class="text-danger highlight-red">This field is required.</div>                 
                              </td>    

                            <td>
                                <div class="fr-form__group">
                                    <label class="edit-occurrence__AddNew">
                                        <a class="btn btn-primary-link pull-right line-height-zero table_addNew" (click)="openContractModel('owner',actionIndex)" >Add New</a> 
                                        
                                    </label>
                                    <p-dropdown id="conEdiMeetingTableOwnerDropdown" [filter]="true" pInputText  [pTooltip]= "addMeetingActionsData.owner!.length > 10 ? addMeetingActionsData.owner!: ''" placeholder="top" [options]="organizerOptions" appendTo="body" [(ngModel)]="addMeetingActionsData.owner" placeholder="Select Owner"></p-dropdown>
                                </div>
                            </td>
                           
                            <td>                                     
                              <p-calendar id="conEdiMeetingTableTargetDateCalendar" appendTo="body" class="form-control" [showIcon]="true" [(ngModel)]="addMeetingActionsData.targetDate" [showIcon]="true" [dateFormat]=_dateformart.CalenderDateFormat></p-calendar>  
                             </td>        
                              <td>
                                <p-dropdown id="conEdiMeetingTableStatusDropdown" appendTo="body" [filter]="true" [options]="meetingStatus"  placeholder="Select Status" optionLabel="name" [(ngModel)]="addMeetingActionsData.statusMappingId"></p-dropdown>
                                <div  *ngIf="addMeetingSaved && addMeetingActionsData.statusMappingId ==''" class="text-danger highlight-red">This field is required.                                                                
                                </div> 
                              </td>                    
                              <td>      
                                <div *ngIf="addMeetingActionsData.statusMappingId.code == 'CLD'">  
                                    <div class="text-danger highlight-red">*</div>                                         
                                  </div>                 
                               
                                <p-calendar id="conEdiMeetingTableClouserDateCalender" appendTo="body"  class="form-control" [showIcon]="true" pInputText [(ngModel)]="addMeetingActionsData.closureDate" [showIcon]="true" [dateFormat]=_dateformart.CalenderDateFormat #closureDateInput="ngModel" [ngClass]="{'highlight-red': addMeetingActionsData.statusMappingId.code == 'CLD' && closureDateInput.invalid}"></p-calendar>            
                               <div *ngIf="addMeetingActionsData.statusMappingId.code == 'CLD' && !addMeetingActionsData.closureDate">
                                <div class="text-danger highlight-red">Closure date is required</div> 
                                  </div>
                              </td>
                            <td>
                                <div *ngIf="addMeetingActionsData.statusMappingId.code == 'CLD'"> 
                                    <div class="text-danger highlight-red">*</div>                                         
                                  </div>  
                                <textarea class="form-control" [(ngModel)]="addMeetingActionsData.remarks"  [required]="true" [maxlength]="300" name="Remarks"  #remarksInput="ngModel" trimSpace ></textarea>
                                  <div *ngIf="addMeetingActionsData.statusMappingId.code == 'CLD' && !addMeetingActionsData.remarks">
                                    <div class="text-danger highlight-red">This field is required</div> 
                                  </div>
                                </td>                 
                            <td id="contractEditActionListDelete" class="grid-icon-center"><span class="pi pi-trash" (click)="deleteAction(actionIndex)"></span></td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                        <td colspan="7" class="text-center"> No Records</td>
                        </tr>
                        </ng-template>
                </p-table>
        </div>
    <div class="fr-flex__container footer_btns">
        <div class="fr-flex__col-12"> 
        <button  type="button" class="btn btn-primary btn-save pull-right" [class.disabled]="isSaveBtnDisable" (click)="isSaveBtnDisable ? null :UpdateMeetingDetails()"> Save </button>
        <button type="button" class="btn btn-secondary pull-right" (click)="onCancelContractMeeting()"> Cancel </button>
     </div>
     </div>
    </div>
     <p-dialog header="{{contactHeader}}" appendTo="body" [(visible)]="displayContractGroupDialog" [modal]="true" [style]="{ width: '40vw' }" [resizable]="true" [closable]="false">
        <ng-template pTemplate="content">
           <form [formGroup]="contactFormGroup">
              <div class="fr-flex__container">
                 <div class="fr-flex__col-12">
                    <div class="fr-form__group">
                       <label for="name">Contact Name<em> * </em> <span class="max-length"> {{contactName.length || 0}}/30</span> </label>
                       <input type="text" id="contactName" class="form-control" formControlName="contractName" [maxlength]="30" [(ngModel)]="contactName" pInputText trimSpace/>
                       <small class="p-error" *ngIf="(contactSubmitted || contactFormGroup?.get('contractName')?.touched) && contactFormGroup?.get('contractName')?.hasError('required')"> This field is required. </small>
                       <small class="p-error" *ngIf="(contactSubmitted || contactFormGroup?.get('contractName')?.touched) && contactFormGroup?.get('contractName')?.hasError('maxlength')">  Maximum length is 30 characters. </small>
                       <small class="p-error" *ngIf="(contactSubmitted || contactFormGroup?.get('contractName')?.touched) && contactFormGroup?.get('contractName')?.hasError('minlength')">  Minimum length is 3 characters. </small>
                       <small class="p-error" *ngIf="(contactSubmitted || contactFormGroup?.get('contractName')?.touched) && contactFormGroup?.get('contractName')?.hasError('onlySpaces')"> Only spaces not allowed.</small>
                       <small class="p-error" *ngIf="(contactSubmitted || contactFormGroup?.get('contractName')?.touched) && contactFormGroup?.get('contractName')?.hasError('pattern')">Special characters and numerics are not allowed, Min length is 3 and Max length is 30 characters.</small>
                    </div>
                 </div>
              </div>
           
           </form>
        </ng-template>
        <!-- Dialog Footer Start -->
        <ng-template pTemplate="footer">
           <button  type="button" class="btn btn-primary pull-right" (click)="SaveMeetingContact()"> Save </button>
           <button type="button" class="btn btn-secondary pull-right" (click)="closeContactDialog()"> Cancel </button>
        </ng-template>
        <!-- Dialog Footer End -->
        </p-dialog>

</body>
</html>