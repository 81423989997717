export class FilterDetails
{
    Key?:string;
    SupplierNameList?: any;
    StartDateList?:any;
    EndDateList?:any;
    Term?: any;
    ValueList?: any;
    ValuePerYearList?:any;
    CurrencyList?: any;
    StatusList?:any;
    StartDateFirst?:any;
    StartDateLast?:any;
    EndtDateFirst?:any;
    EndDateLast?:any;

   }