import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { SupplierProfile } from 'src/app/MasterData/supplier/Models/master-supplier-profile';
import { AuthCommonService } from "src/app/shared/Authentication/auth.service";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
  })
  
  export class MasterSupplierService {
    readonly _supplierApiBaseUrl:any;
  readonly _sponserApiBaseUrl:any="";
  readonly _fileApiBaseURl?:string="";
  readonly _governanceApiBaseURl?:string="";
    constructor(private http: HttpClient,private authCommonService: AuthCommonService) {
     // this._sponserApiBaseUrl=environment?._supplierApiBaseURl;
 
      this._sponserApiBaseUrl=environment?._sponserApiBaseURl;
      this._fileApiBaseURl=environment?._fileApiBaseURl;
      this._governanceApiBaseURl=environment?._governanceApiBaseURl;
  
     }

   AddSupplier(supplier: SupplierProfile): Observable<any> {
   
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.post<any>(this._sponserApiBaseUrl + '/Suppliers/AddSupplier', supplier, httpOptions);
  }
  
  GetSuppliers(){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.get<any>(this._sponserApiBaseUrl + '/Suppliers/GetAllSuppliers', httpOptions)
    .pipe(map((res:any)=>{
      return res;
      
   }));
  }

  GetSuppliersByGroups(groupTypeCode:any,supplierIds:number[]){
    if(groupTypeCode=="SUP")//groupBy
    {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'ApiKey': this.authCommonService.getAuthenticationToken() 
  });
    const params = { groupIds: supplierIds.join(',') }; // Convert array to comma-separated values    
    const options = { params: params, headers: headers };
    return this.http.get<any>(this._sponserApiBaseUrl + '/Suppliers/GetAllSuppliersByGroups',options)
    .pipe(map((res:any)=>{
      return res;
   }));
  }
  else{//all suppliers
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.get<any>(this._sponserApiBaseUrl + '/Suppliers/GetAllSuppliers', httpOptions)
    .pipe(map((res:any)=>{
      return res;
      
   }));
  }
  }

  

  public getCodeListData(type:number,statusType:string): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
   
    return this.http.get<any>(this._sponserApiBaseUrl +  '/CodeList/GetCodeLists?type=' + type + '&statusType=' + statusType, httpOptions);
  
  }

  public GetStatus() {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.get<any>(this._sponserApiBaseUrl + '/Sponsers/GetStatus', httpOptions);
  }

  GetSuppliersWithAppliedFilters(suppliersFiltesObj : any){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.post<any>(this._sponserApiBaseUrl +'/GetFilteredSuppliersList',suppliersFiltesObj, httpOptions)
  }
  
  GetSuppliersLinkedwithSponserId(id:any){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'ApiKey': this.authCommonService.getAuthenticationToken() 
  });
    let params = new HttpParams().set("id", id);
    const options = { params: params, headers: headers };
  return this.http.get<any>(this._sponserApiBaseUrl +'/GetNoOfSuppliersUnderSponserId',options);
}

 

  // public GetAllSponsers() {
  //   const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  //   return this.http.get<any>(this._sponserApiBaseUrl + '/GetAllSponsers', httpOptions);
  // }

  
   GetSupplierById(supplierId:number){
     const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
     return this.http.get<any>(this._sponserApiBaseUrl + '/Suppliers/GetSupplierById?supplierId='+supplierId, httpOptions);
   }
   
  UpdateSupplier(supplier: SupplierProfile): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
    return this.http.put<any>(this._sponserApiBaseUrl + '/Suppliers/UpdateSupplier', supplier, httpOptions);
  }
  
  
  InActivateSupplierById(id: any,statusMappingId:any,status:any){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'ApiKey': this.authCommonService.getAuthenticationToken() 
  });

  let params = new HttpParams().set("id", id).set("statusMappingId",statusMappingId).set("status",status);
    const options = { params: params, headers: headers };
   return this.http.get<any>(this._sponserApiBaseUrl +'/InActivateSupplierById',options)
  }
   
  InActivateSuppliersUnderSponserId(id: any,statusMappingId:any,status:any){
      const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'ApiKey': this.authCommonService.getAuthenticationToken() 
   });


    let params = new HttpParams().set("id", id).set("statusMappingId",statusMappingId).set("status",status);
    const options = { params: params, headers: headers };
    return this.http.get<any>(this._sponserApiBaseUrl +'/InActivateSuppliersUnderSponserId', options)
 }
      
  public getSupplierById(id:any): Observable<any> {
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
        return this.http.get<any>(this._sponserApiBaseUrl +  '/Suppliers/GetSupplierById?supplierId=' + id, httpOptions);
  }


  AddSupplierContact(supplierContact:any): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
  
    return this.http.post<any>(this._sponserApiBaseUrl + '/Suppliers/AddSupplierContact', supplierContact, httpOptions);
  }

  public getSupplierContactsDataById(id:any): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.get<any>(this._sponserApiBaseUrl +  '/Suppliers/GetSupplierContactsById?supplierId=' + id, httpOptions);
  }
  public getStakeholderSupplierContactsDataById(ids:any): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.get<any>(this._sponserApiBaseUrl +  '/Suppliers/GetStakeholderSupplierContacts?supplierIds=' + ids, httpOptions);
  }
 
  }


