<!DOCTYPE html>
<html lang="en">
<head>
   <title> View Forecast </title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>
<div class="card-pad"> 
<div class="fr-flex__container sticky-sub-header">
   <div class="fr-flex__col-8">
      <h3>View Forecast</h3> 
   </div>
   <div class="fr-flex__col-4">
      <select class="form-control pull-right" [(ngModel)]="selectedOption"  (change)="onOptionChange()">
         <option disabled selected > Actions </option>
         <option *ngIf="editPrivilege" editAccrual> Edit </option>
     </select>
     <div class="preview_section pull-right" *ngIf="currentIndex!==-1">
      <button  [class.previous]="currentIndex !== 0" (click)="previousRecord()" [disabled]="currentIndex === 0"><i class="pi pi-arrow-up" pTooltip="Preview" tooltipPosition="top"></i></button>
      <span class="numbers_list">{{getCurrentPositionText()}}</span>
      <button [class.next]="currentIndex !== listOfForecasts.length - 1" (click)="nextRecord()" [disabled]="currentIndex === listOfForecasts.length - 1"><i class="pi pi-arrow-down" pTooltip="Next" tooltipPosition="top"></i></button>
      </div>
     <a class="btn btn-primary-link backtolist pull-right" (click)="BackToList()">  <i class="pi pi-arrow-left"></i> Back to list </a>
     </div>
</div>
<div class="scroll-start-here-sub-tabs"> 
<div class="fr-flex__container m-t-20">
    <div class="fr-flex__col-4">
       <div class="fr-form__view">
          <label> Month  <em> * </em></label>
          <span> <em> {{ forecastDetail?.forecastDate | date:'MMMM yyyy' }}  </em></span>
       </div>
    </div>
    <div class="fr-flex__col-4">
      <div class="fr-form__view">
         <label> Forecasted Amount    <em> * </em></label>
         <span *ngIf="this.forecastDetail?.updatedBy==null"> <em> -- </em></span>
         <span *ngIf="this.forecastDetail?.updatedBy!=null"> <em>  {{ getFormattedValue(forecastDetail?.forecastedAmount, currency) }} </em></span>
      </div>
   </div>
   <div class="fr-flex__col-4">
    <div class="fr-form__view">
       <label> Attachment  <i  pInputText pTooltip="Only 1 Attachment allowed." tooltipPosition="top" class="pi pi-info-circle"> </i></label>
       <span *ngIf="this.forecastAttachment?.fileName=='' || this.forecastAttachment?.fileName==null"> <em> -- </em></span>

       <div *ngIf="this.forecastAttachment?.fileName!=''">
         <a class="attachment-active" routerLinkActive="active" (click)="downloadFile(forecastAttachment?.fileName,forecastAttachment?.filePath)"> 
            <span [pTooltip]="forecastAttachment?.fileName?.length > 40 ? forecastAttachment?.fileName : ''" tooltipPosition="top">
               <em> {{ forecastAttachment?.fileName }}  </em>  
            </span> 
            <i pInputText pTooltip="Download" tooltipPosition="top" class="pi pi-download"></i>
            </a>
      </div>
    </div>
 </div>
   
 </div>

 <div class="fr-flex__container">
  <div class="fr-flex__col-12">
    <div class="fr-form__view">
       <label> Remarks  </label>
       <span class="full-width" *ngIf="remarks  == null">--</span> <span class="full-width" [innerHTML]="remarks" *ngIf="remarks  != null"> {{remarks }} </span>
       
    </div>
 </div>
 </div>

 <div class="fr-flex__container">
   <div class="fr-flex__col-4">
      <div class="fr-form__view">
         <label> Modified By</label>
      <span  *ngIf="updatedBy!=null && updatedBy != undefined && updatedBy != ''"> <em> {{updatedBy}}</em></span>
      <span  *ngIf="updatedBy==null|| updatedBy == undefined || updatedBy == ''"> <em> --</em></span>
      </div>
   </div>
   <div class="fr-flex__col-4">
    <div class="fr-form__view">
      <label> Modified Date & Time </label>
      <span *ngIf="updatedBy!=null && updatedBy != undefined && updatedBy != ''"> <em> {{forecastDetail?.updatedDate | date: _dateformart.DateTimeWithZone }} {{_dateformart.DisplayUTC}}</em></span>
      <span *ngIf="updatedBy ==null || updatedBy == undefined || updatedBy == ''"> <em> --</em></span>
    </div>
    </div>
   </div>
</div>
</div>
</body>
</html>