import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})

export class GenerateTableFormartNotificationService {

constructor(private http: HttpClient,private datePipe: DatePipe)
{
}

getTableHTML_KPI_Engagement(kpiInfo:any,dataCollectionFrequencyValue:any,dataCollection:any,kpiDetail:any,type:string): string {
    var target=this.getTargetThreshold(kpiInfo,dataCollection.dataCollectionDate,kpiDetail);
    if(type=='Engagement')
        {
    return  `
      <table border="1" style="border-collapse: collapse; width: 100%; text-align: center;">
        <thead>
          <tr>
            <th>Engagement</th>
            <th>KPI</th>
            <th>Target</th>
            <th>Data For</th>
            <th>Calculated KPI</th>
            <th>Health</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>${kpiInfo?.engagementName}</td>
            <td>${kpiInfo?.kpiName}</td>
            <td>${target}</td>
            <td>${dataCollectionFrequencyValue}</td>
            <td>${dataCollection.calculatedKPI != null ? dataCollection.calculatedKPI+''+kpiDetail?.unitOfMeasureCode : '-'}</td>
           <td style="text-align: center;">

           
          <!--[if mso]>
          <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" style="height:16px; width:16px; v-text-anchor:middle;" arcsize="50%" fillcolor="${dataCollection?.health}" stroke="f">
            <w:anchorlock/>
            <center style="color:#ffffff; font-family:sans-serif; font-size:16px;">•</center>
          </v:roundrect>
          <![endif]-->
          <![if !mso]>
          <div style="width: 12px; height: 12px; background-color: ${dataCollection?.health}; border-radius: 50%; margin: auto;"></div>
          <![endif]>

        </td>

          </tr>
        </tbody>
      </table>
    `;
        }
        else{//for contract KPI
            return  `
      <table border="1" style="border-collapse: collapse; width: 100%; text-align: center;">
        <thead>
          <tr>
            <th>Engagement</th>
            <th>Contract</th>
            <th>KPI</th>
            <th>Target</th>
            <th>Data For</th>
            <th>Calculated KPI</th>
            <th>Health</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>${kpiInfo?.engagementName}</td>
            <td>${kpiInfo?.contractTitle}</td>
            <td>${kpiInfo?.kpiName}</td>
            <td>${target}</td>
            <td>${dataCollectionFrequencyValue}</td>
            <td>${dataCollection.calculatedKPI != null ? dataCollection.calculatedKPI+''+kpiDetail?.unitOfMeasureCode : '-'}</td>
           <td style="text-align: center;">

           
          <!--[if mso]>
          <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" style="height:16px; width:16px; v-text-anchor:middle;" arcsize="50%" fillcolor="${dataCollection?.health}" stroke="f">
            <w:anchorlock/>
            <center style="color:#ffffff; font-family:sans-serif; font-size:16px;">•</center>
          </v:roundrect>
          <![endif]-->
          <![if !mso]>
          <div style="width: 12px; height: 12px; background-color: ${dataCollection?.health}; border-radius: 50%; margin: auto;"></div>
          <![endif]>

        </td>

          </tr>
        </tbody>
      </table>
    `;
        }
  }
  

  getTargetThreshold(kpiInfo: any, dataCollectionDate: Date,kpiDetail:any) {
    // Convert hyperCareStartDate to a Date object
    const [month, day, year] = kpiInfo.hyperCareStartDate.split('/').map(Number);
    const hyperCareStartDate = new Date(year, month - 1, day);
  
    // Convert hyperCareEndDate to a Date object
    const [month2, day2, year2] = kpiInfo.hyperCareEndDate.split('/').map(Number);
    const hyperCareEndDate = new Date(year2, month2 - 1, day2);

    const normalizedDataCollectionDate = new Date(dataCollectionDate);
  
    // Compare Date objects
    const isWithinHyperCare =  normalizedDataCollectionDate >= hyperCareStartDate && normalizedDataCollectionDate <= hyperCareEndDate;
  
    // Choose the appropriate threshold array
    const thresholds = isWithinHyperCare ? kpiInfo.hyperCareThreshold : kpiInfo.steadyStateThreshold;
  
    // Return comma-separated percentage values
    return thresholds
    .map((item: any) => {
      const matchedSymbolSource = kpiInfo.mathematicalSymbolSource.find(
        (source: any) => source.id === item.mathematicalSymbolId
      );
      const symbol = matchedSymbolSource ? matchedSymbolSource.code : '';
      return `${symbol}${item.percentageValue}${kpiDetail?.unitOfMeasureCode}`;
    })
    .join(",");
  }
  

}