import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})

export class GenerateTableFormartNotificationService {

constructor(private http: HttpClient,private datePipe: DatePipe)
{
}

getTableHTML_KPI_Engagement(kpiInfo:any,dataCollectionFrequencyValue:any,dataCollection:any,kpiDetail:any,type:string): string {
    var target=this.getTargetThreshold(kpiInfo,dataCollection.dataCollectionDate,kpiDetail.unitOfMeasureCode);
    if(type=='Engagement')
        {
    return  `
      <table border="1" style="border-collapse: collapse; width: 100%; text-align: center;">
        <thead>
          <tr>
            <th>Engagement</th>
            <th>KPI</th>
            <th>Target</th>
            <th>Data For</th>
            <th>Calculated KPI</th>
            <th>Health</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>${kpiInfo?.engagementName}</td>
            <td>${kpiInfo?.kpiName}</td>
            <td>${target}</td>
            <td>${dataCollectionFrequencyValue}</td>
            <td>${dataCollection.calculatedKPI != null ? dataCollection.calculatedKPI+''+kpiDetail?.unitOfMeasureCode : '-'}</td>
           <td style="text-align: center;">

           
          <!--[if mso]>
          <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" style="height:16px; width:16px; v-text-anchor:middle;" arcsize="50%" fillcolor="${dataCollection?.health}" stroke="f">
            <w:anchorlock/>
            <center style="color:#ffffff; font-family:sans-serif; font-size:16px;">•</center>
          </v:roundrect>
          <![endif]-->
          <![if !mso]>
          <div style="width: 12px; height: 12px; background-color: ${dataCollection?.health}; border-radius: 50%; margin: auto;"></div>
          <![endif]>

        </td>

          </tr>
        </tbody>
      </table>
    `;
        }
        else{//for contract KPI
            return  `
      <table border="1" style="border-collapse: collapse; width: 100%; text-align: center;">
        <thead>
          <tr>
            <th>Engagement</th>
            <th>Contract</th>
            <th>KPI</th>
            <th>Target</th>
            <th>Data For</th>
            <th>Calculated KPI</th>
            <th>Health</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>${kpiInfo?.engagementName}</td>
            <td>${kpiInfo?.contractTitle}</td>
            <td>${kpiInfo?.kpiName}</td>
            <td>${target}</td>
            <td>${dataCollectionFrequencyValue}</td>
            <td>${dataCollection.calculatedKPI != null ? dataCollection.calculatedKPI+''+kpiDetail?.unitOfMeasureCode : '-'}</td>
           <td style="text-align: center;">

           
          <!--[if mso]>
          <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" style="height:16px; width:16px; v-text-anchor:middle;" arcsize="50%" fillcolor="${dataCollection?.health}" stroke="f">
            <w:anchorlock/>
            <center style="color:#ffffff; font-family:sans-serif; font-size:16px;">•</center>
          </v:roundrect>
          <![endif]-->
          <![if !mso]>
          <div style="width: 12px; height: 12px; background-color: ${dataCollection?.health}; border-radius: 50%; margin: auto;"></div>
          <![endif]>

        </td>

          </tr>
        </tbody>
      </table>
    `;
        }
  }
  

  getTargetThreshold(kpiInfo: any, dataCollectionDate: Date,unitMeasureCode:any) {
    
    var thresholds;
    if(kpiInfo.hyperCareStartDate != undefined)
    {
    // Convert hyperCareStartDate to a Date object
    const [month, day, year] = kpiInfo.hyperCareStartDate.split('/').map(Number);
    const hyperCareStartDate = new Date(year, month - 1, day);
  
    // Convert hyperCareEndDate to a Date object
    const [month2, day2, year2] = kpiInfo.hyperCareEndDate.split('/').map(Number);
    const hyperCareEndDate = new Date(year2, month2 - 1, day2);

    const normalizedDataCollectionDate = new Date(dataCollectionDate);
  
    // Compare Date objects
    const isWithinHyperCare =  normalizedDataCollectionDate >= hyperCareStartDate && normalizedDataCollectionDate <= hyperCareEndDate;
  
    // Choose the appropriate threshold array
    thresholds = isWithinHyperCare ? kpiInfo.hyperCareThreshold : kpiInfo.steadyStateThreshold;
    }
    else{
      thresholds=kpiInfo.steadyStateThreshold;
    }
    // Return comma-separated percentage values
    return thresholds
    .map((item: any) => {
      const matchedSymbolSource = kpiInfo.mathematicalSymbolSource.find(
        (source: any) => source.id === item.mathematicalSymbolId
      );
      const symbol = matchedSymbolSource ? matchedSymbolSource.code : '';
      return `${symbol}${item.percentageValue}${unitMeasureCode}`;
    })
    .join(",");
  }
  
  getTableHTML_Invoice_EmailReport(invoiceData:any,contractTitle:any): string {
       
    return  `
      <table border="1" style="border-collapse: collapse; width: 100%; text-align: center;">
        <thead>
          <tr>
            <th>Engagement</th>
            <th>Contract</th>
            <th>Selected Period</th>
            <th>Total Invoiced Amount</th>
            <th>Budget Balance for Current Fiscal Year
(${invoiceData.CurrentFiscalYear})</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>${invoiceData.EngagementName}</td>
            <td>${contractTitle}</td>
            <td>${invoiceData.StartDate} to ${invoiceData.EndDate}</td>
            <td>${invoiceData.InvoicedAmount}</td>
            <td>${invoiceData.BudgetBalanceFY}</td>
           </tr>
        </tbody>
      </table>
    `;
        
  }




  getTableHTML_PersonalTurnover_EmailReport(payloadDataData:any,contractTitle:any,type:any): string {
       if(type==2){
    return  `
      <table border="1" style="border-collapse: collapse; width: 100%; text-align: center;">
        <thead>
          <tr>
            <th>Engagement</th>
            <th>Selected Period</th>
            <th>Employees Offboarded (x)</th>
            <th>Employees on Last Day (y)</th>
            <th>Turnover Rate (x/y %)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>${payloadDataData.EngagementName}</td>
            <td>${payloadDataData.StartDate} to ${payloadDataData.EndDate}</td>
            <td>${payloadDataData.OffboardedAssociates}</td>
            <td>${payloadDataData.TotalCountOnEndDate}</td>
            <td>${payloadDataData.PersonnelTurnover}</td>
           </tr>
        </tbody>
      </table>
    `;
       }
       else{
        return  `
      <table border="1" style="border-collapse: collapse; width: 100%; text-align: center;">
        <thead>
          <tr>
            <th>Engagement</th>
            <th>Contract</th>
            <th>Selected Period</th>
            <th>Employees Offboarded (x)</th>
            <th>Employees on Last Day (y)</th>
            <th>Turnover Rate (x/y %)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>${payloadDataData.EngagementName}</td>
            <td>${contractTitle}</td>
            <td>${payloadDataData.StartDate} to ${payloadDataData.EndDate}</td>
            <td>${payloadDataData.OffboardedAssociates}</td>
            <td>${payloadDataData.TotalCountOnEndDate}</td>
            <td>${payloadDataData.PersonnelTurnover}</td>
           </tr>
        </tbody>
      </table>
    `;
     
       }  
  }
  
  getTableHTML_KPIMitigationBasicInfoTbl(dataCollectionInfo:any,type:string): string {
    var target=this.getTargetThreshold(dataCollectionInfo,dataCollectionInfo.dataCollectionDate,dataCollectionInfo.unitOfMeasure);
    if(type=='Engagement')
        {
  if(dataCollectionInfo.inputAttributeType!=3){
    return  `
      <table border="1" style="border-collapse: collapse; width: 100%; text-align: center;">
        <thead>
          <tr>
            <th>Engagement</th>
            <th>KPI</th>
            <th>Target</th>
            <th>Data For</th>
            <th>Calculated KPI</th>
            <th>Health</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>${dataCollectionInfo?.engagementName}</td>
            <td>${dataCollectionInfo?.kpiName}</td>
            <td>${target}</td>
            <td>${dataCollectionInfo.Datafor}</td>
            <td>${dataCollectionInfo.kpiValue != null ? dataCollectionInfo.kpiValue : '-'}</td>
           <td style="text-align: center;">

           
          <!--[if mso]>
          <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" style="height:16px; width:16px; v-text-anchor:middle;" arcsize="50%" fillcolor="${dataCollectionInfo?.health}" stroke="f">
            <w:anchorlock/>
            <center style="color:#ffffff; font-family:sans-serif; font-size:16px;">•</center>
          </v:roundrect>
          <![endif]-->
          <![if !mso]>
          <div style="width: 12px; height: 12px; background-color: ${dataCollectionInfo?.health}; border-radius: 50%; margin: auto;"></div>
          <![endif]>

        </td>

          </tr>
        </tbody>
      </table>
    `;
  }
  else
  {
    return  `
    <table border="1" style="border-collapse: collapse; width: 100%; text-align: center;">
      <thead>
        <tr>
          <th>Engagement</th>
          <th>KPI</th>
          <th>Target</th>
          <th>Data For</th>
          <th>Calculated KPI</th>
          <th>Health</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>${dataCollectionInfo?.engagementName}</td>
          <td>${dataCollectionInfo?.kpiName}</td>
          <td>${target}</td>
          <td>${dataCollectionInfo.Datafor}</td>
          <td>NV</td>
         <td>NV</td>
        </tr>
      </tbody>
    </table>
  `;
  }
        }
        else{//for contract KPI
            
          if(dataCollectionInfo.inputAttributeType!=3){
          return  `
      <table border="1" style="border-collapse: collapse; width: 100%; text-align: center;">
        <thead>
          <tr>
            <th>Engagement</th>
            <th>Contract</th>
            <th>KPI</th>
            <th>Target</th>
            <th>Data For</th>
            <th>Calculated KPI</th>
            <th>Health</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>${dataCollectionInfo?.engagementName}</td>
            <td>${dataCollectionInfo?.contractTitle}</td>
            <td>${dataCollectionInfo?.kpiName}</td>
            <td>${target}</td>
            <td>${dataCollectionInfo.Datafor}</td>
            <td>${dataCollectionInfo.kpiValue != null ? dataCollectionInfo.kpiValue : '-'}</td>
           <td style="text-align: center;">

           
          <!--[if mso]>
          <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" style="height:16px; width:16px; v-text-anchor:middle;" arcsize="50%" fillcolor="${dataCollectionInfo?.health}" stroke="f">
            <w:anchorlock/>
            <center style="color:#ffffff; font-family:sans-serif; font-size:16px;">•</center>
          </v:roundrect>
          <![endif]-->
          <![if !mso]>
          <div style="width: 12px; height: 12px; background-color: ${dataCollectionInfo?.health}; border-radius: 50%; margin: auto;"></div>
          <![endif]>

        </td>

          </tr>
        </tbody>
      </table>
    `;
          }
          else{
            return  `
      <table border="1" style="border-collapse: collapse; width: 100%; text-align: center;">
        <thead>
          <tr>
            <th>Engagement</th>
            <th>Contract</th>
            <th>KPI</th>
            <th>Target</th>
            <th>Data For</th>
            <th>Calculated KPI</th>
            <th>Health</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>${dataCollectionInfo?.engagementName}</td>
            <td>${dataCollectionInfo?.contractTitle}</td>
            <td>${dataCollectionInfo?.kpiName}</td>
            <td>${target}</td>
            <td>${dataCollectionInfo.Datafor}</td>
            <td>NV</td>
           <td>NV</td>
          </tr>
        </tbody>
      </table>
    `;
          }
        }
  }


  getTableHTML_MitigationActionTbl(dataCollectionInfo:any): string {
    
 return  `
   <table border="1" style="border-collapse: collapse; width: 100%; text-align: center;">
     <thead>
       <tr>
         <th>Action</th>
         <th>Owner</th>
         <th>Target Date</th>
         <th>Status</th>
       </tr>
     </thead>
     <tbody>
       <tr>
         <td>${dataCollectionInfo.Actionfieldvalue}</td>
        <td>${dataCollectionInfo.ownerName}</td>
         <td>${this.datePipe.transform(dataCollectionInfo.actionTargetDate, "MMMM dd, yyyy")}</td>
         <td>${dataCollectionInfo.mitigationactionstatus}</td>
        </tr>
     </tbody>
   </table>
 `;
    
 
}

getTableHTML_AssociateTbl(associateDetail:any,contractName:any,engagementName:any,statusName:any,contractId:any): string {
    if(contractId==0)
      {
        if(statusName=='Onboard')
          {
            return  `
            <table border="1" style="border-collapse: collapse; width: 100%; text-align: center;">
              <thead>
                <tr>
                  <th>Engagement</th>
                  <th>Associate Name</th>
                  <th>Onboarding Date</th>
                </tr>
              </thead>
             <tbody>
                 <tr>
                  <td>${engagementName}</td>
                 <td>${associateDetail?.associateName}</td>
                  <td>${this.datePipe.transform(associateDetail.onBoardingDate, "MMMM dd, yyyy")}</td>
                 </tr>
              </tbody>
            </table>
          `;
          }
         else//engagement Offboard 
          {

            return  `
            <table border="1" style="border-collapse: collapse; width: 100%; text-align: center;">
              <thead>
                <tr>
                  <th>Engagement</th>
                  <th>Associate Name</th>
                  <th>Effective Offboarding Date</th>
                </tr>
              </thead>
             <tbody>
                 <tr>
                  <td>${engagementName}</td>
                 <td>${associateDetail?.associateName}</td>
                  <td>${this.datePipe.transform(associateDetail.offBoardingDate, "MMMM dd, yyyy")}</td>
                 </tr>
              </tbody>
            </table>
          `;
          } 
        
    

      }
    else
    {
      if(statusName=='Onboard')//contract Onboard
        {
          return  `
          <table border="1" style="border-collapse: collapse; width: 100%; text-align: center;">
            <thead>
              <tr>
                <th>Engagement</th>
                <th>Contract</th>
                <th>Associate Name</th>
                <th>Onboarding Date</th>
              </tr>
            </thead>
           <tbody>
               <tr>
                <td>${engagementName}</td>
                 <td>${contractName}</td>
               <td>${associateDetail?.associateName}</td>
                <td>${this.datePipe.transform(associateDetail.onBoardingDate, "MMMM dd, yyyy")}</td>
               </tr>
            </tbody>
          </table>
        `;
        }
       else//contract Offboard 
        {

          return  `
          <table border="1" style="border-collapse: collapse; width: 100%; text-align: center;">
            <thead>
              <tr>
                <th>Engagement</th>
                 <th>Contract</th>
                <th>Associate Name</th>
                <th>Effective Offboarding Date</th>
              </tr>
            </thead>
           <tbody>
               <tr>
                <td>${engagementName}</td>
                 <td>${contractName}</td>
               <td>${associateDetail?.associateName}</td>
                <td>${this.datePipe.transform(associateDetail.offBoardingDate, "MMMM dd, yyyy")}</td>
               </tr>
            </tbody>
          </table>
        `;
      
        }
      }
      
  
 }


 getTableHTML_DraftInvoiceTbl(draftInvoiceData:any,contractName:any,mailType:any): string {
  if(mailType==1)
        {
          return  `
          <table border="1" style="border-collapse: collapse; width: 100%; text-align: center;">
            <thead>
              <tr>
                <th>Engagement</th>
                <th>Contract</th>
                <th>Month</th>
                <th>Draft Invoices Amount</th>
              </tr>
            </thead>
           <tbody>
               <tr>
                <td>${draftInvoiceData.EngagementName}</td>
               <td>${contractName}</td>
                <td>${draftInvoiceData.AcrualMonthYear}</td>
                <td>${draftInvoiceData.AccrualAmount}</td>
               </tr>
            </tbody>
          </table>
        `;
    }
  else
  {
    
      
    return  `
    <table border="1" style="border-collapse: collapse; width: 100%; text-align: center;">
      <thead>
        <tr>
          <th>Engagement</th>
          <th>Contract</th>
          <th>Month</th>
          <th>Draft Invoices Amount</th>
          <th>Approver</th>
          <th>Approval Date</th>
        </tr>
      </thead>
     <tbody>
         <tr>
          <td>${draftInvoiceData.EngagementName}</td>
         <td>${contractName}</td>
          <td>${draftInvoiceData.AcrualMonthYear}</td>
          <td>${draftInvoiceData.AccrualAmount}</td>
          <td>${draftInvoiceData.ApprovedByUser}</td>
          <td>${this.datePipe.transform(draftInvoiceData.ApprovedDateTime, "MMMM dd, yyyy HH:mm")} UTC</td>
          
         </tr>
      </tbody>
    </table>
  `;
   
    }
    

}


getTableHTML_POUtilizationTbl(contractSummaryData:any,engagementName:any,currencySymbol:any): string {

          return  `
          <table border="1" style="border-collapse: collapse; width: 100%; text-align: center;">
            <thead>
              <tr>
                <th>Engagement</th>
                <th>Contract</th>
                <th>PO Utilization</th>
                <th>PO Number</th>
                <th>PO Value</th>
                <th>PO Balance</th>
              </tr>
            </thead>
           <tbody>
               <tr>
                <td>${engagementName}</td>
               <td>${contractSummaryData.title}</td>
                <td>${contractSummaryData.poValueUtilization}</td>
                <td>${contractSummaryData.poNumber}</td>
                <td>${currencySymbol+contractSummaryData?.poValue}</td>
                <td>${currencySymbol+contractSummaryData?.poBalance}</td>
               </tr>
            </tbody>
          </table>
        `;
   
    
    

}
}

