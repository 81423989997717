import { DatePipe, formatDate } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { } from 'primeng/calendar';
import { ContractService } from 'src/app/Contracts/Services/contract.service';
import { LoaderService } from 'src/app/Loader/loader.service';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';
import { AuditIdentifier, AuditLogEntities, AuditLogModule, AuditOperations, breadcrumnsCodes, CodeList, CodeListStatusTypes, Dateformat, FileExtension } from 'src/app/shared/constants/global.constants';
import { EngagementMeetingDetails, EngagementAddMeetingOccurenceActions, EngagementMeetingOccurrences, EngagementMeetingAttendees, EngagementMeetingAbsentees, EngagementMeetingOccurrenceAttachments, EngagementMeetingContacts } from '../../Modals/engagementmeeting';
import { GovernanceService } from '../../Services/governance.service';
import { ConfirmationService, MessageService, MenuItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { ChecknulloremptyComponent } from 'src/app/shared/checknullorempty/checknullorempty.component';
import { AssociateService } from 'src/app/Team/teams/Services/associate.service';
import { AuditLogService } from 'src/app/shared/ModelComparison/audit.log.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { EngagementService } from 'src/app/Engagement/Services/engagement.service';
import { MasterSupplierService } from 'src/app/MasterData/supplier/Services/master.supplier.service';
import { FileUploadService } from 'src/app/Engagement/Services/fileupload.service';

@Component({
  selector: 'app-eng-create-meeting',
  templateUrl: './eng-create-meeting.component.html',
  styleUrls: ['./eng-create-meeting.component.css']
})
export class EngCreateMeetingComponent {
  isSaveBtnDisable = false;
  @Input() engagementId: any;
  @Input() supplierName: any;
  @Output() cancelMeetingButtonClick = new EventEmitter<void>();
  @Output() saveMeetingButtonClick = new EventEmitter<void>();
  @Output() viewMeetingButtonClick: EventEmitter<any> = new EventEmitter<any>();

  engagementAddMeetingForm: FormGroup;
  recurring: any[] = [];
  options = ['Yes', 'No'];
  _dateformart: any;
  addMeetingSaved = false;
  addmeeting: any[] = [];
  EngagementId: any;

  value!: string;
  date: Date | undefined;
  engagementData: any;
  contactData: any;
 
  organizerOptions: any[] = [];
  selectedItems: [] = [];
  attendeesValues: any = '';
  absenteesValues: any = '';
  newAttachment: any;
  uploadedFiles: File[] = [];
  uploadedFileName: any;
  allowedExtensions: any;
  currentFileSize: any;
  multiMeetingFileData: any[] = [];
  maxFileSize: any
  timeStamp: any;
  codeListValues:any;
  codeListStatusTypes:any;
  _enumBreadcrumb :any;
  formData = new FormData();
  uploadedMeetingFile: any;
  meetingStatus: any[] = [];
  _addActionsFieldsMandatory: boolean = false;
   addMeetingActionsData: any[] = [];
  isAddActionsStatusSelectedAsClosed: boolean = false;
  _engagementmeetingActions = new EngagementAddMeetingOccurenceActions();
   engagementMeetingDetails = new EngagementMeetingDetails();
  _engagementMeetingOccurrences = new EngagementMeetingOccurrences();
  _engagementMeetingAttendees = new EngagementMeetingAttendees();
  _engagementMeetingAbsentees = new EngagementMeetingAbsentees();
  _engagementMeetingOccurrenceAttachments = new EngagementMeetingOccurrenceAttachments();
  _engagementMeetingOccurrenceDetails: EngagementMeetingOccurrences[] = [];
  _engagementMeetingAttendeesDetails: EngagementMeetingAttendees[] = [];
  _engagementMeetingAbsenteesDetails: EngagementMeetingAbsentees[] = [];
  _engagementMeetingOccurrenceAttachmentsDetails: EngagementMeetingOccurrenceAttachments[] = [];
  _engagementMeetingActionsDetails: EngagementAddMeetingOccurenceActions[] = [];
  _PostAddActionsMeetingData:any;
  isUploadButtonDisabled:boolean= false;
  attendeesCount:number=0;
  absenteesCount: number = 0;
  _allAttendees: string[] = [];
  _finalAttendees: any;
  changedEngagementMeetingBasicInfo:any;
  oldEngagementMeetingData:any;
  contactFormGroup:FormGroup;
  contactName: string = '';
  constructor(
    private governanceService: GovernanceService,
    private messageService: MessageService,
    private loaderService: LoaderService,
    private masterDataService: SponserService,
    private contractDataService: ContractService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private _fileService: FileUploadService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private breadcrumbService: BreadcrumbService,
    private checkNullOrEmptyValuesService: ChecknulloremptyComponent,
    private datepipe: DatePipe,
    private _associateService:AssociateService,
    private auditLogService:AuditLogService,
    private privilegeService:PrivilegeService,private engagementService: EngagementService,   private _mastersupplierService: MasterSupplierService, ) {

    this.engagementAddMeetingForm = this.fb.group({
      addMeetingOccurenceDate: ['', [Validators.required]],
      addMeetingTitle: ['', [Validators.required, Validators.maxLength(50), Validators.minLength(3), this.noOnlySpacesValidator]],
      addMeetingOrganizer: ['', [Validators.required]],
      addMeetingRecurring: ['', [Validators.required]],
      addMeetingFileSource: [''], addMeetingFileName: '',
      addMeetingAttachments: [''],
      addMeetingAgenda: ['', [Validators.required]],
      addMeetinMeetingNotes: [''],
      addMeetingDecisions: [''],
      _attendent: [''],
      _absent: [''],
    });
    this.contactFormGroup=this.fb.group({
      contractName:['',[Validators.required,Validators.maxLength(30),Validators.minLength(3),Validators.pattern(/^[A-Za-z- ]{3,30}$/)]],
    });
  }
  auditIdentifierDetails:any;
  auditEntities:any;
  auditOperations:any;
  auditModule:any;

   ngOnInit() {
    this.EngagementId =this.engagementId;
    this._enumBreadcrumb=breadcrumnsCodes;
    this._dateformart = Dateformat;
    this.codeListValues=CodeList;
    this.codeListStatusTypes=CodeListStatusTypes;
    this.auditIdentifierDetails=AuditIdentifier;
    this.auditEntities=AuditLogEntities;
    this.auditOperations=AuditOperations;
    this.auditModule=AuditLogModule;

    this.GetMeetingStatus(this.codeListValues.Tbl_Status, this.codeListStatusTypes.MASTS);
    this.GetEngagementDetailsById();
    this.formOldEngagementMeeting();
    this.oldEngagementMeetingData=JSON.stringify(this.engagementMeetingDetails);
  }

sponserId:number=0;
  GetEngagementDetailsById() {
   this.loaderService.showLoader();
    this.engagementService.GetEngagementById(this.EngagementId)
      .subscribe(res => {    
        this.engagementData = res.data;
        this.sponserId=this.engagementData.sponsorId;
        this.GetSupplierContacts(res.data.supplierId);
        this.GetSponserDetailsById();
      });
  }
  GetSupplierContacts(Id:number){
 
    this._mastersupplierService.getSupplierContactsDataById(Id)
    .subscribe(res=>{
      this.contactData = res.data;
    })
  }
  

sponserData:any;
sponsorContactData:any;
  GetSponserDetailsById(){
 
   this.masterDataService.getSponserData(this.sponserId)
   .subscribe(res=>{
     this.sponserData=res.data.sponserInfo;
     this.sponsorContactData=res.data.sponserContacts;
     this.GetTeamAssociatesList(this.engagementId,2)
   })
  }
  associateData:any;
  GetTeamAssociatesList(conractIdOrSupplierId:any,teamType:number){
   
    this._associateService.GetTeamAssociatesListByEngagementId(conractIdOrSupplierId,teamType).subscribe((res:any)=>{
      if(res.status){
      
      this.associateData=res.data;
      this.updateOrganizerOptions();
      }
    });
  }

  updateOrganizerOptions() {
    if (!this.organizerOptions) {
      this.organizerOptions = [];
    }
  
    const addOptions = (data: any[], nameExtractor: (item: any) => string, label: string) => {
      const options = data.map(item => `${nameExtractor(item)} ( ${label} )`);
      this.organizerOptions = this.organizerOptions.concat(options);
      this._allAttendees = this._allAttendees.concat(options);
    };
  
    if (this.engagementData && this.contactData) {
      addOptions(this.contactData, contact => contact.contactName, this.supplierName);
    }
  
    if (this.sponserData && this.sponsorContactData) {
      addOptions(this.sponsorContactData, contact => contact.name, this.sponserData.name);
    }
  
    // Added by Vittal for associates
    if (this.associateData) {
      addOptions(this.associateData, contact => contact.associateName, 'Associate');
    }
  
    let attendeesObjects: { name: string; code: number }[] = [];
    if (this._allAttendees.length > 0) {
      attendeesObjects = this._allAttendees.map((attendee, index) => ({
        name: attendee,
        code: index + 1,
      }));
    }
    
    this._finalAttendees = attendeesObjects;
    this._allAttendees = attendeesObjects.map(attendee => attendee.name);
    this.loaderService.hideLoader();
    this.loaderService.hidefinalLoader();
  }
  

  onRecurringChange(event: any) {
    this.engagementMeetingDetails.Recurring = event.value === 'Yes' ? true : false;
  }

  saveEngagementMeetingData() {  //Click on Main Save 
    this.loaderService.showLoader();
    this.isSaveBtnDisable = true;
    setTimeout(() => {
      this.isSaveBtnDisable = false;
    }, 3000);
    this.executeCanExist=false;
    this._addActionsFieldsMandatory = false;  
    
    this._addActionsFieldsMandatory = this.addMeetingActionsData.some((Actions) => {
      if (
          this.checkNullOrEmptyValuesService.isNullOrUndefinedOrEmpty(Actions.ActionName) || this.checkNullOrEmptyValuesService.isNullOrUndefinedOrEmpty(Actions.StatusMappingId) || (Actions.StatusMappingId.code === "CLD" &&
              (this.checkNullOrEmptyValuesService.isNullOrUndefinedOrEmpty(Actions.ClosureDate) || this.checkNullOrEmptyValuesService.isNullOrUndefinedOrEmpty(Actions.Remarks)))
      ) {
          return true;
      }
      return false;
  }); 
    this.addMeetingSaved = true
    

    if (this.engagementAddMeetingForm.valid && !this._addActionsFieldsMandatory) {    
      if (this.multiMeetingFileData.length > 0) {
        this._fileService.UploadMultipleFile(this.formData).subscribe((response: any) => {        
          if (response) {
          
            this.meetingSaveData();
            
            
          }
          else {
            this.loaderService.hideLoader();
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Add Meeting File Failed to save', detail: '' });
          }
        })
      }
      else{
        this.meetingSaveData();
      }

    }
    else{
      this.loaderService.hideLoader();
      this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Please enter required information', detail: '' });
    }
  }
  meetingSaveData() {
    this.loaderService.showLoader();
    this._engagementMeetingOccurrences.OccurrenceDate = this.datepipe.transform(this._engagementMeetingOccurrences.OccurrenceDate, "MM/dd/yyyy");
    this._PostAddActionsMeetingData =this.addMeetingActionsData;
    this._PostAddActionsMeetingData.forEach((record:any) => {
      record.StatusMappingId = record.StatusMappingId.id;
      record.TargetDate = this.datepipe.transform(record.TargetDate, "MM/dd/yyyy");
      record.ClosureDate = this.datepipe.transform(record.ClosureDate, "MM/dd/yyyy");
      this._engagementMeetingActionsDetails.push(record);
    }, this); 
    this.engagementMeetingDetails.CreatedBy = this.privilegeService.getLoginUserId();
     
    this.engagementMeetingDetails.EngagementId = this.EngagementId;
   
       if (this.attendeesValues !== "") {
      this._engagementMeetingAttendees.Attendees = this.attendeesValues.map((attendee: { name: any; }) => attendee.name).join(',');
      this._engagementMeetingAttendeesDetails.push(this._engagementMeetingAttendees);
    }

    if (this.absenteesValues !== "") {
      this. _engagementMeetingAbsentees.Absentees = this.absenteesValues.map((absentees: { name: any; }) => absentees.name).join(',');
      this. _engagementMeetingAbsenteesDetails.push(this. _engagementMeetingAbsentees);
    }

    this._engagementMeetingOccurrenceDetails.push(this._engagementMeetingOccurrences);

    this.engagementMeetingDetails.EngagementMeetingOccurrences = this._engagementMeetingOccurrenceDetails;
    this._engagementMeetingOccurrences.EngagementMeetingAttendees = this._engagementMeetingAttendeesDetails;
    this._engagementMeetingOccurrences.EngagementMeetingAbsentees = this._engagementMeetingAbsenteesDetails;
    this._engagementMeetingOccurrences.EngagementMeetingOccurrenceAttachments = this._engagementMeetingOccurrenceAttachmentsDetails;
    this._engagementMeetingOccurrences.EngagementMeetingOccurrenceActions = this._engagementMeetingActionsDetails;
    
    this.governanceService.EngagementMeetingData(this.engagementMeetingDetails).subscribe(
      res => {

        if(res.status){
          this.loaderService.hideLoader();
          this.messageService.add({
            key: 'tc',
            severity: 'success',
            summary: 'Meeting has been added',
            detail: '',
          });
          this.compareModels();
         const dataResult = {
          engagementId:this.engagementId,
          meetingId:parseInt(res.data)
        };
        this.viewMeetingButtonClick.emit(dataResult);
        this.loaderService.hideLoader();
        }
       
      }
      
    );
    
    
  }
  noOnlySpacesValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value && control.value.trim().length === 0) {
      return { onlySpaces: true };
    }
    return null;
  }

  onFileChange(event: any) {
    const files: FileList = event.target.files;
    if (files.length > 1) {
        this.resetFileFormFields('select and upload 1 file at a time.', 'error');
        return;
    }

    this.maxFileSize = this._fileService._fileSize; // 20 MB (value Bytes in binary)
    if (files.length > 0) {
        this.currentFileSize = files[0].size;
        this.uploadedFileName = files[0].name;
        this.allowedExtensions = [
            FileExtension.DOC, FileExtension.DOCX, FileExtension.PDF, 
            FileExtension.XLS, FileExtension.XLSX, FileExtension.PPT, 
            FileExtension.PPTX
        ];
        const uploadedExtension = this.uploadedFileName.split('.').pop().trim().toLowerCase();

        // Check if the uploaded file's extension is allowed
        if (!this.allowedExtensions.includes(uploadedExtension)) {
            this.resetFileFormFields('Invalid file type. Only .pdf, .xls, .xlsx, .doc, .docx files are allowed.', 'error');
            return;
        }

        if (this.currentFileSize > this.maxFileSize) {
            this.resetFileFormFields('File Size limited to 20 Mb', 'warn');
            return;
        }

        if (this.uploadedFiles.length + files.length > 3) {
            this.resetFileFormFields('Maximum 3 files allowed.', 'error');
            return;
        }
        for (let i = 0; i < files.length; i++) {
            this.uploadedFiles.push(files[i]);
            this.engagementAddMeetingForm.patchValue({
                addMeetingAttachments: files[0].name,
            });
            this.uploadedMeetingFile = files[0];
            this.multiMeetingFileData.push(this.uploadedMeetingFile);

            const utcDateTimeNow = new Date().toISOString();
            this.timeStamp = formatDate(utcDateTimeNow, this._dateformart.FiletimeStamp, this._dateformart.TimeStampZone);

            this.formData.append("file", this.uploadedMeetingFile, `Governance/EngagementMeeting/${this.timeStamp}_${this.uploadedMeetingFile.name}`);
            
            this._engagementMeetingOccurrenceAttachments.FileName = this.uploadedMeetingFile.name;
            this._engagementMeetingOccurrenceAttachments.FilePath = `Governance/EngagementMeeting/${this.timeStamp}_${this._engagementMeetingOccurrenceAttachments.FileName}`;
            
            this.newAttachment = {
                FileName: this._engagementMeetingOccurrenceAttachments.FileName,
                FilePath: this._engagementMeetingOccurrenceAttachments.FilePath
            };
            this._engagementMeetingOccurrenceAttachmentsDetails.push(this.newAttachment);
        }
    }

    this.isUploadButtonDisabled = this.uploadedFiles.length >= 3;
}

private resetFileFormFields(message: string, severity: string) {
    this.engagementAddMeetingForm.patchValue({
        addMeetingFileName: '',
        addMeetingFileSource: ''
    });
    this.messageService.add({ key: 'tc', severity, summary: message, detail: '' });
}


  removeFile(file: File) {
    const index = this.uploadedFiles.indexOf(file);
    if (index !== -1) {
      this.uploadedFiles.splice(index, 1);

      const attachmentIndex = this._engagementMeetingOccurrenceAttachmentsDetails.findIndex(attachment => attachment.FileName === file.name);
      if (attachmentIndex !== -1) {
        this._engagementMeetingOccurrenceAttachmentsDetails.splice(attachmentIndex, 1);
      }
    }
    if(this.uploadedFiles.length >= 3)
    {
      this.isUploadButtonDisabled = true;
    }
    else{
      this.isUploadButtonDisabled = false;
    }
  }
 // deleteProduct(addmeeting: any) {//Delete Add Action Meeting item form Add Action table
 deleteAction(currentActionIndex: number) {  
 this.confirmationService.confirm({
      header: 'Confirm',
      message: 'Are you sure you want to delete this action?',//+" "+ addmeeting.ActionName + '?',
      accept: () => {
      this.addMeetingActionsData.splice(currentActionIndex, 1);
      this.messageService.add({ severity: 'success', summary: 'Meeting Action Deleted', detail: '',});
      }
    });
  }
  AddMeetingActions() { //Click on Add Action button new row will be generated on top of the grid
    this.addMeetingActionsData.unshift({
      Id: 0,
      ActionName: '',
      Owner: "",
      TargetDate: null,
      StatusMappingId: '',
      ClosureDate: null,
      Remarks: '',
      EngagementMeetingOccurrenceId: 0,    
      IsDeleted: false
    });
  }
  GetMeetingStatus(enumId: number, type: string) { //Get Meeting Status's from code list for the Meetings
    this.masterDataService.getCodeListData(enumId, type).subscribe(
      res => {      
        if (res.data.length > 0) {
          this.meetingStatus = res.data;
        }
      }
    );
  };
generateBreadcrumb(component?:string,recordName?:string,tabName?:string,childRecName?:string,recordId?:number)
{
  
  this.breadcrumbService.generateBreadcrumb(component,recordName,tabName,childRecName,recordId);
}

getBreadcrumbs(): MenuItem[] {
  return this.breadcrumbService.getBreadcrumbs();
}
  executeCanExist: boolean = true;
  _oldEngagementMeeting:any
  canExist(): boolean | Promise<boolean> | Observable<boolean> {
  
    if (this.executeCanExist &&  (
      (this._oldEngagementMeeting != JSON.stringify(this.engagementMeetingDetails)) || 
      (this._oldEngagementOccurences != JSON.stringify(this._engagementMeetingOccurrences)) || 
      (this._oldEngagementAttendees != JSON.stringify(this._engagementMeetingAttendees)) || 
      (this._oldEngagementAbsentees != JSON.stringify(this._engagementMeetingAbsentees)) || 
      (this._oldEngagementAttachments != JSON.stringify(this._engagementMeetingOccurrenceAttachments)) || 
      (this._oldEngagementActions != JSON.stringify(this._engagementmeetingActions))
  ))
    {
      return new Promise<boolean>((resolve) => {
        this.confirmationService.confirm({
          message: 'Meeting will not be added, Do you want to Continue?',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.messageService.add({
              key: 'tc',
              severity: 'info',
              summary: 'Meeting not added',
              detail: '',
          });
          
            resolve(true); 
          },
          reject: () => {
            resolve(false); 
          },
        });
      });
    }
    else{
      return true;
    }
    
  }
  onCancelEngagementMeeting()
{
  
  if (
    (this._oldEngagementMeeting != JSON.stringify(this.engagementMeetingDetails)) || 
    (this._oldEngagementOccurences != JSON.stringify(this._engagementMeetingOccurrences)) || 
    (this._oldEngagementAttendees != JSON.stringify(this._engagementMeetingAttendees)) || 
    (this._oldEngagementAbsentees != JSON.stringify(this._engagementMeetingAbsentees)) || 
    (this._oldEngagementAttachments != JSON.stringify(this._engagementMeetingOccurrenceAttachments)) || 
    (this._oldEngagementActions != JSON.stringify(this._engagementmeetingActions))
)
  {
      this.confirmationService.confirm({
        message: 'Meeting will not be added, Do you want to Continue?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.cancelMeetingButtonClick.emit();
          this.messageService.add({
            key: 'tc',
            severity: 'info',
            summary: 'Meeting not added',
            detail: '',
        });
        },
        reject: () => {
        },
      });
  }
  else{
    this.cancelMeetingButtonClick.emit();
  }
}
_oldEngagementOccurences:any;
  _oldEngagementAttendees:any;
  _oldEngagementAbsentees:any;
  _oldEngagementAttachments:any;
  _oldEngagementActions:any;
formOldEngagementMeeting(){
  setTimeout(() => {
    this._oldEngagementMeeting=JSON.stringify(this.engagementMeetingDetails);
    this._oldEngagementOccurences=JSON.stringify(this._engagementMeetingOccurrences);
    this._oldEngagementAttendees=JSON.stringify(this._engagementMeetingAttendees);
    this._oldEngagementAbsentees=JSON.stringify(this._engagementMeetingAbsentees);
    this._oldEngagementAttachments=JSON.stringify(this._engagementMeetingOccurrenceAttachments);
    this._oldEngagementActions=JSON.stringify(this._engagementmeetingActions);
    }, 2000);
}

compareModels() {
  this.changedEngagementMeetingBasicInfo = this.auditLogService.compareModels(JSON.parse(this.oldEngagementMeetingData),this.engagementMeetingDetails,this.auditEntities.EngagementMeetings,this.auditIdentifierDetails.EngagementMeetings,this.auditOperations.Create,this.auditModule.GovernanceService);
}
get _attendees() {
  if(this._finalAttendees!=undefined)
  return this._finalAttendees.filter((a: any) => !this.absenteesValues.includes(a));
else
return [];
}
get _absentees() {
  if(this._finalAttendees!=undefined)
  return this._finalAttendees.filter((a: any) => !this.attendeesValues.includes(a));
else
return [];

}
contactHeader='';
contactSubmitted=false;
displayContractGroupDialog:boolean=false;
contractGroup:any;
currentField: any;
currentOwnerIndex:number=0;
openContractModel(field: string,actionIndex:number=0) {
  this.currentOwnerIndex=actionIndex;
  this.contactName='';
  this.contactHeader = 'Add Contact';
  this.currentField = field;
  this.contactFormGroup.reset();
  Object.keys(this.contactFormGroup.controls).forEach(key => {
    this.contactFormGroup?.get(key)?.setErrors(null);
  });
  this.contactSubmitted = false;
  this.contractGroup = { name: "" };
  this.displayContractGroupDialog = true;
}
  validateOnlyNumberandSpecialCharecters(control: AbstractControl): ValidationErrors | null{
    const alphaRegex = /[a-zA-Z]/; // Ensure at least one alphabetic character
    if (control.value && !alphaRegex.test(control.value)) {
      return { invalidNumberSpecialCharecters: true };
    }
    return null;
  }
newlyAddedContacts: any[] = [];
SaveMeetingContact() {
  this.contactSubmitted = true;

  if (this.contactFormGroup.valid) {
    const newContactName = this.contactFormGroup.value.contractName;
    const newCode = this._finalAttendees.length > 0 ? Math.max(...this._finalAttendees.map((contact: { code: any; }) => contact.code || 0)) + 1 : 1;

    const newContact: EngagementMeetingContacts = {
      id: 0, 
      contactName: newContactName,
      designation: "",
      countryCode: "",
      phoneNumber: "",
      email: "",
      meetingId: this.engagementMeetingDetails.Id ?? 0 
    };

    // Check for duplicates
    const isDuplicate= this.checkNameExists(newContactName)
    if (!isDuplicate) {
      switch (this.currentField) {
        case 'organizer':
          this.organizerOptions = [...this.organizerOptions, { label: newContact.contactName, value: newContact.contactName }];
          this.engagementMeetingDetails.Organizer = newContact.contactName;
          const Attendee = { name: newContact.contactName, code: newCode };
          this._finalAttendees = [...this._finalAttendees, Attendee];
          this._attendees.push(Attendee);
          const Absentee = { name: newContact.contactName, code: newCode };
          this._attendees.push(Absentee);
          break;
        case 'attendees':
          const newAttendee = { name: newContact.contactName, code: newCode };
          this._finalAttendees = [...this._finalAttendees, newAttendee];
          this.attendeesValues = [...this.attendeesValues, newAttendee];
          this._attendees.push(newAttendee);
          this.organizerOptions = [...this.organizerOptions, { label: newContact.contactName, value: newContact.contactName }];
          break;
        case 'absentees':
          const newAbsentee = { name: newContact.contactName, code: newCode };
          this._finalAttendees = [...this._finalAttendees, newAbsentee];
          this.absenteesValues = [...this.absenteesValues, newAbsentee];
          this._attendees.push(newAbsentee);
          this.organizerOptions = [...this.organizerOptions, { label: newContact.contactName, value: newContact.contactName }];
          break;
        case 'owner':
          this.organizerOptions = [...this.organizerOptions, { label: newContact.contactName, value: newContact.contactName }];
          this.addMeetingActionsData[this.currentOwnerIndex].Owner = newContact.contactName;
          const Attendees = { name: newContact.contactName, code: newCode };
          this._finalAttendees = [...this._finalAttendees, Attendees];
          this._attendees.push(Attendees);
          const Absentees = { name: newContact.contactName, code: newCode };
          this._attendees.push(Absentees);
          break;
        default:
          break;
      }
      this.newlyAddedContacts.push(newContact);
      this.engagementMeetingDetails.EngagementMeetingContacts = [...this.engagementMeetingDetails.EngagementMeetingContacts ?? [], newContact];
    } else {
      this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Contact Name already exists. Please enter a unique Name', detail: '' });
    }

    this.displayContractGroupDialog = false;
  }
}

closeContactDialog(){
  this.displayContractGroupDialog = false;
  this.messageService.add({
    key: 'tc',
    severity: 'info',
    summary: 'Contact Information has not been saved',
    detail: '',
});
  
}
checkNameExists(name: string): boolean {
  return this._finalAttendees.some((item: { name: string; }) => item.name === name);
}
}
