
<!DOCTYPE html>
<html lang="en">
<head>
    <title>Engagement KPI List</title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>
        <p-tabView class="fr-tabview__header__custom">
            <p-tabPanel>
                <div class="flex-box m-t-70"> 
                <div class="fr-flex__col-12"> 
                <div class="fr-flex__container">
                    <div class="fr-flex__col-8">
                        <span class="number_of_records"> <em> ({{ filteredRecordCount > -1 ? filteredRecordCount : totalRecordCount }} of {{ totalRecordCount }} Records) </em> </span>
                    </div>
                    <div class="fr-flex__col-4">
                      <a *ngIf="createPrivilege" class="btn btn-primary pull-right" (click)="addSupplierKPI()"> Add KPI</a>
                      <button  *ngIf="ExportPrivilage"  (click)="exportexcelKpi()" class="btn btn-outline-primary pull-right" [disabled]="isChecked"> <i class="pi pi-download" ></i> Export </button>
                      <button id="engKPIListTableFilter" class="btn btn-outline-primary pull-right" (click)="showfilter()"
                                [ngClass]="{'active-button': showHideFiltersControls}"> <i class="pi pi-filter"></i> </button>
                      <a *ngIf="KpiDarshBoardPrivilage" class="btn btn-primary-link pull-right" (click)="navigateToDashboard()">View KPI Trend</a>
                    </div>
                </div>
                <div *ngIf="showHideFiltersControls">
                    <div class="card"> 
                            <div class="filter-bg"> 
                    <!-- Filters -->
                    
                    <div class="fr-flex__container">
                        <div class="fr-flex__col-3">
                            <div class="fr-form__group margin-zero"> 
                             <span class="p-input-icon-left"> 
                                <i class="pi pi-search"></i> 
                            <input type="text" placeholder="Search by KPI Name" class="form-control key-search filter-text search-active" [class.search-active]="filterDetailsModel.kpiName !== undefined && filterDetailsModel.kpiName !== null && filterDetailsModel.kpiName !== ''" [(ngModel)]="filterDetailsModel.kpiName" (input)="onfilter()" trimSpace/> 
                       
                        </span> 
                    </div>
                </div>


                        <div class="fr-flex__col-3">
                             
                            <p-multiSelect appendTo="body" class="multiSelect-active muultiselect_header_hide" [showToggleAll]="false" panelStyleClass="custom-panel" [class.multiSelect-active]="filterDetailsModel.Kpi !== undefined && filterDetailsModel.Kpi !== null && filterDetailsModel.Kpi.length !== 0"  [options]="kpidata" name="kpi" [filter]="false"
                            [(ngModel)]="filterDetailsModel.Kpi" placeholder="Select KPI"
                            (onChange)="onfilter()"></p-multiSelect>
                        
                         </div>
                        <div class="fr-flex__col-3">
                             
                            <p-dropdown id="engKpiListFilterStatusDropdown" appendTo="body" class="filter-text dropdown-active" [class.dropdown-active]="filterDetailsModel.Status !== undefined && filterDetailsModel.Status !== null && filterDetailsModel.Status !== null && filterDetailsModel.Status !== ''" name="status" optionLabel="label" [options]="statusdata"
                                [(ngModel)]="filterDetailsModel.Status"  placeholder="Select Status"
                                (onChange)="onfilter()"></p-dropdown>
                            
        
                        </div>
                        <div class="fr-flex__col-3">
                             
                           <p-dropdown id="engKpiListFilterCurrentHealthDropdown" appendTo="body" class="filter-text dropdown-active" [class.dropdown-active]="filterDetailsModel.Health !== undefined && filterDetailsModel.Health !== null && filterDetailsModel.Health !== null && filterDetailsModel.Health !== ''" name="health" optionLabel="label" [options]="healthdata"
                                [(ngModel)]="filterDetailsModel.Health"  placeholder="Select Current Health"
                                (onChange)="onfilter()"></p-dropdown>
                                
                         </div>
                         <div class="fr-flex__col-3"> 
                            <a class="btn btn-primary-link" (click)="clearFilters()"> Clear All Filters </a> 
                        </div>
                    </div>
                    </div>
                
                        </div>
            </div>
               </div>
               </div>
               <div class="fr-flex__col-12">
                <div class="inside-grid-pad"> 
                <p-table id="engListKPITableName" class="primeng-table-custom custom-cells__con-kpi-list" [resizableColumns]="true" columnResizeMode="expand"
                styleClass="p-datatable-striped p-datatable-gridlines"
    [scrollable]="true"
  
    [value]="_engagementKPIListData"  
    [(selection)]="selectedSupplierKPI"
    editMode="row"
    [paginator]="true"
    #SupplierKpiTable
    [rows]="10"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Entries"
    [rowsPerPageOptions]="[10, 20, 50, 100, 200]"
    [(selection)]="checkedKpis"
     [tableStyle]="{ 'min-width': '50rem' }">
        <ng-template pTemplate="header">
            <tr>  
                <th id="engKPIListTableTHCheckbox" pFrozenColumn class="first-child-width">
                    <p-tableHeaderCheckbox (click)="CheckboxEnabled()"></p-tableHeaderCheckbox>
                </th>  
                <th id="engKPIListKpiName" pFrozenColumn pSortableColumn="kpiName" pResizableColumn>KPI Name <p-sortIcon field="kpiName"></p-sortIcon></th>            
                <th id="engKPIListKPI" pSortableColumn="kpi" pResizableColumn>KPI <p-sortIcon field="kpi"></p-sortIcon></th>               
                <th id="engKPIListDataCollectionFrequency" pSortableColumn="kpiDataCollectionFrequency" pResizableColumn>Data Collection Frequency <p-sortIcon field="kpiDataCollectionFrequency"></p-sortIcon></th>
                <th id="engKPIListKpiStartDate" pSortableColumn="kpiStartDate" pResizableColumn>KPI Tracking Start <p-sortIcon field="kpiStartDate"></p-sortIcon></th>
                <th id="engKPIListStatus" pSortableColumn="status" pResizableColumn>Status <p-sortIcon field="status"></p-sortIcon></th>
                <th id="engKPIListCurrentHealth" pSortableColumn="overallAverageHealth" pResizableColumn>Current Health <p-sortIcon field="overallAverageHealth"></p-sortIcon></th>
                <th id="engKPIListEdit" *ngIf="editPrivilege" class="last-child-width">Actions</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-engagementKPI > 
            <tr>
                <td id="engKPIListTableTDCheckbox" pFrozenColumn class="first-child-width">
                    <p-tableCheckbox [value]="engagementKPI" (click)="CheckboxEnabled()"></p-tableCheckbox>
                </td>
                <td id="engKPIListTableHyperLink" pFrozenColumn *ngIf="viewPrivilege" [pTooltip]="engagementKPI.kpiName.length > 47 ? engagementKPI.kpiName : ''" tooltipPosition="top"  routerLinkActive="active" (click)="ViewSupplierKPI(engagementKPI.id)"> <a class="table-active-link"> {{ engagementKPI.kpiName}} </a></td>
                <td *ngIf="!viewPrivilege" [pTooltip]="engagementKPI.kpiName.length > 47 ? engagementKPI.kpiName : ''" tooltipPosition="top">  {{ engagementKPI.kpiName}} </td>
                <td [pTooltip]="engagementKPI.kpi.length > 18 ? engagementKPI.kpi : ''" tooltipPosition="top"> {{ engagementKPI.kpi}} </td> 
                <td>{{ engagementKPI.kpiDataCollectionFrequency }}</td>
                
                <td>{{commonService.getFrequencyFromDate(engagementKPI.kpiStartDate,engagementKPI.kpiDataCollectionFrequency)}}</td>
                <td [ngClass]="{'expired': engagementKPI.status === 'Expired', 'active': engagementKPI.status === 'Active', 'inactive': engagementKPI.status === 'InActive'}"> <span>  {{ engagementKPI.status }} </span></td>
                <td class="grid-icon-center"> <span [ngClass]="{success__circle: engagementKPI.overallAverageHealth=='Green', danger__circle: engagementKPI.overallAverageHealth=='Red'}"></span></td>
                <td id="engKPIListTableEdit" *ngIf="editPrivilege" class="grid-icon-center">
                    <span 
                      class="pi pi-pencil" 
                      *ngIf="!isEditing" 
                      (click)="startEditing(engagementKPI.id)">
                    </span>
                    <div *ngIf="isEditing">
                      <a (click)="editEngagementKPIDataCollection(engagementKPI.id)"><i  class="pi pi-database" pTooltip="Edit Data Collection" tooltipPosition="top"></i></a>
                      <a (click)="editEngagementKPI(engagementKPI.id)"><i  class="pi pi-file-edit m-l-20" pTooltip="Edit KPI" tooltipPosition="top"></i></a>
                    </div>
                  </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
            <td colspan="8" class="text-center"> No Records </td>
            </tr>
            </ng-template>
                </p-table>
            </div> 
            </div>
                </p-tabPanel>
                </p-tabView>
<div>
    
</div>


<p-dialog appendTo="body" [style]="{ width: '80vw' }" header="Edit Data Collection - {{_engagementKpi.kpiName}}" [(visible)]="displayEditDataCollectionDialog" [modal]="true" >
    <div class="m-b-20">
        <span class="table-small-card"> Current Health </span>
           <span id="engEditKPIHealthInformation" class="table-small-card"> {{_readOnlySybmolSteadyStateThreshold=='%'?_overAllAverageKPI == null?'--':_overAllAverageKPI+_readOnlySybmolSteadyStateThreshold:_overAllAverageKPI == null?'--':_overAllAverageKPI+' ' + _readOnlySybmolSteadyStateThreshold}} 
           <span [ngClass]="{
                'success__circle': _overallAverageHealth === 'Green',
                'danger__circle': _overallAverageHealth === 'Red'
              }"></span>
    </span> 
     </div>

    <p-table class="primeng-table-custom custom-cells__contractattribute engeditDataCollection" id="engEditKPItableName" [resizableColumns]="true" columnResizeMode="expand" styleClass="p-datatable-striped p-datatable-gridlines" [scrollable]="true" [value]="_engagementKpiDataCollections">
      <ng-template pTemplate="header">
        <tr>
          <th id="engKPIListDataFor" pSortableColumn="dataCollectionDate" pResizableColumn>Data For <p-sortIcon field="dataCollectionDate"></p-sortIcon></th>
          <th id="engKPIListAttribute1" pSortableColumn="attribute1" pResizableColumn>Attribute 1 <p-sortIcon field="attribute1"></p-sortIcon><br>
            <span [pTooltip]="_readOnlyAttribute1!.length! > 30 ? _readOnlyAttribute1 : ''" tooltipPosition="top" *ngIf="_readOnlyAttribute1!=''">({{_readOnlyAttribute1}})</span>
          </th>
          <th id="engKPIListAttribute2" pSortableColumn="attribute2" pResizableColumn>Attribute 2 <p-sortIcon field="attribute2"></p-sortIcon><br>
            <span [pTooltip]="_readOnlyAttribute2!.length! > 30 ? _readOnlyAttribute2 : ''" tooltipPosition="top" *ngIf="_readOnlyAttribute2!=''">({{_readOnlyAttribute2}})</span>
          </th>
          <th id="engKPIListAttribute3" pSortableColumn="attribute3" pResizableColumn>Attribute 3 <p-sortIcon field="attribute3"></p-sortIcon><br>
            <span [pTooltip]="_readOnlyAttribute3!.length! > 30 ? _readOnlyAttribute3 : ''" tooltipPosition="top" *ngIf="_readOnlyAttribute3!=''">({{_readOnlyAttribute3}})</span>
          </th>
          <th id="engKPIListCalculatKPI" pSortableColumn="calculatedKPI" pResizableColumn>Calculated KPI <p-sortIcon field="calculatedKPI"></p-sortIcon></th>
          <th id="engKPIListMitigationAction" pSortableColumn="findingsCount" pResizableColumn>Mitigation Actions <p-sortIcon field="findingsCount"></p-sortIcon></th>
          <th id="engKPIListRemarks" pResizableColumn>Remarks</th>
          <th id="engEditKPIEditIcon" class="last-child-width">Edit</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-Kpidata>
        <tr>
          <td><a>{{ Kpidata.dataCollectionFrequencyValue }}</a></td>
          <td class="grid-text__center">{{ (Kpidata.attribute1 == null || Kpidata.attribute1 == "") ? '--' : getFormattedValue(Kpidata.attribute1, currencies) }}</td>
          <td class="grid-text__center">{{ (Kpidata.attribute2 == null || Kpidata.attribute2 == "") ? '--' : getFormattedValue(Kpidata.attribute2, currencies) }}</td>
          <td class="grid-text__center">{{ (Kpidata.attribute3 == null || Kpidata.attribute3 == "") ? '--' : getFormattedValue(Kpidata.attribute3, currencies) }} {{ Kpidata.enddate }}</td>
          <td class="grid-text__center">{{ _readOnlySybmolSteadyStateThreshold == '%' ? Kpidata.calculatedKPI == null ? '--' : Kpidata.calculatedKPI + '' + _readOnlySybmolSteadyStateThreshold : Kpidata.calculatedKPI == null ? '--' : Kpidata.calculatedKPI + ' ' + _readOnlySybmolSteadyStateThreshold }}</td>
          <td class="text-center">
            <a class="table-active-link" (click)="viewFindingList(Kpidata)">{{ Kpidata.findingsCount > 0 ? Kpidata.findingsCount : '0' }}</a>
          </td>
          <td class="grid-icon-center"><span pInputText pTooltip="{{ Kpidata.remarks }}" tooltipPosition="top" class="pi pi-info-circle"></span></td>
          <td id="engEditKPIEditIconTD" class="grid-icon-center"> <span (click)="editDataCollection(Kpidata)" class="pi pi-pencil"></span> </td>
        </tr>
      </ng-template>
    </p-table>
    <ng-template pTemplate="footer"> 
    <a type="button" class="btn btn-primary btn-save pull-right" (click)="isSaveBtnDisable ? null : onUpdateEngagementKpi()" >Save</a>
    <a type="button" class="btn btn-secondary pull-right" (click)="cancelKpiDataCollectionDialog()"> Cancel </a>
  </ng-template>
  </p-dialog>


  
        <!-- Mitigation Action Popup Start -->

        <div class="card flex justify-content-center">
            <p-dialog appendTo="body" header="Mitigation Actions" [(visible)]="displayMitigationListPopup" [modal]="true" [style]="{ width: '80vw' }" [draggable]="false">
              <!-- Table structure for displaying data -->
              <table aria-describedby="engKPIPopup" class="table-custom">
                <thead>
                  <tr>
                    <th id="engKPIListDataForPopup">Data For</th>
                    <th id="engKPIListAttriPopup">Attribute 1 <span [pTooltip]="_readOnlyAttribute1!.length! > 5 ? _readOnlyAttribute1 : ''" tooltipPosition="top" *ngIf="_readOnlyAttribute1!=''">({{_readOnlyAttribute1}})</span></th>
                    <th id="engKPIListAttriPopup2">Attribute 2 <span [pTooltip]="_readOnlyAttribute2!.length! > 5 ? _readOnlyAttribute2 : ''" tooltipPosition="top" *ngIf="_readOnlyAttribute2!=''">({{_readOnlyAttribute2}})</span></th>
                    <th id="engKPIListAttriPopup3">Attribute 3 <span [pTooltip]="_readOnlyAttribute3!.length! > 5 ? _readOnlyAttribute3 : ''" tooltipPosition="top" *ngIf="_readOnlyAttribute3!=''">({{_readOnlyAttribute3}})</span></th>
                    <th id="engKPIListCalKPI">Calculated KPI </th>
                    <th id="engKPIListRemarks">Remarks </th>
                </tr>
                </thead>
                <tbody>
                 
                  <tr *ngIf="selectedKpiReportData">
                    <td>{{selectedKpiReportData.dataCollectionFrequencyValue}}</td>
      
                     <td class="grid-text__center">{{(selectedKpiReportData.attribute1==null||selectedKpiReportData.attribute1=="")?'--':selectedKpiReportData.attribute1}}</td>
                     <td class="grid-text__center">{{(selectedKpiReportData.attribute2==null||selectedKpiReportData.attribute2=="")?'--':selectedKpiReportData.attribute2}}</td>
                     <td class="grid-text__center">{{(selectedKpiReportData.attribute3==null||selectedKpiReportData.attribute3=="")?'--':selectedKpiReportData.attribute3}}</td>
                    <td>
                     {{ selectedKpiReportData.calculatedKPI || '--' }}
                     <span *ngIf="selectedKpiReportData.calculatedKPI">{{ _readOnlySybmolSteadyStateThreshold }}</span>
                   </td>
                    <td>
                     <ng-container *ngIf="!selectedKpiReportData.remarks || selectedKpiReportData.remarks.length === 0; else showRemarks">
                       <i class="pi pi-info-circle"></i>
                     </ng-container>
                     <ng-template #showRemarks>
                       {{ selectedKpiReportData.remarks }}
                     </ng-template>
                   </td>
                  </tr>
                </tbody>
              </table>
              <div class="fr-flex__container">
              <div class="fr-flex__col-12 m-b-10 m-t-20">
               <a routerLinkActive="active" class="btn btn-primary pull-right"  (click)="AddMitigationActions()"> Add Action </a>
           </div>
           </div>
                      <div class="fr-flex__col-12 m-b-20"> 
                        <p-table class="primeng-table-custom custom-cells__supplierEditMeeting_action_popup" [scrollable]="true" [value]="visableMitigations" editMode="row">
                           <ng-template pTemplate="header">
                               <tr>                         
                                   <th id="engKPIListAction">Action<em> * </em> </th>
                                   <th id="engKPIListActionOwner">Action Owner</th>
                                   <th id="engKPIListTargetDate">Target Date</th>
                                   <th id="engKPIListActionStatus">Action Status <em> * </em> </th>
                                   <th id="engKPIListClosureDate">Closure Date</th>
                                   <th id="engKPIListDelete" class="last-child-width">Delete</th>
                                  
                                   
                               </tr>
                           </ng-template>
                           <ng-template pTemplate="body" let-addMitigationActionsData let-actionIndex="rowIndex"> 
                               
                               <tr>                    
                                   <td>
                                       <textarea class="form-control" [(ngModel)]="addMitigationActionsData.Action"  [required]="true" [maxlength]="300" name="Action" #ActionNameInput="ngModel" trimSpace ></textarea>
                                       <div *ngIf="ActionNameInput.errors?.['required'] && addMitigationSaved && addMitigationActionsData.Action== ''" class="text-danger highlight-red">This field is required.</div>  
      
                                                                                                               -->
                                     </td>    
                                   <td><p-dropdown id="engKpiListTableActionOwnerDropdown" appendTo="body" [filter]="true" appendTo="body" [(ngModel)]="addMitigationActionsData.ActionOwner" [options]="combinedContacts" optionLabel="contactName" optionValue="id" placeholder="Select Action Owner"></p-dropdown></td>
                                  
                                   <td>                                     
                                     <p-calendar appendTo="body" class="form-control" [showIcon]="true"  [(ngModel)]="addMitigationActionsData.TargetDate" [showIcon]="true" [dateFormat]=_dateformart.CalenderDateFormat></p-calendar>  
                                    </td>        
                                    <td>
                                      <p-dropdown id="engKpiListTableActionStatusDropdown" [filter]="true" [options]="actionStatus" appendTo="body" placeholder="Select Status" optionLabel="name" optionValue="id" [(ngModel)]="addMitigationActionsData.StatusMappingId" name="Status"></p-dropdown>                            
                                       <div  *ngIf="addMitigationSaved && addMitigationActionsData.StatusMappingId ==''" class="text-danger highlight-red">This field is required.                                                                
                                      </div>                                               
                                    </td> 
                                      <td>
                                        <div *ngIf="isClosedStatus(addMitigationActionsData.StatusMappingId) && (!addMitigationActionsData.ClosureDate || closureDateInput.invalid)">
                                           <div class="text-danger highlight-red">*</div>
                                         </div>
                                         <p-calendar appendTo="body" class="form-control" [showIcon]="true" pInputText [(ngModel)]="addMitigationActionsData.ClosureDate" [dateFormat]="_dateformart.CalenderDateFormat" #closureDateInput="ngModel" [ngClass]="{'highlight-red': isClosedStatus(addMitigationActionsData.StatusMappingId) && closureDateInput.invalid}"></p-calendar>            
                                        <div *ngIf="isClosedStatus(addMitigationActionsData.StatusMappingId) && (!addMitigationActionsData.ClosureDate || closureDateInput.invalid)">
                                           <div class="text-danger highlight-red">This field is required</div>
                                         </div>
                                      </td>
                                   <td class="grid-icon-center"><span class="pi pi-trash" (click)="deleteAction(actionIndex)"></span></td>
                               </tr>
                           </ng-template>
                           <ng-template pTemplate="emptymessage">
                               <tr>
                               <td colspan="6" class="text-center"> No Records</td>
                               </tr>
                               </ng-template>
                       </p-table>
                         </div>
                         <a type="button" class="btn btn-primary btn-save pull-right" (click)="saveMitigationActions()">Save</a>
                      <a type="button" class="btn btn-secondary pull-right" (click)="cancelMitigationDialog()"> Cancel </a>
            </p-dialog>
            
                        </div>
          
                        <!-- Mitigation Action Popup End -->

                        <div class="card flex justify-content-center">
                          <p-dialog appendTo="body" header="KPI Data Collection - {{_engagementKpi.kpiName}}" [style]="{ width: '80vw' }" [(visible)]="visible" [modal]="true" [draggable]="false" [resizable]="false" [closable]="false">
                             <div class="fr-flex__container m-b-20">
                                   <div class="fr-flex__col-12 custom-radio"> 
                                      <p-radioButton id="engEditKPICalculatedRadioBtn"
                                      name="KPIoption" 
                                      [value]="1"
                                      [disabled]="_readOnlyFormulaName=='N/A'"
                                      [(ngModel)]="_engagementKpiDataCollection.inputAttributeValueType"
                                      inputId="calculatedKPI"
                                      (click)="onRadioButtonClick('1')"
                                    ></p-radioButton>
                                    <label for="calculatedKPI">Enter Calculated KPI </label>
                                    <p-radioButton
                                    name="KPIoption" id="engEditKPIAttributeValueRadioBtn"
                                    [value]="2"
                                    [disabled]="_readOnlyFormulaName=='N/A'"
                                    [(ngModel)]="_engagementKpiDataCollection.inputAttributeValueType"
                                    inputId="attributeValues"
                                    (click)="onRadioButtonClick('2')"
                                  ></p-radioButton>
                                  <label for="attributeValues">Enter Attribute Values</label>
                                  <p-radioButton
                                  name="KPIoption" id="engEditKPINoVolumeRadioBtn"
                                  [disabled]="_readOnlyFormulaName=='N/A'"
                                  [value]="3"
                                  [(ngModel)]="_engagementKpiDataCollection.inputAttributeValueType"
                                  inputId="novolume"
                                  (click)="onRadioButtonClick('3')"
                                ></p-radioButton>
                                <label for="novolume">No Volume</label>
                                 </div>
                               </div>
                             <form [formGroup]="kpiDataCollectionFrom">
                            <table id="engEditKPIPopupTableName" aria-describedby="engEditKPIcustomTable" class="table-custom">
                             <tr>                
                                <th id="engEditKPIDataForPopupwindow">Data For</th>
                                <th id="engEditKPIAttributeOnePopupwindow">Attribute 1 <br> <span [pTooltip]="_readOnlyAttribute1!.length! > 5 ? _readOnlyAttribute1 : ''" tooltipPosition="top" *ngIf="_readOnlyAttribute1!=''">({{_readOnlyAttribute1}})</span><em *ngIf="_engagementKpiDataCollection.inputAttributeValueType==2 && _readOnlyAttribute1!=''"> * </em></th>
                                <th id="engEditKPIAttributeTwoPopupwindow">Attribute 2 <br> <span [pTooltip]="_readOnlyAttribute2!.length! > 5 ? _readOnlyAttribute2 : ''" tooltipPosition="top" *ngIf="_readOnlyAttribute2!=''">({{_readOnlyAttribute2}})</span><em  *ngIf="_engagementKpiDataCollection.inputAttributeValueType==2 && _readOnlyAttribute2!=''"> * </em></th>
                                <th id="engEditKPIAttributeTherePopupwindow">Attribute 3 <br> <span [pTooltip]="_readOnlyAttribute3!.length! > 5 ? _readOnlyAttribute3 : ''" tooltipPosition="top" *ngIf="_readOnlyAttribute3!=''">({{_readOnlyAttribute3}})</span></th>
                                <th id="engEdidEmptyTh1">&nbsp;</th>
                                <th id="engEditKPICalculatedPopupwindow">Calculated KPI <em *ngIf="_engagementKpiDataCollection.inputAttributeValueType==1" > *</em></th>
                                <th id="engEdidEmptyTh2" class="display-none">&nbsp;</th>
                                
                            </tr>
                            <tr>
                            
                             <td> <span> {{_engagementKpiDataCollection.dataCollectionFrequencyValue}} </span> </td>
                             <td> 
                              
                             <p-inputNumber *ngIf="_kpiAttributeTypeCode=='INT'" inputId="minmaxfraction" mode="decimal" [minFractionDigits]="2" class="form-control-inputNumber"  [class.read-only-grid]="_engagementKpiDataCollection.inputAttributeValueType!=2 || _readOnlyAttribute1==''" [readonly]="_engagementKpiDataCollection.inputAttributeValueType==1 || _readOnlyAttribute1==''" formControlName="_attribute1" (input)="onNumericInputpopUp($event,'_attribute1')" [maxlength]="8" class="form-control"  pInputText [(ngModel)]="_engagementKpiDataCollection.attribute1" trimSpace  />
                             <p-calendar appendTo="body" [disabled]="_engagementKpiDataCollection.inputAttributeValueType!=2 || _readOnlyAttribute1==''" class="form-control read-only-calendar" [class.kpi-read-only-calendar]="_engagementKpiDataCollection.inputAttributeValueType==1"   *ngIf="_kpiAttributeTypeCode=='DTE'" class="form-control"  formControlName="_attribute1"   [dateFormat]=_dateformart.CalenderDateFormat  [(ngModel)]="_engagementKpiDataCollection.attribute1"  [showIcon]="true" (ngModelChange)="onAttributeDateSelection(_engagementKpiDataCollection.attribute1,1)"></p-calendar>
                             <small class="p-error custom-error" *ngIf=" dataCollectionSubmitted &&  _readOnlyAttribute1!='' && kpiDataCollectionFrom?.get('_attribute1')?.hasError('required')">This field is required.</small>
                             </td>
                             <td>
                                
                                <p-inputNumber *ngIf="_kpiAttributeTypeCode=='INT'" inputId="minmaxfraction" mode="decimal" [minFractionDigits]="2" class="form-control-inputNumber" [class.read-only-grid]="_engagementKpiDataCollection.inputAttributeValueType!=2 || _readOnlyAttribute2==''" [readonly]="_engagementKpiDataCollection.inputAttributeValueType==1 || _readOnlyAttribute2==''" formControlName="_attribute2" (input)="onNumericInputpopUp($event,'_attribute2')" [maxlength]="8" class="form-control"  pInputText [(ngModel)]="_engagementKpiDataCollection.attribute2" trimSpace /> 
                               <p-calendar appendTo="body" [disabled]="_engagementKpiDataCollection.inputAttributeValueType!=2 || _readOnlyAttribute2==''" class="form-control read-only-calendar" [class.kpi-read-only-calendar]="_engagementKpiDataCollection.inputAttributeValueType==1"   *ngIf="_kpiAttributeTypeCode=='DTE'" class="form-control"  formControlName="_attribute2"   [dateFormat]=_dateformart.CalenderDateFormat  [(ngModel)]="_engagementKpiDataCollection.attribute2"  [showIcon]="true" (ngModelChange)="onAttributeDateSelection(_engagementKpiDataCollection.attribute2,2)"></p-calendar>
                                <small class="p-error custom-error" *ngIf=" dataCollectionSubmitted && _readOnlyAttribute2!='' &&  kpiDataCollectionFrom?.get('_attribute2')?.hasError('required')">This field is required.</small>
                             </td>
                             <td> 
                               
                               
                               <p-inputNumber *ngIf="_kpiAttributeTypeCode=='INT'" inputId="minmaxfraction" mode="decimal" [minFractionDigits]="2" class="form-control-inputNumber" [class.read-only-grid]="true" [readonly]="true" formControlName="_attribute3" (input)="onNumericInputpopUp($event,'_attribute3')" [maxlength]="8" class="form-control"  pInputText [(ngModel)]="_engagementKpiDataCollection.attribute3" trimSpace />
                               <p-calendar appendTo="body" [disabled]="true" class="form-control read-only-calendar" [class.kpi-read-only-calendar]="_engagementKpiDataCollection.inputAttributeValueType==1 || _readOnlyAttribute3==''"   *ngIf="_kpiAttributeTypeCode=='DTE'" class="form-control"  formControlName="_attribute3"   [dateFormat]=_dateformart.CalenderDateFormat  [(ngModel)]="_engagementKpiDataCollection.attribute3"  [showIcon]="true" (ngModelChange)="onAttributeDateSelection(_engagementKpiDataCollection.attribute3,3)"></p-calendar>
                                <small class="p-error custom-error" *ngIf=" dataCollectionSubmitted && kpiDataCollectionFrom?.get('_attribute3')?.hasError('required')">This field is required.</small>
                             </td>
                    
                           
                    
                            <td>
                             <button *ngIf="_kpiAttributeTypeCode=='INT'" [disabled]="!isAttributeValueSelected" class="btn btn-primary width110" (click)="btnCalculateKPI()"> <em> Calculate </em> <i class="pi pi-arrow-right"> </i> </button>
                            </td>
                    
                    
                             <td> <input type="text" class="form-control" [class.read-only-grid]="_engagementKpiDataCollection.inputAttributeValueType!=1" [readonly]="_engagementKpiDataCollection.inputAttributeValueType!=1" formControlName="_calculatedKPI" (input)="onNumericInputpopUp($event,'_calculatedKPI')" [maxlength]="8" class="form-control"  pInputText [(ngModel)]="_engagementKpiDataCollection.calculatedKPI" trimSpace /> 
                                <small class="p-error custom-error"  *ngIf=" dataCollectionSubmitted && _engagementKpiDataCollection.inputAttributeValueType==1 && kpiDataCollectionFrom?.get('_calculatedKPI')?.hasError('required')">This field is required.</small>
                             </td>
                             <td><div>{{_readOnlySybmolSteadyStateThreshold}}</div></td>
                           
                           </tr>
                                 
                          </table>
                          
                         <div class="fr-flex__container m-t-20">
                          <div class="fr-flex__col-12">
                             <div class="fr-form__group">
                                <label> Remarks <em> * </em> <span class="max-length"> {{_engagementKpiDataCollection.remarks==null?0:_engagementKpiDataCollection.remarks.length}}/300 </span></label>
                                <textarea formControlName="_remarks" [maxlength]="300" class="form-control" InputTextarea  [(ngModel)]="_engagementKpiDataCollection.remarks" trimSpace ></textarea>
                                <small class="p-error" *ngIf=" dataCollectionSubmitted && kpiDataCollectionFrom?.get('_remarks')?.hasError('required')">This field is required.</small>
                             </div>
                          </div>
                       </div>
                    
                       <div *ngIf="isAttributeValueSelected && _kpiAttributeTypeCode=='INT'" class="fr-flex__container">
                          <div class="fr-flex__col-12">
                             <div class="note">
                                 <em> <strong> Note : </strong> Please click on the “Calculate” button to see the updated KPI value, anytime there is a change in the Attribute values. </em>
                             </div>
                             
                       </div>
                       </div>
                    
                    
                        </form>
                       <div class="fr-flex__container footer_btns">
                          <div class="fr-flex__col-12">
                             <a  type="button" class="btn btn-primary btn-save pull-right" [class.disabled]="isSaveKpiDataCollectionPopupBtnDisable" (click)="isSaveKpiDataCollectionPopupBtnDisable ? null : saveKpiDataCollection()"> Save</a>
                             <a type="button" class="btn btn-secondary pull-right" (click)="cancelDialog()"> Cancel </a>
                          </div>
                       </div>
                          </p-dialog>
                     
                    </div>
</body>
</html>
