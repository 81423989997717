



export class EngagementsListModel{
    Id?:number;
    EngagementName?:string;
    EngagementID?:string;
    Notes?:string;
    EngagementStartDate?:Date;
    NumberOfContracts?:number=0
    NumberOfGroups?:number=0;
    TotalEngagementValue?:number=0
    DefaultCurrency?:string;
    
}
export class EngagementPostModel{
    Id?:number;
    sponsorId?:number;
    supplierId?:number;
    engagementName?:string="";
    engagementId?:string="";
    engagementStartDate?: any;
    statusMappingId: number=0;
    defaultCurrency?:number;
    services?: number[];
    notes?:string="";
    createdBy: number=0;
    createdDate?: Date;
    updatedBy: number=0;
    updatedDate?: Date;
    sponsorContacts: any[] = [];
    supplierContacts:any[]=[];
    engagementMasterServiceAgreements?: EngagementMasterServiceAgreement[];
    isDeleted: boolean=false;
    isSendNotification:boolean=false;
    supplierForAudit:any;
    sponsorForAudit:any;
}
export class EngagementViewModel{
    Id?:number;
    SponsorId?:string;
    SupplierId?:string;
    EngagementName?:string;
    EngagementID?:string;
    EngagementStartDate?:Date;
    Status?:string;
    DefaultCurrency?:string;
    Services?:string;
    Notes?:string;
}

export class StakeholderContacts
{
id?:number;
name?:string;
designation?:string;
countryCode?:string;
phoneNumber?:string;
email?:string;
sponserId?:number;
createdBy?:number;
createdDate?:Date;
updatedBy?:number;
updatedDate?:Date;
isDeleted?:Boolean;
isSendNotification?:Boolean;
localId?:number;
}
export class EngagementMasterServiceAgreement {
    id: number=0;
    title?: string="";
    startDate?: any;
    endDate?: any;
    fileName?: string="";
    filePath?: string="";
    description: string="";
    supplierProfileId: number=0;
    sponsorContactId?: number;
    supplierContactId?: number=0;
    paymentTermId?: number;
    functionId?: number;
    statusMappingId: number=0;
    paymentTerm?:string;
    msaStatus?:string;
    fileNameUI?: string;
    createdBy: number=0;
    createdDate?: Date;
    updatedBy: number=0;
    updatedDate?: Date;
    isDeleted: boolean=false;
    supplierContactEmail: string="";
    supplierContactName:string="";
    isPrevFileDelete:boolean=false;
    uploadedMSAFile:any;
  
  }
  export class Contact {
    // forEach(arg0: (item: any) => void) {
    //   throw new Error('Method not implemented.');
    // }
    id: number=0;
    contactName?: string="";//propery for supplier contact
    name?: string="";//propery for sponsor contact
    countryCode: string="";
    phoneNumber: string="";
    email: string="";
    designation:string="";
    supplierProfileId?:number=0;
    sponserId?:number=0;
    createdBy:number=0;
  }


  export class ContractGroupModel{
    Id?:number;
    engagementId?:number;
    name?:string="";
    createdBy: number=0;
    createdDate?: Date;
    updatedBy: number=0;
    updatedDate?: Date;
    isDeleted: boolean=false;
}
  
 
  export class KpiChildComponents
  {
      supKpiList:number=1;
      supKpiAdd:number=2;
      supKpiEdit:number=3;
      supKpiView:number=4;
  }
  
  
  export class AuditChildComponents
  {
      supAuditList:number=1;
      supAuditAdd:number=2;
      supAuditEdit:number=3;
      supAuditView:number=4;
  }
  
  export class MeetingChildComponents
  {
      supMeetingList:number=1;
      supMeetingAdd:number=2;
      supMeetingEdit:number=3;
      supMeetingView:number=4;
      supMeetingEditRecYes:number=5;
  }

export class EngagementSupplierContact
{
id?:number;
supplierContactId?:number;
createdBy?:number;
engagementId?:number;
isSendNotification?:Boolean;
sendNotificationOptions?: number[];
}

export class EngagementSponsorContact
{
id?:number;
sponsorContactId?:number;
createdBy?:number;
engagementId?:number;
isSendNotification?:Boolean;
sendNotificationOptions?: number[];
}
 