<div class="container page-title">
   <div class="login-wrapper">
      <div class="fr-flex__container">
         <div class="fr-flex__col-6">
            <div class="login_bg"> 
               <img class="login_bg_img" src="../../../assets/images/KontractPro_Login_bg.png" alt="Responsive Image">
            </div>
         </div>
         <div class="fr-flex__col-6">
            <div class="login-form-bg container">
               <div class="fr-flex__container m-t-50">
                  <div class="fr-flex__col-12">
                     <div class="login-form">
                        <div class="fr-flex__container">
                           <div class="fr-flex__col-12 text-center">
                              <div class="logo-with__text"> 
                              <img class src="../../../assets/images/Kontract Pro_Logo.png" alt="" height="50">
                              <span class="img_left_border"> <em> Freyr <span> KontractPro </span> </em>  </span>
                           </div>
                           <p class="headingtxt"> Please login to your account </p>
                           </div>
                        </div>
                        <div>
                           <div  class="fr-flex__container">
                              <div class="fr-flex__col-12">
                                 <div class="fr-form__group">
                                    <div class="fr-flex__col-12"> 
                                       <input type="text"  class="form-control" placeholder="User ID" pInputText [(ngModel)]="value" trimSpace/>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="fr-flex__container">
                              <div class="fr-flex__col-12">
                                 <div class="fr-form__group">
                                    <div class="fr-flex__col-12"> 
                                       <input type="password"  class="form-control" placeholder="Password" pInputText [(ngModel)]="value" />
                                       <a href="../Forgotpassword" class="link-text pull-right m-t-10">Forgot Password?</a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="fr-flex__container">
                           <div class="fr-flex__col-12">
                              <a href="../home" class="btn-primary-block">Login</a>
                           </div>
                        </div>
                        <div class="fr-flex__container"> 
                           <div class="fr-flex__col-12"> 
                        <div class="button-container">
                           <button class="outlined-button">Single Sign-On</button>
                           <button class="outlined-button">Create New Account</button>
                       </div>
                     </div>
                     </div>
                       

                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>