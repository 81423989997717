export class ConversionRate {
  AED?: number;
  ARS?: number;
  AUD?: number;
  BGN?: number;
  BRL?: number;
  BSD?: number;
  CAD?: number;
  CHF?: number;
  CLP?: number;
  CNY?: number;
  COP?: number;
  CZK?: number;
  DKK?: number;
  DOP?: number;
  EGP?: number;
  EUR?: number;
  FJD?: number;
  GBP?: number;
  GTQ?: number;
  HKD?: number;
  HRK?: number;
  HUF?: number;
  IDR?: number;
  ILS?: number;
  INR?: number;
  ISK?: number;
  JPY?: number;
  KRW?: number;
  KZT?: number;
  MXN?: number;
  MYR?: number;
  NOK?: number;
  NZD?: number;
  PAB?: number;
  PEN?: number;
  PHP?: number;
  PKR?: number;
  PLN?: number;
  PYG?: number;
  RON?: number;
  RUB?: number;
  SAR?: number;
  SEK?: number;
  SGD?: number;
  THB?: number;
  TRY?: number;
  TWD?: number;
  UAH?: number;
  USD?: number;
  UYU?: number;
  ZAR?: number;
}
