import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { LoaderService } from 'src/app/Loader/loader.service';
import { CodeList, CodeListStatusTypes, Dateformat, breadcrumnsCodes, AuditIdentifier, AuditLogEntities, AuditOperations, AuditLogModule } from '../../../shared/constants/global.constants';
import { SponserService } from '../../../MasterData/Services/sponser.service';
import { ContractService } from '../../../Contracts/Services/contract.service';
import { TeamAssociateDetails } from '../Models/Associate';
import { SponsorContact } from 'src/app/MasterData/supplier/Models/master-supplier-profile';
import { AssociateService } from '../Services/associate.service';
import { Observable} from 'rxjs';
import { DatePipe } from '@angular/common';
import { NotificationService } from 'src/app/shared/Notifications/notification.service';
import { AuditLogService } from 'src/app/shared/ModelComparison/audit.log.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { EngagementService } from 'src/app/Engagement/Services/engagement.service';
import { TabAndStateService } from 'src/app/shared/TabAndStateService/TabAndState.Service';

@Component({
  selector: 'app-associate-add-edit',
  templateUrl: './associate-add-edit.component.html',
  styleUrls: ['./associate-add-edit.component.css']
})
export class AssociateAddEditComponent
{


   date: Date | undefined;
   @Input({ required: true }) FromType: number = 0;
   @Input({ required: true }) entityId: any;
   @Input({ required: true }) actionType: any;
   @Input({ required: true }) associateId: any;
   @Input() SupplierId: any;
   @Input() SponsorId: any;
   @Input() EngagementId:any
   @Output() cancelTeamButtonClick = new EventEmitter<void>();
  @Output() saveTeamButtonClick = new EventEmitter<void>();
  @Output() viewTeamButtonClick: EventEmitter<any> = new EventEmitter<any>();
  myTeamAssociateForm:FormGroup;
  _enumBreadcrumb :any;
  _dateformart :any;
  associatesStatusData:any;
  allocationTypeData:any;
  engagementId:any;
  engagementContractList:any;
  teamAssociateData=new TeamAssociateDetails();
  countryData:any;
  submitted = false;
  isSaveBtnDisable = false;
  contractDetailId:any;
  sponsorContactsDataSource: SponsorContact[] = [];
  oldTeamAssociateModel:any;
  associateRolesData:any;
  sponsorsAssestsData:any;
  createdBy: any;
  updatedBy: any;

  isStatusOffBoard=false;
  olddate:Date=new Date('1900-01-01');
  disableContract=false;
  executeCanExist: boolean = true;
  associateTitle:string="Add Associate";
  codeListValues:any;
  codeListStatusTypes:any;
  countryCodeMandatoryflag=false;
  countryCodeMandatoryastrikflag=false;
  engagementIds = [535,534,531,532];
  allocationTypeDisableFlag: boolean = false;
  listOfAssociates:any;
  currentIndex: number = -1;
  constructor(private router: Router, 
    private fb: FormBuilder, 
    private confirmationService: ConfirmationService, 
    private messageService: MessageService, 
    private loaderService: LoaderService, 
    private breadcrumbService: BreadcrumbService,
    private activeRouter: ActivatedRoute,
    private masterDataService: SponserService,
    private contractService: ContractService,
    private _service:AssociateService,
    private datepipe: DatePipe,
    private notificationService:NotificationService,
    private auditLogService: AuditLogService,
    private privilegeService: PrivilegeService,
    private engagementService: EngagementService,
    private tabAndStateService: TabAndStateService,)
    {
    this.myTeamAssociateForm = this.fb.group({
      associateName: ['', [Validators.required, Validators.maxLength(50),Validators.pattern(/^[a-zA-Z\s]{1,50}$/),  this.noOnlySpacesValidator]],	
      email:['',[Validators.required,Validators.maxLength(100)]],
      countryCode:[''],
      phoneNumber:['',[Validators.minLength(7),Validators.maxLength(15)]],
      address:['',[Validators.maxLength(300)]],
      onBoardingDate:['',[Validators.required]],
      offBoardingDate:[''],
      sponsorUsername: ['',[Validators.maxLength(30),this.noOnlySpacesValidator]],
      engagementUsername: ['',[Validators.maxLength(30),this.noOnlySpacesValidator]],
      sponsorAsset: ['',[Validators.maxLength(50)]],
      supplierAsset: ['',[Validators.maxLength(50)]],
      notes:['',[Validators.maxLength(300)]],
      associateRoleId:['', [Validators.required]],
      sponsorContactId: [''],
      countryId:['', [Validators.required]],
      allocationTypeId:['', [Validators.required]],
      supplierProfileId:[''],
      contractDetailId:['', [Validators.required]],
      statusMappingId:['',[Validators.required]],
      //teamType:[{ value: '', disabled: true }],	
      functionalHead:['',[Validators.maxLength(50),Validators.pattern(/^(?=.*[a-zA-Z])[a-zA-Z0-9\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]{1,50}$/)]],
      sponsorsAsset:['',[Validators.required]],
      offboardingReason:['',[Validators.maxLength(300),this.noOnlySpacesValidator]],
      sponsorAssetDetails:['',[Validators.maxLength(300),this.noOnlySpacesValidator]],
    });
  }

  noOnlySpacesValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value && control.value.trim().length === 0) {
      return { onlySpaces: true };
    }
    return null;
  }
  auditIdentifierDetails:any;
  auditEntities:any;
  auditOperations:any;
  auditModule:any;

  async ngOnInit() {
    this._enumBreadcrumb=breadcrumnsCodes;
    this._dateformart=Dateformat;
    this.codeListValues=CodeList;
    this.codeListStatusTypes=CodeListStatusTypes;
    this.auditIdentifierDetails=AuditIdentifier; 
    this.auditEntities=AuditLogEntities;
    this.auditOperations=AuditOperations;
    this.auditModule=AuditLogModule;
    await this.GetAllocationTypes(this.codeListValues.Tbl_AllocationsTypes);//17
    
    if(this.FromType==1)//Contract
    {
      this.contractDetailId=this.entityId;
      if(this.contractDetailId != undefined && this.contractDetailId>=0)
      this.GetContractDetails(this.contractDetailId);     
      this.disableContract=true;
      
    }
    else//Engagement
    {
      if(this.FromType==2 && this.actionType=="Create"){
        this.loaderService.showLoader();
        this.teamAssociateData.contractDetailId=0;
        setTimeout(() => {
          this.contractChange();
          this.loaderService.hideLoader();
        },1500); 
       
      }

      this.engagementId=this.EngagementId
      this.GetContractByEngagementId(this.engagementId);
      this.GetEngagementDetailsById();
      this.disableContract=false;
    }


   
    this.GetCountries(this.codeListValues.Tbl_Countries);//0
    this.GetAssociateRoles(this.codeListValues.Tbl_TeamAssociateRoles);//18
    this.GetSponsorsAssets(this.codeListValues.Tbl_SponsorsAsset);//19
    await this.GetAssociateStatus(this.codeListValues.Tbl_Status,this.codeListStatusTypes.ASCTSTS);//3
    this.loaderService.hidefinalLoader();// hide after 4 seconds even get any error
   
  }
   
  async GetAssociateStatus(enumId:number,type:string){
  this.masterDataService.getCodeListData(enumId,type).subscribe(
    async res=>{
     this.associatesStatusData = res.data;

     if (res && res.status && res.data && res.data.length > 0)
     {
        if(this.actionType == "Create"){
          this.teamAssociateData.statusMappingId= (res.data.find((c: any) => c.code === "ONB") ?? { id: 0 }).id;
        }
       
     }

     if(this.actionType == "Create")
     {
      this.oldTeamAssociateModel=JSON.stringify(this.teamAssociateData);
      this.associateDetailBeforeUpdate=JSON.stringify(this.teamAssociateData);
        this.associateTitle="Add Associate";
    }
    else{
        this.associateTitle="Edit Associate";
        this.PopulateAssociateModel();
        this.listOfAssociates=this.tabAndStateService.getTeamsData();
        this.currentIndex = this.listOfAssociates.findIndex((a: { id: any; }) => a.id ==this.associateId);
    }

    }
  );
  
  }

completeAllocationTypeDataSource:any;
   async GetAllocationTypes(enumId:number){
    this.masterDataService.getCodeListData(enumId, '').subscribe(
       res => {
       
        this.completeAllocationTypeDataSource = res.data;
        this.allocationTypeData=this.completeAllocationTypeDataSource?.filter((item:any) => item.name !== 'Not Applicable');
      }
    );
    }


    // public async GetAllocationTypes1(enumId:number): Promise<any> {
    //   return new Promise<any>(async (resolve, reject) => {
    //     this.masterDataService.getCodeListData(enumId, '').subscribe(
    //       res => {
    //         this.completeAllocationTypeDataSource = res.data;
    //         this.allocationTypeData = this.completeAllocationTypeDataSource?.filter((item: any) => item.name !== 'Not Applicable');
    //         resolve(true);
    //       }
    //     )
    //     });
    // }

   
    GetContractByEngagementId(engagementId:any){
      this.contractService.GetAllContracts(engagementId,this.engagementIds).subscribe(
        res=>{
         const activeContracts = res.data.filter((contract: { statusName: string; }) => contract.statusName === "Active");
         this.engagementContractList = activeContracts;
       
         let notApplicableItem={'id':0,'title':'Not Applicable'};
         this.engagementContractList.unshift(notApplicableItem);
         

         

        if(this.FromType==1 && this.actionType=="Create"){
          this.teamAssociateData.contractDetailId=parseInt(this.contractDetailId);
        }

        
        

        }
      );
      }

      engagementDetail:any; 
  GetEngagementDetailsById() {

    this.engagementService.GetEngagementById(this.engagementId)
      .subscribe(res => {    
        this.engagementDetail=res.data; 
        //this.getSponserData(res.data.sponsorId);
       
       
      });
  }

    ContractDetails:any
     GetContractDetails(contractId:any){
       this.contractService.GetContractDtailsById(contractId).subscribe(
        res=>{
         this.ContractDetails = res.data; 
         this.engagementId=this.ContractDetails.engagementId;
          this.GetContractByEngagementId(this.engagementId);
          this.GetEngagementDetailsById();
         
        }
      );
    }
    // getSponserData(sponserId:any)
    //   {
    //     this.masterDataService.getSponserData(sponserId).subscribe(res=>{ 
    //       this.sponsorContactsDataSource= res.data.sponserContacts;
    //     });
    //   }  
      GetCountries(enumId:number){
        this.masterDataService.getCodeListData(enumId,'').subscribe(
          res=>{
           this.countryData = res.data;
          }
        );
        }


        GetAssociateRoles(enumId:number){
          this.masterDataService.getCodeListData(enumId,'').subscribe(
            res=>{
             this.associateRolesData = res.data;
            }
          );
          }

          GetSponsorsAssets(enumId:number){
            this.masterDataService.getCodeListData(enumId,'').subscribe(
              res=>{
               this.sponsorsAssestsData = res.data;
              }
            );
            }
          

          mandatorySymbolFlag=false;
          mandatoryResoanFlag=false;
          offBoardStatus:boolean=false;
          associateStatusChangeClick(event:any){
            let statusName = this.associatesStatusData.find((aa:TeamAssociateDetails) => aa.id === this.teamAssociateData.statusMappingId).name;
            if(statusName == 'Offboarded' && (this.teamAssociateData.offBoardingDate == undefined || this.teamAssociateData.offBoardingDate == null))
            {
              this.isStatusOffBoard=true;
            }
            else{
              this.isStatusOffBoard=false;
            }

            if(statusName == 'Offboarded'){
              this.mandatorySymbolFlag=true;
              this.offBoardStatus=false;
            }
            else{
              this.teamAssociateData.offBoardingDate=null;
              this.mandatorySymbolFlag=false;
              this.offBoardStatus=true;
            }
        }


        offBoardChange(){
          if(this.teamAssociateData.statusMappingId != undefined && this.associatesStatusData!=undefined)
          {
            let statusName = this.associatesStatusData.find((aa: TeamAssociateDetails) => aa.id === this.teamAssociateData.statusMappingId)?.name;
    
          if((this.teamAssociateData.offBoardingDate == undefined || this.teamAssociateData.offBoardingDate == null)  && statusName == 'Offboarded')
          {
            this.isStatusOffBoard=true;
          }
          else{
            this.isStatusOffBoard=false;
          }
        }
        }


        onBoardChange(){
          if(this.teamAssociateData.offBoardingDate != null || this.teamAssociateData.offBoardingDate != undefined)
          {
            if(this.teamAssociateData.offBoardingDate < this.teamAssociateData.onBoardingDate)
            this.teamAssociateData.offBoardingDate=null;
          }
        }

        offPhoneNoChange(){
          if(this.teamAssociateData.phoneNumber != null && this.teamAssociateData.phoneNumber != undefined && this.teamAssociateData.phoneNumber != "" && (this.teamAssociateData.countryCode == null || this.teamAssociateData.countryCode == undefined))
          {
              this.countryCodeMandatoryflag=true;
          }
          else{
            this.countryCodeMandatoryflag=false;
          }


          if(this.teamAssociateData.phoneNumber != null && this.teamAssociateData.phoneNumber != undefined && this.teamAssociateData.phoneNumber != "")
          {
              this.countryCodeMandatoryastrikflag=true;
          }
          else{
            this.countryCodeMandatoryastrikflag=false;
          }

        }

        countryCodeChange()
        {
          if(this.teamAssociateData.countryCode == null || this.teamAssociateData.countryCode == undefined)
          {
              this.countryCodeMandatoryflag=true;
          }
          else{
            this.countryCodeMandatoryflag=false;
          }
        }

       
        contractChange(){
          if(this.teamAssociateData?.contractDetailId!=undefined){
          if(this.teamAssociateData?.contractDetailId==0)
          {

            if(this.completeAllocationTypeDataSource!=undefined){
              this.allocationTypeData=this.completeAllocationTypeDataSource;
              let nAAllocationTypeId = this.completeAllocationTypeDataSource?.find((aa: any) => aa.name === 'Not Applicable')?.id;
              this.teamAssociateData.allocationTypeId=nAAllocationTypeId;

            }
            this.allocationTypeDisableFlag=true;
          }
          else{
            this.allocationTypeDisableFlag=false;
            if(this.completeAllocationTypeDataSource!=undefined){
            this.allocationTypeData=this.completeAllocationTypeDataSource?.filter((item:any) => item.name !== 'Not Applicable');
          //   if(this.teamAssociateData?.contractDetailId != undefined)
          //    this.GetContractDetails(this.teamAssociateData.contractDetailId);  // add associate under the supplier team by selecting specific contract instead of not applicable
           }
        }

        }
        }
        oldAssociateStatus:any;//add for email notification logic
        PopulateAssociateModel(){
          this.loaderService.showLoader(); 
          this.oldAssociateStatus=null;//add for email notification logic
          this._service.GetAssociateDataById(this.associateId).subscribe(res => {
            if(res.status)
            {
              this.teamAssociateData=res.data;
              this.createdBy=this.privilegeService.getUserName(res.data.createdBy);
              this.updatedBy=this.privilegeService.getUserName(res.data.updatedBy);
                   this.oldAssociatesData=JSON.stringify(this.teamAssociateData);
                   this.associateDetailBeforeUpdate=JSON.stringify(this.teamAssociateData);
                this.teamAssociateData.onBoardingDate=new Date(res.data.onBoardingDate);
                    if(res.data.offBoardingDate!=null)
                    {
                      this.teamAssociateData.offBoardingDate=new Date(res.data.offBoardingDate);            
                    }
                   
                  
              if(this.teamAssociateData.contractDetailId == null && this.actionType=="Edit"){
                this.teamAssociateData.contractDetailId=0;
              }
              else if(this.actionType=="Edit" && (this.teamAssociateData?.contractDetailId! > 0)){
               // this.GetContractDetails(this.teamAssociateData.contractDetailId);  // existing associate added under the supplier team by selecting specific contract instead of not applicable
                let statusName = this.associatesStatusData.find((aa:TeamAssociateDetails) => aa.id === this.teamAssociateData.statusMappingId).name;
                if(statusName == 'Offboarded'){
                  this.mandatorySymbolFlag=true;
                  this.offBoardStatus=false;
                }
                else
                {
                  this.offBoardStatus=true;
                  this.mandatorySymbolFlag=false;
                  this.teamAssociateData.offBoardingDate='';
                }
              }
              this.oldTeamAssociateModel=JSON.stringify(this.teamAssociateData);
              this.oldAssociateStatus= this.teamAssociateData.statusMappingId; //add for email notification logic
            
              this.contractChange();
              this.loaderService.hideLoader(); 
            }
          });
        }

        associateData:any;// for mail notifications
        associateDetail:any; // for mail notifications
          saveTeamAssociateDetails(){
            const contractId= this.teamAssociateData.contractDetailId;
            this.teamAssociateData.contractDetailId=contractId;
            this.engagementId=this.EngagementId;
            this.associateDetail=null; // for mail notifications
            this.associateData=null;// for mail notifications
            this.submitted=true;
            this.isSaveBtnDisable = true;
            setTimeout(() => {
              this.isSaveBtnDisable = false;
            }, 3000);
            
            const selectedallocation = this.allocationTypeData.find(( allocation: { id: number}) => allocation.id === this.teamAssociateData.allocationTypeId);
             const allocationName=selectedallocation ? selectedallocation.name : '';
            if(contractId!=0 && (allocationName=="Not Applicable" ||allocationName==""))
            {
              this.myTeamAssociateForm.controls['allocationTypeId'].setErrors({required: true});
            }
            else{
              this.myTeamAssociateForm.controls['allocationTypeId'].setErrors(null);
            }
            if(!this.isStatusOffBoard){
              this.myTeamAssociateForm.controls['offBoardingDate'].setErrors(null);
              
            }
            else{
              this.myTeamAssociateForm.controls['offBoardingDate'].setErrors({required: true});
            }
             const offboardingReasonControl = this.myTeamAssociateForm.get('offboardingReason');
            if((this.teamAssociateData.offboardingReason==undefined || this.teamAssociateData.offboardingReason==null || this.teamAssociateData.offboardingReason=="" ) && this.mandatorySymbolFlag==true){
               this.myTeamAssociateForm.controls['offboardingReason'].setErrors({required: true});
            }
            else{
              if(offboardingReasonControl?.valid)
                {
                  this.myTeamAssociateForm.controls['offboardingReason'].setErrors(null);
                }
            
            }
           
            if(!this.countryCodeMandatoryflag){
              this.myTeamAssociateForm.controls['countryCode'].setErrors(null);
            }
            else{
              this.myTeamAssociateForm.controls['countryCode'].setErrors({required: true});
            }
            
           
            if (this.myTeamAssociateForm.valid) {
              this.executeCanExist = false;
              this.loaderService.showLoader();
              this.teamAssociateData.teamType=this.FromType;
              this.teamAssociateData.engagementId=this.engagementId;
              if(this.associateTitle== 'Edit Associate'){
              this.teamAssociateData.updatedBy=this.privilegeService.getLoginUserId();
              }
              else{
                this.teamAssociateData.createdBy=this.privilegeService.getLoginUserId();
              }
              
              this.teamAssociateData.sponsorContactId = this.teamAssociateData.sponsorContactId ?? 0;
              this.teamAssociateData.onBoardingDate = this.datepipe.transform(this.teamAssociateData.onBoardingDate, "MM/dd/yyyy");
              this.teamAssociateData.offBoardingDate = this.datepipe.transform(this.teamAssociateData.offBoardingDate, "MM/dd/yyyy");
              if(this.teamAssociateData.contractDetailId==0){
                this.teamAssociateData.contractDetailId=undefined;
              }
              
              this.associateData =JSON.stringify(this.teamAssociateData);  // for mail notifications
              this._service.AddTeamAssociate(this.teamAssociateData).subscribe(async res => { 
                if(res.status)
                {
                  const dataResult = {
                    associateId:parseInt(res.data)
                  };
                  if(this.actionType=="Create"){
                    if(this.teamAssociateData.contractDetailId==0){
                      this.teamAssociateData.contractDetailId=undefined;
                    }
                    await this.sendAssociateNotification();  // call the send notification method to trigger the notification email
                    this.messageService.add({ key: 'tc', severity: 'success', summary: 'Associate has been added', detail: '' });
                    this.compareModels();
                    this.cancelTeamButtonClick.emit();
                  }
                  else{
                    if(this.oldAssociateStatus!=this.teamAssociateData.statusMappingId)
                    {
                      await this.sendAssociateNotification(); // call the send notification method to trigger the notification email
                    }
                    this.messageService.add({
                    key: 'tc', 
                    severity: 'success',
                     summary: 'Changes have been saved', 
                     detail: '' });
                    this.compareModels();
                    this.viewTeamButtonClick.emit(dataResult); 
                  }
                  this.loaderService.hideLoader();  
                }
              });
            }
            else{
              this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Please enter required information', detail: '' });  
               }
            this.loaderService.hideLoader();
          }
recipientsData:any;
finalMailRecipientsArray:any
_contractName:string="";
async sendAssociateNotification() {
   //logic for email notification start

   //this.teamAssociateData
   this.associateDetail =JSON.parse(this.associateData); //   JSON.stringify(this.associateData) // addded logic for email text issue

   let statusName = this.associatesStatusData?.find((aa:TeamAssociateDetails) => aa.id === this.associateDetail?.statusMappingId)?.name;
   let mailSubject='';
   let contractId= (this.associateDetail?.contractDetailId!=undefined && this.associateDetail?.contractDetailId>0)?this.associateDetail?.contractDetailId:0;
   if(statusName == 'Onboard'){
     if(contractId==0)
     {
       mailSubject="Associate Being Onboarded for Engagement"
     }
     else
     {
      this._contractName=this.engagementContractList?.find((aa: any) => aa.id === contractId)?.title;
       mailSubject="Associate Being Onboarded for Contract"
     }
   }
   else if(statusName == 'Offboarded'){
     if(contractId==0)
     {
       mailSubject="Associate Being Offboarded for Engagement"
     }
     else
     {
       mailSubject="Associate Being Offboarded for Contract"
       this._contractName=this.engagementContractList?.find((aa: any) => aa.id === contractId)?.title;
     }
   }
   
   this.contractService.GetContractRecipientsById(contractId,this.SponsorId,this.SupplierId,this.associateDetail.engagementId).subscribe(
    async res => {
      if (res?.data) {
        this.recipientsData=res?.data;
        const recipients=this.recipientsData.sponsorRecipients;
        this.recipientsData.sponsorRecipients=recipients;
        if(contractId!=0)
          {
            let sponsorRecipients= this.getFilteredEmails("TEM",this.recipientsData.sponsorRecipients);
            let supplierRecipients= this.getFilteredEmails("TEM",this.recipientsData.supplierRecipients)
            let mailRecipients = sponsorRecipients+","+supplierRecipients+','+this.recipientsData.contractSponsorOwner+','+this.recipientsData.contractSupplierOwner;
            let mailRecipientsArray = mailRecipients.split(',').filter(email => email.trim() !== '');
            this.finalMailRecipientsArray = [...new Set(mailRecipientsArray)];
          }
         else{

          let sponsorRecipients= this.getFilteredEmails("TEM",this.recipientsData.sponsorRecipients);
          let supplierRecipients= this.getFilteredEmails("TEM",this.recipientsData.supplierRecipients);
          let sponsorMsaRecipients= this.getFilteredEmails("NA",this.recipientsData.msaSponsorContacts);
          let supplierMsaRecipients= this.getFilteredEmails("NA",this.recipientsData.msaSupplierContacts);
          let mailRecipients = sponsorRecipients+","+supplierRecipients+','+sponsorMsaRecipients+','+supplierMsaRecipients;
          let mailRecipientsArray = mailRecipients.split(',').filter(email => email.trim() !== '');
          this.finalMailRecipientsArray = [...new Set(mailRecipientsArray)];
         }
        
        if(this.finalMailRecipientsArray?.length>0)
          {
           
              let payloadData= {
                  "product": "KontractPro",
                  "module": "Team",
                  "data": {
                  "subject": mailSubject, 
                  "engagementName": (this.engagementDetail!= null ||this.engagementDetail!=undefined)?this.engagementDetail?.engagementName:"",
                  "contractTitle":this._contractName,// (this.ContractDetails!= null ||this.ContractDetails!=undefined)?this.ContractDetails?.title:"",
                  "associateName": this.associateDetail?.associateName,
                  "onboardingDate":  this.datepipe.transform(this.associateDetail?.onBoardingDate, "dd-MMM-yy"),
                  "offboardedDate":  this.datepipe.transform(this.associateDetail?.offBoardingDate, "dd-MMM-yy"),
              }, 
              "recipients": this.finalMailRecipientsArray
              }
              // call the associate Onboarded  or Offboarded send notification service metod
              this.notificationService.sendEmailNotification(payloadData).subscribe(res => {
               
              });
       
          }
     }});

   
  
   // logic for email notification end
}
cancelAssociate(){

  if ((this.oldTeamAssociateModel!=JSON.stringify(this.teamAssociateData)))
  {
    let message="";
    let detail="";
    if(this.actionType=="Create"){
      message='Associate will not be added, Do you want to Continue?';
      detail="Associate not added";
    }
    else{
      message='Changes will not be saved, Do you want to Continue?';
      detail="Changes not saved";
    }
      this.confirmationService.confirm({
        message: message,
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          if(this.actionType=='Create'){
            this.cancelTeamButtonClick.emit();
          }
          else//Edit associate
          {
          const dataResult = {
                    
            associateId:parseInt(this.associateId)
          };
          this.viewTeamButtonClick.emit(dataResult); 
        }

          
          this.messageService.add({
            key: 'tc',
            severity: 'info',
            summary: detail,
            detail: '',
        });
        },
        reject: () => {
        },
      });
  }
  else{
    
    if(this.actionType=='Create'){
      this.cancelTeamButtonClick.emit();
    }
    else//Edit associate
    {
    const dataResult = {
              
      associateId:parseInt(this.associateId)
    };
    this.viewTeamButtonClick.emit(dataResult); 
  }
  }
}
canExist(): boolean | Promise<boolean> | Observable<boolean> {
if (this.executeCanExist &&(this.oldTeamAssociateModel!=JSON.stringify(this.teamAssociateData)))
 {
   return new Promise<boolean>((resolve) => {
   this.confirmationService.confirm({
   message: 'Associate will not be added, Do you want to Continue?',
   header: 'Confirmation',
   icon: 'pi pi-exclamation-triangle',
   accept: () => {
   this.messageService.add({
   key: 'tc',
   severity: 'info',
   summary: 'Associate not added',
   detail: '',
  });
  resolve(true); 
 },
 reject: () => {
 resolve(false); 
 },
});
});
}
else{
return true;
}
}

changedContractAssociatesBasicInfo:any;
oldAssociatesData:any;
associateDetailBeforeUpdate:any
          compareModels() {           
            if(this.FromType==1){
              if(this.actionType == "Create"){
               this.changedContractAssociatesBasicInfo = this.auditLogService.compareModels(JSON.parse(this.associateDetailBeforeUpdate),this.teamAssociateData,this.auditEntities.ContractAssociates,this.auditIdentifierDetails.ContractAssociates,this.auditOperations.Create,this.auditModule.GovernanceService);
               }
               else{
              this.changedContractAssociatesBasicInfo = this.auditLogService.compareModels(JSON.parse(this.associateDetailBeforeUpdate),this.teamAssociateData,this.auditEntities.ContractAssociates,this.auditIdentifierDetails.ContractAssociates,this.auditOperations.Update,this.auditModule.GovernanceService);
               }
            }
            else{
              if(this.actionType == "Create"){
                if(this.teamAssociateData.contractDetailId==undefined){
                  this.teamAssociateData.contractDetailId=1;
                }
                this.changedContractAssociatesBasicInfo = this.auditLogService.compareModels(JSON.parse(this.associateDetailBeforeUpdate),this.teamAssociateData,this.auditEntities.EngagementAssociates,this.auditIdentifierDetails.EngagementAssociates,this.auditOperations.Create,this.auditModule.GovernanceService);
               }
               else{
                if(this.teamAssociateData.contractDetailId==null){
                  this.teamAssociateData.contractDetailId=1;
                }
                this.changedContractAssociatesBasicInfo = this.auditLogService.compareModels(JSON.parse(this.associateDetailBeforeUpdate),this.teamAssociateData,this.auditEntities.EngagementAssociates,this.auditIdentifierDetails.EngagementAssociates,this.auditOperations.Update,this.auditModule.GovernanceService);
               }
            }
            
          }
          getFilteredEmails(notificationCode: string,sponsorRecipients:any): string {
            if(sponsorRecipients!=null)
            {
            const emails= sponsorRecipients
              .filter((recipient: { notificationCode: string; }) => recipient.notificationCode === notificationCode)
              .map((recipient: { email: any; }) => recipient.email);
              const distinctEmails = Array.from(new Set(emails));
              return distinctEmails.join(',');
              }
              else
              return "";
          }
          navigateTo(index: number): void {
            if (index >= 0 && index < this.listOfAssociates.length) {
              this.associateId=this.listOfAssociates[index].id;
              this.PopulateAssociateModel()
              this.currentIndex=index;
              this.DisableNxtPreButton();
            }
          }
          nextRecord(): void {
            this.navigateTo(this.currentIndex + 1);
          }
          previousRecord(): void {
            this.navigateTo(this.currentIndex - 1);
          }
          getCurrentPositionText(): string {
            return `${this.currentIndex + 1} of ${this.listOfAssociates.length}`;
          }
          pageChangeConfirmation(buttonType:number):void{
            if((this.oldTeamAssociateModel==JSON.stringify(this.teamAssociateData)))
            {
              buttonType==1?this.nextRecord():this.previousRecord();
            }
            else{
              this.confirmationService.confirm({
                message: 'Changes will not be saved, Do you want to Continue?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                  this.messageService.add({
                    key: 'tc',
                    severity: 'info',
                    summary: 'Changes not saved',
                    detail: '',
                });
                 buttonType==1?this.nextRecord():this.previousRecord();
                },
                reject: () => {
              
                },
              });
            }
          }
          buttonDisabled:boolean = false;
DisableNxtPreButton()
{
  this.buttonDisabled = true;
  setTimeout(() => {
    this.buttonDisabled = false;
  },2000); 
}
}
