<!DOCTYPE html>
<html lang="en">
<head>
  <title> Engagement </title>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>
  <div class="card flex justify-content-center">
    <p-breadcrumb class="max-w-full" [model]="getBreadcrumbs()"></p-breadcrumb>
  </div>
  <!-- Confrim Popup Start -->
  <p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
  <!-- Confrim Popup END-->
  <div class="container page-title" #scrollContainer>
    <div class="flex-box-inside-pages position-fixed-heading"> 
    <div class="fr-flex__container">
      <div class="fr-flex__col-8">
        <div class="card flex justify-content-center gap-2"></div>
        <h1>{{heading}}</h1>
      </div>
    </div>
  </div>
    <!-- Tab View Module Start -->
    
      <p-tabView class="fr-tabview__header fixed-positon-tabs" #myTabView [(activeIndex)]="tabindex" (onChange)="onTabSelect($event)" [activeIndex]="activeTabIndex">
        <!-- Profile Tab Start -->
        <p-tabPanel header="Profile">
          <form class="card-pad" [formGroup]="engagementForm">
            <!-- start form -->
            <div class="scroll-start-here"> 
            <div class="fr-flex__container">

              <div class="fr-flex__col-4">
                <div class="fr-form__group">
                  <label> Sponsor <em> * </em></label>
                  <p-dropdown appendTo="body" pInputText placeholder="top" [class.read-only-dropdown]="isEditPage" [readonly]="isEditPage" [filter]="true" formControlName="_sponsor" [options]="sponserDataSource" [(ngModel)]="_engagement.sponsorId" optionLabel="name" optionValue="id" placeholder="Select Sponsor" (onChange)="checkExist(); onSponsorChange()">
                  </p-dropdown><br>
                  <small class="p-error" *ngIf="submitted && engagementForm?.get('_sponsor')?.hasError('required')">This field is required.</small>
                </div>
              </div>

              <div class="fr-flex__col-4">
                <div class="fr-form__group">
                  <label> Supplier <em> * </em></label>
                  <p-dropdown appendTo="body" pInputText placeholder="top" [class.read-only-dropdown]="isEditPage" [readonly]="isEditPage" [filter]="true" formControlName="_supplier" [options]="supplierdataSource" [(ngModel)]="_engagement.supplierId" optionLabel="name" optionValue="id" placeholder="Select Supplier" (onChange)="checkExist(); onSupplierChange()">
                  </p-dropdown><br>
                  <small class="p-error" *ngIf="submitted && engagementForm?.get('_supplier')?.hasError('required')">This field is required.</small>
                </div>
              </div>

              <div class="fr-flex__col-4">
                <div class="fr-form__group">
                  <label> Engagement Name <em> * </em> <span class="max-length"> {{_engagement.engagementName?.length}}/50 </span> </label>
                  <input type="text" formControlName="_enagementName" class="form-control" [maxlength]="50" [minlength]="3" pInputText [(ngModel)]="_engagement.engagementName" (ngModelChange)="checkEngagementNameExist()" trimSpace/>
                  <small class="p-error" *ngIf="submitted && engagementForm?.get('_enagementName')?.hasError('required')">This field is required.</small>
                  <small class="p-error" *ngIf="uniqueEngagementMessage">Engagement Name already exists. Please enter a unique Engagement Name.</small>
                  <small class="p-error" *ngIf="submitted && engagementForm?.get('_enagementName')?.hasError('maxlength')">Max length is 100 characters.</small>
                  <small class="p-error" *ngIf="submitted && engagementForm?.get('_enagementName')?.hasError('minlength')">Min length is 3 characters.</small>
                  <small class="p-error" *ngIf="submitted && engagementForm?.get('_enagementName')?.hasError('onlySpaces')">This field cannot contain only spaces.</small>

                </div>
              </div>

            </div>

            <div class="fr-flex__container">

              <div class="fr-flex__col-4">
                <div class="fr-form__group">
                  <label> Engagement ID <em> * </em><span class="max-length"> {{_engagement.engagementId?.length}}/10 </span></label>
                  <input type="text" formControlName="_engagementID" class="form-control" [maxlength]="10" pInputText [(ngModel)]="_engagement.engagementId" (ngModelChange)="checkExist()" trimSpace/>
                  <small class="p-error" *ngIf="submitted && engagementForm?.get('_engagementID')?.hasError('required')">This field is required.</small>
                  <small class="p-error" *ngIf="uniqueMessage">Engagement ID already exists. Please enter a unique ID.</small>
                  <small class="p-error" *ngIf="submitted && engagementForm?.get('_engagementID')?.hasError('pattern')">Spaces and special characters are not allowed, Min length is 3 and Max length is 10 characters.</small>
                </div>
              </div>


              <div class="fr-flex__col-4">
                <div class="fr-form__group">
                  <label> Engagement Start Date <em>*</em></label>
                  <div class="small-calendar">
                    <p-calendar appendTo="body" class="form-control read-only-calendar-condication" [ngClass]="{'read-only-calendar-condication':isEditPage}" [tabindex]="isEditPage ? -1:0" [disabled]="isEditPage" formControlName="_engagementStartDate" [(ngModel)]="_engagement.engagementStartDate" [showIcon]="true" [dateFormat]=_dateformart.CalenderDateFormat [readonlyInput]="true"></p-calendar>
                    <small class="p-error" *ngIf="submitted && engagementForm?.get('_engagementStartDate')?.hasError('required')">This field is required.</small>
                  </div>
                </div>
              </div>

              <div class="fr-flex__col-4">
                <div class="fr-form__group">
                  <label>Status   <em> * </em></label>
                  <p-dropdown appendTo="body" [class.read-only-dropdown]="!isEditPage" [readonly]="!isEditPage" [filter]="true" formControlName="_statusMappingId" [options]="statusData" [(ngModel)]="_engagement.statusMappingId" optionLabel="name" optionValue="id" placeholder="Select Status" (onChange)="onOptionChange($event)"></p-dropdown>
                  <small class="p-error" *ngIf="submitted && engagementForm?.get('_statusMappingId')?.hasError('required')">  This field is required. </small>
                </div>
              </div>

            </div>

            <div class="fr-flex__container">

              <div class="fr-flex__col-4">
                <div class="fr-form__group">
                  <label> Services <em> * </em> <span class="max-length-multiple-selected"> <span> {{_engagement.services?.length}}</span> </span> </label>
                  <p-multiSelect appendTo="body" formControlName="_services" [options]="supplierServicesDataSource" [(ngModel)]="_engagement.services" placeholder="Select Services" optionValue="id" optionLabel="name" display="chip"></p-multiSelect><br>
                  <small class="p-error" *ngIf="submitted && engagementForm?.get('_services')?.hasError('required')">This field is required.</small>
                </div>
              </div>

              <div class="fr-flex__col-4">
                <div class="fr-form__group">
                  <label> Default Currency <em> * </em></label>
                  <p-dropdown appendTo="body" pInputText placeholder="top" [filter]="true" formControlName="_defaultCurrency" [options]="currencyCodeList" [(ngModel)]="_engagement.defaultCurrency" optionLabel="name" optionValue="id" placeholder="Select Currency">
                  </p-dropdown><br>
                  <small class="p-error" *ngIf="submitted && engagementForm?.get('_defaultCurrency')?.hasError('required')">This field is required.</small>
                </div>
              </div>

            </div>


            <div class="fr-flex__container">
              <div class="fr-flex__col-12">
                <div class="fr-form__group">
                  <label> Notes and Comments <span class="max-length"> {{_engagement.notes?.length}}/300 </span> </label>
                  <textarea formControlName="_notes" class="form-control" [maxlength]="300" pInputTextarea [(ngModel)]="_engagement.notes" trimSpace></textarea>
                  <small class="p-error" *ngIf="submitted && engagementForm?.get('_notes')?.hasError('maxlength')">Max length is 300 characters.</small>
                </div>
              </div>
            </div>


            <div *ngIf="isEditPage" class="fr-flex__container">
              <div class="fr-flex__col-4">
                 <div class="fr-form__view">
                    <label>Created By</label>
                    <span> <em> {{CreatedUserName}} </em></span>
                 </div>
              </div>
              <div class="fr-flex__col-4">
                 <div class="fr-form__view">
                    <label>Created Date & Time</label>
                    <span> <em> {{_engagement.createdDate | date:_dateformart.DateTimeWithZone}} {{_dateformart.DisplayUTC}}</em></span>
                 </div>
              </div>
           </div>

          
          </div>
          </form>
          <div class="card-pad pad contact_pad_custom"> 
          <!-- Stakeholder Sponsor Contact Grid Start -->
          <div class="fr-flex__container">
            <div class="fr-flex__col-8">
              <h3>Stakeholders (Sponsor)  </h3>
            </div>
            <div class="fr-flex__col-4">
              <a class="btn btn-primary pull-right" (click)="AddStakeholderSponsorContact()"> Add Row </a>
            </div>
          </div>

          <div class="fr-flex__col-12 m-b-20">
            <p-table id="engAddEditStakeholdersSponsor" class="primeng-table-custom addEditStakeholders_Contact" [scrollable]="true" [value]="stakeholderSponsorcontactData" editMode="row">
              <ng-template pTemplate="header">
                <tr>
                  <th id="addEditStakeholderSponsorName">Name </th>
                  <th id="addEditStakeholderSponsorDesignation">Designation</th>
                  <th id="addEditStakeholderSponsorEmail">Email</th>
                  <th id="addEditStakeholderSponsorCountryCode">Country Code </th>
                  <th id="addEditStakeholderSponsorPhoneNumber">Phone Number</th>
                  <th id="addEditStakeholderSponsorSendNotifications">Send Notifications </th>
                  <th id="addEditStakeholderSponsorDelete">Delete</th>
                  
                  
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-stakeholderSponsorcontactData let-ContactIndex="rowIndex">

                <tr>
                  <td>
                    <div style="display: flex;align-items: center;margin-right: 10px;">
                      <p-dropdown appendTo="body" [filter]="true" [options]="sponsorContactsDataSource" appendTo="body" [(ngModel)]="stakeholderSponsorcontactData.id" placeholder="Select" optionLabel="name" optionValue="id" (onChange)="sponsorContactDetails($event, ContactIndex)" pInputText [pTooltip]="stakeholderSponsorContacts[ContactIndex]?.name.length > 10 ? stakeholderSponsorContacts[ContactIndex]?.name : ''"   tooltipPosition="top"></p-dropdown>
                      <a class="btn btn-primary-link pull-right" (click)="openContactModel(1)">Add New</a>
                    </div>
                  </td>
                  <td [pTooltip]="stakeholderSponsorContacts[ContactIndex]?.designation.length > 15 ? stakeholderSponsorContacts[ContactIndex]?.designation : ''" tooltipPosition="top" [(ngModel)]="stakeholderSponsorcontactData.designation" id="ee">
                    {{ stakeholderSponsorContacts[ContactIndex]?.designation || '--' }} 
                  </td>
                  <td [pTooltip]="stakeholderSponsorContacts[ContactIndex]?.email.length > 15 ? stakeholderSponsorContacts[ContactIndex]?.email : ''" tooltipPosition="top">
                    {{ stakeholderSponsorContacts[ContactIndex]?.email || '--' }} 
                  </td>
                  <td [pTooltip]="stakeholderSponsorContacts[ContactIndex]?.countryCode.length > 10 ? stakeholderSponsorContacts[ContactIndex]?.countryCode : ''" tooltipPosition="top">
                    {{ stakeholderSponsorContacts[ContactIndex]?.countryCode || '--' }}
                  </td>
                  <td [pTooltip]="stakeholderSponsorContacts[ContactIndex]?.phoneNumber.length > 10 ? stakeholderSponsorContacts[ContactIndex]?.phoneNumber : ''" tooltipPosition="top">
                    {{ stakeholderSponsorContacts[ContactIndex]?.phoneNumber || '--' }} 
                  </td>
                  <td>
                   
                    <p-multiSelect appendTo="body" *ngIf="stakeholderSponsorContacts[ContactIndex]"  [options]="notificationOptionsDataSource" [(ngModel)]="stakeholderSponsorcontactData.sendNotificationOptions" placeholder="Select" optionValue="id" optionLabel="name" ></p-multiSelect><br>
                 
                  </td>
                  <td id="engAddEditStakeholdersSponsorDelete" class="grid-icon-center"><span class="pi pi-trash" (click)="deleteSponsorContact(ContactIndex);"></span></td>
                  
                 
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                <td colspan="7" class="text-center"> No Records</td>
                </tr>
                </ng-template>
            </p-table>
            
          </div>

          <!-- Stakeholder Sponsor Contact Grid End -->
          <!-- Stakeholder Supplier Contact Grid Start -->


          <div class="fr-flex__container">
            <div class="fr-flex__col-8">
              <h3>Stakeholders (Supplier)  </h3>
            </div>
            <div class="fr-flex__col-4">
              <a class="btn btn-primary pull-right" (click)="AddStakeholderSupplierContact()"> Add Row </a>
            </div>
          </div>

          <div class="fr-flex__col-12 m-b-20">
            <p-table id="engAddEditStakeholdersSupplier" class="primeng-table-custom addEditStakeholders_Contact" [scrollable]="true" [value]="stakeholderSuppliercontactData" editMode="row">
              <ng-template pTemplate="header">
                <tr>
                  <th id="addEditStakeholderSupplierName">Name </th>
                  <th id="addEditStakeholderSupplierDesignation">Designation</th>
                  <th id="addEditStakeholderSupplierEmail">Email</th>
                  <th id="addEditStakeholderSupplierCountryCode">Country Code </th>
                  <th id="addEditStakeholderSupplierPhoneNumber">Phone Number</th>
                  <th id="addEditStakeholderSupplierSendNotifications">Send Notifications </th>
                  <th id="addEditStakeholderSupplierDelete">Delete</th>
                  
                  
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-stakeholdersuppliercontactData let-ContactIndex="rowIndex">

                <tr>
                  <td>
                    <div style="display: flex;align-items: center;margin-right: 10px;">
                      <p-dropdown appendTo="body" [filter]="true" [options]="supplierContactsDataSource" appendTo="body" [(ngModel)]="stakeholdersuppliercontactData.id" placeholder="Select" optionLabel="contactName" optionValue="id" (onChange)="supplierContactDetails($event, ContactIndex)" pInputText [pTooltip]="stakeholderSupplierContacts[ContactIndex]?.contactName.length > 10 ? stakeholderSupplierContacts[ContactIndex]?.contactName : ''"   tooltipPosition="top"></p-dropdown>
                      <a class="btn btn-primary-link pull-right" (click)="openContactModel(2)">Add New</a>
                    </div>
                  </td>
                  <td [pTooltip]="stakeholderSupplierContacts[ContactIndex]?.designation.length > 15 ? stakeholderSupplierContacts[ContactIndex]?.designation : ''" tooltipPosition="top" [(ngModel)]="stakeholdersuppliercontactData.designation">
                   {{ stakeholderSupplierContacts[ContactIndex]?.designation || '--' }} 
                  </td>
                  <td [pTooltip]="stakeholderSupplierContacts[ContactIndex]?.email.length > 15 ? stakeholderSupplierContacts[ContactIndex]?.email : ''" tooltipPosition="top">
                   {{ stakeholderSupplierContacts[ContactIndex]?.email || '--' }} 
                  </td>
                  <td [pTooltip]="stakeholderSupplierContacts[ContactIndex]?.countryCode.length > 10 ? stakeholderSupplierContacts[ContactIndex]?.countryCode : ''" tooltipPosition="top">
                    {{ stakeholderSupplierContacts[ContactIndex]?.countryCode || '--' }}
                  </td>
                  <td [pTooltip]="stakeholderSupplierContacts[ContactIndex]?.phoneNumber.length > 10 ? stakeholderSupplierContacts[ContactIndex]?.phoneNumber : ''" tooltipPosition="top">
                    {{ stakeholderSupplierContacts[ContactIndex]?.phoneNumber || '--' }}
                  </td>
                  <td>
                    <p-multiSelect appendTo="body"  [options]="notificationOptionsDataSource" [(ngModel)]="stakeholdersuppliercontactData.sendNotificationOptions" placeholder="Select" optionValue="id" optionLabel="name" ></p-multiSelect><br>
                  </td>
                  <td id="engAddEditStakeholdersSupplierDelete" class="grid-icon-center"><span class="pi pi-trash" (click)="deleteSupplierContact(ContactIndex);"></span></td>
                  
                  
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                <td colspan="7" class="text-center"> No Records</td>
                </tr>
                </ng-template>
            </p-table>
            
          </div>

          <!-- Stakeholder Supplier Contact Grid End -->
          <!-- end form -->
          <!-- Add Contact Popup Start -->
          <!-- Heading row Start -->
          <div class="">

            
            <!-- Dialog Start -->


            <p-dialog appendTo="body" header="{{contactHeader}}" [(visible)]="displayDialog" [modal]="true" [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false" [closable]="false">
              <ng-template pTemplate="content">
                <form [formGroup]="contactForm">
                  <div class="fr-flex__container">
                    <div class="fr-flex__col-12">
                      <div class="fr-form__group">
                        <label>Name <em> * </em> <span class="max-length"> {{_contact.contactName?.length}}/30 </span></label>
                        <input type="text" formControlName="_contactName" class="form-control" [maxlength]="30" pInputText [(ngModel)]="_contact.contactName" (blur)="IsCheckDuplicateContact(_contact.contactName,currentHeader)" trimSpace/>
                        <small class="p-error" *ngIf="contactSubmitted && contactForm?.get('_contactName')?.hasError('required')">This field is required.</small>
                        <small class="p-error" *ngIf="contactSubmitted && contactForm?.get('_contactName')?.hasError('maxlength')">Max length is 30 characters.</small>
                        <small class="p-error" *ngIf="contactSubmitted && contactForm?.get('_contactName')?.hasError('pattern')">Only alpha characters and spaces are allowed.</small>
                        <small class="p-error" *ngIf="contactSubmitted && contactForm?.get('_contactName')?.hasError('onlySpaces')">This field cannot contain only spaces.</small>
                        <small class="p-error" *ngIf="iscontactExist">{{contactUniqueMessage}}</small>
                      </div>
                    </div>
                  </div>
                  <div class="display-flex">
                    <div class="fr-flex__col-6">
                      <div class="fr-form__group">
                        <label> Country Code </label>
                        <p-dropdown appendTo="body" [filter]="true" formControlName="_countryCode" [options]="locationsDataSource" [(ngModel)]="_contact.countryCode" optionLabel="sub_Code" optionValue="sub_Code" placeholder="Select Country Code"></p-dropdown>
                      </div>
                    </div>
                    <div class="fr-flex__col-6">
                      <div class="fr-form__group">
                        <label> Phone Number <span class="max-length"> {{_contact.phoneNumber.length}}/15 </span> </label>
                        <input type="text" formControlName="_phoneNumber" class="form-control" [maxlength]="15" pInputText [(ngModel)]="_contact.phoneNumber" trimSpace/>
                        <small class="p-error" *ngIf="contactSubmitted && contactForm?.get('_phoneNumber')?.hasError('maxlength')">Max length is 15 characters.</small>
                        <small class="p-error" *ngIf="contactSubmitted && contactForm?.get('_phoneNumber')?.hasError('minlength')"> Min lenght is 7 characters.</small>
                        <small class="p-error" *ngIf="contactSubmitted && contactForm?.get('_phoneNumber')?.hasError('pattern')">Enter only numeric values.</small>
                      </div>
                    </div>
                  </div>
                  <div class="fr-flex__container">
                    <div class="fr-flex__col-12">
                      <div class="fr-form__group">
                        <label>Designation <em>  </em> <span class="max-length"> {{_contact.designation.length}}/100 </span></label>
                        <input type="text" formControlName="_designation" class="form-control" [maxlength]="100" pInputText [(ngModel)]="_contact.designation" trimSpace />
                        <small class="p-error" *ngIf="contactSubmitted && contactForm?.get('_designation')?.hasError('maxlength')">Max length is 100 characters.</small>
                        <small class="p-error" *ngIf="contactSubmitted && contactForm?.get('_designation')?.hasError('pattern')">Only alpha characters and spaces are allowed.</small>
                        <small class="p-error" *ngIf="contactSubmitted && contactForm?.get('_designation')?.hasError('onlySpaces')">This field cannot contain only spaces.</small>
                      </div>
                    </div>
                  </div>
                  <div class="fr-flex__container">
                    <div class="fr-flex__col-12">
                      <div class="fr-form__group">
                        <label> Email <em> * </em> <span class="max-length"> {{_contact.email.length}}/100 </span></label>
                        <input Id="endEmail" class="form-control" type="text" pInputText [pTooltip]="_contact.email!.length > 50 ? _contact.email!: ''" id="email" [maxlength]="100" formControlName="_email" [(ngModel)]="_contact.email" required pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}" />
                        <small Id="reqField" class="p-error" *ngIf="contactSubmitted && contactForm?.get('_email')?.hasError('required')">This field is required.</small>
                        <small Id="maxlen" class="p-error" *ngIf="contactSubmitted && contactForm?.get('_email')?.hasError('maxlength')">Max length is 100 characters.</small>
                        <small Id="validEmail" class="p-error" *ngIf="contactSubmitted && contactForm?.get('_email')?.hasError('pattern')">Enter valid email.</small>
                      </div>
                    </div>
                  </div>
                </form>
              </ng-template>
              
              <ng-template pTemplate="footer">
                <button Id="saveFun" type="button" class="btn btn-primary pull-right" [class.disabled]="isSavePopupBtnDisable" (click)="isSavePopupBtnDisable ? null : saveContactData()"> Save </button>
                <button Id="canFun" type="button" class="btn btn-secondary pull-right" (click)="cancelDialog()"> Cancel </button>
              </ng-template>
            
            </p-dialog>



            
            <!-- Dialog End -->
            <!-- Add Contact Popup End -->




            <div class="fr-flex__container">
              <div class="fr-flex__col-12">
                <h4 class="note-display"> <span> Note : </span> A contact can either be selected as an MSA owner or a stakeholder. The same contact cannot be selected as both (owner as well as stakeholder)</h4>
              </div>
              </div>
 

            <!-- MSAs Popup Start -->
            <div class="fr-flex__container m-t-10">
              <div class="fr-flex__col-8">
                <h1>MSAs</h1>
              </div>
              <div class="fr-flex__col-4">

                <button type="button" (click)="showAddMSADialog()" class="btn btn-primary pull-right" [disabled]="!_engagement.sponsorId || !_engagement.supplierId"> Add MSAs</button>
                <i pInputText pTooltip="This button will be enabled only when a Sponsor and a Supplier is selected" tooltipPosition="top" class="pi pi-info-circle pull-right m-r-10 m-t-10"> </i>
              </div>
            </div>
            <div class="fr-flex__container">
              <div class="fr-flex__col-12">
                <div class="card-norecord">
                  <div class="card flex justify-content-center m-b-20" *ngIf="_supplierMSADetails.length==0">
                    <p-card header="">
                      <img src="../../../../assets/images/empty-box.svg" alt="" width="100">
                      <p class="m-0">
                        No Records
                      </p>
                    </p-card>
                  </div>
                </div>
              </div>
            </div>


            <!-- Add MSA Information Start -->

            <div class="fr-flex__container" *ngIf="_supplierMSADetails.length!=0">
              <div class="fr-flex__col-4" *ngFor="let objSupplierMSADetail of _supplierMSADetails; let i = index">
                <div class="fr-card__custom">
                  <p-card>
                    <ng-template pTemplate="content">
                      <div class="fr-card__view">
                        <span class="valueSelector"> <a [pTooltip]="objSupplierMSADetail.title!.length > 30 ? objSupplierMSADetail.title! : ''" tooltipPosition="top"> {{ objSupplierMSADetail.title  }} </a> </span>
                        <span class="valueSelector success"> {{ objSupplierMSADetail.msaStatus }} </span>
                      </div>
                      <div class="fr-card__view">
                        <span class="valueSelector"> {{objSupplierMSADetail.startDate| date:_dateformart.GridDateFormat }} - {{objSupplierMSADetail.endDate | date:_dateformart.GridDateFormat }} </span>
                       
                        
                        <span class="valueSelector"> <span class="right_align"> {{objSupplierMSADetail?.paymentTerm}} </span> </span>

                      </div>
                      <div class="fr-card__view">
                        <span class="valueSelector-full-width" title="{{objSupplierMSADetail.fileName}}">
                          <a *ngIf="objSupplierMSADetail.fileName; else emptyFileName" class="form-list-active" (click)="isEditPage && downloadMSAFile(objSupplierMSADetail?.fileName,objSupplierMSADetail?.filePath);">
                            <span [pTooltip]="objSupplierMSADetail.fileName!.length > 30 ? objSupplierMSADetail.fileName! : ''" tooltipPosition="top">
                              {{ objSupplierMSADetail.fileName! | truncate:40}}
                            </span>
                            <i *ngIf="isEditPage" pInputText pTooltip="Download" tooltipPosition="top" class="pi pi-download"> </i>
                          </a>
                          <ng-template #emptyFileName>
                            <span class="form-list-norecord">--</span>
                          </ng-template>
                        </span>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="footer">
                      
                      <a class="btn btn-outlined-primary" (click)="showEditMSADialog(objSupplierMSADetail,i)"> Edit </a>
                    </ng-template>
                  </p-card>
                </div>
              </div>

            </div>

            <!-- Add MSA Information End -->



            <div class="card flex justify-content-center">
              <!--Add MSA Modal Popup Starts-->
              <p-dialog appendTo="body" header="{{msaPopHeader}}" [(visible)]="displayMSADialog" [modal]="true" [style]="{ width: '83vw' }" [draggable]="false" [resizable]="false" [closable]="false">
                <ng-template pTemplate="content">
                  <form [formGroup]="supplierMSAForm">
                    <div class="fr-flex__container">
                      <div class="fr-flex__col-4">
                        <div class="fr-form__group">
                          <label> MSA Title   <em> * </em> <span class="max-length"> {{_supplierMSA.title?.length}}/50 </span></label>
                          <input type="text" pInputText [pTooltip]="_supplierMSA.title!.length > 35 ? _supplierMSA.title!: ''" formControlName="_msaTitle" class="form-control" [maxlength]="50" pInputText [(ngModel)]="_supplierMSA.title" trimSpace/>
                          <small class="p-error" *ngIf="msaSaved && supplierMSAForm?.get('_msaTitle')?.hasError('required')">This field is required.</small>
                          <small class="p-error" *ngIf="msaSaved && supplierMSAForm?.get('_msaTitle')?.hasError('maxlength')">Max length is 50 characters.</small>
                          <small class="p-error" *ngIf="msaSaved && supplierMSAForm?.get('_msaTitle')?.hasError('pattern') && !supplierMSAForm?.get('_msaTitle')?.hasError('maxlength')">Only spaces, only numeric characters and only special characters are not allowed</small>
                        </div>
                      </div>
                      <div class="fr-flex__col-4">
                        <div class="fr-form__group">
                          <label>Start Date   <em> * </em></label>
                          <div class="small-calendar">

                            <p-calendar appendTo="body" class="form-control" formControlName="_msaStartDate" [maxDate]="maxStartDate" [dateFormat]=_dateformart.CalenderDateFormat [(ngModel)]="_supplierMSA.startDate" (ngModelChange)="onStartDateSelection(_supplierMSA.startDate)" [showIcon]="true" [readonlyInput]="true"></p-calendar>
                            <small class="p-error" *ngIf="msaSaved && supplierMSAForm?.get('_msaStartDate')?.hasError('required')">This field is required.</small>
                          </div>
                        </div>
                      </div>
                      <div class="fr-flex__col-4">
                        <div class="fr-form__group">
                          <div class="small-calendar">
                            <label>End Date   <em> * </em></label>

                            <p-calendar appendTo="body" class="form-control" formControlName="_msaEndDate" [minDate]="minEndDate" [dateFormat]=_dateformart.CalenderDateFormat [(ngModel)]="_supplierMSA.endDate" (ngModelChange)="onEndDateSelection(_supplierMSA.endDate)" [showIcon]="true" [readonlyInput]="true"></p-calendar>
                            <small class="p-error" *ngIf="msaSaved && supplierMSAForm?.get('_msaEndDate')?.hasError('required')">This field is required.</small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="fr-flex__container">
                      <div class="fr-flex__col-4">
                        <div class="fr-form__group">
                          <label>Payment Terms    <em> * </em></label>
                          <p-dropdown appendTo="body" pInputText [pTooltip]="selectedPaymentTerms!.length > 35 ? selectedPaymentTerms!: ''" [filter]="true" formControlName="_paymentTerms" [options]="paymentTermsDataSource" [(ngModel)]="_supplierMSA.paymentTermId" optionLabel="label" optionValue="id" placeholder="Select Payment terms" (onChange)="onPaymentTermsChange()"></p-dropdown>
                          <small class="p-error" *ngIf="msaSaved && supplierMSAForm?.get('_paymentTerms')?.hasError('required')">This field is required.</small>
                        </div>
                      </div>
                      <div class="fr-flex__col-4">
                        <div class="fr-form__group">
                          <label>Attachment <em> * </em> <i pInputText pTooltip="Only 1 Attachment allowed." tooltipPosition="top" class="pi pi-info-circle"> </i></label>
                          <div *ngIf="_supplierMSA.fileName==''">
                            <input type="file" accept=".pdf, .xls, .xlsx,.doc,.docx" formControlName="_msafileSource" class="form-control" id="msaFileAttachment" (change)="onMSAfileAttachmentChange($any($event.target).files)" trimSpace >
                            <small class="p-error" *ngIf="msaSaved && supplierMSAForm?.get('_msafileSource')?.hasError('required')">This field is required.</small>
                          </div>
                          <div *ngIf="_supplierMSA.fileName!=''">
                            <a class="attachment-active" routerLinkActive="active">
                              <span [pTooltip]="_supplierMSA.fileName?.length > 40 ? _supplierMSA.fileName : ''" tooltipPosition="top">
                                <em> {{ _supplierMSA.fileName | truncate:39}} </em>
                              </span>
                              <i pInputText pTooltip="Delete" tooltipPosition="top" class="pi pi-trash" (click)="deleteMSAFileObject()"> </i>
                            </a>
                          </div>


                        </div>
  

                      </div>
                      <div class="fr-flex__col-4">
                        <div class="fr-form__group">
                          <label>Status <em> * </em></label>
                          <p-dropdown appendTo="body" [filter]="true" formControlName="_msaStatus" [options]="msaStatusDataSource" [(ngModel)]="_supplierMSA.statusMappingId" optionLabel="name" optionValue="id" placeholder="Select Status"></p-dropdown>
                          <small class="p-error" *ngIf="msaSaved && supplierMSAForm?.get('_msaStatus')?.hasError('required')">This field is required.</small>

                        </div>
                      </div>
                    </div>
                    <div class="fr-flex__container">
                      <div class="fr-flex__col-12">
                        <div class="fr-form__group">
                          <label>Description  <span class="max-length"> {{_supplierMSA.description?.length}}/300 </span> </label>
                          <textarea formControlName="_msaDescription" class="form-control" [maxlength]="300" pInputTextarea [(ngModel)]="_supplierMSA.description" trimSpace></textarea>
                          <small class="p-error" *ngIf="msaSaved && supplierMSAForm?.get('_msaDescription')?.hasError('maxlength')">Max length is 300 characters.</small>
                        </div>
                      </div>
                    </div>
                    <div class="fr-flex__container">
                      <div class="fr-flex__col-12">
                        <h4 class="popup_title"> Sponsor Contact </h4>
                      </div>
                    </div>

                    <div class="fr-flex__container">
                      <div class="fr-flex__col-4">
                        <div class="fr-form__group">
                          <label>Name  <em> * </em></label>
                          <p-dropdown appendTo="body" [filter]="true" appendTo="body" formControlName="_msaSponsorName" [options]="allMsaSponsorContactsDataSource"  (onChange)="onMSAOwnerChange($event)" [(ngModel)]="_supplierMSA.sponsorContactId" optionLabel="name" optionValue="id" placeholder="Select Sponsor Name" ></p-dropdown>
                          <small class="p-error" *ngIf="msaSaved && supplierMSAForm?.get('_msaSponsorName')?.hasError('required')">This field is required.</small>
                        
                        </div>
                      </div>
                    </div>

                    <div class="fr-flex__container">
                      <div class="fr-flex__col-12">
                        <h4 class="popup_title"> Supplier Contact </h4>
                      </div>
                    </div>
                    <div class="fr-flex__container">
                      <div class="fr-flex__col-4">
                        <div class="fr-form__group">
                          <label>Name <em> * </em></label>
                          
                          <p-dropdown appendTo="body" [filter]="true" appendTo="body" formControlName="_msaSupplierName" [options]="allMsaSupplierContactsDataSource"  (onChange)="onStakeholderChange($event)" [(ngModel)]="_supplierMSA.supplierContactId" optionLabel="contactName" optionValue="id" placeholder="Select Supplier Name" ></p-dropdown>
                          <small class="p-error" *ngIf="msaSaved && supplierMSAForm?.get('_msaSupplierName')?.hasError('required')">This field is required.</small>
                        
                        </div>
                      </div>
                    </div>
                    <div class="fr-flex__container">
                      <div class="fr-flex__col-12">
                        <h4 class="note-display popup"> <span> Note : </span> A contact can either be selected as an MSA owner or a stakeholder. The same contact cannot be selected as both (owner as well as stakeholder)</h4>
                      </div>
                    </div>


                  </form>
                </ng-template>
                <!-- Dialog Footer Start -->
                <ng-template pTemplate="footer">
                  <button type="button" class="btn btn-primary pull-right" [class.disabled]="isSavePopupBtnDisable" (click)="isSavePopupBtnDisable ? null : saveMSA()"> Save </button>
                  <button type="button" class="btn btn-secondary pull-right" (click)="cancelMSADialog(true)"> Cancel </button>
                </ng-template>

                <!-- Dialog Footer End -->
              </p-dialog>
            </div>
            <!-- MSAs Popup End -->
           
          </div>
          </div>
 <!-- Save & Cancel Footer buttons Start -->
 <div class="card-pad pad contact_pad_custom"> 
 <div class="fr-flex__container footer_btns">
  <div class="fr-flex__col-12 p-b-20">
    <a routerLinkActive="active" [class.disabled]="isSaveBtnDisable" (click)="isSaveBtnDisable ? null : OnSaveEngagement()" type="button" class="btn btn-primary pull-right"> Save  </a>
    <a type="button" (click)="onCancelEngagementCreate()" class="btn btn-secondary pull-right"> Cancel </a>

  
  </div>
</div>
</div>
<!-- Save & Cancel Footer buttons  End -->
        </p-tabPanel>
      
 <!-- Profile Tab End -->
        <!-- Contract Groups Tab Start -->
        <p-tabPanel [disabled]="!isEditPage" header="Functions">
         
          <div class="card-pad"> 
          <div class="tab-container">
  <!-- Added By Nagaraju Start-->
  <div class="sticky-header"></div>
 
 <!-- Added By Nagaraju End -->
             
<!-- -------------------start------------------------------ -->
<div class="fr-flex__container" *ngIf="!displayContractGroupdashboard">

  <div class="fr-flex__container m-t-20">
    <div class="fr-flex__col-4">
       <div class="fr-form__view function-m-b-10">
           <label>Viewing Currency</label>                    
           <p-dropdown appendTo="body" [filter]="true" (onChange)="OnCurrencyChange($event)" [(ngModel)]="selectedCurrency"  [options]="currenciesData"  optionLabel="code" optionValue="code" ></p-dropdown>       
       </div>
       </div>
       </div>


  <div class="fr-flex__col-4" *ngFor="let objEngagementContractsGroups of engagementContractsGroups; let i = index"> 
     <div class="fr-card__boxshadow"  (click)="TileClickEvent(objEngagementContractsGroups);"> 
     <span class="fr-card__title">
        <div class="fr-symbol__content-primary--text"> <span [pTooltip]="objEngagementContractsGroups.engagementGroupName.length > 30 ? objEngagementContractsGroups.engagementGroupName : ''" tooltipPosition="top">  {{objEngagementContractsGroups.engagementGroupName}} </span></div>
      </span>
      <span class="fr-symbol1">
        <span class="fr-symbol1__box"> 
           <span class="for-symbol__box--icon">
              <i class="icon-paper"></i>
            </span>
        </span>
        <div class="fr-symbol__content">
           <div id="engAddEditFunctionsActiveContracts" class="fr-symbol__content-primary--text">
             {{objEngagementContractsGroups.totalActiveContracts}}
           </div>
           <div class="fr-sybmobol__content-secondary-text">
              Total No of Contracts
           </div>
         </div>
     </span>

     <span class="fr-symbol2"> 
        <span class="fr-symbol2__box"> 
           <span class="for-symbol__box--icon">
              <i class="icon-briefcase1"></i>
            </span>
        </span>
     <div class="fr-symbol__content">
       <div id="engAddEditFunctionsTotalContractedValue" class="fr-symbol__content-primary--text">
         {{ getFormattedValue(objEngagementContractsGroups.totalContractedValue,  selectedCurrency) }} 
       </div>
        <div class="fr-sybmobol__content-secondary-text">
           Total Contracted Value
        </div>
      </div>
     </span>
     <span class="fr-symbol3">
        <span class="fr-symbol3__box"> 
           <span class="for-symbol__box--icon">
              <i class="icon-gps_not_fixed"></i>
            </span>
        </span>
        <div class="fr-symbol__content">
           <div id="engAddEditFunctionsOnboardAssociates" class="fr-symbol__content-primary--text">
              {{objEngagementContractsGroups.totalOnboardAssociates}}
           </div>
           <div class="fr-sybmobol__content-secondary-text">
              Onboard Associates
           </div>
         </div>
     </span>
  </div>
  </div>
</div>


 <div *ngIf="displayContractGroupdashboard">




    <div class="fr-flex__container m-b-10">

      <div class="sticky-header fr-flex__container m-t-20"></div>
      
        <div class="fr-flex__col-8">
            <h3 id="engAddEditFucntionsh3Title">{{selectedContractGroupData.engagementGroupName}}</h3>
        </div>
        <div class="fr-flex__col-4"> 
          <a class="btn btn-primary-link pull-right" (click)="showDashboard()"> <i class="pi pi-eye"></i> View Teams Dashboard </a>
          <a class="btn btn-primary-link backtolist pull-right" (click)="BackToFunctions()">  <i class="pi pi-arrow-left"></i> Back to Functions </a>
       </div>
    </div>
    <div class ="fr-flex__container">
      <div class="display-flex">
        <div class="cg__label"> <span class="legend_smaller_circle"></span> Total No of Contracts : </div>
        <div class="cg__value"> {{selectedContractGroupData.totalActiveContracts}} </div>
      </div>
      <div class="display-flex">
        <div class="cg__label"> <span class="legend_smaller_circle"></span> Onboard Associates : </div>
        <div class="cg__value"> {{selectedContractGroupData.totalOnboardAssociates}} </div>
      </div>
      <div class="display-flex">
        <div class="cg__label"> <span class="legend_smaller_circle"></span> Total Contracted Value : </div>
        <div class="cg__value"> {{ getFormattedValue(selectedContractGroupData.totalContractedValue, defaultCurrency) }} </div>
      </div>
      <div class="display-flex">
        <div class="cg__label"> <span class="legend_smaller_circle"></span> Total Value/Year : </div>
        <div class="cg__value"> {{ getFormattedValue(selectedContractGroupData.totalContractedValuePerYear, defaultCurrency) }} </div>
      </div>
    </div>




    <div class="tab-container" *ngIf="TeamsDashboardPrivilage">
                    
      <div class="fr-flex__col-8">
   <div id="dashboardContainer" class="m-t-20 m-b-20"></div>
  
</div>
</div>


<!-- Start Finnace dashboard -->
<div class="fr-flex__col-12" *ngIf="viewFinanceDashboard">
  <div class="fr-flex__container m-t-10">
      
      <div class="fr-flex__col-3">
          <div class="fr-form__group">
              <label>Finance Dashboard to View</label>
              <p-dropdown Id="dashView" appendTo="body" [options]="DashboardToViewDataSource" placeholder="Select Dashboard to View" optionLabel="label" optionValue="value"
                  (onChange)="onDashboardToViewChange($event)" [(ngModel)]="selectedDashboardToView"></p-dropdown>
                  
  
          </div>
      </div>
      <div class="fr-flex__col-3">
          <div class="fr-form__group">
              <label>Fiscal Year</label>
              <p-dropdown Id="fisYear" appendTo="body" [filter]="true" [options]="fiscalYearList" optionLabel="label" optionValue="value"
              [disabled]="IsDashboardTillDate" [(ngModel)]="selectedFiscalYear"  placeholder="Select Fiscal Year" (onChange)="onFiscalYearChange($event)"></p-dropdown>
              
          </div>
      </div>
      <div class="fr-flex__col-1 custom-pad-left-zero">
          <div class="fr-form__group">
              <label>&nbsp;</label>
              <button Id="dashFun" class="btn btn-primary pull-left" (click)="onDashboardApplyClick()" [disabled]="isApplyDisable"> Apply</button>
              </div>
          </div>
      </div>
  </div>
  <div class="tab-container" *ngIf="viewFinanceDashboard">
  <div id="financeDashboardContainer" class="m-t-20 m-b-20"></div>
  </div>
     <!-- End Finnace dashboard -->      
     <div class="tab-container">
        <app-contractlist [fromSupplier]="true" [supplierId]="engagementId" [selectedContractGroupId]="selectedContractGroupId"></app-contractlist>
     </div>
 </div>
 <!-- -------------------End------------------------------ -->
 </div>
       </div>
               <div class="card-pad" *ngIf="engagementContractsGroups?.length==0">
                <div class="fr-flex__container"> 
               <div  class="fr-flex__col-12">
                  <div class="card-norecord"> 
                     <div class="card flex justify-content-center m-b-20"> 
                  <p-card header="">
                     <img id="emptyBoxImage" src="../../../../assets/images/empty-box.svg" alt="" width="100">
                     <p class="m-0">
                      No Records
                     </p>
                  </p-card>
               </div>
               </div>
                </div>
               </div>
            </div>
        </p-tabPanel>
        <!-- Contract Groups Tab End -->
        <!-- Contracts Tab Start -->
      
        <p-tabPanel header="Contracts" [disabled]="!isEditPage">
          <div class="tab-container" *ngIf="isContractTab" >
              
             <app-contractlist *ngIf="isEditPage" [fromSupplier]="true" [supplierId]="engagementId"></app-contractlist>
             </div>
       
       </p-tabPanel>
       
       <p-tabPanel [disabled]="!isEditPage" header="Governance">
          <p-tabView class="fr-tabview__subheader" #mySubTabView (onChange)="onSubTabSelect($event)" [activeIndex]="activeSubTabIndex" [(activeIndex)]="activeSubTabIndex">
             <p-tabPanel header="KPIs">
                <app-eng-kpi-list id="engKpiList" *ngIf="_kpiChildComponents.supKpiList==_currentKpiChildComponent"
                   [engagementId]="engagementId" (addKpiButtonClick)="handleAddButtonClick(1)"
                   (editKpiButtonClick)="handleEditButtonClick($event,1)"
                   (viewKpiButtonClick)="handleViewButtonClick($event,1)"> </app-eng-kpi-list>
                <app-eng-create-kpi id="engCreateKpi" *ngIf="_kpiChildComponents.supKpiAdd==_currentKpiChildComponent"
                   [engagementId]="engagementId"  (cancelKpitButtonClick)="handleCancelAddButtonClick(1)"
                   (saveKpiButtonClick)="handleSaveAddButtonClick(1)"
                   (viewKpiButtonClick)="handleViewButtonClick($event,1)"> </app-eng-create-kpi>
                <app-eng-edit-kpi id="engEditKpi" *ngIf="_kpiChildComponents.supKpiEdit==_currentKpiChildComponent"
                   [engagementId]="engagementId" [SupplierId]="SupplierId"   [SponsorId]="SponsorId"  (cancelKpitButtonClick)="handleCancelAddButtonClick(1)"
                   (viewKpiButtonClick)="handleViewButtonClick($event,1)" [kpiId]="kpiId"> </app-eng-edit-kpi>
                <app-eng-view-kpi id="engViewKpi" *ngIf="_kpiChildComponents.supKpiView==_currentKpiChildComponent"
                   [engagementId]="engagementId" (cancelKpitButtonClick)="handleCancelAddButtonClick(1)" [kpiId]="kpiId" (editKpiButtonClick)="handleEditButtonClick($event,1)">
                </app-eng-view-kpi>
             </p-tabPanel>
             <p-tabPanel header="Meetings">
                <app-eng-meetings-list id="engMeetingsList" *ngIf="_meetingChildComponents.supMeetingList==_currentMeetingChildComponent"
                   [engagementId]="engagementId" (addMeetingButtonClick)="handleAddButtonClick(2)"
                   (editMeetingButtonClick)="handleEditButtonClick($event,2)"
                   (viewMeetingButtonClick)="handleViewButtonClick($event,2)"> </app-eng-meetings-list>
                <app-eng-create-meeting id="engCreateMeeting" *ngIf="_meetingChildComponents.supMeetingAdd==_currentMeetingChildComponent"
                   [engagementId]="engagementId" [supplierName]="supplierName" (cancelMeetingButtonClick)="handleCancelAddButtonClick(2)"
                   (saveMeetingButtonClick)="handleSaveAddButtonClick(2)"
                   (viewMeetingButtonClick)="handleViewButtonClick($event,2)"> </app-eng-create-meeting>
                <app-eng-edit-meeting id="engEditMeeting" *ngIf="_meetingChildComponents.supMeetingEdit==_currentMeetingChildComponent"
                   [engagementId]="engagementId" [supplierName]="supplierName" (cancelMeetingButtonClick)="handleCancelAddButtonClick(2)"
                   (viewMeetingButtonClick)="handleViewButtonClick($event,2)" [meetingId]="meetingId">
                </app-eng-edit-meeting>
                <app-eng-edit-meeting-rec-yes id="engEditMeetingRecYes"
                   *ngIf="_meetingChildComponents.supMeetingEditRecYes==_currentMeetingChildComponent"
                   [engagementId]="engagementId" [supplierName]="supplierName" (cancelMeetingButtonClick)="handleCancelAddButtonClick(2)"
                   (viewMeetingButtonClick)="handleViewButtonClick($event,2)" [meetingId]="meetingId">
                </app-eng-edit-meeting-rec-yes>
                <app-eng-view-meeting id="engViewMeeting" *ngIf="_meetingChildComponents.supMeetingView==_currentMeetingChildComponent"
                   [engagementId]="engagementId" [supplierName]="supplierName" [meetingId]="meetingId" (cancelMeetingButtonClick)="handleCancelAddButtonClick(2)"
                   (editMeetingButtonClick)="handleEditButtonClick($event,2)"> </app-eng-view-meeting>
             </p-tabPanel>
             <p-tabPanel header="Audit Support">
                <app-eng-list-audit id="engListAudit" *ngIf="_auditChildComponents.supAuditList==_currentAuditChildComponent"
                   [engagementId]="engagementId" (addButtonClick)="handleAuditAddButtonClick()"
                   (editButtonClick)="handleAuditEditButtonClick($event)"
                   (viewButtonClick)="handleAuditViewButtonClick($event)"> </app-eng-list-audit>
                <app-eng-create-audit  id="engCreateAudit" *ngIf="_auditChildComponents.supAuditAdd==_currentAuditChildComponent"
                   [engagementId]="engagementId" [supplierName]="supplierName" (cancelAuditButtonClick)="handleAuditCalcelAddButtonClick()"
                   (saveAuditButtonClick)="handleAuditSaveAddButtonClick()"
                   (viewButtonClick)="handleAuditViewButtonClick($event)"> </app-eng-create-audit>
                <app-eng-edit-audit id="engEditAudit" *ngIf="_auditChildComponents.supAuditEdit==_currentAuditChildComponent"
                   [engagementId]="engagementId"  [supplierName]="supplierName" (cancelAuditButtonClick)="handleAuditCalcelAddButtonClick()"
                   (viewButtonClick)="handleAuditViewButtonClick($event)" [auditId]="auditId"> </app-eng-edit-audit>
                <app-eng-view-audit id="engViewAudit" *ngIf="_auditChildComponents.supAuditView==_currentAuditChildComponent"
                   [engagementId]="engagementId"  [supplierName]="supplierName" [auditId]="auditId" (cancelAuditButtonClick)="handleAuditCalcelAddButtonClick()"
                   (editButtonClick)="handleAuditEditButtonClick($event)"> </app-eng-view-audit>
             </p-tabPanel>
          </p-tabView>
       </p-tabPanel>
        
       <p-tabPanel header="Finance" [disabled]="!isEditPage" [hidden]="!viewFinancePrivilege">
        <app-eng-summary-view [engagementId]="engagementId" [engagementName]="engagementName"></app-eng-summary-view>
       </p-tabPanel>
       
    

       <p-tabPanel header="Team" [disabled]="!isEditPage">
        
        <app-associate-list *ngIf="_teamChildComponents.associateChildList==_currentTeamChildComponent" [FromType]="FromType" [entityId]="engagementId" [SupplierId]="SupplierId"   [SponsorId]="SponsorId"  [EngagementId]="engagementId" [engagementStartDate]="engagementStartDate" [EngagementName]="engagementName" (addTeamButtonClick)="handleAddButtonClick(4)" (editTeamButtonClick)="handleEditButtonClick($event,4)" (viewTeamButtonClick)="handleViewButtonClick($event,4)"> </app-associate-list>
        <app-associate-add-edit *ngIf="_teamChildComponents.associateChildAddEdit==_currentTeamChildComponent" [FromType]="FromType" [actionType]="actionType" [associateId]="associateId" [entityId]="engagementId" [SupplierId]="SupplierId"   [SponsorId]="SponsorId"  [EngagementId]="engagementId" (cancelTeamButtonClick)="handleCancelAddButtonClick(4)" (saveTeamButtonClick)="handleSaveAddButtonClick(4)" (viewTeamButtonClick)="handleViewButtonClick($event,4)"> </app-associate-add-edit>
        <app-associate-view *ngIf="_teamChildComponents.associateChildView==_currentTeamChildComponent" [FromType]="FromType" [associateId]="associateId" [entityId]="engagementId" (editTeamButtonClick)="handleEditButtonClick($event,4)" (cancelTeamButtonClick)="handleCancelAddButtonClick(4)"> </app-associate-view>
    
    </p-tabPanel>


      </p-tabView>
    
      <!-- Tab View Module End -->
  </div>
</body>
</html>
