import { Component , OnInit  } from '@angular/core';
import { DatePipe,} from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import {CodeListValues, SupplierContact,SponsorContact, MasterSupplierProfile} from '../../Models/master-supplier-profile';
import { MasterSupplierService } from '../../Services/master.supplier.service'
import { FormBuilder, FormGroup,  Validators,AbstractControl } from '@angular/forms';
import { ConfirmationService, MessageService,  MenuItem } from 'primeng/api';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';
import { Dateformat, breadcrumnsCodes, AuditIdentifier, AuditLogEntities, AuditOperations, AuditLogModule,ActiveInActiveStatus } from 'src/app/shared/constants/global.constants';
import { LoaderService } from 'src/app/Loader/loader.service';
import { IDeactivateGuard } from 'src/app/shared/Guards/can-deactivate-guard.service';
import { Observable} from 'rxjs';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { AuditLogModel } from 'src/app/shared/ModelComparison/audit.log.response.model';
import { AuditLogService } from 'src/app/shared/ModelComparison/audit.log.service';
import { UserGroupModel } from 'src/app/Admin/Authorization/UserRoleMapping/Models/user';
import { UserService } from 'src/app/Admin/Authorization/UserRoleMapping/Services/user.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { EngagementService } from 'src/app/Engagement/Services/engagement.service';
import { ContractService } from 'src/app/Contracts/Services/contract.service';


interface City {
  name: string;
  code: string;
  Id:number;
}

interface UploadEvent {
  originalEvent: Event;
  files: File[];
}

interface codelist {
  Title: string;
  ID: string;
  StartDate: string;
  EndDate: string;
  Term: string;
  Value: string;
  ValueYear: string;
  Currency: string;
  Status: string;
  Edit: string;
}


@Component({
  selector: 'app-master-add-edit-supplier',
  templateUrl: './master-add-edit-supplier.component.html',
  styleUrls: ['./master-add-edit-supplier.component.css']
})

export class MasterAddEditSupplierComponent   implements OnInit,IDeactivateGuard   {

  onUpload(event: UploadEvent) {
      this.messageService.add({ severity: 'info', summary: 'File Uploaded with Basic Mode', detail: '' });
  }

  countries: any[] | undefined;
  Paymentterms: any[] | undefined;
  status: any[] | undefined;
  sponsorname: any[] | undefined;
  function: any[] | undefined;
  Suppliercontact: any[] | undefined;
  value: string | undefined;
  cities!: City[];
  selectedCities!: City[];
  visible: boolean = false;
  position: string = 'center';
  date: Date | undefined;
  editedSupplierId:number=0;

  showDialog() {
    this.visible = true;
}
  
  codelist: codelist[] = [] ;
  _supplierProfile=new MasterSupplierProfile();
  _supplierContact= new SupplierContact();
  _supplierContacts: SupplierContact[]=[];

  displayDialog: boolean = false;
  name: string = '';
  _codeListValues:CodeListValues[] | undefined;
  locationsDataSource: CodeListValues[] | undefined;
  servicesDataSource: CodeListValues[] | undefined;
  sponserDataSource:any[]=[];
  supplierForm: FormGroup;
  contactForm: FormGroup;
 // supplierMSAForm: FormGroup;
  isFormDirty = false;
  submitted = false;
  contactSubmitted=false;
  selectedItem: any;
  oldSelectedItem: any;
  supplierdata : any[] = [];
  allSupplierdetails: any[] = [];
  uniqueMessage=false;
  uniqueSupplierNameMessage=false;
  uniqueSupplierContactNameMessage=false;
  currentSupplierContactIndex=-1;
  _dateformart :any;
  isLoading=false;
  executeCanExist: boolean = true;
  breadcrumbs: MenuItem[]=[];

  //MSA Logic

  supplierContactsDataSource : SupplierContact[]=[];
  sponsorContactsDataSource : SponsorContact[]=[];
  functionDataSource: any[]=[];

  formData =  new  FormData();
  displayMSADialog: boolean = false;
  msaStatusDataSource : any[]=[]; 
  paymentTermsDataSource : any[]=[];
  functionsDataSource : any[]=[];
  
  selectedMSAItem: any;
  oldSelectedMSAItem: any;
  msaSaved = false;
  enableAddMSA=false;
 
  _enumBreadcrumb :any;
  msaPopHeader='Add MSA';
  minEndDate:any;
  maxStartDate:any;
  isEditMSA= false;
  oldSupplierprofile:any;
  statusData: any=[];
  defaultstatus: any=[];
  contactHeader='Add Contact';
  oldSupplierData:any;
  oldSupplierContact:any;
  oldSupplierMSA:any;
  auditIdentifierDetails:any;

  usergroup=new UserGroupModel();
  supplierId: any;
  heading:any;
  _dateformat:any;
  constructor( private privilegeService: PrivilegeService,private _userService: UserService,private router: Router, 
    private fb: FormBuilder, private confirmationService: ConfirmationService, private messageService: MessageService, 
    private masterDataService: SponserService, private loaderService: LoaderService, private breadcrumbService: BreadcrumbService, 
    private datepipe: DatePipe, private auditLogService:AuditLogService,
    private service: MasterSupplierService, private activeRouter: ActivatedRoute,
  private engagementService:EngagementService,
  private contractService:ContractService){


  this.supplierForm = this.fb.group({
    _supplierName: ['', [Validators.required, Validators.maxLength(100), Validators.minLength(3),Validators.pattern(/^(?=(?:[^a-zA-Z]*[a-zA-Z]){3})/),this.notOnlySpacesValidator]],
    _supplierId:['',[Validators.required,Validators.maxLength(10),Validators.minLength(3),Validators.pattern(/^[a-zA-Z0-9\-]*$/),this.notOnlySpacesValidator]],
    _supplierLocations: ['', [Validators.required]],
    _supplierServices: ['', [Validators.required]],
    _website:['',[Validators.maxLength(30),Validators.pattern(/^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/)]],
    _headquartersAddress:['',[Validators.maxLength(300)]],
    _notesAndComments:['',[Validators.maxLength(300)]],
   // statusMappingId: ['', [Validators.required]],
  });

  this.contactForm = this.fb.group({
    _contactName: ['', [Validators.required, Validators.maxLength(30),Validators.pattern(/^[a-zA-Z\s]{1,30}$/),this.notOnlySpacesValidator]],
    _email:['',[Validators.required,Validators.maxLength(100)]],
    _countryCode:[''],
    _phoneNumber:['',[Validators.minLength(7),Validators.maxLength(15),Validators.pattern(/^[0-9]*$/)]],
    _designation:['',[Validators.maxLength(100),this.notOnlySpacesValidator]],
  });

  this.supplierForm.valueChanges.subscribe(() => {
    this.isFormDirty = true;
  });
 
 
 }
 auditIdentifier:any;
 auditEntities:any;
 auditOperations:any;
 auditModule:any;

    ngOnInit() {
      this.executeCanExist=true;
      this._dateformat=Dateformat;
      this.activeRouter.params.subscribe(params => {
        this.supplierId = params['id']; //log the value of id
      });
      if(this.supplierId!=undefined)
      {
       this.heading="Edit Supplier";
       this.editedSupplierId=this.supplierId;
       this.getSupplierById();
       this.getAllEngagementsBySupplierId(this.editedSupplierId);
      }
      else
      {
      this.supplierId=0;
      this.editedSupplierId=0;
      this.heading="Create Supplier"
      }

      var userData=this.privilegeService.getUserData();
      this.usergroup.userId=userData.id;
      this.usergroup.email=userData.emailId;
      this.usergroup.groupTypeCode=userData.groupTypeCode;
      this._enumBreadcrumb=breadcrumnsCodes;
      this.auditEntities=AuditLogEntities;
      this.auditOperations=AuditOperations;
      this.auditModule=AuditLogModule;
      this.generateBreadcrumb();
      this.GetSupplierStatus();
    
      this._dateformart=Dateformat;  
      this.auditIdentifier=AuditIdentifier;  
      this.GetAllSuppliers();
    
    
      this.GetAllCountries(0);
      this.GetAllServices(1);
     this.codelist = [
      {
        Title: 'Regularity support CMS',
        ID: 'C18973',
        StartDate: '01-Apr-2020',
        EndDate: '09-Jun-2023',
        Term: '3 year 2 Months',
        Value: '4,000,000',
        ValueYear: '13,33,333',
        Currency: 'INR',
        Status: 'Active',
        Edit: 'icon',
      
      },
    
     ];
     this.loaderService.hidefinalLoader();// hide after 4 seconds even get any error
     this.formOldSupplierKpi();
     
     
    
     this.oldSupplierData=JSON.stringify(this._supplierProfile);
     this.oldSupplierContact=JSON.stringify(this._supplierContacts);
     this.auditIdentifierDetails= AuditIdentifier;
       }

    

    formOldSupplierKpi(){
      setTimeout(() => {
        this.oldSupplierprofile=JSON.stringify(this._supplierProfile);
      }, 2000);
    }
    notOnlySpacesValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value && control.value.trim() === '') {
       return { 'onlySpaces': true };
     }
     return null;
   }


engagementDataSource:any;
   getAllEngagementsBySupplierId(supplierId:any)
   {

    this.engagementService.getAllEngagementsBySupplierId(this.supplierId).subscribe(
      (res:any)=>{
      this.engagementDataSource=res.data;
     });
   }

//getting sponser data
GetAllSponsers() {
  this.loaderService.showLoader();
  this.masterDataService.GetAllSponsers()
  .subscribe(res=>{
    this.loaderService.hideLoader();
    this.sponserDataSource=res.data;

    // sponsor dropdown display only active sponsors
    this.sponserDataSource = this.sponserDataSource?.filter(t=>t.isDeleted==false);

    this.sponserDataSource = this.sponserDataSource.map(obj => ({ name: `${obj.name} (${obj.sponserId})`,id:obj.id }));
   
  })
};

selectedMSASponsorEmail:string='';
selectedSponsorName: string = '';
  onSponsorChange() {
    const selectedSponsor = this.sponserDataSource.find(sponsor => sponsor.id === this.supplierForm.value._sponserName);
    this.selectedSponsorName = selectedSponsor ? selectedSponsor.name : '';
  }

//get county data
GetAllCountries(type:number) {
  this.masterDataService.getCodeListData(type,'').subscribe(
    res=>{
      this.locationsDataSource=res.data;
    }
  );
};
//get county data
GetAllServices(type:number) {
  this.masterDataService.getCodeListData(type,'').subscribe(
    res=>{
      this.servicesDataSource=res.data;
    }
  );
};

//get all suppliers data
GetAllSuppliers() {
  this.service.GetSuppliers().subscribe(
    (    res: { data: any[]; })=>{
      this.allSupplierdetails=res.data;
    } 
 );
};

updateUserGroup(GroupId:number)
  {
    this.usergroup.groupId=GroupId;
    this._userService.UpdateUserGroup(this.usergroup).subscribe(
      data=>{
        this.privilegeService.setGroup(GroupId,2);
      }
    );
  }



  
oldServices:any;

  getSupplierById(){
    this.loaderService.showLoader();

    
    this.service.getSupplierById(this.supplierId).subscribe(
     (res:any)=>{
      this.supplierdata = res.data;
      this.oldSupplierprofile=JSON.stringify(this.supplierdata);
      this.oldSupplierData=JSON.stringify(res.data);
      this.oldSupplierContact=JSON.stringify(res.data.supplierContacts);
      this.editedSupplierId=res.data.id;
      this._supplierProfile=res.data;
      this.oldServices=res.data.supplierServices;

      this._supplierProfile.createdBy =this.privilegeService.getUserName(res.data.createdBy);
      this._supplierProfile.updatedBy =this.privilegeService.getUserName(res.data.updatedBy);
      this._supplierProfile.supplierContacts = res.data.supplierContacts;

      this._supplierContacts= res.data.supplierContacts;
      this.generateBreadcrumbforEdit(this._enumBreadcrumb.EMSD, this._supplierProfile.name,"");
    
    });
}
isSaveBtnDisable = false;
onSaveSupplierProfile() {
    this.submitted = true;
    this.isSaveBtnDisable = true;
    setTimeout(() => {
      this.isSaveBtnDisable = false;
    }, 3000);

    //this.supplierForm.valid && !this.uniqueMessage && !this.uniqueSupplierNameMessage
    if (this.supplierForm.valid && !this.uniqueMessage && !this.uniqueSupplierNameMessage) {


      this.loaderService.showLoader();
      this.executeCanExist = false;
      this._supplierProfile.createdBy= this.privilegeService.getLoginUserId();
      

      this._supplierProfile.supplierContacts= this._supplierContacts;
      

      if(this.supplierId >0){
        this.UpdateSupplierDetails();

      }
     else{
      this.service.AddSupplier(this._supplierProfile).subscribe(res => {
        this.messageService.add({ key: 'tc', severity: 'success', summary: 'Supplier Information has been saved', detail: '' });
        if(res.status)
        {
          if(this.usergroup.groupTypeCode=='SUP')
            {
              this.updateUserGroup(res.data)
            }
          this.loaderService.hideLoader();
          this.router.navigate(['/ViewMasterSupplier', res.data]);
        }
        this.compareModels();
    });
     }
      this.loaderService.hideLoader();
    }
    else{
      this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Please enter required information', detail: '' });    
    }
  }


      addContactShowDialog() {
      
      
        this.uniqueSupplierContactNameMessage=false;
        this.currentSupplierContactIndex=-1;
       this._supplierContact= new SupplierContact();
       this.contactSubmitted=false;
        this.displayDialog = true;
        this.contactHeader='Add Contact'
     
     
      }
      isSavePopupBtnDisable = false;
      saveContactData() {
        this.isSavePopupBtnDisable = true;
        setTimeout(() => {
          this.isSavePopupBtnDisable = false;
        }, 3000);
        this.contactSubmitted = true;
        if (this.contactForm.valid && !this.uniqueSupplierContactNameMessage) {
        if(this.selectedItem==null)
        {
        this._supplierContacts.push(this._supplierContact)
        this.displayDialog = false;
        this.messageService.add({
          key: 'tc',
          severity: 'success',
          summary: 'Contact Information has been saved',
          detail: '',
      });
        }
        if(this.selectedItem!=null)
        {
          const contactIndex = this._supplierContacts.findIndex(item => item.contactName === this.oldSelectedItem.contactName && item.email === this.oldSelectedItem.email && item.phoneNumber === this.oldSelectedItem.phoneNumber);
          if (contactIndex !== -1) {
            this._supplierContacts[contactIndex] = { ...this.selectedItem };
            this.selectedItem = null; 
            this.oldSelectedItem=null;
          }
          this.displayDialog = false;
          this.messageService.add({
            key: 'tc',
            severity: 'success',
            summary: 'Contact Information has been updated',
            detail: '',
        });
        }
       
      }
    }
    
    checkExist()
    {
      this.uniqueMessage=false;
    const contactIndex = this.allSupplierdetails.findIndex(item => item.supplierId === (this._supplierProfile.supplierId));
     if(contactIndex !== -1 && this.editedSupplierId!==this.allSupplierdetails[contactIndex].id)
     {
        this.uniqueMessage=true;
     }
      else{
        this.uniqueMessage=false;
      }
    }

    checkSupplierNameExist()
    {
      this.uniqueSupplierNameMessage=false;
    const contactIndex = this.allSupplierdetails.findIndex(item => item.name.toLowerCase() === (this._supplierProfile.name?.toLocaleLowerCase()));
     if(contactIndex !== -1 && this.editedSupplierId!==this.allSupplierdetails[contactIndex].id)
     {
        this.uniqueSupplierNameMessage=true;
     }
      else{
        this.uniqueSupplierNameMessage=false;
      }
    }

    checkSupplierContactNameExist(contactDetails:any)
    {
     
      if(this._supplierContacts.length>0){
        if (this.isNameRepeated(contactDetails)) {
          this.uniqueSupplierContactNameMessage=true;
        } else {
          this.uniqueSupplierContactNameMessage=false;
        }
      }
      
    }

// Function to check if a name exists more than one time in the array
isNameRepeated(contactDetails: any): boolean {

  this.uniqueSupplierContactNameMessage=false;
      let nameCount = 0;
      const contactName = contactDetails.contactName.toLowerCase();
      if(this.currentSupplierContactIndex==-1) 
      {
        this._supplierContacts.forEach((obj: any, index: any) => {
          if (obj.contactName.toLowerCase() === contactName) {
              nameCount++;
          }
      });
      }
      else if(this.currentSupplierContactIndex>=0){
        this._supplierContacts.forEach((obj: any, index: any) => {
          if (obj.contactName.toLowerCase() === contactName &&  index!=this.currentSupplierContactIndex) {
              nameCount++;
          }
      });
      }

      return nameCount > 0;
  }

    editContact(item: any,rowIndex:any) {
      this.uniqueSupplierContactNameMessage=false;
      this._supplierContact= new SupplierContact();
      this.displayDialog = true;
      this.contactHeader='Edit Contact';
      this.selectedItem = { ...item }; 
      this.oldSelectedItem={ ...item }; 
      this._supplierContact=this.selectedItem;
      this.currentSupplierContactIndex=rowIndex;
    }


      cancelDialog() {
        this.displayDialog = false;
        this.selectedItem=null;
        this.messageService.add({
          key: 'tc',
          severity: 'info',
          summary: 'Contact Information has not been saved',
          detail: '',
      });
      }
    
      
      isContactExistOnMSA=false;
      isContactExistOnContract=false;

      confirm(item: any) {
        this.loaderService.showLoader();
        this.selectedItem = item;
        
            
           var msaIndex;
            if(this.supplierId!=0)
              {
            msaIndex = this.engagementDataSource.findIndex((engagement: any) => {
              return engagement.engagementMasterServiceAgreements.some((msa: any) => {
                return msa.supplierContactId === item.id;
              });
            });
          }
          else{
            msaIndex=-1;
          }
      
          
           if(msaIndex==-1)
          {
            this.isContactExistOnMSA=false;
          }
         else{
           this.selectedItem=null;
           this.isContactExistOnMSA=true;
          }

          this.CheckContactOnEngagementsContracts(item.id);
    }





engagementContractList:any;

    CheckContactOnEngagementsContracts(contactId: any) {
      this.contractService.GetAllEngagementsContractsBySupplierId(this.supplierId).subscribe(res => {
          this.engagementContractList = res.data;
  
          const activeTitles = this.engagementContractList
              .filter((contract: any) => contract.ownerSupplierContactId === contactId)
              .map((contract: any) => contract.title);
  
          const commaSeparatedTitles = activeTitles.join(', ');
          this.isContactExistOnContract = activeTitles.length > 0;
  
          if (this.isContactExistOnMSA || this.isContactExistOnContract) {
              let summaryMessage = '';
              
              if (this.isContactExistOnMSA && this.isContactExistOnContract) {
                  summaryMessage = `This contact is selected as the Owner on the Contract(s) "${commaSeparatedTitles}" and also on an MSA. It cannot be deleted.`;
              } else if (this.isContactExistOnMSA) {
                  summaryMessage = 'This contact is linked to an MSA and cannot be deleted.';
              } else if (this.isContactExistOnContract) {
                  summaryMessage = `This contact is selected as the Owner on the Contract(s) "${commaSeparatedTitles}" and cannot be deleted.`;
              }
  
              this.messageService.add({ 
                  key: 'tc', 
                  severity: 'warn', 
                  life: 8000, 
                  summary: summaryMessage, 
                  detail: '' 
              });
          } else {
              this.confirmationService.confirm({
                  message: 'Are you sure you want to delete this contact from this Supplier?',
                  header: 'Confirmation',
                  icon: 'pi pi-exclamation-triangle',
                  accept: () => {
                      this.deleteItem();
                      this.messageService.add({
                          key: 'tc',
                          severity: 'success',
                          summary: 'Contact has been deleted',
                          detail: '',
                      });
                  },
                  reject: (type: any) => { }
              });
          }
          
          this.loaderService.hideLoader();
      });
  }
  

    deleteItem() {
      const contactIndex = this._supplierContacts.findIndex(item => item === this.selectedItem);
      if (contactIndex !== -1) {
        this._supplierContacts.splice(contactIndex, 1);
        this.selectedItem = null;
      }
    }
    onCancelSupplierCreate()
    {
      this.router.navigate(['/SuppliersListMasterData']);
    
    }

    
    onServicesChange(event:any)
    {
      var currentServices=event;
      const removedItems = this.oldServices.filter((item:any) => !currentServices.includes(item));
      
      var isServiceExistInAnyEngagement=false;
      this.engagementDataSource.forEach((engagement: any) => {
        if (engagement.services.some((service: any) => removedItems.includes(service))) {
          isServiceExistInAnyEngagement = true;
          return; // Exit the loop early if a match is found
        }
      });
      
      if (isServiceExistInAnyEngagement) {
        // Handle the removal of items
        this.messageService.add({  key: 'tc',severity: 'warn', summary: 'This service is selected on an Engagement and cannot be removed.', detail: '' });
        this._supplierProfile.supplierServices?.push(removedItems[0]);
      }
      this.oldServices=this._supplierProfile.supplierServices;//In every action in Services oldServices should update, it is previousServices
    }



UpdateSupplierDetails(){

  this._supplierProfile.updatedBy =this.privilegeService.getLoginUserId();
  this.service.UpdateSupplier(this._supplierProfile).subscribe(res => {
    this.messageService.add({ key: 'tc',
     severity: 'success',
     summary: 'Changes have been saved ', detail: '',
    });
    this.loaderService.hideLoader();
    if(res.status)
    {
      //this.router.navigate(['/ViewSupplier',this.selectedSupplierId])
      this.router.navigate(['/ViewMasterSupplier', this.supplierId]);
    }
    this.compareModels();
  });
  this.loaderService.hideLoader();
}



  canExist(): boolean | Promise<boolean> | Observable<boolean> {

    let message="";
    let summary="";
    if(this.editedSupplierId==0){
      message="Supplier will not be saved. Do you want to continue?";
      summary="Supplier not saved";
    }
    else{
      message="Changes will not be saved, Do you want to continue?";
      summary="Changes not saved";
    }

    if (this.executeCanExist &&(!(this.oldSupplierprofile==JSON.stringify(this._supplierProfile)) ||  !(this.oldSupplierContact==JSON.stringify(this._supplierContacts))))
    {
      return new Promise<boolean>((resolve) => {
        this.confirmationService.confirm({
          message: message,//'Supplier will not be saved, Continue?',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.messageService.add({
              key: 'tc',
              severity: 'info',
              summary: summary,
              detail: '',
          });
            resolve(true); 
          },
          reject: () => {
            resolve(false); 
          },
        });
      });
    }
    else{
      return true;
    }
    
  }
  generateBreadcrumb()
  {

    if(this.supplierId==0)
      {
        this.breadcrumbService.generateBreadcrumb(this._enumBreadcrumb.AMSD);
      }
      else if(this.supplierId>0)
      {
        
        this.generateBreadcrumbforEdit(this._enumBreadcrumb.EMSD,"","");
      }

        
  }

  generateBreadcrumbforEdit(component?:string,recordName?:string,tabName?:string)
  {
    this.breadcrumbService.generateBreadcrumb(component,recordName,tabName);
  }
  getBreadcrumbs(): MenuItem[] {
    return this.breadcrumbService.getBreadcrumbs();
  }
  GetSupplierStatus(){
    this.masterDataService.getCodeListData(3,'Supplier').subscribe(
      data=>{
        this.statusData=data.data;
        this.defaultstatus=this.statusData.filter((x:any)=>x.name=ActiveInActiveStatus.Active);
        this._supplierProfile.statusMappingId= this.defaultstatus[0].id;
        
      }
    );
   
  }


  


  onOptionChange(event:any): void { 
    this._supplierProfile.statusMappingId=event.value;
    }

    prepareBlankSupplierInfoObject=new MasterSupplierProfile();
    prepareBlankSupplierContactInfoObject=new SupplierContact();
   
 
    changedSupplierBasicInfo:any;
    changedSupplierContactInfo:any;
    changedSupplierMSAInfo:any;
    listChangedSponsorBasicContactInfo:any;
    auditModelForSponser=new AuditLogModel();
    auditModelForSponserContact=new AuditLogModel();

    compareModels() 
    {
      var operationName="";
      if(this.supplierId==0){
        operationName=this.auditOperations.Create;
      }
      else{
        operationName=this.auditOperations.Update;
      }
      this.changedSupplierBasicInfo = this.auditLogService.compareModels(JSON.parse(this.oldSupplierData),this._supplierProfile,this.auditEntities.SupplierProfile,this.auditIdentifierDetails.SupplierProfile,operationName, this.auditModule.SupplierService);
      
      this._supplierContacts.forEach((item:any) => {
        if(item.id != 0 && !(item.isDeleted))//Edit case
        {
          item.createdBy=this._supplierProfile.updatedBy;
            var oldContactInfo=JSON.parse(this.oldSupplierContact).filter((aa:any)=>aa.id==item.id);
            this.changedSupplierContactInfo=this.auditLogService.compareModels(oldContactInfo[0],item,this.auditEntities.SupplierContacts,this.auditIdentifierDetails.SupplierContacts,operationName,this.auditModule.SupplierService);
        }
        else if(item.id != 0 && item.isDeleted)//DeleteContact Case
        {
          this.changedSupplierContactInfo=this.auditLogService.compareModels(item,JSON.parse(this.oldSupplierContact),this.auditEntities.SupplierContacts,this.auditIdentifierDetails.SupplierContacts,operationName,this.auditModule.SupplierService);
        }
        else//new contact
        {
          item.createdBy=this._supplierProfile.createdBy;
          this.changedSupplierContactInfo=this.auditLogService.compareModels(JSON.parse(this.oldSupplierContact),item,this.auditEntities.SupplierContacts,this.auditIdentifierDetails.SupplierContacts,operationName,this.auditModule.SupplierService);
        }
      });

this.oldSupplierContact = JSON.parse(this.oldSupplierContact);

if (this._supplierContacts.length !== this.oldSupplierContact.length) {
    const deletedContacts = this.oldSupplierContact.filter((oldContact: { id: number; }) => 
        !this._supplierContacts.some(newContact => newContact.id === oldContact.id)
    );

    if (deletedContacts.length > 0) {
      const deletedContact = deletedContacts.map((item: { createdBy: number; }) => {
        item.createdBy = this._supplierProfile.updatedBy;
        return item;
      });
        this.changedSupplierContactInfo = this.auditLogService.compareModels(
          deletedContact, [],this.auditEntities.SupplierContacts,this.auditIdentifierDetails.SupplierContacts,operationName,this.auditModule.SupplierService
        );
    }
}

     
    } 

}
function item(value: SupplierContact, index: number, array: SupplierContact[]): void {
  throw new Error('Function not implemented.');
}

