<!DOCTYPE html>
<html lang="en">
<head>
   <title> Contract KPI </title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>
<div class="card-pad">

    <div class="fr-flex__container sticky-sub-header">
      <div class="fr-flex__col-8">
         <h3>Add KPI </h3>
      </div>
    </div>
    <div class="scroll-start-here-sub-tabs">  
    <form [formGroup]="myContractKPIForm">
    <div class="fr-flex__container m-t-20">
       <div class="fr-flex__col-4">
          <div class="fr-form__group">
             <label> KPI <em> * </em></label>
             <p-dropdown id="engCreKpiDropdown" appendTo="body" [filter]="true" formControlName="_KPIName" (onChange)="OnChangeKPIName($event)" [options]="kpiNameDataSource" [(ngModel)]="_contractKpi.kpiDetailId" optionLabel="name" optionValue="id" placeholder="Select"></p-dropdown>
             <small class="p-error" *ngIf="submitted && myContractKPIForm?.get('_KPIName')?.hasError('required')">This field is required.</small>
          </div>
       </div>

     
       <div class="fr-flex__col-4">
         <div class="fr-form__group">
            <label> Service <em> * </em><span class="max-length"> {{_contractKpi.serviceName?.length}}/200 </span></label>
            <input class="form-control" formControlName="_serviceName" type="text" [maxlength]="200" pInputText [(ngModel)]="_contractKpi.serviceName" (ngModelChange)="OnServiceChange()" trimSpace/>
            <small class="p-error" *ngIf="submitted && myContractKPIForm?.get('_serviceName')?.hasError('required')">This field is required.</small>
            <small class="p-error" *ngIf="submitted && myContractKPIForm?.get('_serviceName')?.hasError('maxlength')">Max length is 200 characters.</small>
            <small class="p-error" *ngIf="myContractKPIForm.get('_serviceName')?.hasError('onlySpaces')">Only spaces not allowed.</small>
            <small class="p-error" *ngIf="myContractKPIForm.get('_serviceName')?.hasError('pattern')">Invalid input.'&' and '%' are not allowed.</small>
         </div>

      </div>


      <div class="fr-flex__col-4">
         <div class="fr-form__group">
            <label> KPI Name<em> * </em><span class="max-length"> {{_contractKpi.kpiName?.length}}/400 </span></label>
            <input class="form-control" formControlName="_kpiName" type="text" [maxlength]="400" pInputText [(ngModel)]="_contractKpi.kpiName" trimSpace />
            <small class="p-error" *ngIf="submitted && myContractKPIForm?.get('_kpiName')?.hasError('required')">This field is required.</small>
            <small class="p-error" *ngIf="submitted && myContractKPIForm?.get('_kpiName')?.hasError('minlength')"> Minimum length is 3 characters. </small>
            <small class="p-error" *ngIf="submitted && myContractKPIForm?.get('_kpiName')?.hasError('maxlength')">Max length is 400 characters.</small>
            <small class="p-error" *ngIf="myContractKPIForm.get('_kpiName')?.hasError('onlySpaces')">Only spaces not allowed.</small>
            <small class="p-error" *ngIf="myContractKPIForm.get('_kpiName')?.hasError('pattern')">Invalid input.'&' and '%' are not allowed.</small>
            <label class="suggestion_label"> Suggestion : <span> {{ suggestedKPIName}} </span> </label>
         </div>

      </div>




       <div class="fr-flex__col-4">
         <div class="fr-form__group">
            <label> Data Collection Frequency  <em> * </em></label>
            <p-dropdown id="engCreDataCollectionFrequencyDropdown" appendTo="body" [filter]="true" formControlName="_kpiDataCollectionFrequency" (onChange)="OnChangeDataCollection($event)" [options]="kpiDataCollectionFrequencyDataSource" [(ngModel)]="_contractKpi.kpiDataCollectionFrequencyId" optionLabel="name" optionValue="id" placeholder="Select"></p-dropdown>
            <small class="p-error" *ngIf="submitted && myContractKPIForm?.get('_kpiDataCollectionFrequency')?.hasError('required')">This field is required.</small>
         </div>
      </div>
       <div class="fr-flex__col-4">
          <div class="fr-form__group">
             <label> KPI Reporting Frequency <em> * </em> <i  pInputText pTooltip="Reporting Frequency can only be selected after Data Collection Frequency is selected." tooltipPosition="top" class="pi pi-info-circle"> </i> </label>
             <p-dropdown id="engCreKpiReportingFrequencyDropdown" appendTo="body" [filter]="true" formControlName="_kpiReportingFrequency" [options]="kpiReportingFrequencyDataSource" [(ngModel)]="_contractKpi.kpiReportingFrequencyId" optionLabel="name" optionValue="id" placeholder="Select"></p-dropdown>
             <small class="p-error" *ngIf="submitted && myContractKPIForm?.get('_kpiReportingFrequency')?.hasError('required')">This field is required.</small>
          </div>
       </div>
       
    </div>
    <div class="fr-flex__container">
      <div class="fr-flex__col-4 custom-pad-zero">
         <div class="display-flex">
            <div class="fr-flex__col-6 custom-pad-right-zero">
               <div class="fr-form__group">
                 <label>KPI Tracking  Start<em> * </em></label>
                 <p-dropdown id="engCreKpiTrackingStartDropdown" appendTo="body" formControlName="_frequencyValueforStartDate" [options]="frequencyData" optionLabel="name" optionValue="order" [(ngModel)]="_contractKpi.frequencyValueforStartDate" placeholder="Select" [disabled]="isFrequencyAnnual"></p-dropdown>
                 <small class="p-error" *ngIf="submitted && myContractKPIForm?.get('_frequencyValueforStartDate')?.hasError('required')">This field is required.</small>
               </div>
            </div>
            <div class="fr-flex__col-6"> 
               <div class="fr-form__group">
                  <label> &nbsp; </label>
                  <input type="text" formControlName="_kpiStartDateYear" [minlength]="4" [maxlength]="4" class="form-control" pInputText [(ngModel)]="_contractKpi.kpiStartDateYear" placeholder="YYYY" (input)="onNumericInput($event,'_kpiStartDateYear')" trimSpace/>
                  
                  <small class="p-error" *ngIf="submitted && myContractKPIForm?.get('_kpiStartDateYear')?.hasError('required')">This field is required.</small>
                  <small class="p-error" *ngIf="submitted && myContractKPIForm?.get('_kpiStartDateYear')?.hasError('minlength')"> Min lenght is 4 characters.</small>
               </div>
            </div>
         </div>
      </div>
      <div class="fr-flex__col-4 custom-pad-zero">
         <div class="display-flex">
            <div class="fr-flex__col-6 custom-pad-right-zero">
               <div class="fr-form__group">
                 <label>KPI Tracking  End<em> * </em></label>
                 <p-dropdown id="engCreKpiTrackingDropdown" appendTo="body" formControlName="_frequencyValueforEndDate" [options]="frequencyData" optionLabel="name" optionValue="order" [(ngModel)]="_contractKpi.frequencyValueforEndDate" placeholder="Select" [disabled]="isFrequencyAnnual"></p-dropdown>
                 <small class="p-error" *ngIf="submitted && myContractKPIForm?.get('_frequencyValueforEndDate')?.hasError('required')">This field is required.</small>
                 </div>
            </div>
            <div class="fr-flex__col-6">
               <div class="fr-form__group">
                  <label> &nbsp; </label>
                  <input type="text" formControlName="_kpiEndDateYear" [minlength]="4" [maxlength]="4" class="form-control" pInputText [(ngModel)]="_contractKpi.kpiEndDateYear" placeholder="YYYY" (input)="onNumericInput($event,'_kpiEndDateYear')" trimSpace/>
                 
                  <small class="p-error" *ngIf="submitted && myContractKPIForm?.get('_kpiEndDateYear')?.hasError('required')">This field is required.</small>
                  <small class="p-error" *ngIf="submitted && myContractKPIForm?.get('_kpiEndDateYear')?.hasError('minlength')"> Min lenght is 4 characters.</small>
               </div>
               </div>
            </div>
      </div>
      <div class="fr-flex__col-4 custom-pad-zero">
         <div class="display-flex">
            <div class="fr-flex__col-12">
               
               <div class="fr-form__group">
                  <label> Status <em> * </em></label>
                  <p-dropdown id="engCreStatusDropdown" appendTo="body" [filter]="true" formControlName="_status" [options]="statusDataSource" [(ngModel)]="_contractKpi.statusMappingId" optionLabel="name" optionValue="id" placeholder="Select"></p-dropdown>
                  <small class="p-error" *ngIf="submitted && myContractKPIForm?.get('_status')?.hasError('required')">This field is required.</small>
               </div>
            
            </div>
         </div>
      </div>
    </div>
    


    <div class="fr-flex__container">
       <div class="fr-flex__col-4">
          <div class="fr-form__group">
             <label> Unit of Measure </label>
             <input [readonly]="true" type="text" formControlName="_unitOfMeasure" class="form-control read-only" pInputText [(ngModel)]="_readOnlyUnitofMeasure" trimSpace/>
          </div>
       </div>
       
      
    </div>

    <div class="fr-flex__container">
      <div class="fr-flex__col-4">
         <div class="fr-form__group">
            <label> Hyper Care Target <em *ngIf="!_isNotApplicable"> * </em> <button type="button" class="addcondition" (click)="addRow(1)" [disabled]="(_dynamicKpiHyperCareThresholds.length==0 &&_isNotApplicable==false)?false:true"> Add Condition  </button></label>
            <div class="display-flex"> 
               <p-dropdown id="engCreHyperCareTargetDropdown" appendTo="body" [readonly]="_isNotApplicable" [class.hypercare-read-only]="_isNotApplicable" class="fr-flex__col-4 custom-pad-left-zero custom-pad-right-zero" [filter]="true" formControlName="_hyperCareThreshold" [options]="hyperCareThresholdDataSource" [(ngModel)]="_kpiHyperCareThreshold.mathematicalSymbolId" optionLabel="name" optionValue="id" placeholder="Select"></p-dropdown>
               <!-- <input type="text" [readonly]="_isNotApplicable" [class.read-only]="_isNotApplicable" formControlName="_hyperCareThresholdValue" [maxlength]="3" class="form-control fr-flex__col-4 m-l-10" pInputText [(ngModel)]="_kpiHyperCareThreshold.percentageValue" (input)="onNumericInput_ForTargetValue($event,'_hyperCareThresholdValue')" trimSpace/> -->
               <input type="text" [readonly]="_isNotApplicable" [class.read-only]="_isNotApplicable" formControlName="_hyperCareThresholdValue" class="form-control fr-flex__col-4 m-l-10" pInputText [(ngModel)]="_kpiHyperCareThreshold.percentageValue" (input)="onNumericInput_ForTargetValue($event,'_hyperCareThresholdValue')" trimSpace/>
               
               <label> <span class="max-length kpi_length"> {{_kpiHyperCareThreshold.percentageValue?.length}}/3 </span></label>
               <input type="text" [readonly]="true" formControlName="_hyperCareThresholdsymbol" class="form-control read-only fr-flex__col-4 m-l-10" pInputText [(ngModel)]="_readOnlySybmolHyperCareThreshold" trimSpace/>  
            </div>
               <small class="p-error" *ngIf="submitted && !_isNotApplicable && myContractKPIForm?.get('_hyperCareThreshold')?.hasError('required')">This field is required.</small>
               <small class="p-error" *ngIf="submitted && _kpiHyperCareThreshold.mathematicalSymbolId==0 && !_isNotApplicable">This field is required.</small>
               <!-- <small class="p-error" *ngIf="submitted && myContractKPIForm?.get('_hyperCareThresholdValue')?.hasError('pattern')">Enter only numeric values.</small> -->
               <small class="p-error" *ngIf="submitted && !_isNotApplicable && myContractKPIForm?.get('_hyperCareThresholdValue')?.hasError('required')"> This field is required.</small>
              <div *ngFor="let field of _dynamicKpiHyperCareThresholds; let i = index" class="display-flex m-t-10">
               <p-dropdown appendTo="body" class="fr-flex__col-4 custom-pad-left-zero custom-pad-right-zero" [filter]="true" formControlName="_dyhyperCareThreshold" [options]="hyperCareThresholdDataSource" [(ngModel)]="_dynamicKpiHyperCareThreshold.mathematicalSymbolId" optionLabel="name" optionValue="id" placeholder="Select"></p-dropdown>
               <input type="text" formControlName="_dyhyperCareThresholdValue" class="form-control fr-flex__col-3 m-l-10" pInputText [(ngModel)]="_dynamicKpiHyperCareThreshold.percentageValue" (input)="onNumericInput_ForTargetValue($event,'_dyhyperCareThresholdValue')" trimSpace/>
               <input type="text" [readonly]="true" formControlName="_dyhyperCareThresholdsymbol" class="form-control read-only fr-flex__col-4 m-l-10" pInputText [(ngModel)]="_readOnlySybmolHyperCareThreshold" trimSpace/>
               <button pButton type="button" icon="pi pi-trash" (click)="removeRow(i,1)" class="p-button-danger" class="form-control"></button>
             </div>
             <div class="display-flex m-t-10">
               <p-checkbox  [binary]="true" inputId="binary" (onChange)="chkChange($event)"></p-checkbox>
               <label for="binary" class="label__alignment">Not Applicable</label>
             </div>
         </div>
      </div>

      <div class="fr-flex__col-4">
         <div class="fr-form__group">
            <label> Hyper Care Start Date <em *ngIf="!_isNotApplicable"> * </em></label>
            
            <p-calendar id="engCreHyperCareStartDateCalendar" appendTo="body" [disabled]="_isNotApplicable" [class.hypercare-read-only]="_isNotApplicable" formControlName="_hyperCareStartDate" [dateFormat]=_dateformart.CalenderDateFormat  [(ngModel)]="_contractKpi.hyperCareStartDate" [showIcon]="true" (ngModelChange)="onHyperStartDateSelection(_contractKpi.hyperCareStartDate,1)"></p-calendar>
           <small class="p-error" *ngIf="submitted &&  !_isNotApplicable && myContractKPIForm?.get('_hyperCareStartDate')?.hasError('required')">This field is required.</small> 
         </div>
      </div>

      <div class="fr-flex__col-4">
         <div class="fr-form__group">
            <label> Hyper Care End Date <em *ngIf="!_isNotApplicable"> * </em></label>
            <p-calendar id="engCreHyperCareEndDateCalendar" appendTo="body" class="form-control" [disabled]="_isNotApplicable" [class.hypercare-read-only]="_isNotApplicable"  formControlName="_hyperCareEndDate"  [minDate]="hyperCareMinEndDate"  [maxDate]="hyperCareMaxEndDate" [dateFormat]=_dateformart.CalenderDateFormat  [(ngModel)]="_contractKpi.hyperCareEndDate"  (ngModelChange)="onEndDateSelection(_contractKpi.hyperCareEndDate,2)" [showIcon]="true" [readonlyInput]="true"></p-calendar>
            <small class="p-error" *ngIf="submitted && !_isNotApplicable && myContractKPIForm?.get('_hyperCareEndDate')?.hasError('required')">This field is required.</small>
           </div>
      </div>

    </div>

    <div class="fr-flex__container">
       
       <div class="fr-flex__col-4">
          <div class="fr-form__group">
             <label> Steady State Target<em> *</em>  <button type="button" class="addcondition" (click)="addRow(2)" [disabled]="_dynamicKpiSteadyStateThresholds.length==0?false:true">  Add Condition  </button></label>
             <div class="display-flex"> 
               <p-dropdown id="engCreSteadyStatetargetDropdown" appendTo="body" class="fr-flex__col-4 custom-pad-left-zero custom-pad-right-zero" [filter]="true" formControlName="_steadyStateThreshold" [options]="hyperCareThresholdDataSource" [(ngModel)]="_kpiSteadyStateThreshold.mathematicalSymbolId" optionLabel="name" optionValue="id" placeholder="Select"></p-dropdown>
                <input type="text" formControlName="_steadyStateThresoldValue"  class="form-control fr-flex__col-4 m-l-10" pInputText [(ngModel)]="_kpiSteadyStateThreshold.percentageValue" (input)="onNumericInput_ForTargetValue($event,'_steadyStateThresoldValue')" trimSpace />
                <label> <span class="max-length kpi_length"> {{_kpiSteadyStateThreshold.percentageValue?.length}}/3 </span> </label>
                <input type="text" [readonly]="true" formControlName="_steadyStateThresoldSymbol"  class="form-control read-only fr-flex__col-4 m-l-10" pInputText [(ngModel)]="_readOnlySybmolSteadyStateThreshold" trimSpace /> 
             </div>
             <small class="p-error" *ngIf="submitted && myContractKPIForm?.get('_steadyStateThreshold')?.hasError('required')">This field is required.</small>
              <!-- <small class="p-error m-l-10" *ngIf="submitted && myContractKPIForm?.get('_steadyStateThresoldValue')?.hasError('pattern')">Enter only numeric values.</small> -->
              <small class="p-error" *ngIf="submitted && myContractKPIForm?.get('_steadyStateThresoldValue')?.hasError('required')"> This field is required.</small>
             <div *ngFor="let field of _dynamicKpiSteadyStateThresholds; let i = index" class="display-flex m-t-10">
               <p-dropdown appendTo="body" class="fr-flex__col-4 custom-pad-left-zero custom-pad-right-zero" [filter]="true" formControlName="_dysteadyStateThreshold" [options]="hyperCareThresholdDataSource" [(ngModel)]="_dynamicKpiSteadyStateThreshold.mathematicalSymbolId" optionLabel="name" optionValue="id" placeholder="Select"></p-dropdown>
               <input type="text" formControlName="_dysteadyStateThresoldValue" class="form-control fr-flex__col-3 m-l-10" pInputText [(ngModel)]="_dynamicKpiSteadyStateThreshold.percentageValue" (input)="onNumericInput_ForTargetValue($event,'_dysteadyStateThresoldValue')" trimSpace />
               <input type="text" [readonly]="true" formControlName="_dysteadyStateThresoldSymbol" class="form-control read-only fr-flex__col-4 m-l-10" pInputText [(ngModel)]="_readOnlySybmolSteadyStateThreshold" trimSpace />
               <button pButton type="button"  icon="pi pi-trash" (click)="removeRow(i,2)" class="p-button-danger" class="form-control"></button>
             </div>

          </div>
       </div>
    </div>
    
    <div class="fr-flex__container">
       <div class="fr-flex__col-4">
          <div class="fr-form__group">
             <label> Category  </label>
             <input formControlName="_category" [readonly]="true" type="text" class="form-control read-only" pInputText [(ngModel)]="_readOnlyCategory" trimSpace/>
          </div>
       </div>
       <div class="fr-flex__col-4">
          <div class="fr-form__group">
             <label> Compliance Indicator  </label>
             <input formControlName="_complianceIndicator" [readonly]="true" type="text" class="form-control read-only" pInputText [(ngModel)]="_readOnlyComplianceIndicator" trimSpace />
          </div>
       </div>
       <div class="fr-flex__col-4">
          <div class="fr-form__group">
             <label> Calculation​  </label>
             <input formControlName="_calculation" type="text" [readonly]="true" class="form-control read-only" pInputText [(ngModel)]="_readOnlyCalculation" trimSpace />
          </div>
       </div>
    </div>


    

    <div class="fr-flex__container">
      <div class="fr-flex__col-4">
         <div class="fr-form__group">
            <label> Attribute 1 <em>*</em> </label>
            <input formControlName="_attribute1" [readonly]="isAttribute1NotApplicable" type="text" class="form-control" pInputText [(ngModel)]="_readOnlyAttribute1" [ngClass]="{'attribute-based__condition': isAttribute1NotApplicable}" (blur)="getCalculationFormulae();" trimSpace/>
            <small class="p-error" *ngIf="submitted && myContractKPIForm?.get('_attribute1')?.hasError('required')">This field is required.</small>
              <small class="p-error" *ngIf="submitted && myContractKPIForm?.get('_attribute1')?.hasError('minlength')">Min length is 3 characters.</small>
              
         </div>
      </div>
      <div class="fr-flex__col-4">
         <div class="fr-form__group">
            <label> Attribute 2  <em>*</em></label>
            <input formControlName="_attribute2" [readonly]="isAttribute2NotApplicable" [ngClass]="{'attribute-based__condition': isAttribute2NotApplicable}" type="text" class="form-control" pInputText [(ngModel)]="_readOnlyAttribute2" (blur)="getCalculationFormulae();" trimSpace/>
            <small class="p-error" *ngIf="submitted && myContractKPIForm?.get('_attribute2')?.hasError('required')">This field is required.</small>
              <small class="p-error" *ngIf="submitted && myContractKPIForm?.get('_attribute2')?.hasError('minlength')">Min length is 3 characters.</small>

         </div>
      </div>
      <div class="fr-flex__col-4">
         <div class="fr-form__group">
            <label> Attribute 3  </label>
            <input formControlName="_attribute3" [readonly]="isAttribute3NotApplicable" type="text" [readonly]="true" class="form-control read-only" InputText [(ngModel)]="_readOnlyAttribute3" (blur)="getCalculationFormulae();" trimSpace/>
         </div>
      </div>
   </div>






    <div class="fr-flex__container">
      <div class="fr-flex__col-12">
         <div class="fr-form__group">
            <label> KPI Description <span class="max-length"> {{_contractKpi.description?.length}}/300 </span></label>
            <textarea formControlName="_kpiDescription" [maxlength]="300" class="form-control" InputTextarea  [(ngModel)]="_contractKpi.description" trimSpace></textarea>
         </div>
      </div>
   </div>
</form>
</div>
    <!-- Save & Cancel Footer buttons Start -->
    <div class="fr-flex__container footer_btns">
       <div class="fr-flex__col-12">
          <a  (click)="isSaveBtnDisable ? null : onSaveContractKpi()" type="button" [class.disabled]="isSaveBtnDisable" class="btn btn-primary btn-save pull-right"> Save </a>
          <a type="button" (click)="onCancelContractKpi()"  class="btn btn-secondary pull-right"> Cancel </a>
       </div>
    </div>
    <!-- Save & Cancel Footer buttons  End -->
 </div>
</body>
</html>