export class InvoiceDetails
{
    Id?:number;
    InvoiceNumber?: string;
    InvoiceValue?:number;
    DraftInvoiceSentDate?:any;
    DraftInvoiceApprovedDate?: any;
    InvoiceRaisedDate?:any;
    DueDate?:any;
    DaysToDueDate?:any;
    PaymentAmount?:any;
    PaymentDate?:any;
    Remarks?:string;
    InvoiceDescription?:string;
    StatusMappingId?:number;
    Status?:string="";
    ContractDetailId?:number;
    createdBy?: number=0;
    createdDate?: Date;
    updatedBy?: number=0;
    updatedDate?: Date;
    isDeleted?: Boolean;
    ContractInvoiceSentDate?:Date;
    ContractInvoiceApprovedDate?:Date;
    ContractInvoiceRaisedDate?:Date;
    ContractInvoiceDueDate?:Date;
    ContractInvoicePaymentDate?:Date;
    FinanceInvoiceAttachments?:FinanceInvoiceAttachments[];
}
export class FinanceInvoiceAttachments{
Id?:number;
FileName?:string="";
FilePath?:string="";
FinanceContractInvoiceId?:number;
createdBy?:number=0;
createdDate?: Date;
updatedBy?: number=0;
updatedDate?: Date;
isDeleted?: Boolean;
}
export class GetInvoiceFilter{
    StartDate:any;
    EndDate:any;
    Status:any;
}
export class InvoiceFilterDetails
{
 
  InvoiceNumber?: any;
  InvoiceValue?: any;
  StartDate?:any;
  EndDate?:any;
  InvoiceDueDate?:any
  Status?: any;
}

export class InvoiceEmailPayload
{
 
  InvoiceNumber?: any;
  InvoiceValue?: any;
  InvoiceDueDate?:any
  PreviousStatus?: any;
  CurrentStatus?: any;
  StartDate?:any;
  EndDate?:any;
  EngagementName?:any;
  InvoicedAmount?:any;
  BudgetBalanceFY?:any;
  CurrentFiscalYear?:any;
  createdBy?:any;
}
 