export class SupplierProfile {
  id: number=0;
  name?: string="";
  supplierId: string="";
  website: string="";
  headquartersAddress: string="";
  notesAndComments: string="";
  sponserId?: number;
  createdBy: number=0;
  createdDate?: Date;
  updatedBy: number=0;
  updatedDate?: Date;
  isDeleted: boolean=false;
  engagementStartDate?: any;
  supplierServices?: number[];
  supplierLocations?: number[];
  supplierContacts?: SupplierContact[];
  suppliersMasterServiceAgreements?: SuppliersMasterServiceAgreement[];
  StatusMappingId?:number[];
}
export class SupplierContact {
  id: number=0;
  contactName?: string="";
  designation?: string="";
  countryCode?: string="";
  phoneNumber?: string="";
  email?: string="";
  supplierProfileId: number=0;
  isSendNotification?:boolean=false;
}

export class SuppliersMasterServiceAgreement {
  id: number=0;
  title?: string="";
  startDate?: any;
  endDate?: any;
  fileName?: string="";
  filePath?: string="";
  description: string="";
  supplierProfileId: number=0;
  sponsorContactId?: number;
  supplierContactId: number=0;
  paymentTermId?: number;
  functionId?: number;
  statusMappingId: number=0;
  paymentTerm?:string;
  msaStatus?:string;
  fileNameUI?: string;
  createdBy: number=0;
  createdDate?: Date;
  updatedBy: number=0;
  updatedDate?: Date;
  isDeleted: boolean=false;
  supplierContactEmail: string="";
  supplierContactName:string="";
  isPrevFileDelete:boolean=false;
  uploadedMSAFile:any;

}

export class CodeListValues {
  name: string="";
  id: number=0;
  code:string="";
  sub_code="";
  type:number=0;
}

export class SponsorContact {
  id?:number;
  name?:string;
  designation?:string;
  countryCode?:string;
  phoneNumber?:string;
  email?:string;
  sponserId?:number;
  }

export class PaymentTermsDropdownBinding
{
  name?: string;
  label?:string;
  id: number=0;;
}


export class KpiChildComponents
{
  supKpiList:number=1;
  supKpiAdd:number=2;
  supKpiEdit:number=3;
  supKpiView:number=4;
}


export class AuditChildComponents
{
  supAuditList:number=1;
  supAuditAdd:number=2;
  supAuditEdit:number=3;
  supAuditView:number=4;
}

export class MeetingChildComponents
{
  supMeetingList:number=1;
  supMeetingAdd:number=2;
  supMeetingEdit:number=3;
  supMeetingView:number=4;
  supMeetingEditRecYes:number=5;
}