import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder,} from '@angular/forms'
import * as FileSaver from 'file-saver';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/Loader/loader.service';
import { ActiveInActiveStatus, StatusCodes, Dateformat, breadcrumnsCodes, FileExtension } from 'src/app/shared/constants/global.constants';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { MenuItem } from 'primeng/api';
import { ContractService } from 'src/app/Contracts/Services/contract.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { Privileges } from 'src/app/shared/constants/privileges.constants';
import { MasterSupplierService } from '../../Services/master.supplier.service'
import { GetSupplierFilter } from '../../Models/master-supplier-profile';
import { ExcelService } from 'src/app/Reusable/excel.service';
@Component({
  selector: 'app-master-suppliers-list',
  templateUrl: './master-suppliers-list.component.html',
  styleUrls: ['./master-suppliers-list.component.css']
})
export class MasterSuppliersListComponent {
  constructor(private privilegeService: PrivilegeService,private fb: FormBuilder, private _http: HttpClient,
     private SupplierSericeApi: MasterSupplierService, private router: Router, private loaderService: LoaderService, private datePipe: DatePipe, private breadcrumbService: BreadcrumbService, private _contractService: ContractService,private excelService: ExcelService,) {


  }

  public GetSuppliersWithSelectedFilters = new GetSupplierFilter();
  supplierdata: any[] = [];
  selectedRecordsToExpotToExcel: any[] = [];
  selectedSuppliers: any;
  noofContracts: any[] = [];
  services: any[] = [];
  selectednoofContracts: any;
  SerivicesName: any[] = [];
  SelectedServices: any;
  FilterValues = new GetSupplierFilter();
  selectedSuplierStatus: any;
  RelationshipStartDate: any;
  SelectedRelationshipStartDate: any;
  enterSupplierIdOrName: any;
  showHiddenControls: boolean = false;
  isChecked: boolean = true;
  selectedDate: any;
  showClear: boolean = true;
  _dateformart: any;
  breadcrumbs: MenuItem[] = [];
  _enumBreadcrumb: any;
  status: any[] = [];
  supplierStaus: any[] = [];
  unfilteredSuppliersData: any[] = [];
  allRecords: any;
  contractList: any[] = [];
  activStatus: boolean | null = null;
  inactiveStatus: boolean | null = null;
  allstatus: any;
  startDate: any;
  endDate: any;
  selectedContract: any;
  filteredSupplierData: any[] = [];
  selectedContractCountOption: number = 0;
  buttonLabel: any;
  ShowServicesButton: boolean = true;
  activeRecords: any;
  showHideFiltersControls: boolean = false;
  servicesToArray: any;

  contractOptions: any[] = [
    { label: 'All', value: 0 },
    { label: '<=10', value: 10 },
    { label: '<=20', value: 20 },
    { label: '<=30', value: 30 },
    { label: '<=40', value: 40 },
    { label: '<=50', value: 50 },
    { label: '<=60', value: 60 },
    { label: '<=70', value: 70 },
    { label: '<=80', value: 80 },
    { label: '<=90', value: 90 },
    { label: '<=100', value: 100 },
  ];
  createPrivilege:boolean=false;
  editPrivilege:boolean=false;
  viewPrivilege:boolean=false;
  ExportPrivilege:boolean=false;

  supplierIds :any;

  _groupTypeCode:any
  ngOnInit() {
    var userData=this.privilegeService.getUserData();
    this.supplierIds=userData.suppliers;
    this._groupTypeCode=userData.groupTypeCode;
    this._enumBreadcrumb = breadcrumnsCodes;
    this.generateBreadcrumb();
    this._dateformart = Dateformat;
    this.loaderService.showLoader();
    if(this.supplierIds!=null && this.supplierIds.length>0)
    {
    this.GetSuppliersByGroups(this.supplierIds);
    }
    else{
      if(this._groupTypeCode!="DFT")
      this.GetSuppliersByGroups([]);
    }
    this.loaderService.hidefinalLoader();
    this.BindPrivileges();
  }


GetSuppliersByGroups(supplierIds:number[]){
      this.SupplierSericeApi.GetSuppliersByGroups(this._groupTypeCode,supplierIds)
      .subscribe(res => {
        this.loaderService.hideLoader();
        this.allRecords = res.data;
        this.supplierdata = res.data;
        this.unfilteredSuppliersData = this.supplierdata;
        this.supplierdata = this.supplierdata.filter((obj) => {
          if (!obj.isDeleted) {
            return obj.isDeleted = ActiveInActiveStatus.Active;
          }
          else {
            return obj.isDeleted = ActiveInActiveStatus.InActive;
          }
        });
        this.supplierdata = this.supplierdata.filter((supplier) => supplier.isDeleted === ActiveInActiveStatus.Active);
        this.activeRecords = this.supplierdata;
      });
   this.GetServiceData();
  }
  BindPrivileges(){
    const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);
     this.createPrivilege = hasPermission(Privileges.CreateSupplier);
this.editPrivilege = hasPermission(Privileges.EditSupplier);
this.viewPrivilege = hasPermission(Privileges.ViewSupplier);
this.ExportPrivilege =hasPermission(Privileges.ExportlistofSuppliers);
    }


  GetStatus() {
    this.SupplierSericeApi.GetStatus()
      .subscribe(res => {
        this.status = res.data;
        var allowStatusCodes = [StatusCodes.All, StatusCodes.Active, StatusCodes.InActive];
        this.status.forEach(obj => {
          if (allowStatusCodes.includes(obj.code)) {
            var status = { label: obj.name, value: obj.code };
            var existingStatus = this.supplierStaus.find(item => item.value === status.value);
            if (!existingStatus) {
              this.supplierStaus.push(status);
            }
          }
        });
      })
  }

  GetContractList() {
    this._contractService.GetAllContracts(0,this.supplierIds).subscribe(
      res => {
        this.contractList = res.data;
        var i = 0;
        this.supplierdata.forEach((supplier) => {
          const filteredContracts = this.contractList.filter((contract: any) => contract.supplierId === supplier.id);
          this.supplierdata[i].Count = filteredContracts.length;
          i++;
        });
      });
      const data=this.supplierdata;
      this.supplierdata=data;
  }

  viewContract(a: any) {

    this.router.navigate(['/contracts'], { queryParams: { supplierId: parseInt(a.id) } });
  }

  onRowEditInit(item: any) {
    this.router.navigate(['/EditMasterSupplierData', item.id]);

   

  }

  //get all suppliers data
GetAllSuppliers() {
  this.SupplierSericeApi.GetSuppliers().subscribe(
    (    res: { data: any[]; })=>{
      this.loaderService.hideLoader();
        this.allRecords = res.data;
        this.supplierdata = res.data;
        this.unfilteredSuppliersData = this.supplierdata;
        this.supplierdata = this.supplierdata.filter((obj) => {
          if (!obj.isDeleted) {
            return obj.isDeleted = ActiveInActiveStatus.Active;
          }
          else {
            return obj.isDeleted = ActiveInActiveStatus.InActive;
          }
        });
        this.supplierdata = this.supplierdata.filter((supplier) => supplier.isDeleted === ActiveInActiveStatus.Active);
        this.activeRecords = this.supplierdata;
    } 
 );
};

  exportExcel(SelectedRecords: any) {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(SelectedRecords);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'Suppliers');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = FileExtension.ExportExtention;
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  ExportSuppliers() {
    this.selectedRecordsToExpotToExcel = this.selectedSuppliers;
    this.selectedRecordsToExpotToExcel = this.selectedRecordsToExpotToExcel.map((supplier) => {
      
      return {
      
        SupplierName: supplier.name,
        SupplierID: supplier.supplierId,
        //NoofContracts:supplier.Count,
        Services: supplier.supplierServices,
        Locations: supplier.supplierLocations,
        Website: supplier.website,
        HeadquartersAddress: supplier.headquartersAddress,
        NotesAndComments: supplier.notesAndComments,
        'Added By': this.privilegeService.getUserName(supplier.createdBy) ,
        // CreatedDate: supplier.createdDate != null ? this.convertDateFormat(supplier.createdDate) : supplier.createdDate, 
        'Added Date & Time': supplier.createdDate != null ? this.excelService.convertDateTimeFormat(supplier.createdDate) : supplier.createdDate,
        ModifiedBy: supplier.updatedBy != null ? this.privilegeService.getUserName(supplier.updatedBy):'',
        'Modified Date & Time' : supplier.updatedBy != null ? (supplier.updatedDate != null ? this.excelService.convertDateTimeFormat(supplier.updatedDate) : '') : '',
       // EngagementStartDate: supplier.engagementStartDate != null ? this.convertDateFormat(supplier.engagementStartDate) : supplier.engagementStartDate,
      //  Status: (!supplier.isDeleted?'Active':'InActive')
      };
    });
    this.exportExcel(this.selectedRecordsToExpotToExcel);
  }
  ShowAndHideFilterControls() {
    if (this.showHiddenControls) {
      this.showHiddenControls = false;
    }
    else {
      this.showHiddenControls = true;
    }
  }
  CheckboxEnabled() {
    if (this.selectedSuppliers.length > 0) {
      this.isChecked = false;
    }
    else {
      this.isChecked = true;
    }
  }
  ClearFilters() {
    this.enterSupplierIdOrName = null;
    this.selectednoofContracts = null;
    this.SelectedServices = null;
    this.selectedDate = null;
    this.GetSuppliersWithSelectedFilters.EngageStrtDate = null;
    this.selectedSuplierStatus = null;
    this.isChecked=true;
    this.isfiltered=false;
    this.statusChange=false;
    this.filteredData=this.supplierdata;
    this.selectedContractCountOption=0;
    this.selectedServiceCount=0;
 
 this.GetSuppliersByGroups(this.supplierIds);
    this.selectedSuppliers = []; //clear selected suppliers
  }

  onContractsChange(event: any) {
       this.selectedContractCountOption = event.value.value;
    this.finalFilter();
  }

  OnSelectDate() {
    if (this.selectedDate != null && this.startDate!='') {
      this.startDate = this.selectedDate[0];
      this.endDate = this.selectedDate[1];

      if (this.startDate != null) {
        this.GetSuppliersWithSelectedFilters.EngageStrtDate = new Date(
          Date.UTC(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate())
        );
      } else {
        this.GetSuppliersWithSelectedFilters.EngageEndDate = null;
      }

      if (this.endDate != null) {
        this.GetSuppliersWithSelectedFilters.EngageEndDate = new Date(
          Date.UTC(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate())
        );
      } else {
        this.GetSuppliersWithSelectedFilters.EngageEndDate = null;
      }
    }
    if (this.startDate != null && this.endDate != null && this.startDate != '' && this.endDate != '') {

      if(!this.selectedDate){
        this.GetSuppliersWithSelectedFilters.EngageStrtDate=null;
        this.GetSuppliersWithSelectedFilters.EngageEndDate=null;
        this.startDate=null;
        this.endDate=null;
      }
      this.finalFilter();
    }
  }

  ViewSupplier(item: any) {
    this.router.navigate(['/ViewMasterSupplier', item.id]);
  }
  convertDateFormat(inputDateTime: string) {
    const date = new Date(inputDateTime);
    const formattedDate = this.datePipe.transform(date, this._dateformart.GridDateFormat);
    return formattedDate;
  }
  generateBreadcrumb() {
    this.breadcrumbService.generateBreadcrumb(this._enumBreadcrumb.SLMD);
  }
  getBreadcrumbs(): MenuItem[] {
    return this.breadcrumbService.getBreadcrumbs();
  }
  serviceData: any[] = [];
  GetServiceData() {
    this.SupplierSericeApi.getCodeListData(1, 'Service').subscribe(
      data => {
        this.serviceData = data.data;
        this.serviceData.forEach((obj) => {
          var label = { label: obj.name };
          var exists = this.SerivicesName.some((item) => item.label === label.label);
          if (!exists) {
            this.SerivicesName.push(label);
          }
        });

      }
    );
  }
selectedServiceCount:any='';
  onServiceChange(event: { value: any; }){
    this.selectedServiceCount = event.value;
    const selectedItemCount = this.SelectedServices.length;
    this.finalFilter();
  }

 

  finalFilter(){
    if (this.statusChange){
      if (this.GetSuppliersWithSelectedFilters.selectedSuplierStatus=="All"){
        this.supplierdata=this.allRecords;
      }
      else if(this.GetSuppliersWithSelectedFilters.selectedSuplierStatus=="InActive"){
        this.supplierdata=this.unfilteredSuppliersData;
      }
      else if(this.GetSuppliersWithSelectedFilters.selectedSuplierStatus=="Active"){
        this.supplierdata=this.activeRecords;
      }

    }
    else{
      this.supplierdata=this.activeRecords;
    }
    this.filteredData='';
    this.isfiltered=false;
    if (this.enterSupplierIdOrName != null && this.enterSupplierIdOrName != undefined && this.enterSupplierIdOrName != "") {
      this.isfiltered=true;
      this.supplierdata = this.supplierdata.filter(a => a.name.toLowerCase().indexOf(this.enterSupplierIdOrName?.toLowerCase()) !== -1 || a.supplierId.toLowerCase().indexOf(this.enterSupplierIdOrName?.toLowerCase()) !== -1);
      this.filteredData=this.supplierdata;
    }
    if (this.selectedContractCountOption != undefined && this.selectedContractCountOption != null) {
      
      if (this.selectedContractCountOption === 0) {
        this.filteredSupplierData = this.supplierdata;
      } 
        else{
        this.isfiltered=true;
        this.filteredSupplierData = this.supplierdata.filter(supplier => supplier.Count<= this.selectedContractCountOption);
        this.supplierdata = this.filteredSupplierData;
        this.filteredData= this.filteredSupplierData;
        }
    }
    this.GetSuppliersWithSelectedFilters.Services = this.SelectedServices == undefined ? "" : this.SelectedServices;
    if(this.selectedServiceCount.length>0)
    {
     
    if (this.GetSuppliersWithSelectedFilters.Services != null && this.GetSuppliersWithSelectedFilters.Services != undefined && this.GetSuppliersWithSelectedFilters.Services.length > 0) {
      var selectedServices = this.GetSuppliersWithSelectedFilters.Services.map((labelObject: { label: any; }) => labelObject.label);
      var uniqueSupplierIds = new Set();
      let serviceData: any[] = [];

      this.supplierdata.forEach(supplier => {
        var supplierServicesArray = supplier.supplierServices.split(', ');

        var hasMatchingService = supplierServicesArray.some((service: any) => selectedServices.includes(service));

        if (hasMatchingService && !uniqueSupplierIds.has(supplier.id)) {
          serviceData.push(supplier);
          uniqueSupplierIds.add(supplier.id);
        }
      });
      this.isfiltered=true;
      this.supplierdata = serviceData;
      this.filteredData=this.supplierdata;
    }
    else{
      this.supplierdata=this.activeRecords;
    }
  }
  if (this.GetSuppliersWithSelectedFilters.EngageStrtDate != null && this.GetSuppliersWithSelectedFilters.EngageStrtDate != undefined && this.GetSuppliersWithSelectedFilters.EngageEndDate != null && this.GetSuppliersWithSelectedFilters.EngageEndDate != undefined) {
    if(this.isfiltered){
    this.supplierdata=this.filteredData;
    }
    this.filteredSupplierData = this.supplierdata.filter(a => {
      this.isfiltered=true;
      const engagementDate = new Date(a.engagementStartDate);
      return engagementDate >= this.startDate && engagementDate <= this.endDate;      
    });
    this.supplierdata = this.filteredSupplierData;
    this.filteredData=this.filteredSupplierData;
  }

if (this.statusChange) {
  if (this.GetSuppliersWithSelectedFilters.selectedSuplierStatus) {
    if (this.isfiltered) {
      this.supplierdata = this.filteredData;
    } else {
      this.supplierdata = this.allRecords;
    }

    const statusLabel = this.GetSuppliersWithSelectedFilters.selectedSuplierStatus;
    if (statusLabel === 'All') {
      
    } else {
     
      this.supplierdata = this.supplierdata.filter(supplier => supplier.isDeleted === statusLabel);
    }
  }
}
  }
  

  

  isfiltered:boolean=false;
  filteredData:any;
  statusChange:boolean=false;
  onStatusFilter() {
    this.statusChange = true;
    this.GetSuppliersWithSelectedFilters.selectedSuplierStatus = this.selectedSuplierStatus?.label || "";
    this.finalFilter();
  }
  showHideControls() {
    this.showHideFiltersControls = !this.showHideFiltersControls;
  }
}
