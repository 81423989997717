<!DOCTYPE html>
<html lang="en">
<head>
  <title> Sponsor </title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>
<div class="card flex justify-content-center" >
  <p-breadcrumb class="max-w-full" [model]="getBreadcrumbs()"></p-breadcrumb>
</div>
<div class="container page-title">
  <div class="flex-box">
  <div class="fr-flex__container">
      <div class="fr-flex__col-8">
          <h1>Sponsors <span class="number_of_records"> ({{ filteredRecordCount > -1 ? filteredRecordCount : totalRecordCount }} of {{ totalRecordCount }} Records)  </span></h1>
      </div>
      <div class="fr-flex__col-4"> <a *ngIf="createPrivilege" routerLinkActive="active" routerLink="/createsponsor" class="btn btn-primary pull-right btn-primary-align"> Create Sponsor </a>
         <button *ngIf="ExportPrivilege" class="btn btn-outline-primary pull-right" (click)="exportSponsers()" [disabled]="isChecked"> <i class="pi pi-download"></i> Export</button> 
        <button class="btn btn-outline-primary pull-right" id="ListBtnFilterSponsor" (click)="hiddenControls()" [ngClass]="{'active-button': showHiddenControls}"> <i class="pi pi-filter"></i> </button> </div>
  </div>
</div>
  <div *ngIf="showHiddenControls">
    <div class="card">
            <div class="filter-bg">
      <div class="fr-flex__container">
          <div class="fr-flex__col-3">
              <div class="fr-form__group margin-zero"> <span class="p-input-icon-left"> <i class="pi pi-search"></i> 
                <input type="text" class="form-control key-search filter-text search-active" [class.search-active]="selectedSponserIdorName !== undefined && selectedSponserIdorName !== null && selectedSponserIdorName !== ''" placeholder="Search by Name, ID" [(ngModel)]="selectedSponserIdorName" trimSpace /> </span> </div>
          </div>
          <div class="fr-flex__col-0 custom-pad-left-zero">
          </div> <a class="btn btn-primary" (click)="filterData()">Apply </a> <a class="btn btn-primary-link" (click)="clearFilters()"> Clear All Filters </a>
      </div>
    </div>
</div>
  </div>
  
      
              <p-table class="primeng-table-custom" [resizableColumns]="true" columnResizeMode="expand" styleClass="p-datatable-striped p-datatable-gridlines" [value]="sponsers" [scrollable]="true" [(selection)]="checkedSponsers" [paginator]="true" [rows]="10" [rowsPerPageOptions]="[10,20,50,100,200]" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Entries">
                  <ng-template pTemplate="header">
                      <tr>
                          <th id="ListGridTHCheckboxSponsor" class="first-child-width">
                              <p-tableHeaderCheckbox (click)="CheckboxEnabled()"></p-tableHeaderCheckbox>
                          </th>
                          <th id="MasterDataSponsorName" pSortableColumn="name" pResizableColumn>Sponsor Name <p-sortIcon field="name"></p-sortIcon></th>
                          <th id="MasterDataSponsorID"pSortableColumn="sponserId">Sponsor ID <p-sortIcon field="sponserId"></p-sortIcon></th>
                          
                          <th id="MasterDataSponsorEdit" class="last-child-width" *ngIf="editPrivilege">Edit</th>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-sponsers>
                      <tr>
                          <td id="ListGridTDCheckboxSponsor" class="first-child-width">
                              <p-tableCheckbox (click)="CheckboxEnabled()" [value]="sponsers"></p-tableCheckbox>
                          </td>
                          <td id="ListGridHyperLinkSponsor" [pTooltip]="sponsers.name.length > 30 ? sponsers.name : ''" tooltipPosition="top">
                            <a *ngIf="viewPrivilege; else disabledLink" class="table-active-link" routerLinkActive="active" (click)="viewSponser(sponsers)">{{ sponsers.name}}</a>
                            <ng-template #disabledLink>
                              {{ sponsers.name}}
                            </ng-template>
                          </td>
                          
                         
                          <td> {{ sponsers.sponserId }} </td>
                         
                          <td id="ListGridBtnEditSponsor" *ngIf="editPrivilege" class="grid-icon-center"><span class="pi pi-pencil"  (click)="editSponser(sponsers)"></span></td>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                    <tr>
                      <td [attr.colspan]="editPrivilege ? 4 : 3" class="text-center">No Records</td>
                    </tr>
                    </ng-template>
              </p-table>
      
  
</div>
</body>
</html>