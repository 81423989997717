import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[trimSpace]'
})
export class TrimSpaceDirective {

  constructor(private ngControl: NgControl) { }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: string) {
    this.ngControl?.control?.setValue(this.normalizeString(value));
  }

  private normalizeString(input: string): string {
    // Split the input into lines
    const lines = input.split('\n');
    
    // Normalize each line
    const normalizedLines = lines.map(line => {
      // Trim leading and trailing spaces on each line
      let normalizedLine = line.trim();
      
      // Replace multiple spaces with a single space within the line
      normalizedLine = normalizedLine.replace(/\s+/g, ' ');

      return normalizedLine;
    });

    // Join the normalized lines back together with newline characters
    return normalizedLines.join('\n');
  }
}
