<!DOCTYPE html>
<html lang="en">
<head>
   <title> Edit Forecast </title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>
<div class="card-pad">
   <div class="fr-flex__container sticky-sub-header">
      <div class="fr-flex__col-6">
         <h3>Edit Forecast</h3> 
      </div>
      <div class="fr-flex__col-6 custom-pad-right-zero">  
         <div class="preview_section pull-right">
            <button  [class.previous]="currentIndex !== 0"  (click)="pageChangeConfirmation(2)" [disabled]="currentIndex === 0"><i class="pi pi-arrow-up" pTooltip="Preview" tooltipPosition="top"></i></button>
            <span class="numbers_list">{{getCurrentPositionText()}}</span>
            <button [class.next]="currentIndex !== listOfForecasts.length - 1" (click)="pageChangeConfirmation(1)"  [disabled]="currentIndex === listOfForecasts.length - 1"><i class="pi pi-arrow-down" pTooltip="Next" tooltipPosition="top"></i></button>
            </div> 
         </div>
   </div>
   <div class="scroll-start-here-sub-tabs"> 
<form [formGroup]="forecastForm"> 
   <div class="fr-flex__container m-t-20">
      <div class="fr-flex__col-4">
         <div class="fr-form__group">
            <label> Month  <em> * </em></label>
              <input type="text" class="form-control read-only" pInputText value="{{ forecastDetail.forecastDate | date:'MMMM yyyy' }}" readonly="readonly"/>
         </div>

        
      </div>
      <div class="fr-flex__col-4">
        <div class="fr-form__group">
           <label> Forecast Amount   <em> * </em></label>
           <p-inputNumber formControlName="_forecastedAmount" inputId="minmaxfraction" mode="decimal" [minFractionDigits]="2" class="form-control-inputNumber" type="text" [maxlength]="15" [(ngModel)]="forecastDetail.forecastedAmount"/>
           <small class="p-error" *ngIf="forecastSubmitted && forecastForm?.get('_forecastedAmount')?.hasError('required')">This field is required.</small>
           <small class="p-error" *ngIf="forecastSubmitted && forecastForm?.get('_forecastedAmount')?.hasError('pattern')"> Only numbers allowed and maximum 15 digits which includes 2 decimal places. </small>
           <small class="p-error" *ngIf="forecastSubmitted && forecastForm?.get('_forecastedAmount')?.hasError('maxlength')">   Maximum length is 15 characters. </small>
               
        </div>
     </div>
     <div class="fr-flex__col-4">
      <div class="fr-form__group">
         <label> Attachment  <i  pInputText pTooltip="Only 1 Attachment allowed." tooltipPosition="top" class="pi pi-info-circle"> </i></label>
                    <div *ngIf="this.forecastAttachment.fileName==''"> 
                      <input   type="file"  accept=".pdf, .xls, .xlsx,.doc,.docx"  formControlName="_forecastFile" class="form-control custom-file-input" id="forecastFileAttachment"  (change)="onForecastAttachmentChange($any($event.target).files)" #fileInput style="display:none" >
                     <button type="button" (click)="fileInput.click()" class="form-control width100">Upload Files</button>
                     </div>

                     <div *ngIf="this.forecastAttachment.fileName!=''">
                     <a class="attachment-active" routerLinkActive="active" > 
                        <span [pTooltip]="forecastAttachment.fileName?.length > 40 ? forecastAttachment.fileName : ''" tooltipPosition="top" (click)="downloadFile(forecastAttachment.fileName,forecastAttachment.filePath)">
                           <em> {{ forecastAttachment.fileName }}  </em>  
                        </span> 
                        <i  pInputText pTooltip="Delete" tooltipPosition="top" class="pi pi-trash" (click)="prepareDeleteFileObject()"> </i> 
                        </a>
                     </div>
      </div>
   </div>
   </div>
   <div class="fr-flex__container">
    <div class="fr-flex__col-12">
      <div class="fr-form__group">
         <label> Remarks <span class="max-length"> {{forecastDetail.remarks?.length || 0}}/300 </span> </label>
         <textarea class="form-control" formControlName="_remarks" pInputTextarea [(ngModel)]="forecastDetail.remarks" [maxlength]="300"></textarea>
      </div>
   </div>
   </div>
</form>


<div class="fr-flex__container">

   <div class="fr-flex__col-3">
      <div class="fr-form__view">
         <label> Modified By</label>
         <span  *ngIf="updatedBy !=null"> <em>  {{updatedBy}} </em></span>
         <span *ngIf="updatedBy == null || updatedBy == undefined || updatedBy == ''"> <em> -- </em></span>
      </div>
   </div>
   <div class="fr-flex__col-3" >
      <div class="fr-form__view">
         <label> Modified Date & Time </label>
         <span  *ngIf="updatedBy !=null && updatedBy != undefined && updatedBy != ''"> <em> {{forecastDetail?.updatedDate | date: _dateformart.DateTimeWithZone }} {{_dateformart.DisplayUTC}}</em></span>
          <span *ngIf="updatedBy ==null || updatedBy == undefined || updatedBy == ''"> <em> -- </em></span>
        </div>
   </div>
</div>
</div>


   <!-- Save & Cancel Footer buttons Start -->
   <div class="fr-flex__container footer_btns">
      <div class="fr-flex__col-12">
         <button routerLinkActive="active" type="button" class="btn btn-primary btn-save pull-right" [class.disabled]="isSaveBtnDisable" (click)="isSaveBtnDisable ? null : saveContractForecast()" > Save </button>
         <button type="button" class="btn btn-secondary pull-right" (click)="onCancelContractForecast()" > Cancel </button>
   </div>
</div>

</div>
</body>
</html>
<!-- Save & Cancel Footer buttons  End -->

