import { Component,EventEmitter,Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/Loader/loader.service';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { AuditIdentifier, AuditLogEntities, AuditLogModule, AuditOperations, CodeList, CodeListStatusTypes, Dateformat, FrequencyValues, breadcrumnsCodes } from 'src/app/shared/constants/global.constants';
import { EngagementKpiService } from '../../Services/engagement-kpi.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { Privileges } from 'src/app/shared/constants/privileges.constants';
import { CodeListValues, EngagementKPIHyperCareThreshold, EngagementKPISteadyStateThreshold, EngagementKpi, EngagementKpiDataCollection, KpiFilterDetails, MasterKpiData } from '../../Model/engagementKpi';
import { Table } from 'primeng/table';
import { DatePipe } from '@angular/common';
import { CommonService } from 'src/app/shared/Service/common.service';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';
import { CurrencyService } from 'src/app/Reusable/currency.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { EngagementService } from 'src/app/Engagement/Services/engagement.service';
import { MasterSupplierService } from 'src/app/MasterData/supplier/Services/master.supplier.service';
import { SponsorContact, SupplierContact } from 'src/app/MasterData/Services/supplier-profile';
import { ContractKPIService } from '../../../Contract/Services/ContractKpi.Service';
import { ChecknulloremptyComponent } from 'src/app/shared/checknullorempty/checknullorempty.component';
import { TabAndStateService } from 'src/app/shared/TabAndStateService/TabAndState.Service';
import { NotificationService } from 'src/app/shared/Notifications/notification.service';
import * as xlsx from 'xlsx';
import { AuditLogService } from 'src/app/shared/ModelComparison/audit.log.service';

@Component({
  selector: 'app-eng-kpi-list',
  templateUrl: './eng-kpi-list.component.html',
  styleUrls: ['./eng-kpi-list.component.css']
})
export class EngKpiListComponent {
 
  @Input() engagementId: any;
  @Output() addKpiButtonClick = new EventEmitter<void>();
  @Output() editKpiButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() viewKpiButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('SupplierKpiTable') AssociateTable!: Table;
  selectedSupplierKPI:any;
  statusdata: any[] = [];
  kpidata: any[] = [];
  healthdata: any[] = [];
  _engagementKPIListDatacopy: any = [];
  checkedKpis:any[]=[];
  selectedRecordsToExpotToExcel : any[] = [];
  isChecked:boolean=true;
  filterDetailsModel=new KpiFilterDetails();
  showHideFiltersControls: boolean = false;

  _dateformart :any;
  _enumBreadcrumb :any;
  _engagementKPIListData: any[] = []
  createPrivilege:boolean=false;
  editPrivilege:boolean=false;
  viewPrivilege:boolean=false;
  ExportPrivilage:boolean=false;
  KpiDarshBoardPrivilage:boolean=false;
  totalRecordCount: number = 0;
  filteredRecordCount: number = -1;
  mitigationForm: FormGroup;
  EngagementName:any;
  EngKpiId:any;
  constructor(private privilegeService: PrivilegeService,private _engagementKPIService : EngagementKpiService,
    private router:Router,
    private loaderService: LoaderService,
    private breadcrumbService: BreadcrumbService,
    private _datePipe: DatePipe,
    public commonService:CommonService,
    private masterDataService: SponserService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private currencyService: CurrencyService,
    private engagementService: EngagementService,
    private supplierService: MasterSupplierService,
    private fb: FormBuilder,
    private _contractKPIService:ContractKPIService,
    private checkNullOrEmptyValuesService: ChecknulloremptyComponent,
    private tabAndStateService: TabAndStateService,
    private notificationService:NotificationService,
    private auditLogService: AuditLogService
    )
    
   {
    this.myEngagementKPIForm = this.fb.group({
      _KPIName:[{ value: '', disabled: true }],
      _kpiDataCollectionFrequency:[{ value: '', disabled: true }],
      _kpiReportingFrequency:['', [Validators.required]],
      //_kpiStartDate:[{ value: '', disabled: true }],
      //_kpiEndDate:[{ value: '', disabled: true }],
      _status:['', [Validators.required,this.validateStatus]],
      _unitOfMeasure: [{ value: '', disabled: true }],	

      _hyperCareThreshold: [null, this._isNotApplicable ? null : Validators.required],
      _hyperCareThresholdValue:['',[this._isNotApplicable ? null : Validators.required,Validators.minLength(1),Validators.maxLength(3),Validators.pattern(/^\d*$/)]],
      _hyperCareThresholdsymbol:[{ value: '', disabled: true }],
      _hyperCareStartDate:[{ value: '', disabled: true }],
      _hyperCareEndDate:[{ value: '', disabled: true }],

      _dyhyperCareThreshold: [''],
      _isNotApplicable: [''],
      _steadyStateThreshold:['', [Validators.required]],
      _dysteadyStateThreshold:[''],
      _dyhyperCareThresholdValue:[''],
      _dyhyperCareThresholdsymbol:[{ value: '', disabled: true }],	

  
    });
    this.mitigationForm = this.fb.group({
      Action: ['', Validators.required],
       Status:['', Validators.required]
     });
    //data collection from
    this.kpiDataCollectionFrom = this.fb.group({
      _calculatedKPI: ['',[Validators.required]],
      _remarks: ['',[Validators.required]],
      _attribute1: ['',[Validators.required]],
      _attribute2: ['',[Validators.required]],
      _attribute3:[''],
      _health:[''],
    });
   }
   isEditing: boolean = false;
   codeListValues:any;
   codeListStatusTypes:any;
   noOnlySpacesValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value && control.value.trim().length === 0) {
      return { onlySpaces: true };
    }
    return null;
  }
  validateStatus(control: FormControl) {
    const status = control.value;
    if (status === undefined || status === 0) {
        return { required: true }; // Validation failed
    }
    return null; // Validation passed
}
auditIdentifierDetails:any;
auditEntities:any;
auditOperations:any;
auditModule:any;
  ngOnInit() {
    this._enumBreadcrumb=breadcrumnsCodes;
    this._dateformart=Dateformat;
    this.auditIdentifierDetails=AuditIdentifier; 
      this.auditEntities=AuditLogEntities;
      this.auditOperations=AuditOperations;
      this.auditModule=AuditLogModule;
    this.getEngagementKPIsByEngagementId();
    this.loaderService.hidefinalLoader();// hide after 4 seconds even get any error
    this.BindPrivileges();
    this.GetEngagementDataById(this.engagementId);
    this.GetAllMasterKpis(11);
    this.codeListValues=CodeList;
    this.codeListStatusTypes=CodeListStatusTypes
    this.GetMitigationStatus(this.codeListValues.Tbl_Status, this.codeListStatusTypes.MASTS);
    this.GetAllkpiDataCollectionFrequenies(8);
    this.FindAllHyperCareThresholds(10);
  }
  GetAllMasterKpis(type:number) {
    this.masterDataService.GetAllSupplierMasterData(type).subscribe(
      res=>{
        this.masterKpiDataSource=res.data;
      }
    );
  };
  startEditing(kpiId: number) {
    this.isEditing = true;
  }
  BindPrivileges(){
    const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);
     this.createPrivilege = hasPermission(Privileges.AddSupplierKPI);
this.editPrivilege =hasPermission(Privileges.EditSupplierKPI);
this.viewPrivilege = hasPermission(Privileges.ViewSupplierKPI);
this.ExportPrivilage= hasPermission(Privileges.ExportEngagementKPI);
this.KpiDarshBoardPrivilage=hasPermission(Privileges.ViewEngagementKPIDashboard);
    }

addSupplierKPI()
{
   this.addKpiButtonClick.emit();
}
editEngagementKPI(Id:any){
  const data = {
    engagementId:this.engagementId,
    kpiId:Id
  };
  this.editKpiButtonClick.emit(data); 
}

editEngagementKPIDataCollection(Id:any){
  this.EngKpiId=Id;
  this. editedKPIDataCollection=[];
  this.displayEditDataCollectionDialog = true;
  this.GetKpiById(Id);
}
getEngagementKPIsByEngagementId(){
  let result: Object;
  this.loaderService.showLoader();
  this._engagementKPIService.getEngagementKPIsByEngagementId(this.engagementId).subscribe(
    response=>{
     this._engagementKPIListData=response.data;
     this._engagementKPIListDatacopy=response.data;
     this.tabAndStateService.setKpiData(this._engagementKPIListData);
     this.totalRecordCount= this._engagementKPIListData.length;
     this.statusdata = this.extractUniqueValues(this._engagementKPIListData, 'status').map((obj: any) => ({ label: obj }));
     this.healthdata = this.extractUniqueValues(this._engagementKPIListData, 'overallAverageHealth').map((obj: any) => ({ label: obj }));
     this.healthdata= this.healthdata.filter(t=>t.label!=null &&t.label!='yellow' );
     this.kpidata = this.extractUniqueValues(this._engagementKPIListData, 'kpi');
      this.loaderService.hideLoader();
    }
  );
}


ViewSupplierKPI(Id:any)
{
  const data = {
    supplierId:this.engagementId,
    kpiId:Id
  };
  this.viewKpiButtonClick.emit(data);
}
navigateToDashboard(){
  const breadcrumb=this._enumBreadcrumb.EVSUP
  const engagementId = this.engagementId;
  this.router.navigate(['/KPITrendDashboard'], { queryParams: { engagementId, breadcrumb } });
}
CheckboxEnabled(){
  if(this.checkedKpis.length>0){
    this.isChecked=false;
  }
  else{
    this.isChecked=true;
  }
}
KpiExportData:any;
exportexcelKpi() {
  this.loaderService.showLoader();
  let KpiIds = this.checkedKpis.map(kpi => kpi.id).join(',');
  this._engagementKPIService.GetEngagementKPIDetailsByIdForExport(KpiIds).subscribe(response => {
    this.loaderService.hideLoader();
    this.KpiExportData = response.data;
    this.KpiExportData.forEach((kpi: { engagementDataCollectionList: any[]; dataCollectionFrequency: any; }) => {
      this.SetFrequencyDataCollection(kpi.engagementDataCollectionList, kpi.dataCollectionFrequency);
    });
    this.selectedRecordsToExpotToExcel = this.KpiExportData;

    const dataToExport = this.mapKpiDataForExport(this.selectedRecordsToExpotToExcel);
    const kpiDataToExport = this.mapKpiDataCollectionForExport(this.selectedRecordsToExpotToExcel);

    this.exportToExcel(dataToExport, kpiDataToExport);
  });
}

private mapKpiDataForExport(kpiData: any[]) {
  return kpiData.map(kpi => this.mapSingleKpi(kpi));
}

private mapSingleKpi(kpi: any) {
  return {
    'KPI': kpi.kpi,
    'Service': kpi.services,
    'KPI Name': kpi.kpiName,
    'Data Collection Frequency': kpi.dataCollectionFrequency,
    'KPI Reporting Frequency': kpi.kpiReportingCollectionFrequency,
    'KPI Tracking Start': this.formatDate(kpi.kpiTrackingStartDate),
    'KPI Tracking End': this.formatDate(kpi.kpiTrackingEndDate),
    'Status': kpi.status,
    'Unit of Measure': kpi.unitOfMeasure,
    'Hyper Care Target': kpi.hyperCareThresold,
    'Hyper Care Start Date': this.formatDate(kpi.hyperCareStartDate),
    'Hyper Care End Date': this.formatDate(kpi.hyperCareEndDate),
    'Steady State Target': kpi.steadyStateThreshold,
    'Category': kpi.category,
    'Compliance Indicator': kpi.complianceIndicator,
    'Calculation': kpi.calculation,
    'Attribute 1': kpi.attribute1,
    'Attribute 2': kpi.attribute2,
    'Attribute 3': kpi.attribute3,
    'KPI Description': kpi.kpiDescription,
    'Current KPI': kpi.overAllAverageKPI,
    'Current Health': kpi.overallAverageHealth,
    'Added By': this.getUserName(kpi.createdBy),
    'Added Date': this.formatDate(kpi.createdDate),
    'Modified By': this.getUserName(kpi.updatedBy),
    'Modified Date': this.formatDate(kpi.updatedDate)
  };
}

private getUserName(userId: any) {
  return this.privilegeService.getUserName(userId);
}

private mapKpiDataCollectionForExport(kpiData: any[]) {
  return kpiData.flatMap(kpi =>
    kpi.engagementDataCollectionList.map((dataCollection: { dataCollectionFrequencyValue: any; attribute1: any; attribute2: any; attribute3: any; calculatedKPI: any; remarks: any; engagementKpiMitigationActionsList: string | any[]; health: any; updatedDate: any; }) => ({
      'KPI Name': kpi.kpiName,
      'Data For': dataCollection.dataCollectionFrequencyValue,
      'Attribute 1': dataCollection.attribute1,
      'Attribute 2': dataCollection.attribute2,
      'Attribute 3': dataCollection.attribute3,
      'Calculated KPI': dataCollection.calculatedKPI,
      'Remarks': dataCollection.remarks,
      'Mitigation Actions': dataCollection.engagementKpiMitigationActionsList.length,
      'Health': dataCollection.health,
      'Actual Data Fill Date': (dataCollection.attribute1 != null || dataCollection.attribute2 != null || dataCollection.calculatedKPI != null) ? this.formatDate(dataCollection.updatedDate) : null,
      'Data Filled By': this.privilegeService.getUserName(kpi.createdBy)
    }))
  );
}
formatDate(date: any) {
  return date != null ? this.convertDateFormat(date) : date;
}
exportToExcel(dataToExport: any[], kpiDataToExport: any[]) {
  const wb = xlsx.utils.book_new();
  const detailsSheet = xlsx.utils.json_to_sheet(dataToExport);
  const kpiDataSheet = xlsx.utils.json_to_sheet(kpiDataToExport);

  xlsx.utils.book_append_sheet(wb, detailsSheet, 'Details');
  xlsx.utils.book_append_sheet(wb, kpiDataSheet, 'KPI Data');
  xlsx.writeFile(wb, `${this.engagementName} - KPIsExport - ${this.convertDateFormat(Date())}.xlsx`);
}

showfilter()
{
  this.showHideFiltersControls = !this.showHideFiltersControls;
}
  convertDateFormat(inputDateTime: string) {
    const date = new Date(inputDateTime);
    const formattedDate = this._datePipe.transform(date, this._dateformart.GridDateFormat);
    return formattedDate;
  }

  onfilter()
  {
    this._engagementKPIListData=this._engagementKPIListDatacopy;
  
  
    if(this.filterDetailsModel.Status && this.filterDetailsModel.Status.label){
      this._engagementKPIListData = this._engagementKPIListData.filter((c:any) => c.status === this.filterDetailsModel.Status.label);

    }
    if(this.filterDetailsModel.Health &&this.filterDetailsModel.Health.label){
      this._engagementKPIListData = this._engagementKPIListData.filter((c:any) => c.overallAverageHealth === this.filterDetailsModel.Health.label);
    }
    if(this.filterDetailsModel.Kpi &&  this.filterDetailsModel.Kpi.length > 0) {
      this._engagementKPIListData = this._engagementKPIListData.filter((c:any) => this.filterDetailsModel.Kpi.includes(c.kpi));
    }

    //filter By name
    if (this.filterDetailsModel.kpiName != null && this.filterDetailsModel.kpiName != undefined && this.filterDetailsModel.kpiName != "") {
      this._engagementKPIListData = this._engagementKPIListData.filter(a => a.kpiName.toLowerCase().indexOf(this.filterDetailsModel.kpiName?.toLowerCase()) !== -1);
    }
    this.filteredRecordCount= this._engagementKPIListData.length;
    this.tabAndStateService.setKpiData(this._engagementKPIListData);
  }
  extractUniqueValues(array: any, key: any)
  {
    return Array.from(new Set(array.map((item: any) => item[key])));
  }
  clearFilters(){
  this.filterDetailsModel={
   Status: '',
   Health: '',
   Kpi: [],
    }

    this.checkedKpis=[];
    this.isChecked=true;
    
   this._engagementKPIListData=this._engagementKPIListDatacopy;
   this.filteredRecordCount= this._engagementKPIListData.length;
   
   }
   selectedKpiReportData:any;
   displayMitigationListPopup:boolean=false;
   displayEditDataCollectionDialog: boolean = false;
   oldMitigationActionsData: any[] = [];
  viewFindingList(Kpidata: any) {
    this.selectedKpiReportData = Kpidata;
    this.addMitigationActionsData = Kpidata.engagementKpiMitigationActionsList ? Kpidata.engagementKpiMitigationActionsList.map((item: { targetDate: string; TargetDate: string; closureDate: string; ClosureDate: string; }) => {
      const formattedTargetDate = item.targetDate ? this.convertDateFormat(item.targetDate) : item.TargetDate ? this.convertDateFormat(item.TargetDate) : null;
      const formattedClosureDate = item.closureDate ? this.convertDateFormat(item.closureDate) : item.ClosureDate ? this.convertDateFormat(item.ClosureDate) : null;
  
      return {
        ...item,
        TargetDate: formattedTargetDate ? new Date(formattedTargetDate) : null,
        ClosureDate: formattedClosureDate ? new Date(formattedClosureDate) : null,
      };
    }) : [];
  
    this.addMitigationActionsData.forEach(item => {
      item.Id = item.id || item.Id;
      item.KpiId = item.kpiid || item.KpiId;
      item.DataCollectionId = item.dataCollectionId || item.DataCollectionId;
      item.Action = item.action || item.Action;
      item.ActionOwner = item.actionOwnerId || item.ActionOwner;
      item.StatusMappingId = item.statusMappingId || item.StatusMappingId;
      item.TargetDate= item.targetDate!=null ? new Date(item.targetDate) : null  || item.TargetDate!=null ? new Date(item.TargetDate) : null ;
      item.ClosureDate= item.closureDate!=null ? new Date(item.closureDate) : null  || item.ClosureDate !=null ? new Date(item.ClosureDate) : null ;
    });
    this.oldMitigationActionsData = JSON.parse(JSON.stringify(this.addMitigationActionsData));
    this.visableMitigations = this.addMitigationActionsData.filter(action => !action.isDeleted);

    this.selectedKpiReportData.findingsCount = this.addMitigationActionsData.length;
    this.displayMitigationListPopup = true;
    this.addMitigationSaved = false;
  } 
  addMitigationActionsData: any[] = [];
  AddMitigationActions() { //Click on Add Action button new row will be generated on top of the grid
    this.addMitigationActionsData.unshift({
      Id: 0,
      Action: '',
      ActionOwner: "",
      TargetDate: null,
      StatusMappingId: '',
      ClosureDate: null,
      IsDeleted: false
    });
    this.visableMitigations = this.addMitigationActionsData.filter(action => !action.IsDeleted);
  }
  _engagementKpiDataCollections: EngagementKpiDataCollection[]=[];
  _engagementKpi=new EngagementKpi();
  previousEndDate:any;
  DataCollectionFrequency:string="";
  isAttribute1NotApplicable=false;
  isAttribute2NotApplicable=false;
  isAttribute3NotApplicable=false;
  _readOnlyCalculation:string="";
  _readOnlyAttribute1:string="";
  _readOnlyAttribute2:string="";
  _readOnlyAttribute3:string="";
  _readOnlyFormulaName:string="";
  _formulaeId:number=0;
  _kpiAttributeTypeCode:string="";
  _kpiAttributeTypeId:number=0;
  _readOnlySybmolHyperCareThreshold:string="";
  _readOnlySybmolSteadyStateThreshold:string="";
  _isNotApplicable:boolean=false;
  _unitCode:string="";
  selectedItem: any;
    oldSelectedItem: any;
    dataCollectionSubmitted=false;
    _overAllAverageKPI?:string="";
    _overallAverageHealth?:string="";
    suggestedKPIName:string="";
    kpiCreatedUserName:string="";
    kpiUpdatedUserName:string="";
    _kpiHyperCareThreshold= new EngagementKPIHyperCareThreshold();
    _kpiHyperCareThresholds: EngagementKPIHyperCareThreshold[]=[];
    _dynamicKpiHyperCareThreshold= new EngagementKPIHyperCareThreshold();
    _dynamicKpiHyperCareThresholds: EngagementKPIHyperCareThreshold[]=[];
    _dynamicKpiSteadyStateThreshold= new EngagementKPISteadyStateThreshold();
    _dynamicKpiSteadyStateThresholds: EngagementKPISteadyStateThreshold[]=[];
    _kpiSteadyStateThreshold= new EngagementKPISteadyStateThreshold();
    _kpiSteadyStateThresholds: EngagementKPISteadyStateThreshold[]=[];
    kpiNameDataSource: CodeListValues[] | undefined;
  kpiReportingFrequencyDataSource: CodeListValues[] | undefined;
  kpiDataCollectionFrequencyDataSource: CodeListValues[] | undefined;
  statusDataSource: CodeListValues[] | undefined;
  oldKpiInfo:any;
  changedEngagementKPIBasicInfo:any;
    oldEngaementKPIData:any;
    engagementKPIDetailBeforeUpdate:any
    oldEngagementDataCollection:any;
  GetKpiById(EngKpiId:number) {
    this.oldEngagementDataCollection='';
    this.loaderService.showLoader();
      this._engagementKPIService.GetEngagementKpiById(EngKpiId).subscribe(
      res=>{
        this._engagementKpi=res.data;
       this.previousEndDate=res.data.kpiEndDate;
        this._engagementKpi.kpiStartDate=new Date(res.data.kpiStartDate);
        this._engagementKpi.kpiEndDate=new Date(res.data.kpiEndDate);
        this._engagementKpi.hyperCareStartDate=new Date(res.data.hyperCareStartDate);
        this._engagementKpi.hyperCareEndDate=new Date(res.data.hyperCareEndDate);
          if(this._engagementKpi.attribute1 != 'NA' && this._engagementKpi.attribute1 != "")
            {
            this.isAttribute1NotApplicable=false;
            }
            else{
              this.isAttribute1NotApplicable=true;
            }
            if(this._engagementKpi.attribute2 != 'NA' && this._engagementKpi.attribute2 != "")
              {
              this.isAttribute2NotApplicable=false;
              }
              else{
                this.isAttribute2NotApplicable=true;
              }
              if(this._engagementKpi.attribute3 != 'NA' && this._engagementKpi.attribute3 != "")
                {
                this.isAttribute3NotApplicable=false;
                }
                else{
                  this.isAttribute3NotApplicable=true;
                }
        

        this._readOnlyCalculation=res.data.calculation?res.data.calculation:"";
        this._readOnlyAttribute1=res.data.attribute1?res.data.attribute1:"";
        this._readOnlyAttribute2=res.data.attribute2?res.data.attribute2:"";
        this._readOnlyAttribute3=res.data.attribute3?res.data.attribute3:"";



        this._engagementKpiDataCollections=res.data.engagementDataCollectionList;

        this._engagementKpiDataCollections.forEach((item: any) => {
          item.findingsCount = item.engagementKpiMitigationActionsList ? item.engagementKpiMitigationActionsList.length : 0;
        });
      
       
        this.GetAllkpiDataCollectionFrequenies(8);
       
        this._overAllAverageKPI=res.data.overAllAverageKPI;
        this._overallAverageHealth=res.data.overallAverageHealth;
        this.kpiCreatedUserName=this.privilegeService.getUserName(res.data.createdBy);
        this.kpiUpdatedUserName=this.privilegeService.getUserName(res.data.updatedBy);
        if(res.data.engagementKPIHyperCareThreshold?.length==1)
        {
        this._kpiHyperCareThreshold=res.data.engagementKPIHyperCareThreshold[0];
        }
        else if(res.data.engagementKPIHyperCareThreshold?.length==2){
        this._kpiHyperCareThreshold=res.data.engagementKPIHyperCareThreshold[0];
        this._dynamicKpiHyperCareThresholds.push(res.data.engagementKPIHyperCareThreshold[1]);
        this._dynamicKpiHyperCareThreshold=res.data.engagementKPIHyperCareThreshold[1];
        }
        else{
         this._isNotApplicable=true;
         this._engagementKpi.hyperCareStartDate=undefined;
         this._engagementKpi.hyperCareEndDate=undefined;
        }
        if(res.data.engagementKPISteadyStateThreshold?.length<2)
        {
        this._kpiSteadyStateThreshold=res.data.engagementKPISteadyStateThreshold[0];
        }
        else{
        this._kpiSteadyStateThreshold=res.data.engagementKPISteadyStateThreshold[0];
        this._dynamicKpiSteadyStateThresholds.push(res.data.engagementKPISteadyStateThreshold[1]);
        this._dynamicKpiSteadyStateThreshold=res.data.engagementKPISteadyStateThreshold[1];
        }
        setTimeout(() => {
          this.OnChangeKPIName(res.data.kpiDetailId);
          let filteDataCollection = this.kpiDataCollectionFrequencyDataSource?.filter(t=>t.id==this._engagementKpi.kpiDataCollectionFrequencyId);
          if(filteDataCollection!=null)
          {
          this.DataCollectionFrequency=filteDataCollection[0].name;
          let selectedSubCode=filteDataCollection[0].sub_Code;
          this.kpiReportingFrequencyDataSource=this.kpiReportingFrequencyDataSource?.filter(t=>t.sub_Code>=selectedSubCode) ;
       
          }
         
        },1000);
        this.oldKpiInfo=res.data;
        this. oldKpiInfo.dataCollectionList=res.data.engagementDataCollectionList;
        this. oldKpiInfo.overAllAverageKPI=res.data.overAllAverageKPI;
        this. oldKpiInfo.overallAverageHealth=res.data.overallAverageHealth
        this.engagementKPIDetailBeforeUpdate=JSON.stringify(this.oldKpiInfo);
        this.loaderService.hideLoader();
      }
    );
    this.oldEngagementDataCollection = JSON.parse(JSON.stringify(this._engagementKpiDataCollections));
  };
  isFrequencyAnnual=false;
  editedKPIDataCollection:any=[];//msg
  frequencyData:any;
  GetAllkpiDataCollectionFrequenies(type:number) {
    this.masterDataService.getCodeListData(type,'').subscribe(
      res=>{
        this.kpiDataCollectionFrequencyDataSource=res.data;

        let frequency=this.kpiDataCollectionFrequencyDataSource?.filter(t=>t.id==this._engagementKpi.kpiDataCollectionFrequencyId)[0].name;
        const kpiStart=this.commonService.getFrequencyFromDate(this._engagementKpi.kpiStartDate,frequency);
        const kpiEnd=this.commonService.getFrequencyFromDate(this._engagementKpi.kpiEndDate,frequency);
        
        //Get Frequency Data Here
        if(frequency=="Monthly")
          this.frequencyData=JSON.parse(FrequencyValues.Monthly);
        else if(frequency=="Quarterly")
          this.frequencyData=JSON.parse(FrequencyValues.Quarterly);
        else if(frequency=="Half-Year")
          this.frequencyData=JSON.parse(FrequencyValues.HalfYearly);
        else if(frequency=="Annual")
          this.frequencyData=JSON.parse(FrequencyValues.Annual);

          if(frequency=="Annual"){
            this.isFrequencyAnnual=true;
          }
          else{
            this.isFrequencyAnnual=false;
          }

          this.SetFrequencyValueforDataCollection(this._engagementKpi.kpiDataCollectionFrequencyId);

          this.BindFrequencyDates(kpiStart,kpiEnd,frequency,this.frequencyData);
      }
    );
  };
  SetFrequencyValueforDataCollection(frequencyId:any){
    let frequency=this.kpiDataCollectionFrequencyDataSource?.filter(t=>t.id==frequencyId)[0].name;
        
      this._engagementKpiDataCollections.forEach((item:any) => {
      item.dataCollectionFrequencyValue=this.commonService.getFrequencyFromDate(item.dataCollectionDate,frequency);
    });
  }
  BindFrequencyDates(startFreq: any, endFreq: any, frequency: any, dataCollectionFrequencyData:any) 
  {
    let startFrequencyValue: string;
    let startFrequencyYear: string;
    let endFrequencyValue: string;
    let endFrequencyYear: string;
  
    switch (frequency) {
      case "Monthly":
        [startFrequencyValue, startFrequencyYear] = startFreq.split(" ");
        [endFrequencyValue, endFrequencyYear] = endFreq.split(" ");
        break;
      case "Quarterly":
      case "Half-Year":
      case "Annual":
        startFrequencyValue = startFreq.substring(0, 2); // Extract the first two characters
        startFrequencyYear = startFreq.slice(-4);
        endFrequencyValue = endFreq.substring(0, 2); // Extract the first two characters
        endFrequencyYear = endFreq.slice(-4);
        break;
      default:
        throw new Error('Invalid frequency');
    }

    if(frequency != "Annual"){
      this._engagementKpi.frequencyValueforStartDate=dataCollectionFrequencyData.find((a:any)=>a.name==startFrequencyValue).order;
      this._engagementKpi.frequencyValueforEndDate=dataCollectionFrequencyData.find((a:any)=>a.name==endFrequencyValue).order;;
    }

  this._engagementKpi.kpiStartDateYear=startFrequencyYear;
  this._engagementKpi.kpiEndDateYear=endFrequencyYear;
    
  }
  _readOnlyUnitofMeasure:string="";
  _readOnlyCategory:string="";
  _readOnlyComplianceIndicator:string="";
  masterKpiDataSource: MasterKpiData[] | undefined;
  OnChangeKPIName(kpiDetailId:number){
   
    let filteSupplierMasterData = this.masterKpiDataSource?.filter(t=>t.kpiDetailId==kpiDetailId);
    if(filteSupplierMasterData!=null)
    {
  this._readOnlyUnitofMeasure=filteSupplierMasterData[0].unitOfMeasure;
  this._readOnlyCategory=filteSupplierMasterData[0].category;
  this._readOnlyComplianceIndicator=filteSupplierMasterData[0].complianceIndicator;
  this._unitCode=filteSupplierMasterData[0].unitOfMeasureCode;
  this._formulaeId=filteSupplierMasterData[0].formulaeId;
  this._readOnlyFormulaName=filteSupplierMasterData[0].formulaName;
  this._kpiAttributeTypeCode=filteSupplierMasterData[0].kpiAttributeTypeCode;
  this._kpiAttributeTypeId=filteSupplierMasterData[0].kpiAttributeTypeId;
 
  
  if(!this._isNotApplicable)
  {
    this._readOnlySybmolHyperCareThreshold=this._unitCode;
  }
  else{
    this._readOnlySybmolHyperCareThreshold="";
  }
  this._readOnlySybmolSteadyStateThreshold=this._unitCode;
  
  }
   else{
    this._readOnlyUnitofMeasure="";
    this._readOnlyCategory="";
    this._readOnlyComplianceIndicator="";
    this._readOnlyCalculation="";
    this._readOnlyAttribute1="";
    this._readOnlyAttribute2="";
    this._readOnlyAttribute3="";
    this._readOnlySybmolHyperCareThreshold="";
    this._readOnlySybmolSteadyStateThreshold="";
    this._unitCode="";
    this._readOnlyFormulaName="";
    this._formulaeId=0;
    this._kpiAttributeTypeCode="";
    this._kpiAttributeTypeId=0;
    }
   
  }
  getCalculationFormulae(){
    let data=this._readOnlyFormulaName;
    data=data.replace('Attribute 1',this._readOnlyAttribute1);
    data=data.replace('Attribute 2',this._readOnlyAttribute2);
    data=data.replace('Attribute 3',this._readOnlyAttribute3);
    this._readOnlyCalculation=data;
   }
   currencies: any='USD';
   getFormattedValue(value: number, currencyName: string): string {
   let formattedValue = this.currencyService.getFormattedValue(value, currencyName);
  return this.handleCurruncy(formattedValue);
  }
  private handleCurruncy(formattedValue: string): string {
    return formattedValue.replace(/^[^\d]+/, '');
}
addMitigationSaved = false;
_addActionsFieldsMandatory: boolean = false;
saveMitigationActions() {
this.addMitigationSaved = true;
this._addActionsFieldsMandatory = this.addMitigationActionsData.some((action) => {
const status = this.actionStatus.find((a: { id: any; }) => a.id === action.StatusMappingId);
if (
  this.checkNullOrEmptyValuesService.isNullOrUndefinedOrEmpty(action.Action) || 
  this.checkNullOrEmptyValuesService.isNullOrUndefinedOrEmpty(action.StatusMappingId) || 
  (status && status.code === "CLD" && this.checkNullOrEmptyValuesService.isNullOrUndefinedOrEmpty(action.ClosureDate))
) {
  return true;
}
return false;
});

if (this.validateFields()) {
this.selectedKpiReportData.engagementKpiMitigationActionsList = this.addMitigationActionsData.map(action => ({
  ...action,
  StatusMappingId: action.StatusMappingId, 
  ActionOwner: action.ActionOwner === '' ? 0 : action.ActionOwner,
  TargetDate: action.TargetDate || '', 
  ClosureDate: action.ClosureDate || '', 
}));
this.selectedKpiReportData.findingsCount = this.addMitigationActionsData.length; 
this.displayMitigationListPopup = false;

} else {
    Object.values(this.mitigationForm.controls).forEach(control => {
  control.markAsTouched();
});
}
}
validateFields(): boolean {
for (const actionData of this.addMitigationActionsData) {
  const status = this.actionStatus.find((s: { id: any; }) => s.id === actionData.StatusMappingId);
  if (!actionData.Action || actionData.Action.trim() === '') {
    return false;
  }
  if (!actionData.StatusMappingId) {
    return false;
  }
  if (status && status.code === "CLD") {
    if (!actionData.ClosureDate) {
      return false;
    }
  }
}
return true;
}
cancelMitigationDialog() {
  if (JSON.stringify(this.oldMitigationActionsData) !== JSON.stringify(this.addMitigationActionsData)) {
    this.confirmationService.confirm({
      message: 'Changes will not be saved, Do you want to Continue?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.messageService.add({
          key: 'tc',
          severity: 'info',
          summary: 'Changes not saved',
          detail: '',
        });
        this.displayMitigationListPopup = false;
      },
      reject: () => {
       
      },
    });
  } else {
    this.displayMitigationListPopup = false;
  }
  }
  visableMitigations:any;
deleteAction(currentActionIndex: number) {  
  this.confirmationService.confirm({
       header: 'Confirm',
       message: 'Are you sure you want to delete this action?',
       accept: () => {
        this.visableMitigations.splice(currentActionIndex,1);
        this.addMitigationActionsData=this.visableMitigations;
       this.messageService.add({ severity: 'success', summary: 'Mitigation Action Deleted', detail: '', });
     }
     });
   }
   actionStatus:any;
   GetMitigationStatus(enumId: number, type: string) { //Get Meeting Status's from code list for the Meetings
     this.masterDataService.getCodeListData(enumId, type).subscribe(
       res => {      
         if (res.data.length > 0) {
           this.actionStatus = res.data;
         }
       }
     );
   };
   sponsorId:number=0;
   supplierId:number=0;
   engagementName:any;
   GetEngagementDataById(EngagementId:number){
    this.engagementService.GetEngagementById(EngagementId).subscribe(
      res=>{
        this.engagementName=res.data.engagementName
        this.loaderService.hideLoader();
        this.EngagementName=res.data.engagementName;
        this.sponsorId=res.data.supplierId;
        this.supplierId=res.data.sponsorId;
        this.getCombinedContacts(res.data.supplierId,res.data.sponsorId);
      }
    );
  }
convertActionDateFormat(inputDateTime: string) {
  const date = new Date(inputDateTime);
  const formattedDate = this._datePipe.transform(date, this._dateformart.AuditFormat);
  return formattedDate;
}
supplierContactsDataSource : SupplierContact[]=[];
sponsorContactsDataSource : SponsorContact[]=[];
combinedContacts: any[] = [];
getCombinedContacts(supplierId: number = 0, sponsorId: number = 0) {
  this.supplierService.GetSupplierById(supplierId).subscribe(supplierRes => {
      this.supplierContactsDataSource = supplierRes.data.supplierContacts;
      this.masterDataService.getSponserData(sponsorId).subscribe(sponsorRes => {
        this.sponsorContactsDataSource = sponsorRes.data.sponserContacts.map((contact: { id: any; name: any; countryCode: any; designation: any; phoneNumber: any; email: any; sponserId: any; createdBy: any; createdDate: any; updatedBy: any; updatedDate: any; isDeleted: any; }) => {
          return {
              id: contact.id,
              contactName: contact.name, 
              countryCode: contact.countryCode,
              designation: contact.designation,
              phoneNumber: contact.phoneNumber,
              email: contact.email,
              supplierProfileId: contact.sponserId, 
              createdBy: contact.createdBy,
              createdDate: contact.createdDate,
              updatedBy: contact.updatedBy,
              updatedDate: contact.updatedDate,
              isDeleted: contact.isDeleted
          };
      });
          this.combinedContacts = [...this.supplierContactsDataSource, ...this.sponsorContactsDataSource];
      });
  });
}
cancelKpiDataCollectionDialog(){
  if(this.oldKpiDataCollection){
if(this.oldEngagementDataCollection.length>0 || this.oldKpiDataCollection.length>0){
  if ((JSON.stringify(this.oldEngagementDataCollection) !== JSON.stringify(this._engagementKpiDataCollections)) || (JSON.stringify(this.oldKpiDataCollection) !== JSON.stringify(this._engagementKpiDataCollections))) {
    this.confirmationService.confirm({
      message: 'Changes will not be saved, Do you want to Continue?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.messageService.add({
          key: 'tc',
          severity: 'info',
          summary: 'Changes not saved',
          detail: '',
        });
        this.displayEditDataCollectionDialog=false;
      },
      reject: () => {
       
      },
    });
  }
  else {
    this.displayEditDataCollectionDialog=false;
  }
 }else {
    this.displayEditDataCollectionDialog=false;
  }
}else{
  this.displayEditDataCollectionDialog=false;
}
}


hyperCareThresholdDataSource: CodeListValues[] | undefined;
steadyStateThresholdDataSource: CodeListValues[] | undefined;
FindAllHyperCareThresholds(type:number) {
  this.masterDataService.getCodeListData(type,'').subscribe(
    res=>{
      this.hyperCareThresholdDataSource=res.data;
      this.steadyStateThresholdDataSource=res.data;
    }
  );
};


isSaveBtnDisable = false;
submitted = false;
onUpdateEngagementKpi()
{
  this.isSaveBtnDisable = true;
  setTimeout(() => {
    this.isSaveBtnDisable = false;
  }, 3000);


   //validate dynamic fileds


  this.submitted = true;
 

    this.loaderService.showLoader();
  
    this._engagementKpiDataCollections.forEach(collection => {
      if (collection.engagementKpiMitigationActionsList) {
        collection.engagementKpiMitigationActionsList = collection.engagementKpiMitigationActionsList.map(action => this.updateMitigations(action));
      }
    });
    
    if(!this._isNotApplicable)
    {
      this._kpiHyperCareThresholds.push(this._kpiHyperCareThreshold);
      if(this._dynamicKpiHyperCareThreshold.mathematicalSymbolId!=0)
      this._kpiHyperCareThresholds.push(this._dynamicKpiHyperCareThreshold)
    }
    this._kpiSteadyStateThresholds.push(this._kpiSteadyStateThreshold);
    if(this._dynamicKpiSteadyStateThreshold.mathematicalSymbolId!=0)
    this._kpiSteadyStateThresholds.push(this._dynamicKpiSteadyStateThreshold);
    this._engagementKpi.EngagementKPIHyperCareThreshold=this._kpiHyperCareThresholds;
    this._engagementKpi.EngagementKPISteadyStateThreshold=this._kpiSteadyStateThresholds;
   
    this._engagementKpi.kpiReportingFrequencyCode=this.kpiDataCollectionFrequencyDataSource?.filter(t=>t.id==this._engagementKpi.kpiDataCollectionFrequencyId)[0].code;
   

    this._engagementKpi.updatedBy=this.privilegeService.getLoginUserId();
    this._engagementKpi.EngagementDataCollectionList=this._engagementKpiDataCollections;
    if(this._engagementKpi.hyperCareEndDate != undefined && this._engagementKpi.hyperCareEndDate != null)
  {
    this._engagementKpi.hyperCareEndDate =  this._datePipe.transform(this._engagementKpi.hyperCareEndDate, "MM/dd/yyyy");
  }
  if(this._engagementKpi.hyperCareStartDate != undefined && this._engagementKpi.hyperCareStartDate != null)
  {
    this._engagementKpi.hyperCareStartDate = this._datePipe.transform(this._engagementKpi.hyperCareStartDate, "MM/dd/yyyy");
  }  
  this._engagementKpi.attribute1=this._readOnlyAttribute1;
  this._engagementKpi.attribute2=this._readOnlyAttribute2;
  this._engagementKpi.attribute3=this._readOnlyAttribute3;
  this._engagementKpi.calculation=this._readOnlyCalculation;

  let frequency=this.kpiDataCollectionFrequencyDataSource?.filter(t=>t.id==this._engagementKpi.kpiDataCollectionFrequencyId)[0].name;
  
  this._engagementKpi.kpiStartDate=this.commonService.getCompleteDate(frequency,this._engagementKpi.frequencyValueforStartDate,this._engagementKpi.kpiStartDateYear);
  this._engagementKpi.kpiEndDate=this.commonService.getCompleteDate(frequency,this._engagementKpi.frequencyValueforEndDate,this._engagementKpi.kpiEndDateYear);
  
 //Check Start date should always greater than End Date
  if(this._engagementKpi.kpiStartDate>this._engagementKpi.kpiEndDate)
  {
    this.messageService.add({  key: 'tc',life: 4000,severity: 'warn', summary: 'KPI Tracking End should be greater than KPI Tracking Start', detail: '' });  
    this.loaderService.hideLoader();    
  }
  else{

    //Check End Date should always greater than previous End Date
    if(this._engagementKpi.kpiEndDate<new Date(this.previousEndDate)){
      this.messageService.add({  key: 'tc',life: 4000, severity: 'warn', summary: 'KPI Tracking End can only be extended and cannot be shortened', detail: '' });  
      this.loaderService.hideLoader();  
    }
    else{

    this._engagementKpi.kpiEndDate = this._datePipe.transform(this._engagementKpi.kpiEndDate, "MM/dd/yyyy");
    this._engagementKpi.kpiStartDate = this._datePipe.transform(this._engagementKpi.kpiStartDate, "MM/dd/yyyy");
      this._engagementKPIService.UpdateEngagementKpi(this._engagementKpi).subscribe(async (res: { status: any; }) => {
     if(res.status)
     {
      this.compareMitigations();
      if(this.editedKPIDataCollection?.length>0){
        let kpiData ={
         'kpiName':this._engagementKpi.kpiName,
         'engagementName':this.EngagementName,
         'contractTitle':'',
         'engagementId':this.engagementId,
         'contractId':0,
         'kpiId':this.EngKpiId,
         'kpiDataCollectionEdited':this.editedKPIDataCollection,
         'kpiDataCollection':this._engagementKpiDataCollections,
         'attribute1':this._engagementKpi.attribute1,
         'attribute2':this._engagementKpi.attribute2,
         'attribute3':this._engagementKpi.attribute3,
         'hyperCareThreshold':this._engagementKpi.EngagementKPIHyperCareThreshold,
                   'steadyStateThreshold':this._engagementKpi.EngagementKPISteadyStateThreshold,
                   'hyperCareStartDate':this._engagementKpi.hyperCareStartDate,
                   'hyperCareEndDate':this._engagementKpi.hyperCareEndDate,
                   'mathematicalSymbolSource':this.hyperCareThresholdDataSource
        }
        //below line commented for resove everloading issue
await this.notificationService.KPIThresholdNotification(0,kpiData,this.EngKpiId,this.sponsorId,this.supplierId)// call the KPI Threshold send notification service metod param1 - contract KPI ID, param2 - required KPI data, param3 - supplier KPI Id
}
      this.compareModels();
      this.loaderService.hideLoader();
       this.displayEditDataCollectionDialog=false;
      this.messageService.add({ key: 'tc', severity: 'success', summary: 'Changes have been saved', detail: '' });
      this.getEngagementKPIsByEngagementId();
     }
   });
  }
  }
 
}
updateMitigations(action: any): any {
  return {
    id: action.Id ?? action.id ?? null,
    action: action.Action ?? action.action ?? '',
    actionOwnerId: action.ActionOwner ?? action.actionOwnerId ?? null,
    targetDate: this._datePipe.transform(action.TargetDate ?? action.targetDate, 'MM/dd/yyyy') ?? null,
    statusMappingId: action.StatusMappingId ?? action.statusMappingId ?? null,
    closureDate: this._datePipe.transform(action.ClosureDate ?? action.closureDate, 'MM/dd/yyyy') ?? null,
    isDeleted: action.IsDeleted ?? action.isDeleted ?? false
  };
}
_engagementKpiDataCollection= new EngagementKpiDataCollection();
visible: boolean = false; 
myEngagementKPIForm: FormGroup;
kpiDataCollectionFrom: FormGroup;
isAttributeValueSelected=false;
editDataCollection(item: any) {
  this._engagementKpiDataCollection = new EngagementKpiDataCollection();
  this.dataCollectionSubmitted = false;
  this.visible = true;
  this.selectedItem = { ...item };
  this.oldSelectedItem = { ...item };

  const resetAttributes = () => {
    for (let i = 1; i <= 3; i++) {
      const attributeName = `attribute${i}`;
      this.selectedItem[attributeName] = this.selectedItem[attributeName] ? this.selectedItem[attributeName] : null;
    }
  };

  if (this._kpiAttributeTypeCode === "INT") {
    if (this._engagementKpiDataCollection.inputAttributeValueType == 1) {
      this.kpiDataCollectionFrom.get('_calculatedKPI')?.enable();
    }

    this.selectedItem.attribute1 = this.selectedItem.attribute1 || "";
    this.selectedItem.attribute2 = this.selectedItem.attribute2 || "";
    this.selectedItem.attribute3 = this.selectedItem.attribute3 || "";
    
    this.selectedItem.calculatedKPI = (this.selectedItem.attribute1 === "" && this.selectedItem.calculatedKPI == null) ? "" : this.selectedItem.calculatedKPI;
    
    this._engagementKpiDataCollection = this.selectedItem;
    resetAttributes();
    
    this.kpiDataCollectionFrom.get('_attribute1')?.enable();
    this.kpiDataCollectionFrom.get('_attribute2')?.enable();

    this.isAttributeValueSelected = this._engagementKpiDataCollection.inputAttributeValueType == 2;
  }

  if (this._kpiAttributeTypeCode === "DTE") {
    this.selectedItem.attribute1 = this.selectedItem.attribute1 ? new Date(this.selectedItem.attribute1) : "";
    this.selectedItem.attribute2 = this.selectedItem.attribute2 ? new Date(this.selectedItem.attribute2) : "";
    this.selectedItem.attribute3 = this.selectedItem.attribute3 ? new Date(this.selectedItem.attribute3) : "";

    this.selectedItem.calculatedKPI = (this.selectedItem.attribute1 === "" && this.selectedItem.calculatedKPI == null) ? "" : this.selectedItem.calculatedKPI;
    
    this._engagementKpiDataCollection = this.selectedItem;

    if (this._engagementKpiDataCollection.inputAttributeValueType == 1) {
      this.kpiDataCollectionFrom.get('_attribute1')?.disable();
      this.kpiDataCollectionFrom.get('_attribute2')?.disable();
      this.kpiDataCollectionFrom.get('_attribute3')?.disable();
      resetAttributes();
    } else {
      this.kpiDataCollectionFrom.get('_attribute1')?.disable();
      this.kpiDataCollectionFrom.get('_attribute2')?.disable();
      this.kpiDataCollectionFrom.get('_attribute3')?.disable();
      this.kpiDataCollectionFrom.get('_calculatedKPI')?.disable();
      resetAttributes();
    }
  }

  if (this._kpiAttributeTypeCode === "NA") {
    this.selectedItem.attribute1 = this.selectedItem.attribute1 || "";
    this.selectedItem.attribute2 = this.selectedItem.attribute2 || "";
    this.selectedItem.attribute3 = this.selectedItem.attribute3 || "";

    this.selectedItem.calculatedKPI = this.selectedItem.calculatedKPI == null ? "" : this.selectedItem.calculatedKPI;
    
    this._engagementKpiDataCollection = this.selectedItem;
    this.isAttributeValueSelected = false;
  }
}
isSaveKpiDataCollectionPopupBtnDisable = false;
oldKpiDataCollection:any;
saveKpiDataCollection() {
  this.oldKpiDataCollection=this._engagementKpiDataCollections;
  if(this._engagementKpiDataCollection.attribute1=="")
    {
      this._engagementKpiDataCollection.attribute1="0";
    }
    if(this._engagementKpiDataCollection.attribute2=="")
    {
      this._engagementKpiDataCollection.attribute2="0";
    }
    if(this._engagementKpiDataCollection.attribute3=="")
    {
      this._engagementKpiDataCollection.attribute3="0";
    }
  this.isSaveKpiDataCollectionPopupBtnDisable = true;
  setTimeout(() => {
    this.isSaveKpiDataCollectionPopupBtnDisable = false;
  }, 3000);
  if(this._engagementKpiDataCollection.inputAttributeValueType==1)
  {
    this.kpiDataCollectionFrom.controls['_attribute1'].setErrors(null);
    this.kpiDataCollectionFrom.controls['_attribute2'].setErrors(null);
    this.kpiDataCollectionFrom.controls['_attribute3'].setErrors(null);
  }
  else if(this._engagementKpiDataCollection.inputAttributeValueType==2){
    this.kpiDataCollectionFrom.controls['_calculatedKPI'].setErrors(null);
    if(this._readOnlyAttribute1=='')
    {
      this.kpiDataCollectionFrom.controls['_attribute1'].setErrors(null);
    }
    if(this._readOnlyAttribute2=='')
    {
      this.kpiDataCollectionFrom.controls['_attribute2'].setErrors(null);
    }
  }
  else
  {
    this.kpiDataCollectionFrom.controls['_attribute1'].setErrors(null);
    this.kpiDataCollectionFrom.controls['_attribute2'].setErrors(null);
    this.kpiDataCollectionFrom.controls['_attribute3'].setErrors(null);
    this.kpiDataCollectionFrom.controls['_calculatedKPI'].setErrors(null);
  }
  this.dataCollectionSubmitted = true;
  if (this.kpiDataCollectionFrom.valid) {
      if(this._engagementKpiDataCollection.inputAttributeValueType==2){
    this.isBtnClicked=true;
    this.CalculateKPI();
    }
    else{
      this.saveLocalDataCollection();
    }
    
}
}
saveLocalDataCollection(){
  const KpiDataCollectionIndex = this._engagementKpiDataCollections.findIndex(item => item.id === this.oldSelectedItem.id);
  if (KpiDataCollectionIndex !== -1) {
    if(this._kpiAttributeTypeCode=="INT")
    {
    this.selectedItem.attribute1=this._engagementKpiDataCollection.attribute1;
    this.selectedItem.attribute2=this._engagementKpiDataCollection.attribute2;
    this.selectedItem.attribute3=this._engagementKpiDataCollection.attribute3;
    }
    if(this._kpiAttributeTypeCode=="DTE"){
      this.selectedItem.attribute1=this._datePipe.transform(this._engagementKpiDataCollection.attribute1, "dd-MMM-yyyy");
      this.selectedItem.attribute2=this._datePipe.transform(this._engagementKpiDataCollection.attribute2, "dd-MMM-yyyy");
      this.selectedItem.attribute3=this._datePipe.transform(this._engagementKpiDataCollection.attribute3, "dd-MMM-yyyy");
    }
      this.selectedItem.calculatedKPI=this._engagementKpiDataCollection.calculatedKPI==""?null:this._engagementKpiDataCollection.calculatedKPI;
    this.selectedItem.health=this._engagementKpiDataCollection.health;
    this.selectedItem.kpiAttributeTypeId=this._kpiAttributeTypeId;
    this._engagementKpiDataCollections[KpiDataCollectionIndex] = { ...this.selectedItem };

    const indexKpiDataCollection = this.editedKPIDataCollection.findIndex((obj: { id: any; }) => obj.id === this.selectedItem.id);

    if (indexKpiDataCollection !== -1) {
      // If newObj already exists, remove the old object at the index
      this.editedKPIDataCollection.splice(indexKpiDataCollection, 1);
    }
    
    // Push the new object into the array
    this.editedKPIDataCollection.push(this.selectedItem);
    
    this.selectedItem = null; 
    this.oldSelectedItem=null;
  }
  this.visible = false;
  this.messageService.add({
    key: 'tc',
    severity: 'success',
    summary: 'KPI data collection has been saved',
    detail: '',
});
}
calculatedFieldValue:any;
isBtnClicked=false;
CalculateKPI(){
  this._engagementKpiDataCollection.attribute1=this._engagementKpiDataCollection.attribute1?.toString();
  this._engagementKpiDataCollection.attribute2=this._engagementKpiDataCollection.attribute2?.toString();
  if(this._engagementKpiDataCollection.attribute1!="" && this._engagementKpiDataCollection.attribute2!="" && this._engagementKpiDataCollection.attribute1!=null && this._engagementKpiDataCollection.attribute2!=null)
  {
   this.loaderService.showLoader();

  this._contractKPIService.CalculateKPI_New(this._formulaeId,this._engagementKpiDataCollection.attribute1,this._engagementKpiDataCollection.attribute2,this._engagementKpiDataCollection.attribute3,'EngagementKPI',this._kpiAttributeTypeCode)
   .then(calculatedKPI => {

    if(calculatedKPI.toString().toLocaleLowerCase()!='infinity')
   {
    if(this.isBtnClicked){
      this.isBtnClicked=false;
     this._engagementKpiDataCollection.calculatedKPI=calculatedKPI;
      this.saveLocalDataCollection();
    }
    else{
      this._engagementKpiDataCollection.calculatedKPI=calculatedKPI;
     }
    }
    else{
      this.isBtnClicked=false;
      this.messageService.add({ key: 'tc', life: 5000, severity: 'error', summary: 'The values you entered for the attributes are resulting in an invalid output. Please re-enter the attribute values.', detail: '' });
    }
  
       this.loaderService.hideLoader();
   })
   .catch(error => {
    this._engagementKpiDataCollection.calculatedKPI=0;
   });
  
 }
  this.loaderService.hidefinalLoader();
  setTimeout(() => {
   this.finalClear();
 },2000);
 
}
finalClear(){
 if((this._engagementKpiDataCollection.attribute1== "" || this._engagementKpiDataCollection.attribute2=="") && this._engagementKpiDataCollection.inputAttributeValueType==2)
 {
  this._engagementKpiDataCollection.calculatedKPI="";
 }
}
cancelDialog() {
    
  this.visible = false;
  this.messageService.add({
    key: 'tc',
    severity: 'info',
    summary: 'KPI data collection has not been saved',
    detail: '',
});
}
onNumericInputpopUp(event: Event, _controlname: string) {
  const inputElement = event.target as HTMLInputElement;
  let sanitizedValue = inputElement.value.replace(/[^\d.]/g, ''); // Allow only digits and a single dot
  const decimalParts = sanitizedValue.split('.'); // Split value by dot

  // If there are more than 2 decimal parts, keep only the first two parts
  if (decimalParts.length > 1) {
    sanitizedValue = decimalParts[0] + '.' + (decimalParts[1] || '').slice(0, 2); // Keep only up to two decimal places
  }

  inputElement.value = sanitizedValue;
  this.kpiDataCollectionFrom.controls[_controlname].setValue(sanitizedValue);
}

btnCalculateKPI(){
  
    // Attribute3 should be considered here when attribute3 will be included in the business case
      if(this._engagementKpiDataCollection.attribute1 != '' && this._engagementKpiDataCollection.attribute2 != ""){
    this.CalculateKPI();
    }
    else
    {
      this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Enter the required Attribute values', detail: '' });
    }
 
}
onAttributeDateSelection(attributeDate:any,type:number){
  if(this._engagementKpiDataCollection.calculatedKPI=="")
  {
    this.dataCollectionSubmitted =false
  }
  if(this._engagementKpiDataCollection.attribute1!="" && this._engagementKpiDataCollection.attribute2!="" && this._engagementKpiDataCollection.attribute1!=null && this._engagementKpiDataCollection.attribute2!=null && !this.dataCollectionSubmitted)
   {
    this.loaderService.showLoader();
    this._contractKPIService.CalculateKPI_New(this._formulaeId,this._engagementKpiDataCollection.attribute1,this._engagementKpiDataCollection.attribute2,this._engagementKpiDataCollection.attribute3,'EngagementKPI',this._kpiAttributeTypeCode)
    .then(calculatedKPI => {
      this._engagementKpiDataCollection.calculatedKPI=calculatedKPI;
        this.loaderService.hideLoader();
    })
    .catch(error => {
      this._engagementKpiDataCollection.calculatedKPI=0;
    });
   }
   
}
  //Data collection SubGridOperations
  onRadioButtonClick(KPIoption: string) {
 
    
    if(KPIoption=='2'){
      this.isAttributeValueSelected=true;
      }
      else{
        this.isAttributeValueSelected=false;
      }

    this.dataCollectionSubmitted = false;
    if(KPIoption=='1')
    {
    this._engagementKpiDataCollection.attribute1="";
    this._engagementKpiDataCollection.attribute2="";
    this._engagementKpiDataCollection.attribute3="";
    if(this._readOnlyFormulaName!='N/A')
    this._engagementKpiDataCollection.calculatedKPI="";
    this.kpiDataCollectionFrom.controls['_attribute1'].setErrors(null);
    this.kpiDataCollectionFrom.controls['_attribute2'].setErrors(null);
    this.kpiDataCollectionFrom.controls['_attribute3'].setErrors(null);
    this.kpiDataCollectionFrom.controls['_calculatedKPI'].setErrors({required: true});
    this.kpiDataCollectionFrom.get('_attribute1')?.disable();
    this.kpiDataCollectionFrom.get('_attribute2')?.disable();
    this.kpiDataCollectionFrom.get('_attribute3')?.disable();
    this.kpiDataCollectionFrom.get('_calculatedKPI')?.enable();
    this._engagementKpiDataCollection.attribute1=null;
    this._engagementKpiDataCollection.attribute2=null;
    this._engagementKpiDataCollection.attribute3=null;
    
 
    }
    else if(KPIoption=='2')
    {
      this.kpiDataCollectionFrom.get('_attribute1')?.enable();
      this.kpiDataCollectionFrom.get('_attribute2')?.enable();
      this._engagementKpiDataCollection.attribute1=null;
      this._engagementKpiDataCollection.attribute2=null;
      this._engagementKpiDataCollection.attribute3=null;
      if(this._readOnlyFormulaName!='N/A')
      this._engagementKpiDataCollection.calculatedKPI="";
      if(this._readOnlyAttribute3!=''  && this._readOnlyFormulaName!='N/A')
      {
        this.kpiDataCollectionFrom.controls['_attribute3'].setErrors({required: true});
        this.kpiDataCollectionFrom.get('_attribute3')?.enable();
      }
      else{
        this.kpiDataCollectionFrom.get('_attribute3')?.disable();
      }
      if(this._readOnlyAttribute1!=''  && this._readOnlyFormulaName!='N/A')
      {
        this.kpiDataCollectionFrom.controls['_attribute1'].setErrors({required: true});
      }
      if(this._readOnlyAttribute2!=''  && this._readOnlyFormulaName!='N/A')
      {
        this.kpiDataCollectionFrom.controls['_attribute2'].setErrors({required: true});
      }
    }
    else{
      this._engagementKpiDataCollection.attribute1="";
      this._engagementKpiDataCollection.attribute2="";
      this._engagementKpiDataCollection.attribute3="";
      if(this._readOnlyFormulaName!='N/A')
      this._engagementKpiDataCollection.calculatedKPI="";
      this.kpiDataCollectionFrom.controls['_attribute1'].setErrors(null);
      this.kpiDataCollectionFrom.controls['_attribute2'].setErrors(null);
      this.kpiDataCollectionFrom.controls['_attribute3'].setErrors(null);
      this.kpiDataCollectionFrom.controls['_calculatedKPI'].setErrors(null);
      this.kpiDataCollectionFrom.get('_attribute1')?.disable();
      this.kpiDataCollectionFrom.get('_attribute2')?.disable();
      this.kpiDataCollectionFrom.get('_attribute3')?.disable();
      this.kpiDataCollectionFrom.get('_calculatedKPI')?.disable();
      this._engagementKpiDataCollection.attribute1=null;
      this._engagementKpiDataCollection.attribute2=null;
      this._engagementKpiDataCollection.attribute3=null;
    }
  }
  isClosedStatus(statusMappingId: number): boolean {
    const status = this.actionStatus.find((s: { id: number; }) => s.id === statusMappingId);
    return status ? status.code === "CLD" : false;
  }
  oldKpiData: any;
  newKpiData: any;
  mitigationDataCollection: any[] = [];
  newMitigation:any;
 async compareMitigations() {
 this.mitigationDataCollection=[];
   this.oldKpiData = JSON.parse(this.engagementKPIDetailBeforeUpdate);
    this.newKpiData=this._engagementKpi;
    const oldDataCollectionList = this.oldKpiData.dataCollectionList;
    const newDataCollectionList = this.newKpiData?.dataCollectionList;
    oldDataCollectionList.forEach((oldData: { engagementKpiMitigationActionsList: any; dataCollectionDate: any; }, index: string | number) => {
     const newData = newDataCollectionList ? newDataCollectionList[index] : null;
      if (oldData && newData) {
        const oldMitigations = oldData.engagementKpiMitigationActionsList;
        const newMitigations = newData.engagementKpiMitigationActionsList;
          if(oldMitigations.length==0 && newMitigations.length!=0 )
          {
            newMitigations.forEach((newitigation: any) => {
              this.mitigationDataCollection.push({
                'kpiName':this.newKpiData.kpiName,
                'engagementName':this.EngagementName,
                'mitigationactionstatus':this.actionStatus?.find((aa:any) => aa.id === newitigation?.statusMappingId)?.name,
                'engagementId':this.engagementId,
                'contractTitle':'',
                 "Datafor": newData.dataCollectionFrequencyValue,
                 "kpiValue":newData.calculatedKPI === null ? '--':newData.calculatedKPI+''+this._readOnlySybmolSteadyStateThreshold,
                 "Actionfieldvalue": newitigation.action,
                 'contractId':0,
                 'id':newitigation.id,
                 'ownerEmail':this.getEmailById(newitigation.actionOwnerId),
               });
            });
          }
          else
        {
         const filterNewMitigations = newMitigations?.filter((t: { id: number; })=>t.id==0);
         filterNewMitigations.forEach((newitigation: any) => {
          this.mitigationDataCollection.push({
            'kpiName':this.newKpiData.kpiName,
            'engagementName':this.EngagementName,
            'mitigationactionstatus':this.actionStatus?.find((aa:any) => aa.id === newitigation?.statusMappingId)?.name,
            'engagementId':this.engagementId,
            'contractTitle':'',
             "Datafor": newData.dataCollectionFrequencyValue,
             "kpiValue":newData.calculatedKPI === null ? '--':newData.calculatedKPI+''+this._readOnlySybmolSteadyStateThreshold,
             "Actionfieldvalue": newitigation.action,
             'contractId':'',
             'id':newitigation.id,
             'ownerEmail':this.getEmailById(newitigation.actionOwnerId),
           });
        });

        const newMitigationsMap = new Map(newMitigations.map((mitigation: any) => [mitigation.id, mitigation]));
        oldMitigations.forEach((oldMitigation: any) => {
          this.newMitigation = newMitigationsMap.get(oldMitigation.id);
           if (this.newMitigation) {
           const _oldStatus = this.actionStatus?.find((aa:any) => aa.id === oldMitigation.statusMappingId)?.code;
           const _newStatus = this.actionStatus?.find((aa:any) => aa.id === this.newMitigation?.statusMappingId)?.code;
             if (_oldStatus=='OPN' && _newStatus=='CLD') {
              this.mitigationDataCollection.push({
                'kpiName':this.newKpiData.kpiName,
                'engagementName':this.EngagementName,
                'mitigationactionstatus':this.actionStatus?.find((aa:any) => aa.id === this.newMitigation?.statusMappingId)?.name,
                'engagementId':this.engagementId,
                'contractTitle':'',
                 "Datafor": newData.dataCollectionFrequencyValue,
                 "kpiValue":newData.calculatedKPI === null ? '--':newData.calculatedKPI+''+this._readOnlySybmolSteadyStateThreshold,
                 "Actionfieldvalue": this.newMitigation.action,
                 'contractId':'',
                 'id':this.newMitigation.id,
                 'ownerEmail':this.getEmailById(this.newMitigation.actionOwnerId),
               });
             }
           };
           
         });
        }
       }
     });
     if(this.mitigationDataCollection.length>0)
     {
     await this.notificationService.KpiMitigationNotification(0,this.mitigationDataCollection,this.engagementId,this.sponsorId,this.supplierId)
     }
  }
  SetFrequencyDataCollection(dataCollectionList: any[], frequency: any) {
    dataCollectionList.forEach(item => {
      item.dataCollectionFrequencyValue = this.commonService.getFrequencyFromDate(item.dataCollectionDate, frequency);
    });
  }
  getEmailById(id: number): string | undefined {
    const contact = this.combinedContacts.find(c => c.id === id);
    if(contact!=undefined)
    return contact?.email;
    else
    return "";
  }
  compareModels() {
    if(this._engagementKpi.hyperCareStartDate){
      this._engagementKpi.hyperCareStartDate= new Date(this._engagementKpi.hyperCareStartDate).toISOString();
    }
    if(this._engagementKpi.hyperCareEndDate){
      this._engagementKpi.hyperCareEndDate= new Date(this._engagementKpi.hyperCareEndDate).toISOString();
    }    
    this._engagementKpi.kpiStartDate= new Date(this._engagementKpi.kpiStartDate).toISOString();
    this._engagementKpi.kpiEndDate= new Date(this._engagementKpi.kpiEndDate).toISOString();

    this.engagementKPIDetailBeforeUpdate=JSON.parse(this.engagementKPIDetailBeforeUpdate);
    this.engagementKPIDetailBeforeUpdate.engagementId=this.engagementKPIDetailBeforeUpdate.engagementId.toString();
    this.engagementKPIDetailBeforeUpdate=JSON.stringify(this.engagementKPIDetailBeforeUpdate);

    this.changedEngagementKPIBasicInfo = this.auditLogService.compareModels(JSON.parse(this.engagementKPIDetailBeforeUpdate),this._engagementKpi,this.auditEntities.EngagementKPIs,this.auditIdentifierDetails.EngagementKPIs,this.auditOperations.Update,this.auditModule.GovernanceService);
  }
}
