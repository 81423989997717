import { Component, HostListener } from '@angular/core';
import * as jQuery from 'jquery';
import { LoaderService } from './Loader/loader.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthCommonService } from './shared/Authentication/auth.service';
import { Subscription } from 'rxjs';
import { AuthGuard } from './shared/Authentication/auth.guard';
import { Privileges } from './shared/constants/privileges.constants';
import { PrivilegeService } from './Reusable/privilege.service';
import { PlatformLocation } from '@angular/common';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  hideClasses: boolean = false;
  hideClassess: boolean = false;
  routerEvents: any;
  currentPage:string="";
  showMenu=true;
  showHeader: boolean = false;
  showSideMenu :boolean=false;


  
  private roles: string[] = [];
  isLoggedIn = false;
  showAdminBoard = false;
  showModeratorBoard = false;
  username?: string;
  paramSubscription: Subscription | undefined;
  previousUrl: any;
  userAdminAccessPrivilege:boolean=false;
  
  // browser back button click event logic start //
  @HostListener('window:popstate', ['$event'])
  
  async onBackButtonEvent(event: Event) {
    // Reload the page

    event.preventDefault(); // Prevents the default browser behavior
    let authToken =  this.authCommonService.getCookieValue('LOGIN_TOKEN');
    await this.authCommonService.isTokenValid(authToken);
    if (authToken.length<=0){
      this.authCommonService.logOutUser();
    }
  }

  @HostListener('window:popstate', ['$event'])
  async onPopState(event: PopStateEvent) {
    // Reload the page 
  
    let authToken =  this.authCommonService.getCookieValue('LOGIN_TOKEN');
    await this.authCommonService.isTokenValid(authToken);
    if (authToken.length<=0){
      this.authCommonService.logOutUser();
    }
  }
  // browser back button click event logic end //
  constructor(private platformLocation: PlatformLocation,public loaderService: LoaderService, private router: Router,private authCommonService: AuthCommonService,private route: ActivatedRoute, private authGuard:AuthGuard,private privilegeService: PrivilegeService) {  
    this.routerEvents = this.router.events.subscribe(
      (event:any)=>{
        if(event instanceof NavigationEnd){
         this.currentPage =event.url;
          if( this.currentPage=='/login' || this.currentPage=='/Forgotpassword')  
          {
            this.hideClasses = true;
            this.hideClassess = true;
              this.showHeader=false;
            this.showSideMenu=false;
          }
          else{
            this.showHeader=true;
            this.showSideMenu=true;
            this.hideClasses = false;
            this.hideClassess = false;
            }
        }
      }
    )
  }


   async logOut(): Promise<void> {   
     await this.authCommonService.logOutUser().then(response => {
       let loginURL = this.authCommonService._loginPortalAppURl+'?redirect='+this.authCommonService._kontractProAppURl;
       window.location.href = loginURL;
     })
     .catch(err => {
       let loginURL = this.authCommonService._loginPortalAppURl+'?redirect='+this.authCommonService._kontractProAppURl;
       window.location.href = loginURL;
     });;
   }

   async handleBrowserBackButton() {
    // Write your custom logic here
    // For example, you can navigate to a specific route or perform any other action.

    let authToken =  this.authCommonService.getCookieValue('LOGIN_TOKEN');
    await this.authCommonService.isTokenValid(authToken);

    if (authToken.length<=0){
      this.authCommonService.logOutUser();
    }
  }
  
  title = 'SupplierManagementSystem.UI';
  async ngOnInit(){
   
   // window.onpopstate = function (event) {
      // Prevent navigation
   //   window.history.forward();
   // };
   //this.handleBrowserBackButton();

    // browser back button click event logic start //
    window.addEventListener('popstate', () => {

      // Call your custom function here
      this.handleBrowserBackButton();
    });

    this.platformLocation.onPopState(async () => {


      // Handle browser back button event here
      // You can navigate to a specific page or trigger custom logic
      let authToken =  this.authCommonService.getCookieValue('LOGIN_TOKEN');
     await this.authCommonService.isTokenValid(authToken);
    if (authToken.length<=0){
        this.authCommonService.logOutUser();
         location.reload();
      }

    });
   // browser back button click event logic end //
    this.isLoggedIn = await this.authCommonService.isUserLoggedIn();
    if (this.isLoggedIn) {     
      console.log("User LoggedIn - " + this.isLoggedIn);
    }
    else {
          //const url = this.router.serializeUrl(
                //  this.router.createUrlTree(['/example'])
          //  );
          // Redirect to your login application URL
      //var loginURL = this.authCommonService._loginPortalAppURl + '?redirect=' + this._kontractProAppURl;
      //   window.location.href = loginURL; 
          this.loaderService.hidefinalLoader();
    }
    // logic menu slider
    let menutoggle = $(".nav-toggleicon");
    let bodyelement = $("body");
    menutoggle.click(function(){
      $('li').removeClass('main_menu_active');
      if(bodyelement.hasClass("menu-mini")){
        bodyelement.removeClass("menu-mini");
      }
      else{
        bodyelement.addClass("menu-mini");
      }
    })
    // User Menu 
    $(document).ready(function(){
      // Toggle dropdown when clicking on the dropdown toggle button
      $('.user-dropdown').click(function(event) {  
          let menubtn = $(this);
          if(menubtn.hasClass("open")){
              menubtn.removeClass("open");
          } else {
              menubtn.addClass("open");
          }
          event.stopPropagation(); // Prevents the body click event from firing
      });
  
      // Hide dropdown when clicking outside of it
      $(document).click(function(event) { 
          // Check if the clicked element is not within the dropdown
          if (!$(event.target).closest('.user-dropdown').length) {
              // If not, close the dropdown
              $('.user-dropdown').removeClass("open");
          }        
      });
  });
          $(".menu_item").click(function(){
            $(this).toggleClass('open') 
            $(this).siblings('li').removeClass('open');
            $("i", this).toggleClass("icon-down-open");
            $("i", ".menu_item2").removeClass("icon-down-open");
          });
          $(".menu_item2").click(function(){
            $(this).toggleClass('open') 
            $(this).siblings('li').removeClass('open');
            $("i", this).toggleClass("icon-down-open");
            $("i", ".menu_item").removeClass("icon-down-open");
          });
          $(document).ready(function() {
            $("a.sub_menu").click(function(event) {
              event.preventDefault(); 
              $("i.icon-right-open", this).toggleClass("icon-down-open");
            });
          });
          
    $(".menu_item").click(function(){   
        $(this).parent('li').toggleClass('main_menu_active')  
        $(this).parent('li').siblings().removeClass('main_menu_active')  
        $(".sub_menu").removeClass('sub_menu_active')  
        
      });
      $(".menu_item2").click(function(){   
        $(this).parent('li').toggleClass('main_menu_active')  
        $(this).parent('li').siblings().removeClass('main_menu_active')  
        $(".sub_menu").removeClass('sub_menu_active')  
        
      });

    $(".sub_menu").click(function(){  
      $("li").removeClass("main_menu_active");
        $(this).closest('.has-submenu').addClass('main_menu_active')  
        $(this).parent('li').toggleClass('sub_menu_active')  
      });
      

    $("li.hoverIconHide").mouseover(function(){
      $('.menu-toggle').css('z-index','-1');
    })
    $("li.hoverIconHide").mouseout(function(){
      $('.menu-toggle').removeAttr('style');
    })
    $(".parent").click(function(){  
      // $(this).toggleClass('opens') 
      // $(this).siblings('li').removeClass('opens') 
    });

    $(".submenuClick").click(function(){  
      $(this).toggleClass('subactive') 
      // $().siblings('li').removeClass('open') 
    });
    this.BindPrivileges();
  }
  BindPrivileges(){
    const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);

     this.userAdminAccessPrivilege = hasPermission(Privileges.AccessAdmincenter);

    }
  openACInNewWindow(event: MouseEvent) {
    event.preventDefault();
    let acAppURL = this.authCommonService._adminCenterAppURl;
    window.open(acAppURL, '_blank','width=800,height=600'); // Redirect to your Admin Center application URL
  }

  ngOnDestroy(): void {
    this.routerEvents.unsubscribe();
    // Unsubscribe to avoid memory leak
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }

    window.removeEventListener('popstate', this.handleBrowserBackButton);
  }
  Profile()
  {
  
    this.router.navigate(['/UserProfile']);
  }

}
