<div class="card-pad">
  <div class="fr-flex__container">
      <div class="fr-flex__col-8">
          <h3>Edit Meeting</h3>
      </div>
  </div>
 <form [formGroup]="contractMeetingForm">
      <div class="fr-flex__container m-t-40">
          <div class="fr-flex__col-4">
              <div class="fr-form__group"> 
                  <label> Title <em> * </em> <span class="max-length"> {{contractMeetingDetails.Title?.length || 0}}/50 </span></label> 
          <input type="text" class="form-control" pInputText [pTooltip]="contractMeetingDetails.Title!.length > 40 ? contractMeetingDetails.Title!: ''" placeholder=""  [maxlength]="50" [(ngModel)]="contractMeetingDetails.Title" formControlName="addMeetingTitle" [minlength]="3" trimSpace/> 
          <small class="p-error" *ngIf="contractMeetingForm?.get('addMeetingTitle')?.touched && contractMeetingForm?.get('addMeetingTitle')?.hasError('required')">This field is required.</small>
          <small class="p-error" *ngIf="contractMeetingForm?.get('addMeetingTitle')?.touched && contractMeetingForm?.get('addMeetingTitle')?.hasError('maxlength')">Maximum length is 50 characters.</small>
          <small class="p-error" *ngIf="contractMeetingForm?.get('addMeetingTitle')?.touched && contractMeetingForm.get('addMeetingTitle')?.hasError('onlySpaces')">Only spaces not allowed.</small>
          <small class="p-error" *ngIf="contractMeetingForm?.get('addMeetingTitle')?.touched && contractMeetingForm?.get('addMeetingTitle')?.hasError('minlength')">Minimum length is 3 characters.</small>

              </div>
          </div>
          <div class="fr-flex__col-4">
              <div class="fr-form__group"> <label> Organizer <em> * </em>
                <a class="btn btn-primary-link pull-right line-height-zero" (click)="openContractModel('organizer',0)" >Add New</a>
                </label>
                  <p-dropdown appendTo="body" [filter]="true" [options]="organizerOptions" [(ngModel)]="contractMeetingDetails.Organizer" formControlName="addMeetingOrganizer"></p-dropdown>
              </div>
          </div>
          <div class="fr-flex__col-4">
              <div class="fr-form__group"> <label> Recurring <em> * </em></label>
                  <p-dropdown appendTo="body" [filter]="true" class="read-only-dropdown" [options]="options" optionLabel="name" optionValue="value" [(ngModel)]="contractMeetingDetails.Recurring" formControlName="addMeetingRecurring"></p-dropdown>
              </div>
          </div>
      </div>
   
      <div class="fr-flex__container">
          <div class="fr-flex__col-12">
              <div class="fr-form__group">
                  <label> Agenda/Description <em> * </em> <span class="max-length"> {{contractMeetingDetails.Agenda?.length || 0}}/300 </span>  </label> 
                  <textarea  class="form-control" [maxlength]="300" pInputTextarea [(ngModel)]="contractMeetingDetails.Agenda" formControlName="addMeetingAgenda" trimSpace ></textarea>
                  <small class="p-error" *ngIf="contractMeetingForm?.get('addMeetingAgenda')?.touched && contractMeetingForm?.get('addMeetingAgenda')?.hasError('required')">This field is required.</small> 
              </div>
          </div>
      </div>

      <div class="fr-flex__container">
          <div class="fr-flex__col-3">
             <div class="fr-form__view">
                <label> Added By</label>
                <span> <em> {{MeetingCreatedByUserName}} </em></span>
             </div>
          </div>
          <div class="fr-flex__col-3">
             <div class="fr-form__view">
                <label> Added Date & Time </label>
                <span> <em>   {{contractMeetingDetails.CreatedDate | date: _dateformart.DateTimeWithZone  }} {{_dateformart.DisplayUTC}}</em></span>
             </div>
          </div>
          <div class="fr-flex__col-3">
             <div class="fr-form__view">
                <label> Modified By</label>
                <span  *ngIf="MeetingUpdatedByUserName!=''"> <em> {{MeetingUpdatedByUserName}}</em></span>
                <span  *ngIf="MeetingUpdatedByUserName==''"> <em> --</em></span>
             </div>
          </div>
          <div class="fr-flex__col-3" >
             <div class="fr-form__view">
                <label> Modified Date & Time </label>
                <span *ngIf="contractMeetingDetails?.UpdatedDate!=null"> <em> {{contractMeetingDetails.UpdatedDate | date: _dateformart.DateTimeWithZone }} {{_dateformart.DisplayUTC}} </em></span>
                <span *ngIf="contractMeetingDetails?.UpdatedDate==null"> <em> --</em></span>
               </div>
          </div>
       </div>
 </form>
      <div class="fr-flex__container">
          <div class="fr-flex__col-8">
              <h3> Occurrence </h3>
          </div>
          <div class="fr-flex__col-4">
              <a (click)="AddNewOccurrences()" routerLinkActive="active" class="btn btn-primary pull-right"> Add Occurrence
              </a>
          </div>
      </div>
  <!-- </form> -->
  <p-dialog appendTo="body" [header]="addOccurrenceDialog" [(visible)]="displayAddOccurrencesPopUP" [modal]="true"
      [style]="{ width: '80vw' }" [draggable]="false" [resizable]="false" [closable]="false">
      <ng-template pTemplate="content">
          <form [formGroup]="contractAddoccurrencesPopUpForm">
              <div class="fr-flex__container">
                  <div class="fr-flex__col-4">
                      <div class="fr-form__group">
                          <label> Date <em> * </em></label>
                          <p-calendar appendTo="body"  [(ngModel)]="_contractMeetingOccurrences.OccurrenceDate" [showIcon]="true"
                              [dateFormat]=_dateformart.CalenderDateFormat formControlName="addOcurrneceMeetingOccurenceDate" [required]="true" [readonlyInput]="true"></p-calendar>
                          <small class="p-error" *ngIf="submitted && contractAddoccurrencesPopUpForm?.get('addOcurrneceMeetingOccurenceDate')?.hasError('required')">This field is required.</small>
                      </div>
                  </div>
                  
                  <div class="fr-flex__col-8">
                      <div class="fr-form__group">
                          <label> Attachments <i pInputText pTooltip="Maximum 3 Attachments allowed."
                                  tooltipPosition="top" class="pi pi-info-circle"> </i></label>
                          <input type="file" accept=".pdf, .xls, .xlsx,.doc,.docx" multiple
                              (change)="onFileChange($event)" class="form-control"
                              formControlName="addOcurrneceMeetingFileSource" #fileInput style="display:none" trimSpace />
                          <button type="button" (click)="fileInput.click()" class="form-control width50 m-b-10" [disabled]="isAddEditOccurrenceFileUploadButtonDisabled">Upload Files</button>
                          <div class="display-inline-block" *ngFor="let file of uploadedFiles">
                              <a class="form-list-active">
                                  <span [pTooltip]="file.name.length > 45 ? file.name : ''" tooltipPosition="top"> {{ file.name | truncate:45 }} </span>
                                  <i (click)="removeFile(file)" pInputText pTooltip="Delete" tooltipPosition="top" class="pi pi pi-trash"></i>
                              </a>
                              
                              
                          </div>
                          
                      </div>

                  </div>
              </div>
              <div class="fr-flex__container">
                  <div class="fr-flex__col-4">
                      <div class="fr-form__group"> <label> Attendees
                        <span class="max-length-multiple-selected"> </span>  <a class="btn btn-primary-link pull-right line-height-zero" (click)="openContractModel('attendees',0)" >Add New</a> </label> 
                          <p-multiSelect appendTo="body" [options]="_attendees" optionLabel="name" 
                              [(ngModel)]="_contractMeetingAttendees.Attendees" placeholder="Select Attendees"
                              display="chip" formControlName="addOcurrneceMeetingAttendees"></p-multiSelect>
                      </div>
                  </div>
                  <div class="fr-flex__col-4">
                      <div class="fr-form__group"> <label> Absentees 
                        <span class="max-length-multiple-selected"> </span>  <a class="btn btn-primary-link pull-right line-height-zero" (click)="openContractModel('absentees',0)" >Add New</a>
                      </label>
                          <p-multiSelect appendTo="body" class="form-control" [options]="_absentees" optionLabel="name" 
                              [(ngModel)]="_contractMeetingAbsentees.Absentees" placeholder="Select Absentees"
                              display="chip" formControlName="addOcurrneceMeetingAbsentees"></p-multiSelect>
                      </div>
                  </div>
              </div>
              <div class="fr-flex__container">
                  <div class="fr-flex__col-12">
                      <div class="fr-form__group"> <label> Meeting Notes </label> <textarea class="form-control"
                              [maxlength]="500" pInputTextarea [(ngModel)]="_contractMeetingOccurrences.MeetingNotes"
                              formControlName="addOcurrneceMeetingNotes" trimSpace ></textarea>
                      </div>
                  </div>
              </div>
              <div class="fr-flex__container">
                  <div class="fr-flex__col-12">
                      <div class="fr-form__group"> <label> Decisions </label> <textarea class="form-control"
                              [maxlength]="500" pInputTextarea [(ngModel)]="_contractMeetingOccurrences.Decisions"
                              formControlName="addOcurrneceMeetingDecisions" trimSpace ></textarea>
                      </div>
                  </div>
              </div>

              <div class="fr-flex__container" *ngIf="isEditTrue">
                  <div class="fr-flex__col-3">
                     <div class="fr-form__view">
                        <label> Added By</label>
                        <span *ngIf="_contractMeetingOccurrences.CreatedDate !=null"> <em>  {{MeetingCreatedByUserName}} </em></span>
                        <span *ngIf="_contractMeetingOccurrences.CreatedDate ==null"> <em> --</em></span>
                     </div>
                  </div>
                  <div class="fr-flex__col-3">
                     <div class="fr-form__view">
                        <label> Added Date & Time </label>
                        <span *ngIf="_contractMeetingOccurrences.CreatedDate !=null"> <em>  {{_contractMeetingOccurrences.CreatedDate | date: _dateformart.DateTimeWithZone}} {{_dateformart.DisplayUTC}}</em></span>
                        <span *ngIf="_contractMeetingOccurrences.CreatedDate ==null"> <em> --</em></span>
                     </div>
                  </div>
                  <div class="fr-flex__col-3" >
                     <div class="fr-form__view">
                        <label> Modified By</label>
                        <span  *ngIf="_contractMeetingOccurrences.UpdatedDate !=null && _contractMeetingOccurrences.UpdatedBy !=null"> <em>  {{MeetingUpdatedByUserName}} </em></span>
                        <span *ngIf="_contractMeetingOccurrences.UpdatedDate ==null"> <em> --</em></span>
                     </div>
                  </div>
                  <div class="fr-flex__col-3" >
                     <div class="fr-form__view">
                        <label> Modified Date & Time </label>
                        <span  *ngIf="_contractMeetingOccurrences.UpdatedDate !=null"> <em>  {{_contractMeetingOccurrences.UpdatedDate | date: _dateformart.DateTimeWithZone}} {{_dateformart.DisplayUTC}} </em></span>
                        <span *ngIf="_contractMeetingOccurrences.UpdatedDate ==null"> <em> --</em></span>
                      </div>
                  </div>
               </div>
             
          </form>
          <div class="fr-flex__container page-title__contract">
              <div class="fr-flex__col-8">
                  <h3>Actions</h3>
              </div>
              <div class="fr-flex__col-4">
                  <a routerLinkActive="active" class="btn btn-primary pull-right" (click)="AddMeetingActions()">
                      Add Action </a>
              </div>
          </div>
   
          <div class="fr-flex__col-12 m-b-20">
            
              <p-table class="primeng-table-custom custom-cells__supplierEditMeeting_action_editOccurrence" [scrollable]="true"
                  [value]="addMeetingActionsData" editMode="row">
                  <ng-template pTemplate="header">
                      <tr>
                          <th id="conEditMeetingRecYesAction">Action<em> * </em> </th>
                          <th id="conEditMeetingRecYesOwner">Owner</th>
                          <th id="conEditMeetingRecYesTargetDate">Target Date</th>
                          <th id="conEditMeetingRecYesStatus">Status <em> * </em> </th>
                          <th id="conEditMeetingRecYesTargetClosureDate">Closure Date</th>
                          <th id="conEditMeetingRecYesRemarks">Remarks</th>
                          <th id="conEditMeetingRecYesDelete">Delete</th>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-addMeetingActionsData let-actionIndex="rowIndex">

                      <tr>
                          <td>
                              <textarea type="text" class="form-control" pInputText
                                  [(ngModel)]="addMeetingActionsData.ActionName" [required]="true"
                                  [maxlength]="300"  #ActionNameInput="ngModel" trimSpace ></textarea>
                                  <div *ngIf="ActionNameInput.errors?.['required'] && submitted && addMeetingActionsData.ActionName== ''" class="text-danger highlight-red">This field is required.</div> 
                          </td>
                          <td>
                            <label class="edit-occurrence__AddNew">
                                <a class="btn btn-primary-link pull-right line-height-zero table_addNew" (click)="openContractModel('owner',actionIndex)" >Add New</a> 
                            </label>
                            <p-dropdown appendTo="body" [filter]="true" class="form-control" [options]="organizerOptions" appendTo="body" pInputText
                                  [(ngModel)]="addMeetingActionsData.Owner" placeholder="Select Owner"  
                                  [style]="{'width':'100%'}" ></p-dropdown>

                                  </td>
                          <td>
                              <p-calendar appendTo="body" class="form-control" [(ngModel)]="addMeetingActionsData.TargetDate"
                                  [showIcon]="true" [dateFormat]=_dateformart.CalenderDateFormat ></p-calendar>
                          </td>
                          <td>
                              <p-dropdown appendTo="body" [filter]="true" class="form-control" [options]="meetingStatus" appendTo="body" placeholder="Select Status" 
                                  optionLabel="name" [style]="{'width':'100%'}"
                                  [(ngModel)]="addMeetingActionsData.StatusMappingId" ></p-dropdown>
                                  <div  *ngIf="submitted && addMeetingActionsData.StatusMappingId ==''" class="text-danger highlight-red">This field is required.                                                                
                                  </div>  
                          </td>
                          <td>
                              <div *ngIf="addMeetingActionsData.StatusMappingId.code == 'CLD'">
                                  <span class="text-danger highlight-red">*</span>    
                              </div>

                              <p-calendar appendTo="body" class="form-control" pInputText
                                  [(ngModel)]="addMeetingActionsData.ClosureDate" [showIcon]="true"
                                  [dateFormat]=_dateformart.CalenderDateFormat #closureDateInput="ngModel" 
                                  [ngClass]="{'highlight-red': addMeetingActionsData.StatusMappingId.code == 'CLD' && closureDateInput.invalid}"></p-calendar>
                              <div
                                  *ngIf="(addMeetingActionsData.StatusMappingId.code == 'CLD' && (closureDateInput.invalid || !addMeetingActionsData.ClosureDate) && (closureDateInput.dirty || closureDateInput.touched))||(addMeetingActionsData.StatusMappingId.code == 'CLD' && closureDateInput.dirty == false&& !addMeetingActionsData.ClosureDate)">
                                  <div class="text-danger highlight-red">Closure date is required</div> 
                              </div>
                          </td>
                          <td>
                              <div *ngIf="addMeetingActionsData.StatusMappingId.code == 'CLD'">
                                  <span class="text-danger highlight-red">*</span>    
                              </div>
                              <textarea type="text" class="form-control" pInputText
                                  [(ngModel)]="addMeetingActionsData.Remarks" #remarksInput="ngModel" [maxlength]="300" trimSpace ></textarea>
                              <div
                                  *ngIf="(addMeetingActionsData.StatusMappingId.code == 'CLD' && (remarksInput.invalid || !addMeetingActionsData.Remarks) && (remarksInput.dirty || remarksInput.touched))||(addMeetingActionsData.StatusMappingId.code == 'CLD' && remarksInput.dirty == false && !addMeetingActionsData.Remarks)">                                
                                  <div class="text-danger highlight-red">This field is required</div> 
                              </div>
                          </td>
                          <td  class="grid-icon-center"><span class="pi pi-trash"
                                  (click)="deleteAction(actionIndex)"></span></td>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                      <tr>
                      <td colspan="7" class="text-center"> No Records</td>
                      </tr>
                      </ng-template>
              </p-table>              
          </div>
      </ng-template>
              
       
      <ng-template pTemplate="footer">
          <div class="fr-flex__container m-b-10 m-t-10">
              <div class="fr-flex__col-12">
                  <button type="button" class="btn btn-primary pull-right" [class.disabled]="isSavePopupBtnDisable" (click)="isSavePopupBtnDisable ? null : SaveOccurrencesButtonClick()"> Save </button>
                  <button type="button" class="btn btn-secondary pull-right"
                      (click)="addOccurrencesPopUpCancelButton()"> Cancel </button>
              </div>
          </div>

      </ng-template>
  </p-dialog>


  <div class="fr-flex__col-12">     
     
        <p-table class="primeng-table-custom occurrenceList__th5" [value]="contractMeetingDetails.ContractMeetingOccurrences || []">
          <ng-template pTemplate="header">
             <tr>
                <th id="conEditMeetingRecYesDate">Date</th>
                <th id="conEditMeetingRecYesTotalActions">Total Actions</th>
                <th id="conEditMeetingRecYesOpenActions">Open Actions</th>
                <th id="conEditMeetingRecYesEdit" class="last-child-width">Edit</th>
                <th id="conEditMeetingOccurrencesOpenActions" *ngIf="deletePrivilege">Delete</th>
             </tr>
          </ng-template>
          <ng-template pTemplate="body" let-meetingOccurrence let-i="rowIndex"> 
             <!-- <tr> <td colspan="7" style="text-align: center;"> No record found </td></tr> -->
             <tr>
                 <td routerLinkActive="active"> <span> <a>{{ meetingOccurrence?.occurrenceDate ||  meetingOccurrence?.OccurrenceDate | date: _dateformart.GridDateFormat }} </a></span></td>
              
                 <td> <span *ngIf="meetingOccurrence?.TotalActionsCount==null">0 </span><span *ngIf="meetingOccurrence?.TotalActionsCount!=null">{{ meetingOccurrence?.TotalActionsCount  }} </span></td>	
                 <td> <span *ngIf="meetingOccurrence?.OpenActionsCount ==null">0 </span><span *ngIf="meetingOccurrence?.OpenActionsCount!=null">{{ meetingOccurrence?.OpenActionsCount }} </span></td>
                 <td id="contractEditMeetingRecYesEdit" class="grid-icon-center"> <i  class="pi pi-pencil" (click)="EditOccurrenceDialog(meetingOccurrence,i)">  </i></td>
                 <td class="ellipsis-cell grid-icon-center" *ngIf="deletePrivilege">
                    <span class="pi pi-trash" (click)="deleteOccurrece(meetingOccurrence.Id,i)">  </span>
                  </td>
             </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
            <td colspan="5" class="text-center"> No Records</td>
            </tr>
            </ng-template>
       </p-table>

       
  </div>


  <div class="fr-flex__container footer_btns">
      <div class="fr-flex__col-12"> 
          <!-- <button  type="button" class="btn btn-primary pull-right"> Save </button>
          <button type="button" class="btn btn-secondary pull-right"> Cancel </button> -->
      <button  type="button" class="btn btn-primary pull-right" [class.disabled]="isSaveBtnDisable" (click)="isSaveBtnDisable ? null :UpdateMeetingDetails()"> Save </button>
      <button type="button" class="btn btn-secondary pull-right" (click)="onCancelContractMeeting()"> Cancel </button>
   </div>
   </div>
   <p-dialog  appendTo="body" header="{{contactHeader}}" [(visible)]="displayContractGroupDialog" [modal]="true" [style]="{ width: '40vw' }" [resizable]="true" [closable]="false">
    <ng-template pTemplate="content">
       <form [formGroup]="contactFormGroup">
          <div class="fr-flex__container">
             <div class="fr-flex__col-12">
                <div class="fr-form__group">
                   <label for="name">Contact Name<em> * </em> <span class="max-length"> {{contactName.length || 0}}/30</span> </label>
                   <input type="text" id="contactName" class="form-control" formControlName="contractName" [maxlength]="30" [(ngModel)]="contactName" pInputText trimSpace/>
                   <small class="p-error" *ngIf="(contactSubmitted || contactFormGroup?.get('contractName')?.touched) && contactFormGroup?.get('contractName')?.hasError('required')"> This field is required. </small>
                   <small class="p-error" *ngIf="(contactSubmitted || contactFormGroup?.get('contractName')?.touched) && contactFormGroup?.get('contractName')?.hasError('maxlength')">  Maximum length is 30 characters. </small>
                   <small class="p-error" *ngIf="(contactSubmitted || contactFormGroup?.get('contractName')?.touched) && contactFormGroup?.get('contractName')?.hasError('minlength')">  Minimum length is 3 characters. </small>
                   <small class="p-error" *ngIf="(contactSubmitted || contactFormGroup?.get('contractName')?.touched) && contactFormGroup?.get('contractName')?.hasError('onlySpaces')"> Only spaces not allowed.</small>
                   <small class="p-error" *ngIf="(contactSubmitted || contactFormGroup?.get('contractName')?.touched) && contactFormGroup?.get('contractName')?.hasError('pattern')">Special characters and numerics are not allowed, Min length is 3 and Max length is 30 characters.</small>

                </div>
             </div>
          </div>
       
       </form>
    </ng-template>
    <!-- Dialog Footer Start -->
    <ng-template pTemplate="footer">
       <button  type="button" class="btn btn-primary pull-right" (click)="SaveMeetingContact()"> Save </button>
       <button type="button" class="btn btn-secondary pull-right" (click)="closeContactDialog()"> Cancel </button>
    </ng-template>
    <!-- Dialog Footer End -->
    </p-dialog>
</div>
