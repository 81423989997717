<!DOCTYPE html>
<html lang="en">
<head>
   <title> Engagement Audit </title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>
<div class="card-pad">
   <div class="fr-flex__container sticky-sub-header">
      <div class="fr-flex__col-8">
         <h3>Add Audit</h3>
      </div>
   </div>
   <div class="scroll-start-here-sub-tabs"> 
   <form [formGroup]="myEngagementAuditForm">
   <div class="fr-flex__container m-t-20">
      <div class="fr-flex__col-4">
         <div class="fr-form__group">
            <label>
               Audit Title  <em> * </em><span class="max-length"> {{_engagementAudit.auditTitle?.length || 0}}/50 </span>
            </label>
            <input id="engCreAuditTitleInput" type="text" formControlName="_auditTitle" class="form-control" [maxlength]="50"  pInputText [(ngModel)]="_engagementAudit.auditTitle"  trimSpace />
            <small class="p-error" *ngIf="submitted && myEngagementAuditForm?.get('_auditTitle')?.hasError('required')">This field is required.</small>
            <small class="p-error" *ngIf="submitted && myEngagementAuditForm?.get('_auditTitle')?.hasError('maxlength')">Max length is 50 characters.</small>
            <small class="p-error" *ngIf="submitted && myEngagementAuditForm?.get('_auditTitle')?.hasError('onlySpaces')">This field cannot contain only spaces.</small>
         </div>
      </div>
      <div class="fr-flex__col-4">
         <div class="fr-form__group">
            <label>
               Audit No  <em> * </em><span class="max-length"> {{_engagementAudit.auditNumber?.length || 0}}/15 </span>
            </label>
            <input id="engCreAuditNoInput" type="text" formControlName="_auditNumber" class="form-control" [maxlength]="15"  pInputText [(ngModel)]="_engagementAudit.auditNumber" trimSpace />
            <small class="p-error" *ngIf="submitted && myEngagementAuditForm?.get('_auditNumber')?.hasError('required')">This field is required.</small>
            <small class="p-error" *ngIf="submitted && myEngagementAuditForm?.get('_auditNumber')?.hasError('maxlength')">Max length is 15 characters.</small>
            <small class="p-error" *ngIf="submitted && myEngagementAuditForm?.get('_auditNumber')?.hasError('onlySpaces')">This field cannot contain only spaces.</small>
            <small class="p-error" *ngIf="submitted && myEngagementAuditForm?.get('_auditNumber')?.hasError('pattern')">Space(s) are not allowed.</small>
         </div>
      </div>
   </div>
   <div class="fr-flex__container">
      <div class="fr-flex__col-4">
         <div class="fr-form__group">
            <label>
               Auditor   <em> * </em><span class="max-length"> {{_engagementAudit.auditor?.length || 0}}/50 </span>
            </label>
            <input id="engCreAuditorInput" type="text" formControlName="_auditor" class="form-control" [maxlength]="50"  pInputText [(ngModel)]="_engagementAudit.auditor"  trimSpace />
            <small class="p-error" *ngIf="submitted && myEngagementAuditForm?.get('_auditor')?.hasError('required')">This field is required.</small>
            <small class="p-error" *ngIf="submitted && myEngagementAuditForm?.get('_auditor')?.hasError('maxlength')">Max length is 50 characters.</small>
            <small class="p-error" *ngIf="submitted && myEngagementAuditForm?.get('_auditor')?.hasError('onlySpaces')">This field cannot contain only spaces.</small>
            <small class="p-error" *ngIf="submitted && myEngagementAuditForm?.get('_auditor')?.hasError('pattern')">Only alpha characters are allowed.</small>
         </div>
      </div>
      <div class="fr-flex__col-4">
         <div class="fr-form__group">
            <label>
               Auditee SPOC <em> * </em>
            </label>
            <p-dropdown id="engCreAuditeeSpocDropdown" appendTo="body" [filter]="true" formControlName="_auditeeSPOC" [options]="auditAuditeeSPOCDataSource" [(ngModel)]="_engagementAudit.auditeeSPOC" optionLabel="name" optionValue="id" placeholder="Select Auditee SPOC"></p-dropdown><br>
           <small class="p-error" *ngIf="submitted && myEngagementAuditForm?.get('_auditeeSPOC')?.hasError('required')">This field is required.</small>
         </div>
      </div>
      <div class="fr-flex__col-8">
         <div class="fr-form__group">
            <div class="fr-form__group">
               <label> Attachments  <em></em> <i  pInputText pTooltip="Maximum 3 Attachments allowed." tooltipPosition="top" class="pi pi-info-circle" > </i></label>
               <input id="engCreAttachmntsInput" type="file" accept=".pdf, .xls, .xlsx,.doc,.docx" multiple (change)="onFileChange($event)" class="form-control" formControlName="_auditFile" #fileInput style="display:none" trimSpace />
               <button type="button" (click)="fileInput.click()" class="form-control width50 m-b-10" [disabled]="isUploadButtonDisabled">Upload Files</button>
               
               <div class="display-inline-block" *ngFor="let file of uploadedFiles">
                   <a class="form-list-active">
                        <span [pTooltip]="file.name.length > 45 ? file.name : ''" tooltipPosition="top">  {{ file.name | truncate:45 }} </span> 
                        <i pInputText pTooltip="Delete" tooltipPosition="top" (click)="removeFile(file)" class="pi pi-trash"></i> </a>
                 </div>
                 
               </div>
         </div>
      </div>
   </div>
   <div class="fr-flex__container">
      <div class="fr-flex__col-4">
         <div class="fr-form__group">
            <label>
               Start Date<em> * </em>
            </label>
            <p-calendar id="engCreAuditStartDateCalendar" appendTo="body" formControlName="_auditStartDate" [(ngModel)]="_engagementAudit.auditStartDate" [showIcon]="true" [dateFormat]=_dateformart.CalenderDateFormat (ngModelChange)="onStartDateSelection(_engagementAudit.auditStartDate)" [maxDate]="auditMaxStartDate" [readonlyInput]="true"></p-calendar>
             <small class="p-error" *ngIf="submitted && myEngagementAuditForm?.get('_auditStartDate')?.hasError('required')">This field is required.</small>
         </div>
      </div>
      <div class="fr-flex__col-4">
         <div class="fr-form__group">
            <label>
               End Date<em> * </em>
            </label>
            <p-calendar id="engCreAuditEndDateCalendar" appendTo="body" formControlName="_auditEndDate" [(ngModel)]="_engagementAudit.auditEndDate" [showIcon]="true" [dateFormat]=_dateformart.CalenderDateFormat (ngModelChange)="onEndDateSelection(_engagementAudit.auditEndDate)" [minDate]="auditMinEndDate" [readonlyInput]="true"></p-calendar>
            <small class="p-error" *ngIf="submitted && myEngagementAuditForm?.get('_auditEndDate')?.hasError('required')">This field is required.</small>
         </div>
      </div>
      <div class="fr-flex__col-4">
         <div class="fr-form__group">
            <label>
               Audit Status  <em> * </em> 
            </label>
            <p-dropdown id="engCreAuditStatusDropdown" appendTo="body" [filter]="true" formControlName="_status" [options]="auditStatusDataSource" [(ngModel)]="_engagementAudit.statusMappingId" optionLabel="name" optionValue="id" placeholder="Select"></p-dropdown><br>
            <small class="p-error" *ngIf="submitted && myEngagementAuditForm?.get('_status')?.hasError('required')">This field is required.</small>
            
         </div>
      </div>
   </div>
   <div class="fr-flex__container">
      <div class="fr-flex__col-12">
         <div class="fr-form__group">
            <label> Description <span class="max-length"> {{_engagementAudit.description?.length || 0}}/300 </span>  
            </label>
            <textarea id="engCreAuditDescriptionTextarea" formControlName="_description" class="form-control" [maxlength]="300" pInputTextarea [(ngModel)]="_engagementAudit.description" trimSpace ></textarea>
            <small class="p-error" *ngIf="submitted && myEngagementAuditForm?.get('_description')?.hasError('maxlength')">Max length is 300 characters.</small>
            <small class="p-error" *ngIf="submitted && myEngagementAuditForm?.get('_description')?.hasError('onlySpaces')">This field cannot contain only spaces.</small>
          </div>
      </div>
      </div>

   </form>
</div>
<div class="fr-flex__container">
   <div class="fr-flex__col-8">
       <h3>Observations​ </h3>
   </div>
   <div class="fr-flex__col-4"> 
       <a  class="btn btn-primary pull-right" (click)="AddObservations()"> Add Observation </a>
   </div>
</div>

<!-- Observations​ Start -->
<div class="fr-flex__col-12 m-b-20"> 
   <p-table id="engCreateAuditTableName" class="primeng-table-custom observation-input-width custom-cells__supplierCreateAudi_Observations" [scrollable]="true" [value]="_auditObservations" editMode="row">
       <ng-template pTemplate="header">
           <tr>                         
               <th id="engCreateAuditObservationID">Observation ID<em *ngIf="_auditObservations.length>0"> * </em> </th>
               <th id="engCreateAuditGrade">Grade</th>
               <th id="engCreateAuditNoofFindings">No. of Findings</th>
               <th id="engCreateAuditObservationStatus">Observation Status </th>
               <th id="engCreateAuditDelete" class="last-child-width">Delete</th>
           </tr>
       </ng-template>
       <ng-template pTemplate="body" let-_auditObservations let-ObservationIndex="rowIndex"> 
       
         <tr>                    
             <td>
               <input type="text"  id="engCreAuditObservationID" class="form-control" [maxlength]="15" [required]="true"  pInputText [(ngModel)]="_auditObservations.observationId" name="observationId" #observationIdInput="ngModel" trimSpace />
                 <div *ngIf="(observationIdInput.invalid || !_auditObservations.observationId) && submitted">
                   <div *ngIf="observationIdInput.errors?.['required']" style="color: red;">This field is required.</div>  
                 </div>                 
               </td>    
             <td>
               <p-dropdown id="engCreAuditGrade" appendTo="body" [filter]="true" [options]="observationGradesDataSource" appendTo="body"  [(ngModel)]="_auditObservations.grade" placeholder="Select Grade" optionLabel="name" optionValue="id"></p-dropdown>
            </td>
            <td id="engCreateAudiHyperLink" class="grid-center">
               <a class="table-active-link" (click)="GetFindinglist(ObservationIndex)">{{_auditObservations.numberofFidings}} </a>
            </td>
             <td>
                 <p-dropdown id="engCreAuditNoofFindings" appendTo="body" [filter]="true" [options]="observationStatusDataSource" appendTo="body" placeholder="Select Status" optionLabel="name" optionValue="id" [(ngModel)]="_auditObservations.statusMappingId"></p-dropdown>
             </td>                    
             <td id="engCreateAuditDelete" class="grid-icon-center"><span class="pi pi-trash" (click)="deleteObservation(ObservationIndex)"></span></td>
         </tr>
     </ng-template>
     <ng-template pTemplate="emptymessage">
      <tr>
      <td colspan="5" class="text-center"> No Records</td>
      </tr>
      </ng-template>
   </p-table>
</div>
<!-- Observations​ End -->




<!-- Dialog Findings List Start -->
<div class="card flex justify-content-center"> 
<p-dialog appendTo="body" header="Findings List" [(visible)]="visible" [modal]="true" [style]="{ width: '80vw' }" [draggable]="false" [resizable]="false" [closable]="false">
   <div class="fr-flex__container"> 
   <div class="fr-flex__col-4">
      <div class="fr-form__group">
         <label> Observation ID   <em> * </em></label>
         <input type="text" id="engCreAuditDialogObservationIDInput" [readOnly]="true"  class="form-control read-only" [maxlength]="50" pInputText [(ngModel)]="observationId" trimSpace/>
      </div>
   </div>
</div>
<div class="fr-flex__col-12 m-b-20"> 
   <div class="fr-flex__container">
      <div class="fr-flex__col-8">
      </div>
      <div class="fr-flex__col-4 custom-pad-zero m-b-10">
        <a class="btn btn-primary pull-right" (click)="openSecondLevelPopup()"> Add Finding</a>
      </div>
  </div>
   <p-table id="engCreateAuditAddFindingTableName" class="primeng-table-custom custom-cells__AddFinding small_scroll" [scrollable]="true" [value]="findingListDataSource">
       <ng-template pTemplate="header">
           <tr>                         
               <th id="engCreateAuditFindingID">Finding ID</th>
               <th id="engCreateAuditFindingOwner">Finding Owner</th>
               <th id="engCreateAuditTargetDate">Target Date</th>
               <th id="engCreateAuditFindingStatus">Finding Status </th>
               <th id="engCreateAuditEdit" class="last-child-width">Edit</th>
               <th id="engCreateAuditDelete" class="last-child-width">Delete</th>
           </tr>
       </ng-template>
       <ng-template pTemplate="body" let-findingItem let-findingIndex="rowIndex"> 
         
           <tr>  
            <td [pTooltip]="findingItem?.findingId?.length > 30 ? findingItem?.findingId : ''" tooltipPosition="top">{{ findingItem?.findingId}} </td>
            <td [pTooltip]="findingItem?.findingOwnerName?.length > 30 ? findingItem?.findingOwnerName : ''" tooltipPosition="top">{{ findingItem?.findingOwnerName}}</td>
            <td> {{ findingItem?.targetDate  | date: _dateformart.GridDateFormat}} </td>
            <td> {{ findingItem?.findingStatus}} </td>
            <td id="engCreateAuditAddFindingEdit" class="grid-icon-center"> <span> <i  class="pi pi-pencil"  (click)="editFinding(findingIndex);" ></i></span></td>
            <td id="engCreateAuditAddFindingDelete" class="grid-icon-center"> <span class="pi pi-trash" (click)="deleteFinding(findingIndex);" > </span></td>	                  
          </tr>
       </ng-template>
       <ng-template pTemplate="emptymessage">
         <tr>
         <td colspan="6" class="text-center"> No Records</td>
         </tr>
         </ng-template>
   </p-table>
  
</div>
<ng-template pTemplate="footer">
  
   <button type="button" class="btn btn-secondary pull-right" (click)="cancelFindingList();"> Close </button>
</ng-template>
</p-dialog>
</div>
<!-- Dialog Findings List End -->




<!-- Dialog Finding Start -->
<div class="card flex justify-content-center"> 
   <p-dialog appendTo="body" id="engCreFindingPopup" [header]="header" [(visible)]="secondLevelPopupVisible" [modal]="true" [style]="{ width: '80vw' }" [draggable]="false" [resizable]="false" [closable]="false">
      <ng-template pTemplate="content"> 
         <form [formGroup]="engagementFindingForm"> 
      <div class="fr-flex__container">
         <div class="fr-flex__col-4">
            <div class="fr-form__group">
               <label>Finding ID   <em> * </em>
                  <span class="max-length"> {{engagementAuditObsvFindingData.findingId?.length || 0}}/15 </span>
               </label>
               <input type="text" class="form-control" formControlName="_findingId" [maxlength]="15" [minlength]="3" pInputText [(ngModel)]="engagementAuditObsvFindingData.findingId" trimSpace />
               <small class="p-error" *ngIf="findingSubmitFlag && engagementFindingForm?.get('_findingId')?.hasError('required')">This field is required.</small>
               <small class="p-error" *ngIf="findingSubmitFlag && engagementFindingForm?.get('_findingId')?.hasError('maxlength')">Maximum length is 50 characters.</small>
               <small class="p-error" *ngIf="findingSubmitFlag && engagementFindingForm.get('_findingId')?.hasError('onlySpaces')">Only spaces not allowed.</small>
               <small class="p-error" *ngIf="findingSubmitFlag && engagementFindingForm?.get('_findingId')?.hasError('minlength')">Minimum length is 3 characters.</small>
            
            </div>
         </div>
         <div class="fr-flex__col-4">
            <div class="fr-form__group">
               <label>
                  Finding Area   <em> * </em>
               </label>
               <p-dropdown id="engCreAuditFindingArea" appendTo="body" [filter]="true" class="form-control" formControlName="_findingAreaId" [(ngModel)]="engagementAuditObsvFindingData.findingAreaId" [options]="findingAreaData" optionLabel="name" optionValue="id" placeholder="Select"></p-dropdown>
               <small class="p-error" *ngIf="findingSubmitFlag && engagementFindingForm?.get('_findingAreaId')?.hasError('required')">This field is required.</small>
            </div>
         </div>
         <div class="fr-flex__col-4">
            <div class="fr-form__group">
               <label>Finding Owner <em> * </em></label>
               <p-dropdown id="engCreAuditFindingOwner" appendTo="body" [filter]="true" class="form-control" formControlName="_findingOwnerId" [(ngModel)]="engagementAuditObsvFindingData.findingOwnerId" [options]="auditAuditeeSPOCDataSource" optionLabel="name" optionValue="id" placeholder="Select"></p-dropdown>
               <small class="p-error" *ngIf="findingSubmitFlag && engagementFindingForm?.get('_findingOwnerId')?.hasError('required')">This field is required.</small>
            </div>
         </div>
      </div>
   
      <div class="fr-flex__container">
         <div class="fr-flex__col-4">
            <div class="fr-form__group">
               <label>Target Date   <em> * </em></label>
               <p-calendar id="engCreAuditTargetDateCalendar" appendTo="body" formControlName="_targetDate" [(ngModel)]="engagementAuditObsvFindingData.targetDate" [showIcon]="true" [dateFormat]=_dateformart.CalenderDateFormat [readonlyInput]="true"></p-calendar>
               <small class="p-error" *ngIf="findingSubmitFlag && engagementFindingForm?.get('_targetDate')?.hasError('required')">This field is required.</small>
            </div>
         </div>
         <div class="fr-flex__col-4">
            <div class="fr-form__group">
               <label>
                  Finding Status  <em> * </em>
               </label>
               <p-dropdown id="engCreAuditFindingStatus" appendTo="body" [filter]="true" formControlName="_statusMappingId" [(ngModel)]="engagementAuditObsvFindingData.statusMappingId" [options]="findingStatusData" optionLabel="name" optionValue="id" placeholder="Select" (onChange)="findingStatusChangeClick($event)"></p-dropdown>
               <small class="p-error" *ngIf="findingSubmitFlag && engagementFindingForm?.get('_statusMappingId')?.hasError('required')">This field is required.</small>
            </div>
         </div>
        
      </div>
      <div class="fr-flex__container">
         <div class="fr-flex__col-12">
            <div class="fr-form__group">
               <label> Finding Detail <em> * </em>
                  <span class="max-length"> {{engagementAuditObsvFindingData.findingDetail?.length || 0}}/300 </span>
               </label>
               <textarea id="engCreAuditFindingDetailTextarea" class="form-control" formControlName="_findingDetail" pInputTextarea [(ngModel)]="engagementAuditObsvFindingData.findingDetail" [maxlength]="300" trimSpace ></textarea>
               <small class="p-error" *ngIf="findingSubmitFlag && engagementFindingForm?.get('_findingDetail')?.hasError('required')">This field is required.</small>
               <small class="p-error" *ngIf="findingSubmitFlag && engagementFindingForm?.get('_findingDetail')?.hasError('maxlength')">Maximum length is 300 characters.</small>
            </div>
         </div>
         </div>
         <div class="fr-flex__container">
            <div class="fr-flex__col-12">
               <div class="fr-form__group">
                  <label> Supplier Response <em *ngIf="isStatusClosed"> * </em>
                     <span class="max-length"> {{engagementAuditObsvFindingData.engagementResponse?.length || 0}}/300 </span>
                  </label>
                  <textarea id="engCreAuditSupplierResponseTextarea" class="form-control" formControlName="_supplierResponse" pInputTextarea [(ngModel)]="engagementAuditObsvFindingData.engagementResponse" (ngModelChange)="engagementResponseChange()" [maxlength]="300" trimSpace></textarea>
                  <small class="p-error" *ngIf="findingSubmitFlag && engagementFindingForm?.get('_supplierResponse')?.hasError('maxlength')">Maximum length is 300 characters.</small>
                  <small class="p-error" *ngIf="isStatusClosedForSupplier">This field is required.</small>
               </div>
            </div>
            </div>
   
            <div class="fr-flex__container">
               <div class="fr-flex__col-6">
                  <div class="fr-form__group">
                     <label> Evidence for Closure
                        <span class="max-length"> {{engagementAuditObsvFindingData.evidenceOfClosure?.length || 0}}/300 </span>
                     </label>
                     <textarea id="engCreAuditEvidenceforClosureTextarea" class="form-control" formControlName="_evidenceOfClosure" pInputTextarea [(ngModel)]="engagementAuditObsvFindingData.evidenceOfClosure" [maxlength]="300" trimSpace ></textarea>
                     <small class="p-error" *ngIf="findingSubmitFlag && engagementFindingForm?.get('_evidenceOfClosure')?.hasError('maxlength')">Maximum length is 300 characters.</small>
                  </div>
               </div>
               <div class="fr-flex__col-6">
                  <div class="fr-form__group">
                  <label> Attachments  <i  pInputText pTooltip="Maximum 3 Attachments allowed." tooltipPosition="top" class="pi pi-info-circle" > </i></label>
                  <input type="file" accept=".pdf, .xls, .xlsx,.doc,.docx" multiple (change)="onFindingFileChange($event)" class="form-control" formControlName="_findingFile" #fileInput style="display:none" trimSpace />
                  <button type="button" (click)="fileInput.click()" class="form-control width100" [disabled]="isAddFindingFileUploadButtonDisabled">Upload Files</button>
                  <div class="display-inline-block" *ngFor="let file of uploadedFindingFileData">
                     <a class="form-list-active">
                        <span [pTooltip]="file.name.length > 45 ? file.name : ''" tooltipPosition="top">  {{ file.name | truncate:45 }} </span> 
                        <i pInputText pTooltip="Delete" tooltipPosition="top" (click)="removeFindingFile(file)" class="pi pi-trash"></i>
                     </a>
                    </div> 
                  </div>
              </div>
               
          </div>
               <div class="fr-flex__container">
                  <div class="fr-flex__col-12">
                     <div class="fr-form__group">
                        <label> Decision of Review <em *ngIf="isStatusClosed"> * </em>
                           <span class="max-length"> {{engagementAuditObsvFindingData.decisionOfReview?.length || 0}}/300 </span>
                        </label>
                        <textarea id="engCreAuditDecisionofReviewTextarea" class="form-control" formControlName="_decisionOfReview" pInputTextarea [(ngModel)]="engagementAuditObsvFindingData.decisionOfReview" (ngModelChange)="decisionReviewChange()" [maxlength]="300" trimSpace ></textarea>
                        <small class="p-error" *ngIf="findingSubmitFlag && engagementFindingForm?.get('_decisionOfReview')?.hasError('maxlength')">Maximum length is 300 characters.</small>
                        <small class="p-error" *ngIf="isStatusClosedForDecision">This field is required.</small>
                     </div>
                  </div>
                  </div>


                  <div *ngIf="isUpdatedFlag" class="fr-flex__container">
                     <div class="fr-flex__col-3">
                        <div class="fr-form__view">
                           <label> Added By</label>
                           <span> <em>  {{engagementAuditObsvFindingData.createdBy}} </em></span>
                           <span *ngIf="engagementAuditObsvFindingData.createdBy ==null"> <em> --</em></span>
                        </div>
                     </div>
                     <div class="fr-flex__col-3">
                        <div class="fr-form__view">
                           <label> Added Date & Time </label>
                           <span *ngIf="engagementAuditObsvFindingData.createdDate !=null"> <em>  {{engagementAuditObsvFindingData.createdDate | date: _dateformart.DateTimeWithZone}}{{_dateformart.DisplayUTC}}</em></span>
                           <span *ngIf="engagementAuditObsvFindingData.createdDate ==null"> <em> --</em></span>
                        </div>
                     </div>
                     <div class="fr-flex__col-3" >
                        <div class="fr-form__view">
                           <label> Modified By</label>
                           <span  *ngIf="engagementAuditObsvFindingData.updatedBy !=null"> <em>  {{engagementAuditObsvFindingData.updatedBy}} </em></span>
                           <span *ngIf="engagementAuditObsvFindingData.updatedBy ==null"> <em> --</em></span>
                        </div>
                     </div>
                     <div class="fr-flex__col-3" >
                        <div class="fr-form__view">
                           <label> Modified Date & Time </label>
                           <span  *ngIf="engagementAuditObsvFindingData.updatedDate !=null"> <em>  {{engagementAuditObsvFindingData.updatedDate | date: _dateformart.DateTimeWithZone}}{{_dateformart.DisplayUTC}}</em></span>
                           <span *ngIf="engagementAuditObsvFindingData.updatedDate ==null"> <em> --</em></span>
                         </div>
                     </div>
                  </div>
               </form>
              
      </ng-template>
      <ng-template pTemplate="footer"> 
         <button  type="button" class="btn btn-primary pull-right" [class.disabled]="isSaveFindingPopupBtnDisable" (click)="isSaveFindingPopupBtnDisable ? null : saveFinding()"> Save </button>
         <button type="button" class="btn btn-secondary pull-right" (click)="cancelFinding()"> Cancel </button>
         </ng-template>
   </p-dialog>
   </div>
   <!-- Dialog Finding End -->



<div class="fr-flex__container footer_btns">
   <div class="fr-flex__col-12">
      <a  (click)="isSaveBtnDisable ? null : onSaveEngagementAudit()" type="button" [class.disabled]="isSaveBtnDisable" class="btn btn-primary btn-save pull-right"> Save </a>
      <a type="button" (click)="onCancelEngagementAudit()"  class="btn btn-secondary pull-right"> Cancel </a>
   </div>
</div>
</div>
</body>
</html>