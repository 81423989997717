<!DOCTYPE html>
<html lang="en">
<head>
  <title> Home </title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>
<div class="container"> 
  <div class="home_custom_css"> 
<div class="fr-flex__container m-t-20">
    <div class="fr-flex__col-4"> 
        <p-card>
          <span class="fr-card__title">
            <div class="fr-symbol__content-primary--text" (click)="supplier_navigation()">  Suppliers  </div>
            <div class="fr-sybmobol__content-secondary-text">  Recent Suppliers statistics  </div>
          </span>
          <span class="fr-symbol1">
            <span class="fr-symbol1__box"> 
              <span class="for-symbol__box--icon">
                <i class="icon-refresh-file"></i>
              </span>
            </span>
            <div class="fr-symbol__content">
            <div class="fr-symbol__content-primary--text" >
             {{ActiveSupplierCount}}
              </div>
              <div class="fr-sybmobol__content-secondary-text">
                Total
              </div>
            </div>
          </span>
        </p-card>
    </div>
    <div class="fr-flex__col-4"> 
        <p-card>
          <span class="fr-card__title">
            <div class="fr-symbol__content-primary--text" (click)="contract_navigation()">  Contracts  </div>
            <div class="fr-sybmobol__content-secondary-text">  Recent Contracts statistics  </div>
          </span>

          <p class="m-0">
            <span class="fr-symbol2">
              <span class="fr-symbol2__box"> 
                <span class="for-symbol__box--icon">
                  <i class="icon-text-file2"></i>
                </span>
              </span>
              <div class="fr-symbol__content">
                <div class="fr-symbol__content-primary--text">
                  {{ActiveContractsCount}}
                </div>
                <div class="fr-sybmobol__content-secondary-text">
                  Active Contracts
                </div>
              </div>
              <div class="fr-symbol__content">
                <div class="fr-symbol__content-primary--text">
                 {{ExpiredContractsCount}}
                </div>
                <div class="fr-sybmobol__content-secondary-text">
                  Expired Contracts
                </div>
              </div>
            </span>
             
          </p>
         
      </p-card>
      </div>
      <div class="fr-flex__col-4"> 
        <p-card class="home-title">
          <span class="fr-card__title">
            <div class="fr-symbol__content-primary--text" (click)="dashboard_navigation()">  Dashboards  </div>
            <div class="fr-sybmobol__content-secondary-text">  Recent Dashboards statistics  </div>
          </span>
          <span class="fr-symbol3">
            <span class="fr-symbol3__box">
              <span class="for-symbol__box--icon">
                <i class="icon-pie-chart2"></i>
              </span>
            </span>
            <div class="fr-symbol__content">
              <!--routerLink="/dashboard">-->
              <div class="fr-symbol__content-primary--text">
                01
              </div>
              <div class="fr-sybmobol__content-secondary-text">
                Total
              </div>
            </div>
          </span>
      </p-card>
      </div>
    </div>
    <div class="fr-flex__container m-t-20">
    </div>
  </div>
</div>
</body>
</html>