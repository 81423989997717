import { DatePipe,} from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import FileSaver from 'file-saver';
import { ContractService } from 'src/app/Contracts/Services/contract.service';
import { ContracDetails } from 'src/app/Finance/Models/contract-accrual';
import { FinanceService } from 'src/app/Finance/Services/invoice.service';
import { LoaderService } from 'src/app/Loader/loader.service';
import { CurrencyService } from 'src/app/Reusable/currency.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { Dateformat, FileExtension } from 'src/app/shared/constants/global.constants';
import { Privileges } from 'src/app/shared/constants/privileges.constants';
import { TabAndStateService } from 'src/app/shared/TabAndStateService/TabAndState.Service';

@Component({
  selector: 'app-con-accruals-list',
  templateUrl: './con-accruals-list.component.html',
  styleUrls: ['./con-accruals-list.component.css']
})
export class ConAccrualsListComponent {
  
  @Output() addButtonClick = new EventEmitter<void>();
  @Output() editButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() viewButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() childData: EventEmitter<any> = new EventEmitter<any>();
 
  @Input() contractId: any;
  @Input() accrualId: any;

  selectedContractAccrual:any;
  _contractAccrualListData: any[] = []
  _dateformart :any;
  
  _contractDetail= new ContracDetails();
  editPrivilege:boolean=false;
  viewPrivilege:boolean=false;
  ExportPrivilage:boolean=false;
  constructor(private privilegeService: PrivilegeService,private route: ActivatedRoute,private router:Router,private loaderService: LoaderService,private datePipe: DatePipe,private financeService:FinanceService, private contractService: ContractService,private currencyService: CurrencyService,private tabAndStateService: TabAndStateService ){
  }
  
  currency:any;
   ngOnInit() {
     
    this._dateformart=Dateformat;
    this.loaderService.showLoader();
    this.GetContractDetailsByContractId(this.contractId);//To get ContractDetails by Id
    this.loaderService.hidefinalLoader();// hide after 4 seconds even get any error
    this.BindPrivileges();
    this.currency= this.route.snapshot.queryParamMap.get('currency');
    }
    BindPrivileges(){
      const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);
  this.editPrivilege = hasPermission(Privileges.EditAccruals);
  this.viewPrivilege =hasPermission(Privileges.ViewAccruals);
  this.ExportPrivilage= hasPermission(Privileges.ExportContractDraftInvoices);
      }
    contractInfo:any;
    GetContractDetailsByContractId(contractId:number) {
      this.loaderService.showLoader();
      this.contractService.GetContractDtailsById(contractId).subscribe(res => {
        if (res.data != null) {
          this.contractInfo = res.data;
                 
          if(this.contractInfo!=null && this.contractInfo!=undefined){
            this._contractDetail.contractDetailId=this.contractInfo.id;
            this._contractDetail.contractStartDate= this.datePipe.transform(this.contractInfo.startDate, "MM/dd/yyyy");
            this._contractDetail.contractEndDate=this.datePipe.transform(this.contractInfo.endDate, "MM/dd/yyyy");
          }
          this.getContractAccrualsByContractId(this._contractDetail);
        }
      
      });
    }
  getContractAccrualsByContractId(contractDetail:any){
      this.financeService.GetContractAccrualByContractId(contractDetail).subscribe(
        response=>{
         this._contractAccrualListData=response.data;
         this.loaderService.hideLoader();
         this.tabAndStateService.setDraftInvoicesData( this._contractAccrualListData);
        });
    }
  
  editAccrual(currentAccrualData:any){
    var contractId= this.contractId;
    var accrualId=currentAccrualData.id.toString();
    const data = {
      contractId:this.contractId,
      accrualId: currentAccrualData
    };
    this.editButtonClick.emit(data);
  }
  
  viewAccrual(currentAccrualData:any){
    var contractId= this.contractId;
    var accrualId=currentAccrualData.id.toString();
    const data = {
      contractId:this.contractId,
      accrualId: currentAccrualData
    };
    this.viewButtonClick.emit(data);
  }
  
  
  isChecked: boolean = true;
checkboxEnabled() {
  if (this.selectedContractAccrual.length > 0) {
    this.isChecked = false;
  }
  else {
    this.isChecked = true;
  }
}

exportExcel(SelectedRecords: any) {
  import('xlsx').then((xlsx) => {
    const worksheet = xlsx.utils.json_to_sheet(SelectedRecords);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
    const fileName = `${this.contractInfo.title} - Draft InvoicesExport - ${this.convertFileDateFormat(Date())}.xlsx`;
    this.saveAsExcelFile(excelBuffer, fileName);
  });
}

saveAsExcelFile(buffer: any, fileName: string): void {
  let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  let EXCEL_EXTENSION = FileExtension.ExportExtention;
  const data: Blob = new Blob([buffer], {
    type: EXCEL_TYPE
  });
  FileSaver.saveAs(data, fileName);
}
selectedRecordsToExpotToExcel: any[] = [];
exportContractAccurals() {
  this.selectedRecordsToExpotToExcel = this.selectedContractAccrual;
  this.selectedRecordsToExpotToExcel = this.selectedRecordsToExpotToExcel.map((accural) => {
    return {
      Month:accural.accrualDate!=null?this.convertDateFormat(accural.accrualDate):accural.accrualDate,
      'Draft Invoices Amount': accural.updatedBy != null ? accural.accrualAmount : '--',
      'Status':accural.accrualStatus,
       Attachment: accural.financeContractAccrualAttachments && accural.financeContractAccrualAttachments.length > 0 ? accural.financeContractAccrualAttachments[0].fileName : '--',
      Remarks:accural.remarks,
      'Modified By':this.privilegeService.getUserName(accural.updatedBy),
      'Modified Date and Time':accural.updatedDate!=null?this.convertDateTimeFormat(accural.updatedDate):accural.updatedDate,
      'Approved By':this.privilegeService.getUserName(accural.approvedBy),
      'Approved Date and Time':accural.approvedDateTime!=null?this.convertDateTimeFormat(accural.approvedDateTime):accural.approvedDateTime,
    };
  });
  this.exportExcel(this.selectedRecordsToExpotToExcel);
}
convertDateFormat(inputDateTime: string) {
  const date = new Date(inputDateTime);
  const formattedDate = this.datePipe.transform(date, this._dateformart.MonthYearFormat);
  return formattedDate;
}

convertGridDateFormat(inputDateTime: string) {
  const date = new Date(inputDateTime);
  const formattedDate = this.datePipe.transform(date, this._dateformart.GridDateFormat);
  return formattedDate;
}

getFormattedValue(value: number, currencyName: string): string {
  return this.currencyService.getFormattedValue(value, currencyName);
}
convertDateTimeFormat(inputDateTime: string) {  // while export to excel display UTC format
  const date = new Date(inputDateTime);

  // Adjust for timezone offset
  const timezoneOffsetHours = 5; 
  const timezoneOffsetMinutes = 30;
  date.setUTCHours(date.getUTCHours() + timezoneOffsetHours);
  date.setUTCMinutes(date.getUTCMinutes() + timezoneOffsetMinutes);

  // Format date
  const day = date.getUTCDate().toString().padStart(2, '0');
  const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
  const year = date.getUTCFullYear();
  const formattedDate = `${day}-${month}-${year}`;

  // Format time in UTC
  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  const formattedTime = `${hours}:${minutes} UTC`;

  return `${formattedDate} ${formattedTime}`;
}


convertFileDateFormat(inputDateTime: string) {
  const date = new Date(inputDateTime);
  const formattedDate = this.datePipe.transform(date, this._dateformart.GridDateFormat);
  return formattedDate;
}
}
