<div class="card flex justify-content-center">
    <p-breadcrumb class="max-w-full" [model]="getBreadcrumbs()"></p-breadcrumb>
</div>
<div class="container page-title">
    <div class="flex-box"> 
        <div class="fr-flex__container">
            <div class="fr-flex__col-8">
            </div>
        </div>
    </div>
    <img src="../../../../assets/images/Dashboard-bg.jpg" class="dashboard-bg" alt="">
  
    <div class="fr-flex__container dashboard_relative m-t-20">
        <div class="fr-flex__col-4">
            <div class="fr-card__dashboard" (click)="showDashboard('KpiTreand')"> 
                  <span class="fr-symbol">
                    <span class="fr-icon__box"> 
                        <span class="fr-icon">
                            <img src="../../../../assets/images/ActiveKPI's.svg" width="30" alt="dashboard active img">
                         </span>
                     </span>
                     <div class="fr-content">
                        <div id="" class="fr-content__primary--text">
                            KPI Trend
                        </div>
                      </div>
                    </span>
            </div>
        </div>
        <div class="fr-flex__col-4">
            <div class="fr-card__dashboard" (click)="showDashboard('Finance')"> 
                  <span class="fr-symbol">
                    <span class="fr-icon__box"> 
                        <span class="fr-icon">
                            <img src="../../../../assets/images/TotalEngagementValue.svg" width="30" alt="">
                         </span>
                     </span>
                     <div class="fr-content">
                        <div id="" class="fr-content__primary--text">
                            Finance
                        </div>
                      </div>
                    </span>
            </div>
        </div>
        <div class="fr-flex__col-4">
            <div class="fr-card__dashboard" (click)="showDashboard('Teams')"> 
                  <span class="fr-symbol">
                    <span class="fr-icon__box"> 
                        <span class="fr-icon">
                            <img src="../../../../assets/images/OnboardsAssociates.svg" width="30" alt="">
                         </span>
                     </span>
                     <div class="fr-content">
                        <div id="" class="fr-content__primary--text">
                            Teams
                        </div>
                      </div>
                    </span>
            </div>
        </div>
    </div>

    
</div>


