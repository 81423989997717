<!DOCTYPE html>
<html lang="en">
<head>
   <title> Associate Add & Edit </title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>


<form [formGroup]="myTeamAssociateForm">
   <div class="card-pad"> 
      <div class="fr-flex__container sticky-header">
         <div class="fr-flex__col-6">
            <h3>{{associateTitle}}</h3>
         </div>
         <div class="fr-flex__col-6">  
            <div class="preview_section pull-right" *ngIf="associateTitle == 'Edit Associate'">
               <button  [class.previous]="currentIndex !== 0"  (click)="pageChangeConfirmation(2)" [disabled]="currentIndex === 0 ||buttonDisabled"><i class="pi pi-arrow-up" pTooltip="Preview" tooltipPosition="top"></i></button>
               <span class="numbers_list">{{getCurrentPositionText()}}</span>
               <button [class.next]="currentIndex !== listOfAssociates.length - 1" (click)="pageChangeConfirmation(1)"  [disabled]="currentIndex === listOfAssociates.length - 1 ||buttonDisabled"><i class="pi pi-arrow-down" pTooltip="Next" tooltipPosition="top"></i></button>
               </div> 
            </div>
      </div>
      <div class="scroll-start-here">
<div class="fr-flex__container">
    
    <div class="fr-flex__col-4">
       <div class="fr-form__group">
          <label> Associate Name   <em> * </em><span class="max-length"> {{teamAssociateData.associateName?.length ||
            0}}/50 </span>
        </label>
          <input type="text" class="form-control" formControlName="associateName"  pInputText [(ngModel)]="teamAssociateData.associateName" [maxlength]="50" trimSpace />
          <small class="p-error" *ngIf="submitted && myTeamAssociateForm?.get('associateName')?.hasError('required')">This field is required.</small>
          <small class="p-error" *ngIf="submitted && myTeamAssociateForm?.get('associateName')?.hasError('maxlength')">Max length is 50 characters.</small>
          <small class="p-error" *ngIf="submitted && myTeamAssociateForm?.get('associateName')?.hasError('pattern')">Only alpha characters are allowed.</small>
          <small class="p-error" *ngIf="myTeamAssociateForm.get('associateName')?.hasError('onlySpaces')">Only spaces not allowed.</small>
        </div>
    </div>
    <div class="fr-flex__col-4">
    <div class="fr-form__group"> 
        <label>Contract   <em> * </em></label>
        <p-dropdown [tabindex]="disableContract ? -1 : 0" appendTo="body" class="team-read-only-dropdown" [class.read-only-dropdown]="disableContract" formControlName="contractDetailId" [filter]="true" [options]="engagementContractList" placeholder="Select Contract" [(ngModel)]="teamAssociateData.contractDetailId" optionLabel="title" [disabled]="disableContract" optionValue="id" (ngModelChange)="contractChange()"></p-dropdown>
        <small class="p-error" *ngIf="submitted && myTeamAssociateForm?.get('contractDetailId')?.hasError('required')">This field is required.</small>
    </div>
   </div>
    <div class="fr-flex__col-4">
       <div class="fr-form__group">
          <label> Allocation  <em> * </em></label>
          <p-dropdown appendTo="body" [filter]="true" class="team-read-only-dropdown" [class.read-only-dropdown]="allocationTypeDisableFlag" formControlName="allocationTypeId" [options]="allocationTypeData" placeholder="Select Allocation Type" [(ngModel)]="teamAssociateData.allocationTypeId" optionLabel="name" optionValue="id" [disabled]="allocationTypeDisableFlag"></p-dropdown>
          <small class="p-error" *ngIf="submitted && myTeamAssociateForm?.get('allocationTypeId')?.hasError('required')">This field is required.</small>
        </div>
    </div>
   
 </div>
 <div class="fr-flex__container">
    <div class="fr-flex__col-4">
       <div class="fr-form__group">
          <label> Location    <em> * </em></label>
          <p-dropdown appendTo="body" [filter]="true" formControlName="countryId" [options]="countryData" placeholder="Select Location" [(ngModel)]="teamAssociateData.countryId" optionLabel="name" optionValue="id"></p-dropdown>
          <small class="p-error" *ngIf="submitted && myTeamAssociateForm?.get('countryId')?.hasError('required')">This field is required.</small>
        </div>
    </div>
    <div class="fr-flex__col-4">
      <div class="fr-form__group">
         <label> Email    <em> * </em>
            <span class="max-length"> {{teamAssociateData.email?.length ||
                0}}/100 </span>
        </label>
         <input formControlName="email" [maxlength]="100" type="text" class="form-control" pInputText [(ngModel)]="teamAssociateData.email" required pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"/>
         <small class="p-error" *ngIf="submitted && myTeamAssociateForm?.get('email')?.hasError('required')">This field is required.</small>
         <small class="p-error" *ngIf="submitted && myTeamAssociateForm?.get('email')?.hasError('maxlength')"> Maximum length is 100 characters. </small>
         <small class="p-error" *ngIf="submitted && myTeamAssociateForm?.get('email')?.hasError('pattern')">Please enter valid email</small>
        </div>
   </div>
   <div class="fr-flex__col-4"> 
   <div class="display-flex">
    <div class="fr-flex__col-3 custom-pad-zero">
       <div class="fr-form__group">
          <label>Country Code<em *ngIf="countryCodeMandatoryastrikflag"> * </em></label>
          <p-dropdown appendTo="body" formControlName="countryCode" [options]="countryData" [filter]="true"  formControlName="countryCode" optionLabel="sub_Code" optionValue="sub_Code" placeholder="Select Country Code" [(ngModel)]="teamAssociateData.countryCode" (ngModelChange)="countryCodeChange()"></p-dropdown>
          <small class="p-error" *ngIf="countryCodeMandatoryflag">This field is required.</small>
         </div>
    </div>
    <div class="fr-flex__col-9 custom-pad-right-zero">
       <div class="fr-form__group">
          <label>Phone Number
            <span class="max-length"> {{teamAssociateData.phoneNumber?.length ||
                0}}/15 </span>    
        </label>
          <input formControlName="phoneNumber" [maxlength]="15" type="text" class="form-control" pInputText [(ngModel)]="teamAssociateData.phoneNumber" (ngModelChange)="offPhoneNoChange()" pattern="^[0-9]+$"/>
          <small class="p-error" *ngIf="submitted && myTeamAssociateForm?.get('phoneNumber')?.hasError('minlength')"> Minimum length is 7 characters. </small>
          <small class="p-error" *ngIf="submitted && myTeamAssociateForm?.get('phoneNumber')?.hasError('maxlength')"> Maximum length is 15 characters. </small>
          <small class="p-error" *ngIf="submitted && myTeamAssociateForm?.get('phoneNumber')?.hasError('pattern')">Please enter only numeric numbers.</small>
        </div>
    </div>
 </div>
</div>
 </div>
 <div class="fr-flex__container">
    <div class="fr-flex__col-12">
        <div class="fr-form__group">
           <label> Address 
            <span class="max-length"> {{teamAssociateData.address?.length ||
                0}}/300 </span>    
        </label>
           <textarea class="form-control" [maxlength]="300" formControlName="address" pInputTextarea [(ngModel)]="teamAssociateData.address" trimSpace ></textarea>
           <small class="p-error" *ngIf="submitted && myTeamAssociateForm?.get('address')?.hasError('maxlength')"> Maximum length is 300 characters. </small>
        </div>
     </div>
 </div>
 <div class="fr-flex__container">
    <div class="fr-flex__col-4">
       <div class="fr-form__group">
          <label> Status     <em> * </em></label>
          <p-dropdown [tabindex]="actionType=='Create' ? -1 : 0" appendTo="body" [filter]="true" class="team-read-only-dropdown" [class.read-only-dropdown]="actionType=='Create'" [disabled]="actionType=='Create'" formControlName="statusMappingId" [options]="associatesStatusData" placeholder="Select Status" [(ngModel)]="teamAssociateData.statusMappingId" optionLabel="name" optionValue="id" (onChange)="associateStatusChangeClick($event)"></p-dropdown>
          <small class="p-error" *ngIf="submitted && myTeamAssociateForm?.get('statusMappingId')?.hasError('required')">This field is required.</small>
        </div>
    </div>
    <div class="fr-flex__col-4">
      <div class="fr-form__group">
         <label> Onboarding Date    <em> * </em></label>
         <p-calendar appendTo="body" formControlName="onBoardingDate" [(ngModel)]="teamAssociateData.onBoardingDate" [showIcon]="true" (ngModelChange)="onBoardChange()" [dateFormat]=_dateformart.CalenderDateFormat></p-calendar>
         <small class="p-error" *ngIf="submitted && myTeamAssociateForm?.get('onBoardingDate')?.hasError('required')">This field is required.</small>
        </div>
   </div>
   <div class="fr-flex__col-4">
    <div class="fr-form__group">
       <label> Offboarding Date <em *ngIf="mandatorySymbolFlag"> * </em></label>
       <p-calendar appendTo="body" formControlName="offBoardingDate" [(ngModel)]="teamAssociateData.offBoardingDate" [dateFormat]=_dateformart.CalenderDateFormat [showIcon]="true" (ngModelChange)="offBoardChange()" [minDate]="teamAssociateData.onBoardingDate ? teamAssociateData.onBoardingDate : olddate" [ngClass]="{'read-only-calendar-condication':offBoardStatus}" [disabled]="offBoardStatus"></p-calendar>
       <small class="p-error" *ngIf="isStatusOffBoard">This field is required.</small>
       
       
    </div>
 </div>
 </div>
 <div class="fr-flex__container">
   <div class="fr-flex__col-12">
       <div class="fr-form__group">
          <label> Offboarding Reason  <em *ngIf="mandatorySymbolFlag"> * </em>
           <span class="max-length"> {{teamAssociateData.offboardingReason?.length ||
               0}}/300 </span>
          </label>
          <textarea class="form-control" [maxlength]="300" formControlName="offboardingReason" pInputTextarea [(ngModel)]="teamAssociateData.offboardingReason" trimSpace ></textarea>
          <small class="p-error" *ngIf="submitted && myTeamAssociateForm?.get('offboardingReason')?.hasError('maxlength')"> Maximum length is 300 characters. </small>
          <small class="p-error" *ngIf="submitted && myTeamAssociateForm?.get('offboardingReason')?.hasError('required')">This field is required.</small>
          <small class="p-error" *ngIf="submitted && myTeamAssociateForm?.get('offboardingReason')?.hasError('onlySpaces')">Only spaces not allowed.</small> 
       </div>
    </div>
</div>
 <div class="fr-flex__container">
    <div class="fr-flex__col-4">
       <div class="fr-form__group">
          <label> Role      <em> * </em></label>
          <p-dropdown appendTo="body" [filter]="true" formControlName="associateRoleId" [options]="associateRolesData" placeholder="Select Role" [(ngModel)]="teamAssociateData.associateRoleId" optionLabel="name" optionValue="id"></p-dropdown>
          <small class="p-error" *ngIf="submitted && myTeamAssociateForm?.get('associateRoleId')?.hasError('required')">This field is required.</small>
        </div>
    </div>
    <div class="fr-flex__col-4">
      <div class="fr-form__group">
         
       <label> Functional Head   <em></em><span class="max-length"> {{teamAssociateData.functionalHead?.length ||
         0}}/50 </span>
     </label>
       <input type="text" class="form-control" formControlName="functionalHead"  pInputText [(ngModel)]="teamAssociateData.functionalHead" [maxlength]="50" trimSpace/>
       <small class="p-error" *ngIf="submitted && myTeamAssociateForm?.get('functionalHead')?.hasError('maxlength')"> Maximum length is 50 characters. </small>
       <small class="p-error" *ngIf="submitted && myTeamAssociateForm?.get('functionalHead')?.hasError('pattern') && !myTeamAssociateForm?.get('functionalHead')?.hasError('maxlength')">Only spaces, only numeric characters and only special characters are not allowed</small>
     </div>
   </div>
   
 </div>



 <div class="fr-flex__container">
   <div class="fr-flex__col-4">
      <div class="fr-form__group">
         <label> Sponsor Username 
            <span class="max-length"> {{teamAssociateData.sponsorUsername?.length ||
                0}}/30 </span>    
        </label>
         <input formControlName="sponsorUsername" [maxlength]="30" type="text" class="form-control" pInputText [(ngModel)]="teamAssociateData.sponsorUsername" trimSpace />
         <small class="p-error" *ngIf="submitted && myTeamAssociateForm?.get('sponsorUsername')?.hasError('maxlength')"> Maximum length is 30 characters. </small>
         <small class="p-error" *ngIf="myTeamAssociateForm.get('sponsorUsername')?.hasError('onlySpaces')">Only spaces not allowed.</small> 
       </div>
    </div>
    <div class="fr-flex__col-4">
      <div class="fr-form__group">
         <label> Supplier Username  
          <span class="max-length"> {{teamAssociateData.engagementUsername?.length ||
              0}}/30 </span>
  
         </label>
         <input formControlName="engagementUsername" [maxlength]="30" type="text" class="form-control" pInputText [(ngModel)]="teamAssociateData.engagementUsername" trimSpace/>
         <small class="p-error" *ngIf="submitted && myTeamAssociateForm?.get('engagementUsername')?.hasError('maxlength')"> Maximum length is 30 characters. </small>
         <small class="p-error" *ngIf="myTeamAssociateForm.get('engagementUsername')?.hasError('onlySpaces')">Only spaces not allowed.</small> 
        </div>
    </div>
 </div>

 <div class="fr-flex__container">
   <div class="fr-flex__col-4">
  
      <div class="fr-form__group">
       <label> Sponsor Asset  <em> * </em></label>
       <p-dropdown appendTo="body" [filter]="true" formControlName="sponsorsAsset" [options]="sponsorsAssestsData" placeholder="Select" [(ngModel)]="teamAssociateData.sponsorsAsset" optionLabel="name" optionValue="id"></p-dropdown>
       <small class="p-error" *ngIf="submitted && myTeamAssociateForm?.get('sponsorsAsset')?.hasError('required')">This field is required.</small>
     </div>
     </div>
   <div class="fr-flex__col-4">
      <div class="fr-form__group">
         <label> Supplier Asset  
   
           <span class="max-length"> {{teamAssociateData.supplierAsset?.length ||
               0}}/50 </span>
         </label>
         <input formControlName="supplierAsset" [maxlength]="50" type="text" class="form-control" pInputText [(ngModel)]="teamAssociateData.supplierAsset" trimSpace/>
         <small class="p-error" *ngIf="submitted && myTeamAssociateForm?.get('supplierAsset')?.hasError('maxlength')"> Maximum length is 50 characters. </small>
       </div>
    </div>

   </div>
<div class="fr-flex__container">
    <div class="fr-flex__col-12">
      <div class="fr-form__group">
         <label> Sponsor Asset Details  
   
           <span class="max-length"> {{teamAssociateData.sponsorAssetDetails?.length ||
               0}}/300 </span>
         </label>
         
         
         <textarea class="form-control" [maxlength]="300" formControlName="sponsorAssetDetails" pInputTextarea [(ngModel)]="teamAssociateData.sponsorAssetDetails" trimSpace ></textarea>
         <small class="p-error" *ngIf="submitted && myTeamAssociateForm?.get('sponsorAssetDetails')?.hasError('maxlength')"> Maximum length is 300 characters. </small>
         <small class="p-error" *ngIf="submitted && myTeamAssociateForm?.get('sponsorAssetDetails')?.hasError('onlySpaces')">Only spaces not allowed.</small> 
       </div>
    </div>
</div>

   <div class="fr-flex__container">
    <div class="fr-flex__col-12">
        <div class="fr-form__group">
           <label> Notes 
            <span class="max-length"> {{teamAssociateData.notes?.length ||
                0}}/300 </span>

           </label>
           <textarea class="form-control" [maxlength]="300" formControlName="notes" pInputTextarea [(ngModel)]="teamAssociateData.notes" trimSpace ></textarea>
           <small class="p-error" *ngIf="submitted && myTeamAssociateForm?.get('notes')?.hasError('maxlength')"> Maximum length is 300 characters. </small>
        </div>
     </div>
 </div>


 <div *ngIf="actionType=='Edit'" class="fr-flex__container">
    <div class="fr-flex__col-3">
       <div class="fr-form__view">
          <label> Added By</label>
          <span> <em>  {{createdBy}} </em></span>
          <span *ngIf="createdBy ==null"> <em> --</em></span>
       </div>
    </div>
    <div class="fr-flex__col-3">
       <div class="fr-form__view">
          <label> Added Date & Time </label>
          <span *ngIf="teamAssociateData.createdDate !=null"> <em>  {{teamAssociateData.createdDate | date: _dateformart.DateTimeWithZone}}{{_dateformart.DisplayUTC}} </em></span>
          <span *ngIf="teamAssociateData.createdDate ==null"> <em> --</em></span>
       </div>
    </div>
    <div class="fr-flex__col-3" >
       <div class="fr-form__view">
          <label> Modified By</label>
          <span  *ngIf="updatedBy !=null"> <em>  {{updatedBy}} </em></span>
          <span *ngIf="updatedBy == null || updatedBy == undefined || updatedBy == ''"> <em> -- </em></span>
       </div>
    </div>
    <div class="fr-flex__col-3" >
       <div class="fr-form__view">
          <label> Modified Date & Time </label>
          <span  *ngIf="updatedBy !=null && updatedBy != undefined && updatedBy != ''"> <em> {{teamAssociateData.updatedDate | date: _dateformart.DateTimeWithZone}}{{_dateformart.DisplayUTC}}</em></span>
          <span *ngIf="updatedBy ==null || updatedBy == undefined || updatedBy == ''"> <em> -- </em></span>
        </div>
    </div>
 </div>

 <div class="fr-flex__container footer_btns">
    <div class="fr-flex__col-12">
       <a type="button" class="btn btn-primary btn-save pull-right" [class.disabled]="isSaveBtnDisable" (click)="isSaveBtnDisable ? null : saveTeamAssociateDetails()"> Save </a>
       <a type="button" class="btn btn-secondary pull-right" (click)="cancelAssociate()"> Cancel </a>
    </div>
 </div>
 </div>
</div>
</form>
</body>
</html>