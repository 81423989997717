<div class="card flex justify-content-center">
    <p-breadcrumb class="max-w-full" [model]="getBreadcrumbs()"></p-breadcrumb>
</div>
<div class="container page-title">
    <div class="flex-box"> 
        <div class="fr-flex__container">
            <div class="fr-flex__col-8">
                <h1>KPI Trend Dashboards</h1>
            </div>
        </div>
    </div>
    <p-tabView class="fr-tabview__header__custom"> 
        <p-tabPanel>
<form [formGroup]="dashboardForm"  *ngIf="IsShowButton">
    <div class="fr-flex__col-12">
<div class="fr-flex__container m-t-10">
    
    <div class="fr-flex__col-3">
        <div class="fr-form__group">
            <label>Engagement <em> * </em></label>
            <p-dropdown appendTo="body" [filter]="true" [options]="engagements" [(ngModel)]="dashboardDetails.EngagementId" placeholder="Select Engagement" optionLabel="label" optionValue="value"
                (onChange)="onEngagementChange($event)" formControlName="dashboardEngagement" [disabled]="disableEngagement" ></p-dropdown>
                <small class="p-error" *ngIf="dashboardApplyClicked && dashboardForm?.get('dashboardEngagement')?.hasError('required')">This field is required.</small>

        </div>
    </div>
    <div class="fr-flex__col-3">
        <div class="fr-form__group">
            <label>Contract <em> * </em></label>
            <p-dropdown appendTo="body" [filter]="true" [disabled]="IsContractDisabled" [options]="contracts" [(ngModel)]="dashboardDetails.ContractId" optionLabel="label" optionValue="value"
            formControlName="dashboardContract" [placeholder]="placeholder" [disabled]="disableEngagement" (onChange)="onContractChange()"></p-dropdown>
            <small class="p-error" *ngIf="dashboardApplyClicked && dashboardForm?.get('dashboardContract')?.hasError('required')">This field is required.</small>
        </div>
    </div>
    <div class="fr-flex__col-1 custom-pad-left-zero">
        <div class="fr-form__group">
            <label>&nbsp;</label>
            <button class="btn btn-primary pull-left" (click)="onApplyClick()" [disabled]="disableApplyBtn"> Apply</button>
            </div>
        </div>
    </div>
</div>
</form>
<!-- <div id="dashboardContainer"></div> -->

<div>
    
    <p-paginator *ngIf="showPaginator" #paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="kpiPerPage" [totalRecords]="this._kPIListData.length"></p-paginator>

    
   <div style="text-align: right;" *ngIf="showPaginator">{{firstRecOfPage}} - {{lastRecOfPage}} out of {{this._kPIListData.length}}</div>
    <div id="dashboardContainer"></div>
  
    <p-paginator *ngIf="showBottomPaginator" #paginatorBottom (onPageChange)="onBottomPageChange($event)" [first]="first" [rows]="kpiPerPage" [totalRecords]="this._kPIListData.length"></p-paginator>

</div>


<!-- =============================================================== -->
</p-tabPanel>
</p-tabView>
<div class="margin-center"> 
    <span *ngIf="!IsShowButton"> You does not have privilage to geneare and show Darshboard </span>
</div>
</div>

