import { Component } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  showContent: boolean = false;
  value!: string;
  ingredient!: string;
  
  selectedDiv: string = 'div1';
  constructor() {}
}

