
export class ContractDetails
{
    Id?:number;
    Title?: string;
    ContractId?:string;
    StartDate?:any;
    EndDate?: any;
    Term?: string;
   Value?:any;
    TotalValue?: any;
    ValuePerYear?:any;
    SupplierId?:number;
    SupplierMasterServiceAgreementId?:number;
    EngagementMasterServiceAgreementId?:number;
    OwnerSupplierContactId?:number;
    SponserContactId?:number;
    OwnerFunctionId?:number;
    StatusMappingId?:number;
    CurrencyId?:number;
    Description?:string;
    NotesandComments?:string;
    SowFileName?:string;
    SowFilePath?:string;
    createdBy?: number;
    createdDate?: Date;
    updatedBy?: number;
    updatedDate?: Date;
    isDeleted?: Boolean;
    sponserId?: string;
    PONumber?: string;
    POValue?:number;
    POStartDate?: any;
    POEndDate?: any;
    POFileName?:string;
    POFilePath?:string;
    PORemarks?:string;
    EngagementId?:number;
    BillingTypes?:ContractBillingTypes[];
    ChangeOrderDetails?:ContractChangeOrder[];
    AddendumDetails?:ContracAddendumDetails[];
    SponsorStakeholderDetails?:ContractStakeHolderSponsorContact[];
    SupplierStakeholderDetails?:ContractStakeHolderSupplierContact[];
}
export class ContractChangeOrder {
    id: number=0;
    title?: string="";
    StartDate?: any;
    EndDate?: any;
    value?:any;
    currency?:number;
    currencyId?:number;
    status?:string="";
    statusMappingId: number=0;
    description?:string="";
    _changeOrderfileSource?:string="";
    fileName?:string="";
    filePath?:string="";
    
  
  }

  export class ContracAddendumDetails {
    id: number=0;
    title?: string="";
    StartDate?: any;
    EndDate?: any;
    status?:string="";
    statusMappingId: number=0;
    description?:string="";
    _addendumDetailfileSource?:string="";
    fileName?:string="";
    filePath?:string="";
    
  
  }

export class KpiChildComponents
{
    conKpiList:number=1;
    conKpiAdd:number=2;
    conKpiEdit:number=3;
    conKpiView:number=4;
}

  export class ContractBillingTypes {
    Id: number=0; 
    BillingTypeId: any=0;
    ContractDetailId: number=0; 
  }
export class MeetingChildComponents
{
    conMeetingList:number=1;
    conMeetingAdd:number=2;
    conMeetingEdit:number=3;
    conMeetingView:number=4;
    conMeetingEditRecYes:number=5;
}
export class ContractStakeHolderSponsorContact
{
  Id:number=0;
  ContractId:number=0;
  sponsorContactId:any;
  isSendNotification:Boolean=false;
  sendNotificationOptions?: number[];
}
export class ContractStakeHolderSupplierContact
{
  Id:number=0;
  ContractId:number=0;
  supplierContactId:any;
  isSendNotification:Boolean=false;
  sendNotificationOptions?: number[];
} 



