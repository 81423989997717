import { DatePipe, formatDate } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, NgForm, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TabView } from 'primeng/tabview';
import { ContractService } from 'src/app/Contracts/Services/contract.service';
import { FileUploadService } from 'src/app/Engagement/Services/fileupload.service';
import { FinanceInvoiceAttachments, InvoiceDetails, InvoiceEmailPayload } from 'src/app/Finance/finances/Model/ContractInvoice';
import { FinanceService } from 'src/app/Finance/Services/invoice.service';
import { EngagementAuditService } from 'src/app/Governance/audits/Engagements/Services/engagement-audit-service.service';
import { LoaderService } from 'src/app/Loader/loader.service';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';
import { CurrencyService } from 'src/app/Reusable/currency.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { AuditIdentifier, AuditLogEntities, AuditLogModule, AuditOperations, CodeList, CodeListStatusTypes, Dateformat, StatusCodes,CurrencyType } from 'src/app/shared/constants/global.constants';
import { AuditLogService } from 'src/app/shared/ModelComparison/audit.log.service';
import { NotificationService } from 'src/app/shared/Notifications/notification.service';
import { TabAndStateService } from 'src/app/shared/TabAndStateService/TabAndState.Service';



@Component({
  selector: 'app-cont-invoice-edit',
  templateUrl: './cont-invoice-edit.component.html',
  styleUrls: ['./cont-invoice-edit.component.css']
})
export class ContInvoiceEditComponent {
 
  @Input() invoiceId:any;
  @Input() ContractId:any;

  @ViewChild('mySupplierAuditForm') childForm: NgForm | undefined;
  @ViewChild('myTabView', { static: false }) tabView!: TabView;
  @Output() saveInvoiceButtonClick = new EventEmitter<void>();
  @Output() calcelInvoiceButtonClick = new EventEmitter<void>();
  @Output() viewButtonClick: EventEmitter<any> = new EventEmitter<any>();
  currencyformat:any;
  myContractInvoiceForm: FormGroup;
  submitted: boolean=false;
  isSaveBtnDisable = false;
  contractInvoice=new InvoiceDetails();
  codeListStatusTypes: any;
  _dateformart :any;
  codeListValues:any;
  daysToDueDate: number | string = '';
  statusData:any;
  maxFileSize:any
  uploadedInvoiceFileData: any;
  timeStamp:any;
  formData =  new  FormData();
  contractInvoiceAttachments:FinanceInvoiceAttachments[]=[];
  contractInvoiceAttachment=new FinanceInvoiceAttachments();
  isPaymentDateEditable: boolean = false;
  showPlaceholder: boolean = false;
  ispaymentAmountEnable:boolean=true;
  statusCodes:any;
  invoiceRaisedMinDate:any;
  @Input() SupplierId: any;
  @Input() SponsorId: any;
  emailBodyData=new InvoiceEmailPayload();
  currency:any;
  currentIndex: number = -1; 
  listOfInvoices:any;
  constructor(
    private router: Router, private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private masterDataService: SponserService,
    private _engagementAuditService: EngagementAuditService,
    private _financeInvoiceService:FinanceService,
    private activeRouter: ActivatedRoute,private datePipe: DatePipe,
    private route: ActivatedRoute,private contractService: ContractService,
    private _masterDataService: SponserService, private loaderService: LoaderService, private breadcrumbService: BreadcrumbService, private _fileService: FileUploadService,private notificationService:NotificationService,private auditLogService: AuditLogService,
    private privilegeService: PrivilegeService,
    private _contractService:ContractService,
    private currencyService: CurrencyService, private tabAndStateService: TabAndStateService) {
      this.statusCodes = StatusCodes;
      this.myContractInvoiceForm = this.fb.group({
        invoicenumber: ['', [Validators.required, Validators.maxLength(15),this.noOnlySpacesValidator,Validators.pattern(/^\S*$/)]],
        invoicevalue:['',[Validators.required, Validators.maxLength(15),this.validateInvoiceNoField]],
        invoiceRaisedDate:['',[Validators.required]],
        dueDate:['',[Validators.required]],
        draftInvoiceApprovedDate:[''],
        draftInvoicesentdDate:[''],
        statusMappingId:['',[Validators.required]],
        //invoicefileSource: ['', Validators.required], invoiceFileName: '',
      invoicefileSource: [''], invoiceFileName: '',
        daystoDueDate:[''],
        remarks:['',[Validators.maxLength(300)]],
        description:['',[Validators.maxLength(300)]],
        paymentAmount: ['', [this.conditionalRequired(this.contractInvoice.Status === this.statusCodes.Paid), Validators.maxLength(15), this.validateNumericField]],
        paymentDate: ['', [this.conditionalRequired(this.contractInvoice.Status === this.statusCodes.Paid), Validators.required]],
  
       })
       
  }
  showAttachmentButton: boolean = false;
  showAttachedFile: boolean = true;
  auditIdentifierDetails:any;
  auditEntities:any;
  auditOperations:any;
  auditModule:any;


  
  validateInvoiceNoField(control: any) {
    const numericRegex = /^-?\d+(\.\d{1,2})?$/;
    if (control.value && !numericRegex.test(control.value)) {
      return { invalidNumber: true };
    }
    return null;
  }

  ngOnInit() {
    this._dateformart=Dateformat;
    this.invoiceRaisedMinDate=null;
    this.ContractId= this.route.snapshot.queryParamMap.get('ContractId');
    this.codeListValues=CodeList;
    this.codeListStatusTypes=CodeListStatusTypes
    this.GetInvoiceStatus(this.codeListValues.Tbl_Status, this.codeListStatusTypes.INVSTS);
    setTimeout(() => {
      this.GetContractInvoiceDetails(this.invoiceId);
      this.formOldContractInvoice();
    }, 1000);
    this.statusCodes = StatusCodes;  
    this.auditIdentifierDetails=AuditIdentifier;
    this.auditEntities=AuditLogEntities;
    this.auditOperations=AuditOperations;
    this.auditModule=AuditLogModule;
    this.currencyformat=CurrencyType;
    this.currency= this.activeRouter.snapshot.queryParamMap.get('currency');
    this.listOfInvoices=this.tabAndStateService.getInvoicesData();
    this.currentIndex = this.listOfInvoices.findIndex((a: { id: any; }) => a.id ==this.invoiceId);
  this.GetContractDetails();
  }
  conditionalRequired(condition: boolean) {
    return (control: AbstractControl) => {
      if (condition) {
        return null; 
      } else {
        return Validators.required(control); 
      }
    };
  }
  convertDateFormat(inputDateTime: string) {
    const date = new Date(inputDateTime);
    const formattedDate = this.datePipe.transform(date, this._dateformart.CalenderDateFormat);
    return formattedDate;
  }
  GetInvoiceStatus(enumId: number, type: string) { 
    this.masterDataService.getCodeListData(enumId, type).subscribe(
      res => {
        if (res.data.length > 0) {
          this.statusData = res.data;
        }
      }
    );
  };
  existingDueDate:any;
  contractinvoicedata:any;
  existingPaymentDate:any;
  createdBy:any;
  updatedBy:any;
  isStatusPaid=false;
  GetContractInvoiceDetails(ContractId:number){
    this.loaderService.showLoader();
     this._financeInvoiceService.GetContractFinanceInvoiceDetailsById(ContractId).subscribe(
      res => {
        if (res.data) {

          this.contractinvoicedata = res.data;
          this.contractInvoice.Id=res.data.id;
          this.contractInvoice.InvoiceNumber=res.data.invoiceNumber;
          this.contractInvoice.InvoiceValue=res.data.invoiceValue;
          this.createdBy =this.privilegeService.getUserName(res.data.createdBy);
          this.contractInvoice.createdDate=res.data.createdDate;
          this.updatedBy =this.privilegeService.getUserName(res.data.updatedBy);
         
          this.contractInvoice.updatedDate=res.data.updatedDate;

          if(res.data.draftInvoiceSentDate != null)
          {
          this.contractInvoice.ContractInvoiceSentDate=new Date (res.data.draftInvoiceSentDate);
          }

          if(res.data.draftInvoiceApprovedDate != null){
          this.contractInvoice.ContractInvoiceApprovedDate=new Date(res.data.draftInvoiceApprovedDate);
          }

          this.contractInvoice.ContractInvoiceRaisedDate=new Date(res.data.invoiceRaisedDate);
          this.contractInvoice.ContractInvoiceDueDate=new Date(res.data.dueDate);
          this.existingDueDate=this.contractInvoice.ContractInvoiceDueDate;
          if(res.data.paymentDate){
          this.contractInvoice.ContractInvoicePaymentDate=new Date(res.data.paymentDate);
          }
          this.existingPaymentDate=res.data.paymentDate;         
          const existingStatus = this.statusData.find((status: { id: any; }) => status.id === res.data.statusMappingId);
          if (existingStatus) {
          this.selectedStatus = existingStatus.code;
          this.emailBodyData.PreviousStatus=existingStatus.name;
            } 

            if(this.selectedStatus=='PAD')
              {
              this.isStatusPaid=true;
              this.isPaymentDateEditable=false;
            }
            else{
              this.isStatusPaid=false;
            }


          this.contractInvoice.StatusMappingId= res.data.statusMappingId;
          if(this.existingPaymentDate==null && this.selectedStatus!=this.statusCodes.Rejected){
          this.DueDateChange( this.existingDueDate);
          }
          else{
            this.contractInvoice.DaysToDueDate='NA';
          }

   
          this.contractInvoice.PaymentAmount=res.data.paymentAmount;       
          this.contractInvoice.Remarks=res.data.remarks;
          this.contractInvoice.InvoiceDescription=res.data.invoiceDescription;
           this.contractInvoiceAttachment.FileName=res.data.fileName;
           this.contractInvoiceAttachment.FilePath=res.data.filePath;
           this.contractInvoiceAttachment.FinanceContractInvoiceId=res.data.financeContractInvoiceId;
           
           if(res.data.fileName != null){
           const newAttachment = new FinanceInvoiceAttachments();
            newAttachment.FileName = res.data.fileName;
            newAttachment.FilePath = res.data.filePath;

           
            


            newAttachment.FinanceContractInvoiceId = res.data.financeContractInvoiceId;
            this.contractInvoiceAttachments.push(newAttachment);
           }

            if(res.data.fileName == null){
              this.showAttachmentButton=true;
              this.showAttachedFile=false;
            }

           this.contractInvoice.FinanceInvoiceAttachments=this.contractInvoiceAttachments;

           if(res.data.draftInvoiceSentDate != null){
           this.contractInvoice.DraftInvoiceSentDate =new Date (res.data.draftInvoiceSentDate);
           }

           if(res.data.draftInvoiceApprovedDate != null){
          this.contractInvoice.DraftInvoiceApprovedDate = new Date(res.data.draftInvoiceApprovedDate);
           }
           
           this.contractInvoice.InvoiceRaisedDate = new Date(res.data.invoiceRaisedDate);
           this.contractInvoice.DueDate =new Date(res.data.dueDate);

           this.oldContractInvoiceData=JSON.stringify(this.contractInvoice);
           this.contractInvoiceDetailBeforeUpdate=JSON.stringify(this.contractInvoice);
           setTimeout(() => {
            this.loaderService.hideLoader();
          },1500); 
          
        }
      }
    );  
  }



  status:any;
  getStatusName(statusId: number): string {
    this.status = this.statusData.find((s: { id: number; }) => s.id === statusId);
    return this.status ? this.status.name : 'Unknown'; 
  }
  noOnlySpacesValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value && control.value.trim().length === 0) {
      return { onlySpaces: true };
    }
    return null;
  }
  validateNumericField(control: any) {
    const numericRegex = /^\d+(\.\d{1,2})?$/;
    if (control.value && !numericRegex.test(control.value)) {
      return { invalidNumber: true };
    }
    return null;
  }
  getDefaultMinDate(): Date {
    return  new Date('1970-01-01')
  }
  onInvoiceFileChange(fileDetails:any) {

    this.maxFileSize = this._fileService._fileSize;
    if(fileDetails.length > 0)
    {
     var currentFileSize=fileDetails[0].size;
     var uploadedFileName = fileDetails[0].name;
  
     var uploadedExtension = uploadedFileName.split('.').pop().trim().toLowerCase();
  
      if (this._fileService.allowedExtensions.includes(uploadedExtension))  {
     if(currentFileSize<=this.maxFileSize)
      {
          this.myContractInvoiceForm.patchValue({
           invoiceFileName: fileDetails[0].name,
          })
         this.uploadedInvoiceFileData = fileDetails[0];
   }
  
   else{
      this.myContractInvoiceForm.patchValue({
       invoiceFileName: '',
        invoicefileSource: ''
    })
    this. contractInvoiceAttachment.FileName='';
    this.messageService.add({  key: 'tc',severity: 'warn', summary: 'File Size limited to 20 Mb', detail: '' });
  }
  }
  else {
   this.myContractInvoiceForm.patchValue({
    invoiceFileName: '',
    invoicefileSource: ''
  })
    this. contractInvoiceAttachment.FileName='';
   this.messageService.add({ key: 'tc', severity: 'error', summary: 'Invalid file type. Only .pdf, .xls, .xlsx, .doc, .docx files are allowed.', detail: '' });
  }

  this.contractInvoiceAttachment.FileName=fileDetails[0].name;
  
  }

this.showAttachmentButton=false;
this.showAttachedFile=true;
  }
  selectedStatus:any;
  onStatusChange(event:any){
    var selectedInvoiceStatus = this.statusData?.filter((t: { id: number | undefined; })=>t.id==this.contractInvoice.StatusMappingId);
             if(selectedInvoiceStatus.length>0 && selectedInvoiceStatus[0].code != undefined){
               this.contractInvoice.Status =selectedInvoiceStatus[0].code;
               this.selectedStatus=this.contractInvoice.Status;
             }
            if(  this.contractInvoice.Status === this.statusCodes.Rejected){
              this.contractInvoice.DaysToDueDate='NA';
              this.showPlaceholder=false;
              this.ispaymentAmountEnable=true;
            }
            if ( this.contractInvoice.Status === this.statusCodes.Paid) {
              this.contractInvoice.DaysToDueDate='NA';
              this.isPaymentDateEditable = true;
              this.ispaymentAmountEnable=false;
  
            }
            if( this.contractInvoice.Status!== this.statusCodes.Paid && this.contractInvoice.Status !== this.statusCodes.Rejected ) {
              this.isPaymentDateEditable = false;
              this.ispaymentAmountEnable=true;
              if(this.existingDueDate==this.contractInvoice.ContractInvoiceDueDate){
              this.DueDateChange(this.contractInvoice.ContractInvoiceDueDate);
              }
              else{
                this.onDueDateChange(this.contractInvoice.ContractInvoiceDueDate);
              }
            }
            if(this.contractInvoice.Status!== this.statusCodes.Paid){
              this.myContractInvoiceForm.patchValue({
                paymentDate:null,
                paymentAmount:null
              })
            }
    }
    onDueDateChange(event:any) {
       if(event!=undefined && this.existingDueDate!=this.contractInvoice.ContractInvoiceDueDate ){
      if (this.contractInvoice.ContractInvoiceDueDate) {
        const currentDate = new Date();
        const dueDate = new Date(this.contractInvoice.ContractInvoiceDueDate);
        const timeDifference = dueDate.getTime() - currentDate.getTime();
        const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
    
        this.contractInvoice.DaysToDueDate = daysDifference;
      } else {
        this.contractInvoice.DaysToDueDate = 0;
      }
      this.contractInvoice.ContractInvoiceDueDate=event;
      if(this.contractInvoice.ContractInvoiceDueDate!=null && this.contractInvoice.ContractInvoiceDueDate!=undefined && this.contractInvoice.ContractInvoicePaymentDate!=null && this.contractInvoice.ContractInvoicePaymentDate!=undefined){
        if( this.contractInvoice.ContractInvoiceDueDate>this.contractInvoice.ContractInvoicePaymentDate)
        {
          this.myContractInvoiceForm.patchValue({
            paymentDate:null
          });
        }
      }
    }
    if(this.existingPaymentDate==null){
      this.myContractInvoiceForm.patchValue({
        paymentDate:null
      });
    }
  }
  
  invoiceApprovalMinDate:any;
  onInvoiceSentDateChange(event:any){
    this.contractInvoice.ContractInvoiceSentDate=event;

    if(event == undefined || event == null){
      this.invoiceApprovalMinDate=new Date('1970-01-01');
    }
    else{
    this.invoiceApprovalMinDate=event;
    }


    if(this.contractInvoice.ContractInvoiceSentDate!=null && this.contractInvoice.ContractInvoiceSentDate!=undefined && this.contractInvoice.ContractInvoiceApprovedDate!=null && this.contractInvoice.ContractInvoiceApprovedDate!=undefined){
      if( this.contractInvoice.ContractInvoiceSentDate>this.contractInvoice.ContractInvoiceApprovedDate)
      {
        this.myContractInvoiceForm.patchValue({
          draftInvoiceApprovedDate: null,
          invoiceRaisedDate: null,
          dueDate:null,
          paymentDate:null
        });
      }
    }
  
  }

  
  onInvoiceApprovalDateChange(event:any){
    this.contractInvoice.ContractInvoiceApprovedDate=event;
    if(event == undefined || event == null){
      this.invoiceRaisedMinDate=new Date('1970-01-01');
    }
    else{
    this.invoiceRaisedMinDate=event;
    }
    if(this.contractInvoice.ContractInvoiceApprovedDate!=null && this.contractInvoice.ContractInvoiceApprovedDate!=undefined && this.contractInvoice.ContractInvoiceRaisedDate!=null && this.contractInvoice.ContractInvoiceRaisedDate!=undefined){
      if( this.contractInvoice.ContractInvoiceApprovedDate>this.contractInvoice.ContractInvoiceRaisedDate)
      {
        this.myContractInvoiceForm.patchValue({
          invoiceRaisedDate: null,
          dueDate:null,
          paymentDate:null
        });
      }
    }
  }
 
  onInvoiceRaisedDateChange(event:any){
    if( this.invoiceRaisedMinDate == null){
      this.invoiceRaisedMinDate=new Date();
    }
    this.contractInvoice.ContractInvoiceRaisedDate=event;
    if(this.contractInvoice.ContractInvoiceRaisedDate!=null && this.contractInvoice.ContractInvoiceRaisedDate!=undefined && this.contractInvoice.ContractInvoiceDueDate!=null && this.contractInvoice.ContractInvoiceDueDate!=undefined){
      if( this.contractInvoice.ContractInvoiceRaisedDate>this.contractInvoice.ContractInvoiceDueDate)
      {
        this.myContractInvoiceForm.patchValue({
          dueDate:null,
          paymentDate:null
        });
      }
    }
  }
  _oldContractInvoice:any;
  onCancelClick()
  {
    if ((this._oldContractInvoice!=JSON.stringify(this.contractInvoice)))
    {
        this.confirmationService.confirm({
          message: 'Invoice will not be added, Do you want to Continue?',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.calcelInvoiceButtonClick.emit();
            this.messageService.add({
              key: 'tc',
              severity: 'info',
              summary: 'Invoice not added',
              detail: '',
          });
          },
          reject: () => {
          },
        });
    }
    else{
      this.calcelInvoiceButtonClick.emit();
    }
  
  
  }
  downloadAttachemnt(fileName: any, filePath: any) { // download Uploaded Attachemnets 

    var filedetail = {
      "fileName": fileName,
      "filePath": filePath
    };
    this._fileService.DownloadFile(filedetail).subscribe(
      res => {
        var test = res;
        var blobData = this._fileService.dataURItoBlob(res);
        this._fileService.downloadBase64File(blobData, filedetail.fileName);
      }
    );

  }
  multiFileData: any[] = [];
  prepareDeletePOFileObject(FileName: any, FilePath: any) {
  
    this.showAttachmentButton = true;
    this.showAttachedFile = false;
    this.contractInvoiceAttachment.FileName = "";
    this.contractInvoiceAttachment.FilePath = "";
    this.myContractInvoiceForm.patchValue({
      FileName: '',
      invoicefilesource: ''
    })

    var filedetail1 = {
      "fileName": FileName,
      "filePath": FilePath
    };
    const indexToRemove = this.contractInvoiceAttachments.findIndex(attachment =>
      attachment.FileName === FileName && attachment.FilePath === FilePath
    );
  
    if (indexToRemove !== -1) {
      this.contractInvoiceAttachments.splice(indexToRemove, 1);
    }

    this.uploadedInvoiceFileData=null;

    this.multiFileData.push(filedetail1);
   
  }

 //emailBodyData:any;
  uploadInvoiceFile()  {
    this.loaderService.showLoader();
     var utcDateTimeNow =  new Date().toISOString();
     this.timeStamp = formatDate(utcDateTimeNow, this._dateformart.FiletimeStamp, this._dateformart.TimeStampZone);
     if (this.uploadedInvoiceFileData) {
  
      const formData = new FormData();
      formData.append('file', this.uploadedInvoiceFileData,"Invoice/" + this.timeStamp+"_"+this.uploadedInvoiceFileData.name);
  
      this._fileService.UploadSingleFile(formData).subscribe((res: any) => {
       if (res)  {  // file uploaded successfully into the file system
        this.contractInvoice.ContractDetailId=this.ContractId;
        this.contractInvoiceAttachment.FileName = this.uploadedInvoiceFileData.name;
        this.contractInvoiceAttachment.FilePath ="Invoice/"+this.timeStamp+"_"+this.contractInvoiceAttachment.FileName;
        this.contractInvoice.updatedBy = this.privilegeService.getLoginUserId();
           this.contractInvoice.updatedDate=new Date();
           this.contractInvoice.isDeleted=false;
           this.contractInvoice.createdBy = this.privilegeService.getLoginUserId();
           var selectedInvoiceStatus = this.statusData?.filter((t: { id: number | undefined; })=>t.id==this.contractInvoice.StatusMappingId);
             if(selectedInvoiceStatus.length>0 && selectedInvoiceStatus[0].name != undefined){
               this.contractInvoice.Status =selectedInvoiceStatus[0].name;
               this.emailBodyData.CurrentStatus=selectedInvoiceStatus[0].name;
             }
             this.contractInvoiceAttachments.push(this.contractInvoiceAttachment);
             this.contractInvoice.FinanceInvoiceAttachments=this.contractInvoiceAttachments;

             if(this.contractInvoice.ContractInvoiceSentDate!=undefined){
              const DraftInvoiceSentDate: Date = this.contractInvoice.ContractInvoiceSentDate;
              const InvoiceSentDate: Date = new Date(DraftInvoiceSentDate.getTime() - DraftInvoiceSentDate.getTimezoneOffset() * 60000);
              this.contractInvoice.DraftInvoiceSentDate = InvoiceSentDate.toISOString();
               }
               if(this.contractInvoice.ContractInvoiceApprovedDate!=undefined){ 
              const DraftInvoiceApprovedDate: Date = this.contractInvoice.ContractInvoiceApprovedDate;
              const InvoiceApprovedDate: Date = new Date(DraftInvoiceApprovedDate.getTime() - DraftInvoiceApprovedDate.getTimezoneOffset() * 60000);
              this.contractInvoice.DraftInvoiceApprovedDate = InvoiceApprovedDate.toISOString();
               }
    
               if(this.contractInvoice.ContractInvoiceDueDate!=undefined){ 
              const DraftDueDate: Date = this.contractInvoice.ContractInvoiceDueDate;
              const DueDate: Date = new Date(DraftDueDate.getTime() - DraftDueDate.getTimezoneOffset() * 60000);
              this.contractInvoice.DueDate = DueDate.toISOString();
              this.emailBodyData.InvoiceDueDate=this.datePipe.transform(this.contractInvoice.ContractInvoiceDueDate, 'dd-MMM-yy') as string;
               }
    
              if(this.contractInvoice.ContractInvoicePaymentDate!=undefined){
              const DraftPaymentDate: Date = this.contractInvoice.ContractInvoicePaymentDate;
              const PaymentDate: Date = new Date(DraftPaymentDate.getTime() - DraftPaymentDate.getTimezoneOffset() * 60000);
              this.contractInvoice.PaymentDate = PaymentDate.toISOString();
              }
              if(this.contractInvoice.ContractInvoiceRaisedDate!=undefined){
              const DraftInvoiceRaisedDate: Date = this.contractInvoice.ContractInvoiceRaisedDate;
              const InvoiceRaisedDate: Date = new Date(DraftInvoiceRaisedDate.getTime() - DraftInvoiceRaisedDate.getTimezoneOffset() * 60000);
              this.contractInvoice.InvoiceRaisedDate = InvoiceRaisedDate.toISOString();
              }
            if (this.myContractInvoiceForm.valid) {
              this.loaderService.showLoader();
               this._financeInvoiceService.updateFinanceData(this.contractInvoice).subscribe(
                 async data=>{ 
                  this.emailBodyData.InvoiceNumber=this.contractInvoice.InvoiceNumber;
                  const invoiceValueStr:string = (this.contractInvoice?.InvoiceValue)?.toString() ?? '0'; // Ensure it's a string and not undefined
                 this.emailBodyData.InvoiceValue=this.getFormattedValue(parseFloat(invoiceValueStr),this.currency),
                  await this.notificationService.POUtilization(this.contractInvoice.ContractDetailId,this.SponsorId,this.SupplierId); //call the PO Utilization send notification service metod
                  this.loaderService.hideLoader();
                   this.messageService.add({
                     key: 'tc',
                     severity: 'success',
                     summary: 'Changes have been saved',
                     detail: '',
                 });
                 
                 this.saveInvoiceButtonClick.emit();
                
          const dataResult = {
            contractid:this.ContractId,
            invoiceId:this.invoiceId
          };
          this.viewButtonClick.emit(dataResult);
          this.compareModels();
       })
             }
              else{
          this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Please enter required information', detail: '' });
          this.loaderService.hideLoader();
           }
  
       }
      })
    }
    else{
      
      this.contractInvoice.ContractDetailId=this.ContractId;
        this.contractInvoice.updatedBy = this.privilegeService.getLoginUserId();
         this.contractInvoice.updatedDate=new Date();
         this.contractInvoice.isDeleted=false;
         this.contractInvoice.createdBy = this.privilegeService.getLoginUserId();
         var selectedInvoiceStatus = this.statusData?.filter((t: { id: number | undefined; })=>t.id==this.contractInvoice.StatusMappingId);
           if(selectedInvoiceStatus.length>0 && selectedInvoiceStatus[0].name != undefined){
             this.contractInvoice.Status =selectedInvoiceStatus[0].name;
             this.emailBodyData.CurrentStatus=selectedInvoiceStatus[0].name;
           }
           this.contractInvoice.FinanceInvoiceAttachments=this.contractInvoiceAttachments;

           if(this.contractInvoice.ContractInvoiceSentDate!=undefined){
          const DraftInvoiceSentDate: Date = this.contractInvoice.ContractInvoiceSentDate;
          const InvoiceSentDate: Date = new Date(DraftInvoiceSentDate.getTime() - DraftInvoiceSentDate.getTimezoneOffset() * 60000);
          this.contractInvoice.DraftInvoiceSentDate = InvoiceSentDate.toISOString();
           }
           if(this.contractInvoice.ContractInvoiceApprovedDate!=undefined){ 
          const DraftInvoiceApprovedDate: Date = this.contractInvoice.ContractInvoiceApprovedDate;
          const InvoiceApprovedDate: Date = new Date(DraftInvoiceApprovedDate.getTime() - DraftInvoiceApprovedDate.getTimezoneOffset() * 60000);
          this.contractInvoice.DraftInvoiceApprovedDate = InvoiceApprovedDate.toISOString();
           }

           if(this.contractInvoice.ContractInvoiceDueDate!=undefined){ 
          const DraftDueDate: Date = this.contractInvoice.ContractInvoiceDueDate;
          const DueDate: Date = new Date(DraftDueDate.getTime() - DraftDueDate.getTimezoneOffset() * 60000);
          this.contractInvoice.DueDate = DueDate.toISOString();
          this.emailBodyData.InvoiceDueDate=this.datePipe.transform(this.contractInvoice.ContractInvoiceDueDate, 'MM/dd/yyyy') as string;
           }

          if(this.contractInvoice.ContractInvoicePaymentDate!=undefined){
          const DraftPaymentDate: Date = this.contractInvoice.ContractInvoicePaymentDate;
          const PaymentDate: Date = new Date(DraftPaymentDate.getTime() - DraftPaymentDate.getTimezoneOffset() * 60000);
          this.contractInvoice.PaymentDate = PaymentDate.toISOString();
          }
          if(this.contractInvoice.ContractInvoiceRaisedDate!=undefined){
          const DraftInvoiceRaisedDate: Date = this.contractInvoice.ContractInvoiceRaisedDate;
          const InvoiceRaisedDate: Date = new Date(DraftInvoiceRaisedDate.getTime() - DraftInvoiceRaisedDate.getTimezoneOffset() * 60000);
          this.contractInvoice.InvoiceRaisedDate = InvoiceRaisedDate.toISOString();
          }
          this.myContractInvoiceForm.patchValue({
            invoicefileSource: this.contractInvoiceAttachment.FileName,
           })
          if (this.myContractInvoiceForm.valid) {
            this.loaderService.showLoader();
            if(this.contractInvoice.DaysToDueDate!='NA'){
              this.contractInvoice.DaysToDueDate=this.contractInvoice.DaysToDueDate.toString();
            }
             this._financeInvoiceService.updateFinanceData(this.contractInvoice).subscribe(
              async data=>{
                this.emailBodyData.InvoiceNumber=this.contractInvoice.InvoiceNumber;
                const invoiceValueStr:string = (this.contractInvoice?.InvoiceValue)?.toString() ?? '0'; // Ensure it's a string and not undefined
                this.emailBodyData.InvoiceValue=this.getFormattedValue(parseFloat(invoiceValueStr),this.currency),
                await this.notificationService.POUtilization(this.contractInvoice.ContractDetailId,this.SponsorId,this.SupplierId); //call the PO Utilization send notification service metod
                this.loaderService.hideLoader();
                 this.messageService.add({
                   key: 'tc',
                   severity: 'success',
                   summary: 'Changes have been saved',
                   detail: '',
               });
              
               this.saveInvoiceButtonClick.emit();
              
        const dataResult = {
          contractid:this.ContractId,
          invoiceId:this.invoiceId
        };
        this.viewButtonClick.emit(dataResult);
        this.compareModels();
     })
           }
            else{
        this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Please enter required information', detail: '' });
        this.loaderService.hideLoader();
         }
    }    
  }

  DueDateChange(event:any) {
     if(event!=undefined && this.existingDueDate==this.contractInvoice.ContractInvoiceDueDate ){
    if (this.contractInvoice.ContractInvoiceDueDate) {
      const currentDate = new Date();
      const dueDate = new Date(this.contractInvoice.ContractInvoiceDueDate);
      const timeDifference = dueDate.getTime() - currentDate.getTime();
      const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
  
      this.contractInvoice.DaysToDueDate = daysDifference;
    } else {
      this.contractInvoice.DaysToDueDate = 0;
    }
    this.contractInvoice.ContractInvoiceDueDate=event;
    if(this.contractInvoice.ContractInvoiceDueDate!=null && this.contractInvoice.ContractInvoiceDueDate!=undefined && this.contractInvoice.ContractInvoicePaymentDate!=null && this.contractInvoice.ContractInvoicePaymentDate!=undefined){
      if( this.contractInvoice.ContractInvoiceDueDate>this.contractInvoice.ContractInvoicePaymentDate)
      {
        this.myContractInvoiceForm.patchValue({
          paymentDate:null
        });
      }
    }
  }
  if(this.existingPaymentDate==null){
    this.myContractInvoiceForm.patchValue({
      paymentDate:null
    });
  }
}

engagementId:any;
GetContractDetails(){
  this._contractService.GetContractDtailsById(this.ContractId).subscribe(
    (res:any) => {
      this.engagementId=res.data.engagementId;
    });
}


isInvoiceNoExist=false;
checkInvoiceNoExistence(){
  if(this.contractInvoice.InvoiceNumber != undefined && this.contractInvoice.InvoiceNumber != null)
    {
  
      const invoiceNumber=this.contractInvoice.InvoiceNumber?.replace(/-/g, '~');
       
      //checkInvoiceNoExistence
      this._financeInvoiceService.checkInvoiceNoExistence(this.engagementId,this.contractInvoice.Id,invoiceNumber).subscribe(
        (res:any) => {
          if(res.data==1){
            this.isInvoiceNoExist=true;
          }
          else{
            this.isInvoiceNoExist=false;
          }
        });
    
  }
}
  
  UpdateInvoiceDetails(){
    this.loaderService.showLoader();

      //checkInvoiceNoExistence
      const invoiceNumber=this.contractInvoice.InvoiceNumber?.replace(/-/g, '~');
      
      this._financeInvoiceService.checkInvoiceNoExistence(this.engagementId,this.contractInvoice.Id,invoiceNumber).subscribe(
        (res:any) => {
          if(res.data==1){
            this.isInvoiceNoExist=true;
            this.loaderService.hideLoader();
            this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Invoice Number already exists with in selected Engagement. Please enter a unique Number.', detail: '' });
          }
          else{
            this.isInvoiceNoExist=false;
            if (this.myContractInvoiceForm.controls['paymentDate']) {
              if (this.selectedStatus !== this.statusCodes.Paid) {
                this.myContractInvoiceForm.controls['paymentDate'].clearValidators();
                this.myContractInvoiceForm.controls['paymentAmount'].clearValidators();
                this.myContractInvoiceForm.controls['paymentDate'].updateValueAndValidity();
                this.myContractInvoiceForm.controls['paymentAmount'].updateValueAndValidity();
              }
              if(!this.uploadedInvoiceFileData){
                this.myContractInvoiceForm.controls['invoicefileSource'].clearValidators();
                this.myContractInvoiceForm.controls['invoicefileSource'].updateValueAndValidity();
              }
            }
            if(this.myContractInvoiceForm.valid && !this.isInvoiceNoExist){  
                if (this.contractInvoice.DaysToDueDate == undefined) {
                  this.contractInvoice.DaysToDueDate = 0;
                  }
                if (this.contractInvoice.DaysToDueDate !== 'NA') {
                this.contractInvoice.DaysToDueDate = this.contractInvoice.DaysToDueDate.toString();
                }
                if(this.contractInvoice.PaymentAmount){
                  this.contractInvoice.PaymentAmount=this.contractInvoice.PaymentAmount.toString();
                }
        
              this.uploadInvoiceFile();
            }
            else{
              this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Please enter required information', detail: '' });  
              this.loaderService.hideLoader()
            }
          }
        });


   
    this.submitted=true;
    this.isSaveBtnDisable = true;
       setTimeout(() => {
      this.isSaveBtnDisable = false;
    }, 3000);



  }



  formOldContractInvoice() {
    setTimeout(() => {
      this._oldContractInvoice = JSON.stringify(this.contractInvoice);
    }, 2000);
  }
  changedContractInvoiceBasicInfo:any;
  oldContractInvoiceData:any;
  contractInvoiceDetailBeforeUpdate:any;
 
 
  compareModels() {
    this.contractInvoice.DraftInvoiceSentDate = new Date(this.contractInvoice.DraftInvoiceSentDate).toISOString();
    this.contractInvoice.DraftInvoiceApprovedDate = new Date(this.contractInvoice.DraftInvoiceApprovedDate).toISOString();
    this.contractInvoice.InvoiceRaisedDate = new Date(this.contractInvoice.InvoiceRaisedDate).toISOString();
    this.contractInvoice.DueDate = new Date(this.contractInvoice.DueDate).toISOString();

    this.changedContractInvoiceBasicInfo = this.auditLogService.compareModels(JSON.parse(this.contractInvoiceDetailBeforeUpdate),this.contractInvoice,this.auditEntities.ContractInvoices,this.auditIdentifierDetails.ContractInvoices,this.auditOperations.Update,this.auditModule.FinanceService);
  }
  getFormattedValue(value: number, currencyName: string): string {
    return this.currencyService.getFormattedValue(value, currencyName);
  }
  navigateTo(index: number): void {
    if (index >= 0 && index < this.listOfInvoices.length) {
      this.invoiceId=this.listOfInvoices[index].id;
      this.GetContractInvoiceDetails(this.invoiceId)
      this.currentIndex=index;
      this.loaderService.showLoader();
      this.formOldContractInvoice();
      this.DisableNxtPreButton();
    }
  }
  nextRecord(): void {
    this.navigateTo(this.currentIndex + 1);
  }
  previousRecord(): void {
    this.navigateTo(this.currentIndex - 1);
  }
  getCurrentPositionText(): string {
    return `${this.currentIndex + 1} of ${this.listOfInvoices.length}`;
  }
  pageChangeConfirmation(buttonType:number):void{
    if((this._oldContractInvoice==JSON.stringify(this.contractInvoice)))
    {
      buttonType==1?this.nextRecord():this.previousRecord();
    }
    else{
      this.confirmationService.confirm({
        message: 'Changes will not be saved, Do you want to Continue?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.messageService.add({
            key: 'tc',
            severity: 'info',
            summary: 'Changes not saved',
            detail: '',
        });
         buttonType==1?this.nextRecord():this.previousRecord();
        },
        reject: () => {
      
        },
      });
    }
  }
  buttonDisabled:boolean = false;
  DisableNxtPreButton()
  {
    this.buttonDisabled = true;
    setTimeout(() => {
      this.buttonDisabled = false;
      this.loaderService.hideLoader();
    },2000); 
  }
}
