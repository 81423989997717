<!DOCTYPE html>
<html lang="en">
<head>
    <title> Engagement Create Meeting</title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>
<div class="card-pad">
	<div class="fr-flex__container sticky-sub-header">
		<div class="fr-flex__col-8">
			<h3>Add Meeting</h3>
		</div>
	</div>
    <div class="scroll-start-here-sub-tabs"> 
    <form [formGroup]="engagementAddMeetingForm">
       
      
	<div class="fr-flex__container">
		<div class="fr-flex__col-4">
			<div class="fr-form__group"> <label> Title <em> * </em> <span class="max-length"> {{engagementMeetingDetails.Title?.length || 0}}/50 </span></label> 
            <input type="text" class="form-control" pInputText [pTooltip]="engagementMeetingDetails.Title && engagementMeetingDetails.Title.length > 35 ? engagementMeetingDetails.Title : ''" tooltipPosition="top" [maxlength]="50" [(ngModel)]="engagementMeetingDetails.Title" formControlName="addMeetingTitle" [minlength]="3" trimSpace /> 
            <small class="p-error" *ngIf="addMeetingSaved && engagementAddMeetingForm?.get('addMeetingTitle')?.hasError('required')">This field is required.</small>
            <small class="p-error" *ngIf="addMeetingSaved && engagementAddMeetingForm?.get('addMeetingTitle')?.hasError('maxlength')">Max length is 50 characters.</small>
            <small class="p-error" *ngIf="engagementAddMeetingForm.get('addMeetingTitle')?.hasError('onlySpaces')">Only spaces not allowed.</small>
            <small class="p-error" *ngIf="addMeetingSaved && engagementAddMeetingForm?.get('addMeetingTitle')?.hasError('minlength')">min length is 3 characters.</small>

        </div>
		</div>
		<div class="fr-flex__col-4">
			<div class="fr-form__group"> <label> Organizer <em> * </em>
                <a class="btn btn-primary-link pull-right line-height-zero" (click)="openContractModel('organizer',0)" >Add New</a> 
            </label>
             <p-dropdown appendTo="body" [filter]="true"  [options]="organizerOptions" [(ngModel)]="engagementMeetingDetails.Organizer" placeholder="Select Organizer" formControlName="addMeetingOrganizer"></p-dropdown>
             <small class="p-error" *ngIf="addMeetingSaved && engagementAddMeetingForm?.get('addMeetingOrganizer')?.hasError('required')">This field is required.</small>
            </div>
		</div>
		<div class="fr-flex__col-4">
			<div class="fr-form__group"> <label> Recurring <em> * </em></label>
                <p-dropdown appendTo="body" [filter]="true" [options]="options" (onChange)="onRecurringChange($event)" placeholder="Select Recurring" formControlName="addMeetingRecurring"></p-dropdown>
                <small class="p-error" *ngIf="addMeetingSaved && engagementAddMeetingForm?.get('addMeetingRecurring')?.hasError('required')">This field is required.</small>
            </div>
		</div>
	</div>
    <div class="fr-flex__container">
        <div class="fr-flex__col-4"> 
		<div class="fr-form__group">
            <label> Date <em> * </em></label>
            <p-calendar appendTo="body" class="form-control" [showIcon]="true" [(ngModel)]="_engagementMeetingOccurrences.OccurrenceDate"  formControlName="addMeetingOccurenceDate" [dateFormat]=_dateformart.CalenderDateFormat [readonlyInput]="true"></p-calendar>
            <small class="p-error" *ngIf="addMeetingSaved && engagementAddMeetingForm?.get('addMeetingOccurenceDate')?.hasError('required')">This field is required.</small> 
        </div>
        </div>
		<div class="fr-flex__col-8">
			<div class="fr-form__group">
            <label> Attachments <i  pInputText pTooltip="Maximum 3 Attachments allowed." tooltipPosition="top" class="pi pi-info-circle" > </i></label>
            <input type="file" accept=".pdf, .xls, .xlsx,.doc,.docx" multiple (change)="onFileChange($event)" class="form-control m-b-10" formControlName="addMeetingFileSource" #fileInput style="display:none" trimSpace />
            <button type="button" (click)="fileInput.click()" class="form-control width50 m-b-10" [disabled]="isUploadButtonDisabled">Upload Files</button>
            <div class="display-inline-block" *ngFor="let file of uploadedFiles">
                <a class="form-list-active">
                     <span [pTooltip]="file.name.length > 45 ? file.name : ''" tooltipPosition="top">  {{ file.name | truncate:45 }} </span> 
                     <i pInputText pTooltip="Delete" tooltipPosition="top" (click)="removeFile(file)" class="pi pi-trash"></i> </a>
              </div>
              
            </div>
            
        </div>
		
	</div>
    <div class="fr-flex__container">
		<div class="fr-flex__col-12">
			<div class="fr-form__group"> 
            <label> Agenda/Description <em> * </em> <span class="max-length"> {{engagementMeetingDetails.Agenda?.length || 0}}/300 </span>  </label> 
            <textarea  class="form-control" [maxlength]="300" pInputTextarea [(ngModel)]="engagementMeetingDetails.Agenda" formControlName="addMeetingAgenda" trimSpace ></textarea>
            <small class="p-error" *ngIf="addMeetingSaved && engagementAddMeetingForm?.get('addMeetingAgenda')?.hasError('required')">This field is required.</small> 
            </div>
		</div>
	</div>
    <div class="fr-flex__container">
        <div class="fr-flex__col-4">
			<div class="fr-form__group">
                 <label> Attendees <span class="max-length-multiple-selected"> <span> </span> </span>
                    <a class="btn btn-primary-link pull-right line-height-zero" (click)="openContractModel('attendees',0)" >Add New</a> 
                </label> 
                <p-multiSelect appendTo="body" formControlName="_attendent" optionLabel="name"  [options]="_attendees" [(ngModel)]="attendeesValues" placeholder="Select Attendees" display="chip"></p-multiSelect>
            </div>
		</div>
        <div class="fr-flex__col-4">
			<div class="fr-form__group"> <label> Absentees <span class="max-length-multiple-selected"> <span>  </span> </span>
                <a class="btn btn-primary-link pull-right line-height-zero" (click)="openContractModel('absentees',0)" >Add New</a> 
            </label> 
               <p-multiSelect appendTo="body" formControlName="_absent" optionLabel="name"  [options]="_absentees" [(ngModel)]="absenteesValues" placeholder="Select Absentees" display="chip"></p-multiSelect>
            </div>
		</div>
    </div>
    <div class="fr-flex__container">
		<div class="fr-flex__col-12">
			<div class="fr-form__group"> 
                <label> Meeting Notes <span class="max-length"> {{_engagementMeetingOccurrences.MeetingNotes?.length || 0}}/500 </span></label> 
                <textarea  class="form-control" [maxlength]="500" pInputTextarea [(ngModel)]="_engagementMeetingOccurrences.MeetingNotes" formControlName="addMeetinMeetingNotes" trimSpace ></textarea>
            </div>
		</div>
	</div>
    <div class="fr-flex__container">
		<div class="fr-flex__col-12">
			<div class="fr-form__group"> 
                <label> Decisions <span class="max-length"> {{_engagementMeetingOccurrences.Decisions?.length || 0}}/500 </span></label> 
                <textarea  class="form-control" [maxlength]="500" pInputTextarea [(ngModel)]="_engagementMeetingOccurrences.Decisions" formControlName="addMeetingDecisions" trimSpace ></textarea>
            </div>
		</div>
	</div>
    <div class="fr-flex__container">
        <div class="fr-flex__col-8">
            <h3>Actions  </h3>
        </div>
        <div class="fr-flex__col-4">
            <a routerLinkActive="active" class="btn btn-primary pull-right"  (click)="AddMeetingActions()"> Add Action </a>
        </div>
    </div>
    </form> 
</div>
    <div class="fr-flex__col-12 m-b-20"> 
        <p-table id="engCreateMeetingActionTableName" class="primeng-table-custom custom-cells__supplierEditMeeting_action_editOccurrence" [scrollable]="true" [value]="addMeetingActionsData" editMode="row">
            <ng-template pTemplate="header">
                <tr>                         
                    <th id="engCrateMeetingAction">Action<em> * </em> </th>
                    <th id="engCrateMeetingOwner">Owner</th>
                    <th id="engCrateMeetingTargetDate">Target Date</th>
                    <th id="engCrateMeetingStatus">Status <em> * </em> </th>
                    <th id="engCrateMeetingClosureDate">Closure Date</th>
                    <th id="engCrateMeetingRemarks">Remarks</th>
                    <th id="engCrateMeetingDelete">Delete</th>
                   
                    
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-addMeetingActionsData let-actionIndex="rowIndex"> 
                
                <tr>                    
                    <td>
                        <textarea class="form-control" [(ngModel)]="addMeetingActionsData.ActionName"  [required]="true" [maxlength]="300" name="ActionName" #ActionNameInput="ngModel" trimSpace ></textarea>                       
                          <div *ngIf="ActionNameInput.errors?.['required'] && addMeetingSaved && addMeetingActionsData.ActionName== ''" class="text-danger highlight-red">This field is required.</div>                                                            
                      </td>    
                    <td>
                        <div class="fr-form__group">
                            <label class="edit-occurrence__AddNew">
                                <a class="btn btn-primary-link pull-right line-height-zero table_addNew" (click)="openContractModel('owner',actionIndex)" >Add New</a> 
                            </label>
                            <p-dropdown [filter]="true" [options]="organizerOptions" appendTo="body" [(ngModel)]="addMeetingActionsData.Owner" placeholder="Select Owner"></p-dropdown>
                        </div>
                    </td>
                   
                    <td>                                     
                      <p-calendar appendTo="body" class="form-control" [showIcon]="true"  [(ngModel)]="addMeetingActionsData.TargetDate" [showIcon]="true" [dateFormat]=_dateformart.CalenderDateFormat></p-calendar>  
                     </td>        
                      <td>
                        <p-dropdown appendTo="body" [filter]="true" [options]="meetingStatus" appendTo="body" placeholder="Select Status" optionLabel="name" [(ngModel)]="addMeetingActionsData.StatusMappingId"></p-dropdown>                            
                         <div  *ngIf="addMeetingSaved && addMeetingActionsData.StatusMappingId ==''" class="text-danger highlight-red">This field is required.                                                                
                        </div>                                               
                      </td>                    
                      <td>      
                        <div *ngIf="addMeetingActionsData.StatusMappingId.code == 'CLD'">  
                            <div class="text-danger highlight-red">*</div>                      
                          </div>               
                       
                        <p-calendar appendTo="body" class="form-control" [showIcon]="true" pInputText [(ngModel)]="addMeetingActionsData.ClosureDate" [showIcon]="true" [dateFormat]=_dateformart.CalenderDateFormat #closureDateInput="ngModel" [ngClass]="{'highlight-red': addMeetingActionsData.StatusMappingId.code == 'CLD' && closureDateInput.invalid}"></p-calendar>            
                        <div *ngIf="(addMeetingActionsData.StatusMappingId.code == 'CLD' && (closureDateInput.invalid || !addMeetingActionsData.ClosureDate) && (closureDateInput.dirty || closureDateInput.touched))||(addMeetingActionsData.StatusMappingId.code == 'CLD' && closureDateInput.dirty == false)">                                          
                            <div class="text-danger highlight-red">This field is required</div> 
                          </div>                                                       
                      </td>
                    <td>
                        <div *ngIf="addMeetingActionsData.StatusMappingId.code == 'CLD'">  
                            <div class="text-danger highlight-red">*</div>                    
                          </div>  
                        <textarea class="form-control" [(ngModel)]="addMeetingActionsData.Remarks"  [required]="true" [maxlength]="300" name="Remarks"  #remarksInput="ngModel" trimSpace ></textarea>
                        <div *ngIf="(addMeetingActionsData.StatusMappingId.code == 'CLD' && (remarksInput.invalid || !addMeetingActionsData.Remarks) && (remarksInput.dirty || remarksInput.touched))||(addMeetingActionsData.StatusMappingId.code == 'CLD' && remarksInput.dirty == false)">                                          
                            <div class="text-danger highlight-red">This field is required</div> 
                          </div>  
                        </td>                 
                    <td id="engCreateMeetingActionDelete" class="grid-icon-center"><span class="pi pi-trash" (click)="deleteAction(actionIndex)"></span></td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                <td colspan="7" class="text-center"> No Records</td>
                </tr>
                </ng-template>
        </p-table>
</div>
<div class="fr-flex__container footer_btns">
    <div class="fr-flex__col-12"> 
    <a  type="button" class="btn btn-primary btn-save pull-right" [class.disabled]="isSaveBtnDisable" (click)="isSaveBtnDisable ? null : saveEngagementMeetingData()" > Save </a>
    <a type="button" class="btn btn-secondary pull-right" (click)="onCancelEngagementMeeting()"> Cancel </a>
 </div>
 </div>
 <p-dialog appendTo="body" header="{{contactHeader}}" [(visible)]="displayContractGroupDialog" [modal]="true" [style]="{ width: '40vw' }" [resizable]="true" [closable]="false">
    <ng-template pTemplate="content">
       <form [formGroup]="contactFormGroup">
          <div class="fr-flex__container">
             <div class="fr-flex__col-12">
                <div class="fr-form__group">
                   <label for="name">Contact Name<em> * </em> <span class="max-length">{{contactName.length || 0}}/30 </span> </label>
                   <input type="text" id="contactName" class="form-control" formControlName="contractName" [(ngModel)]="contactName" [maxlength]="30" pInputText trimSpace/>
                   <small class="p-error" *ngIf="(contactSubmitted || contactFormGroup?.get('contractName')?.touched) && contactFormGroup?.get('contractName')?.hasError('required')"> This field is required. </small>
                   <small class="p-error" *ngIf="(contactSubmitted || contactFormGroup?.get('contractName')?.touched) && contactFormGroup?.get('contractName')?.hasError('maxlength')">  Maximum length is 30 characters. </small>
                   <small class="p-error" *ngIf="(contactSubmitted || contactFormGroup?.get('contractName')?.touched) && contactFormGroup?.get('contractName')?.hasError('minlength')">  Minimum length is 3 characters. </small>
                   <small class="p-error" *ngIf="(contactSubmitted || contactFormGroup?.get('contractName')?.touched) && contactFormGroup?.get('contractName')?.hasError('onlySpaces')"> Only spaces not allowed.</small>
                   <small class="p-error" *ngIf="(contactSubmitted || contactFormGroup?.get('contractName')?.touched) && contactFormGroup?.get('contractName')?.hasError('pattern')">Special characters and numerics are not allowed, Min length is 3 and Max length is 30 characters.</small>
                </div>
             </div>
          </div>
       
       </form>
    </ng-template>
    <!-- Dialog Footer Start -->
    <ng-template pTemplate="footer">
       <button  type="button" class="btn btn-primary pull-right" (click)="SaveMeetingContact()"> Save </button>
       <button type="button" class="btn btn-secondary pull-right" (click)="closeContactDialog()"> Cancel </button>
    </ng-template>
    <!-- Dialog Footer End -->
    </p-dialog>
 </div>
 </body>
</html>