<!DOCTYPE html>
<html lang="en">
<head>
    <title>Contact List</title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>

<!-- BreadCrumb -->
<div class="card flex justify-content-center" *ngIf="!fromSupplier">
    <p-breadcrumb class="max-w-full" [model]="getBreadcrumbs()"></p-breadcrumb>
</div>
<div class="container contract-list__overflowScroll">
    <div class="page-title__contract" [ngClass]="{'page-title__Insidecontract': fromSupplier}"> 
    <div class="flex-box"> 
    <div class="fr-flex__container" [ngClass]="{'contract_pad': fromSupplier}">
        <div class="fr-flex__col-6">
            <h1> <span *ngIf="!fromSupplier && !fromContractGroup"> Contracts </span> <span class="number_of_records"> ({{ filteredRecordCount > -1 ? filteredRecordCount : totalRecordCount }} of {{ totalRecordCount }} Records) </span></h1> 
        </div>
        <div class="fr-flex__col-6"> 
            <a *ngIf="createPrivilege" (click)="createContract()" class="btn btn-primary pull-right"> Create Contract </a> 
            <button *ngIf="ExportPrivilege" class="btn btn-outline-primary pull-right" (click)="exportContracts()" [disabled]="isChecked"> <i class="pi pi-download"></i> Export </button> 
            <button class="btn btn-outline-primary pull-right" id="contractListFilter" (click)="showHideControls()" [ngClass]="{'active-button': showHideFiltersControls}"> <i class="pi pi-filter"></i> </button> 
        </div>
    </div>
</div>
    <div class="fr-flex__col-12">
    <div *ngIf="showHideFiltersControls">
        <div class="card"> 
                <div class="filter-bg"> 
                <div class="fr-flex__col-12"> 
                  
            <!-- Filters -->
            <div class="fr-flex__container">
                <div class="fr-flex__col-3">
                    <div class="fr-form__group margin-zero"> 
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i> 
                            <input type="text" class="form-control key-search filter-text search-active" [class.search-active]="filterDetailsModel.Key !== undefined && filterDetailsModel.Key !== null && filterDetailsModel.Key !== ''" [(ngModel)]="filterDetailsModel.Key" placeholder="Search by Title, ID" (input)="onFinalfilter()" trimSpace/> </span>
                         </div>
                </div>
                <div class="fr-flex__col-3" *ngIf="!fromSupplier">
                    <p-multiSelect appendTo="body" class="multiSelect-active muultiselect_header_hide" [showToggleAll]="false" panelStyleClass="custom-panel" [class.multiSelect-active]="filterDetailsModel.SupplierNameList !== undefined && filterDetailsModel.SupplierNameList !== null && filterDetailsModel.SupplierNameList.length !== 0" [options]="supplierdata" [filter]="false" [(ngModel)]="filterDetailsModel.SupplierNameList" placeholder="Select Engagement" (onChange)="onFinalfilter()"></p-multiSelect>
                </div>
                <div class="fr-flex__col-3">
                    <p-dropdown appendTo="body" [options]="options" class="dropdown-active" [class.dropdown-active]="selectedOption !== undefined && selectedOption !== null" placeholder="Select Date" [filter]="true" [(ngModel)]="selectedOption" (onChange)="onDateSelect($event)">
                      
                    </p-dropdown>
                </div>
                <div class="fr-flex__col-3">
                    <p-calendar appendTo="body" class="calendar-active" [class.calendar-active]="selectedDate !== undefined && selectedDate !== null && selectedDate !== ''" selectionMode="range" [(ngModel)]="selectedDate" dateFormat="dd/mm/yy" [showClear]="true" (ngModelChange)="OnSelectDate()" placeholder="Select Date Range"></p-calendar>
                </div>
               
            </div>
            <div class="fr-flex__container m-t-10">
                <div class="fr-flex__col-3">
                    <p-dropdown appendTo="body" [options]="contractValues" class="dropdown-active" [class.dropdown-active]="filterDetailsModel.ValueList !== undefined && filterDetailsModel.ValueList !== null && filterDetailsModel.ValueList !== ''" [filter]="true" [(ngModel)]="filterDetailsModel.ValueList" placeholder="Select Value" (onChange)="onContractsValueChange($event)"></p-dropdown>
                </div>
                <div class="fr-flex__col-3">
                    <p-dropdown appendTo="body" [options]="contractValues" class="dropdown-active" [class.dropdown-active]="filterDetailsModel.ValuePerYearList !== undefined && filterDetailsModel.ValuePerYearList !== null && filterDetailsModel.ValuePerYearList !== ''" [filter]="true" [(ngModel)]="filterDetailsModel.ValuePerYearList" placeholder="Select Value/Year" (onChange)="onContractsValuePerYearChange($event)" ></p-dropdown>
                </div>
                <div class="fr-flex__col-3">
                    <p-multiSelect appendTo="body" [options]="currenciesData" class="multiSelect-active muultiselect_header_hide" [showToggleAll]="false" panelStyleClass="custom-panel" [class.multiSelect-active]="filterDetailsModel.CurrencyList !== undefined && filterDetailsModel.CurrencyList !== null && filterDetailsModel.CurrencyList !== ''" [filter]="false" [(ngModel)]="filterDetailsModel.CurrencyList" optionLabel="name" optionValue="name" placeholder="Select Currency" (onChange)="onFinalfilter()"></p-multiSelect>
                </div>
                <div class="fr-flex__col-3">
                    <p-multiSelect appendTo="body" [options]="statusData" class="multiSelect-active muultiselect_header_hide" [ngClass]="{'multiSelect-active': filterDetailsModel.StatusList && filterDetailsModel.StatusList.length > 0}" [showToggleAll]="false" panelStyleClass="custom-panel" [filter]="false" [(ngModel)]="filterDetailsModel.StatusList" optionLabel="name" optionValue="name" placeholder="Select Status" (onChange)="onFinalfilter()">
                      
                    </p-multiSelect>
                </div>
                
            </div>
            <div class="fr-flex__container">
                <div class="fr-flex__col-3"> 
                    <a class="btn btn-primary-link custom-pad-left-zero" (click)="clearFilters()"> Clear All Filters </a> </div>
            </div>
        </div>
                 </div>
        
        </div>
        </div> <!-- Table -->
        </div>
    
    <div class="contract_pad_table" [ngClass]="{'contract_pad_table': fromSupplier}">   
        <p-table class="primeng-table-custom custom-cells__contract-list" [resizableColumns]="true" columnResizeMode="expand" styleClass="p-datatable-striped p-datatable-gridlines" [scrollable]="true" #contractDataTable [value]="contractList" [(selection)]="checkedContracts" [paginator]="true" [rows]="10" [rowsPerPageOptions]="[10,20,50,100,200]" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Entries" [showCurrentPageReport]="true">
            <ng-template pTemplate="header">
              <tr>
                <th id="contractListTHCheckbox" pFrozenColumn class="first-child-width">
                  <p-tableHeaderCheckbox (click)="CheckboxEnabled()"></p-tableHeaderCheckbox>
                </th>
                <th id="contractListTitle" pFrozenColumn pSortableColumn="title" pResizableColumn pInputText>Contract Title <p-sortIcon field="title"></p-sortIcon></th>
                <th id="contractListID" pSortableColumn="contractId" pResizableColumn>ID <p-sortIcon field="contractId"></p-sortIcon></th>
                <th id="contractListFunctionName" pSortableColumn="functionName" pResizableColumn [ngStyle]="{'width.px': fromSupplier ? 177 : null}" *ngIf="fromSupplier" >Function <p-sortIcon field="functionName"></p-sortIcon></th>
                <th id="contractListEngagementName" pSortableColumn="engagementName" pResizableColumn [ngStyle]="{'width.px': !fromSupplier ? 177 : null}" *ngIf="!fromSupplier">Engagement <p-sortIcon field="engagementName"></p-sortIcon></th>
                <th id="contractListStartDate" pSortableColumn="startDate" pResizableColumn>Start Date <p-sortIcon field="startDate"></p-sortIcon></th>
                <th id="contractListEndDate" pSortableColumn="endDate" pResizableColumn>End Date <p-sortIcon field="endDate"></p-sortIcon></th>
                <th id="contractListValue" pSortableColumn="value" pResizableColumn [ngStyle]="{'width.px': fromSupplier ? 140 : null}">Total Value <p-sortIcon field="value"></p-sortIcon></th>
                <th id="contractListValuePerYear" pSortableColumn="valuePerYear" pResizableColumn pInputText [ngStyle]="{'width.px': fromSupplier ? 155 : null}">Total Value/Year <p-sortIcon field="valuePerYear"></p-sortIcon></th>
                <th id="contractListCurrencyName" pSortableColumn="currencyName" pResizableColumn [ngStyle]="{'width.px': fromSupplier ? 150 : null}">Currency <p-sortIcon field="currencyName"></p-sortIcon></th>
                <th id="contractListStatusName" pSortableColumn="statusName" pResizableColumn [ngStyle]="{'width.px': fromSupplier ? 108 : null}">Status <p-sortIcon field="statusName"></p-sortIcon></th>
                <th id="contractListEdit" [ngStyle]="{'width.px': fromSupplier ? 60 : null}" *ngIf="editPrivilege" class="last-child-width"> Edit</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-contractList>
              <tr>
                <td id="contractListTDCheckbox" pFrozenColumn class="first-child-width">
                  <p-tableCheckbox [value]="contractList" (click)="CheckboxEnabled()"></p-tableCheckbox>
                </td>
                <td id="contractListHyperLink" pFrozenColumn>
                  <a class="table-active-link" *ngIf="viewPrivilege; else disabledLink" routerLinkActive="active" (click)="viewContract(contractList)" [pTooltip]="contractList.title.length > 50 ? contractList.title : ''" tooltipPosition="top"> {{ contractList.title }} </a>
                  <ng-template #disabledLink>
                    {{ contractList.title}}
                  </ng-template>
                </td>
                <td> {{ contractList.contractId }}</td>
                <!-- added by Nagaraju Start -->
                <td [pTooltip]="contractList.functionName.length > 12 ? contractList.functionName : ''" tooltipPosition="top" *ngIf="fromSupplier">
                    {{ contractList.functionName }}
                </td>
                <td [pTooltip]="contractList.engagementName.length > 12 ? contractList.engagementName : ''" tooltipPosition="top" *ngIf="!fromSupplier">
                    {{ contractList.engagementName }}
                </td>
                 <!--added by Nagaraju End -->

              
                <td> {{ contractList.startDate  | date:_dateformart.GridDateFormat}} </td>
                <td> {{ contractList.endDate  | date:_dateformart.GridDateFormat}} </td>
                <td [pTooltip]="contractList.totalValue ? getFormattedValue(contractList.totalValue, contractList.currencyName) : ''" tooltipPosition="top">{{ getFormattedValue(contractList.totalValue, contractList.currencyName) }}</td>
                <td [pTooltip]="contractList.valuePerYear ? getFormattedValue((contractList.valuePerYear !== null ? contractList.valuePerYear : 'NA'), contractList.currencyName) : ''" tooltipPosition="top">{{ getFormattedValue((contractList.valuePerYear !== null ? contractList.valuePerYear : 'NA'), contractList.currencyName) }} </td>
                <td> {{ contractList.currencyName }} </td>
                <td [ngClass]="{'expired': contractList.statusName === 'Expired', 'terminated': contractList.statusName === 'Terminated', 'closed': contractList.statusName === 'Closed', 'active': contractList.statusName === 'Active', 'inactive': contractList.statusName === 'InActive'}"> <span> {{ contractList.statusName }} </span>  </td>
                <td id="contractListEdit" *ngIf="editPrivilege" class="grid-icon-center"><span class="pi pi-pencil" (click)="editContract(contractList)"></span></td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                <td colspan="10" class="text-center"> No Records</td>
                </tr>
                </ng-template>
        </p-table>
    </div>
   
</div>
</div>
</body>
</html>