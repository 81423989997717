import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appRouterLinkActiveCustom]'
})
export class RouterLinkActiveCustomDirective implements OnInit, OnDestroy {
  @Input('appRouterLinkActiveCustom') routes: string[] = [];  // Initialize with an empty array
  @Input() activeClass: string = 'active';

  private subscription: Subscription = new Subscription();

  constructor(private router: Router, private element: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.subscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateActiveClass();
      }
    });
    this.updateActiveClass();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private updateActiveClass() {
    const isActive = this.routes.some(route => this.router.url.startsWith(route));
    if (isActive) {
      this.renderer.addClass(this.element.nativeElement, this.activeClass);
    } else {
      this.renderer.removeClass(this.element.nativeElement, this.activeClass);
    }
  }
}
