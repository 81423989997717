import { Injectable } from "@angular/core";
import { ISponserData } from "../Models/sponser-data";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { AuditLogService } from "src/app/shared/ModelComparison/audit.log.service";
import { AuthCommonService } from "src/app/shared/Authentication/auth.service";



@Injectable({

  providedIn: 'root'

})
export class SponserService{
  apiURL = environment._sponserApiBaseURl;
  readonly _sponserApiBaseURl?:string="";
constructor(private http: HttpClient,private _auditLogService:AuditLogService,private authCommonService: AuthCommonService) { 
this._sponserApiBaseURl=environment?._sponserApiBaseURl;
}



  public saveSponserData(sponserData:ISponserData): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
    return this.http.post<any>(this.apiURL +  '/Sponsers/AddSponser', sponserData,httpOptions);
  
  }


  public getCodeListData(type:number,statusType:string): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
   
    return this.http.get<any>(this.apiURL +  '/CodeList/GetCodeLists?type=' + type + '&statusType=' + statusType, httpOptions);
  
  }
  
  public getSponserData(id:any): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.get<any>(this._sponserApiBaseURl +  '/Sponsers/GetSponserDataById?sponserId=' + id, httpOptions);
  }


  public updateSponserData(id:any,sponserData:ISponserData): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
    return this.http.put<any>(this.apiURL +  '/Sponsers/UpdateSponser',sponserData,httpOptions);
  
  }

  
  public GetAllSponsers() {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.get<any>(this._sponserApiBaseURl + '/Sponsers/GetAllSponsers', httpOptions);
  }


 
public GetAllSponsersByGroup(sponserIds:any){
  const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
  return this.http.post<any>(this._sponserApiBaseURl +'/Sponsers/GetAllSponsersByGroups',sponserIds, httpOptions)

}

  GetSponsersWithFilters(sponsersFiltesObj : any){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.post<any>(this._sponserApiBaseURl +'/Sponsers/GetFilteredSponsersList',sponsersFiltesObj, httpOptions)

  }

  public GetStatus() {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.get<any>(this._sponserApiBaseURl + '/Sponsers/GetStatus', httpOptions);
  }
  InActivateSponserById(id: any,statusMappingId:any,status:any){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'ApiKey': this.authCommonService.getAuthenticationToken()
  });
  let params = new HttpParams().set("id", id).set("statusMappingId",statusMappingId).set("status",status);
  const options = { params: params, headers: headers };
    return this.http.get<any>(this._sponserApiBaseURl +'/Sponsers/InActivateSponserById',options)
    }
   
    public GetAllCodeLists() {
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
      return this.http.get<any>(this._sponserApiBaseURl + '/CodeList/GetCodeListDetails', httpOptions);
    }


   
        public GetAllSupplierMasterData(type:number): Observable<any> {
          const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
          return this.http.get<any>(this._sponserApiBaseURl + '/CodeList/GetEngagementMasterKpiList?type='+type, httpOptions);
        }
     


        AddSponsorContact(sponsorContact:any): Observable<any> {
          const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
          return this.http.post<any>(this._sponserApiBaseURl +'/Sponsers/AddSponsorContact', sponsorContact, httpOptions);
        }

        public getSponserContactsDataById(id:any): Observable<any> {
          const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
          return this.http.get<any>(this._sponserApiBaseURl +  '/Sponsers/GetSponserContactsById?sponserId=' + id, httpOptions);
        }

        public GetAllSuppliers() {
          const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
          return this.http.get<any>(this._sponserApiBaseURl + '/Suppliers/GetAllSuppliers', httpOptions);
        }
        public GetSupplierById(supplierId:number): Observable<any> {
          const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
          return this.http.get<any>(this._sponserApiBaseURl + '/Suppliers/GetSupplierById?supplierId='+supplierId, httpOptions);
        }

        }

     

    
