import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';



@Injectable({
  providedIn: 'root'
})

export class CommonService {

constructor(private http: HttpClient,
    private datePipe: DatePipe,
    ) {

}

getCompleteDate(frequency: any, period: any, year: any): Date {
  let monthIndex: number;
//let frequency=this.kpiDataCollectionFrequencyDataSource?.filter(t=>t.id==frequencyId)[0].name;
  if (frequency === 'Monthly') {
    monthIndex = (new Date(period + ' 1, ' + year)).getMonth();
  } 
  else if (frequency === 'Quarterly') {
    switch (period) {
      case 1:
        monthIndex = 0;
        break;
      case 2:
        monthIndex = 3;
        break;
      case 3:
        monthIndex = 6;
        break;
      case 4:
        monthIndex = 9;
        break;
      default:
        throw new Error('Invalid quarter');
    }
  } else if (frequency === 'Half-Year') {
    monthIndex = period === 1 ? 0 : 6;
  } else { // Annual
    monthIndex = 0;
  }

  const date = new Date(year, monthIndex, 1);
  return date;
}



getFrequencyFromDate(date: Date, frequency: any): string {
  if (!(date instanceof Date)) {
         date = new Date(date);
      }
  const monthNames: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  if (frequency === 'Monthly') {
    return `${month} ${year}`;
  } else if (frequency === 'Quarterly') {
    const quarter = Math.floor(date.getMonth() / 3) + 1;
    return `Q${quarter} ${year}`;
  } else if (frequency === 'Half-Year') {
    const halfYear = date.getMonth() < 6 ? 'H1' : 'H2';
    return `${halfYear} ${year}`;
  } else if (frequency === 'Annual') {
    return `${year}`;
  } else {
    throw new Error('Invalid frequency');
  }
}



onNumericInput_ForTargetValue_KPI(event: Event) {
  const inputElement = event.target as HTMLInputElement;
  const sanitizedValue = inputElement.value = inputElement.value.replace(/[^0-9.]/g, '')    // Remove non-numeric characters except decimal
  .replace(/(\..*)\./g, '$1')            // Allow only one decimal point
  .replace(/(\.\d{2})\d+/g, '$1');       // Limit to two decimal places

  inputElement.value = sanitizedValue;
  return sanitizedValue;
}


}



