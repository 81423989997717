import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthCommonService } from './auth.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(private router: Router,private authCommonService: AuthCommonService, private cookieService:CookieService) {}

  async canActivate(): Promise<boolean> {
    // If you want to extract a specific cookie value, you can parse the string and access the desired cookie
    var authToken = this.authCommonService.getCookieValue('LOGIN_TOKEN');

    var isTokenValid =  await this.authCommonService.isTokenValid(authToken);
   
    if (isTokenValid) {
      return true; // User is logged in and token is valid, allow access
    } else {
       // Redirect to your login application URL
      var loginURL = this.authCommonService._loginPortalAppURl+'?redirect='+this.authCommonService._kontractProAppURl;
      window.location.href = loginURL; // Redirect to your login application URL
      // this.router.parseUrl('/login');
      return false; // User is not logged in or token is invalid, prevent access
    }
  }

}






