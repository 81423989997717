import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TeamAssociateDetails } from '../Models/Associate';
import { Observable } from 'rxjs';
import { AuthCommonService } from 'src/app/shared/Authentication/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AssociateService {
  readonly _governanceApiBaseURl:any;
 
  constructor(private http: HttpClient,private authCommonService: AuthCommonService) {
    this._governanceApiBaseURl=environment._governanceApiBaseURl;
   }
  
   AddTeamAssociate(teamDetails: TeamAssociateDetails): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
    return this.http.post<any>(this._governanceApiBaseURl + '/TeamAssociates/AddUpdateTeamAssociate', teamDetails, httpOptions);
  }

  GetAssociateDataById(associateId:number){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.get<any>(this._governanceApiBaseURl + '/TeamAssociates/GetAssociateDataById?id='+associateId, httpOptions);
  }
  GetTeamAssociatesList(conractIdOrSupplierId:number, teamType:number){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.get<any>(this._governanceApiBaseURl + '/TeamAssociates/GetTeamAssociatesList?conractIdOrSupplierId='+conractIdOrSupplierId+'&teamType='+teamType, httpOptions);
   
  }

  GetAssociateViewDetailsById(associateId:number){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.get<any>(this._governanceApiBaseURl + '/TeamAssociates/GetAssociateViewDetailsById?teamAssociateId='+associateId, httpOptions);
  }
  GetExportTeamAssociatesList(conractIdOrSupplierId:any, teamType:number,teamAssociateIds:string){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.get<any>(this._governanceApiBaseURl + '/TeamAssociates/GetExportTeamAssociatesList?conractIdOrSupplierId='+conractIdOrSupplierId+'&teamType='+teamType+'&teamAssociateIds='+teamAssociateIds,httpOptions);
  }

  GetTeamAssociatesListByEngagementId(conractIdOrEngagementId:number, teamType:number){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.get<any>(this._governanceApiBaseURl + '/TeamAssociates/GetTeamAssociatesListByEngagementId?conractIdOrEngagementId='+conractIdOrEngagementId+'&teamType='+teamType, httpOptions);
   
  }
  DeleteAssociate(engagementOrContractId: any): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'ApiKey': this.authCommonService.getAuthenticationToken() }) };
    return this.http.put<any>(`${this._governanceApiBaseURl}/TeamAssociates/DeleteAssociateById?engagementOrContractId=${engagementOrContractId}`, {}, httpOptions);
  }
  
}
