import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { InvoiceDetails } from "../finances/Model/ContractInvoice";
import { AuthCommonService } from "src/app/shared/Authentication/auth.service";

@Injectable({
    providedIn: 'root'
  })

  export class FinanceService { 
    apiURL = environment._finacneApiBaseURl;

    readonly _financeApiBaseURl?:string="";
    readonly _contractApiBaseURl?:string="";

    constructor(private http: HttpClient,private authCommonService: AuthCommonService) { 
        this._financeApiBaseURl=environment?._finacneApiBaseURl;
        this._contractApiBaseURl=environment?._contractApiBaseURl;
        }

        public UpdateContractAccrual(accrualModel: any): Observable<any> {
          const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
          return this.http.post<any>(this._financeApiBaseURl + '/FinanceAccrual/UpdateContractAccrual', accrualModel, httpOptions);
        }
        public GetContractAccrualByContractId(contractModelData:any): Observable<any> {
          const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
          return this.http.post<any>(this.apiURL +  '/FinanceAccrual/GetContractAccrualByContractId', contractModelData,httpOptions);
        }
      
        public GetContractAccrualDetailById(accrualId:number): Observable<any> {
          const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
          return this.http.get<any>(this._financeApiBaseURl +  '/FinanceAccrual/GetContractAccrualDetailById?accrualId=' + accrualId, httpOptions);
      
        }

        public UpdateContractForecast(forecastModel: any): Observable<any> {
          const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
          return this.http.post<any>(this._financeApiBaseURl + '/FinanceForecast/UpdateContractForecast', forecastModel, httpOptions);
        }
        public GetContractForecastByContractId(contractModelData:any): Observable<any> {
          const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
          return this.http.post<any>(this.apiURL +  '/FinanceForecast/GetContractForecastByContractId', contractModelData,httpOptions);
        }
      
        public GetContractForecastDetailById(forecastId:number): Observable<any> {
          const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
          return this.http.get<any>(this._financeApiBaseURl +  '/FinanceForecast/GetContractForecastDetailById?forecastId=' + forecastId, httpOptions);
        }


        public saveFinanceData(invoiceData:InvoiceDetails): Observable<any> {
            const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
            return this.http.post<any>(this.apiURL +  '/FinanceInvoice/AddInvoice', invoiceData,httpOptions);
          }

         public GetFinanceInvoiceId(contractDetailId:number){
            const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
            return this.http.get<any>(this.apiURL + '/FinanceInvoice/ GetAllContractInvoices?contractDetailId='+contractDetailId, httpOptions);
          }
        public GetContractFinanceInvoiceDetailsById(id: any){
            const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
            return this.http.get<any>(this.apiURL + '/FinanceInvoice/GetContractFinanceInvoiceDetailsById?contractFinanceInvoiceId='+id, httpOptions);
          }

          public updateFinanceData(invoiceData:InvoiceDetails): Observable<any> {
            const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
            return this.http.put<any>(this.apiURL +  '/FinanceInvoice/UpdateContractInvoiceDetails', invoiceData,httpOptions);
          
          }
          public GetContractSummaryDetailsById(id: any){
            const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
            return this.http.get<any>(this.apiURL + '/FinanceSummary/GetContractFinanceSummaryById?contractDetailsId='+id, httpOptions);
          }

          public GetSupplierSummaryDetailsById(id: any){
            const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
            return this.http.get<any>(this.apiURL + '/FinanceSummary/GetSupplierFinanceSummaryById?supplierId='+id, httpOptions);
          }
          public GetEngagementSummaryDetailsById(id: any){
            const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
            return this.http.get<any>(this.apiURL + '/FinanceSummary/GetEngagementFinanceSummaryById?engagementId='+id, httpOptions);
          }

          checkInvoiceNoExistence(engagementId:any,invoiceId:any,invoiceNumber:any){
            const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
            return this.http.get<any>(this.apiURL + '/FinanceInvoice/checkInvoiceNoExistence?engagementId='+engagementId+'&invoiceId='+invoiceId+'&invoiceNumber='+invoiceNumber , httpOptions);
          }

          GetFiscalYearDataByContractId(contractId:any){
            const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
            return this.http.get<any>(this._contractApiBaseURl  + '/GetFiscalYearDataByContractId?contractDetailsId='+contractId , httpOptions);
          }


          public UpdateFiscalYearData(fiscalData: any): Observable<any> {
            const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
            return this.http.post<any>(this._contractApiBaseURl + '/UpdateFiscalYearDataByContractId', fiscalData, httpOptions);
          }
       

          public GetEngagementAccumulativeExportData(engagementId:number,exportType:number){
            const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
            return this.http.get<any>(this.apiURL + '/FinanceSummary/FinanceEngagementExportById?engagementId='+engagementId+'&exportType='+exportType, httpOptions);
          }

          GetFiscalYearDataByEngagementId(engagementId:any){
            const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
            return this.http.get<any>(this._contractApiBaseURl  + '/GetFiscalYearDataByEngagementId?engagementId='+engagementId , httpOptions);
          }

          GetAllFiscalYearDetailsByEngagementId(engagementId:any){
            const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
            return this.http.get<any>(this._contractApiBaseURl  + '/GetFiscalYearDetailsByEngagementId?engagementId='+engagementId , httpOptions);
          }
           DeleteInvoice(contractInvoiceId: any): Observable<any> {
            const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'ApiKey': this.authCommonService.getAuthenticationToken() }) };
            return this.http.put<any>(`${this.apiURL}/FinanceInvoice/DeleteContractInvoiceById?contractInvoiceId=${contractInvoiceId}`, {}, httpOptions);
          }
       
}
