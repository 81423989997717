import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RadioButtonModule } from 'primeng/radiobutton';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SponsorComponent } from './MasterData/Components/sponsor/sponsor.component';
import { ButtonModule } from 'primeng/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { CreatesponsorComponent } from './MasterData/Components/createsponsor/createsponsor.component';
import { ViewsponsorComponent } from './MasterData/Components/viewsponsor/viewsponsor.component';
import { CodelistComponent } from './MasterData/Components/codelist/codelist.component';
import { HttpClientModule } from '@angular/common/http';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessageService,ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { CardModule } from 'primeng/card';
import { HomeComponent } from './Home/home/home.component';
import { SponserService } from './MasterData/Services/sponser.service';
import { EditsponserComponent } from './MasterData/Components/editsponser/editsponser.component';
import { DatePipe } from '@angular/common';
import { CreatecontractComponent } from './Contracts/Components/createcontract/createcontract.component';
import { TreeTableModule } from 'primeng/treetable';
import { TooltipModule } from 'primeng/tooltip';
import { FileUploadModule } from 'primeng/fileupload';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ViewcontractComponent } from './Contracts/Components/viewcontract/viewcontract.component';
import { ContractlistComponent } from './Contracts/Components/contractlist/contractlist.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { LoaderService } from './Loader/loader.service';
import { CheckboxModule } from 'primeng/checkbox';
import { EditcontractComponent } from './Contracts/Components/editcontract/editcontract.component';

import { TeamsComponent } from './Team/teams/teams.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { BreadcrumbService } from './shared/Breadcrumb/breadcrumb.service';
import { TruncatePipe } from './shared/custompipes/truncate.pipe';
import { EngCreateKpiComponent } from './Governance/kpis/Engagement/Components/eng-create-kpi/eng-create-kpi.component';
import { EngEditKpiComponent } from './Governance/kpis/Engagement/Components/eng-edit-kpi/eng-edit-kpi.component';
import { EngViewKpiComponent } from './Governance/kpis/Engagement/Components/eng-view-kpi/eng-view-kpi.component';
import { EngKpiListComponent } from './Governance/kpis/Engagement/Components/eng-kpi-list/eng-kpi-list.component';
import { ConKpiListComponent } from './Governance/kpis/Contract/Components/con-kpi-list/con-kpi-list.component';
import { ConViewKpiComponent } from './Governance/kpis/Contract/Components/con-view-kpi/con-view-kpi.component';
import { ConEditKpiComponent } from './Governance/kpis/Contract/Components/con-edit-kpi/con-edit-kpi.component';
import { ConCreateKpiComponent } from './Governance/kpis/Contract/Components/con-create-kpi/con-create-kpi.component';
import { AuditLogService } from './shared/ModelComparison/audit.log.service';
import { LoginComponent } from './Login/login/login.component';
import { ForgotpasswordComponent } from './ForgotPassword/forgotpassword/forgotpassword.component';
import { ContractKPIService } from './Governance/kpis/Contract/Services/ContractKpi.Service';
import { EngMeetingsListComponent } from './Governance/meetings/Engagements/Components/eng-meetings-list/eng-meetings-list.component';
import { EngCreateMeetingComponent } from './Governance/meetings/Engagements/Components/eng-create-meeting/eng-create-meeting.component';
import { EngEditMeetingComponent } from './Governance/meetings/Engagements/Components/eng-edit-meeting/eng-edit-meeting.component';
import { EngViewMeetingComponent } from './Governance/meetings/Engagements/Components/eng-view-meeting/eng-view-meeting.component';
import { ConMeetingsListComponent } from './Governance/meetings/Contracts/Components/con-meetings-list/con-meetings-list.component';
import { ConCreateMeetingComponent } from './Governance/meetings/Contracts/Components/con-create-meeting/con-create-meeting.component';
import { ConEditMeetingComponent } from './Governance/meetings/Contracts/Components/con-edit-meeting/con-edit-meeting.component';
import { ConViewMeetingComponent } from './Governance/meetings/Contracts/Components/con-view-meeting/con-view-meeting.component';

import { EngEditMeetingRecYesComponent } from './Governance/meetings/Engagements/Components/eng-edit-meeting-rec-yes/eng-edit-meeting-rec-yes.component';

import { EngCreateAuditComponent } from './Governance/audits/Engagements/Components/eng-create-audit/eng-create-audit.component';
import { EngEditAuditComponent } from './Governance/audits/Engagements/Components/eng-edit-audit/eng-edit-audit.component';
import { EngViewAuditComponent } from './Governance/audits/Engagements/Components/eng-view-audit/eng-view-audit.component';
import { EngListAuditComponent } from './Governance/audits/Engagements/Components/eng-list-audit/eng-list-audit.component';
import { ContInvoiceListComponent } from './Finance/finances/Invoices/Contract/Components/cont-invoice-list/cont-invoice-list.component';
import { ContInvoiceCreateComponent } from './Finance/finances/Invoices/Contract/Components/cont-invoice-create/cont-invoice-create.component';
import { ContInvoiceEditComponent } from './Finance/finances/Invoices/Contract/Components/cont-invoice-edit/cont-invoice-edit.component';
import { ContInvoiceViewComponent } from './Finance/finances/Invoices/Contract/Components/cont-invoice-view/cont-invoice-view.component';
import { ConAccrualsListComponent } from './Finance/Accruals/contract/Components/con-accruals-list/con-accruals-list.component';
import { ConAccrualsEditComponent } from './Finance/Accruals/contract/Components/con-accruals-edit/con-accruals-edit.component';
import { ConAccrualsViewComponent } from './Finance/Accruals/contract/Components/con-accruals-view/con-accruals-view.component';
import { ConForecastListComponent } from './Finance/Forecast/contract/Components/con-forecast-list/con-forecast-list.component';
import { ConForecastEditComponent } from './Finance/Forecast/contract/Components/con-forecast-edit/con-forecast-edit.component';
import { ConForecastViewComponent } from './Finance/Forecast/contract/Components/con-forecast-view/con-forecast-view.component';
import { ConSummaryViewComponent } from './Finance/Summary/contract/Components/con-summary-view/con-summary-view.component';
import { AssociateListComponent } from './Team/teams/associate-list/associate-list.component';
import { AssociateAddEditComponent } from './Team/teams/associate-add-edit/associate-add-edit.component';
import { AssociateViewComponent } from './Team/teams/associate-view/associate-view.component';

import { ConEditMeetingRecYesComponent } from './Governance/meetings/Contracts/Components/con-edit-meeting-rec-yes/con-edit-meeting-rec-yes.component';
import { UserRoleMappingListComponent } from './Admin/Authorization/UserRoleMapping/Components/user-role-mapping-list/user-role-mapping-list.component';

import { EditUserRoleMappingComponent } from './Admin/Authorization/UserRoleMapping/Components/edit-user-role-mapping/edit-user-role-mapping.component';
import { ViewUserRoleMappingComponent } from './Admin/Authorization/UserRoleMapping/Components/view-user-role-mapping/view-user-role-mapping.component';

import { EngSummaryViewComponent } from './Finance/Summary/contract/Components/eng-summary-view/eng-summary-view.component';
import { DashboardComponent } from './Dashboard/Components/dashboard/dashboard.component';
import { UserAdminComponent } from './Admin/UserAdmin/Components/user-admin/user-admin.component';

import { InputNumberModule } from 'primeng/inputnumber';
import { AppInitializerService } from './Reusable/app-initializer.service';

// Import the module from the SDK
import { httpInterceptorProviders } from './helpers/http.interceptor';
import { CookieService } from 'ngx-cookie-service';
import { AuthGuard } from './shared/Authentication/auth.guard';

import {CookieSecureService } from './shared/Authentication/CookieSecureService';
import { UserProfileComponent } from './Admin/Authorization/UserRoleMapping/Components/user-profile/user-profile.component';
import { AddEditEngagementComponent } from './Engagement/Components/add-edit-engagement/add-edit-engagement.component';

import { EngagementsListComponent } from './Engagement/Components/engagements-list/engagements-list.component';
import { ViewEngagementComponent } from './Engagement/Components/view-engagement/view-engagement.component'

import { PaginatorModule } from 'primeng/paginator';
import { MasterViewSupplierComponent } from './MasterData/supplier/Components/master-view-supplier/master-view-supplier.component';
import { MasterSuppliersListComponent } from './MasterData/supplier/Components/master-suppliers-list/master-suppliers-list.component';
import { MasterAddEditSupplierComponent } from './MasterData/supplier/Components/master-supplier-add-edit-component/master-add-edit-supplier.component';
import { TrimSpaceDirective } from './shared/trimSpace/trim-space.directive';
import { DashboardhomeComponent } from './Dashboard/Components/dashboardhome/dashboardhome/dashboardhome.component';
import { FinancedashboardComponent } from './Dashboard/Components/financedashboard/financedashboard/financedashboard.component';
import { TeamsdashboardComponent } from './Dashboard/Components/teamsdashboard/teamsdashboard/teamsdashboard.component';
import { RouterLinkActiveCustomDirective } from './CustomDirectives/router-link-active-custom.directive';


export function initializeApp(appInitializationService: AppInitializerService) {
  return () => appInitializationService.initializeApp();
}


@NgModule({
  declarations: [
    AppComponent,
    SponsorComponent,
    CreatesponsorComponent,
    ViewsponsorComponent,
    TrimSpaceDirective,
    // CreateSupplierComponent,
    // SuppliersComponent,
    // ViewSupplierComponent,
    HomeComponent,
    EditsponserComponent,
    CodelistComponent,
    CreatecontractComponent,
    // EditSupplierComponent,
    ViewcontractComponent,
    ContractlistComponent,
    EditcontractComponent,
    TeamsComponent,
    TruncatePipe,
    EngCreateKpiComponent,
    EngEditKpiComponent,
    EngViewKpiComponent,
    EngKpiListComponent,
    ConKpiListComponent,
    ConViewKpiComponent,
    ConEditKpiComponent,
    ConCreateKpiComponent,
    LoginComponent,
    ForgotpasswordComponent,
    EngEditMeetingComponent,
    EngViewMeetingComponent,
    EngMeetingsListComponent,
    ConCreateMeetingComponent,
    ConEditMeetingComponent,
    ConViewMeetingComponent,
    EngCreateMeetingComponent,
    ConMeetingsListComponent,
    EngEditMeetingRecYesComponent,
    EngCreateAuditComponent,
    EngEditAuditComponent,
    EngViewAuditComponent,
    EngListAuditComponent,
    ContInvoiceListComponent,
    ContInvoiceCreateComponent,
    ContInvoiceEditComponent,
    ContInvoiceViewComponent,
    ConAccrualsListComponent,
    ConAccrualsEditComponent,
    ConAccrualsViewComponent,
    ConForecastListComponent,
    ConForecastEditComponent,
    ConForecastViewComponent,
    ConSummaryViewComponent,
    AssociateListComponent,
    AssociateAddEditComponent,
    AssociateViewComponent,
    ConEditMeetingRecYesComponent,
    UserRoleMappingListComponent,
    UserRoleMappingListComponent,
    UserRoleMappingListComponent,
    UserRoleMappingListComponent,
    EditUserRoleMappingComponent,
    ViewUserRoleMappingComponent,
    UserProfileComponent,
    EngSummaryViewComponent,
    DashboardComponent,
    UserAdminComponent,
    AddEditEngagementComponent,
    EngagementsListComponent,
    ViewEngagementComponent,
    MasterViewSupplierComponent,
    MasterSuppliersListComponent,
    MasterAddEditSupplierComponent,
    DashboardhomeComponent,
    FinancedashboardComponent,
    TeamsdashboardComponent,
    RouterLinkActiveCustomDirective,
  
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextareaModule,
    DropdownModule,
    BrowserAnimationsModule,
    MultiSelectModule,
    CalendarModule,
    TableModule,
    DialogModule,
    CardModule,
    TreeTableModule,
    HttpClientModule,
    ConfirmDialogModule,
    ToastModule,
    TabViewModule,
    TooltipModule,
    FileUploadModule,
    KeyFilterModule,
    ProgressSpinnerModule,
    CheckboxModule,
    BreadcrumbModule,
    RadioButtonModule,
    InputNumberModule,
    PaginatorModule
  ],

  providers: [ConfirmationService, MessageService, SponserService, DatePipe, 
    LoaderService, BreadcrumbService, AuditLogService, ContractKPIService, Document, CookieService,AuthGuard,CookieSecureService,
    httpInterceptorProviders,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitializerService],
      multi: true,
    }],

  bootstrap: [AppComponent]
})
export class AppModule { }
