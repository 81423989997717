<!DOCTYPE html>
<html lang="en">
<head>
   <title> Contract Invoice </title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>
<div class="card-pad"> 
<div class="fr-flex__container sticky-sub-header">
   <div class="fr-flex__col-6">
      <h3>Edit Invoice</h3>
   </div>
   <div class="fr-flex__col-6">  
      <div class="preview_section pull-right">
         <button  [class.previous]="currentIndex !== 0"  (click)="pageChangeConfirmation(2)" [disabled]="currentIndex === 0||buttonDisabled"><i class="pi pi-arrow-up" pTooltip="Preview" tooltipPosition="top"></i></button>
         <span class="numbers_list">{{getCurrentPositionText()}}</span>
         <button [class.next]="currentIndex !== listOfInvoices.length - 1" (click)="pageChangeConfirmation(1)"  [disabled]="currentIndex === listOfInvoices.length - 1||buttonDisabled"><i class="pi pi-arrow-down" pTooltip="Next" tooltipPosition="top"></i></button>
         </div> 
                        </div>
</div>
<div class="scroll-start-here-sub-tabs"> 
      <form [formGroup]="myContractInvoiceForm">
         <div class="fr-flex__container m-t-20">
            <div class="fr-flex__col-4">
               <div class="fr-form__group">
                  <label> Invoice Number​ <em> * </em><span class="max-length">  </span></label>
                  <input formControlName="invoicenumber" [maxlength]="15" class="form-control" [(ngModel)]="contractInvoice.InvoiceNumber" [ngClass]="{'read-only-value-condication': isStatusPaid}" [readonly]="isStatusPaid" (blur)="checkInvoiceNoExistence()" trimSpace/>
                  <small class="p-error" *ngIf="submitted && myContractInvoiceForm?.get('invoicenumber')?.hasError('required')">  This field is required. </small>
                  <small class="p-error" *ngIf="submitted && myContractInvoiceForm?.get('invoicenumber')?.hasError('maxlength')">   Maximum length is 15 characters. </small>
                  <small class="p-error" *ngIf="myContractInvoiceForm.get('invoicenumber')?.hasError('onlySpaces')">Only spaces not allowed.</small>
                  <small class="p-error" *ngIf="submitted && myContractInvoiceForm?.get('invoicenumber')?.hasError('pattern')">Spaces not allowed.</small>
                  <small class="p-error" *ngIf="isInvoiceNoExist">Invoice Number already exists with in selected Engagement. Please enter a unique Number.</small>
               </div>
            </div> 
            <div class="fr-flex__col-4">
               <div class="fr-form__group">
                  <label>Invoice Value   <em> * </em> <span class="max-length"> </span> </label>
                  <p-inputNumber formControlName="invoicevalue" inputId="minmaxfraction" mode="decimal" [minFractionDigits]="2" class="form-control-inputNumber" type="text" [maxlength]="15" [(ngModel)]="contractInvoice.InvoiceValue"  [ngClass]="{'read-only-value-condication': isStatusPaid}" [readonly]="isStatusPaid" trimSpace/>
                  <small class="p-error" *ngIf="submitted && myContractInvoiceForm?.get('invoicevalue')?.hasError('required')">  This field is required. </small>
                  <small class="p-error" *ngIf="submitted && myContractInvoiceForm?.get('invoicevalue')?.hasError('invalidNumber')"> Invalid number format - Only numbers and 2 decimal places allowed. </small>
                  <small class="p-error" *ngIf="submitted && myContractInvoiceForm?.get('invoicevalue')?.hasError('maxlength')">   Maximum length is 15 characters. </small>
               </div>
            </div>
            <div class="fr-flex__col-4">
               <div class="fr-form__group">
                  <label>Draft Invoice Sent Date  </label>
                  <p-calendar appendTo="body" formControlName="draftInvoicesentdDate" class="form-control" [showIcon]="true" [(ngModel)]="contractInvoice.ContractInvoiceSentDate" [dateFormat]=_dateformart.CalenderDateFormat (ngModelChange)="onInvoiceSentDateChange($event)" [readonlyInput]="true" [ngClass]="{'read-only-calendar-condication':isStatusPaid}" [disabled]="isStatusPaid"></p-calendar>
                  <small class="p-error" *ngIf="submitted && myContractInvoiceForm?.get('draftInvoicesentdDate')?.hasError('required')">  This field is required. </small>
               </div>
            </div>

         
         </div>

         <div class="fr-flex__container">
            <div class="fr-flex__col-4">
               <div class="fr-form__group">
                  <label>Draft Invoice Approval Date <em> </em> <i  pInputText pTooltip="Should be on or after Draft Invoice Sent Date" tooltipPosition="top" class="pi pi-info-circle"> </i></label>
                  
                  <p-calendar appendTo="body" formControlName="draftInvoiceApprovedDate" class="form-control" [showIcon]="true" [(ngModel)]="contractInvoice.ContractInvoiceApprovedDate" [minDate]="invoiceApprovalMinDate" [dateFormat]=_dateformart.CalenderDateFormat (ngModelChange)="onInvoiceApprovalDateChange($event)" [readonlyInput]="true" [ngClass]="{'read-only-calendar-condication':isStatusPaid}" [disabled]="isStatusPaid"></p-calendar>
               </div>
            </div>
            <div class="fr-flex__col-4">
               <div class="fr-form__group">
               <label>Invoice Raised Date  <em> * </em> <i  pInputText pTooltip="Should be on or after Draft Invoice Approved Date" tooltipPosition="top" class="pi pi-info-circle"> </i> </label>
               
               <p-calendar appendTo="body" formControlName="invoiceRaisedDate" class="form-control" [showIcon]="true" [(ngModel)]="contractInvoice.ContractInvoiceRaisedDate" [minDate]="invoiceRaisedMinDate" [dateFormat]=_dateformart.CalenderDateFormat (ngModelChange)="onInvoiceRaisedDateChange($event)" [readonlyInput]="true" [ngClass]="{'read-only-calendar-condication':isStatusPaid}" [disabled]="isStatusPaid"></p-calendar>
               <small class="p-error" *ngIf="submitted && myContractInvoiceForm?.get('invoiceRaisedDate')?.hasError('required')">  This field is required. </small>
            </div>
            </div>
            <div class="fr-flex__col-4">
               <div class="fr-form__group">
                  <label>Due Date   <em> * </em> <i  pInputText pTooltip="Should be on or after Invoice Raised Date" tooltipPosition="top" class="pi pi-info-circle"> </i></label>
                  <p-calendar appendTo="body" formControlName="dueDate" class="form-control" [showIcon]="true" [(ngModel)]="contractInvoice.ContractInvoiceDueDate" [minDate]="contractInvoice.ContractInvoiceRaisedDate || getDefaultMinDate()" [dateFormat]=_dateformart.CalenderDateFormat (ngModelChange)="onDueDateChange($event)" [readonlyInput]="true" [ngClass]="{'read-only-calendar-condication':isStatusPaid}" [disabled]="isStatusPaid"></p-calendar>
                  <small class="p-error" *ngIf="submitted && myContractInvoiceForm?.get('dueDate')?.hasError('required')">  This field is required. </small>
               </div>
            </div>
            </div>
      
         <div class="fr-flex__container">
            <div class="fr-flex__col-4">
               <div class="fr-form__group">
                  <label>Status <em> * </em></label>
                 <p-dropdown appendTo="body" [filter]="true" formControlName="statusMappingId" [options]="statusData" [(ngModel)]="contractInvoice.StatusMappingId" optionLabel="name" optionValue="id" (onChange)="onStatusChange($event)" [ngClass]="{'read-only-dropdown':isStatusPaid}" [readonly]="isStatusPaid"></p-dropdown>
                 <small class="p-error" *ngIf="submitted && myContractInvoiceForm?.get('statusMappingId')?.hasError('required')">  This field is required. </small>
               </div>
            </div>
            <div  class="fr-flex__col-4">
                <div class="fr-form__group">
                   <label> Attachment <i  pInputText pTooltip="Only 1 Attachment allowed." tooltipPosition="top" class="pi pi-info-circle"> </i> </label>
                   <div *ngIf="showAttachmentButton">
                   <input class="form-control" type="file" accept=".pdf, .xls, .xlsx,.doc,.docx"  formControlName="invoicefileSource" id="invoiceFileAttachment"  (change)="onInvoiceFileChange($any($event.target).files)" [ngClass]="{'read-only-attachment': isStatusPaid}" [disabled]="isStatusPaid">
                   
                 </div>
                 <div  *ngIf="showAttachedFile">
                    <a class="attachment-active" routerLinkActive="active" (click)="downloadAttachemnt(contractInvoiceAttachment.FileName,contractInvoiceAttachment.FilePath)" [ngClass]="{'read-only-attachment': isStatusPaid}">
                    <span [pTooltip]="contractInvoiceAttachment.FileName!.length > 30 ? contractInvoiceAttachment.FileName!: ''" tooltipPosition="top">  <em>  {{ contractInvoiceAttachment.FileName! | truncate:45 }} </em> </span>
                    <i pInputText pTooltip="Delete" tooltipPosition="top" class="pi pi-trash" (click)="prepareDeletePOFileObject(contractInvoiceAttachment.FileName,contractInvoiceAttachment.FilePath)"> </i> 
                   </a>
                    
                 </div>
                 
                 </div>
              </div>

 
            <div class="fr-flex__col-4">
               <div class="fr-form__group">
                  <label>Days to Due Date  </label>
                  <input formControlName="daystoDueDate" type="text" class="form-control read-only" [readOnly]="true" [ngModel]="contractInvoice.DaysToDueDate" trimSpace/>
                  <small class="p-error" *ngIf="submitted && myContractInvoiceForm?.get('daystoDueDate')?.hasError('required')">  This field is required. </small>
               </div>
            </div>
         </div>
         <div class="fr-flex__container">
            <div class="fr-flex__col-4">
               <div class="fr-form__group">
                  <label> Payment Amount  <em *ngIf="contractInvoice.Status === statusCodes.Paid"> * </em></label>
                  <p-inputNumber formControlName="paymentAmount" type="text" inputId="minmaxfraction" mode="decimal" [minFractionDigits]="2" class="form-control-inputNumber" [ngClass]="{'read-only-value-condication': ispaymentAmountEnable}" [readonly]="ispaymentAmountEnable" [(ngModel)]="contractInvoice.PaymentAmount" trimSpace/>
                  <div *ngIf="contractInvoice.Status === statusCodes.Paid">
                     <small class="p-error" *ngIf="submitted && myContractInvoiceForm?.get('paymentAmount')?.hasError('required')">  This field is required. </small>
                     <small class="p-error" *ngIf="submitted && myContractInvoiceForm?.get('paymentAmount')?.hasError('invalidNumber')"> Invalid number format - Only numbers and 2 decimal places allowed. </small>
                     <small class="p-error" *ngIf="submitted && myContractInvoiceForm?.get('paymentAmount')?.hasError('maxlength')">   Maximum length is 15 characters. </small>
                  </div>               
               </div>
            </div>
            <div class="fr-flex__col-4">
               <div class="fr-form__group">
                  <label>Payment Date   <em *ngIf="contractInvoice.Status === statusCodes.Paid"> * </em></label>
                  <p-calendar appendTo="body" formControlName="paymentDate" class="form-control" [ngClass]="{'read-only-calendar-condication':isStatusPaid}" [showIcon]="true" [(ngModel)]="contractInvoice.ContractInvoicePaymentDate" [minDate]="contractInvoice.ContractInvoiceRaisedDate || getDefaultMinDate()"  [dateFormat]=_dateformart.CalenderDateFormat [readonlyInput]="isStatusPaid" [disabled]="isStatusPaid" ></p-calendar>
                  <div *ngIf="contractInvoice.Status === statusCodes.Paid">
                     <small class="p-error" *ngIf="submitted && myContractInvoiceForm?.get('paymentDate')?.hasError('required')">  This field is required. </small>
                  </div>  
               </div>
            </div>
         
         </div>
         
         <div class="fr-flex__container">
            <div class="fr-flex__col-12">
               <div class="fr-form__group">
                  <label> Remarks  <span class="max-length"> {{contractInvoice.Remarks?.length || 0}}/300 </span></label>
                  <textarea formControlName="remarks" class="form-control" [maxlength]="300" pInputTextarea [(ngModel)]="contractInvoice.Remarks" trimSpace></textarea>
                  <small class="p-error" *ngIf="submitted && myContractInvoiceForm?.get('remarks')?.hasError('maxlength')">   Maximum length is 300 characters. </small>
               </div>
            </div>
         </div>
         <div class="fr-flex__container">
            <div class="fr-flex__col-12">
               <div class="fr-form__group">
                  <label> Description  <span class="max-length"> {{contractInvoice.InvoiceDescription?.length || 0}}/300 </span></label>
                  <textarea formControlName="description" class="form-control" [maxlength]="300" pInputTextarea [(ngModel)]="contractInvoice.InvoiceDescription" [ngClass]="{'read-only-text-area-condication': isStatusPaid}" [readonly]="isStatusPaid" trimSpace></textarea>
                  <small class="p-error" *ngIf="submitted && myContractInvoiceForm?.get('description')?.hasError('maxlength')">   Maximum length is 300 characters. </small>
               </div>
            </div>
         </div>
         <div class="fr-flex__container">
            <div class="fr-flex__col-3">
               <div class="fr-form__view">
                  <label> Added By</label>
                  <span> <em>  {{createdBy}} </em></span>
                  <span *ngIf="createdBy ==null"> <em> --</em></span>
               </div>
            </div>
            <div class="fr-flex__col-3">
               <div class="fr-form__view">
                  <label> Added Date & Time </label>
                  <span *ngIf="contractInvoice.createdDate !=null"> <em>  {{contractInvoice.createdDate | date: _dateformart.DateTimeWithZone}}{{_dateformart.DisplayUTC}} </em></span>
                  <span *ngIf="contractInvoice.createdDate ==null"> <em> --</em></span>
               </div>
            </div>
            <div class="fr-flex__col-3" >
               <div class="fr-form__view">
                  <label> Modified By</label>
                  <span  *ngIf="updatedBy !=null"> <em>  {{updatedBy}} </em></span>
                  <span *ngIf="updatedBy == null || updatedBy == undefined || updatedBy == ''"> <em> -- </em></span>
               </div>
            </div>
            <div class="fr-flex__col-3" >
               <div class="fr-form__view">
                  <label> Modified Date & Time </label>
                  <span  *ngIf="updatedBy !=null && updatedBy != undefined && updatedBy != ''"> <em>  {{contractInvoice.updatedDate | date: _dateformart.DateTimeWithZone}}{{_dateformart.DisplayUTC}} </em></span>
                  <span *ngIf="updatedBy ==null || updatedBy == undefined || updatedBy == ''"> <em> -- </em></span>
                </div>
            </div>
         </div>
      </form>
   </div>
            <div class="fr-flex__container footer_btns">
               <div class="fr-flex__col-12"> 
                  <a  type="button" class="btn btn-primary btn-save pull-right" [class.disabled]="isSaveBtnDisable" (click)="isSaveBtnDisable ? null : UpdateInvoiceDetails()"> Save </a>
                  <a type="button" class="btn btn-secondary pull-right" (click)="onCancelClick()"> Cancel </a>
            </div>
            </div>
</div>  
</body>
</html>