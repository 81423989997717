<!DOCTYPE html>
<html lang="en">
<head>
  <title> Contract Invoice List </title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>
    
    <p-tabView class="fr-tabview__header__custom">
      <p-tabPanel>
        <div class="flex-box m-t-70"> 
        <div class="fr-flex__col-12"> 
        <div class="fr-flex__container">
          <div class="fr-flex__col-4"> <span class="number_of_records"> <em>({{ filteredRecordCount > -1 ? filteredRecordCount : totalRecordCount }} of {{ totalRecordCount }} Records) </em> </span>
          </div>
          <div class="fr-flex__col-8">
            <button *ngIf="createPrivilege" class="btn btn-primary pull-right" (click)="addContractInvoice()"> Add Invoice  </button>
            <button *ngIf="EmailInvoiceReport" class="btn btn-primary pull-right" (click)="emailReport()"> Email Report </button>
            <button *ngIf="ExportPrivilege" class="btn btn-outline-primary pull-right" [disabled]="isChecked" (click)="ExportInvoiceData()"> <i class="pi pi-download"></i> Export </button>
            <button id="contractInvoiceListFilter" class="btn btn-outline-primary pull-right" (click)="ShowAndHideFilterControls()" [ngClass]="{'active-button': showHiddenControls}"> <i class="pi pi-filter"></i> </button>
            
          </div>
      </div> 
    </div>
  </div>
    <div *ngIf="showHiddenControls">
      <div class="fr-flex__col-12"> 
      <div class="filter-bg"> 
        <div class="fr-flex__container filter_multiselect-width">
            <div class="fr-flex__col-3">
            <div class="fr-form__group margin-zero">
              <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
              <input type="text" placeholder="Search by Invoice Number" class="form-control key-search filter-text search-active" [class.search-active]="invoiceFilterDetailsModel.InvoiceNumber !== undefined && invoiceFilterDetailsModel.InvoiceNumber !== null && invoiceFilterDetailsModel.InvoiceNumber !== ''" [(ngModel)]="invoiceFilterDetailsModel.InvoiceNumber" (input)="onfilter($event)" trimSpace/>
            </span>
          </div>
           </div>
        
               <div class="fr-flex__col-2">
                <p-dropdown appendTo="body" class="dropdown-active" [class.dropdown-active]="invoiceFilterDetailsModel.InvoiceValue !== undefined && invoiceFilterDetailsModel.InvoiceValue !== null && invoiceFilterDetailsModel.InvoiceValue !== ''"  [options]="invoiceValues"  placeholder="Select Value" [(ngModel)]="invoiceFilterDetailsModel.InvoiceValue" optionLabel="label"  optionValue="value"  (onChange)="onfilter($event)"></p-dropdown>
               </div>

              <div class="fr-flex__col-2">
                <p-calendar appendTo="body" class="filter-text calendar-active" [class.calendar-active]="invoiceFilterDetailsModel.InvoiceDueDate !== undefined && invoiceFilterDetailsModel.InvoiceDueDate !== null && invoiceFilterDetailsModel.InvoiceDueDate !== ''" selectionMode="range" [(ngModel)]="invoiceFilterDetailsModel.InvoiceDueDate" dateFormat="dd/mm/yy" [showClear]="true" (ngModelChange)="onfilter($event)" placeholder="Select Due Date"></p-calendar>
              </div> 
              <div class="fr-flex__col-2">
                <p-multiSelect appendTo="body" [filter]="false" [showToggleAll]="false" class="filter-text multiSelect-active muultiselect_header_hide" [class.multiSelect-active]="invoiceFilterDetailsModel.Status !== undefined && invoiceFilterDetailsModel.Status !== null && invoiceFilterDetailsModel.Status !== ''" name="invoiceStatus" [options]="StatusName" [(ngModel)]="invoiceFilterDetailsModel.Status" optionLabel="label" placeholder="Select Status" (onChange)="onfilter($event)" ></p-multiSelect>
                </div>
              
            

            <a class="btn btn-primary-link" (click)="ClearFilters()"> Clear All Filters </a>
          </div>
        </div>
      </div>
      </div>
      <div class="fr-flex__col-12"> 
        <div class="inside-grid-pad"> 
          <p-table id="contractInvoiceListTableName" class="primeng-table-custom custom-cells__con-finance-invoice-list" [resizableColumns]="true" columnResizeMode="expand" [value]="invoiceData" 
          [paginator]="true" [scrollable]="true"
          [rows]="10" styleClass="p-datatable-striped p-datatable-gridlines"
          [(selection)]="selectedInvoices"
          [showCurrentPageReport]="true"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Entries"
          [rowsPerPageOptions]="[10, 20, 50, 100, 200]">
            <ng-template pTemplate="header">
                <tr> 
                <th id="contractInvoiceListTHCheckbox" pFrozenColumn class="first-child-width">
                  <p-tableHeaderCheckbox (click)="CheckboxEnabled()" ></p-tableHeaderCheckbox>
              </th>
                    <th id="conInvoiceListInvoiceNumber" pFrozenColumn pSortableColumn="invoiceNumber" pResizableColumn pInputText>Invoice Number <p-sortIcon field="invoiceNumber"></p-sortIcon></th>
                    <th id="conInvoiceListInvoiceValue" pSortableColumn="invoiceValue" pResizableColumn>Invoice Value <p-sortIcon field="invoiceValue"> </p-sortIcon></th>
                    <th id="conInvoiceListInvoiceRaiseDate" pSortableColumn="invoiceRaisedDate" pResizableColumn pInputText>Invoice Raised Date <p-sortIcon field="invoiceRaisedDate"></p-sortIcon></th>
                    <th id="conInvoiceListDueDate" pSortableColumn="dueDate" pResizableColumn>Due Date <p-sortIcon field="dueDate"></p-sortIcon></th>
                    <th id="conInvoiceListDaysToDueDate" pSortableColumn="dueDate" pResizableColumn pInputText>Days to Due Date <p-sortIcon field="dueDate"></p-sortIcon></th>
                    <th id="conInvoiceListStatusMapplingID" pSortableColumn="statusMappingId" pResizableColumn>Status <p-sortIcon field="statusMappingId"></p-sortIcon></th>
                    <th id="conInvoiceListPaymentAmount" pSortableColumn="paymentAmount" pResizableColumn pInputText>Payment Amount <p-sortIcon field="paymentAmount"></p-sortIcon></th>
                    <th id="conInvoiceListPaymentDate" pSortableColumn="paymentDate" pResizableColumn pInputText>Payment Date <p-sortIcon field="paymentDate"></p-sortIcon></th>
                    <th id="conInvoiceListEdit" *ngIf="editPrivilege" class="last-child-width">Edit</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-invoice> 
                <tr>
                  <td id="contractInvoiceListTDCheckbox" pFrozenColumn>
                    <p-tableCheckbox [value]="invoice" (click)="CheckboxEnabled()"></p-tableCheckbox>
                </td>  
                    <td id="contractInvoiceListHyperLink" pFrozenColumn>
                       <a class="table-active-link" *ngIf="viewPrivilege; else disabledLink" routerLinkActive="active" (click)="viewInvoice(invoice)" tooltipPosition="top"> {{ invoice.invoiceNumber }} </a>
                       <ng-template #disabledLink>
                        {{ invoice.invoiceNumber}}
                      </ng-template>
                      </td>
                    <td>{{ getFormattedValue(invoice.invoiceValue, currency) }}</td>
                    <td>{{ invoice.invoiceRaisedDate | date:_dateformart.GridDateFormat }}</td>
                    <td>{{ invoice.dueDate | date:_dateformart.GridDateFormat}}</td>

                    
                      <td [ngClass]="{'expired':invoice.invoiceDueDateClass === 'red', 'inactive': invoice.invoiceDueDateClass === 'orange', 'active': invoice.invoiceDueDateClass === 'green'}">
                      <span class="invoice.InvoiceDueDateClass">
                      <ng-container *ngIf="(getStatusName(invoice.statusMappingId) === 'Paid' || getStatusName(invoice.statusMappingId) === 'Rejected'); else displayDaysToDueDate">
                        NA
                      </ng-container>
                      <ng-template #displayDaysToDueDate>
                        {{ invoice.paymentDate === null && invoice.dueDate ? DueDateChange(invoice) : '--' }}
                      </ng-template>
                    </span>
                    </td>

                    
                    <td>{{ getStatusName(invoice.statusMappingId) }}</td>
                    <td> {{ invoice.paymentAmount!=null ? getFormattedValue(invoice.paymentAmount, currency) : '--' }}</td> 
                    <td>{{ invoice.paymentDate ? (invoice.paymentDate | date:_dateformart.GridDateFormat) : '--' }}</td>
                    <td id="contractInvoiceListEdit" *ngIf="editPrivilege" class="grid-icon-center"><span> <i class="pi pi-pencil" (click)="onInvoiceEdit(invoice)"> </i></span></td> 
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
              <td colspan="10" class="text-center"> No Records</td>
              </tr>
              </ng-template>
        </p-table>
      </div>
      </div>
    </p-tabPanel>
      </p-tabView>

      <p-dialog appendTo="body" header="{{popupHeader}}" [(visible)]="mailPopup" [modal]="true"   [resizable]="true" [closable]="false">
        <ng-template pTemplate="content">
              <div class="fr-flex__container">
                 <div class="fr-flex__col-12">
                    <div class="fr-form__group">
                       <label for="name">Enter the Date Range for which you would like an invoice report<em> * </em> <span class="max-length">  </span> </label>
                       <p-calendar appendTo="body" class="calendar-active" placeholder="dd/mm/yy - dd/mm/yy" selectionMode="range" [(ngModel)]="selectedDateRange" [maxDate]="maxDate" [minDate]="minDate"  dateFormat="dd/mm/yy" [showClear]="true" (ngModelChange)="onEmailSelectDate()"  [showIcon]="true" ></p-calendar>
                    </div>
                 </div>
              </div>
        </ng-template>
        <!-- Dialog Footer Start -->
        <ng-template pTemplate="footer">
           <button [disabled]="isSendEmailbtnEabled||selectedDateRange==null||endDate==null" type="button" class="btn btn-primary pull-right" (click)="sendNotification()"> Send Email </button>
           <button type="button" class="btn btn-secondary pull-right" (click)="closeMailDialog()"> Cancel </button>
        </ng-template>
        <!-- Dialog Footer End -->
        </p-dialog>
      </body>
      </html>
