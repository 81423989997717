import { Component, ElementRef, OnInit, ViewChild, Input } from '@angular/core';
import { ContractService } from '../../Services/contract.service';
import { } from '../../Models/contract-details';
import { ActivatedRoute, Router } from '@angular/router';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';
import { DatePipe } from '@angular/common';
import { FilterDetails } from '../../Models/filter-details';
import { Table } from 'primeng/table';
import { LoaderService } from 'src/app/Loader/loader.service';
import { Dateformat, breadcrumnsCodes, CurrencyType, } from 'src/app/shared/constants/global.constants';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { MenuItem } from 'primeng/api';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { Privileges } from 'src/app/shared/constants/privileges.constants';
import { map, Observable } from 'rxjs';
import { CurrencyService } from 'src/app/Reusable/currency.service';
import { EngagementService } from 'src/app/Engagement/Services/engagement.service';
import { TabAndStateService } from 'src/app/shared/TabAndStateService/TabAndState.Service';
import * as xlsx from 'xlsx';
import { MasterSupplierService } from 'src/app/MasterData/supplier/Services/master.supplier.service';
import { CodeListValues } from 'src/app/MasterData/Models/country-data';

@Component({
  selector: 'app-contractlist',
  templateUrl: './contractlist.component.html',
  styleUrls: ['./contractlist.component.css']
})
export class ContractlistComponent implements OnInit {
  @ViewChild('contractDataTable') contractDataTable!: Table;
  @ViewChild('headerCheckbox') headerCheckbox!: ElementRef;
  @Input() fromSupplier: boolean = false;
  @Input() supplierId: any = 0;
  @Input() invoiceId: any;
  @Input() engagementId: any = 0;
  @Input() selectedContractGroupId: any;
  @Input() isFunctionTrue: boolean = false;
  selectAllChecked: boolean = false;
  selectPageRecords: boolean = false;
  checkboxState: boolean = false;
  contractList: any[] = [];
  unfilteredContractData: any[] = [];
  contract: any;
  checkedContracts: any[] = [];
  selectedRecordsToExpotToExcel: any[] = [];
  statusData: any[] = [];
  currenciesData: any[] = [];
  supplierdata: any[] = [];
  startDateData: any[] = [];
  endDateData: any[] = [];
  termData: any[] = [];
  valueData: any[] = [];
  valueyearData: any[] = [];
  showFilter = true;
  filterDetailsModel = new FilterDetails();
  _dateformart: any;
  checked: boolean = false;
  showHideFiltersControls: boolean = false;
  _enumBreadcrumb: any;
  currencyformat: any;
  isChecked: boolean = true;
  createPrivilege: boolean = false;
  editPrivilege: boolean = false;
  viewPrivilege: boolean = false;
  ExportPrivilege: boolean = false;
  fromContractGroup=false;
  constructor(
    private privilegeService: PrivilegeService,
    private _contractService: ContractService,
    private router: Router,
    private _masterDataService: SponserService,
    private datePipe: DatePipe,
    private loaderService: LoaderService,
    private breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute,
    private currencyService: CurrencyService,
    private engagementService: EngagementService,
    private tabAndStateService: TabAndStateService,
    private _mastersupplierService: MasterSupplierService
  ) {

  }
  totalRecordCount: number = 0;
  filteredRecordCount: number = -1;
  contractValues: any[] = [
    { label: 'All', value: 0 },
    { label: '<=10,000', value: 10000 },
    { label: '<=30,000', value: 30000 },
    { label: '<=50,000', value: 50000 },
    { label: '<=70,000', value: 70000 },
    { label: '<=90,000', value: 90000 },
    { label: '>90,000', value: 90001 },
  ];
  options = [
    { label: 'Start Date', value: 'StartDate' },
    { label: 'End Date', value: 'EndDate' },
  ];
  selectedOption: any;
  supplierIds: any;
  engagementIds: any;
  supplierRecord: any;
  //suppliersdata: any;
  suppliers: any;
  conSupplierId: any;

  ngOnInit() {

    let userData = this.privilegeService.getUserData();
    this.supplierIds = userData.suppliers;
    this.engagementIds=userData.engagements;
    this._enumBreadcrumb = breadcrumnsCodes;
    this.currencyformat = CurrencyType;

    this.route.params.subscribe(params => {
      this.conSupplierId = params['supplierdata']; //log the value of id
    });
    this.GetContractList();
    this.GetBillingTypeData();
    //this.GetSupplierData();
    //this.getAllSponserData();
   //this.getAllSuppliers();
   this.getAllEngagements();
   this.getAllContractGroups();
   this.GetChangeOrdersStatus();
   this.GetAddendumStatus();
   this.GetAllNotificationOptions(20);
    const supplierIdQueryParam = this.route.snapshot.queryParamMap.get('supplierId');

   
   
    if (supplierIdQueryParam !== null) {
      this.supplierId = parseInt(supplierIdQueryParam);
      setTimeout(() => {
        const selecedEngagement = this.engagementdata.find((engagement: { id: any }) => engagement.id === this.supplierId);
        this.supplierRecord = selecedEngagement.engagementName;
        this.breadcrumbService.generateBreadcrumb(this._enumBreadcrumb.EVENG, this.supplierRecord,"Contracts")
      }, 2000);
    }

    if (this.supplierId == 0) {
      this.generateBreadcrumb();
    }
    this._dateformart = Dateformat;
    // if (this.engagementIds != null && this.engagementIds.length > 0) {
    //   this.GetContractList();
    // }
    this.GetContractStatus();
    this.GetCurrencyData();
    this.loaderService.hidefinalLoader();// hide after 4 seconds even get any error
    this.BindPrivileges();

  }

  
  BindPrivileges() {
    const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);

       this.createPrivilege = hasPermission(Privileges.CreateContract);
  this.editPrivilege =hasPermission(Privileges.EditContract);
  this.viewPrivilege = hasPermission(Privileges.ViewContract);
  this.ExportPrivilege = hasPermission(Privileges.ExportlistofContracts);
      }

  sponsorId: any;
  
  GetSupplierDataById(selectedSupplierId: any,selectedContractSupContactId:any,selectedContractMSAId:any): Observable<{ supplierContactName: string, msaName: string }> {
    this.sponsorId = '';
    if (selectedSupplierId == 0) {
      selectedSupplierId = this.conSupplierId;
    }
    // return this._supplierService.GetSupplierById(selectedSupplierId).pipe(
      return this._masterDataService.GetSupplierById(selectedSupplierId).pipe(
      map((res:any) => {
        this.sponsorId = res.data.sponserId;
        const contactName=res.data.supplierContacts.find((x:any)=>x.id=== selectedContractSupContactId).contactName;
        const msaTitle=res.data.suppliersMasterServiceAgreements.find((x:any)=>x.id=== selectedContractMSAId).title;
        return {
          supplierContactName:  contactName,
          msaName: msaTitle,
        };
      })
    );
  }

  sponsorContactName: any;
  sponsorContactsDataSource:any;
  //sponsorsDataSource:any;
  getSponserData(id: any) {//Get Sponser Contacts
  this._masterDataService.getSponserData(id).subscribe(res => {
      this.sponsorContactsDataSource = res.data.sponserContacts[0].title;

  });

  }

//  getAllSponserData() {//Get Sponser Contacts
//   this._masterDataService.GetAllSponsers().subscribe(res => {
//       this.sponsorsDataSource = res.data;

//   });
// }

// getAllSuppliers() {

//   this._masterDataService.GetAllSuppliers()
    
//       .subscribe(res => {
//         this.suppliersdata = res.data;
        
//         this.suppliers = this.supplierdata.map((supplier: { id: any, name: any }) => ({ label: supplier.name, value: supplier.id }));

//         this.GetBillingTypeData();
//         this.GetSponsorFunctionData();

//       });

//   }

  GetContractList() {
    this.loaderService.showLoader();
    this.supplierId=this.supplierId.toString();
    this._contractService.GetAllContracts(this.supplierId, this.engagementIds).subscribe(
      res => {
        //this.loaderService.hideLoader();
        this.contractList = res.data;

        if(this.selectedContractGroupId != null && this.selectedContractGroupId != undefined){
          this.fromContractGroup=true;
          this.contractList=this.contractList.filter(aa=>aa.ownerFunctionId===this.selectedContractGroupId);
        }
        else{
          this.fromContractGroup=false;
        }

          // Map engagementId to engagementName

           // --------------added by Nagaraju Start-------------------
        //   this.contractList.forEach(contract => {
        //     const engagement = this.engagementdata.find((e: { id: any; }) => e.id === contract.engagementId);
        //     contract.engagementName = engagement ? engagement.engagementName : 'N/A';
        // });
        
        this.unfilteredContractData = this.contractList;
        //this.totalRecordCount = res.data.length
        if(this.contractList != null && this.contractList!=undefined)
        this.totalRecordCount=this.contractList.length;
        // on load default show only active contracts
        if (this.router?.url.indexOf('contracts') > -1)
          this.contractList = this.contractList.filter((obj) => { return obj.statusName.toLowerCase() === 'active' });

          // --------------added by Nagaraju Start-------------------
        

        this.contractList.forEach(contract => {
          const contractGroup = this.contractGroupData.find((e: { id: any; }) => e.id === contract.ownerFunctionId);
          contract.functionName = contractGroup ? contractGroup.name : 'Others';
      });

        // ------------added by Nagaraju End--------------------
        
        // Extract supplierName
        this.supplierdata = this.contractList
          .map((contract) => contract.engagementName)
          .filter((value, index, self) => self.indexOf(value) === index);

        this.startDateData = this.contractList
          .map((contract) => this.datePipe.transform(contract.startDate, 'dd-MMM-yyyy'))
          .filter((value, index, self) => self.indexOf(value) === index);

        // Extract endDate
        this.endDateData = this.contractList
          .map((contract) => this.datePipe.transform(contract.endDate, 'dd-MMM-yyyy'))
          .filter((value, index, self) => self.indexOf(value) === index);


        // Extract term

        this.termData = this.contractList
          .map((contract) => contract.term)
          .filter((value, index, self) => value !== '' && self.indexOf(value) === index);

        // Extract value
        this.valueData = this.contractList
          .map((contract) => contract.value)
          .filter((value, index, self) => self.indexOf(value) === index);

        //Extract valueyearData
        this.valueyearData = this.contractList
          .map((contract) => contract.valuePerYear)
          .filter((value, index, self) => self.indexOf(value) === index);
        this.filteredRecordCount = this.contractList.length;

        this.loaderService.hideLoader();
      }

    );
   
  }

  GetContractStatus() {
    this._masterDataService.getCodeListData(3, 'Contract').subscribe(
      data => {
        this.statusData = data.data;

      }
    );
  }

  GetCurrencyData() {
    this._masterDataService.getCodeListData(4, '').subscribe(
      data => {
        this.currenciesData = data.data;
      }
    );
  }

  getFormattedValue(value: number, currencyName: string): string {
    return this.currencyService.getFormattedValue(value, currencyName);
  }

  // GetSupplierData() {
  //     this._masterDataService.GetAllSuppliers().subscribe(
  //     res => {
  //       this.supplierdata = res.data;
  //       //this.suppliersdata = res.data;
  //       //this.suppliers = this.supplierdata.map((supplier: { id: any, name: any }) => ({ label: supplier.name, value: supplier.id }));

  //       this.GetBillingTypeData();
  //       //this.GetSponsorFunctionData();
  //     }
  //   );
  // }



  editContract(rowData: any) {
    this.selectedcurrencyName = rowData.currencyName;
    if (this.supplierId == 0) {
      this.tabAndStateService.setCurrencyName(this.selectedcurrencyName);
      this.router.navigate(['/editcontract'], { queryParams: { ContractId: rowData.id, currency: this.selectedcurrencyName } });
    }
    else {
      this.tabAndStateService.setCurrencyName(this.selectedcurrencyName);
      this.tabAndStateService.setPreviousState('Contracts', 2);
      this.router.navigate(['/editcontract'], { queryParams: { ContractId: rowData.id, engagementId: parseInt(this.supplierId), currency: this.selectedcurrencyName } });
    }
  }

  selectedcurrencyName: any;
  viewContract(rowData: any) {
    this.selectedcurrencyName = rowData.currencyName;
    if (this.supplierId == 0) {
      this.tabAndStateService.setCurrencyName(this.selectedcurrencyName);
      this.router.navigate(['/viewcontract'], { queryParams: { ContractId: rowData.id, currency: this.selectedcurrencyName } });
    }
    else {
      this.tabAndStateService.setPreviousState('Contracts', 2);
      this.tabAndStateService.setCurrencyName(this.selectedcurrencyName);
      const breadcrumb = this._enumBreadcrumb.EVSUP
      this.router.navigate(['/viewcontract'], { queryParams: { ContractId: rowData.id, supplierId: parseInt(this.supplierId), breadcrumb, currency: this.selectedcurrencyName } });
    }
  }

async exportContracts() {
  this.loaderService.showLoader();
  this.selectedRecordsToExpotToExcel = this.checkedContracts;
   await this.getContacts();
    setTimeout(() => {
      const details = this.selectedRecordsToExpotToExcel.map(contract =>
        new Promise((resolve) => {
          const exportedData = {
            'Contract Title': contract.title,
            'Contract Id': contract.contractId,
            'Engagement Name': contract.engagementName,
            MSA: this.GetEngagementMSAname(contract.engagementId, contract.supplierMasterServiceAgreementId),
            'Billing Type': this.GetSelectedBillingTypeCodeList(contract.billingTypes),
            'Start Date': contract.startDate != null ? this.convertDateFormat(contract.startDate) : contract.startDate,
            'End Date': contract.endDate != null ? this.convertDateFormat(contract.endDate) : contract.endDate,
            Term: contract.term,
            'Supplier Contract Owner': this.GetFilteredSupplierContactName(contract.ownerSupplierContactId),
            'Sponsor Contract Owner': this.GetFilteredSponsorContactName(contract.sponserContactId),
            'Function': this.GetSelectedFunctionData(contract.ownerFunctionId),
            Value: contract.value,
            'Value/Year': contract.valuePerYear,
            'Total Value': contract.totalValue,
            Currency: contract.currencyName,
            'SOW Attachment': contract.sowFileName,
            Description: contract.description,
            Status: contract.statusName,
            'PO Number': contract.poNumber,
            'PO Value': contract.poValue,
            'PO Start Date': contract.poStartDate != null ? this.convertDateFormat(contract.poStartDate) : contract.poStartDate,
            'PO End Date': contract.poEndDate != null ? this.convertDateFormat(contract.poEndDate) : contract.poEndDate,
            'PO Attachment': contract.poFileName,
            'PO Remarks': contract.poRemarks,
            'Added By': this.privilegeService.getUserName(contract.createdBy),
            'Added Date and Time': contract.createdDate != null ? this.convertDateTimeFormat(contract.createdDate) : contract.createdDate,
            'Modified By': this.privilegeService.getUserName(contract.updatedBy),
            'Modified Date and Time': contract.updatedBy?(contract.updatedDate != null ? this.convertDateTimeFormat(contract.updatedDate) : contract.updatedDate):"",
          };
          resolve(exportedData);
        })
      );
  const stakeholders = this.selectedRecordsToExpotToExcel.flatMap(contract =>
    [
      ...(contract.sponsorStakeholderDetails ?? [{}]).map((stakeholder: { sponsorContactId: any; isSendNotification: any; sendNotificationOptions :any }) => ({
        'Contract Title': contract.title,
        'Contract Id': contract.contractId,
        'Engagement Name': contract.engagementName,
        'Stakeholder Org': 'Sponsor',
        'Stakeholder Name': this.getStakeholderDetail(stakeholder.sponsorContactId, this.sponsorContacts, 'name') ?? '',
      'Designation': this.getStakeholderDetail(stakeholder.sponsorContactId, this.sponsorContacts, 'designation') ?? '',
      'Email': this.getStakeholderDetail(stakeholder.sponsorContactId, this.sponsorContacts, 'email') ?? '',
      'Country Code': this.getStakeholderDetail(stakeholder.sponsorContactId, this.sponsorContacts, 'countryCode') ?? '',
      'Phone Number': this.getStakeholderDetail(stakeholder.sponsorContactId, this.sponsorContacts, 'phoneNumber') ?? '',
        'Send Notifications': this.GetNotificationDetails(stakeholder.sendNotificationOptions) || '',

      })),
      ...(contract.supplierStakeholderDetails ?? [{}]).map((stakeholder: { supplierContactId: any; isSendNotification: any; sendNotificationOptions:any}) => ({
        'Contract Title': contract.title,
        'Contract Id': contract.contractId,
        'Engagement Name': contract.engagementName,
        'Stakeholder Org': 'Supplier',
        'Stakeholder Name': this.getStakeholderDetail(stakeholder.supplierContactId, this.supplierContactData, 'contactName') ?? '',
        'Designation': this.getStakeholderDetail(stakeholder.supplierContactId, this.supplierContactData, 'designation') ?? '',
        'Email': this.getStakeholderDetail(stakeholder.supplierContactId, this.supplierContactData, 'email') ?? '',
        'Country Code': this.getStakeholderDetail(stakeholder.supplierContactId, this.supplierContactData, 'countryCode') ?? '',
        'Phone Number': this.getStakeholderDetail(stakeholder.supplierContactId, this.supplierContactData, 'phoneNumber') ?? '',
        'Send Notifications': this.GetNotificationDetails(stakeholder.sendNotificationOptions) || '',
      }))
    ]
  );
  
  const changeOrders = this.selectedRecordsToExpotToExcel.flatMap(contract =>
    (contract.changeOrderDetails ?? [{}]).map((changeOrder: { title: any; startDate: any; endDate: any; statusMappingId: any; value: any; description: any; fileName: any; }) => ({
      'Contract Title': contract.title,
      'Contract Id': contract.contractId,
      'Engagement Name': contract.engagementName,
      'Change Order Title': changeOrder.title ?? '',
      'Start Date': changeOrder.startDate ?? '',
      'End Date': changeOrder.endDate ?? '',
      'Status': this.getStatusNameById(changeOrder.statusMappingId, 'ChangeOrder') ?? '',
      'Value': changeOrder.value ?? '',
      'Description': changeOrder.description ?? '',
      'Attachment': changeOrder.fileName ?? '',
    }))
  );
  
  const addendums = this.selectedRecordsToExpotToExcel.flatMap(contract =>
    (contract.addendumDetails ?? [{}]).map((addendum: { title: any; startDate: any; endDate: any; statusMappingId: any; description: any; fileName: any; }) => ({
      'Contract Title': contract.title,
      'Contract Id': contract.contractId,
      'Engagement Name': contract.engagementName,
      'Addendum Title': addendum.title ?? '',
      'Start Date': addendum.startDate ?? '',
      'End Date': addendum.endDate ?? '',
      'Status': this.getStatusNameById(addendum.statusMappingId, 'Addendum') ?? '',
      'Description': addendum.description ?? '',
      'Attachment': addendum.fileName ?? '',
    }))
  );

  Promise.all([details, stakeholders, changeOrders, addendums].map(p => Promise.all(p)))
    .then(([detailsData, stakeholdersData, changeOrdersData, addendumsData]) => {
      const worksheetDetails = xlsx.utils.json_to_sheet(detailsData);
      const worksheetStakeholders = xlsx.utils.json_to_sheet(stakeholdersData);
      const worksheetChangeOrders = xlsx.utils.json_to_sheet(changeOrdersData);
      const worksheetAddendums = xlsx.utils.json_to_sheet(addendumsData);

      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheetDetails, 'Details');
      xlsx.utils.book_append_sheet(workbook, worksheetStakeholders, 'Stakeholders');
      xlsx.utils.book_append_sheet(workbook, worksheetChangeOrders, 'Change Orders');
      xlsx.utils.book_append_sheet(workbook, worksheetAddendums, 'Addendums');
      if(this.isFunctionTrue){
        xlsx.writeFile(workbook, `${this.selectedRecordsToExpotToExcel[0].engagementName} - Function - ContractsExport  - ${this.convertDateFormat(Date())}.xlsx`);

      }
      if(this.fromSupplier && !this.isFunctionTrue){
        xlsx.writeFile(workbook, `${this.selectedRecordsToExpotToExcel[0].engagementName} - ContractsExport  - ${this.convertDateFormat(Date())}.xlsx`);
     }
     if(!this.fromSupplier && !this.isFunctionTrue){
      xlsx.writeFile(workbook, `AllContractsExport - ${this.convertDateFormat(Date())}.xlsx`);
     }
    })
    .catch(error => {
      console.error('An error occurred while exporting data:', error);
    })
    .finally(() => {
      this.loaderService.hideLoader();
    });
  }, 3000);
}

engagementName:any;
  getContractDetails: any;
  GetContractDetailsById(id: any): Observable<any> {
    return this._contractService.GetContractDtailsById(id).pipe(
      map(res => {
        if (res.data != null) {
          this.getContractDetails = res.data;
          return res.data;
        }

      })
    );
  }

  GetBillingTypeCodeList(billingTypes: any[]): Observable<string> {
    return this._masterDataService.getCodeListData(2, '').pipe(
      map(res => {
        const billingTypePerRecord: any[] = [];

        this.billingTypesDataSource = res.data;

        if (
          this.billingTypesDataSource != undefined &&
          this.billingTypesDataSource.length > 0 &&
          billingTypes.length > 0
        ) {
          billingTypes.forEach(id => {
            const filteredBillingTypes = this.billingTypesDataSource.filter(
              (billing: { id: any }) => billing.id === id.billingTypeId
            );
            if (
              (filteredBillingTypes != null &&
                filteredBillingTypes != undefined)
            ) {
              billingTypePerRecord.push(filteredBillingTypes[0].name);
            }
          });
        }

        return billingTypePerRecord.join(','); // Return the concatenated billing types
      })
    );
  }



  convertDateFormat(inputDateTime: string) {
    const date = new Date(inputDateTime);
    const formattedDate = this.datePipe.transform(date, this._dateformart.GridDateFormat);
    return formattedDate;
  }


  convertDateTimeFormat(inputDateTime: string) {  // while export to excel display UTC format
    const date = new Date(inputDateTime);
  
    // Adjust for timezone offset
    const timezoneOffsetHours = 5; 
    const timezoneOffsetMinutes = 30;
    date.setUTCHours(date.getUTCHours() + timezoneOffsetHours);
    date.setUTCMinutes(date.getUTCMinutes() + timezoneOffsetMinutes);
  
    // Format date
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
    const year = date.getUTCFullYear();
    const formattedDate = `${day}-${month}-${year}`;
  
    // Format time in UTC
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const formattedTime = `${hours}:${minutes} UTC`;
  
    return `${formattedDate} ${formattedTime}`;
  }

  selectedContractValuePerYearCount: any
  onContractsValuePerYearChange(event: any) {

    this.selectedContractValuePerYearCount = event.value;
    
    this.onFinalfilter();
  }

  selectedContractCount: any
  onContractsValueChange(event: any) {
    this.selectedContractCount = event.value;
    
    this.onFinalfilter();
  }
  selectFilterDate: any
  onDateSelect(event: any) {
    this.selectFilterDate = "";
    this.selectFilterDate = event.value;
    this.selectedDate = '';
  }

  onContractValuePerYearFilter() {
    this.filteredRecordCount = -1;
    this.contractList = this.unfilteredContractData;
    if (this.selectedContractValuePerYearCount != undefined && this.selectedContractValuePerYearCount != null) {
      if (this.selectedContractValuePerYearCount === 0) {
     
      }
      else {
        if (this.selectedContractValuePerYearCount == 90001) { //If the user chooses a filter with a value greater than 90,000 in the "contractValues" data source, the system should display results for values greater than or equal to 90001.
          this.contractList = this.contractList.filter(contract => contract.valuePerYear >= this.selectedContractValuePerYearCount);
        }
        else {
          this.contractList = this.contractList.filter(contract => contract.valuePerYear <= this.selectedContractValuePerYearCount);
        }
      }
      this.filteredRecordCount = this.contractList.length;
    }
  }

  onContractValueFilter() {
    this.filteredRecordCount = -1;
    this.contractList = this.unfilteredContractData;
    if (this.selectedContractCount != undefined && this.selectedContractCount != null) {

      if (this.selectedContractCount === 0) {
      } else {
        if (this.selectedContractCount == 90001) { //If the user chooses a filter with a value greater than 90000 in the "contractValues" data source, the system should display results for values greater than or equal to 90001.
          this.contractList = this.contractList.filter(contract => contract.value >= this.selectedContractCount);
        }
        else {
          this.contractList = this.contractList.filter(contract => contract.value <= this.selectedContractCount);
        }
      }
      this.filteredRecordCount = this.contractList.length;
    }
  }

  startDate: any;
  endDate: any;
  selectedDate: any;
  OnSelectDate() {
    if (this.selectFilterDate == "StartDate" && this.startDate != null && this.endDate != null && this.startDate != '' && this.endDate != '') {
      this.selectedDate = '';
      this.contractList = this.unfilteredContractData;

    }
    if (this.selectFilterDate == "StartDate" && this.selectedDate != null && this.startDate != '') {
      this.startDate = this.selectedDate[0];
      this.endDate = this.selectedDate[1];

      if (this.startDate != null) {
        this.filterDetailsModel.StartDateFirst = new Date(
          Date.UTC(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate())
        );
      } else {
        this.filterDetailsModel.StartDateLast = null;
      }

      if (this.endDate != null) {
        this.filterDetailsModel.StartDateLast = new Date(
          Date.UTC(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate())
        );
      } else {
        this.filterDetailsModel.StartDateLast = null;
      }
    }
    if (this.selectFilterDate == "StartDate" && this.startDate != null && this.endDate != null && this.startDate != '' && this.endDate != '') {
      
      this.onFinalfilter();
    }
    if (this.selectFilterDate == "EndDate" && this._startDate != null && this._endDate != null && this._startDate != '' && this._endDate != '') {
      this.selectedDate = '';
      this.contractList = this.unfilteredContractData;

    }
    if (this.selectFilterDate == "EndDate" && this.selectedDate != null && this._startDate != '') {
      this._startDate = this.selectedDate[0];
      this._endDate = this.selectedDate[1];

      if (this._startDate != null) {
        this.filterDetailsModel.EndtDateFirst = new Date(
          Date.UTC(this._startDate.getFullYear(), this._startDate.getMonth(), this._startDate.getDate())
        );
      } else {
        this.filterDetailsModel.EndDateLast = null;
      }

      if (this._endDate != null) {
        this.filterDetailsModel.EndDateLast = new Date(
          Date.UTC(this._endDate.getFullYear(), this._endDate.getMonth(), this._endDate.getDate())
        );
      } else {
        this.filterDetailsModel.EndDateLast = null;
      }
    }
    if (this.selectFilterDate == "EndDate" && this._startDate != null && this._endDate != null && this._startDate != '' && this._endDate != '') {
     
      this.onFinalfilter();
    }
  }

  _endDate: any
  _startDate: any;
  ondateFilter() {
    this.contractList = this.unfilteredContractData;

    if (this.filterDetailsModel.StartDateFirst != null && this.filterDetailsModel.StartDateLast != null) {
      if (!isNaN(this.startDate) && !isNaN(this.endDate)) {
        this.contractList = this.contractList.filter((contract) => {
          const contractDate = new Date(contract.startDate);
          return contractDate >= this.startDate && contractDate <= this.endDate;

        });
      }
    }
    this.filteredRecordCount = this.contractList.length;
  }


  onEndDateFilter() {
    this.contractList = this.unfilteredContractData;

    if (this.filterDetailsModel.EndtDateFirst != null && this.filterDetailsModel.EndDateLast != null) {
      if (!isNaN(this._startDate) && !isNaN(this._endDate)) {
        this.contractList = this.contractList.filter((contract) => {
          const contractDate = new Date(contract.endDate);

          return contractDate >= this._startDate && contractDate <= this._endDate;
        });
      }
    }
    this.filteredRecordCount = this.contractList.length;
  }


  isfiltered:boolean=false;
  filteredData:any;
  statusChange:boolean=false;

onFinalfilter(){
  this.filteredData='';
    this.isfiltered=false;
    this.contractList=this.unfilteredContractData;

  if (this.filterDetailsModel) {
    // ---------Key filter-------------------------
    if (this.filterDetailsModel.Key != null && this.filterDetailsModel.Key != undefined && this.filterDetailsModel.Key != "") {
      this.isfiltered=true;
      this.contractList = this.contractList.filter(a => a.title.toLowerCase().indexOf(this.filterDetailsModel.Key?.toLowerCase()) !== -1 || a.contractId.toLowerCase().indexOf(this.filterDetailsModel.Key?.toLowerCase()) !== -1);
      this.filteredData=this.contractList;
      this.filteredRecordCount = this.contractList.length;
    }
  }
   // ---------Supplier filter-------------------------

   if (this.filterDetailsModel.SupplierNameList != null && this.filterDetailsModel.SupplierNameList != undefined && this.filterDetailsModel.SupplierNameList.length > 0) {
    if(this.isfiltered){
    this.contractList=this.filteredData;
    }

    let supplierData: any[] = [];
    this.filterDetailsModel.SupplierNameList.forEach((engagementname: string) => {
      supplierData = [...supplierData, ...this.contractList.filter(a => a.engagementName.toLowerCase() === engagementname.toLowerCase())];
    });
    this.isfiltered=true;
    this.contractList = supplierData;
    this.filteredData=this.contractList;
  }

  //----------------------Start Date filter------------------------------------------------------

  if (this.filterDetailsModel.StartDateFirst != null && this.filterDetailsModel.StartDateLast != null) {
    if(this.isfiltered){
      this.contractList=this.filteredData;
    }
    if (!isNaN(this.startDate) && !isNaN(this.endDate)) {
      this.contractList = this.contractList.filter((contract) => {
        const contractDate = new Date(contract.startDate);
       
        return contractDate >= this.startDate && contractDate <= this.endDate;

      });
      this.isfiltered=true;
      this.filteredData=this.contractList;
    }
  }

  //----------------------End Date filter------------------------------------------------------
      if (this.filterDetailsModel.EndtDateFirst != null && this.filterDetailsModel.EndDateLast != null) {
        if(this.isfiltered){
          this.contractList=this.filteredData;
        }
      if (!isNaN(this._startDate) && !isNaN(this._endDate)) {
        this.contractList = this.contractList.filter((contract) => {
          const contractDate = new Date(contract.endDate);
         
          return contractDate >= this._startDate && contractDate <= this._endDate;
        });
        this.isfiltered=true;
        this.filteredData=this.contractList;
      }
    }

  //------------------------------Value Filter----------------------------------------------
   
    if (this.selectedContractCount != undefined && this.selectedContractCount != null) {
    if(this.isfiltered){
      this.contractList=this.filteredData;
    }
      if (this.selectedContractCount === 0) {
      }
       else {
        if (this.selectedContractCount == 90001) { //If the user chooses a filter with a value greater than 90000 in the "contractValues" data source, the system should display results for values greater than or equal to 90001.
          this.contractList = this.contractList.filter(contract => contract.value >= this.selectedContractCount);
        }
        else {
          this.contractList = this.contractList.filter(contract => contract.value <= this.selectedContractCount);
        }
        this.isfiltered=true;
        this.filteredData=this.contractList;
      }
     
    }

    //----------------------------Value per year filter--------------------------------------
   
    if (this.selectedContractValuePerYearCount != undefined && this.selectedContractValuePerYearCount != null) {
      if(this.isfiltered){
        this.contractList=this.filteredData;
      }
      if (this.selectedContractValuePerYearCount === 0) {
      }
      else {
        if (this.selectedContractValuePerYearCount == 90001) { //If the user chooses a filter with a value greater than 90,000 in the "contractValues" data source, the system should display results for values greater than or equal to 90001.
          this.contractList = this.contractList.filter(contract => contract.valuePerYear >= this.selectedContractValuePerYearCount);
        }
        else {
          this.contractList = this.contractList.filter(contract => contract.valuePerYear <= this.selectedContractValuePerYearCount);
        }
        this.isfiltered=true;
        this.filteredData=this.contractList;
      }
  
    }

    //-------------------------------------Curruncy filter-------------------------------------------
    if (this.filterDetailsModel.CurrencyList != null && this.filterDetailsModel.CurrencyList != undefined && this.filterDetailsModel.CurrencyList.length > 0) {
      if(this.isfiltered){
        this.contractList=this.filteredData;
      }
      let currencyFilterData: any[] = [];
      this.filterDetailsModel.CurrencyList.forEach((currencyname: string) => {
        currencyFilterData = [...currencyFilterData, ...this.contractList.filter(a => a.currencyName.toLowerCase() === currencyname.toLowerCase())];
      });
      this.contractList = currencyFilterData;
      this.isfiltered=true;
      this.filteredData=this.contractList;

    }
    // --------------------Status filter-----------------------------

    if (this.filterDetailsModel.StatusList != null && this.filterDetailsModel.StatusList != undefined && this.filterDetailsModel.StatusList.length > 0) {
      if(this.isfiltered){
       this.contractList=this.filteredData;
      }
     if (this.contractList.length == 0) {
       this.contractList = this.unfilteredContractData;
     }
     let statusFilterData: any[] = [];
     this.filterDetailsModel.StatusList.forEach((statusname: string) => {
       if (this.contractList.length == 0) {
         this.contractList = this.unfilteredContractData;
       }

       statusFilterData = [...statusFilterData, ...this.contractList.filter(a => a.statusName.toLowerCase() === statusname.toLowerCase())];
     });
     this.contractList = statusFilterData;
     this.filteredRecordCount = this.contractList.length;
     this.isfiltered=true;
     this.filteredData=this.contractList;
  }
  this.filteredRecordCount = this.contractList.length;
}

  clearFilters() {

    this.selectedContractValuePerYearCount = 0;
    this.selectedContractCount = 0;
    this.selectedDate = '';
    this.selectedOption = null;
    this.isChecked=true;
    this.isfiltered=false;
    this.checkedContracts=[];
    this.filterDetailsModel = {
      Key: '',
      SupplierNameList: [],
      StartDateList: '',
      EndDateList: '',
      ValueList: '',
      ValuePerYearList: '',
      Term: '',
      CurrencyList: '',
      StatusList: '',


    };
    this.GetContractList();
    this.onContractValuePerYearFilter();



  }


  selectCurrentPageRecords() {

    if (this.selectPageRecords) {
      const firstRowIndex = this.contractDataTable.first ?? 0; // Index of the first row on the current page
      const rowsPerPage = this.contractDataTable.rows ?? 10; // Number of rows displayed per page
      const currentPageRecords = this.contractList.slice(firstRowIndex, firstRowIndex + rowsPerPage);
      this.checkedContracts = [...currentPageRecords];
      this.CheckboxEnabled();
    }
    else {
      this.checkedContracts = [];

    }

  }



  selectAllRecords() {
    if (this.checked) {
      this.checkedContracts = [...this.contractDataTable.value];
      this.CheckboxEnabled();
    }
    else {
      this.checkedContracts = [];
    }
  }


  showHideControls() {
    this.showHideFiltersControls = !this.showHideFiltersControls;
  }
  generateBreadcrumb() {
 
    this.breadcrumbService.generateBreadcrumb(this._enumBreadcrumb.LCON);
  }
  getBreadcrumbs(): MenuItem[] {
    return this.breadcrumbService.getBreadcrumbs();
  }
  createContract() {
    if (this.supplierId == 0) {
      this.router.navigate(['/createcontract']);
    }
    else {
      this.tabAndStateService.setPreviousState('Contracts', 2);
      this.router.navigate(['/createcontract', this.supplierId]);
    }
  }


  CheckboxEnabled() {
    if (this.checkedContracts.length > 0) {
      this.isChecked = false;
    }
    else {
      this.isChecked = true;
    }
  }

  billingTypesDataSource: any;
  ContractDetails: any;
  BillingType: any;
  function: any;

  GetFunctionData(ownerFunctionId: any): Observable<any> {
    return this._masterDataService.getCodeListData(5, '').pipe(
      map(res => {
        if (res.data.length > 0) {
          var filteredFunction = res.data.filter((func: any) => func.id == ownerFunctionId);
          if (filteredFunction != undefined && filteredFunction != null && filteredFunction.length > 0)
            return filteredFunction[0].name;
        }
        return null;
      })
    );
  }


  SponserData(id: any, sponserContactId: any): Observable<string> {
    return this._masterDataService.getSponserData(id).pipe(
      map(res => {
        const matchingContact = res.data.sponserContacts.find((contact: { id: any }) => contact.id === sponserContactId);

        return matchingContact ? matchingContact.name : '';
      })
    );
  }
   

   billingTypeData:any
   GetBillingTypeData(){
    this._masterDataService.getCodeListData(2,'').subscribe(
      data=>{
        this.billingTypeData=data.data;
       ;
      }
    );
  }

  // functionData:any
  // GetSponsorFunctionData(){
  //   this._masterDataService.getCodeListData(5,'').subscribe(
  //     data=>{
  //       this.functionData=data.data;
      
  //     }
  //   );
  // }

  GetSelectedFunctionData(ownerFunctionId: any) {
    if (
      this.contractGroupData != undefined &&
      this.contractGroupData.length > 0 &&
      ownerFunctionId > 0
    ) {
      let filteredFunction =  this.contractGroupData.filter((func: any) => func.id == ownerFunctionId);

      if (filteredFunction != undefined && filteredFunction != null && filteredFunction.length > 0)
    
        return filteredFunction[0].name;
       
    }
    else{
      return 'Others';
     // return null
     
    }
  }

    // method to get filtered the billing type details the supplier Id
  GetSelectedBillingTypeCodeList(billingTypes: any[]) {
    var billingTypeList: any[] = [];
    if (
      this.billingTypeData != undefined &&
      this.billingTypeData.length > 0 &&
      billingTypes.length > 0
    ) {
      billingTypes.forEach(id => {
        const filteredBillingTypes = this.billingTypeData.filter(
          (billing: { id: any }) => billing.id === id.billingTypeId
        );
        if (
          (filteredBillingTypes != null &&
            filteredBillingTypes != undefined)
        ) {
          billingTypeList.push(filteredBillingTypes[0].name);
        }
      });
     
    }
   
    return billingTypeList.join(','); // Return the concatenated billing types
  }

  // method to get the supplier MSA name using the supplier Id 
  filteredSupplierMasterServiceAgreement:any;
  // GetSupplierMSAname(supplierId:any, supplierMasterServiceAgreementId:any){

  //   if (this.suppliersdata != undefined &&
  //     this.suppliersdata.length > 0 &&
  //     supplierId > 0) 
  //   {

  //     const filteredSupplier = this.suppliersdata.filter(
  //       (supplier: { id: any }) => supplier.id === supplierId
  //     );

  //     if (filteredSupplier != null &&
  //         filteredSupplier != undefined &&
  //         filteredSupplier.length > 0) {

  //       this.filteredSupplierMasterServiceAgreement = filteredSupplier[0].suppliersMasterServiceAgreements.filter(
  //         (supplierMSA: { id: any }) => supplierMSA.id === supplierMasterServiceAgreementId
  //       );

  //       if (this.filteredSupplierMasterServiceAgreement != null &&
  //           this.filteredSupplierMasterServiceAgreement != undefined &&
  //           this.filteredSupplierMasterServiceAgreement.length > 0) 
  //     {
  //          return this.filteredSupplierMasterServiceAgreement[0].title;
  //     }
  //     else
  //          return null;

  //     }

  //   }
    
  // }


  filteredEngagemetMasterServiceAgreement:any;
  GetEngagementMSAname(engagementId:any, engagementMasterServiceAgreementId:any){

    if (this.engagementdata != undefined &&
      this.engagementdata.length > 0 &&
      engagementId > 0) 
    {

      const filteredEngagement = this.engagementdata.filter(
        (supplier: { id: any }) => supplier.id === engagementId
      );

      if (filteredEngagement != null &&
        filteredEngagement != undefined &&
        filteredEngagement.length > 0) {

        this.filteredSupplierMasterServiceAgreement = filteredEngagement[0].engagementMasterServiceAgreements.filter(
          (engagementMSA: { id: any }) => engagementMSA.id === engagementMasterServiceAgreementId
        );

        if (this.filteredSupplierMasterServiceAgreement != null &&
            this.filteredSupplierMasterServiceAgreement != undefined &&
            this.filteredSupplierMasterServiceAgreement.length > 0) 
      {
           return this.filteredSupplierMasterServiceAgreement[0].title;
      }
      else
           return null;

      }

    }
    
  }

 filteredSupplierContact:any;
 filteredSponsorContact:any;
  // GetFilteredSponsorsBySupplierId(supplierId:any, sponsercontactId:any)
  // {

  //   if (supplierId != undefined && supplierId != null &&
  //     supplierId > 0) 
  //   {

  //     // const filteredSupplier = this.suppliersdata.filter(
  //     //   (supplier: { id: any }) => supplier.id === supplierId
  //     // );
  //     const filteredSupplier = this.supplierdata.filter(
  //       (supplier: { id: any }) => supplier.id === supplierId
  //     );

  //     if (filteredSupplier != null &&
  //       filteredSupplier != undefined &&
  //       filteredSupplier.length > 0) {
          
  //        var sponserId = filteredSupplier[0].sponserId;
      
  //       if (this.sponsorsDataSource != undefined &&
  //         this.sponsorsDataSource.length > 0 &&
  //         sponserId > 0) 
  //       {
        
  //           const filteredSponsor = this.sponsorsDataSource.filter(
  //             (sponser: { id: any }) => sponser.id === sponserId
  //           );
  
  //             if (filteredSponsor != null &&
  //               filteredSponsor != undefined &&
  //               filteredSponsor.length > 0) {
  //                 this.filteredSponsorContact = filteredSponsor[0].sponserContacts.filter(
  //                   (sponsorContact: { id: any, sponserId:any }) => sponsorContact.id === sponsercontactId && sponsorContact.sponserId === sponserId
  //                 );          
  //                 if (this.filteredSponsorContact != null &&
  //                     this.filteredSponsorContact != undefined &&
  //                     this.filteredSponsorContact.length > 0) 
  //                 {
  //                     return this.filteredSponsorContact[0].name;
  //                 }
  //                 else
  //                      return null;
  //               }
  //     }

  //   }
   
  // }
  //   }
    engagementdata:any;
    getAllEngagements(){
        this.engagementService.GetEngagementList()
        .subscribe(res => {
          //this.loaderService.hideLoader();
          this.engagementdata = res.data;
          this.supplierdata=res.data;
        });
    
  
    }
    contractGroupData:any
    getAllContractGroups(){
      this.engagementService.GetContractGroupList()
      .subscribe(res => {
        //this.loaderService.hideLoader();
        this.contractGroupData = res.data;
      });
   }
   changeOrders: any;
   changeOrderStatus: any;
   GetChangeOrdersStatus() {
    this._masterDataService.getCodeListData(3, 'Change Order').subscribe(
        data => {
            this.changeOrderStatus = data.data;
        });
}

   addendumStatus:any;
   GetAddendumStatus() {
    this._masterDataService.getCodeListData(3, 'Addendum').subscribe(
        res => {
          this.addendumStatus=res.data;
        });
}
getStatusNameById(statusId: number,name:string): string | undefined {
  if(name=='ChangeOrder'){
  if (!this.changeOrderStatus) {
      return undefined;
  }
  const status = this.changeOrderStatus.find((status: any) => status.id === statusId);
  return status ? status.name : undefined;
}
else{
  if (!this.addendumStatus) {
    return undefined;
}
const status = this.addendumStatus.find((status: any) => status.id === statusId);
return status ? status.name : undefined;
}
}

sponsorContacts:any[]=[];
getContacts(){
this.sponsorContacts=[];
  this.checkedContracts.forEach(contract => {
    const engagement = this.engagementdata.find((e: { id: any; }) => e.id === contract.engagementId);
    if (engagement) {
      contract.engagementDetails = engagement;
      this._masterDataService.getSponserContactsDataById(engagement.sponsorId).subscribe(
        async res =>{
          if (Array.isArray(res.data)) {
            this.sponsorContacts.push(...res.data); 
          } else {
            this.sponsorContacts.push(res.data); 
          }
          this.GetSupplierContacts(engagement.supplierId);
      });
    }
    return contract;
  });
  
}

supplierContactData: any[] = []; 

GetSupplierContacts(Id: number) {
  this._mastersupplierService.getSupplierContactsDataById(Id)
    .subscribe(async res => {
      if (Array.isArray(res.data)) {
        this.supplierContactData.push(...res.data);
      } else {
        this.supplierContactData.push(res.data); 
      }
      await this.supplierContactData;
    });
}



GetFilteredSupplierContactName(Id: number) {
  const contact = this.supplierContactData?.find((contact: { id: number; }) => contact.id === Id);
  return contact ? contact.contactName : '';
}

GetFilteredSponsorContactName(Id: number) {
  const contact = this.sponsorContacts?.find((contact: { id: number; }) => contact.id === Id);
  return contact ? contact.name : '';
}


getStakeholderDetail(stakeholderId: any, collection: any[], detailKey: string): string {
  const stakeholder = collection.find((s: { id: any; }) => s.id === stakeholderId);
  return stakeholder ? stakeholder[detailKey] : '';
}


notificationOptionsDataSource: CodeListValues[] | undefined;
GetAllNotificationOptions(type:number) {
  this._masterDataService.getCodeListData(type,'').subscribe(
    res=>{
      this.notificationOptionsDataSource=res.data;
    }
  );
};

GetNotificationDetails(NotificationIds: any): string {
  if (!NotificationIds || !this.notificationOptionsDataSource) return ''; // Check if NotificationIds or data source is missing

  const idsArray = Array.isArray(NotificationIds) ? NotificationIds : [NotificationIds];
  
  const notificationNames = idsArray
    .map(notificationId => {
      const notification = this.notificationOptionsDataSource?.find((n: any) => n.id === notificationId);
      return notification ? notification.name : ''; 
    })
    .filter(name => name); 

  return notificationNames.join(', ') || '';
}




}

