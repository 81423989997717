import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })

  export class ExcelService{
  convertDateTimeFormat(inputDateTime: string) { 
    const date = new Date(inputDateTime);

    // Adjust for timezone offset
    const timezoneOffsetMinutes = (5 * 60) + 30; // Convert hours and minutes to total minutes
    date.setUTCMinutes(date.getUTCMinutes() + timezoneOffsetMinutes);

    // Format date
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
    const year = date.getUTCFullYear();
    const formattedDate = `${day}-${month}-${year}`;

    // Format time in UTC
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const formattedTime = `${hours}:${minutes} UTC`;

    return `${formattedDate} ${formattedTime}`;
}

}