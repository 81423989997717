import { forkJoin, tap } from 'rxjs';
import { Component, Input, } from '@angular/core';
import { } from '@angular/router';
import { } from '@angular/router';
import { DatePipe } from '@angular/common';

import { Dateformat,CurrencyType, FileExtension, } from 'src/app/shared/constants/global.constants';
import { FinanceService } from 'src/app/Finance/Services/invoice.service';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';
import { LoaderService } from 'src/app/Loader/loader.service';
import { CurrencyService } from 'src/app/Reusable/currency.service';
import { ConversionRate } from 'src/app/Reusable/conversion-rate';
import FileSaver from 'file-saver';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { ExcelService } from 'src/app/Reusable/excel.service';
import { EngagementService } from 'src/app/Engagement/Services/engagement.service';
import { Privileges } from 'src/app/shared/constants/privileges.constants';
import { environment } from 'src/environments/environment';
import { AuthCommonService } from 'src/app/shared/Authentication/auth.service';
import { DashboardService } from 'src/app/Dashboard/Services/dashboard.service';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import { EmbeddingContext } from 'amazon-quicksight-embedding-sdk/dist';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { TrustedAdvisor } from 'aws-sdk';

@Component({
  selector: 'app-eng-summary-view',
  templateUrl: './eng-summary-view.component.html',
  styleUrls: ['./eng-summary-view.component.css']
})
export class EngSummaryViewComponent {
  @Input() engagementId:any;
  @Input() engagementName:any;
  _dateformart :any;
  currenciesData : any[] = [];
  AccumulativeExportData : any[] = [];
  currencyformat:any;
  conversionRate: ConversionRate = new ConversionRate();
  engagementSummarydata:any;
  summaryData:any[]=[];
  engagementSummarydataCopy:any
  totalNoOfContracts:any;
  totalContractedValue:any
  forecastedBillingYTD:any
  actualBillingYTD:any
  paymentYTD:any
  outstandingAmount:any
  selectedCurrency:any;
  selectedAccumulativeExport:any=0;
  ExportPrivilage:boolean=false;
  constructor(
    private _financeInvoiceService:FinanceService,private _masterDataService:SponserService,
    private loaderService: LoaderService,private _currencyService:CurrencyService,
    private privilegeService: PrivilegeService,private datePipe: DatePipe,private excelService: ExcelService,
    private engagementService: EngagementService,private authService: AuthCommonService,private _dashboaredService: DashboardService) {
  }
  ngOnInit() {
    this._dateformart=Dateformat;
    this.currencyformat=CurrencyType;
    this.GetCurrencyData();
    this.loaderService.hidefinalLoader();
    this.AccumulativeExportData=[
      { Id: 1, Text: 'Invoices'},
      { Id: 2, Text: 'Draft Invoices' },
      { Id: 3, Text: 'Forecasts'}
  ];
  this.BindEngagementContractsGroups();

  this.GetEngagementFiscalYearDetails();
  this.BindPrivileges();
  setTimeout(() => {
    this.GetEngagementDetailsById();
  }, 2000);

  }

  viewFinanceDashboard=false;
  BindPrivileges(){
 const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);
 this.ExportPrivilage = hasPermission(Privileges.ExportEngagementFinance);
 this.viewFinanceDashboard=hasPermission(Privileges.ViewEngagementFinanceDashboard);
 }

  GetConversionRates(){
    this._currencyService.GetConversionRates.subscribe(
      (result:any)=>{
        this.conversionRate=result.data.conversionRates;
        setTimeout(() => {
          this.GetEngagementSummaryDetails(this.engagementId);
        }, 2000);
        
        this.updateTooltipContent();
      }
    );
  }
  GetCurrencyData(){
    this.loaderService.showLoader();
    this._masterDataService.getCodeListData(4,'').subscribe(
      data=>{
      
        this.currenciesData= data.data;

        this.GetConversionRates();
      }
    );
  }
  
  isBaseValue:boolean=false;
  GetEngagementSummaryDetails(engagementId:number){
    this.isBaseValue=false;
    this._financeInvoiceService.GetEngagementSummaryDetailsById(engagementId).subscribe(
      res => {
        if (res.data) {
         
          this.engagementSummarydata = res.data;
          this.engagementSummarydataCopy=res.data;
          this.totalNoOfContracts= this.engagementSummarydata.length;
          if(this.engagementSummarydata.length>0){
          this.isBaseValue=true;
          const currency=this.selectedCurrency.toLowerCase();
          this.ConvertContactValuesByCurrency(currency,this.isBaseValue);
          
          }
          this.loaderService.hideLoader();
        }
      }
    );
  }
  
  ConvertContactValuesByCurrency(currency: any,isBaseValue:boolean) {
    const originalData = [...this.engagementSummarydataCopy];
    this.engagementSummarydata = originalData.map(con => ({ ...con }));
  
    const conversionObservables = this.engagementSummarydata.map((con:any) => {
      if(isBaseValue){
        return this._currencyService.getExchangeRate(this.conversionRate, currency, currency).pipe(
          tap(amount => {
            con.totalContractValue *= amount;
            con.forecastedBillingYTD *= amount;
            con.actualBilledYTD *= amount;
            con.paymentYTD *= amount;
          })
        );
      }
      else{
        con.currencyCode=this.baseCurruncy.toLowerCase();
      return this._currencyService.getExchangeRate(this.conversionRate, con.currencyCode, currency).pipe(
        tap(amount => {
          con.totalContractValue *= amount;
          con.forecastedBillingYTD *= amount;
          con.actualBilledYTD *= amount;
          con.paymentYTD *= amount;
        })
      );
    }
    });

   

  
   
  
    forkJoin(conversionObservables).subscribe(() => {
      this.updateSummaryValues();
     
    });
  }



  ConvertContactValuesByCurrency_ForFiscalYear(currency: any,isBaseCurrency:boolean) {
    const originalData = [...this.engagementFiscalYearDataOriginal];
    this.engagementFiscalYearData = originalData.map(con => ({ ...con }));
  
    const conversionObservables1=this.engagementFiscalYearData.map((con:any) => {
      if(isBaseCurrency){
      return this._currencyService.getExchangeRate(this.conversionRate, currency, currency).pipe(
        tap(amount1 => {
          con.totalActiveContractBudget *= amount1;
          con.totalActiveContractActualBilled *= amount1;
          con.totalActiveContractBudgetBalance *= amount1;
        })
      );
    }
    else{
      return this._currencyService.getExchangeRate(this.conversionRate, this.baseCurruncy, currency).pipe(
        tap(amount1 => {
          con.totalActiveContractBudget *= amount1;
          con.totalActiveContractActualBilled *= amount1;
          con.totalActiveContractBudgetBalance *= amount1;
        })
      );
    }
    });

   

  
   
  
    forkJoin(conversionObservables1).subscribe(() => {
     //console.log(this.engagementFiscalYearData);
  
    });
  }
  
  
  private updateSummaryValues() {
    this.totalContractedValue = this.engagementSummarydata.reduce((t: any, valu: { totalContractValue: any; }) => t + valu.totalContractValue, 0);
    this.forecastedBillingYTD = this.engagementSummarydata.reduce((t: any, valu: { forecastedBillingYTD: any; }) => t + valu.forecastedBillingYTD, 0).toFixed(0);
    this.actualBillingYTD = this.engagementSummarydata.reduce((t: any, valu: { actualBilledYTD: any; }) => t + valu.actualBilledYTD, 0);
    this.paymentYTD = this.engagementSummarydata.reduce((t: any, valu: { paymentYTD: any; }) => t + valu.paymentYTD, 0);
    this.outstandingAmount = (this.actualBillingYTD - this.paymentYTD).toFixed(2);
  }

  getFormattedValue(value: number, currencyName: string): string {
    return this._currencyService.getFormattedValue(value, currencyName);
  }
  
  isChecked:boolean=true;
  checkedInvoice:any[]=[];
  CheckboxEnabled(){
    if(this.checkedInvoice.length>0){
      this.isChecked=false;
    }
    else{
      this.isChecked=true;
    }
  }
  
  selectedRecordsToExpotToExcel: any[] = [];
  exportexcelInvoice() {
  this.selectedRecordsToExpotToExcel = this.checkedInvoice;
  this.selectedRecordsToExpotToExcel = this.selectedRecordsToExpotToExcel.map((invoice) => {
    
    return {
    
      'Contract Id': invoice.contractID,
      Title:invoice.title,
      'Total Contract Value':invoice.totalContractValue,
      'PO Number':invoice.poNumber,
      'Forecasted Billing Till Date':invoice.forecastedBillingYTD,
      'Actual Billed Till Date':invoice.actualBilledYTD,
      'Payment Till Date':invoice.paymentYTD,
      'PO Utilization (%)':invoice.poUtilization
    };
  });
  this.exportExcel(this.selectedRecordsToExpotToExcel,'Engagement Finance',"Finance");
}
  OnCurrencyChange(event:any){
    this.checkedInvoice = [];
    this.CheckboxEnabled();
    this.selectedCurrency=event.value;
   this.ConvertContactValuesByCurrency(event.value,false);
   this.ConvertContactValuesByCurrency_ForFiscalYear(event.value,false);
   this.updateTooltipContent();
  }

exportExcel(SelectedRecords: any, fileName: any, sheetName: string) {
  import('xlsx').then((xlsx) => {
    const worksheet = xlsx.utils.json_to_sheet(SelectedRecords);
    const workbook = { Sheets: { [sheetName]: worksheet }, SheetNames: [sheetName] };
    const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, fileName);
  });
}
saveAsExcelFile(buffer: any, fileName: string): void {
  let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  let EXCEL_EXTENSION = FileExtension.ExportExtention;
  const data: Blob = new Blob([buffer], {
    type: EXCEL_TYPE
  });
  FileSaver.saveAs(data, fileName+ EXCEL_EXTENSION);
}
isSelected:boolean=false;
OnAccumulativeExportChange(event:any){
 this.selectedAccumulativeExport=event.value;
}
_engagementAccumulativeExportData: any[] = [];
GetEngagementAccumulativeExportData(){
  this.loaderService.showLoader();
  this._financeInvoiceService.GetEngagementAccumulativeExportData(this.engagementId,this.selectedAccumulativeExport).subscribe(
    res => {
      if (res.data) {
        this._engagementAccumulativeExportData = res.data;
        this.AccumulativeExport(this.selectedAccumulativeExport)
        this.loaderService.hideLoader();
      }
    }
  );
}

AccumulativeExport(exportType:any) {
  if(exportType==1)
    {
    this._engagementAccumulativeExportData = this._engagementAccumulativeExportData.map((invoice) => {
    return {
      'Contract Name': invoice.contractName,
      'Contract ID': invoice.contractId,
      'Function': this.getEngagementGroupNameById(invoice.function),
      'Invoice Number':invoice.invoiceNumber,
      'Invoice Value':invoice.invoiceValue,
      'Invoice Status':invoice.status,
      'Draft Invoice Sent Date':invoice.draftInvoiceSentDate!=null ? this.convertDateFormat(invoice.draftInvoiceSentDate):invoice.draftInvoiceSentDate,
      'Draft Invoice Approval Date':invoice.draftInvoiceApprovalDate!=null ? this.convertDateFormat(invoice.draftInvoiceApprovalDate):invoice.draftInvoiceApprovalDate,
      'Invoice Upload Date':invoice.invoiceRaisedDate!=null ? this.convertDateFormat(invoice.invoiceRaisedDate):invoice.invoiceRaisedDate,
      'Due Date':invoice.dueDate!=null ? this.convertDateFormat(invoice.dueDate):invoice.dueDate,
       'Days To Due Date' : invoice.daysToDueDate,
      'Payment Amount':invoice.paymentAmount,
      'Payment Date':invoice.paymentDate!=null ? this.convertDateFormat(invoice.paymentDate):invoice.paymentDate,
      'Attachment': (invoice.financeContractInvoiceAttachments && invoice.financeContractInvoiceAttachments.length > 0) ? invoice.financeContractInvoiceAttachments[0].fileName : '',
      'Remarks':invoice.remarks,
      'Description':invoice.description,
       'Modified By':this.privilegeService.getUserName(invoice.modifiedBy),
      'Modified Date & Time' : invoice.modifiedBy != null ? (invoice.modifiedDateTime != null ? this.excelService.convertDateTimeFormat(invoice.modifiedDateTime) : '') : '',
    };
  });
  this.exportExcel(this._engagementAccumulativeExportData,this.engagementName+`- InvoicesExport - ${this.convertDateFormat(Date())}`,'InvoicesExportData');
}
if(exportType==2)
  {
  this._engagementAccumulativeExportData = this._engagementAccumulativeExportData.map((accural) => {
  return {
    'Contract Name': accural.contractName,
    'Contract ID': accural.contractId,
    'Function': this.getEngagementGroupNameById(accural.function),
    'Month':this.datePipe.transform(accural.month, "MMMM yyyy"),
    'Draft Invoices Amount':accural.draftInvoicesAmount!=0?accural.draftInvoicesAmount:'',
    'Status':accural.status,
    'Remarks':accural.remarks,
    'Attachment': (accural.financeContractAccrualAttachments && accural.financeContractAccrualAttachments.length > 0) ? accural.financeContractAccrualAttachments[0].fileName : '',
    'Modified By':this.privilegeService.getUserName(accural.modifiedBy),
    'Modified Date & Time' : accural.modifiedBy != null ? (accural.modifiedDateTime != null ? this.excelService.convertDateTimeFormat(accural.modifiedDateTime) : '') : '',
    'Approved By':this.privilegeService.getUserName(accural.approvedBy),
    'Approved Date & Time':accural.approvedBy != null ? (accural.approvedDateTime != null ? this.excelService.convertDateTimeFormat(accural.approvedDateTime) : '') : '',
  };
});
this.exportExcel(this._engagementAccumulativeExportData,this.engagementName+`- DraftInvoicesExport - ${this.convertDateFormat(Date())}`,'DraftInvoicesExportData');
}
if (exportType == 3) {
const uniqueForecastsWithAmount = (forecasts: any[]) => {
  const foreCastRecords = new Map();
  forecasts.forEach((forecast: { contractName: any; month: string | number | Date; forecastAmount: number; }) => {
    const foreCastMonth = `${forecast.contractName}-${this.datePipe.transform(forecast.month, "MMMM yyyy")}`;
    if (forecast.forecastAmount !== 0) {
      foreCastRecords.set(foreCastMonth, forecast); 
    } else {
      if (!foreCastRecords.has(foreCastMonth)) {
        foreCastRecords.set(foreCastMonth, forecast);
      }
    }
  });
  return Array.from(foreCastRecords.values());
};

this._engagementAccumulativeExportData = uniqueForecastsWithAmount(this._engagementAccumulativeExportData).map((forecast) => { 
  return {
    'Contract Name': forecast.contractName,
    'Contract ID': forecast.contractId,
    'Function': this.getEngagementGroupNameById(forecast.function),
    'Month': this.datePipe.transform(forecast.month, "MMMM yyyy"),
    'Forecast Amount': forecast.forecastAmount !== 0 ? forecast.forecastAmount : '',
    'Remarks': forecast.remarks,
    'Attachment': (forecast.financeContractForecastAttachments && forecast.financeContractForecastAttachments.length > 0) ? forecast.financeContractForecastAttachments[0].fileName : '',
    'Modified By': this.privilegeService.getUserName(forecast.modifiedBy),
    'Modified Date & Time': forecast.modifiedBy !== null ? 
      (forecast.modifiedDateTime !== null ? this.excelService.convertDateTimeFormat(forecast.modifiedDateTime) : '') : '',
  };
});
  this.exportExcel(this._engagementAccumulativeExportData,this.engagementName + `- ForecastsExport - ${this.convertDateFormat(Date())}`,'ForecastsExportData');
}

}
convertDateFormat(inputDateTime: string) {
  const date = new Date(inputDateTime);
  const formattedDate = this.datePipe.transform(date, this._dateformart.GridDateFormat);
  return formattedDate;
}
engagementContractsGroups: any;
BindEngagementContractsGroups(){

  this.engagementService.GetAllEngagementContractGroupsById(this.engagementId).subscribe(
    res => {
      if (res.data) {
        this.engagementContractsGroups=res.data;
      }
    }
  );
}
getEngagementGroupNameById(id: number): string | undefined {
  const record = this.engagementContractsGroups.find((item: { id: number; }) => item.id === id);
  return record ? record.engagementGroupName : undefined;
}

engagementFiscalYearData:any;
engagementFiscalYearDataOriginal:any;
GetEngagementFiscalYearDetails(){
  this._financeInvoiceService.GetFiscalYearDataByEngagementId(this.engagementId).subscribe(
    res => {
      if (res.data) {
        this.engagementFiscalYearData=res.data;
        this.engagementFiscalYearDataOriginal=res.data;
        //this.ConvertContactValuesByCurrency_ForFiscalYear(this.selectedCurrency.toLowerCase());
      }
    
});


}


//DashboardCode will start from Here
selectedDashboardToView="";
DashboardToViewDataSource: any[] = [
  { label: 'Till Date Values', value: 'tillDate' },
  { label: 'Fiscal Year Values', value: 'fiscalYear' }
];

IsDashboardTillDate=true;
fiscalYearList:any[]=[];
selectedFiscalYear:any;
onDashboardToViewChange(data:any){
  if(data.value=='tillDate'){
    this.IsDashboardTillDate=true;
    this.fiscalYearList=[];
    this.fiscalYearList.push({ label: 'Not Applicable', value: 'NotApplicable' });
    this.selectedFiscalYear=this.fiscalYearList[0].value;
    this.isApplyDisable=false;
  }
  else{
    this.IsDashboardTillDate=false;
    this.isApplyDisable=true;
    this.GetEngagementFiscalYearTableData();
  }
}


isApplyDisable=true;
onFiscalYearChange(data:any){
  if(data.value != null){
  this.isApplyDisable=false;
  }
  else{
    this.isApplyDisable=true;
  }
}

dashboardId:any;
onDashboardApplyClick(){
    if(this.IsDashboardTillDate){
     
      this.dashboardId=environment?._financeEngagementTillDateDashboardId;
    }
    else{
      this.dashboardId=environment?._financeEngagementFiscalYearDashboardId;
    }




    
    this.GetReportingServicesEmbedURL();
}


engagementFiscalYearTableData:any;
GetEngagementFiscalYearTableData(){
  
  this._financeInvoiceService.GetAllFiscalYearDetailsByEngagementId(this.engagementId).subscribe(
    res => {
      if (res.data) {
        this.engagementFiscalYearTableData=res.data;
        this.GetFiscalYearList();
      }
});
}


GetFiscalYearList(){
  this.fiscalYearList=[];
  const distinctFiscalYears = Array.from(new Set(this.engagementFiscalYearTableData.map((item:any) => item.fiscalYear)));
  
  // Sort the distinct fiscal years in ascending order
  distinctFiscalYears.sort((a:any, b:any) => {
    const [aStartYear, aEndYear] = a.split(' - ').map((part:any) => parseInt(part.split(' ')[1]));
    const [bStartYear, bEndYear] = b.split(' - ').map((part:any) => parseInt(part.split(' ')[1]));

    return aStartYear - bStartYear || aEndYear - bEndYear;
  });
  this.fiscalYearList = distinctFiscalYears.map(fiscalYear => ({
    label: fiscalYear,
    value: fiscalYear
  }));

}

reportingURL:any;
    public async GetReportingServicesEmbedURL() {
     
      var model = {
        "dashboardID": this.dashboardId,
        "token": this.authService.getCookieValue('LOGIN_TOKEN'),
        "privilege": [
          {
            "MenuName": "string",
            "isAllDataAvailable": true,
            "DataValues": []
          }
        ]
      };
    
      // Create an observable for the firstEmdbedURL
    this._dashboaredService.getEmbedUrlData(model).subscribe(
      async (res: any) => {
  
          this.reportingURL=res.data;
  
          await this.FinanceEngagementDashboard();
    });
    
      // Combine the observables using forkJoin
     
    }
  
   
    public async FinanceEngagementDashboard() {
      var containerDiv = document.getElementById("DashboardContainer") || '';
      if (containerDiv && containerDiv instanceof HTMLElement) {
        containerDiv.innerHTML = ''; // Clear the container
    }
      const embeddingContext: EmbeddingContext = await createEmbeddingContext();
      //this.engagementId=3334;//3308;
      //this.selectedFiscalYear="April 2023 - March 2024";
     let encodedFiscalYear = encodeURIComponent(this.selectedFiscalYear);
      var URL;
      
      if(this.IsDashboardTillDate){
        URL=this.reportingURL + '&#p.EngagementId=' + this.engagementId + '&p.Currency=' + this.baseCurruncy;
      }
      else{
        URL=this.reportingURL + '&#p.EngagementId=' + this.engagementId + '&p.FiscalYear=' + encodedFiscalYear + '&p.Currency=' + this.baseCurruncy;
      }
      
     
      const frameOptions = {
        url: URL,
        container: containerDiv,
        height: "800px",
       // width: "700px",
        resizeHeightOnSizeChangedEvent: true,
        onChange: (changeEvent:any, metadata:any) => {
          switch (changeEvent.eventName) {
            
              case 'FRAME_LOADED': {
              
                   this.loaderService.hideLoader();
                
                  break;
              }
          }
      },
    }
      
     embeddingContext.embedDashboard(frameOptions);
  
    }
tooltipContent: string = '';
getBaseRate(selectedCurrency: string): number | undefined {
  const lowerCaseCurrency = selectedCurrency.toLowerCase(); // Convert to lowercase
  return this.conversionRate[lowerCaseCurrency as keyof ConversionRate];
}

generateTooltipContent(selectedCurrency: string): string {
  const baseRate = this.getBaseRate(selectedCurrency);
  if (baseRate === undefined) {
    return `Base rate for ${selectedCurrency.toUpperCase()} not found`;
  }

  const availableCurrencyCodes = this.getAvailableCurrencyCodes();
  let content = '';

  for (const [currency, rate] of Object.entries(this.conversionRate)) {
    if (currency.toUpperCase() !== selectedCurrency.toUpperCase() &&
        availableCurrencyCodes.has(currency.toUpperCase()) &&
        rate != null) {
      const convertedRate = this.getConvertedRate(rate, baseRate);
      content += `${selectedCurrency.toUpperCase()} - ${currency.toUpperCase()}: ${isNaN(convertedRate) ? 'N/A' : convertedRate}\n`;
    }
  }
  return content;
}
getConvertedRate(rate: number | undefined, baseRate: number | undefined): number {
  if (rate == null || baseRate == null) {
    return NaN;
  }
  return parseFloat((rate / baseRate).toFixed(4));
}

updateTooltipContent() {
  this.tooltipContent = this.generateTooltipContent(this.selectedCurrency);
} 
getAvailableCurrencyCodes(): Set<string> {
  return new Set(this.currenciesData.map(currency => currency.code.toUpperCase()));
}
defaultCurrency:any;
baseCurruncy:any;
GetEngagementDetailsById() {
  this.loaderService.showLoader();  
  if (this.engagementId != undefined && this.engagementId != null) {
    this.engagementService.ViewEngagementById(this.engagementId)
      .subscribe(res => {
        this.loaderService.hideLoader();
       this.defaultCurrency = res.data.defaultCurrency;
       const selectedCurrencyObj = this.currenciesData.find(currency => currency.name === this.defaultCurrency);
       if (selectedCurrencyObj) {
         this.selectedCurrency = selectedCurrencyObj.code;
         this.baseCurruncy=selectedCurrencyObj.code;
         this.ConvertContactValuesByCurrency_ForFiscalYear(this.selectedCurrency.toLowerCase(),true);
       }
     });
}
}
}
