import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "src/environments/environment";




@Injectable({

  providedIn: 'root'

})
export class DashboardService{
  readonly __reportingServiceApiBaseURl?:string="";
 
constructor(private http: HttpClient) { 
  this.__reportingServiceApiBaseURl=environment?._commonReportingServiceApiBaseURL;
}



  public getEmbedUrlData(model:any): Observable<any> {
    var token="TestValue";
      var httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json',"Authorization": "Bearer "+token}) };
    
    //var url='https://6m2pgwkskf.execute-api.us-east-1.amazonaws.com/api/v1/generate_report_url';
    var url=this.__reportingServiceApiBaseURl + '/generate_report_url';
    return this.http.post<any>(url, model,httpOptions);
    
  }


  


  


    }
