import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FileExtension } from 'src/app/shared/constants/global.constants';
import { AuthCommonService } from 'src/app/shared/Authentication/auth.service';


@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  readonly _fileApiBaseURl?:string="";
  readonly _fileSize:Number;
  readonly  allowedExtensions = [FileExtension.DOC,FileExtension.DOCX,FileExtension.PDF,FileExtension.XLS,FileExtension.XLSX];
  constructor(private http: HttpClient,private authCommonService: AuthCommonService) {
    this._fileApiBaseURl=environment?._fileApiBaseURl;
    this._fileSize=environment?._fileSize!;
   }

  UploadSingleFile(fileData:any): Observable<any> {
    //const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data',"Accept": "text/plain" }) };
    //return this.http.post<any>('https://localhost:7016/Api/Gateway/Files/UploadFile', fileData);
    // return this.http.post<any>( this._fileApiBaseURl + '/UploadFile', fileData);
    const httpOptions = { headers: new HttpHeaders({'ApiKey':this.authCommonService.getAuthenticationToken()}) };
   return this.http.post<any>( this._fileApiBaseURl + '/UploadSingleFile', fileData,httpOptions);
   
  }

  UploadMultipleFile(fileData:any): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({'ApiKey':this.authCommonService.getAuthenticationToken()}) };
   return this.http.post<any>( this._fileApiBaseURl + '/UploadMultipleFiles', fileData,httpOptions);
  }
  
  DownloadFile(fileDetail:any) {
   // let params = new HttpParams().set("fileDetail", fileDetail);
    const httpOptions = { headers: new HttpHeaders({'ApiKey':this.authCommonService.getAuthenticationToken()}) };
    return this.http.post<any>( this._fileApiBaseURl + '/DownloadFile',fileDetail,httpOptions);
   // return this.http.get<any>( this._fileApiBaseURl + '/DownloadFile1?fileName=' + fileDetail.fileName);
  }
  DeleteFile(fileDetail:any): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({'ApiKey':this.authCommonService.getAuthenticationToken()}) };
   return this.http.post<any>( this._fileApiBaseURl + '/DeleteFile', fileDetail,httpOptions);
  }

  dataURItoBlob(dataURI:any) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array]);
    return blob;
  }

  downloadBase64File(blob:any, fileName:any) {
    const response=true;

    let dwldLink = document.createElement('a');
    let url = URL.createObjectURL(blob);
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', fileName);
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);

     return response;
  }


}
