import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
// import { EngagementAudit } from '../Model/SupplierAudit';
import { EngagementAudit } from '../Model/EngagementAudit';
import { Observable } from 'rxjs';
import { AuthCommonService } from 'src/app/shared/Authentication/auth.service';

@Injectable({
  providedIn: 'root'
})
export class EngagementAuditService {
  readonly _governanceAuditApiBaseURl:any;
  readonly _sponserApiBaseUrl:any="";
  readonly _fileApiBaseURl?:string="";
  constructor(private http: HttpClient,private authCommonService: AuthCommonService) {
    this._governanceAuditApiBaseURl=environment._governanceApiBaseURl;
   }
  // AddSupplierAudit(supplierAudit: SupplierAudit): Observable<any> {
  //  const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
  //  return this.http.post<any>(this._governanceAuditApiBaseURl + '/GovernanceAudits/AddSupplierAudit', supplierAudit, httpOptions);
  //}
  GetSupplierAuditById(supplierAuditId:number){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
    return this.http.get<any>(this._governanceAuditApiBaseURl + '/GovernanceAudits/GetSupplierAuditById?supplierAuditId='+supplierAuditId, httpOptions);
  }
  //UpdateSupplierAudit(supplierAudit: SupplierAudit): Observable<any> {
  //  const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
  //  return this.http.put<any>(this._governanceAuditApiBaseURl + '/GovernanceAudits/UpdateSupplierAudit', supplierAudit, httpOptions);
  //}
  public getSupplierAuditsBySupplierProfileId(supplierProfileId:number): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.get<any>(this._governanceAuditApiBaseURl +  '/GovernanceAudits/GetSupplierAuditsBySupplierProfileId?supplierProfileId=' + supplierProfileId, httpOptions);
  }

  public getSupplierAuditById(supplierAuditId:number): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.get<any>(this._governanceAuditApiBaseURl +  '/GovernanceAudits/GetSupplierAuditById?supplierAuditId=' + supplierAuditId, httpOptions);

  }
  public getengagementAuditsByEngagementId(engagementId:number): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.get<any>(this._governanceAuditApiBaseURl +  '/GovernanceAudits/GetEngagementAuditsByEngagementId?engagementId=' + engagementId, httpOptions);
  }
  
  AddEngagementAudit(engagementAudit: EngagementAudit): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
    return this.http.post<any>(this._governanceAuditApiBaseURl + '/GovernanceAudits/AddEngagementAudit', engagementAudit, httpOptions);
  }
  public getEngagementAuditById(engagementAuditId:number): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.get<any>(this._governanceAuditApiBaseURl +  '/GovernanceAudits/GetEngagementAuditById?engagementAuditId=' + engagementAuditId, httpOptions);

  }
  UpdateEngagementAudit(engagementAudit: EngagementAudit): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.put<any>(this._governanceAuditApiBaseURl + '/GovernanceAudits/UpdateEngagementAudit', engagementAudit, httpOptions);
  }
  DeleteEngagementAudit(engagementAuditId: any): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'ApiKey': this.authCommonService.getAuthenticationToken() }) };
    return this.http.put<any>(`${this._governanceAuditApiBaseURl}/GovernanceAudits/DeleteEngagementAuditById?engagementAuditId=${engagementAuditId}`, {}, httpOptions);
  }
}
