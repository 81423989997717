import { } from 'rxjs/operators';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { AssociateService } from '../Services/associate.service';
import { AssociateStatusCodes, AuditIdentifier, AuditLogEntities, AuditLogModule, AuditOperations, breadcrumnsCodes, Dateformat } from 'src/app/shared/constants/global.constants';
import { LoaderService } from 'src/app/Loader/loader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { Privileges } from 'src/app/shared/constants/privileges.constants';
import { ContractService } from 'src/app/Contracts/Services/contract.service';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';
import { ExcelService } from 'src/app/Reusable/excel.service';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import { EmbeddingContext } from 'amazon-quicksight-embedding-sdk/dist';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { DashboardService } from 'src/app/Dashboard/Services/dashboard.service';
import { environment } from 'src/environments/environment';
import { AuthCommonService } from 'src/app/shared/Authentication/auth.service';
import { Associate, PersonnelTurnoverEmailPayload, } from '../Models/Associate';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AuditLogService } from 'src/app/shared/ModelComparison/audit.log.service';
import { NotificationService } from 'src/app/shared/Notifications/notification.service';
import { TabAndStateService } from 'src/app/shared/TabAndStateService/TabAndState.Service';
@Component({
  selector: 'app-associate-list',
  templateUrl: './associate-list.component.html',
  styleUrls: ['./associate-list.component.css']
})
export class AssociateListComponent
{
  @ViewChild('AssociateTable') AssociateTable!: Table;
  @Input({ required: true }) FromType: number = 0;
  @Input() entityId:number | undefined;
  //@Input() ContractId:any;
  @Input() contractStartDate:any;
  @Input() engagementStartDate:any;
  @Input() EngagementId:any
  @Input() EngagementName:any
  @Input() ContractName:any
  @Input() SupplierId: any;
  @Input() SponsorId: any;
  @Output() addTeamButtonClick = new EventEmitter<void>();
  @Output() editTeamButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() viewTeamButtonClick: EventEmitter<any> = new EventEmitter<any>();
  
  filterDetailsModel = new AssociateFilterDetails();
  showHideFiltersControls: boolean = false;
  showAssociateStatus: boolean = false;
  selectAllChecked: boolean = false;
  selectPageRecords: boolean = false;
  selectedRecordsToExpotToExcel : any[] = [];
  isChecked:boolean=true;
  checked: boolean=false;
  checkedAssociates:any[]=[];
  associateList: any = [];
  associateListcopy: any = [];
  roledata: any[] = [];
  statusdata: any[] = [];
  locationdata: any[] = [];
  allocationdata: any[] = [];
  currency:any;
  contractdata: any[] = [];
  _dateformart :any;
  offboardedCount:number =0;
  onboardedCount:number =0;
  createPrivilege:boolean=false;
  editPrivilege:boolean=false;
  viewPrivilege:boolean=false;
  exportPrivilege:boolean=false;
  viewContactPrivilege:boolean=false;
  DashboarPrivilage:boolean=false;
   statusCount: { [status: string]: number } = {};
   _associateCodes=AssociateStatusCodes;
   selectedDate:any;
   startDate: any;
   endDate: any;
   maxDate: any
   minDate: any
   mailPopup:boolean=false;
   popupHeader:any;
   EmailTurnoverReport:boolean=false;
   emailBodyData=new PersonnelTurnoverEmailPayload();
   _contractId:any;
   _mailSubject:any;
   _alertMessage:any;
constructor(private privilegeService: PrivilegeService,
  private _associateService:AssociateService,
  private _loaderService: LoaderService,
   private _router:Router,private _datePipe: DatePipe, private route: ActivatedRoute,
   private contractService: ContractService,private _masterDataService:SponserService,
   private excelService: ExcelService,
  private _dashboaredService: DashboardService,private authService: AuthCommonService,
  private messageService: MessageService,
  private confirmationService: ConfirmationService,private auditLogService: AuditLogService,
  private notificationService:NotificationService,
  private tabAndStateService: TabAndStateService
) {
  

}
uesrId:any;
  ngOnInit(): void
  {

    let userData=this.privilegeService.getUserData();
    this.uesrId=userData.id;
        
    this.auditIdentifierDetails=AuditIdentifier;
    this.auditEntities=AuditLogEntities;
    this.auditOperations=AuditOperations;
    this.auditModule=AuditLogModule;
    this._dateformart=Dateformat;
    this._associateCodes=AssociateStatusCodes;
    this.GetCurrencyData();
    this.currency= this.route.snapshot.queryParamMap.get('currency');
    this.GetTeamAssociatesList(this.entityId,this.FromType);
    this.BindPrivileges();
    this._enumBreadcrumb=breadcrumnsCodes;
    if(this.FromType==2){
      
      this.minDate=new Date(this.engagementStartDate);
      this._contractId=0;
       this._mailSubject="Engagement Personnel Turnover";
       this._alertMessage="Engagement";
    }
    else
    {
      this.minDate=new Date(this.contractStartDate);
      this._contractId=this.entityId;
      this._mailSubject="Contract Personnel Turnover";
      this._alertMessage="Contract";
      this.GetContractAssociateDetailsById(this._contractId);
    }
    this.maxDate = new Date();
  }
  BindPrivileges(){
   // if FromType==1 Contracts else Engagement
const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);
const associatePrivilege = this.FromType === 1 ? Privileges.ContractAddAssociate : Privileges.SupplierAddAssociate;
this.createPrivilege = hasPermission(associatePrivilege);

const editPrivilege = this.FromType === 1 ? Privileges.ContractEditAssociate : Privileges.SupplierEditAssociate;
this.editPrivilege = hasPermission(editPrivilege);

const exportPrivilege = this.FromType === 1 ? Privileges.ContractExportlistofAssociates : Privileges.SupplierExportlistofAssociates;
this.exportPrivilege = hasPermission(exportPrivilege);
this.viewContactPrivilege = hasPermission(Privileges.ViewContract);

const EmailTurnoverReport = this.FromType === 1 ? Privileges.EmailTurnoverReport : Privileges.EngagementEmailTurnoverReport;
this.EmailTurnoverReport=hasPermission(EmailTurnoverReport);
this.DashboarPrivilage= hasPermission(Privileges.ViewEngagementTeamsDashboard);

const viewPrivilege = this.FromType === 1 ? Privileges.ViewContractTeams : Privileges.ViewEngagementTeams;
this.viewPrivilege = hasPermission(viewPrivilege);
 
}
  _associatesListData:any;
  GetTeamAssociatesList(conractIdOrEngagementId:any,teamType:number){
    this._loaderService.showLoader();
      this._associateService.GetTeamAssociatesListByEngagementId(conractIdOrEngagementId,teamType).subscribe((res:any)=>{
      if(res.status){
      this.associateList=res.data;
      this._associatesListData=res.data;
     this.associateListcopy=res.data;
     this.tabAndStateService.setTeamsData(this.associateList);
     this.offboardedCount=this.associateListcopy.filter((c:any)=>c.statusCode==this._associateCodes.Offboarded).length;
     this.onboardedCount=this.associateListcopy.filter((c:any)=>c.statusCode==this._associateCodes.Onboard).length;
     this.roledata = this.extractUniqueValues(this.associateList, 'role').map(obj => ({ label: obj }));
     this.allocationdata = this.extractUniqueValues(this.associateList, 'allocation').map(obj => ({ label: obj }));
     this.statusdata = this.extractUniqueValues(this.associateList, 'status').map(obj => ({ label: obj }));
     this.locationdata = this.extractUniqueValues(this.associateList, 'location');
     this.contractdata = this.extractUniqueValues(this.associateList, 'contractName');
        
      }
      this._loaderService.hideLoader();
    });
  }
  
  currenciesData:any;
  GetCurrencyData(){
    this._masterDataService.getCodeListData(4,'').subscribe(
      data=>{
        this.currenciesData=data.data;
        
      }
    );
  }

  ContractDetails:any;
  GetContractDetailsById(id: any) {
    this.contractService.GetContractDtailsById(id).subscribe(res => {
      if (res.data != null) {
        this.ContractDetails = res.data;
        const selectedCurrency = this.currenciesData.find((currency: { id: any; }) => currency.id === res.data.currencyId);
        if (selectedCurrency) {
        this.currency = selectedCurrency.name;
          } 
      }
    });
  }

  showfilter()
  {
    this.showHideFiltersControls = !this.showHideFiltersControls;
  }
  extractUniqueValues(array: any, key: any)
  {
    return Array.from(new Set(array.map((item: any) => item[key])));
  }
  onfilter()
  {
    this.associateList=this.associateListcopy;
    if (this.filterDetailsModel.Key) {
      const filterKey = this.filterDetailsModel.Key.toLowerCase();
      this.associateList = this.associateList.filter((a: any) =>
        a.associateName.toLowerCase().includes(filterKey)
      );
    }
    if(this.filterDetailsModel.Status && this.filterDetailsModel.Status.label){
      this.associateList = this.associateList.filter((c:any) => c.status === this.filterDetailsModel.Status.label);

    }
    if(this.filterDetailsModel.Role &&this.filterDetailsModel.Role.label){
      this.associateList = this.associateList.filter((c:any) => c.role === this.filterDetailsModel.Role.label);

    }
    if(this.filterDetailsModel.Allocation &&this.filterDetailsModel.Allocation.label){
      this.associateList = this.associateList.filter((c:any) => c.allocation === this.filterDetailsModel.Allocation.label);

    }
    if(this.filterDetailsModel.Location &&  this.filterDetailsModel.Location.length > 0) {
      this.associateList = this.associateList.filter((c:any) => this.filterDetailsModel.Location.includes(c.location));

    }
    if(this.filterDetailsModel.Contract &&  this.filterDetailsModel.Contract.length > 0) {
      this.associateList = this.associateList.filter((c:any) => this.filterDetailsModel.Contract.includes(c.contractName));

    }
    this.tabAndStateService.setTeamsData(this.associateList);
  }
  clearFilters(){
   this.filterDetailsModel={
    Key: '',
  Role: '',
  Status: '',
  Allocation: '',
  Location: [],
  Contract: []
   }
   this.checkedAssociates=[];
  this.associateList=this.associateListcopy;
  this.isChecked=true;
  }
  
  CheckboxEnabled(){
    if(this.checkedAssociates.length>0){
      this.isChecked=false;
    }
    else{
      this.isChecked=true;
    }
  }
  exportexcelAssociates(){
    
    if(this.checkedAssociates.length>0){
      this._loaderService.showLoader();
    const AssociateIds = this.checkedAssociates.map(detail => detail.id).join(',');

    this._associateService.GetExportTeamAssociatesList(this.entityId,this.FromType,AssociateIds).subscribe((res:any)=>{
      if(res.status){
        this.selectedRecordsToExpotToExcel= res.data;
        const dataToExport = this.selectedRecordsToExpotToExcel.map((associate) => {
          const convertDateFormat = (date:any) => date != null ? this.convertDateFormat(date) : date;
          const exportdata:any={
        
              'Associate Name': associate.associateName,
              'Contract':associate.contract,
              'Allocation': associate.allocation,
              'Location': associate.location,
              'Email': associate.email,
              'Country Code & Phone Number': `${associate.countryCode != null ? associate.countryCode + '-' : ''}${associate.phoneNumber ?? ''}`,
              'Address': associate.address,
              'Status': associate.status,
              'Onboarding Date':convertDateFormat(associate.onboardingDate),
              'Offboarding Date': convertDateFormat(associate.offboardingDate),
              'Offboarding Reason':associate.offboardingReason,
              'Role': associate.role,
              'Functional Head': associate.functionalHead,
              'Sponsor Username': associate.sponsorUsername,
              'Supplier Username': associate.engagementUsername,
              'Sponsor Asset': associate.sponsorsAsset,
              'Supplier Asset': associate.supplierAsset,
              'Sponsor Asset Details':associate.sponsorAssetDetails,
              'Notes': associate.notes,
              'Added By': this.privilegeService.getUserName(associate.createdBy),
              'Added Date & Time': associate.createdDate != null ? this.excelService.convertDateTimeFormat(associate.createdDate) : associate.createdDate,
              'Modified By':this.privilegeService.getUserName(associate.updatedBy),
              'Modified Date & Time' : associate.updatedBy != null ? (associate.updatedDate != null ? this.excelService.convertDateTimeFormat(associate.updatedDate) : '') : '',
          };
         
          return exportdata;
      });
      
        this.exportExcel(dataToExport);
        this._loaderService.hideLoader();
      }
    
    });

  }
  }
  convertDateFormat(inputDateTime: string) {
    const date = new Date(inputDateTime);
    const formattedDate = this._datePipe.transform(date, this._dateformart.GridDateFormat);
    return formattedDate;
  }
  exportExcel(SelectedRecords:any) {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(SelectedRecords);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array"
      });
      if(this.FromType==1){
        const fileName = `${this.ContractAssociateDetails.title} - AssociatesExport  - ${this.convertDateFormat(Date())}.xlsx`;
        this.saveAsExcelFile(excelBuffer, fileName);
      }
      else{
        const fileName = `${this.EngagementName} - AssociatesExport  - ${this.convertDateFormat(Date())}.xlsx`;
        this.saveAsExcelFile(excelBuffer, fileName);

      }
    });
  }
  saveAsExcelFile(data: ArrayBuffer, fileName: string): void {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${fileName}`;
    link.click();
    window.URL.revokeObjectURL(url);
    }

  
  //creat associate 
  CreateAssociate()
  {
    this.showAssociateStatus = true;
    this.addTeamButtonClick.emit();
  }
viewAssociate(Id:any){
  const AssociateObj = {
    associateId:parseInt(Id)
  };
  this.viewTeamButtonClick.emit(AssociateObj);   
}
  EditAssociate(id:any){
    const data = {
      associateId:id  //item.id
    };
    this.editTeamButtonClick.emit(data);
   }  
   _enumBreadcrumb :any;
  viewContractAssociate(contractId:any){
    this.GetContractDetailsById(contractId);
    setTimeout(() => {
      if(this.entityId==0){
      this._router.navigate(['/viewcontract'], { queryParams: {ContractId: contractId , TabId:5,currency: this.currency}});
      }
      else{
        const breadcrumb = this._enumBreadcrumb.EVSUP
        this.tabAndStateService.setPreviousState('Team', 5);
        this._router.navigate(['/viewcontract'], { queryParams: { ContractId: contractId, supplierId: this.entityId, breadcrumb,  TabId:5,currency: this.currency } });
      }
    }, 1000);
    
   
  }


  
  //dashboard code start from here
  showheading=false;
  showTeamDashboard(){
    if(this.FromType==2){
      this.showheading=true;
    this.showDashboard();
    }
  }
  
  reportingServiceURL="";

  showDashboard()
  {
    this._loaderService.showLoader();
  
    this.GetReportingServicesEmbedURL();
  }

engagementTeamDashboardId:any;
contractGroupdashboardId:any;
  public async GetReportingServicesEmbedURL() {
    this.engagementTeamDashboardId=environment?._engagementTeamDashboardId;
    let model = {
      "dashboardID": this.engagementTeamDashboardId,
      "token": this.authService.getCookieValue('LOGIN_TOKEN'),
      "privilege": [
        {
          "MenuName": "string",
          "isAllDataAvailable": true,
          "DataValues": []
        }
      ]
    };
  
    // Create an observable for the firstEmdbedURL
  this._dashboaredService.getEmbedUrlData(model).subscribe(
    async (res: any) => {

        this.reportingServiceURL=res.data;

        await this.Dashboard();
  });
  
    // Combine the observables using forkJoin
   
  }

  public async Dashboard() {
    let containerDiv = document.getElementById("dashboardContainer") || '';
    if (containerDiv && containerDiv instanceof HTMLElement) {
      containerDiv.innerHTML = ''; // Clear the container
  }
    const embeddingContext: EmbeddingContext = await createEmbeddingContext();
  
    const frameOptions = {
      url: this.reportingServiceURL + '&#p.EngagementId=' + this.entityId,
      container: containerDiv,
      height: "400px",
      width: "700px",
      resizeHeightOnSizeChangedEvent: true,
      onChange: (changeEvent:any, metadata:any) => {
        switch (changeEvent.eventName) {
          
            case 'FRAME_LOADED': {
            
                 this._loaderService.hideLoader();
              
                break;
            }
        }
    },
  }
    
   embeddingContext.embedDashboard(frameOptions);

  }

  
  //dashboard code end here
  emailReport() {
   if( this.associateList.length>0)
    {
    this.popupHeader='Send Personnel Turnover Report' ;  
    this.mailPopup = true;
     this.selectedDateRange=null;
     this.emailBodyData.StartDate="";
     this.emailBodyData.EndDate="";
     this.emailBodyData.EngagementName="";
    this.emailBodyData.PersonnelTurnover=0;
    this.emailBodyData.OnboardAssociates=0;
    this.emailBodyData.OffboardedAssociates=0;
    this.emailBodyData.TotalCountOnEndDate=0;
   this.emailBodyData.createdBy= this.uesrId;
     this.oldEmailData = JSON.stringify(this.emailBodyData);
    }
    else
    {
      this.messageService.add({
        key: 'tc',
        severity: 'info',
        summary: 'This will send the Personnel Turnover Report to your registered email ID. Are you sure you want to trigger this notification?',
        detail: '',
    });
    }
  }
  closeMailDialog(){
    this.mailPopup = false;
    this.messageService.add({
      key: 'tc',
      severity: 'info',
      summary:'Notification not triggered',
      detail: '',
  });
}
invoicedAmount:any=0;
sendNotification()
{
  this.confirmationService.confirm({
    message: 'This will send the Personnel Turnover Report to your registered email ID. Are you sure you want to trigger this notification?',
    header: 'Confirmation',
    icon: 'pi pi-exclamation-triangle',
    accept: () => {
      this.mailPopup = false;
      this.sendInvoiceReportEmail();
      this.messageService.add({
        key: 'tc',
        severity: 'success',
        summary: 'Email Notification has been triggered',
        detail: '',
    });
    },
    reject: () => {
      this.mailPopup = false;
      this.messageService.add({
        key: 'tc',
        severity: 'info',
        summary: 'Notification not triggered',
        detail: '',
    });
    },
  });
}
selectedDateRange: any;
isSendEmailbtnEabled:boolean=true;
offBoardedNumber:any;
personnelTurnover:any;
_filterAssociatesListData:any;
_totalCountOnStartDate:any;
_newJoiner:any;
_attrition:any;
_totalCountOnEndDate:any;
_listOffboarded:any=[];
onEmailSelectDate() {
    this.startDate = this.selectedDateRange[0];
    this.endDate = this.selectedDateRange[1];
  if (this.startDate != null && this.endDate != null) {
    if (!isNaN(this.startDate) && !isNaN(this.endDate)) {
      this.isSendEmailbtnEabled=false;
      let OnboardData =  this._associatesListData.filter((a: Associate ) =>  {
        const onBoardingDate = new Date(a.onBoardingDate);
        return onBoardingDate <= this.startDate && a.statusCode==this._associateCodes.Onboard;
      });
      let offboardedData =  this._associatesListData.filter((a: Associate ) =>  {
        const onBoardingDate = new Date(a.onBoardingDate);
        const offBoardingDate = new Date(a.offBoardingDate);
        return (onBoardingDate <= this.startDate && offBoardingDate >= this.startDate) && a.statusCode==this._associateCodes.Offboarded;
      });
      this._totalCountOnStartDate=OnboardData.length+offboardedData.length;
      let newJoinerData =  this._associatesListData.filter((a: Associate ) => {
        const onBoardingDate = new Date(a.onBoardingDate);
        return onBoardingDate >= this.startDate && onBoardingDate <= this.endDate;
      });
      this._newJoiner=newJoinerData.length;
      let attritionData=this._associatesListData.filter((a: Associate ) => {
        const offBoardingDate = new Date(a.offBoardingDate);
        return (offBoardingDate >= this.startDate && offBoardingDate <= this.endDate) && a.statusCode==this._associateCodes.Offboarded;;
      });
      this._listOffboarded=attritionData;
      this._attrition=attritionData.length;
     
      this._totalCountOnEndDate= (this._totalCountOnStartDate+this._newJoiner)-(this._attrition);
      if(this._attrition!=0 && this._totalCountOnEndDate!=0)
      {
      this.personnelTurnover=(this._attrition/this._totalCountOnEndDate)*100;
      this.personnelTurnover = parseFloat(this.personnelTurnover.toFixed(2));
      }
      else
      {
        this.personnelTurnover=0;
      }
      
    }
  }
}
oldEmailData:any;
newEmailData:any;
async sendInvoiceReportEmail()
{
  this.emailBodyData.StartDate=this._datePipe.transform(this.startDate, 'dd-MMM-yy') as string;
  this.emailBodyData.EndDate=this._datePipe.transform(this.endDate, 'dd-MMM-yy') as string;
  this.emailBodyData.EngagementName=this.EngagementName;
  this.emailBodyData.OffboardedAssociates=this._attrition 
  this.emailBodyData.TotalCountOnEndDate=this._totalCountOnEndDate;
  this.emailBodyData.PersonnelTurnover=this.personnelTurnover;
  this.emailBodyData.createdBy= this.uesrId;
  await this.notificationService.PersonnelTurnoverNotification(this._contractId,this.emailBodyData,this.SupplierId,this.SponsorId,this.EngagementId,this._mailSubject,this._listOffboarded);
  this.newEmailData =this.emailBodyData;
  this.auditLogForEmail();
}
auditIdentifierDetails:any;
auditEntities:any;
auditOperations:any;
auditModule:any;
auditLogForEmail() {
  if(this._contractId!=0)
  {
  this.auditLogService.compareModels(JSON.parse(this.oldEmailData),this.newEmailData,this.auditEntities.ContractTurnoverEmail,this.auditIdentifierDetails.ContractTurnoverEmail,this.auditOperations.Create,this.auditModule.GovernanceService);
  }
  else
  {
    this.auditLogService.compareModels(JSON.parse(this.oldEmailData),this.newEmailData,this.auditEntities.EngagementTurnoverEmail,this.auditIdentifierDetails.EngagementTurnoverEmail,this.auditOperations.Create,this.auditModule.GovernanceService);
  }
}
ContractAssociateDetails:any;
GetContractAssociateDetailsById(id: any) {
  this.contractService.GetContractDtailsById(id).subscribe(res => {
    if (res.data != null) {
      this.ContractAssociateDetails = res.data;
    }
  })
}
}
export class AssociateFilterDetails
{
  Key?: string;
  Role?: any;
  Status?: any;
  Allocation?: any;
  Location?: any;
  Contract?: any;

}

