<div class="card flex justify-content-center">
    <p-breadcrumb class="max-w-full" [model]="getBreadcrumbs()"></p-breadcrumb>
</div>
<div class="container page-title">
    <div class="flex-box"> 
        <div class="fr-flex__container">
            <div class="fr-flex__col-8">
                <h1>Finance Dashboards</h1>                
            </div>
        </div>
    </div>
    <p-tabView class="fr-tabview__header__custom"> 
        <p-tabPanel>
<form [formGroup]="dashboardForm"  *ngIf="IsShowButton">
    <div class="fr-flex__col-12">
<div class="fr-flex__container m-t-10">
    <div class="fr-flex__col-3">
        <div class="fr-form__group">
            <label>Engagement <em> * </em></label>
            <p-dropdown appendTo="body" [filter]="true" [options]="engagements" placeholder="Select" optionLabel="label" optionValue="value"
                (onChange)="onFinDashboardEngChange($event)" formControlName="dashboardEngagement"  ></p-dropdown>
                <small class="p-error" *ngIf="dashboardApplyClicked && dashboardForm?.get('dashboardEngagement')?.hasError('required')">This field is required.</small>

        </div>
    </div>
    <div class="fr-flex__col-3">
        <div class="fr-form__group">
            <label>Function <em> * </em></label>
            <p-dropdown [disabled]="IsFunctionDisable" appendTo="body" [filter]="true" [options]="listOfFunctions" optionLabel="label" optionValue="value"
            formControlName="dashboardFunction" placeholder="Select"  (onChange)="onFinDashboardFunEngChange($event)"  [(ngModel)]="SelectedFunction"></p-dropdown>
            <small class="p-error" *ngIf="dashboardApplyClicked && dashboardForm?.get('dashboardFunction')?.hasError('required')">This field is required.</small>
        </div>
    </div>
    <div class="fr-flex__col-3">
        <div class="fr-form__group">
            <label>Finance Dashboard to View <em> * </em></label>
            <p-dropdown appendTo="body" [filter]="true" [options]="DashboardToViewDataSource" placeholder="Select" optionLabel="label" optionValue="value"
            formControlName="dashboardToVeiw"   (onChange)="onDashboardToViewChange($event)" [(ngModel)]="selectedDashboardToView"></p-dropdown>
            <small class="p-error" *ngIf="dashboardApplyClicked && dashboardForm?.get('dashboardToVeiw')?.hasError('required')">This field is required.</small>
        </div>
    </div>
    <div class="fr-flex__col-2">
        <div class="fr-form__group">
            <label>Fiscal Year <em> * </em></label>
            <p-dropdown appendTo="body" [filter]="true" [options]="fiscalYearList" optionLabel="label" optionValue="value" placeholder="Select"
            formControlName="dashboadFicalYear"  [disabled]="IsDashboardTillDate" [(ngModel)]="selectedFiscalYear"></p-dropdown>
            <small class="p-error" *ngIf="dashboardApplyClicked && dashboardForm?.get('dashboadFicalYear')?.hasError('required')">This field is required.</small>
        </div>
    </div>
    <div class="fr-flex__col-1 custom-pad-left-zero" *ngIf="IsShowButton">
        <div class="fr-form__group">
            <label>&nbsp;</label>
            <button [disabled]="selectedFiscalYear==''" class="btn btn-primary pull-left" (click)="onDashboardApplyClick()"> Apply</button>
            </div>
        </div>
    </div>
</div>
</form>
<div class="tab-container" *ngIf="viewFinanceDashboard">
    <div>
       <div id="DashboardContainer" class="m-t-20 m-b-20"></div>
    </div>
</div>


</p-tabPanel>
</p-tabView>
<div class="margin-center"> 
    <span *ngIf="!IsShowButton"> You does not have privilage to geneare and show Darshboard </span>
</div>
</div>

