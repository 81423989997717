<!DOCTYPE html>
<html lang="en">
<head>
   <title> View Engagement </title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>
<div class="card flex justify-content-center">
   <p-breadcrumb class="max-w-full" [model]="getBreadcrumbs()"></p-breadcrumb>
</div>
<p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
  <div class="container page-title" #scrollContainer>
   <div class="flex-box-inside-pages position-fixed-heading"> 
   <div class="fr-flex__container">
      <div class="fr-flex__col-12">
         <h1>  {{engagementdata?.engagementName}}-{{engagementdata?.engagementId}} </h1>
      </div>
   </div>
</div>
    <!-- Tab View Module Start -->
      <p-tabView class="fr-tabview__header fixed-positon-tabs" #myTabView [(activeIndex)]="tabindex" (onChange)="onTabSelect($event)" [activeIndex]="activeTabIndex">
        <!-- Profile Tab Start -->
        <p-tabPanel header="Profile">
         <div class="card-pad">  
            <div class="fr-flex__container sticky-header"> 
               <div class="fr-flex__col-4">
                  <h3>View Engagement </h3>
               </div>
            <div class="fr-flex__col-8" *ngIf="tabindex==0">
               <select class="form-control selectpicker pull-right" [(ngModel)]="selectedOption" (change)="onOptionChange()">
                  <option disabled selected> Actions </option>
                  <option *ngIf="editPrivilege"> Edit </option>
               </select>
               <div class="preview_section pull-right">
                  </div>
               <a class="btn btn-primary-link backtolist pull-right" (click)="BackToList()">  <i class="pi pi-arrow-left"></i> Back to list </a>
            </div>
            
         </div>
         <div class="view-eng-scroll-start-here"> 

         <div class="fr-flex__container">
            <div class="fr-flex__col-4">
               <div class="fr-form__view">
                  <label> Sponsor <em> * </em></label>
                  <span [pTooltip]="engagementdata?.sponsor?.length > 41 ? engagementdata?.sponsor : ''" tooltipPosition="top"> <em> {{engagementdata.sponsor}} </em></span>
               </div>
            </div>
            <div class="fr-flex__col-4">
               <div class="fr-form__view">
                  <label> Supplier <em> * </em></label>
                  <span [pTooltip]="engagementdata?.supplier?.length > 41 ? engagementdata?.supplier : ''" tooltipPosition="top"> <em> {{engagementdata.supplier}} </em></span>
               </div>
            </div>
            <div class="fr-flex__col-4">
               <div class="fr-form__view">
                  <label> Engagement Name <em> * </em></label>
                  <span [pTooltip]="engagementdata?.engagementName?.length > 41 ? engagementdata?.engagementName : ''" tooltipPosition="top"> <em> {{engagementdata.engagementName}} </em></span>
               </div>
            </div>
         </div>

         <div class="fr-flex__container">
            <div class="fr-flex__col-4">
               <div class="fr-form__view">
                  <label> Engagement ID <em> * </em></label>
                  <span > <em> {{engagementdata.engagementId}} </em></span>
               </div>
            </div>

            <div class="fr-flex__col-4">
               <div class="fr-form__view">
                  <label> Engagement Start Date <em> * </em></label>
                     <span> <em> {{engagementdata.engagementStartDate | date : _dateformart.GridDateFormat}} </em></span>
               </div>
            </div>

            <div class="fr-flex__col-4">
               <div class="fr-form__view">
                  <label> Status <em> * </em></label>
                  <span > <em> {{engagementdata.status}} </em></span>
               </div>
            </div>
            
         </div>

         <div class="fr-flex__container">
           
            <div class="fr-flex__col-4">
               <div class="fr-form__view">
                  <label> Services<em> * </em></label>
                  <ul class="services_ul" *ngFor="let singleService of services; let i = index">
                     <li> {{singleService}} </li>
                  </ul>
                  <span><em> <p-button (onClick)="showDialog()" [hidden]="ShowServicesButton"
                           [ngClass]="'blue-button'" [label]="buttonLabel"></p-button> </em></span>
                           <p-dialog appendTo="body" header="Services" [modal]="true" [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false" [closable]="true"  [(visible)]="visible">
                              <div class="dialog-ul" *ngFor="let service of servicesToArray; let i = index">
                                 <span *ngIf="i > 1">                                       
                                    <em [pTooltip]="service.length > 30 ? service : ''" tooltipPosition="top"> {{service}}</em>                    
                                 </span>
                              </div>
                              </p-dialog>
                  
               </div>
            </div>

            <div class="fr-flex__col-4">
               <div class="fr-form__view">
                  <label id="defaultCurrencyLabel">Default Currency<em> * </em></label>
                  <span  id="defaultCurrencyValue"> <em> {{engagementdata.defaultCurrency}} </em></span>
               </div>
            </div>
         </div>

         

         <div class="fr-flex__container">
            <div class="fr-flex__col-12">
               <div class="fr-form__view">
                  <label id="notesLabel"> Notes and Comments </label>
                  <span id="noNotes" class="full-width" *ngIf="notes  == null || notes == ''">--</span> <span id="notesValue" class="full-width" [innerHTML]="notes" *ngIf="notes  != null"> {{notes }} </span>
               </div>
            </div>
         </div>
         <div class="fr-flex__container">
            <div class="fr-flex__col-3">
               <div class="fr-form__view">
                  <label id="createdByLabel"> Created By </label>
                  <span id="createdByValue"> <em> {{createdBy? createdBy :"--"}} </em> </span>
               </div>
            </div>
            <div class="fr-flex__col-3">
               <div class="fr-form__view">
                  <label id="createdDateLabel"> Created Date & Time</label>
                  <span id="createdDateValue"> <em> {{engagementdata.createdDate | date: _dateformart.DateTimeWithZone}} {{_dateformart.DisplayUTC}}</em> </span>
               </div>
            </div>
            <div class="fr-flex__col-3">
               <div class="fr-form__view">
                  <label> Modified By</label>
                  <span> <em> {{updatedBy? updatedBy:"--"}} </em> </span>
               </div>
            </div>
            <div class="fr-flex__col-3">
               <div class="fr-form__view">
                  <label> Modified Date & Time</label>
                  <span *ngIf="engagementdata?.updatedBy!=null"> <em> {{ engagementdata.updatedDate| date: _dateformart.DateTimeWithZone}} {{_dateformart.DisplayUTC}} </em> </span>
                  <span *ngIf="engagementdata?.updatedBy==null"> <em> -- </em></span>
               </div>
            </div>
         </div>


         <div class="fr-flex__container">
            <div class="fr-flex__col-8">
               <div class="sub-page-title">
                  <h3>Stakeholders (Sponsor)</h3>
               </div>
            </div>
         </div>
     
      </div>
         <!-- grid code -->

         <div class="">
            <div class="fr-flex__col-12">
                  <p-table class="primeng-table-custom engViewStakeholders_contact" styleClass="p-datatable-striped" [value]="stakeholdersSponsors" [scrollable]="true" >
                  <ng-template pTemplate="header">
                        <tr>
                            
                            <th id="engViewStakeholdersSponsorName">Name</th>
                            <th id="engViewStakeholdersSponsorDesignation">Designation</th>
                            <th id="engViewStakeholdersSponsorEmail">Email</th>
                            <th id="engViewStakeholdersSponsorCountryCode">Country Code</th>
                            <th id="engViewStakeholdersSponsorPhoneNumber">Phone Number</th>
                            <th id="engViewStakeholdersSponsorsendNotifications">Send Notifications</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-contact>
                        <tr>
                         
                            <td> {{ contact.name? contact.name : '--' }} </td>
                            <td [pTooltip]="contact.designation.length > 15 ? contact.designation : ''" tooltipPosition="top"> {{ contact.designation? contact.designation : '--'  }} </td>
                            <td [pTooltip]="contact.email.length > 15 ? contact.email : ''" tooltipPosition="top"> {{ contact.email? contact.email : '--' }} </td>
                            <td [pTooltip]="contact.countryCode.length > 20 ? contact.countryCode : ''" tooltipPosition="top"> {{ contact.countryCode? contact.countryCode : "--" }} </td>
                            <td> {{ contact.phone? contact.phone : '--' }} </td>
                           <td> {{ contact.sendNotificationOptions? contact.sendNotificationOptions : '--' }} </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                     <tr>
                     <td colspan="6" class="text-center"> No Records</td>
                     </tr>
                     </ng-template>
                </p-table>
            </div>
        </div>


        <div class="fr-flex__container m-t-20">
         <div class="fr-flex__col-8">
            <div class="sub-page-title">
               <h3>Stakeholders (Supplier)</h3>
            </div>
         </div>
      </div>


      <!-- grid code -->

      <div class="">
         <div class="fr-flex__col-12">
               <p-table class="primeng-table-custom engViewStakeholders_contact" styleClass="p-datatable-striped" [value]="stakeholdersSuppliers" [scrollable]="true" >
               <ng-template pTemplate="header">
                     <tr>
                         
                         <th id="engViewStakeholdersSupplierName">Name</th>
                         <th id="engViewStakeholdersSupplierDesignation">Designation</th>
                         <th id="engViewStakeholdersSupplierEmail">Email</th>
                         <th id="engViewStakeholdersSupplierCountryCode">Country Code</th>
                         <th id="engViewStakeholdersSupplierPhoneNumber">Phone Number</th>
                         <th id="engViewStakeholdersSupplierSendNotifications">Send Notifications</th>
                     </tr>
                 </ng-template>
                 <ng-template pTemplate="body" let-contact>
                     <tr>
                      
                        <td> {{ contact.name? contact.name : '--'  }} </td>
                        <td [pTooltip]="contact.designation.length > 15 ? contact.designation : ''" tooltipPosition="top"> {{ contact.designation? contact.designation : '--' }} </td>
                        <td [pTooltip]="contact.email.length > 15 ? contact.email : ''" tooltipPosition="top"> {{ contact.email? contact.email : '--' }} </td>
                        <td [pTooltip]="contact.countryCode.length > 10 ? contact.countryCode : ''" tooltipPosition="top"> {{ contact.countryCode? contact.countryCode: '--' }} </td>
                        <td> {{ contact.phone? contact.phone : '--' }} </td>
                         <td> {{ contact.sendNotificationOptions? contact.sendNotificationOptions : '--' }} </td>
                         
                     </tr>
                 </ng-template>
                 <ng-template pTemplate="emptymessage">
                  <tr>
                  <td colspan="6" class="text-center"> No Records</td>
                  </tr>
                  </ng-template>
             </p-table>
         </div>
     </div>

     <div class="fr-flex__container m-t-20">
      <div class="fr-flex__col-8">
         <div class="sub-page-title">
            <h1>MSAs</h1>
         </div>
      </div>
   </div>
    <div class="fr-flex__container">
      <div class="fr-flex__col-12">
         <div class="card-norecord">
            <div class="card flex justify-content-center" *ngIf="engagementMasterServiceAgreements.length==0">
               <p-card header="">
                  <img src="../../../../assets/images/empty-box.svg" alt="" width="100">
                  <p class="m-0">
                     No Records
                  </p>
               </p-card>
            </div>
         </div>
      </div>
   </div> 
    <div class="fr-flex__container">
      <div class="fr-flex__col-4"
         *ngFor="let objMasterServiceAgreement of engagementMasterServiceAgreements; let i = index">
         <div class="fr-card__custom">
            <p-card>
               <ng-template pTemplate="content">
                  <div class="fr-card__view"> 
                     <span class="valueSelector"> <a class="cursor-pointer" (click)="showMSAViewpopup(i)"
                           [pTooltip]="objMasterServiceAgreement.msaTitle!.length > 40 ? objMasterServiceAgreement.msaTitle! : ''"
                           tooltipPosition="top"> {{objMasterServiceAgreement.msaTitle}} </a></span>
                     <span class="valueSelector success"> {{objMasterServiceAgreement.status}} </span>
                  </div>

                  <div class="fr-card__view">
                     <span class="valueSelector"> {{objMasterServiceAgreement.startDate| date:
                        _dateformart.GridDateFormat }} - {{objMasterServiceAgreement.endDate | date:
                        _dateformart.GridDateFormat }} </span>
                     <span class="valueSelector"> <span class="right_align"
                           [pTooltip]="objMasterServiceAgreement.paymentTerms!.length > 15 ? objMasterServiceAgreement.paymentTerms! : ''"
                           tooltipPosition="top"> {{objMasterServiceAgreement.paymentTerms}} </span></span>
                  </div>
                  <div class="fr-card__view">
                     <span class="valueSelector-full-width"> 
                        <a class="form-list-active" *ngIf="objMasterServiceAgreement.fileName; else emptyFileName" routerLinkActive="active"
                           [pTooltip]="objMasterServiceAgreement.fileName!.length > 30 ? objMasterServiceAgreement.fileName! : ''"
                           tooltipPosition="top"
                           (click)="downloadAttachemnt(objMasterServiceAgreement.fileName,objMasterServiceAgreement.filePath)">
                           <span> {{ objMasterServiceAgreement.fileName | truncate:45 }} </span>
                           <i pInputText pTooltip="Download" tooltipPosition="top" class="pi pi-download"></i>
                        </a>
                           <ng-template #emptyFileName>
                              <span class="form-list-norecord">--</span>
                              </ng-template>
                        </span>

                  </div>
                  <div class="fr-card__view">
                     <span class="valueSelector"> Description <i pInputText
                           pTooltip={{objMasterServiceAgreement.description}} tooltipPosition="top"
                           class="pi pi-info-circle"> </i> </span>
                  </div>
               </ng-template>
            </p-card>
         </div>
      </div>
   </div> 
         </div>
        </p-tabPanel>



        <p-tabPanel header="Functions">
         <div class="card-pad"> 
         <div class="tab-container"> 
            
           <!-- -------------------start------------------------------ -->
            <div class="fr-flex__container" *ngIf="!displayContractGroupdashboard">
               <div class="sticky-header"></div>
               <div class="fr-flex__container m-t-30">
                  <div class="fr-flex__col-4">
                     <div class="fr-form__view function-m-b-10">
                         <label>Viewing Currency 
                           <span class="valueSelector"> <i pInputText
                               pTooltip="{{tooltipContent}}" tooltipPosition="top"
                               class="pi pi-info-circle"> </i> </span>
                       </label>  
                         <p-dropdown appendTo="body" [filter]="true" (onChange)="OnCurrencyChange($event)" [(ngModel)]="selectedCurrency"  [options]="currenciesData"  optionLabel="code" optionValue="code" ></p-dropdown>       
                     </div>
                     </div>
                     </div>  

                     
               <div class="fr-flex__col-4" *ngFor="let objEngagementContractsGroups of engagementContractsGroups; let i = index"> 
                  <div class="fr-card__boxshadow" id="engViewFunctionsCard" (click)="TileClickEvent(objEngagementContractsGroups);"> 
                  <span class="fr-card__title">
                     <div class="fr-symbol__content-primary--text"> <span [pTooltip]="objEngagementContractsGroups.engagementGroupName.length > 30 ? objEngagementContractsGroups.engagementGroupName : ''" tooltipPosition="top">  {{objEngagementContractsGroups.engagementGroupName}} </span></div>
                   </span>
                   <span class="fr-symbol1">
                     <span class="fr-symbol1__box"> 
                        <span class="for-symbol__box--icon">
                           <i class="icon-paper"></i>
                         </span>
                     </span>
                     <div class="fr-symbol__content">
                        <div id="engViewFunctionsActiveContracts"class="fr-symbol__content-primary--text">
                          {{objEngagementContractsGroups.totalActiveContracts}}
                        </div>
                        <div class="fr-sybmobol__content-secondary-text">
                           Total No of Contracts
                        </div>
                      </div>
                  </span>

                  <span class="fr-symbol2"> 
                     <span class="fr-symbol2__box"> 
                        <span class="for-symbol__box--icon">
                           <i class="icon-briefcase1"></i>
                         </span>
                     </span>
                  <div class="fr-symbol__content">
                     <div id="engViewFunctionsTotalContractedValue" class="fr-symbol__content-primary--text">
                         {{ getFormattedValue(objEngagementContractsGroups.totalContractedValue,  selectedCurrency) }} 
                     </div>
                     <div class="fr-sybmobol__content-secondary-text">
                        Total Contracted Value
                     </div>
                   </div>
                  </span>
                  <span class="fr-symbol3">
                     <span class="fr-symbol3__box"> 
                        <span class="for-symbol__box--icon">
                           <i class="icon-gps_not_fixed"></i>
                         </span>
                     </span>
                     <div class="fr-symbol__content">
                        <div id="engViewFunctionsOnboardAssociates" class="fr-symbol__content-primary--text">
                           {{objEngagementContractsGroups.totalOnboardAssociates}}
                        </div>
                        <div class="fr-sybmobol__content-secondary-text">
                           Onboard Associates
                        </div>
                      </div>
                  </span>
               </div>
               </div>
            </div>


              <div *ngIf="displayContractGroupdashboard">

                
                  <div class="fr-flex__container m-b-10">
                     <div class="sticky-header fr-flex__container m-t-20"></div>
                      <div class="fr-flex__col-8">
                          <h3 id="engViewFucntionsh3Title">{{selectedContractGroupData.engagementGroupName}}</h3>
                      </div>
                      <div class="fr-flex__col-4"> 
                        <a *ngIf="TeamsDashboardPrivilage" class="btn btn-primary-link pull-right" (click)="showDashboard()"> <i class="pi pi-eye"></i> View Teams Dashboard </a>
                        <a class="btn btn-primary-link backtolist pull-right" (click)="BackToFunctions()">  <i class="pi pi-arrow-left"></i> Back to Functions </a>
                     </div>
                  </div>
                  <div class ="fr-flex__container">
                     
                     <div class="display-flex"> 
                        <div class="cg__label" id="activeContractsValue"> <span class="legend_smaller_circle"></span> Total No of Contracts : </div>
                        <div class="cg__value" id="activeContractsLabel"> {{selectedContractGroupData.totalActiveContracts}} </div>
                     </div>
                     <div class="display-flex"> 
                        <div class="cg__label" id="onboardAssociatesLabel"> <span class="legend_smaller_circle"></span> Onboard Associates : </div>
                        <div class="cg__value" id="onboardAssociatesValue"> {{selectedContractGroupData.totalOnboardAssociates}} </div>
                     </div>
                        <div class="display-flex"> 
                           <div class="cg__label"> <span class="legend_smaller_circle"></span> Total Contracted Value : </div>

                           
                           <div class="cg__value"> {{ getFormattedValue(selectedContractGroupData.totalContractedValue, engagementdata.defaultCurrency) }} </div>
                        </div>
                        <div class="display-flex"> 
                           <div class="cg__label"> <span class="legend_smaller_circle"></span> Total Value/Year : </div>
                           <div class="cg__value"> {{ getFormattedValue(selectedContractGroupData.totalContractedValuePerYear, engagementdata.defaultCurrency) }} </div>
                        </div>
                  </div>



                     <div class="tab-container" *ngIf="TeamsDashboardPrivilage">
                        
                        <div class="fr-flex__col-8">
                           <div id="dashboardContainer" class="m-t-20 m-b-20"></div>

                        </div>
                     </div>
            
                 
                 <!-- Add Finance Dashboard here -->
<!-- Start Finnace dashboard -->
<div *ngIf="viewFinanceDashboard">
   <div class="fr-flex__container m-t-10">
       
       <div class="fr-flex__col-3">
           <div class="fr-form__group">
               <label>Finance Dashboard to View</label>
               <p-dropdown id="financeDashboardDropdown"  viewFinanceDashboard appendTo="body" [options]="DashboardToViewDataSource" placeholder="Select Dashboard to View" optionLabel="label" optionValue="value"
                   (onChange)="onDashboardToViewChange($event)" [(ngModel)]="selectedDashboardToView"></p-dropdown>
                   
   
           </div>
       </div>
       <div class="fr-flex__col-3">
           <div class="fr-form__group">
               <label>Fiscal Year</label>
               <p-dropdown id="fiscalYearDropdown" appendTo="body" [filter]="true" [options]="fiscalYearList" optionLabel="label" optionValue="value"
               [disabled]="IsDashboardTillDate" [(ngModel)]="selectedFiscalYear"  placeholder="Select Fiscal Year" (onChange)="onFiscalYearChange($event)"></p-dropdown>
               
           </div>
       </div>
       <div class="fr-flex__col-1 custom-pad-left-zero">
           <div class="fr-form__group">
               <label>&nbsp;</label>
               <button class="btn btn-primary pull-left" (click)="onDashboardApplyClick()" [disabled]="isApplyDisable"> Apply</button>
               </div>
           </div>
       </div>
   </div>



   <div class="tab-container" *ngIf="viewFinanceDashboard">
                    
      
         <div id="financeDashboardContainer" class="m-t-20 m-b-20"></div>
  
      
   </div>
                 
           <!-- End Finnace dashboard -->      
                 
                  <div class="tab-container">
                    
                     
                     <app-contractlist [fromSupplier]="true" [supplierId]="engagementId" [isFunctionTrue]="true" [selectedContractGroupId]="selectedContractGroupId"></app-contractlist>
                 
                  </div>
            

              </div>


              <!-- -------------------End------------------------------ -->

              
             
            </div>
         </div>
               <div class="card-pad" *ngIf="engagementContractsGroups?.length==0">
                <div class="fr-flex__container"> 
               <div  class="fr-flex__col-12">
                  <div class="card-norecord" id="noRecordsCard"> 
                     <div class="card flex justify-content-center m-b-20"> 
                  <p-card header="">
                     <img id="emptyBoxImage" src="../../../../assets/images/empty-box.svg" alt="" width="100">
                     <p class="m-0">
                        No Records
                     </p>
                  </p-card>
               </div>
               </div>
                </div>
               </div>
            </div>
         
      </p-tabPanel>



        <p-tabPanel header="Contracts">
         <div class="tab-container" >
             
            <app-contractlist [fromSupplier]="true" [supplierId]="engagementId"></app-contractlist>
         </div>
      
      </p-tabPanel>
      
      <p-tabPanel header="Governance">
         <p-tabView class="fr-tabview__subheader" #mySubTabView (onChange)="onSubTabSelect($event)" [activeIndex]="activeSubTabIndex" [(activeIndex)]="activeSubTabIndex">
            <p-tabPanel id="kpiTab" header="KPIs">
               <app-eng-kpi-list id="kpiListComponent" *ngIf="_kpiChildComponents.supKpiList==_currentKpiChildComponent"
                  [engagementId]="engagementId" (addKpiButtonClick)="handleAddButtonClick(1)"
                  (editKpiButtonClick)="handleEditButtonClick($event,1)"
                  (viewKpiButtonClick)="handleViewButtonClick($event,1)"> </app-eng-kpi-list>
               <app-eng-create-kpi  id="kpiCreateComponent" *ngIf="_kpiChildComponents.supKpiAdd==_currentKpiChildComponent"
                  [engagementId]="engagementId" (cancelKpitButtonClick)="handleCancelAddButtonClick(1)"
                  (saveKpiButtonClick)="handleSaveAddButtonClick(1)"
                  (viewKpiButtonClick)="handleViewButtonClick($event,1)"> </app-eng-create-kpi>
               <app-eng-edit-kpi id="kpiEditComponent" *ngIf="_kpiChildComponents.supKpiEdit==_currentKpiChildComponent"
                  [engagementId]="engagementId" (cancelKpitButtonClick)="handleCancelAddButtonClick(1)"
                  (viewKpiButtonClick)="handleViewButtonClick($event,1)" [kpiId]="kpiId"> </app-eng-edit-kpi>
               <app-eng-view-kpi id="kpiViewComponent" *ngIf="_kpiChildComponents.supKpiView==_currentKpiChildComponent"
                  [engagementId]="engagementId" (cancelKpitButtonClick)="handleCancelAddButtonClick(1)" [kpiId]="kpiId" (editKpiButtonClick)="handleEditButtonClick($event,1)">
               </app-eng-view-kpi>
            </p-tabPanel>
            <p-tabPanel header="Meetings" id="meetingTab">
               <app-eng-meetings-list id="meetingListComponent" *ngIf="_meetingChildComponents.supMeetingList==_currentMeetingChildComponent"
                  [engagementId]="engagementId" (addMeetingButtonClick)="handleAddButtonClick(2)"
                  (editMeetingButtonClick)="handleEditButtonClick($event,2)"
                  (viewMeetingButtonClick)="handleViewButtonClick($event,2)"> </app-eng-meetings-list>
               <app-eng-create-meeting id="meetingCreateComponent" *ngIf="_meetingChildComponents.supMeetingAdd==_currentMeetingChildComponent"
                  [engagementId]="engagementId" [supplierName]="engagementdata.supplier" (cancelMeetingButtonClick)="handleCancelAddButtonClick(2)"
                  (saveMeetingButtonClick)="handleSaveAddButtonClick(2)"
                  (viewMeetingButtonClick)="handleViewButtonClick($event,2)"> </app-eng-create-meeting>
               <app-eng-edit-meeting  id="meetingEditComponent" *ngIf="_meetingChildComponents.supMeetingEdit==_currentMeetingChildComponent"
                  [engagementId]="engagementId" [supplierName]="engagementdata.supplier" (cancelMeetingButtonClick)="handleCancelAddButtonClick(2)"
                  (viewMeetingButtonClick)="handleViewButtonClick($event,2)" [meetingId]="meetingId">
               </app-eng-edit-meeting>
               <app-eng-edit-meeting-rec-yes id="meetingEditRecYesComponent"
                  *ngIf="_meetingChildComponents.supMeetingEditRecYes==_currentMeetingChildComponent"
                  [engagementId]="engagementId" [supplierName]="engagementdata.supplier" (cancelMeetingButtonClick)="handleCancelAddButtonClick(2)"
                  (viewMeetingButtonClick)="handleViewButtonClick($event,2)" [meetingId]="meetingId">
               </app-eng-edit-meeting-rec-yes>
               <app-eng-view-meeting id="meetingViewComponent" *ngIf="_meetingChildComponents.supMeetingView==_currentMeetingChildComponent"
                  [engagementId]="engagementId" [supplierName]="engagementdata.supplier" [meetingId]="meetingId" (cancelMeetingButtonClick)="handleCancelAddButtonClick(2)"
                  (editMeetingButtonClick)="handleEditButtonClick($event,2)"> </app-eng-view-meeting>
            </p-tabPanel>
            <p-tabPanel header="Audit Support">
               <app-eng-list-audit id="auditListComponent" *ngIf="_auditChildComponents.supAuditList==_currentAuditChildComponent"
                  [engagementId]="engagementId" (addButtonClick)="handleAuditAddButtonClick()"
                  (editButtonClick)="handleAuditEditButtonClick($event)"
                  (viewButtonClick)="handleAuditViewButtonClick($event)"> </app-eng-list-audit>
               <app-eng-create-audit id="auditCreateComponent" *ngIf="_auditChildComponents.supAuditAdd==_currentAuditChildComponent"
                  [engagementId]="engagementId" [supplierName]="engagementdata.supplier" (cancelAuditButtonClick)="handleAuditCalcelAddButtonClick()"
                  (saveAuditButtonClick)="handleAuditSaveAddButtonClick()"
                  (viewButtonClick)="handleAuditViewButtonClick($event)"> </app-eng-create-audit>
               <app-eng-edit-audit id="auditEditComponent" *ngIf="_auditChildComponents.supAuditEdit==_currentAuditChildComponent"
                  [engagementId]="engagementId" [supplierName]="engagementdata.supplier" (cancelAuditButtonClick)="handleAuditCalcelAddButtonClick()"
                  (viewButtonClick)="handleAuditViewButtonClick($event)" [auditId]="auditId"> </app-eng-edit-audit>
               <app-eng-view-audit id="auditViewComponent" *ngIf="_auditChildComponents.supAuditView==_currentAuditChildComponent"
                  [engagementId]="engagementId" [auditId]="auditId" [supplierName]="engagementdata.supplier" (cancelAuditButtonClick)="handleAuditCalcelAddButtonClick()"
                  (editButtonClick)="handleAuditEditButtonClick($event)"> </app-eng-view-audit>
            </p-tabPanel>
         </p-tabView>
      </p-tabPanel>
       
      <p-tabPanel header="Finance" [hidden]="!viewFinancePrivilege">
       <app-eng-summary-view *ngIf="showSummaryView"   [engagementId]="engagementId" [engagementName]="engagementName"></app-eng-summary-view>
      </p-tabPanel>
   
      <p-tabPanel header="Team">
         <app-associate-list *ngIf="_teamChildComponents.associateChildList==_currentTeamChildComponent" [FromType]="FromType" [entityId]="engagementId" [SupplierId]="SupplierId"   [SponsorId]="SponsorId" [EngagementId]="engagementId" [engagementStartDate]="engagementStartDate" [EngagementName]="engagementName" (addTeamButtonClick)="handleAddButtonClick(4)" (editTeamButtonClick)="handleEditButtonClick($event,4)" (viewTeamButtonClick)="handleViewButtonClick($event,4)"> </app-associate-list>
         <app-associate-add-edit *ngIf="_teamChildComponents.associateChildAddEdit==_currentTeamChildComponent" [FromType]="FromType" [actionType]="actionType" [associateId]="associateId" [entityId]="engagementId" [SupplierId]="SupplierId"   [SponsorId]="SponsorId" [EngagementId]="engagementId" (cancelTeamButtonClick)="handleCancelAddButtonClick(4)" (saveTeamButtonClick)="handleSaveAddButtonClick(4)" (viewTeamButtonClick)="handleViewButtonClick($event,4)"> </app-associate-add-edit>
         <app-associate-view *ngIf="_teamChildComponents.associateChildView==_currentTeamChildComponent" [FromType]="FromType" [associateId]="associateId" [entityId]="engagementId" (cancelTeamButtonClick)="handleCancelAddButtonClick(4)" (editTeamButtonClick)="handleEditButtonClick($event,4) "> </app-associate-view>
     
      </p-tabPanel>

      
      </p-tabView>
      <!-- Tab View Module End -->
    <p-dialog appendTo="body" header="View MSA" [(visible)]="displayMSADialog" [modal]="true" [draggable]="false"
    [style]="{ width: '82vw' }" [resizable]="false" [closable]="true">
   <ng-template pTemplate="content">
         <div class="fr-flex__container">
            <div class="fr-flex__col-4">
               <div class="fr-form__view">
                  <label> MSA Title <em> * </em> </label>
                  <span [pTooltip]="currentMSADetail.msaTitle!.length > 30 ? currentMSADetail.msaTitle : ''" tooltipPosition="top"> <em>  {{currentMSADetail?.msaTitle}} </em> </span>
               </div>
            </div>
            <div class="fr-flex__col-4">
               <div class="fr-form__view">
                  <label>Start Date <em> * </em> </label>
                  <span> <em> {{currentMSADetail?.startDate | date: _dateformart.GridDateFormat}} </em> </span>
               </div>
            </div>
            <div class="fr-flex__col-4">
               <div class="fr-form__view">
                  <label>End Date <em> * </em> </label>
                  <span><em> {{currentMSADetail?.endDate | date: _dateformart.GridDateFormat}} </em> </span>
            </div>
            </div>
         </div>
         <div class="fr-flex__container">
            <div class="fr-flex__col-4">
               <div class="fr-form__view">
                  <label>Payment Terms <em> * </em> </label>
                  <span [pTooltip]="currentMSADetail.paymentTerms!.length > 30 ? currentMSADetail.paymentTerms : ''" tooltipPosition="top"> <em> {{currentMSADetail?.paymentTerms}} </em></span>


               </div>
            </div>
            <div class="fr-flex__col-4">
               <div class="fr-form__view">
                  <label>Attachment <em> * </em> <i pInputText pTooltip="Only 1 Attachment allowed." tooltipPosition="top" class="pi pi-info-circle"> </i> </label>
                  <a [class.attachment-active]="currentMSADetail?.fileName" routerLinkActive="active"
                     (click)="currentMSADetail?.fileName && downloadAttachemnt(currentMSADetail?.fileName, currentMSADetail?.filePath)">
                     <span [pTooltip]="currentMSADetail?.fileName?.length > 40 ? currentMSADetail?.fileName : ''"
                        tooltipPosition="top">
                        <em>{{ currentMSADetail?.fileName || '--' }}</em>
                     </span>
                     <div *ngIf="currentMSADetail?.fileName !== '' && currentMSADetail?.fileName !== undefined">
                        <i pInputText pTooltip="Download" tooltipPosition="top"
                           (click)="downloadAttachemnt(currentMSADetail?.fileName, currentMSADetail?.filePath)"
                           class="pi pi-download">
                        </i>
                     </div>
                  </a>
               </div>
            </div>
            <div class="fr-flex__col-4">
               <div class="fr-form__view">
                  <label>Status <em> * </em></label>
                  <span> <em> {{currentMSADetail.status}} </em></span>
               </div>
            </div>
         </div>
         <div class="fr-flex__container">
            <div class="fr-flex__col-12">
               <div class="fr-form__view">
                  <label>Description </label>

                  <span class="full-width" *ngIf="description === null || description==='' || description.length===0 ">--</span> <span class="full-width" [innerHTML]="description" *ngIf="description  !== null"> {{description }} </span>

               </div>
            </div>

            <div class="fr-flex__container">
               <div class="fr-flex__col-12">
                  <h4 class="popup_title"> Sponsor Contact </h4>
               </div>
            </div>
            <div class="fr-flex__container">
               <div class="fr-flex__col-4">
                  <div class="fr-form__view">
                     <label>Name <em> * </em> </label>
                     <span> <em> {{sponsorContactData[0] ? sponsorContactData[0] : '--'}} </em></span>
                  </div>
               </div>
               <div class="fr-flex__col-4">
                  <div class="fr-form__view">
                     <label>Designation <em>  </em> </label>
                     <span [pTooltip]="sponsorContactData[1].length > 30 ? sponsorContactData[1] : ''" tooltipPosition="top"> <em> {{sponsorContactData[1] ? sponsorContactData[1] : '--'}}  </em></span>
                  </div>
               </div>
               <div class="fr-flex__col-4">
                  <div class="fr-form__view">
                     <label>Email <em> * </em> </label>
                     <span [pTooltip]="sponsorContactData[2].length > 30 ? sponsorContactData[2] : ''" tooltipPosition="top"> <em>{{sponsorContactData[2] ? sponsorContactData[2] : '--'}}  </em></span>
                  </div>
               </div>
            </div>

            <div class="fr-flex__container"> 
               <div class="fr-flex__col-4">
                  <div class="fr-form__view">
                     <label>Country code </label>
                     <span><em> {{sponsorContactData[3] ? sponsorContactData[3] : '--'}} </em></span>
                  </div>
               </div>
               <div class="fr-flex__col-4">
                  <div class="fr-form__view">
                     <label>Phone Number </label>
                     <span><em> {{sponsorContactData[4] ? sponsorContactData[4] : '--'}}  </em></span>
                  </div>
               </div>
             
            </div>
          
            <div class="fr-flex__container">
               <div class="fr-flex__col-12">
                  <h4 class="popup_title"> Supplier Contact </h4>
               </div>
            </div>
            <div class="fr-flex__container">
               <div class="fr-flex__col-4">
                  <div class="fr-form__view">
                     <label>Name <em> * </em> </label>
                     <span> <em> {{supplierContactData[0] ? supplierContactData[0] : '--'}} </em></span>
                  </div>
               </div>
               <div class="fr-flex__col-4">
                  <div class="fr-form__view">
                     <label>Designation <em>  </em> </label>
                     <span [pTooltip]="supplierContactData[1].length > 30 ? supplierContactData[1] : ''" tooltipPosition="top"> <em> {{supplierContactData[1] ? supplierContactData[1] : '--'}}  </em></span>
                  </div>
               </div>
               <div class="fr-flex__col-4">
                  <div class="fr-form__view">
                     <label>Email <em> * </em> </label>
                     <span [pTooltip]="supplierContactData[2].length > 30 ? supplierContactData[2] : ''" tooltipPosition="top"> <em>{{supplierContactData[2] ? supplierContactData[2] : '--'}}  </em></span>
                  </div>
               </div>
            </div>
            <div class="fr-flex__container">
               <div class="fr-flex__col-4">
                  <div class="fr-form__view">
                     <label>Country code </label>
                     <span><em> {{supplierContactData[3] ? supplierContactData[3] : '--'}} </em></span>
                  </div>
               </div>
               <div class="fr-flex__col-4">
                  <div class="fr-form__view">
                     <label>Phone Number </label>
                     <span><em> {{supplierContactData[4] ? supplierContactData[4] : '--'}}  </em></span>
                  </div>
               </div>
               </div>
           
         </div>
   </ng-template>
   <ng-template pTemplate="footer">
      <button type="button" class="btn btn-secondary pull-right" (click)="cancelDialog()"> Close </button>
   </ng-template>
    </p-dialog>
  </div>


</body>
</html>
