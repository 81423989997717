import { DatePipe, formatDate } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CodeListValues, AccrualAttachments, AccuralEmailPayload } from 'src/app/Finance/Models/contract-accrual';
import { FinanceService } from 'src/app/Finance/Services/invoice.service';
import { LoaderService } from 'src/app/Loader/loader.service';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';

import { AuditIdentifier, AuditLogEntities, AuditLogModule, AuditOperations, Dateformat } from 'src/app/shared/constants/global.constants';
import { AuditLogService } from 'src/app/shared/ModelComparison/audit.log.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { FileUploadService } from 'src/app/Engagement/Services/fileupload.service';
import { NotificationService } from 'src/app/shared/Notifications/notification.service';
import { CurrencyService } from 'src/app/Reusable/currency.service';
import { TabAndStateService } from 'src/app/shared/TabAndStateService/TabAndState.Service';
import { } from 'console';
import { Datetime } from 'aws-sdk/clients/costoptimizationhub';

@Component({
  selector: 'app-con-accruals-edit',
  templateUrl: './con-accruals-edit.component.html',
  styleUrls: ['./con-accruals-edit.component.css']
})

export class ConAccrualsEditComponent {
  
  @Output() cancelAccrualButtonClick = new EventEmitter<void>();
  @Output() saveAccrualButtonClick = new EventEmitter<void>();
  @Output() viewButtonClick: EventEmitter<any> = new EventEmitter<any>();


  accrualStatusDataSource: CodeListValues[] | undefined;
  accrualForm: FormGroup;
  @Input() contractId: any;
  @Input() accrualId: any;
  @Input() SupplierId: any;
  @Input() SponsorId: any;
  @Input() EngagementName: any;

  accrualDetail:any;
  accrualDetailOld:any;
  accrualSubmitted:boolean=false;
  isSaveBtnDisable = false;
  _dateformart :any;
  _accuralEmailPayload=new AccuralEmailPayload();
  currency:any;
  currentIndex: number = -1; 
  listOfDraftInvocies:any;
  constructor(private route: ActivatedRoute,private router:Router,private loaderService: LoaderService,
    private confirmationService: ConfirmationService,private messageService: MessageService,
    private _fileService: FileUploadService,private datePipe: DatePipe,private formBuilder: FormBuilder,
    private financeService:FinanceService, private masterDataService: SponserService,
    private auditLogService: AuditLogService,private privilegeService: PrivilegeService,
    private notificationService:NotificationService,private currencyService: CurrencyService, private tabAndStateService: TabAndStateService ){
  
     this.accrualForm = this.formBuilder.group({
      _accrualAmount: ['', [Validators.required,Validators.pattern(/^\d{1,13}\.\d{1,2}$|^\d{1,15}$/)]],
      _statusMappingId: ['', [Validators.required]],
      _remarks:['', [Validators.maxLength(300)]],
      _accrualFile: [''], 
      _accrualFileName: '',
      _accrualAttachments: [''],
    });
  
  }

  attchement:any
  accrualAttachment:any;
  accrualAttachments: AccrualAttachments[]=[];
  auditIdentifierDetails:any;
  auditEntities:any;
 auditOperations:any;
auditModule:any;
updatedBy:any;
oldStatus:any;
oldAmout:any;

   ngOnInit() {
    
    this.bindData(this.accrualId);
    this.GetAccrualStatus(3);
    this._dateformart=Dateformat;
    this.auditEntities=AuditLogEntities;
    this.auditOperations=AuditOperations;
    this.auditModule=AuditLogModule;
    this.currency= this.route.snapshot.queryParamMap.get('currency');
  this.listOfDraftInvocies=this.tabAndStateService.getDraftInvoicesData();
  this.currentIndex = this.listOfDraftInvocies.findIndex((a: { accrualDate: any; }) => a.accrualDate ==this.accrualDetail.accrualDate);
}
  
approvedByUser:any;
bindData(data:any)
{

  this.accrualDetail = data;
  this.accrualAttachment= new AccrualAttachments();
  if(this.accrualDetail.id!=0)
    {
      if(this.accrualDetail.statusMappingId==0) //this.accrualDetail.accrualAmount==0 
      {
        this.accrualDetail.accrualAmount=null;
        this.accrualDetail.statusMappingId=null;
      }
      
      this.accrualDetail.contractDetailId=this.contractId;
      this.updatedBy=this.privilegeService.getUserName(this.accrualDetail.updatedBy); 
      if(this.accrualDetail.approvedBy!=null){
      this.approvedByUser= this.privilegeService.getUserName(this.accrualDetail.approvedBy);
      }
      else{
        this.approvedByUser=null;
      }

      
     // if(this.accrualDetail.statusMappingId==0)
      if(this.accrualDetail.financeContractAccrualAttachments == null){
        this.accrualDetail.financeContractAccrualAttachments.push(this.accrualAttachment)
      }
      else if(this.accrualDetail.financeContractAccrualAttachments.length>0){
        this.accrualAttachment.fileName=this.accrualDetail.financeContractAccrualAttachments[0].fileName;
        this.accrualAttachment.filePath=this.accrualDetail.financeContractAccrualAttachments[0].filePath;
     }
     this.oldAmout=this.accrualDetail.accrualAmount;
     this.oldStatus=this.accrualDetail.statusMappingId;
    }
    else{
      this.approvedByUser=null;
      this.updatedBy=null;
      this.accrualDetail.accrualAmount=null;
      this.accrualDetail.remarks=null;
      this.accrualDetail.statusMappingId=null;
      if(this.accrualDetail.statusMappingId==0){
        this.accrualDetail.statusMappingId=null;
        
      }
      
      this.accrualDetail.contractDetailId=this.contractId;
      if(this.accrualDetail.financeContractAccrualAttachments == null){
        this.accrualDetail.financeContractAccrualAttachments=this.accrualAttachments;
        this.accrualDetail.financeContractAccrualAttachments.push(this.accrualAttachment)
      }
     
    }

    this.GetAccrualStatus(3);
   
   
   this.accrualDetailOld=JSON.stringify(this.accrualDetail);
   this.auditIdentifierDetails=AuditIdentifier; 

   this.oldContractAccuralData=JSON.stringify(this.accrualDetail);
  this.contractAccuralDetailBeforeUpdate=JSON.stringify(this.accrualDetail);
}  
validateNumericField(control: any) {
  const numericRegex = /^\d+(\.\d{1,2})?$/;
  if (control.value && !numericRegex.test(control.value)) {
    return { invalidNumber: true };
  }
  return null;
}

isDisabled=false;
GetAccrualStatus(type:number) {
      this.masterDataService.getCodeListData(type,'Accrual Status').subscribe(
        res=>{
          this.accrualStatusDataSource=res.data;
          var status= this.accrualStatusDataSource?.find(data=>data.id==this.accrualDetail.statusMappingId)?.name;
          if(status=='Approved'){
              this.isDisabled=true;
          }
          else{
              this.isDisabled=false;
          }
        }
      );
};
   
GetContractAccrualDetailById() {
      if(this.accrualDetail.id>0){
        this.financeService.GetContractAccrualDetailById(this.accrualDetail.id).subscribe(
          res=>{
            let accrualData=res.data;
          }
        );
      }
};


onCancelContractAccrual()
{
 if((this.accrualDetailOld==JSON.stringify(this.accrualDetail)))
 {
  this.cancelAccrualButtonClick.emit();
 }
 else{
  this.confirmationService.confirm({
    header: 'Confirm',
    message: 'Changes will not be saved, Do you want to Continue?',
    accept: () => {
    this.messageService.add({key: 'tc', severity: 'warn', summary: 'Changes not saved', detail: ''});
    this.cancelAccrualButtonClick.emit();
  }
  });
 }
}

async saveContractAccrual()
{
  this.accrualSubmitted=true;
  this.isSaveBtnDisable = true;
  setTimeout(() => {
    this.isSaveBtnDisable = false;
  }, 3000);
  if(this.accrualForm.valid)
  {
    this.loaderService.showLoader();
    if(this.deleteAccuralFiles.length>0)
     await this.deleteAccuralFile();
         let isUploadRequired=false;
           if(this.uploadedAccrualFileData.length>0){
                
                  this.accrualFormData.append("file",this.uploadedAccrualFileData[0],this.accrualDetail.financeContractAccrualAttachments[0].filePath);
                  isUploadRequired=true;
               
           } 
            if(isUploadRequired) {
              this._fileService.UploadMultipleFile(this.accrualFormData).subscribe((isUploaded: any) => {
                if (isUploaded)
                {  
                    // file uploaded successfully into the s3 bucket     
                    this.updateAccrualDetails();
                }
                else
                {
                    this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Add accrual attachment failed to save', detail: '' })
                    this.loaderService.hideLoader();
                }
              });
            }
            else
            {
                  this.updateAccrualDetails();
            }
  }
  else{
    this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Please enter required information', detail: '' });  
  }
}
isStatusApproved:boolean=false;
isDataUpdate:boolean=false;
updateAccrualDetails(){

        this.accrualDetail.accrualDate = this.datePipe.transform(this.accrualDetail.accrualDate, "MM/dd/yyyy");
        this.accrualDetail.createdBy = this.privilegeService.getLoginUserId();
        this.accrualDetail.updatedBy = this.privilegeService.getLoginUserId();

        this.accrualDetail.accrualStatus = this.accrualStatusDataSource?.find(data=>data.id==this.accrualDetail.statusMappingId)?.name;
  
        if(this.oldAmout!=this.accrualDetail.accrualAmount)
          {
            this.isDataUpdate=true;
          }
          if(this.oldStatus!=this.accrualDetail.statusMappingId && this.accrualDetail.accrualStatus=="Approved")
          {
              this.isStatusApproved=true;
          }


  this.financeService.UpdateContractAccrual(this.accrualDetail).subscribe(async res => {
    if(res.status)
    {
     
     
      this._accuralEmailPayload.SupplierId=this.SupplierId;
      this._accuralEmailPayload.ContractId=this.contractId;
      this._accuralEmailPayload.SponsorId=this.SponsorId;
      this._accuralEmailPayload.EngagementName=this.EngagementName;
      const formattedValue = this.getFormattedValue(parseFloat(this.accrualDetail.accrualAmount), this.currency);
      this._accuralEmailPayload.AccrualAmount = formattedValue;
      this._accuralEmailPayload.AcrualMonthYear= this.datePipe.transform(this.accrualDetail.accrualDate, "MMMM yyyy");
      this.messageService.add({ key: 'tc', severity: 'success', summary: 'Changes have been saved', detail: '' });

      
      if(this.isDataUpdate && !this.isStatusApproved)
        {
           await this.notificationService.UpdateAccuralNotification(this._accuralEmailPayload,1);
        }
        if(this.isStatusApproved)
          {
            this._accuralEmailPayload.ApprovedByUser=this.privilegeService.getUserName(this.accrualDetail.approvedBy);
            this._accuralEmailPayload.ApprovedDateTime=this.accrualDetail.approvedDateTime;
            this._accuralEmailPayload.ApprovedDateTime=new Date(this._accuralEmailPayload.ApprovedDateTime.getTime() - (5.5 * 60 * 60 * 1000)); // Convert to UTC
 
            await this.notificationService.UpdateAccuralNotification(this._accuralEmailPayload,2);
          }
      this.compareModels();
      this.loaderService.hideLoader();
      this.saveAccrualButtonClick.emit();

          
    

      
    }
    else{
      this.loaderService.hideLoader();
    }
  });
 
}

maxFileSize:any;
uploadedAccrualFileData: any[] = [];
timeStamp:any;
accrualFormData = new FormData();


onAccrualAttachmentChange(fileDetails:any) {
 this.maxFileSize=this._fileService._fileSize; // 20 MB  (value Bytes  in binary)
   // file upload on change event method
      if(fileDetails.length > 0) 
      {
         let currentFileSize=fileDetails[0].size;
         if(currentFileSize<=this.maxFileSize)
         {
           let uploadedFile = fileDetails[0];
           let uploadedExtension = uploadedFile.name.split('.').pop().trim().toLowerCase();
           if(this._fileService.allowedExtensions.includes(uploadedExtension))   
           {
                         this.accrualForm.patchValue({
                          _accrualFileName: uploadedFile.name
                          })
                     this.uploadedAccrualFileData=[];
                     this.uploadedAccrualFileData.push(uploadedFile);
                     this.timeStamp ="";
                     let utcDateTimeNow =  new Date().toISOString()
                     this.timeStamp = formatDate(utcDateTimeNow, 'ddMMyyyy-hhmmss', 'en-US');
                     // update File name / file relative path for UI and DB 

                    this.accrualDetail.financeContractAccrualAttachments[0].fileName=this.uploadedAccrualFileData[0].name
                    this.accrualDetail.financeContractAccrualAttachments[0].filePath ="/Contracts/Finance/Accrual/" +this.timeStamp+"_"+ this.accrualDetail.financeContractAccrualAttachments[0].fileName;                 
                    this.accrualAttachment.fileName= this.accrualDetail.financeContractAccrualAttachments[0].fileName;
                    this.accrualAttachment.filePath= this.accrualDetail.financeContractAccrualAttachments[0].filePath;
           }
           else{
             this.accrualForm.patchValue({
              _accrualFileName: '',
              _accrualFile: ''
           })
           this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Invalid file type only .pdf, .xls, .xlsx, .doc, .docx files are allowed.', detail: '' });
           }
     }
     else{
        this.accrualForm.patchValue({
          _accrualFileName: '',
          _accrualFile: ''
      })
      this.messageService.add({  key: 'tc',severity: 'warn', summary: 'File Size limited to 20 Mb', detail: '' });
    }
    }
}


deleteAccuralFile() : any {
  let isDeleted=false;
   this._fileService.DeleteFile(this.deleteAccuralFiles).subscribe(responseDelete=>{
       return isDeleted =responseDelete;
       } 
   );
 }



 deleteAccuralFiles : any[]=[]; 

 prepareDeleteFileObject(){

   if(this.accrualDetail.id!=0 && this.accrualDetail.financeContractAccrualAttachments[0].id!=0){
       let fileDetail = {
         "fileName":this.accrualDetail.financeContractAccrualAttachments[0].fileName,
         "filePath":this.accrualDetail.financeContractAccrualAttachments[0].filePath
       };
       this.deleteAccuralFiles.push(fileDetail);
      
      this.accrualAttachment.isPrevFileDelete=true;
    }
       this.accrualDetail.financeContractAccrualAttachments[0].fileName = '';
       this.accrualDetail.financeContractAccrualAttachments[0].filePath = '';
       this.accrualAttachment.fileName= '';
       this.accrualAttachment.filePath= '';
       
       this.accrualForm.patchValue({
        _accrualFileName: '',
       })  
       this.uploadedAccrualFileData=[];
   }
 
 



fileDetail:any;
async downloadFile(fileName: any, filePath: any) {
  this.loaderService.showLoader();

  if (this.uploadedAccrualFileData.length > 0) {
      this.prepareDownload(fileName, this.uploadedAccrualFileData[0]);
      this.loaderService.hideLoader(); // Hide loader immediately after preparing the download
  } else {
      let fileDetail = {
          "fileName": fileName,
          "filePath": filePath
      };
      
      this._fileService.DownloadFile(fileDetail).subscribe(
          async res => {
              try {
                  let blobData = this._fileService.dataURItoBlob(res);
                  if (blobData.size > 0) {
                      await this._fileService.downloadBase64File(blobData, fileDetail.fileName);
                  } else {
                      this.handleDownloadError();
                  }
              } catch (error) {
                  this.handleDownloadError();
              }
          },
          error => {
              this.handleDownloadError();
          }
      );
  }
}

private handleDownloadError() {
  this.loaderService.hideLoader();
  this.messageService.add({
      key: 'tc',
      severity: 'warn',
      summary: 'Sorry, unable to download the file',
      detail: ''
  });
}


prepareDownload(filename:any, file:any) {

  

  let fileContent= file;
  const fileData=new Blob([fileContent], { type:"text/plain"});
  const link = document.createElement('a');
  link.href = URL.createObjectURL(fileData);
  link.download=filename;
  link.click();
  link.remove();

  this.loaderService.hideLoader();
}

changedContractAccuralBasicInfo:any;
oldContractAccuralData:any;
contractAccuralDetailBeforeUpdate:any
compareModels() {
  this.changedContractAccuralBasicInfo = this.auditLogService.compareModels(JSON.parse(this.contractAccuralDetailBeforeUpdate),this.accrualDetail,this.auditEntities.ContractAccruals,this.auditIdentifierDetails.ContractAccruals,this.auditOperations.Update,this.auditModule.FinanceService);
}
getFormattedValue(value: number, currencyName: string): string {
  return this.currencyService.getFormattedValue(value, currencyName);
}
navigateTo(index: number): void {
  if (index >= 0 && index < this.listOfDraftInvocies.length) {
      this.accrualDetail=this.listOfDraftInvocies[index];
      this.bindData(this.accrualDetail);
      this.currentIndex=index;
  }
}
nextRecord(): void {
  this.navigateTo(this.currentIndex + 1);
}
previousRecord(): void {
  this.navigateTo(this.currentIndex - 1);
}
getCurrentPositionText(): string {
  return `${this.currentIndex + 1} of ${this.listOfDraftInvocies.length}`;
}
pageChangeConfirmation(buttonType:number):void{
  if((this.accrualDetailOld==JSON.stringify(this.accrualDetail)))
  {
    buttonType==1?this.nextRecord():this.previousRecord();
  }
  else{
    this.confirmationService.confirm({
      message: 'Changes will not be saved, Do you want to Continue?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.messageService.add({
          key: 'tc',
          severity: 'info',
          summary: 'Changes not saved',
          detail: '',
      });
       buttonType==1?this.nextRecord():this.previousRecord();
      },
      reject: () => {
    
      },
    });
  }
}

onStatusChange(event:any){
 var status= this.accrualStatusDataSource?.find(data=>data.id==event.value)?.name;

if(status=='Approved')
{
  this.accrualDetail.approvedBy=this.privilegeService.getLoginUserId();;
  this.accrualDetail.approvedDateTime=new Date();
 }
else{
  this.accrualDetail.approvedBy=null;
  this.accrualDetail.approvedDateTime=null;
}

}

}


