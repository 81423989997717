import { ElementRef, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TabAndStateService {
  private tabName: string | undefined;
  private tabId: number | undefined;
  private FY:any;
  private FYBugetBalacne: any;
  private paginationDataSource: any[] = [];
  private kpiSource: any[] = [];
  private TeamsSource: any[] = [];
  private InvoicesSource: any[] = [];
  private ForecasteSource: any[] = [];
  private DraftInvoicesSource: any[] = [];
  private  CurrencyName: any[] = [];


  constructor() { }

  setPreviousState(tabName: string, tabId: number): void {
    this.tabName = tabName;
    this.tabId = tabId;
  }

  getPreviousState(): { tabName: string | undefined, tabId: number | undefined } {
    return {
      tabName: this.tabName,
      tabId: this.tabId
    };
  }

  setFYBalance(FY: any, FYBugetBalacne:any): void {
    this.FY = FY;
    this.FYBugetBalacne = FYBugetBalacne;
  }

  getFYBalance(): { FY: any, FYBugetBalacne:any } {
    return {
      FY: this.FY,
      FYBugetBalacne: this.FYBugetBalacne
    };
  }



 

  setPaginationDataSource(paginationDataSource: any): void {
    this.paginationDataSource = paginationDataSource;
  }
  getPaginationDataSource():any {
    return this.paginationDataSource;
  }
  setKpiData(kpiSource: any): void {
    this.kpiSource = kpiSource;
  }
  getKpiData():any {
    return this.kpiSource;
  }
  setTeamsData(TeamsSource: any): void {
    this.TeamsSource = TeamsSource;
  }
  getTeamsData():any {
    return this.TeamsSource;
  }
  setInvoicesData(InvoicesSource: any): void {
    this.InvoicesSource = InvoicesSource;
  }
  getInvoicesData():any {
    return this.InvoicesSource;
  }

  setDraftInvoicesData(DraftInvoicesSource: any): void {
    this.DraftInvoicesSource = DraftInvoicesSource;
  }
  getDraftInvoicesData():any {
    return this.DraftInvoicesSource;
  }
  
  setForecastData(ForecasteSource: any): void {
    this.ForecasteSource = ForecasteSource;
  }
  getForecasetData():any {
    return this.ForecasteSource;
  }


  setCurrencyName(CurrencyName: any): void {
    this.CurrencyName = CurrencyName;
  }
  getCurrencyName():any {
    return this.CurrencyName;
  }
  scrollToTop(scrollContainer?: ElementRef): void {
    if (scrollContainer) {
      scrollContainer.nativeElement.scrollTop = 0;
    }
    window.scrollTo(0, 0);
  }
}
