export class EngagementKpi {
  id: number=0;
  kpiDetailId?: number=0;
  engagementId?:number=0;
  kpiReportingFrequencyId?: number=0;
  kpiDataCollectionFrequencyId?: number=0;
  kpiReportingFrequencyCode?:string="";
  statusMappingId?: number=0;
  createdBy: number=0;
  createdDate?: Date;
  updatedBy:number=0;;
  updatedDate?: Date;
  isDeleted: boolean=false;
  kpiStartDate?: any;
  kpiEndDate?: any;
  hyperCareStartDate?: any;
  hyperCareEndDate?: any;
  description?: string = "";
  EngagementKPIHyperCareThreshold?: EngagementKPISteadyStateThreshold[];
  EngagementKPISteadyStateThreshold?: EngagementKPIHyperCareThreshold[];
  EngagementDataCollectionList?: EngagementKpiDataCollection[];
  serviceName?:string="";
  kpiName?:string="";
  frequencyValueforStartDate?:string="";
  kpiStartDateYear?:any;
  frequencyValueforEndDate?:string="";
  kpiEndDateYear?:any;
  attribute1?: string = "";
  attribute2?: string = "";
  attribute3?: string = "";
  calculation?: string = "";
}
export class EngagementKPISteadyStateThreshold {
  Id: number=0;
  percentageValue?:string="";
  mathematicalSymbolId: number=0;
  engagementKPIId: number=0;
}

export class EngagementKPIHyperCareThreshold {
  Id: number=0;
  percentageValue?:string="";
  mathematicalSymbolId: number=0;
  engagementKPIId: number=0;
}

export class CodeListValues {
  name: string="";
  id: number=0;
  code:string="";
  sub_Code="";
  type:number=0;
}

export class MasterKpiData {
  Id: number=0;
  kpiDetailId: number=0;
  KpiName: string="";
  unitOfMeasure: string="";
  unitOfMeasureCode: string="";
  category: string="";
  complianceIndicator: string="";
  calculation: string="";
  attribute1: string="";
  attribute2: string="";
  attribute3: string="";
  formulaeId:any;
  formulaName: string="";
  kpiAttributeTypeId:number=0;
  kpiAttributeTypeCode:string="";
  
}

export class EngagementKpiDataCollection {
  id: number=0;
  inputValueType:boolean=false;
  selectedInputValueType:string="";
  dataCollectionDate?: Date;
  calculatedKPI:any;
  remarks?:string="";
  engagementKpiId:number=0;
  attribute1?:string | null
  attribute2?:string | null
  attribute3?:string | null
  health:string="";
  kpiAttributeTypeId:number=0;
 dataCollectionFrequencyValue?:any;
 inputAttributeValueType:number=1;
 engagementKpiMitigationActionsList?: EngagementKpiMitigationActionsModel[]; 
  mitigationActions: any;
}
export class KpiFilterDetails
{
  Status?: any;
  Health?: any;
  Kpi?: any;
  kpiName?:any;
}
export class EngagementKpiMitigationActionsModel {
  id: number = 0; 
  action: string = ""; 
  actionOwner: string = "";
  targetDate?: any;
  statusMappingId: number = 0;
  closureDate?: any; 
  isDeleted?: boolean; 
}

