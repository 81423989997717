import { UserData,  UsersInfo } from './../Admin/Authorization/UserRoleMapping/Models/user';
import { Injectable } from '@angular/core';
import { Privileges } from '../shared/constants/privileges.constants';

@Injectable({
  providedIn: 'root'
})
export class PrivilegeService {

  private privileges: Privileges[] = [];
  private UserData: UserData = new UserData();
  private usersInfoList:UsersInfo[] = [];
  constructor() {}

  setPrivileges(privileges: Privileges[]) {
    this.privileges = privileges;
  }

  getPrivileges(): Privileges[] {
    return this.privileges;
  }
  setUserData(userData: UserData){
    this.UserData=userData;
  }
  getUserData(){
    return this.UserData;
  }
  setUsersList(usersList:UsersInfo[]){
    this.usersInfoList = usersList;
  }
  getUsersList(){
    return this.usersInfoList;
  }
  getUserName(UserId: any): any  {
    if (UserId != null && UserId !== undefined) {
        const user = this.usersInfoList?.find(user => user.id === UserId);
        return user?.name;
    }
    return "";
}
getLoginUserId(): any {
 return this.UserData.id;
}
  
  setGroup(groupId:number,groupType:number){
    const userData = this.UserData;
    if (groupType === 1) {
      userData.sponsers ??= [];
      userData.sponsers.push(groupId);
    }
    if (groupType === 2) {
      userData.suppliers ??= [];
      userData.suppliers.push(groupId);
    }
     else {
      userData.engagements ??= [];
      userData.engagements.push(groupId);
    }
  }

  hasPermission(permission: any): boolean {
    return this.privileges.includes(permission);

    
  }
}
