export enum Privileges {
  ViewHomePage='PV-VHMP',
  CreateSponsor='PV-SPOC',
  ViewSponsor='PV-SPOV',
  EditSponsor='PV-SPOE',
  InactiveSponsor='PV-SPOI',
  ExportlistofSponsors='PV-EXSPO',
  CreateSupplier='PV-CSUP',
  ViewSupplier='PV-VSUP',
  EditSupplier='PV-ESUP',
  InactiveSupplier='PV-ISUP',
  ExportlistofSuppliers='PV-EXSUP',
  AddSupplierKPI='PV-ASUKPI',
  ViewSupplierKPI='PV-VSUKPI',
  EditSupplierKPI='PV-ESUKPI',
  AddSupplierMeeting='PV-ASUPM',
  ViewSupplierMeeting='PV-VSUPM',
  EditSupplierMeeting='PV-ESUPM',
  AddSupplierAudit='PV-ASUPA',
  ViewSupplierAudit='PV-VSUPA',
  EditSupplierAudit='PV-ESUPA',
  ViewEngagementFinance='PV-VSUPF',
  ViewEngagementTeams='PV-VSUPT',
  SupplierAddAssociate='PV-ASUPAC',
  SupplierEditAssociate='PV-ESUPAC',
  SupplierExportlistofAssociates='PV-EXSUPAC',
  CreateContract='PV-CCON',
  ViewContract='PV-VCON',
  EditContract='PV-ECON',
  InactiveContract='PV-ICON',
  ExportlistofContracts='PV-EXCON',
  AddContractKPI='PV-ACONKPI',
  ViewContractKPI='PV-VCONKPI',
  EditContractKPI='PV-ECONKPI',
  AddContractMeeting='PV-ACONM',
  ViewContractMeeting='PV-VCONM',
  EditContractMeeting='PV-ECONM',
  ViewContractFinance='PV-VCONF',
  ViewInvoice='PV-VINV',
  AddInvoice='PV-AINV',
  EditInvoice='PV-EINV',
  ExportListofInvoices='PV-EXINV',
  ViewAccruals='PV-VACC',
  EditAccruals='PV-EACC',
  ViewForecasts='PV-VFRC',
  EditForecasts='PV-EFRC',
  ViewContractTeams='PV-VCONT',
  ContractAddAssociate='PV-ACONAC',
  ContractEditAssociate='PV-ECONTAC',
  ContractExportlistofAssociates='PV-EXCONTAC',
  CreateRoles='PV-CROLE',
  EditRoles='PV-EROLE',
  PrivilegesMapping='PV-AURPM',
  AssignRolesUsers='PV-AURU',
  AccessAdmincenter="PV-AAC",
  CreateEngagement='PV-CENG',
  ViewEngagement='PV-VENG',
  EditEngagement='PV-EENG',
 EmailInvoiceReport='PV-EIR',
  EmailTurnoverReport='PV-ETR',
  EngagementEmailTurnoverReport='PV-ENGETR',

ExportEngagementKPI='PV-EXENKPI',
ExportEngagementMeetings='PV-EXENM',
ExportEngagementAudit='PV-EXENA',
ExportEngagementFinance='PV-EXENF',
ExportContractKPI='PV-EXCONKPI',
ExportContractMeeting='PV-EXCONM',
ExportContractForecast='PV-EXCONF',
ExportContractDraftInvoices='PV-EXCONDINV',

ViewEngagementKPIDashboard='PV-VEKD',
ViewContractKPIDashboard='PV-VCKD',
ViewFunctionsFinanceDashboard='PV-VFFD',
ViewFunctionsTeamsDashboard='PV-VFTD',
ViewEngagementFinanceDashboard='PV-VEFD',
ViewEngagementTeamsDashboard='PV-VETD',
 EditFYBudget='PV-EFB',

 DeleteEngagementKPI='PV_EGKD',
 DeleteContractKPI='PV_COKD',
 DeleteEngagementAudit='PV_EGAD',
 DeleteContractAssociate='PV_CASD',
 DeleteEngagementAssociate='PV_EASD',
 DeleteContractInvoice='PV_CIND',
 DeleteContractMeeting='PV_CMED',
 DeleteEngagementMeeting='PV_EMED',
 }
