<!DOCTYPE html>
<html lang="en">
<head>
    <title> Contract Meeting List</title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>

            <p-tabView class="fr-tabview__header__custom"> 
                <p-tabPanel> 
                    <div class="flex-box m-t-70"> 
                    <div class="fr-flex__col-12"> 
                    <div class="fr-flex__container">
                        <div class="fr-flex__col-8">
                            <span class="number_of_records"> <em> ({{ filteredRecordCount > -1 ? filteredRecordCount : totalRecordCount }} of {{ totalRecordCount }} Records) </em> </span>
                        </div>
                        <!-- (click)="AddSupplierKPI()" -->
                        <div class="fr-flex__col-4">  
                            <a *ngIf="createPrivilege" class="btn btn-primary pull-right" (click)="addContractMeeting()"> Add Meeting </a>
                            <button *ngIf="ExportPrivilage" class="btn btn-outline-primary pull-right" [disabled]="isChecked" (click)="ExportContractMeetings()"><i class="pi pi-download"></i> Export</button>
                          
                        </div>
                    </div>
                </div>
            </div>
                    <div class="fr-flex__col-12">
                        <div class="inside-grid-pad"> 
        <p-table id="contractMeetingListTableName" class="primeng-table-custom custom-cells__sup-meeting-list"
        [scrollable]="true" [resizableColumns]="true" columnResizeMode="expand" styleClass="p-datatable-striped p-datatable-gridlines"
        [value]="_contractMeetingListData" 
        [(selection)]="selectedContractMeeting"
        editMode="row"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Entries"
        [rowsPerPageOptions]="[10, 20, 50, 100, 200]"
         [tableStyle]="{ 'min-width': '50rem' }">
            <ng-template pTemplate="header">
                <tr>  
                    <th id="contractMeetingListTHCheckbox" pFrozenColumn class="first-child-width">
                        <p-tableHeaderCheckbox (click)="CheckboxEnabled()"></p-tableHeaderCheckbox>
                    </th>              
                    <th id="conMeetingListTitle" pFrozenColumn pSortableColumn="title" pResizableColumn>Meeting Title <p-sortIcon field="title"></p-sortIcon></th>
                    <th id="conMeetingListDate" pSortableColumn="occurrenceDate" pResizableColumn>Date <p-sortIcon field="occurrenceDate"></p-sortIcon></th>
                    <th id="conMeetingListRecurring" pSortableColumn="recurring" pResizableColumn pInputText pTooltip="Recurring">Recurring <p-sortIcon field="recurring"></p-sortIcon></th>
                    <th id="conMeetingListOrganizer" pSortableColumn="organizer" pResizableColumn>Organizer <p-sortIcon field="organizer"></p-sortIcon></th>
                    <th id="conMeetingListOpenActions" pSortableColumn="openActionsCount" pResizableColumn>Open Actions​ <p-sortIcon field="openActionsCount"></p-sortIcon></th>
                    <th id="conMeetingListEdit" *ngIf="editPrivilege" class="last-child-width">Edit</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-contractMeeting> 
                <tr>
                    <td id="contractMeetingListTDCheckbox" pFrozenColumn class="first-child-width">
                        <p-tableCheckbox (click)="CheckboxEnabled()" [value]="contractMeeting"></p-tableCheckbox>
                    </td>
                     <td id="contractMeetingListHyperLink" pFrozenColumn routerLinkActive="active" >
                         <a (click)="viewContractMeeting(contractMeeting)" *ngIf="viewPrivilege; else disabledLink" class="table-active-link">{{ contractMeeting.title }}</a>
                         <ng-template #disabledLink>
                            {{ contractMeeting.title}}
                          </ng-template>
                        </td>
                    <td> <span *ngIf="!contractMeeting.recurring"> {{ contractMeeting.occurrenceDate | date:_dateformart.GridDateFormat}} </span> <span *ngIf="contractMeeting.recurring">NA</span></td>
                    <td> <span *ngIf="!contractMeeting.recurring"> No </span> <span class="ellipsis-content" *ngIf="contractMeeting.recurring"> Yes </span></td>
                    <td [pTooltip]="contractMeeting.organizer.length > 60 ? contractMeeting.organizer : ''" tooltipPosition="top"> <span title="{{ contractMeeting.organizer }}">{{ contractMeeting.organizer }}</span></td>
                    <td> <span *ngIf="contractMeeting.openActionsCount > 0">{{ contractMeeting.openActionsCount }}</span> <span *ngIf="contractMeeting.openActionsCount == 0">0</span></td>
                    <td id="contractMeetingListEdit" *ngIf="editPrivilege" class="grid-icon-center"><span class="pi pi-pencil" (click)="editContractMeeting(contractMeeting.id,contractMeeting.recurring)"></span></td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                <td colspan="8" class="text-center"> No Records</td>
                </tr>
                </ng-template>
        </p-table>
    </div>
</div>
    </p-tabPanel>
    </p-tabView>
</body>
</html>

   
