  export class CodeListValues {
    name: string="";
    id: number=0;
    code:string="";
    sub_code="";
    type:number=0;
    isActive:boolean=false;
  } 

export class EngagementAudit
{
    id: number=0;
    engagementId?: number=0;
    auditTitle?: string;
    auditNumber?: string;
    auditor?: string;
    auditeeSPOC?: number;
    auditee?: string;
    auditStartDate?: any;
    auditEndDate?: any;
    statusMappingId?: number;
    description?: string;
    createdBy: number=0;
    createdDate?: Date;
    updatedBy: number=0;
    updatedDate?: Date;
    isDeleted: boolean=false;
    auditAttachments?:EngagementAuditAttachments[];
    auditObservations?:EngagementAuditObservations[];
    
}

export class EngagementAuditAttachments {
    id: number=0;
    fileName?:string;
    filePath?:string;
    engagementAuditId: number=0;
    createdBy?: number=0;
    ceatedDate?: Date;
    updatedBy?: number=0;
    updatedDate?: Date;
    isDeleted?: Boolean;
  }
  export class EngagementAuditObservations{
    id: number=0;
    observationId?:string;
    grade?:number;
    numberofFidings?:number=0;
    statusMappingId?: number=0;
    engagementId: number=0;
    createdBy?: number;
    ceatedDate?: Date;
    updatedBy?: number;
    updatedDate?: Date;
    isDeleted?: Boolean;
    engagementAuditObservationFindingList?:EngagementAuditObsvFinding[];
  }

 
  export class EngagementAuditObsvFinding{
    id: number=0;
    findingId?:string;
    findingAreaId?:number;
    findingDetail?:string;
    findingOwnerId?: number;
    //targetDate?:Date;
    targetDate?:any;
    engagementResponse?:string;
    evidenceOfClosure?:string;
    decisionOfReview?:string;
    statusMappingId?:number;
    findingStatus?:string;
    findingOwnerName?:string;
    engagementAuditObservationId?:number;
    createdBy?: number;
    createdDate?: Date;
    updatedBy?:number;
    updatedDate?: Date;
    isDeleted?: Boolean;
    observationFindingAttachment?:EngagementAuditObsvFindingAttachments[];
    
  }


  export class EngagementAuditObsvFindingAttachments {
    id: number=0;
    fileName?:string;
    filePath?:string;
    engagementAuditObsvFindingId: number=0;
    createdBy?: number=0;
    ceatedDate?: Date;
    updatedBy?: number=0;
    updatedDate?: Date;
    isDeleted?: Boolean;
    isFileExist?:Boolean;
  }
