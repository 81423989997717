import { Component } from '@angular/core';
import { CodeList, Dateformat, breadcrumnsCodes } from 'src/app/shared/constants/global.constants';
import { MasterSupplierService } from '../../Services/master.supplier.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { LoaderService } from 'src/app/Loader/loader.service';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';
import { Privileges } from 'src/app/shared/constants/privileges.constants';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-master-view-supplier',
  templateUrl: './master-view-supplier.component.html',
  styleUrls: ['./master-view-supplier.component.css']
})
export class MasterViewSupplierComponent {

  _enumBreadcrumb:any;
  _dateformat:any;
supplierId:any;
supplierdata:any;
createdBy:any="System";
updatedBy:any="System";
supplierContacts:any;
codeListValues:any;
//Location Variables
locationsToArray: any;
ShowlocationsButton: boolean= true;
buttonlocationsLabel:any;
locationsvisible :boolean =false;
locationsDataSource: any;
locations: any;

//Service variables
servicesDataSource:any;
servicesToArray:any; 
ShowServicesButton : boolean= true;
services: any;
buttonLabel:any;
visible:boolean  = false;


editPrivilege:boolean=false;
selectedOption: string = 'Actions';


  constructor(private _masterSupplierSerice: MasterSupplierService, 
    private route: ActivatedRoute,
    private privilegeService: PrivilegeService,
    private loaderService: LoaderService,
    private breadcrumbService: BreadcrumbService,
    private SponserService: SponserService,
    private router:Router
    )
     {

     }

   
  ngOnInit() {
    this._enumBreadcrumb=breadcrumnsCodes;
    this.BindPrivileges();
    this._dateformat=Dateformat;
    this.route.params.subscribe(params => {
      
      this.supplierId=+params['id'] //log the value of id
    });
    this.codeListValues=CodeList;
   
   this.getSupplierById();
   this.generateBreadcrumb(this._enumBreadcrumb.VMSP,this.supplierdata.name);
     this.loaderService.hidefinalLoader();// hide after 4 seconds even get any error
    
   }



generateBreadcrumb(component?:string,recordName?:string)
{
  this.breadcrumbService.generateBreadcrumb(component,recordName);
}

   getBreadcrumbs(): MenuItem[] {
    return this.breadcrumbService.getBreadcrumbs();
  }

  notes:any;
  headquartersAddress:any;
   getSupplierById(){
    this.loaderService.showLoader();

    
    this._masterSupplierSerice.getSupplierById(this.supplierId).subscribe(
     (res:any)=>{
      this.supplierdata = res.data;
      this.headquartersAddress = this.supplierdata.headquartersAddress ? this.supplierdata.headquartersAddress.replace(/\n/g, "<br>") : this.supplierdata.headquartersAddress;
      this.notes = this.supplierdata.notesAndComments ? this.supplierdata.notesAndComments.replace(/\n/g, "<br>") : this.supplierdata.notesAndComments;
      this.createdBy =this.privilegeService.getUserName(res.data.createdBy);
      this.updatedBy =this.privilegeService.getUserName(res.data.updatedBy);
      
     
      this.supplierContacts = res.data.supplierContacts;
      if (this.supplierdata != undefined) {         
        
        this.GetAllCountries(this.codeListValues.Tbl_Countries);// 0to get locations from the code list
        this.GetAllServices(this.codeListValues.Tbl_Services);//1 To get the services from code list
      }
      this.generateBreadcrumb(this._enumBreadcrumb.VMSP,this.supplierdata.name);
    });
}



GetAllCountries(type:number) {
  this.SponserService.getCodeListData(type,'').subscribe(
    res=>{
      this.locationsDataSource=res.data;
      if(this.locationsDataSource != undefined && this.locationsDataSource.length > 0 && this.supplierdata.supplierLocations.length > 0)
      {
         this.supplierdata.supplierLocations.filter((id:any) => {  
         var filteredLocations = this.locationsDataSource.filter((location:any) => location.id === id);   
         if((filteredLocations != null && filteredLocations != undefined) && (this.locations == undefined || this.locations == ""))   
         {
            this.locations = filteredLocations[0].name;
         }
         else
         {
            this.locations += ","+filteredLocations[0].name;        
         }
      })
      if(this.supplierdata.supplierLocations.length > 2 && this.locations != undefined)
      {
    this.locationsToArray = this.locations.split(',');
    this.locations = this.locationsToArray.slice(0, 2);
    this.buttonlocationsLabel = this.supplierdata.supplierLocations.length -2; 
    this.buttonlocationsLabel = '+'+this.buttonlocationsLabel;
    this.ShowlocationsButton = false;
     }else{
      if(this.locations != null && this.locations != undefined)
      {
       this.locationsToArray = this.locations.split(',');
       this.locations = this.locationsToArray.slice(0, 2);
      }
     }  
      }
    }
  );
}


GetAllServices(type:number) {
  this.SponserService.getCodeListData(type,'').subscribe(
    res=>{
      this.loaderService.hideLoader();
      this.servicesDataSource=res.data;
      if(this.servicesDataSource != undefined && this.servicesDataSource.length > 0 && this.supplierdata.supplierServices.length > 0)
      {
            this.supplierdata.supplierServices.filter((id:any) => {  
         var filteredServices = this.servicesDataSource.filter((serv:any) => serv.id === id);   
         if((filteredServices != null && filteredServices != undefined && filteredServices.length > 0) && (this.services == undefined || this.services == ""))   
         {
            this.services = filteredServices[0].name;
         }
         else
         {
          if(filteredServices != null && filteredServices != undefined && filteredServices.length > 0) 
          {
            this.services += ","+filteredServices[0].name;    
          }             
         }
      })
      if(this.supplierdata.supplierServices.length > 2 && this.services != undefined)
      {
    this.servicesToArray = this.services.split(',');
    this.services = this.servicesToArray.slice(0, 2);
    this.buttonLabel = this.supplierdata.supplierServices.length -2; 
    this.buttonLabel = '+'+this.buttonLabel;
    this.ShowServicesButton = false;
     }else{
      if(this.services != null && this.services != undefined)
      {
       this.servicesToArray = this.services.split(',');
       this.services = this.servicesToArray.slice(0, 2);
      }
     }  
      }
    }
  );
  
}


showlocationsDialog()// tool tip on mouse over
{
  this.locationsvisible = !this.locationsvisible
}
hidelocationsDialog()// tool tip on mouse out
{
this.locationsvisible = false;

}



showServiceDialog()// tool tip on mouse over
{
  this.visible = !this.visible
}
hideServiceDialog()// tool tip on mouse out
{
  this.visible = false;
 
}


BindPrivileges(){
const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);
this.editPrivilege = hasPermission(Privileges.EditSupplier);
}

async onOptionChange(): Promise<void> {
  try {
    if (this.selectedOption === 'Edit') {
      await this.router.navigate(['/EditMasterSupplierData', this.supplierId]);
    }
  } catch (error) {
    console.log(error);
  }
}
BackToList() {
  this.router.navigate(['/SuppliersListMasterData']);
}

}
