import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import {map} from 'rxjs/operators'
import { EngagementKpi } from '../Model/engagementKpi';
import { AuthCommonService } from 'src/app/shared/Authentication/auth.service';

@Injectable({
  providedIn: 'root'
})
export class EngagementKpiService {

  readonly _governanceKpiApiBaseURl:any;
  readonly _sponserApiBaseUrl:any="";
  readonly _fileApiBaseURl?:string="";
  constructor(private http: HttpClient,private authCommonService: AuthCommonService) {
    this._governanceKpiApiBaseURl=environment._governanceApiBaseURl;
   }
  //  AddSupplierKpi(supplierkpi: SupplierKpi): Observable<any> {
  //   const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
  //   return this.http.post<any>(this._governanceKpiApiBaseURl + '/GovernanceKpis/AddSupplierKpi', supplierkpi, httpOptions);
  // }
  GetSupplierKpiById(supplierKpiId:number){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.get<any>(this._governanceKpiApiBaseURl + '/GovernanceKpis/GetSupplierKpiById?supplierKpiId='+supplierKpiId, httpOptions);
  }
  // UpdateSupplierKpi(supplierkpi: SupplierKpi): Observable<any> {
  //   const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
  //   return this.http.put<any>(this._governanceKpiApiBaseURl + '/GovernanceKpis/UpdateSupplierKpi', supplierkpi, httpOptions);
  // }
  public getSupplierKPIsBySupplierId(supplierProfileId:number): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
    return this.http.get<any>(this._governanceKpiApiBaseURl +  '/GovernanceKpis/GetSupplierKPIsBySupplierId?supplierProfileId=' + supplierProfileId, httpOptions);
  }
  
  GetSupplierKPIDetailsById(id: any)
  {
   const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.get<any>(this._governanceKpiApiBaseURl + '/GovernanceKpis/GetSupplierKPIDetailsById?supplierKPIId='+id, httpOptions);

  }


  GetSupplierKPIDetailsByIdForViewPage(id: any)
  {
   const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
    return this.http.get<any>(this._governanceKpiApiBaseURl + '/GovernanceKpis/GetSupplierKPIDetailsByIdForViewPage?supplierKPIId='+id, httpOptions);

  }

  public getSupplierMeetingsBySupplierId(supplierProfileId:number): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
    return this.http.get<any>(this._governanceKpiApiBaseURl +  '/GovernanceMeetings/GetSupplierMeetingsBySupplierId?supplierProfileId=' + supplierProfileId, httpOptions);
  }

  GetSupplierKPIDetailsByIdForExport(KpiIds:string){
    
 const headers = new HttpHeaders({
  'Content-Type': 'application/json',
  'ApiKey': this.authCommonService.getAuthenticationToken() 
});
    const params = { kpiIds: KpiIds };    
    const options = { params: params, headers: headers };
    return this.http.get<any>(this._governanceKpiApiBaseURl + '/GovernanceKpis/GetSupplierKPIDetailsByIdForExport',options)
    .pipe(map((res:any)=>{
      return res;
   }));
  }
  public getEngagementKPIsByEngagementId(engagementId:number): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
    return this.http.get<any>(this._governanceKpiApiBaseURl +  '/GovernanceKpis/GetEngagementKPIsByEngagementId?engagementId=' + engagementId, httpOptions);
  }
  AddEngagementKpi(engagementkpi: EngagementKpi): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
    return this.http.post<any>(this._governanceKpiApiBaseURl + '/GovernanceKpis/AddEngagementKpi', engagementkpi, httpOptions);
  }
  GetEngagementKPIDetailsByIdForViewPage(id: any)
  {
   const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
    return this.http.get<any>(this._governanceKpiApiBaseURl + '/GovernanceKpis/GetEngagementKPIDetailsByIdForViewPage?engagementKPIId='+id, httpOptions);

  }
  GetEngagementKpiById(engagementKpiId:number){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.get<any>(this._governanceKpiApiBaseURl + '/GovernanceKpis/GetEngagementKpiById?engagementKpiId='+engagementKpiId, httpOptions);
  }
  UpdateEngagementKpi(engagementkpi: EngagementKpi): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
    return this.http.put<any>(this._governanceKpiApiBaseURl + '/GovernanceKpis/UpdateEngagementKpi', engagementkpi, httpOptions);
  }
  GetEngagementKPIDetailsById(id: any)
  {
   const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.get<any>(this._governanceKpiApiBaseURl + '/GovernanceKpis/GetEngagementKPIDetailsById?engagementKPIId='+id, httpOptions);

  }
  GetEngagementKPIDetailsByIdForExport(KpiIds:string){
    
    const headers = new HttpHeaders({
     'Content-Type': 'application/json',
     'ApiKey': this.authCommonService.getAuthenticationToken() 
   });
       const params = { kpiIds: KpiIds };    
       const options = { params: params, headers: headers };
       return this.http.get<any>(this._governanceKpiApiBaseURl + '/GovernanceKpis/GetEngagementKPIDetailsByIdForExport',options)
       .pipe(map((res:any)=>{
         return res;
      }));
     }
    DeleteEngagementKpi(engagementKpiId: any): Observable<any> {
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'ApiKey': this.authCommonService.getAuthenticationToken() }) };
      return this.http.put<any>(`${this._governanceKpiApiBaseURl}/GovernanceKpis/DeleteEngagementKpiById?engagementKPIId=${engagementKpiId}`, {}, httpOptions);
    }
}
