import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import {CodeList, CodeListStatusTypes, ActiveInActiveStatus,StatusCodes,Dateformat,breadcrumnsCodes, CurrencyType, AuditIdentifier, AuditLogEntities, AuditOperations, AuditLogModule } from 'src/app/shared/constants/global.constants';
import { LoaderService } from 'src/app/Loader/loader.service';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { RouteUtilsService } from 'src/app/shared/Guards/route-utils.service';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';

import { FinanceService } from 'src/app/Finance/Services/invoice.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { Privileges } from 'src/app/shared/constants/privileges.constants';
import { CurrencyService } from 'src/app/Reusable/currency.service';
import { FileUploadService } from 'src/app/Engagement/Services/fileupload.service';
import { TabAndStateService } from 'src/app/shared/TabAndStateService/TabAndState.Service';
import { InvoiceDetails } from 'src/app/Finance/finances/Model/ContractInvoice';
import { AuditLogService } from 'src/app/shared/ModelComparison/audit.log.service';


@Component({
  
  selector: 'app-cont-invoice-view',
  templateUrl: './cont-invoice-view.component.html',
  styleUrls: ['./cont-invoice-view.component.css']
})

export class ContInvoiceViewComponent {
  @Input() invoiceId:any;
  @Input() ContractId:any;
  currencyformat:any;
  @Output() editButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() calcelInvoiceButtonClick = new EventEmitter<void>();
  constructor(private privilegeService: PrivilegeService,private route: ActivatedRoute, private datePipe: DatePipe,private router:Router,private loaderService: LoaderService,private breadcrumbService: BreadcrumbService 
    ,private routeUtils: RouteUtilsService, private SponserService: SponserService, private _fileService: FileUploadService, private _financeService: FinanceService,private currencyService: CurrencyService,private tabAndStateService: TabAndStateService ,
    private confirmationService: ConfirmationService,private messageService: MessageService,private auditLogService: AuditLogService ) {
     
  }

  _dateformart:any;
  _contractFinanceInvoiceDetails:any;
  selectedOption: string = 'Actions';
  codeListValues:any;
  codeListStatusTypes: any;
  currency:any;
  editPrivilege: boolean = false;
  deletePrivilege:boolean=false;
  createdBy: any;
  updatedBy: any;
  currentIndex: number = -1; 
  listOfInvoices:any;
  ngOnInit() {
    
    this._dateformart=Dateformat;
    this.codeListValues=CodeList;
    this.codeListStatusTypes=CodeListStatusTypes;
    this.currencyformat=CurrencyType;
    this.currency= this.route.snapshot.queryParamMap.get('currency');
    this.GetContractFinanceInvoiceDetailsById();
    this.BindPrivileges();
    this.listOfInvoices=this.tabAndStateService.getInvoicesData();
    this.currentIndex = this.listOfInvoices.findIndex((a: { id: any; }) => a.id ==this.invoiceId);
    this.auditIdentifierDetails=AuditIdentifier; 
    this.auditEntities=AuditLogEntities;
    this.auditOperations=AuditOperations;
    this.auditModule=AuditLogModule;
  }
  BindPrivileges(){
    const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);
  this.editPrivilege = hasPermission(Privileges.EditInvoice);
  this.deletePrivilege=hasPermission(Privileges.DeleteContractInvoice);
    }
    remarks:any;
    description:any;
  GetContractFinanceInvoiceDetailsById() {
      this.loaderService.showLoader();
    if (this.ContractId != undefined && this.ContractId != null && this.invoiceId != undefined && this.invoiceId != null) {
       this._financeService.GetContractFinanceInvoiceDetailsById(this.invoiceId)
         .subscribe(res => {               
           this._contractFinanceInvoiceDetails = res.data;
           this._oldContractInvoice=res.data;
           this._contractInvoice=this._contractFinanceInvoiceDetails;
           this.remarks = this._contractFinanceInvoiceDetails.remarks ? this._contractFinanceInvoiceDetails.remarks.replace(/\n/g, "<br>") : this._contractFinanceInvoiceDetails.remarks;
           this.description = this._contractFinanceInvoiceDetails.invoiceDescription ? this._contractFinanceInvoiceDetails.invoiceDescription.replace(/\n/g, "<br>") : this._contractFinanceInvoiceDetails.invoiceDescription;
           this.createdBy =this.privilegeService.getUserName(res.data.createdBy);
           this.updatedBy =this.privilegeService.getUserName(res.data.updatedBy);
           this.GetContractInvoiceStatus(this.codeListValues.Tbl_Status, this.codeListStatusTypes.INVSTS);
           this.loaderService.hideLoader();
         });
    }
  }
  GetContractInvoiceStatus(enumId: number, type: string) {
    this.SponserService.getCodeListData(enumId,type).subscribe(
        res => {
          if (res.data.length > 0) {//Matching the sponser Details status id with code list
            var filteredStatus = res.data.filter((status: any) => status.id ==  this._contractFinanceInvoiceDetails.statusMappingId);
            if (filteredStatus != undefined && filteredStatus != null && filteredStatus.length > 0) {
                this._contractFinanceInvoiceDetails.statusMappingId = filteredStatus[0].name;
            }  
            if(filteredStatus[0].name == 'Paid' || filteredStatus[0].name == 'Rejected'){
              this._contractFinanceInvoiceDetails.invoiceDueDateClass="";
            }     
          }
    
        }
      );
    }
    downloadAttachemnt(fileName: any, filePath: any) { // download Uploaded Attachemnets 

      this.loaderService.showLoader();
  
      var filedetail = {
        "fileName": fileName,
        "filePath": filePath
      };
      this._fileService.DownloadFile(filedetail).subscribe(
        res => {
          var test = res;
          var blobData = this._fileService.dataURItoBlob(res);
          this._fileService.downloadBase64File(blobData, filedetail.fileName);
          this.loaderService.hideLoader();
        }
      );
      this.loaderService.hidefinalLoader();
    }
    onOptionChange(): void {//Click on Actions button navigate to edit  
       if (this.selectedOption === 'Edit') {
        var contractId= this.ContractId;
        var invoiceId=this.invoiceId
        const data = {
          ContractId:this.ContractId,
          invoiceid:this.invoiceId
        };
        this.editButtonClick.emit(this.invoiceId);
      }
      if(this.selectedOption === 'Delete' && this.invoiceId){
        this.confirmationService.confirm({
          message: 'Are you sure you want to delete this Invoice',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this._financeService.DeleteInvoice(this.invoiceId).subscribe(res => {
            if(res.data > 0)
            {
              this.messageService.add({
              key: 'tc',
              severity: 'info',
              summary: `Invoice is deleted successfully`,
              detail: '',
          });
          this.compareModels();
          this.calcelInvoiceButtonClick.emit();
            }
        });
             },
             reject: () => {
              this.selectedOption = 'Actions';
             },
           });       
      }     

      

  }
  BackToList()
  {
    this.calcelInvoiceButtonClick.emit();
  }
  getFormattedValue(value: number, currencyName: string): string {
    return this.currencyService.getFormattedValue(value, currencyName);
  }
  DueDateChange(invoice: any) {
    if (invoice.paymentDate === null && invoice.dueDate) {
      const currentDate = new Date();
      const dueDate = new Date(invoice.dueDate);
      const timeDifference = dueDate.getTime() - currentDate.getTime();
      const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
  
      return daysDifference;
    } else {
      return 0;
    }
  }

navigateTo(index: number): void {
  if (index >= 0 && index < this.listOfInvoices.length) {
    this.invoiceId=this.listOfInvoices[index].id;
    this.GetContractFinanceInvoiceDetailsById();
    this.currentIndex=index;
  }
}
nextRecord(): void {
  this.navigateTo(this.currentIndex + 1);
}
previousRecord(): void {
  this.navigateTo(this.currentIndex - 1);
}
getCurrentPositionText(): string {
  return `${this.currentIndex + 1} of ${this.listOfInvoices.length}`;
}

_contractInvoice=new InvoiceDetails();
changedContractinvoiceBasicInfo:any;
auditIdentifierDetails:any;
auditEntities:any;
auditOperations:any;
auditModule:any;
_oldContractInvoice: any;
compareModels() {
  this._oldContractInvoice = JSON.parse(JSON.stringify(this._oldContractInvoice));
  this._contractInvoice.isDeleted = true;
  this.changedContractinvoiceBasicInfo = this.auditLogService.compareModels((this._oldContractInvoice),this._contractInvoice,this.auditEntities.ContractInvoices,this.auditIdentifierDetails.DeleteContractInvoice,this.auditOperations.Delete,this.auditModule.FinanceService);
}
}

