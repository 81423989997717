<!DOCTYPE html>
<html lang="en">
<head>
   <title> Engagement View Audit</title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>
<div class="card-pad">
   <div class="fr-flex__container sticky-sub-header">
      <div class="fr-flex__col-8">
         <h3>View Audit</h3>
      </div>
      <div class="fr-flex__col-4">
         <select class="form-control pull-right"  [(ngModel)]="selectedOption"  (change)="onOptionChange()">
            <option disabled selected > Actions </option>
            <option *ngIf="editPrivilege"> Edit </option>
            <option *ngIf="deletePrivilege"> Delete </option>
            </select>
            <a class="btn btn-primary-link backtolist pull-right" (click)="BackToList()">  <i class="pi pi-arrow-left"></i> Back to list </a>
      </div>
   </div>
   <div class="scroll-start-here-sub-tabs"> 
  <div class="fr-flex__container m-t-20">
           <div class="fr-flex__col-4">
              <div class="fr-form__view">
                 <label> Audit Title <em> * </em></label>
                 <span [pTooltip]="engagementAuditDetail?.auditTitle?.length > 15 ? engagementAuditDetail?.auditTitle : ''" tooltipPosition="top"> <em > {{engagementAuditDetail?.auditTitle}} </em></span>
              </div>
           </div>
           <div class="fr-flex__col-4">
              <div class="fr-form__view">
                 <label> Audit No  <em> * </em></label>
                 <span> <em> {{engagementAuditDetail?.auditNumber}}</em></span>
              </div>
           </div>
           <div class="fr-flex__col-4">
              <div class="fr-form__view">
                 <label> Auditor   <em> * </em></label>
                 <span [pTooltip]="engagementAuditDetail?.auditor?.length > 15 ? engagementAuditDetail?.auditor : ''" tooltipPosition="top"> <em> {{engagementAuditDetail?.auditor}}</em></span>
              </div>
           </div>
  </div>
 
      <div class="fr-flex__container">
         <div class="fr-flex__col-4">
            <div class="fr-form__view">
               <label> Auditee SPOC <em> * </em></label>
               <span [pTooltip]="engagementAuditDetail?.auditee?.length > 15 ? engagementAuditDetail?.auditee : ''" tooltipPosition="top"> <em > {{engagementAuditDetail?.auditee ? engagementAuditDetail?.auditee : '--'}} </em></span>
            </div>
         </div>
         <div class="fr-flex__col-8">
            <div class="fr-form__view">
               <label> Attachments <i  pInputText pTooltip="Maximum 3 Attachments allowed." tooltipPosition="top" class="pi pi-info-circle" > </i> </label>
               <div class="display-inline-block" *ngFor="let attachment of engagementAuditDetail?.auditAttachments; let j = index">  
               <a class="form-list-active"> 
               <span [pTooltip]="attachment.fileName.length > 35 ? attachment.fileName : ''" tooltipPosition="top"  (click)="downloadAttachment(attachment)">{{attachment.fileName | truncate:45}} </span> 
               <i (click)="downloadAttachment(attachment)" pInputText pTooltip="Download" tooltipPosition="top" class="pi pi-download"></i>
            </a> 
               
               </div>
   
               <span *ngIf="engagementAuditDetail?.auditAttachments  == null ||  engagementAuditDetail?.auditAttachments?.length==0">  -- </span>
            </div>
         </div>
      </div>

      <div class="fr-flex__container">
         <div class="fr-flex__col-4">
            <div class="fr-form__view">
               <label> Start Date <em> * </em></label>
               <span> <em > {{engagementAuditDetail?.auditStartDate  | date: _dateformart.GridDateFormat }}</em></span>
            </div>
         </div>
         <div class="fr-flex__col-4">
            <div class="fr-form__view">
               <label> End Date  <em> * </em></label>
               <span> <em> {{engagementAuditDetail?.auditEndDate  | date: _dateformart.GridDateFormat }}</em></span>
            </div>
         </div>
         <div class="fr-flex__col-4">
            <div class="fr-form__view">
               <label>Audit Status   <em> * </em></label>
               <span> <em> {{engagementAuditDetail?.auditStatus}}</em></span>
            </div>
         </div>
      </div>



        <div class="fr-flex__container">
           <div class="fr-flex__col-12">
              <div class="fr-form__view">
                 <label> Description </label>
                <span class="full-width" *ngIf="description  == null || description == '' ">--</span> <span class="full-width" [innerHTML]="description" *ngIf="description  != null"> {{description }} </span>
      
              </div>
           </div>
        </div>
 
        <div class="fr-flex__container">
           <div class="fr-flex__col-3">
              <div class="fr-form__view">
                 <label> Added By</label>
                 <span> <em> {{createdBy ? createdBy : '--'}} </em></span>
              </div>
           </div>
           <div class="fr-flex__col-3">
              <div class="fr-form__view">
                 <label> Added Date & Time </label>
                 <span> <em>   {{engagementAuditDetail?.createdDate | date: _dateformart.DateTimeWithZone  }} {{_dateformart.DisplayUTC}}</em></span>
              </div>
           </div>
           <div class="fr-flex__col-3">
              <div class="fr-form__view">
                 <label> Modified By</label>
                 <span  *ngIf="updatedBy !=null"> <em>  {{updatedBy}} </em></span>
                 <span *ngIf="updatedBy == null || updatedBy == undefined || updatedBy == ''"> <em> -- </em></span>
              </div>
           </div>
           <div class="fr-flex__col-3" >
              <div class="fr-form__view">
                 <label> Modified Date & Time </label>
                 <span  *ngIf="updatedBy !=null && updatedBy != undefined && updatedBy != ''"> <em>  {{engagementAuditDetail?.updatedDate | date: _dateformart.DateTimeWithZone}}{{_dateformart.DisplayUTC}} </em></span>
                 <span *ngIf="updatedBy ==null || updatedBy == undefined || updatedBy == ''"> <em> -- </em></span>
                </div>
           </div>
        </div>
         
        <div class="fr-flex__container">
         <div class="fr-flex__col-12">
            <div class="fr-form__view">
               <h3>Observations </h3>
            </div>
         </div>
   </div>
   </div>
   <div class="fr-flex__col-12">
           <div class="m-b-50">
              <p-table class="primeng-table-custom" styleClass="p-datatable-striped" [scrollable]="true" [value]="engagementAuditDetail?.auditObservations">
                 <ng-template pTemplate="header">
                    <tr>
                       <th id="engViewObservationsID">Observation ID <em> * </em> </th>
                       <th id="engViewObservationsGrade">Grade</th>
                       <th id="engViewObservationsNoofFindings">No. of Findings</th>
                       <th id="engViewObservationsStatus">Observation Status</th>
                      
                    </tr>
                 </ng-template>
                 <ng-template pTemplate="body" let-auditObservation let-observationIndex="rowIndex"> 
                    
                    <tr>
                        <td>{{ auditObservation?.observationId ? auditObservation?.observationId : '--' }}</td>
                        <td>{{ auditObservation?.gradeName ? auditObservation?.gradeName : '--' }}</td>
                        <td id="engViewAudiHyperLink"> <span *ngIf="auditObservation?.findingsCount==null">0</span><span *ngIf="auditObservation?.findingsCount!=null" (click)="viewFindingList(observationIndex)"><a class="table-active-link">{{ auditObservation?.findingsCount}}</a></span></td>	
                        <td> <span>{{ auditObservation?.observationStatus ? auditObservation?.observationStatus : '--' }} </span></td>
                        
                    </tr>
                 </ng-template>
                 <ng-template pTemplate="emptymessage">
                  <tr>
                  <td colspan="4" class="text-center"> No Records</td>
                  </tr>
                  </ng-template>
              </p-table>
           </div>
        </div>
     </div>





   

<!-- Dialog Findings List Start -->
<div class="card flex justify-content-center"> 
   <p-dialog appendTo="body" header="Findings List" [(visible)]="displayFindingListPopup" [modal]="true" [style]="{ width: '80vw' }" [draggable]="false" [resizable]="false" [closable]="false">
      <div class="fr-flex__container"> 
      <div class="fr-flex__col-4">
         <div class="fr-form__view">
         
            <label> Observation ID   <em> * </em></label>
            <span> <em>  {{selectedObservationId ? selectedObservationId : '--'}} </em> </span>
           
         </div>
      </div>
   </div>
   <div class="fr-flex__col-12"> 
      <div class="fr-flex__container">
         <div class="fr-flex__col-8">
         </div>
         <div class="fr-flex__col-4 custom-pad-zero m-b-10">
          
         </div>
     </div>
      <p-table class="primeng-table-custom custom-cells__AddFinding small_scroll" styleClass="p-datatable-striped" [scrollable]="true" [value]="findingListDataSource">
          <ng-template pTemplate="header">
              <tr>                         
                  <th id="engViewAuditFindingID">Finding ID</th>
                  <th id="engViewAuditFindingOwner">Finding Owner</th>
                  <th id="engViewAuditTargetDate">Target Date</th>
                  <th id="engViewAuditFindingStatus">Finding Status </th>
                 
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-findingItem let-findingIndex="rowIndex"> 
            
              <tr>  
               <td id="engViewAuditFindingIDHyperLink" (click)="viewFindingDetails(findingIndex)"> <span><a class="table-active-link">{{ findingItem?.findingId}} </a></span></td>
               <td> <span [pTooltip]="findingItem?.findingOwnerName?.length > 30 ? findingItem?.findingOwnerName : ''" tooltipPosition="top">{{ findingItem?.findingOwnerName}} </span></td>
               <td> {{ findingItem?.targetDate  | date: _dateformart.GridDateFormat}} </td>
               <td> {{ findingItem?.findingStatus}}</td>
              
                               
             </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
            <td colspan="4" class="text-center"> No Records</td>
            </tr>
            </ng-template>
          
      </p-table>
     
   </div>
   <ng-template pTemplate="footer">
     
      <button type="button" class="btn btn-secondary pull-right" (click)="cancelFindingListPopup();"> Close </button>
   </ng-template>
   </p-dialog>
   </div>
   <!-- Dialog Findings List End -->
   
   


   

<!-- Dialog View Finding Start -->
<div class="card flex justify-content-center"> 
   <p-dialog appendTo="body" header="View Finding" [(visible)]="displayViewFindingPopup" [modal]="true" [style]="{ width: '80vw' }" [draggable]="false" [resizable]="false" [closable]="false">


        
   
      <div class="fr-flex__container">

         <div class="fr-flex__col-4">
            <div class="fr-form__view">
               <label> Finding ID  <em> * </em></label>
               <span [pTooltip]="finding?.findingId?.length > 15 ? finding?.findingId : ''" tooltipPosition="top"> <em>  {{finding?.findingId}} </em></span>
            </div>
         </div>

         <div class="fr-flex__col-4">
            <div class="fr-form__view">
               <label> Finding Area  <em> * </em></label>
               <span [pTooltip]="finding?.findingArea?.length > 15 ? finding?.findingArea : ''" tooltipPosition="top"> <em>  {{finding?.findingArea}} </em></span>
            </div>
         </div>

         <div class="fr-flex__col-4">
            <div class="fr-form__view">
               <label> Finding Owner  <em> * </em></label>
               <span [pTooltip]="finding?.findingOwnerName?.length > 15 ? finding?.findingOwnerName : ''" tooltipPosition="top"> <em> {{finding?.findingOwnerName}} </em></span>
            </div>
         </div>

     </div>

     <div class="fr-flex__container">
      <div class="fr-flex__col-4">
         <div class="fr-form__view">
            <label> Target Date  <em> * </em></label>
            <span> <em> {{finding?.targetDate | date: _dateformart.GridDateFormat}} </em></span>
         </div>
      </div>

      <div class="fr-flex__col-4">
         <div class="fr-form__view">
            <label> Finding Status  <em> * </em></label>
            <span> <em> {{finding?.findingStatus}} </em></span>
         </div>
      </div>
      <div class="fr-flex__col-4">
         <div class="fr-form__view">
            <label> Attachments   <i  pInputText pTooltip="Maximum 3 Attachments allowed." tooltipPosition="top" class="pi pi-info-circle" > </i> </label>
            <div class="display-inline-block" *ngFor="let attachment of finding?.observationFindingAttachment; let j = index">  
            <a class="form-list-active"> 
            <span [pTooltip]="attachment.fileName.length > 35 ? attachment.fileName : ''" tooltipPosition="top"  (click)="downloadAttachment(attachment)">
               {{attachment.fileName}} </span> 
               <i (click)="downloadAttachment(attachment)" pInputText pTooltip="Download" tooltipPosition="top" class="pi pi-download"></i>
            </a> 
            
            </div>
   
            <span *ngIf="finding?.observationFindingAttachment  == null ||  finding?.observationFindingAttachment?.length==0">  -- </span>
         </div>
      </div>
  </div>

  <div class="fr-flex__container">
      <div class="fr-flex__col-12">
            <div class="fr-form__view">
               <label> Finding Detail <em> * </em></label>
               <span class="full-width" *ngIf="findingDetail  == null">--</span> <span class="full-width" [innerHTML]="findingDetail" *ngIf="findingDetail  != null"> {{findingDetail }} </span>

            </div>
      </div>
     
   
   </div>
   <div class="fr-flex__container">
      <div class="fr-flex__col-12">
         <div class="fr-form__view">
            <label> Supplier Response </label>
           <span class="full-width" *ngIf="engagementResponse  == null">--</span> <span class="full-width" [innerHTML]="engagementResponse" *ngIf="engagementResponse  != null"> {{engagementResponse }} </span>

         </div>
   </div>
   </div>
   <div class="fr-flex__container">
      <div class="fr-flex__col-12">
         <div class="fr-form__view">
            <label>Evidence for Closure </label>
             <span class="full-width" *ngIf="evidenceOfClosure  == null">--</span> <span class="full-width" [innerHTML]="evidenceOfClosure" *ngIf="evidenceOfClosure  != null"> {{evidenceOfClosure }} </span>

         </div>
   </div>

   </div>
   


 
   <div class="fr-flex__container">
      <div class="fr-flex__col-12">
         <div class="fr-form__view">
            <label>Decision of Review </label>
            <span class="full-width" *ngIf="decisionOfReview  == null">--</span> <span class="full-width" [innerHTML]="decisionOfReview" *ngIf="decisionOfReview  != null"> {{decisionOfReview }} </span>

         </div>
   </div>
   </div>
 
   <div class="fr-flex__container">
      <div class="fr-flex__col-3">
         <div class="fr-form__view">
            <label> Added By</label>
            <span> <em> {{findingCreatedBy}} </em></span>
         </div>
      </div>
      <div class="fr-flex__col-3">
         <div class="fr-form__view">
            <label> Added Date & Time </label>
            <span> <em>   {{finding?.createdDate | date: _dateformart.DateTimeWithZone  }} {{_dateformart.DisplayUTC}}</em></span>
         </div>
      </div>
      <div class="fr-flex__col-3">
         <div class="fr-form__view">
            <label> Modified By</label>
            <span  *ngIf="findingUpdatedBy !=null"> <em>  {{findingUpdatedBy}} </em></span>
            <span *ngIf="findingUpdatedBy == null || findingUpdatedBy == undefined || findingUpdatedBy == ''"> <em> -- </em></span>
         </div>
      </div>
      <div class="fr-flex__col-3" >
         <div class="fr-form__view">
            <label> Modified Date & Time </label>
            <span  *ngIf="findingUpdatedBy !=null && findingUpdatedBy != undefined && findingUpdatedBy != ''"> <em>  {{engagementAuditDetail?.updatedDate | date: _dateformart.DateTimeWithZone}}{{_dateformart.DisplayUTC}} </em></span>
            <span *ngIf="findingUpdatedBy ==null || findingUpdatedBy == undefined || findingUpdatedBy == ''"> <em> -- </em></span>
           </div>
      </div>
   </div>

  
   <ng-template pTemplate="footer">
      <button type="button" class="btn btn-secondary pull-right" (click)="cancelViewFindingPopup();"> Close </button>
   </ng-template>
   </p-dialog>
   </div>
   </body>
   </html>
   <!-- Dialog View Finding End -->
   
