import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Dateformat, breadcrumnsCodes } from 'src/app/shared/constants/global.constants';
import { LoaderService } from 'src/app/Loader/loader.service';
import { DatePipe } from '@angular/common';

import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { MenuItem } from 'primeng/api'
import { UserService } from '../../Services/user.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { Privileges } from 'src/app/shared/constants/privileges.constants';
import { TabAndStateService } from 'src/app/shared/TabAndStateService/TabAndState.Service';

@Component({
  selector: 'app-view-user-role-mapping',
  templateUrl: './view-user-role-mapping.component.html',
  styleUrls: ['./view-user-role-mapping.component.css']
})
export class ViewUserRoleMappingComponent {
  user: any;
  userId:number=0;
  _enumBreadcrumb: any;
  _dateformart :any;
  selectedOption: string = 'Actions';
  editPrivilege:boolean=false;
  updatedByUserName:string='';
  router: any;
  currentIndex: number = -1;
  constructor(
    private privilegeService: PrivilegeService,
    private _userService:UserService,
    private _loaderService: LoaderService, 
    private _router:Router,
    private _datePipe: DatePipe,
    private breadcrumbService: BreadcrumbService,private route: ActivatedRoute,private tabAndStateService: TabAndStateService) {
  }
  ngOnInit() {
    this._dateformart=Dateformat;
    this._enumBreadcrumb = breadcrumnsCodes;
    this.generateBreadcrumb();
    this.route.params.subscribe(params => {
      this.userId = params['id']; //log the value of id
      this.userData=this.tabAndStateService.getPaginationDataSource();
      this.currentIndex = this.userData.findIndex((user: { id: any; }) => user.id ==this.userId);
      if(this.userData.length==0)
        {
          this._router.navigate(['/UserRoleMapping']);
        }
    });
    this.GetUserViewData(this.userId);
    this.BindPrivileges();
  }
  BindPrivileges(){
    const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);
    this.editPrivilege = hasPermission(Privileges.AssignRolesUsers);
    }
    remarks:any;
    userData:any
  GetUserViewData(id:number){
    this._loaderService.showLoader();
    this._userService.GetUserViewDetailsById(id).subscribe((res:any)=>{
      if(res.status){
      this.user=res.data;
      this.remarks = this.user.remarks ? this.user.remarks.replace(/\n/g, "<br>") : this.remarks;
      this.updatedByUserName=this.privilegeService.getUserName(res.data.updatedBy);
      }
      this._loaderService.hideLoader();
    });

  }
  generateBreadcrumb() {
    this.breadcrumbService.generateBreadcrumb(this._enumBreadcrumb.VU);
  }
  getBreadcrumbs(): MenuItem[] {
    return this.breadcrumbService.getBreadcrumbs();
  }
  onOptionChange(): void {//Click on Actions button navigate to edit  
    if (this.selectedOption === 'Edit') {
      this._router.navigate(['/EditUserRolesMapping',this.userId])
    }    
    
  }
  BackToList() {
    this._router.navigate(['/UserRoleMapping']);
  }

  navigateTo(index: number): void {
    if (index >= 0 && index < this.userData.length) {
      this.userId=this.userData[index].id
      this.GetUserViewData( this.userId);
      this.currentIndex=index;
    }
  }
  nextRecord(): void {
    this.navigateTo(this.currentIndex + 1);
  }
  previousRecord(): void {
    this.navigateTo(this.currentIndex - 1);
  }
  getCurrentPositionText(): string {
    return `${this.currentIndex + 1} of ${this.userData.length}`;
  }
  
}
