import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { breadcrumnsCodes } from 'src/app/shared/constants/global.constants';
import { LoaderService } from 'src/app/Loader/loader.service';
import { DatePipe } from '@angular/common';

import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { MenuItem } from 'primeng/api';
import { UserService } from '../../Services/user.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { Privileges } from 'src/app/shared/constants/privileges.constants';
import { TabAndStateService } from 'src/app/shared/TabAndStateService/TabAndState.Service';

@Component({
  selector: 'app-user-role-mapping-list',
  templateUrl: './user-role-mapping-list.component.html',
  styleUrls: ['./user-role-mapping-list.component.css']
})

export class UserRoleMappingListComponent {
  usersList: any = [];
  usersListCopy: any = [];
  groupTypeList: any = [];
  groupList : any = [];
  assignedRolesList : any = [];
  _enumBreadcrumb: any;
  editPrivilege:boolean=false;
  filterDetailsModel = new userroleFilterDetails();
  showHideFiltersControls: boolean = false;
  totalRecordCount: number = 0;
  filteredRecordCount: number = -1;
  
  constructor(private privilegeService: PrivilegeService,private _userService:UserService,
    private _loaderService: LoaderService, 
    private _router:Router,
    private _datePipe: DatePipe,
    private breadcrumbService: BreadcrumbService,private tabAndStateService: TabAndStateService) {

  }
  ngOnInit() {
    this._enumBreadcrumb = breadcrumnsCodes;
    this.generateBreadcrumb();
    this.GetUsersList();
    this.BindPrivileges();
  }
  BindPrivileges(){
    const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);
    this.editPrivilege = hasPermission(Privileges.AssignRolesUsers);
    }
  GetUsersList(){
    this._loaderService.showLoader();
    this._userService.GetUserList().subscribe((res:any)=>{
      if(res.status){
      
      this.usersList=res.data;
      this.totalRecordCount=this.usersList.length;
      this.usersListCopy=[...this.usersList];
      this.groupTypeList = this.extractUniqueValues(this.usersList, 'groupType').map(obj => ({ label: obj }));
       let groupdata = this.extractUniqueValues(this.usersList, 'group');
      let assignroledata = this.extractUniqueValues(this.usersList, 'assignedRoles');
      let grouplistdata = groupdata.flatMap((group:any) => group.split(',').map((item:any) => item.trim())) .filter(item => item.length > 0);
      let assignedRolesListdata= assignroledata.flatMap((role:any) => role.split(',').map((item:any) => item.trim())) .filter(item => item.length > 0);
       this.groupList = Array.from(new Set(grouplistdata)).map(obj => ({ label: obj }));
       this.assignedRolesList = Array.from(new Set(assignedRolesListdata)).map(obj => ({ label: obj }));
      this.tabAndStateService.setPaginationDataSource(this.usersList);
      }
      this._loaderService.hideLoader();
    });
  }
  extractUniqueValues(array: any, key: any)
  {
    return Array.from(new Set(array.map((item: any) => item[key])));
  }
ViewRoleMapping(userId:number) 
{
  this._router.navigate(['/ViewUserRolesMapping', userId]);
}
EidtRoleMapping(userId:number)
{
  this._router.navigate(['/EditUserRolesMapping',userId])
}
generateBreadcrumb() {
  this.breadcrumbService.generateBreadcrumb(this._enumBreadcrumb.UL);
}
getBreadcrumbs(): MenuItem[] {
  return this.breadcrumbService.getBreadcrumbs();
}


onfilter()
  {
    this.usersList=[...this.usersListCopy];
    
  if(this.filterDetailsModel.Key!=undefined){
    this.usersList = this.usersList.filter((a:any) => a.firstName.toLowerCase().indexOf(this.filterDetailsModel.Key?.toLowerCase()) !== -1 || a.lastName.toLowerCase().indexOf(this.filterDetailsModel.Key?.toLowerCase()) !== -1|| a.emailId.toLowerCase().indexOf(this.filterDetailsModel.Key?.toLowerCase()) !== -1);
  }
    if(this.filterDetailsModel.GroupType &&  this.filterDetailsModel.GroupType.length > 0) {
      this.usersList = this.usersList.filter((c:any) => this.filterDetailsModel.GroupType.includes(c.groupType));

    }
    if (this.filterDetailsModel.Group && this.filterDetailsModel.Group.length > 0) {
      let uniquegroupIds = new Set();
      let groupData: any[] = [];

      this.usersList.forEach((gr:any) => {
        let GroupArray = gr.group.split(',').map((item: any) => item.trim());
        let hasMatchingService = GroupArray.some((c: any) => this.filterDetailsModel.Group.includes(c));
        if (hasMatchingService && !uniquegroupIds.has(gr.id)) {
          groupData.push(gr);
          uniquegroupIds.add(gr.id);
        }
      });
      this.usersList = groupData;

    }
    if (this.filterDetailsModel.AssignedRoles && this.filterDetailsModel.AssignedRoles.length > 0) {
      let uniqueroleids = new Set();
      let roleData: any[] = [];

      this.usersList.forEach((gr:any) => {
        let roleArray = gr.assignedRoles.split(',').map((item: any) => item.trim());
        let hasMatchingrole = roleArray.some((c: any) => this.filterDetailsModel.AssignedRoles.includes(c));
        if (hasMatchingrole && !uniqueroleids.has(gr.id)) {
          roleData.push(gr);
          uniqueroleids.add(gr.id);
        }
      });
      this.usersList = roleData;

    }
    
    this.filteredRecordCount=this.usersList.length;
    this.tabAndStateService.setPaginationDataSource(this.usersList);
  }
  showfilter()
  {
    this.showHideFiltersControls = !this.showHideFiltersControls;
  }
clearFilters(){
  this.filterDetailsModel={
    Key:'',
    FirstName: '',
    LastName: '',
    Email:'',
  GroupType:  [],
  Group:[],
  AssignedRoles:[]
  
   }
   
  this.usersList=this.usersListCopy;
  this.filteredRecordCount=this.usersList.length;
  this.tabAndStateService.setPaginationDataSource(this.usersList);
}
}
export class userroleFilterDetails{
  Key?:string;
  FirstName?: string;
  LastName?:string;
  Email?:string;
  GroupType?: any;
  Group?:any;
  AssignedRoles:any;

}