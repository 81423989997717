export class ContractKpi
{
    
        id: number=0;
        kpiDetailId?: number=0;
        kpiReportingFrequencyId?: number=0;
        kpiDataCollectionFrequencyId?: number=0;
        kpiReportingFrequencyCode?:string="";
        statusMappingId?: number=0;
        createdBy: number=0;
        createdDate?: Date;
        updatedBy: number=0;
        updatedDate?: Date;
        isDeleted: boolean=false;
        kpiStartDate?: any;
        kpiEndDate?: any;
        hyperCareStartDate?: any;
        hyperCareEndDate?: any;
        description?: string = "";
        KPIHyperCareThresholdDetail?: KPIThreshold[];
        KPISteadyStateThresholdDetail?: KPIThreshold[];
        contractDetailId?:number;
        kpiDataCollection?: ContractKpiDataCollection[];
        serviceName?:string="";
        kpiName?:string="";
        frequencyValueforStartDate?:string="";
        kpiStartDateYear?:any;
        frequencyValueforEndDate?:string="";
        kpiEndDateYear?:any;
        attribute1?: string = "";
        attribute2?: string = "";
        attribute3?: string = "";
        calculation?: string = "";
      }

      export class KPIThreshold {
        Id: number=0;
        percentageValue?:string="";
        mathematicalSymbolId: number=0;
        contractKPIId: number=0;
      }
    
      export class CodeListValues {
        name: string="";
        id: number=0;
        code:string="";
        sub_Code="";
        type:number=0;
      }
      export class MasterKpiData {
        Id: number=0;
        kpiDetailId: number=0;
        KpiName: string="";
        unitOfMeasure: string="";
        unitOfMeasureCode: string="";
        category: string="";
        complianceIndicator: string="";
        calculation: string="";
        attribute1: string="";
        attribute2: string="";
        attribute3: string="";
        formulaeId:any;
        formulaName: string="";
        kpiAttributeTypeId:number=0;
        kpiAttributeTypeCode:string="";
      }
      export class ContractKpiDataCollection {
        id: number=0;
        inputValueType:boolean=false;
        selectedInputValueType:string="";
        dataCollectionDate?: Date;
        calculatedKPI:any;
        remarks?:string="";
        supplierKpiId:number=0;
        attribute1?:string | null;
        attribute2?:string | null;
        attribute3?:string | null;
        health:string="";
        kpiAttributeTypeId:number=0;
        dataCollectionFrequencyValue?:any;
        inputAttributeValueType:number=1;
        contractKpiMitigationActionsList?: ContractKpiMitigationActionsModel[]; 
        mitigationActions: any;
      }
 export class KpiFilterDetails
{
  Status?: any;
  Health?: any;
  Kpi?: any;
  kpiName?:any;
}
export class ContractKpiMitigationActionsModel {
  id: number = 0; 
  action: string = ""; 
  actionOwner: string = "";
  targetDate?: any;
  statusMappingId: number = 0; // Status of the mitigation action
  closureDate?: any; 
}
