import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Dateformat, breadcrumnsCodes } from 'src/app/shared/constants/global.constants';
import { LoaderService } from 'src/app/Loader/loader.service';
import { DatePipe } from '@angular/common';

import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { MenuItem } from 'primeng/api'
import { UserService } from '../../Services/user.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { Privileges } from 'src/app/shared/constants/privileges.constants';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent {
  user: any;
  userId:number=0;
  _enumBreadcrumb: any;
  _dateformart :any;
  selectedOption: string = 'Actions';
  editPrivilege:boolean=false;
  updatedByUserName:string='';
  constructor(
    private privilegeService: PrivilegeService,
    private _userService:UserService,
    private _loaderService: LoaderService, 
    private _router:Router,
    private _datePipe: DatePipe,
    private breadcrumbService: BreadcrumbService,private route: ActivatedRoute) {

  }
  ngOnInit() {
    let userData=this.privilegeService.getUserData();
    this.userId=userData.id;
    this._dateformart=Dateformat;
    this._enumBreadcrumb = breadcrumnsCodes;
    this.generateBreadcrumb();
    this.GetUserViewData(this.userId);
    this.BindPrivileges();
  }
  BindPrivileges(){
    const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);
    this.editPrivilege = hasPermission(Privileges.AssignRolesUsers);
    }
  GetUserViewData(id:number){
    this._loaderService.showLoader();
    this._userService.GetUserViewDetailsById(id).subscribe((res:any)=>{
      if(res.status){
      this.user=res.data;
      this.updatedByUserName=this.privilegeService.getUserName(res.data.updatedBy);
      }
      this._loaderService.hideLoader();
    });
  }
  generateBreadcrumb() {
    this.breadcrumbService.generateBreadcrumb(this._enumBreadcrumb.UP);
  }
  getBreadcrumbs(): MenuItem[] {
    return this.breadcrumbService.getBreadcrumbs();
  }
}
