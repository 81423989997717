import { Component,EventEmitter,Input,OnInit,  Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { } from 'src/app/Governance/meetings/Engagements/Services/governance.service';
import { LoaderService } from 'src/app/Loader/loader.service';
import { } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { Dateformat, breadcrumnsCodes, FileExtension } from 'src/app/shared/constants/global.constants';
import { EngagementAuditService } from '../../Services/engagement-audit-service.service';
import { DatePipe } from '@angular/common';
import FileSaver from 'file-saver';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { Privileges } from 'src/app/shared/constants/privileges.constants';
import { ExcelService } from 'src/app/Reusable/excel.service';
import { EngagementService } from 'src/app/Engagement/Services/engagement.service';
import * as xlsx from 'xlsx';
import { MasterSupplierService } from 'src/app/MasterData/supplier/Services/master.supplier.service';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';
@Component({
  selector: 'app-eng-list-audit',
  templateUrl: './eng-list-audit.component.html',
  styleUrls: ['./eng-list-audit.component.css']
})
export class EngListAuditComponent implements  OnInit {
  codelist: any[] = [] ; 
  @Output() addButtonClick = new EventEmitter<void>();
  @Output() editButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() viewButtonClick: EventEmitter<any> = new EventEmitter<any>();

  
  @Output() childData: EventEmitter<any> = new EventEmitter<any>();
 
  @Input() engagementId: any;
  @Input() auditId: any;

  totalRecordCount: number = 0;
  filteredRecordCount: number = -1;
  selectedEngagementAudit:any;
  _engagementAuditListData: any[] = []
  _dateformart :any;
  _enumBreadcrumb :any;
  isChecked: boolean = true;
  createPrivilege:boolean=false;
  editPrivilege:boolean=false;
  viewPrivilege:boolean=false;
  ExportPrivilage:boolean=false;
  constructor(private privilegeService: PrivilegeService,private engagementAuditService: EngagementAuditService,private router:Router,private loaderService: LoaderService,private datePipe: DatePipe,private excelService: ExcelService,private route: ActivatedRoute,  private engagementService: EngagementService,private _mastersupplierService: MasterSupplierService,private masterDataService: SponserService){
  }

  ngOnInit() {
    this._enumBreadcrumb=breadcrumnsCodes;
    this._dateformart=Dateformat;
    this.loaderService.showLoader();
   this.route.params.subscribe(params => {
    this.engagementId = params['id']; //log the value of id
    this.GetAllEditAuditeeSPOCs();
  });
  
  this.GetEngagementDetailsById();
    this.getEngagementAuditsByEngagementId();
    this.loaderService.hidefinalLoader();// hide after 4 seconds even get any error
    this.BindPrivileges();
  }

  engagementdata:any;
  selectedEngagementName:any;
  engagementName:any
  GetEngagementDetailsById() {
    this.loaderService.showLoader();
    if (this.engagementId != undefined && this.engagementId != null) {
      this.engagementService.ViewEngagementById(this.engagementId)
        .subscribe(res => {
          this.loaderService.hideLoader();
          this.engagementdata = res.data;
          this.selectedEngagementName=this.engagementdata.engagementName;
          this.engagementName=this.engagementdata.engagementName;
        });
      };
    }
  supplierDetails:any;
  BindPrivileges(){
    const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);

     this.createPrivilege = hasPermission(Privileges.AddSupplierAudit);
this.editPrivilege = hasPermission(Privileges.EditSupplierAudit);
this.viewPrivilege = hasPermission(Privileges.ViewSupplierAudit);
this.ExportPrivilage= hasPermission(Privileges.ExportEngagementAudit);

    }

  getEngagementAuditsByEngagementId(){
    this.engagementAuditService.getengagementAuditsByEngagementId(this.engagementId).subscribe(
      response=>{
       this._engagementAuditListData=response.data;
       this.totalRecordCount= this._engagementAuditListData.length;
       if(this.engagementdata!=undefined && this.engagementdata !=null)
       {
          var engagementName = this.selectedEngagementName;
          if(this.engagementdata.stakeholderSuppliers!= undefined && this.engagementdata.stakeholderSuppliers.length>0){
          this._engagementAuditListData.forEach(audit => {
            var supplierContactName = this.engagementdata.stakeholderSuppliers.filter((t: { id: any; })=>t.id==audit.auditeeSPOC);
            if(supplierContactName != undefined && supplierContactName.length>0){
              audit.auditee = supplierContactName[0].contactName+" ("+engagementName+")";
            }
          }); 
         }
       }
        this.loaderService.hideLoader();
      }
    );
  }

addEngagementAudit()
{
    this.addButtonClick.emit();
}
editEngagementAudit(Id:number){
  var engagementId= this.engagementId;
  var auditId=Id.toString();
  const data = {
    engagementId:this.engagementId,
    auditId:Id
  };
  this.editButtonClick.emit(data);
}

viewEngagementAudit(Id:number){
  var engagementId= this.engagementId;
  var auditId=Id.toString();
  const data = {
    engagementId:this.engagementId,
    auditId:Id
  };
  this.viewButtonClick.emit(data);
}

CheckboxEnabled() {
  if (this.selectedEngagementAudit.length > 0) {
    this.isChecked = false;
  }
  else {
    this.isChecked = true;
  }
}
selectedRecordsToExpotToExcel: any[] = [];
async ExportEngagementAudits() {
  const exportaudits: any[] = [];
  const observations: any[] = [];
  const findings: any[] = [];

  for (const audit of this.selectedEngagementAudit) {
      const auditDetails = await this.getEngagementAuditById(audit.id);

      exportaudits.push({
          'Audit Number': audit.auditNumber,
          'Audit Title': audit.auditTitle,
          'Start Date': audit.auditStartDate ? this.convertDateFormat(audit.auditStartDate) : '',
          'End Date': audit.auditEndDate ? this.convertDateFormat(audit.auditEndDate) : '',
          'Audit Status': audit.auditStatus,
          'Description': auditDetails.description || '',
          'Attachments': auditDetails.attachments.join(', '),
          'Observations': audit.observationsCount || 0,
          'Auditor': audit.auditor,
          'Auditee SPOC': this.getSPOCName(audit.auditeeSPOC),
          'Added By': this.privilegeService.getUserName(audit.createdBy),
          'Added Date & Time': audit.createdDate ? this.excelService.convertDateTimeFormat(audit.createdDate) : '',
          'Modified By': audit.updatedBy ? this.privilegeService.getUserName(audit.updatedBy) : '',
          'Modified Date & Time' : audit.updatedBy != null ? this.excelService.convertDateTimeFormat(audit.updatedDate) : '',

      });

      if (auditDetails.auditObservations) {
          for (const observation of auditDetails.auditObservations) {
              observations.push({
                  'Audit Number': audit.auditNumber,
                  'Audit Title': audit.auditTitle,
                  'Observation Id': observation.observationId,
                  'Grade': observation.grade,
                  'No Of Findings': observation.findingsCount,
                  'Observation Status': observation.observationStatus,
                  
                });
              if (observation.findings) {
                  for (const finding of observation.findings) {
                      findings.push({
                          'Audit Number': audit.auditNumber,
                          'Audit Title': audit.auditTitle,
                          'Observation Id': observation.observationId,
                          'Finding Id': finding.findingId,
                          'Finding Owner': this.getSPOCName(finding.findingOwnerId),
                          'Target Date': finding.findingTargetDate ? this.convertDateFormat(finding.findingTargetDate) : '',
                          'Finding Status': finding.findingStatus,
                          'Finding Area': finding.findingArea,
                          'Finding Details': finding.findingDetail,
                          'Supplier Response': finding.supplierResponse,
                          'Evidence for Closure': finding.evidanceOfClouser,
                          'Decision of Review': finding.decisionOfReview,
                          'Attachments': finding.findingAttachments,
                         });
                  }
              }
          }
      }
  }

  const workbook = xlsx.utils.book_new();

  if (exportaudits.length > 0) {
      const worksheetAudits = xlsx.utils.json_to_sheet(exportaudits);
      xlsx.utils.book_append_sheet(workbook, worksheetAudits, 'Audits');
  }

  if (observations.length > 0) {
      const worksheetObservations = xlsx.utils.json_to_sheet(observations);
      xlsx.utils.book_append_sheet(workbook, worksheetObservations, 'Observations');
  }

  if (findings.length > 0) {
      const worksheetFindings = xlsx.utils.json_to_sheet(findings);
      xlsx.utils.book_append_sheet(workbook, worksheetFindings, 'Findings');
  }

  const fileName = `${this.engagementName} - AuditsExport - ${this.convertDateFormat(Date())}.xlsx`;
  xlsx.writeFile(workbook, fileName);
}

convertDateFormat(inputDateTime: string) {
  const date = new Date(inputDateTime);
  const formattedDate = this.datePipe.transform(date, this._dateformart.GridDateFormat);
  return formattedDate;
}

async getEngagementAuditById(auditId: number) {
  return new Promise<any>((resolve, reject) => {
    this.loaderService.showLoader();
    this.engagementAuditService.getEngagementAuditById(auditId).subscribe(
      (response) => {
        // Map the necessary data for each observation and finding
        const auditObservations = response.data.auditObservations.map((observation: any) => ({
          observationId: observation.observationId,
          grade: observation.gradeName,
          findingsCount: observation.findingsCount,
          observationStatus: observation.observationStatus,
          findings: observation.engagementAuditObservationFindingList.map((finding: any) => ({
            findingId: finding.findingId,
            findingArea: finding.findingArea,
            findingDetail: finding.findingDetail,
            findingOwnerId:finding.findingOwnerId,
            findingOwnerName: finding.findingOwnerName,
            findingStatus: finding.findingStatus,
            findingTargetDate:finding.targetDate,
            evidanceOfClouser:finding.evidenceOfClosure,  
            decisionOfReview:finding.decisionOfReview,
            findingAttachments: finding.observationFindingAttachment
              .filter((attachment: { isDeleted: boolean }) => !attachment.isDeleted)
              .map((attachment: { fileName: string }) => attachment.fileName)
              .join(', '),  
            supplierResponse:finding.engagementResponse,
            findingAddedBy:finding.createdBy,
            findingCreatedDate:finding.createdDate,
            findingUpdatedBy:finding.updatedBy,
            findingUpdatedDate:finding.updatedDate
          }))
        }));

        resolve({
          description: response.data.description,
          attachments: response.data.auditAttachments.map((attachment: any) => attachment.fileName),
          id: auditId,
          auditObservations: auditObservations
        });

        this.loaderService.hideLoader();
      },
      (error) => {
        reject(error);
        this.loaderService.hideLoader();
      }
    );
  });
}
auditAuditeeSPOCData:any[]=[];
engagementData:any;
GetEditSupplierContacts(Id:number){
  this._mastersupplierService.getSupplierContactsDataById(Id)
  .subscribe(res=>{
    const Data = res.data;
    if (this.engagementData != undefined &&  Data != undefined) {
      this.auditAuditeeSPOCData = Data.map((obj: {contactName: any; id: any; }) => ({ name: `${obj.contactName} ( ${this.engagementData.engagementName} )`,id:obj.id }));
    }
  })
}
GetAllEditAuditeeSPOCs() {
  this.engagementService.GetEngagementById(this.engagementId)
  .subscribe(res => {  
    this.engagementData = res.data;  
    this.GetEditSupplierContacts(res.data.supplierId);
  });
}
getSPOCName(id: number): string | undefined {
  const found = this.auditAuditeeSPOCData.find(item => item.id === id);
  return found ? found.name : undefined;
}

}
