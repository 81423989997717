import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { } from 'src/app/Contracts/Services/contract.service';
import { LoaderService } from 'src/app/Loader/loader.service';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { breadcrumnsCodes, StatusCodes } from 'src/app/shared/constants/global.constants';
import { EmbeddingContext } from 'amazon-quicksight-embedding-sdk/dist';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { } from '@angular/common/http';
import { AuthCommonService } from 'src/app/shared/Authentication/auth.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { } from 'primeng/paginator';
import { EngagementService } from 'src/app/Engagement/Services/engagement.service';
import { Privileges } from 'src/app/shared/constants/privileges.constants';
import { DashboardService } from 'src/app/Dashboard/Services/dashboard.service';
import { FinanceService } from 'src/app/Finance/Services/invoice.service';
import { environment } from 'src/environments/environment';
import { } from 'aws-sdk/clients/chime';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';

@Component({
  selector: 'app-financedashboard',
  templateUrl: './financedashboard.component.html',
  styleUrls: ['./financedashboard.component.css']
})
export class FinancedashboardComponent {

  dashboardForm: FormGroup;
  _enumBreadcrumb: any;
  dashboardApplyClicked: boolean = false;
  engagementdata: any;
  engagements: any;
  engagementId: any;
  statusCodes:any;
  dashboardIdsDetails:any;
  ViewFunctionsFinanceDashboard:boolean=false;
  ViewEngagementFinanceDashboard:boolean=false;
  listOfFunctions: any;
  selectedDashboardToView:string="";
  DashboardToViewDataSource: any[] = [];
  IsDashboardTillDate:boolean=false;
  SelectedFunction:string="";
  IsFunctionDisable:boolean=false;
  constructor(
    private breadcrumbService: BreadcrumbService,
    private _engagementSerice: EngagementService,
    private fb: FormBuilder,
    private messageService: MessageService,
    private loaderService: LoaderService,
    private authService: AuthCommonService,
    private privilegeService: PrivilegeService,
    private _dashboaredService: DashboardService,
    private _financeInvoiceService:FinanceService,
    private _masterDataService:SponserService) {
    this.dashboardForm = this.fb.group({
      dashboardEngagement: ['', [Validators.required]],
      dashboardFunction: ['', [Validators.required]],
      dashboardToVeiw: ['', [Validators.required]],
      dashboadFicalYear: ['', [Validators.required]],
    });
  }
  placeholder:any;
  queryParams:any;
  contractqueryParams:any;
  engagementRecord:any
  contractRecord:any
  engagementdashboard:any;
  engagementsIds :any=0;
  ngOnInit() {
  let userData=this.privilegeService.getUserData();
  this.engagementsIds=userData.engagements;
  this.getAllEngagements();
  this._enumBreadcrumb = breadcrumnsCodes;
  this.statusCodes=StatusCodes;
  this.breadcrumbService.generateBreadcrumb(this._enumBreadcrumb.FDB);
  this.BindPrivileges();
  this.GetCurrencyData();
  }
 IsShowButton:boolean=true;
  BindPrivileges()
  {
    const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);
    this.ViewFunctionsFinanceDashboard=hasPermission(Privileges.ViewFunctionsFinanceDashboard);
    this.ViewEngagementFinanceDashboard=hasPermission(Privileges.ViewEngagementFinanceDashboard);
    if(!this.ViewFunctionsFinanceDashboard && !this.ViewEngagementFinanceDashboard )
    {
      this.IsShowButton=false;
    }
  }
  getBreadcrumbs(): MenuItem[] {
    return this.breadcrumbService.getBreadcrumbs();
  }
  getAllEngagements() {
    this._engagementSerice.GetEngagementsByGroupTypes(this.engagementsIds)
      .subscribe(res => {
        this.engagementdata = res.data;
        this.engagements = this.engagementdata.map((engagement: { id: any, engagementName: any }) => ({ label: engagement.engagementName, value: engagement.id }));    
      });
  }
  functionsData: any;
  BindEngagementContractsGroups(){
    this.loaderService.showLoader();
    this._engagementSerice.GetAllEngagementContractGroupsById(this.engagementId).subscribe(
      res => {
        if (res.data) {
          this.loaderService.hideLoader();
          this.functionsData=res.data;
          this.listOfFunctions = this.functionsData.map((_function: { id: any, engagementGroupName: any }) => ({ label: _function.engagementGroupName, value: _function.id }));   
          if(this.ViewEngagementFinanceDashboard)
          {
          this.listOfFunctions.unshift({ label: this.statusCodes.NA, value: 'Not Applicable' }); 
          }
        }
      }
    );
  }
  BindDashboardToViewDataSource()
  {
  this.DashboardToViewDataSource= [
    { label: 'Till Date Values', value: 'tillDate' },
    { label: 'Fiscal Year Values', value: 'fiscalYear' }
  ];
}
BindFiscalYear()
{
this.fiscalYearList=[];
this.fiscalYearList= [
  { label: 'Not Applicable', value: 'NotApplicable' }
];
}
dashboardId:any;
viewFinanceDashboard=true;
onDashboardApplyClick(){
  this.loaderService.showLoader();
  this.dashboardApplyClicked = true;
  if(this.dashboardForm.valid)
  {
    if(this.SelectedFunction=='Not Applicable')
    {
    if(this.IsDashboardTillDate){
      this.dashboardId=environment?._financeEngagementTillDateDashboardId;
    }
    else{
      this.dashboardId=environment?._financeEngagementFiscalYearDashboardId;
    }
  }
  else{
    if(this.IsDashboardTillDate){
     
      this.dashboardId=environment?._financeTillDateDashboardId;
    }
    else{
      this.dashboardId=environment?._financeFiscalYearDashboardId;
    }
  }
    this.GetReportingServicesEmbedURL();
  }
  else{
    this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Please select required information', detail: ''});
  }
}
engagementFiscalYearTableData:any;
GetEngagementFiscalYearTableData(){
  this._financeInvoiceService.GetAllFiscalYearDetailsByEngagementId(this.engagementId).subscribe(
    res => {
      if (res.data) {
        this.engagementFiscalYearTableData=res.data;
        this.GetFiscalYearList();
      }
});
}
GetFiscalYearList(){
  this.fiscalYearList=[];
  const distinctFiscalYears = Array.from(new Set(this.engagementFiscalYearTableData.map((item:any) => item.fiscalYear)));
  distinctFiscalYears.sort((a:any, b:any) => {
    const [aStartYear, aEndYear] = a.split(' - ').map((part:any) => parseInt(part.split(' ')[1]));
    const [bStartYear, bEndYear] = b.split(' - ').map((part:any) => parseInt(part.split(' ')[1]));
    return aStartYear - bStartYear || aEndYear - bEndYear;
  });
  this.fiscalYearList = distinctFiscalYears.map(fiscalYear => ({
    label: fiscalYear,
    value: fiscalYear
  }));
}
reportingURL:any;
    public async GetReportingServicesEmbedURL() {
      let model = {
        "dashboardID": this.dashboardId,
        "token":this.authService.getCookieValue('LOGIN_TOKEN'),
        "privilege": [
          {
            "MenuName": "string",
            "isAllDataAvailable": true,
            "DataValues": []
          }
        ]
      };
    this._dashboaredService.getEmbedUrlData(model).subscribe(
      async (res: any) => {
          this.reportingURL=res.data;
          await this.FinanceEngagementDashboard();
    });
    }
    URL:string="";
    public async FinanceEngagementDashboard() {
      let containerDiv = document.getElementById("DashboardContainer") || '';
      if (containerDiv && containerDiv instanceof HTMLElement) {
        containerDiv.innerHTML = ''; // Clear the container
    }
      const embeddingContext: EmbeddingContext = await createEmbeddingContext();
     let encodedFiscalYear = encodeURIComponent(this.selectedFiscalYear);
      if(this.SelectedFunction=='Not Applicable')
      {
        
      if(this.IsDashboardTillDate){
        this.URL=this.reportingURL + '&#p.EngagementId=' + this.engagementId + '&p.Currency=' + this.baseCurrency;
      }
      else{
        this.URL=this.reportingURL + '&#p.EngagementId=' + this.engagementId + '&p.FiscalYear=' + encodedFiscalYear + '&p.Currency=' + this.baseCurrency;
      }
    }
    else{
        if(this.IsDashboardTillDate){
          this.URL=this.reportingURL + '&#p.ContractGroupId=' + this.SelectedFunction + '&p.Currency=' + this.baseCurrency;
        }
        else{
          this.URL=this.reportingURL + '&#p.ContractGroupId=' + this.SelectedFunction + '&p.FiscalYear=' + encodedFiscalYear + '&p.Currency=' + this.baseCurrency;
        }
      }
      const frameOptions = {
        url: this.URL,
        container: containerDiv,
        height: "800px",
        resizeHeightOnSizeChangedEvent: true,
        onChange: (changeEvent:any, metadata:any) => {
          switch (changeEvent.eventName) {
              case 'FRAME_LOADED': {
                   this.loaderService.hideLoader();
                  break;
              }
          }
      },
    }
     embeddingContext.embedDashboard(frameOptions);
     this.loaderService.hideLoader();
    }
  
    onFinDashboardEngChange(event: any) {
    this.engagementId = event.value;
    this.GetBaseCurrency(this.engagementId);
    if(this.ViewFunctionsFinanceDashboard)
    {
      this.SelectedFunction="";
      this.selectedFiscalYear="";
      this.fiscalYearList=[];
      this.DashboardToViewDataSource=[];
      this.BindEngagementContractsGroups();
    }
    else{
      this.listOfFunctions=[];
      this.listOfFunctions.push({ label: this.statusCodes.NA, value: 'NotApplicable' });
       this.SelectedFunction=this.listOfFunctions[0].value;
      this.IsFunctionDisable=true;
      this.BindDashboardToViewDataSource();
      this.selectedDashboardToView="";
      this.selectedFiscalYear="";
    }
  }

  baseCurrency:any;
  GetBaseCurrency(engagementId:any){
    const selectedEngagementData=this.engagementdata.filter((aa:any)=>aa.id==engagementId);
    this.baseCurrency=this.currenciesData.filter((aa:any)=>aa.name==selectedEngagementData[0].defaultCurrency)[0].code;
  }


  onFinDashboardFunEngChange(event: any){
    this.BindDashboardToViewDataSource();
    this.selectedDashboardToView="";
    this.selectedFiscalYear="";
 }
 fiscalYearList:any[]=[];
selectedFiscalYear:string="";
onDashboardToViewChange(data:any){
   this.selectedFiscalYear="";
  if(data.value=='tillDate'){
    this.IsDashboardTillDate=true;
    this.fiscalYearList=[];
    this.fiscalYearList.push({ label: 'Not Applicable', value: 'NotApplicable' });
    this.selectedFiscalYear=this.fiscalYearList[0].value;
  }
  else{
    this.IsDashboardTillDate=false;
    this.GetEngagementFiscalYearTableData();
  }
}

currenciesData:any;
GetCurrencyData(){
 
  this._masterDataService.getCodeListData(4,'').subscribe(
    (data:any)=>{
      this.currenciesData= data.data;
 }
  );
}
}
