import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContractService } from 'src/app/Contracts/Services/contract.service';
import { LoaderService } from 'src/app/Loader/loader.service';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';
import { AssociateService } from '../Services/associate.service';
import { DatePipe } from '@angular/common';
import { SponsorContact } from 'src/app/MasterData/supplier/Models/master-supplier-profile';
import { AuditIdentifier, AuditLogEntities, AuditLogModule, AuditOperations, Dateformat } from 'src/app/shared/constants/global.constants';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { Privileges } from 'src/app/shared/constants/privileges.constants';
import { EngagementService } from 'src/app/Engagement/Services/engagement.service';
import { TabAndStateService } from 'src/app/shared/TabAndStateService/TabAndState.Service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TeamAssociateDetails } from '../Models/Associate';
import { AuditLogService } from 'src/app/shared/ModelComparison/audit.log.service';

@Component({
  selector: 'app-associate-view',
  templateUrl: './associate-view.component.html',
  styleUrls: ['./associate-view.component.css']
})
export class AssociateViewComponent {

  @Input({ required: true }) FromType: number = 0;
  @Input() entityId:number | undefined;
  @Output() editTeamButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() viewTeamButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelTeamButtonClick = new EventEmitter<void>();
  @Input({ required: true }) associateId: any;
  _dateformart :any;
  sponsorContactsDataSource: SponsorContact[] = [];
  associateViewData:any;
  engagementId:any;
  sponsorContanctName:any
  selectedOption: string = 'Actions';
  contractDetailId: any;
  createdBy: any;
  updatedBy: any;
  listOfAssociates:any;
  currentIndex: number = -1; 
  editPrivilege:boolean=false;
  deletePrivilege:boolean=false;
  constructor(
    private loaderService: LoaderService, 
    private privilegeService: PrivilegeService,
    private masterDataService: SponserService,
    private contractService: ContractService,
    private _service:AssociateService,
    private engagementService: EngagementService,
    private datepipe: DatePipe,
    private confirmationService: ConfirmationService,private messageService: MessageService, private auditLogService: AuditLogService, private tabAndStateService: TabAndStateService,)
    {
    
    }
 
  ngOnInit() {
     this._dateformart=Dateformat;
 if(this.FromType==1)//Contract
    {
      this.contractDetailId=this.entityId;
      this.GetContractDetails(this.contractDetailId);
      this.GetAssocaiteTeamViewData();
    }
    else//Supplier
    {
      this.engagementId=this.entityId;
      this.GetAssocaiteTeamViewData();
    }
    this.BindPrivileges();
    this.listOfAssociates=this.tabAndStateService.getTeamsData();
    this.currentIndex = this.listOfAssociates.findIndex((a: { id: any; }) => a.id ==this.associateId);
    this.auditIdentifierDetails=AuditIdentifier; 
    this.auditEntities=AuditLogEntities;
    this.auditOperations=AuditOperations;
    this.auditModule=AuditLogModule;
  }
  BindPrivileges(){
    const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);
    const editPrivilege = this.FromType === 1 ? Privileges.ContractEditAssociate : Privileges.SupplierEditAssociate;
    this.editPrivilege = hasPermission(editPrivilege);
    const deletePrivilege = this.FromType === 1 ? Privileges.DeleteContractAssociate : Privileges.DeleteEngagementAssociate;
    this.deletePrivilege = hasPermission(deletePrivilege);
        }
  GetContractDetails(contractId:any){
    this.contractService.GetContractDtailsById(contractId).subscribe(
      res=>{
       var ContractDetails = res.data;
       this.engagementId=ContractDetails.supplierId;
      }
    );
  }

  address:any;
  offboardingReason:any;
  SponsorAssetDetails:any;
  notes:any;
  GetAssocaiteTeamViewData(){
    this.loaderService.showLoader(); 
    this._service.GetAssociateViewDetailsById(this.associateId).subscribe(res => {
      if(res.status)
      {
        this.associateViewData=res.data;
        this._oldTeamsAssociate=res.data;
        this._teamsAssociate=this.associateViewData;
        this.SponsorAssetDetails = this.associateViewData.sponsorAssetDetails ? this.associateViewData.sponsorAssetDetails.replace(/\n/g, "<br>") : this.associateViewData.sponsorAssetDetails;
        this.address = this.associateViewData.address ? this.associateViewData.address.replace(/\n/g, "<br>") : this.associateViewData.address;
        this.offboardingReason = this.associateViewData.offboardingReason ? this.associateViewData.offboardingReason.replace(/\n/g, "<br>") : this.associateViewData.offboardingReason;
        this.notes = this.associateViewData.notes ? this.associateViewData.notes.replace(/\n/g, "<br>") : this.associateViewData.notes;
        this.createdBy =this.privilegeService.getUserName(res.data.createdBy);
        this.updatedBy=this.privilegeService.getUserName(res.data.updatedBy);
        this. GetEngagementDetailsById();
        this.loaderService.hideLoader(); 
      }
    });
  }

GetEngagementDetailsById() {
 
  this.engagementService.GetEngagementById(this.engagementId)
    .subscribe(res => {    
      this.getSponserData(res.data.sponsorId);
     
     
    });
}

getSponserData(sponserId:any)
{
  this.masterDataService.getSponserData(sponserId).subscribe(res=>{ 
    this.sponsorContactsDataSource= res.data.sponserContacts;
    
    var sponsorContanctData = this.sponsorContactsDataSource.filter(t=>t.id== this.associateViewData.sponsorContactId);
    if(sponsorContanctData!=undefined && sponsorContanctData.length>0){
    this.sponsorContanctName =sponsorContanctData[0].name;
    }
    this.loaderService.hideLoader();
  });
}  

onOptionChange(): void {//Click on Actions button navigate to edit  
  if (this.selectedOption === 'Edit') {
    const data = {
      associateId:this.associateId,
    };
    this.editTeamButtonClick.emit(data);
  } 
  if(this.selectedOption === 'Delete' && this.associateId){
    this.confirmationService.confirm({
      message: `Are you sure you want to delete this Associate?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this._service.DeleteAssociate(this.associateId).subscribe(res => {
        if(res.data > 0)
        {
          this.messageService.add({
          key: 'tc',
          severity: 'info',
          summary: `Associate is deleted successfully`,
          detail: '',
      });
      this.compareModels();
      this.cancelTeamButtonClick.emit();
        }
    });
         },
         reject: () => {
          this.selectedOption = 'Actions';
         },
       });       
  }     
}
BackToList()
{
  this.cancelTeamButtonClick.emit();
}

navigateTo(index: number): void {
  if (index >= 0 && index < this.listOfAssociates.length) {
    this.associateId=this.listOfAssociates[index].id;
    this.GetAssocaiteTeamViewData();
    this.currentIndex=index;
  }
}
nextRecord(): void {
  this.navigateTo(this.currentIndex + 1);
}
previousRecord(): void {
  this.navigateTo(this.currentIndex - 1);
}
getCurrentPositionText(): string {
  return `${this.currentIndex + 1} of ${this.listOfAssociates.length}`;
}
_teamsAssociate=new TeamAssociateDetails();
changedTeamsInfo:any;
auditIdentifierDetails:any;
auditEntities:any;
auditOperations:any;
auditModule:any;
_oldTeamsAssociate: any;
compareModels() {
  this._oldTeamsAssociate = JSON.parse(JSON.stringify(this._oldTeamsAssociate));
  this._teamsAssociate.isDeleted = true;
  if(this.FromType==1){
  this.changedTeamsInfo = this.auditLogService.compareModels((this._oldTeamsAssociate),this._teamsAssociate,this.auditEntities.ContractAssociates,this.auditIdentifierDetails.DeleteContractAssociate,this.auditOperations.Delete,this.auditModule.FinanceService);
  }
  else{
    this.changedTeamsInfo = this.auditLogService.compareModels((this._oldTeamsAssociate),this._teamsAssociate,this.auditEntities.EngagementAssociates,this.auditIdentifierDetails.DeleteEngagementAssociate,this.auditOperations.Delete,this.auditModule.FinanceService);
    }
}

}
