import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { ContractService } from 'src/app/Contracts/Services/contract.service';
import { LoaderService } from 'src/app/Loader/loader.service';
import { MasterSupplierService } from 'src/app/MasterData/supplier/Services/master.supplier.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { AuthCommonService } from 'src/app/shared/Authentication/auth.service';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { breadcrumnsCodes } from 'src/app/shared/constants/global.constants';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit
{

  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];

  currentUser: any;
  selectedRoute: string = "";
  _enumBreadcrumb: any;
  ActiveContractsCount: number = 0;
  ExpiredContractsCount: number = 0;
  ActiveSupplierCount: number = 0;
  supplierIds :any;
  _groupTypeCode:any
  constructor(private privilegeService: PrivilegeService,private router: Router, private breadcrumbService: BreadcrumbService,private authService: AuthCommonService, private _contractService: ContractService, private SupplierSericeApi: MasterSupplierService,private loaderService: LoaderService) { }
  
  ngOnInit(): void
  {
    var userData=this.privilegeService.getUserData();
    this.supplierIds=userData.suppliers;
    this._groupTypeCode=userData.groupTypeCode;
    this._enumBreadcrumb = breadcrumnsCodes;
    this.generateBreadcrumb();
    this.router.events.subscribe(event =>
    {
      if (event instanceof NavigationEnd)
      {
        this.selectedRoute = event.url;
      }
    });
  //  this.loaderService.showLoader();
    
    if(this.supplierIds!=null && this.supplierIds.length>0 )
    {
      this.GetContractList();
      this.getAllSuppliers(this.supplierIds);
    }
    
  }

  GetContractList()
  {
    
    this._contractService.GetAllContracts(0,this.supplierIds).subscribe(
      res =>
      {
        if (res && res.status && res.data && res.data.length > 0)
        {
          const data = res?.data || [];
          this.ActiveContractsCount = data.filter((obj: any) => obj.statusName?.toLowerCase() === 'active').length;
          this.ExpiredContractsCount = data.filter((obj: any) => obj.statusName?.toLowerCase() === 'expired').length;
        }
      }

    );

  }
  getAllSuppliers(supplierIds:number[])
  {
    this.SupplierSericeApi.GetSuppliersByGroups(this._groupTypeCode,supplierIds)
      .subscribe(res =>
      {
        this.loaderService.hideLoader();
        if (res && res.status && res.data && res.data.length > 0)
        {
          this.ActiveSupplierCount = res.data.filter((c: any) => c.isDeleted === false).length;
        }
      });
  }

  generateBreadcrumb()
  {
    this.breadcrumbService.generateBreadcrumb(this._enumBreadcrumb.HC);
  }
  getBreadcrumbs(): MenuItem[]
  {
    return this.breadcrumbService.getBreadcrumbs();
  }

  supplier_navigation() {
    this.router.navigate(['./Suppliers']);
  }
  contract_navigation() {
    this.router.navigate(['./contracts']);
  }
  admin_navigation() {
    this.router.navigate(['./admin']);
  }
  dashboard_navigation() {
    this.router.navigate(['./dashboard']);
  } 

// added for login

  reloadPage(): void {
   window.location.reload();
  }


}


