import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { LocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root',
})
export class AuthCommonService {

  private loggedIn = false;
  private authUserToken!: string;


  

  readonly _adminCenterAppURl?:string="";
  readonly _kontractProAppURl?:string="";
  readonly _loginPortalAppURl?:string="";
  readonly _commonAuthApiBaseURl?:string="";
  readonly _commonUAMApiBaseURl?:string="";
  readonly _commonServiceApiBaseURl?:string="";
  readonly _domainName?: string ="";


  public userDetails: any;
  public userEmail!: string;

  constructor(private locationStrategy: LocationStrategy,private router: Router,private http: HttpClient, private cookieService:CookieService) {
    this._adminCenterAppURl=environment?._adminCenterAppURl;
    this._kontractProAppURl=environment?._kontractProAppURl;
    this._loginPortalAppURl=environment?._loginPortalAppURl;
    this._commonAuthApiBaseURl=environment?._commonAuthApiBaseURl;
    this._commonUAMApiBaseURl=environment?._commonUAMApiBaseURl;
    this._commonServiceApiBaseURl=environment?._commonServiceApiBaseURl;
    this._domainName=environment?._appDomainName;

  }

  // method to verify user loggedIn or not
  public async getLoggedInUserInfo(): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      this.userEmail = '';
      this.userEmail = this.getCookieValue('EMAIL');
       //.userEmail = (this.userEmail.length==0?"admin@kp.com":this.userEmail); //  for local development and testing
      if (this.userEmail != '') {
        await this.getUserInfo(this.userEmail).then(response => {
          resolve(true);
        })
          .catch(err => {
            //console.log(err);
            resolve(false);
          });;
      }
    });
  }

  public async getUserInfo(userEmailId: any): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      // Your synchronous code or asynchronous code within a Promise
      // Resolve the Promise when the task is complete
      this.userDetails = null;
      if (userEmailId != '') {
        this.getUserIdByEmail(userEmailId).subscribe((res) => {
          if (res != null && res != "") {
            this.getUserDetailsById(res).subscribe(response => {
              if (response != null && response != "") {
                this.userDetails = response;
                //console.log("LoggedIn User Details 2 - " + JSON.stringify(this.userDetails));
                resolve(true);
              }
              else {
                resolve(false);
              }
            },
              (error) => {
                // Handle error here
                console.log('An error occurred:', error);
                resolve(false);
              });
          }
          else {
            resolve(false);
          }
        },
          (error) => {
            // Handle error here
            console.log('An error occurred:', error);
            resolve(false);
          });
      }
      else {
        this.logOutUser();  // if email id not exists on the cookies
        resolve(false); // update value as true for local development and testing
      }


    });
  }

  public async isTokenValid(token: any): Promise<boolean> {
    return new Promise<any>(async (resolve, reject) => {
      // Your synchronous code or asynchronous code within a Promise
      // Resolve the Promise when the task is complete


       //resolve(true); //  for local development and testing


      //  for deployment
      if (token.length > 0) {

        this.getValidateUserToken(token).subscribe((res) => {
          if (res != null && res != "" && res) {
            resolve(res);
          }
          else {
            this.logOutUser();
            resolve(false);
          }
        },
          (error) => {
            // Handle error here
            console.log('An error occurred:', error);
            this.logOutUser();
            resolve(false);
          });
      }
      else {
        this.logOutUser();
        resolve(false);
      }

    });
  }

  public async logOutCommonAuthUser(): Promise<boolean> {
    return new Promise<any>(async (resolve, reject) => {
      // Your synchronous code or asynchronous code within a Promise
      // Resolve the Promise when the task is complete
      // var response =false;
      var authToken = this.getCookieValue('LOGIN_TOKEN');
      var isTokenValid = await this.isTokenValid(authToken);

      if (isTokenValid) {
        this.logOutUserCommonAuthService(authToken).subscribe((res) => {
          if (res != null && res != "" && res) {
            resolve(res);
          }
          else {
            resolve(false); // update value as true for local development and testing
          }
        },
          (error) => {
            // Handle error here
            console.log('An error occurred:', error);
            // this.logOutUser();
            resolve(false); // update value as true for local development and testing
          });
      }
      else {
        // this.logOutUser();
        resolve(false); // update value as true for local development and testing
      }
    });
  }
  public async isUserLoggedIn(): Promise<boolean> {
    return new Promise<any>(async (resolve, reject) => {
      // Your synchronous code or asynchronous code within a Promise
      // Resolve the Promise when the task is complete
      // If you want to extract a specific cookie value, you can parse the string and access the desired cookie

      this.loggedIn = false;
      var authToken = this.getCookieValue('LOGIN_TOKEN');
      var isTokenValid = await this.isTokenValid(authToken);

      if (isTokenValid) {
        await this.getLoggedInUserInfo().then(response => {
          this.loggedIn = true;
          resolve(this.loggedIn);
        })
          .catch(err => {
            //console.log(err);
            this.loggedIn = false;
            this.logOutUser();
            resolve(this.loggedIn);
          });;
        // User is logged in and token is valid, allow access
      } else {
        this.loggedIn = false;
        this.logOutUser();
        resolve(this.loggedIn);  // User is not logged in or token is invalid, prevent access
        // Return true if token is valid, false otherwise
      }
      // Set cookie with SameSite attribute
      this.setCookieWithSameSiteAndSecureFlag();
    });
  }
  setCookieWithSameSiteAndSecureFlag() {
    // Set cookie with SameSite and Secure flags using ngx-cookie-service
    // this.cookieService.set('cookieName', 'cookieValue', { sameSite: 'Strict', secure: true });
    this.cookieService.set('cookieName', 'cookieValue', 5000, '/', this._domainName, true, 'Strict');
  }

  getToken(): string {
    // Return the stored authToken
    return this.authUserToken;
  }

  // method to get the browser cookie values by passing the specific key name
  getCookieValue(keyName: string): string {
    const keys = document.cookie.split('; '); // Split the cookies string into individual cookies
    for (const key of keys) {
      const [name, value] = key.split('='); // Split each cookie into name-value pairs
      if (name === keyName) {
        return decodeURIComponent(value); // Decode the cookie value if needed
      }
    }
    return ''; // Return an empty string if the cookie doesn't exist
  }

  reloadPage(): void {

    // Prevent going back to the previous page

    // const currentUrl = this.router.url;
    //this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    //  this.router.navigateByUrl(currentUrl);
    //});

    //  this.locationStrategy.onPopState(() => {
    //  this.locationStrategy.pushState(null, '', '/','');
    // });

    //this.locationStrategy.pushState(null, '', '/','');
    //history.pushState(null, document.title, window.location.href);

    // window.location.reload();

    var loginURL = this._loginPortalAppURl + '?redirect=' + this._kontractProAppURl;
    window.location.href = loginURL;  // Redirect to your login application URL
  }


  // logOut current user and remove the storage values
  public async logOutUser(): Promise<boolean> {


   // this.logOutCommonAuthUser();

    this.cookieService.delete("LOGIN_TOKEN", "/",  this._domainName);
    this.cookieService.delete("EMAIL", "/",  this._domainName, true, "None");;

    this.cookieService.deleteAll();
    // this.reloadPage();
    var loginURL = this._loginPortalAppURl + '?redirect=' + this._kontractProAppURl;
    window.location.href = loginURL;  // Redirect to your login application URL

    return true;
  }
  // get user Id by passing email
  public getUserIdByEmail(emailId: any): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get<any>(this._commonUAMApiBaseURl + '/system/users/email-related-id?email=' + emailId, httpOptions);
  }
  // get user details by passing user Id
  public getUserDetailsById(id: any): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get<any>(this._commonUAMApiBaseURl + '/system/users/' + id, httpOptions);
  }
  // post method
  public postDataRequest(data: any): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<any>(this._commonUAMApiBaseURl + '/notifications', data, httpOptions);
  }


  public getValidateUserToken(token: any): Observable<any> {
    // const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json',"Authorization": token}) };
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', "Authorization": "Bearer " + token }) };
    //var validateUserTokenAPIurl ="https://csapidev.freyrkontractpro.com/common-auth/token-validation";
    var validateUserTokenAPIurl = this._commonServiceApiBaseURl + "common-auth/token-validation";
    return this.http.get<any>(validateUserTokenAPIurl, httpOptions);
  }

  public logOutUserCommonAuthService(token: any): Observable<any> {
    // const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json',"Authorization": token}) };
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', "Authorization": "Bearer " + token }) };
    //var userLoggedOutCommonAuthServiceAPIurl ="https://csapidev.freyrkontractpro.com/common-auth/logout";
    var userLoggedOutCommonAuthServiceAPIurl = this._commonServiceApiBaseURl + "common-auth/logout";
    return this.http.delete<any>(userLoggedOutCommonAuthServiceAPIurl, httpOptions);
  }


  private getCookieValue1(keyName: string): any {
    const keys = document.cookie.split(';'); // Get all the cookies as a single string
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i].trim();

      if (key.startsWith(keyName + '=')) {
        return key.substring(keyName.length + 1);
      }
    }
    return null; // Cookie not found
  }
  getAuthenticationToken(): string {
    var authenticationToken = this.getCookieValue('LOGIN_TOKEN');
    return authenticationToken;
  }


  public getAdminCenterUsers(): Observable<any> {
    //let token='eyJraWQiOiJwTTFScFlMN1wvXC9kdEZiekhUbjY0WHFSK0w2WmliQmpXdFwvd0FLMDUyVkFjPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI4YmQ2NjdjYy03YzM2LTQ4ZjUtODRhMy02NzJjMTFmNzIyYzciLCJjb2duaXRvOmdyb3VwcyI6WyJ0ZW5hbnRfMCJdLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV9rVGRrOXR4cGwiLCJjbGllbnRfaWQiOiIxMmlwMjZtdHY0bjl1NDY5OWh1cDRsajBjZCIsIm9yaWdpbl9qdGkiOiJlNGNhYmJlOS03OTYxLTRiMGItYTA4NC1iZTNlNzg4OTg3NTIiLCJldmVudF9pZCI6ImE4YTBkY2EzLTNiMDQtNDUxYi1hZTQxLTY5NzBkOWM3N2Y3NyIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE3MzQwODM4NTIsImV4cCI6MTczNDA4NzQ1MiwiaWF0IjoxNzM0MDgzODUyLCJqdGkiOiJkYTYxNmY3YS05NzkwLTQ0YmMtYmU0MC0wMGYxZDY3YTY1Y2UiLCJ1c2VybmFtZSI6ImFkbWluJTQwa3AuY29tIn0.RBxKz_SH_kO9U7MXOGWPyxEds2EbY4Dn3eVz_W7-SlAfeJIS-vzS315nfk5wBcaT9RZhYE2tSnogEW3lkaeF-AJKsWPJ0cFDciPEIrMRdps7iUIHmUJuwoHSnFo3gpLX21BIgcenwHYpKfvswMPez5DVZeLKJBnXkik59nxZWmM5bG_8RvCk57F9P-5yypREOFKVmQuZWERC4DnDAQdtdka880tO-LtCFn9jwRudcjJbWLsBWpY7b9cAU78y8z9DT5ND5FLwG-q7aO0w6m3QhYVoLLgBDpaPPzrkNa9UB-UP9V2KwiIYuqVOyxxSNAIRDFM6aOUqGNFbxUztdyVVGw';
    var token = this.getCookieValue('LOGIN_TOKEN');

    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json',"Authorization": "Bearer " + token}) };
    //var this._commonUAMApiBaseURl="https://devapi.freyrkontractpro.com/Api/Gateway/common-uam";
    return this.http.get<any>(this._commonUAMApiBaseURl + '/users/list?pageSize=1000', httpOptions);
  }
 
}
