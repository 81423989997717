import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, booleanAttribute } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContractMeetingAbsentees, ContractMeetingAttendees, ContractMeetingDetails, ContractMeetingOccurrenceAttachments, ContractMeetingOccurrences, ContractAddMeetingOccurenceActions, ContractAddMultipleOccurrences, ContractMeetingRecOccurrences ,ContactMeetingContacts} from 'src/app/Governance/meetings/Contracts/Modals/ContractMeeting';
import { ConfirmationService, MessageService, MenuItem } from 'primeng/api';
import { Dateformat, FileExtension, breadcrumnsCodes, CodeList, CodeListStatusTypes, AuditIdentifier, AuditLogEntities, AuditOperations, AuditLogModule } from 'src/app/shared/constants/global.constants';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';

import { DatePipe, formatDate } from '@angular/common';
import { ContractMeetingService } from '../../Services/MeetingServices';
import { LoaderService } from 'src/app/Loader/loader.service';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { ChecknulloremptyComponent } from 'src/app/shared/checknullorempty/checknullorempty.component';
import { Observable } from 'rxjs';
import { GovernanceService } from '../../../Engagements/Services/governance.service';
import { AssociateService } from 'src/app/Team/teams/Services/associate.service';
import { AuditLogService } from 'src/app/shared/ModelComparison/audit.log.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { MasterSupplierService } from 'src/app/MasterData/supplier/Services/master.supplier.service';
import { EngagementService } from 'src/app/Engagement/Services/engagement.service';
import { FileUploadService } from 'src/app/Engagement/Services/fileupload.service';
import { Privileges } from 'src/app/shared/constants/privileges.constants';

@Component({
  selector: 'app-con-edit-meeting-rec-yes',
  templateUrl: './con-edit-meeting-rec-yes.component.html',
  styleUrls: ['./con-edit-meeting-rec-yes.component.css']
})


export class ConEditMeetingRecYesComponent {
  isSaveBtnDisable = false;
  @Input() ContractId: any;
  @Input() meetingId: any;
  @Input() EngagementId: any;
  @Input() supplierName: any;
  @Output() cancelMeetingButtonClick = new EventEmitter<void>();
  @Output() saveMeetingButtonClick = new EventEmitter<void>();
  @Output() viewMeetingButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('fileInput') fileInput: ElementRef | undefined;
  contractMeetingForm: FormGroup;
  _dateformart: any;
  _enumBreadcrumb: any;
  codeListValues: any;
  codeListStatusTypes: any;
  submitted: boolean = false;
  supplierData: any;
  contactData: any;
  contractData: any;
  sponserData: any;
  sponserId: number = 0;
  sponsorContactData: any;
  maxFileSize: any;
  timeStamp: any;
  currentFileSize: any;
  allowedExtensions: any;
  newAttachment: any;
  attendeesValues: any = '';
  absenteesValues: any = '';
  attendees: string[] = [];
  absentees: string[] = [];
  _addActionsFieldsMandatory: boolean = false;
  formData = new FormData();
  multiMeetingFileData: any[] = [];
  uploadedFileName: any;
  organizerOptions: any[] = [];
  meetingStatus: any[] = [];
  uploadedMeetingFile: any;
  addMeetingOccurenceDate: Date | undefined;
  addMeetingActionsData: any[] = [];
  uploadedFiles: File[] = [];
  _allAttendees: string[] = [];
  displayAddOccurrencesPopUP: boolean = false;
  addOccurrenceDialog: string = '';
  isAddEditOccurrenceFileUploadButtonDisabled:boolean = false;
  contractAddoccurrencesPopUpForm: FormGroup;
  _contractMeetingOccurrenceAttachments = new ContractMeetingOccurrenceAttachments();
  _contractMeetingAttendees = new ContractMeetingAttendees();
  _contractMeetingAbsentees = new ContractMeetingAbsentees();
  _contractMeetingOccurrences = new ContractMeetingOccurrences();

  _contractAddOccurrences = new ContractAddMultipleOccurrences();
  contractMeetingDetails = new ContractMeetingDetails();
  _contractMeetingOccurrenceAttachmentsDetails: ContractMeetingOccurrenceAttachments[] = [];
  _contractAddMultipleOccurrences: ContractAddMultipleOccurrences[] = [];
  _contractMeetingAttendeesDetails: ContractMeetingAttendees[] = [];
  _contractMeetingAbsenteesDetails: ContractMeetingAbsentees[] = [];
  MeetingCreatedByUserName:string='';
  MeetingUpdatedByUserName:string='';
  _finalAttendees:any;
  contactFormGroup:FormGroup;
  contactName: string = '';
  deletePrivilege:boolean=false;
  constructor(
    private governanceService: GovernanceService,
    private contractmeetingService: ContractMeetingService,
    private messageService: MessageService,
    private masterDataService: SponserService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private _fileService: FileUploadService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private loaderService: LoaderService,
    private breadcrumbService: BreadcrumbService,
    private checkNullOrEmptyValuesService: ChecknulloremptyComponent,
    private _associateService:AssociateService,
    private auditLogService: AuditLogService,
    private privilegeService:PrivilegeService,
    private _mastersupplierService: MasterSupplierService,
    private engagementService: EngagementService 
  ) {
    this.contractAddoccurrencesPopUpForm = this.fb.group({
      addOcurrneceMeetingAbsentees: [''],
      addOcurrneceMeetingAttendees: [''],
      addOcurrneceMeetingOccurenceDate: [null, [Validators.required]],
      addOcurrneceMeetingFileSource: [''], addOcurrneceMeetingFileName: '',
      addOcurrneceMeetingAttachments: [''],
      addOcurrneceMeetingAgenda: [''],
      addOcurrneceMeetingNotes: [''],
      addOcurrneceMeetingDecisions: [''],
    });

    this.contractMeetingForm = this.fb.group({
      addMeetingTitle: ['', [Validators.required, Validators.maxLength(50), Validators.minLength(3), this.noOnlySpacesValidator]],
      addMeetingOrganizer: ['', [Validators.required]],
      addMeetingRecurring: [{ value: '', disabled: true }],
      addMeetingAgenda: ['', [Validators.required, Validators.maxLength(300)]],
    });
    this.contactFormGroup=this.fb.group({
      contractName:['',[Validators.required,Validators.maxLength(30),Validators.minLength(3),Validators.pattern(/^[A-Za-z- ]{3,30}$/)]],
    });
  }
  contractMeetingId: any;
  auditIdentifierDetails:any;
  auditEntities:any;
  auditOperations:any;
  auditModule:any;

  ngOnInit() {
    this._dateformart = Dateformat;
    this._enumBreadcrumb = breadcrumnsCodes;
    this.codeListValues = CodeList;
    this.codeListStatusTypes = CodeListStatusTypes
    this.contractMeetingId = this.meetingId;
   

  
    this.GetMeetingStatus(this.codeListValues.Tbl_Status, this.codeListStatusTypes.MASTS);
    this.GetEngagementDetailsById();
    this.GetContractMeetingById(this.contractMeetingId);
    this.auditIdentifierDetails=AuditIdentifier; 
    this.auditEntities=AuditLogEntities;
    this.auditOperations=AuditOperations;
    this.auditModule=AuditLogModule;
   this.BindPrivileges();
  }
  BindPrivileges(){
    const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);
  this.deletePrivilege=hasPermission(Privileges.DeleteContractMeeting);
    }
  options: any[] = [
    { name: 'Yes', value: true }, 
    { name: 'No', value: false },
  ];
  meetingContacts:any;
  _oldContractMeetingDetails:any;
  GetContractMeetingById(contractMeetingId: any) {
    
    this.loaderService.showLoader();
    this.governanceService.getContractMeetingsById(contractMeetingId)
      .subscribe(res => {
      if(res!=null){
     
          this.contractMeetingDetails.Title=res.data.title;
          this.contractMeetingDetails.Organizer=res.data.organizer;
          this.contractMeetingDetails.Agenda=res.data.agenda;
          this.contractMeetingDetails.Recurring=res.data.recurring;
          this.contractMeetingDetails.ContractId=res.data.contractId;
          this.MeetingCreatedByUserName=this.privilegeService.getUserName(res.data.createdBy);
          this.MeetingUpdatedByUserName=this.privilegeService.getUserName(res.data.updatedBy);
          this.meetingContacts=res.data.contractMeetingOccurrences[0]?.contractMeetingContacts;
          //this.contractMeetingDetails.CreatedBy=res.data.createdBy;
          this.contractMeetingDetails.CreatedDate=res.data.createdDate;
          //this.contractMeetingDetails.UpdatedBy=res.data.updatedBy;
          this.contractMeetingDetails.UpdatedDate=res.data.updatedDate;
          this.contractMeetingDetails.ContractMeetingOccurrences = res.data.contractMeetingOccurrences.map((e: any) => {
           return this.convertKeysToUppercase(e);
           
       });
          this._oldContractMeetingDetails=JSON.stringify(this.contractMeetingDetails);
          this.oldContractMeetingData=JSON.stringify(this.contractMeetingDetails);
          this.contractMeetingDetailBeforeUpdate=JSON.stringify(this.contractMeetingDetails);
      }
      });
      
    }


  GetMeetingStatus(enumId: number, type: string) { //Get Meeting Status's from code list for the Meetings
    this.masterDataService.getCodeListData(enumId, type).subscribe(
      res => {
        if (res.data.length > 0) {
          this.meetingStatus = res.data;
        }
      }
    );
  };
  AddNewOccurrences() { // click on Add Occurrence Button
    this.isAddEditOccurrenceFileUploadButtonDisabled=false;
    this._contractMeetingOccurrenceAttachments = new ContractMeetingOccurrenceAttachments();
    this._contractAddOccurrences = new ContractAddMultipleOccurrences();
    this._contractMeetingAttendees = new ContractMeetingAttendees();
    this._contractMeetingAbsentees = new ContractMeetingAbsentees();
    this._contractMeetingOccurrences = new ContractMeetingOccurrences();
    this.addMeetingActionsData = [];
    this._contractMeetingOccurrenceAttachmentsDetails = [];
    this._contractAddMultipleOccurrences = [];
    this._contractMeetingAbsenteesDetails = [];
    this._contractMeetingAttendeesDetails = [];
    this.uploadedFiles = [];
    this.submitted = false;
    this.displayAddOccurrencesPopUP = true;
    this.addOccurrenceDialog = "Add Occurrence"
    this.contractAddoccurrencesPopUpForm.reset();
    this.isEditTrue = false;
  
    this._individualContractMeetingOccurDetailsBeforeAddOrEdit = JSON.stringify({   
      contractMeetingOccurrences: this._contractMeetingOccurrences,
            contractMeetingAbsentees: this._contractMeetingAbsentees.Absentees,
            contractMeetingAttendees: this._contractMeetingAttendees.Attendees,
            addMeetingActionsData: this.addMeetingActionsData,
            uploadAttachement: this.uploadedFiles
    });
  }
  updateOrganizerOptions() {
    if (this.engagementData !== undefined && this.contactData !== undefined) {
        const supplierOptions = this.contactData.map((contact: { contactName: any; }) => `${contact.contactName} ( ${this.supplierName} )`);
      if (!this.organizerOptions) {
        this.organizerOptions = [];
      }
      this.organizerOptions = this.organizerOptions.concat(supplierOptions);
      this.attendees = supplierOptions;
      this.absentees = supplierOptions;
      this._allAttendees = supplierOptions;
    }
    if (this.sponserData !== undefined && this.sponsorContactData !== undefined) {
      const sponsorOptions = this.sponsorContactData.map((contact: { name: any; }) => `${contact.name} ( ${this.sponserData.name} )`);
      if (!this.organizerOptions) {
        this.organizerOptions = [];
      }
      if(this.organizerOptions!=undefined){
        this.organizerOptions = this.organizerOptions.concat(sponsorOptions);
        }
        if(this.attendees!=undefined){
          this.attendees=this.attendees.concat(sponsorOptions);
        }
        if(this.absentees!=undefined){
          this.absentees=this.absentees.concat(sponsorOptions);
        }
      this._allAttendees=this._allAttendees.concat(sponsorOptions);
    }
     //added by vittal for associates
     if (this.associateData !== undefined && this.associateData !== null) {
      const associateOptions = this.associateData.map((contact: { associateName: any; }) => `${contact.associateName} ( Associate )`);
      if (!this.organizerOptions) {
        this.organizerOptions = [];
      }
      if(this.organizerOptions!=undefined){
      this.organizerOptions = this.organizerOptions.concat(associateOptions);
      }
      if(this.attendees!=undefined){
      this.attendees=this.attendees.concat(associateOptions);
      }
      if(this.absentees!=undefined){
      this.absentees=this.absentees.concat(associateOptions);
      }
      if(this._allAttendees!=undefined){
      this._allAttendees=this._allAttendees.concat(associateOptions);
      }
    }

     //added by vittal for Engagement MeetingContacts on 22-09-2024
     if (this.meetingContacts !== undefined && this.meetingContacts !== undefined) {
      const meetingContactsOptions = this.meetingContacts.map((contact: { contactName: any; }) => `${contact.contactName}`);
      if (!this.organizerOptions) {
        this.organizerOptions = [];
      }
      if(this.organizerOptions!=undefined){
        this.organizerOptions = this.organizerOptions.concat(meetingContactsOptions);
        
        }
        if(this.attendees!=undefined){
          this.attendees=this.attendees.concat(meetingContactsOptions);
        }
        if(this.absentees!=undefined){
          this.absentees=this.absentees.concat(meetingContactsOptions);
        }
      this._allAttendees=this._allAttendees.concat(meetingContactsOptions);
    }

    let attendeesObjects: { name: string; code: number }[] = [];
    if (this._allAttendees.length > 0) {
        attendeesObjects = this._allAttendees.map((attendee, index) => ({
            name: attendee,
            code: index + 1, 
        }));
  }
  this._finalAttendees=attendeesObjects;
  this._allAttendees = attendeesObjects.map((attendee) => attendee.name);
  this.loaderService.hideLoader();
  this.loaderService.hidefinalLoader();
  }

  engagementData:any;
  GetEngagementDetailsById() {
 
    this.engagementService.GetEngagementById(this.EngagementId)
      .subscribe(res => {    
        this.engagementData = res.data;
        this.sponserId=this.engagementData.sponsorId;
        this.GetSupplierContacts(res.data.supplierId);
        this.GetSponserDetailsById();
      });
  }
  GetSupplierContacts(Id:number){
 
    this._mastersupplierService.getSupplierContactsDataById(Id)
    .subscribe(res=>{
      this.contactData = res.data;
    })
  }
  GetSponserDetailsById() {
    this.masterDataService.getSponserData(this.sponserId)
      .subscribe(res => {
        this.sponserData = res.data.sponserInfo;
        this.sponsorContactData = res.data.sponserContacts;
        this.GetSupplierTeamAssociatesList(this.EngagementId,2)
       // this.GetTeamAssociatesList(this.ContractId,1)
      })
  }

  supplierassociateData:any;
  supplierTeams:any;
  GetSupplierTeamAssociatesList(conractIdOrSupplierId:any,teamType:number){
   
    this._associateService.GetTeamAssociatesList(conractIdOrSupplierId,teamType).subscribe((res:any)=>{
      if(res.status){
        this.GetTeamAssociatesList(this.ContractId,1)
      this.supplierassociateData=res.data;
      this.supplierassociateData = this.supplierassociateData?.filter((t:any) => t.contractName === 'Not Applicable');
      this.supplierTeams = this.supplierassociateData.map((contact: { associateName: any; }) => `${contact.associateName} ( Associate )`);
      }
    });
  }

  associateData:any;
  GetTeamAssociatesList(conractIdOrSupplierId:any,teamType:number){
   
    this._associateService.GetTeamAssociatesList(conractIdOrSupplierId,teamType).subscribe((res:any)=>{
      if(res.status){
      this.associateData=res.data;
      this.updateOrganizerOptions();
      }
    });
  }


  noOnlySpacesValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value && control.value.trim().length === 0) {
      return { onlySpaces: true };
    }
    return null;
  }
  onFileChange(event: any) {
    const files: FileList = event.target.files;
    if (files.length <= 1) {
      this.maxFileSize = this._fileService._fileSize; // 20 MB (value Bytes in binary)
      if (files.length > 0) {
        this.currentFileSize = files[0].size;
        this.uploadedFileName = files[0].name;
        this.allowedExtensions = [FileExtension.DOC, FileExtension.DOCX, FileExtension.PDF, FileExtension.XLS, FileExtension.XLSX,FileExtension.PPT,FileExtension.PPTX];
        var uploadedExtension = this.uploadedFileName.split('.').pop().trim().toLowerCase();
      }
      // Check if the uploaded file's extension is allowed
      if (this.allowedExtensions.includes(uploadedExtension)) {
        if (this.currentFileSize <= this.maxFileSize) {
          if (this.uploadedFiles.length + files.length <= 3) {
            for (let i = 0; i < files.length; i++) {
              this.uploadedFiles.push(files[i]);
              this.contractAddoccurrencesPopUpForm.patchValue({
                addMeetingAttachments: files[0].name,
              });
              this.uploadedMeetingFile = files[0];
              this.multiMeetingFileData.push(this.uploadedMeetingFile);
              this.timeStamp = "";
              var utcDateTimeNow = new Date().toISOString();
              this.timeStamp = formatDate(utcDateTimeNow, this._dateformart.FiletimeStamp, this._dateformart.TimeStampZone);
              //this.formData.append("files[]", file, fileName);
              this.formData.append("file", this.uploadedMeetingFile, "Governance/ContractMeeting/" + this.timeStamp + "_" + this.uploadedMeetingFile.name);
              this._contractMeetingOccurrenceAttachments.FileName = this.uploadedMeetingFile.name;
              this._contractMeetingOccurrenceAttachments.FilePath = "Governance/ContractMeeting/" + this.timeStamp + "_" + this._contractMeetingOccurrenceAttachments.FileName;
              this.newAttachment = {
                FileName: this._contractMeetingOccurrenceAttachments.FileName,
                filePath: this._contractMeetingOccurrenceAttachments.FilePath
              };
              this._contractMeetingOccurrenceAttachmentsDetails.push(this.newAttachment);
            }
          } else {
            this.contractAddoccurrencesPopUpForm.patchValue({
              addMeetingFileName: '',
              addMeetingFileSource: ''

            });

            this.messageService.add({ key: 'tc', severity: 'error', summary: 'Maximum 3 files allowed.', detail: '' });
          }
        }
        else {
          this.contractAddoccurrencesPopUpForm.patchValue({
            addMeetingFileName: '',
            addMeetingFileSource: ''
          });
          this.messageService.add({ key: 'tc', severity: 'warn', summary: 'File Size limited to 20 Mb', detail: '' });
        }
      }
      else {
        this.contractAddoccurrencesPopUpForm.patchValue({
          addMeetingFileName: '',
          addMeetingFileSource: ''
        });
        this.messageService.add({ key: 'tc', severity: 'error', summary: 'Invalid file type. Only .pdf, .xls, .xlsx, .doc, .docx files are allowed.', detail: '' });
      }

    }
    else {
      this.contractAddoccurrencesPopUpForm.patchValue({
        addMeetingFileName: '',
        addMeetingFileSource: ''
      });
      this.messageService.add({ key: 'tc', severity: 'error', summary: 'Select and upload 1 file at a time.', detail: '' });

    }
    if(this.uploadedFiles.length >= 3)
    {
     this.isAddEditOccurrenceFileUploadButtonDisabled = true;
    }else{
     this.isAddEditOccurrenceFileUploadButtonDisabled = false;
    }
  }
  deleteUploadedFiles: any[] = [];
  removeFile(file: File) { //Attachement file remove cross button click
    this._contractAddOccurrences.ContractMeetingAddOccurrenceOccurrenceAttachments ??= [];
    const index = this.uploadedFiles.indexOf(file);
    if (index !== -1) {
      this.uploadedFiles.splice(index, 1);
      if(this.fileInput){
      this.fileInput.nativeElement.value = ''; 
      }
      const attachmentIndex = this._contractMeetingOccurrenceAttachmentsDetails.findIndex(attachment => attachment.FileName === file.name);
      if (attachmentIndex !== -1) {
        this._contractMeetingOccurrenceAttachmentsDetails.splice(attachmentIndex, 1);
      }
      this._contractAddOccurrences.ContractMeetingAddOccurrenceOccurrenceAttachments = this._contractAddOccurrences.ContractMeetingAddOccurrenceOccurrenceAttachments.map(e => {
        return this.convertKeysToUppercase(e);
      });

      var isOldOccurrenceAttachement = this._contractAddOccurrences.ContractMeetingAddOccurrenceOccurrenceAttachments.some(item => item.hasOwnProperty('Id'));
      if (isOldOccurrenceAttachement) {
        const editOccurremceAttachment = this._contractAddOccurrences.ContractMeetingAddOccurrenceOccurrenceAttachments.findIndex(s => s.FileName == file.name && s.Id != 0);
        if (editOccurremceAttachment !== -1) {
          var filedetails = {
            "fileName": this._contractAddOccurrences.ContractMeetingAddOccurrenceOccurrenceAttachments[editOccurremceAttachment].FileName,
            "filePath": this._contractAddOccurrences.ContractMeetingAddOccurrenceOccurrenceAttachments[editOccurremceAttachment].FilePath
          };
          if (filedetails.fileName != "" && filedetails.filePath != "") {
            this.deleteUploadedFiles.push(filedetails);
            this._contractAddOccurrences.ContractMeetingAddOccurrenceOccurrenceAttachments.splice(editOccurremceAttachment, 1)
          }
        }
      }
      else {
        if (this._contractAddOccurrences.ContractMeetingAddOccurrenceOccurrenceAttachments) {
          const index = this._contractAddOccurrences.ContractMeetingAddOccurrenceOccurrenceAttachments.findIndex(attachment => attachment.FileName === file.name);
          if (index !== -1) {
            this._contractAddOccurrences.ContractMeetingAddOccurrenceOccurrenceAttachments.splice(index, 1)
          }
        }
      }

    }
    if(this.uploadedFiles.length >= 3)
 {
  this.isAddEditOccurrenceFileUploadButtonDisabled = true;
 }else{
  this.isAddEditOccurrenceFileUploadButtonDisabled = false;
 }
  }


  //Delete Add Action Meeting item form Add Action table
  deleteAction(currentActionIndex: number) {
    this.confirmationService.confirm({
      header: 'Confirm',
      message: 'Are you sure you want to delete this action?',
      accept: () => {

        this.addMeetingActionsData.splice(currentActionIndex, 1);
        this.messageService.add({ severity: 'success', summary: 'Meeting Action Deleted', detail: '',});
      }
    });
  }
  AddMeetingActions() { //Click on Add Action button new row will be generated on top of the grid
    this.addMeetingActionsData.unshift({
      Id: 0,
      ActionName: '',
      Owner: "",
      TargetDate: null,
      StatusMappingId: '',
      ClosureDate: null,
      Remarks: '',
      SupplierMeetingOccurrenceId: 0,
      IsDeleted: false
    });
  }
  isSavePopupBtnDisable = false;
  isEditTrue: boolean = false;
  editOccurrencePosition: number = 0;
  _individualContractMeetingOccurDetailsBeforeAddOrEdit: any;
  SaveOccurrencesButtonClick() //click on save button in Add occurrences popup
  {
    this.isSavePopupBtnDisable = true;
    setTimeout(() => {
      this.isSavePopupBtnDisable = false;
    }, 3000);
    this.submitted = true;
    const contractMeetingOccurrencesArray: ContractMeetingOccurrences[] = [];
    this.CheckAddActionsMandatoryChecks();
     //After Add Occurrence Popup Form Valid preparing the data into single collection
    if (this.contractAddoccurrencesPopUpForm.valid && !this._addActionsFieldsMandatory) {
      if (this.isEditTrue && this._contractMeetingOccurrences != undefined && this._contractMeetingOccurrences.Id != 0) {
        if (Array.isArray(this._contractMeetingAbsentees.Absentees) && this._contractMeetingAbsentees.Absentees != undefined) {
          this._contractMeetingAbsentees.Absentees = this._contractMeetingAbsentees.Absentees.map((absentee: { name: any; }) => absentee.name).join(',');
          this._contractMeetingAbsenteesDetails.push(this._contractMeetingAbsentees);  
          if (this._contractMeetingOccurrences?.ContractMeetingAbsentees && this._contractMeetingAbsentees?.Absentees != undefined) {
            if (this._contractMeetingOccurrences.ContractMeetingAbsentees.length > 0) {
              this._contractMeetingOccurrences.ContractMeetingAbsentees[0].Absentees = this._contractMeetingAbsentees.Absentees;
            }
            else {
              this._contractMeetingOccurrences.ContractMeetingAbsentees = this._contractMeetingAbsenteesDetails;
            }

          }
        }
        if (Array.isArray(this._contractMeetingAttendees.Attendees) && this._contractMeetingAttendees.Attendees != undefined) {
          this._contractMeetingAttendees.Attendees = this._contractMeetingAttendees.Attendees.map((attendee: { name: any; }) => attendee.name).join(',');
          this._contractMeetingAttendeesDetails.push(this._contractMeetingAttendees);  
          if (this._contractMeetingOccurrences?.ContractMeetingAttendees && this._contractMeetingAttendees?.Attendees != undefined) {
            if (this._contractMeetingOccurrences.ContractMeetingAttendees.length > 0) {
              this._contractMeetingOccurrences.ContractMeetingAttendees[0].Attendees = this._contractMeetingAttendees.Attendees;
            }
            else 
            {this._contractMeetingOccurrences.ContractMeetingAttendees = this._contractMeetingAttendeesDetails;}
          }
        }
        this._contractAddOccurrences.ContractMeetingAddOccurrenceAbsentees = this._contractMeetingOccurrences.ContractMeetingAbsentees;
        this._contractAddOccurrences.ContractMeetingAddOccurrenceAttendees = this._contractMeetingOccurrences.ContractMeetingAttendees;
      }
      else {

        if (Array.isArray(this._contractMeetingAbsentees.Absentees) && this._contractMeetingAbsentees.Absentees!= undefined) {
          this._contractMeetingAbsentees.Absentees = this._contractMeetingAbsentees.Absentees.map((absentee: { name: any; }) => absentee.name).join(',');
          this._contractMeetingAbsenteesDetails.push(this._contractMeetingAbsentees); 
        }
        if (Array.isArray(this._contractMeetingAttendees.Attendees) && this._contractMeetingAttendees.Attendees!= undefined) {
          this._contractMeetingAttendees.Attendees = this._contractMeetingAttendees.Attendees.map((attendee: { name: any; }) => attendee.name).join(',');
          this._contractMeetingAttendeesDetails.push(this._contractMeetingAttendees);     
        }
        this._contractAddOccurrences.ContractMeetingAddOccurrenceAbsentees = this._contractMeetingAbsenteesDetails;
        this._contractAddOccurrences.ContractMeetingAddOccurrenceAttendees = this._contractMeetingAttendeesDetails;     
      }

      this._contractAddOccurrences.ContractMeetingAddOccurrenceDetails = [this._contractMeetingOccurrences];
      this._contractAddOccurrences.ContractMeetingAddOccurrenceActions = this.addMeetingActionsData;
      if (this._contractAddOccurrences.ContractMeetingAddOccurrenceOccurrenceAttachments == undefined) {
        this._contractAddOccurrences.ContractMeetingAddOccurrenceOccurrenceAttachments = [];
        this._contractAddOccurrences.ContractMeetingAddOccurrenceOccurrenceAttachments?.push(
          ...this._contractMeetingOccurrenceAttachmentsDetails
        );
      }
      else {
        this._contractAddOccurrences.ContractMeetingAddOccurrenceOccurrenceAttachments?.push(
          ...this._contractMeetingOccurrenceAttachmentsDetails
        );
      }

      

      const meetingOccurrence: ContractMeetingOccurrences = {
            Id: this._contractMeetingOccurrences.Id? this._contractMeetingOccurrences.Id : 0,
            OccurrenceDate: this._contractMeetingOccurrences? this._contractMeetingOccurrences.OccurrenceDate : undefined,
            MeetingNotes: this._contractMeetingOccurrences? this._contractMeetingOccurrences.MeetingNotes : "",
            Decisions: this._contractMeetingOccurrences? this._contractMeetingOccurrences.Decisions : "",
            ContractMeetingId: this._contractMeetingOccurrences? this._contractMeetingOccurrences.ContractMeetingId : 0,
            CreatedBy: this._contractMeetingOccurrences? this._contractMeetingOccurrences.CreatedBy : undefined,
            CreatedDate: this._contractMeetingOccurrences?this._contractMeetingOccurrences.CreatedDate : undefined,
            UpdatedBy: this._contractMeetingOccurrences? this._contractMeetingOccurrences.UpdatedBy : undefined,
            UpdatedDate: this._contractMeetingOccurrences? this._contractMeetingOccurrences.UpdatedDate : undefined,
            IsDeleted: false,
            TotalActionsCount: this.addMeetingActionsData != undefined ? this.addMeetingActionsData.length : 0,
            OpenActionsCount: this.getOpenActionsCount(this.addMeetingActionsData),
            ContractMeetingAttendees: this._contractAddOccurrences.ContractMeetingAddOccurrenceAttendees,
            ContractMeetingAbsentees:  this._contractAddOccurrences.ContractMeetingAddOccurrenceAbsentees,
            ContractMeetingOccurrenceAttachments: this._contractAddOccurrences.ContractMeetingAddOccurrenceOccurrenceAttachments,
            ContractMeetingOccurrenceActions: this.addMeetingActionsData
           };
           contractMeetingOccurrencesArray.push(meetingOccurrence);



      if (this.isEditTrue) {
        if (this.contractMeetingDetails.ContractMeetingOccurrences) {
          const index = this.editOccurrencePosition;
          this.contractMeetingDetails.ContractMeetingOccurrences = [
            ...this.contractMeetingDetails.ContractMeetingOccurrences.slice(0, index),
            ...contractMeetingOccurrencesArray,
            ...this.contractMeetingDetails.ContractMeetingOccurrences.slice(index + 1)
          ];
        }
      } else {
        if (this.contractMeetingDetails.ContractMeetingOccurrences == undefined) {
          this.contractMeetingDetails.ContractMeetingOccurrences = [];
        }
        this.contractMeetingDetails.ContractMeetingOccurrences?.unshift(...contractMeetingOccurrencesArray);
      }
      if (this.isEditTrue) {
        this.displayAddOccurrencesPopUP = false;
        this.isEditTrue = false;
        this.messageService.add({
          key: 'tc',
          severity: 'success',
          summary: 'Changes have been saved',
          detail: '',
        });
        this.compareModels();
      }
      else {
        this.displayAddOccurrencesPopUP = false;
        this.uploadedFiles = [];
        this.isEditTrue = false;
        this.messageService.add({
          key: 'tc',
          severity: 'success',
          summary: 'Occurrence has been added',
          detail: '',
        });
      }
      
    }
  }


  addOccurrencesPopUpCancelButton() // click on cancal button on Add occurrences Popup
  {
    var occurreceData: string = JSON.stringify({
      contractMeetingOccurrences: this._contractMeetingOccurrences,
            contractMeetingAbsentees: this._contractMeetingAbsentees.Absentees,
            contractMeetingAttendees: this._contractMeetingAttendees.Attendees,
            addMeetingActionsData: this.addMeetingActionsData,
            uploadAttachement:this.uploadedFiles
    });

    if(this._individualContractMeetingOccurDetailsBeforeAddOrEdit != occurreceData)
    {
      if(!this.isEditTrue)
      {
        this.confirmationService.confirm({
          header: 'Confirm',
          message: 'Occurrence will not be added, Do you want to Continue?',
          accept: () => {
            this.displayAddOccurrencesPopUP = false;
            this._individualContractMeetingOccurDetailsBeforeAddOrEdit = [];
            occurreceData = '{}';
            this.messageService.add({
              key: 'tc',
              severity: 'info',
              summary: 'Occurrence not be added',
              detail: '',
          });
          }
        });
      }
      else{
        this.confirmationService.confirm({
          header: 'Confirm',
          message: 'Changes will not be saved, Do you want to Continue?',
          accept: () => {
            this.displayAddOccurrencesPopUP = false;
            this._individualContractMeetingOccurDetailsBeforeAddOrEdit = [];
            occurreceData = '{}';
            this.messageService.add({
              key: 'tc',
              severity: 'info',
              summary: 'Changes not saved',
              detail: '',
          });
          }
        });
      }

    }
    else{
      this._individualContractMeetingOccurDetailsBeforeAddOrEdit = [];
      occurreceData = '{}';
      this.displayAddOccurrencesPopUP = false;
    }
  }
  getOpenActionsCount(actions: any[]): number {
    return actions.filter(action => action.StatusMappingId.code === "OPN").length;
  }
  individualOccurrenceDetails: any;
  myAction:any;
  EditOccurrenceDialog(meetingOccurrence: any, index: number) {
    this.editOccurrencePosition = index;
    this._contractMeetingOccurrenceAttachments = new ContractMeetingOccurrenceAttachments();
    this._contractMeetingAttendees = new ContractMeetingAttendees();
    this._contractMeetingAbsentees = new ContractMeetingAbsentees();
    this._contractMeetingOccurrences = new ContractMeetingOccurrences();
    this._contractAddOccurrences = new ContractAddMultipleOccurrences();
    this._contractAddMultipleOccurrences = [];
    this._contractMeetingAbsenteesDetails = [];
    this._contractMeetingAttendeesDetails = [];
    this._contractMeetingOccurrenceAttachmentsDetails = [];
    this.displayAddOccurrencesPopUP = true;
    this.addOccurrenceDialog =  "Edit Occurrence";
    this.contractAddoccurrencesPopUpForm.reset();
    this.isEditTrue = true;
   
    this.individualOccurrenceDetails = this.contractMeetingDetails?.ContractMeetingOccurrences?.[index];
     if(typeof meetingOccurrence.OccurrenceDate == 'string')
     {
      meetingOccurrence.OccurrenceDate = new Date(meetingOccurrence.OccurrenceDate);
     }
    this._contractMeetingOccurrences =  this.convertKeysToUppercase(meetingOccurrence);

    this._contractMeetingOccurrences.ContractMeetingAttendees = this._contractMeetingOccurrences.ContractMeetingAttendees?.map((e: any) => {
      return this.convertKeysToUppercase(e);
       });
       this._contractMeetingOccurrences.ContractMeetingAbsentees = this._contractMeetingOccurrences.ContractMeetingAbsentees?.map((e: any) => {
     return this.convertKeysToUppercase(e);
    });
    this._contractMeetingOccurrences.ContractMeetingOccurrenceAttachments = this._contractMeetingOccurrences.ContractMeetingOccurrenceAttachments?.map((e: any) => {
    return this.convertKeysToUppercase(e);
     });
     this._contractMeetingOccurrences.ContractMeetingOccurrenceActions = this._contractMeetingOccurrences.ContractMeetingOccurrenceActions?.map((e: any) => {
      return this.convertKeysToUppercase(e);
       });

       this.individualOccurrenceDetails.ContractMeetingAttendees = this.individualOccurrenceDetails?.ContractMeetingAttendees?.map((e: any) => {
        return this.convertKeysToUppercase(e);
         });

         this.individualOccurrenceDetails.ContractMeetingAbsentees = this.individualOccurrenceDetails?.ContractMeetingAbsentees?.map((e: any) => {
          return this.convertKeysToUppercase(e);
           });
     this.myAction = this._contractMeetingOccurrences.ContractMeetingOccurrenceActions
     this.addMeetingActionsData = [];
    for (const action of this.myAction) {
      if(action.Id !=0 ){
        if(action.TargetDate != null){
          action.TargetDate = new Date(action.TargetDate);
        }
        if(action.ClosureDate != null)
        {
          action.ClosureDate = new Date(action.ClosureDate);
        }
        if(action.StatusMappingId == undefined)
        {
            action.StatusMappingId=this.meetingStatus.find(status => status.id === action.StatusMappingId);
        }
        if(typeof action.StatusMappingId == 'number' && action.StatusMappingId != 0)
        {
          action.StatusMappingId=this.meetingStatus.find(status => status.id === action.StatusMappingId );
        }
      this.addMeetingActionsData.push(action);
    }
    else{
      this.addMeetingActionsData.push(action);
    }
    }

      if(this.individualOccurrenceDetails.ContractMeetingAbsentees && this.individualOccurrenceDetails.ContractMeetingAbsentees[0]?.Absentees != undefined && this.individualOccurrenceDetails.ContractMeetingAbsentees[0]?.Absentees != "")
       {
       this._contractMeetingAbsentees.Absentees =  this.individualOccurrenceDetails.ContractMeetingAbsentees[0]?.Absentees.split(',');
       if (this._contractMeetingAbsentees.Absentees.length > 0) {
        this._contractMeetingAbsentees.Absentees = this._finalAttendees.filter((absentee: { name: any; }) => this._contractMeetingAbsentees.Absentees.includes(absentee.name));
       }
      
       }

       if(this.individualOccurrenceDetails?.ContractMeetingAttendees && this.individualOccurrenceDetails.ContractMeetingAttendees[0]?.Attendees != undefined && this.individualOccurrenceDetails.ContractMeetingAttendees[0]?.Attendees != "")
       {
       this._contractMeetingAttendees.Attendees =  this.individualOccurrenceDetails?.ContractMeetingAttendees[0]?.Attendees.split(',');
       if (this._contractMeetingAttendees.Attendees.length > 0) {
        this._contractMeetingAttendees.Attendees = this._finalAttendees.filter((attendee: { name: any; }) => this._contractMeetingAttendees.Attendees.includes(attendee.name));
       }
      
       }

      this.uploadedFiles = this._contractMeetingOccurrences.ContractMeetingOccurrenceAttachments?.map((attachment: ContractMeetingOccurrenceAttachments) => {
        const fileName = attachment?.FileName;
        if (fileName && attachment?.Id != 0) {
            return new File([fileName], fileName);
        }
        return null;
    }).filter(file => file !== null) as File[];

      var oldAccurrenceFiles =  this.individualOccurrenceDetails.ContractMeetingOccurrenceAttachments? this.individualOccurrenceDetails.ContractMeetingOccurrenceAttachments:this.individualOccurrenceDetails.ContractMeetingOccurrenceAttachments;
                              if (!this._contractAddOccurrences.ContractMeetingAddOccurrenceOccurrenceAttachments) {
                                   this._contractAddOccurrences.ContractMeetingAddOccurrenceOccurrenceAttachments = [];
                                  }
           // Push the oldAccurrenceFiles into the SupplierMeetingAddOccurrenceOccurrenceAttachments
          this._contractAddOccurrences.ContractMeetingAddOccurrenceOccurrenceAttachments.push(...oldAccurrenceFiles);
        this.onEditUpdateAbsenteesDropDownOptions(this._contractMeetingAbsentees.Absentees);
        this.onEditAttendeesDropDownOptions(this._contractMeetingAttendees.Attendees);
        if(this.uploadedFiles.length >=3)
        {
         this.isAddEditOccurrenceFileUploadButtonDisabled = true;
        } else
        {
          this.isAddEditOccurrenceFileUploadButtonDisabled = false;
        }


          this._individualContractMeetingOccurDetailsBeforeAddOrEdit = JSON.stringify({
            contractMeetingOccurrences: this._contractMeetingOccurrences,
            contractMeetingAbsentees: this._contractMeetingAbsentees.Absentees,
            contractMeetingAttendees: this._contractMeetingAttendees.Attendees,
            addMeetingActionsData: this.addMeetingActionsData,
            uploadAttachement: this.uploadedFiles
          });
  }

UpdateMeetingDetails()//click on save button in edit meeting screen

{
  this.isSaveBtnDisable = true;
  setTimeout(() => {
    this.isSaveBtnDisable = false;
  }, 3000);
  if (this.contractMeetingForm.valid) { 
    this.loaderService.showLoader();
    if (this.deleteUploadedFiles.length > 0 ) {
      this.deleteOccurrenceAttachment();
    }
    else{
        if (this.multiMeetingFileData.length > 0) {
    this._fileService.UploadMultipleFile(this.formData).subscribe((response: any) => {
      if (response) {
        this.updateMeetingData();
      }
      else {
        this.loaderService.hideLoader();
        this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Add Occurrence Meeting File Failed to save', detail: '' });
      }
    })
  }
  else{
    this.updateMeetingData();  
  }
    }
}
else{
  this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Please enter required information', detail: '' });  
}
this.loaderService.hideLoader();
}
deleteOccurrenceAttachment(){
  this._fileService.DeleteFile(this.deleteUploadedFiles).subscribe(
    res => {

      this.UploadFilesAndSaveFinalData();
    }
  );
  }
  UploadFilesAndSaveFinalData()
{
  if (this.multiMeetingFileData.length > 0) {
    this._fileService.UploadMultipleFile(this.formData).subscribe((response: any) => {
      if (response) {
        this.updateMeetingData();
      }
      else {
        this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Add Occurrence Meeting File Failed to save', detail: '' });
      }
    })
  }
  else{
    this.updateMeetingData();
  }
}
_PostAddActionsMeetingData:any;
executeCanExist: boolean = true;
updateMeetingData(){
  this.loaderService.showLoader();
  this.executeCanExist = false;
  this.contractMeetingDetails.Id= this.contractMeetingId;
  this.contractMeetingDetails.UpdatedBy=this.privilegeService.getLoginUserId();
  const allOccurrences = [
    ...(this.contractMeetingDetails.ContractMeetingOccurrences || []),
    ...this.deletedOccurrencesList 
  ];

  allOccurrences.forEach((occurrence) => {
    if (occurrence.IsDeleted) {
      this.contractmeetingService.DeleteContractMeeting(0, occurrence.Id).subscribe((res: { data: number; }) => {
        if (res.data > 0) {
        }
      });
    }
  })
  this.contractMeetingDetails.ContractMeetingOccurrences?.filter((occurrences)=>
   {
    occurrences.OccurrenceDate = this.datePipe.transform(occurrences.OccurrenceDate, "MM/dd/yyyy");
      if(occurrences.ContractMeetingOccurrenceActions)
           {
            occurrences.ContractMeetingOccurrenceActions = occurrences.ContractMeetingOccurrenceActions?.map((e: any) => {
                  return this.convertKeysToUppercase(e);
                   });
             this._PostAddActionsMeetingData= occurrences.ContractMeetingOccurrenceActions;
             occurrences.ContractMeetingOccurrenceActions = [];
             this._PostAddActionsMeetingData.forEach((record:any) => {
              record.TargetDate = this.datePipe.transform(record.TargetDate, "MM/dd/yyyy");
              record.ClosureDate = this.datePipe.transform(record.ClosureDate, "MM/dd/yyyy");
              if(typeof record.StatusMappingId == 'object'){
                record.StatusMappingId = record.StatusMappingId.id;
              }
             else{
              record.StatusMappingId = record.StatusMappingId;
             }
               occurrences.ContractMeetingOccurrenceActions?.push(record);
               }, this);
           }
});
this.contractmeetingService.ContractUpdateMeetingData(this.contractMeetingDetails).subscribe(
  res => {
    this.loaderService.hideLoader();
    if(res.status){
      this.messageService.add({
        key: 'tc',
        severity: 'success',
        summary: 'Changes have been saved',
        detail: '',
      });   
      this.compareModels();      
      const _dataResult = {
        contractId:this.ContractId,
        meetingId:this.meetingId,
      };
     
      this.viewMeetingButtonClick.emit(_dataResult);
    }      
  }     
);    
}
  convertKeysToUppercase(obj: any): any {
    const convertedObject: any = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const uppercaseKey = key.charAt(0).toUpperCase() + key.slice(1);
        convertedObject[uppercaseKey] = obj[key];
      }
    }

    return convertedObject;
  }
  onAttendeesChange(event: any) {
      const selectedItems = event.value;
      const existingAttendees = this.attendeesValues ? this.attendeesValues.split(',') : [];

      for (const existingItem of existingAttendees) {
        if (!selectedItems.includes(existingItem)) {
          const index = existingAttendees.indexOf(existingItem);
          if (index !== -1) {
            existingAttendees.splice(index, 1);
          }
        }
      }

      for (const item of selectedItems) {
        if (!existingAttendees.includes(item)) {
          existingAttendees.push(item);
        }
      }

      this.attendeesValues = existingAttendees.join(',');
      const selectedItemsArray =  this.attendeesValues.split(',');
      if(selectedItems.length!=0)
      {
      this.absentees=this.organizerOptions.filter(c=>!selectedItemsArray.includes(c))
      }
      else{
        this.attendeesValues="";
        this.absentees=this.organizerOptions;
      }
    }
  onAbsenteesChange(event: any) {

    const selectedItems = event.value;
    const existingAbsentees = this.absenteesValues ? this.absenteesValues.split(',') : [];

    for (const existingItem of existingAbsentees) {
      if (!selectedItems.includes(existingItem)) {
        const index = existingAbsentees.indexOf(existingItem);
        if (index !== -1) {
          existingAbsentees.splice(index, 1);
        }
      }
    }

    for (const item of selectedItems) {
      if (!existingAbsentees.includes(item)) {
        existingAbsentees.push(item);
      }
    }

    this.absenteesValues = existingAbsentees.join(',');
    const selectedItemsArray =  this.absenteesValues.split(',');
    if(selectedItems.length!=0)
    {
    this.attendees=this.organizerOptions.filter(c=>!selectedItemsArray.includes(c))
    }
    else{
      this.absenteesValues="";
      this.attendees=this.organizerOptions;
    }
  }
  CheckAddActionsMandatoryChecks()//Checking the Add actions Mandatory fields
  {
    this._addActionsFieldsMandatory = false;
    this._addActionsFieldsMandatory = this.addMeetingActionsData.some((Actions) => {
      if (
        this.checkNullOrEmptyValuesService.isNullOrUndefinedOrEmpty(Actions.ActionName) ||
        this.checkNullOrEmptyValuesService.isNullOrUndefinedOrEmpty(Actions.StatusMappingId) ||
        (Actions.StatusMappingId.code === "CLD" &&
          (this.checkNullOrEmptyValuesService.isNullOrUndefinedOrEmpty(Actions.ClosureDate) || this.checkNullOrEmptyValuesService.isNullOrUndefinedOrEmpty(Actions.Remarks)))
      ) {
        return true;
      }
      return false;
    });
    if (this._addActionsFieldsMandatory) {
      this.messageService.add({
        key: 'tc',
        severity: 'error',
        summary: 'Please fill add actions mandatory fields',
        detail: '',
      });
    } 
  }
  
  onCancelContractMeeting()
  {
    const dataResult = {
      contractid:this.ContractId,
      meetingId:this.meetingId,
    };
    if (!(this._oldContractMeetingDetails == JSON.stringify(this.contractMeetingDetails))) {
      this.confirmationService.confirm({
        message: 'Changes will not be saved, Do you want to Continue?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.cancelMeetingButtonClick.emit();
          this.messageService.add({
            key: 'tc',
            severity: 'info',
            summary: 'Changes not saved',
            detail: '',
        });
           },
           reject: () => {
           },
         });
     }
     else{
       this.cancelMeetingButtonClick.emit();
     }
  }
  onEditUpdateAbsenteesDropDownOptions(selectedOptions: any) { //On Edit Each Occurrence Update Absentees Dropdown Options 
    if(selectedOptions != undefined){
        const existingAbsentees = this.absenteesValues ? this.absenteesValues.split(',') : []; 
        for (const existingItem of existingAbsentees) {
          if (!selectedOptions.includes(existingItem)) {
            const index = existingAbsentees.indexOf(existingItem);
            if (index !== -1) {
              existingAbsentees.splice(index, 1);
            }
          }
        }    
        for (const item of selectedOptions) {
          if (!existingAbsentees.includes(item)) {
            existingAbsentees.push(item);
          }
        }
        this.absenteesValues = existingAbsentees.join(',');
        const selectedItemsArray =  this.absenteesValues.split(',');
        if(selectedOptions.length!=0)
        {
        this.attendees=this.organizerOptions.filter(c=>!selectedItemsArray.includes(c))
        }
        else{
          this.attendees=this.organizerOptions;
        }
      }
      }
      onEditAttendeesDropDownOptions(selectedValues: any) {//On Edit Each Occurrence Update Attendees Dropdown Options
       if(selectedValues != undefined){
        const existingAttendees = this.attendeesValues ? this.attendeesValues.split(',') : []; 
        for (const existingItem of existingAttendees) {
          if (!selectedValues.includes(existingItem)) {
            const index = existingAttendees.indexOf(existingItem);
            if (index !== -1) {
              existingAttendees.splice(index, 1);
            }
          }
        }  
        for (const item of selectedValues) {
          if (!existingAttendees.includes(item)) {
            existingAttendees.push(item);
          }
        }
        this.attendeesValues = existingAttendees.join(',');
        const selectedItemsArray =  this.attendeesValues.split(',');
        if(selectedValues.length!=0)
        {
        this.absentees=this.organizerOptions.filter(c=>!selectedItemsArray.includes(c))
        }
        else{
          this.absentees=this.organizerOptions;
        }
      }
    }
    changedContractMeetingBasicInfo:any;
    oldContractMeetingData:any;
    contractMeetingDetailBeforeUpdate:any
    compareModels() {
      if(this.contractMeetingDetailBeforeUpdate){
      this.changedContractMeetingBasicInfo = this.auditLogService.compareModels(JSON.parse(this.contractMeetingDetailBeforeUpdate),this.contractMeetingDetails,this.auditEntities.ContractMeetings,this.auditIdentifierDetails.ContractMeetings,this.auditOperations.Update,this.auditModule.GovernanceService);
    }
  }
    
    get _attendees() {
      return this._finalAttendees.filter((a: any) => !this._contractMeetingAbsentees.Absentees.includes(a));
    }
    get _absentees() {
      return this._finalAttendees.filter((a: any) => !this._contractMeetingAttendees.Attendees.includes(a));
    }

    
    contactHeader='';
    contactSubmitted=false;
    displayContractGroupDialog:boolean=false;
    contractGroup:any;
    currentField: any;
    actionIndex:number=0;
    openContractModel(field: string,rowIndex:number=0) {
      this.actionIndex=rowIndex;
      this.contactName='';
      this.contactHeader = 'Add Contact';
      this.currentField = field;
      this.contactFormGroup.reset();
      Object.keys(this.contactFormGroup.controls).forEach(key => {
        this.contactFormGroup?.get(key)?.setErrors(null);
      });
      this.contactSubmitted = false;
      this.contractGroup = { name: "" };
      this.displayContractGroupDialog = true;
    }
    newlyAddedContacts: any[] = [];
SaveMeetingContact() {
  this.contactSubmitted = true;

  if (this.contactFormGroup.valid) {
    const newContactName = this.contactFormGroup.value.contractName;
    const newCode = this._finalAttendees.length > 0 ? Math.max(...this._finalAttendees.map((contact: { code: any; }) => contact.code || 0)) + 1 : 1;
    const newContact: ContactMeetingContacts = {
      id: 0, 
      contactName: newContactName,
      designation: "",
      countryCode: "",
      phoneNumber: "",
      email: "",
      meetingId: this.contractMeetingDetails.Id ?? 0 
    };
    const isDuplicate= this.checkNameExists(newContactName)
    if (!isDuplicate) {
      switch (this.currentField) {
        case 'organizer':
          this.organizerOptions = [...this.organizerOptions, { label: newContact.contactName, value: newContact.contactName }];
          this.contractMeetingDetails.Organizer = newContact.contactName;
          const Attendee = { name: newContact.contactName, code: newCode };
          this._finalAttendees = [...this._finalAttendees, Attendee];
          this._attendees.push(Attendee);
          const Absentee = { name: newContact.contactName, code: newCode };
          this._attendees.push(Absentee);
          break;
        case 'attendees':
          const newAttendee = { name: newContact.contactName, code: newCode };
          this._finalAttendees = [...this._finalAttendees, newAttendee];
          this._contractMeetingAttendees.Attendees = [...this._contractMeetingAttendees.Attendees, newAttendee];
          this._attendees.push(newAttendee);
          this.organizerOptions = [...this.organizerOptions, { label: newContact.contactName, value: newContact.contactName }];
          break;
        case 'absentees':
          const newAbsentee = { name: newContact.contactName, code: newCode };
          this._finalAttendees = [...this._finalAttendees, newAbsentee];
          this._contractMeetingAbsentees.Absentees = [...this._contractMeetingAbsentees.Absentees, newAbsentee];
          this._attendees.push(newAbsentee);
          this.organizerOptions = [...this.organizerOptions, { label: newContact.contactName, value: newContact.contactName }];
          break;
        case 'owner':
          this.organizerOptions = [...this.organizerOptions, { label: newContact.contactName, value: newContact.contactName }];
          this.addMeetingActionsData[this.actionIndex].Owner = newContact.contactName;
          const Attendees = { name: newContact.contactName, code: newCode };
          this._finalAttendees = [...this._finalAttendees, Attendees];
          this._attendees.push(Attendees);
          const Absentees = { name: newContact.contactName, code: newCode };
          this._attendees.push(Absentees);
          break;
        default:
          break;
      }
      this.newlyAddedContacts.push(newContact);
      this.contractMeetingDetails.ContractMeetingContacts = [...this.contractMeetingDetails.ContractMeetingContacts ?? [], newContact];
    } else {
      this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Contact Name already exists. Please enter a unique Name', detail: '' });
    }
    this.displayContractGroupDialog = false;
  }
}
closeContactDialog(){
  this.displayContractGroupDialog = false;
  this.messageService.add({
    key: 'tc',
    severity: 'info',
    summary: 'Contact Information has not been saved',
    detail: '',
});
  
}
checkNameExists(name: string): boolean {
  return this._finalAttendees.some((item: { name: string; }) => item.name === name);
}
deletedOccurrencesList: any[] = [];
deleteOccurrece(occurrenceId: number, index: number) {
  if (occurrenceId != undefined) {
    this.confirmationService.confirm({
      message: `Are you sure you want to delete this Meeting Occurrence?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (this.contractMeetingDetails && this.contractMeetingDetails.ContractMeetingOccurrences) {
          const deletedOccurrence = this.contractMeetingDetails.ContractMeetingOccurrences[index];
          deletedOccurrence.IsDeleted = true;
          this.contractMeetingDetails.ContractMeetingOccurrences.splice(index, 1);
          this.deletedOccurrencesList.push(deletedOccurrence);
          this.showMessage('info', 'Meeting Occurrence is deleted successfully.');
        }
      },
      reject: () => {},
    });
  }
}
showMessage(severity: string, summary: string, detail: string = ''): void {
  this.messageService.add({
    key: 'tc',
    severity: severity,
    summary: summary,
    detail: detail,
  });
}
}






