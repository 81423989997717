import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ConfirmationService, MessageService,  MenuItem } from 'primeng/api';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';
import { Dateformat,breadcrumnsCodes, AuditIdentifier, AuditLogEntities, AuditOperations, AuditLogModule, FrequencyValues } from 'src/app/shared/constants/global.constants';
import { LoaderService } from 'src/app/Loader/loader.service';
import { } from 'src/app/shared/Guards/can-deactivate-guard.service';
import { Observable} from 'rxjs';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { CodeListValues, EngagementKpi, EngagementKPIHyperCareThreshold, EngagementKPISteadyStateThreshold, MasterKpiData } from '../../Model/engagementKpi';
import { EngagementKpiService } from '../../Services/engagement-kpi.service';
import { DatePipe } from '@angular/common';
import { AuditLogService } from 'src/app/shared/ModelComparison/audit.log.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { CommonService } from 'src/app/shared/Service/common.service';
import { EngagementService } from 'src/app/Engagement/Services/engagement.service';
@Component({
  selector: 'app-eng-create-kpi',
  templateUrl: './eng-create-kpi.component.html',
  styleUrls: ['./eng-create-kpi.component.css']
})

export class EngCreateKpiComponent implements OnInit  {
  isSaveBtnDisable = false;
  @Input() engagementId: any;
  @Output() cancelKpitButtonClick = new EventEmitter<void>();
  @Output() saveKpiButtonClick = new EventEmitter<void>();
  @Output() viewKpiButtonClick: EventEmitter<any> = new EventEmitter<any>();
executeCanExist: boolean = true;
breadcrumbs: MenuItem[]=[];
_enumBreadcrumb :any;
_dateformart :any;
 isLoading=false;
kpiNameDataSource: CodeListValues[] | undefined;
kpiReportingFrequencyDataSource: CodeListValues[] | undefined;
kpiDataCollectionFrequencyDataSource: CodeListValues[] | undefined;
kpiReportingFrequencyMainDataSource: CodeListValues[] | undefined;
statusDataSource: CodeListValues[] | undefined;
hyperCareThresholdDataSource: CodeListValues[] | undefined;
steadyStateThresholdDataSource: CodeListValues[] | undefined;
masterKpiDataSource: MasterKpiData[] | undefined;
_engagementKpi=new EngagementKpi();
_kpiSteadyStateThreshold= new EngagementKPISteadyStateThreshold();
_kpiSteadyStateThresholds: EngagementKPISteadyStateThreshold[]=[];
_kpiHyperCareThreshold= new EngagementKPIHyperCareThreshold();
_kpiHyperCareThresholds: EngagementKPIHyperCareThreshold[]=[];
_dynamicKpiHyperCareThreshold= new EngagementKPIHyperCareThreshold();
_dynamicKpiHyperCareThresholds: EngagementKPIHyperCareThreshold[]=[];
_dynamicKpiSteadyStateThreshold= new EngagementKPISteadyStateThreshold();
_dynamicKpiSteadyStateThresholds: EngagementKPISteadyStateThreshold[]=[];
_readOnlyUnitofMeasure:string="";
_readOnlyCategory:string="";
_readOnlyComplianceIndicator:string="";
_readOnlyCalculation:string="";
_readOnlyAttribute1:string="";
_readOnlyAttribute2:string="";
_readOnlyAttribute3:string="";
_readOnlyFormulaName:string="";
_kpiAttributeTypeCode:string="";
_kpiAttributeTypeId:number=0;
_readOnlySybmolHyperCareThreshold:string="";
_readOnlySybmolSteadyStateThreshold:string="";
_isNotApplicable:boolean=false;
_unitCode:string="";
 submitted = false;
  KpiMinEndDate:any;
  KpiMaxStartDate:any;
  hyperCareMinEndDate:any;
  hyperCareMaxStartDate:any;
  myEngagementKPIForm: FormGroup;
  EngagementId:any;
  EngagementName:any;
  _oldEngagementKpi:any
  _dataCollectionCode:string="";
  suggestedKPIName:string="";
  frequencyData:any;
  oldEngagementKPIData:any;
  changedEngagementKPIBasicInfo:any;

  constructor(private privilegeService: PrivilegeService,private router: Router, 
    private fb: FormBuilder, private confirmationService: ConfirmationService, 
    private messageService: MessageService, private loaderService: LoaderService, 
    private breadcrumbService: BreadcrumbService,private masterDataService: SponserService,
    private service: EngagementKpiService,
    private activeRouter: ActivatedRoute,private datepipe:DatePipe,
    private auditLogService: AuditLogService,
    private commonService:CommonService,
    private engagementService: EngagementService,){
    this.myEngagementKPIForm = this.fb.group({
      _KPIName:['', [Validators.required,this.validateStatus]],
      _kpiReportingFrequency:['', [Validators.required,this.validateStatus]],
      _kpiDataCollectionFrequency:['', [Validators.required,this.validateStatus]],
     // _kpiStartDate:['',[Validators.required]],
     // _kpiEndDate:['',[Validators.required]],
      _status:['', [Validators.required,this.validateStatus]],
      _unitOfMeasure: [{ value: '', disabled: true }],	

      _hyperCareThreshold: [null, this._isNotApplicable ? null : Validators.required],
      _hyperCareThresholdValue:['',[this._isNotApplicable ? null : Validators.required,Validators.minLength(1),Validators.maxLength(3),Validators.pattern(/^[0-9]*$/)]],
      _hyperCareThresholdsymbol:[{ value: '', disabled: true }],
      // _hyperCareStartDate:[{ value: '', disabled: true }],
      //_hyperCareStartDate:[{ value: ''}],
      _hyperCareStartDate:[null, this._isNotApplicable ? null : Validators.required],
      _hyperCareEndDate:[null, this._isNotApplicable ? null : Validators.required],

      _dyhyperCareThreshold: [''],
      _steadyStateThreshold:['', [Validators.required]],
      _dysteadyStateThreshold:[''],
      _dyhyperCareThresholdValue:[''],
      _dyhyperCareThresholdsymbol:[{ value: '', disabled: true }],	

      _steadyStateThresoldValue:['',[Validators.required,Validators.minLength(1),Validators.maxLength(3),Validators.pattern(/^[0-9]*$/)]],
      _steadyStateThresoldSymbol:[{ value: '', disabled: true }],	
      _dysteadyStateThresoldValue:[''],
      _dysteadyStateThresoldSymbol:[{ value: '', disabled: true }],	
      _category:[{ value: '', disabled: true }],	
      _complianceIndicator:[{ value: '', disabled: true }],	
      _calculation:[{ value: '', disabled: true }],	
      // _attribute1:[{ value: '', disabled: true }],	
      // _attribute2:[{ value: '', disabled: true }],	
      // _attribute3:[{ value: '', disabled: true }],	

      _attribute1:['', [Validators.required,Validators.minLength(3),this.noOnlySpacesValidator,Validators.pattern(/^[^&%]*$/)]],
      _attribute2:['', [Validators.required,Validators.minLength(3),this.noOnlySpacesValidator,Validators.pattern(/^[^&%]*$/)]],

      _attribute3:[{ value: ''}],	

      _kpiDescription:['',[Validators.maxLength(300)]],	
      _serviceName:['', [Validators.required,Validators.maxLength(200),this.noOnlySpacesValidator,Validators.pattern(/^[^&%]*$/)]],
      _kpiName:['', [Validators.required,Validators.minLength(3),Validators.maxLength(400),this.noOnlySpacesValidator,Validators.pattern(/^[^&%]*$/)]],
      _frequencyValueforStartDate:['', [Validators.required]],
      _kpiStartDateYear:['', [Validators.required,Validators.minLength(3)]],
      _frequencyValueforEndDate:['', [Validators.required]],
      _kpiEndDateYear:['', [Validators.required,Validators.minLength(3)]],
    });
  }; 
  
  noOnlySpacesValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value && control.value.trim().length === 0) {
      return { onlySpaces: true };
    }
    return null;
  }
  validateStatus(control: FormControl) {
    const status = control.value;
    if (status === undefined || status === 0) {
        return { required: true }; // Validation failed
    }
    return null; // Validation passed
}
  auditIdentifierDetails:any;
  auditEntities:any;
  auditOperations:any;
  auditModule:any;

  ngOnInit() {   
    this.loaderService.showLoader();
    this.loaderService.hidefinalLoader();
    this.EngagementId=this.engagementId;
    this.loaderService.hidefinalLoader();
    this.KpiMinEndDate=null;
    this.KpiMaxStartDate=null;
    this.hyperCareMinEndDate=null;
    this.hyperCareMaxStartDate=null;
    this._enumBreadcrumb=breadcrumnsCodes;
    this._dateformart=Dateformat;
    this.auditIdentifierDetails=AuditIdentifier; 
    this.auditEntities=AuditLogEntities;
    this.auditOperations=AuditOperations;
    this.auditModule=AuditLogModule;

    this.GetAllKpiNames(7);
    this.GetAllKpiReportingFrequencies(9) ;
    this.GetAllkpiDataCollectionFrequenies(8);
    this.GetAllStatus(3);
    this.GetAllHyperCareThresholds(10);
    this.GetAllMasterKpis(11);
    this.GetEngagementDataById(this.EngagementId);
    this.formOldEngagementKpi();
    this.oldEngagementKPIData=JSON.stringify(this._engagementKpi);
}


GetAllKpiNames(type:number) {
  this.masterDataService.getCodeListData(type,'SupplierKPI').subscribe(
    res=>{
      this.kpiNameDataSource=res.data;
    }
  );
};

GetAllKpiReportingFrequencies(type:number) {
  this.masterDataService.getCodeListData(type,'').subscribe(
    res=>{
      this.kpiReportingFrequencyMainDataSource=res.data;
    
    }
  );
};
GetAllkpiDataCollectionFrequenies(type:number) {
  this.masterDataService.getCodeListData(type,'').subscribe(
    res=>{
      this.kpiDataCollectionFrequencyDataSource=res.data;
     
    }
  );
};
GetAllStatus(type:number) {
  this.masterDataService.getCodeListData(type,'KPI Status').subscribe(
    res=>{
      this.statusDataSource=res.data;
    }
  );
};
GetAllHyperCareThresholds(type:number) {
  this.masterDataService.getCodeListData(type,'').subscribe(
    res=>{
      this.hyperCareThresholdDataSource=res.data;
      this.steadyStateThresholdDataSource=res.data;
    }
  );
};
GetAllMasterKpis(type:number) {
  this.masterDataService.GetAllSupplierMasterData(type).subscribe(
    res=>{
      this.masterKpiDataSource=res.data;
    }
  );
};

isAttribute1NotApplicable=false;
isAttribute2NotApplicable=false;
isAttribute3NotApplicable=false;

OnChangeKPIName(data: any) {
  const selectedKpi = data.value;
  const filteredSupplierMasterData = this.masterKpiDataSource?.find(t => t.kpiDetailId == selectedKpi);

  if (filteredSupplierMasterData) {
      const {
          unitOfMeasure,
          category,
          complianceIndicator,
          calculation,
          attribute1,
          attribute2,
          attribute3,
          formulaName,
          unitOfMeasureCode,
          kpiAttributeTypeCode,
          kpiAttributeTypeId
      } = filteredSupplierMasterData;

      this._readOnlyUnitofMeasure = unitOfMeasure;
      this._readOnlyCategory = category;
      this._readOnlyComplianceIndicator = complianceIndicator;
      this._readOnlyCalculation = calculation;
      this._readOnlyFormulaName = formulaName;

      this._unitCode = unitOfMeasureCode;
      this._kpiAttributeTypeCode = kpiAttributeTypeCode;
      this._kpiAttributeTypeId = kpiAttributeTypeId;

      this.isAttribute1NotApplicable = this.checkAttributeNotApplicable(attribute1);
      this.isAttribute2NotApplicable = this.checkAttributeNotApplicable(attribute2);
      this.isAttribute3NotApplicable = this.checkAttributeNotApplicable(attribute3);

      this._readOnlyAttribute1 = attribute1;
      this._readOnlyAttribute2 = attribute2;
      this._readOnlyAttribute3 = attribute3;

      this._readOnlySybmolHyperCareThreshold = !this._isNotApplicable ? this._unitCode : "";
      this._readOnlySybmolSteadyStateThreshold = this._unitCode;
  } else {
      this.resetReadOnlyFields();
  }

  this.PrepareKPINameSuggestion();
}

private checkAttributeNotApplicable(attribute: string): boolean {
  return attribute === 'NA' || attribute === '';
}

resetReadOnlyFields() {
  this._readOnlyUnitofMeasure = "";
  this._readOnlyCategory = "";
  this._readOnlyComplianceIndicator = "";
  this._readOnlyCalculation = "";
  this._readOnlyAttribute1 = "";
  this._readOnlyAttribute2 = "";
  this._readOnlyAttribute3 = "";
  this._readOnlySybmolHyperCareThreshold = "";
  this._readOnlySybmolSteadyStateThreshold = "";
  this._unitCode = "";
  this._readOnlyFormulaName = "";
}


isFrequencyAnnual=false;
DataCollectionFrequency:string="";
OnChangeDataCollection(data:any){
  let selectedItem =data.value;
  let filteDataCollection = this.kpiDataCollectionFrequencyDataSource?.filter(t=>t.id==selectedItem);
  if(filteDataCollection!=null)
  {
  this._dataCollectionCode=filteDataCollection[0].code;
  let selectedSubCode=filteDataCollection[0].sub_Code;
this.DataCollectionFrequency=filteDataCollection[0].name;
  
  this.kpiReportingFrequencyDataSource=this.kpiReportingFrequencyMainDataSource?.filter(t=>t.sub_Code>=selectedSubCode) ;


  if(filteDataCollection[0].name=="Annual"){
    this.isFrequencyAnnual=true;
  }
  else{
    this.isFrequencyAnnual=false;
  }
  //Get Frequency Data Here
  if(filteDataCollection[0].name=="Monthly")
    this.frequencyData=JSON.parse(FrequencyValues.Monthly);
  else if(filteDataCollection[0].name=="Quarterly")
    this.frequencyData=JSON.parse(FrequencyValues.Quarterly);
  else if(filteDataCollection[0].name=="Half-Year")
    this.frequencyData=JSON.parse(FrequencyValues.HalfYearly);
  else if(filteDataCollection[0].name=="Annual")
  this.frequencyData=JSON.parse(FrequencyValues.Annual);
  
  }
  else
  {
    this._dataCollectionCode="";
  }


  
}
chkChange(event: any) {
 if(event.checked)
 {
  this.myEngagementKPIForm.controls['_hyperCareStartDate'].setErrors(null);
  this.myEngagementKPIForm.controls['_hyperCareEndDate'].setErrors(null);
  this.myEngagementKPIForm.controls['_hyperCareThresholdValue'].setErrors(null);
  this._engagementKpi.hyperCareStartDate=undefined;
  this._engagementKpi.hyperCareEndDate=undefined;
  this._readOnlySybmolHyperCareThreshold="";
  this._readOnlySybmolHyperCareThreshold="";
  this._kpiHyperCareThreshold= new EngagementKPIHyperCareThreshold();
  this._dynamicKpiHyperCareThreshold= new EngagementKPIHyperCareThreshold();
  this._dynamicKpiHyperCareThresholds.splice(0, 1);
  this._isNotApplicable=true;
   this.myEngagementKPIForm.controls['_dyhyperCareThresholdValue'].setErrors(null);
   this.myEngagementKPIForm.controls['_dyhyperCareThreshold'].setErrors(null);
 
 }
 else{
  this.myEngagementKPIForm.controls['_hyperCareStartDate'].setErrors({required: true});
  this.myEngagementKPIForm.controls['_hyperCareEndDate'].setErrors({required: true});
  this.myEngagementKPIForm.controls['_hyperCareThresholdValue'].setErrors({required: true});
  this._readOnlySybmolHyperCareThreshold=this._unitCode
  this._isNotApplicable=false;
  this._engagementKpi.hyperCareStartDate=this._engagementKpi.kpiStartDate;
 }
}
newEngagementKpi:any;
onSaveEngagementKpi()
{
  this.isSaveBtnDisable = true;
  setTimeout(() => {
    this.isSaveBtnDisable = false;
  }, 3000);
  if(this._isNotApplicable)
  {
    this.myEngagementKPIForm.controls['_hyperCareStartDate'].setErrors(null);
    this.myEngagementKPIForm.controls['_hyperCareEndDate'].setErrors(null);
    this.myEngagementKPIForm.controls['_hyperCareThresholdValue'].setErrors(null);
   
  }

  if(this.DataCollectionFrequency == "Annual"){
    this.myEngagementKPIForm.controls['_frequencyValueforStartDate'].setErrors(null);
    this.myEngagementKPIForm.controls['_frequencyValueforEndDate'].setErrors(null);
  }
 //validate dynamic fileds
 if(this._dynamicKpiHyperCareThresholds.length!=0)
 {
  this.myEngagementKPIForm.controls['_dyhyperCareThresholdValue'].setErrors(this._dynamicKpiHyperCareThreshold.percentageValue=="" ? {required: true} :null);
  this.myEngagementKPIForm.controls['_dyhyperCareThreshold'].setErrors(this._dynamicKpiHyperCareThreshold.mathematicalSymbolId==0 ? {required: true} :null);
 }
 if(this._dynamicKpiSteadyStateThresholds.length!=0)
 {
  this.myEngagementKPIForm.controls['_dysteadyStateThresoldValue'].setErrors(this._dynamicKpiSteadyStateThreshold.percentageValue=="" ? {required: true} :null);
  this.myEngagementKPIForm.controls['_dysteadyStateThreshold'].setErrors(this._dynamicKpiSteadyStateThreshold.mathematicalSymbolId==0 ? {required: true} :null);
 }
  this.submitted = true;
  
  this.updateValidators('_attribute1');
  this.updateValidators('_attribute2');

  if (this.myEngagementKPIForm.valid) {
    this.executeCanExist = false;
    this.loaderService.showLoader();
    if(!this._isNotApplicable)
    {
      this._kpiHyperCareThresholds.push(this._kpiHyperCareThreshold);
      if(this._dynamicKpiHyperCareThreshold.mathematicalSymbolId!=0)
      this._kpiHyperCareThresholds.push(this._dynamicKpiHyperCareThreshold)
    }
    this._kpiSteadyStateThresholds.push(this._kpiSteadyStateThreshold);
    if(this._dynamicKpiSteadyStateThreshold.mathematicalSymbolId!=0)
    this._kpiSteadyStateThresholds.push(this._dynamicKpiSteadyStateThreshold);
    this._engagementKpi.EngagementKPIHyperCareThreshold=this._kpiHyperCareThresholds;
    this._engagementKpi.EngagementKPISteadyStateThreshold=this._kpiSteadyStateThresholds;
    this._engagementKpi.engagementId=this.EngagementId;
    this._engagementKpi.createdBy=this.privilegeService.getLoginUserId();
    this._engagementKpi.kpiReportingFrequencyCode=this._dataCollectionCode;

    this._engagementKpi.attribute1=this._readOnlyAttribute1;
    this._engagementKpi.attribute2=this._readOnlyAttribute2;
    this._engagementKpi.attribute3=this._readOnlyAttribute3;
    this._engagementKpi.calculation=this._readOnlyCalculation;
    if(this._engagementKpi.hyperCareEndDate != undefined && this._engagementKpi.hyperCareEndDate != null)
    {
      this._engagementKpi.hyperCareEndDate =  this.datepipe.transform(this._engagementKpi.hyperCareEndDate, "MM/dd/yyyy");
    }
    if(this._engagementKpi.hyperCareStartDate != undefined && this._engagementKpi.hyperCareStartDate != null)
    {
      this._engagementKpi.hyperCareStartDate = this.datepipe.transform(this._engagementKpi.hyperCareStartDate, "MM/dd/yyyy");
    }       
    if(this._engagementKpi.hyperCareEndDate!=undefined){
    this.newEngagementKpi=this._engagementKpi.hyperCareEndDate;
    }

    let frequency=this.kpiDataCollectionFrequencyDataSource?.filter(t=>t.id==this._engagementKpi.kpiDataCollectionFrequencyId)[0].name;
    this._engagementKpi.kpiStartDate=this.commonService.getCompleteDate(frequency,this._engagementKpi.frequencyValueforStartDate,this._engagementKpi.kpiStartDateYear);
    this._engagementKpi.kpiEndDate=this.commonService.getCompleteDate(frequency,this._engagementKpi.frequencyValueforEndDate,this._engagementKpi.kpiEndDateYear);
    
    if(this._engagementKpi.kpiStartDate>this._engagementKpi.kpiEndDate)
    {
      this.messageService.add({  key: 'tc',severity: 'warn', summary: 'KPI Tracking End should be greater than KPI Tracking Start', detail: '' });  
      this.loaderService.hideLoader();    
    }
    else{
      this._engagementKpi.kpiEndDate = this.datepipe.transform(this._engagementKpi.kpiEndDate, "MM/dd/yyyy");
      this._engagementKpi.kpiStartDate = this.datepipe.transform(this._engagementKpi.kpiStartDate, "MM/dd/yyyy");   

        this.service.AddEngagementKpi(this._engagementKpi).subscribe(res => {
        
     if(res.status)
     {
      this.messageService.add({ key: 'tc', severity: 'success', summary: 'KPI has been added', detail: '' });
      this.compareModels();
       this.loaderService.hideLoader();    
      const dataResult = {
      engagementId:this.engagementId,
        kpiId:parseInt(res.data)
      };
      this.viewKpiButtonClick.emit(dataResult);
    
     }
   });
  }
  }
  else{
    this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Please enter required information', detail: '' });  
  }
}
onCancelEngagementKpi()
{
  if ((this._oldEngagementKpi!=JSON.stringify(this._engagementKpi)))
  {
      this.confirmationService.confirm({
        message: 'KPI will not be added, Do you want to Continue?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.cancelKpitButtonClick.emit();
          this.messageService.add({
            key: 'tc',
            severity: 'info',
            summary: 'KPI not added',
            detail: '',
        });
        },
        reject: () => {
        },
      });
  }
  else{
    this.cancelKpitButtonClick.emit();
  }

}
onStartDateSelection(startDate:any,type:number){
  
  this.hyperCareMinEndDate= new Date(startDate);
  this.hyperCareMinEndDate.setDate(this.hyperCareMinEndDate.getDate() + 1);
}
onEndDateSelection(endDate:any,type:number){
  if(type==1)
  {
    this.KpiMaxStartDate= new Date(endDate);
    this.KpiMaxStartDate.setDate(this.KpiMaxStartDate.getDate() - 1);
  }
  else{
    this.hyperCareMaxStartDate= new Date(endDate);
    this.hyperCareMaxStartDate.setDate(this.hyperCareMaxStartDate.getDate() - 1);
  }
   
}
addRow(type:number) {
  if(type==1)
  {
  this._dynamicKpiHyperCareThresholds.push({
    Id:0,
    percentageValue: '',
    mathematicalSymbolId:0,
    engagementKPIId:0,
  });
}
else{
  this._dynamicKpiSteadyStateThresholds.push({
    Id:0,
    percentageValue: '',
    mathematicalSymbolId:0,
    engagementKPIId:0,
  });
}
}
removeRow(index: number,type:number) {
  if(type==1)
  {
    this._dynamicKpiHyperCareThreshold= new EngagementKPIHyperCareThreshold();
    this._dynamicKpiHyperCareThresholds.splice(index, 1);
     this.myEngagementKPIForm.controls['_dyhyperCareThresholdValue'].setErrors(null);
     this.myEngagementKPIForm.controls['_dyhyperCareThreshold'].setErrors(null);
    
  }
  else{
    this._dynamicKpiSteadyStateThreshold= new EngagementKPIHyperCareThreshold();
    this._dynamicKpiSteadyStateThresholds.splice(index, 1);
    this.myEngagementKPIForm.controls['_dysteadyStateThresoldValue'].setErrors(null);
    this.myEngagementKPIForm.controls['_dysteadyStateThreshold'].setErrors(null);
  }
}
GetEngagementDataById(EngagementId:number){
  this.engagementService.GetEngagementById(EngagementId).subscribe(
    res=>{
      this.loaderService.hideLoader();
      this.EngagementName=res.data.engagementName;
      this.suggestedKPIName=this.EngagementName;
    }
  );
}

OnServiceChange(){
  this.PrepareKPINameSuggestion();
}

PrepareKPINameSuggestion(){
 
  if(this._engagementKpi.kpiDetailId != null && this._engagementKpi.kpiDetailId != undefined && this._engagementKpi.serviceName != '' && this._engagementKpi.serviceName != null && this._engagementKpi.serviceName != undefined)
  {
    
    var kpi = this.kpiNameDataSource?.find((aa:any) => aa.id === this._engagementKpi.kpiDetailId)?.name;
    this.suggestedKPIName=this.EngagementName + '-' + kpi + '-' + this._engagementKpi.serviceName;
  
  }
  else if(this._engagementKpi.kpiDetailId != null && this._engagementKpi.kpiDetailId != undefined && (this._engagementKpi.serviceName == '' || this._engagementKpi.serviceName == null || this._engagementKpi.serviceName == undefined))
  {
    var kpi = this.kpiNameDataSource?.find((aa:any) => aa.id === this._engagementKpi.kpiDetailId)?.name;
    this.suggestedKPIName=this.EngagementName + '-' + kpi;
  
  }
  else if((this._engagementKpi.kpiDetailId == null || this._engagementKpi.kpiDetailId == undefined) && this._engagementKpi.serviceName != '' && this._engagementKpi.serviceName != null && this._engagementKpi.serviceName != undefined)
  {
    this.suggestedKPIName=this.EngagementName  + '-' + this._engagementKpi.serviceName;
  }
  else{
    this.suggestedKPIName=this.EngagementName;
  }
  
}

canExist(): boolean | Promise<boolean> | Observable<boolean> {
  if (this.executeCanExist &&!(this._oldEngagementKpi==JSON.stringify(this._engagementKpi)))
  {
    return new Promise<boolean>((resolve) => {
      this.confirmationService.confirm({
        message: 'KPI will not be added, Do you want to Continue?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.messageService.add({
            key: 'tc',
            severity: 'info',
            summary: 'KPI not added',
            detail: '',
        });
          resolve(true); 
        },
        reject: () => {
          resolve(false); 
        },
      });
    });
  }
  else{
    return true;
  }
  
}
generateBreadcrumb(component?:string,recordName?:string,tabName?:string,childRecName?:string,recordId?:number)
{
  this.breadcrumbService.generateBreadcrumb(component,recordName,tabName,childRecName,recordId);
}

getBreadcrumbs(): MenuItem[] {
  return this.breadcrumbService.getBreadcrumbs();
}
formOldEngagementKpi(){
  setTimeout(() => {
    this._oldEngagementKpi=JSON.stringify(this._engagementKpi);
  }, 2000);
}
onNumericInput(event: Event,_controlname:string) {
  const inputElement = event.target as HTMLInputElement;
  const sanitizedValue = inputElement.value.replace(/\D/g, '');
  inputElement.value = sanitizedValue;
  this.myEngagementKPIForm.controls[_controlname].setValue(sanitizedValue);
}
compareModels() {
  this._engagementKpi.hyperCareEndDate=this.newEngagementKpi;
  this.changedEngagementKPIBasicInfo = this.auditLogService.compareModels(JSON.parse(this.oldEngagementKPIData),this._engagementKpi,this.auditEntities.EngagementKPIs,this.auditIdentifierDetails.EngagementKPIs,this.auditOperations.Create,this.auditModule.GovernanceService);
}

getCalculationFormulae(){
  //this._readOnlyFormulaName
  let data=this._readOnlyFormulaName;
  data=data.replace('Attribute 1',this._readOnlyAttribute1);
  data=data.replace('Attribute 2',this._readOnlyAttribute2);
  data=data.replace('Attribute 3',this._readOnlyAttribute3);
  this._readOnlyCalculation=data;
 }

 updateValidators(controlName: string) {
  const control = this.myEngagementKPIForm.get(controlName);
  if (control) {
    if (control.value === 'NA') {
      control.clearValidators();
      control.setValidators([Validators.required, this.noOnlySpacesValidator, Validators.pattern(/^[^&%]*$/)]);
    } else {
      control.setValidators([Validators.required, Validators.minLength(3), this.noOnlySpacesValidator, Validators.pattern(/^[^&%]*$/)]);
    }
    control.updateValueAndValidity();
  }
}


}
