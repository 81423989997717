import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';
import { ContracAddendumDetails, ContractChangeOrder, ContractDetails, ContractBillingTypes, MeetingChildComponents,KpiChildComponents } from '../../Models/contract-details';
import { ContractService } from '../../Services/contract.service';
import { EngagementDropdownBinding } from '../../Models/supplier-binding-dropdown';
import { } from 'date-fns';
import { Dateformat, breadcrumnsCodes, FileExtension, CurrencyType, AuditIdentifier, AuditLogEntities, AuditOperations, AuditLogModule } from 'src/app/shared/constants/global.constants';
import { LoaderService } from 'src/app/Loader/loader.service';
import { DatePipe, formatDate } from '@angular/common';
import { IDeactivateGuard } from 'src/app/shared/Guards/can-deactivate-guard.service';
import { Observable, forkJoin, map, } from 'rxjs';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { ConCreateKpiComponent } from 'src/app/Governance/kpis/Contract/Components/con-create-kpi/con-create-kpi.component';
import { ConEditKpiComponent } from 'src/app/Governance/kpis/Contract/Components/con-edit-kpi/con-edit-kpi.component';
import { TabView } from 'primeng/tabview';
import { CodeListValues, SponsorContact, SupplierContact, SupplierProfile } from 'src/app/MasterData/Services/supplier-profile'
import { ConAccrualsEditComponent } from 'src/app/Finance/Accruals/contract/Components/con-accruals-edit/con-accruals-edit.component';
import { ConForecastEditComponent } from 'src/app/Finance/Forecast/contract/Components/con-forecast-edit/con-forecast-edit.component';
import { AccrualChildComponents } from 'src/app/Finance/Models/contract-accrual';
import { ForecastChildComponents } from 'src/app/Finance/Models/contract-forecast';
import { ConCreateMeetingComponent } from 'src/app/Governance/meetings/Contracts/Components/con-create-meeting/con-create-meeting.component';
import { ConEditMeetingComponent } from 'src/app/Governance/meetings/Contracts/Components/con-edit-meeting/con-edit-meeting.component';
import { TeamChildComponents } from '../../../Team/teams/Models/Associate';
import { AssociateAddEditComponent } from '../../../Team/teams/associate-add-edit/associate-add-edit.component';

import { NotificationService } from 'src/app/shared/Notifications/notification.service';
import { ConEditMeetingRecYesComponent } from 'src/app/Governance/meetings/Contracts/Components/con-edit-meeting-rec-yes/con-edit-meeting-rec-yes.component';
import { AuditLogService } from 'src/app/shared/ModelComparison/audit.log.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { Privileges } from 'src/app/shared/constants/privileges.constants';
import { CurrencyService } from 'src/app/Reusable/currency.service';
import { ContInvoiceCreateComponent } from 'src/app/Finance/finances/Invoices/Contract/Components/cont-invoice-create/cont-invoice-create.component';
import { ContInvoiceEditComponent } from 'src/app/Finance/finances/Invoices/Contract/Components/cont-invoice-edit/cont-invoice-edit.component';
import { InvoiceChildComponents } from '../../Services/contract-invoice';
import { ConSummaryViewComponent } from 'src/app/Finance/Summary/contract/Components/con-summary-view/con-summary-view.component';
import { EngagementService } from 'src/app/Engagement/Services/engagement.service';
import { ContractGroupModel, EngagementSponsorContact, EngagementSupplierContact } from 'src/app/Engagement/Models/Engagement';
import { MasterSupplierService } from 'src/app/MasterData/supplier/Services/master.supplier.service';
import { ITbl_SponserContacts } from 'src/app/MasterData/Models/sponser-data';
import { FileUploadService } from 'src/app/Engagement/Services/fileupload.service';
import { TabAndStateService } from 'src/app/shared/TabAndStateService/TabAndState.Service';

@Component({
    selector: 'app-editcontract',
    templateUrl: './editcontract.component.html',
    styleUrls: ['./editcontract.component.css']
})
export class EditcontractComponent implements OnInit, IDeactivateGuard {
    @Input() invoiceId: any;
    @ViewChild(ConAccrualsEditComponent) childAccrualEditComponent!: ConAccrualsEditComponent;
    @ViewChild(ConForecastEditComponent) childForecastEditComponent!: ConForecastEditComponent;


    @ViewChild(ConCreateKpiComponent) childKpiCreateComponent!: ConCreateKpiComponent;
    @ViewChild(ConEditKpiComponent) childKpiEditComponent!: ConEditKpiComponent;
    @ViewChild('myTabView', { static: false }) tabView!: TabView;
    activeTabIndex = 0;
    @ViewChild('mySubTabView', { static: false }) subTabView!: TabView;
    activeFinanceSubTabIndex=0;
    @ViewChild('myFinanceSubTabView', { static: false }) financeSubTabView!: TabView;

    @ViewChild(ConCreateMeetingComponent) childMeetingCreateComponent!: ConCreateMeetingComponent;
    @ViewChild(ConEditMeetingComponent) childMeetingEditComponent!: ConEditMeetingComponent;
    @ViewChild(ConEditMeetingRecYesComponent) childMeetingEditRecYesComponent!: ConEditMeetingRecYesComponent;

    @ViewChild(AssociateAddEditComponent) childTeamCreateEditComponent!: AssociateAddEditComponent;

    @ViewChild(ContInvoiceCreateComponent) childInvoiceCreateComponent!: ContInvoiceCreateComponent;
    @ViewChild(ContInvoiceEditComponent) childInvoiceEditComponent!: ContInvoiceEditComponent;
    
    @ViewChild(ConSummaryViewComponent) childConSummaryViewComponent!: ConSummaryViewComponent;
    @ViewChild('scrollContainer', { static: false }) scrollContainer!: ElementRef;

    isfromEngagement: boolean = false;

    _invoiceChildComponents=new InvoiceChildComponents();
    _accrualChildComponents = new AccrualChildComponents();
    _forecastChildComponents = new ForecastChildComponents();

    _currentAccrualChildComponent: number = 0;
    _currentForecastChildComponent: number = 0;
    PaymentTermName: any;
    _kpiChildComponents = new KpiChildComponents();
    _meetingChildComponents = new MeetingChildComponents();
    _teamChildComponents = new TeamChildComponents();
    _currentKpiChildComponent: number=0;
    _currentMeetingChildComponent: number = 0;
    _currentTeamChildComponent: number = 0;
    kpiId: any;
    currentChildComponent: any;
    activeSubTabIndex = 0;
    value: string | undefined;
    visible: boolean = false;
    myContractDetailsForm: FormGroup;
    contractsChangeOrderForm: FormGroup;
    contactFormGroup:FormGroup;
    contractDetails = new ContractDetails();
    contractsAddendumDetailsForm: FormGroup;
    supplierdata: any[] = [];
    supplierMSAdata: any[] = [];
    BillingTypeData: any[] = [];
    statusData: any[] = [];
    currenciesData: any[] = [];
    supplierContactData: any[] = [];
    functionData: any[] = [];
    contractGroupData:any[]=[];
    submitted = false;
    uniqueMessage = false;
    selectedEngagementId: number = 0;
    IsDisabled: boolean = true;
    engagementDropdownBindingDataList:EngagementDropdownBinding[]=[];
    engagementDropdownBindingData=new EngagementDropdownBinding();
    startDate: any;
    endDate: any;
    EngagementId:any;
    editedContractId: any;
    isEngagementDisabled = true;
    _dateformart: any;
    isLoading = false;
    executeCanExist: boolean = true;
    showAttachmentButton: boolean = false;
    showPOAttachmentButton: boolean = false;
    showChangeOrderAttachmentButton: boolean = false;
    showAddendumAttachementButton: boolean = false;
    sDate: any;
    edate: any;
    changeOrders: any;
    showAttachedFile: boolean = true;
    showPOAttachedFile: boolean = true;
    showAddendumAttachedFile: boolean = true;
    showChangeOrderAttachedFile: boolean = true;
    _enumBreadcrumb: any;
    oldContractData: any;
    uploadedPOFileData: any;
    multiFileData: any[] = [];
    multiPOFileData: any[] = [];
    _changeOrders: ContractChangeOrder[] = [];
    _changeOrder = new ContractChangeOrder();
    changeOrderSaved = false;
    selectedItem: any;
    oldSelectedItem: any;
    changeOrderDataSource: any[] = [];
    changeOrderValue: number = 0;
    displayChangeOrderDialog: boolean = false;
    finalValue: any;
    contractCurrency: any;
    uploadedMultipleChangeOrderFileData: any[] = [];
    timeStamp: any;
    formData = new FormData();
    oldChangeOrderValue: any;
    displayAdendumDialog: boolean = false;
    _ContractaddendumDetails: any;
    _addendumDetails: ContracAddendumDetails[] = [];
    _addendumDetail = new ContracAddendumDetails();
    addendumDetailsSaved = false;
    addendumDetailStatusDataSource: any[] = [];
    uploadedMultipleAddendumFileData: any[] = [];
    addendumDetailsDataSource: any[] = [];
    currentDialog: string = "";
    sponsorContactsDataSource: SponsorContact[] = [];
    _addendumDetailsAfterDataRetrive: any;
    _contractBillingTypes = new ContractBillingTypes();
    _contractBillingTypestosave = new ContractBillingTypes();
    _contractBillingTypesDetails: ContractBillingTypes[] = [];
    meetingId: any;
    FromType = 1;
    associateTeamId: any;
    viewTeamPrivilege:boolean=false;
    viewFinancePrivilege:boolean=false;

    currency:any;
    contractGroup:any;
    ContractCreatedUserName:string='';
    selectedContractGroup: string = '';
    dialogFormGroup: FormGroup;
    stakeholderSponsorContacts: any[] = [];
    stakeholderSupplierContacts:any[]=[];
    selectedSupplierContactId: any;
    selectedSponsorContactIds: any[] = [];
    selectedSponsorContactId:any;
    supplierContactList:SupplierContact[]=[];
    sponserContactList:ITbl_SponserContacts[]=[];
    supplierId:any;
    _supplierContact= new SupplierContact();
    _supplierContacts: SupplierContact[]=[];
    sponsorId:any;
    engagementsData:any;
    supplierContacts:any;
    sponsorContacts:any;
    stakeholdercontactData:any[]=[];
    notificationOptionsDataSource: CodeListValues[] | undefined;
    
    EngagementName:any;
    contractStartDate:any;
    supplierContactsFiltered: any[] = [];
    stakholderSponsorContactsFiltered:any;
    sponsorContactsFiltered: any[] = [];
    StakholderSupplierContactsFiltered:any;
    constructor(private privilegeService: PrivilegeService,private _masterDataService: SponserService,
        private _contractService: ContractService,
        private router: Router,
        private confirmationService: ConfirmationService,
        private messageService: MessageService,
        private fb: FormBuilder,
        private activeRouter: ActivatedRoute,
        private loaderService: LoaderService,
        private _fileService: FileUploadService,
        private breadcrumbService: BreadcrumbService,
        private datepipe: DatePipe,
        private notificationService:NotificationService,
        private auditLogService:AuditLogService,
        private currencyService: CurrencyService,
        private engagementService: EngagementService,
        private _mastersupplierService: MasterSupplierService,
        private masterDataService:SponserService,
        private _service:SponserService,private tabAndStateService: TabAndStateService) {

        this.myContractDetailsForm = this.fb.group({
            title: ['', [Validators.required, Validators.maxLength(100), this.validateAlphaCharacters]],
            contractId: ['', [Validators.required, Validators.maxLength(15)]],
            startDate: ['', [Validators.required]],
            endDate: ['', [Validators.required]],
            supplierId: ['', [Validators.required]],
            supplierMasterServiceAgreementId: [ [Validators.required]],
            billingTypeId: [null, [Validators.required, this.validateMaxSelections.bind(this)]],
            SponserContactId: ['', [Validators.required]],
            statusMappingId: ['', [Validators.required]],
            currencyId: ['', [Validators.required]],
            ownerSupplierContactId: ['', [Validators.required]],
            // ownerFunctionId: ['', [Validators.required]],
            ownerFunctionId: [''],
            value: ['', [Validators.required, Validators.maxLength(15), this.validateNumericField]],
            totalValue: ['', [Validators.required]],
            valuePerYear: ['', [Validators.required]],
            description: ['', [Validators.maxLength(300)]],
            notesandComments: ['', [Validators.maxLength(300)]],
            term: [''],
            //sowfileSource:['', Validators.required],sowFileName: '',
            sowfileSource:[''],sowFileName: '',
            ponumber: ['', [Validators.required, Validators.maxLength(15), this.noOnlySpacesValidator, Validators.pattern(/^\S*$/)]],
            povalue: ['', [Validators.required, Validators.maxLength(15), this.validateNumericField]],
            postartDate: ['', [Validators.required]],
            poendDate: ['', [Validators.required]],
            poremarks: [''],
            //pofileSource:['', Validators.required],poFileName: '',
            pofileSource:[''],poFileName: '',
        });
        this.contractsChangeOrderForm = this.fb.group({
            _changeOrderTitle: ['', [Validators.required, Validators.maxLength(100), Validators.minLength(3), Validators.pattern(/^[\S\s]*$/), this.noOnlySpacesValidator]],
            _changeOrderStartDate: ['', [Validators.required]],
            _changeOrderEndDate: ['', [Validators.required]],
            //_changeOrderValue: ['', [Validators.required, Validators.maxLength(15), this.validateNumericField]],
            _changeOrderValue:['',[Validators.required, Validators.maxLength(15),this.validateNumericFieldwithNegative]],
            _changeOrderCurrency: [''],
            //_changeOrderfileSource :['',[Validators.required]],_changeOrderFileName:'',
            _changeOrderfileSource :[''],_changeOrderFileName:'',
            _changeOrderStatus: ['', [Validators.required]],
            _changeOrderDescription: ['', [Validators.maxLength(300)]],
        });
        this.contractsAddendumDetailsForm = this.fb.group({
            _addendumDetailTitle: ['', [Validators.required, Validators.maxLength(100), Validators.pattern(/^[\S\s]*$/), this.noOnlySpacesValidator]],
            _addendumDetailStartDate: ['', [Validators.required]],
            _addendumDetailEndDate: ['', [Validators.required]],
           //_addendumDetailfileSource :['',[Validators.required]],_addendumDetailFileName:'',
            _addendumDetailfileSource :[''],_addendumDetailFileName:'',
            _addendumDetailStatus: ['', [Validators.required]],
            _addendumDetailDescription: ['', [Validators.maxLength(300)]],
        });
        this.dialogFormGroup = this.fb.group({
           contactName: ['', [Validators.required,Validators.maxLength(30),Validators.pattern(/^[a-zA-Z\s]{1,30}$/),this.notOnlySpacesValidator]], 
           contactDesignation:['', [Validators.maxLength(100),Validators.minLength(3),this.notOnlySpacesValidator]],
            countryCode: [''],
            phoneNumber:['',[Validators.minLength(7),Validators.maxLength(15)]],
            email:['',[Validators.required,Validators.maxLength(100)]]
          });
        this.contactFormGroup=this.fb.group({
            contractGroupName:['', [Validators.required,Validators.maxLength(50),Validators.minLength(3),this.validateOnlyNumberandSpecialCharecters,this.noOnlySpacesValidator]]
          });
        this.GetCurrencyData();
        this.getAllEngagementsData();

    }

    validateAlphaCharacters(control: AbstractControl): ValidationErrors | null {
        const value = control.value;

        
        const pattern = /^[\S\s]*$/;

        if (value && !pattern.test(value)) {
            return { invalidAlphaCharacters: true };
        }

        return null;
    }

    validateOnlyNumberandSpecialCharecters(control: AbstractControl): ValidationErrors | null{
        const alphaRegex = /[a-zA-Z]/; // Ensure at least one alphabetic character
        const value = control.value ? control.value.trim() : '';
        if (value && !alphaRegex.test(value)) {
          return { invalidNumberSpecialCharecters: true };
        }
        return null;
      }
    validateNumericField(control: any) {
        const numericRegex = /^\d+(\.\d{1,2})?$/;
        if (control.value && !numericRegex.test(control.value)) {
            return { invalidNumber: true };
        }
        return null;
    }
    validateNumericFieldwithNegative(control: any) {
        const numericRegex = /^-?\d+(\.\d{1,2})?$/; //  allow negative numbers
        if (control.value && !numericRegex.test(control.value)) {
          return { invalidNumber: true };
        }
        return null;
      }
      trimInput() {
        const control = this.contactFormGroup.get('contractGroupName');
        if (control && control.value) {
          control.setValue(control.value.trim());
        }
      }
    auditIdentifierDetails:any;
    auditEntities:any;
    auditOperations:any;
    auditModule:any;
    countryCodes:any;
    ngOnInit() {
         let datenow = new Date();
         this.currency= this.activeRouter.snapshot.queryParamMap.get('currency');

        this.timeStamp = formatDate(datenow, 'ddMMyyyy-hhmmss', 'en-US');
        //this._currentKpiChildComponent = 1;
        //this._currentInvoiceChildComponent=1;
        this.loaderService.showLoader();
        this.loaderService.hidefinalLoader();
        this._dateformart = Dateformat;
        this._enumBreadcrumb = breadcrumnsCodes;
        this.auditIdentifierDetails=AuditIdentifier; 
        this.auditEntities=AuditLogEntities;
        this.auditOperations=AuditOperations;
        this.auditModule=AuditLogModule;
        this.editedContractId = this.activeRouter.snapshot.queryParamMap.get('ContractId')
        this.EngagementId = this.activeRouter.snapshot.queryParamMap.get('engagementId');
        setTimeout(() => {
            this.loaderService.showLoader();
            this.PopulateContractModel(this.editedContractId);
            this.loaderService.hidefinalLoader();
        }, 1000);
        
        if (this.editedContractId == "" || this.editedContractId == null) {
            this.router.navigate(['/home']);
        }
        if (this.EngagementId == null || this.EngagementId == "") {
            this.isfromEngagement = false;
            this.generateBreadcrumb(this._enumBreadcrumb.EVCON, '', "Details");
        }
        else {
            this.isfromEngagement = true;
        }
        this._service.getCodeListData(0,'').subscribe(
            data=>{
              this.countryCodes=data.data;
              
            }
          );
        this.GetBillingTypeCodeList();
        this.GetAllNotificationOptions(20);
        this.GetContractStatus();
        this.GetStatusMasterData(3, 'Change Order');
       

        this.loaderService.hidefinalLoader();
        this.BindPrivileges();
    }
    BindPrivileges(){
        const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);
         this.viewTeamPrivilege= hasPermission(Privileges.ViewContractTeams);
         this.viewFinancePrivilege= hasPermission(Privileges.ViewContractFinance);
        }
    oldContractChangeOrderData:any;
    contractDetailBeforeUpdate:any;
    oldContractAddendumData:any;
    contractAddendumListBeforeUpdate:any;
    contractChangeOrderDetailListBeforeUpdate:any;
    msaId: number = 0;
    selectedCurrencyId: any;
    previousContractData:any;
    engagementId:any;
    filteredSupplierContacts: any[] = [];
    filteredSponsorContacts: any[] = [];
    supplierStakeholderDetails:any[]=[];
    sponsorStakeholderDetails:any[]=[];
    SponsorContactId:number=0;
    SupplierContactId:number=0;
    PopulateContractModel(contractId: number) {
        this._contractService.GetContractDtailsById(contractId).subscribe(
            res => {

                this.previousContractData=res.data;
           
                this.engagementId=res.data.engagementId;
                this.contractStartDate=res.data.startDate;
                this.contractDetails.Id = res.data.id;
                this.contractDetails.EngagementId=this.engagementId; 
                this.msaId = res.data.engagementMasterServiceAgreementId;
                this.contractDetails.Title = res.data.title;
                this.supplierStakeholderDetails = res.data.supplierStakeholderDetails;
                this.sponsorStakeholderDetails=res.data.sponsorStakeholderDetails;
                this.generateBreadcrumb(this._enumBreadcrumb.EVCON, this.contractDetails.Title, "Details");
                this.contractDetails.ContractId = res.data.contractId;
                setTimeout(() => {
                    
                    this.GetEngagementDataById(this.engagementId);
                    this.loaderService.hideLoader();
                }, 1000);

                setTimeout(() => {
                    this.contractDetails.POFileName = res.data.poFileName;
                    this.contractDetails.POFilePath = res.data.poFilePath;
                    this.contractDetails.SowFileName = res.data.sowFileName;
                    this.contractDetails.SowFilePath = res.data.sowFilePath;
              
                if(res.data.poFileName == null || res.data.poFileName == ""){
                    this.showPOAttachmentButton=true;
                    this.showPOAttachedFile=false;
                }
                if(res.data.sowFileName == null || res.data.sowFileName == ""){
                this.showAttachmentButton = true;
                this.showAttachedFile=false;
                }

                this.contractDetails.StartDate = new Date(res.data.startDate);
                this.contractDetails.EndDate = new Date(res.data.endDate);
                this.contractDetails.Term = res.data.term;
                this.contractDetails.Value = res.data.value;
                this.contractDetails.TotalValue = res.data.totalValue;
                this.contractDetails.NotesandComments = res.data.notesAndComments;
                this.contractDetails.Description = res.data.description;
                this.contractDetails.createdBy = res.data.createdBy;
                this.ContractCreatedUserName=this.privilegeService.getUserName(res.data.createdBy);
                this.contractDetails.createdDate = res.data.createdDate;
                this.contractDetails.SponserContactId = res.data.sponserContactId;
                this.contractDetails.OwnerSupplierContactId = res.data.ownerSupplierContactId;
                this.SponsorContactId=res.data.sponserContactId;
                this.SupplierContactId= res.data.ownerSupplierContactId;
                this.contractDetails.StatusMappingId = res.data.statusMappingId;
                this.contractDetails.CurrencyId = res.data.currencyId;
                this.selectedCurrencyId = this.contractDetails.CurrencyId;
                const currency = this.currenciesData.filter(x => x.id == this.selectedCurrencyId);
                this.selectedCurrency = currency[0].name;
                this.contractDetails.OwnerFunctionId = res.data.ownerFunctionId;
                this.contractDetails.SupplierMasterServiceAgreementId= res.data.engagementMasterServiceAgreementId;
               
                this.contractDetails.ValuePerYear = res.data.valuePerYear;
                this.contractDetails.PONumber = res.data.poNumber;
                this.contractDetails.POValue = res.data.poValue;
                this.contractDetails.POStartDate = new Date(res.data.poStartDate);
                this.contractDetails.POEndDate = new Date(res.data.poEndDate);
                this.contractDetails.PORemarks = res.data.poRemarks;
                this.GetContractGroupData(this.engagementId);
                // setTimeout(() => {
                //     if (this.contractDetails.SponserContactId) {
                //         const selectedContact = this.sponsorContactsDataSource.find(contact => contact.id === this.contractDetails.SponserContactId);
                //         if (selectedContact) {
                //             this.sponserContactList = [selectedContact];
                //             this.updateSponsorDropdownOptions();
                //         }
                //     }
                //     if( this.contractDetails.OwnerSupplierContactId){
                //         const selectedContact = this.supplierContactData.find(contact => contact.id === this.contractDetails.OwnerSupplierContactId);
                //         if (selectedContact) {
                //             this.supplierContactList = [selectedContact];
                //             this.updateDropdownOptions();
                //         }
                //         this.excludeContact();
                //     }
                // }, 1000);



                this._contractBillingTypes.BillingTypeId = res.data.billingTypes.map((billingType: { billingTypeId: any; }) => billingType.billingTypeId);
                this.changeOrders = res.data.changeOrderDetails;
                this.changeOrderDataAftetRetrival = res.data.changeOrderDetails;
                this.changeOrders.forEach((data: { id: number; title: string | undefined; startDate: string | number | Date; endDate: string | number | Date; value: any; currencyId: number | undefined; statusMappingId: number; description: string | undefined; fileName: string | undefined; filePath: string | undefined; }) => {
                    const changeOrder: ContractChangeOrder = new ContractChangeOrder();
                    changeOrder.id = data.id;
                    changeOrder.title = data.title;
                    changeOrder.StartDate = new Date(data.startDate);
                    changeOrder.EndDate = new Date(data.endDate);
                    changeOrder.value = data.value;
                    changeOrder.currencyId = data.currencyId;
                    changeOrder.statusMappingId = data.statusMappingId;
                    changeOrder.description = data.description;
                    changeOrder.fileName = data.fileName;
                    changeOrder.filePath = data.filePath;


                    let selectedChangeOrderStatus = this.changeOrderDataSource?.filter(t => t.id == changeOrder.statusMappingId);
                    if (selectedChangeOrderStatus.length > 0 && selectedChangeOrderStatus[0].name != undefined)
                        changeOrder.status = selectedChangeOrderStatus[0].name;


                    if (changeOrder.currencyId != 0) {
                        let selectedCurrencyCode = this.currenciesData?.filter(t => t.id == changeOrder.currencyId);
                        if (selectedCurrencyCode.length > 0 && selectedCurrencyCode[0].name != undefined)
                            changeOrder.currency = selectedCurrencyCode[0].code;
                        this.contractCurrency = selectedCurrencyCode[0].code;
                    }
                    this._changeOrders.push(changeOrder);
                });
                this.GetChangeOrdersStatus();
                this.GetCurrencyForChangeOrders();
                
                this.myContractDetailsForm.patchValue({
                    sowfileSource: res.data.sowFileName
                })

                this.contractDetails.POFileName = res.data.poFileName;
                this.contractDetails.POFilePath = res.data.poFilePath;
                this.myContractDetailsForm.patchValue({
                    pofileSource: res.data.poFileName
                })

                this.CalculateTotalValue(res.data.value, this.changeOrders);
                this.oldContractData = JSON.stringify(this.contractDetails);
                this._addendumDetailsAfterDataRetrive = res.data.addendumDetails;

                const modifiedAddendumDetails = res.data.addendumDetails.map((addendum: any) => {
                    const { startDate, endDate, ...rest } = addendum;
                    const modifiedObject = { ...rest, StartDate: addendum.startDate, EndDate: addendum.endDate };
                    return modifiedObject;
                });

                this._addendumDetails = modifiedAddendumDetails;

                this._addendumDetails = this._addendumDetails.map(item => ({
                    ...item,
                    EndDate: this.contractDetails.EndDate
                  }));


                this.GetAddendumStatus();

            }, 1000);
            
                this.oldContractData=JSON.stringify(res.data);
                this.oldContractChangeOrderData=JSON.stringify(res.data.changeOrderDetails);
                this.oldContractAddendumData=JSON.stringify(res.data.addendumDetails);
                this.contractDetailBeforeUpdate=JSON.stringify(res.data);
                this.contractChangeOrderDetailListBeforeUpdate=JSON.stringify(res.data.changeOrderDetails);
                this.contractAddendumListBeforeUpdate=JSON.stringify(res.data.addendumDetails);

            }
        );

    }

filterSupplierContacts() {
if(this.supplierContactData.length>0){
    this.supplierStakeholderDetails.forEach((item1:any, index:any) => {
this.stakeholdersuppliercontactData.push({
  id:  item1.supplierContactId,
  name: '',
  designation:'',
  countrycode: '',
  phonenumber:'',
  isSendnotification:item1.isSendNotification,
  isdeleted:false,
  supplierContactId:item1.supplierContactId,
  sendNotificationOptions:item1.sendNotificationOptions
});

this.supplierContactDetails({ value: item1.supplierContactId }, index);
});
}

}
filterSponsorContacts() {
if(this.sponsorContactsDataSource.length>0){
    this.sponsorStakeholderDetails.forEach((item:any, index:any) => {
      this.stakeholdercontactData.push({
        id:  item.sponsorContactId,
        name: '',
        designation:'',
        countrycode: '',
        phonenumber:'',
        isSendnotification:item.isSendNotification,
        isdeleted:false,
        sponsorContactId:item.sponsorContactId,
        sendNotificationOptions:item.sendNotificationOptions
      
      });

      this.sponsorContactDetails({ value: item.sponsorContactId }, index);
    });
  }
}
    GetCurrencyForChangeOrders() {
        let i = 0;
        if (this.changeOrders != undefined && this.changeOrders.length > 0) { //Matching the contract Details currency id with code list for change orders tiles
            this.changeOrders.filter((ChangeOrds: any) => {
                let  filteredcurencys = this.currenciesData.filter((currency: any) => currency.id === ChangeOrds.currencyId);
                if (filteredcurencys != null && filteredcurencys != undefined && filteredcurencys.length > 0) {
                    this.changeOrders[i].currencyId = filteredcurencys[0].code;
                }
                i++;
            })
        }
    }

    CalculateTotalValue(contractValue: number, changeOrderDetails: any) {
        this.contractDetails.TotalValue = contractValue;
        if (changeOrderDetails != null && changeOrderDetails != undefined && changeOrderDetails.length>0){
            for (const changeOrder of changeOrderDetails) {
                this.contractDetails.TotalValue += changeOrder.value;
            }
        }
        
    }
      //get SendNotification options data
      GetAllNotificationOptions(type:number) {
        this.masterDataService.getCodeListData(type,'').subscribe(
          res=>{
            this.notificationOptionsDataSource=res.data;
          }
        );
      };
    GetBillingTypeCodeList() {
        this._masterDataService.getCodeListData(2, '').subscribe(
            data => {
                this.BillingTypeData = data.data;

            }
        );
    }

    GetContractStatus() {
        this._masterDataService.getCodeListData(3, 'Contract').subscribe(
            data => {
                this.statusData = data.data;

            }
        );
    }

    changeOrderStatus: any;
    GetChangeOrdersStatus() {
        this._masterDataService.getCodeListData(3, 'Change Order').subscribe(
            data => {
                this.changeOrderStatus = data.data;
                let i = 0;//Matching the contract Details status id with code list for change orders tiles
                if (this.changeOrders != undefined && this.changeOrders.length > 0) {
                    this.changeOrders.filter((ChangeOrds: any) => {
                        let filteredstatusChangeOrder = this.changeOrderStatus.filter((status: any) => status.id === ChangeOrds.statusMappingId);
                        if (filteredstatusChangeOrder != null && filteredstatusChangeOrder != undefined && filteredstatusChangeOrder.length > 0) {
                            this.changeOrders[i].statusMappingId = filteredstatusChangeOrder[0].name;
                        }
                        i++;
                    })
                }
            }
        );
    }

    GetCurrencyData() {
        this._masterDataService.getCodeListData(4, '').subscribe(
            data => {
                this.currenciesData = data.data;
            }
        );
    }

    GetContractGroupData(id: number) {
        this.engagementService.GetContractByEngagementId(id).subscribe(
            res => {
                this.contractGroupData = res.data;
              
                if (this.contractDetails.OwnerFunctionId != null) {
                    const matchingFunction = res.data.find((func: { id: number | undefined; }) => func.id == this.contractDetails.OwnerFunctionId);
                    this.selectedContractGroup = matchingFunction.name ? matchingFunction.name : '';
                    if (matchingFunction) {
                        this.contractDetails.OwnerFunctionId = matchingFunction.id;
                    } else {
                        this.contractDetails.OwnerFunctionId = 0;
                    }
                } else {
                    this.contractDetails.OwnerFunctionId = 0;
                }
            });
    }
    
   

    engagementData:any;
    getAllEngagementsData(){
        this.loaderService.showLoader();
        this.engagementService.GetEngagementList().subscribe(
          data=>{
            this.engagementData=data.data;
            this.engagementData.forEach((item: { id: number | undefined; engagementName: string | undefined; engagementID: string; }) => {
              this.engagementDropdownBindingData=new EngagementDropdownBinding();
              this.engagementDropdownBindingData.Id=item.id;
              this.engagementDropdownBindingData.Name=item.engagementName;
              this.engagementDropdownBindingData.Label=item.engagementName + ' [' + item.engagementID + ']';
             this.engagementDropdownBindingDataList.push(this.engagementDropdownBindingData);
             
            });
      
             this.loaderService.hideLoader();
          }
        );
      }

    supplierName: string = "";
    GetEngagementDataById(engagementId: any) {
        this.engagementService.GetEngagementById(engagementId).subscribe(
            res => {
                this.supplierContactData = res.data.supplierContacts;
                this.supplierMSAdata = res.data.engagementMasterServiceAgreements;
                let selectedMSA = this.supplierMSAdata?.filter(t => t.id == this.msaId);
                this.supplierId=res.data.supplierId;
                this.sponsorId=res.data.sponsorId;
                this.supplierName = res.data.engagementName;
                this.EngagementName=res.data.engagementName;
                this.sponsorContacts=res.data.sponsorContacts;
                this.supplierContacts=res.data.supplierContacts;
                this.GetSuppliersData(res.data.supplierId);
                 this.GetSponsorsData(res.data.sponsorId);
                if (selectedMSA !=undefined && selectedMSA.length > 0) {
                    this.PaymentTermName = selectedMSA[0].paymentTermName;
                }

                if (!this.isfromEngagement) {
                    this.generateBreadcrumb(this._enumBreadcrumb.EVCON, this.contractDetails.Title, "Details");
                }
                else {
                    this.breadcrumbService.generateBreadcrumb(this._enumBreadcrumb.VECONFE,this.supplierName,"Contracts",this.contractDetails.Title,this.EngagementId,"Details");
                }
              
                //Mutual excludeContact after data source
                 setTimeout(() => {
                    if (this.contractDetails.SponserContactId) {
                        const selectedContact = this.sponsorContactsDataSource.find(contact => contact.id === this.contractDetails.SponserContactId);
                        if (selectedContact) {
                            this.sponserContactList = [selectedContact];
                            this.updateSponsorDropdownOptions();
                        }
                    }
                    if( this.contractDetails.OwnerSupplierContactId){
                        const selectedContact = this.supplierContactData.find(contact => contact.id === this.contractDetails.OwnerSupplierContactId);
                        if (selectedContact) {
                            this.supplierContactList = [selectedContact];
                            this.updateDropdownOptions();
                        }
                        this.excludeContact();
                    }
                },2000);
            }
        );
    }
    supplierData:any;
    sponsorInfo:any;
    totalSupplierContacts:any;
    _supplierName:any="";
    GetSuppliersData(Id:any){
        this._mastersupplierService.getSupplierById (Id).subscribe(
          res=>{
              if(res.data){
                this._supplierName=res.data.name;
             this.supplierContactData=res.data.supplierContacts;
              this.supplierData=res.data;
              this.supplierContacts=res.data.supplierContacts;
              this.StakholderSupplierContactsFiltered= this.supplierContactData;
              this.supplierContactsFiltered=this.supplierContactData;  
              this.totalSupplierContacts=  res.data.supplierContacts;    
              this.filterSupplierContacts();
              }
          })
      }  
      totalSponsorContact:any;
      GetSponsorsData(Id:any){
        this._masterDataService.getSponserData (Id).subscribe(
          res=>{
            this.sponsorInfo=res.data;
            this.sponsorContactsDataSource = res.data.sponserContacts;
            this.sponsorContacts=res.data.sponserContacts;
            this.stakholderSponsorContactsFiltered = this.sponsorContactsDataSource;
            this.sponsorContactsFiltered = this.sponsorContactsDataSource;
            this.totalSponsorContact=res.data.sponserContacts;
            this.filterSponsorContacts();
          })
      }
    onEngagementDropdownChange(event: any) {
        const selectedValue = event.value;
        this.selectedEngagementId = selectedValue;
    }
    contractId:any;
    isContractIdExist(event: any) {
        if(!event.target){
            this.contractId=event;
        }
        else{
        this.contractId = event.target.value;
        }
        this.contractId=this.contractId?.replace(/-/g, '~');
        this._contractService.CheckContractIdUniqueness(this.contractId, this.editedContractId).subscribe(
            res => {
                if (res.data == 1) {
                    this.uniqueMessage = true;
                }
                else {
                    this.uniqueMessage = false;
                }

            }
        );
    }

    onValueChange(event: any) {
        
        const value = event;
        let contractVal=0;
        if(value != null)
        {
        contractVal = parseFloat(value);
        }

        


        
        this.CalculateTotalValue(contractVal, this._changeOrders);
        
    }


    differenceYears: any;
    differenceMonths: any;
    differenceDays: any;
    DateDifference(): string {
        this.startDate = this.contractDetails.StartDate;
        this.endDate = this.contractDetails.EndDate;
        const timeDifference = this.endDate.getTime() - this.startDate.getTime();

        this.differenceYears = Math.floor(timeDifference / (365 * 24 * 60 * 60 * 1000));
        const remainingMilliseconds = timeDifference - (this.differenceYears * 365 * 24 * 60 * 60 * 1000);

        this.differenceMonths = Math.floor(remainingMilliseconds / (30 * 24 * 60 * 60 * 1000));

        const remainingMillisecondsAfterMonths = remainingMilliseconds - (this.differenceMonths * 30 * 24 * 60 * 60 * 1000);

        this.differenceDays = Math.floor(remainingMillisecondsAfterMonths / (24 * 60 * 60 * 1000));



        if (this.differenceYears == 0 && this.differenceMonths == 0 && this.differenceDays > 0) {
            return `1 months`;
        }
        else if (this.differenceYears == 0 && this.differenceMonths > 0) {
            return `${this.differenceMonths} months `
        }
        else if (this.differenceYears > 0 && this.differenceMonths == 0) {
            return `${this.differenceYears} years`
        }
        else if (this.differenceYears == 0 && this.differenceMonths == 0 && this.differenceDays == 0) {
            return `0`;
        }
        else {
            return `${this.differenceYears} years and ${this.differenceMonths} months`;
        }

    }


    onStartDateChange(event: any) {

        //Check Start Date with other dates
        this.checkContractStartDate(event);

        if (!this.startDateMessageFlag) {
            this.startDate = event;

            if (this.startDate != null && this.startDate != undefined && this.contractDetails.EndDate != null && this.contractDetails.EndDate != undefined) {

                if (this.startDate > this.contractDetails.EndDate) {
                    this.myContractDetailsForm.patchValue({
                        endDate: null,
                        term: null
                    });

                }
                else {
                    this.contractDetails.Term = this.DateDifference();
                }
            }
        }
        else {
            this.startDateMessageFlag = false;
            this.myContractDetailsForm.patchValue({
                startDate: this.startDate,

            });

        }
    }




    startDateMessageFlag = false;
    checkContractStartDate(startDate: any) {
        this.startDateMessageFlag = false;
    
        this.checkStartDateAgainstChangeOrders(startDate);
        if (!this.startDateMessageFlag) {
            this.checkStartDateAgainstAddendums(startDate);
        }
        if (!this.startDateMessageFlag) {
            this.checkStartDateAgainstPO(startDate);
        }
    
        if (this.startDateMessageFlag) {
            this.displayStartDateErrorMessage();
        }
    }
    
    private checkStartDateAgainstChangeOrders(startDate: any) {
        if (this._changeOrders) {
            for (const item of this._changeOrders) {
                if (item.StartDate && startDate > item.StartDate) {
                    this.startDateMessageFlag = true;
                    break;
                }
            }
        }
    }
    
    private checkStartDateAgainstAddendums(startDate: any) {
        if (this._addendumDetails) {
            for (const item of this._addendumDetails) {
                if (item.StartDate && startDate > item.StartDate) {
                    this.startDateMessageFlag = true;
                    break;
                }
            }
        }
    }
    
    private checkStartDateAgainstPO(startDate: any) {
        if (this.contractDetails.POStartDate && startDate > this.contractDetails.POStartDate) {
            this.startDateMessageFlag = true;
        }
    }
    
    private displayStartDateErrorMessage() {
        this.messageService.add({
            key: 'tc',
            severity: 'error',
            summary: 'Contract Start Date cannot be after Purchase Order, Change Order, or Addendum Start Dates. Please change the Dates on Purchase Orders, Change Orders, and/or Addendums first.',
            detail: ''
        });
    }
    



    onEndDateChange(event: any) {
        this.checkContractEndDate(event);
        let endDate = event;
        if (this.contractDetails.StartDate != null && this.contractDetails.StartDate != undefined && endDate != null && endDate != undefined) {

            this.contractDetails.Term = this.DateDifference();

        }

        this._addendumDetails = this._addendumDetails.map(item => ({
            ...item,
            EndDate: endDate
          }));
    }

    calculateValuePerYearOnValueChange(event: any) {
        if (this.contractDetails.Term != null && this.contractDetails.Term != undefined && event != null && event != undefined) {
            this.CalculateValuePerYear(event);
        }
    }

    calculateValuePerYearOnTermChange(event: any) {
        if (this.contractDetails.TotalValue != null && this.contractDetails.TotalValue != undefined && this.differenceYears != null && this.differenceYears != undefined) {
            
           
            this.CalculateValuePerYear(this.contractDetails.TotalValue);
        }
       
    }

    CalculateValuePerYear(value: number) {
        if (this.differenceYears == 0 && this.differenceMonths == 0 && this.differenceDays > 0) {
            this.differenceMonths = 1;
        }
        if (this.differenceYears == 0 && this.differenceMonths <= 12) {
            this.contractDetails.ValuePerYear = value;
        }
        else {
            const totalMonths = this.differenceYears * 12 + this.differenceMonths;
            if (totalMonths !== 0) {
                this.contractDetails.ValuePerYear = value / totalMonths * 12;
                this.contractDetails.ValuePerYear = Math.round(this.contractDetails.ValuePerYear * 100) / 100;
            } else {
                this.contractDetails.ValuePerYear = 0; // Handle divide by zero or invalid input
            }
        }
    }



  isSaveBtnDisable = false;
  async saveContractData() {
        this.submitted = true;
      this.isSaveBtnDisable = true;

      setTimeout(() => {
        this.isSaveBtnDisable = false;
      }, 3000);
         this.isContractIdExist(this.contractDetails.ContractId);
      setTimeout(() => {
      if (this.myContractDetailsForm.valid && !this.uniqueMessage) {
            this.loaderService.showLoader();
            this.sDate = this.contractDetails.StartDate;
            this.edate = this.contractDetails.EndDate;
            if (this.sDate > this.edate) {
                this.messageService.add({ key: 'tc', severity: 'error', summary: 'Start Date should be less than the End Date', detail: '' });
            }
            else {
                this.executeCanExist = false;
                this.contractDetails.updatedBy =this.privilegeService.getLoginUserId();
                this.contractDetails.updatedDate = new Date();
                
                if (this.multiPOFileData.length > 0 || this.multiFileData.length > 0 || this.multiChangeOrderFileData.length > 0 || this._editAddendumDeletedAttachemntFiles.length > 0) {
                    this.deleteSowFile();
                }
                else if (this.uploadedMultipleChangeOrderFileData.length > 0 || this.uploadedMultipleAddendumFileData.length > 0) {
                    this.uploadSOWFile();
                }
                else if((this.uploadedSOWFileData || this.uploadedPOFileData)){
                    this.uploadSOWFile();
                }
                else {
                    this.UpdateContract();
                }
            }
        }
  
        else {
            this.loaderService.hideLoader();
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Please enter required information', detail: '' });
        }
    }, 2000);
    }

    UpdateContract() {
        if (this._contractBillingTypes != undefined && this._contractBillingTypes.BillingTypeId && this._contractBillingTypes.BillingTypeId.length > 0) {
            for (let i = 0; i <= this._contractBillingTypes.BillingTypeId.length - 1; i++) {
                let selectedBillingTypeId = this._contractBillingTypes.BillingTypeId[i];
                this._contractBillingTypestosave.BillingTypeId = selectedBillingTypeId;
                this._contractBillingTypesDetails.push(this._contractBillingTypestosave);
                this._contractBillingTypestosave = new ContractBillingTypes();
            }
            this.contractDetails.BillingTypes = this._contractBillingTypesDetails;
        }
        this.contractDetails.StartDate = this.datepipe.transform(this.contractDetails.StartDate, "MM/dd/yyyy");
        this.contractDetails.EndDate = this.datepipe.transform(this.contractDetails.EndDate, "MM/dd/yyyy");
        this.contractDetails.POStartDate = this.datepipe.transform(this.contractDetails.POStartDate, "MM/dd/yyyy");
        this.contractDetails.POEndDate = this.datepipe.transform(this.contractDetails.POEndDate, "MM/dd/yyyy");
        this._changeOrders = this._changeOrders.map(Co => ({
            ...Co,
            StartDate: this.datepipe.transform(Co.StartDate, "MM/dd/yyyy"),
            EndDate: this.datepipe.transform(Co.EndDate, "MM/dd/yyyy")
        }));
        
        this._addendumDetails = this._addendumDetails.map(addendum => ({
            ...addendum,
            StartDate: this.datepipe.transform(addendum.StartDate, "MM/dd/yyyy"),
            EndDate: this.datepipe.transform(addendum.EndDate, "MM/dd/yyyy")
        }));

        if (this.uploadedSOWFileData != undefined && this.uploadedSOWFileData != null) {
            this.contractDetails.SowFileName = this.uploadedSOWFileData.name;
            this.contractDetails.SowFilePath = "Contracts/" + this.timeStamp + "_" + this.contractDetails.SowFileName;
        }
        if (this.uploadedPOFileData != undefined && this.uploadedPOFileData != null) {
            this.contractDetails.POFileName = this.uploadedPOFileData.name;
            this.contractDetails.POFilePath = "Contracts/" + this.timeStamp + "_" + this.contractDetails.POFileName;
        }

        this.contractDetails.SponsorStakeholderDetails=this.stakeholdercontactData.filter(record => record.id !== null && record.id !==0);
        this.contractDetails.SupplierStakeholderDetails=this.stakeholdersuppliercontactData.filter(record => record.id !== null && record.id !==0);
        this.contractDetails.ChangeOrderDetails = this._changeOrders;
        this.contractDetails.AddendumDetails = this._addendumDetails;
        this.contractDetails.EngagementId=this.engagementId;    
        
        this._contractService.UpdateContractDetails(this.contractDetails).subscribe(
            async res => {

             await this.notificationService.POUtilization(this.contractDetails.Id,this.sponsorId,this.supplierId,this.EngagementName);  //call the PO Utilization send notification service metod
             
              this.messageService.add({
                    key: 'tc',
                    severity: 'success',
                    summary: 'Contract Information has been saved',
                    detail: '',
                });
               
                if(this.isfromEngagement)
                {
                    this.router.navigate(['/viewcontract'], { queryParams: { ContractId: this.editedContractId,supplierId:parseInt(this.EngagementId),currency: this.currency}});
                }
                else{
                    this.router.navigate(['/viewcontract'], { queryParams: { ContractId: this.editedContractId,currency: this.currency}});
                }
                this.loaderService.hideLoader();
                this.compareModels();
            }

        );
    }


    BackToViewPage() {
        if (!this.isfromEngagement) {
            this.router.navigate(['/viewcontract'], { queryParams: { ContractId: this.editedContractId,currency: this.currency } });
        }
        else {
            this.tabAndStateService.setPreviousState('Contracts', 2);
          this.router.navigate(['/ViewEngagement', this.EngagementId]);
        }
    }
    infoChangeorderMessageDisplayed: any
    showDialog() {
        if (this.infoChangeorderMessageDisplayed) {
            return;
        }
        this.changeOrderDialog = true;
        this.changeOrderDialogHeader = 'Add Change Order';
        this.visible = true;
        this.showChangeOrderAttachedFile = false
        this.showChangeOrderAttachmentButton = true;
        this.selectedItem = null;
        this.currentDialog = 'changeOrder';
            this.resetChangeOrderForm();
            this.changeOrderSaved = false;
            this.GetStatusMasterData(3, 'Change Order');
            this._changeOrder = new ContractChangeOrder();
            this._changeOrder.currency = this.contractCurrency;
            this.displayChangeOrderDialog = true;
    }


    uploadedSOWFileData: any;
    uploadedChangeOrderFile: any;
    maxFileSize: any

    onSowFileChange(fileDetails: any) {
        this.maxFileSize = this._fileService._fileSize;
        // file upload on change event method
        if (fileDetails.length > 0) {
            let currentFileSize = fileDetails[0].size;
            let uploadedFileName = fileDetails[0].name;
            let uploadedExtension = uploadedFileName.split('.').pop().trim().toLowerCase();
            this.contractDetails.SowFileName = uploadedFileName;
            // Check if the uploaded file's extension is allowed
            if (this._fileService.allowedExtensions.includes(uploadedExtension)) {
                if (currentFileSize <= this.maxFileSize) {
                    this.myContractDetailsForm.patchValue({
                        sowFileName: fileDetails[0].name,
                    })
                    this.uploadedSOWFileData = fileDetails[0];
                    this.showAttachmentButton = false;
                    this.showAttachedFile = true;
                }

                else {
                    this.myContractDetailsForm.patchValue({
                        sowFileName: '',
                        sowfileSource: ''
                    })
                    this.contractDetails.SowFileName='';
                    this.messageService.add({ key: 'tc', severity: 'warn', summary: 'File Size limited to 20 Mb', detail: '' });
                }
            }
            else {
                this.myContractDetailsForm.patchValue({
                    sowFileName: '',
                    sowfileSource: ''
                })
                this.contractDetails.SowFileName='';
                this.messageService.add({ key: 'tc', severity: 'error', summary: 'Invalid file type. Only .pdf, .xls, .xlsx, .doc, .docx files are allowed.', detail: '' });
            }

         
        }
    }


uploadSOWFile() {
    const fileUploadObservables = [];

    const addFileUpload = (fileData: File | undefined, fileNameKey: keyof typeof this.contractDetails, filePathKey: keyof typeof this.contractDetails) => {
        if (fileData) {
            const formData = new FormData();
            formData.append('file', fileData, `Contracts/${this.timeStamp}_${fileData.name}`);

            fileUploadObservables.push(
                this._fileService.UploadSingleFile(formData).pipe(
                    map((res: boolean) => {
                        if (res) {
                            this.contractDetails[fileNameKey] = fileData.name;
                            this.contractDetails[filePathKey] = `Contracts/${this.timeStamp}_${fileData.name}`;
                        }
                        return res;
                    })
                )
            );
        }
    };

    addFileUpload(this.uploadedSOWFileData, 'SowFileName', 'SowFilePath');
    addFileUpload(this.uploadedPOFileData, 'POFileName', 'POFilePath');

    if (this.uploadedMultipleChangeOrderFileData.length > 0 || this.uploadedMultipleAddendumFileData.length > 0) {
        fileUploadObservables.push(
            this._fileService.UploadMultipleFile(this.addendumChangeOrderFormData).pipe(
                map((res: boolean) => res)
            )
        );
    }

    if (fileUploadObservables.length > 0) {
        forkJoin(fileUploadObservables).subscribe(() => this.UpdateContract());
    } else {
        this.UpdateContract();
    }
}
    downloadAttachemnt(fileName: any, filePath: any) { // download Uploaded Attachemnets 

        var filedetail = {
            "fileName": fileName,
            "filePath": filePath
        };
        this._fileService.DownloadFile(filedetail).subscribe(
            res => {
                var test = res;
                var blobData = this._fileService.dataURItoBlob(res);
                this._fileService.downloadBase64File(blobData, filedetail.fileName);
            }
        );

    }



    prepareDeleteSowFileObject(sowFileName: any, sowFilePath: any) {
        this.showAttachmentButton = true;
        this.showAttachedFile = false;
        
        this.myContractDetailsForm.patchValue({
            sowFileName: '',
            sowfileSource: ''
        })

       

        if(this.previousContractData.sowFileName == sowFileName && this.previousContractData.sowFilePath == sowFilePath)
        {
            var filedetail1 = {
                "fileName": sowFileName,
                "filePath": sowFilePath
            };
           this.multiFileData.push(filedetail1);
           
         
        }

        this.contractDetails.SowFileName = "";
        this.contractDetails.SowFilePath = "";
        this.uploadedSOWFileData=null;
    }
    prepareDeletePOFileObject(poFileName: any, poFilePath: any) {

        this.showPOAttachmentButton = true;
        this.showPOAttachedFile = false;
       
        this.myContractDetailsForm.patchValue({
            POFileName: '',
            pofileSource: ''
        })

        

        if(this.previousContractData.poFileName == poFileName && this.previousContractData.poFilePath == poFilePath)
        {
            var filedetail1 = {
                "fileName": poFileName,
                "filePath": poFilePath
            };
        this.multiPOFileData.push(filedetail1);
       
        }

        this.contractDetails.POFileName = "";
        this.contractDetails.POFilePath = "";
        this.uploadedPOFileData=null;

    }
    changeOrderDataAftetRetrival: any;
    DeleteChangeOrderFile(_changeOrderFileName: any, _changeOrderfileSource: any) {
        this._addendumOrChangeOrderAttachementRemoved = true;
        this.showChangeOrderAttachmentButton = true;
        this.showChangeOrderAttachedFile = false;
        this._changeOrder.fileName = "";
        this._changeOrder.filePath = "";
        this.contractsChangeOrderForm.patchValue({
            _changeOrderfileSource: null,
            _changeOrderFileName: null
        })

        var changeOrderFileDetail = {
            "fileName": _changeOrderFileName,
            "filePath": _changeOrderfileSource
        };
        if (changeOrderFileDetail.fileName != "" && changeOrderFileDetail.filePath != "" && this._changeOrder.id != 0) {
            var isFileExist = this.changeOrderDataAftetRetrival?.filter((t: { id: number; fileName: string; filePath: string }) => t.id == this._changeOrder.id && t.fileName == _changeOrderFileName && t.filePath == _changeOrderfileSource);
            if (isFileExist.length > 0) {
                const fileNameToRemove = _changeOrderFileName;
                const indexToRemove = this.multiChangeOrderFileData.findIndex(file => file.fileName === fileNameToRemove && file.filePath == _changeOrderfileSource);
                if (indexToRemove != 0) {
                    this.multiChangeOrderFileData.push(changeOrderFileDetail);
                }
            }
        }
    }
    POresult: any;
    deletePOFile() {

        if (this.multiPOFileData.length > 0) {
            this._fileService.DeleteFile(this.multiPOFileData).subscribe(
                res => {
                    this.POresult = res;
                    if (this.POresult && this.uploadedPOFileData != undefined && this.uploadedPOFileData != null) {
                        if (this.uploadedPOFileData) {
                            
                            
                            const formData = new FormData();

                            formData.append('file', this.uploadedPOFileData, "Contracts/" + this.timeStamp + "_" + this.uploadedPOFileData.name);
                            this._fileService.UploadSingleFile(formData).subscribe((res: any) => {
                                this.contractDetails.POFileName = this.uploadedPOFileData.name;
                                this.contractDetails.POFilePath = "Contracts/" + this.timeStamp + "_" + this.contractDetails.POFileName;

                            });
                        }

                    }
                    else {
                        this.messageService.add({ key: 'tc', severity: 'error', summary: 'PO Attached file failed to save', detail: '' });
                    }
                });
        }
    }



deleteSowFile() { // delete the files from s3 Bucket after remove the old attahment and new attachent in sow field, ChangeOrder, Addendum tiles

const fileDeletionObservables = [];

// Delete SOW File
if (this.multiFileData.length > 0) {
    fileDeletionObservables.push(
        this._fileService.DeleteFile(this.multiFileData).pipe(
            map(res => res)
        )
    );
}

// Delete PO File
if (this.multiPOFileData.length > 0) {
    fileDeletionObservables.push(
        this._fileService.DeleteFile(this.multiPOFileData).pipe(
            map(res => res)
        )
    );
}

// Delete Change Orders
if (this.multiChangeOrderFileData.length>0) {
    fileDeletionObservables.push(
        this._fileService.DeleteFile(this.multiChangeOrderFileData).pipe(
            map(res => res)
        )
    );
}

// Delete Addendum
if (this._editAddendumDeletedAttachemntFiles.length > 0) {
    fileDeletionObservables.push(
        this._fileService.DeleteFile(this._editAddendumDeletedAttachemntFiles).pipe(
            map(res => res)
        )
    );
}


// Use forkJoin to wait for all file uploads to complete
if(fileDeletionObservables.length>0){
forkJoin(fileDeletionObservables).pipe(
    map((results: any[]) => {
      // Handle individual results if needed
      //const result1 = results[0]; // Result of the first observable
      //const result2 = results[1]; // Result of the second observable
      // ...
      return results; // Or return any other value you need
    })
  ).subscribe(() => {
    // All file uploads are complete, now you can call UpdateContract
    this.uploadSOWFile();
  });
}
else{
    this.uploadSOWFile();
}

}





    

    deleteChangeOrderFile() {
        this._fileService.DeleteFile(this.multiChangeOrderFileData).subscribe(
            res => {
                var result = res;
                if (result && this.uploadedMultipleChangeOrderFileData.length > 0) {
                    this.uploadSOWFile();
                }
                else {
                    this.messageService.add({ key: 'tc', severity: 'error', summary: 'ChangeOrder Failed to Save', detail: '' });

                }
            }
        );


    }

    getDefaultMinDate(): Date {

        // Set your desired default minimum date

        return new Date() // For example, you can set it to the current date

    }




    _oldObject: string = "";
    _newObject: string = "";
    _message: string = "";
    _details: string = "";
    canExist(): boolean | Promise<boolean> | Observable<boolean> {
        this.CommonTabClick(this.previousSubTabIndex,this.Previoustabindex);

       
       
        if (this.executeCanExist && !(this._oldObject == this._newObject)) {
            return new Promise<boolean>((resolve) => {
                this.confirmationService.confirm({
                    message: this._message,
                    header: 'Confirmation',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {
                        this.messageService.add({
                            key: 'tc',
                            severity: 'info',
                            summary: this._details,
                            detail: '' ,
                        });
                        resolve(true);
                    },
                    reject: () => {
                        resolve(false);
                    },
                });
            });
        }
        else {
            return true;
        }
    }




    generateBreadcrumb(component?: string, recordName?: string, tabName?: string, childRecName?: string, recordId?: number, subTabName?: string) {
        this.breadcrumbService.generateBreadcrumb(component, recordName, tabName, childRecName, recordId, subTabName);
    }
    getBreadcrumbs(): MenuItem[] {
        return this.breadcrumbService.getBreadcrumbs();
    }
    tabindex: number = 0;
    Previoustabindex:number = 0
    showSummaryDetails=false;
    onTabSelect(event?: any) {
        this.tabAndStateService.scrollToTop(this.scrollContainer);
        this.tabindex=event.index;



        if(this.tabindex==1)
        {
         this._currentKpiChildComponent = 1;
        
        }
        else if(this.tabindex==2)
        {
            this.showSummaryDetails=true;
            this._currentInvoiceChildComponent=1;
        }







        this.CommonTabClick(this.previousSubTabIndex,this.Previoustabindex);
        if (this.isformObjectChange)
        {
            this.confirmationService.confirm({
              message: this._message,
              header: 'Confirmation',
              icon: 'pi pi-exclamation-triangle',
              accept: () => {
                this.Previoustabindex=this.tabindex;
                this.isformObjectChange=false;
                this._oldObject="";
                this._newObject="";
                this.ParentTabSelect(this.tabindex)
                this.resetallsubtabs();
                this.messageService.add({
                  key: 'tc',
                  severity: 'info',
                  summary: this._details,
                  detail: '',
              });
              },
              reject: () => {
               
                setTimeout(() => {
                  this.tabindex=this.Previoustabindex;
                  this.activeTabIndex = this.Previoustabindex;
                  this.tabView.activeIndex = this.Previoustabindex;
               },250);
               
              },
            });
        }
        else{
          this.Previoustabindex=this.tabindex;
          this.isformObjectChange=false;
          this._oldObject="";
          this._newObject="";
          this.resetallsubtabs();
          this.ParentTabSelect(this.tabindex)
        }
    }
    resetallsubtabs()
    {
      this._currentKpiChildComponent=1;
      this._currentMeetingChildComponent=1;
      this._currentAccrualChildComponent=1;
      this._currentForecastChildComponent=1;
      this._currentTeamChildComponent=1;
      this._currentInvoiceChildComponent=1;
      this.subTabindex = 0;
    }
    ParentTabSelect(ParentTabIndex:number)
    {
     
        this.tabindex=ParentTabIndex;
        switch (ParentTabIndex) {
            case 0:
                if (!this.isfromEngagement)
                    this.generateBreadcrumb(this._enumBreadcrumb.EVCON, this.contractDetails.Title, "Details");
                else {
                    this.generateBreadcrumb(this._enumBreadcrumb.VECONFE, this.supplierName, "Contracts", this.contractDetails.Title, this.EngagementId, "Details");
                }
                break;
            case 1:
                if (!this.isfromEngagement) {
                    this.generateBreadcrumb(this._enumBreadcrumb.EVCON, this.contractDetails.Title, "Governance");
                }
                else {
                    this.generateBreadcrumb(this._enumBreadcrumb.VECONFE, this.supplierName, "Contracts", this.contractDetails.Title, this.EngagementId, "Governance");
                }
                break;
            case 2:
                if (!this.isfromEngagement) {
                    this.generateBreadcrumb(this._enumBreadcrumb.EVCON, this.contractDetails.Title, "Finance");
                }
                else {
                    this.generateBreadcrumb(this._enumBreadcrumb.VECONFE, this.supplierName, "Contracts", this.contractDetails.Title, this.EngagementId, "Finance");
                }
                break;
            case 3:
                this._currentTeamChildComponent = 1;
                this.generateBreadcrumb(this._enumBreadcrumb.EVCON, this.contractDetails.Title, "Team");

                if (!this.isfromEngagement) {
                    this.generateBreadcrumb(this._enumBreadcrumb.EVCON, this.contractDetails.Title, "Team");
                }
                else {
                    this.generateBreadcrumb(this._enumBreadcrumb.VECONFE, this.supplierName, "Contracts", this.contractDetails.Title, this.EngagementId, "Team");

                }

                break;
        }
    }
    isSaveChangeOrderPopupBtnDisable = false;
    
    selectedCurrency: any
    saveChangeOrderData() {
        this.isSaveChangeOrderPopupBtnDisable = true;
        setTimeout(() => {
            this.isSaveChangeOrderPopupBtnDisable = false;
          }, 3000);
        var anyControlsTouched = this.checkIfAnyControlsTouched(this.contractsChangeOrderForm);
        if (this._addendumOrChangeOrderAttachementRemoved) {
            anyControlsTouched = this._addendumOrChangeOrderAttachementRemoved;
       }
      
            this.loaderService.showLoader();
            this.changeOrderSaved = true;
            if (this.contractsChangeOrderForm.valid && this._changeOrder.statusMappingId>0) {

                if (this.selectedItem == null) {
                    var selectedChangeOrderStatus = this.changeOrderDataSource?.filter(t => t.id == this._changeOrder.statusMappingId);
                    if (selectedChangeOrderStatus.length > 0 && selectedChangeOrderStatus[0].name != undefined)
                        this._changeOrder.status = selectedChangeOrderStatus[0].name;

                    if (this.contractCurrency != null && this.contractCurrency != undefined) {
                        var selectedCurrencyCode = this.currenciesData?.filter(t => t.name == this._changeOrder.currency);
                        if (selectedCurrencyCode.length > 0 && selectedCurrencyCode[0].name != undefined)
                            this._changeOrder.currency = selectedCurrencyCode[0].code;
                        this._changeOrder.currencyId = selectedCurrencyCode[0].id;
                        this.selectedCurrency = selectedCurrencyCode[0].name;
                    }

                    this.changeOrderValue = this._changeOrder.value;
                    this._changeOrders.push(this._changeOrder);
                    this.changeOrders.push(this._changeOrder);
                    this.onChangeOrderValueChange();
                    this.displayChangeOrderDialog = false;
                    this.messageService.add({
                        key: 'tc',
                        severity: 'success',
                        summary: 'Change Order has been saved',
                        detail: '',

                    });
                    

                }
                if (this.selectedItem != null) {

                    const contactIndex = this._changeOrders.findIndex(item => item.title === this.oldSelectedItem.title && item.StartDate === this.oldSelectedItem.StartDate && item.EndDate === this.oldSelectedItem.EndDate && item.value === this.oldSelectedItem.value && item.currency === this.oldSelectedItem.currency && item.status === this.oldSelectedItem.status);
                    if (contactIndex !== -1) {
                        this.oldChangeOrderValue = this.oldSelectedItem.value;
                        var selectedChangeOrderStatus = this.changeOrderDataSource?.filter(t => t.id == this._changeOrder.statusMappingId);
                        if (selectedChangeOrderStatus.length > 0 && selectedChangeOrderStatus[0].name != undefined)

                        this._changeOrders[contactIndex] = { ...this.selectedItem };
                        
                        this._changeOrders[contactIndex].status = selectedChangeOrderStatus[0].name;
                        this.selectedItem = null;
                        this.oldSelectedItem = null;
                        this.changeOrderValue = this._changeOrder.value;
                        this.onChangeOrderValueChange();
                    }
                    this.displayChangeOrderDialog = false;

                    this.messageService.add({
                        key: 'tc',
                        severity: 'success',
                        summary: 'Change Order has been saved',
                        detail: '',

                    });
                }
                if (this._changeOrder && this._changeOrder.EndDate && this.contractDetails && this.contractDetails.EndDate) {
                    if (this._changeOrder.EndDate > this.contractDetails.EndDate) {

                        this.contractDetails.EndDate = this._changeOrder.EndDate;
                        this._addendumDetails = this._addendumDetails.map(item => ({
                            ...item,
                            EndDate: this._changeOrder.EndDate
                          }));

                    }
                    else {
                        if (this._changeOrders.length > 0) {
                            const highestEndDate = this._changeOrders.reduce((maxDate, order) => {
                                const orderEndDate = order.EndDate ? new Date(order.EndDate) : null;
                                if (orderEndDate && orderEndDate > maxDate) {
                                    maxDate = orderEndDate;
                                }
                                return maxDate;
                            }, new Date(0));
                            if (this.contractDetails.EndDate < highestEndDate){
                                this.contractDetails.EndDate = highestEndDate;

                                this._addendumDetails = this._addendumDetails.map(item => ({
                                    ...item,
                                    EndDate: highestEndDate
                                  }));

                            }
                        }
                    }
                }
            }
            else {
                this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Please enter required information', detail: '' });
            }
        // }
        // else {
        //     this.displayChangeOrderDialog = false;
        // }

        this.isChangeOrderEdit = false;
        this.loaderService.hideLoader();

    }

    onChangeOrderValueChange() {
        this.finalValue = '';

        if (this.oldChangeOrderValue != null && this.oldChangeOrderValue != undefined && this.contractDetails.TotalValue) {
            this.contractDetails.TotalValue = (+this.contractDetails.TotalValue - +this.oldChangeOrderValue);
        }
        if (this.changeOrderValue != undefined && this.changeOrderValue != null && this.contractDetails.TotalValue != null && this.contractDetails.TotalValue != undefined) {

            this.contractDetails.TotalValue = +this.changeOrderValue + +this.contractDetails.TotalValue;
        }
        else {
            this.contractDetails.TotalValue = this.changeOrderValue;
        }
        let sum = 0;
        this._changeOrders.forEach(order => {
            sum += +order.value;
        });

        this.finalValue = (+this.finalValue + sum).toString();
        this.oldChangeOrderValue = '';
    }

    resetChangeOrderForm() {
        this.changeOrderSaved = false;
        this.contractsChangeOrderForm.reset();
        this.contractsChangeOrderForm.patchValue({
            _changeOrderfileSource: null,
            _changeOrderFileName: null
        })
    }
    changeOrderStatusDataSource: any[] = [];
    GetStatusMasterData(enumId: number, type: string = '') {
        this._masterDataService.getCodeListData(enumId, type).subscribe(
            res => {
                this.changeOrderStatusDataSource = res.data;
                this.changeOrderDataSource = res.data;
                this.addendumDetailsDataSource = res.data;
                this.addendumDetailStatusDataSource = res.data;
            }
        );

    }


    checkIfAnyControlsTouched(formGroup: FormGroup) {
        for (const controlName in formGroup.controls) {
            if (formGroup.controls.hasOwnProperty(controlName)) {
                const control = formGroup.controls[controlName];
                if (control.dirty) {
                    return true; // If any control is touched, return true
                }
            }
        }

        return false; // No controls have been touched
    }
    multiChangeOrderFileData: any[] = [];
    onChangeOrderFileChange(fileDetails: any) {
        var uploadedChangeOrderFile: any;
        this.maxFileSize = this._fileService._fileSize; // 20 MB (value Bytes in binary)
        // Check if any files were selected
        if (fileDetails.length > 0) {
            var currentFileSize = fileDetails[0].size;
            var uploadedFileName = fileDetails[0].name;
            var allowedExtensions = [FileExtension.DOC, FileExtension.DOCX, FileExtension.PDF, FileExtension.XLS, FileExtension.XLSX];
            var uploadedExtension = uploadedFileName.split('.').pop().trim().toLowerCase();

            
            if (allowedExtensions.includes(uploadedExtension)) {
                if (currentFileSize <= this.maxFileSize) {
                    this.contractsChangeOrderForm.patchValue({
                        _changeOrderFileName: fileDetails[0].name,
                    });
                    uploadedChangeOrderFile = fileDetails[0];

                    this.uploadedMultipleChangeOrderFileData.push(uploadedChangeOrderFile);
                   
                    
                   this.addendumChangeOrderFormData.append("file", uploadedChangeOrderFile, "Contracts/ChangeOrders/" + this.timeStamp + "_" + uploadedChangeOrderFile.name);
                    
                    this._changeOrder.fileName = uploadedChangeOrderFile.name;
                    this._changeOrder.filePath = "Contracts/ChangeOrders/" + this.timeStamp + "_" + this._changeOrder.fileName;
                    this.showChangeOrderAttachmentButton = false;
                    this.showChangeOrderAttachedFile = true;
                } else {
                    this.contractsChangeOrderForm.patchValue({
                        _changeOrderFileName: null,
                        _changeOrderfileSource: null
                    });
                    this._changeOrder.fileName = '';
                    this.messageService.add({ key: 'tc', severity: 'warn', summary: 'File Size limited to 20 Mb', detail: '' });
                }
            } else {
                this.contractsChangeOrderForm.patchValue({
                    _changeOrderFileName: null,
                    _changeOrderfileSource: null
                });
                this._changeOrder.fileName = '';
                this.messageService.add({ key: 'tc', severity: 'error', summary: 'Invalid file type. Only .pdf, .xls, .xlsx, .doc, .docx files are allowed.', detail: '' });
            }
        }   
    }
    isChangeOrderEdit: boolean = false;
    onChangeOrderDateChange(event: any) {
        if (this.isChangeOrderEdit && this.contractsChangeOrderForm.get('_changeOrderStartDate')?.touched) {
            if (this._changeOrder.StartDate != null && this._changeOrder.StartDate != undefined && this._changeOrder.EndDate != null && this._changeOrder.EndDate != undefined) {
                if (this._changeOrder.StartDate > this._changeOrder.EndDate) {
                    this.contractsChangeOrderForm.patchValue({
                        _changeOrderEndDate: null
                    });
                }
            }
            if (this.startDate != null && this._changeOrder.StartDate != null && this._changeOrder.StartDate != undefined) {
                if (this.startDate > this._changeOrder.StartDate) {
                    this.contractsChangeOrderForm.patchValue({
                        _changeOrderStartDate: null
                    });
                    this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Start Date should be after Start Date of Contract', detail: '' });
                }
            }
        }
        else {
            if (this.currentDialog == "changeOrder" && !this.isChangeOrderEdit) {
                this._changeOrder.StartDate = event;
                if (this._changeOrder.StartDate != null && this._changeOrder.StartDate != undefined && this._changeOrder.EndDate != null && this._changeOrder.EndDate != undefined) {
                    if (this._changeOrder.StartDate > this._changeOrder.EndDate) {
                        this.contractsChangeOrderForm.patchValue({
                            _changeOrderEndDate: null
                        });
                    }
                }
                if (this.startDate != null && this._changeOrder.StartDate != null && this._changeOrder.StartDate != undefined) {
                    if (this.startDate > this._changeOrder.StartDate) {
                        this.contractsChangeOrderForm.patchValue({
                            _changeOrderStartDate: null
                        });
                        this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Start Date should be after Start Date of Contract', detail: '' });
                    }

                }
            }
        }
    }
    onPODateChange(event: any) {
        this.contractDetails.POStartDate = event;
        if (this.contractDetails.POStartDate != null && this.contractDetails.POStartDate != undefined && this.contractDetails.POEndDate != null && this.contractDetails.POEndDate != undefined) {
            if (this.contractDetails.POStartDate > this.contractDetails.POEndDate) {
                this.myContractDetailsForm.patchValue({
                    poendDate: null
                });
            }
        }

    }

    onAddendumDateChange(event: any) {
        if (this.isAddendumEdit && this.contractsAddendumDetailsForm.get('_addendumDetailStartDate')?.touched) {
            if (this._addendumDetail.StartDate != null && this._addendumDetail.StartDate != undefined && this._addendumDetail.EndDate != null && this._addendumDetail.EndDate != undefined) {
                if (this._addendumDetail.StartDate > this._addendumDetail.EndDate) {
                    this.contractsAddendumDetailsForm.patchValue({
                        _addendumDetailEndDate: null
                    });
                }
            }
            if (this.startDate != null && this._addendumDetail.StartDate != null && this._addendumDetail.StartDate != undefined) {
                if (this.startDate > this._addendumDetail.StartDate) {
                    this.contractsAddendumDetailsForm.patchValue({
                        _addendumDetailStartDate: null
                    });
                    this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Start Date should be after Start Date of Contract', detail: '' });
                }
            }
        }
        else {
            if (this.currentDialog == "Addendum") {
                this._addendumDetail.StartDate = event;
                if (this._addendumDetail.StartDate != null && this._addendumDetail.StartDate != undefined && this._addendumDetail.EndDate != null && this._addendumDetail.EndDate != undefined) {
                    if (this._addendumDetail.StartDate > this._addendumDetail.EndDate) {
                        this.contractsAddendumDetailsForm.patchValue({
                            _addendumDetailEndDate: null
                        });
                    }

                }
                if (this.startDate != null && this.startDate != undefined && this._addendumDetail.StartDate != null && this._addendumDetail.StartDate != undefined) {
                    if (this.startDate > this._addendumDetail.StartDate) {
                        this.contractsAddendumDetailsForm.patchValue({
                            _addendumDetailStartDate: null
                        });
                        this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Start Date should be after Start Date of Contract', detail: '' });
                    }

                }
            }
        }
    }
    changeOrderDialogHeader = 'Add Change Order';
    changeOrderDialog: boolean = false;
    AddendumDialogHeader = 'Add Addendum';
    addendumDialog: boolean = false;

    editChangeOrder(item: any) {
        this.changeOrderDialog = true;
        this.changeOrderDialogHeader = 'Edit Change Order';
        this._addendumOrChangeOrderAttachementRemoved = false;
        this.isChangeOrderEdit = true;
        this.resetChangeOrderForm();
        this.showChangeOrderAttachedFile = true;
        this.showChangeOrderAttachmentButton = false;
        this.GetStatusMasterData(3, 'Change Order');
        this._changeOrder = new ContractChangeOrder()
        this.displayChangeOrderDialog = true;

        this.selectedItem = { ...item };
        this.oldSelectedItem = { ...item };
        this._changeOrder = this.selectedItem;
        this._changeOrder._changeOrderfileSource = this._changeOrder.fileName;

        this.contractsChangeOrderForm.patchValue({
            _changeOrderfileSource: this._changeOrder.fileName

        });

        if(this._changeOrder.fileName==""){
            this.showChangeOrderAttachmentButton=true;
            this.showChangeOrderAttachedFile=false;
          }


    }

    updateChangeOrderCurrency(selectedCurrency: any) {

        const selectedCurrencyObject = this.currenciesData.find(currency => currency.id === selectedCurrency);

        if (selectedCurrencyObject) {
            this.contractCurrency = selectedCurrencyObject.name;
        }
    }

    noOnlySpacesValidator(control: AbstractControl): ValidationErrors | null {
        if (control.value && control.value.trim().length === 0) {
            return { onlySpaces: true };
        }
        return null;
    }

    GetAddendumStatus() {
        this._masterDataService.getCodeListData(3, 'Addendum').subscribe(
            res => {
                let addendumStatus = 0;
                if (this._addendumDetails != undefined && this._addendumDetails.length > 0) {
                    this._addendumDetails.forEach((AddendunOrds: any) => {
                        let filteredstatusOfAddendum = res.data.filter((status: any) => status.id === AddendunOrds.statusMappingId);
                        if (filteredstatusOfAddendum != null && filteredstatusOfAddendum != undefined && filteredstatusOfAddendum.length > 0) {
                            
                            this._addendumDetails[addendumStatus].status = filteredstatusOfAddendum[0].name;
                        }
                        addendumStatus++;
                    })
                }
            }
        );
    }
    isAddendumEdit: boolean = false;
    EditAddendumTile(item: any) { //When we click on the Edit Button on tile
        this.addendumDialog = true;
        this.AddendumDialogHeader = 'Edit Addendum';
        this._addendumOrChangeOrderAttachementRemoved = false;
        this.isAddendumEdit = true;
        this.resetAddendumDetailsForm();
        if (typeof item.StartDate === 'string') {
            item.StartDate = new Date(item.StartDate);
            item.EndDate = new Date(item.EndDate);
        }
        this.showAddendumAttachedFile = true;
        this.showAddendumAttachementButton = false;
        this.GetStatusMasterData(3, 'Addendum');
        this._addendumDetail = new ContracAddendumDetails()
        this.displayAdendumDialog = true;
        this.selectedItem = { ...item };
        this.oldSelectedItem = { ...item };
        this._addendumDetail = this.selectedItem;
        this._addendumDetail._addendumDetailfileSource = this._addendumDetail.fileName;
        this.contractsAddendumDetailsForm.patchValue({
            _addendumDetailfileSource: this._addendumDetail.fileName
        });

        if(this._addendumDetail.fileName==""){
            this.showAddendumAttachementButton=true;
            this.showAddendumAttachedFile=false;
          }
    }

    addendumChangeOrderFormData=new FormData();

    onAddendumFileChange(fileDetails: any) {
        let uploadedAddendumFile: any;
        this.maxFileSize = this._fileService._fileSize; // 20 MB  (value Bytes  in binary)
        // file upload on change event method
        if (fileDetails.length > 0) {
            let currentFileSize = fileDetails[0].size;
            let uploadedFileName = fileDetails[0].name;
            let allowedExtensions = [FileExtension.DOC, FileExtension.DOCX, FileExtension.PDF, FileExtension.XLS, FileExtension.XLSX];
            let uploadedExtension = uploadedFileName.split('.').pop().trim().toLowerCase();

            if (allowedExtensions.includes(uploadedExtension)) {
                if (currentFileSize <= this.maxFileSize) {
                    this.contractsAddendumDetailsForm.patchValue({
                        _addendumDetailFileName: fileDetails[0].name,
                    })
                    uploadedAddendumFile = fileDetails[0];

                    this.uploadedMultipleAddendumFileData.push(uploadedAddendumFile);
                    
                    this.addendumChangeOrderFormData.append("file", uploadedAddendumFile, "Contracts/Addendums/" + this.timeStamp + "_" + uploadedAddendumFile.name);
                    // update File name / file relative path for UI and DB 
                    this._addendumDetail.fileName = uploadedAddendumFile.name;
                    this._addendumDetail.filePath = "Contracts/Addendums/" + this.timeStamp + "_" + this._addendumDetail.fileName;
                    this.showAddendumAttachedFile = true;
                    this.showAddendumAttachementButton = false;
                }
                else {
                    this.contractsAddendumDetailsForm.patchValue({
                        _addendumDetailFileName: '',
                        _addendumDetailfileSource: ''
                    })
                    this._addendumDetail.fileName=''
                    this.messageService.add({ key: 'tc', severity: 'warn', summary: 'File Size limited to 20 Mb', detail: '' });
                }
            }
            else {
                this.contractsAddendumDetailsForm.patchValue({
                    _addendumDetailFileName: '',
                    _addendumDetailfileSource: ''
                })
                this._addendumDetail.fileName=''
                this.messageService.add({ key: 'tc', severity: 'error', summary: 'Invalid file type. Only .pdf, .xls, .xlsx, .doc, .docx files are allowed.', detail: '' });
            }
        }      
    }
    isSaveAddendumDataPopupBtnDisable = false;
    saveAddendumData() {
        this.isSaveAddendumDataPopupBtnDisable = true;
        setTimeout(() => {
            this.isSaveAddendumDataPopupBtnDisable = false;
          }, 3000);
        let anyControlsTouched = this.checkIfAnyControlsTouched(this.contractsAddendumDetailsForm);
       if (this._addendumOrChangeOrderAttachementRemoved) {
           anyControlsTouched = this._addendumOrChangeOrderAttachementRemoved;
       }
    //    if (anyControlsTouched) {
            this.loaderService.showLoader();
            this.addendumDetailsSaved = true;
            if (this.contractsAddendumDetailsForm.valid && this._addendumDetail.statusMappingId>0) {
                if (this.selectedItem == null) {
                    var selectedAddendumDetailsStatus = this.addendumDetailsDataSource?.filter(t => t.id == this._addendumDetail.statusMappingId);
                    if (selectedAddendumDetailsStatus.length > 0 && selectedAddendumDetailsStatus[0].name != undefined)
                        this._addendumDetail.status = selectedAddendumDetailsStatus[0].name;

                        

                    this._addendumDetails.push(this._addendumDetail);
                    this.displayAdendumDialog = false;
                    this.messageService.add({ key: 'tc', severity: 'success', summary: 'Addendum has been saved', detail: '' });

                }
                else {
                    if (this.selectedItem != null) {
                        const contactIndex = this._addendumDetails.findIndex(item => item.title === this.oldSelectedItem.title && item.StartDate === this.oldSelectedItem.StartDate && item.EndDate === this.oldSelectedItem.EndDate && item.status === this.oldSelectedItem.status);
                        if (contactIndex !== -1) {
                            var selectedAddendumDetailsStatus = this.addendumDetailsDataSource?.filter(t => t.id == this._addendumDetail.statusMappingId);
                            if (selectedAddendumDetailsStatus.length > 0 && selectedAddendumDetailsStatus[0].name != undefined)
                                this.selectedItem.status = selectedAddendumDetailsStatus[0].name;
                            this._addendumDetails[contactIndex] = { ...this.selectedItem };
                            this.selectedItem = null;
                            this.oldSelectedItem = null;
                        }
                        this.displayAdendumDialog = false;
                        this.messageService.add({
                            key: 'tc',
                            severity: 'success',
                            summary: 'Addendum has been saved',
                            detail: '',
                        });
                    }
                    else {
                        this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Addendum Failed to save', detail: '' });
                    }
                }
            }
            else {
                this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Please enter required information', detail: '' });
            }
    //     }
    //     else {
    //         this.displayAdendumDialog = false;
    //    }
        this.isAddendumEdit = false;
        this.loaderService.hideLoader();

    }
    cancelDialog(isCancelButton: boolean, buttonClicked: string) { //When click on cancel button in the ChangeOrder and Addendum Tile
        var dilalogFormName: any;
        if (buttonClicked === 'Change Order') {
            dilalogFormName = this.contractsChangeOrderForm;
        }
        else {
            dilalogFormName = this.contractsAddendumDetailsForm
        }

        var anyControlsTouched = this.checkIfAnyControlsTouched(dilalogFormName);
        if (this._addendumOrChangeOrderAttachementRemoved) {
            anyControlsTouched = this._addendumOrChangeOrderAttachementRemoved;
        }
        if (anyControlsTouched) {
            if (isCancelButton) {
                let detailMessage = "";
                if (buttonClicked === 'Change Order') {
                    
                    detailMessage = "Change Order has not been saved";
                } else if (buttonClicked === 'Adendum') {
                    this.displayAdendumDialog = false;
                    detailMessage = "Addendum has not been saved";
                }
                this.messageService.add({
                    key: 'tc',
                    severity: 'info',
                    summary: detailMessage,
                    detail: '',
                });
            }
        }
        this.isAddendumEdit = false;
        this.isChangeOrderEdit = false;
        this.displayChangeOrderDialog = false
        this.displayAdendumDialog = false;

    }
    _editAddendumDeletedAttachemntFiles: any[] = [];
    _addendumOrChangeOrderAttachementRemoved: any;
    DeletAddendumFile(_addendumDetailFileName: any, _addendumDetailfileSource: any) { //when user Remove the existing uploaded attachment from the edit addeddum tile Popup will create a list, of removing the 
        this._addendumOrChangeOrderAttachementRemoved = true;
        this.showAddendumAttachementButton = true;
        this.showAddendumAttachedFile = false;
        this._addendumDetail.fileName = '';
        this._addendumDetail.filePath = '';
        this.contractsAddendumDetailsForm.patchValue({
            _addendumDetailfileSource: '',
            _addendumDetailFileName: ''
        })
        var filedetails = {
            "fileName": _addendumDetailFileName,
            "filePath": _addendumDetailfileSource
        };
        if (filedetails.fileName != "" && filedetails.filePath != "" && this._addendumDetail.id != 0) {
            var isFileAvailable = this._addendumDetailsAfterDataRetrive?.filter((t: { id: number; fileName: string; filePath: string }) => t.id == this._addendumDetail.id && t.fileName == _addendumDetailFileName && t.filePath == _addendumDetailfileSource);
            if (isFileAvailable.length > 0) {
                const fileNameToRemove = _addendumDetailFileName;
                const indexToRemove = this._editAddendumDeletedAttachemntFiles.findIndex(file => file.fileName === fileNameToRemove && file.filePath == _addendumDetailfileSource);
                if (indexToRemove != 0) {
                    this._editAddendumDeletedAttachemntFiles.push(filedetails);
                }
            }

        }

    }

    infoAddendumMessageDisplayed: any;
    showDialogAdendum() //when user Click on Add Addendum button pop up will shown for adding addendum
    {
        if (this.infoAddendumMessageDisplayed) {
            return;
        }
        this.showAddendumAttachedFile = false;
        this.showAddendumAttachementButton = true;
        this.addendumDialog = true;
        this.AddendumDialogHeader = 'Add Addendum';
        this.selectedItem = null;
        this.currentDialog = 'Addendum';
        this.visible = true;
            this.resetAddendumDetailsForm();
            this.addendumDetailsSaved = false;
            this.GetStatusMasterData(3, 'Addendum');
            this._addendumDetail = new ContracAddendumDetails();
            this.displayAdendumDialog = true;
            this._addendumDetail.EndDate = this.contractDetails.EndDate;
    }
    resetAddendumDetailsForm() {
        this.addendumDetailsSaved = false;
        this.contractsAddendumDetailsForm.reset();
        this.contractsAddendumDetailsForm.patchValue({
            _addendumDetailfileSource: '',
            _addendumDetailFileName: ''
        })
    }
    onPOFileChange(fileDetails: any) {

        this.maxFileSize = 20971520; // 20 MB  (value Bytes  in binary)
        // file upload on change event method
        if (fileDetails.length > 0) {
            var currentFileSize = fileDetails[0].size;
            var uploadedFileName = fileDetails[0].name;
            this.contractDetails.POFileName = uploadedFileName;

            var uploadedExtension = uploadedFileName.split('.').pop().trim().toLowerCase();

            // Check if the uploaded file's extension is allowed
            if (this._fileService.allowedExtensions.includes(uploadedExtension)) {
                if (currentFileSize <= this.maxFileSize) {
                    this.myContractDetailsForm.patchValue({
                        poFileName: fileDetails[0].name,
                    })
                    this.uploadedPOFileData = fileDetails[0];
                    this.showPOAttachmentButton = false;
                    this.showPOAttachedFile = true;
                }

                else {
                    this.myContractDetailsForm.patchValue({
                        poFileName: '',
                        pofileSource: ''
                    })
                    this.contractDetails.POFileName='';
                    this.messageService.add({ key: 'tc', severity: 'warn', summary: 'File Size limited to 20 Mb', detail: '' });
                }
            }
            else {
                this.myContractDetailsForm.patchValue({
                    poFileName: '',
                    pofileSource: ''
                })
                this.contractDetails.POFileName='';
                this.messageService.add({ key: 'tc', severity: 'error', summary: 'Invalid file type. Only .pdf, .xls, .xlsx, .doc, .docx files are allowed.', detail: '' });
            }

        }       
    }
    endDateMessageFlag = false;
    checkContractEndDate(endDate: any) {
        // Check in the Change Order
        if (this._changeOrders != null && this._changeOrders != undefined) {
            this._changeOrders.forEach(item => {
                if (item.EndDate != null && item.EndDate != undefined) {
                    if (endDate < item.EndDate) {
                        this.startDateMessageFlag = true;
                    }
                }
            });
        }
        if (this.startDateMessageFlag) {
            this.messageService.add({ key: 'tc', severity: 'error', summary: 'Contract End Date cannot be before a CO End Date', detail: '' });
        }
        if (!this.startDateMessageFlag) {
            this.endDate = endDate;
        }
        else {
            this.startDateMessageFlag = false;
            this.myContractDetailsForm.patchValue({
                endDate: this.endDate,

            });
        }

    }
    subTabindex: number = 0;
    //
    previousSubTabIndex=0;
    onSubTabSelect(event?: any) {
       
    this.tabAndStateService.scrollToTop(this.scrollContainer);
    this.subTabindex=event.index;
    this.CommonTabClick(this.previousSubTabIndex,this.Previoustabindex);
    if (this.isformObjectChange)
    {
        this.confirmationService.confirm({
          message: this._message,
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.previousSubTabIndex=this.subTabindex;
            this.clearFormCompareObjects();

            this.SubTabSelect(this.subTabindex)
            this.messageService.add({
              key: 'tc',
              severity: 'info',
              summary: this._details,
              detail: '',
          });
          },
          reject: () => {
            this.activeSubTabIndex = this.previousSubTabIndex;
            this.subTabView.activeIndex = this.previousSubTabIndex;
            setTimeout(() => {
              this.activeSubTabIndex = this.previousSubTabIndex;
              this.subTabView.activeIndex = this.previousSubTabIndex;
           },10);
           
          },
        });
    }
    else{
      this.previousSubTabIndex=this.subTabindex;
      this.clearFormCompareObjects();

      this.SubTabSelect(this.subTabindex)
    }
       
    }
    SubTabSelect(subTabIndex:number)
    {
        this.subTabView.activeIndex = subTabIndex;
        switch (subTabIndex) {
            case 0:
                this._currentKpiChildComponent = 1;
                this.activeSubTabIndex = subTabIndex;
                break;
            case 1:
                this._currentMeetingChildComponent = 1;
                this.activeSubTabIndex = subTabIndex;
                break;
            case 2:
               
                this._currentInvoiceChildComponent=1;
                this.activeSubTabIndex = subTabIndex;
                if(this.childInvoiceCreateComponent!=undefined)
                this.childInvoiceCreateComponent.submitted = false;
               
                this._currentAccrualChildComponent=1; 
                this.activeSubTabIndex = subTabIndex;
                if(this.childAccrualEditComponent!=undefined)
                this.childAccrualEditComponent.accrualSubmitted = false;
                break;
            case 3:
                this._currentForecastChildComponent = 1;
                this.activeSubTabIndex = subTabIndex;
                if (this.childForecastEditComponent != undefined)
                    this.childForecastEditComponent.forecastSubmitted = false;
                break;
        }
    }
    onFinanceSubTabSelect(event?:any)
    { 
      this.tabAndStateService.scrollToTop(this.scrollContainer);
      this.subTabindex=event.index;
      this.CommonTabClick(this.previousSubTabIndex,this.Previoustabindex);
      if (this.isformObjectChange)
      {
          this.confirmationService.confirm({
            message: this._message,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              this.previousSubTabIndex=this.subTabindex;
              this.clearFormCompareObjects();

              this.FinanceSubTabSelect(this.subTabindex)
              this.messageService.add({
                key: 'tc',
                severity: 'info',
                summary: this._details,
                detail: '',
            });
            },
            reject: () => {
              
              setTimeout(() => {
                this.activeFinanceSubTabIndex = this.previousSubTabIndex;
                this.financeSubTabView.activeIndex = this.previousSubTabIndex;
             },250);
             
            },
          });
      }
      else{
        this.previousSubTabIndex=this.subTabindex;
        this.clearFormCompareObjects();

        this.FinanceSubTabSelect(this.subTabindex)
      }

    }
 
    FinanceSubTabSelect(subTabIndex:number)
    {
      
        this.financeSubTabView.activeIndex = subTabIndex;
        switch (subTabIndex) {
            case 0:
                //this._currentKpiChildComponent = 1;
                this.childConSummaryViewComponent.GetContractSummaryDetails(this.editedContractId)
                this.childConSummaryViewComponent.GetFiscalYearDataByContractId();
                this.activeFinanceSubTabIndex = subTabIndex;
                break;
            case 1:
                this._currentInvoiceChildComponent=1;
                this.activeFinanceSubTabIndex = subTabIndex;
                break;
            case 2:
                this._currentAccrualChildComponent = 1;
                this.activeFinanceSubTabIndex = subTabIndex;
                if (this.childAccrualEditComponent != undefined)
          this._currentInvoiceChildComponent=1;
          this.activeSubTabIndex = this.subTabindex;
          if(this.childInvoiceCreateComponent!=undefined)
          this.childInvoiceCreateComponent.submitted = false;
         // this.childInvoiceCreateComponent.submitted = false;
          this._currentAccrualChildComponent=1; 
          this.activeSubTabIndex = this.subTabindex;
          if(this.childAccrualEditComponent!=undefined)
          this.childAccrualEditComponent.accrualSubmitted = false;
        break;
            case 3:
                this._currentForecastChildComponent = 1;
                this.activeFinanceSubTabIndex = subTabIndex;
                if (this.childForecastEditComponent != undefined)
                    this.childForecastEditComponent.forecastSubmitted = false;
                break;
    }
    }
    // accrual
    accrualId: any;
    handleAccrualEditButtonClick(data: any) {
        this.accrualId = data.accrualId;
        this._currentAccrualChildComponent = 2; // Edit page
    }

    handleAccrualCancelEditButtonClick() {
        this._currentAccrualChildComponent = 1; // list page
    }

    handleAccrualSaveEditButtonClick() {
        this.clearFormCompareObjects();
        this._currentAccrualChildComponent = 1;  // view page
    }

    handleAccrualViewButtonClick(data: any) {
        this.accrualId = data.accrualId;
        this._currentAccrualChildComponent = 3; // view page
    }

    // forecast
    forecastId: any;
    handleForecastEditButtonClick(data: any) {

        this.forecastId = data.forecastId;
        this._currentForecastChildComponent = 2; // Edit page
    }

    handleForecastCancelEditButtonClick() {
        this._currentForecastChildComponent = 1; // list page
    }
    handleForecastSaveEditButtonClick() {
        this.clearFormCompareObjects();
        this._currentForecastChildComponent = 1;  // view page
    }

    handleForecastViewButtonClick(data: any) {
        this.forecastId = data.forecastId;
        this._currentForecastChildComponent = 3; // view page
    }
    //******  code end ********/


    handleAddButtonClick(addButtonType: number) {
        switch (addButtonType) {
            case 1:
                this._currentKpiChildComponent = 2;
                this.currentChildComponent = this.childKpiCreateComponent;
                break;
            case 2:
                this._currentMeetingChildComponent = 2;
                this.currentChildComponent = this.childMeetingCreateComponent;
                break;
            case 3:
                //team tab
                this.actionType = 'Create';
                this._currentTeamChildComponent = 2;//Add associate
                this.currentChildComponent = this.childTeamCreateEditComponent;
                break;

        }

    }
    handleCancelAddButtonClick(cancelButtonType: number)//common cancel child compoments method
    {

        switch (cancelButtonType) {
            case 1:
                this._currentKpiChildComponent = 1;//Contract KPI
                break;
            case 2:
                this._currentMeetingChildComponent = 1;//contract meeting
                break;
            case 3:
                this._currentTeamChildComponent = 1;//List associate
                break;

        }
    }

    handleSaveAddButtonClick(saveButtonType: number)//common save method for all child components
    {
        this.clearFormCompareObjects();
        switch (saveButtonType) {
            case 1:
                this._currentKpiChildComponent = 4;
                break;
            case 2:
                //meeting tab
                break;
            case 3:
                //team tab
                this._currentTeamChildComponent = 3;//View associate
                this.currentChildComponent = this.childTeamCreateEditComponent;
                break;

        }
    }

    actionType: any;
    associateId: any
    handleEditButtonClick(data: any, editButtonType: number) {

        switch (editButtonType) {
            case 1:
                this.kpiId = data.kpiId;
                this._currentKpiChildComponent = 3;
                this.currentChildComponent = this.childKpiEditComponent;
                break;
            case 2:
                this.meetingId = data.meetingId;
                if (data.isRecurring == false) {
                    this._currentMeetingChildComponent = 3;
                    this.currentChildComponent = this.childMeetingEditComponent;
                }
                else {
                    this._currentMeetingChildComponent = 5;
                    this.currentChildComponent = this.childMeetingEditRecYesComponent;
                }
                break;
            case 3:
                //team tab
                this._currentTeamChildComponent = 2;//Add associate
                this.actionType = 'Edit';
                this.associateId = data.associateId;
                this.currentChildComponent = this.childTeamCreateEditComponent;
                break;


        }

    }
    handleViewButtonClick(data: any, viewButtonType: number) {

        switch (viewButtonType) {
            case 1:
                this.kpiId = data.kpiId;
                this._currentKpiChildComponent = 4;
                this.currentChildComponent = undefined;
                break;
            case 2:
                this.meetingId = data.meetingId;
                this._currentMeetingChildComponent = 4;
                this.currentChildComponent = undefined;
                break;
            case 3:
                this.associateId = data.associateId;
                this._currentTeamChildComponent = 3;

        }
    }
  
    validateMaxSelections(control: any) {
        const selectedOptions = control.value;
        if (selectedOptions && selectedOptions.length > 2) {
            return { maxSelectionsExceeded: true };
        }
        return null;
    }
    getCurrencyType(currencyName: string): CurrencyType | undefined {
        return this.currencyService.getCurrencyType(currencyName);
      }

      contractGroupSubmitted=false;
    _contractGroup=new ContractGroupModel()
    SaveContractGroup() {
        this.contractGroupSubmitted = true;
        this.trimInput();
        if (this.contactFormGroup.valid) {
            this.loaderService.showLoader();
            this._contractGroup.name = this.contractGroup.name;
            this._contractGroup.engagementId = this.engagementId
            this._contractGroup.createdBy = this.privilegeService.getLoginUserId();
            const existingRecord = this.contractGroupData.find(record => record.engagementId === this._contractGroup.engagementId && record.name.toLowerCase() === this._contractGroup.name?.toLowerCase());
            if (existingRecord ||this._contractGroup.name?.toLowerCase()=="others") {
                this.messageService.add({ key: 'tc', severity: 'warn', summary: 'This Function already exists in the selected Engagement', detail: '' });
                this.loaderService.hideLoader();
            } else {
                this.engagementService.AddContractGroup(this._contractGroup).subscribe(res => {
                    this.messageService.add({ key: 'tc', severity: 'success', summary: 'Function Information has been saved', detail: '' });
                    this.displayContractGroupDialog = false;
                   this.GetContractGroupData( this.engagementId);
                   setTimeout(() => {
                    this.contractDetails.OwnerFunctionId = this.contractGroupData[this.contractGroupData.length - 1].id;
                    const contractGroupName = this.contractGroupData.find(contract => contract.id === this.contractDetails.OwnerFunctionId);
                    this.selectedContractGroup = contractGroupName.name ? contractGroupName.name : '';
                    this.loaderService.hideLoader();
                   }, 1000);
                  
                });
            }
        }
    }
    ContractGroupText(){
        this.selectedContractGroup='';
        const contractGroupName = this.contractGroupData.find(contract => contract.id === this.contractDetails.OwnerFunctionId);
        this.selectedContractGroup = contractGroupName.name ? contractGroupName.name : '';
      }
         displayContractGroupDialog:boolean=false;
          contactGroup:any;
          contactHeader='';
            openContractGroupModel() {
                this.contactHeader='Add Function' ;  
                  if (this.contractDetails.EngagementId) {
                    this.contactFormGroup.reset();
                    Object.keys(this.contactFormGroup.controls).forEach(key => {
                      this.contactFormGroup?.get(key)?.setErrors(null);
                    });
                    this.contractGroupSubmitted = false;
                    this.contractGroup = {
                      name: ""
                     
                    };
                
                    this.displayContractGroupDialog = true;
                  } else {
                    this.messageService.add({
                      key: 'tc',
                      severity: 'warn',
                      summary: 'Please select an Engagement First.',
                      detail: '',
                    });
                  }
                }
                displayDialog:boolean=false;
                contactSubmitted=false;
                saveContext: string = '';
                sponserContact:ITbl_SponserContacts={};
                openContactModel(header: number, saveContext: string) {
                    this.contactHeader=header==1 ? 'Add Supplier Contact' : 'Add Sponsor Contact';  
                    this.existSponsorContact="";
                    this.uniqueSponsorContactNameMessage=false;
                      if (this.contractDetails.EngagementId) {
                        this.dialogFormGroup.reset();
                        Object.keys(this.dialogFormGroup.controls).forEach(key => {
                          this.dialogFormGroup?.get(key)?.setErrors(null);
                        });
                        
                        this._supplierContact= new SupplierContact();
                        this.saveContext = saveContext; // Set the save context
                        this.contactSubmitted = false;
                        
                        this.sponserContact = {
                          name: "",
                          designation: "",
                          countryCode: "",
                          phoneNumber: "",
                          email: ""
                        };
                    
                        this.displayDialog = true;
                      } else {
                        this.messageService.add({
                          key: 'tc',
                          severity: 'warn',
                          summary: 'Please select an Engagement First.',
                          detail: '',
                        });
                      }
                    }

            closeContractGroupDialog(){
                this.displayContractGroupDialog = false;
                this.messageService.add({
                  key: 'tc',
                  severity: 'info',
                  summary: 'Function Information has not been saved',
                  detail: '',
              });
                
              }
    changedContractBasicInfo:any;
    changedContractChangeOrderInfo:any;
    changedContractAddendumInfo:any;
    blankChangeOrder= new ContractChangeOrder();
    blankAddendum=new ContracAddendumDetails();
    changedSponsorContactInfo:any;
    changedSupplierContactInfo:any;
    compareModels() {
        this.contractDetails.StartDate= new Date(this.contractDetails.StartDate).toISOString();
        this.contractDetails.EndDate= new Date(this.contractDetails.EndDate).toISOString();
        this.contractDetails.POStartDate= new Date(this.contractDetails.POStartDate).toISOString();
        this.contractDetails.POEndDate= new Date(this.contractDetails.POEndDate).toISOString();
        
        this.contractDetailBeforeUpdate=JSON.parse(this.contractDetailBeforeUpdate);
        this.contractDetailBeforeUpdate.SupplierMasterServiceAgreementId= this.contractDetails.SupplierMasterServiceAgreementId;
        this.contractDetailBeforeUpdate.BillingTypes=this.contractDetails.BillingTypes;
        this.contractDetailBeforeUpdate.EngagementId=this.contractDetails.EngagementId;
        if((this.contractDetails.POStartDate!=this.contractDetailBeforeUpdate.poStartDate)){
            this.contractDetails.POStartDate = new DatePipe('en-US').transform(new Date(this.contractDetails.POStartDate), this._dateformart.AuditLogFormat);
            this.contractDetailBeforeUpdate.poStartDate = new DatePipe('en-US').transform(new Date(this.contractDetailBeforeUpdate.poStartDate), this._dateformart.AuditLogFormat);
        }
        if((this.contractDetails.POEndDate!=this.contractDetailBeforeUpdate.poEndDate) ){
            this.contractDetails.POEndDate = new DatePipe('en-US').transform(new Date(this.contractDetails.POEndDate), this._dateformart.AuditLogFormat);
            this.contractDetailBeforeUpdate.poEndDate = new DatePipe('en-US').transform(new Date(this.contractDetailBeforeUpdate.poEndDate), this._dateformart.AuditLogFormat);
        }
        this.contractDetailBeforeUpdate.startDate=this.contractDetails.StartDate;
        this.contractDetailBeforeUpdate.endDate=this.contractDetails.EndDate;
        this.contractDetailBeforeUpdate = this.capitalizeKeys(this.contractDetailBeforeUpdate);
        this.contractDetailBeforeUpdate=JSON.stringify(this.contractDetailBeforeUpdate);
        this.changedContractBasicInfo = this.auditLogService.compareModels(JSON.parse(this.contractDetailBeforeUpdate),this.contractDetails,this.auditEntities.ContractDetails ,this.auditIdentifierDetails.ContractDetails,this.auditOperations.Update,this.auditModule.Contractservice);

        this.contractChangeOrderDetailListBeforeUpdate=JSON.parse(this.contractChangeOrderDetailListBeforeUpdate);
        this._changeOrders.forEach((item:any) => {
           
          if(item.id != 0 && !(item.isDeleted))//Edit case
          {
         
            item.StartDate = new Date(item.StartDate).toISOString();
            item. EndDate = new Date(item.EndDate).toISOString();

            let ChangeOrderInfo = this.contractChangeOrderDetailListBeforeUpdate.find((aa: any) => aa.id == item.id);
            ChangeOrderInfo = this.capitalizeKeys(ChangeOrderInfo); 

            if(ChangeOrderInfo && item.StartDate!=ChangeOrderInfo.startDate){
                item.StartDate = new DatePipe('en-US').transform(new Date(item.StartDate), this._dateformart.AuditLogFormat);
                ChangeOrderInfo.StartDate = new DatePipe('en-US').transform(new Date(ChangeOrderInfo.StartDate), this._dateformart.AuditLogFormat);
            }

            if(ChangeOrderInfo && item.EndDate!=ChangeOrderInfo.endDate){
                item.EndDate = new DatePipe('en-US').transform(new Date(item.EndDate), this._dateformart.AuditLogFormat);
                ChangeOrderInfo.EndDate = new DatePipe('en-US').transform(new Date(ChangeOrderInfo.EndDate), this._dateformart.AuditLogFormat);
            }


              var oldChangeOrderInfo=this.contractChangeOrderDetailListBeforeUpdate.filter((aa:any)=>aa.id==item.id);
              oldChangeOrderInfo[0].StartDate=oldChangeOrderInfo[0].startDate;
              oldChangeOrderInfo[0].EndDate=oldChangeOrderInfo[0].endDate;
              oldChangeOrderInfo[0].currency=item.currency;
              item.StartDate=new DatePipe('en-US').transform(new Date( item.StartDate), this._dateformart.AuditLogFormat);
              item.EndDate=new DatePipe('en-US').transform(new Date(item.EndDate), this._dateformart.AuditLogFormat);
              oldChangeOrderInfo[0].StartDate=new DatePipe('en-US').transform(new Date(oldChangeOrderInfo[0].StartDate), this._dateformart.AuditLogFormat);
              oldChangeOrderInfo[0].EndDate=new DatePipe('en-US').transform(new Date(oldChangeOrderInfo[0].EndDate), this._dateformart.AuditLogFormat);
              this.changedContractChangeOrderInfo=this.auditLogService.compareModels(oldChangeOrderInfo[0],item,this.auditEntities.ContractChangeOrders,this.auditIdentifierDetails.ContractChangeOrders,this.auditOperations.Update,this.auditModule.Contractservice);
          }
          else if(item.id != 0 && item.isDeleted)//DeleteContact Case
          {
            this.changedContractChangeOrderInfo=this.auditLogService.compareModels(item,this.blankChangeOrder,this.auditEntities.ContractChangeOrders,this.auditIdentifierDetails.ContractChangeOrders,this.auditOperations.Delete,this.auditModule.Contractservice);
          }
          else//new contact
          {
            this.changedContractChangeOrderInfo=this.auditLogService.compareModels(this.blankChangeOrder,item,this.auditEntities.ContractChangeOrders,this.auditIdentifierDetails.ContractChangeOrders,this.auditOperations.Create,this.auditModule.Contractservice);
          }
        });

      
        this.contractAddendumListBeforeUpdate=JSON.parse(this.contractAddendumListBeforeUpdate);
        this._addendumDetails.forEach((item:any) => {

          if(item.id != 0 && !(item.isDeleted))//Edit case
          {
            
            item.StartDate = new DatePipe('en-US').transform(new Date(item.StartDate), this._dateformart.AuditLogFormat);
            item.EndDate= new Date(item.EndDate).toISOString();

            let AddendumInfo = this.contractAddendumListBeforeUpdate.find((aa: any) => aa.id == item.id);

            AddendumInfo = this.capitalizeKeys(AddendumInfo); 
            if(AddendumInfo && item.StartDate!=AddendumInfo.startDate){
                item.StartDate = new DatePipe('en-US').transform(new Date(item.StartDate), this._dateformart.AuditLogFormat);
                AddendumInfo.StartDate = new DatePipe('en-US').transform(new Date(AddendumInfo.StartDate), this._dateformart.AuditLogFormat);
            }

            if(AddendumInfo && item.EndDate!=AddendumInfo.endDate){
                item.EndDate = new DatePipe('en-US').transform(new Date(item.EndDate), this._dateformart.AuditLogFormat);
                AddendumInfo.EndDate = new DatePipe('en-US').transform(new Date(AddendumInfo.EndDate), this._dateformart.AuditLogFormat);
            }
              var oldAddendumInfo=this.contractAddendumListBeforeUpdate.filter((aa:any)=>aa.id==item.id);
              oldAddendumInfo[0].StartDate=oldAddendumInfo[0].startDate;
              oldAddendumInfo[0].EndDate=oldAddendumInfo[0].endDate;
              item.StartDate=new DatePipe('en-US').transform(new Date( item.StartDate), this._dateformart.AuditLogFormat);
              item.EndDate=new DatePipe('en-US').transform(new Date(item.EndDate), this._dateformart.AuditLogFormat);
              oldAddendumInfo[0].StartDate=new DatePipe('en-US').transform(new Date(oldAddendumInfo[0].StartDate), this._dateformart.AuditLogFormat);
              oldAddendumInfo[0].EndDate=new DatePipe('en-US').transform(new Date(oldAddendumInfo[0].EndDate), this._dateformart.AuditLogFormat);
              this.changedContractAddendumInfo=this.auditLogService.compareModels(oldAddendumInfo[0],item,this.auditEntities.ContractAddendums,this.auditIdentifierDetails.ContractAddendums,this.auditOperations.Update,this.auditModule.Contractservice);
          }
          else if(item.id != 0 && item.isDeleted)//DeleteContact Case
          {
            this.changedContractAddendumInfo=this.auditLogService.compareModels(item,this.blankAddendum,this.auditEntities.ContractAddendums,this.auditIdentifierDetails.ContractAddendums,this.auditOperations.Delete,this.auditModule.Contractservice);
          }
          else//new contact
          {
            this.changedContractAddendumInfo=this.auditLogService.compareModels(this.blankAddendum,item,this.auditEntities.ContractAddendums,this.auditIdentifierDetails.ContractAddendums,this.auditOperations.Create,this.auditModule.Contractservice);
          }
        });
      
      }

    capitalizeKeys(data: any): any {
        if (Array.isArray(data)) {
            return data.map(item => this.capitalizeKeys(item)); 
        } else if (data !== null && typeof data === 'object') {
            const result: any = {};
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    let newKey = key;
    
                    if (['poNumber', 'poValue', 'poStartDate', 'poEndDate', 'poRemarks','poFileName','poFilePath'].includes(key)) {
                        newKey = key.replace(/^po/, 'PO'); // Capitalize first two characters (po -> PO)
                    } else {
                        newKey = key.charAt(0).toUpperCase() + key.slice(1);
                    }
                    result[newKey] = this.capitalizeKeys(data[key]);
                }
            }
            return result;
        } else {
            return data; 
        }
    }
    
isformObjectChange=false;
CommonTabClick(CurrentSubIndex:number,CurrentTabIndex:number=0)
{
  
  this.clearFormCompareObjects();

if (CurrentTabIndex == 0) {
    this._message = 'Changes will not be saved, Do you want to continue?';
    this._details = 'Changes not saved';
    this._oldObject = this.oldContractData; this._newObject = JSON.stringify(this.contractDetails);
}
else if (CurrentSubIndex == 2 && this._currentAccrualChildComponent == 2) //accrual tab(2) edit screen(2)  
{
    this._details = "Changes not saved";
    this._message = "Changes will not be saved, Do you want to Continue?"
    this.currentChildComponent = this.childAccrualEditComponent;
    this._oldObject = this.currentChildComponent.accrualDetailOld; this._newObject = JSON.stringify(this.currentChildComponent.accrualDetail);
}
else if (CurrentSubIndex == 3 && this._currentForecastChildComponent == 2) { //forecast tab(3) edit screen(2)  
    this._details = "Changes not saved";
    this._message = "Changes will not be saved, Do you want to Continue?"
    this.currentChildComponent = this.childForecastEditComponent;
    this._oldObject = this.currentChildComponent.forecastDetailOld; this._newObject = JSON.stringify(this.currentChildComponent.forecastDetail);
}
else if(this._currentInvoiceChildComponent==2 && CurrentSubIndex==1)
 {
    this._details="Invoice not added";
    this._message="Invoice will not be added, Do you want to Continue?";
    this.currentChildComponent=this.childInvoiceCreateComponent;
    this._oldObject=this.currentChildComponent._oldContractInvoice; this._newObject=JSON.stringify(this.currentChildComponent.contractInvoice);
 }
else if(this._currentInvoiceChildComponent==3 && CurrentSubIndex==1)
{
    this._details="Changes not saved";
    this._message="Changes will not be saved, Do you want to Continue?"
    this.currentChildComponent=this.childInvoiceEditComponent;
    this._oldObject=this.currentChildComponent._oldContractInvoice; this._newObject=JSON.stringify(this.currentChildComponent.contractInvoice);
}

else if (this._currentKpiChildComponent == 2 && CurrentSubIndex == 0 && CurrentTabIndex == 1) {
    this._details = "KPI not added";
    this._message = "KPI will not be added, Do you want to Continue?";
    this.currentChildComponent = this.childKpiCreateComponent;
    this._oldObject = this.currentChildComponent._oldContractKpi; this._newObject = JSON.stringify(this.currentChildComponent._contractKpi);
}
else if (this._currentKpiChildComponent == 3 && CurrentSubIndex == 0 && CurrentTabIndex == 1) {
    this._details = "Changes not saved";
    this._message = "Changes will not be saved, Do you want to Continue?"
    this.currentChildComponent = this.childKpiEditComponent;
    this._oldObject = this.currentChildComponent._oldContractKpi; this._newObject = JSON.stringify(this.currentChildComponent._contractKpi);
}

else if(this._currentMeetingChildComponent==2 && CurrentSubIndex==1)
{
 
 this. _details="Meeting not added";
 this. _message="Meeting will not be added, Do you want to Continue?";
  this.currentChildComponent=this.childMeetingCreateComponent;
  this._oldObject=this.currentChildComponent._oldContractMeeting; 
  if((!(this.currentChildComponent._oldContractMeeting==JSON.stringify(this.currentChildComponent.contractMeetingDetails))) || (this.currentChildComponent.attendeesValues!="") ||(this.currentChildComponent.absenteesValues!="") ||(this.currentChildComponent.addMeetingActionsData.length!=0)||(!(this.currentChildComponent._oldContractAttachments==JSON.stringify(this.currentChildComponent._contractMeetingOccurrenceAttachments))))
  {
   this._newObject="Data updated"
  }
  else{
  this._newObject=JSON.stringify(this.currentChildComponent.contractMeetingDetails);
  }
}
else if(this._currentMeetingChildComponent==3 && CurrentSubIndex==1)
{
 
 this. _details="Meeting not added";
 this. _message="Changes will not be saved, Do you want to Continue?";
  this.currentChildComponent=this.childMeetingEditComponent;
  this._oldObject=this.currentChildComponent._oldContractMeeting; this._newObject=JSON.stringify(this.currentChildComponent.contractMeetingDetails);
}
else if(this._currentMeetingChildComponent==5 && CurrentSubIndex==1)
       {
         this._details="Meeting not added";
         this._message="Changes will not be saved, Do you want to Continue?";
         this.currentChildComponent=this.childMeetingEditRecYesComponent;
         this._oldObject=this.currentChildComponent._oldContractMeetingDetails;
         this._newObject=JSON.stringify(this.currentChildComponent.contractMeetingDetails);
       }
else if (CurrentTabIndex == 3) {
    
    if (this.actionType == "Create") {
        this._message = 'Associate will not be added, Do you want to Continue?';
        this._details = 'Associate not added';
    }
    else {
        this._message = 'Changes will not be saved, Do you want to continue?';
        this._details = 'Changes not saved';
    }
    // Sometime we are founding Child Component undefined here, thats why we are checking the condition otherwise application is getting hanged
    if (this.childTeamCreateEditComponent != undefined) {
        this.currentChildComponent = this.childTeamCreateEditComponent;
        this._oldObject = this.childTeamCreateEditComponent.oldTeamAssociateModel;
        this._newObject = JSON.stringify(this.childTeamCreateEditComponent.teamAssociateData);
    }
    else {
        this._oldObject = "";
        this._newObject = "";
    }
}
 if(this._oldObject!=this._newObject)
 {
   this.isformObjectChange=true;
 }
 else
 {
    this.clearFormCompareObjects();
 }
}
clearFormCompareObjects()
{
    this._oldObject=""; 
     this._newObject="";
     this.isformObjectChange=false;
}
      

      _currentInvoiceChildComponent:number=0;
      //Child components actions
  handleInvoiceAddButtonClick() {
    this._currentInvoiceChildComponent=2;
   }
   handleInvoiceCancelAddButtonClick()
  {
    this._currentInvoiceChildComponent=1;
  }
  handleInvoiceSaveAddButtonClick()
  {
    this._currentInvoiceChildComponent=4;
  }
  
   handleInvoiceEditButtonClick(data: any) {
     this.invoiceId=data;
    this._currentInvoiceChildComponent=3;
   }
   handleInvoiceViewButtonClick(data: any) {
    this.invoiceId=data.invoiceId;
   this._currentInvoiceChildComponent=4;
} 

  notOnlySpacesValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value && control.value.trim() === '') {
       return { 'onlySpaces': true };
     }
     return null;
   }
   closeDialog(){
    this.displayDialog = false;
    this.messageService.add({
      key: 'tc',
      severity: 'info',
      summary: 'Contact Information has not been saved',
      detail: '',
  });
    
  }
  isSavePopupBtnDisable = false;
  SaveContact() {
    this.isSavePopupBtnDisable = true;
    setTimeout(() => {
        this.isSavePopupBtnDisable = false;
      }, 3000);
    if (this.saveContext === 'StakeholderSponsor') {
      this.SaveStakeholderSponsorContact();
    } else if (this.saveContext === 'StakeholderSupplier') {
      this.SaveStakeholderSupplierContact();
    } else if (this.saveContext === 'Sponsor') {
      this.SaveSponsorContact();
    } else if (this.saveContext === 'Supplier') {
      this.SaveSupplierContact();
    }
  }
  selectedSponsorContact:any;
  SaveStakeholderSponsorContact(){
    this.contactSubmitted=true;
    this.trimInput();
    var _blankContact=new SponsorContact();
    if (this.dialogFormGroup.valid && ! this.uniqueSponsorContactNameMessage) {
    this.contactSubmitted=false;
    this.sponserContact.createdBy= this.privilegeService.getLoginUserId();
    this.sponserContact.createdDate=new Date();
    this.sponserContact.updatedBy=this.privilegeService.getLoginUserId();
    this.sponserContact.updatedDate=new Date();
    this.sponserContact.isDeleted=false;
    this.sponserContact.isSendNotification= false;
    this.sponserContact.sponserId=this.sponsorInfo.sponserInfo.id;
    this.sponserContact.id=0;

   if(this.supplierData){
    this.masterDataService.AddSponsorContact(this.sponserContact).subscribe(res=>
      {
        this.newSponsorConatactId=res;
        this.sponserContact.id=res;
        this.masterDataService.getSponserData(this.sponsorId).subscribe(res=>{
          this.sponsorContactsDataSource= res.data.sponserContacts;
          this.filteredStakholderSponsorContacts= this.sponsorContactsDataSource;
          this.totalSponsorContact=this.sponsorContactsDataSource;
          this.sponsorContacts=res.data.sponserContacts;
          this.ExcludeStakeholderContacts('sponsor',0);
          this.updateSponsorDropdownOptions();
          this.excludeContact();
        });
        this.displayDialog = false;
        this.contactHeader=''
        this.messageService.add({
          key: 'tc',
          severity: 'success',
          summary: 'Contact has been saved',
          detail: '',
      });
      this.changedSponsorContactInfo = this.auditLogService.compareModels(_blankContact, this.sponserContact,this.auditEntities.ContractDetails,this.auditIdentifierDetails.SponsorContacts,this.auditOperations.Create,this.auditModule.Contractservice);  
      this.saveEngagmentSponsorContact();
      }
    );

   }
 }

  }
  selectedSupplierContact:any;
  SaveStakeholderSupplierContact(){
    this.contactSubmitted=true;
    this.trimInput();
    var _blankContact=new SupplierContact();
    if (this.dialogFormGroup.valid && ! this.uniqueSponsorContactNameMessage) {
    this.contactSubmitted=false;
   this._supplierContact.contactName=this.sponserContact.name;
   this._supplierContact.designation=this.sponserContact.designation;
   this._supplierContact.phoneNumber=this.sponserContact.phoneNumber;
   this._supplierContact.countryCode=this.sponserContact.countryCode;
   this._supplierContact.email=this.sponserContact.email;
   this._supplierContact.supplierProfileId=this.supplierId;
   this._supplierContact.isSendNotification= false;

   if(this.supplierData){
     this._supplierProfile=this.supplierData;
     this._supplierProfile.engagementStartDate = this.datepipe.transform(this._supplierProfile.engagementStartDate, "MM/dd/yyyy");
       this._mastersupplierService.AddSupplierContact(this._supplierContact).subscribe(res =>
         {
        this.newSupplierConatactId=res;
        this._supplierContact.id=res;
        this._supplierContact.id=this.newSupplierConatactId;
        this._mastersupplierService.getSupplierById(this.supplierId).subscribe(
         res => {
       this.supplierContactData = res.data.supplierContacts;
       this.filteredStakholderSupplierContacts=this.supplierContactData;
       this.supplierContacts=res.data.supplierContacts;
       this.totalSupplierContacts=this.supplierContacts;
        this.ExcludeStakeholderContacts("supplier",0);
        this.updateDropdownOptions();
        this.excludeContact();
         })
        this.contactHeader='';
        this.displayDialog = false;
         this.messageService.add({ 
             key: 'tc', 
             severity: 'success',
             summary: 'Contact has been saved',
             detail: '',
             });
             this.changedSupplierContactInfo = this.auditLogService.compareModels(_blankContact, this._supplierContact,this.auditEntities.ContractDetails,this.auditIdentifierDetails.SupplierContacts,this.auditOperations.Create,this.auditModule.Contractservice);
             this.saveEngagmentSupplier();
    });
   
   }
    }
  }
  _supplierProfile=new SupplierProfile();

SaveSupplierContact() {
    this.contactSubmitted = true;
    this.trimInput();
    if (this.dialogFormGroup.valid && ! this.uniqueSponsorContactNameMessage) {
      this.contactSubmitted = false;
  
      const newSupplierContact = {
        id:0,
        supplierProfileId:this.supplierId,
        contactName: this.sponserContact.name,
        designation: this.sponserContact.designation,
        phoneNumber: this.sponserContact.phoneNumber,
        countryCode: this.sponserContact.countryCode,
        email: this.sponserContact.email,
        createdBy:this.privilegeService.getLoginUserId(),
        isSendNotification: false
      };
  
      // Check if supplierData and supplierContacts are both defined
      if (this.supplierContacts) { 
          this.supplierContactList.push(newSupplierContact);
          this.filteredSupplierContacts.push(newSupplierContact);
          this.filteredStakholderSupplierContacts.push(newSupplierContact);
            this._mastersupplierService.AddSupplierContact(newSupplierContact).subscribe(res => {
              this.newSupplierConatactId=res;
              this._mastersupplierService.getSupplierById(this.supplierId).subscribe(
              res => {
                if (res.data && res.data.supplierContacts) {
                  this.supplierContactData = res.data.supplierContacts;
                  this.filteredStakholderSupplierContacts=this.supplierContactData;
                  this.supplierContacts=res.data.supplierContacts;
                  this.totalSupplierContacts=this.supplierContacts;
                  this.ExcludeStakeholderContacts('supplier',this.newSupplierConatactId);
                  this.contractDetails.OwnerSupplierContactId = this.supplierContactData[this.supplierContactData.length - 1].id;
                  const supplierContact = this.supplierContactData[this.supplierContactData.length - 1];
                  this.supplierContactList = [supplierContact];
                }
              }
            );
            this.messageService.add({ key: 'tc', severity: 'success', summary: 'Contact has been saved', detail: '' });
            this.contactHeader = '';
            this.displayDialog = false;
            this.saveEngagmentSupplier();
          });
      }
    }
  }
  stakeholdersuppliercontactData:any[]=[];
  AddStakeholderSupplierContact(){ 
    this.stakeholdersuppliercontactData.unshift({
      id: 0,
      name: '',
      designation:'',
      countrycode: '',
      phonenumber:'',
      isSendnotification:false,
      isdeleted:false
    
    });
    this.stakeholdersuppliercontactData.forEach((item, index) => {
      this.supplierContactDetails({ value: item.id }, index);
    });
  }



SaveSponsorContact(){
    this.contactSubmitted=true;
    this.trimInput();
    if (this.dialogFormGroup.valid && ! this.uniqueSponsorContactNameMessage) {
    this.contactSubmitted=false;
    this.sponserContact.createdBy= this.privilegeService.getLoginUserId();
    this.sponserContact.createdDate=new Date();
    this.sponserContact.updatedBy=this.privilegeService.getLoginUserId();
    this.sponserContact.updatedDate=new Date();
    this.sponserContact.isDeleted=false;
    this.sponserContact.isSendNotification=false;
    this.sponserContact.id=0;
  if (this.supplierData) {
    this.sponserContact.createdBy=this.privilegeService.getLoginUserId();
    this.sponserContact.sponserId=this.sponsorId;
    this.sponserContactList.push(this.sponserContact);
    this.filteredSponsorContacts.push(this.sponserContact)
    this.filteredStakholderSponsorContacts.push(this.sponserContact)
    this.masterDataService.AddSponsorContact(this.sponserContact).subscribe(
      data=>{
        this.newSponsorConatactId=data;
        this.masterDataService.getSponserData(this.sponsorId).subscribe(res=>{
        this.sponsorContactsDataSource= res.data.sponserContacts;
        this.filteredStakholderSponsorContacts= this.sponsorContactsDataSource;
        this.sponsorContacts=res.data.sponserContacts;
        this.totalSponsorContact=this.sponsorContactsDataSource;
        this.ExcludeStakeholderContacts('sponsor',this.newSponsorConatactId);
          this.contractDetails.SponserContactId = this.sponsorContactsDataSource[this.sponsorContactsDataSource.length - 1].id;
          const sponsorContact = this.sponsorContactsDataSource[this.sponsorContactsDataSource.length - 1];
            this.sponserContactList = [sponsorContact];
        });

        this.displayDialog = false;
        this.contactHeader=''
        this.messageService.add({
          key: 'tc',
          severity: 'success',
          summary: 'Contact has been saved',
          detail: '',
      });
      this.saveEngagmentSponsorContact();
      }
    );
   }
 }

  }
  AddStakeholderSponsorContact(){
    this.stakeholdercontactData.unshift({
      id: 0,
      name: '',
      designation:'',
      countrycode: '',
      phonenumber:'',
      isSendnotification:false,
      isdeleted:false
    
    });
    this.stakeholdercontactData.forEach((item, index) => {
      this.sponsorContactDetails({ value: item.id }, index);
    });
  
  }
  uniqueSponsorContactNameMessage=false;
  existSponsorContact:any;
  editConcheckSponsorContactNameExist(contactDetails:any)
  {
    if(this.contactHeader=="Add Supplier Contact"){
      if(this.supplierContactData.length>0){
        if (this.isNameRepeated(contactDetails)) {
          this.uniqueSponsorContactNameMessage=true;
        } else {
          this.uniqueSponsorContactNameMessage=false;
        }
      }
    }
    else{
    if(this.sponsorContactsDataSource.length>0){
      if (this.isNameRepeated(contactDetails)) {
        this.uniqueSponsorContactNameMessage=true;
      } else {
        this.uniqueSponsorContactNameMessage=false;
      }
    }
  }
  }
  currentSponsorContactIndex=-1;
  currentSupplierContactIndex=-1;
  isNameRepeated(contactDetails: any): boolean {
  
    this.uniqueSponsorContactNameMessage=false;
        let nameCount = 0;
        const contactName = contactDetails.name.toLowerCase();
        if(this.contactHeader=="Add Supplier Contact"){ 
          if(this.currentSupplierContactIndex==-1) 
          {
            this.supplierContactData.forEach((obj: any, index: any) => {
              if (obj.contactName.toLowerCase() === contactName) {
                this.existSponsorContact="Contact Name already exists in the Supplier of selected Engagement. Please enter a unique Name";
                  nameCount++;
              }
          });
          }
          else if(this.currentSupplierContactIndex>=0){
            this.supplierContactData.forEach((obj: any, index: any) => {
              if (obj.contactName.toLowerCase() === contactName &&  index!=this.currentSupplierContactIndex) {
                  nameCount++;
              }
          });
          }
          return nameCount > 0;
        }
        else{
        if(this.currentSponsorContactIndex==-1) 
        {
          this.sponsorContactsDataSource.forEach((obj: any, index: any) => {
            if (obj.name.toLowerCase() === contactName) {
                this.existSponsorContact="Contact Name already exists in the Sponsor of selected Engagement. Please enter a unique Name";
                nameCount++;
            }
        });
        }
        else if(this.currentSponsorContactIndex>=0){
          this.sponsorContactsDataSource.forEach((obj: any, index: any) => {
            if (obj.name.toLowerCase() === contactName &&  index!=this.currentSponsorContactIndex) {
                nameCount++;
            }
        });
        }
  
        return nameCount > 0;
      }
    }

    onSponsorContactChange(event: any): void {
        const selectedId = event.value;
        const selectedContact = this.sponsorContactsDataSource.find(contact => contact.id === selectedId);
        if (selectedContact) {
          this.sponserContactList = [selectedContact];
          this.sponsorContactsFiltered = this.sponsorContactsDataSource.filter(contact => contact.id !== selectedId);
        }
        this.ExcludeStakeholderContacts('sponsor',selectedId);
      }
      onSupplierContactChange(event: any): void {
        const selectedId = event.value;
        const selectedContact = this.supplierContactData.find(contact => contact.id === selectedId);
        if (selectedContact) {
          this.supplierContactList = [selectedContact];
          this.supplierContactsFiltered = this.supplierContactData.filter(contact => contact.id !== selectedId);
        }
        this.ExcludeStakeholderContacts('supplier',selectedId);
      }
      
      newSupplierConatactId:number=0;
      newSponsorConatactId:number=0;
     // selectedEngagement:any;
      _engagmentSupplierContact=new EngagementSupplierContact();
      _engagmentSponsorContact=new EngagementSponsorContact()
      saveEngagmentSupplier()
      {
        this._engagmentSupplierContact.supplierContactId=this.newSupplierConatactId;
        this._engagmentSupplierContact.engagementId=this.engagementId;
        this._engagmentSupplierContact.createdBy=this.privilegeService.getLoginUserId();
        this._engagmentSupplierContact.isSendNotification=false;
        this.engagementService.AddEngagementSupplierContact(this._engagmentSupplierContact).subscribe(res => {
    });
      }
    
      saveEngagmentSponsorContact()
      {
        this._engagmentSponsorContact.sponsorContactId=this.newSponsorConatactId;
        this._engagmentSponsorContact.engagementId=this.engagementId;
        this._engagmentSponsorContact.createdBy=this.privilegeService.getLoginUserId();
        this._engagmentSponsorContact.isSendNotification=false;
        this.engagementService.AddEngagementSponsorContact(this._engagmentSponsorContact).subscribe(res => {
      });
    }
    deleteSponsorContact(index:any,contractId:number){
        if(contractId>0)
          {
        this.confirmationService.confirm({
          message: 'Are you sure you want to delete this stakeholder from this Contract?  Note: The stakeholder will only be deleted from this contract and will still stay under the Sponsor Contacts and Engagement Stakeholders.',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.stakeholdercontactData.splice(index, 1);
            this.stakeholderSponsorContacts.splice(index, 1);
            this.updateSponsorDropdownOptions();
          },
          reject: (type: any) => {
          
          }
      });
          }
          else
          {
            this.stakeholdercontactData.splice(index, 1);
            this.stakeholderSponsorContacts.splice(index, 1);
          }
        }
      
        deleteSupplierContact(index:any,contractId:number){
          if(contractId>0)
            {
          this.confirmationService.confirm({
            message: 'Are you sure you want to delete this stakeholder from this Contract? Note: The stakeholder will only be deleted from this contract and will still stay under the Supplier Contacts and Engagement Stakeholders.',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              this.stakeholdersuppliercontactData.splice(index, 1);
              this.stakeholderSupplierContacts.splice(index, 1);
              this.updateDropdownOptions();
            },
            reject: (type: any) => {
            
            }
        });
            }
            else
            {
              this.stakeholdersuppliercontactData.splice(index, 1);
              this.stakeholderSupplierContacts.splice(index, 1);
            }
        }
        filteredStakholderSponsorContacts:any;
        filteredStakholderSupplierContacts:any;

        supplierContactDetails(event: any, index: number): void {
            const selectedSupplierContactId = event.value;
        
            // Check if the selected supplier is already in the stakeholder list
            const isDuplicate = this.stakeholderSupplierContacts.some(
              (contact,idx) => contact?.id === selectedSupplierContactId && idx!=index
            );
        
            if (isDuplicate) {
              this.messageService.add({
                key: 'tc',
                severity: 'warn',
                summary: 'Contact already selected.',
                detail: '',
              });
              setTimeout(() => {
                this.stakeholdersuppliercontactData[index].id=0;
                event.value = null; 
              }, 0);
          
              return;
            } else {
              // Add the selected stakeholder contact
              const selectedSupplierContact = this.supplierContactData.find(
                (contact) => contact.id === selectedSupplierContactId
              );
              this.stakeholderSupplierContacts[index] = selectedSupplierContact;
        
              this.updateDropdownOptions();
            }
          }
        updateDropdownOptions(): void {
            const selectedOwnerId = this.contractDetails.OwnerSupplierContactId;
            const selectedStakeholderIds = this.stakeholderSupplierContacts
              .filter(contact => contact && contact.id)
              .map(contact => contact.id);
        
            this.filteredSupplierContacts = [
              ...this.stakeholderSupplierContacts.filter(contact => contact && contact.id), // Keep selected stakeholders
              ...this.supplierContactData.filter(contact => !selectedStakeholderIds.includes(contact.id) && contact.id !== selectedOwnerId)
            ];
        
            this.filteredStakholderSupplierContacts = [
              ...this.supplierContactData.filter(contact => !selectedStakeholderIds.includes(contact.id))
            ];
          }
        
          sponsorContactDetails(event: any, index: number): void {
            const selectedSponsorContactId = event.value;
        
            const isDuplicate = this.stakeholderSponsorContacts.some(
              (contact,idx) => contact?.id === selectedSponsorContactId && idx!=index
            );
        
            if (isDuplicate) {
              this.messageService.add({
                key: 'tc',
                severity: 'warn',
                summary: 'Contact already selected.',
                detail: '',
              });
              setTimeout(() => {
                this.stakeholdercontactData[index].id=0;
                event.value = null; 
              }, 0);
          
              return;
            } else {
              const selectedSponsorContact = this.sponsorContactsDataSource.find(
                (contact) => contact.id === selectedSponsorContactId
              );
              this.stakeholderSponsorContacts[index] = selectedSponsorContact;
        
              this.updateSponsorDropdownOptions();
            }
          }
          updateSponsorDropdownOptions(): void {
            const selectedOwnerId = this.contractDetails.SponserContactId;
            const selectedStakeholderIds = this.stakeholderSponsorContacts
              .filter(contact => contact && contact.id)
              .map(contact => contact.id);
        
            this.filteredSponsorContacts = [
              ...this.stakeholderSponsorContacts.filter(contact => contact && contact.id), // Keep selected stakeholders
              ...this.sponsorContactsDataSource.filter(contact => !selectedStakeholderIds.includes(contact.id) && contact.id !== selectedOwnerId)
            ];
        
            this.filteredStakholderSponsorContacts = [
              ...this.sponsorContactsDataSource.filter(contact => !selectedStakeholderIds.includes(contact.id))
            ];
          }
        excludeContact() {
            if(this.filteredStakholderSponsorContacts){
            const sponsorContactIdsToRemove = this.sponsorContactsDataSource.map(item => item.id);
            this.filteredStakholderSponsorContacts = this.filteredStakholderSponsorContacts.filter((contact: { id: number | undefined; }) => 
                sponsorContactIdsToRemove.includes(contact.id)
            );
        }
        if(this.filteredStakholderSupplierContacts){
            const supplierContactIdsToRemove = this.supplierContactData.map(item => item.id);
            this.filteredStakholderSupplierContacts = this.filteredStakholderSupplierContacts.filter((contact: { id: any; }) => 
                supplierContactIdsToRemove.includes(contact.id)
            );
        }
            if(this.totalSponsorContact){
            this.sponsorContactsFiltered = this.totalSponsorContact.filter((contact: { id: number; }) => contact.id !== this.SponsorContactId);
            }
            if(this.totalSupplierContacts){
            this.supplierContactsFiltered = this.totalSupplierContacts.filter((contact: { id: number; }) => contact.id !== this.SupplierContactId);
            }
        }
        
        ExcludeStakeholderContacts(contactType:string,Id:number=0){
            if(contactType=='sponsor')
            {
            this.sponsorContactsFiltered = this.totalSponsorContact.filter((contact: { id: number; }) => contact.id !== Id);
             }
             if(contactType=='supplier'){
                this.supplierContactsFiltered = this.totalSupplierContacts.filter((contact: { id: number; }) => contact.id !== Id);
           }
           }
           
}
