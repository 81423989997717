<!DOCTYPE html>
<html lang="en">
<head>
    <title> Associate List</title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>
    <p-tabView class="fr-tabview__header__custom" >
        <p-tabPanel>
           
            <div class="flex-box m-t-40"> 
            <div class="fr-flex__col-12"> 
        <div class="fr-flex__container">
            <div class="fr-flex__col-3">
                <h3>List of Associates <small>({{associateList.length}} of {{ associateListcopy.length }} Records)</small></h3>
            </div>
    <div class="fr-flex__col-9"> <a *ngIf="createPrivilege" class="btn btn-primary pull-right" (click)="CreateAssociate()"> Add
            Associate
        </a>
        <button *ngIf="EmailTurnoverReport" class="btn btn-primary pull-right" (click)="emailReport()"> Email Report </button>
        <button *ngIf="exportPrivilege" (click)="exportexcelAssociates()" class="btn btn-outline-primary pull-right" [disabled]="isChecked"> <i class="pi pi-download" ></i> Export </button>
        <button id="teamAssociateListFilter" class="btn btn-outline-primary pull-right" (click)="showfilter()"
            [ngClass]="{'active-button': showHideFiltersControls}"> <i class="pi pi-filter"></i> </button>
            <a *ngIf="DashboarPrivilage && FromType==2" class="btn btn btn-primary-link pull-right m-r-10" (click)="showTeamDashboard()"> <i class="pi pi-eye"></i> View Teams Dashboard </a>
    </div>
        </div>
       
    </div>
</div>
<div *ngIf="showHideFiltersControls">
    <div class="card">
        <div class="fr-flex__col-12"> 
    <div class="filter-bg"> 
    <!-- Filters -->
    <div class="fr-flex__container">
        <div class="fr-flex__col-3">
            <div class="fr-form__group margin-zero"> <span class="p-input-icon-left"> <i
               class="pi pi-search"></i>
               <input type="text" class="form-control key-search filter-text search-active" [class.search-active]="filterDetailsModel.Key !== undefined && filterDetailsModel.Key !== null && filterDetailsModel.Key !== ''"
               [(ngModel)]="filterDetailsModel.Key" placeholder="Search by Associate Name"
               (input)="onfilter()" trimSpace /> </span> 
            </div>
         </div>
         <div class="fr-flex__col-3" *ngIf="FromType==2">
            <p-multiSelect appendTo="body" [showToggleAll]="false" class="multiSelect-active muultiselect_header_hide" panelStyleClass="custom-panel" [class.multiSelect-active]="filterDetailsModel.Contract !== undefined && filterDetailsModel.Contract !== null && filterDetailsModel.Contract.length !== 0" [options]="contractdata" [filter]="false"
                [(ngModel)]="filterDetailsModel.Contract" placeholder="Select Contracts"
                (onChange)="onfilter()"></p-multiSelect>
        </div>
       
        <div class="fr-flex__col-3">
            <p-dropdown appendTo="body"  class="filter-text dropdown-active" [class.dropdown-active]="filterDetailsModel.Role !== undefined && filterDetailsModel.Role !== null && filterDetailsModel.Role !== ''" name="supplierrole" [options]="roledata"
            [(ngModel)]="filterDetailsModel.Role" optionLabel="label" placeholder="Select Role"
            (onChange)="onfilter()"></p-dropdown>
         </div>
        <div class="fr-flex__col-3">
            <p-dropdown appendTo="body" class="filter-text dropdown-active" [class.dropdown-active]="filterDetailsModel.Status !== undefined && filterDetailsModel.Status !== null && filterDetailsModel.Status !== ''" name="status" optionLabel="label" [options]="statusdata"
                [(ngModel)]="filterDetailsModel.Status"  placeholder="Select Status"
                (onChange)="onfilter()"></p-dropdown>

        </div>
    </div>
    <div class="fr-flex__container m-t-10">
        <div class="fr-flex__col-3">
            


            <p-multiSelect appendTo="body" [showToggleAll]="false" class="multiSelect-active muultiselect_header_hide" panelStyleClass="custom-panel" [class.multiSelect-active]="filterDetailsModel.Location !== undefined && filterDetailsModel.Location !== null && filterDetailsModel.Location.length !== 0" [options]="locationdata"  [filter]="false"
            [(ngModel)]="filterDetailsModel.Location" placeholder="Select Location"
            (onChange)="onfilter()"></p-multiSelect>
 
         

         </div>
       
        
        <div class="fr-flex__col-3"> 
            <a class="btn btn-primary-link custom-pad-left-zero" (click)="clearFilters()"> Clear All Filters </a>
         </div>
    </div>
</div>
</div>
    </div>
</div>
                <div class="fr-flex__col-12">
                    <div class="inside-grid-pad"> 
                <p-table id="teamAssociateListTableName" class="primeng-table-custom" [resizableColumns]="true" columnResizeMode="expand" styleClass="p-datatable-striped p-datatable-gridlines"  [scrollable]="true" #AssociateTable [(selection)]="checkedAssociates"
                [value]="associateList" [paginator]="true" [rows]="10" [rowsPerPageOptions]="[10,20,50,100,200]"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Entries"
                [showCurrentPageReport]="true">
                <ng-template pTemplate="header">
                    <tr>
                        <th id="teamAssociateListTHCheckbox" [ngStyle]="{'width.px': FromType ===(2) ? 35 : 35}"  class="first-child-width">
                            <p-tableHeaderCheckbox (click)="CheckboxEnabled()"></p-tableHeaderCheckbox>
                        </th>
                        <th *ngIf="FromType==2 && viewContactPrivilege" id="teamAssociateListContactName" pSortableColumn="contractName" pResizableColumn [ngStyle]="{'width.px': FromType ===(2) ? 310 : 0}">Contract <p-sortIcon field="contractName"></p-sortIcon></th>
                        <th *ngIf="FromType==2 && !viewContactPrivilege" id="teamAssociateListContactName" pSortableColumn="contractName" pResizableColumn [ngStyle]="{'width.px': FromType ===(2) ? 310 : 0}">Contract <p-sortIcon field="contractName"></p-sortIcon></th>
                        <th id="teamAssociateListAssociateName" pSortableColumn="associateName" [ngStyle]="{'width.px': FromType ===(2) ? 310 : 310}" pResizableColumn pInputText>Associate Name <p-sortIcon field="associateName"></p-sortIcon></th>
                        <th id="teamAssociateListRole" pSortableColumn="role" [ngStyle]="{'width.px': FromType ===(2) ? 130 : 200}" pResizableColumn>Role <p-sortIcon field="role"></p-sortIcon></th>
                        <th id="teamAssociateListOnBoardingDate" pSortableColumn="onBoardingDate" [ngStyle]="{'width.px': FromType ===(2) ? 158 : 160}" pResizableColumn pInputText>Onboarding Date <p-sortIcon field="onBoardingDate"></p-sortIcon></th>
                        <th id="teamAssociateListOffBoardingDate" pSortableColumn="offBoardingDate" [ngStyle]="{'width.px': FromType ===(2) ? 160 : 160}"  pResizableColumn pInputText>Offboarding Date <p-sortIcon field="offBoardingDate"></p-sortIcon></th>
                        <th id="teamAssociateListStatus" pSortableColumn="status" pResizableColumn [ngStyle]="{'width.px': FromType ===(2) ? 110 : 110}">Status <p-sortIcon field="status"></p-sortIcon></th>
                        <th id="teamAssociateListLoaction" pSortableColumn="location" pResizableColumn [ngStyle]="{'width.px': FromType ===(2) ? 155 : 155}">Location <p-sortIcon field="location"></p-sortIcon></th>
                        <th id="teamAssociateListEdit" *ngIf="editPrivilege"  [ngStyle]="{'width.px': FromType ===(2) ? 60 : 60}" class="last-child-width"> Edit</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-associateList>
                    <tr>
                        <td id="teamAssociateListTDCheckbox" class="first-child-width">
                            <p-tableCheckbox [value]="associateList" (click)="CheckboxEnabled()"></p-tableCheckbox>
                        </td>
                        <td  id="teamAssociateListTDHyperLinkContract" *ngIf="FromType==2 && viewContactPrivilege" > 
                            <a *ngIf="associateList.contractId>0; else notApplicable" class="table-active-link"  href="javascript:void(0)" (click)="viewContractAssociate(associateList.contractId)" [pTooltip]="associateList.contractName.length > 24 ? associateList.contractName : ''" tooltipPosition="top"> {{ associateList.contractName }} </a>
                           
                            <ng-template #notApplicable>
                               <td>
                                  Not Applicable 
                               </td>
                             </ng-template>  
                       </td>
                       <td *ngIf="FromType==2 && !viewContactPrivilege"> 
                        <a *ngIf="associateList.contractId>0; else notApplicable"  [pTooltip]="associateList.contractName.length > 24 ? associateList.contractName : ''" tooltipPosition="top"> {{ associateList.contractName }} </a>
                       
                        <ng-template #notApplicable>
                           <div>
                             <p> Not Applicable  </p>
                           </div>
                         </ng-template>  
                   </td>
                        <td id="teamAssociateListTDHyperLinkAssociate" [pTooltip]="associateList.associateName.length > 30 ? associateList.associateName : ''" tooltipPosition="top">
                            <a *ngIf="viewPrivilege; else disabledLink" class="table-active-link" (click)="viewAssociate(associateList.id)">{{associateList.associateName}}</a> 
                            <ng-template #disabledLink>
                                {{ associateList.associateName}}
                              </ng-template>
                        </td>

                       

                        <td [pTooltip]="associateList.role.length > 12 ? associateList.role : ''" tooltipPosition="top"> {{ associateList.role }} </td>
                        <td> {{ associateList.onBoardingDate | date:_dateformart.GridDateFormat}} </td>
                        <td> {{ associateList.offBoardingDate ? (associateList.offBoardingDate | date:_dateformart.GridDateFormat) : '--'}} </td>
                        <td [ngClass]="{'active': associateList.status === 'Onboard', 'inactive': associateList.status === 'Offboarded'}" [pTooltip]="associateList.associateName.status > 15 ? associateList.status : ''" tooltipPosition="top"> <span> {{ associateList.status }} </span>  </td>
                        <td [pTooltip]="associateList.associateName.location > 15 ? associateList.location : ''" tooltipPosition="top"> {{ associateList.location }} </td>
                        
                      
                    
                        <td id="teamAssociateListEdit" *ngIf="editPrivilege" class="grid-icon-center"><span class="pi pi-pencil" (click)="EditAssociate(associateList.id)"></span></td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                    <td colspan="9" class="text-center"> No Records</td>
                    </tr>
                    </ng-template>
            </p-table>
        </div>
</div>    
<div *ngIf="FromType==2 && DashboarPrivilage">
    <div class="fr-flex__container"> 
        <div class="fr-flex__col-8">
            <div *ngIf="showheading" class="map_heading"> <h3> Onboard Associates and Laptops </h3> </div>
            <div id="dashboardContainer" class="m-t-20 m-b-20 m-l-10"></div>
        </div>
    </div>
   
</div>   
</p-tabPanel>
</p-tabView>

<p-dialog appendTo="body" header="{{popupHeader}}" appendTo="body" [(visible)]="mailPopup" [modal]="true"   [resizable]="true" [closable]="false">
    <ng-template pTemplate="content">
          <div class="fr-flex__container">
             <div class="fr-flex__col-12">
                <div class="fr-form__group">
                   <label for="name">Enter the Date Range for which you would like the personnel turnover report<em> * </em> <span class="max-length">  </span>
                    <i  pInputText pTooltip="Personnel Turnover = 100 * (Attrition/Number of Associates on Last Day of selected rage)" tooltipPosition="top" class="pi pi-info-circle"> </i>
                   </label>
                   <p-calendar appendTo="body" class="calendar-active" placeholder="dd/mm/yy - dd/mm/yy"  selectionMode="range" [(ngModel)]="selectedDateRange" [maxDate]="maxDate" [minDate]="minDate"  dateFormat="dd/mm/yy" [showClear]="true" (ngModelChange)="onEmailSelectDate()"  [showIcon]="true"></p-calendar>
                </div>
             </div>
          </div>
    </ng-template>
    <!-- Dialog Footer Start -->
    <ng-template pTemplate="footer">
       <button [disabled]="isSendEmailbtnEabled||selectedDateRange==null||endDate==null" type="button" class="btn btn-primary pull-right" (click)="sendNotification()"> Send Email </button>
       <button type="button" class="btn btn-secondary pull-right" (click)="closeMailDialog()"> Cancel </button>
    </ng-template>
    <!-- Dialog Footer End -->
    </p-dialog>
</body>
</html>
