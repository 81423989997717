<!DOCTYPE html>
<html lang="en">
<head>
    <title> Engagement Summary </title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>
    <p-tabView class="fr-tabview__header__custom">
        <p-tabPanel>
<div class="card-pad"> 
    <div class="fr-flex__container m-t-40">
        <div class="fr-flex__col-4">
            <div class="fr-form__view">
                <label> Total No of Contracts </label>
                <span> <em> {{totalNoOfContracts ? totalNoOfContracts : "--"}} </em></span>
            </div>
            </div>
            <div class="fr-flex__col-4">
                <div class="fr-form__view">
                    <label> Total Contracted Value​ </label>
                    <span *ngIf='totalContractedValue==undefined'><em>{{totalContractedValue? totalContractedValue: "--" }}</em></span>
                    <span *ngIf='totalContractedValue!=undefined'> <em> {{ getFormattedValue(totalContractedValue, selectedCurrency) }} </em></span>
                </div>
                </div>
                <div class="fr-flex__col-4">
                    <div class="fr-form__view">
                        <label>Viewing Currency 
                            <span class="valueSelector"> <i pInputText
                                pTooltip="{{tooltipContent}}" tooltipPosition="top"
                                class="pi pi-info-circle"> </i> </span>
                        </label>                    
                        <p-dropdown appendTo="body" id="engsummaryCurrencyDropdown" [filter]="true" (onChange)="OnCurrencyChange($event)" [(ngModel)]="selectedCurrency"  [options]="currenciesData"  optionLabel="code" optionValue="code" ></p-dropdown>       
                    </div>
                    </div>
        </div>
        <div class="fr-flex__container">
            <div class="fr-flex__col-4">
                <div class="fr-form__view">
                    <label> Forecasted Billing Till Date </label>
                    <span *ngIf='forecastedBillingYTD==undefined'> <em>  {{ forecastedBillingYTD? forecastedBillingYTD : "--"  }} </em></span>
                    <span *ngIf='forecastedBillingYTD!=undefined'> <em>  {{ getFormattedValue(forecastedBillingYTD, selectedCurrency) }} </em></span>
                </div>
                </div>
                <div class="fr-flex__col-4">
                    <div class="fr-form__view">
                        <label> Actual Billing Till Date </label>
                        <span *ngIf='actualBillingYTD==undefined'> <em>  {{ actualBillingYTD? actualBillingYTD : "--"  }} </em></span>
                        <span *ngIf='actualBillingYTD!=undefined'> <em>  {{ getFormattedValue(actualBillingYTD, selectedCurrency) }} </em></span>
                    </div>
                    </div>
                  
            </div>
            <div class="fr-flex__container">
                <div class="fr-flex__col-4">
                    <div class="fr-form__view">
                        <label> Payment Till Date </label>
                        <span *ngIf='paymentYTD==undefined'> <em>  {{ paymentYTD? paymentYTD : "--"  }} </em></span>
                        <span *ngIf='paymentYTD!=undefined'> <em>  {{ getFormattedValue(paymentYTD, selectedCurrency) }} </em></span>
                    </div>
                    </div>
                    <div class="fr-flex__col-4">
                        <div class="fr-form__view">
                            <label> Outstanding Amount <i  pInputText pTooltip="Actual Billed Till Date - Payment Till Date" tooltipPosition="top" class="pi pi-info-circle"> </i>  </label>
                            <span *ngIf='outstandingAmount==undefined'> <em>  {{ outstandingAmount? outstandingAmount : "--" }} </em></span>
                            <span *ngIf='outstandingAmount!=undefined'> <em>  {{ getFormattedValue(outstandingAmount, selectedCurrency) }} </em></span>
                        </div>
                        </div>
                        <div class="fr-flex__col-4"> 
                            <div class="fr-form__view">
                                <label>Accumulative Export <i  pInputText pTooltip="For all Contracts in this Engagement" tooltipPosition="top" class="pi pi-info-circle"> </i> <button *ngIf="ExportPrivilage" (click)="GetEngagementAccumulativeExportData()" class="btn btn-outline-primary pull-right m-t--15" [disabled]="selectedAccumulativeExport==0"> <i class="pi pi-download" ></i> Export </button></label>                    
                                <p-dropdown appendTo="body" id="accumulativeExportDropdown" placeholder="Select"  [filter]="true" (onChange)="OnAccumulativeExportChange($event)" [(ngModel)]="selectedAccumulativeExport"  [options]="AccumulativeExportData"  optionLabel="Text" optionValue="Id" ></p-dropdown>
                            </div>
                        </div>
                      
                </div>


<!-- start FinanceEngagementDashboard Code -->
<div *ngIf="viewFinanceDashboard">
    <div class="fr-flex__container m-t-10">
        
        <div class="fr-flex__col-3">
            <div class="fr-form__group">
                <label>Finance Dashboard to View</label>
                <p-dropdown appendTo="body" [options]="DashboardToViewDataSource" placeholder="Select Dashboard to View" optionLabel="label" optionValue="value"
                    (onChange)="onDashboardToViewChange($event)" [(ngModel)]="selectedDashboardToView"></p-dropdown>
            </div>
        </div>
        <div class="fr-flex__col-3">
            <div class="fr-form__group">
                <label>Fiscal Year</label>
                <p-dropdown appendTo="body" [filter]="true" [options]="fiscalYearList" optionLabel="label" optionValue="value" placeholder="Select Fiscal Year"
                [disabled]="IsDashboardTillDate" [(ngModel)]="selectedFiscalYear" (onChange)="onFiscalYearChange($event)"></p-dropdown>
                
            </div>
        </div>
        <div class="fr-flex__col-1 custom-pad-left-zero">
            <div class="fr-form__group">
                <label>&nbsp;</label>
                <button class="btn btn-primary pull-left" (click)="onDashboardApplyClick()" [disabled]="isApplyDisable"> Apply</button>
                </div>
            </div>
        </div>
    </div>

    <div class="tab-container" *ngIf="viewFinanceDashboard">
        <div>
           <div id="DashboardContainer" class="m-t-20 m-b-20"></div>
        </div>
    </div>
<!-- end FinanceEngagementDashboard Code -->
 <!-- Fiscal year code  Start -->
 <div class="fr-flex__container m-t-20">
    <div class="fr-flex__col-4">
        <h3>Fiscal Year Budget</h3>
    </div>
</div>
<div class="fr-flex__col-12 m-b-10"> 
    <p-table id="fiscalYearDataGrid" class="primeng-table-custom custom-cells__contractsummary" [resizableColumns]="true" columnResizeMode="expand" styleClass="p-datatable-striped p-datatable-gridlines" [scrollable]="true" [value]="engagementFiscalYearData" editMode="row">
        <ng-template pTemplate="header">
            <tr>                
                <th id="conSummaryFiscalYear" pSortableColumn="fiscalYear" pResizableColumn>Fiscal Year <p-sortIcon field="fiscalYear"></p-sortIcon> </th>
                <th id="conSummaryMrpBudget" pSortableColumn="budgetFY" pResizableColumn>MRP Approved Budget <p-sortIcon field="budgetFY"></p-sortIcon></th>
                <th id="conSummaryActualBilled" pSortableColumn="actualBilledFY" pResizableColumn>Actual Billed <p-sortIcon field="actualBilledFY"></p-sortIcon></th>
                <th id="conSummaryBudgetBalance" pSortableColumn="budgetBalanceFY" pResizableColumn>Budget Balance <p-sortIcon field="budgetBalanceFY"></p-sortIcon></th>
                
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-fiscalData> 
            <tr>
                <td>{{ fiscalData.fiscalYear}}</td>
                <td>{{(fiscalData.totalActiveContractBudget==null||fiscalData.totalActiveContractBudget=="")?'--':getFormattedValue(fiscalData.totalActiveContractBudget, selectedCurrency)}}</td>
                <td>{{(fiscalData.totalActiveContractActualBilled==null||fiscalData.totalActiveContractActualBilled=="")?'--':getFormattedValue(fiscalData.totalActiveContractActualBilled, selectedCurrency)}}</td>
                <td>{{(fiscalData.totalActiveContractBudgetBalance==null||fiscalData.totalActiveContractBudgetBalance=="")?'--':getFormattedValue(fiscalData.totalActiveContractBudgetBalance, selectedCurrency)}}</td>
              
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
            <td colspan="4" class="text-center"> No Records</td>
            </tr>
            </ng-template>
    </p-table>
</div>

<!-- Fiscal year code  End -->

                <div class="fr-flex__col-12"> 
                <div class="fr-flex__container">
                    <div class="fr-flex__col-8">
                    </div>
                    <div class="fr-flex__col-4 custom-pad-zero">
                      <button  *ngIf="ExportPrivilage"  (click)="exportexcelInvoice()" class="btn btn-outline-primary pull-right m-b-10" [disabled]="isChecked"> <i class="pi pi-download" ></i> Export </button>
                    </div>
                </div>
        </div>
                <div class="fr-flex__col-12"> 
                <p-table id="engSummaryTableName" class="primeng-table-custom finance-custom-table" [resizableColumns]="true" columnResizeMode="expand" styleClass="p-datatable-striped p-datatable-gridlines" [value]="engagementSummarydata" 
                [paginator]="true" [scrollable]="true" 
                [rows]="10" [(selection)]="checkedInvoice"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Entries"
                [rowsPerPageOptions]="[10, 20, 50, 100, 200]">
                  <ng-template pTemplate="header">
                      <tr> 
                        <th id="engSummaryTHCheckbox" class="first-child-width">
                            <p-tableHeaderCheckbox (click)="CheckboxEnabled()"></p-tableHeaderCheckbox>
                        </th> 
                          <th id="engSummaryContractID" pSortableColumn="contractID" pResizableColumn> Contract ID <p-sortIcon field="contractID"></p-sortIcon></th>
                          <th id="engSummaryTitle" pSortableColumn="title" pResizableColumn> Title <p-sortIcon field="title"></p-sortIcon></th>
                          <th id="engSummaryTotalContractValue" pSortableColumn="totalContractValue" pResizableColumn pInputText> Total Contract Value <p-sortIcon field="totalContractValue"></p-sortIcon></th>
                          <th id="engSummaryPONumber" pSortableColumn="poNumber" pResizableColumn> PO Number <p-sortIcon field="poNumber"></p-sortIcon></th>
                          <th id="engSummaryForecastedBillingTillDate" pSortableColumn="forecastedBillingYTD" pResizableColumn pInputText> Forecasted Billing Till Date <p-sortIcon field="forecastedBillingYTD"></p-sortIcon></th>
                          <th id="engSummaryActualBilledTillDate" pSortableColumn="actualBilledYTD" pResizableColumn pInputText> Actual Billed Till Date <p-sortIcon field="actualBilledYTD"></p-sortIcon> </th>
                          <th id="engSummaryPaymentTillDate" pSortableColumn="paymentYTD" pResizableColumn pInputText>  Payment Till Date <p-sortIcon field="paymentYTD"></p-sortIcon></th>
                          <th id="engSummaryPOUtilization" pSortableColumn="poUtilization" pResizableColumn pInputText>  PO Utilization (%) <p-sortIcon field="poUtilization"></p-sortIcon></th>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-supContracts> 
                      <tr>
                        <td id="engSummaryTDCheckbox" class="first-child-width">
                            <p-tableCheckbox [value]="supContracts" (click)="CheckboxEnabled()"></p-tableCheckbox>
                        </td>
                          <td [pTooltip]="supContracts.contractID.length > 15 ? supContracts.contractID : ''" tooltipPosition="top">{{ supContracts.contractID }}</td>
                          <td [pTooltip]="supContracts.title.length > 15 ? supContracts.title : ''" tooltipPosition="top">{{ supContracts.title }}</td>
                          <td [pTooltip]="supContracts.totalContractValue.length > 10 ? supContracts.title : ''" tooltipPosition="top">{{ getFormattedValue(supContracts.totalContractValue, selectedCurrency) }} </td>
                          <td [pTooltip]="supContracts.poNumber.length > 10 ? supContracts.poNumber : ''" tooltipPosition="top">{{ supContracts.poNumber }}</td>
                          <td [pTooltip]="supContracts.forecastedBillingYTD.length > 10 ? supContracts.forecastedBillingYTD : ''" tooltipPosition="top">{{ getFormattedValue(supContracts.forecastedBillingYTD, selectedCurrency) }} </td>
                          <td [pTooltip]="supContracts.actualBilledYTD.length > 10 ? supContracts.actualBilledYTD : ''" tooltipPosition="top">{{ getFormattedValue(supContracts.actualBilledYTD, selectedCurrency) }} </td>
                          <td [pTooltip]="supContracts.paymentYTD.length > 10 ? supContracts.paymentYTD : ''" tooltipPosition="top">{{ getFormattedValue(supContracts.paymentYTD, selectedCurrency) }} </td>
                          <td [pTooltip]="supContracts.poUtilization.length > 10 ? supContracts.poUtilization : ''" tooltipPosition="top">{{ supContracts.poUtilization }}</td>
              
              
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                    <tr>
                    <td colspan="9" class="text-center"> No Records</td>
                    </tr>
                    </ng-template>
              </p-table>
            </div>
           
            </div>







            </p-tabPanel>
            </p-tabView>
          
</body>
</html>                
    
