export class TeamAssociateDetails
{
    
    id?:number;
    associateName?:string;
    email?:string
    countryCode?:string;
    phoneNumber?:string;
    address?:string;
    onBoardingDate?:any;
    offBoardingDate?:any;
    sponsorUsername?:string;
    supplierUsername?:string;
    sponsorAsset?:string;
    supplierAsset?:string;
    notes?:string;
    associateRoleId?:number;
    sponsorContactId?:number;
    countryId?:number;
    allocationTypeId?:number;
    supplierProfileId?:number;
    contractDetailId?:number;
    statusMappingId?:number;
    teamType?:number;
    createdBy:number=0;
    createdDate?:Date;
    updatedBy?:number=0;
    updatedDate?:Date;
    isDeleted?:boolean;
    engagementId?:number;
    engagementUsername?:string;
    sponsorsAsset?:number;
    functionalHead?:string;
    offboardingReason?:string;
    sponsorAssetDetails?:string;
      }


export class TeamChildComponents
{
    associateChildList:number=1;
    associateChildAddEdit:number=2;
    associateChildView:number=3;
}

export class PersonnelTurnoverEmailPayload
{
  StartDate?:any;
  EndDate?:any;
  EngagementName?:any;
  ContractName?:any;
  PersonnelTurnover?: any;
  OnboardAssociates?: any;
  OffboardedAssociates?: any;
  createdBy?:any;
  TotalCountOnEndDate?:any;
}
export interface Associate {
  statusCode: string;
  offBoardingDate: Date | string | number;
  onBoardingDate: Date | string | number;
}
 
