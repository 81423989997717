import { Component , OnInit  } from '@angular/core';
import { } from "@angular/common/http";
import { } from '@angular/forms';
import { SponserService } from '../../Services/sponser.service';
import { Router } from '@angular/router';
import { GetSponserFilter } from '../../Models/getSponserFilter';
import { ActiveInActiveStatus,StatusCodes,breadcrumnsCodes } from 'src/app/shared/constants/global.constants';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { MenuItem } from 'primeng/api';
import { LoaderService } from 'src/app/Loader/loader.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { Privileges } from 'src/app/shared/constants/privileges.constants';
@Component({
  selector: 'sponsor',
  templateUrl: './sponsor.component.html',
  styleUrls: ['./sponsor.component.css']
})

export class SponsorComponent  implements OnInit  {
  public GetSponsersWithSelectedFilters = new GetSponserFilter();
  selectedSponserName: any; 
  selectedSponserId: any; 
  selectedSponserStatus:any;
  selectedSponserIdorName:any;
  sponser:any[]=[];
  sponsers: any[] = [] ;
 sponsername:any[]=[] ;
 sponserid:any[]=[] ;
 sponserStaus :any[] = [];
 selectedRecordsToExpotToExcel : any[] = [];
 checkedSponsers: any[]=[];
  showHiddenControls: boolean = false;
  isChecked:boolean=true;
  status:any[]=[];
  _enumBreadcrumb :any;
  sponserStatusData: any[]=[];
  _enumPrivileges:any;
  createPrivilege:boolean=false;
  editPrivilege:boolean=false;
  viewPrivilege:boolean=false;
  ExportPrivilege:boolean=false;
  sponserIds:any;
  groupIds:any;
  totalRecordCount: number = 0;
 filteredRecordCount: number = -1;
  constructor(private privilegeService: PrivilegeService,private router: Router,private service: SponserService,private breadcrumbService: BreadcrumbService,private loaderService: LoaderService){}


    ngOnInit() {
      var userData=this.privilegeService.getUserData();
      this.sponserIds=userData.sponsers;
      this._enumBreadcrumb=breadcrumnsCodes;
      this._enumPrivileges=Privileges;
      this.generateBreadcrumb();
  
      if(this.sponserIds!=null && this.sponserIds.length>0)
      {
        this.GetAllSponsersGroupBy(this.sponserIds);
      }
      this.GetStatus();
      this.loaderService.hidefinalLoader();// hide after 4 seconds even get any error
      this.BindPrivileges();
    }
    BindPrivileges(){
      const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);
       this.createPrivilege = hasPermission(Privileges.CreateSponsor);
  this.editPrivilege = hasPermission(Privileges.EditSponsor);
  this.viewPrivilege = hasPermission(Privileges.ViewSponsor);
  this.ExportPrivilege = hasPermission(Privileges.ExportlistofSponsors);
      }
    
    
   GetAllSponsersGroupBy(sponserIds:number[]) {
    this.sponsername = [];
    this.sponserid=[];

      this.loaderService.showLoader();
 this.service.GetAllSponsersByGroup(sponserIds)
    
      .subscribe(res=>{
        this.loaderService.hideLoader();
        this.sponsers = res.data;
       // onload show only active sponsors 
       this.sponsers = this.sponsers.filter((obj) => { return obj.isDeleted==false});
       this. totalRecordCount=this.sponsers.length;
       this.filteredRecordCount=this.sponsers.length;
       this.sponsers = this.sponsers.filter((obj) => {
                this.sponsername.push( {label: obj.name});
                this.sponserid.push({label: obj.sponserId});
         if(!obj.isDeleted){
          return obj.status = ActiveInActiveStatus.Active; 
         }
          else{
           return obj.status =  ActiveInActiveStatus.InActive;
         }
      });
     

      })
    }
   
    GetStatus() {
      this.service.GetStatus()
      .subscribe(res=>{
       this.status = res.data;
       this.status = this.status.filter((obj) => {
         if(obj.code==StatusCodes.All || obj.code==StatusCodes.Active || obj.code==StatusCodes.InActive){
                this.sponserStaus.push( {label: obj.name,value:obj.code});
         }
      });
      })
    }
    CheckboxEnabled(){
      if(this.checkedSponsers.length>0){
        this.isChecked=false;
      }
      else{
        this.isChecked=true;
      }
    }
    exportSponsers()
    {
    this.selectedRecordsToExpotToExcel =this.checkedSponsers;
    const dataToExport = this.selectedRecordsToExpotToExcel.map((sponser) => {
          return {
            SponsorName: sponser.name,
            SponsorID: sponser.sponserId,
          
          };
        });
        this.exportExcel(dataToExport);
        
    }
    hiddenControls(){
      if(this.showHiddenControls){
        this.showHiddenControls=false;
      }
      else{
        this.showHiddenControls=true;
      }
    }
    clearFilters() {
      this.selectedSponserIdorName = null;
      this.selectedSponserName = null;
      this.selectedSponserId = null;
      this.selectedSponserStatus = null;
     this.GetAllSponsersGroupBy(this.sponserIds);
      this.checkedSponsers = []; // Clear the checkedSponsers array
      this.isChecked=true;
    }
    filterData()
    {
      this.checkedSponsers = []; // Clear the checkedSponsers array
      this.isChecked=true;
      var sponserIdorName = this.selectedSponserIdorName==undefined ? '' : this.selectedSponserIdorName;
      var sponserName = this.selectedSponserName == undefined ? '' : this.selectedSponserName.label;
      var sponserId = this.selectedSponserId ==undefined ? '' : this.selectedSponserId.label;
      this.GetSponsersWithSelectedFilters.SponserIdOrName = sponserIdorName;
      this.GetSponsersWithSelectedFilters.Name = sponserName;
      this.GetSponsersWithSelectedFilters.SponserId = sponserId;
      if(this.selectedSponserStatus == undefined)
      {
         this.GetSponsersWithSelectedFilters.IsActive = StatusCodes.Active;
      }else{
        this.GetSponsersWithSelectedFilters.IsActive  = this.selectedSponserStatus.value.toString();
      }
         this.groupIds= this.sponserIds.join(',');
      this.GetSponsersWithSelectedFilters.GroupIds=this.groupIds;
      this.service.GetSponsersWithFilters(this.GetSponsersWithSelectedFilters)
      .subscribe(res=>{
       this.sponsers = res.data;
       this.filteredRecordCount=this.sponsers.length;
            this.sponsers = this.sponsers.filter((obj) => {
             if(!obj.isDeleted){
              obj.status = ActiveInActiveStatus.Active;
             }
             else{
              obj.status = ActiveInActiveStatus.InActive; 
             }
             return obj.status;
         })
      })
    }
    exportExcel(SelectedRecords:any) {
      import('xlsx').then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(SelectedRecords);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer: any = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array"
        });
       this.saveAsExcelFile(excelBuffer, "sponsors");
      });
    }
    saveAsExcelFile(data: ArrayBuffer, fileName: string): void {
  const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `${fileName}.xlsx`;
  link.click();
  window.URL.revokeObjectURL(url);
}
viewSponser(item:any)
{
  this.router.navigate(['/viewsponsor',item.id]);
}
editSponser(item:any)
{
  this.router.navigate(['/editsponsor',item.id]);
}
generateBreadcrumb()
{
  this.breadcrumbService.generateBreadcrumb(this._enumBreadcrumb.LSP);
}
getBreadcrumbs(): MenuItem[] {
  return this.breadcrumbService.getBreadcrumbs();
}
GetsponserStatus(){
  this.service.getCodeListData(3,'Sponser').subscribe(
    data=>{
      this.sponserStatusData=data.data;
      
    }
  );
}


}

