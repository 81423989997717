import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { environment } from "src/environments/environment";
import {map} from 'rxjs/operators'
import { ContractMeetingDetails } from "../Modals/ContractMeeting";
import { AuthCommonService } from "src/app/shared/Authentication/auth.service";

@Injectable({

  providedIn: 'root'

})

export class ContractMeetingService{
    //apiURL = environment._contractApiBaseURl;
    readonly _governanceMeetingApiBaseURl?:string="";
    readonly _fileApiBaseURl?:string="";
    _meetingDetail:any

  constructor(private http: HttpClient,private authCommonService: AuthCommonService) { 
    this._governanceMeetingApiBaseURl=environment?._governanceApiBaseURl;
    this._fileApiBaseURl=environment?._fileApiBaseURl;
  }

  public setMeetingDetails(data:any){
    this._meetingDetail=data;
  }
  
  public ContractMeetingData(MeetingData:ContractMeetingDetails): Observable<any> {
   
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken()
  }) };
    return this.http.post<any>(this._governanceMeetingApiBaseURl +  '/GovernanceMeetings/AddContractMeeting', MeetingData,httpOptions);
  }

  public getContractMeetingsByContractId(contractDetailsId:number): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken()
  }) };
    return this.http.get<any>(this._governanceMeetingApiBaseURl +  '/GovernanceMeetings/GetContractMeetingsByContractId?contractDetailsId=' + contractDetailsId, httpOptions);
  }

  public getContractMeetingsById(contractMeetingId:number): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken()
  }) };
    return this.http.get<any>(this._governanceMeetingApiBaseURl +  '/GovernanceMeetings/GetContractMeetingById?contractMeetingId=' + contractMeetingId, httpOptions);
  }

  public ContractUpdateMeetingData(MeetingData:ContractMeetingDetails): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken()
  }) };
    return this.http.post<any>(this._governanceMeetingApiBaseURl +  '/GovernanceMeetings/UpdateContractMeeting', MeetingData,httpOptions);
  }
  DeleteContractMeeting(contractMeetingId: any, occurrenceId: any): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'ApiKey': this.authCommonService.getAuthenticationToken() }) };
    return this.http.put<any>(`${this._governanceMeetingApiBaseURl}/GovernanceMeetings/DeleteContractMeetingById?contractMeetingId=${contractMeetingId}&occurrenceId=${occurrenceId}`, {}, httpOptions);
  }
  

}
