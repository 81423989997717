<!DOCTYPE html>
<html lang="en">
<head>
    <title> Engagement View Meeting </title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>
<div class="card-pad">
       <div class="fr-flex__container sticky-sub-header">
           <div class="fr-flex__col-8">
               <h3>View Meeting <span> <em class="fr-subtitle_view"> </em> </span></h3>
           </div>
           <div class="fr-flex__col-4"> <select class="form-control selectpicker pull-right" [(ngModel)]="selectedOption" (change)="onOptionChange()">
                   <option disabled selected> Actions </option>
                   <option *ngIf="editPrivilege"> Edit </option>
                   <option *ngIf="deletePrivilege"> Delete </option>
               </select> 
               <a class="btn btn-primary-link backtolist pull-right" (click)="BackToList()">  <i class="pi pi-arrow-left"></i> Back to list </a>
            </div>
       </div>
    <!-- Recurring "Yes" View Engagement Start -->
    <div class="scroll-start-here-sub-tabs">
   <div *ngIf="isRecurringMeeting">
       <div class="fr-flex__container">
           <div class="fr-flex__col-4">
               <div class="fr-form__view"> <label> Title <em> * </em></label> 
                <span [pTooltip]="engagementMeetingDetail?.title?.length > 40 ? engagementMeetingDetail?.title : ''" tooltipPosition="top"> <em> {{engagementMeetingDetail?.title}} </em></span> </div>
           </div>
           <div class="fr-flex__col-4">
               <div class="fr-form__view"> <label> Organizer <em> * </em></label> <span [pTooltip]="engagementMeetingDetail?.organizer?.length > 40 ? engagementMeetingDetail?.organizer : ''" tooltipPosition="top"> <em> {{engagementMeetingDetail?.organizer}}</em></span> </div>
           </div>
           <div class="fr-flex__col-4">
               <div class="fr-form__view"> <label> Recurring <em> * </em></label> <span *ngIf="engagementMeetingDetail?.recurring"> <em>Yes </em></span> <span *ngIf="!engagementMeetingDetail?.recurring"> <em>No</em></span> </div>
           </div>
       </div>
       <div class="fr-flex__container">
           <div class="fr-flex__col-12">
               <div class="fr-form__view" > <label> Agenda/Description <em> * </em></label>
                <span class="full-width" *ngIf="agenda  == null">--</span> <span class="full-width" [innerHTML]="agenda" *ngIf="agenda  != null"> {{agenda }} </span> </div>
           </div>
       </div>
       <div class="fr-flex__container">
           <div class="fr-flex__col-3">
               <div class="fr-form__view"> <label> Added By</label> <span> <em> {{MeetingCreatedByUserName}} </em></span> </div>
           </div>
           <div class="fr-flex__col-3">
               <div class="fr-form__view"> <label> Added Date & Time </label> <span> <em> {{engagementMeetingDetail?.createdDate | date: _dateformart.DateTimeWithZone  }} {{_dateformart.DisplayUTC}}</em></span> </div>
           </div>
           <div class="fr-flex__col-3">
               <div class="fr-form__view"> <label> Modified By</label> 
                <span  *ngIf="MeetingUpdatedByUserName !=null"> <em>  {{MeetingUpdatedByUserName}} </em></span>
                <span *ngIf="MeetingUpdatedByUserName == null || MeetingUpdatedByUserName == undefined || MeetingUpdatedByUserName == ''"> <em> -- </em></span>
            </div>
           </div>
           <div class="fr-flex__col-3">
               <div class="fr-form__view"> <label> Modified Date & Time </label> <span *ngIf="engagementMeetingDetail?.updatedDate!=null"> <em> {{engagementMeetingDetail?.updatedDate | date: _dateformart.DateTimeWithZone }} {{_dateformart.DisplayUTC}}</em></span> <span *ngIf="engagementMeetingDetail?.updatedDate==null"> <em> --</em></span> </div>
           </div>
       </div>
       <div class="fr-flex__container">
           <div class="fr-flex__col-12">
               <div class="fr-form__view">
                   <h3>Occurrences </h3>
               </div>
           </div>
       </div>
       <div class="fr-flex__col-12">
           <div class="m-b-50">
               <p-table class="primeng-table-custom" styleClass="p-datatable-striped" [scrollable]="true" [value]="engagementMeetingDetail?.engagementMeetingOccurrences">
                   <ng-template pTemplate="header">
                       <tr>
                           <th id="engViewMeetingOccurrencesDate">Date</th>
                           <th id="engViewMeetingOccurrencesTotalActions">Total Actions</th>
                           <th id="engViewMeetingOccurrencesTotalOpenActions">Open Actions</th> 
                       </tr>
                   </ng-template>
                   <ng-template pTemplate="body" let-meetingOccurrence let-i="rowIndex">
                       
                       <tr>
                           <td id="engViewMeetingOccurrenceHyperLink" routerLinkActive="active" (click)="viewOccurrence(i)"><a class="table-active-link">{{ meetingOccurrence?.occurrenceDate | date: _dateformart.GridDateFormat }} </a></td>
                           <td> <span *ngIf="meetingOccurrence?.totalActionsCount==null">0 </span><span *ngIf="meetingOccurrence?.totalActionsCount!=null">{{ meetingOccurrence?.totalActionsCount  }} </span></td>
                           <td> <span *ngIf="meetingOccurrence?.openActionsCount ==null">0 </span><span *ngIf="meetingOccurrence?.openActionsCount!=null">{{ meetingOccurrence?.openActionsCount }} </span></td>
                           
                       </tr>
                   </ng-template>
               </p-table>
           </div>
       </div>
   </div> <!-- Recurring "Yes" View Engagement End -->
   <div *ngIf="!isRecurringMeeting">
    <div class="scroll__start">
       <div class="fr-flex__container">
           <div class="fr-flex__col-4">
               <div class="fr-form__view"> <label> Title <em> * </em></label> <span [pTooltip]="engagementMeetingDetail?.title.length > 45 ? engagementMeetingDetail?.title : ''" tooltipPosition="top"> <em> {{engagementMeetingDetail?.title}} </em></span> </div>
           </div>
           <div class="fr-flex__col-4">
               <div class="fr-form__view"> <label> Organizer <em> * </em></label> <span [pTooltip]="engagementMeetingDetail?.organizer.length > 45 ? engagementMeetingDetail?.organizer : ''" tooltipPosition="top"> <em> {{engagementMeetingDetail?.organizer}} </em></span> </div>
           </div>
           <div class="fr-flex__col-4">
               <div class="fr-form__view"> <label> Recurring <em> * </em></label> <span *ngIf="engagementMeetingDetail?.recurring"> <em>Yes </em></span> <span *ngIf="!engagementMeetingDetail?.recurring"> <em>No</em></span> </div>
           </div>
       </div>
       <div *ngFor="let occurrence of engagementMeetingDetail?.engagementMeetingOccurrences; let i = index">
           <div class="fr-flex__container">
               <div class="fr-flex__col-4">
                   <div class="fr-form__view"> <label> Date <em> * </em></label> <span> <em> {{occurrence.occurrenceDate | date: _dateformart.GridDateFormat}} </em></span> </div>
               </div>
               <div class="fr-flex__col-8">
                   <div class="fr-form__view"> <label> Attachments <i pInputText pTooltip="Maximum 3 Attachments allowed." tooltipPosition="top" class="pi pi-info-circle"> </i> </label>
                       <div class="display-inline-block" *ngFor="let attachment of occurrence?.engagementMeetingOccurrenceAttachments; let j = index"> <a class="form-list-active"> <span [pTooltip]="attachment.fileName.length > 35 ? attachment.fileName : ''" tooltipPosition="top" (click)="downloadAttachment(attachment)"> {{attachment.fileName}} </span> <i pInputText pTooltip="Download" (click)="downloadAttachment(attachment)" tooltipPosition="top" class="pi pi-download"></i> </a>
                           
                           
                       </div> <span *ngIf="occurrence?.engagementMeetingOccurrenceAttachments  == null ||  occurrence?.engagementMeetingOccurrenceAttachments?.length==0"> -- </span>
                   </div>
               </div>
           </div>
           <div class="fr-flex__container" *ngIf="!engagementMeetingDetail?.recurring">
               <div class="fr-flex__col-12">
                   <div class="fr-form__view"> <label> Agenda/Description <em> * </em></label> 
                    <span class="full-width"  *ngIf="agenda  == null">--</span> <span class="full-width" [innerHTML]="agenda" *ngIf="agenda  != null"> {{agenda }} </span>
                 </div>
               </div>
           </div>
           <div class="fr-flex__container">
               <div class="fr-flex__col-4">                  
                   <div class="fr-form__view">
                    <label> Attendees</label>
                    <ng-container *ngFor="let meetingAttendees of occurrence?.engagementMeetingAttendees; let k = index">
                      <ng-container *ngIf="meetingAttendees?.attendees else noEngviewAttendees">
                            <ul class="services_ul meeting" *ngFor="let attendefirst of meetingAttendees?.attendees.split(','); let i = index">
                                <li [pTooltip]="attendefirst.length > 15 ? attendefirst : ''" tooltipPosition="top" *ngIf="i == 0">  {{attendefirst ? attendefirst : '--'}} </li>
                              </ul>
                              <span *ngIf="meetingAttendees?.attendees.split(',').length > 1"><em>  <p-button (onClick)="showAttendeesDialog()" [hidden]="showAttendeesButton" [ngClass]="'blue-button'" [label]="'+'+(meetingAttendees?.attendees.split(',').length - 1).toString()"></p-button> </em></span>
                              <p-dialog appendTo="body" header="Attendees" [modal]="true" [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false" [closable]="true" [(visible)]="attendeesVisible">
                                <div class="dialog-ul" *ngFor="let attendes of meetingAttendees?.attendees.split(','); let i = index">
                                   <span *ngIf="i > 0">                                       
                                    <em [pTooltip]="attendes.length > 30 ? attendes : ''" tooltipPosition="top"> {{attendes.trim()}}</em>                    
                                   </span>
                                </div>
                                </p-dialog>
                                                  
                        
                      </ng-container>
                      <ng-template #noEngviewAttendees>
                        --
                      </ng-template>
                    </ng-container>
                    <span *ngIf="!occurrence?.engagementMeetingAttendees || occurrence?.engagementMeetingAttendees?.length == 0"> -- </span>
                  </div>
               </div>
               <div class="fr-flex__col-6">                   
                <div class="fr-form__view">
                 <label> Absentees</label>
                 <ng-container *ngFor="let meetingAbsentees of occurrence?.engagementMeetingAbsentees; let k = index">
                   <ng-container *ngIf="meetingAbsentees?.absentees else noEngviewAbsentees">
                         <ul class="services_ul meeting" *ngFor="let absenteefirst of meetingAbsentees?.absentees.split(','); let i = index">
                             <li [pTooltip]="absenteefirst.length > 15 ? absenteefirst : ''" tooltipPosition="top" *ngIf="i == 0">  {{absenteefirst ? absenteefirst : '--'}} </li>
                           </ul>
                           <span *ngIf="meetingAbsentees?.absentees.split(',').length >1"><em>  <p-button (onClick)="showAbsenteesDialog()" [hidden]="showAbsenteesButton" [ngClass]="'blue-button'" [label]="'+'+(meetingAbsentees?.absentees.split(',').length - 1).toString()"></p-button> </em></span>
                           <p-dialog appendTo="body" header="Absentees" [modal]="true" [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false" [closable]="true" [(visible)]="absenteesVisible">
                            <div class="dialog-ul" *ngFor="let absentee of meetingAbsentees?.absentees.split(','); let i = index">
                               <span *ngIf="i > 0">                                       
                                <em [pTooltip]="absentee.length > 30 ? absentee : ''" tooltipPosition="top"> {{absentee.trim()}}</em>                    
                               </span>
                            </div>
                            </p-dialog>
                                                
                     
                   </ng-container>
                   <ng-template #noEngviewAbsentees>
                    --
                  </ng-template>
                 </ng-container>
                 <span *ngIf="!occurrence?.engagementMeetingAbsentees || occurrence?.engagementMeetingAbsentees?.length == 0"> -- </span>                  
               </div>
            </div>
               </div>
           <div class="fr-flex__container">
               <div class="fr-flex__col-12">
                   <div class="fr-form__view"> <label> Meeting Notes </label> 
                    <span class="full-width" *ngIf="occurrence?.meetingNotes === null || occurrence?.meetingNotes==='' || occurrence?.meetingNotes.length===0 ">--</span> <span class="full-width" *ngIf="occurrence?.meetingNotes  !== null"> {{occurrence?.meetingNotes }} </span>
                </div>
               </div>
           </div>
           <div class="fr-flex__container">
               <div class="fr-flex__col-12">
                   <div class="fr-form__view"> <label> Decisions </label> 
                    <span class="full-width" *ngIf="occurrence?.decisions  == null || occurrence?.decisions == ''">--</span> <span class="full-width" *ngIf="occurrence?.decisions  != null"> {{occurrence?.decisions }} </span>
                </div>
               </div>
           </div>
           <div class="fr-flex__container">
               <div class="fr-flex__col-3">
                   <div class="fr-form__view"> <label> Added By</label> <span> <em> {{occurrence?.createdBy ? occurrence?.createdBy : '--'}} </em></span> </div>
               </div>
               <div class="fr-flex__col-3">
                   <div class="fr-form__view"> <label> Added Date & Time  </label> <span> <em>{{occurrence?.createdDate | date: _dateformart.DateTimeWithZone}} {{_dateformart.DisplayUTC}} </em></span> </div>
               </div>
               <div class="fr-flex__col-3">
                   <div class="fr-form__view"> <label> Modified By</label> 
                    <span  *ngIf="occurrence?.updatedBy !=null"> <em>  {{occurrence?.updatedBy}} </em></span>
                    <span *ngIf="occurrence?.updatedBy == null || occurrence?.updatedBy == undefined || occurrence?.updatedBy == ''"> <em> -- </em></span>
                    </div>
               </div>
               <div class="fr-flex__col-3">
                   <div class="fr-form__view"> <label> Modified Date & Time </label> <span *ngIf="occurrence?.updatedDate!=null"> <em> {{occurrence?.updatedDate | date: _dateformart.DateTimeWithZone}} {{_dateformart.DisplayUTC}}</em></span> <span *ngIf="occurrence?.updatedDate==null"> <em> -- </em></span> </div>
               </div>
           </div>
           <div class="fr-flex__container">
               <div class="fr-flex__col-12">
                       <h3>Actions</h3>
               </div>
           </div>
           <div class="fr-flex__col-12 m-b-50">
               <p-table id="engViewMeetingActionTableName" class="primeng-table-custom" styleClass="p-datatable-striped" [value]="occurrence?.engagementMeetingOccurrenceActions" [scrollable]="true">
                   <ng-template pTemplate="header">
                       <tr>
                           <th id="engViewMeetingAction">Action</th>
                           <th id="engViewMeetingOwner">Owner</th>
                           <th id="engViewMeetingTargetDate">Target Date</th>
                           <th id="engViewMeetingStatus">Status </th>
                           <th id="engViewMeetingClosureDate">Closure Date</th>
                           <th id="engViewMeetingRemarks">Remarks</th>
                           
                       </tr>
                   </ng-template>
                   <ng-template pTemplate="body" let-action>
                       
                       <tr>
                           <td [pTooltip]="action?.actionName.length > 15 ? action?.actionName : ''" tooltipPosition="top"><span *ngIf="!action?.actionName">--</span>
                            <span *ngIf="action?.actionName" > {{ action?.actionName }} </span>
                          </td>
                           <td [pTooltip]="action?.owner.length > 20 ? action?.owner : ''" tooltipPosition="top">  {{ action?.owner ? action?.owner : '--' }} </td>
                           <td>{{ action?.targetDate ? (action?.targetDate | date: _dateformart.GridDateFormat) : '--' }} </td>
                           <td>{{ action?.actionStatus ? action?.actionStatus : '--' }} </td>
                           <td>{{ action?.closureDate ? (action?.closureDate | date: _dateformart.GridDateFormat) : '--' }}</td>
                           <td [pTooltip]="action?.remarks.length > 15 ? action?.remarks : ''" tooltipPosition="top">
                            <span *ngIf="!action?.remarks">--</span>
                            <span *ngIf="action?.remarks" > {{ action?.remarks }} </span>
                          </td>
                       </tr>
                   </ng-template>
                   <ng-template pTemplate="emptymessage">
                    <tr>
                    <td colspan="6" class="text-center"> No Records</td>
                    </tr>
                    </ng-template>
               </p-table>
           </div>
       </div>
   </div>
   </div>
   </div>
</div> 
<!-- View Occurrence Popup Start -->
<div class="card flex justify-content-center">
   <p-dialog appendTo="body" header="View Occurrence" appendTo="body" [(visible)]="displayOccurrencePopup" [modal]="true" [style]="{ width: '80vw' }" [draggable]="false" [resizable]="false" [closable]="false">
       <ng-template pTemplate="content">
           <div>
               <div class="fr-flex__container">
                   <div class="fr-flex__col-4">
                       <div class="fr-form__view"> <label> Date <em> * </em></label> <span> <em> {{occurrenceDetail?.occurrenceDate | date: _dateformart.GridDateFormat}} </em></span> </div>
                   </div>
                   <div class="fr-flex__col-6">
                       <div class="fr-form__view"> <label> Attachments <i pInputText pTooltip="Maximum 3 Attachments allowed." tooltipPosition="top" class="pi pi-info-circle"> </i> </label>
                           <div class="display-inline-block" *ngFor="let attachment of occurrenceDetail?.engagementMeetingOccurrenceAttachments; let j = index"> <a class="form-list-active"> <span [pTooltip]="attachment.fileName.length > 35 ? attachment.fileName : ''" tooltipPosition="top" (click)="downloadAttachment(attachment)">{{attachment.fileName | truncate:25}} </span> <i (click)="downloadAttachment(attachment)" pInputText pTooltip="Download" tooltipPosition="top" class="pi pi-download"></i> </a>
                               
                               
                           </div> <span *ngIf="occurrenceDetail?.engagementMeetingOccurrenceAttachments  == null || occurrenceDetail?.engagementMeetingOccurrenceAttachments?.length==0"> -- </span>
                       </div>
                   </div>
               </div>
               <div class="fr-flex__container m-b-20">
                   <div class="fr-flex__col-4">                                       
                        <div class="fr-form__view">
                            <label> Attendees</label>
                            <ng-container *ngFor="let meetingAttendees of occurrenceDetail?.engagementMeetingAttendees; let k = index">
                              <ng-container *ngIf="meetingAttendees?.attendees">
                                    <ul class="services_ul Occurrence" *ngFor="let attendefirst of meetingAttendees?.attendees.split(','); let i = index">
                                        <li [pTooltip]="attendefirst.length > 15 ? attendefirst : ''" tooltipPosition="top" *ngIf="i == 0">  {{attendefirst ? attendefirst: '--' }} </li>
                                      </ul>
                                      <span *ngIf="meetingAttendees?.attendees.split(',').length > 1"><em>  <p-button (onClick)="showAttendeesDialog()" [hidden]="showAttendeesButton" [ngClass]="'blue-button'" [label]="'+'+(meetingAttendees?.attendees.split(',').length - 1).toString()"></p-button> </em></span>
                                      <p-overlay contentStyleClass="p-overlaypanel overlay-custom-services-scroll" header="Services" [(visible)]="attendeesVisible" [responsive]="{ direction: 'bottom', }">   
                                        <ul class="overlay_ul" *ngFor="let attendes of meetingAttendees?.attendees.split(','); let i = index">
                                          <li *ngIf="i > 0" >                                       
                                            <em [pTooltip]="attendes.length > 30 ? attendes : ''" tooltipPosition="top"> {{attendes.trim()}}</em>                    
                                          </li>
                                        </ul>
                                  </p-overlay>                     
                                
                              </ng-container>
                            </ng-container>
                            <span *ngIf="!occurrenceDetail?.engagementMeetingAttendees || occurrenceDetail?.engagementMeetingAttendees?.length == 0"> -- </span>
                          </div>                   

                   </div>
                   <div class="fr-flex__col-4">                     
                    <div class="fr-form__view">
                        <label> Absentees</label>
                        <ng-container *ngFor="let meetingAbsentees of occurrenceDetail?.engagementMeetingAbsentees; let k = index">
                          <ng-container *ngIf="meetingAbsentees?.absentees">
                                <ul class="services_ul Occurrence" *ngFor="let absenteefirst of meetingAbsentees?.absentees.split(','); let i = index">
                                    <li [pTooltip]="absenteefirst.length > 15 ? absenteefirst : ''" tooltipPosition="top" *ngIf="i == 0">  {{absenteefirst ? absenteefirst: '--' }} </li>
                                  </ul>
                                  <span *ngIf="meetingAbsentees?.absentees.split(',').length >1"><em>  <p-button (onClick)="showAbsenteesDialog()" [hidden]="showAbsenteesButton" [ngClass]="'blue-button'" [label]="'+'+(meetingAbsentees?.absentees.split(',').length - 1).toString()"></p-button> </em></span>
                                  <p-overlay contentStyleClass="p-overlaypanel overlay-custom-services-scroll" header="Services" [(visible)]="absenteesVisible" [responsive]="{ direction: 'bottom', }">   
                                    <ul class="overlay_ul" *ngFor="let absentee of meetingAbsentees?.absentees.split(','); let i = index">
                                      <li *ngIf="i > 0">                                       
                                        <em [pTooltip]="absentee.length > 30 ? absentee : ''" tooltipPosition="top"> {{absentee.trim()}}</em>                    
                                      </li>
                                    </ul>
                              </p-overlay>                     
                          </ng-container>
                        </ng-container>
                        <span *ngIf="!occurrenceDetail?.engagementMeetingAbsentees || occurrenceDetail?.engagementMeetingAbsentees?.length == 0"> -- </span>                  
                      </div>       
               </div>
                   </div>
                   
               <div class="fr-flex__container">
                   <div class="fr-flex__col-12">
                       <div class="fr-form__view"> 
                        <label> Meeting Notes</label> 
                        <span class="full-width" *ngIf="meetingNotes  == null">--</span> <span class="full-width" [innerHTML]="meetingNotes" *ngIf="meetingNotes  != null"> {{meetingNotes }} </span>
                   </div>
               </div>
               </div>
               <div class="fr-flex__container">
                   <div class="fr-flex__col-12">
                       <div class="fr-form__view"> 
                        <label> Decisions </label> 
                        <span class="full-width" *ngIf="decision  == null">--</span> <span class="full-width" [innerHTML]="decision" *ngIf="decision  != null"> {{decision }} </span>
                    </div>
                   </div>
               </div>
               <div class="fr-flex__container">
                   <div class="fr-flex__col-3">
                       <div class="fr-form__view"> <label> Added By</label> <span> <em> {{occurrenceDetail?.createdBy}} </em></span> </div>
                   </div>
                   <div class="fr-flex__col-3">
                       <div class="fr-form__view"> <label> Added Date & Time </label> <span> <em> {{occurrenceDetail?.createdDate | date: _dateformart.DateTimeWithZone}} {{_dateformart.DisplayUTC}}</em></span> </div>
                   </div>
                   <div class="fr-flex__col-3">
                       <div class="fr-form__view"> <label> Modified By</label> <span *ngIf="occurrenceDetail?.updatedBy !=null"> <em> {{occurrenceDetail?.updatedBy}} </em></span> <span *ngIf="occurrenceDetail?.updatedBy ==null || occurrenceDetail?.updatedBy == ''"> <em> --</em></span> </div>
                   </div>
                   <div class="fr-flex__col-3">
                       <div class="fr-form__view"> <label> Modified Date & Time </label> <span *ngIf="occurrenceDetail?.updatedDate !=null"> <em> {{occurrenceDetail?.updatedDate | date: _dateformart.DateTimeWithZone}} {{_dateformart.DisplayUTC}}</em></span> <span *ngIf="occurrenceDetail?.updatedDate ==null"> <em> --</em></span> </div>
                   </div>
               </div>
           </div>
           <div class="fr-flex__container page-title__contract">
               <div class="fr-flex__col-12">
                   <div class="fr-form__view">
                       <h3>Actions </h3>
                   </div>
               </div>
           </div>
           <div class="fr-flex__col-12">
               <p-table class="primeng-table-custom" styleClass="p-datatable-striped" [value]="occurrenceDetail?.engagementMeetingOccurrenceActions">
                <ng-template pTemplate="header">
                       <tr>
                           <th id="engViewMeetingPopupAction">Action</th> 
                           <th id="engViewMeetingPopupOwner">Owner</th>
                           <th id="engViewMeetingPopupTargetDate">Target Date</th>
                           <th id="engViewMeetingPopupStatus">Status </th>
                           <th id="engViewMeetingPopupClosureDate">Closure Date</th>
                           <th id="engViewMeetingPopupClosureRemarks">Remarks</th> 
                       </tr>
                   </ng-template>
                   <ng-template pTemplate="body" let-occurrenceAction>
                       <tr>
                          <td [pTooltip]="occurrenceAction?.actionName?.length > 15 ? occurrenceAction?.actionName : ''" tooltipPosition="top">
                            <span *ngIf="!occurrenceAction?.actionName">--</span>
                            <span *ngIf="occurrenceAction?.actionName" > {{ occurrenceAction?.actionName }}  </span>
                          </td>
                          <td [pTooltip]="occurrenceAction?.owner?.length > 15 ? occurrenceAction?.owner : ''" tooltipPosition="top"> {{ occurrenceAction?.owner ? occurrenceAction?.owner : '--' }} </td>
                           <td> {{ occurrenceAction?.targetDate?(occurrenceAction.targetDate|date: _dateformart.GridDateFormat): '--' }} </td>
                           <td> {{ occurrenceAction?.actionStatus }}</td>
                           <td> {{ occurrenceAction?.closureDate?(occurrenceAction.closureDate | date: _dateformart.GridDateFormat): '--' }} </td>
                          
                           <td [pTooltip]="occurrenceAction?.remarks?.length > 15 ? occurrenceAction?.remarks : ''" tooltipPosition="top">
                            <span *ngIf="!occurrenceAction?.remarks">--</span>
                            <span *ngIf="occurrenceAction?.remarks"> {{ occurrenceAction?.remarks }} </span>
                          </td>
                       </tr>
                   </ng-template>
                   <ng-template pTemplate="emptymessage">
                    <tr>
                    <td colspan="6" class="text-center"> No Records</td>
                    </tr>
                    </ng-template>
               </p-table>
           </div>
       </ng-template> <!-- Dialog Footer Start -->
       <ng-template pTemplate="footer"> <button type="button" class="btn btn-secondary pull-right" (click)="cancelDialog()"> Close </button> </ng-template> <!-- Dialog Footer End -->
   </p-dialog>
</div> <!-- View Occurrence Popup End -->
</body>
</html>
