import { Component } from '@angular/core';

@Component({
  selector: 'app-user-admin',
  templateUrl: './user-admin.component.html',
  styleUrls: ['./user-admin.component.css']
})
export class UserAdminComponent {
  ngOnInit(): void {
    // Add event listener to receive messages from the iframe
    window.addEventListener('message', this.handleMessageFromIframe);
  }
  ngOnDestroy(): void {
    // Remove event listener when the component is destroyed
    window.removeEventListener('message', this.handleMessageFromIframe);
  }
  handleIFrameLoad(event: Event): void {
    const iframe = event.target as HTMLIFrameElement;
    let targetOrigin = 'https://google.com';
    // Access the iframe's contentWindow to send messages
    iframe.contentWindow?.postMessage('Hello from Angular!',targetOrigin);
  }

  handleMessageFromIframe(event: MessageEvent): void {
    // Handle messages received from the iframe
    console.log('Message from iframe:', event.data);
  }
}
