<!DOCTYPE html>
<html lang="en">
<head>
    <title> View Sponsor</title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>
<div class="card flex justify-content-center">
   <p-breadcrumb class="max-w-full" [model]="getBreadcrumbs()"></p-breadcrumb>
</div>
<div class="container page-title">
    <div class="flex-box-inside-pages"> 
   <div class="fr-flex__container">
       <div class="fr-flex__col-8">
           <h1>View Sponsor_{{SponserId}} </h1>
       </div>
       
   </div>
</div>
   <p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
    <p-tabView class="fr-tabview__header__custom">
        <p-tabPanel>
            <div class="card-pad"> 
            <div class="fr-flex__col-4"> 
                <select class="form-control selectpicker pull-right" [(ngModel)]="selectedOption" (change)="onOptionChange()">
                <option disabled selected> Actions </option>
                <option *ngIf="editPrivilege"> Edit </option>
            </select> 
            <a class="btn btn-primary-link backtolist pull-right" (click)="BackToList()">  <i class="pi pi-arrow-left"></i> Back to list </a>
        </div>
       
   <div class="fr-flex__container">
       <div class="fr-flex__col-4">
           <div class="fr-form__view"> <label> Sponsor Name <em> * </em></label> <span [pTooltip]="SponserName!.length > 30 ? SponserName!: ''"
            tooltipPosition="top"> <em> {{SponserName}} </em></span> </div>
       </div>
       <div class="fr-flex__col-4">
        <div class="fr-form__view"> <label> Sponsor ID <em> * </em></label> <span> <em> {{SponserId}} </em></span> </div>
    </div>

   </div>
   <div class="fr-flex__container">
       <div class="fr-flex__col-12">
           <div class="fr-form__view"> <label> Address </label>  
            <span class="full-width" *ngIf="Address  == null || Address == '' "> -- </span> <span class="full-width" [innerHTML]="Address" *ngIf="Address  != null"> {{Address }} </span>
         </div>
       </div>
   </div>
   <div class="fr-flex__container">
    <div class="fr-flex__col-12">
        <div class="fr-form__view"> <label> Notes and Comments </label> 
            <span class="full-width" *ngIf="NotesandComments  == null || NotesandComments == '' ">--</span> <span class="full-width" [innerHTML]="NotesandComments" *ngIf="NotesandComments  != null"> {{NotesandComments }} </span>
        </div>
    </div>
</div>
   <div class="fr-flex__container">
       <div class="fr-flex__col-3">
           <div class="fr-form__view"> <label> Created By </label> <span> <em> {{createdBy? createdBy :"--"}} </em> </span> </div>
       </div>
       <div class="fr-flex__col-3">
        <div class="fr-form__view"> <label> Created Date & Time</label> <span> <em> {{createdDateAndTime | date: _dateformart.DateTimeWithZone}} {{_dateformart.DisplayUTC}} </em> </span> </div>
       </div>
       <div class="fr-flex__col-3">
           <div class="fr-form__view"> <label> Modified By</label> <span> <em> {{UpdatedBy? UpdatedBy:"--"}} </em> </span> </div>
       </div>
       <div class="fr-flex__col-3">
           <div class="fr-form__view"> <label> Modified Date & Time</label> 
            <span  *ngIf="UpdatedBy !=null && UpdatedBy != undefined && UpdatedBy != ''"> <em>  {{ UpdatedByAndTime |date: _dateformart.DateTimeWithZone}} {{_dateformart.DisplayUTC}}</em></span>
            <span *ngIf="UpdatedBy ==null || UpdatedBy == undefined || UpdatedBy == ''"> <em> -- </em></span>
    </div>
       </div>
   </div>
   <div class="fr-flex__container">
       <div class="fr-flex__col-8">
           <div class="sub-page-title">
               <h3>Contacts</h3>
           </div>
       </div>
   </div>


<!-- Grid Code Start -->
<div class="fr-flex__col-12">
    <p-table class="primeng-table-custom masterdata_contacts_th5" [resizableColumns]="true" columnResizeMode="expand" styleClass="p-datatable-striped p-datatable-gridlines" [value]="sponserContactList" [scrollable]="true">
        <ng-template pTemplate="header">
            <tr>
                
                <th id="masterDataViewSponsorName" pSortableColumn="name" pResizableColumn>Name <p-sortIcon field="name"></p-sortIcon></th>
                <th id="masterDataViewSponsorDesignation" pSortableColumn="designation" pResizableColumn>Designation <p-sortIcon field="designation"></p-sortIcon></th>
                <th id="masterDataViewSponsorEmail" pSortableColumn="email" pResizableColumn>Email <p-sortIcon field="email"></p-sortIcon></th>
                <th id="masterDataViewSponsorCountryCode" pSortableColumn="countryCode" pResizableColumn>Country Code <p-sortIcon field="countryCode"></p-sortIcon></th>
                <th id="masterDataViewSponsorPhoneNumber" pSortableColumn="phoneNumber" pResizableColumn>Phone Number <p-sortIcon field="phoneNumber"></p-sortIcon></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-contact let-rowIndex="rowIndex">
            <tr>
                <td> {{ contact.name }} </td>
                <td [pTooltip]="contact.designation.length > 20 ? contact.designation : ''" tooltipPosition="top"> {{ contact.designation ? contact.designation : '--'  }} </td>
                <td [pTooltip]="contact.email.length > 20 ? contact.email : ''" tooltipPosition="top"> {{ contact.email }} </td>
                <td [pTooltip]="contact.countryCode.length > 10 ? contact.countryCode : ''" tooltipPosition="top"> {{ contact.countryCode ? contact.countryCode : '--'  }} </td>
                <td> {{ contact.phoneNumber ? contact.phoneNumber : '--' }} </td>
            </tr>
            
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
            <td colspan="5" class="text-center"> No Records</td>
            </tr>
            </ng-template>
    </p-table>
    
 </div>
</div>
 <!-- Grid Code End -->
</p-tabPanel>
</p-tabView>
</div>
</body>
</html>
