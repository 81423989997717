import { DatePipe } from '@angular/common';
import { Component,EventEmitter,Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import FileSaver from 'file-saver';
import { LoaderService } from 'src/app/Loader/loader.service';
import { Dateformat, breadcrumnsCodes, FileExtension } from 'src/app/shared/constants/global.constants';
import { GovernanceService } from '../../Services/governance.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { Privileges } from 'src/app/shared/constants/privileges.constants';
import * as xlsx from 'xlsx';
import { EngagementService } from 'src/app/Engagement/Services/engagement.service';
import { ExcelService } from 'src/app/Reusable/excel.service';
@Component({
  selector: 'app-eng-meetings-list',
  templateUrl: './eng-meetings-list.component.html',
  styleUrls: ['./eng-meetings-list.component.css']
})

export class EngMeetingsListComponent {


  @Input() engagementId: any;
  @Input() Id: any;
  @Output() addMeetingButtonClick = new EventEmitter<void>();
  @Output() editMeetingButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() viewMeetingButtonClick: EventEmitter<any> = new EventEmitter<any>();
  selectedEngagementMeeting:any;
 
  _dateformart :any;
  _enumBreadcrumb :any;
  _engagementMeetingListData: any[] = []
  createPrivilege:boolean=false;
  editPrivilege:boolean=false;
  viewPrivilege:boolean=false;
  ExportPrivilage:boolean=false;
  totalRecordCount: number = 0;
  filteredRecordCount: number = -1;
 
  constructor(private privilegeService: PrivilegeService,private governanceService: GovernanceService,
    private router:Router,private datePipe: DatePipe,  private engagementService: EngagementService,
    private loaderService: LoaderService,private excelService: ExcelService)
  {

  }
 
  ngOnInit() {
    this._enumBreadcrumb=breadcrumnsCodes;
    this._dateformart=Dateformat;
    this.getEngagementMeetingsByEngagementId();
    this.loaderService.hidefinalLoader();// hide after 4 seconds even get any error
    this.BindPrivileges();
    this.GetEngagementDetailsById();
  }
  
  BindPrivileges(){
    const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);
this.createPrivilege = hasPermission(Privileges.AddSupplierMeeting);
this.editPrivilege =hasPermission(Privileges.EditSupplierMeeting);
this.viewPrivilege = hasPermission(Privileges.ViewSupplierMeeting);
this.ExportPrivilage= hasPermission(Privileges.ExportEngagementMeetings);

    }
addEngagementMeeting()
{
  this.addMeetingButtonClick.emit();
}
editEngagementMeeting(Id:any,recurring: boolean){
  var engagementId= this.engagementId;
  var meetingId=Id.toString();
  if (recurring === false){
  const data = {
    isRecurring:false,
    engagementId:this.engagementId,
    meetingId:Id
  };
  this.editMeetingButtonClick.emit(data);
 }
 else{
  
  const data = {
    isRecurring:true,
    engagementId:this.engagementId,
    meetingId:Id
  };
  this.editMeetingButtonClick.emit(data);
 }
}

getEngagementMeetingsByEngagementId(){
  this.loaderService.showLoader();
  this.governanceService.getEngaementMeetingsByEngaementId(this.engagementId).subscribe(
    response=>{
     this._engagementMeetingListData=response.data;
     this.totalRecordCount= this._engagementMeetingListData.length;
      this.loaderService.hideLoader();
    }
  );
}

viewEngagementMeeting(engMeeting:any)
{
 
 var engagementId= this.engagementId;
 var auditId=engMeeting.toString();
 const data = {
   engagementId:this.engagementId,
    meetingId:engMeeting.id
 };
 this.viewMeetingButtonClick.emit(data);
}

isChecked: boolean = true;
CheckboxEnabled() {
  if (this.selectedEngagementMeeting.length > 0) {
    this.isChecked = false;
  }
  else {
    this.isChecked = true;
  }
}



selectedRecordsToExpotToExcel: any[] = [];

async ExportEngagementMeetings() {
  const nonRecurringMeetings = [];
  const recurringMeetings: any[] = [];
  const openActionMeetings: any[] = [];

  for (const meeting of this.selectedEngagementMeeting) {
    const meetingDetails = await this.getEngagementMeetingsById(meeting.id);

    // Non-Recurring Meetings
    if (meetingDetails.recurring === 'No') {
      nonRecurringMeetings.push({
        'Meeting Title': meetingDetails.title,
        Date: meetingDetails.occurrenceDate != "" ? this.convertDateFormat(meetingDetails.occurrenceDate) : meetingDetails.occurrenceDate,
        Organizer: meetingDetails.organizer,
        'Agenda': meetingDetails.agenda,
        'Open Action Count': meeting.openActionsCount,
        Attendees: meetingDetails.attendees.join(', '),
        Absentees: meetingDetails.absentees.join(', '),
        'Meeting Notes': meetingDetails.meetingNotes,
        Decisions: meetingDetails.decisions,
        Attachment: meetingDetails.attachments,
        'Added By': this.privilegeService.getUserName(meetingDetails.createdBy),
        'Added Date & Time': meetingDetails.createdDate != null ? this.excelService.convertDateTimeFormat(meetingDetails.createdDate) : '',
        'Modified By': this.privilegeService.getUserName(meetingDetails.updatedBy),
        'Modified Date & Time' : meetingDetails.updatedDate != null ? this.excelService.convertDateTimeFormat(meetingDetails.updatedDate) : '',
      });
    }

    // Recurring Meetings
    if (meetingDetails.recurring === 'Yes') {
      this.completeMeetingData.engagementMeetingOccurrences.forEach( (item: {
        engagementMeetingOccurrenceAttachments: any;
        engagementMeetingAttendees: any;engagementMeetingAbsentees: any; occurrenceDate: string; openActionsCount:any;attendees:any;absentees:any;meetingNotes:any;decisions:any;attachments:any;createdBy:any;createdDate:any;updatedBy:any;updatedDate:any
        }) => {
        recurringMeetings.push({
          'Meeting Title': meetingDetails.title,
          'Occurrence Date':item.occurrenceDate != "" ? this.convertDateFormat(item.occurrenceDate) : item.occurrenceDate,
          Organizer: meetingDetails.organizer,
          'Agenda': meetingDetails.agenda,
          'Open Action Count': item.openActionsCount,
          Attendees: item.engagementMeetingAttendees[0]?.attendees != null ?  item.engagementMeetingAttendees[0]?.attendees:'',
          Absentees: item.engagementMeetingAbsentees[0]?.absentees != null ? item.engagementMeetingAbsentees[0]?.absentees:'',
          'Meeting Notes': item.meetingNotes,
          Decisions: item.decisions,
         'Attachments': item.engagementMeetingOccurrenceAttachments != null && item.engagementMeetingOccurrenceAttachments.length > 0
          ? item.engagementMeetingOccurrenceAttachments.map((att: { fileName: any; }) => att.fileName).join(', ')
          : '',
          'Added By': this.privilegeService.getUserName(item.createdBy),
          'Added Date & Time': item.createdDate != null ? this.excelService.convertDateTimeFormat(item.createdDate) : '',
          'Modified By': this.privilegeService.getUserName(item.updatedBy),
          'Modified Date & Time' : item.updatedDate != null ? this.excelService.convertDateTimeFormat(item.updatedDate) : '',
        })
       
      }
    )
    }
    

    // Meetings with Actions
    for (const action of meetingDetails.actions) {
      openActionMeetings.push({
        'Meeting Title': meetingDetails.title,
        'Meeting Date': action.MeetingDate != "" ? this.convertDateFormat(action.MeetingDate) : action.MeetingDate,
        Organizer: meetingDetails.organizer,
        'Agenda': meetingDetails.agenda,
        Action: action.actionName,
        'Action Owner': action.owner,
        'Target Date': action.targetDate != null ? this.convertDateFormat(action.targetDate) : action.targetDate,
        'Action Status': action.actionStatus,
        'Closure Date': action.closureDate != null ? this.convertDateFormat(action.closureDate) : action.closureDate,
        'Remarks': action.remarks,
      });
    }
  }

  const workbook = xlsx.utils.book_new();

  if (nonRecurringMeetings.length > 0) {
    const worksheetNonRecurring = xlsx.utils.json_to_sheet(nonRecurringMeetings);
    xlsx.utils.book_append_sheet(workbook, worksheetNonRecurring, 'Non Recurring Meetings');
  }

  if (recurringMeetings.length > 0) {
    const worksheetRecurring = xlsx.utils.json_to_sheet(recurringMeetings);
    xlsx.utils.book_append_sheet(workbook, worksheetRecurring, 'Recurring Meetings');
  }

  if (openActionMeetings.length > 0) {
    const worksheetOpenActions = xlsx.utils.json_to_sheet(openActionMeetings);
    xlsx.utils.book_append_sheet(workbook, worksheetOpenActions, 'Actions');
  }

  const fileName = `${this.engagementName} - MeetingsExport - ${this.convertDateFormat(Date())}.xlsx`;
  xlsx.writeFile(workbook, fileName);
}

convertDateFormat(inputDateTime: string) {
  const date = new Date(inputDateTime);
  const formattedDate = this.datePipe.transform(date, this._dateformart.GridDateFormat);
  return formattedDate;
}
completeMeetingData:any;
async getEngagementMeetingsById(engagementMeetingId: any) {
  const response: any = await this.governanceService.getEngagementMeetingsById(engagementMeetingId).toPromise();
  const meetingDetails = response.data;
  this.completeMeetingData=response.data;
  const extractedDetails: any = {
    title: meetingDetails.title,
    organizer: meetingDetails.organizer,
    recurring: meetingDetails.recurring ? 'Yes' : 'No',
    agenda: meetingDetails.agenda,
    createdBy: meetingDetails.createdBy,
    createdDate: meetingDetails.createdDate,
    updatedBy: meetingDetails.updatedBy,
    updatedDate: meetingDetails.updatedDate,
    actions: [], // Store all actions
    attendees: [],
    absentees: [],
    meetingNotes: '',
    decisions: '',
    attachments: '',
    occurrenceDate: '', 
  };

  if (meetingDetails.engagementMeetingOccurrences.length>0) {
    for (const occurrence of meetingDetails.engagementMeetingOccurrences) {
      if (meetingDetails.recurring) {
        extractedDetails.occurrenceDate = occurrence.occurrenceDate || ''; 
      } else {
        if (!extractedDetails.occurrenceDate) {
          extractedDetails.occurrenceDate = occurrence.occurrenceDate || '';
        }
      }

      const occurrenceAttendees = occurrence.engagementMeetingAttendees || [];
      const occurrenceAbsentees = occurrence.engagementMeetingAbsentees || [];

      extractedDetails.attendees.push(...occurrenceAttendees.map((att: { attendees: any; }) => att.attendees).flat());
      extractedDetails.absentees.push(...occurrenceAbsentees.map((abs: { absentees: any; }) => abs.absentees).flat());

      extractedDetails.meetingNotes += occurrence.meetingNotes ? occurrence.meetingNotes  : '';
      extractedDetails.decisions += occurrence.decisions ? occurrence.decisions  : '';
      extractedDetails.attachments += occurrence.engagementMeetingOccurrenceAttachments
        ? occurrence.engagementMeetingOccurrenceAttachments.map((att: { fileName: any; }) => att.fileName).join(', ') : '';

      occurrence.engagementMeetingOccurrenceActions.forEach((action: { actionName: any; owner: any; targetDate: any; actionStatus: any; closureDate: any; remarks: any; }) => {
        extractedDetails.actions.push({
          MeetingDate:occurrence.occurrenceDate,
          actionName: action.actionName,
          owner: action.owner,
          targetDate: action.targetDate,
          actionStatus: action.actionStatus,
          closureDate: action.closureDate,
          remarks: action.remarks,
        });
      });
    }
  }
  return extractedDetails;
}




engagementName:any;
GetEngagementDetailsById() {
  this.loaderService.showLoader();
  this.engagementService.GetEngagementById(this.engagementId).subscribe(
    res => {
        this.loaderService.hideLoader();
        this.engagementName=res.data.engagementName;
      })
    }
}
