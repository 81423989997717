import { Component } from '@angular/core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChecknulloremptyComponent {
  isNullOrUndefinedOrEmpty(value:any) {
    return value === null || value === undefined || value === "";
  }
}
