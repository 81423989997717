<!DOCTYPE html>
<html lang="en">
<head>
    <title> Contract View KPI</title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>
<div class="card-pad">
   <div class="fr-flex__container sticky-sub-header">
       <div class="fr-flex__col-4">
           <h3>View KPI </h3>
       </div> 
       <div class="fr-flex__col-8">
           <select class="form-control selectpicker pull-right"  [(ngModel)]="selectedOption" (change)="onOptionChange()">
               <option disabled selected > Actions </option>
               <option *ngIf="editPrivilege"> Edit </option>
               <option *ngIf="deletePrivilege"> Delete </option>
           </select>
            <div class="preview_section pull-right" *ngIf="currentIndex!==-1">
                <button  [class.previous]="currentIndex !== 0" (click)="previousRecord()" [disabled]="currentIndex === 0"><i class="pi pi-arrow-up" pTooltip="Preview" tooltipPosition="top"></i></button>
                <span class="numbers_list">{{getCurrentPositionText()}}</span>
                <button [class.next]="currentIndex !== listOfKpis.length - 1" (click)="nextRecord()" [disabled]="currentIndex === listOfKpis.length - 1"><i class="pi pi-arrow-down" pTooltip="Next" tooltipPosition="top"></i></button>
                </div> 
           <a class="btn btn-primary-link backtolist pull-right" (click)="BackToList()">  <i class="pi pi-arrow-left"></i> Back to list </a>
           
       </div>
   </div>
   <div class="scroll-start-here-sub-tabs"> 
                       <div class="fr-flex__container">
                           <div class="fr-flex__col-4">
                               <div class="fr-form__view">
                                   <label> KPI <em> * </em></label>
                                  <span> <em> {{_kpiDetails.kpiName}}</em></span> 
                               </div>
                           </div>  

                          <div class="fr-flex__col-4">
                            <div class="fr-form__view">
                                <label> Service <em> * </em></label>
                               <span [pTooltip]="_viewContractKPIDetails.serviceName!.length > 30 ? _viewContractKPIDetails.serviceName! : ''"
                                tooltipPosition="top"> <em> {{_viewContractKPIDetails.serviceName}}</em></span> 
                            </div>
                        </div>  


                        <div class="fr-flex__col-4">
                            <div class="fr-form__view">
                                <label> KPI Name<em> * </em></label>
                               <span [pTooltip]="_viewContractKPIDetails.kpiName!.length > 30 ? _viewContractKPIDetails.kpiName! : ''"
                                tooltipPosition="top"> <em> {{_viewContractKPIDetails.kpiName}}</em></span> 
                            </div>
                        </div> 
                       </div>
                       </div>
                       <div class="fr-flex__col-12">
                        <div class="m-b-20">
                            <span class="table-small-card"> Current Health </span>
                            <span id="conViewKPIHealthInformation" class="table-small-card"> {{_kpiDetails.unitOfMeasureCode=='%'?_viewContractKPIDetails?.overAllAverageKPI == null?"--": _viewContractKPIDetails?.overAllAverageKPI+_kpiDetails.unitOfMeasureCode:_viewContractKPIDetails?.overAllAverageKPI == null?"--": _viewContractKPIDetails?.overAllAverageKPI+ ' ' +_kpiDetails.unitOfMeasureCode}} 
                                <span [ngClass]="{
                                    'success__circle': _viewContractKPIDetails?.overallAverageHealth === 'Green',
                                    'danger__circle': _viewContractKPIDetails?.overallAverageHealth === 'Red'
                                  }"></span>
                        </span> 
                         </div>
                    <div class="m-b-30"> 
                     <p-table id="viewContractKPItableName" class="primeng-table-custom custom-cells__contractattributeview attribute_max_heigh_scroll"  [resizableColumns]="true" columnResizeMode="expand" styleClass="p-datatable-striped p-datatable-gridlines" [scrollable]="true" [value]="kpiReportDataCollection" [tableStyle]="{ 'min-width': '50rem' }">
                         <ng-template pTemplate="header"> 
                             <tr>
                                 <th id="conViewkpiDataFor" pResizableColumn>Data For</th>
                                 <th id="conViewkpiAttributeOne" pResizableColumn>Attribute 1  <br> <span [pTooltip]="_viewContractKPIDetails?.attribute1?.length > 20 ? _viewContractKPIDetails?.attribute1 : ''" tooltipPosition="top">  ({{_viewContractKPIDetails.attribute1 == ""?"--":_viewContractKPIDetails.attribute1}}) </span> </th>
                                 <th id="conViewkpiAttributeTwo" pResizableColumn>Attribute 2  <br> <span [pTooltip]="_viewContractKPIDetails?.attribute2?.length > 20 ? _viewContractKPIDetails?.attribute2 : ''" tooltipPosition="top"> ({{_viewContractKPIDetails.attribute2 == ""?"--":_viewContractKPIDetails.attribute2}})</span> </th>
                                 <th id="conViewkpiAttributeThree" pResizableColumn>Attribute 3 <br> <span [pTooltip]="_viewContractKPIDetails?.attribute3?.length > 20 ? _viewContractKPIDetails?.attribute3 : ''" tooltipPosition="top"> ({{_viewContractKPIDetails.attribute3 == ""?"--":_viewContractKPIDetails.attribute3}}) </span> </th>
                                 <th id="conViewkpiCalculatedKPI" pResizableColumn>Calculated KPI </th>
                                 <th id="conViewkpiActualDataFillDate" pResizableColumn>Actual Data Fill Date </th>
                                 <th id="conViewkpiActualDataFillBy" pResizableColumn> Data Filled By </th>
                                 <th id="conViewkpiRemarks" pResizableColumn>Remarks </th>
                                 <th id="conViewKpiMitigationActions" pResizableColumn>Mitigation Actions </th>
                                 <th id="conViewkpiHealth" class="contact-last-child-width" pInputText>Health</th>
                             </tr>
                         </ng-template>
                         <ng-template pTemplate="body" let-kpiReportDataCollection let-i="index">
                             <tr class="bg" *ngIf="_viewContractKPIDetails.kpiDataCollectionFrequencyName !=_viewContractKPIDetails.kpiReportingFrequencyName">
                                 <td class="text-center" colspan="4">{{kpiReportDataCollection.name}}</td>
                                 <td class="weight500" colspan="5">{{_kpiDetails.unitOfMeasureCode=='%'?kpiReportDataCollection.avearageCalculatedKPIForReport ==null? "--":kpiReportDataCollection.avearageCalculatedKPIForReport+""+_kpiDetails.unitOfMeasureCode:kpiReportDataCollection.avearageCalculatedKPIForReport ==null? "--":kpiReportDataCollection.avearageCalculatedKPIForReport+" "+_kpiDetails.unitOfMeasureCode}}</td>
                                 <td class="circle_center">
                                     <span class="success__circle" *ngIf="kpiReportDataCollection?.healthForReport === 'Green'"></span>
                                     <span class="danger__circle" *ngIf="kpiReportDataCollection?.healthForReport === 'Red'"></span>
                                   </td>    
                             </tr>
                         
                         <tr *ngFor="let dataCollectionItem of kpiReportDataCollection.kpiContractDataCollectionModelList ; let i = index">
                            
                                 
                                 <td>{{ dataCollectionItem.dataCollectionFrequencyValue}}</td>
                                 <td class="grid-text__center">{{ (dataCollectionItem.attribute1 ==null|| dataCollectionItem.attribute1 =="")? "--": getFormattedValue(dataCollectionItem.attribute1,currencies)}}</td>
                                 <td class="grid-text__center">{{ (dataCollectionItem.attribute2 ==null|| dataCollectionItem.attribute2 =="")? "--": getFormattedValue(dataCollectionItem.attribute2,currencies)}}</td>
                                 <td class="grid-text__center">{{ (dataCollectionItem.attribute3 ==null|| dataCollectionItem.attribute3 =="")? "--":getFormattedValue(dataCollectionItem.attribute3,currencies)}}</td>
                                 <td class="">{{ _kpiDetails.unitOfMeasureCode=='%'?dataCollectionItem.calculatedKPI ==null? "--": dataCollectionItem.calculatedKPI+""+_kpiDetails.unitOfMeasureCode:dataCollectionItem.calculatedKPI ==null? "--": dataCollectionItem.calculatedKPI+" "+_kpiDetails.unitOfMeasureCode}}</td>
                                 <td class="grid-text__center">{{ dataCollectionItem.remarks ==null? "--" : dataCollectionItem.updatedDate | date: _dateformart.GridDateFormat }}</td>
                                 <td class="grid-text__center">{{dataCollectionItem.remarks ==null? "--" : privilegeService.getUserName(dataCollectionItem.createdBy)}}</td>
                                 <td class="grid-text__center">
                                    <span pInputText pTooltip="{{ dataCollectionItem.remarks == '' ? '--' : dataCollectionItem.remarks }}" tooltipPosition="top" class="pi pi-info-circle"></span>
                                </td> 
                                <td class="text-center">
                                    <a class="table-active-link" (click)="viewFindingList(i,kpiReportDataCollection)">
                                      {{ getMitigationActionCount(dataCollectionItem.id) > 0 ? getMitigationActionCount(dataCollectionItem.id) : '0' }}
                                    </a>
                                  </td>
                                                                
                                   <td class="grid-icon-center">
                                    <ng-container *ngIf="dataCollectionItem?.health; else noHealth">
                                      <span class="success__circle" *ngIf="dataCollectionItem?.health === 'Green'"></span>
                                      <span class="danger__circle" *ngIf="dataCollectionItem?.health === 'Red'"></span>
                                    </ng-container>
                                    <ng-template #noHealth>--</ng-template>
                                  </td>
                                                
                         </tr>

                     </ng-template>
                     <ng-template pTemplate="emptymessage">
                        <tr>
                        <td colspan="9" class="text-center"> No Records</td>
                        </tr>
                        </ng-template>
                     </p-table>
                </div>


                       </div>
                       <div class="fr-flex__container">
                        <div class="fr-flex__col-4">
                            <div class="fr-form__view">
                                <label> Data Collection Frequency <em> * </em></label>
                                <span> <em> {{_viewContractKPIDetails.kpiDataCollectionFrequencyName}}  </em></span> 
                            </div>
                        </div>                          
                           <div class="fr-flex__col-4">
                               <div class="fr-form__view">
                                   <label> KPI Reporting Frequency <em> * </em> <i  pInputText pTooltip="Reporting Frequency can only be selected after Data Collection Frequency is selected." tooltipPosition="top" class="pi pi-info-circle"> </i></label>
                                  <span> <em> {{_viewContractKPIDetails.kpiReportingFrequencyName}}  </em></span> 
                               </div>
                           </div>
                       </div>
                       <div class="fr-flex__container">
                           <div class="fr-flex__col-4">
                               <div class="fr-form__view">
                                   <label> KPI Tracking Start <em> * </em></label>
                               <span> <em> {{kpiStartFrequency}} </em></span> 
                               </div>
                           </div>
                           <div class="fr-flex__col-4">
                               <div class="fr-form__view">
                                   <label> KPI Tracking End <em> * </em></label>
                                <span> <em>  {{kpiEndFrequency}} </em></span> 
                               </div>
                           </div>
                           <div class="fr-flex__col-4">
                               <div class="fr-form__view">
                                   <label> Status <em> * </em></label>
                               <span> <em> {{_viewContractKPIDetails?.status }}  </em></span> 
                               </div>
                           </div>
                       </div>
                       <div class="fr-flex__container">
                           <div class="fr-flex__col-4">
                               <div class="fr-form__view">
                                   <label> Unit of Measure</label>
                            <span> <em> {{_kpiDetails.unitOfMeasure ? _kpiDetails.unitOfMeasure : '--'}}  </em></span>
                               </div>
                           </div>                                                   
                           <div class="fr-flex__col-4">
                               <div class="fr-form__view">
                                   <label>Hyper Care Target <em> * </em></label>
                                   <ng-container *ngIf="_viewContractKPIHyperCareThresholdDetails.length == 0; else dataBlock">
                                   <div class="display-flex m-t-10">
                                       
                                       <p-checkbox class="kpi-checkbox__alignment" [binary]="true" inputId="binary" [(ngModel)]="checkBoxValue" [disabled]="true"> </p-checkbox>
                                       <label for="binary">Not Applicable</label>
                                     </div>
                                   </ng-container>                                
                                   <ng-template #dataBlock>                                      
                                       <ng-container *ngFor="let _hyperCareThreshold of _viewContractKPIHyperCareThresholdDetails; let i = index">
                                           <span><em>{{_hyperCareThreshold.mathematicalSymbol}}&nbsp;</em></span>
                                           <span><em>{{_hyperCareThreshold.percentageValue}}</em></span>
                                           <span class="m-r-10"><em>{{_kpiDetails.unitOfMeasureCode}} </em></span>
                                       </ng-container>
                                   </ng-template>
                                   
                               </div>
                           </div>
                           
       
                       </div>
                       <div class="fr-flex__container">
                           <div class="fr-flex__col-4">
                               <div class="fr-form__view">
                                   <label> Hyper Care Start Date <em> * </em> </label>                                  
                                     <span *ngIf="_viewContractKPIDetails?.hyperCareStartDate !== null">                                       
                                      <em>  {{ _viewContractKPIDetails?.hyperCareStartDate | date: _dateformart.GridDateFormat }}  </em>                                     
                                     </span>
                                     <span *ngIf="_viewContractKPIDetails?.hyperCareStartDate === null"> <em> -- </em></span>
                               </div>
                           </div> 
                           <div class="fr-flex__col-4">
                               <div class="fr-form__view">
                                  <label> Hyper Care End Date <em> * </em> </label>                                                           
                                     <span *ngIf="_viewContractKPIDetails?.hyperCareEndDate !== null">                                       
                                       <em>  {{ _viewContractKPIDetails?.hyperCareEndDate | date: _dateformart.GridDateFormat }} </em>                                      
                                     </span>
                                     <span *ngIf="_viewContractKPIDetails?.hyperCareEndDate === null"> <em> -- </em>  </span>
                               </div>
                           </div>                           
                           <div class="fr-flex__col-4">
                               <div class="fr-form__view">
                                   <label>Steady State Target <em> * </em></label>
                                   <ng-container *ngFor="let _steadyStateCareThresholdDetails of _viewContractKPIteadyStedyStateThresholdDetails; let i = index">                               
                                       <span><em>{{_steadyStateCareThresholdDetails.mathematicalSymbol}}&nbsp;</em></span>
                                       <span><em>{{_steadyStateCareThresholdDetails.percentageValue}}</em></span>
                                       <span class="m-r-10"><em>{{_kpiDetails.unitOfMeasureCode}}</em></span>
                                   </ng-container>
                               </div>
                           </div>
                           
                       </div>
                      
                       <div class="fr-flex__container">
                           <div class="fr-flex__col-4">
                               <div class="fr-form__view">
                                   <label> Category </label>
                                 <span> <em>  {{ _kpiDetails.category ? _kpiDetails.category : '--'}}  </em></span>
                               </div>
                           </div>
                           <div class="fr-flex__col-4">
                               <div class="fr-form__view">
                                   <label> Compliance Indicator  </label>
                                   <span> <em> {{_kpiDetails.complianceIndicator ? _kpiDetails.complianceIndicator : '--'}} </em></span> 
                               </div>
                           </div>
                           <div class="fr-flex__col-4">
                               <div class="fr-form__view">
                                   <label> Calculation </label>
                                    <span [pTooltip]="_viewContractKPIDetails.calculation.length > 45 ? _viewContractKPIDetails.calculation : ''" tooltipPosition="top"> <em>  {{_viewContractKPIDetails.calculation ? _viewContractKPIDetails.calculation : '--'}}  </em></span> 
                               </div>
                           </div>
                           
       
                       </div>
                       <div class="fr-flex__container">
                           <div class="fr-flex__col-4">
                               <div class="fr-form__view">
                                   <label> Attribute 1 </label>
                                  <span> <em> {{_viewContractKPIDetails.attribute1 ? _viewContractKPIDetails.attribute1 : '--'}}  </em></span> 
                               </div>
                           </div>

                           <div class="fr-flex__col-4">
                               <div class="fr-form__view">
                                   <label> Attribute 2 </label>
                                   <span> <em>  {{_viewContractKPIDetails.attribute2 ? _viewContractKPIDetails.attribute2 : '--'}} </em></span>
                               </div>
                           </div>
                           <div class="fr-flex__col-4">
                               <div class="fr-form__view">
                                   <label> Attribute 3 </label>
                                  <span [pTooltip]="_viewContractKPIDetails.attribute3.length > 20 ? _viewContractKPIDetails.attribute3 : ''" tooltipPosition="top"> <em>  {{_viewContractKPIDetails.attribute3 ? _viewContractKPIDetails.attribute3 : '--'}} </em></span> 
                               </div>
                           </div>
                       </div>
                       
                       <div class="fr-flex__container">
                           <div class="fr-flex__col-12">
                               <div class="fr-form__view">
                                   <label> KPI Description </label>
                                   <span class="full-width" *ngIf="kpiDescription  == null || kpiDescription == ''">--</span> <span class="full-width" [innerHTML]="kpiDescription" *ngIf="kpiDescription  != null"> {{kpiDescription }} </span>
                               </div>
                           </div>
                       </div>

                       <div class="fr-flex__container">
                           <div class="fr-flex__col-3">
                               <div class="fr-form__view">
                                   <label> Added By </label>
                                  <span> <em> {{KpiCreatedUserName}} </em></span> 
                               </div>
                           </div>
                           <div class="fr-flex__col-3">
                               <div class="fr-form__view">
                                   <label> Added Date & Time</label>
                                  
                                  <span> <em> {{_viewContractKPIDetails?.createdDate  | date: _dateformart.DateTimeWithZone}} {{_dateformart.DisplayUTC}} </em></span> 

                                 

                               </div>
                           </div>
                           
                           <div class="fr-flex__col-3">
                               <div class="fr-form__view">
                                   <label> Modified By </label>
                                 <span> <em> {{KpiUpdatedUserName !== '' ? KpiUpdatedUserName : '--'}}  </em></span> 
                               </div>
                           </div>
                           <div class="fr-flex__col-3">
                               <div class="fr-form__view">
                                   <label> Modified Date & Time </label>
                                   <span *ngIf="_viewContractKPIDetails?.updatedBy !== null">                                       
                                         
                                       <em> {{ _viewContractKPIDetails?.updatedDate  | date: _dateformart.DateTimeWithZone}}  {{_dateformart.DisplayUTC}}</em>                                 
                                     </span>
                                     <span *ngIf="_viewContractKPIDetails?.updatedBy === null">--</span>
                               </div>
                           </div>
                           </div>

                   <!-- Mitigation Action Popup Start -->

             <div class="card flex justify-content-center">
                <p-dialog appendTo="body" header="Mitigation Actions" [(visible)]="displayMitigationListPopup" [modal]="true" [style]="{ width: '80vw' }" [draggable]="false">
                  <table aria-describedby="contractViewKPIMitigation" class="table-custom View_KPI_MitigationAction__Popup">
                    <thead>
                      <tr>
                          <th id="conViewKPIDataForPopup">Data For</th>
                        <th id="conViewKPIAttributeOnePopup">Attribute 1 <br><span>({{ _viewContractKPIDetails.attribute1 == ""?"--":_viewContractKPIDetails.attribute1 }})</span></th>
                        <th id="conViewKPIAttributeTwoPopup">Attribute 2<br><span>({{ _viewContractKPIDetails.attribute2 == ""?"--":_viewContractKPIDetails.attribute2 }})</span></th>
                        <th id="conViewKPIAttributeThreePopup">Attribute 3<br><span>({{ _viewContractKPIDetails.attribute3 == ""?"--":_viewContractKPIDetails.attribute3 }})</span></th>
                        <th id="conViewKPICalculatedKPIPopup">Calculated KPI </th>
                        <th id="conViewKPIActualDataFillDatePopup">Actual Data Fill Date </th>
                        <th id="conViewKPIDataFilledByPopup">Data Filled By </th>
                        <th id="conViewKPIRemarksPopup">Remarks </th>
                        <th id="conViewKPIHealthPopup" class="last-child-width"> Health </th>  
                    </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="selectedKpiReportData">
                          <td>{{selectedKpiReportData.dataCollectionFrequencyValue}}</td>
  
                          <td class="text-center">{{( selectedKpiReportData.attribute1==null|| selectedKpiReportData.attribute1=="")? "--":  selectedKpiReportData.attribute1}}</td>
                          <td class="text-center">{{( selectedKpiReportData.attribute2==null|| selectedKpiReportData.attribute2=="")? "--":  selectedKpiReportData.attribute2}}</td>
                          <td class="text-center">{{( selectedKpiReportData.attribute3==null|| selectedKpiReportData.attribute3=="")? "--":  selectedKpiReportData.attribute3}}</td>
                          <td>
                              {{ selectedKpiReportData.calculatedKPI || '--' }}
                              <span *ngIf="selectedKpiReportData.calculatedKPI">{{ _kpiDetails.unitOfMeasureCode }}</span>
                            </td>
                          <td>{{ selectedKpiReportData.remarks ==null? "--": selectedKpiReportData.updatedDate | date: _dateformart.GridDateFormat  }}</td>
                          <td>{{selectedKpiReportData.remarks ==null? "--": privilegeService.getUserName(selectedKpiReportData.createdBy)  }}</td>
                          <td>
                              <ng-container *ngIf="!selectedKpiReportData.remarks || selectedKpiReportData.remarks.length === 0; else showRemarks">
                                <i class="pi pi-info-circle"></i>
                              </ng-container>
                              <ng-template #showRemarks>
                                {{ selectedKpiReportData.remarks }}
                              </ng-template>
                            </td>
                                <td class="grid-icon-center">
                                  <ng-container *ngIf="selectedKpiReportData?.health; else noHealth">
                                    <span class="success__circle" *ngIf="selectedKpiReportData?.health === 'Green'"></span>
                                    <span class="danger__circle" *ngIf="selectedKpiReportData?.health === 'Red'"></span>
                                  </ng-container>
                                  <ng-template #noHealth>--</ng-template>
                                </td>
                        </tr>
                      </tbody>
                  </table>
                  <br><br>
                  <table aria-describedby="contractViewKPIMitigationAction" class="table-custom View_Mitigation__alignTextPopup">
                    <thead>
                      <tr>
                          <th id="conViewKPIAction">Action</th>
                          <th id="conViewKPIActionOwner">Action Owner</th>
                          <th id="conViewKPITargetDate">Target Date</th>
                          <th id="conViewKPIActionStatus">Status</th>
                          <th id="conViewKPIClosureData">Closure Date</th>
                          </tr>
                          </thead>
                          <tbody>
                                <tr *ngFor="let action of mitigationActions">
                                <td>{{action.action}}</td>
                                <td>{{getContactName(action.actionOwnerId)}}</td>
                                <td>{{action.targetDate | date: _dateformart.GridDateFormat}}</td>
                                <td>{{getStatusName(action.statusMappingId)}}</td>
                                <td>{{action.closureDate | date: _dateformart.GridDateFormat}}</td>
                            </tr>
                          </tbody>
                          </table>
                          <a type="button" class="btn btn-secondary pull-right m-t-10" (click)="cancelDialog()"> Close </a>
                        </p-dialog>
                
                            </div>
              
                            <!-- Mitigation Action Popup End -->                   
   
</div>
</body>
</html>
