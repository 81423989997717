
export class ContractMeetingDetails
{
    Id?:number;
    Title?: string;
    Recurring?: Boolean;
    Organizer?:any;
    ContractId?:number;
    CreatedBy?: number;
    CreatedDate?: Date;
    UpdatedBy?: number;
    UpdatedDate?: Date;
    IsDeleted?: Boolean;
    Agenda?:string;
    ContractMeetingOccurrences?:ContractMeetingOccurrences[];
    ContractMeetingContacts?: ContactMeetingContacts[];
}
export class ContractMeetingOccurrences {
    Id: number=0;
    OccurrenceDate?: any;
    MeetingNotes?:string;
    Decisions?:string;
    ContractMeetingId: number=0;
    TotalActionsCount:number=0;
    OpenActionsCount:number=0;
    CreatedBy?: number;
    CreatedDate?: Date;
    UpdatedBy?: number;
    UpdatedDate?: Date;
    IsDeleted?: Boolean;
     ContractMeetingAttendees?:ContractMeetingAttendees[];
     ContractMeetingAbsentees?:ContractMeetingAbsentees[];
     ContractMeetingOccurrenceAttachments?:ContractMeetingOccurrenceAttachments[];
     ContractMeetingOccurrenceActions?:ContractAddMeetingOccurenceActions[];
    
  
  }
  export class ContractMeetingRecOccurrences {
    Id: number=0;
    OccurrenceDate?: Date | undefined;
    MeetingNotes?:string;
    Decisions?:string;
    ContractMeetingId: number=0;
    TotalActionsCount:number=0;
    OpenActionsCount:number=0;
    CreatedBy?: number;
    CreatedDate?: Date;
    UpdatedBy?: number;
    UpdatedDate?: Date;
    IsDeleted?: Boolean;
     ContractMeetingAttendees1 = new ContractMeetingAttendees();
     ContractMeetingAbsentees1 = new ContractMeetingAbsentees();
     ContractMeetingOccurrenceAttachments?:ContractMeetingOccurrenceAttachments[];
     ContractMeetingOccurrenceActions?:ContractAddMeetingOccurenceActions[];
    
  
  }

  export class ContractMeetingAttendees {
    Id: number=0;
    Attendees?:any=[];
    ContractMeetingOccurrenceId: number=0;
    CreatedBy?: number;
    CreatedDate?: Date;
    UpdatedBy?: number;
    UpdatedDate?: Date;
    IsDeleted?: Boolean;
  
  }
  export class ContractMeetingAbsentees {
    Id: number=0;
    Absentees?:any=[];
    ContractMeetingOccurrenceId: number=0;
    CreatedBy?: number;
    CreatedDate?: Date;
    UpdatedBy?: number;
    UpdatedDate?: Date;
    IsDeleted?: Boolean;
  
  }

  export class ContractMeetingOccurrenceAttachments {
    Id: number=0;
    FileName?:string;
    FilePath?:string;
    ContractMeetingOccurrenceId: number=0;
    CreatedBy?: number;
    CreatedDate?: Date;
    UpdatedBy?: number;
    UpdatedDate?: Date;
    IsDeleted?: Boolean;
  
  }
  export class ContractAddMeetingOccurenceActions {   
    ActionName?: string;
    Owner?:string; 
    TargetDate?:Date;
    ClosureDate?:Date;
    Remarks?:string;
    ContractMeetingOccurrenceId?:number;
    StatusMappingId?:number;
    IsDeleted?:boolean;
    CreatedBy?: number;
    CreatedDate?: Date;
    UpdatedBy?: number;
    UpdatedDate?: Date;
  } 
   export class ContractAddMultipleOccurrences {   
    ContractMeetingAddOccurrenceDetails?:ContractMeetingOccurrences[];
    ContractMeetingAddOccurrenceAttendees?:ContractMeetingAttendees[];
    ContractMeetingAddOccurrenceAbsentees?:ContractMeetingAbsentees[];
    ContractMeetingAddOccurrenceOccurrenceAttachments?:ContractMeetingOccurrenceAttachments[];
    ContractMeetingAddOccurrenceActions?:ContractAddMeetingOccurenceActions[];
   }
   export class ContactMeetingContacts{
    id: number=0;
    contactName?: string="";
    designation?: string="";
    countryCode?: string="";
    phoneNumber?: string="";
    email?: string="";
    meetingId: number=0;
   }
 

