import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ContractKPIService } from '../../Services/ContractKpi.Service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from 'src/app/Loader/loader.service';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import {AuditIdentifier, AuditLogEntities, AuditLogModule, AuditOperations, CodeList, CodeListStatusTypes, Dateformat, FrequencyValues, breadcrumnsCodes } from 'src/app/shared/constants/global.constants';
import { ContractService } from 'src/app/Contracts/Services/contract.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { Privileges } from 'src/app/shared/constants/privileges.constants';
import { Table } from 'primeng/table';
import { DatePipe } from '@angular/common';
import { CodeListValues, ContractKpi, ContractKpiDataCollection, KPIThreshold, KpiFilterDetails, MasterKpiData } from '../../Model/contractKpi';
import { CommonService } from 'src/app/shared/Service/common.service';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CurrencyService } from 'src/app/Reusable/currency.service';
import { EngagementService } from 'src/app/Engagement/Services/engagement.service';
import { MasterSupplierService } from 'src/app/MasterData/supplier/Services/master.supplier.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { SponsorContact, SupplierContact } from 'src/app/MasterData/Services/supplier-profile';
import { ChecknulloremptyComponent } from 'src/app/shared/checknullorempty/checknullorempty.component';
import { TabAndStateService } from 'src/app/shared/TabAndStateService/TabAndState.Service';
import { NotificationService } from 'src/app/shared/Notifications/notification.service';
import * as xlsx from 'xlsx';
import { AuditLogService } from 'src/app/shared/ModelComparison/audit.log.service';

@Component({
  selector: 'app-con-kpi-list',
  templateUrl: './con-kpi-list.component.html',
  styleUrls: ['./con-kpi-list.component.css']
})
export class ConKpiListComponent implements OnInit {
  statusdata: any[] = [];
  kpidata: any[] = [];
  healthdata: any[] = [];
  _kpiContractListDatacopy: any = [];
  checkedKpis:any[]=[];
  selectedRecordsToExpotToExcel : any[] = [];
  isChecked:boolean=true;
 filterDetailsModel=new KpiFilterDetails();
  showHideFiltersControls: boolean = false;
  _dateformart :any;
  _enumBreadcrumb :any;
  _kpiContractListData:any;
  totalRecordCount: number = 0;
  filteredRecordCount: number = -1;
  @ViewChild('ContractKpiTable') AssociateTable!: Table;
  @Input() contractDetailId: any;
  @Output() addKpiButtonClick = new EventEmitter<void>();
  @Output() editKpiButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() viewKpiButtonClick: EventEmitter<any> = new EventEmitter<any>();
  createPrivilege:boolean=false;
  editPrivilege:boolean=false;
  viewPrivilege:boolean=false;
  ExportPrivilage:boolean=false;
  KpiDarshBoardPrivilage:boolean=false;
   mitigationForm: FormGroup;
   _engagementName:any;
   _contractName:any;
   _supplierId:any;
   _sponsorId:any;
   EngKpiId:any;
  constructor(
    private privilegeService: PrivilegeService,
    private _contractKPIServiceService : ContractKPIService,
    private router:Router,
    private loaderService: LoaderService,
    private breadcrumbService: BreadcrumbService,
    private _contractService : ContractService,
    private _datePipe: DatePipe,
    private route: ActivatedRoute,
    public commonService:CommonService,
    private masterDataService: SponserService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private currencyService: CurrencyService,
    private engagementService: EngagementService,
    private supplierService: MasterSupplierService,
    private fb: FormBuilder,
    private _contractKPIService:ContractKPIService,
    private checkNullOrEmptyValuesService: ChecknulloremptyComponent,
    private tabAndStateService: TabAndStateService,
    private notificationService:NotificationService,
    private auditLogService: AuditLogService
  
   )
    {

const requiredValidator = Validators.required;
const noSpacesValidator = this.noOnlySpacesValidator;
const minLengthValidator = (length: number) => Validators.minLength(length);
const maxLengthValidator = (length: number) => Validators.maxLength(length);
const patternValidator = (pattern: RegExp) => Validators.pattern(pattern);

const disabledField = (initialValue: any = '') => ({ value: initialValue, disabled: true });

// Function to Create Form Control
const createControl = (initialValue: any, validators: any[] = []) => {
  return [initialValue, validators];
};

this.myContrctKPIForm = this.fb.group({
  _KPIName: disabledField(),
  _kpiDataCollectionFrequency: disabledField(),
  _kpiReportingFrequency: createControl('', [requiredValidator]),
  _status: createControl('', [requiredValidator]),
  _unitOfMeasure: disabledField(),
  _hyperCareThreshold: createControl(null, [this._isNotApplicable ? null : requiredValidator]),
  _hyperCareThresholdValue: createControl('', [
    this._isNotApplicable ? null : requiredValidator,
    minLengthValidator(1),
    maxLengthValidator(3),
    patternValidator(/^\d*$/)
  ]),
  _hyperCareThresholdsymbol: disabledField(),
  _hyperCareStartDate: disabledField(),
  _hyperCareEndDate: disabledField(),
  _dyhyperCareThreshold: createControl(''),
  _isNotApplicable: createControl(''),
  _steadyStateThreshold: createControl('', [requiredValidator]),
  _dyhyperCareThresholdValue: createControl(''),
  _dyhyperCareThresholdsymbol: disabledField(),
  _steadyStateThresoldValue: createControl('', [
    requiredValidator,
    minLengthValidator(1),
    maxLengthValidator(3),
    patternValidator(/^\d*$/)
  ]),
  _steadyStateThresoldSymbol: disabledField(),
  _dysteadyStateThresoldValue: createControl(''),
  _dysteadyStateThresoldSymbol: disabledField(),
  _dysteadyStateThreshold: createControl(''),
  _category: disabledField(),
  _complianceIndicator: disabledField(),
  _calculation: disabledField(),
  _kpiDescription: createControl('', [maxLengthValidator(300)]),
  _serviceName: createControl('', [
    requiredValidator,
    maxLengthValidator(200),
    noSpacesValidator,
    patternValidator(/^[^&%]*$/)
  ]),
  _attribute1: createControl('', [
    requiredValidator,
    minLengthValidator(3),
    noSpacesValidator,
    patternValidator(/^[^&%]*$/)
  ]),
  _attribute2: createControl('', [
    requiredValidator,
    minLengthValidator(3),
    noSpacesValidator,
    patternValidator(/^[^&%]*$/)
  ]),
  _kpiName: createControl('', [
    requiredValidator,
    minLengthValidator(3),
    maxLengthValidator(400),
    noSpacesValidator,
    patternValidator(/^[^&%]*$/)
  ]),
  _frequencyValueforStartDate: disabledField(),
  _kpiStartDateYear: disabledField(),
  _frequencyValueforEndDate: createControl('', [requiredValidator]),
  _kpiEndDateYear: createControl('', [requiredValidator, minLengthValidator(3)])
});

this.kpiDataCollectionFrom = this.fb.group({
  _calculatedKPI: createControl('', [requiredValidator]),
  _remarks: createControl('', [requiredValidator]),
  _attribute1: createControl('', [requiredValidator]),
  _attribute2: createControl('', [requiredValidator]),
  _attribute3: createControl(''),
  _health: createControl('')
});

this.mitigationForm = this.fb.group({
  Action: createControl('', [requiredValidator]),
  Status: createControl('', [requiredValidator])
});

    }; 
       isEditing: boolean = false;
     codeListValues:any;
     codeListStatusTypes:any;
     noOnlySpacesValidator(control: AbstractControl): ValidationErrors | null {
      if (control.value && control.value.trim().length === 0) {
        return { onlySpaces: true };
      }
      return null;
    }
    validateStatus(control: FormControl) {
      const status = control.value;
      if (status === undefined || status === 0) {
          return { required: true }; // Validation failed
      }
      return null; // Validation passed
  }
  auditIdentifierDetails:any;
  auditEntities:any;
  auditOperations:any;
  auditModule:any;    
  breadcrumb:any;
  ngOnInit() {
    this._enumBreadcrumb=breadcrumnsCodes;
    this._dateformart=Dateformat;
    this.auditIdentifierDetails=AuditIdentifier; 
    this.auditEntities=AuditLogEntities;
    this.auditOperations=AuditOperations;
    this.auditModule=AuditLogModule;
    this.GetAllContractKpisData();
    this.loaderService.hidefinalLoader();// hide after 4 seconds even get any error
    this.GetContractList();
    this.BindPrivileges();
    this.GetContractDataById(this.contractDetailId)
   setTimeout(() => {
    this.route.queryParams.subscribe(params => {
      if(params['supplierId']!=undefined && params['supplierId']!=null ){
        this.breadcrumb=params['breadcrumb'];
      }
    
    });
   }, 1000);
  this.GetAllMasterKpis(12);
  this.GetAllHyperCareThresholds(10);
   this.codeListValues=CodeList;
   this.codeListStatusTypes=CodeListStatusTypes
   this.GetMitigationStatus(this.codeListValues.Tbl_Status, this.codeListStatusTypes.MASTS);
    
  }

  GetAllMasterKpis(type:number) {
    this.masterDataService.GetAllSupplierMasterData(type).subscribe(
      res=>{
        this.masterKpiDataSource=res.data;
      }
    );
  };
    GetAllKpiReportingFrequencies(type:number) {
    this.masterDataService.getCodeListData(type,'').subscribe(
      res=>{
        this.kpiReportingFrequencyDataSource=res.data;
      }
    );
  };
  
  startEditing(kpiId: number) {
    this.isEditing = true;
    // Optionally, store kpiId if needed for further actions
  }
  GetAllContractKpisData(){
    this.loaderService.showLoader();
    this._contractKPIServiceService.GetAllContractKpis(this.contractDetailId).subscribe(
      data=>{
        this._kpiContractListData=data.data;
        this._kpiContractListDatacopy=data.data;
        this.tabAndStateService.setKpiData(this._kpiContractListData);
        this.totalRecordCount=this._kpiContractListData.length;
        this.statusdata = this.extractUniqueValues(this._kpiContractListData, 'status').map((obj: any) => ({ label: obj }));
        this.healthdata = this.extractUniqueValues(this._kpiContractListData, 'health').map((obj: any) => ({ label: obj }));
        this.healthdata= this.healthdata.filter(t=>t.label!=null &&t.label!='yellow');
        this.kpidata = this.extractUniqueValues(this._kpiContractListData, 'kpi');
        this.loaderService.hideLoader();
      }
    );
  }
  kpiId:any;
  editContractKPIDataCollection(Id:any){
    this.editedKPIDataCollection=[];
    this.kpiId=Id;
    this.EngKpiId=Id;
    this.displayEditDataCollectionDialog = true;
    this.GetContractKpiById(Id)
  }
  supplierDetailId:any
  contractName:any;
  GetContractList(){
    this.loaderService.showLoader();
    this._contractService.GetContractById(this.contractDetailId).subscribe(
       res=>{
       this.contractName=res.data.title;
         this.supplierDetailId=res.data.supplierId
        
       }
     
     );
  
   }
  BindPrivileges()
  {
    const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);
    this.createPrivilege = hasPermission(Privileges.AddContractKPI);
    this.editPrivilege = hasPermission(Privileges.EditContractKPI);
    this.viewPrivilege = hasPermission(Privileges.ViewContractKPI);
    this.ExportPrivilage= hasPermission(Privileges.ExportContractKPI);
    this.KpiDarshBoardPrivilage=hasPermission(Privileges.ViewContractKPIDashboard);
  }

  editKPIContract(item:any){
   const data = {
     supplierId:this.contractDetailId,
     kpiId:item.id
   };
   this.editKpiButtonClick.emit(data);
  }

 

  AddKpi(){
   this.addKpiButtonClick.emit();
  }

ViewContractKPI(Id:any)
{
  const data = {
    ContractId:this.contractDetailId,
    kpiId:Id
  };
  this.viewKpiButtonClick.emit(data);

}
navigateToDashboard(){
  const engagementId = this.engagementId;
  const contractDetailsId=this.contractDetailId;
  const breadcrumb=this.breadcrumb;
  
  this.router.navigate(['/KPITrendDashboard'], { queryParams: { engagementId,contractDetailsId,breadcrumb } });
}

CheckboxEnabled(){
  if(this.checkedKpis.length>0){
    this.isChecked=false;
  }
  else{
    this.isChecked=true;
  }
}
KpiExportData:any;

exportexcelKpi(){
  this.loaderService.showLoader();
  this.loaderService.hidefinalLoader();

  let KpiIds = this.checkedKpis.map(kpi => kpi.id).join(',');
  this._contractKPIServiceService.GetContracKPIDetailsByIdForExport(KpiIds).subscribe(
    response => {
      this.loaderService.hideLoader();
      this.KpiExportData = response.data;
      this.selectedRecordsToExpotToExcel = this.KpiExportData;
      
      this.KpiExportData.forEach((kpi: { contractDataCollectionList: any[]; dataCollectionFrequency: any; }) => {
        this.SetFrequencyDataCollection(kpi.contractDataCollectionList, kpi.dataCollectionFrequency);
      });

      const dataToExport = this.DataToExport(this.selectedRecordsToExpotToExcel);
      const kpiDataToExport = this.prepareKpiDataToExport(this.selectedRecordsToExpotToExcel);

      const wb = xlsx.utils.book_new();
      const detailsSheet = xlsx.utils.json_to_sheet(dataToExport);
      const kpiDataSheet = xlsx.utils.json_to_sheet(kpiDataToExport);

      xlsx.utils.book_append_sheet(wb, detailsSheet, 'Details');
      xlsx.utils.book_append_sheet(wb, kpiDataSheet, 'KPI Data');
      xlsx.writeFile(wb, `${this.contractName} - KPIsExport - ${this.convertDateFormat(Date())}.xlsx`);
    }
  );
}

private DataToExport(kpis: any[]) {
  return kpis.map(kpi => ({
    'KPI': kpi.kpi,
    'Service': kpi.services,
    'KPI Name': kpi.kpiName,
    'Data Collection Frequency': kpi.dataCollectionFrequency,
    'KPI Reporting Frequency': kpi.kpiReportingCollectionFrequency,
    'KPI Tracking Start': this.formatDate(kpi.kpiTrackingStartDate),
    'KPI Tracking End': this.formatDate(kpi.kpiTrackingEndDate),
    'Status': kpi.status,
    'Unit of Measure': kpi.unitOfMeasure,
    'Hyper Care Target': kpi.hyperCareThresold,
    'Hyper Care Start Date': this.formatDate(kpi.hyperCareStartDate),
    'Hyper Care End Date': this.formatDate(kpi.hyperCareEndDate),
    'Steady State Target': kpi.steadyStateThreshold,
    'Category': kpi.category,
    'Compliance Indicator': kpi.complianceIndicator,
    'Calculation': kpi.calculation,
    'Attribute 1': kpi.attribute1,
    'Attribute 2': kpi.attribute2,
    'Attribute 3': kpi.attribute3,
    'KPI Description': kpi.kpiDescription,
    'Current KPI': kpi.overAllAverageKPI,
    'Current Health': kpi.overallAverageHealth,
    'Added By': this.getUserName(kpi.createdBy),
    'Added Date': this.formatDate(kpi.createdDate),
    'Modified By': this.getUserName(kpi.updatedBy),
    'Modified Date': this.formatDate(kpi.updatedDate),
  }));
}

private prepareKpiDataToExport(kpis: any[]) {
  return kpis.flatMap(kpi =>
    kpi.contractDataCollectionList.map((dataCollection: { dataCollectionFrequencyValue: any; attribute1: any; attribute2: any; attribute3: any; calculatedKPI: any; remarks: any; contractKpiMitigationActionsList: string | any[]; health: any; updatedDate: any; }) => ({
      'KPI Name': kpi.kpiName,
      'Data For': dataCollection.dataCollectionFrequencyValue,
      'Attribute 1': dataCollection.attribute1,
      'Attribute 2': dataCollection.attribute2,
      'Attribute 3': dataCollection.attribute3,
      'Calculated KPI': dataCollection.calculatedKPI,
      'Remarks': dataCollection.remarks,
      'Mitigation Actions': dataCollection.contractKpiMitigationActionsList.length,
      'Health': dataCollection.health,
      'Actual Data Fill Date': (dataCollection.attribute1 != null || dataCollection.attribute2 != null || dataCollection.calculatedKPI != null) ? this.formatDate(dataCollection.updatedDate) : null,
      'Data Filled By': this.getUserName(kpi.createdBy),
    }))
  );
}

private formatDate(date: any): string {
  return date ? this.convertDateFormat(date) : date;
}

private getUserName(userId: any): string {
  return this.privilegeService.getUserName(userId);
}

showfilter()
{
  this.showHideFiltersControls = !this.showHideFiltersControls;
}
  convertDateFormat(inputDateTime: string) {
    const date = new Date(inputDateTime);
    const formattedDate = this._datePipe.transform(date, this._dateformart.GridDateFormat);
    return formattedDate;
  }

  onfilter()
  {
    this._kpiContractListData=this._kpiContractListDatacopy;
  
  
    if(this.filterDetailsModel.Status && this.filterDetailsModel.Status.label){
      this._kpiContractListData = this._kpiContractListData.filter((c:any) => c.status === this.filterDetailsModel.Status.label);

    }
    if(this.filterDetailsModel.Health &&this.filterDetailsModel.Health.label){
      this._kpiContractListData = this._kpiContractListData.filter((c:any) => c.health === this.filterDetailsModel.Health.label);
    }
    if(this.filterDetailsModel.Kpi &&  this.filterDetailsModel.Kpi.length > 0) {
      this._kpiContractListData = this._kpiContractListData.filter((c:any) => this.filterDetailsModel.Kpi.includes(c.kpi));

    }

    //filter By name
    if (this.filterDetailsModel.kpiName != null && this.filterDetailsModel.kpiName != undefined && this.filterDetailsModel.kpiName != "") {
      this._kpiContractListData = this._kpiContractListData.filter((a:any) => a.kpiName.toLowerCase().indexOf(this.filterDetailsModel.kpiName?.toLowerCase()) !== -1);
    }
    this.filteredRecordCount=this._kpiContractListData.length;
    this.tabAndStateService.setKpiData(this._kpiContractListData);
  }
  extractUniqueValues(array: any, key: any)
  {
    return Array.from(new Set(array.map((item: any) => item[key])));
  }
  clearFilters(){
  this.filterDetailsModel={
   Status: '',
   Health: '',
   Kpi: [],
    }
    this.checkedKpis=[];
    this.isChecked=true;
   this._kpiContractListData=this._kpiContractListDatacopy;
   this.filteredRecordCount= this._kpiContractListData.length;
 
   }
   selectedKpiReportData:any;
   displayMitigationListPopup:boolean=false;
   displayEditDataCollectionDialog: boolean = false;
  viewFindingList(Kpidata: any) {
    this.selectedKpiReportData = Kpidata;
    this.addMitigationActionsData = Kpidata.contractKpiMitigationActionsList ? Kpidata.contractKpiMitigationActionsList.map((item: { targetDate: string; TargetDate: string; closureDate: string; ClosureDate: string; }) => {
      const formattedTargetDate = item.targetDate ? this.convertDateFormat(item.targetDate) : item.TargetDate ? this.convertDateFormat(item.TargetDate) : null;
      const formattedClosureDate = item.closureDate ? this.convertDateFormat(item.closureDate) : item.ClosureDate ? this.convertDateFormat(item.ClosureDate) : null;
  
      return {
        ...item,
        TargetDate: formattedTargetDate ? new Date(formattedTargetDate) : null,
        ClosureDate: formattedClosureDate ? new Date(formattedClosureDate) : null,
      };
    }) : [];
    this.addMitigationActionsData.forEach(item => {
      item.Id = item.id || item.Id;
      item.KpiId = item.kpiid || item.KpiId;
      item.DataCollectionId = item.dataCollectionId || item.DataCollectionId;
      item.Action = item.action || item.Action;
      item.ActionOwner = item.actionOwnerId || item.ActionOwner;
      item.StatusMappingId = item.statusMappingId || item.StatusMappingId;
      item.TargetDate= item.targetDate!=null ? new Date(item.targetDate) : null  || item.TargetDate!=null ? new Date(item.TargetDate) : null ;
      item.ClosureDate= item.closureDate!=null ? new Date(item.closureDate) : null  || item.ClosureDate !=null ? new Date(item.ClosureDate) : null ;
    });
    this.oldMitigationActionsData = JSON.parse(JSON.stringify(this.addMitigationActionsData));
    this.visableMitigations = this.addMitigationActionsData.filter(action => !action.isDeleted);
    this.selectedKpiReportData.findingsCount = this.addMitigationActionsData.length;
   this.displayMitigationListPopup = true;
   this.addMitigationSaved = false;
} 
  addMitigationActionsData: any[] = [];
  AddMitigationActions() { //Click on Add Action button new row will be generated on top of the grid
    this.addMitigationActionsData.unshift({
      Id: 0,
      Action: '',
      ActionOwner: "",
      TargetDate: null,
      StatusMappingId: '',
      ClosureDate: null,
      IsDeleted: false
    });
    this.visableMitigations = this.addMitigationActionsData.filter(action => !action.IsDeleted);
  }
  _contractKpiDataCollections: ContractKpiDataCollection[]=[];
  _contractKpi=new ContractKpi();
  oldKpiInfo:any;
  previousEndDate:any;
  DataCollectionFrequency:string="";
  isAttribute1NotApplicable=false;
  isAttribute2NotApplicable=false;
  isAttribute3NotApplicable=false;
  _readOnlyCalculation:string="";
  _readOnlyAttribute1:string="";
  _readOnlyAttribute2:string="";
  _readOnlyAttribute3:string="";
  _readOnlyFormulaName:string="";
  _formulaeId:number=0;
  _kpiAttributeTypeCode:string="";
  _kpiAttributeTypeId:number=0;
  _readOnlySybmolHyperCareThreshold:string="";
  _readOnlySybmolSteadyStateThreshold:string="";
  _isNotApplicable:boolean=false;
  _unitCode:string="";
  selectedItem: any;
    oldSelectedItem: any;
    dataCollectionSubmitted=false;
    _overAllAverageKPI?:string="";
    _overallAverageHealth?:string="";
    suggestedKPIName:string="";
    kpiCreatedUserName:string="";
    kpiUpdatedUserName:string="";
    _kpiHyperCareThreshold= new KPIThreshold();
    _kpiSteadyStateThresholdDetail= new KPIThreshold();
    _kpiSteadyStateThresholdDetails: KPIThreshold[]=[];
    _kpiHyperCareThresholdDetail= new KPIThreshold();
    _kpiHyperCareThresholdDetails: KPIThreshold[]=[];
    _dynamicKpiHyperCareThreshold= new KPIThreshold();
    _dynamicKpiHyperCareThresholds: KPIThreshold[]=[];
    _dynamicKpiSteadyStateThreshold= new KPIThreshold();
    _dynamicKpiSteadyStateThresholds: KPIThreshold[]=[];
    _readOnlyUnitofMeasure:string="";
    _readOnlyCategory:string="";
    _readOnlyComplianceIndicator:string="";
    kpiNameDataSource: CodeListValues[] | undefined;
  kpiReportingFrequencyDataSource: CodeListValues[] | undefined;
  kpiDataCollectionFrequencyDataSource: CodeListValues[] | undefined;
  statusDataSource: CodeListValues[] | undefined;
  oldContractDataCollection:any;
  GetContractKpiById(ContractKpiId: number) {
    this.oldContractDataCollection = '';
    this.loaderService.showLoader();
    this._contractKPIServiceService.GetContractKpiById(ContractKpiId).subscribe(
      res => {
        this._contractKpi = res.data;
        this.previousEndDate = res.data.kpiEndDate;
        this.assignDates(res.data);
  
        this.isAttribute1NotApplicable = this.isAttributeNotApplicable(res.data.attribute1);
        this.isAttribute2NotApplicable = this.isAttributeNotApplicable(res.data.attribute2);
        this.isAttribute3NotApplicable = this.isAttributeNotApplicable(res.data.attribute3);
  
        this._readOnlyCalculation = this.getDefaultString(res.data.calculation);
        this._readOnlyAttribute1 = this.getDefaultString(res.data.attribute1);
        this._readOnlyAttribute2 = this.getDefaultString(res.data.attribute2);
        this._readOnlyAttribute3 = this.getDefaultString(res.data.attribute3);
  
        this._contractKpiDataCollections=res.data.dataCollectionList;
              this._contractKpiDataCollections.forEach((item: any) => {
                item.findingsCount = item.contractKpiMitigationActionsList ? item.contractKpiMitigationActionsList.length : 0;
              });
  
        this.GetAllkpiDataCollectionFrequenies(8);
        this._overAllAverageKPI = res.data.overAllAverageKPI;
        this.kpiCreatedUserName = this.privilegeService.getUserName(res.data.createdBy);
        this.kpiUpdatedUserName = this.privilegeService.getUserName(res.data.updatedBy);
        this._overallAverageHealth = res.data.overallAverageHealth;
  
        this.handleThresholdDetails(res.data);
  
        setTimeout(() => {
          this.OnChangeKPIName(res.data.kpiDetailId);
          this.updateDataCollectionFrequency();
        }, 1000);
  
        this.oldKpiInfo = this.createOldKpiInfo(res.data);
       
        this.loaderService.hideLoader();
      }
    );
  
    this.oldContractDataCollection = JSON.parse(JSON.stringify(this._contractKpiDataCollections));
  }
  
  // Utility Functions
  
  private assignDates(data: any) {
    this._contractKpi.kpiStartDate = new Date(data.kpiStartDate);
    this._contractKpi.kpiEndDate = new Date(data.kpiEndDate);
    this._contractKpi.hyperCareStartDate = new Date(data.hyperCareStartDate);
    this._contractKpi.hyperCareEndDate = new Date(data.hyperCareEndDate);
  }
  
  private isAttributeNotApplicable(attribute: string): boolean {
    return attribute === 'NA';
  }
  
  private getDefaultString(value: string | undefined): string {
    return value || '';
  }
  
  private handleThresholdDetails(data: any) {
    if (data.kpiHyperCareThresholdDetail?.length === 1) {
      this._kpiHyperCareThresholdDetail = data.kpiHyperCareThresholdDetail[0];
    } else if (data.kpiHyperCareThresholdDetail?.length === 2) {
      this._kpiHyperCareThresholdDetail = data.kpiHyperCareThresholdDetail[0];
      this._dynamicKpiHyperCareThresholds.push(data.kpiHyperCareThresholdDetail[1]);
      this._dynamicKpiHyperCareThreshold = data.kpiHyperCareThresholdDetail[1];
    } else {
      this._isNotApplicable = true;
      this._contractKpi.hyperCareStartDate = undefined;
      this._contractKpi.hyperCareEndDate = undefined;
    }
  
    if (data.kpiSteadyStateThresholdDetail?.length < 2) {
      this._kpiSteadyStateThresholdDetail = data.kpiSteadyStateThresholdDetail[0];
    } else {
      this._kpiSteadyStateThresholdDetail = data.kpiSteadyStateThresholdDetail[0];
      this._dynamicKpiSteadyStateThresholds.push(data.kpiSteadyStateThresholdDetail[1]);
      this._dynamicKpiSteadyStateThreshold = data.kpiSteadyStateThresholdDetail[1];
    }
  }
  
  private updateDataCollectionFrequency() {
    const filteredDataCollection = this.kpiDataCollectionFrequencyDataSource?.filter(t => t.id === this._contractKpi.kpiDataCollectionFrequencyId);
    if (filteredDataCollection != null) {
      this.DataCollectionFrequency = filteredDataCollection[0].name;
      const selectedSubCode = filteredDataCollection[0].sub_Code;
      this.kpiReportingFrequencyDataSource = this.kpiReportingFrequencyDataSource?.filter(t => t.sub_Code >= selectedSubCode);
    }
  }
  
  private createOldKpiInfo(data: any) {
    const oldKpiInfo = { ...data };
    oldKpiInfo.dataCollectionList = data.dataCollectionList;
    oldKpiInfo.overAllAverageKPI = data.overAllAverageKPI;
    oldKpiInfo.overallAverageHealth = data.overallAverageHealth;
    this.contractKPIDetailBeforeUpdate=JSON.stringify(oldKpiInfo);
    return JSON.stringify(oldKpiInfo);
  }
  
  isFrequencyAnnual=false;
  editedKPIDataCollection:any=[];//msg
  frequencyData:any;
  GetAllkpiDataCollectionFrequenies(type:number) {
    this.masterDataService.getCodeListData(type,'').subscribe(
      res=>{
        this.kpiDataCollectionFrequencyDataSource=res.data;

        let frequency=this.kpiDataCollectionFrequencyDataSource?.filter(t=>t.id==this._contractKpi.kpiDataCollectionFrequencyId)[0].name;
        const kpiStart=this.commonService.getFrequencyFromDate(this._contractKpi.kpiStartDate,frequency);
        const kpiEnd=this.commonService.getFrequencyFromDate(this._contractKpi.kpiEndDate,frequency);
        
        //Get Frequency Data Here
        if(frequency=="Monthly")
          this.frequencyData=JSON.parse(FrequencyValues.Monthly);
        else if(frequency=="Quarterly")
          this.frequencyData=JSON.parse(FrequencyValues.Quarterly);
        else if(frequency=="Half-Year")
          this.frequencyData=JSON.parse(FrequencyValues.HalfYearly);
        else if(frequency=="Annual")
          this.frequencyData=JSON.parse(FrequencyValues.Annual);

          if(frequency=="Annual"){
            this.isFrequencyAnnual=true;
          }
          else{
            this.isFrequencyAnnual=false;
          }

          this.SetFrequencyValueforDataCollection(this._contractKpi.kpiDataCollectionFrequencyId);

          this.BindFrequencyDates(kpiStart,kpiEnd,frequency,this.frequencyData);
      }
    );
  };
  SetFrequencyValueforDataCollection(frequencyId:any){
    let frequency=this.kpiDataCollectionFrequencyDataSource?.filter(t=>t.id==frequencyId)[0].name;
        
      this._contractKpiDataCollections.forEach((item:any) => {
      item.dataCollectionFrequencyValue=this.commonService.getFrequencyFromDate(item.dataCollectionDate,frequency);
    });
  }
  BindFrequencyDates(startFreq: any, endFreq: any, frequency: any, dataCollectionFrequencyData:any) 
  {
    let startFrequencyValue: string;
    let startFrequencyYear: string;
    let endFrequencyValue: string;
    let endFrequencyYear: string;
  
    switch (frequency) {
      case "Monthly":
        [startFrequencyValue, startFrequencyYear] = startFreq.split(" ");
        [endFrequencyValue, endFrequencyYear] = endFreq.split(" ");
        break;
      case "Quarterly":
      case "Half-Year":
      case "Annual":
        startFrequencyValue = startFreq.substring(0, 2); // Extract the first two characters
        startFrequencyYear = startFreq.slice(-4);
        endFrequencyValue = endFreq.substring(0, 2); // Extract the first two characters
        endFrequencyYear = endFreq.slice(-4);
        break;
      default:
        throw new Error('Invalid frequency');
    }

    if(frequency != "Annual"){
      this._contractKpi.frequencyValueforStartDate=dataCollectionFrequencyData.find((a:any)=>a.name==startFrequencyValue).order;
      this._contractKpi.frequencyValueforEndDate=dataCollectionFrequencyData.find((a:any)=>a.name==endFrequencyValue).order;;
    }

  this._contractKpi.kpiStartDateYear=startFrequencyYear;
  this._contractKpi.kpiEndDateYear=endFrequencyYear;
    
  }
  masterKpiDataSource: MasterKpiData[] | undefined;
  OnChangeKPIName(kpiDetailId:number){
   
    let filteSupplierMasterData = this.masterKpiDataSource?.filter(t=>t.kpiDetailId==kpiDetailId);
    if(filteSupplierMasterData!=null)
    {
  this._readOnlyUnitofMeasure=filteSupplierMasterData[0].unitOfMeasure;
  this._readOnlyCategory=filteSupplierMasterData[0].category;
  this._readOnlyComplianceIndicator=filteSupplierMasterData[0].complianceIndicator;
  this._unitCode=filteSupplierMasterData[0].unitOfMeasureCode;
  this._formulaeId=filteSupplierMasterData[0].formulaeId;
  this._readOnlyFormulaName=filteSupplierMasterData[0].formulaName;
  this._kpiAttributeTypeCode=filteSupplierMasterData[0].kpiAttributeTypeCode;
  this._kpiAttributeTypeId=filteSupplierMasterData[0].kpiAttributeTypeId;
 
  
  if(!this._isNotApplicable)
  {
    this._readOnlySybmolHyperCareThreshold=this._unitCode;
  }
  else{
    this._readOnlySybmolHyperCareThreshold="";
  }
  this._readOnlySybmolSteadyStateThreshold=this._unitCode;
  
  }
   else{
    this._readOnlyUnitofMeasure="";
    this._readOnlyCategory="";
    this._readOnlyComplianceIndicator="";
    this._readOnlyCalculation="";
    this._readOnlyAttribute1="";
    this._readOnlyAttribute2="";
    this._readOnlyAttribute3="";
    this._readOnlySybmolHyperCareThreshold="";
    this._readOnlySybmolSteadyStateThreshold="";
    this._unitCode="";
    this._readOnlyFormulaName="";
    this._formulaeId=0;
    this._kpiAttributeTypeCode="";
    this._kpiAttributeTypeId=0;
    }
   
  }
  oldContractKPIData:any;
  contractKPIDetailBeforeUpdate:any
  getCalculationFormulae(){
    //this._readOnlyFormulaName
    let data=this._readOnlyFormulaName;
    data=data.replace('Attribute 1',this._readOnlyAttribute1);
    data=data.replace('Attribute 2',this._readOnlyAttribute2);
    data=data.replace('Attribute 3',this._readOnlyAttribute3);
    this._readOnlyCalculation=data;
   }
   currencies: any='USD';
   getFormattedValue(value: number, currencyName: string): string {
   let formattedValue = this.currencyService.getFormattedValue(value, currencyName);
  return this.handleCurruncy(formattedValue);
  }
  private handleCurruncy(formattedValue: string): string {
    return formattedValue.replace(/^[^\d]+/, '');
}
addMitigationSaved = false;
_addActionsFieldsMandatory: boolean = false;
saveMitigationActions() {;
  this.addMitigationSaved = true;
  this._addActionsFieldsMandatory = this.addMitigationActionsData.some((action) => {
    const status = this.actionStatus.find((a: { id: any; }) => a.id === action.StatusMappingId);
    if (
      this.checkNullOrEmptyValuesService.isNullOrUndefinedOrEmpty(action.Action) || 
      this.checkNullOrEmptyValuesService.isNullOrUndefinedOrEmpty(action.StatusMappingId) || 
      (status && status.code === "CLD" && this.checkNullOrEmptyValuesService.isNullOrUndefinedOrEmpty(action.ClosureDate))
    ) {
      return true;
    }
    return false;
  });
  if (this.validateFields()) {
  this.selectedKpiReportData.contractKpiMitigationActionsList = this.addMitigationActionsData.map(action => ({
    ...action,
    StatusMappingId: action.StatusMappingId,
    ActionOwner: action.ActionOwner === '' ? 0 : action.ActionOwner,
    TargetDate: action.TargetDate || '', 
    ClosureDate: action.ClosureDate || '', 
  }));

  this.selectedKpiReportData.findingsCount = this.addMitigationActionsData.length; 
  this.displayMitigationListPopup = false;
} else {
      Object.values(this.mitigationForm.controls).forEach(control => {
    control.markAsTouched();
  });
}
}
validateFields(): boolean {
  for (const actionData of this.addMitigationActionsData) {
    const status = this.actionStatus.find((s: { id: any; }) => s.id === actionData.StatusMappingId);
    if (!actionData.Action || actionData.Action.trim() === '') {
      return false;
    }
    if (!actionData.StatusMappingId) {
      return false;
    }
     if (status && status.code === "CLD")
      {
      if (!actionData.ClosureDate) {
        return false;
      }
    }
  }
  return true;
}
oldMitigationActionsData: any[] = [];
cancelMitigationDialog() {
  if (JSON.stringify(this.oldMitigationActionsData) !== JSON.stringify(this.addMitigationActionsData)) {
    this.confirmationService.confirm({
      message: 'Changes will not be saved, Do you want to Continue?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.messageService.add({
          key: 'tc',
          severity: 'info',
          summary: 'Changes not saved',
          detail: '',
        });
        this.displayMitigationListPopup = false;
      },
      reject: () => {
      },
    });
  } else {
    this.displayMitigationListPopup = false;
  }
  }
  visableMitigations:any;
deleteAction(currentActionIndex: number) {  
  this.confirmationService.confirm({
       header: 'Confirm',
       message: 'Are you sure you want to delete this action?',
       accept: () => {
        this.visableMitigations.splice(currentActionIndex,1);
        this.addMitigationActionsData=this.visableMitigations;
        
       this.messageService.add({ severity: 'success', summary: 'Mitigation Action Deleted', detail: '', });
     }
     });
   }
   actionStatus:any;
   GetMitigationStatus(enumId: number, type: string) { //Get Meeting Status's from code list for the Meetings
     this.masterDataService.getCodeListData(enumId, type).subscribe(
       res => {      
         if (res.data.length > 0) {
           this.actionStatus = res.data;
         }
       }
     );
   };
   

   engagementId:any;
   GetContractDataById(ContractId:number){
    this._contractService.GetContractById(ContractId).subscribe(
      res=>{
        this._contractName=res.data.title;

       this.engagementId=res.data.engagementId;

        this.GetEngagementDataById(res.data.engagementId);
      }
    );
  }
  GetEngagementDataById(EngagementId:number){
    this.engagementService.GetEngagementById(EngagementId).subscribe(
      res=>{
        this.loaderService.hideLoader();
        this._engagementName=res.data.engagementName;
        this._supplierId=res.data.supplierId;
        this._sponsorId=res.data.sponsorId;
        this.getCombinedContacts(res.data.supplierId,res.data.sponsorId);
      }
    );
  }
convertActionDateFormat(inputDateTime: string) {
  const date = new Date(inputDateTime);
  const formattedDate = this._datePipe.transform(date, this._dateformart.AuditFormat);
  return formattedDate;
}
supplierContactsDataSource : SupplierContact[]=[];
sponsorContactsDataSource : SponsorContact[]=[];
combinedContacts: any[] = [];
getCombinedContacts(supplierId: number = 0, sponsorId: number = 0) {
  this.supplierService.GetSupplierById(supplierId).subscribe(supplierRes => {
    this.supplierContactsDataSource = supplierRes.data.supplierContacts;

    this.masterDataService.getSponserData(sponsorId).subscribe(sponsorRes => {
      this.sponsorContactsDataSource = sponsorRes.data.sponserContacts.map(this.mapSponsorContact);
      
      this.combinedContacts = [...this.supplierContactsDataSource, ...this.sponsorContactsDataSource];
    });
  });
}

mapSponsorContact(contact: any): any {
  return {
    id: contact.id,
    contactName: contact.name,
    countryCode: contact.countryCode,
    designation: contact.designation,
    phoneNumber: contact.phoneNumber,
    email: contact.email,
    supplierProfileId: contact.sponserId,
    createdBy: contact.createdBy,
    createdDate: contact.createdDate,
    updatedBy: contact.updatedBy,
    updatedDate: contact.updatedDate,
    isDeleted: contact.isDeleted
  };
}


cancelKpiDataCollectionDialog(){
  if(this.oldKpiDataCollection){
if(this.oldContractDataCollection.length>0 || this.oldKpiDataCollection.length>0){
  if ((JSON.stringify(this.oldContractDataCollection) !== JSON.stringify(this._contractKpiDataCollections))|| (JSON.stringify(this.oldKpiDataCollection) !== JSON.stringify(this._contractKpiDataCollections))) {
    this.confirmationService.confirm({
      message: 'Changes will not be saved, Do you want to Continue?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.messageService.add({
          key: 'tc',
          severity: 'info',
          summary: 'Changes not saved',
          detail: '',
        });
        this.displayEditDataCollectionDialog=false;
      },
      reject: () => {
       
      },
    });
  }
  else {
    this.displayEditDataCollectionDialog=false;
  }
 }else {
    this.displayEditDataCollectionDialog=false;
  }
}
else{
  this.displayEditDataCollectionDialog=false;
}

}


hyperCareThresholdDataSource: CodeListValues[] | undefined;
steadyStateThresholdDataSource: CodeListValues[] | undefined;
GetAllHyperCareThresholds(type:number) {
  this.masterDataService.getCodeListData(type,'').subscribe(
    res=>{
      this.hyperCareThresholdDataSource=res.data;
      this.steadyStateThresholdDataSource=res.data;
    }
  );
};


isSaveBtnDisable = false;
submitted = false;
onUpdateContractKpi()
{
  if(this._isNotApplicable)
  {
    this.myContrctKPIForm.controls['_hyperCareStartDate'].setErrors(null);
    this.myContrctKPIForm.controls['_hyperCareEndDate'].setErrors(null);
    this.myContrctKPIForm.controls['_hyperCareThresholdValue'].setErrors(null);
    this.myContrctKPIForm.controls['_hyperCareThreshold'].setErrors(null);
    
  }

  if(this.DataCollectionFrequency == "Annual"){
    this.myContrctKPIForm.controls['_frequencyValueforStartDate'].setErrors(null);
    this.myContrctKPIForm.controls['_frequencyValueforEndDate'].setErrors(null);
  }
   //validate dynamic fileds
if(this._dynamicKpiHyperCareThresholds?.length!=0)
{
this.myContrctKPIForm.controls['_dyhyperCareThresholdValue'].setErrors(this._dynamicKpiHyperCareThreshold.percentageValue=="" ? {required: true} :null);
this.myContrctKPIForm.controls['_dyhyperCareThreshold'].setErrors(this._dynamicKpiHyperCareThreshold.mathematicalSymbolId==0 ? {required: true} :null);
}
if(this._dynamicKpiSteadyStateThresholds?.length!=0)
{
this.myContrctKPIForm.controls['_dysteadyStateThresoldValue'].setErrors(this._dynamicKpiSteadyStateThreshold.percentageValue=="" ? {required: true} :null);
this.myContrctKPIForm.controls['_dysteadyStateThreshold'].setErrors(this._dynamicKpiSteadyStateThreshold.mathematicalSymbolId==0 ? {required: true} :null);
}
  this.submitted = true;
  this.isSaveBtnDisable = true;
  setTimeout(() => {
    this.isSaveBtnDisable = false;
  }, 3000);

  
    this.loaderService.showLoader();

    this._kpiHyperCareThresholdDetails=[];
    this._kpiSteadyStateThresholdDetails=[];


    this._contractKpiDataCollections.forEach(collection => {
      if (collection.contractKpiMitigationActionsList) {
        collection.contractKpiMitigationActionsList = collection.contractKpiMitigationActionsList.map(action => this.updateMitigations(action));
      }
    });
    if(!this._isNotApplicable)
    {
      this._kpiHyperCareThresholdDetails.push(this._kpiHyperCareThresholdDetail);
      if(this._dynamicKpiHyperCareThreshold.mathematicalSymbolId!=0)
      this._kpiHyperCareThresholdDetails.push(this._dynamicKpiHyperCareThreshold)
    }
    this._kpiSteadyStateThresholdDetails.push(this._kpiSteadyStateThresholdDetail);
    if(this._dynamicKpiSteadyStateThreshold.mathematicalSymbolId!=0)
    this._kpiSteadyStateThresholdDetails.push(this._dynamicKpiSteadyStateThreshold);
    this._contractKpi.KPIHyperCareThresholdDetail=this._kpiHyperCareThresholdDetails;
    this._contractKpi.KPISteadyStateThresholdDetail=this._kpiSteadyStateThresholdDetails;

    this._contractKpi.kpiReportingFrequencyCode=this.kpiDataCollectionFrequencyDataSource?.filter(t=>t.id==this._contractKpi.kpiDataCollectionFrequencyId)[0].code;
   
    this._contractKpi.updatedBy=this.privilegeService.getLoginUserId();
    this._contractKpi.kpiDataCollection=this._contractKpiDataCollections;
    if(this._contractKpi.hyperCareEndDate != undefined && this._contractKpi.hyperCareEndDate != null)
    {
      this._contractKpi.hyperCareEndDate =  this._datePipe.transform(this._contractKpi.hyperCareEndDate, "MM/dd/yyyy");
    }
    if(this._contractKpi.hyperCareStartDate != undefined && this._contractKpi.hyperCareStartDate != null)
    {
      this._contractKpi.hyperCareStartDate = this._datePipe.transform(this._contractKpi.hyperCareStartDate, "MM/dd/yyyy");
    }     


    this._contractKpi.attribute1=this._readOnlyAttribute1;
  this._contractKpi.attribute2=this._readOnlyAttribute2;
  this._contractKpi.attribute3=this._readOnlyAttribute3;
  this._contractKpi.calculation=this._readOnlyCalculation;

  let frequency=this.kpiDataCollectionFrequencyDataSource?.filter(t=>t.id==this._contractKpi.kpiDataCollectionFrequencyId)[0].name;
    this._contractKpi.kpiStartDate=this.commonService.getCompleteDate(frequency,this._contractKpi.frequencyValueforStartDate,this._contractKpi.kpiStartDateYear);
    this._contractKpi.kpiEndDate=this.commonService.getCompleteDate(frequency,this._contractKpi.frequencyValueforEndDate,this._contractKpi.kpiEndDateYear);
    
   //Check Start date should always greater than End Date
    if(this._contractKpi.kpiStartDate>this._contractKpi.kpiEndDate)
    {
      this.messageService.add({  key: 'tc',life: 4000,severity: 'warn', summary: 'KPI Tracking End should be greater than KPI Tracking Start', detail: '' });  
      this.loaderService.hideLoader();    
    }
    else{

      //Check End Date should always greater than previous End Date
      if(this._contractKpi.kpiEndDate<new Date(this.previousEndDate)){
        this.messageService.add({  key: 'tc',life: 4000, severity: 'warn', summary: 'KPI Tracking End can only be extended and cannot be shortened', detail: '' });  
        this.loaderService.hideLoader();  
      }
      else{
    this._contractKpi.kpiEndDate = this._datePipe.transform(this._contractKpi.kpiEndDate, "MM/dd/yyyy");
    this._contractKpi.kpiStartDate = this._datePipe.transform(this._contractKpi.kpiStartDate, "MM/dd/yyyy");
    this._contractKPIServiceService.UpdateContractKpi(this._contractKpi).subscribe(async (res: { status: any; }) => {
     if(res.status)
     {
      this.compareMitigations();
      if (this.editedKPIDataCollection && this.editedKPIDataCollection.length > 0) {
        const kpiData = {
            kpiName: this._contractKpi.kpiName,
            engagementName: this._engagementName,
            contractTitle: this._contractName,
            supplierId: 0,
            contractId: this.contractDetailId,
            kpiId: this.kpiId,
            kpiDataCollectionEdited: this.editedKPIDataCollection,
            kpiDataCollection: this._contractKpiDataCollections,
            attribute1: this._contractKpi.attribute1,
            attribute2: this._contractKpi.attribute2,
            attribute3: this._contractKpi.attribute3,
            hyperCareThreshold:this._contractKpi.KPIHyperCareThresholdDetail,
          steadyStateThreshold:this._contractKpi.KPISteadyStateThresholdDetail,
          hyperCareStartDate:this._contractKpi.hyperCareStartDate,
          hyperCareEndDate:this._contractKpi.hyperCareEndDate,
          mathematicalSymbolSource:this.hyperCareThresholdDataSource
        };
        await this.notificationService.KPIThresholdNotification(this.kpiId, kpiData, 0, this._sponsorId, this._supplierId);
      }
      this.loaderService.hideLoader();
      this.compareModels();
      this.displayEditDataCollectionDialog=false;
      this.messageService.add({ key: 'tc', severity: 'success', summary: 'Changes have been saved', detail: '' });
      this.GetAllContractKpisData();
     }
   });
  }
}

}
updateMitigations(action: any): any {
  return {
    id: action.Id ?? action.id ?? null,
    action: action.Action ?? action.action ?? '',
    actionOwnerId: action.ActionOwner ?? action.actionOwnerId ?? null,
    targetDate: this._datePipe.transform(action.TargetDate ?? action.targetDate, 'MM/dd/yyyy') ?? null,
    statusMappingId: action.StatusMappingId ?? action.statusMappingId ?? null,
    closureDate: this._datePipe.transform(action.ClosureDate ?? action.closureDate, 'MM/dd/yyyy') ?? null,
    isDeleted: action.IsDeleted ?? action.isDeleted ?? false
  };
}
_contractKpiDataCollection= new ContractKpiDataCollection();
visible: boolean = false; 
myContrctKPIForm: FormGroup;
kpiDataCollectionFrom: FormGroup;
isAttributeValueSelected=false;
editDataCollection(item: any) {
  this._contractKpiDataCollection = new ContractKpiDataCollection();
  this.dataCollectionSubmitted = false;
  this.visible = true;
  this.selectedItem = { ...item };
  this.oldSelectedItem = { ...item };

  const resetAttributes = () => {
    for (let i = 1; i <= 3; i++) {
      const attributeName = `attribute${i}`;
      this.selectedItem[attributeName] = this.selectedItem[attributeName] ? this.selectedItem[attributeName] : null;
    }
  };

  if (this._kpiAttributeTypeCode === "INT") {
    if (this._contractKpiDataCollection.inputAttributeValueType == 1) {
      this.kpiDataCollectionFrom.get('_calculatedKPI')?.enable();
    }

    this.selectedItem.attribute1 = this.selectedItem.attribute1 || "";
    this.selectedItem.attribute2 = this.selectedItem.attribute2 || "";
    this.selectedItem.attribute3 = this.selectedItem.attribute3 || "";
    
    this.selectedItem.calculatedKPI = (this.selectedItem.attribute1 === "" && this.selectedItem.calculatedKPI == null) ? "" : this.selectedItem.calculatedKPI;
    
    this._contractKpiDataCollection = this.selectedItem;
    resetAttributes();
    
    this.kpiDataCollectionFrom.get('_attribute1')?.enable();
    this.kpiDataCollectionFrom.get('_attribute2')?.enable();

    this.isAttributeValueSelected = this._contractKpiDataCollection.inputAttributeValueType == 2;
  }

  if (this._kpiAttributeTypeCode === "DTE") {
    this.selectedItem.attribute1 = this.selectedItem.attribute1 ? new Date(this.selectedItem.attribute1) : "";
    this.selectedItem.attribute2 = this.selectedItem.attribute2 ? new Date(this.selectedItem.attribute2) : "";
    this.selectedItem.attribute3 = this.selectedItem.attribute3 ? new Date(this.selectedItem.attribute3) : "";

    this.selectedItem.calculatedKPI = (this.selectedItem.attribute1 === "" && this.selectedItem.calculatedKPI == null) ? "" : this.selectedItem.calculatedKPI;
    
    this._contractKpiDataCollection = this.selectedItem;

    if (this._contractKpiDataCollection.inputAttributeValueType == 1) {
      this.kpiDataCollectionFrom.get('_attribute1')?.disable();
      this.kpiDataCollectionFrom.get('_attribute2')?.disable();
      this.kpiDataCollectionFrom.get('_attribute3')?.disable();
      resetAttributes();
    } else {
      this.kpiDataCollectionFrom.get('_attribute1')?.disable();
      this.kpiDataCollectionFrom.get('_attribute2')?.disable();
      this.kpiDataCollectionFrom.get('_attribute3')?.disable();
      this.kpiDataCollectionFrom.get('_calculatedKPI')?.disable();
      resetAttributes();
    }
  }

  if (this._kpiAttributeTypeCode === "NA") {
    this.selectedItem.attribute1 = this.selectedItem.attribute1 || "";
    this.selectedItem.attribute2 = this.selectedItem.attribute2 || "";
    this.selectedItem.attribute3 = this.selectedItem.attribute3 || "";

    this.selectedItem.calculatedKPI = this.selectedItem.calculatedKPI == null ? "" : this.selectedItem.calculatedKPI;
    
    this._contractKpiDataCollection = this.selectedItem;
    this.isAttributeValueSelected = false;
  }
}
cancelDialog() {
  this.visible = false;
  this.messageService.add({
    key: 'tc',
    severity: 'info',
    summary: 'KPI data collection has not been saved',
    detail: '',
});
}

isBtnClicked=false;
btnCalculateKPI(){
  
    // Attribute3 should be considered here when attribute3 will be included in the business case
    if(this._contractKpiDataCollection.attribute1 != '' && this._contractKpiDataCollection.attribute2 != ""){
    this.CalculateKPI();
    }
    else
    {
      this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Enter the required Attribute values', detail: '' });
    }
 
}

isSaveKpiDataCollectionPopupBtnDisable = false;
oldKpiDataCollection:any;
saveKpiDataCollection() {
  this.oldKpiDataCollection=this._contractKpiDataCollections;
  this.isSaveKpiDataCollectionPopupBtnDisable = true;
  setTimeout(() => {
    this.isSaveKpiDataCollectionPopupBtnDisable = false;
  }, 3000);
  if(this._contractKpiDataCollection.inputAttributeValueType==1)
  {
    this.kpiDataCollectionFrom.controls['_attribute1'].setErrors(null);
    this.kpiDataCollectionFrom.controls['_attribute2'].setErrors(null);
    this.kpiDataCollectionFrom.controls['_attribute3'].setErrors(null);
  }
  else if(this._contractKpiDataCollection.inputAttributeValueType==2){
    this.kpiDataCollectionFrom.controls['_calculatedKPI'].setErrors(null);
    if(this._readOnlyAttribute1=='')
    {
      this.kpiDataCollectionFrom.controls['_attribute1'].setErrors(null);
    }
    if(this._readOnlyAttribute2=='')
    {
      this.kpiDataCollectionFrom.controls['_attribute2'].setErrors(null);
    }
  }
  else
  {
    this.kpiDataCollectionFrom.controls['_attribute1'].setErrors(null);
    this.kpiDataCollectionFrom.controls['_attribute2'].setErrors(null);
    this.kpiDataCollectionFrom.controls['_attribute3'].setErrors(null);
    this.kpiDataCollectionFrom.controls['_calculatedKPI'].setErrors(null);
  }
  this.dataCollectionSubmitted = true;
  if (this.kpiDataCollectionFrom.valid) {
    if(this._contractKpiDataCollection.inputAttributeValueType==2)
    {
    this.isBtnClicked=true;
    this.CalculateKPI();
    }
    else{
      this.saveLocalDataCollection();
    }
}
}

saveLocalDataCollection(){
const KpiDataCollectionIndex = this._contractKpiDataCollections.findIndex(item => item.id === this.oldSelectedItem.id);
if (KpiDataCollectionIndex !== -1) {

  if(this._kpiAttributeTypeCode=="INT")
  {
  this.selectedItem.attribute1=this._contractKpiDataCollection.attribute1;
  this.selectedItem.attribute2=this._contractKpiDataCollection.attribute2;
  this.selectedItem.attribute3=this._contractKpiDataCollection.attribute3;
  }
  if(this._kpiAttributeTypeCode=="DTE"){
    this.selectedItem.attribute1=this._datePipe.transform(this._contractKpiDataCollection.attribute1, "dd-MMM-yyyy");
    this.selectedItem.attribute2=this._datePipe.transform(this._contractKpiDataCollection.attribute2, "dd-MMM-yyyy");
    this.selectedItem.attribute3=this._datePipe.transform(this._contractKpiDataCollection.attribute3, "dd-MMM-yyyy");
  }
  // this.selectedItem.calculatedKPI=parseInt( this._contractKpiDataCollection.calculatedKPI ||0)
  this.selectedItem.calculatedKPI=this._contractKpiDataCollection.calculatedKPI==""?null:this._contractKpiDataCollection.calculatedKPI;
  this.selectedItem.health=this._contractKpiDataCollection.health;
  this.selectedItem.kpiAttributeTypeId=this._kpiAttributeTypeId;
  this._contractKpiDataCollections[KpiDataCollectionIndex] = { ...this.selectedItem };

  const indexKpiDataCollection = this.editedKPIDataCollection.findIndex((obj: { id: any; }) => obj.id === this.selectedItem.id);

  if (indexKpiDataCollection !== -1) {
    // If newObj already exists, remove the old object at the index
    this.editedKPIDataCollection.splice(indexKpiDataCollection, 1);
  }
  
  // Push the new object into the array
  this.editedKPIDataCollection.push(this.selectedItem);

  this.selectedItem = null; 
  this.oldSelectedItem=null;
}
this.visible = false;
this.messageService.add({
  key: 'tc',
  severity: 'success',
  summary: 'KPI data collection has been saved',
  detail: '',
});
}
calculatedFieldValue:any;
CalculateKPI(){
  this._contractKpiDataCollection.attribute1=this._contractKpiDataCollection.attribute1?.toString();
  this._contractKpiDataCollection.attribute2=this._contractKpiDataCollection.attribute2?.toString();

  if(this._contractKpiDataCollection.attribute1!="" && this._contractKpiDataCollection.attribute2!="")
  {
   this.loaderService.showLoader();
   this._contractKPIService.CalculateKPI_New(this._formulaeId,this._contractKpiDataCollection.attribute1,this._contractKpiDataCollection.attribute2,this._contractKpiDataCollection.attribute3,'ContractKPI',this._kpiAttributeTypeCode)
   .then(calculatedKPI => {
    if(calculatedKPI.toString().toLocaleLowerCase()!='infinity')
    {
    if(this.isBtnClicked){
      this.isBtnClicked=false;
      this._contractKpiDataCollection.calculatedKPI=calculatedKPI;
      this.saveLocalDataCollection();
    
    }
    else{
       this._contractKpiDataCollection.calculatedKPI=calculatedKPI;
     }
    }
    else{
      this.isBtnClicked=false;
      this.messageService.add({ key: 'tc', life: 5000, severity: 'error', summary: 'The values you entered for the attributes are resulting in an invalid output. Please re-enter the attribute values.', detail: '' });
    }
       this.loaderService.hideLoader();
   })
   .catch(error => {
     this._contractKpiDataCollection.calculatedKPI=0;
   });
  }
  this.loaderService.hidefinalLoader();
  setTimeout(() => {
   this.finalClear();
 },2000);
 
}
finalClear(){
 if((this._contractKpiDataCollection.attribute1== "" || this._contractKpiDataCollection.attribute2=="") && this._contractKpiDataCollection.inputAttributeValueType==2)
 {
  this._contractKpiDataCollection.calculatedKPI="";
 }
}
onNumericInputpopUp(event: Event, _controlname: string) {
  const inputElement = event.target as HTMLInputElement;
  let sanitizedValue = inputElement.value.replace(/[^\d.]/g, ''); // Allow only digits and a single dot
  const decimalParts = sanitizedValue.split('.'); // Split value by dot

  // If there are more than 2 decimal parts, keep only the first two parts
  if (decimalParts.length > 1) {
    sanitizedValue = decimalParts[0] + '.' + (decimalParts[1] || '').slice(0, 2); // Keep only up to two decimal places
  }

  inputElement.value = sanitizedValue;
  this.kpiDataCollectionFrom.controls[_controlname].setValue(sanitizedValue);
}
onAttributeDateSelection(attributeDate:any,type:number){
  if(this._contractKpiDataCollection.calculatedKPI=="")
  {
    this.dataCollectionSubmitted =false
  }
  if(this._contractKpiDataCollection.attribute1!="" && this._contractKpiDataCollection.attribute2!="" && this._contractKpiDataCollection.attribute1!=null && this._contractKpiDataCollection.attribute2!=null &&this.dataCollectionSubmitted ==false)
   {
    this.loaderService.showLoader();
    this._contractKPIService.CalculateKPI_New(this._formulaeId,this._contractKpiDataCollection.attribute1,this._contractKpiDataCollection.attribute2,this._contractKpiDataCollection.attribute3,'ContractKPI',this._kpiAttributeTypeCode)
    .then(calculatedKPI => {
      this._contractKpiDataCollection.calculatedKPI=calculatedKPI;
        this.loaderService.hideLoader();
    })
    .catch(error => {
      this._contractKpiDataCollection.calculatedKPI=0;
    });
   }
   
}

onRadioButtonClick(KPIoption: string) {

  this.isAttributeValueSelected = KPIoption === '2';
  this.dataCollectionSubmitted = false;

  const resetAttributes = () => {
    this._contractKpiDataCollection.attribute1 = "";
    this._contractKpiDataCollection.attribute2 = "";
    this._contractKpiDataCollection.attribute3 = "";
    if (this._readOnlyFormulaName !== 'N/A') {
      this._contractKpiDataCollection.calculatedKPI = "";
    }
    this.kpiDataCollectionFrom.controls['_attribute1'].setErrors(null);
    this.kpiDataCollectionFrom.controls['_attribute2'].setErrors(null);
    this.kpiDataCollectionFrom.controls['_attribute3'].setErrors(null);
    this.kpiDataCollectionFrom.get('_attribute1')?.disable();
    this.kpiDataCollectionFrom.get('_attribute2')?.disable();
    this.kpiDataCollectionFrom.get('_attribute3')?.disable();
    this.kpiDataCollectionFrom.get('_calculatedKPI')?.disable();
  };

  if (KPIoption === '1') {
    resetAttributes();
    this.kpiDataCollectionFrom.controls['_calculatedKPI'].setErrors({ required: true });
    this.kpiDataCollectionFrom.get('_calculatedKPI')?.enable();
  } else if (KPIoption === '2') {
    this.kpiDataCollectionFrom.get('_attribute1')?.enable();
    this.kpiDataCollectionFrom.get('_attribute2')?.enable();
    
    if (this._readOnlyFormulaName !== 'N/A') {
      this._contractKpiDataCollection.calculatedKPI = "";
    }

    if (this._readOnlyAttribute3 && this._readOnlyFormulaName !== 'N/A') {
      this.kpiDataCollectionFrom.controls['_attribute3'].setErrors({ required: true });
      this.kpiDataCollectionFrom.get('_attribute3')?.enable();
    } else {
      this.kpiDataCollectionFrom.get('_attribute3')?.disable();
    }

    if (this._readOnlyAttribute1 && this._readOnlyFormulaName !== 'N/A') {
      this.kpiDataCollectionFrom.controls['_attribute1'].setErrors({ required: true });
    }

    if (this._readOnlyAttribute2 && this._readOnlyFormulaName !== 'N/A') {
      this.kpiDataCollectionFrom.controls['_attribute2'].setErrors({ required: true });
    }
  } else {
    resetAttributes();
  }
}
isClosedStatus(statusMappingId: number): boolean {
  const status = this.actionStatus.find((s: { id: number; }) => s.id === statusMappingId);
  return status ? status.code === "CLD" : false;
}
oldKpiData: any;
newKpiData: any;
mitigationDataCollection: any[] = [];
newMitigation:any;
async compareMitigations() {
  this.mitigationDataCollection=[];
  this.oldKpiData=JSON.parse(this.contractKPIDetailBeforeUpdate);
  this.newKpiData=this._contractKpi;
  const oldDataCollectionList = this.oldKpiData.dataCollectionList;
  const newDataCollectionList = this.newKpiData?.kpiDataCollection;
  oldDataCollectionList.forEach((oldData: { contractKpiMitigationActionsList: any; dataCollectionDate: any; }, index: string | number) => {
   const newData = newDataCollectionList ? newDataCollectionList[index] : null;
    if (oldData && newData) {
      const oldMitigations = oldData.contractKpiMitigationActionsList;
      const newMitigations = newData.contractKpiMitigationActionsList;
        if(oldMitigations.length==0 && newMitigations.length!=0 )
        {
          newMitigations.forEach((newitigation: any) => {
            this.mitigationDataCollection.push({
              'kpiName':this.newKpiData.kpiName,
              'engagementName':this._engagementName,
              'mitigationactionstatus':this.actionStatus?.find((aa:any) => aa.id === newitigation?.statusMappingId)?.name,
              'engagementId':0,
              'contractTitle':this._contractName,
               "Datafor": newData.dataCollectionFrequencyValue,
               "kpiValue": newData.calculatedKPI === null ? '--':newData.calculatedKPI+''+this._readOnlySybmolSteadyStateThreshold,
               "Actionfieldvalue": newitigation.action,
               'contractId':this.contractDetailId,
               'id':newitigation.id,
               'ownerEmail':this.getEmailById(newitigation.actionOwnerId),
               'hyperCareThreshold':this._contractKpi.KPIHyperCareThresholdDetail,
               'steadyStateThreshold':this._contractKpi.KPISteadyStateThresholdDetail,
               'hyperCareStartDate':this._contractKpi.hyperCareStartDate,
               'hyperCareEndDate':this._contractKpi.hyperCareEndDate,
               'mathematicalSymbolSource':this.hyperCareThresholdDataSource,
               'actionTargetDate':newitigation.targetDate,
               'unitOfMeasure':this._readOnlySybmolSteadyStateThreshold,
               'health':newData.health,
               'ownerName':this.getContactNameById(newitigation.actionOwnerId),
               'dataCollectionDate':newData.dataCollectionDate,
               'inputAttributeType':newData.inputAttributeValueType
             });

          });
        }
        else
      {
       const filterNewMitigations = newMitigations?.filter((t: { id: number; })=>t.id==0);
       filterNewMitigations.forEach((newitigation: any) => {
        this.mitigationDataCollection.push({
          'kpiName':this.newKpiData.kpiName,
          'engagementName':this._engagementName,
          'mitigationactionstatus':this.actionStatus?.find((aa:any) => aa.id === newitigation?.statusMappingId)?.name,
          'engagementId':0,
          'contractTitle':this._contractName,
           "Datafor": newData.dataCollectionFrequencyValue,
           "kpiValue": newData.calculatedKPI === null ? '--':newData.calculatedKPI+''+this._readOnlySybmolSteadyStateThreshold,
           "Actionfieldvalue": newitigation.action,
           'contractId':this.contractDetailId,
           'id':newitigation.id,
           'ownerEmail':this.getEmailById(newitigation.actionOwnerId),
           'hyperCareThreshold':this._contractKpi.KPIHyperCareThresholdDetail,
           'steadyStateThreshold':this._contractKpi.KPISteadyStateThresholdDetail,
           'hyperCareStartDate':this._contractKpi.hyperCareStartDate,
           'hyperCareEndDate':this._contractKpi.hyperCareEndDate,
           'mathematicalSymbolSource':this.hyperCareThresholdDataSource,
           'actionTargetDate':newitigation.targetDate,
           'unitOfMeasure':this._readOnlySybmolSteadyStateThreshold,
           'health':newData.health,
           'ownerName':this.getContactNameById(newitigation.actionOwnerId),
           'dataCollectionDate':newData.dataCollectionDate,
           'inputAttributeType':newData.inputAttributeValueType
         });
      });

      const newMitigationsMap = new Map(newMitigations.map((mitigation: any) => [mitigation.id, mitigation]));
      oldMitigations.forEach((oldMitigation: any) => {
        this.newMitigation = newMitigationsMap.get(oldMitigation.id);
         if ( this.newMitigation) {
         const _oldStatus = this.actionStatus?.find((aa:any) => aa.id === oldMitigation.statusMappingId)?.code;
         const _newStatus = this.actionStatus?.find((aa:any) => aa.id === this.newMitigation?.statusMappingId)?.code;
           if (_oldStatus=='OPN' && _newStatus=='CLD') {
            this.mitigationDataCollection.push({
              'kpiName':this.newKpiData.kpiName,
              'engagementName':this._engagementName,
              'mitigationactionstatus':this.actionStatus?.find((aa:any) => aa.id === this.newMitigation?.statusMappingId)?.name,
              'engagementId':0,
              'contractTitle':this._contractName,
               "Datafor": newData.dataCollectionFrequencyValue,
               "kpiValue": newData.calculatedKPI === null ? '--':newData.calculatedKPI+''+this._readOnlySybmolSteadyStateThreshold,
               "Actionfieldvalue": this.newMitigation.action,
               'contractId':this.contractDetailId,
               'id':this.newMitigation.id,
               'ownerEmail':this.getEmailById(this.newMitigation.actionOwnerId),

               'hyperCareThreshold':this._contractKpi.KPIHyperCareThresholdDetail,
                 'steadyStateThreshold':this._contractKpi.KPISteadyStateThresholdDetail,
                 'hyperCareStartDate':this._contractKpi.hyperCareStartDate,
                 'hyperCareEndDate':this._contractKpi.hyperCareEndDate,
                 'mathematicalSymbolSource':this.hyperCareThresholdDataSource,
                 'actionTargetDate':this.newMitigation.targetDate,
                 'unitOfMeasure':this._readOnlySybmolSteadyStateThreshold,
                 'health':newData.health,
                 'ownerName':this.getContactNameById(this.newMitigation.actionOwnerId),
                 'dataCollectionDate':newData.dataCollectionDate,
                 'inputAttributeType':newData.inputAttributeValueType
             });
           }
         };
       });
      }
     }
   });
   if(this.mitigationDataCollection.length>0)
   {
   await this.notificationService.KpiMitigationNotification(this.kpiId,this.mitigationDataCollection,0,this._sponsorId,this._supplierId)
}
}
SetFrequencyDataCollection(dataCollectionList: any[], frequency: any) {
  dataCollectionList.forEach(item => {
    item.dataCollectionFrequencyValue = this.commonService.getFrequencyFromDate(item.dataCollectionDate, frequency);
  });
}
getEmailById(id: number): string | undefined {
  const contact = this.combinedContacts.find(c => c.id === id);
  if(contact!=undefined)
  return contact?.email;
  else
  return "";
}

getContactNameById(id: number): string | undefined {
  const contact = this.combinedContacts.find(c => c.id === id);
  if(contact!=undefined)
  return contact?.contactName;
  else
  return "";
}


changedContractKPIBasicInfo:any;
compareModels() {
  if(this._contractKpi.hyperCareStartDate){
    this._contractKpi.hyperCareStartDate= new Date(this._contractKpi.hyperCareStartDate).toISOString();
  }
  if(this._contractKpi.hyperCareEndDate){
    this._contractKpi.hyperCareEndDate= new Date(this._contractKpi.hyperCareEndDate).toISOString();
  }
  this._contractKpi.kpiStartDate= new Date(this._contractKpi.kpiStartDate).toISOString();
  this._contractKpi.kpiEndDate= new Date(this._contractKpi.kpiEndDate).toISOString();

  this.contractKPIDetailBeforeUpdate=JSON.parse(this.contractKPIDetailBeforeUpdate);
  this.contractKPIDetailBeforeUpdate.contractDetailId=this._contractKpi.contractDetailId;
  this.contractKPIDetailBeforeUpdate=JSON.stringify(this.contractKPIDetailBeforeUpdate);
  this.changedContractKPIBasicInfo = this.auditLogService.compareModels(JSON.parse(this.contractKPIDetailBeforeUpdate),this._contractKpi,this.auditEntities.ContractKPIs,this.auditIdentifierDetails.ContractKPIs,this.auditOperations.Update,this.auditModule.GovernanceService);
}
}
