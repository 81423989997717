import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContractService } from 'src/app/Contracts/Services/contract.service';
import { EngagementService } from 'src/app/Engagement/Services/engagement.service';
import { FinanceService } from 'src/app/Finance/Services/invoice.service';
import { ContractKPIService } from 'src/app/Governance/kpis/Contract/Services/ContractKpi.Service';

import { EngagementKpiService } from 'src/app/Governance/kpis/Engagement/Services/engagement-kpi.service';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';
import { MasterSupplierService } from 'src/app/MasterData/supplier/Services/master.supplier.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { environment } from 'src/environments/environment';
import { GenerateTableFormartNotificationService } from './generatetableformat.notifications';



@Injectable({
  providedIn: 'root'
})

export class NotificationService {
readonly _notificationApiBaseURl?:string="";
readonly _poUtilization:number;
constructor(private http: HttpClient,
    private datePipe: DatePipe,
    private masterDataService: SponserService,
    private contractService: ContractService,
    private financeInvoiceService:FinanceService,
    private engagementKpiService:EngagementKpiService,
    private contractKpiService: ContractKPIService,
    private engagementService: EngagementService,
    private _mastersupplierService: MasterSupplierService,
    private privilegeService: PrivilegeService,
    private tableFormartNotificationService:GenerateTableFormartNotificationService
    ) {
this._notificationApiBaseURl=environment?._notificationApiBaseURl;
this._poUtilization = environment?._poUtilization!;
}

public sendEmailNotification(payloadData:any): Observable<any> {

  const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  return this.http.post<any>(this._notificationApiBaseURl +  '/notifications', payloadData,httpOptions);
}



sponserData:any;
sponsorContactData:any

async GetSponserContactsBySponsorId(sponsorId:any) {
  this.masterDataService.getSponserData(sponsorId)
  .subscribe(res=>{
   this.sponserData=res?.data?.sponserInfo;
   this.sponsorContactData=res?.data?.sponserContacts;
  })
}


 supplierData:any;
 supplierContactData:any;
 contractData:any;
 engagementData:any;
 sponserId:any;

GetEngagementDetails(engagementId:any): Promise<any> {
  return new Promise<any>((resolve, reject) => {
     // Your synchronous code or asynchronous code within a Promise
    // Resolve the Promise when the task is complete
        this.engagementService.GetEngagementById(engagementId)
        .subscribe(res => {
          this.engagementData=res?.data;
          this.sponserId=this.engagementData.sponsorId;
          this.GetSupplierContacts(res.data.supplierId);
        this.masterDataService.getSponserData(this.sponserId)
        .subscribe(res=>{

        this.sponserData=res.data.sponserInfo;
        this.sponsorContactData=res.data.sponserContacts;
        resolve(true);
 
        })

        });
    });
}

GetSupplierContacts(Id:number){
 
  this._mastersupplierService.getSupplierContactsDataById(Id)
  .subscribe(res=>{
    this.supplierContactData = res.data;
  })
}
 GetContractDetailByContractId(contractId:any): Promise<any>{

  return new Promise<any>((resolve, reject) => {
    // Your synchronous code or asynchronous code within a Promise
    // Resolve the Promise when the task is complete
    this.contractService.GetContractDtailsById(contractId).subscribe(
      res=>{
      this.contractData = res?.data; 
      this.engagementService.GetEngagementById(this.contractData.supplierId)
      .subscribe(res => {  
        this.supplierData=res?.data;
        this._mastersupplierService.getSupplierContactsDataById(res.data.supplierId)
        .subscribe(res=>{
          this.supplierContactData = res.data;
        })
        this.masterDataService.getSponserData(this.supplierData?.sponsorId)?.subscribe(res=>{
          this.sponserData=res?.data?.sponserInfo;
          this.sponsorContactData=res?.data?.sponserContacts;
          resolve(true);
      })
      })
      }
    );
   
  });

  
}

reciients:any;

async GetRecipients(contractId:any,engagementId:any,supplierMSAid:any=0): Promise<any>{
  return new Promise<any>(async (resolve, reject) => {
    // Your synchronous code or asynchronous code within a Promise
    // Resolve the Promise when the task is complete
    this.reciients=[];
    if(contractId>0){
          await this.GetContractDetailByContractId(contractId); // get specific contract contact detail
          let suppContactData = this.supplierContactData?.filter((data: { id: any; })=>data.id==this.contractData?.ownerSupplierContactId);
          if(suppContactData?.length>0)
          this.reciients?.push(suppContactData[0]?.email);
          let sponContactData = this.sponsorContactData?.filter((data: { id: any; })=>data.id==this.contractData?.sponserContactId);
          if(sponContactData?.length>0)
          this.reciients.push(sponContactData[0]?.email);
  
          if(supplierMSAid>0 && this.supplierData?.suppliersMasterServiceAgreements?.length>0){ // get specific MSA contact details
              let specificMSA = this.supplierData?.suppliersMasterServiceAgreements?.filter((data: { id: any; })=>data.id==supplierMSAid);
              if(specificMSA.length>0){ // get contact detail for specific msa
                  let msa= specificMSA[0];
                  let suppContactData = this.supplierContactData?.filter((data: { id: any; })=>data.id==msa?.supplierContactId);
                  if(suppContactData.length>0)
                  this.reciients?.push(suppContactData[0]?.email);
                  let sponContactData = this.sponsorContactData?.filter((data: { id: any; })=>data.id==msa?.sponsorContactId);
                  if(sponContactData?.length>0)
                  this.reciients?.push(sponContactData[0]?.email);
                  resolve(this.reciients);
                }
          }
          else{
            resolve(this.reciients);
          }
    }
    else if(engagementId>0){
          await this.GetEngagementDetails(engagementId); // get specific supplier contact detail
          if(this.supplierData?.suppliersMasterServiceAgreements?.length>0) { 
            // get all MSA contact details
              this.supplierData?.suppliersMasterServiceAgreements?.forEach((msa: any) => {  
                let suppContactData = this.supplierContactData?.filter((data: { id: any; })=>data.id==msa?.supplierContactId);
                if(suppContactData?.length>0)
                this.reciients?.push(suppContactData[0]?.email);
                let sponContactData = this.sponsorContactData?.filter((data: { id: any; })=>data.id==msa?.sponsorContactId);
                if(sponContactData?.length>0)
                this.reciients?.push(sponContactData[0]?.email);
               

              });
              resolve(this.reciients);
          }
          else{
            resolve(this.reciients);
          }
    }
    });
  }

contractSummaryData:any;
recipientsData:any;
finalMailRecipientsArray:any;
async POUtilization(contractId:any,sponsorId:number,supplierId:number): Promise<any>{
  return new Promise<any>((resolve, reject) => {
    // Your synchronous code or asynchronous code within a Promise
    // Resolve the Promise when the task is complete
       this.contractSummaryData=null;
       this.contractService.GetContractRecipientsById(contractId,sponsorId,supplierId).subscribe(
        async res => {
          if (res?.data) {
            this.recipientsData=res?.data;
            let currencySybol=res?.data.currencySymbol;
            let sponsorRecipients= this.getFilteredEmails("FIN",this.recipientsData.sponsorRecipients);
            let supplierRecipients= this.getFilteredEmails("FIN",this.recipientsData.supplierRecipients)
            const mailRecipients = sponsorRecipients+","+supplierRecipients+','+this.recipientsData.contractSponsorOwner+','+this.recipientsData.contractSupplierOwner;
            let mailRecipientsArray = mailRecipients.split(',').filter(email => email.trim() !== '');
            mailRecipientsArray = [...new Set(mailRecipientsArray)];
            this.financeInvoiceService.GetContractSummaryDetailsById(contractId).subscribe(
              async res => {
                if (res?.data) {
                  this.contractSummaryData = res?.data;
                  if(this.contractSummaryData != null && this.contractSummaryData?.poValueUtilization>=this._poUtilization)
                  {
                      if(mailRecipientsArray?.length>0)
                      {
                          let payloadData= {
                              "product": "KontractPro",
                              "module": "Finance",
                              "data": {
                                  "subject":"PO Utilization",
                                  "poValue": currencySybol+this.contractSummaryData?.poValue,
                                  "poNumber": this.contractSummaryData?.poNumber,
                                  "poUtilization": this.contractSummaryData?.poValueUtilization,
                                  "contractTitle": this.contractSummaryData?.title,
                                  "poBalance": currencySybol+this.contractSummaryData?.poBalance,
                              }, 
                          "recipients": mailRecipientsArray
                          }
        
                          this.sendEmailNotification(payloadData).subscribe(res => {
                               
                                resolve(true);                           
                          });
                        resolve(true);       
                      }
                      else{
                        resolve(true);                    
                      }
                      
                  }
                  else{
                    resolve(true);
                  }
              }
              else{
                  resolve(true);
              }
          });
               
        }
        else{
            resolve(true);
        }
    });





   
              
     });
  
}

//added by vittal on 28-05-2024 for send notification add/update status
async AddInvoiceNotification(
  contractId: any,
  payloadDataObject: any,
  supplierId: number,
  sponsorId: number
): Promise<any> {
  try {
    const res = await this.contractService
      .GetContractRecipientsById(contractId, sponsorId, supplierId)
      .toPromise();

    if (res?.data) {
      let sponsorRecipients = this.getFilteredEmails("FIN", res.data.sponsorRecipients);
      let supplierRecipients = this.getFilteredEmails("FIN", res.data.supplierRecipients);

      const mailRecipients = [
        ...sponsorRecipients.split(','),
        ...supplierRecipients.split(','),
        res.data.contractSponsorOwner,
        res.data.contractSupplierOwner,
      ].filter(email => email.trim() !== '');

      const uniqueMailRecipients = [...new Set(mailRecipients)];

      if (uniqueMailRecipients.length > 0) {
        const payloadData = {
          product: "KontractPro",
          module: "Finance",
          data: {
            subject: "Invoice Added",
            invoiceno: payloadDataObject.InvoiceNumber,
            invoicevalue: payloadDataObject.InvoiceValue,
            contractTitle: res.data.contractTitle,
            invoiceduedate: payloadDataObject.InvoiceDueDate,
          },
          recipients: uniqueMailRecipients,
        };

        await this.sendEmailNotification(payloadData).toPromise();
      }
    }
    return Promise.resolve(true);
  } catch (error) {
    return Promise.reject(error);
  }
}


async UpdateInvoiceNotification(contractId:any,payloadDataObject:any,supplierId:number,sponsorId:number): Promise<any>{
  return new Promise<any>((resolve, reject) => {
       this.contractService.GetContractRecipientsById(contractId,sponsorId,supplierId).subscribe(
           async res => {
             if (res?.data) {
               let sponsorRecipients= this.getFilteredEmails("FIN",res.data.sponsorRecipients);
               let supplierRecipients= this.getFilteredEmails("FIN",res.data.supplierRecipients)
               const mailRecipients = sponsorRecipients+","+supplierRecipients+','+res.data.contractSponsorOwner+','+res.data.contractSupplierOwner;
               let mailRecipientsArray = mailRecipients.split(',').filter(email => email.trim() !== '');
               mailRecipientsArray = [...new Set(mailRecipientsArray)];

              
                   if(mailRecipientsArray.length>0 && payloadDataObject.PreviousStatus!=payloadDataObject.CurrentStatus)
                   {
                       let payloadData= {
                           "product": "KontractPro",
                           "module": "Finance",
                           "data": {
                               "subject":"Invoice Status Updated",
                               "invoiceno": payloadDataObject.InvoiceNumber,
                               "invoicevalue":payloadDataObject.InvoiceValue,
                               "contractTitle": res.data.contractTitle,
                               "invoiceduedate":this.datePipe.transform(payloadDataObject.InvoiceDueDate, "dd-MMM-yy"),
                               "previousinvoicestatus": payloadDataObject.PreviousStatus,
                               "currentinvoicestatus": payloadDataObject.CurrentStatus,
                           }, 
                       "recipients": mailRecipientsArray
                       }
                       this.sendEmailNotification(payloadData).subscribe(res => {
                             resolve(true);                           
                       });
                     resolve(true);       
                   }
                   else{
                     resolve(true);                    
                   }
           }
           else{
               resolve(true);
           }
       });
              
     });
  
}


//added by vittal on 28-05-2024 for send notification add/update status

// async InvoiceReportNotification(contractId:any,payloadDataObject:any,supplierId:number,sponsorId:number): Promise<any>{
//   let userData=this.privilegeService.getUserData();
//   let LoginUser= userData.emailId;
//   return new Promise<any>((resolve, reject) => {
//     this.contractService.GetContractRecipientsById(contractId, sponsorId, supplierId).subscribe(
//         async res => {
//             if (res?.data) {
//                 const sponsorRecipients = this.getFilteredEmails("FIN", res.data.sponsorRecipients);
//                 const supplierRecipients = this.getFilteredEmails("FIN", res.data.supplierRecipients);
                
//                 let mailRecipients = [
//                     sponsorRecipients,
//                     supplierRecipients,
//                     res.data.contractSponsorOwner,
//                     res.data.contractSupplierOwner,
//                     LoginUser
//                 ].join(',');
                
//                 let mailRecipientsArray = mailRecipients.split(',')
//                     .filter(email => email.trim() !== '')
//                     .filter((value, index, self) => self.indexOf(value) === index); // Remove duplicates
                
//                 if (mailRecipientsArray.length > 0) {
//                     let payloadData = {
//                         "product": "KontractPro",
//                         "module": "Finance",
//                         "data": {
//                             "subject": "Invoice Report",
//                             "startDate": payloadDataObject.StartDate,
//                             "endDate": payloadDataObject.EndDate,
//                             "contractTitle": res.data.contractTitle,
//                             "engagementName": payloadDataObject.EngagementName,
//                             "InvoicedAmount": payloadDataObject.InvoicedAmount,
//                             "BudgetBalanceFY": payloadDataObject.BudgetBalanceFY,
//                             "currentfiscalyear": payloadDataObject.CurrentFiscalYear,
//                         },
//                         "recipients": mailRecipientsArray
//                     };
//                     this.sendEmailNotification(payloadData).subscribe(() => {
//                         resolve(true);
//                     });
//                 } else {
//                     resolve(true);
//                 }
//             } else {
//                 resolve(true);
//             }
//         },
//         err => {
//             reject(err); // Optionally handle errors
//         }
//     );
// });

// }

async InvoiceReportNotification(contractId:any,payloadDataObject:any,supplierId:number,sponsorId:number): Promise<any>{
  let userData=this.privilegeService.getUserData();
  let LoginUser= userData.emailId;
  return new Promise<any>((resolve, reject) => {
    this.contractService.GetContractRecipientsById(contractId, sponsorId, supplierId).subscribe(
        async res => {
            if (res?.data) {
                if (LoginUser!=null && LoginUser!="") {
                    let payloadData = {
                        "product": "KontractPro",
                        "module": "Finance",
                        "data": {
                            "subject": "Invoice Report",
                            "startDate": payloadDataObject.StartDate,
                            "endDate": payloadDataObject.EndDate,
                            "contractTitle": res.data.contractTitle,
                            "engagementName": payloadDataObject.EngagementName,
                            "InvoicedAmount": payloadDataObject.InvoicedAmount,
                            "BudgetBalanceFY": payloadDataObject.BudgetBalanceFY,
                            "currentfiscalyear": payloadDataObject.CurrentFiscalYear,
                        },
                        "recipients": [LoginUser]
                    };
                   
                    this.sendEmailNotification(payloadData).subscribe(() => {
                        resolve(true);
                    });
                } else {
                    resolve(true);
                }
            } else {
                resolve(true);
            }
        },
        err => {
            reject(err); // Optionally handle errors
        }
    );
});

}

// async PersonnelTurnoverNotification(contractId:any,payloadDataObject:any,supplierId:number,sponsorId:number,engagementId:any,subject:any,listofOffboared:any[]): Promise<any>{
//   let userData=this.privilegeService.getUserData();
//   let LoginUser= userData.emailId;
  
//   return new Promise<any>((resolve, reject) => {
//        this.contractService.GetContractRecipientsById(contractId,sponsorId,supplierId,engagementId).subscribe(
//            async res => {
//              if (res?.data) {
//               this.recipientsData=res?.data;
//               let teamType=0;
//               if(contractId!=0)
//                 {
//                   teamType=1;
//                   let sponsorRecipients= this.getFilteredEmails("TEM",this.recipientsData.sponsorRecipients);
//                   let supplierRecipients= this.getFilteredEmails("TEM",this.recipientsData.supplierRecipients)
//                   let mailRecipients = sponsorRecipients+","+supplierRecipients+','+this.recipientsData.contractSponsorOwner+','+this.recipientsData.contractSupplierOwner+','+LoginUser;
//                   let mailRecipientsArray = mailRecipients.split(',').filter(email => email.trim() !== '');
//                   this.finalMailRecipientsArray = [...new Set(mailRecipientsArray)];
//                 }
//                else{
//                 teamType=2;
//                 let sponsorRecipients= this.getFilteredEmails("TEM",this.recipientsData.sponsorRecipients);
//                 let supplierRecipients= this.getFilteredEmails("TEM",this.recipientsData.supplierRecipients);
//                 let sponsorMsaRecipients= this.getFilteredEmails("NA",this.recipientsData.msaSponsorContacts);
//                 let supplierMsaRecipients= this.getFilteredEmails("NA",this.recipientsData.msaSupplierContacts);
//                 let mailRecipients = sponsorRecipients+","+supplierRecipients+','+sponsorMsaRecipients+','+supplierMsaRecipients+','+LoginUser;
//                 let mailRecipientsArray = mailRecipients.split(',').filter(email => email.trim() !== '');
//                 this.finalMailRecipientsArray = [...new Set(mailRecipientsArray)];
//                }
//                    if(this.finalMailRecipientsArray.length>0)
//                    {
//                     let listofOffboaredTable=this.prepareOffboardedTable(listofOffboared,teamType);
//                        let payloadData= {
//                            "product": "KontractPro",
//                            "module": "Team",
//                            "data": {
//                                "subject":subject,
//                                "startDate":payloadDataObject.StartDate,
//                                "endDate":payloadDataObject.EndDate,
//                                "contractTitle":res.data.contractTitle,
//                                "engagementName":payloadDataObject.EngagementName,
//                                "PersonnelTurnover":payloadDataObject.PersonnelTurnover,
//                                "onboardassociates":payloadDataObject.OnboardAssociates,
//                                "offboardedassociates":payloadDataObject.OffboardedAssociates,
//                                "numberofassociatesonlastday":payloadDataObject.TotalCountOnEndDate,
//                                "listoffboardedassociates":listofOffboaredTable,
//                            }, 
//                        "recipients": this.finalMailRecipientsArray
//                        }
//                        this.sendEmailNotification(payloadData).subscribe(res => {
//                              resolve(true);                           
//                        });
//                      resolve(true);       
//                    }
//                    else{
//                      resolve(true);                    
//                    }
//            }
//            else{
//                resolve(true);
//            }
//        });
              
//      });
  
// }

async PersonnelTurnoverNotification(contractId:any,payloadDataObject:any,supplierId:number,sponsorId:number,engagementId:any,subject:any,listofOffboared:any[]): Promise<any>{
  let userData=this.privilegeService.getUserData();
  let LoginUser= userData.emailId;
  return new Promise<any>((resolve, reject) => {
       this.contractService.GetContractRecipientsById(contractId,sponsorId,supplierId,engagementId).subscribe(
           async res => {
             if (res?.data) {
              this.recipientsData=res?.data;
              let teamType=0;
               teamType = (contractId != 0) ? 1 : 2;
                   if(LoginUser!=null && LoginUser!="")
                   {
                    let listofOffboaredTable=this.prepareOffboardedTable(listofOffboared,teamType);
                       let payloadData= {
                           "product": "KontractPro",
                           "module": "Team",
                           "data": {
                               "subject":subject,
                               "startDate":payloadDataObject.StartDate,
                               "endDate":payloadDataObject.EndDate,
                               "contractTitle":res.data.contractTitle,
                               "engagementName":payloadDataObject.EngagementName,
                               "PersonnelTurnover":payloadDataObject.PersonnelTurnover,
                               "onboardassociates":payloadDataObject.OnboardAssociates,
                               "offboardedassociates":payloadDataObject.OffboardedAssociates,
                               "numberofassociatesonlastday":payloadDataObject.TotalCountOnEndDate,
                               "listoffboardedassociates":listofOffboaredTable,
                           }, 
                     "recipients": [LoginUser]
                       }
                     
                       this.sendEmailNotification(payloadData).subscribe(res => {
                             resolve(true);                           
                       });
                     resolve(true);       
                   }
                   else{
                     resolve(true);                    
                   }
           }
           else{
               resolve(true);
           }
       });
              
     });
  
}
kpiData:any;
kpiDataCollectionDetail:any;
kpiDetail:any;
async KPIThresholdNotification(contractKPIId:any,kpiInfo:any,engagementKPIId:any=0,sponsorId:number=0,supplierId:number=0): Promise<any>{
  return new Promise<any>(async (resolve, reject) => {
    this.kpiData=null;
    this.kpiDataCollectionDetail=[]//null;
    this.kpiDetail=null;
    if(engagementKPIId>0)
    {
    
      this.contractService.GetContractRecipientsById(0,sponsorId,supplierId,kpiInfo.engagementId).subscribe(
        async res => {
          if (res?.data) {
            this.recipientsData=res?.data;
            var sponsorRecipients= this.getFilteredEmails("KPI",this.recipientsData.sponsorRecipients);
            var supplierRecipients= this.getFilteredEmails("KPI",this.recipientsData.supplierRecipients);
            var sponsorMsaRecipients= this.getFilteredEmails("NA",this.recipientsData.msaSponsorContacts);
            var supplierMsaRecipients= this.getFilteredEmails("NA",this.recipientsData.msaSupplierContacts);
            var mailRecipients = sponsorRecipients+","+supplierRecipients+','+sponsorMsaRecipients+','+supplierMsaRecipients;
            var mailRecipientsArray = mailRecipients.split(',').filter(email => email.trim() !== '');
            this.finalMailRecipientsArray = [...new Set(mailRecipientsArray)];
           
            this.engagementKpiService.GetEngagementKPIDetailsById(engagementKPIId).subscribe( 

        async res=>{
        
          
          this.kpiData=(res.data.length>0)?res.data[0]:res.data;
                            this.kpiDataCollectionDetail=this.kpiData.engagementKPIDataCollection;
                            this.kpiDetail=(this.kpiData.kpiDetails.length>0?this.kpiData.kpiDetails[0]:null);
                          
                            
                              // add logic to filter the currently edited KPI Data Collection rows only 
                            
                             let filteredKPIDataCollection = this.kpiDataCollectionDetail?.filter((obj1: { id:any  }) => {
                              return kpiInfo?.kpiDataCollectionEdited?.some((obj2: { id:any  }) => obj2.id === obj1.id);
                            });
                  
                         
                              filteredKPIDataCollection = filteredKPIDataCollection?.filter((data: { health: any; })=>data?.health=='Red');
                           




          if(filteredKPIDataCollection.length>0)
          {

              if(this.finalMailRecipientsArray?.length>0)
              {
                var table=this.getTableHtml(kpiInfo,this.kpiDetail,this.kpiDataCollectionDetail);
                
                
                await filteredKPIDataCollection?.forEach(async (dataCollection: any) => {  
                var dataCollectionFrequencyValue=  kpiInfo?.kpiDataCollection?.find((data: { id: any; })=>data.id==dataCollection.id)?.dataCollectionFrequencyValue;
                    
                var basicInfoTable=this.tableFormartNotificationService.getTableHTML_KPI_Engagement(kpiInfo,dataCollectionFrequencyValue,dataCollection,this.kpiDetail,'Engagement');
                
                
                var payloadData= {
                                  "product": "KontractPro",
                                  "module": "Governance",
                                  "data": {
                                      "subject":"Engagement KPI Below Threshold",
                                      "kpiName": kpiInfo?.kpiName,  
                                      "contractTitle":"",
                                      "engagementName": kpiInfo?.engagementName,
                                      "kpiDataCollectionDate":dataCollectionFrequencyValue,
                                      "calculatedKPI": dataCollection?.calculatedKPI,
                                      "kpiDataCollectionHealth":table,
                                      "unitOfMeasureCode":this.kpiDetail?.unitOfMeasureCode,
                                     "kpiInformation":basicInfoTable
                                  }, 
                              "recipients": this.finalMailRecipientsArray
                  }

                                 this.sendEmailNotification(payloadData).subscribe(res => {
                                  if(res?.code==200)
                                  {
                                  }
                                  else{
                                  }
                                  resolve(true);                           
                            });
                });
                resolve(true);  
              }
              else{
                resolve(true);                    
              }
          }
          else{
            resolve(true);                    
           }
        }
      );
        }
        else{
            resolve(true);
        }
    });





    }
    else{
      resolve(true);
    }
     if(contractKPIId>0){
      // 
                   this.contractService.GetContractRecipientsById(kpiInfo.contractId,sponsorId,supplierId).subscribe(
                    async res => {
                      if (res?.data) {
                        this.recipientsData=res?.data;
                        var sponsorRecipients= this.getFilteredEmails("KPI",this.recipientsData.sponsorRecipients);
                        var supplierRecipients= this.getFilteredEmails("KPI",this.recipientsData.supplierRecipients)
                        const mailRecipients = sponsorRecipients+","+supplierRecipients+','+this.recipientsData.contractSponsorOwner+','+this.recipientsData.contractSupplierOwner;
                        var mailRecipientsArray = mailRecipients.split(',').filter(email => email.trim() !== '');
                        this.finalMailRecipientsArray = [...new Set(mailRecipientsArray)];
                        this.contractKpiService.GetContractKPIDetailsById(contractKPIId).subscribe( 
                          async res=>{
                            this.kpiData=(res.data.length>0)?res.data[0]:res.data;
                            this.kpiDataCollectionDetail=this.kpiData.dataCollectionList;
                            this.kpiDetail=(this.kpiData.kpiDetails.length>0?this.kpiData.kpiDetails[0]:null);
                             let filteredKPIDataCollection = this.kpiDataCollectionDetail?.filter((obj1: { id:any  }) => {
                              return kpiInfo?.kpiDataCollectionEdited?.some((obj2: { id:any  }) => obj2.id === obj1.id);
                            });
                  
                         
                              filteredKPIDataCollection = filteredKPIDataCollection?.filter((data: { health: any; })=>data?.health=='Red');
                           
                              
                            if(filteredKPIDataCollection.length>0)
                            {
                                if(mailRecipientsArray?.length>0)
                                {
                                  var table=this.getTableHtml(kpiInfo,this.kpiDetail,this.kpiDataCollectionDetail);
                                 
                                  await filteredKPIDataCollection?.forEach(async (dataCollection: any) => {  
                                    var dataCollectionFrequencyValue=  kpiInfo?.kpiDataCollection?.find((data: { id: any; })=>data.id==dataCollection.id)?.dataCollectionFrequencyValue;
                                              
                                    var basicInfoTable=this.tableFormartNotificationService.getTableHTML_KPI_Engagement(kpiInfo,dataCollectionFrequencyValue,dataCollection,this.kpiDetail,'Contract');
                
                                    var payloadData= {
                                                    "product": "KontractPro",
                                                    "module": "Governance",
                                                    "data": {
                                                        "subject":"Contract KPI Below Threshold",
                                                        "kpiName":kpiInfo?.kpiName,  
                                                        "contractTitle": kpiInfo?.contractTitle,
                                                        "engagementName": kpiInfo?.engagementName,
                                                        "kpiDataCollectionDate":dataCollectionFrequencyValue,
                                                        "calculatedKPI": dataCollection?.calculatedKPI,
                                                        "kpiDataCollectionHealth": table,
                                                        "unitOfMeasureCode":this.kpiDetail?.unitOfMeasureCode,
                                                        "kpiInformation":basicInfoTable
                                                      }, 
                                                    "recipients": this.finalMailRecipientsArray
                                                }
                                                this.sendEmailNotification(payloadData).subscribe(res => {
                                                    if(res?.code==200)
                                                    {
                                                    }
                                                    else{
                                                    }
                                                  resolve(true);                           
                                              });
                                    });
                                  resolve(true);  
                                }
                                else{
                                  resolve(true);                    
                                }
                            }
                            else{
                              resolve(true);                    
                             }
                          }
                        );
                    }
                    else{
                        resolve(true);
                    }
                });

      

      resolve(true);        
    }
    else
    {
      resolve(true);
    }

  });
  
}



filteredKPIDataCollection:any
previousOwnerEmail:string="";
async KpiMitigationNotification(contractKPIId:any,mitigationDataCollection:any,engagementKPIId:any=0,sponsorId:number=0,supplierId:number=0): Promise<any>{
  return new Promise<any>(async (resolve, reject) => {
    this.kpiData=null;
    this.kpiDataCollectionDetail=[];
    this.kpiDetail=null;
    if(engagementKPIId>0)
    {
      this.previousOwnerEmail="";
      this.contractService.GetContractRecipientsById(0,sponsorId,supplierId,mitigationDataCollection[0].engagementId).subscribe(
        async res => {
          if (res?.data) {
            this.recipientsData=res?.data;
            let sponsorRecipients= this.getFilteredEmails("KPI",this.recipientsData.sponsorRecipients);
            let supplierRecipients= this.getFilteredEmails("KPI",this.recipientsData.supplierRecipients);
            let sponsorMsaRecipients= this.getFilteredEmails("NA",this.recipientsData.msaSponsorContacts);
            let supplierMsaRecipients= this.getFilteredEmails("NA",this.recipientsData.msaSupplierContacts);
            let mailRecipients = sponsorRecipients+","+supplierRecipients+','+sponsorMsaRecipients+','+supplierMsaRecipients;
            let mailRecipientsArray = mailRecipients.split(',').filter(email => email.trim() !== '');
            this.finalMailRecipientsArray = [...new Set(mailRecipientsArray)];
              if(this.finalMailRecipientsArray?.length>0)
              {
                
                await mitigationDataCollection?.forEach(async (dataCollection: any) => {  
                  const mailSubject=dataCollection.id==0?"Mitigation Action for Engagement KPI Added":"Mitigation Action for Engagement KPI Closed";
                  if(this.previousOwnerEmail!="")
                  {
                    const emailIndex = this.finalMailRecipientsArray.indexOf(this.previousOwnerEmail);
                    if (emailIndex > -1) {
                      this.finalMailRecipientsArray.splice(emailIndex, 1);
                    }
                    this.finalMailRecipientsArray.push(dataCollection.ownerEmail);
                    this.previousOwnerEmail=dataCollection.ownerEmail;
                  }
                  else{
                    this.finalMailRecipientsArray.push(dataCollection.ownerEmail);
                    this.previousOwnerEmail=dataCollection.ownerEmail;
                  }
                    
                            let payloadData= {
                                  "product": "KontractPro",
                                  "module": "Governance",
                                  "data": {
                                      "subject":mailSubject,
                                      "mitigationactionstatus": dataCollection?.mitigationactionstatus,  
                                      "contractTitle":dataCollection?.contractTitle,
                                      "engagementName": dataCollection?.engagementName,
                                      "kpiName": dataCollection?.kpiName,
                                      "Datafor":dataCollection?.Datafor,
                                      "kpiValue":dataCollection?.kpiValue,
                                      "Actionfieldvalue":dataCollection?.Actionfieldvalue,
                                  }, 
                              
                              "recipients": Array.from(new Set(this.finalMailRecipientsArray))
                                         }
                                 this.sendEmailNotification(payloadData).subscribe(res => {
                                  resolve(true);                           
                            });
                });
                resolve(true);  
              }
              else{
                resolve(true);                    
              }
        }
        else{
            resolve(true);
        }
    });
    }
    else{
      resolve(true);
    }
     if(contractKPIId>0){
      this.previousOwnerEmail="";
                   this.contractService.GetContractRecipientsById(mitigationDataCollection[0].contractId,sponsorId,supplierId).subscribe(
                    async res => {
                      if (res?.data) {
                        this.recipientsData=res?.data;
                        let sponsorRecipients= this.getFilteredEmails("KPI",this.recipientsData.sponsorRecipients);
                        let supplierRecipients= this.getFilteredEmails("KPI",this.recipientsData.supplierRecipients)
                        const mailRecipients = sponsorRecipients+","+supplierRecipients+','+this.recipientsData.contractSponsorOwner+','+this.recipientsData.contractSupplierOwner;
                        let mailRecipientsArray = mailRecipients.split(',').filter(email => email.trim() !== '');
                        this.finalMailRecipientsArray = [...new Set(mailRecipientsArray)];
                        if(this.finalMailRecipientsArray?.length>0)
                          {
                           
                            await mitigationDataCollection?.forEach(async (dataCollection: any) => {  
                              const mailSubject=dataCollection.id==0?"Mitigation Action for Contract KPI Added":"Mitigation Action for Contract KPI Closed";
                              if(this.previousOwnerEmail!="")
                                {
                                  const emailIndex = this.finalMailRecipientsArray.indexOf(this.previousOwnerEmail);
                                  if (emailIndex > -1) {
                                    this.finalMailRecipientsArray.splice(emailIndex, 1);
                                  }
                                  this.finalMailRecipientsArray.push(dataCollection.ownerEmail);
                                  this.previousOwnerEmail=dataCollection.ownerEmail;
                                }
                                else{
                                  this.finalMailRecipientsArray.push(dataCollection.ownerEmail);
                                  this.previousOwnerEmail=dataCollection.ownerEmail;
                                }
                                        let payloadData= {
                                              "product": "KontractPro",
                                              "module": "Governance",
                                              "data": {
                                                  "subject":mailSubject,
                                                  "mitigationactionstatus": dataCollection?.mitigationactionstatus,  
                                                  "contractTitle": dataCollection?.contractTitle,
                                                  "engagementName": dataCollection?.engagementName,
                                                  "kpiName": dataCollection?.kpiName,
                                                  "Datafor":dataCollection?.Datafor,
                                                  "kpiValue":dataCollection?.kpiValue,
                                                  "Actionfieldvalue":dataCollection?.Actionfieldvalue,
                                              }, 
                                         "recipients": Array.from(new Set(this.finalMailRecipientsArray))
                                                     }
                                             this.sendEmailNotification(payloadData).subscribe(res => {
                                              resolve(true);                           
                                        });
                            });
                            resolve(true);  
                          }
                                else{
                                  resolve(true);                    
                                }
                          
                    }
                    else{
                        resolve(true);
                    }
                });

      

      resolve(true);        
    }
    else
    {
      resolve(true);
    }

  });
  
}
//end

async UpdateAccuralNotification(payloadDataObject:any,mailType:any): Promise<any>{
 
  return new Promise<any>((resolve, reject) => {
       this.contractService.GetContractRecipientsById(payloadDataObject.ContractId,payloadDataObject.SponsorId,payloadDataObject.SupplierId).subscribe(
           async res => {
             if (res?.data) {
               let sponsorRecipients= this.getFilteredEmails("FIN",res.data.sponsorRecipients);
               let supplierRecipients= this.getFilteredEmails("FIN",res.data.supplierRecipients)
               const mailRecipients = sponsorRecipients+","+supplierRecipients+','+res.data.contractSponsorOwner+','+res.data.contractSupplierOwner;
               let mailRecipientsArray = mailRecipients.split(',').filter(email => email.trim() !== '');
               mailRecipientsArray = [...new Set(mailRecipientsArray)];
               let subject=mailType==1?"Draft Invoices Added":"Draft Invoices Approved";
                   if(mailRecipientsArray.length>0)
                   {
                       let payloadData= {
                           "product": "KontractPro",
                           "module": "Finance",
                           "data": {
                               "subject":subject,
                               "accrualamount": payloadDataObject.AccrualAmount,
                               "accrualmonthyear":payloadDataObject.AcrualMonthYear,
                               "contractTitle": res.data.contractTitle,
                               "engagementName":payloadDataObject.EngagementName,
                              
                           }, 
                       "recipients": mailRecipientsArray
                       }
                       this.sendEmailNotification(payloadData).subscribe(res => {
                             resolve(true);                           
                       });
                     resolve(true);       
                   }
                   else{
                     resolve(true);                    
                   }
           }
           else{
               resolve(true);
           }
       });
              
     });
  
}





getFilteredEmails(notificationCode: string,sponsorRecipients:any): string {
  if(sponsorRecipients!=null)
  {
  const emails= sponsorRecipients
    .filter((recipient: { notificationCode: string; }) => recipient.notificationCode === notificationCode)
    .map((recipient: { email: any; }) => recipient.email);
    const distinctEmails = Array.from(new Set(emails));
    return distinctEmails.join(',');
    }
    else
    return "";
}

getTableHtml(collectiondata:any,kpidetails:any,latestKpIDataCollection:any): string {
  collectiondata.kpiDataCollection.sort((a: { dataCollectionDate: string | number | Date; }, b: { dataCollectionDate: string | number | Date; }) => new Date(b.dataCollectionDate).getTime() - new Date(a.dataCollectionDate).getTime());
  if (collectiondata.kpiDataCollection.length > 10) {
    collectiondata.kpiDataCollection = collectiondata.kpiDataCollection.slice(0, 10);
  }
  let html = `
    <table border="1" style="border-collapse: collapse; width: 100%;">
      <thead>
        <tr>
          <th>Data for</th>
          <th>Attribute 1 (${collectiondata?.attribute1|| '-'})</th>
          <th>Attribute 2 (${collectiondata?.attribute2|| '-'})</th>
          <th>Attribute 3 (${collectiondata?.attribute3|| '-'})</th>
          <th>Calculated KPI</th>
          <th>Remarks</th>
          <th>Health</th>
        </tr>
      </thead>
      <tbody>
  `;

  collectiondata?.kpiDataCollection.forEach(async (row: any) => {
   
    html += `
     <tr>
          <td>${row.dataCollectionFrequencyValue}</td>
          <td>${row.attribute1 || '-'}</td>
          <td>${row.attribute2 || '-'}</td>
          <td>${row.attribute3 || '-'}</td>
           <td>${row.calculatedKPI != null ? row.calculatedKPI+''+kpidetails?.unitOfMeasureCode : '-'}</td>
          <td>${row.remarks || '-'}</td>
          <td style="text-align: center;">

          <!--[if mso]>
          <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" style="height:16px; width:16px; v-text-anchor:middle;" arcsize="50%" fillcolor="${latestKpIDataCollection?.find((data: { id: any; })=>data.id==row.id)?.health}" stroke="f">
            <w:anchorlock/>
            <center style="color:#ffffff; font-family:sans-serif; font-size:16px;">•</center>
          </v:roundrect>
          <![endif]-->
          <![if !mso]>
          <div style="width: 12px; height: 12px; background-color: ${latestKpIDataCollection?.find((data: { id: any; })=>data.id==row.id)?.health}; border-radius: 50%; margin: auto;"></div>
          <![endif]>

        </td>
        </tr>
    `;
  //}
//);

 

 
  })
  html += `
  </tbody>
</table>
`;
  return html;
}


//Turnover  table preparation
prepareOffboardedTable(listofOffboared: any[], teamType: number): string {
  if(listofOffboared.length!=0)
  {
  let html = `
    <table border="1" style="border-collapse: collapse; width: 100%;">
      <thead>
        <tr>
          <th>Associate Name</th>
          <th>Onboarding Date</th>
          <th>Offboarding Date</th>
          <th>Offboarding Reason</th>
          <th>Role</th>
          ${teamType === 2 ? '<th>Contract</th>' : ''}
        </tr>
      </thead>
      <tbody>
  `;

  listofOffboared.forEach((row: any) => {
    html += `
      <tr>
        <td style="text-align: center;">${row.associateName}</td>
        <td style="text-align: center;">${this.datePipe.transform(row.onBoardingDate, "dd-MMM-yy")}</td>
        <td style="text-align: center;">${this.datePipe.transform(row.offBoardingDate, "dd-MMM-yy")}</td>
        <td style="text-align: center;">${row.offboardingReason||''}</td>
        <td style="text-align: center;">${row.role}</td>
        ${teamType === 2 ? `<td style="text-align: center;">${row.contractName}</td>` : ''}
      </tr>
    `;
  });

  html += `
      </tbody>
    </table>
  `;
  return html;
}
else{
  return "No Associates were offboarded in the selected Date Range.";
}
  
}



}



