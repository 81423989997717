import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ContractService } from 'src/app/Contracts/Services/contract.service';
import { ForecastAttachments } from 'src/app/Finance/Models/contract-forecast';
import { FinanceService } from 'src/app/Finance/Services/invoice.service';
import { LoaderService } from 'src/app/Loader/loader.service';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';

import { Dateformat } from 'src/app/shared/constants/global.constants';
import { Privileges } from 'src/app/shared/constants/privileges.constants';
import { CurrencyService } from 'src/app/Reusable/currency.service';
import { FileUploadService } from 'src/app/Engagement/Services/fileupload.service';
import { TabAndStateService } from 'src/app/shared/TabAndStateService/TabAndState.Service';

@Component({
  selector: 'app-con-forecast-view',
  templateUrl: './con-forecast-view.component.html',
  styleUrls: ['./con-forecast-view.component.css']
})
export class ConForecastViewComponent {

  @Output() editButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelForecastButtonClick = new EventEmitter<void>();


  @Input() contractId: any;
  @Input() forecastId: any;

  forecastDetail:any;
  _dateformart :any;
  selectedOption: string = 'Actions';
  editPrivilege:boolean=false;
  currentIndex: number = -1; 
  listOfForecasts:any;
  constructor(private privilegeService: PrivilegeService,private route: ActivatedRoute,private router:Router,private loaderService: LoaderService,private datePipe: DatePipe,private financeService:FinanceService,private messageService: MessageService, private contractService: ContractService,private _fileService: FileUploadService, private masterDataService: SponserService,private currencyService: CurrencyService, private tabAndStateService: TabAndStateService ){
  }
  
  attchement:any
  currency:any;
  forecastAttachment:any;
  forecastAttachments: ForecastAttachments[]=[];
  updatedBy:any;
  remarks:any;
   ngOnInit() {
   this.BindData(this.forecastId);
    this._dateformart=Dateformat;
    this.BindPrivileges();
    this.listOfForecasts=this.tabAndStateService.getForecasetData();
    this.currentIndex = this.listOfForecasts.findIndex((a: { forecastDate: any; }) => a.forecastDate ==this.forecastDetail.forecastDate);
}

BindData(data:any)
{
  this.forecastDetail =data;
    this.remarks = this.forecastDetail.remarks ? this.forecastDetail.remarks.replace(/\n/g, "<br>") : this.forecastDetail.remarks;
    this.forecastAttachment= new ForecastAttachments();
    this.currency= this.route.snapshot.queryParamMap.get('currency');
    if(this.forecastDetail.id!=0)
    {
      //if(this.forecastDetail.forecastedAmount==0)
      if(this.forecastDetail?.updatedBy==null)
          this.forecastDetail.forecastedAmount=null;
      this.forecastDetail.contractDetailId=this.contractId;
      
    this.updatedBy=this.privilegeService.getUserName(this.forecastDetail.updatedBy)

      if(this.forecastDetail.financeContractForecastAttachments == null){
        this.forecastDetail.financeContractForecastAttachments.push(this.forecastAttachment)
      }
      else if(this.forecastDetail.financeContractForecastAttachments.length>0){
        this.forecastAttachment.fileName=this.forecastDetail.financeContractForecastAttachments[0].fileName;
        this.forecastAttachment.filePath=this.forecastDetail.financeContractForecastAttachments[0].filePath;
     }

    }
    else{

      this.updatedBy=null;
      this.forecastDetail.forecastedAmount=null;
      this.forecastDetail.contractDetailId=this.contractId;
      if(this.forecastDetail.financeContractForecastAttachments == null){
        this.forecastDetail.financeContractForecastAttachments=this.forecastAttachments;
        this.forecastDetail.financeContractForecastAttachments.push(this.forecastAttachment)
      }
     
    }

}




BindPrivileges()
  {
    const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);
    this.editPrivilege = hasPermission(Privileges.EditForecasts);
  }
GetContractForecastDetailById() {
  if(this.forecastDetail.id>0){
    this.financeService.GetContractForecastDetailById(this.forecastDetail.id).subscribe(
      res=>{
        var forecastData=res.data;
      }
    );
  }
};


fileDetail:any;
async downloadFile(fileName:any,filePath:any){
 this.loaderService.showLoader();
  var fileDetail = {
    "fileName":fileName,
    "filePath":filePath
 };
 this._fileService.DownloadFile(fileDetail).subscribe(
    async res=>{
        var blobData = this._fileService.dataURItoBlob(res);
        if(blobData.size>0){
           var response = await this._fileService.downloadBase64File(blobData,fileDetail.fileName);
           this.loaderService.hideLoader();
        }
        else{
          this.loaderService.hideLoader();
          this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Sorry, unable to download the file', detail: '' });
        }
    } 
 );

}


onOptionChange(): void {//Click on Actions button navigate to edit  
  if (this.selectedOption === 'Edit') {
    var contractId= this.contractId;
    var forecastId=this.forecastDetail.id.toString();
    const data = {
      contractId:this.contractId,
      forecastId: this.forecastDetail
    };
    this.editButtonClick.emit(data);
  }    
}
BackToList()
{
  this.cancelForecastButtonClick.emit();
}
getFormattedValue(value: number, currencyName: string): string {
  return this.currencyService.getFormattedValue(value, currencyName);
}

navigateTo(index: number): void {
  if (index >= 0 && index < this.listOfForecasts.length) {
    this.forecastDetail=this.listOfForecasts[index];
    this.BindData(this.forecastDetail);
    this.currentIndex=index;
  }
}
nextRecord(): void {
  this.navigateTo(this.currentIndex + 1);
}
previousRecord(): void {
  this.navigateTo(this.currentIndex - 1);
}
getCurrentPositionText(): string {
  return `${this.currentIndex + 1} of ${this.listOfForecasts.length}`;
}

}
