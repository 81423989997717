import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { HttpParams } from '@angular/common/http';
import { ContractDetails } from "../Models/contract-details";
import { environment } from "src/environments/environment";
import {map} from 'rxjs/operators'
import { AuthCommonService } from "src/app/shared/Authentication/auth.service";



@Injectable({

  providedIn: 'root'

})

export class ContractService{
    readonly _contractApiBaseURl?:string="";
    readonly _engagementApiBaseURl?:string="";
    readonly _fileApiBaseURl?:string="";
  constructor(private http: HttpClient,private authCommonService: AuthCommonService) { 
    this._contractApiBaseURl=environment?._contractApiBaseURl;
    this._engagementApiBaseURl=environment?._engagementApiBaseURl;
    this._fileApiBaseURl=environment?._fileApiBaseURl;
  }

  public saveContractData(contractData:ContractDetails): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.post<any>(this._contractApiBaseURl +  '/AddContractDetails', contractData,httpOptions);
  }

  public CheckContractIdUniqueness(contractId:string,editedId:number): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.get<any>(this._contractApiBaseURl +  '/CheckContractIdUniqueness?contractId=' + contractId + '&editedId=' + editedId, httpOptions);
  }


  public GetAllContracts(engagementId:number,engagementIds:number[]): Observable<any> {
    if(engagementId==0)
    { 
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': this.authCommonService.getAuthenticationToken() 
    });
    const params = { groupIds: engagementIds?.join(',') };
    const options = { params: params, headers: headers };
     return this.http.get<any>(this._contractApiBaseURl +  '/GetAllContractsByGroups',options);
     }
     else
     {
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
      return this.http.get<any>(this._contractApiBaseURl +  '/GetAllContractsByEngagementId?engagementId='+engagementId, httpOptions);
     }
  }

  public GetAllContract(supplierId?:number): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    if(supplierId==0)
    { 
      return this.http.get<any>(this._contractApiBaseURl +  '/GetAllContracts', httpOptions);
     }
     else
     {
      return this.http.get<any>(this._contractApiBaseURl +  '/GetAllContractsBySupplierId?supplierId='+supplierId, httpOptions);
     }
  }

  public GetAllContractsByGroups(supplierIds:number[]): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'ApiKey': this.authCommonService.getAuthenticationToken() // Assuming getToken() returns a string
  });
  
    const params = { groupIds: supplierIds?.join(',') };

    const options = { params: params, headers: headers };
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.get<any>(this._contractApiBaseURl +  '/GetAllContractsByGroups',options);
  }
 
  public GetContractById(id:number): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.get<any>(this._contractApiBaseURl +  '/GetContractById?id=' + id, httpOptions);
  }

  public UpdateContractDetails(contractData:ContractDetails): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
    return this.http.put<any>(this._contractApiBaseURl +  '/UpdateContractDetails', contractData,httpOptions);

  }
  GetContractDtailsById(id: any)
  {
   const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.get<any>(this._contractApiBaseURl + '/GetContractDetailsById?contractId='+id, httpOptions);

  }
  InActivateContractsUnderSupplierId(id: any,statusMappingId:any,status:any){
    // Create HttpHeaders directly
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'ApiKey': this.authCommonService.getAuthenticationToken() // Assuming getToken() returns a string
  });
  // Create HttpParams
  let params = new HttpParams().set("id", id).set("statusMappingId", statusMappingId).set("status", status);
  // Correctly structured options object
  const options = { params: params, headers: headers };
  return this.http.get<any>(this._contractApiBaseURl + '/InActivateContractsUnderSupplierId', options);
    }



    public GetAllContractByEngagementId(engagementId?:number): Observable<any> {
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
      if(engagementId==0)
      { 
        return this.http.get<any>(this._contractApiBaseURl +  '/GetAllContracts', httpOptions);
       }
       else
       {
        return this.http.get<any>(this._contractApiBaseURl +  '/GetAllContractsByEngagementId?engagementId='+engagementId, httpOptions);
       }
    }
  

    UpdateContractStatus(engagementId: any,statusMappingId:any)
    {
     const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
      return this.http.get<any>(this._contractApiBaseURl + '/UpdateContractStatus?engagementId='+engagementId +"&statusMappingId=" + statusMappingId, httpOptions);
  
    }

    public GetAllEngagementsContractsBySupplierId(supplierId?:number): Observable<any> {
        
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
        return this.http.get<any>(this._contractApiBaseURl +  '/GetAllEngagementsContractsBySupplierId?supplierId='+supplierId, httpOptions);
       
    }


    public GetAllEngagementsContractsBySponsorId(sponsorId?:number): Observable<any> {
        
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
      return this.http.get<any>(this._contractApiBaseURl +  '/GetAllEngagementsContractsBySponsorId?sponsorId='+sponsorId, httpOptions);
     
  }


  GetContractRecipientsById(id: number,sponsorId:number,supplierId:number,engagementId:number=0){
    // Create HttpHeaders directly
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'ApiKey': this.authCommonService.getAuthenticationToken() // Assuming getToken() returns a string
  });
   if(id!=0)
    { 
      let params = new HttpParams().set("id", id).set("sponsorId", sponsorId).set("supplierId", supplierId);
       const options = { params: params, headers: headers };
       return this.http.get<any>(this._contractApiBaseURl + '/GetContractRecipientsById', options);
    }
    else
    {
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
      return this.http.get<any>(this._engagementApiBaseURl + '/Engagement/GetEngagementRecipientsById?engagementId='+engagementId, httpOptions);
    }
    }
}
