export enum ActiveInActiveStatus {
    Active = 'Active',
    InActive = 'InActive'
  }

  export enum StatusCodes{
      All='ALL',
      Active='ATV',
      InActive='ITV',
      Expired='EXP',
      Terminated='TMD',
      Paid='PAD',
      Open='OPN',
      Approved='APP',
      Submitted='SUB',
      Rejected='REJ',
      NA='Not Applicable'

  }
  export enum Dateformat{
    GridDateFormat='dd-MMM-yyyy',
    DisplayDateTime='dd-MMM-yyyy hh:mm',
    CalenderDateFormat='dd-M-yy',
    FiletimeStamp='ddMMyyyy-hhmmss',
    DateTimeWithZone='dd-MMM-yyyy HH:mm',
    TimeStampZone='en-US',
    DisplayUTC=' UTC',
    PayloadDateTime='dd-MMM-yyyy HH:mm:ss',
    MonthYearFormat='MMMM yyyy', 
    AuditLogFormat='MM-dd-yyyy',  
 }

 export enum breadcrumnsCodes{
  HC='Home',
  CL='CodeList',
  LSP='SponsorList',
  CSP='Createsponsor',
  EVSP='EditAndViewSponser',
  LSUP='SuppliersList',
  CSUP='CreateSupplier',
  EVSUP='EditAndViewSupplier',
  LCON='Contractlist',
  CCON='Createcontract',
  EVCON='EditAndViewContract',
  CCONFS='CreatecontractfromSupplier',
  VECONFS='ViewEditcontractfromSupplier',
  SKPIC='SupplierKPICreate',
  CKPIC='ContractKPICreate',
  KPIVS='KPIViewSupplier',
  SVMET="SupplierViewMeeting",
  SEMET='EditSupplierMeeting',
  CSMET='CreatSupplierMeeting',
  CVMET="ContractViewMeeting",
  KPIDB="KPITrendDashboard",
  KPISD="KPISupplierDashboard",
  KPICD="KPIContractDashboard",
  UL="UserList",
  UU="UpdateUser",
  VU="ViewUser",
  RL="RolesList",
  VR="ViewRole",
  ER="EditRole",
  AR="AddRole",
  SCON="SupplierContractlist",
  SDAS="SupplierDashboard",
  ENGL='EngagementsList',
  CENG='CreateEngagement',
  EVENG='EditAndViewEngagement',
  ENGCONL="EngagementContractlist",
  VMSP='ViewMasterSupplier',
  SLMD='SuppliersListMasterData',
  UP='UserProfile',
  AMSD='AddMasterSupplierData',
  EMSD='EditMasterSupplierData',
  VECONFE='ViewEditcontractfromEngagement',
  EVMET="EngagementViewMeeting",
  DBH="Dashboards",
  FDB="FinanceDashboard",
  TDB="TeamsDashboard",
}
export enum FileExtension{
  PDF = 'pdf',
  XLS = 'xls',
  XLSX = 'xlsx',
  DOC = 'doc',
  DOCX = 'docx',
  ExportExtention='.xlsx',
  PPT='ppt',
  PPTX='pptx'
}


export enum CodeList{
          Tbl_Countries=0,
           Tbl_Services=1,
           Tbl_BilliingType=2,
           Tbl_Status=3,
           Tbl_Currency=4,
           Tbl_Functions=5,
           Tbl_PaymentTerms=6,
           Tbl_KPIDetails = 7,
           Tbl_DataCollectionFrequency = 8,
           Tbl_KPIReportingFrequency = 9,
           Tbl_MathematicalSymbols = 10,
           Tbl_SuppliersMasterKPIList=11,
           Tbl_ContractsMasterKPIList = 12,
           Tbl_KPIFormulas = 13,
           Tbl_ObservationGrades = 14,
            Tbl_FindingAreas=15,
            Tbl_KPIAttributeTypes=16,
            Tbl_AllocationsTypes = 17,
            Tbl_TeamAssociateRoles = 18,
            Tbl_SponsorsAsset=19,
            Tbl_NotificationOptions=20,
}

export enum CodeListStatusTypes{
  MASTS='MeetingActionStatus',
  INVSTS='Invoice Status',
  ASCTSTS='Associate Status',
}
export enum AssociateStatusCodes{
  Offboarded='OFB',
  Onboard="ONB"
}

export enum SupplierContactAuditEntityIdentifiers{
  EntityIdentifier='Supplier',
  EntityName='SupplierContacts',
  FromService='Supplier',
  EntityCreatedByName='System',
  EntityModifiedByName='System',
}

export enum SupplierAuditEntityIdentifiers{
  EntityIdentifier='Supplier',
  EntityName='SupplierProfile',
  FromService='Supplier',
  EntityCreatedByName='System',
  EntityModifiedByName='System',
}

export enum SupplierMSAAuditEntityIdentifiers{
  EntityIdentifier='Supplier',
  EntityName='SupplierMSAs',
  FromService='Supplier',
  EntityCreatedByName='System',
  EntityModifiedByName='System',
}

export enum AuditIdentifier {
  SponsorDetails = 'SPDT-00000001',
  SponsorContacts = 'SPCN-00000002',
  SupplierProfile = 'SUPR-00000003',
  SupplierMSAs = 'SUMS-00000004',
  SupplierContacts = 'SUCN-00000005',
  ContractDetails = 'CNDT-00000006',
  ContractChangeOrders = 'CNCO-00000007',
  ContractAddendums = 'CNAD-00000008',
  // SupplierKPIs = 'SUKP-00000009',
  EngagementKPIs = 'ENKP-00000009',
  SupplierMeetings = 'SUMT-00000010',
  SupplierAudits = 'SUAD-00000011',
  ContractKPIs = 'CNKP-00000012',
  ContractMeetings = 'CNMT-00000013',
  ContractInvoices='CNIN-00000014',
  ContractAccruals='CNAC-00000015',
  ContractForecasts='CNFO-00000016',
  SupplierAssociates='SUTM-00000017',
  ContractAssociates='CNTM-00000018',
  EngagementDetails='EMDT-00000001',
  EngagementSPPLIAR="EMSPPLIAR-00000002",
  EngagementSPONSOR="EMSPONSOR-00000003",
  EngagementMSA="EMMS-00000004",
  EngagementSponsorStakeholders="ESPS-00000005",
  EngagementSupplierStakeholders="ESS-00000006",
  MonthlyInvoiceEmail="CNFE-00000001",
  ContractTurnoverEmail='CNTE-00000001',
  EngagementTurnoverEmail='EMTE-00000001',
  DeleteEngagementKPI='DENK-00000001',
  DeleteContractKPI='DCOK-00000001',
  DeleteEngagementAudit='DENA-00000001',
  DeleteEngagementAssociate='DENA-00000001',
  DeleteContractAssociate='DCOA-00000001',
  DeleteContractInvoice='DCOI-00000001',
  DeleteContractMeeting='DCOM-00000001',
  DeleteEngagementMeeting='DENM-00000001',
  EngagementMeetings = 'ENMT-00000010',
  EngagementAudits='ENAD-00000011',
  EngagementAssociates='ENTM-00000017',
  UserUpdate="USUP-00000001",
  AddRole="ADRL-00000001",
  UpdateRole="UPRL-00000001",
  ContractSponsorStakeholders="CSPS-00000009",
  ContactSupplierStakeholders="CSUS-00000010",
  UpdateUser='ADUU-00000001'
}


export enum CurrencyType {
  USD = 'USD',
  INR = 'INR',
  CYN = 'CYN',
  JPY = 'JPY',
  CNY='CNY'
}

export enum CurruncyFormat {
  US = 'en-US',
  IN = 'en-IN',
  CY = 'zh-CN',
  JP = 'jp-JP',
 
}


export enum DashboardIds {
  SupplierDashboardId = '80fc9abd-b984-4b5d-ab87-64fe516a73eb',
  ContractDashboardId = '2f7fd848-2554-46ba-808f-89d2ff2e6964'
  
}

export enum AuditLogEntities {
  SponsorDetails = 'Sponsor Details',
  SponsorContacts = 'Sponsor Contacts',
  SupplierProfile = 'Supplier Profile',
  SupplierMSAs = 'Supplier MSAs',
  SupplierContacts = 'Supplier Contacts',
  ContractDetails = 'Contract Details',
  ContractChangeOrders = 'Contract Change Orders',
  ContractAddendums = 'Contract Addendums',
  SupplierKPIs = 'Supplier KPIs',
  SupplierMeetings = 'Supplier Meetings',
  SupplierAudits = 'Supplier Audits',
  ContractKPIs = 'Contract KPIs',
  ContractMeetings = 'Contract Meetings',
  ContractInvoices='Contract Invoices',
  ContractAccruals='Contract Accruals',
  ContractForecasts='Contract Forecasts',
  SupplierAssociates='Supplier Associates',
  ContractAssociates='Contract Associates',
  EngagementDetails='Engagement Detail',
  EngagementSPPLIAR="Engagement Supplier",
  EngagementSPONSOR="Engagement Sponsor",
  EngagementMSA="Engagement MSA",
  EngagementSponsorStakeholders="Engagement Sponsor Stakeholders",
  EngagementSupplierStakeholders="Engagement Supplier Stakeholders",
  EngagementKPIs = 'Engagement KPIs',
  EngagementMeetings = 'Engagement Meetings',
  EngagementAudits = 'Engagement Audits',
  EngagementAssociates='Engagement Associates',
  MonthlyInvoiceEmail='Monthly Invoice Email',
  ContractTurnoverEmail='Contract Turnover Email',
  EngagementTurnoverEmail='Engagement Turnover Email',
  UpdateUser="Update User",
  AddRole="Add Role",
  UpdateRole="Update Role",
  ContractSponsorStakeholders="Contract Sponsor Stakeholders",
  ContractSupplierStakeholders="Contract Supplier Stakeholders",
}

export enum AuditOperations{
  Create='Create',
  Update='Update',
  Delete='Delete'
}

export enum AuditLogModule {
  Contractservice='Contract service',
  FinanceService='Finance Service',
  GovernanceService='Governance Service',
  Sponsorservice='Sponsor service',
  SupplierService='Supplier Service',
  EngagementService='Engagement Service',
  AdministratorService='Administrator Service'
}


export enum FrequencyValues {
  Monthly = '[{"name": "Jan", "order": 1}, {"name": "Feb", "order": 2}, {"name": "Mar", "order": 3}, {"name": "Apr", "order": 4}, {"name": "May", "order": 5}, {"name": "Jun", "order": 6}, {"name": "Jul", "order": 7}, {"name": "Aug", "order": 8}, {"name": "Sep", "order": 9}, {"name": "Oct", "order": 10}, {"name": "Nov", "order": 11}, {"name": "Dec", "order": 12}]',
  Quarterly = '[{"name": "Q1", "order": 1}, {"name": "Q2", "order": 2}, {"name": "Q3", "order": 3}, {"name": "Q4", "order": 4}]',
  HalfYearly = '[{"name": "H1", "order": 1}, {"name": "H2", "order": 2}]',
  Annual = '[{"name": "Annual", "order": 1}]'
}
