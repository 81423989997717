import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import FileSaver from 'file-saver';
import { ContractService } from 'src/app/Contracts/Services/contract.service';
import { ContracDetails } from 'src/app/Finance/Models/contract-accrual';
import { FinanceService } from 'src/app/Finance/Services/invoice.service';
import { LoaderService } from 'src/app/Loader/loader.service';
import { CurrencyService } from 'src/app/Reusable/currency.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { Dateformat, FileExtension } from 'src/app/shared/constants/global.constants';
import { Privileges } from 'src/app/shared/constants/privileges.constants';
import { TabAndStateService } from 'src/app/shared/TabAndStateService/TabAndState.Service';
@Component({
  selector: 'app-con-forecast-list',
  templateUrl: './con-forecast-list.component.html',
  styleUrls: ['./con-forecast-list.component.css']
})
export class ConForecastListComponent {
 
  @Output() addButtonClick = new EventEmitter<void>();
  @Output() editButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() viewButtonClick: EventEmitter<any> = new EventEmitter<any>();

  @Output() childData: EventEmitter<any> = new EventEmitter<any>();
 
  @Input() contractId: any;
  @Input() forecastId: any;

  selectedContractForecast:any;
  _contractForecastListData: any[] = []
  _dateformart :any;
  
  _contractDetail= new ContracDetails();
  editPrivilege:boolean=false;
  viewPrivilege:boolean=false;
  ExportPrivilage:boolean=false;
  constructor(private privilegeService: PrivilegeService,private route: ActivatedRoute,private router:Router,private loaderService: LoaderService,private datePipe: DatePipe,private financeService:FinanceService, private contractService: ContractService,private currencyService: CurrencyService,private tabAndStateService: TabAndStateService ){
  }
  
  currency:any;
   ngOnInit() {
      
    this._dateformart=Dateformat;
    this.loaderService.showLoader();
    this.GetContractDetailsByContractId(this.contractId);//To get ContractDetails by Id
    this.loaderService.hidefinalLoader();// hide after 4 seconds even get any error
    this.BindPrivileges();
    this.currency= this.route.snapshot.queryParamMap.get('currency');
    }
  BindPrivileges()
  {
    const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);
    this.editPrivilege = hasPermission(Privileges.EditForecasts);
    this.viewPrivilege = hasPermission(Privileges.ViewForecasts);
    this.ExportPrivilage= hasPermission(Privileges.ExportContractForecast);
  }
    contractInfo:any;
    GetContractDetailsByContractId(contractId:number) {
      this.loaderService.showLoader();
      this.contractService.GetContractDtailsById(contractId).subscribe(res => {
        if (res.data != null) {
          this.contractInfo = res.data;
          if(this.contractInfo!=null && this.contractInfo!=undefined){
            this._contractDetail.contractDetailId=this.contractInfo.id;
            this._contractDetail.contractStartDate= this.datePipe.transform(this.contractInfo.startDate, "MM/dd/yyyy");
            this._contractDetail.contractEndDate=this.datePipe.transform(this.contractInfo.endDate, "MM/dd/yyyy");

          }
          this.getContractForecastByContractId(this._contractDetail);
           
        }
      });
    }

  
    getContractForecastByContractId(contractDetail:any){
      this.financeService.GetContractForecastByContractId(contractDetail).subscribe(
        response=>{
         this._contractForecastListData=response.data;
         this.tabAndStateService.setForecastData( this._contractForecastListData);
         this.loaderService.hideLoader();
        });
    }
  
  editForecast(currentForecastData:any){
    var contractId= this.contractId;
    var forecastId=currentForecastData.id.toString();
    const data = {
      contractId:this.contractId,
      forecastId: currentForecastData
    };
    this.editButtonClick.emit(data);
  }
  
  viewForecast(Id:number, currentForecastData:any){
    var contractId= this.contractId;
    var forecastId=currentForecastData.id.toString();
    const data = {
      contractId:this.contractId,
      forecastId: currentForecastData
    };
    this.viewButtonClick.emit(data);
  }

  isChecked: boolean = true;
  checkboxEnabled() {
    if (this.selectedContractForecast.length > 0) {
      this.isChecked = false;
    }
    else {
      this.isChecked = true;
    }
  }
  
  exportExcel(SelectedRecords: any) {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(SelectedRecords);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'Contract Forecast');
    });
  }
  
  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = FileExtension.ExportExtention;
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
  selectedRecordsToExpotToExcel: any[] = [];
  exportContractForecast() {
    this.selectedRecordsToExpotToExcel = this.selectedContractForecast;
    this.selectedRecordsToExpotToExcel = this.selectedRecordsToExpotToExcel.map((forecast) => {
      
      return {
      
        Month:forecast.forecastDate!=null?this.convertDateFormat(forecast.forecastDate):forecast.forecastDate,
        'Forecasted Amount': forecast.updatedBy != null ? forecast.forecastedAmount : '--',
         Attachment: forecast.financeContractForecastAttachments && forecast.financeContractForecastAttachments.length > 0 ? forecast.financeContractForecastAttachments[0].fileName : '--',
        Remarks:forecast.remarks,
        'Modified By':this.privilegeService.getUserName(forecast.updatedBy),
        'Modified Date':forecast.updatedDate!=null?this.convertGridDateFormat(forecast.updatedDate):forecast.updatedDate,
  
      };
    });
    this.exportExcel(this.selectedRecordsToExpotToExcel);
  }
  convertDateFormat(inputDateTime: string) {
    const date = new Date(inputDateTime);
    const formattedDate = this.datePipe.transform(date, this._dateformart.MonthYearFormat);
    return formattedDate;
  }

  convertGridDateFormat(inputDateTime: string) {
    const date = new Date(inputDateTime);
    const formattedDate = this.datePipe.transform(date, this._dateformart.GridDateFormat);
    return formattedDate;
  }
  getFormattedValue(value: number, currencyName: string): string {
    return this.currencyService.getFormattedValue(value, currencyName);
  }
  
}
