<div class="container page-title">
   <div class="login-wrapper">
      <div class="fr-flex__container">
         <div class="fr-flex__col-6">
            <div class="login_bg"> 
               <img class="login_bg_img" src="../../../assets/images/KontractPro_Login_bg.png" alt="Responsive Image">
            </div>
         </div>
         <div class="fr-flex__col-6">
            <div class="login-form-bg container">
               <div class="fr-flex__container m-t-50">
                  <div class="fr-flex__col-12">
                     <div class="login-form">
                        <div class="fr-flex__container">
                           <div class="fr-flex__col-12 text-center">
                              <div class="logo-with__text"> 
                                 <img class src="../../../assets/images/Kontract Pro_Logo.png" alt="" height="50">
                                 <span class="img_left_border"> <em> Freyr <span> KontractPro </span> </em>  </span>
                              </div>
                           <p class="headingtxt"> Enter your registered Email ID below to reset your password. </p>
                           </div>
                        </div>
                        <div class="fr-flex__container">
                           <div class="fr-flex__col-12">
                              <div class="fr-form__group">
                                 <div class="fr-flex__col-12">
                                    <input type="text"  class="form-control" placeholder="Email ID" pInputText [(ngModel)]="value" trimSpace />
                                    <a href="../login" class="link-text pull-right m-t-10"> Go to Login</a>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="fr-flex__container">
                           <div class="fr-flex__col-12">
                              <a href="#" class="btn-primary-block">Reset Password</a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>