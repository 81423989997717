import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MenuItem,ConfirmationService, MessageService } from 'primeng/api';
import { Router,ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';
import { LoaderService } from 'src/app/Loader/loader.service';
import { Dateformat,breadcrumnsCodes } from 'src/app/shared/constants/global.constants';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { RouteUtilsService } from 'src/app/shared/Guards/route-utils.service';
import { TabView } from 'primeng/tabview';
import { AuditChildComponents, KpiChildComponents, MeetingChildComponents } from '../../Models/Engagement';
import { forkJoin, Observable, tap } from 'rxjs';
import { IDeactivateGuard } from 'src/app/shared/Guards/can-deactivate-guard.service';
import { EngCreateAuditComponent } from 'src/app/Governance/audits/Engagements/Components/eng-create-audit/eng-create-audit.component';
import { EngEditAuditComponent } from 'src/app/Governance/audits/Engagements/Components/eng-edit-audit/eng-edit-audit.component';
import { EngCreateKpiComponent } from 'src/app/Governance/kpis/Engagement/Components/eng-create-kpi/eng-create-kpi.component';
import { EngEditKpiComponent } from 'src/app/Governance/kpis/Engagement/Components/eng-edit-kpi/eng-edit-kpi.component';
import { EngCreateMeetingComponent } from 'src/app/Governance/meetings/Engagements/Components/eng-create-meeting/eng-create-meeting.component';
import { EngEditMeetingRecYesComponent } from 'src/app/Governance/meetings/Engagements/Components/eng-edit-meeting-rec-yes/eng-edit-meeting-rec-yes.component';
import { EngEditMeetingComponent } from 'src/app/Governance/meetings/Engagements/Components/eng-edit-meeting/eng-edit-meeting.component';
import { AssociateAddEditComponent } from 'src/app/Team/teams/associate-add-edit/associate-add-edit.component';
import { TeamChildComponents } from 'src/app/Team/teams/Models/Associate';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { Privileges } from 'src/app/shared/constants/privileges.constants';
import { FileUploadService } from '../../Services/fileupload.service';
import { EngagementService } from '../../Services/engagement.service';
import { CurrencyService } from 'src/app/Reusable/currency.service';
import { DashboardService } from 'src/app/Dashboard/Services/dashboard.service';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import { EmbeddingContext } from 'amazon-quicksight-embedding-sdk/dist';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { environment } from 'src/environments/environment';
import { AuthCommonService } from 'src/app/shared/Authentication/auth.service';
import { TabAndStateService } from 'src/app/shared/TabAndStateService/TabAndState.Service';
import { ConversionRate } from 'src/app/Reusable/conversion-rate';
import { ContractService } from 'src/app/Contracts/Services/contract.service';
import { FinanceService } from 'src/app/Finance/Services/invoice.service';

@Component({
  selector: 'app-view-engagement',
  templateUrl: './view-engagement.component.html',
  styleUrls: ['./view-engagement.component.css']
})
export class ViewEngagementComponent implements  OnInit,IDeactivateGuard{
  @ViewChild('myTabView', { static: false }) tabView!: TabView;
  activeTabIndex = 0;
  @ViewChild('mySubTabView', { static: false }) subTabView!: TabView;
  @ViewChild(EngCreateAuditComponent) childAuditCreateComponent!: EngCreateAuditComponent;
  @ViewChild(EngEditAuditComponent) childAuditEditComponent!: EngEditAuditComponent;
  @ViewChild(EngCreateKpiComponent) childKpiCreateComponent!: EngCreateKpiComponent;
  @ViewChild(EngEditKpiComponent) childKpiEditComponent!: EngEditKpiComponent;
  @ViewChild(EngCreateMeetingComponent) childMeetingCreateComponent!: EngCreateMeetingComponent;
  @ViewChild(EngEditMeetingRecYesComponent) childMeetingRecYesEditComponent!: EngEditMeetingRecYesComponent;
  @ViewChild(EngEditMeetingComponent) childMeetingEditComponent!: EngEditMeetingComponent;
  @ViewChild(AssociateAddEditComponent) childTeamCreateEditComponent!: AssociateAddEditComponent;
  @ViewChild('scrollContainer', { static: false }) scrollContainer!: ElementRef;

  activeSubTabIndex = 0;
  engagementId: any;
  engagementName: any;
  engagementdata: any; 
  engagementStartDate:any;
  services: any;
  locations: any;
  supplierContacts: any;
  sponserId:any;
  sponserName:any;
  locationsDataSource: any;
  servicesDataSource:any;
  selectedOption: string = 'Actions';
  engagementMasterServiceAgreements:any;
  stakeholdersSponsors:any;
  stakeholdersSuppliers:any;
  buttonLabel:any;
  visible:boolean  = false;
  servicesToArray:any; 
  ShowServicesButton : boolean= true;
  _dateformart :any;
  breadcrumbs: MenuItem[]=[];
  selectedEngagementName:string="";
  _enumBreadcrumb :any;
  paymentTermsDataSource : any[]=[];
  previousRouteName: any;

  
  displayMSADialog: boolean =false;
  sponserContactName: any;
  functionsDataSource : any[]=[];
  msaStatusDataSource: any;
  functionsname: any=[];
  viewsuppliercontactdata: any=[];
  viewsponserdata: any=[];
  viewsponser:any=[];
  locationsToArray: any;
  ShowlocationsButton: boolean= true;
  ViewSupplier: any;
  buttonlocationsLabel:any;
  locationsvisible :boolean =false;

  _kpiChildComponents=new KpiChildComponents();
  _auditChildComponents=new AuditChildComponents();
  _meetingChildComponents=new MeetingChildComponents();
  _teamChildComponents=new TeamChildComponents();
  _currentAuditChildComponent:number=0;
  _currentKpiChildComponent:number=0;
  _currentMeetingChildComponent:number=0;
  _currentTeamChildComponent:number=0;
  auditId:any;
  kpiId:any;
  meetingId:any;
  StatusMappingId: any;
  supplierStatus: any;
  currentChildComponent:any;
  FromType=2;
  associateTeamId:any;
  editPrivilege:boolean=false;
  viewFinancePrivilege:boolean=false;
  viewTeamPrivilege: boolean = false;
  viewSupplierTeams: boolean = false;
  TeamsDashboardPrivilage:boolean=false;
  createdBy: any;
  updatedBy: any;
  engagementContractsGroups:any;
  _TabId:any=0;
  isContractTab=false;
  currentTab:any;
  selectedCurrency:any;
  currenciesData : any[] = [];
  conversionRate=new ConversionRate();
  
  SupplierId: any;
  SponsorId:any;


  constructor(private privilegeService: PrivilegeService,
    private route: ActivatedRoute,
    private engagementService: EngagementService,
    private datePipe: DatePipe,
    private confirmationService: ConfirmationService, 
    private messageService: MessageService,
    private fileUploadService: FileUploadService,
    private router:Router,
    private loaderService: LoaderService,
    private breadcrumbService: BreadcrumbService ,
    private routeUtils: RouteUtilsService,
      private currencyService: CurrencyService, private tabAndStateService: TabAndStateService, private _dashboaredService: DashboardService, private _masterDataService: SponserService,
      private authService: AuthCommonService,private _contractService: ContractService,
      private _financeInvoiceService:FinanceService) {
    this.previousRouteName = this.routeUtils.getPreviousUrl().split('/')[1];
  }
 
  ngOnInit() {
    this.BindPrivileges();

    this._currentKpiChildComponent=1;
    this.ViewSupplier="View Engagement";
    this._enumBreadcrumb=breadcrumnsCodes;
    this.generateBreadcrumb(this._enumBreadcrumb.EVENG,"","Profile");
    this._dateformart=Dateformat;
    this.route.params.subscribe(params => {
      this.engagementId = params['id']; //log the value of id
      this._TabId= params['tabId'];
      if(this._TabId == undefined){
        this._TabId =0;
      }
    });



    this.GetEngagementDetailsById();
    this.loaderService.hidefinalLoader();// hide after 4 seconds even get any error
    this.currentTab = this.tabAndStateService.getPreviousState();
  
    this.GetCurrencyData();
  }

viewFinanceDashboard=false;
  BindPrivileges(){
      const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);
      this.editPrivilege = hasPermission(Privileges.EditEngagement);
      this.viewFinanceDashboard=hasPermission(Privileges.ViewFunctionsFinanceDashboard);
      this.TeamsDashboardPrivilage= hasPermission(Privileges.ViewFunctionsTeamsDashboard);
      this.viewFinancePrivilege= hasPermission(Privileges.ViewEngagementFinance);
    }

    BindEngagementContractsGroups(){     
      this.setDefaultCurrency();
      this.loaderService.showLoader();    
      this.engagementService.GetAllEngagementContractGroupsById(this.engagementId).subscribe(res => {
        this.engagementContractsGroups = res.data;
        this.engagementContractsGroupsCopy = res.data;
        this.GetContractList();
    
        this.engagementContractsGroups.forEach((group: { totalActiveContracts: number; totalContractedValue: number; totalOnboardAssociates: number; }) => {
          group.totalActiveContracts = group.totalActiveContracts || 0;
          group.totalContractedValue = group.totalContractedValue || 0;
          group.totalOnboardAssociates = group.totalOnboardAssociates || 0;
        });
    
        this.loaderService.hideLoader();
      });
    }    
    notes:any;
  GetEngagementDetailsById() {
    this.loaderService.showLoader();  
    if (this.engagementId != undefined && this.engagementId != null) {
      this.engagementService.ViewEngagementById(this.engagementId)
        .subscribe(res => {
          this.loaderService.hideLoader();
          this.engagementdata = res.data;
          this.SupplierId = this.engagementdata.supplierId;
          this.SponsorId = this.engagementdata.sponsorId;
  
          this.notes = this.engagementdata.notes?.replace(/\n/g, "<br>") || this.engagementdata.notes;
          this.description = this.engagementdata.engagementMasterServiceAgreements[0]?.description?.replace(/\n/g, "<br>") || this.engagementdata.engagementMasterServiceAgreements[0]?.description;
  
          this.selectedEngagementName = this.engagementdata.engagementName;
          this.engagementName = this.engagementdata.engagementName;
          this.engagementStartDate = this.engagementdata.engagementStartDate;
  
          this.createdBy = this.privilegeService.getUserName(this.engagementdata.createdBy);
          this.updatedBy = this.privilegeService.getUserName(this.engagementdata.updatedBy);
  
          this.servicesToArray = this.engagementdata.services.split(',');
          this.services = this.servicesToArray.slice(0, 2);  // Always show the first 2 services
          if (this.servicesToArray.length > 2) {
            this.buttonLabel = `+${this.servicesToArray.length - 2}`;
            this.ShowServicesButton = false;
          }
  
          this.engagementMasterServiceAgreements = this.engagementdata.engagementMasterServiceAgreements;
          this.stakeholdersSponsors = this.engagementdata.stakeholderSponsors;
          this.stakeholdersSuppliers = this.engagementdata.stakeholderSuppliers;
  
          this.generateBreadcrumb(this._enumBreadcrumb.EVENG, this.selectedEngagementName, "Profile");
  
          if (this._TabId != null) {
            this.onTabSelect({ index: parseInt(this._TabId) });
          }
  
          if (this.currentTab) {
            if (this.currentTab.tabName === "Contracts" || this.currentTab.tabName === "Team") {
              this.ParentTabSelect(this.currentTab.tabId);
              this.tabAndStateService.setPreviousState('', 0);
            }
          }
        });
    }
  }
  

  currentMSADetail:any;
  supplierContactData:any;
  sponsorContactData:any;
  description:any;
  showMSAViewpopup(index:any)
  {
    this.currentMSADetail="";
    let emptyString=", , , ,"
    if(index>=0){   
      this.currentMSADetail =this.engagementMasterServiceAgreements[index];
        if(this.currentMSADetail.supplierContact!=null)
             this.supplierContactData = this.currentMSADetail.supplierContact.split(',');
        else
          this.supplierContactData=emptyString.split(',');
        
        if(this.currentMSADetail.sponsorContact!=null)
          this.sponsorContactData = this.currentMSADetail.sponsorContact.split(',');         
          else
            this.sponsorContactData=emptyString.split(',');
      this.displayMSADialog =true;
    }
  }
  cancelDialog() {
    this.displayMSADialog = false;
  }
 
  async onOptionChange(): Promise<void> {
    try {
      if (this.selectedOption === 'Edit') {
        await this.router.navigate(['/EditEngagement', this.engagementId]); // Assuming this is asynchronous
      }
    } catch (error) {
      console.log(error);
    }
  }
showDialog()// tool tip on mouse over
{
  this.visible = !this.visible
}
hideDialog()// tool tip on mouse out
{
  this.visible = false;
 
}
generateBreadcrumb(component?:string,recordName?:string,tabName?:string)
  {
    this.breadcrumbService.generateBreadcrumb(component,recordName,tabName);
  }
  getBreadcrumbs(): MenuItem[] {
    return this.breadcrumbService.getBreadcrumbs();
  }
  tabindex:number = 0;
  Previoustabindex:number = 0
  showSummaryView:boolean=false;

  onTabSelect(event?: any) {
    this.tabAndStateService.scrollToTop(this.scrollContainer);
    this.showSummaryView = false;
    this.tabindex = event.index !== undefined ? event.index : event;
    this.toggleContractTab(this.tabindex);
    this.setActiveTabViewIndex(this.tabindex);
    
      this.CommonTabClick(this.previousSubTabIndex, this.Previoustabindex);
  
      if (this.isformObjectChange) {
        this.showConfirmationDialog();
      } else {
        this.completeTabChange();
      }
   
    
  }
  
  toggleContractTab(tabIndex: number) {
    this.isContractTab = (tabIndex === 2);
  }
  
  setActiveTabViewIndex(tabIndex: number) {
    if (this.tabView) {
      this.tabView.activeIndex = tabIndex;
    }
  }
  
  showConfirmationDialog() {
    this.confirmationService.confirm({
      message: this._message,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.acceptTabChange();
      },
      reject: () => {
        this.rejectTabChange();
      }
    });
  }
  
  acceptTabChange() {
    this.Previoustabindex = this.tabindex;
    this.isformObjectChange = false;
    this.clearFormObjects();
    this.completeTabChange();
    this.messageService.add({
      key: 'tc',
      severity: 'info',
      summary: this._detail,
      detail: '',
    });
  }
  
  rejectTabChange() {
        setTimeout(() => {
          this.tabindex=this.Previoustabindex;
          this.activeTabIndex = this.Previoustabindex;
          this.tabView.activeIndex = this.Previoustabindex;
       },250);
  }
  
  completeTabChange() {
    this.Previoustabindex = this.tabindex;
    this.isformObjectChange = false;
    this.clearFormObjects();
    this.resetallsubtabs();
    this.ParentTabSelect(this.tabindex);
  }
  
  clearFormObjects() {
    this._oldObject = "";
    this._newObject = "";
  }
  
  resetallsubtabs()
  {
    this._currentKpiChildComponent=1;
    this._currentMeetingChildComponent=1;
    this._currentTeamChildComponent=1;
    this._currentAuditChildComponent=1;
    this.subTabindex = 0;
  }
ParentTabSelect(ParentTabIndex:number)
{
 
  switch (ParentTabIndex) {
    case 0:
      this.generateBreadcrumb(this._enumBreadcrumb.EVENG,this.selectedEngagementName,"Profile");
      this.activeTabIndex = ParentTabIndex;
      this.ViewSupplier ="View Supplier";
      break;
      case 1:
        this.generateBreadcrumb(this._enumBreadcrumb.EVENG,this.selectedEngagementName,"Functions");
        this.activeTabIndex = ParentTabIndex;
        this.ViewSupplier = this.engagementdata.engagementName;
        this.BindEngagementContractsGroups();
        break;
      case 2:
      this.generateBreadcrumb(this._enumBreadcrumb.EVENG,this.selectedEngagementName,"Contracts");
      this.activeTabIndex = ParentTabIndex;
      this.ViewSupplier = this.engagementdata.engagementName;
      break;
      case 3:
      this.generateBreadcrumb(this._enumBreadcrumb.EVENG,this.selectedEngagementName,"Governance");
      this.activeTabIndex =ParentTabIndex;
      this.ViewSupplier = this.engagementdata.engagementName;
      break;
      case 4:
      this.generateBreadcrumb(this._enumBreadcrumb.EVENG,this.selectedEngagementName,"Finance");
      this.activeTabIndex =ParentTabIndex;
      this.ViewSupplier = this.engagementdata.engagementName;
      this.showSummaryView=true;
      break;
      case 5:
        this._currentTeamChildComponent=1;
        this.generateBreadcrumb(this._enumBreadcrumb.EVENG,this.selectedEngagementName,"Team");
        this.activeTabIndex = ParentTabIndex;
        this.ViewSupplier = this.engagementdata.engagementName;
        break;
}
}
  downloadAttachemnt(fileName: any, filePath: any) { // download Uploaded Attachemnets 

    this.loaderService.showLoader();

    let filedetail = {
      "fileName": fileName,
      "filePath": filePath
    };
    this.fileUploadService.DownloadFile(filedetail).subscribe(
      res => {
        let test = res;
        let blobData = this.fileUploadService.dataURItoBlob(res);
        this.fileUploadService.downloadBase64File(blobData, filedetail.fileName);
        this.loaderService.hideLoader();
      }
    );
    this.loaderService.hidefinalLoader();
  }

//Child components actions
handleAuditAddButtonClick() {
  this._currentAuditChildComponent=2;
  this.currentChildComponent=this.childAuditCreateComponent;
 }

 actionType:any;
  associateId:any;
 handleAddButtonClick(addButtonType:number) {
  switch (addButtonType) {
    case 1:
      this._currentKpiChildComponent=2;
      this.currentChildComponent=this.childKpiCreateComponent;
        break;
    case 2:
      this._currentMeetingChildComponent=2;
      this.currentChildComponent=this.childMeetingCreateComponent;
        break;

        case 4://Teams

            //team tab
            this.actionType='Create';
            this._currentTeamChildComponent=2;//Add associate
            this.currentChildComponent=this.childTeamCreateEditComponent;
             break;

 
}

 }

handleAuditCalcelAddButtonClick()
{
  this._currentAuditChildComponent=1;
}

handleAuditSaveAddButtonClick()
{
  this.clearFormCompareObjects();
  this._currentAuditChildComponent=4;
}

handleCancelAddButtonClick(cancelButtonType:number)//common cancel child compoments method
{
 
  switch (cancelButtonType) {
    case 1:
      this._currentKpiChildComponent=1;//supplier KPI
        break;
    case 2:
      this._currentMeetingChildComponent=1;//supplier Meeting list
        break;
        case 4:

        this._currentTeamChildComponent=1;//List associate
               break;
   
}
}

handleSaveAddButtonClick(saveButtonType:number)//common save method for all child components
{
  this.clearFormCompareObjects();
  switch (saveButtonType) {
    case 1:
      this._currentKpiChildComponent=4;
        break;
    case 2:
      this._currentMeetingChildComponent=4;
        break;
        case 4:
          //team tab
          this._currentTeamChildComponent=3;//View associate
          this.currentChildComponent=this.childTeamCreateEditComponent;
           break;
}
}



subTabindex:number = 0;
previousSubTabIndex=0;
  onSubTabSelect(event?: any) {
   this.tabAndStateService.scrollToTop(this.scrollContainer);
  this.subTabindex = event.index;

  this.CommonTabClick(this.previousSubTabIndex, this.Previoustabindex);

  if (this.isformObjectChange) {
    this.showSubTabConfirmationDialog();
  } else {
    this.completeSubTabChange();
  }
}

showSubTabConfirmationDialog() {
  this.confirmationService.confirm({
    message: this._message,
    header: 'Confirmation',
    icon: 'pi pi-exclamation-triangle',
    accept: () => {
      this.previousSubTabIndex = this.subTabindex;
      this.clearFormCompareObjects();
      this.completeSubTabChange();
      this.messageService.add({
        key: 'tc',
        severity: 'info',
        summary: this._detail,
        detail: '',
      });
    },
    reject: () => {
      this.rejectSubTabChange();
    }
  });
}
rejectSubTabChange() {
  this.setActiveSubTabIndex(this.previousSubTabIndex);
  setTimeout(() => {
    this.setActiveSubTabIndex(this.previousSubTabIndex);
  }, 10);
}

completeSubTabChange() {
  this.previousSubTabIndex = this.subTabindex;
  this.clearFormCompareObjects();
  this.SubTabSelect(this.subTabindex);
}

setActiveSubTabIndex(subTabIndex: number) {
  this.activeSubTabIndex = subTabIndex;
  if (this.subTabView) {
    this.subTabView.activeIndex = subTabIndex;
  }
}

  SubTabSelect(subTabIndex:number)
  {

    this.subTabView.activeIndex = subTabIndex;
    switch (subTabIndex) {
      case 0:
        this._currentKpiChildComponent=1;
       this.activeSubTabIndex = subTabIndex;
        break;
        case 1:
          this._currentMeetingChildComponent=1;
          this.activeSubTabIndex = subTabIndex;
        break;
        case 2:
          this._currentAuditChildComponent=1;
          this.activeSubTabIndex = subTabIndex;
          if(this.childAuditCreateComponent!=undefined)
            this.childAuditCreateComponent.submitted = false;
        break;
  }
  }

   _message="";
   _detail="";
  _oldObject:string="";
  _newObject:string="";
  _commonEditDetails="Changes not saved";
  _commonEditMessage="Changes will not be saved, Do you want to Continue?";
  canExist(): boolean | Promise<boolean> | Observable<boolean> {
    this.CommonTabClick(this.subTabindex, this.Previoustabindex);
  
    if (this.currentChildComponent != undefined && this.isformObjectChange) {
      return new Promise<boolean>((resolve) => {
        const handleConfirmation = (result: boolean) => {
          if (result) {
            this.messageService.add({
              key: 'tc',
              severity: 'info',
              summary: this._detail,
              detail: '',
            });
          }
          resolve(result);
        };
  
        this.confirmationService.confirm({
          message: this._message,
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: () => handleConfirmation(true),
          reject: () => handleConfirmation(false),
        });
      });
    } else {
      return true;
    }
  }
  
handleAuditEditButtonClick(data: any) {
 
  this.auditId=data.auditId;
  this._currentAuditChildComponent=3;
  this.currentChildComponent=this.childAuditEditComponent;
 }


handleEditButtonClick(data: any,editButtonType:number) {
  
  switch (editButtonType) {
    case 1:
      this.kpiId=data.kpiId;
      this._currentKpiChildComponent=3;
      this.currentChildComponent=this.childKpiEditComponent;
      break;
    case 2:
     
      this.meetingId=data.meetingId;
      if(data.isRecurring)
      {
        this._currentMeetingChildComponent=5;
        this.currentChildComponent=this.childMeetingRecYesEditComponent;
      }
      else{
        this._currentMeetingChildComponent=3;
        this.currentChildComponent=this.childMeetingEditComponent;
      }
        break;
        case 4:
          //team tab
         this._currentTeamChildComponent=2;//Add associate
         this.actionType='Edit';
         this.associateId=data.associateId;
         this.currentChildComponent=this.childTeamCreateEditComponent;
           break;
}
  
 }



 handleAuditViewButtonClick(data: any) {
   this.auditId=data.auditId;
  this._currentAuditChildComponent=4;
  this.currentChildComponent=undefined;
 }

 handleViewButtonClick(data: any,viewButtonType:number) {


  switch (viewButtonType) {
    case 1:
      this.kpiId=data.kpiId;
       this._currentKpiChildComponent=4;
       this.currentChildComponent=undefined;
        break;
    case 2:
      this.meetingId=data.meetingId;
       this._currentMeetingChildComponent=4;
       this.currentChildComponent=undefined;
        break;
        case 4:
          this.associateId=data.associateId;
          this._currentTeamChildComponent=3;
          break;
  
}
}

 handleSubTabChange(e:any) {
  if ( e.index == 2){
    this._currentAuditChildComponent=1;
  }
}

isformObjectChange=false;
CommonTabClick(subTabindex:number,tabindex:number)
{
  this.clearFormCompareObjects();


if(tabindex==3)
{
  switch (subTabindex) {
    case 0:
      if(this._currentKpiChildComponent==2)
      {
        this. _detail="KPI not added";
        this. _message="KPI will not be added, Do you want to Continue?";
        this.currentChildComponent=this.childKpiCreateComponent;
        this._oldObject=this.currentChildComponent.oldEngagementKPIData; this._newObject=JSON.stringify(this.currentChildComponent._engagementKpi);
      }
      else if(this._currentKpiChildComponent==3)
      {
        this._detail=this._commonEditDetails;
        this._message=this._commonEditMessage;
        this.currentChildComponent=this.childKpiEditComponent;
        this._oldObject=this.currentChildComponent._oldEngagementKpi; this._newObject=JSON.stringify(this.currentChildComponent._engagementKpi);
      }
      break;
      case 1:
        if(this._currentMeetingChildComponent==2)
        {
          this. _detail="Meeting not added";
          this. _message="Meeting will not be added, Do you want to Continue?";
          this.currentChildComponent=this.childMeetingCreateComponent;
          
          this._oldObject=this.currentChildComponent._oldEngagementMeeting;
           this._newObject=JSON.stringify(this.currentChildComponent.engagementMeetingDetails);
        }
        else if(this._currentMeetingChildComponent==3 )
        {
          this._detail=this._commonEditDetails;
          this._message=this._commonEditMessage;
          this.currentChildComponent=this.childMeetingEditComponent;
          
          this._oldObject=this.currentChildComponent._oldEngagementMeeting; this._newObject=JSON.stringify(this.currentChildComponent.engagementMeetingDetails);
        }
        else if(this._currentMeetingChildComponent==5)
        {
          this._detail=this._commonEditDetails;
          this._message=this._commonEditMessage;
          this.currentChildComponent=this.childMeetingRecYesEditComponent;
          this._oldObject=this.currentChildComponent._oldEngagementMeeting; this._newObject=JSON.stringify(this.currentChildComponent.engagementMeetingDetails);
        }
      break;
      case 2:
        if(this._currentAuditChildComponent==2)
        {
          this._detail="Audit not added";
          this._message="Audit will not be added, Do you want to Continue?";
         this.currentChildComponent=this.childAuditCreateComponent;
          this._oldObject=this.currentChildComponent._oldEngagementAudit; this._newObject=JSON.stringify(this.currentChildComponent._engagementAudit);
        }
        else if(this._currentAuditChildComponent==3)
        {
          this._detail=this._commonEditDetails;
          this._message=this._commonEditMessage;
          this.currentChildComponent=this.childAuditEditComponent;
          this._oldObject=this.currentChildComponent._oldEngagementAudit; this._newObject=JSON.stringify(this.currentChildComponent._engagementAudit);
        }
      break;
    }
}

else if(tabindex==5){
  if(this.actionType=="Create")
  {
    this._message='Associate will not be added, Do you want to Continue?';
    this._detail='Associate not added';
  }
  else{
  this._message='Changes will not be saved, Do you want to continue?';
  this._detail='Changes not saved';
  }
   if (this.childTeamCreateEditComponent) {
    this.currentChildComponent = this.childTeamCreateEditComponent;
    this._oldObject = this.childTeamCreateEditComponent.oldTeamAssociateModel;
    this._newObject = JSON.stringify(this.childTeamCreateEditComponent.teamAssociateData);
  } else {
    this._oldObject = "";
    this._newObject = "";
  }
}
if(this._oldObject!=this._newObject)
this.isformObjectChange=true;
}

clearFormCompareObjects()
{
    this._oldObject=""; 
     this._newObject="";
     this.isformObjectChange=false;
}

getFormattedValue(value: number, currencyName: string): string {
  return this.currencyService.getFormattedValue(value, currencyName);
}
  BackToList() {
    this.router.navigate(['/home']);
  }



  TileClickEvent(contractGroupData:any)
  {
    var selectedData=this.engagementContractsGroupsCopy.filter((aa:any)=>aa.id===contractGroupData.id)
    this.selectedContractGroupData=selectedData[0];
    this.selectedContractGroupId=this.selectedContractGroupData.id;
    this.displayContractGroupdashboard=true;
   this.tabAndStateService.scrollToTop(this.scrollContainer);
  }

  displayContractGroupdashboard=false;
  reportingServiceURL="";
  selectedContractGroupData:any;
  selectedContractGroupId:any;
  showDashboard()
  {
    this.loaderService.showLoader();
   

    this.GetReportingServicesEmbedURL();
  }


contractGroupdashboardId:any;
  public async GetReportingServicesEmbedURL() {
    this.contractGroupdashboardId=environment?._contractGroupDashboardId;
    let model = {
      "dashboardID": this.contractGroupdashboardId,//"d4fafce8-b6ad-4bb7-a62d-c211eecb4d58",
      "token": this.authService.getCookieValue('LOGIN_TOKEN'),
      "privilege": [
        {
          "MenuName": "string",
          "isAllDataAvailable": true,
          "DataValues": []
        }
      ]
    };
  
    // Create an observable for the firstEmdbedURL
  this._dashboaredService.getEmbedUrlData(model).subscribe(
    async (res: any) => {

        this.reportingServiceURL=res.data;

        await this.Dashboard();
  });
  
    // Combine the observables using forkJoin
   
  }

  public async Dashboard() {
    let containerDiv = document.getElementById("dashboardContainer") || '';
    if (containerDiv && containerDiv instanceof HTMLElement) {
      containerDiv.innerHTML = ''; // Clear the container
  }
    const embeddingContext: EmbeddingContext = await createEmbeddingContext();
    
    const frameOptions = {
     
      url: this.reportingServiceURL + '&#p.ContractGroupId=' + this.selectedContractGroupId,//628,668,958 + this.selectedContractGroupId,
      container: containerDiv,
      height: "400px",
      width: "700px",
      resizeHeightOnSizeChangedEvent: true,
      onChange: (changeEvent:any, metadata:any) => {
        switch (changeEvent.eventName) {
          
            case 'FRAME_LOADED': {
            
                this.loaderService.hideLoader();
              
                break;
            }
        }
    },
  }
    
   embeddingContext.embedDashboard(frameOptions);

  }

  BackToFunctions(){
    this.displayContractGroupdashboard=false;
  }

  GetConversionRates(){
    this.currencyService.GetConversionRates.subscribe(
      (result:any)=>{
        this.conversionRate=result.data.conversionRates;
        setTimeout(() => {
          this.updateTooltipContent();    
        }, 1000);
        
      }
    );
  }
    OnCurrencyChange(event: any) {
        this.selectedCurrency = event.value;
        this.ConvertContactValuesByCurrency(event.value);
        this.updateTooltipContent();
    }
    engagementContractsGroupsCopy: any;
    baseCurrencyCode:any;
    ConvertContactValuesByCurrency(currency: any) {
        const originalData = [...this.engagementContractsGroupsCopy];
        this.engagementContractsGroups = originalData.map(con => ({ ...con }));

        const baseCurrency = this.engagementdata.defaultCurrency;
        const baseCurrencyObj = this.currenciesData.find(currency => currency.name === baseCurrency);
        if (baseCurrencyObj) {
          this.baseCurrencyCode = baseCurrencyObj.code;
        }

        const conversionObservables = this.engagementContractsGroups.map((con: any) => {
            return this.currencyService.getExchangeRate(this.conversionRate, this.baseCurrencyCode, currency).pipe(
                tap((amount: number) => {
                    con.totalContractedValue *= amount;
                })
            );
        });

        forkJoin(conversionObservables).subscribe(() => {
             this.updateSummaryValues();
        });
        return conversionObservables.totalContractedValue
    }
    totalContractedValue:any;
    private updateSummaryValues() {
       this.totalContractedValue = this.engagementContractsGroups.reduce((t: any, valu: { totalContractedValue: any; }) => t + valu.totalContractedValue, 0);
    }
    GetCurrencyData() {
        this.loaderService.showLoader();
        this._masterDataService.getCodeListData(4, '').subscribe(
            data => {
                this.currenciesData = data.data;
                setTimeout(() => {
                  this.setDefaultCurrency();
                }, 1000);
                this.GetConversionRates();
            }
        );  
    }

    baseCurrency:string="";
    setDefaultCurrency() {
      const defaultCurrency = this.engagementdata.defaultCurrency;
      const selectedCurrencyObj = this.currenciesData.find(currency => currency.name === defaultCurrency);
      if (selectedCurrencyObj) {
        this.selectedCurrency = selectedCurrencyObj.code;
        this.baseCurrency=this.selectedCurrency;
      }
    }


    //Finance Dashboard Code

    fiscalYearList:any[]=[];

    IsDashboardTillDate=true;
    selectedFiscalYear:any;
    selectedDashboardToView="";
    DashboardToViewDataSource: any[] = [
      { label: 'Till Date Values', value: 'tillDate' },
      { label: 'Fiscal Year Values', value: 'fiscalYear' }
    ];

    
    onDashboardToViewChange(data:any){
    
      if(data.value=='tillDate'){
        this.IsDashboardTillDate=true;
        this.fiscalYearList=[];
        this.fiscalYearList.push({ label: 'Not Applicable', value: 'NotApplicable' });
        this.selectedFiscalYear=this.fiscalYearList[0].value;
        this.isApplyDisable=false;
      }
      else{
        this.IsDashboardTillDate=false;
        this.loaderService.showLoader();
        this.getContractForFinanceDashboard();
        this.isApplyDisable=true;
      }
    }

    isApplyDisable=true;
onFiscalYearChange(data:any){
  if(data.value != null){
  this.isApplyDisable=false;
  }
  else{
    this.isApplyDisable=true;
  }
}

    engagementContractData:any;
    getContractForFinanceDashboard(){
      this._contractService.GetAllContracts(this.engagementId,[0,0]).subscribe(
        res => {
          this.engagementContractData = res.data;
          this.GetFunctionContracts();
        });
    }

    onDashboardApplyClick(){
      this.selectedContractGroupId=this.selectedContractGroupData.id;
        if(this.IsDashboardTillDate){
          this.GetReportingServicesEmbedURLForFinanceTillDate();
          
        }
        else{
          this.GetReportingServicesEmbedURLForFinanceFiscalYear();
        }
    }


financeTillDateDashboardId:any;
reportingURLforFinanceTillDate:any;
    public async GetReportingServicesEmbedURLForFinanceTillDate() {

     

      this.financeTillDateDashboardId=environment?._financeTillDateDashboardId;
      let model = {
        "dashboardID": this.financeTillDateDashboardId,//"d4fafce8-b6ad-4bb7-a62d-c211eecb4d58",
        "token": this.authService.getCookieValue('LOGIN_TOKEN'),
        "privilege": [
          {
            "MenuName": "string",
            "isAllDataAvailable": true,
            "DataValues": []
          }
        ]
      };
    
      // Create an observable for the firstEmdbedURL
    this._dashboaredService.getEmbedUrlData(model).subscribe(
      async (res: any) => {
  
          this.reportingURLforFinanceTillDate=res.data;
  
          await this.FinanceTillDashboard();
    });
    
      // Combine the observables using forkJoin
     
    }
  
    public async FinanceTillDashboard() {
      let containerDiv = document.getElementById("financeDashboardContainer") || '';
      const embeddingContext: EmbeddingContext = await createEmbeddingContext();
    //this.selectedContractGroupId=1652;
     if (containerDiv && containerDiv instanceof HTMLElement) {
      containerDiv.innerHTML = ''; // Clear the container
  }
      const frameOptions = {
       
        url: this.reportingURLforFinanceTillDate + '&#p.ContractGroupId=' + this.selectedContractGroupId + '&p.Currency=' + this.baseCurrency,//628,668,958 + this.selectedContractGroupId,
        container: containerDiv,
        height: "800px",//"100%",
        // width: "700px",
        resizeHeightOnSizeChangedEvent: true,
        onChange: (changeEvent:any, metadata:any) => {
          switch (changeEvent.eventName) {
            
              case 'FRAME_LOADED': {
              
                   
                
                  break;
              }
          }
      },
    }
      
     embeddingContext.embedDashboard(frameOptions);
  
    }




    selectedGroup:any;
  
    GetContractList() {
    
      this._contractService.GetAllContracts(this.engagementId,[0,0]).subscribe(
          res => {
              if (res && res.status && res.data && res.data.length > 0) {
              
                  const data = res.data || [];
                  this.selectedGroup = data.filter((contract: { ownerFunctionId: any; }) => {
                      return this.engagementContractsGroups.some((group: { id: any; }) => group.id === contract.ownerFunctionId);
                  });
                  
                  this.intialConversion();
                
              }
          }
      );
  }
  isIntialConvert:boolean=false;
  intialCurrencyCode:any;
    intialConversion() {
    this.isIntialConvert=true;
    const intialCurrency = this.selectedGroup[0].currencyId;
    const intialCurrencyObj = this.currenciesData.find(currency => currency.id === intialCurrency);
    if (intialCurrencyObj) {
      this.intialCurrencyCode = intialCurrencyObj.code;
    }

    const conversionObservables = this.engagementContractsGroups.map((con: any) => {
      return this.currencyService.getExchangeRate(this.conversionRate, this.intialCurrencyCode, this.selectedCurrency).pipe(
          tap((amount: number) => {
             con.totalContractedValue *= amount;
          })
      );
  });

  forkJoin(conversionObservables).subscribe(() => {
      
  });
 
  }

  functionContracts:any;
GetFunctionContracts(){
  this.loaderService.showLoader();
  
  this.functionContracts=this.engagementContractData.filter((aa:any)=>aa.ownerFunctionId===this.selectedContractGroupId);
this.GetEngagementFiscalYearDetails();
}

engagementFiscalYearData:any;
GetEngagementFiscalYearDetails(){
  
  this._financeInvoiceService.GetAllFiscalYearDetailsByEngagementId(this.engagementId).subscribe(
    res => {
      if (res.data) {
        this.engagementFiscalYearData=res.data;
        this.GetFiscalYearList();
      }
});
}


GetFiscalYearList(){
  const filteredData = this.engagementFiscalYearData.filter((efy:any) =>
    this.functionContracts.some((fc:any) => fc.ContractDetailId === efy.ContractDetailId)
  );

 
  this.fiscalYearList=[];
  const distinctFiscalYears = Array.from(new Set(filteredData.map((item:any) => item.fiscalYear)));
  
  // Sort the distinct fiscal years in ascending order
  distinctFiscalYears.sort((a:any, b:any) => {
    const [aStartYear, aEndYear] = a.split(' - ').map((part:any) => parseInt(part.split(' ')[1]));
    const [bStartYear, bEndYear] = b.split(' - ').map((part:any) => parseInt(part.split(' ')[1]));

    return aStartYear - bStartYear || aEndYear - bEndYear;
  });

  this.fiscalYearList = distinctFiscalYears.map(fiscalYear => ({
    label: fiscalYear,
    value: fiscalYear
  }));
  setTimeout(() => {
    this.loaderService.hideLoader();
  }, 2000);
  
}

//FiscalYearDashboardData

financeFiscalYearDashboardId:any;
reportingURLforFinanceFiscalYear:any;
    public async GetReportingServicesEmbedURLForFinanceFiscalYear() {
      this.financeFiscalYearDashboardId=environment?._financeFiscalYearDashboardId;
      let model = {
        "dashboardID": this.financeFiscalYearDashboardId,//"d4fafce8-b6ad-4bb7-a62d-c211eecb4d58",
        "token": this.authService.getCookieValue('LOGIN_TOKEN'),
        "privilege": [
          {
            "MenuName": "string",
            "isAllDataAvailable": true,
            "DataValues": []
          }
        ]
      };
    
      // Create an observable for the firstEmdbedURL
    this._dashboaredService.getEmbedUrlData(model).subscribe(
      async (res: any) => {
  
          this.reportingURLforFinanceFiscalYear=res.data;
  
          await this.FinanceFiscalYearDashboard();
    });
    
      // Combine the observables using forkJoin
     
    }
  
   
    public async FinanceFiscalYearDashboard() {
      let containerDiv = document.getElementById("financeDashboardContainer") || '';
      if (containerDiv && containerDiv instanceof HTMLElement) {
        containerDiv.innerHTML = ''; // Clear the container
    }
      const embeddingContext: EmbeddingContext = await createEmbeddingContext();
     let encodedFiscalYear = encodeURIComponent(this.selectedFiscalYear);
      const frameOptions = {
       
        url: this.reportingURLforFinanceFiscalYear + '&#p.ContractGroupId=' + this.selectedContractGroupId + '&p.FiscalYear=' + encodedFiscalYear + '&p.Currency=' + this.baseCurrency,
        container: containerDiv,
        height: "800px",
        // width: "700px",
        resizeHeightOnSizeChangedEvent: true,
        onChange: (changeEvent:any, metadata:any) => {
          switch (changeEvent.eventName) {
            
              case 'FRAME_LOADED': {
                  break;
              }
          }
      },
    }
      
     embeddingContext.embedDashboard(frameOptions);
  
    }
    tooltipContent: string = '';
    getBaseRate(selectedCurrency: string): number | undefined {
      const lowerCaseCurrency = selectedCurrency.toLowerCase(); // Convert to lowercase
      return this.conversionRate[lowerCaseCurrency as keyof ConversionRate];
    }
    
    generateTooltipContent(selectedCurrency: string): string {
      const baseRate = this.getBaseRate(selectedCurrency);
      if (baseRate === undefined) {
        return `Base rate for ${selectedCurrency.toUpperCase()} not found`;
      }
    
      const availableCurrencyCodes = this.getAvailableCurrencyCodes();
      let content = '';
    
      for (const [currency, rate] of Object.entries(this.conversionRate)) {
        if (currency.toUpperCase() !== selectedCurrency.toUpperCase() &&
            availableCurrencyCodes.has(currency.toUpperCase()) &&
            rate != null) {
          const convertedRate = this.getConvertedRate(rate, baseRate);
          content += `${selectedCurrency.toUpperCase()} - ${currency.toUpperCase()}: ${isNaN(convertedRate) ? 'N/A' : convertedRate}\n`;
        }
      }
      return content;
    }
    getConvertedRate(rate: number | undefined, baseRate: number | undefined): number {
      if (rate == null || baseRate == null) {
        return NaN;
      }
      return parseFloat((rate / baseRate).toFixed(4));
    }
    
    updateTooltipContent() {
      this.tooltipContent = this.generateTooltipContent(this.selectedCurrency);
    } 
    getAvailableCurrencyCodes(): Set<string> {
      return new Set(this.currenciesData.map(currency => currency.code.toUpperCase()));
    }
   
}

