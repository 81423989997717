import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ContractService } from '../../Services/contract.service';

import { SponserService } from 'src/app/MasterData/Services/sponser.service';
import { Dateformat,breadcrumnsCodes, CurrencyType, } from 'src/app/shared/constants/global.constants';
import { LoaderService } from 'src/app/Loader/loader.service';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';

import { TabView } from 'primeng/tabview';
import { ContInvoiceCreateComponent } from 'src/app/Finance/finances/Invoices/Contract/Components/cont-invoice-create/cont-invoice-create.component';
import { InvoiceChildComponents } from '../../Services/contract-invoice';
import { AccrualChildComponents } from 'src/app/Finance/Models/contract-accrual';
import { ForecastChildComponents } from 'src/app/Finance/Models/contract-forecast';
import { ConCreateKpiComponent } from 'src/app/Governance/kpis/Contract/Components/con-create-kpi/con-create-kpi.component';
import { ConEditKpiComponent } from 'src/app/Governance/kpis/Contract/Components/con-edit-kpi/con-edit-kpi.component';
import { ContractDetails, ContractStakeHolderSponsorContact, ContractStakeHolderSupplierContact, KpiChildComponents, MeetingChildComponents } from '../../Models/contract-details';
import { Observable } from 'rxjs';
import { IDeactivateGuard } from 'src/app/shared/Guards/can-deactivate-guard.service';
import { ConAccrualsEditComponent } from 'src/app/Finance/Accruals/contract/Components/con-accruals-edit/con-accruals-edit.component';
import { ConForecastEditComponent } from 'src/app/Finance/Forecast/contract/Components/con-forecast-edit/con-forecast-edit.component';
import { ContInvoiceEditComponent } from 'src/app/Finance/finances/Invoices/Contract/Components/cont-invoice-edit/cont-invoice-edit.component';
import { ConEditMeetingComponent } from 'src/app/Governance/meetings/Contracts/Components/con-edit-meeting/con-edit-meeting.component';
import { ConCreateMeetingComponent } from 'src/app/Governance/meetings/Contracts/Components/con-create-meeting/con-create-meeting.component';
import { ConAccrualsViewComponent } from 'src/app/Finance/Accruals/contract/Components/con-accruals-view/con-accruals-view.component';
import { ConForecastViewComponent } from 'src/app/Finance/Forecast/contract/Components/con-forecast-view/con-forecast-view.component';
import { TeamChildComponents } from '../../../Team/teams/Models/Associate';
import { AssociateAddEditComponent } from '../../../Team/teams/associate-add-edit/associate-add-edit.component';
import { ConEditMeetingRecYesComponent } from 'src/app/Governance/meetings/Contracts/Components/con-edit-meeting-rec-yes/con-edit-meeting-rec-yes.component';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { Privileges } from 'src/app/shared/constants/privileges.constants';
import { CurrencyService } from 'src/app/Reusable/currency.service';
import { ConSummaryViewComponent } from 'src/app/Finance/Summary/contract/Components/con-summary-view/con-summary-view.component';
import { EngagementService } from 'src/app/Engagement/Services/engagement.service';
import { MasterSupplierService } from 'src/app/MasterData/supplier/Services/master.supplier.service';
import { FileUploadService } from 'src/app/Engagement/Services/fileupload.service';
import { TabAndStateService } from 'src/app/shared/TabAndStateService/TabAndState.Service';
import { SupplierContact } from 'src/app/MasterData/Services/supplier-profile';
import { ITbl_SponserContacts } from 'src/app/MasterData/Models/sponser-data';

@Component({
  selector: 'app-viewcontract',
  templateUrl: './viewcontract.component.html',
  styleUrls: ['./viewcontract.component.css']
})
export class ViewcontractComponent implements  OnInit,IDeactivateGuard {
  @ViewChild('myTabView', { static: false }) tabView!: TabView;
  activeTabIndex = 0;
  @ViewChild('mySubTabView', { static: false }) subTabView!: TabView;
  activeFinanceSubTabIndex=0;
  @ViewChild('myFinanceSubTabView', { static: false }) financeSubTabView!: TabView;

  @ViewChild(ConAccrualsEditComponent) childAccrualEditComponent!: ConAccrualsEditComponent;
  @ViewChild(ConAccrualsViewComponent) childAccrualViewComponent!: ConAccrualsViewComponent;

  @ViewChild(ConForecastEditComponent) childForecastEditComponent!: ConForecastEditComponent;
  @ViewChild(ConForecastViewComponent) childForecastViewComponent!: ConForecastViewComponent;

  @ViewChild(ContInvoiceCreateComponent) childInvoiceCreateComponent!: ContInvoiceCreateComponent;
  @ViewChild(ContInvoiceEditComponent) childInvoiceEditComponent!: ContInvoiceEditComponent;
//KPI chaild

@ViewChild(ConCreateKpiComponent) childKpiCreateComponent!: ConCreateKpiComponent;
@ViewChild(ConEditKpiComponent) childKpiEditComponent!: ConEditKpiComponent;

@ViewChild(ConCreateMeetingComponent) childMeetingCreateComponent!: ConCreateMeetingComponent;
@ViewChild(ConEditMeetingComponent) childMeetingEditComponent!: ConEditMeetingComponent;
@ViewChild(ConEditMeetingRecYesComponent) childMeetingEditRecYesComponent!: ConEditMeetingRecYesComponent;

@ViewChild(AssociateAddEditComponent) childTeamCreateEditComponent!: AssociateAddEditComponent;

@ViewChild(ConSummaryViewComponent) childConSummaryViewComponent!: ConSummaryViewComponent;
@ViewChild('scrollContainer', { static: false }) scrollContainer!: ElementRef;


_kpiChildComponents=new KpiChildComponents();
_meetingChildComponents=new MeetingChildComponents();
_teamChildComponents=new TeamChildComponents();
_currentKpiChildComponent:number=0;
_currentMeetingChildComponent:number=0;
_currentTeamChildComponent:number=0;
  activeSubTabIndex = 0;
  kpiId:any;
  currentChildComponent:any;
  //end
 _invoiceChildComponents=new InvoiceChildComponents();
 isfromSupplier:boolean=false;
 _accrualChildComponents=new AccrualChildComponents();
 _forecastChildComponents=new ForecastChildComponents();
  PaymentTermName:any;
  EngagementId:any;
  ContractId: any;
  ContractDetails: any;
  engagementName: string = '';
  engagementStartDate:any;
  supplierContacts: any;
  suppliersMasterServiceAgreements: any;
  selectedMSA: string = "";
  selectedSupplierContact: string = "";
  currencies: any;
  function: any;
  BillingType: any;
  status: any;
  _dateformart: any;
  changeOrders: any;
  contractAddendumDetails:any;
  _enumBreadcrumb:any;
  selectedOption: string = 'Actions';
  ViewContract: any;
  _currentInvoiceChildComponent:number=0;
  _currentAccrualChildComponent:number=0; 
  _currentForecastChildComponent:number=0; 
  visibleBillingsTypes:boolean = false;
  meetingId:any;
  _TabId:any=0;
  FromType=1;
  associateTeamId:any;
  currencyformat:any;
  editPrivilege:boolean=false;
  viewTeamPrivilege:boolean=false;
  viewFinancePrivilege:boolean=false;
  ContractCreatedUserName:string='';
  ContractUpdatedUserName:string='';
  supplierContactList:SupplierContact[]=[];
  sponserContactList:ITbl_SponserContacts[]=[];
  SupplierId: any;
  SponsorId:any;
  EngagementName:any;
  notificationOptionsDataSource: any
  constructor(private privilegeService: PrivilegeService,
    private route: ActivatedRoute, private datePipe: DatePipe,
    private router: Router, private contractService: ContractService, 
    private _masterDataService: SponserService, 
    private loaderService: LoaderService, 
    private breadcrumbService: BreadcrumbService, private _fileService: FileUploadService,
    private confirmationService: ConfirmationService, 
    private messageService: MessageService,
    private currencyService: CurrencyService,
    private engagementService: EngagementService,
    private _mastersupplierService: MasterSupplierService,
    private activeRouter: ActivatedRoute,private tabAndStateService: TabAndStateService) {

  }
  currency:any;
  ngOnInit() {
    // this._currentKpiChildComponent=1;
    // this._currentInvoiceChildComponent=1;
    // this._currentAccrualChildComponent=1;
    // this._currentForecastChildComponent=1;
     this.ViewContract="View Contract";
    this._dateformart = Dateformat;
    this._enumBreadcrumb=breadcrumnsCodes;
    this.currencyformat=CurrencyType;
    this.ContractId= this.route.snapshot.queryParamMap.get('ContractId')
    this.EngagementId= this.route.snapshot.queryParamMap.get('supplierId');
    this.currency= this.route.snapshot.queryParamMap.get('currency');
    this._TabId= this.route.snapshot.queryParamMap.get('TabId');
    this.BindPrivileges();
    this.GetContractDetailsById(this.ContractId);
    if(this.ContractId=="" ||this.ContractId==null)
    {
      this.router.navigate(['/home']);
    }
    if(this.EngagementId==null || this.EngagementId=="" )
    {
      this.isfromSupplier=false;
    
      this.generateBreadcrumb(this._enumBreadcrumb.EVCON, '', this.GetBreadcrumbSubTabName());
    }
    else{
      this.isfromSupplier=true;
      setTimeout(() => {
        this.GetEngagementById(this.EngagementId);
      }, 2000);
     
     this.loaderService.hidefinalLoader();// hide after 4 seconds even get any error
    
    }
   }
  BindPrivileges(){
    const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);
     this.editPrivilege = hasPermission(Privileges.EditContract);
     this.viewTeamPrivilege= hasPermission(Privileges.ViewContractTeams);
     this.viewFinancePrivilege= hasPermission(Privileges.ViewContractFinance);
     
    }
  GetBreadcrumbSubTabName():string{
    const tabId = this._TabId ?? 0;
    return tabId == 5 ? "Team" : "Details";
  } 
  engagementId:any;
  supplierStakeholderDetails:any;
  sponsorStakeholderDetails:any;
  description:any;
  poRemarks:any;
  changeorderDescription:any;
  addendumDescription:any;
  contractStartDate:any
  GetContractDetailsById(id: any) {
    this.loaderService.showLoader();
    this.contractService.GetContractDtailsById(id).subscribe(res => {
      if (res.data != null) {
        this.ContractDetails = res.data;
        this.contractStartDate=this.ContractDetails.startDate;
        this.ContractCreatedUserName=this.privilegeService.getUserName(res.data.createdBy);
        this.ContractUpdatedUserName=this.privilegeService.getUserName(res.data.updatedBy);
        this.engagementId=res.data.engagementId
        this.description = res.data.description ?res.data.description.replace(/\n/g, "<br>") : res.data.description;
        this.poRemarks = res.data.poRemarks ?res.data.poRemarks.replace(/\n/g, "<br>") : res.data.poRemarks;

        this.changeOrders = res.data.changeOrderDetails;
        this.changeorderDescription =  this.changeOrders.description ? this.changeOrders.description.replace(/\n/g, "<br>") :  this.changeOrders.description;
        this.contractAddendumDetails = res.data.addendumDetails;
        this.addendumDescription =  this.contractAddendumDetails.description ? this.contractAddendumDetails.description.replace(/\n/g, "<br>") :  this.contractAddendumDetails.description;
        this.supplierStakeholderDetails =  res.data.supplierStakeholderDetails.sort((a: { supplierContactId: number; }, b: { supplierContactId: number; }) => a.supplierContactId - b.supplierContactId);
        this.sponsorStakeholderDetails= res.data.sponsorStakeholderDetails.sort((a: { sponsorContactId: number; }, b: { sponsorContactId: number; }) => a.sponsorContactId - b.sponsorContactId);
        if(!this.isfromSupplier){
        //  this.GetEngagementById(this.engagementId);
          this.GetContractGroupData(this.engagementId);
          this.generateBreadcrumb(this._enumBreadcrumb.EVCON,this.ContractDetails.title,this.GetBreadcrumbSubTabName());
        }
        if(this._TabId!=null && this._TabId==5){
              let eventObj:any={};
                eventObj.index=3;
              this.onTabSelect(eventObj);
             }
        this.GetEngagementById(this.engagementId);
        this.GetBillingTypeCodeList();// to get the billing types from code list
        this.GetContractStatus(); // to get the status from the code list
        this.GetCurrencyData(); // to get the currency from the code list
        this.GetAllNotificationOptions(20);
        this.GetChangeOrdersStatus() //to get Change orders 
        this.GetAddendumStatus() //to get Addendum status
       
        // setTimeout(() => {
        //   if (this.ContractDetails.sponserContactId) {
        //     const selectedContact = this.sponsorContactsDataSource.find((contact: { id: number | undefined; }) => contact.id === this.ContractDetails.sponserContactId);
        //     if (selectedContact) {
        //       this.sponserContactList = [selectedContact];
        //     }
        //   }
        //   if (this.ContractDetails.ownerSupplierContactId) {
        //     const selectedContact = this.supplierContactData.find((contact: { id: number | undefined; }) => contact.id === this.ContractDetails.ownerSupplierContactId);
        //     if (selectedContact) {
        //       this.supplierContactList = [selectedContact];
        //     }
        //   }
        // }, 2000);
             }
      
    
    });
  }
  stakeholdersuppliercontactData:any[] = [];
  stakeholdercontactData: any[] = [];
  filterSupplierContacts() {
    if(this.supplierStakeholderDetails!=undefined && this.supplierStakeholderDetails){
    const supplierContactIds = this.supplierStakeholderDetails.map((detail: { supplierContactId: any; }) => detail.supplierContactId);
    this.stakeholdersuppliercontactData = this.supplierContactData.filter((contact: { id: any; }) => {
      return supplierContactIds.includes(contact.id);
    });
    this.stakeholdersuppliercontactData.forEach((contact: { id: any; }, index: any) => {
        const stakeholderSupplier = {
            value: contact.id
        };
         this.supplierContactDetails(stakeholderSupplier, index);
    });
  }
   
}
filterSponsorContacts() {
  if(this.sponsorStakeholderDetails!=undefined && this.sponsorStakeholderDetails){
    const sponsorContactIds = this.sponsorStakeholderDetails.map((detail: { sponsorContactId: any; }) => detail.sponsorContactId);
    this.stakeholdercontactData = this.sponsorContactsDataSource.filter((contact: { id: any; }) => {
      return sponsorContactIds.includes(contact.id);
    });
    this.stakeholdercontactData.forEach((contact: { id: any; }, index: any) => {
        const stakeholderSponsor = {
            value: contact.id
        };
         this.sponsorContactDetails(stakeholderSponsor, index);
    });

  }
}
sponsorContactDetails(event: any, index: number) {
const id = event.value;
    this.selectedSponsorContactId = id;
    if (this.selectedSponsorContactId) {
    
        const selectedContact = this.sponsorContactsDataSource.find((contact: { id: any; }) => contact.id === this.selectedSponsorContactId);
        if (selectedContact) {
            this.stakeholderSponsorContacts[index] = selectedContact;
            if (!this.contractDetails.SponsorStakeholderDetails) {
                this.contractDetails.SponsorStakeholderDetails = [];
            }
            const existingContact = this.contractDetails.SponsorStakeholderDetails.findIndex(detail =>
                detail.sponsorContactId === selectedContact.id
            );

            if (existingContact === -1) { 
                const sponsorStakeholderDetail: ContractStakeHolderSponsorContact = {
                    Id: 0,
                    ContractId: this.contractDetails.Id || 0,
                    sponsorContactId: selectedContact.id, 
                    isSendNotification: false
                };
                this.contractDetails.SponsorStakeholderDetails.push(sponsorStakeholderDetail);
                
            }
           
        }
    } else {
        this.stakeholderSponsorContacts[index] = null;
    }
   
}
selectedSupplierContactId: any;
selectedSponsorContactId:any;
stakeholderSponsorContacts: any[] = [];
stakeholderSupplierContacts:any[]=[];
contractDetails = new ContractDetails();
supplierContactDetails(event: any, index: number) {
const id = event.value;
    this.selectedSupplierContactId = id;
    if (this.selectedSupplierContactId) {
        const selectedSupplierContact = this.supplierContactData.find((contact: { id: any; }) => contact.id === this.selectedSupplierContactId);
        if (selectedSupplierContact) {
          this.stakeholderSupplierContacts[index] = selectedSupplierContact;
          if (!this.contractDetails.SupplierStakeholderDetails) {
              this.contractDetails.SupplierStakeholderDetails = [];
          }
          const existingcontact = this.contractDetails.SupplierStakeholderDetails.findIndex(detail =>
              detail.supplierContactId === selectedSupplierContact.id
          );
          if (existingcontact === -1) { 
              const supplierStakeholderDetail: ContractStakeHolderSupplierContact = {
                  Id: 0,
                  ContractId: this.contractDetails.Id || 0,
                  supplierContactId: selectedSupplierContact.id, 
                  isSendNotification: false
              };

              this.contractDetails.SupplierStakeholderDetails.push(supplierStakeholderDetail);
          }
      }
    } else {
        this.stakeholderSupplierContacts[index] = null;
    }
}
  GetEngagementById(id: any) { // Getting the supplier Details by id, Supplier Details,Supplier Contacts,MSA's.
    this.engagementService.GetEngagementById(id).subscribe(res => {
      this.loaderService.hideLoader();
      if (res.data != undefined && res.data) {
        this.engagementName = res.data.engagementName;
      //below paramenter for invoice out put
       this.EngagementName=res.data.engagementName;
       this.engagementStartDate=res.data.engagementStartDate;
       this.SponsorId=res.data.sponsorId;
       this.SupplierId=res.data.supplierId;
        if(!this.isfromSupplier )
        {
        this.generateBreadcrumb(this._enumBreadcrumb.EVCON,this.ContractDetails.title,this.GetBreadcrumbSubTabName());
        }
        else{

          this.breadcrumbService.generateBreadcrumb(this._enumBreadcrumb.VECONFE,this.engagementName,"Contracts",this.ContractDetails.title,this.EngagementId,this.GetBreadcrumbSubTabName());
        }
        this.supplierContacts = res.data.supplierContacts;
        this.suppliersMasterServiceAgreements = res.data.engagementMasterServiceAgreements;
        var filteredMsa = this.suppliersMasterServiceAgreements.filter((Msa: any) => Msa.id == this.ContractDetails.engagementMasterServiceAgreementId);
        var filteredSupplierContact = this.supplierContacts.filter((SupContact: any) => SupContact.id == this.ContractDetails.ownerSupplierContactId);
        this.selectedMSA = filteredMsa.length > 0 ? filteredMsa[0].title : "";
        this.PaymentTermName=filteredMsa.length > 0 ? filteredMsa[0].paymentTermName : "";
        this.selectedSupplierContact = filteredSupplierContact.length > 0 ? filteredSupplierContact[0].contactName : "";
       
       
        //--------------------------------------
        this.getSponserData(res.data.sponsorId);
        this.GetSuppliersData(res.data.supplierId)
        this.GetContractGroupData(res.data.id);

        setTimeout(() => {
          if (this.ContractDetails.sponserContactId) {
            const selectedContact = this.sponsorContactsDataSource.find((contact: { id: number | undefined; }) => contact.id === this.ContractDetails.sponserContactId);
            if (selectedContact) {
              this.sponserContactList = [selectedContact];
            }
          }
          if (this.ContractDetails.ownerSupplierContactId) {
            const selectedContact = this.supplierContactData.find((contact: { id: number | undefined; }) => contact.id === this.ContractDetails.ownerSupplierContactId);
            if (selectedContact) {
              this.supplierContactList = [selectedContact];
            }
          }
        }, 2000);
      }
    });
   
  }
  GetCurrencyData() {
    this._masterDataService.getCodeListData(4, '').subscribe(
      res => {
        if (res.data.length > 0) { //Matching the contract Details status id with code list
          var filteredCurrency = res.data.filter((currency: any) => currency.id == this.ContractDetails.currencyId);
          if (filteredCurrency != undefined && filteredCurrency != null && filteredCurrency.length > 0) {
            this.currencies = filteredCurrency[0].name;
          }
          var i = 0;
          if (this.changeOrders != undefined && this.changeOrders.length > 0) { //Matching the contract Details currency id with code list for change orders tiles
            this.changeOrders.filter((ChangeOrds: any) => {
              var filteredcurencys = res.data.filter((currency: any) => currency.id === ChangeOrds.currencyId);
              if (filteredcurencys != null && filteredcurencys != undefined && filteredcurencys.length > 0) {
                this.changeOrders[i].currencyId = filteredcurencys[0].code;
              }
              i++;
            })
          }

        }
      }
    );
  }

  GetContractGroupData(id:number){
    this.engagementService.GetContractByEngagementId(id).subscribe(
      res => {
              if (res.data.length > 0 && this.ContractDetails.ownerFunctionId != null) {  //Matching the contract Details function id with code list
               
                var filteredFunction = res.data.filter((func: any) => func.id == this.ContractDetails.ownerFunctionId);
                if (filteredFunction != undefined && filteredFunction != null && filteredFunction.length > 0){
                  this.function = filteredFunction[0].name;
                }
                else{
                  this.function="Others";
                }
                
              }
              else{
                this.function="Others";
              }
      
            });
  }
  billingTypesDataSource:any;

  GetBillingTypeCodeList() {
    this._masterDataService.getCodeListData(2, '').subscribe(
      res => {    
        if (res.data.length > 0) { //Matching the contract Details Billingtype id with code list         
        this.billingTypesDataSource=res.data;
        if(this.billingTypesDataSource != undefined && this.billingTypesDataSource.length > 0 && this.ContractDetails.billingTypes.length > 0)
        {
           this.ContractDetails.billingTypes.filter((id:any) => {  
           var filteredBillingTypes = this.billingTypesDataSource.filter((billing:any) => billing.id === id.billingTypeId);   
           if((filteredBillingTypes != null && filteredBillingTypes != undefined) && (this.BillingType == undefined || this.BillingType == ""))   
           {
              this.BillingType = filteredBillingTypes[0].name;          
           }
           else
           {
              this.BillingType += ","+filteredBillingTypes[0].name;                
           }
        });
      }
        }
      }
    );
  }

  GetContractStatus() {
    this._masterDataService.getCodeListData(3, 'Contract').subscribe(
      res => {
        if (res.data.length > 0) {//Matching the contract Details status id with code list
          var filteredStatus = res.data.filter((status: any) => status.id == this.ContractDetails.statusMappingId);
          if (filteredStatus != undefined && filteredStatus != null && filteredStatus.length > 0) {
              this.status = filteredStatus[0].name;
          }       
        }

      }
    );
  }
  GetChangeOrdersStatus()
  {
    this._masterDataService.getCodeListData(3, 'Change Order').subscribe(
      res => {
    var i = 0;//Matching the contract Details status id with code list for change orders tiles
    if (this.changeOrders != undefined && this.changeOrders.length > 0) {
      this.changeOrders.filter((ChangeOrds: any) => {
        var filteredstatusChangeOrder = res.data.filter((status: any) => status.id === ChangeOrds.statusMappingId);
        if (filteredstatusChangeOrder != null && filteredstatusChangeOrder != undefined && filteredstatusChangeOrder.length > 0) {
          this.changeOrders[i].statusMappingId = filteredstatusChangeOrder[0].name;
        }
        i++;
      }) 
    }
  }
    );
  }
  GetAddendumStatus()
  {
    this._masterDataService.getCodeListData(3, 'Addendum').subscribe(
      res => {     
      var addendumStatus = 0;//Matching the contract Details status id with code list for Addendum tiles
      if (this.contractAddendumDetails != undefined && this.contractAddendumDetails.length > 0) {
        this.contractAddendumDetails.filter((AddendunOrds: any) => {
          var filteredstatusOfAddendum = res.data.filter((status: any) => status.id === AddendunOrds.statusMappingId);
          if (filteredstatusOfAddendum != null && filteredstatusOfAddendum != undefined && filteredstatusOfAddendum.length > 0) {
            this.contractAddendumDetails[addendumStatus].statusMappingId = filteredstatusOfAddendum[0].name;
          }
          addendumStatus++;
        })
      }
    
  }
    );
  }

  GetAllNotificationOptions(type:number) {
    this._masterDataService.getCodeListData(type,'').subscribe(
      res=>{
        this.notificationOptionsDataSource=res.data;
      }
    );
  };

  generateBreadcrumb(component?:string,recordName?:string,tabName?:string,childRecName?:string,recordId?:number,subTabName?:string)
  {
    this.breadcrumbService.generateBreadcrumb(component,recordName,tabName,childRecName,recordId,subTabName);
  }
  getBreadcrumbs(): MenuItem[] {
    return this.breadcrumbService.getBreadcrumbs();
  }
  tabindex:number = 0;
  Previoustabindex:number = 0
  showSummaryDetails=false;
  onTabSelect(event?:any)
  {
    this.tabAndStateService.scrollToTop(this.scrollContainer);
    this.tabindex=event.index;


    
if(this.tabindex==1){
  this._currentKpiChildComponent=1;
}
else if(this.tabindex==2){
  this.showSummaryDetails=true;
  this._currentInvoiceChildComponent=1;
  this._currentAccrualChildComponent=1;
  this._currentForecastChildComponent=1;
}

    this.CommonTabClick(this.previousSubTabIndex,this.Previoustabindex);
    if (this.isformObjectChange)
    {
        this.confirmationService.confirm({
          message: this._message,
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.Previoustabindex=this.tabindex;
            this.isformObjectChange=false;
            this._oldObject="";
            this._newObject="";
            this.ParentTabSelect(this.tabindex)
            this.resetallsubtabs();
            this.messageService.add({
              key: 'tc',
              severity: 'info',
              summary: this._detail,
              detail: '',
          });
          },
          reject: () => {
          
            setTimeout(() => {
              this.tabindex=this.Previoustabindex;
              this.activeTabIndex = this.Previoustabindex;
              this.tabView.activeIndex = this.Previoustabindex;
           },250);
           
          },
        });
    }
    else{
      this.Previoustabindex=this.tabindex;
      this.isformObjectChange=false;
      this._oldObject="";
      this._newObject="";
      this.resetallsubtabs();
      this.ParentTabSelect(this.tabindex)
    }
  
  }
resetallsubtabs()
{
  this._currentKpiChildComponent=1;
  this._currentMeetingChildComponent=1;
  this._currentAccrualChildComponent=1;
  this._currentForecastChildComponent=1;
  this._currentInvoiceChildComponent=1;
  this._currentTeamChildComponent=1;
  this.subTabindex = 0;

}

ParentTabSelect(ParentTabIndex:number)
{
  this.tabindex=ParentTabIndex;
  switch (ParentTabIndex) {
    case 0:
      if(!this.isfromSupplier)
      {
      this.generateBreadcrumb(this._enumBreadcrumb.EVCON,this.ContractDetails.title,"Details");
      }
      else{
        this.generateBreadcrumb(this._enumBreadcrumb.VECONFE,this.engagementName,"Contracts",this.ContractDetails.title,this.EngagementId,"Details");
      }
      this.ViewContract="View Contract";
      break;
      case 1:
        
      if(!this.isfromSupplier )
      {
      this.generateBreadcrumb(this._enumBreadcrumb.EVCON,this.ContractDetails.title,"Governance");
      }
      else{
        this.generateBreadcrumb(this._enumBreadcrumb.VECONFE,this.engagementName,"Contracts",this.ContractDetails.title,this.EngagementId,"Governance");
      }
      this.ViewContract=this.ContractDetails.title;
      break;
      case 2:
     
      if(!this.isfromSupplier )
      {
      this.generateBreadcrumb(this._enumBreadcrumb.EVCON,this.ContractDetails.title,"Finance");
      }
      else{
        this.generateBreadcrumb(this._enumBreadcrumb.VECONFE,this.engagementName,"Contracts",this.ContractDetails.title,this.EngagementId,"Finance");
      }
    
      this.ViewContract=this.ContractDetails.title;
      break;
      case 3:
        this._currentTeamChildComponent=1;
        this.ViewContract=this.ContractDetails.title;
        if(!this.isfromSupplier)
        {
        this.generateBreadcrumb(this._enumBreadcrumb.EVCON,this.ContractDetails.title,"Team");
        }
        else{
          this.generateBreadcrumb(this._enumBreadcrumb.VECONFE,this.engagementName,"Contracts",this.ContractDetails.title,this.EngagementId,"Team");
        }
        break;
}
}

  onOptionChange(): void {
    try {
      if (this.selectedOption === 'Edit') {
        if(!this.isfromSupplier)
        {
          this.router.navigate(['/editcontract'], {queryParams: {ContractId: this.ContractId,currency: this.currency }});
        }
        else{
          this.tabAndStateService.setPreviousState('Contracts', 2);
          this.router.navigate(['/editcontract'], { queryParams: { ContractId: this.ContractId, engagementId: parseInt(this.EngagementId), currency: this.currency } });
        }
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  downloadAttachemnt(fileName: any, filePath: any) { // download Uploaded Attachemnets 
    this.loaderService.showLoader();
    var filedetail = {
      "fileName": fileName,
      "filePath": filePath
    };
    this._fileService.DownloadFile(filedetail).subscribe(
      res => {
        var test = res;
        var blobData = this._fileService.dataURItoBlob(res);
        this._fileService.downloadBase64File(blobData, filedetail.fileName);
        this.loaderService.hideLoader();
      }
    );

  }

  //Child components actions
  handleInvoiceAddButtonClick() {
  this._currentInvoiceChildComponent=2;
 }
 handleInvoiceCancelAddButtonClick()
{
  this._currentInvoiceChildComponent=1;
}
handleInvoiceSaveAddButtonClick()
{
  
  this.clearFormCompareObjects();
  this._currentInvoiceChildComponent=4;
}

 handleInvoiceEditButtonClick(data: any) {
   this.invoiceId=data;
  this._currentInvoiceChildComponent=3;
 }
 handleInvoiceViewButtonClick(data: any) {
  this.invoiceId=data.invoiceId;
 this._currentInvoiceChildComponent=4;
}


// accrual
accrualId:any;
handleAccrualEditButtonClick(data: any) {
  this.accrualId=data.accrualId;
  this._currentAccrualChildComponent=2; // Edit page
 }
 
handleAccrualCancelEditButtonClick()
{
  this._currentAccrualChildComponent=1; // list page
}

handleAccrualSaveEditButtonClick()
{
  
  this.clearFormCompareObjects();
  this._currentAccrualChildComponent=1;  // view page
}

 handleAccrualViewButtonClick(data: any) {
  this.accrualId=data.accrualId;
 this._currentAccrualChildComponent=3; // view page
}

// forecast
forecastId:any;
handleForecastEditButtonClick(data: any) {
 
  this.forecastId=data.forecastId;
  this._currentForecastChildComponent=2; // Edit page
}
 
 handleForecastCancelEditButtonClick()
{
  this._currentForecastChildComponent=1; // list page
}
handleForecastSaveEditButtonClick()
{
  this.clearFormCompareObjects();
  this._currentForecastChildComponent=1;  // view page
}

 handleForecastViewButtonClick(data: any) {
  this.forecastId=data.forecastId;
 this._currentForecastChildComponent=3; // view page
}
//******  code end ********/

  invoiceId:any;
  subTabindex:number = 0;
  previousSubTabIndex=0;
  submitted:boolean = false;
  onSubTabSelect(event?:any)
  { 
    this.tabAndStateService.scrollToTop(this.scrollContainer);
    this.subTabindex=event.index;
    this.CommonTabClick(this.previousSubTabIndex,this.Previoustabindex);
    if (this.isformObjectChange)
    {
        this.confirmationService.confirm({
          message: this._message,
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.previousSubTabIndex=this.subTabindex;
            this.isformObjectChange=false;
            this._oldObject="";
            this._newObject="";
            this.SubTabSelect(this.subTabindex)
            this.messageService.add({
              key: 'tc',
              severity: 'info',
              summary: this._detail,
              detail: '',
          });
          },
          reject: () => {
            this.activeSubTabIndex = this.previousSubTabIndex;
            this.subTabView.activeIndex = this.previousSubTabIndex;
            setTimeout(() => {
              this.activeSubTabIndex = this.previousSubTabIndex;
              this.subTabView.activeIndex = this.previousSubTabIndex;
           },10);
           
          },
        });
    }
    else{
      this.previousSubTabIndex=this.subTabindex;
      this.clearFormCompareObjects();
      this.SubTabSelect(this.subTabindex)
    }
 
  }
  SubTabSelect(subTabIndex:number)
  {
    this.subTabView.activeIndex = subTabIndex;
    switch (subTabIndex) {
      case 0:
      this._currentKpiChildComponent=1;
       this.activeSubTabIndex = subTabIndex;
        break;
        case 1:
          this._currentMeetingChildComponent=1;
          this.activeSubTabIndex = subTabIndex;
        break;
        case 2:
          
          this._currentInvoiceChildComponent=1;
          this.activeSubTabIndex = subTabIndex;
          if(this.childInvoiceCreateComponent!=undefined)
          this.childInvoiceCreateComponent.submitted = false;
         
          this._currentAccrualChildComponent=1; 
          this.activeSubTabIndex = subTabIndex;
          if(this.childAccrualEditComponent!=undefined)
          this.childAccrualEditComponent.accrualSubmitted = false;
        break;
        case 3:
        this._currentForecastChildComponent=1; 
        this.activeSubTabIndex = subTabIndex;
        if(this.childForecastEditComponent!=undefined)
        this.childForecastEditComponent.forecastSubmitted = false;
        break;
  }
  }


  
  onFinanceSubTabSelect(event?:any)
  { 
    this.tabAndStateService.scrollToTop(this.scrollContainer);
    this.subTabindex=event.index;
    this.CommonTabClick(this.previousSubTabIndex,this.Previoustabindex);
    if (this.isformObjectChange)
    {
        this.confirmationService.confirm({
          message: this._message,
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.previousSubTabIndex=this.subTabindex;
            this.clearFormCompareObjects();
            this.FinanceSubTabSelect(this.subTabindex)
            this.messageService.add({
              key: 'tc',
              severity: 'info',
              summary: this._detail,
              detail: '',
          });
          },
          reject: () => {
            setTimeout(() => {
              this.activeFinanceSubTabIndex = this.previousSubTabIndex;
              this.financeSubTabView.activeIndex = this.previousSubTabIndex;
           },250);
           
          },
        });
    }
    else{
      this.previousSubTabIndex=this.subTabindex;
      this.clearFormCompareObjects();
      this.FinanceSubTabSelect(this.subTabindex)
    }
 
  }
  FinanceSubTabSelect(subTabIndex:number)
  {
  
    this.financeSubTabView.activeIndex = subTabIndex;
    switch (subTabIndex) {
      case 0:
     
     this.childConSummaryViewComponent.GetContractSummaryDetails(this.ContractId);
     this.childConSummaryViewComponent.GetFiscalYearDataByContractId();
       this.activeFinanceSubTabIndex = subTabIndex;
        break;
        case 1:
          this._currentInvoiceChildComponent=1;
          this.activeFinanceSubTabIndex = subTabIndex;
        break;
        case 2:
          
          this._currentInvoiceChildComponent=1;
          this.activeFinanceSubTabIndex = subTabIndex;
          if(this.childInvoiceCreateComponent!=undefined)
          this.childInvoiceCreateComponent.submitted = false;
         
          this._currentAccrualChildComponent=1; 
          this.activeFinanceSubTabIndex = subTabIndex;
          if(this.childAccrualEditComponent!=undefined)
          this.childAccrualEditComponent.accrualSubmitted = false;
        break;
        case 3:
        this._currentForecastChildComponent=1; 
        this.activeFinanceSubTabIndex = subTabIndex;
        if(this.childForecastEditComponent!=undefined)
        this.childForecastEditComponent.forecastSubmitted = false;
        break;
  }
  }
  //chaild tabs kpis
  handleAddButtonClick(addButtonType:number) {
    switch (addButtonType) {
      case 1:
        this._currentKpiChildComponent=2;
        this.currentChildComponent=this.childKpiCreateComponent;
          break;
      case 2:
        this._currentMeetingChildComponent=2;
        this.currentChildComponent=this.childMeetingCreateComponent;
          break;
        case 3:
            //team tab
            this.actionType='Create';
            this._currentTeamChildComponent=2;//Add associate
            this.currentChildComponent=this.childTeamCreateEditComponent;
             break;
  }
  
  
   }
   handleCancelAddButtonClick(cancelButtonType:number)//common cancel child compoments method
   {
     switch (cancelButtonType) {
       case 1:
         this._currentKpiChildComponent=1;//Contract KPI
           break;
       case 2:
        this._currentMeetingChildComponent=1;//contract meeting
           break;
        case 3:
            this._currentTeamChildComponent=1;//List associate
            break;
      
   }
   }
   handleSaveAddButtonClick(saveButtonType:number)//common save method for all child components
  {
    
    this.clearFormCompareObjects();
  switch (saveButtonType) {
    case 1:
      this._currentKpiChildComponent=4;
        break;
    case 2:
       //meeting tab
        break;
      case 3:
          //team tab
          this._currentTeamChildComponent=3;//View associate
          this.currentChildComponent=this.childTeamCreateEditComponent;
           break;
  }
  }

  actionType:any;
  associateId:any;
  handleEditButtonClick(data: any,editButtonType:number) {
    switch (editButtonType) {
      case 1:
        this.kpiId=data.kpiId;
        this._currentKpiChildComponent=3;
        this.currentChildComponent=this.childKpiEditComponent;
        break;
      case 2:
        this.meetingId=data.meetingId;
        if(data.isRecurring==false){
          this._currentMeetingChildComponent=3;
          this.currentChildComponent=this.childMeetingEditComponent;
        }
        else{
          this._currentMeetingChildComponent=5;
          this.currentChildComponent=this.childMeetingEditRecYesComponent;
        }
          break;
      case 3:
            //team tab
           this._currentTeamChildComponent=2;//Add associate
           this.actionType='Edit';
           this.associateId=data.associateId;
           this.currentChildComponent=this.childTeamCreateEditComponent;
             break;
  }
    
   }
   
   handleViewButtonClick(data: any,viewButtonType:number) {
    switch (viewButtonType) {
      case 1:
        this.kpiId=data.kpiId;
         this._currentKpiChildComponent=4;
         this.currentChildComponent=undefined;
          break;
      case 2:
        this.meetingId=data.meetingId;
        this._currentMeetingChildComponent=4;
        this.currentChildComponent=undefined;
          break;

      case 3:
            this.associateId=data.associateId;
            this._currentTeamChildComponent=3;
            break;
    
  }
  }



  _message="";
  _detail="";
 _oldObject:string="";
 _newObject:string="";
 canExist(): boolean | Promise<boolean> | Observable<boolean> {
  this.CommonTabClick(this.previousSubTabIndex,this.Previoustabindex);

   if (this.currentChildComponent!=undefined  &&!(this._oldObject==this._newObject))
   {
     return new Promise<boolean>((resolve) => {
       this.confirmationService.confirm({
         message:  this._message,
         header: 'Confirmation',
         icon: 'pi pi-exclamation-triangle',
         accept: () => {
           this.messageService.add({
             key: 'tc',
             severity: 'info',
             summary: this._detail,
             detail:  '',
         });
           resolve(true); 
         },
         reject: () => {
           resolve(false); 
         },
       });
     });
   }
   else{
     return true;
   }
 } 
  sponserContactName:any="";
  sponsorContactsDataSource:any;
  getSponserData(id:any){
    this._masterDataService.getSponserData(id).subscribe(res=>{ 
      if (res.data != undefined) {  //Matching the contract Details function id with code list
        this.sponsorContactsDataSource = res.data.sponserContacts;
        var filteredSponserData = res.data.sponserContacts.filter((func: any) => func.id == this.ContractDetails.sponserContactId);
        if (filteredSponserData != undefined && filteredSponserData != null && filteredSponserData.length > 0)
          this.sponserContactName = filteredSponserData[0].name;
          this.filterSponsorContacts();
      }       
    });
  }
  supplierContactData:any;
  supplierName:any="";
  GetSuppliersData(Id:any){
    this._mastersupplierService.getSupplierById (Id).subscribe(
      res=>{
        if (res.data != undefined) {  //Matching the contract Details function id with code list
          this.supplierContactData=res.data.supplierContacts;
          this.supplierName=res.data.name;
          var filteredSupplierData = res.data.supplierContacts.filter((func: any) => func.id == this.ContractDetails.ownerSupplierContactId);
          if (filteredSupplierData != undefined && filteredSupplierData != null && filteredSupplierData.length > 0)
            this.selectedSupplierContact = filteredSupplierData[0].contactName;
            this.filterSupplierContacts();
        }  

      })
  }
  
  ShowBillingTypesDialog()
{
  this.visibleBillingsTypes = !this.visibleBillingsTypes
  }


isformObjectChange=false;
CommonTabClick(CurrentSubIndex:number,CurrentTabIndex:number=0)
{
  

  this.clearFormCompareObjects();
  if(CurrentSubIndex==2 &&  this._currentAccrualChildComponent==2 ) //accrual tab(2) edit screen(2)  
  {
    this._detail="Changes not saved";
    this._message="Changes will not be saved, Do you want to Continue?"
    this.currentChildComponent=this.childAccrualEditComponent;
    this._oldObject=this.currentChildComponent.accrualDetailOld; this._newObject=JSON.stringify(this.currentChildComponent.accrualDetail);
  }
  else if(CurrentSubIndex==3 && this._currentForecastChildComponent==2){ //forecast tab(3) edit screen(2)  
   this._detail="Changes not saved";
    this._message="Changes will not be saved, Do you want to Continue?"
    this.currentChildComponent=this.childForecastEditComponent;
    this._oldObject=this.currentChildComponent.forecastDetailOld; this._newObject=JSON.stringify(this.currentChildComponent.forecastDetail);
  }
  else if(this._currentInvoiceChildComponent==2 && CurrentSubIndex==1)
  {
    this._detail="Invoice not added";
    this._message="Invoice will not be added, Do you want to Continue?";
    this.currentChildComponent=this.childInvoiceCreateComponent;
    this._oldObject=this.currentChildComponent._oldContractInvoice; this._newObject=JSON.stringify(this.currentChildComponent.contractInvoice);
  }
  else if(this._currentInvoiceChildComponent==3 && CurrentSubIndex==1)
  {
    this._detail="Changes not saved";
    this._message="Changes will not be saved, Do you want to Continue?"
    this.currentChildComponent=this.childInvoiceEditComponent;
    this._oldObject=this.currentChildComponent._oldContractInvoice; this._newObject=JSON.stringify(this.currentChildComponent.contractInvoice);
  }
  else if(this._currentKpiChildComponent==2 && CurrentSubIndex==0)
  {
    this. _detail="KPI not added";
    this. _message="KPI will not be added, Do you want to Continue?";
    this.currentChildComponent=this.childKpiCreateComponent;
    this._oldObject=this.currentChildComponent._oldContractKpi; this._newObject=JSON.stringify(this.currentChildComponent._contractKpi);
  }
  else if(this._currentKpiChildComponent==3 && CurrentSubIndex==0)
  {
    this._detail="Changes not saved";
    this._message="Changes will not be saved, Do you want to Continue?"
    this.currentChildComponent=this.childKpiEditComponent;
    this._oldObject=this.currentChildComponent._oldContractKpi; this._newObject=JSON.stringify(this.currentChildComponent._contractKpi);
  }
  else if(this._currentMeetingChildComponent==2 && CurrentSubIndex==1)
  {
   
   this. _detail="Meeting not added";
   this. _message="Meeting will not be added, Do you want to Continue?";
    this.currentChildComponent=this.childMeetingCreateComponent;
    this._oldObject=this.currentChildComponent._oldContractMeeting; 
    if((!(this.currentChildComponent._oldContractMeeting==JSON.stringify(this.currentChildComponent.contractMeetingDetails))) || (this.currentChildComponent.attendeesValues!="") ||(this.currentChildComponent.absenteesValues!="") ||(this.currentChildComponent.addMeetingActionsData.length!=0)||(!(this.currentChildComponent._oldContractAttachments==JSON.stringify(this.currentChildComponent._contractMeetingOccurrenceAttachments))))
    {
     this._newObject="Data updated"
    }
    else{
    this._newObject=JSON.stringify(this.currentChildComponent.contractMeetingDetails);
    }
  }
  else if(this._currentMeetingChildComponent==3 && CurrentSubIndex==1)
  {
   
   this. _detail="Meeting not added";
   this. _message="Changes will not be saved, Do you want to Continue?";
    this.currentChildComponent=this.childMeetingEditComponent;
    this._oldObject=this.currentChildComponent._oldContractMeeting; this._newObject=JSON.stringify(this.currentChildComponent.contractMeetingDetails);
  }
  else if(this._currentMeetingChildComponent==5 && CurrentSubIndex==1)
         {
           this._detail="Meeting not added";
           this._message="Changes will not be saved, Do you want to Continue?";
           this.currentChildComponent=this.childMeetingEditRecYesComponent;
           this._oldObject=this.currentChildComponent._oldContractMeetingDetails;
           this._newObject=JSON.stringify(this.currentChildComponent.contractMeetingDetails);
         }
  else if(CurrentTabIndex==3){

   if(this.actionType=="Create")
   {
     this._message='Associate will not be added, Do you want to Continue?';
     this._detail='Associate not added';
   }
   else{
   this._message='Changes will not be saved, Do you want to continue?';
   this._detail='Changes not saved';
   }
    // Sometime we are founding Child Component undefined here, thats why we are checking the condition otherwise application is getting hanged
   if(this.childTeamCreateEditComponent != undefined){
   this.currentChildComponent=this.childTeamCreateEditComponent; 
    this._oldObject=this.childTeamCreateEditComponent.oldTeamAssociateModel; 
    this._newObject=JSON.stringify(this.childTeamCreateEditComponent.teamAssociateData);
   }
   else{
     this._oldObject=""; 
     this._newObject="";
   }
  
 }
 if(this._oldObject!=this._newObject)
 {
   this.isformObjectChange=true;
 }
 else
 {
  this.isformObjectChange=false;
 }

}
  getFormattedValue(value: number, currencyName: string): string {
    return this.currencyService.getFormattedValue(value, currencyName);
  }
  getCurrencyType(currencyName: string): CurrencyType | undefined {
    return this.currencyService.getCurrencyType(currencyName);
  }
clearFormCompareObjects()
{
    this._oldObject=""; 
     this._newObject="";
     this.isformObjectChange=false;
}
BackToList() {
  if(this.EngagementId==undefined)
  {
  this.router.navigate(['/contracts']);
  }
  else{
    this.tabAndStateService.setPreviousState('Contracts', 2);
    this.router.navigate(['/ViewEngagement', this.EngagementId]);
  }
}

 getCommaSeparatedSupplierNames(ids: number[]): string {
  if(ids!=null && ids.length>0)
    {
     return this.notificationOptionsDataSource
    .filter((item: { id: number; }) => ids.includes(item.id))
    .map((item: { name: any; }) => item.name)
    .join(', ');
    }
    else{
      return "";
    }
}
  getCommaSeparatedSponsorNames(ids: number[]): string {
    if(ids!=null && ids.length>0)
    {
     return this.notificationOptionsDataSource
    .filter((item: { id: number; }) => ids.includes(item.id))
    .map((item: { name: any; }) => item.name)
    .join(', ');
    }
    else{
      return "";
    }
}



}
