<!DOCTYPE html>
<html lang="en">
<head>
    <title> Master Supplier </title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>

<div class="card flex justify-content-center">
   <p-breadcrumb class="max-w-full" [model]="getBreadcrumbs()"></p-breadcrumb>
</div>

  <!-- Confrim Popup Start -->
<p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>

  <!-- Confrim Popup END-->
 <div class="container page-title">
    <div class="flex-box-inside-pages"> 
   <div class="fr-flex__container">
      <div class="fr-flex__col-8">
         <div class="card flex justify-content-center gap-2"></div>
         <h1>{{heading}}</h1>
      </div>
   </div>
</div>
   
    <p-tabView class="fr-tabview__header__custom">
        <p-tabPanel> 
            <div class="card-pad"> 
        <form [formGroup]="supplierForm">
            <div class="fr-flex__container">
                <div class="fr-flex__col-6">
                    &nbsp;
                </div>
                <div class="fr-flex__col-6 custom-pad-right-zero " *ngIf="heading == 'Edit Supplier'">  
                    <div class="preview_section pull-right custom-m-r-2">
                        </div>
                    </div>
            </div>
            <!-- start form -->
            <div class="fr-flex__container">
                <div class="fr-flex__col-4">  
                        <div class="fr-form__group">
                            <label> Supplier Name <em> * </em> <span class="max-length"> {{_supplierProfile.name?.length}}/100 </span> </label>
                            <input type="text" formControlName="_supplierName" class="form-control" [maxlength]="100" [minlength]="3" pInputText [(ngModel)]="_supplierProfile.name"   (blur)="checkSupplierNameExist()" trimSpace/>
                            <small class="p-error" *ngIf="submitted && supplierForm?.get('_supplierName')?.hasError('required')">This field is required.</small>
                            <small class="p-error" *ngIf="uniqueSupplierNameMessage">Supplier Name already exists. Please enter a unique Supplier Name.</small>
                            <small class="p-error" *ngIf="submitted && supplierForm?.get('_supplierName')?.hasError('maxlength') ">Max length is 100 characters.</small>
                            
                            <small class="p-error" *ngIf="submitted && supplierForm?.get('_supplierName')?.hasError('onlySpaces')">This field cannot contain only spaces.</small>
                            <small class="p-error" *ngIf="submitted &&  !supplierForm?.get('_supplierName')?.hasError('onlySpaces') && ( supplierForm?.get('_supplierName')?.hasError('pattern') ||  supplierForm?.get('_supplierName')?.hasError('minlength'))">Min 3 Alpha characters required.</small>
                        </div>
                </div>
                <div class="fr-flex__col-4"> 
                        <div class="fr-form__group">              
                            <label> Supplier ID <em> * </em><span class="max-length"> {{_supplierProfile.supplierId.length}}/10 </span></label>
                            <input type="text" formControlName="_supplierId" class="form-control" [maxlength]="10" pInputText [(ngModel)]="_supplierProfile.supplierId" (blur)="checkExist()" trimSpace />
                            <small class="p-error" *ngIf="submitted && supplierForm?.get('_supplierId')?.hasError('required')">This field is required.</small>
                            <small class="p-error" *ngIf="uniqueMessage">Supplier ID already exists. Please enter a unique ID.</small>
                            <small class="p-error" *ngIf="submitted && supplierForm?.get('_supplierId')?.hasError('maxlength')">Max length is 10 characters.</small>
                            <small class="p-error" *ngIf="submitted && supplierForm?.get('_supplierId')?.hasError('minlength')">Min length is 3 characters.</small>
                            <small class="p-error" *ngIf="submitted && supplierForm?.get('_supplierId')?.hasError('onlySpaces')">This field cannot contain only spaces.</small>
                            <small class="p-error" *ngIf="submitted && supplierForm?.get('_supplierId')?.hasError('pattern')">Only alpha numeric characters and hyphen "-" are allowed and Spaces are not allowed</small>
                    
                        </div>
                    </div>
          
                <div class="fr-flex__col-4"> 
                            <div class="fr-form__group">
                                <label> Services <em> * </em> <span class="max-length-multiple-selected"> <span> {{_supplierProfile.supplierServices?.length || null}} </span> </span> </label>
                                <p-multiSelect appendTo="body" formControlName="_supplierServices" [options]="servicesDataSource" [(ngModel)]="_supplierProfile.supplierServices" placeholder="Select Services" optionValue="id" optionLabel="name" display="chip" (ngModelChange)="onServicesChange($event)"></p-multiSelect><br>
                                <small class="p-error" *ngIf="submitted && supplierForm?.get('_supplierServices')?.hasError('required')">This field is required.</small>
                        
                            </div>
                    </div>
            </div> 

            <div class="fr-flex__container">

                <div class="fr-flex__col-4"> 
                    <div class="fr-form__group">
                        <label> Website <em>  </em> <span class="max-length"> {{_supplierProfile.website.length}}/30 </span></label>
                        <input type="text" formControlName="_website" class="form-control" [maxlength]="30" pInputText [(ngModel)]="_supplierProfile.website" trimSpace/>
                        <small class="p-error" *ngIf="submitted && supplierForm?.get('_website')?.hasError('maxlength')">Max length is 30 characters.</small>
                        <small class="p-error" *ngIf="submitted && supplierForm?.get('_website')?.hasError('pattern')">Please enter valid website.</small>
                     
                    </div>
                    </div>
                    <div class="fr-flex__col-4">
                        <div class="fr-form__group">
                            <label> Locations <em> * </em> <span class="max-length-multiple-selected"> <span> {{_supplierProfile.supplierLocations?.length || null}} </span> </span> </label>
                            <p-multiSelect appendTo="body" formControlName="_supplierLocations" [options]="locationsDataSource" [(ngModel)]="_supplierProfile.supplierLocations" optionValue="id" placeholder="Select Locations" optionLabel="name" display="chip"></p-multiSelect><br>
                            <small class="p-error" *ngIf="submitted && supplierForm?.get('_supplierLocations')?.hasError('required')">This field is required.</small>
                        </div>
                     </div>
            </div>   

        <div class="fr-flex__container">
            <div class="fr-flex__col-12"> 
                <div class="fr-form__group"> 
                <label> Headquarters Address <span class="max-length"> {{_supplierProfile.headquartersAddress.length}}/300 </span> </label>
                <textarea formControlName="_headquartersAddress" class="form-control" [maxlength]="300" pInputTextarea [(ngModel)]="_supplierProfile.headquartersAddress" trimSpace ></textarea>
                <small class="p-error" *ngIf="submitted && supplierForm?.get('_headquartersAddress')?.hasError('maxlength')">Max length is 300 characters.</small>
                </div>
            </div> 
        </div>
        
        <div class="fr-flex__container">
                <div class="fr-flex__col-12">
                    <div class="fr-form__group"> 
                    <label> Notes and Comments<span class="max-length"> {{_supplierProfile.notesAndComments.length}}/300 </span> </label>
                    <textarea formControlName="_notesAndComments" class="form-control" [maxlength]="300" pInputTextarea [(ngModel)]="_supplierProfile.notesAndComments" trimSpace ></textarea>
                    <small class="p-error" *ngIf="submitted && supplierForm?.get('_notesAndComments')?.hasError('maxlength')">Max length is 300 characters.</small>
                    </div>
                </div>
        </div>

        
     </form>
    <!-- end form -->

              

    <!-- Add Contact Popup Start -->
            <!-- Heading row Start --> 
                <div class="fr-flex__container">
                <div class="fr-flex__col-8">
                    <h3>Contacts</h3>
                </div>
                <div class="fr-flex__col-4">
                    <a routerLinkActive="active" (click)="addContactShowDialog()" class="btn btn-primary pull-right"> Add Contact </a>
                </div>
                </div>

                  <!-- grid code -->

                    <div class="fr-flex__col-12">
                        <p-table class="primeng-table-custom masterdata_contacts" id="masterAddEditSupplierContacttablename" [resizableColumns]="true" columnResizeMode="expand" styleClass="p-datatable-striped p-datatable-gridlines" [value]="_supplierContacts" [scrollable]="true">
                            <ng-template pTemplate="header">
                                <tr>
                                    
                                    <th id="MasterDataAddEditSupplierName" pSortableColumn="contactName" pResizableColumn>Name <p-sortIcon field="contactName"></p-sortIcon></th>
                                    <th id="MasterDataAddEditSupplierDesignation" pSortableColumn="designation" pResizableColumn>Designation <p-sortIcon field="designation"></p-sortIcon></th>
                                    <th id="MasterDataAddEditSupplierEmail" pSortableColumn="email" pResizableColumn>Email <p-sortIcon field="email"></p-sortIcon></th>
                                    <th id="MasterDataAddEditSupplierCountryCode" pSortableColumn="countryCode" pResizableColumn>Country Code <p-sortIcon field="countryCode"></p-sortIcon></th>
                                    <th id="MasterDataAddEditSupplierPhoneNumber" pSortableColumn="phoneNumber" pResizableColumn>Phone Number <p-sortIcon field="phoneNumber"></p-sortIcon></th>
                                    <th id="MasterDataAddEditSupplierEdit">Edit</th>
                                    <th id="MasterDataAddEditSupplierDelete">Delete</th>

                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-contact let-rowIndex="rowIndex">
                                <tr>
                                 


                                    <td> {{ contact.contactName }} </td>
                                    <td [pTooltip]="contact.designation.length > 20 ? contact.designation : ''" tooltipPosition="top"> {{ contact.designation ? contact.designation : '--'  }} </td>
                                    <td [pTooltip]="contact.email.length > 20 ? contact.email : ''" tooltipPosition="top"> {{ contact.email }} </td>
                                    <td [pTooltip]="contact.countryCode.length > 10 ? contact.countryCode : ''" tooltipPosition="top"> {{ contact.countryCode ? contact.countryCode : '--'  }} </td>
                                    <td> {{ contact.phoneNumber ? contact.phoneNumber : '--' }} </td>
                                    <td id="masterAddEditSupplierContactEdit" class="grid-icon-center">  <i (click)="editContact(contact,rowIndex)" class="pi pi-pencil"></i> </td>
                                    <td id="masterAddEditSupplierContactDelete" class="grid-icon-center">  <i (click)="confirm(contact)" class="pi pi-trash custom-delete-btn"></i>  </td>

                                </tr>
                                
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                <td colspan="7" class="text-center"> No Records</td>
                                </tr>
                                </ng-template>
                        </p-table>
                        
                    </div>
                

                <!-- Heading row End -->
                
                <!-- Add contact Information Start -->
                
                 <!-- Add contact Information End -->
                <!-- Dialog Start -->
                <p-dialog appendTo="body" header="{{contactHeader}}" [(visible)]="displayDialog" [modal]="true" [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false" [closable]="false">
                    <ng-template pTemplate="content">
                    <form [formGroup]="contactForm">
                        <div class="fr-flex__container">
                        <div class="fr-flex__col-12">
                        <div class="fr-form__group">
                        <label>Name <em> * </em> <span class="max-length"> {{_supplierContact.contactName?.length}}/30 </span></label>
                        <input type="text"  formControlName="_contactName" class="form-control" [maxlength]="30" pInputText [(ngModel)]="_supplierContact.contactName"  (blur)="checkSupplierContactNameExist(_supplierContact)" trimSpace/>
                        <small class="p-error" *ngIf="contactSubmitted && contactForm?.get('_contactName')?.hasError('required')">This field is required.</small>
                        <small class="p-error" *ngIf="contactSubmitted && contactForm?.get('_contactName')?.hasError('maxlength')">Max length is 30 characters.</small>
                        <small class="p-error" *ngIf="uniqueSupplierContactNameMessage">Contact Name already exists. Please enter a unique contact name.</small>
                        <small class="p-error" *ngIf="contactSubmitted && contactForm?.get('_contactName')?.hasError('pattern')">Only alpha characters and spaces are allowed.</small>
                        <small class="p-error" *ngIf="contactSubmitted && contactForm?.get('_contactName')?.hasError('onlySpaces')">This field cannot contain only spaces.</small>
                    
                        
                        
    
                       </div>
                        </div>
                        </div>
                        <div class="fr-flex__container">
                            
                        
                            <div class="fr-flex__col-12">
                                <div class="fr-form__group">
                                    <label> Designation <span class="max-length"> {{_supplierContact.designation.length}}/100 </span></label>
                                    <input class="form-control" type="text" pInputText [pTooltip]="_supplierContact.designation!.length > 50 ? _supplierContact.designation!: ''" id="designation" [maxlength]="100" formControlName="_designation" [(ngModel)]="_supplierContact.designation" trimSpace />
                                    <small class="p-error" *ngIf="contactSubmitted && contactForm?.get('_designation')?.hasError('required')">This field is required.</small>
                                    <small class="p-error" *ngIf="contactSubmitted && contactForm?.get('_designation')?.hasError('maxlength')">Max length is 100 characters.</small>
                                    <small class="p-error" *ngIf="contactSubmitted && contactForm?.get('_designation')?.hasError('onlySpaces')">This field cannot contain only spaces.</small>
                                    
                                </div>
                        </div> 
    
                    </div>
                                
                        
    
                        <div class="display-flex">
                        <div class="fr-flex__col-3">  
                        <div class="fr-form__group">
                        <label> Country Code </label>
                        <p-dropdown appendTo="body" [filter]="true" appendTo="body" formControlName="_countryCode" [options]="locationsDataSource" [(ngModel)]="_supplierContact.countryCode" optionLabel="sub_Code" optionValue="sub_Code" placeholder="Select Country Code"></p-dropdown>
                        </div>
                        </div>
    
                        
    
                        <div class="fr-flex__col-9">
    
                        <div class="fr-form__group">
                        <label> Phone Number <span class="max-length"> {{_supplierContact.phoneNumber.length}}/15 </span> </label>
                        <input type="text" formControlName="_phoneNumber" class="form-control" [maxlength]="15" pInputText [(ngModel)]="_supplierContact.phoneNumber" trimSpace />
                        <small class="p-error" *ngIf="contactSubmitted && contactForm?.get('_phoneNumber')?.hasError('maxlength')">Max length is 15 characters.</small>
                        <small class="p-error" *ngIf="contactSubmitted && contactForm?.get('_phoneNumber')?.hasError('minlength')"> Min lenght is 7 characters.</small>
                        <small class="p-error" *ngIf="contactSubmitted && contactForm?.get('_phoneNumber')?.hasError('pattern')">Enter only numeric values.</small>
                        </div>
                        </div>
                        
                        </div>
                        <div class="fr-flex__container">
                            <div class="fr-flex__col-12">
                                <div class="fr-form__group">
                                <label> Email <em> * </em> <span class="max-length"> {{_supplierContact.email.length}}/100 </span></label>
                                <input class="form-control" type="text" pInputText [pTooltip]="_supplierContact.email!.length > 50 ? _supplierContact.email!: ''" id="email" [maxlength]="100" formControlName="_email" [(ngModel)]="_supplierContact.email" required pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}" />
                                <small class="p-error" *ngIf="contactSubmitted && contactForm?.get('_email')?.hasError('required')">This field is required.</small>
                                <small class="p-error" *ngIf="contactSubmitted && contactForm?.get('_email')?.hasError('maxlength')">Max length is 100 characters.</small>
                                <small class="p-error" *ngIf="contactSubmitted && contactForm?.get('_email')?.hasError('pattern')">Enter valid email.</small>
                                
                      
                                </div>
                        </div> 
                             </div>
                                             
                    </form>
                    </ng-template>
                    <!-- Dialog Footer Start -->
                    <ng-template pTemplate="footer">
                    <button  type="button" class="btn btn-primary pull-right" [class.disabled]="isSavePopupBtnDisable" (click)="isSavePopupBtnDisable ? null : saveContactData()"> Save </button>
                    <button type="button" class="btn btn-secondary pull-right" (click)="cancelDialog()"> Cancel </button>
                    </ng-template>
                    <!-- Dialog Footer End -->
                    </p-dialog>
                    <!-- Dialog End -->
                    <!-- Add Contact Popup End -->
    
                    <div class="card flex justify-content-center">
                        <!-- Save & Cancel Footer buttons Start -->
                    <div class="fr-flex__container footer_btns">
                        <div class="fr-flex__col-12">
                            <a routerLinkActive="active" [class.disabled]="isSaveBtnDisable" (click)="isSaveBtnDisable ? null : onSaveSupplierProfile()" type="button" class="btn btn-primary pull-right "> Save </a>
                            <a type="button" (click)="onCancelSupplierCreate()"  class="btn btn-secondary pull-right"> Cancel </a>
            
                        
                        </div>
                    </div>
                    <!-- Save & Cancel Footer buttons  End -->
                    </div>
                </div>
        </p-tabPanel> 
            </p-tabView>
    
   </div>

</body>
</html>