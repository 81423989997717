import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SponsorComponent } from './MasterData/Components/sponsor/sponsor.component';
import { CreatesponsorComponent } from './MasterData/Components/createsponsor/createsponsor.component';
import { ViewsponsorComponent } from './MasterData/Components/viewsponsor/viewsponsor.component';
//import { CreateSupplierComponent } from './Supplier/Components/create-supplier/create-supplier.component';
//import { SuppliersComponent } from './Supplier/Components/suppliers/suppliers.component';
//import { ViewSupplierComponent } from './Supplier/Components/view-supplier/view-supplier.component';
import { HomeComponent } from './Home/home/home.component';
import { CodelistComponent } from './MasterData/Components/codelist/codelist.component';
import { EditsponserComponent } from './MasterData/Components/editsponser/editsponser.component';
//import { EditSupplierComponent } from './Supplier/Components/edit-supplier/edit-supplier.component';
import { CreatecontractComponent } from './Contracts/Components/createcontract/createcontract.component';
import { ViewcontractComponent } from './Contracts/Components/viewcontract/viewcontract.component';
import { ContractlistComponent } from './Contracts/Components/contractlist/contractlist.component';
import { CanDeactivateGuard } from './shared/Guards/can-deactivate-guard.service';
import { EditcontractComponent } from './Contracts/Components/editcontract/editcontract.component';
import { LoginComponent } from './Login/login/login.component';
import { EngCreateMeetingComponent } from './Governance/meetings/Engagements/Components/eng-create-meeting/eng-create-meeting.component';
import { ForgotpasswordComponent } from './ForgotPassword/forgotpassword/forgotpassword.component';
import { ContInvoiceViewComponent } from './Finance/finances/Invoices/Contract/Components/cont-invoice-view/cont-invoice-view.component';
import { ContInvoiceCreateComponent } from './Finance/finances/Invoices/Contract/Components/cont-invoice-create/cont-invoice-create.component';
import { UserRoleMappingListComponent } from './Admin/Authorization/UserRoleMapping/Components/user-role-mapping-list/user-role-mapping-list.component';



import { ViewUserRoleMappingComponent } from './Admin/Authorization/UserRoleMapping/Components/view-user-role-mapping/view-user-role-mapping.component';
import { EditUserRoleMappingComponent } from './Admin/Authorization/UserRoleMapping/Components/edit-user-role-mapping/edit-user-role-mapping.component';
import { RolesPrivilegesComponent } from './Admin/Authorization/RolesPrivileges/Components/roles-privileges/roles-privileges.component';
import { DashboardComponent } from './Dashboard/Components/dashboard/dashboard.component';
import { privilegeGuard } from './shared/Guards/privilege.guard';


// Import the created AuthGuard
import { AuthGuard } from './shared/Authentication/auth.guard';


//const routes: Routes = [];

const routes: Routes = [
 // {path: '', canActivate:[AuthGuard], children: [
 { path: '', redirectTo: 'home', pathMatch: 'full' },
 { path: 'sponsor', component: SponsorComponent, canActivate:[AuthGuard]   },
 { path: 'createsponsor', component: CreatesponsorComponent,canDeactivate:[CanDeactivateGuard] ,canActivate: [AuthGuard,privilegeGuard],  data: { privileges: [Privileges.CreateSponsor]} } ,
 { path: 'viewsponsor', component: ViewsponsorComponent,canActivate: [AuthGuard,privilegeGuard],  data: { privileges: [Privileges.ViewSponsor,]}},
 //{ path: 'Suppliers', component: SuppliersComponent, canActivate:[AuthGuard] },
 //{ path: 'CreateSupplier', component: CreateSupplierComponent,canDeactivate:[CanDeactivateGuard],canActivate: [AuthGuard,privilegeGuard],  data: { privileges: [Privileges.CreateSupplier]}},
 {path: 'codelist', component: CodelistComponent, canActivate:[AuthGuard] },
 { path: 'editsponsor/:id', component: EditsponserComponent,canDeactivate:[CanDeactivateGuard],canActivate: [AuthGuard,privilegeGuard],  data: { privileges: [Privileges.EditSponsor]}},
 { path: 'viewsponsor/:sponserId', component: ViewsponsorComponent ,canActivate: [AuthGuard,privilegeGuard],  data: { privileges: [Privileges.ViewSponsor,]}},
 { path: 'viewsponsor', component: ViewsponsorComponent,canActivate: [AuthGuard,privilegeGuard],  data: { privileges: [Privileges.ViewSponsor,]}},
//  {path: 'home', component: HomeComponent,canActivate: [AuthGuard,privilegeGuard],  data: { privileges: [Privileges.ViewHomePage,]}},
  {path: 'home', component: EngagementsListComponent,canActivate: [AuthGuard,privilegeGuard],  data: { privileges: [Privileges.ViewHomePage,]}},
  { path: 'CreateEngagement', component: AddEditEngagementComponent,canDeactivate:[CanDeactivateGuard],canActivate: [AuthGuard,privilegeGuard],  data: { privileges: [Privileges.CreateEngagement]}},
  { path: 'EditEngagement/:id', component: AddEditEngagementComponent,canDeactivate:[CanDeactivateGuard],canActivate: [AuthGuard,privilegeGuard],  data: { privileges: [Privileges.EditEngagement]}},
  { path: 'ViewEngagement/:id', component: ViewEngagementComponent,canDeactivate:[CanDeactivateGuard], canActivate:[AuthGuard] },
  { path: 'ViewEngagement/:id/:tabId', component: ViewEngagementComponent,canDeactivate:[CanDeactivateGuard], canActivate:[AuthGuard] },  
  {path : 'createcontract', component: CreatecontractComponent,canDeactivate:[CanDeactivateGuard], canActivate:[AuthGuard] },
 //{path: 'EditSupplier/:id', component: EditSupplierComponent ,canDeactivate:[CanDeactivateGuard], canActivate:[AuthGuard] },
 {path : 'createcontract/:id', component: CreatecontractComponent,canDeactivate:[CanDeactivateGuard], canActivate:[AuthGuard] },
 {path : 'viewcontract', component: ViewcontractComponent,canDeactivate:[CanDeactivateGuard], canActivate:[AuthGuard] },
 {path : 'contracts', component: ContractlistComponent, canActivate:[AuthGuard] },
 //{ path: 'ViewSupplier/:supplierdata', component: ViewSupplierComponent,canDeactivate:[CanDeactivateGuard], canActivate:[AuthGuard] }, 
 { path: 'editcontract', component: EditcontractComponent,canDeactivate:[CanDeactivateGuard], canActivate:[AuthGuard] }, 
 //{path: 'login', component: LoginComponent, canActivate:[AuthGuard] },
 //{path: 'Forgotpassword', component: ForgotpasswordComponent, canActivate:[AuthGuard] },
 {path : 'ContractInvoiceView', component: ContInvoiceViewComponent, canActivate:[AuthGuard] },
 {path : 'ViewMasterSupplier/:id', component: MasterViewSupplierComponent, canActivate:[AuthGuard]} ,
 { path: 'SuppliersListMasterData', component: MasterSuppliersListComponent, canActivate:[AuthGuard] },
 { path: 'AddMasterSupplierData', component: MasterAddEditSupplierComponent,canDeactivate:[CanDeactivateGuard],canActivate: [AuthGuard,privilegeGuard],  data: { privileges: [Privileges.CreateSupplier]}},
 { path: 'EditMasterSupplierData/:id', component: MasterAddEditSupplierComponent,canDeactivate:[CanDeactivateGuard],canActivate: [AuthGuard,privilegeGuard],  data: { privileges: [Privileges.EditSupplier]}},
 {path : 'UserRoleMapping', component: UserRoleMappingListComponent, canActivate:[AuthGuard] },
 {
  path: 'Roles', 
  loadChildren: () => import('./Admin/Authorization/RolesPrivileges/roles-priveleges-module').then(m => m.RolesPrivelegesModule), canActivate:[AuthGuard]
 },
 {path : 'ViewUserRolesMapping/:id', component: ViewUserRoleMappingComponent, canActivate:[AuthGuard]},
 {path : 'EditUserRolesMapping/:id', component: EditUserRoleMappingComponent,canDeactivate:[CanDeactivateGuard], canActivate:[AuthGuard]},
 {path : 'KPITrendDashboard', component: DashboardComponent, canActivate:[AuthGuard]},
{path : 'Dashboards', component: DashboardhomeComponent},
{path : 'FinanceDashboard', component: FinancedashboardComponent, canActivate:[AuthGuard]},
{path : 'TeamsDashboard', component: TeamsdashboardComponent, canActivate:[AuthGuard]},
 {path : 'UserAdmin', component: UserAdminComponent, canActivate:[AuthGuard]} ,
 {path : 'UserProfile', component: UserProfileComponent, canActivate:[AuthGuard]},
 
 // ]}
];

import { UserAdminComponent } from './Admin/UserAdmin/Components/user-admin/user-admin.component';
import { Privileges } from './shared/constants/privileges.constants';
import { EngagementsListComponent } from './Engagement/Components/engagements-list/engagements-list.component';
import { AddEditEngagementComponent } from './Engagement/Components/add-edit-engagement/add-edit-engagement.component';
import { ViewEngagementComponent } from './Engagement/Components/view-engagement/view-engagement.component';
import { MasterViewSupplierComponent } from './MasterData/supplier/Components/master-view-supplier/master-view-supplier.component';
import { MasterSuppliersListComponent } from './MasterData/supplier/Components/master-suppliers-list/master-suppliers-list.component';
import { MasterAddEditSupplierComponent } from './MasterData/supplier/Components/master-supplier-add-edit-component/master-add-edit-supplier.component';
import { UserProfileComponent } from './Admin/Authorization/UserRoleMapping/Components/user-profile/user-profile.component';
import { DashboardhomeComponent } from './Dashboard/Components/dashboardhome/dashboardhome/dashboardhome.component';
import { FinancedashboardComponent } from './Dashboard/Components/financedashboard/financedashboard/financedashboard.component';
import { TeamsdashboardComponent } from './Dashboard/Components/teamsdashboard/teamsdashboard/teamsdashboard.component';

@NgModule({
  
  imports: [RouterModule.forRoot(routes)
],
  exports: [RouterModule],
  providers: [CanDeactivateGuard]
})
export class AppRoutingModule { }
