import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { HttpParams } from '@angular/common/http';

import { environment } from "src/environments/environment";
import {map} from 'rxjs/operators'
import { EngagementMeetingDetails } from "../Modals/engagementmeeting";
import { ContractMeetingDetails } from "../../Contracts/Modals/ContractMeeting";
import { AuthCommonService } from "src/app/shared/Authentication/auth.service";



@Injectable({

  providedIn: 'root'

})

export class GovernanceService{
    //apiURL = environment._contractApiBaseURl;
    readonly _governanceMeetingApiBaseURl?:string="";
    readonly _fileApiBaseURl?:string="";
    _meetingDetail:any

  constructor(private http: HttpClient,private authCommonService: AuthCommonService) { 
    this._governanceMeetingApiBaseURl=environment?._governanceApiBaseURl;
    this._fileApiBaseURl=environment?._fileApiBaseURl;
  }

  public setMeetingDetails(data:any){
    this._meetingDetail=data;
  }
  
  //public SupplierMeetingData(MeetingData:SupplierMeetingDetails): Observable<any> {
  //  const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
  //  return this.http.post<any>(this._governanceMeetingApiBaseURl +  '/GovernanceMeetings/AddSupplierMeeting', MeetingData,httpOptions);
  //}

  public getSupplierMeetingsBySupplierId(supplierProfileId:number): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
    return this.http.get<any>(this._governanceMeetingApiBaseURl +  '/GovernanceMeetings/GetSupplierMeetingsBySupplierId?supplierProfileId=' + supplierProfileId, httpOptions);
  }

  public getSupplierMeetingsById(supplierMeetingId:number): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.get<any>(this._governanceMeetingApiBaseURl +  '/GovernanceMeetings/GetSupplierMeetingById?supplierMeetingId=' + supplierMeetingId, httpOptions);
  }

  //public SupplierUpdateMeetingData(MeetingData:SupplierMeetingDetails): Observable<any> {
  //  const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
  //  return this.http.post<any>(this._governanceMeetingApiBaseURl +  '/GovernanceMeetings/UpdateSupplierMeeting', MeetingData,httpOptions);
  //}

    public getContractMeetingsByContractId(contractId: number): Observable<any> {
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
        return this.http.get<any>(this._governanceMeetingApiBaseURl + '/GovernanceMeetings/GetContractMeetingsByContractId?contractId=' + contractId, httpOptions);
    }
 
  public ContractUpdateMeetingData(MeetingData:ContractMeetingDetails): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.post<any>(this._governanceMeetingApiBaseURl +  '/GovernanceMeetings/UpdateContractMeeting', MeetingData,httpOptions);
  }

  public getContractMeetingsById(contractMeetingId:number): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
    return this.http.get<any>(this._governanceMeetingApiBaseURl +  '/GovernanceMeetings/GetContractMeetingById?contractMeetingId=' + contractMeetingId, httpOptions);
  }
  public getEngaementMeetingsByEngaementId(engagementId:number): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
    return this.http.get<any>(this._governanceMeetingApiBaseURl +  '/GovernanceMeetings/GetEngagementMeetingsByEngagementId?engagementId=' + engagementId, httpOptions);
  }
  public EngagementMeetingData(MeetingData:EngagementMeetingDetails): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
    return this.http.post<any>(this._governanceMeetingApiBaseURl +  '/GovernanceMeetings/AddEngagementMeeting', MeetingData,httpOptions);
  }
  public getEngagementMeetingsById(engagementMeetingId:number): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.get<any>(this._governanceMeetingApiBaseURl +  '/GovernanceMeetings/GetEngagementMeetingById?engagementMeetingId=' + engagementMeetingId, httpOptions);
  }
  public EngagementUpdateMeetingData(MeetingData:EngagementMeetingDetails): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
    return this.http.post<any>(this._governanceMeetingApiBaseURl +  '/GovernanceMeetings/UpdateEngagementMeeting', MeetingData,httpOptions);
  }
    DeleteEngagementMeeting(engagementMeetingId: any, occurrenceId: any): Observable<any> {
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'ApiKey': this.authCommonService.getAuthenticationToken() }) };
      return this.http.put<any>(`${this._governanceMeetingApiBaseURl}/GovernanceMeetings/DeleteEngagementMeetingById?engagementMeetingId=${engagementMeetingId}&occurrenceId=${occurrenceId}`, {}, httpOptions);
    }
}
