<!DOCTYPE html>
<html lang="en">
<head>
   <title> Create Sponsor </title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>
<p-toast position="top-center"
   key="tc"></p-toast>
   <div class="card flex justify-content-center">
      <p-breadcrumb class="max-w-full" [model]="getBreadcrumbs()"></p-breadcrumb>
     </div>
       <!-- Confrim Popup Start -->
  <p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
  <!-- Confrim Popup END-->
<div class="container page-title">
   <div class="flex-box-inside-pages"> 
<div class="fr-flex__container">
   <div class="fr-flex__col-8">
      <h1>Create Sponsor</h1>
   </div>
</div>
</div>
   <p-tabView class="fr-tabview__header__custom">
      <p-tabPanel>
         <div class="card-pad"> 
      <form [formGroup]="myForm">
   <div class="fr-flex__container">
      <div class="fr-flex__col-4">
         <div class="fr-form__group">
            <label> Sponsor Name <em> * </em> <span class="max-length"> {{sponserInfo.name?.length || 0}}/100 </span></label>
            <input type="text" id="creSponsorNameInput" class="form-control" formControlName="sponserName" [maxlength]="100" [minlength]="3" pInputText [(ngModel)]="sponserInfo.name" (ngModelChange)="checkSponsorNameExist()" trimSpace/>
            <small class="p-error" *ngIf="submitted && myForm?.get('sponserName')?.hasError('required')">  This field is required. </small>
            <small class="p-error" *ngIf="uniqueSponsorNameMessage">Sponsor Name already exists. Please enter a unique Sponsor Name.</small>
            <small class="p-error" *ngIf="submitted && myForm?.get('sponserName')?.hasError('maxlength')">   Maximum length is 100 characters. </small>
            <small class="p-error" *ngIf="submitted && myForm?.get('sponserName')?.hasError('minlength')">   Minimum length is 3 characters.</small>
            <small class="p-error" *ngIf="myForm.get('sponserName')?.hasError('onlySpaces')">Only spaces not allowed.</small> 
            <small class="p-error" *ngIf="submitted &&  !myForm?.get('sponserName')?.hasError('onlySpaces') && ( myForm?.get('sponserName')?.hasError('pattern') ||  myForm?.get('sponserName')?.hasError('minlength'))">Min 3 Alpha characters required.</small>
         </div>
      </div>
     

    
   </div>
   <div class="fr-flex__container">
      <div class="fr-flex__col-12">
         <div class="fr-form__group">
            <label> Address <span class="max-length"> {{sponserInfo.address?.length || 0}}/300 </span> </label>
            <textarea id="creSponsorAddressTextarea" class="form-control" [maxlength]="300" pInputTextarea formControlName="sponserAddress" [(ngModel)]="sponserInfo.address" trimSpace ></textarea>
            <small *ngIf="myForm?.get('sponserAddress')?.touched && myForm?.get('sponserAddress')?.hasError('maxlength')" class="p-error">  Maximum length is 300 characters. </small>
         </div>
      </div>
   </div>

   <div class="fr-flex__container">
      <div class="fr-flex__col-12">
         <div class="fr-form__group"> 
            <label> Notes and Comments <span class="max-length"> {{sponserInfo.notesAndComments?.length || 0}}/300 </span> </label>
            <textarea id="creSponsorNotesCommentsTextarea" class="form-control" formControlName="sponserNotesAndComments" [maxlength]="300" pInputTextarea [(ngModel)]="sponserInfo.notesAndComments" trimSpace ></textarea>
            <small *ngIf="myForm?.get('sponserNotesAndComments')?.touched && myForm?.get('sponserNotesAndComments')?.hasError('maxlength')" class="p-error">  Maximum length is 300 characters. </small>
         </div>
      </div>
   </div>
</form>
<div class="fr-flex__container">
   <div class="fr-flex__col-8">
      <h3>Contacts</h3>
   </div>
   <div class="fr-flex__col-4">
      <a  class="btn btn-primary pull-right" (click)="openContactModel()"> Add Contact </a>
   </div>
</div>
<!-- Grid Code Start -->
<div class="fr-flex__col-12">
   <p-table class="primeng-table-custom masterdata_contacts" [resizableColumns]="true" columnResizeMode="expand" styleClass="p-datatable-striped p-datatable-gridlines" [value]="sponserContactList" [scrollable]="true">
       <ng-template pTemplate="header">
           <tr>
               
               <th id="MasterDataCreateSponsorName" pSortableColumn="name" pResizableColumn>Name <p-sortIcon field="name"></p-sortIcon></th>
               <th id="MasterDataCreateSponsorDesignation" pSortableColumn="designation" pResizableColumn>Designation <p-sortIcon field="designation"></p-sortIcon></th>
               <th id="MasterDataCreateSponsorEmail" pSortableColumn="email" pResizableColumn>Email <p-sortIcon field="email"></p-sortIcon></th>
               <th id="MasterDataCreateSponsorCountryCode" pSortableColumn="countryCode" pResizableColumn>Country Code <p-sortIcon field="countryCode"></p-sortIcon></th>
               <th id="MasterDataCreateSponsorPhoneNumber" pSortableColumn="phoneNumber" pResizableColumn>Phone Number <p-sortIcon field="phoneNumber"></p-sortIcon></th>
               <th id="MasterDataCreateSponsorEdit">Edit</th>
               <th id="MasterDataCreateSponsorDelete">Delete</th>

           </tr>
       </ng-template>
       <ng-template id="CreSpoContactGrid" pTemplate="body" let-contact let-rowIndex="rowIndex">
           <tr>
               <td id="MasterDataCreateSponsorNameValue"> {{ contact.name }} </td>
               <td id="MasterDataCreateSponsorDesignationValue" [pTooltip]="contact.designation.length > 20 ? contact.designation : ''" tooltipPosition="top"> {{ contact.designation ? contact.designation : '--'  }} </td>
               <td id="MasterDataCreateSponsorEmailValue" [pTooltip]="contact.email.length > 20 ? contact.email : ''" tooltipPosition="top"> {{ contact.email }} </td>
               <td id="MasterDataCreateSponsorCounCodeValue" [pTooltip]="contact.countryCode.length > 10 ? contact.countryCode : ''" tooltipPosition="top"> {{ contact.countryCode ? contact.countryCode : '--'  }} </td>
               <td id="MasterDataCreateSponsorPhnNumValue"> {{ contact.phoneNumber ? contact.phoneNumber : '--' }} </td>
               <td id="GridCreatSponsorContact" class="grid-icon-center">  <i (click)="editContact(contact,rowIndex)" class="pi pi-pencil"></i> </td>
               <td id="GridDeleteSponsorContact" class="grid-icon-center">  <i (click)="deleteContact(rowIndex)" class="pi pi-trash custom-delete-btn"></i>  </td>

           </tr>
           
       </ng-template>
       <ng-template pTemplate="emptymessage">
         <tr>
         <td colspan="7" class="text-center"> No Records</td>
         </tr>
         </ng-template>
   </p-table>
</div>

<!-- Grid Code End -->

<div class="fr-flex__container footer_btns">
   <div class="fr-flex__col-12">
      <a type="button" routerLinkActive="active" class="btn btn-primary btn-save pull-right" [class.disabled]="isSaveBtnDisable" (click)="isSaveBtnDisable ? null : saveSponserData()"> Save </a>
      <a type="button" class="btn btn-secondary pull-right" (click)="BackToListPage()"> Cancel </a>
   </div>
</div>
<p-dialog appendTo="body" header="{{contactHeader}}" appendTo="body" [(visible)]="displayDialog" [modal]="true" [style]="{ width: '40vw' }" [resizable]="true" [closable]="false">
<ng-template pTemplate="content">
   <form [formGroup]="dialogFormGroup">
      <div class="fr-flex__container">
         <div class="fr-flex__col-12">
            <div class="fr-form__group">
               <label for="name">Name<em> * </em> <span class="max-length"> {{sponserContact.name?.length || 0}}/30 </span> </label>
               <input type="text" id="creNameInput" id="contactName" class="form-control" formControlName="contactName" [maxlength]="30" pInputText [(ngModel)]="sponserContact.name" (blur)="creSponcheckSponsorContactNameExist(sponserContact)" trimSpace />
               <small class="p-error" *ngIf="(contactSubmitted || dialogFormGroup?.get('contactName')?.touched) && dialogFormGroup?.get('contactName')?.hasError('required')"> This field is required. </small>
               <small class="p-error" *ngIf="(contactSubmitted || dialogFormGroup?.get('contactName')?.touched) && dialogFormGroup?.get('contactName')?.hasError('maxlength')">  Maximum length is 30 characters. </small>
               <small class="p-error" *ngIf="(contactSubmitted || dialogFormGroup?.get('contactName')?.touched) && dialogFormGroup?.get('contactName')?.hasError('pattern')">Only alpha characters and spaces are allowed.</small>
               <small class="p-error" *ngIf="(contactSubmitted || dialogFormGroup?.get('contactName')?.touched) && dialogFormGroup?.get('contactName')?.hasError('onlySpaces')">This field cannot contain only spaces.</small>
               <small class="p-error" *ngIf="uniqueSponsorContactNameMessage">Contact Name already exists. Please enter a unique contact name.</small>
            </div>
         </div>
      </div>

      <div class="fr-flex__container">
         <div class="fr-flex__col-12">
            <div class="fr-form__group">
               <label for="name">Designation <span class="max-length"> {{sponserContact.designation?.length || 0}}/100 </span> </label>
               <input type="text" id="creDesignationInput" id="ediSponcontactDesignation" class="form-control" formControlName="contactDesignation" [maxlength]="100" [minlength]="3" pInputText [(ngModel)]="sponserContact.designation" trimSpace />
               <small class="p-error" *ngIf="(contactSubmitted || dialogFormGroup?.get('contactDesignation')?.touched) && dialogFormGroup?.get('contactDesignation')?.hasError('maxlength')">  Maximum length is 100 characters. </small>
               <small class="p-error" *ngIf="(contactSubmitted || dialogFormGroup?.get('contactDesignation')?.touched) && dialogFormGroup?.get('contactDesignation')?.hasError('minlength')">  Minimum length is 3 characters.</small>
               <small class="p-error" *ngIf="(contactSubmitted || dialogFormGroup?.get('contactDesignation')?.touched) && dialogFormGroup?.get('contactDesignation')?.hasError('onlySpaces')">This field cannot contain only spaces.</small>
            </div>
         </div>
      </div>

      <div class="display-flex">
         <div class="fr-flex__col-2">
            <div class="fr-form__group">
               <label>Country Code</label>
               <p-dropdown id="creSponsorCountryCodeDropdown" appendTo="body" [filter]="true" [options]="countryCodes" formControlName="countryCode" [(ngModel)]="sponserContact.countryCode" optionLabel="sub_Code" optionValue="sub_Code" placeholder="Select Country Code"></p-dropdown>
            </div>
         </div>
         <div class="fr-flex__col-8">
            <div class="fr-form__group">
               <label>Phone Number <span class="max-length"> {{sponserContact.phoneNumber?.length || 0}}/15 </span> </label>
               <input id="creSponsorPhoneNumberInput" class="form-control" type="text" pInputText [maxlength]="15" id="phoneNumber" formControlName="phoneNumber" [(ngModel)]="sponserContact.phoneNumber"  pattern="^[0-9]+$" />
               <small class="p-error" *ngIf="(contactSubmitted || dialogFormGroup?.get('phoneNumber')?.touched) && dialogFormGroup?.get('phoneNumber')?.hasError('minlength')"> Minimum length is 7 characters. </small>
               <small class="p-error" *ngIf="(contactSubmitted || dialogFormGroup?.get('phoneNumber')?.touched) && dialogFormGroup?.get('phoneNumber')?.hasError('maxlength')"> Maximum length is 15 characters. </small>
               <small class="p-error" *ngIf="(contactSubmitted || dialogFormGroup?.get('phoneNumber')?.touched) && dialogFormGroup?.get('phoneNumber')?.hasError('pattern')">Please enter only numeric numbers.</small>
            </div>
         </div>
      </div>
      <div class="fr-flex__container">
         <div class="fr-flex__col-12">
            <div class="fr-form__group">
               <label>Email<em> * </em> <span class="max-length"> {{sponserContact.email?.length || 0}}/100 </span></label>
               <input id="creSponsorEmailInput" class="form-control" type="text" pInputText id="email" [maxlength]="100" formControlName="email" [(ngModel)]="sponserContact.email" required pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"/>
               <small class="p-error" *ngIf="(contactSubmitted || dialogFormGroup?.get('email')?.touched) && dialogFormGroup?.get('email')?.hasError('required')"> This field is required. </small>
               <small class="p-error" *ngIf="(contactSubmitted || dialogFormGroup?.get('email')?.touched) && dialogFormGroup?.get('email')?.hasError('maxlength')"> Maximum length is 100 characters. </small>
               <small class="p-error" *ngIf="(contactSubmitted || dialogFormGroup?.get('email')?.touched) && dialogFormGroup?.get('email')?.hasError('pattern')">Please enter valid email</small>
            </div>
         </div>
      </div>
   </form>
</ng-template>
<!-- Dialog Footer Start -->
<ng-template pTemplate="footer">
   <button  type="button" class="btn btn-primary pull-right" [class.disabled]="isSavePopupBtnDisable" (click)="isSavePopupBtnDisable ? null : SaveContact()"> Save </button>
   <button type="button" class="btn btn-secondary pull-right" (click)="closeDialog()"> Cancel </button>
</ng-template>
<!-- Dialog Footer End -->
</p-dialog>
</div>
</p-tabPanel>
</p-tabView>
</div>
</body>
</html>