<!DOCTYPE html>
<html lang="en">
<head>
  <title> Contract Accruals List </title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>
  <p-tabView class="fr-tabview__header__custom"> 
    <p-tabPanel> 
      <div class="flex-box m-t-70"> 
      <div class="fr-flex__col-12"> 
      <div class="fr-flex__container">
        <div class="fr-flex__col-8">
        </div>
        <div class="fr-flex__col-4">
          <button *ngIf="ExportPrivilage" class="btn btn-outline-primary pull-right" [disabled]="isChecked" (click)="exportContractAccurals()"><i class="pi pi-download"></i> Export</button>
        </div>
    </div>
  </div>
</div>
    <div class="fr-flex__col-12">
      <div class="inside-grid-pad"> 
  <p-table class="primeng-table-custom custom-cells_draft-invoices"
    [scrollable]="true" [resizableColumns]="true" columnResizeMode="expand" styleClass="p-datatable-striped p-datatable-gridlines"
    [value]="_contractAccrualListData" 
    [(selection)]="selectedContractAccrual"
    editMode="row"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Entries"
    [rowsPerPageOptions]="[10, 20, 50, 100, 200]"
     [tableStyle]="{ 'min-width': '50rem' }">
      <ng-template pTemplate="header">
          <tr>  
            <th id="contractAccrualsListTHCheckbox" class="first-child-width">
              <p-tableHeaderCheckbox (click)="checkboxEnabled()"></p-tableHeaderCheckbox>
          </th>               
            <th id="conAccrualListMonth" pSortableColumn="accrualDate" pResizableColumn>Month <p-sortIcon field="accrualDate"></p-sortIcon></th>
            <th id="conAccrualListAmount" pSortableColumn="accrualAmount" pResizableColumn>Draft Invoices Amount <p-sortIcon field="accrualAmount"></p-sortIcon></th>
            <th id="conAccrualListStatus" pSortableColumn="accrualStatus" pResizableColumn>Status <p-sortIcon field="accrualStatus"></p-sortIcon></th>
            <th id="conAccrualListRemarks" pSortableColumn="remarks" pResizableColumn>Remarks <p-sortIcon field="remarks"></p-sortIcon></th>
            <th class="last-child-width" id="conAccrualListEdit" *ngIf="editPrivilege" class="last-child-width">Edit</th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-contractAccrual> 
          <tr>
            <td id="contractAccrualsListTDCheckbox" class="first-child-width">
              <p-tableCheckbox (click)="checkboxEnabled()" [value]="contractAccrual"></p-tableCheckbox>
          </td>
            <td routerLinkActive="active" >
               <a id="contractAccrualsListHyperLink" class="table-active-link" (click)="viewAccrual(contractAccrual)" *ngIf="viewPrivilege; else disabledLink"> {{ contractAccrual.accrualDate | date:'MMMM yyyy' }} </a>
               <ng-template #disabledLink>
                {{ contractAccrual.accrualDate | date:'MMMM yyyy'}}
              </ng-template>
              </td>
            <td> <span  *ngIf="contractAccrual.accrualStatus!=null">{{ getFormattedValue(contractAccrual.accrualAmount, currency) }}</span> <span  *ngIf="contractAccrual.accrualStatus==null">--</span></td>
            <td> <span class="ellipsis-content" *ngIf="contractAccrual.accrualStatus!=null">{{ contractAccrual.accrualStatus }}</span> <span class="ellipsis-content" *ngIf="contractAccrual.accrualStatus==null">--</span></td>
            <td class="grid-icon-center"><span><i class="pi pi-info-circle"  [pTooltip]="(contractAccrual.remarks!=null&& contractAccrual.remarks.length >0)?contractAccrual.remarks:'Not Available'"></i></span></td>
            <td id="contractAccrualsListEdit" *ngIf="editPrivilege" class="grid-icon-center"><span><i class="pi pi pi-pencil" (click)="editAccrual(contractAccrual)"></i></span></td>

          </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
        <td colspan="6" class="text-center"> No Records</td>
        </tr>
        </ng-template>
  </p-table>
</div> 
  </div>
</p-tabPanel>
</p-tabView>
</body>
</html>


