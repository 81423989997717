
export class ContractAccrual
{
    id?:number;
    accrualDate?: any;
    accrualAmount?:number;
    remarks?: string;
    statusMappingId?: number=0;
    accrualStatus?: string;
    createdBy: number=0;
    createdDate?: Date;
    updatedBy?: number=0;
    updatedDate?: Date;
    isDeleted?: Boolean;
    financeContractAccrualAttachments?:AccrualAttachments[];
}


  export class ContracDetails {
    contractDetailId?:number;
    contractStartDate?:any;
    contractEndDate?: any;
  }

  
export class CodeListValues {

    name?: string;
    id?: number;
    code?:string;
    phoneCode?:string;
  }

export class AccrualAttachments {
  id: number=0;
  fileName?:string='';
  filePath?:string='';
  financeContractAccrualId: number=0;
  createdBy?: number=0;
  ceatedDate?: Date;
  updatedBy?:number=0;
  updatedDate?: Date;
  isDeleted?: Boolean;
  isPrevFileDelete?:Boolean=false;
}

export class AccrualChildComponents
{
    contAccrualList:number=1;
    contAccrualEdit:number=2;
    contAccrualView:number=3;
}

export class AccuralEmailPayload
{
 
  SupplierId:number=0;
  SponsorId: number=0;
  ContractId?:Number=0;
  AccrualAmount?: any;
  AcrualMonthYear?: any;
  ContractTitle?: any;
  EngagementName?: any;
  
}