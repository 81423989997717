import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS, HttpResponse } from '@angular/common/http';
import { AuthCommonService } from '../shared/Authentication/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  readonly _kontractProAppURl?: string = "";
  readonly _loginPortalAppURl?: string = "";
  readonly _domainName?: string = "";
  constructor(private authCommonService: AuthCommonService, private cookieService:CookieService) {
    this._kontractProAppURl=environment?._kontractProAppURl;
    this._loginPortalAppURl=environment?._loginPortalAppURl;
    this._domainName=environment?._appDomainName;
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const clonedRequest = req.clone({
   
    });

   // Handle the request
   return next.handle(clonedRequest).pipe(
    // Handle successful responses
    tap(evt => {
      if (evt instanceof HttpResponse) {
      }
    }),
    // Handle errors
    catchError(error => {
      if(error.status==401 && error.error=="Unauthorized")
      {
        this.cookieService.delete("LOGIN_TOKEN", "/", this._domainName);
        this.cookieService.delete("EMAIL", "/", this._domainName, true, "None");
        this.cookieService.deleteAll();
        let loginURL = this._loginPortalAppURl + '?redirect=' + this._kontractProAppURl;
        window.location.href = loginURL;  // Redirect to your login application URL
        
      }
      return throwError(() => error);
    })
  );
}


  public setHeaders() {
    let headersList = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    
    }
    return headersList;
  }
  }

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
];
