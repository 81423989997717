
 <!DOCTYPE html>
 <html lang="en">
 <head>
    <title> Engagement Meeting List</title>
     <meta charset="UTF-8">
     <meta name="viewport" content="width=device-width, initial-scale=1.0">
     <meta http-equiv="X-Content-Type-Options" content="nosniff">
 </head>
 <body>
   <p-tabView class="fr-tabview__header__custom">
            <p-tabPanel>    
                <div class="flex-box m-t-70"> 
                <div class="fr-flex__col-12"> 
                <div class="fr-flex__container">
                    <div class="fr-flex__col-8">
                        <span class="number_of_records"> <em> ({{ filteredRecordCount > -1 ? filteredRecordCount : totalRecordCount }} of {{ totalRecordCount }} Records) </em> </span>
                    </div>
                    <!-- (click)="AddSupplierKPI()" -->
                    <div class="fr-flex__col-4">  
                        <a *ngIf="createPrivilege"  class="btn btn-primary pull-right" (click)="addEngagementMeeting()"> Add Meeting </a>
                        <button *ngIf="ExportPrivilage"  class="btn btn-outline-primary pull-right" [disabled]="isChecked" (click)="ExportEngagementMeetings()"><i class="pi pi-download"></i> Export</button>
                      
                      
                    </div>
                </div>
            </div>
        </div>
        <div class="fr-flex__col-12"> 
            
                <div class="inside-grid-pad"> 
        <p-table id="engMeetingListTableName" class="primeng-table-custom custom-cells__sup-meeting-list" [resizableColumns]="true" columnResizeMode="expand"
        [scrollable]="true" styleClass="p-datatable-striped p-datatable-gridlines"
        [value]="_engagementMeetingListData" 
        [(selection)]="selectedEngagementMeeting"
        editMode="row"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Entries"
        [rowsPerPageOptions]="[10, 20, 50, 100, 200]"
         [tableStyle]="{ 'min-width': '50rem' }">
            <ng-template pTemplate="header">
                <tr>      
                    <th id="engMeetingListTHCheckbox" pFrozenColumn class="first-child-width">
                        <p-tableHeaderCheckbox (click)="CheckboxEnabled()"></p-tableHeaderCheckbox>
                    </th>            
                    <th id="engMeetingListTitle" pFrozenColumn pSortableColumn="title" pResizableColumn>Meeting Title <p-sortIcon field="title"></p-sortIcon></th>
                    <th id="engMeetingListDate" pSortableColumn="occurrenceDate" pResizableColumn>Date <p-sortIcon field="occurrenceDate"></p-sortIcon></th>
                    <th id="engMeetingListRecurring" pSortableColumn="recurring" pResizableColumn pInputText>Recurring <p-sortIcon field="module"></p-sortIcon></th>
                    <th id="engMeetingListOrganizer" pSortableColumn="organizer" pResizableColumn>Organizer <p-sortIcon field="organizer"></p-sortIcon></th>
                    <th id="engMeetingListOpenActions" pSortableColumn="openActionsCount" pResizableColumn>Open Actions <p-sortIcon field="openActionsCount"></p-sortIcon>​</th>
                    <th id="engMeetingListEdit" *ngIf="editPrivilege" class="last-child-width">Edit</th>
                    
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-engagementMeeting> 
                <tr>
                    <td id="engMeetingListTDCheckbox" pFrozenColumn class="first-child-width">
                        <p-tableCheckbox (click)="CheckboxEnabled()" [value]="engagementMeeting"></p-tableCheckbox>
                    </td>
                     <td id="engMeetingListHyperLink" pFrozenColumn routerLinkActive="active"> <a *ngIf="viewPrivilege; else disabledLink"  (click)="viewEngagementMeeting(engagementMeeting)"class="table-active-link">{{ engagementMeeting.title }}</a>
                        <ng-template #disabledLink>
                            {{ engagementMeeting.title}}
                          </ng-template> 
                    </td>
                    
                    <td> <span *ngIf="!engagementMeeting.recurring"> {{ engagementMeeting.occurrenceDate | date:_dateformart.GridDateFormat}} </span> <span *ngIf="engagementMeeting.recurring"> NA </span></td>
                    <td> <span *ngIf="!engagementMeeting.recurring"> No </span> <span class="ellipsis-content" *ngIf="engagementMeeting.recurring"> Yes </span></td>
                    <td [pTooltip]="engagementMeeting.organizer.length > 50 ? engagementMeeting.organizer : ''" tooltipPosition="top"> <span>{{ engagementMeeting.organizer }}</span></td>
                    <td> <span *ngIf="engagementMeeting.openActionsCount > 0">{{ engagementMeeting.openActionsCount }}</span> <span *ngIf="engagementMeeting.openActionsCount == 0">0</span></td>
                    <td id="engMeetingListEdit"  *ngIf="editPrivilege" class="grid-icon-center"><span class="pi pi-pencil" (click)="editEngagementMeeting(engagementMeeting.id,engagementMeeting.recurring)"></span></td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                <td colspan="7" class="text-center"> No Records</td>
                </tr>
                </ng-template>
        </p-table>
    </div>
    </div>
    
    </p-tabPanel>
    </p-tabView>
    </body>
   </html>

   
