import { DatePipe, formatDate } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, NgForm, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TabView } from 'primeng/tabview';
import {  } from 'rxjs';
import { ContractService } from 'src/app/Contracts/Services/contract.service';
import { EngagementService } from 'src/app/Engagement/Services/engagement.service';
import { FileUploadService } from 'src/app/Engagement/Services/fileupload.service';
import { FinanceInvoiceAttachments, InvoiceDetails, InvoiceEmailPayload } from 'src/app/Finance/finances/Model/ContractInvoice';
import { FinanceService } from 'src/app/Finance/Services/invoice.service';

import { LoaderService } from 'src/app/Loader/loader.service';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';
import { CurrencyService } from 'src/app/Reusable/currency.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { AuditIdentifier, AuditLogEntities, AuditLogModule, AuditOperations, CodeList, CodeListStatusTypes, Dateformat, StatusCodes,CurrencyType } from 'src/app/shared/constants/global.constants';
import { AuditLogService } from 'src/app/shared/ModelComparison/audit.log.service';
import { NotificationService } from 'src/app/shared/Notifications/notification.service';

@Component({
  selector: 'app-cont-invoice-create',
  templateUrl: './cont-invoice-create.component.html',
  styleUrls: ['./cont-invoice-create.component.css']
})
export class ContInvoiceCreateComponent {
  @ViewChild('mySupplierAuditForm') childForm: NgForm | undefined;
  @ViewChild('myTabView', { static: false }) tabView!: TabView;
  @Output() saveInvoiceButtonClick = new EventEmitter<void>();
  @Output() calcelInvoiceButtonClick = new EventEmitter<void>();
  @Output() viewButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() ContractId: any;
  @Input() invoiceId: any;
  @Input() SupplierId: any;
  @Input() SponsorId: any;
  currencyformat:any;
  myContractInvoiceForm: FormGroup;
  submitted: boolean = false;
  isSaveBtnDisable = false;
  contractInvoice = new InvoiceDetails();
  codeListStatusTypes: any;
  _dateformart: any;
  codeListValues: any;
  daysToDueDate: number | string = '';
  statusData: any;
  maxFileSize: any
  uploadedInvoiceFileData: any;
  timeStamp: any;
  formData = new FormData();
  contractInvoiceAttachments: FinanceInvoiceAttachments[] = [];
  contractInvoiceAttachment = new FinanceInvoiceAttachments();
  isPaymentDateEditable: boolean = false;
  showPlaceholder: boolean = false;
  ispaymentAmountEnable: boolean = true;
  statusCodes: any;
  invoiceRaisedMinDate:any;
  currency:any;
  emailBodyData=new InvoiceEmailPayload();
  constructor(private router: Router, private fb: FormBuilder,

    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private loaderService: LoaderService,
    private breadcrumbService: BreadcrumbService, private masterDataService: SponserService,
    private _financeInvoiceService: FinanceService,
    private activeRouter: ActivatedRoute, private _fileService: FileUploadService, private datePipe: DatePipe,
    private notificationService:NotificationService,private auditLogService: AuditLogService,
    private privilegeService: PrivilegeService,
    private _contractService:ContractService,
    private currencyService: CurrencyService,
  private engagementService: EngagementService,) {
      this.statusCodes = StatusCodes;
    this.myContractInvoiceForm = this.fb.group({
      invoicenumber: ['', [Validators.required, Validators.maxLength(15), this.noOnlySpacesValidator, Validators.pattern(/^\S*$/)]],
      invoicevalue: ['', [Validators.required, Validators.maxLength(15), this.validateInvoiceNoField]],
      invoiceRaisedDate: ['', [Validators.required]],
      dueDate: ['', [Validators.required]],
      draftInvoiceApprovedDate: [''],
      draftInvoicesentdDate: [''],
      statusMappingId: ['', [Validators.required]],
      //invoicefileSource: ['', Validators.required], invoiceFileName: '',
      invoicefileSource: [''], invoiceFileName: '',
      daystoDueDate: ['', [Validators.required]],
      remarks: ['', [Validators.maxLength(300)]],
      description: ['', [Validators.maxLength(300)]],
      paymentAmount: ['', [this.conditionalRequired(this.contractInvoice.Status === this.statusCodes.Paid), Validators.maxLength(15), this.validateNumericField]],
      paymentDate: [null, [this.conditionalRequired(this.contractInvoice.Status === this.statusCodes.Paid), Validators.required]],
    })
  }

  auditIdentifierDetails:any;
  auditEntities:any;
 auditOperations:any;
auditModule:any;

  ngOnInit() {
   
    this._dateformart = Dateformat;
    this.isPaymentDateEditable = false;
    this.codeListValues = CodeList;
    this.codeListStatusTypes = CodeListStatusTypes;
    this.auditEntities=AuditLogEntities;
    this.auditOperations=AuditOperations;
    this.auditModule=AuditLogModule;

    this.GetInvoiceStatus(this.codeListValues.Tbl_Status, this.codeListStatusTypes.INVSTS);
    this.formOldContractInvoice();
    this.statusCodes = StatusCodes;
    this.auditIdentifierDetails=AuditIdentifier;
    this.oldContractInvoiceData=JSON.stringify(this.contractInvoice);
    this.currencyformat=CurrencyType;
    this.currency= this.activeRouter.snapshot.queryParamMap.get('currency');
   this.GetContractDetails();
  }

  conditionalRequired(condition: boolean) {
    return (control: AbstractControl) => {
      if (condition) {
        return null; 
      } else {
        return Validators.required(control); 
      }
    };
  }
  onDueDateChange(event: any) {

    if (this.contractInvoice.ContractInvoiceDueDate) {
      const currentDate = new Date();
      const dueDate = new Date(this.contractInvoice.ContractInvoiceDueDate);
      const timeDifference = dueDate.getTime() - currentDate.getTime();
      const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

      this.contractInvoice.DaysToDueDate = daysDifference;
    } else {
      this.contractInvoice.DaysToDueDate = 0;
    }
    this.contractInvoice.ContractInvoiceDueDate = event;
    if (this.contractInvoice.ContractInvoiceDueDate != null && this.contractInvoice.ContractInvoiceDueDate != undefined && this.contractInvoice.ContractInvoicePaymentDate != null && this.contractInvoice.ContractInvoicePaymentDate != undefined) {
      if (this.contractInvoice.ContractInvoiceDueDate > this.contractInvoice.ContractInvoicePaymentDate) {
        this.myContractInvoiceForm.patchValue({
          paymentDate: null
        });
      }
    }
  }

  GetInvoiceStatus(enumId: number, type: string) {

    this.masterDataService.getCodeListData(enumId, type).subscribe(
      res => {
        if (res.data.length > 0) {
          this.statusData = res.data;
        }
      }
    );
  };
  minDueDate() {
    if (this.contractInvoice.InvoiceRaisedDate) {
      return this.contractInvoice.InvoiceRaisedDate;
    } else {
      return this.getDefaultMinDate();
    }
  }

  uploadedInvoiceFileName:any='';
  deleteInvoiceFile(){
    this.uploadedInvoiceFileName="";
    this.uploadedInvoiceFileData=null;
    this.myContractInvoiceForm.patchValue({
      invoiceFileName: '',
       invoicefileSource: ''
   })
  }
  onInvoiceFileChange(fileDetails: any) {
    this.maxFileSize = this._fileService._fileSize;
    if (fileDetails.length > 0) {
      var currentFileSize = fileDetails[0].size;
      var uploadedFileName = fileDetails[0].name;
      this.uploadedInvoiceFileName= uploadedFileName;

      var uploadedExtension = uploadedFileName.split('.').pop().trim().toLowerCase();

      if (this._fileService.allowedExtensions.includes(uploadedExtension)) {
        if (currentFileSize <= this.maxFileSize) {
          this.myContractInvoiceForm.patchValue({
            invoiceFileName: fileDetails[0].name,
          })
          this.uploadedInvoiceFileData = fileDetails[0];
        }

        else {
          this.myContractInvoiceForm.patchValue({
            invoiceFileName: '',
            invoicefileSource: '',
          })
           this.uploadedInvoiceFileName=''
          this.messageService.add({ key: 'tc', severity: 'warn', summary: 'File Size limited to 20 Mb', detail: '' });
        }
      }
      else {
        this.myContractInvoiceForm.patchValue({
          invoiceFileName: '',
          invoicefileSource: ''
        })
         this.uploadedInvoiceFileName=''
        this.messageService.add({ key: 'tc', severity: 'error', summary: 'Invalid file type. Only .pdf, .xls, .xlsx, .doc, .docx files are allowed.', detail: '' });
      }

    }
  }
  enteredPaymentAmount: number=0;
  onPaymentAmountInput(event: any) {
    this.enteredPaymentAmount = event.target.value;
    this.contractInvoice.PaymentAmount= this.enteredPaymentAmount;
  }
  uploadInvoiceFile() {
    if (this.myContractInvoiceForm.controls['paymentDate']) {
      if (this.selectedStatus !== this.statusCodes.Paid) {
        this.myContractInvoiceForm.controls['paymentDate'].clearValidators();
        this.myContractInvoiceForm.controls['paymentAmount'].clearValidators();
        this.myContractInvoiceForm.controls['paymentDate'].updateValueAndValidity();
        this.myContractInvoiceForm.controls['paymentAmount'].updateValueAndValidity();
      }
    }
if(this.myContractInvoiceForm.valid){
    this.loaderService.showLoader();
    var utcDateTimeNow = new Date().toISOString();
    this.timeStamp = formatDate(utcDateTimeNow, this._dateformart.FiletimeStamp, this._dateformart.TimeStampZone);
    if (this.uploadedInvoiceFileData) {

      const formData = new FormData();
      formData.append('file', this.uploadedInvoiceFileData, "Invoice/" + this.timeStamp + "_" + this.uploadedInvoiceFileData.name);

      this._fileService.UploadSingleFile(formData).subscribe((res: any) => {
        if (res) {  // file uploaded successfully into the file system
          this.contractInvoiceAttachment.FileName = this.uploadedInvoiceFileData.name;
          this.contractInvoiceAttachment.FilePath = "Invoice/" + this.timeStamp + "_" + this.contractInvoiceAttachment.FileName;
         
          this.contractInvoiceAttachments.push(this.contractInvoiceAttachment);
          this.contractInvoice.FinanceInvoiceAttachments = this.contractInvoiceAttachments;
         
          this.SaveInvoiceDetails();

        }
      })
    }
    else{
      this.SaveInvoiceDetails();
    }
  }
  else {
    this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Please enter required information', detail: '' });
  }

  }


  
  SaveInvoiceDetails(){
      this.contractInvoice.ContractDetailId = this.ContractId;
      this.contractInvoice.createdBy = this.privilegeService.getLoginUserId();
      this.contractInvoice.createdDate = new Date();
      this.contractInvoice.updatedBy = this.privilegeService.getLoginUserId();
      this.contractInvoice.updatedDate = new Date();
      this.contractInvoice.isDeleted = false;
      
      var selectedInvoiceStatus = this.statusData?.filter((t: { id: number | undefined; }) => t.id == this.contractInvoice.StatusMappingId);
      if (selectedInvoiceStatus.length > 0 && selectedInvoiceStatus[0].name != undefined) {
        this.contractInvoice.Status = selectedInvoiceStatus[0].name;
      }
        this.contractInvoice.DraftInvoiceSentDate = this.datePipe.transform(this.contractInvoice.ContractInvoiceSentDate, 'MM/dd/yyyy') as string;
        this.contractInvoice.DraftInvoiceApprovedDate = this.datePipe.transform(this.contractInvoice.ContractInvoiceApprovedDate, 'MM/dd/yyyy') as string;
        this.contractInvoice.DueDate = this.datePipe.transform(this.contractInvoice.ContractInvoiceDueDate, 'MM/dd/yyyy') as string;
        this.contractInvoice.PaymentDate = this.datePipe.transform(this.contractInvoice.ContractInvoicePaymentDate, 'MM/dd/yyyy') as string;
        this.contractInvoice.InvoiceRaisedDate = this.datePipe.transform(this.contractInvoice.ContractInvoiceRaisedDate, 'MM/dd/yyyy') as string;
       
        //mail payload data
        this.emailBodyData.InvoiceNumber=this.contractInvoice.InvoiceNumber;
        const invoiceValueStr:string = (this.contractInvoice?.InvoiceValue)?.toString() ?? '0'; // Ensure it's a string and not undefined
        this.emailBodyData.InvoiceValue=this.getFormattedValue(parseFloat(invoiceValueStr),this.currency),
        this.emailBodyData.InvoiceDueDate=this.datePipe.transform(this.contractInvoice.ContractInvoiceDueDate, 'dd-MMM-yy') as string;
         //------  
      if (this.myContractInvoiceForm.valid) {

        if (this.selectedStatus === this.statusCodes.Paid) {
          if ((this.contractInvoice.PaymentDate!=undefined || this.contractInvoice.PaymentDate!=null) && (this.contractInvoice.PaymentAmount!=undefined || this.contractInvoice.PaymentAmount!=null)) {
            this.contractInvoice.PaymentAmount=this.contractInvoice.PaymentAmount.toString();
            this.loaderService.showLoader();
            this._financeInvoiceService.saveFinanceData(this.contractInvoice).subscribe(
              async data => {
               // await this.notificationService.AddInvocieNotification(this.contractInvoice.ContractDetailId,this.emailBodyData,this.SupplierId,this.SponsorId);//User Story 126921: Notification - Enhancement
                await this.notificationService.POUtilization(this.contractInvoice.ContractDetailId,this.SponsorId,this.SupplierId,this.EngagementName);  //call the PO Utilization send notification service metod
                this.loaderService.hideLoader();
                this.messageService.add({
                  key: 'tc',
                  severity: 'success',
                  summary: 'Invoice has been added',
                  detail: '',
                });
                this.compareModels();
                this.saveInvoiceButtonClick.emit();
                const dataResult = {
                  contractid: this.ContractId,
                  invoiceId: data.data
                };
                this.viewButtonClick.emit(dataResult);
              })
          }
         
        }
        else {
          this.loaderService.showLoader();
          this._financeInvoiceService.saveFinanceData(this.contractInvoice).subscribe(
            async data => {
             // await this.notificationService.AddInvocieNotification(this.contractInvoice.ContractDetailId,this.emailBodyData,this.SupplierId,this.SponsorId);
              await this.notificationService.POUtilization(this.contractInvoice.ContractDetailId,this.SponsorId,this.SupplierId,this.EngagementName); //call the PO Utilization send notification service metod
              this.loaderService.hideLoader();
              this.messageService.add({
                key: 'tc',
                severity: 'success',
                summary: 'Invoice has been added',
                detail: '',
              });
              this.compareModels();
              this.saveInvoiceButtonClick.emit();
              const dataResult = {
                contractid: this.ContractId,
                invoiceId: data.data
              };
              this.viewButtonClick.emit(dataResult);
            })
        }


      }
      else {
        this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Please enter required information', detail: '' });
      }

    
  }




  noOnlySpacesValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value && control.value.trim().length === 0) {
      return { onlySpaces: true };
    }
    return null;
  }
  validateNumericField(control: any) {
    const numericRegex = /^\d+(\.\d{1,2})?$/;
    if (control.value && !numericRegex.test(control.value)) {
      return { invalidNumber: true };
    }
    return null;
  }


  validateInvoiceNoField(control: any) {
    const numericRegex = /^-?\d+(\.\d{1,2})?$/;
    if (control.value && !numericRegex.test(control.value)) {
      return { invalidNumber: true };
    }
    return null;
  }
  

  getDefaultMinDate(): Date {
    return new Date('1970-01-01')
  }
selectedStatus:any;
  onStatusChange(event: any) {
    var selectedInvoiceStatus = this.statusData?.filter((t: { id: number | undefined; }) => t.id == this.contractInvoice.StatusMappingId);
    if (selectedInvoiceStatus.length > 0 && selectedInvoiceStatus[0].code != undefined) {
      this.contractInvoice.Status = selectedInvoiceStatus[0].code;
      this.selectedStatus=this.contractInvoice.Status;
    }
    if (this.contractInvoice.Status === this.statusCodes.Rejected) {
      this.contractInvoice.DaysToDueDate = 'NA';
      this.showPlaceholder = false;
      this.ispaymentAmountEnable = true;

    }
    if (this.contractInvoice.Status === this.statusCodes.Paid) {
      this.contractInvoice.DaysToDueDate = 'NA';
      this.isPaymentDateEditable = true;
      this.ispaymentAmountEnable = false;
    }
    if (this.contractInvoice.Status !== this.statusCodes.Paid && this.contractInvoice.Status !== this.statusCodes.Rejected) {
      this.isPaymentDateEditable = false;
      this.ispaymentAmountEnable = true;
      this.onDueDateChange(this.contractInvoice.ContractInvoiceDueDate);
    }
    if(this.contractInvoice.Status!== this.statusCodes.Paid){
      this.myContractInvoiceForm.patchValue({
        paymentDate:null,
        paymentAmount:null
      })
    }
  }

invoiceApprovalMinDate:any;
  onInvoiceSentDateChange(event: any) {
    this.contractInvoice.ContractInvoiceSentDate = event;

    if(event == undefined || event == null){
      this.invoiceApprovalMinDate=new Date('1970-01-01');
    }
    else{
    this.invoiceApprovalMinDate=event;
    }

    if (this.contractInvoice.ContractInvoiceSentDate != null && this.contractInvoice.ContractInvoiceSentDate != undefined && this.contractInvoice.ContractInvoiceApprovedDate != null && this.contractInvoice.ContractInvoiceApprovedDate != undefined) {
      if (this.contractInvoice.ContractInvoiceSentDate > this.contractInvoice.ContractInvoiceApprovedDate) {
        this.myContractInvoiceForm.patchValue({
          draftInvoiceApprovedDate: null,
          invoiceRaisedDate: null,
          dueDate: null,
          paymentDate: null
        });
      }
    }

  }

 
  onInvoiceApprovalDateChange(event: any) {
    this.contractInvoice.ContractInvoiceApprovedDate = event;
    if(event == undefined || event == null){
      this.invoiceRaisedMinDate=new Date('1970-01-01');
    }
    else{
    this.invoiceRaisedMinDate=event;
    }
    if (this.contractInvoice.ContractInvoiceApprovedDate != null && this.contractInvoice.ContractInvoiceApprovedDate != undefined && this.contractInvoice.ContractInvoiceRaisedDate != null && this.contractInvoice.ContractInvoiceRaisedDate != undefined) {
      if (this.contractInvoice.ContractInvoiceApprovedDate > this.contractInvoice.ContractInvoiceRaisedDate) {
        this.myContractInvoiceForm.patchValue({
          invoiceRaisedDate: null,
          dueDate: null,
          paymentDate: null
        });
      }
    }
  }
  onInvoiceRaisedDateChange(event: any) {
    if( this.invoiceRaisedMinDate == null){
      this.invoiceRaisedMinDate=new Date();
    }
    this.contractInvoice.ContractInvoiceRaisedDate = event;
    if (this.contractInvoice.ContractInvoiceRaisedDate != null && this.contractInvoice.ContractInvoiceRaisedDate != undefined && this.contractInvoice.ContractInvoiceDueDate != null && this.contractInvoice.ContractInvoiceDueDate != undefined) {
      if (this.contractInvoice.ContractInvoiceRaisedDate > this.contractInvoice.ContractInvoiceDueDate) {
        this.myContractInvoiceForm.patchValue({
          dueDate: null,
          paymentDate: null
        });
      }
    }
  }

  onCancelClick() {

    if ((this._oldContractInvoice != JSON.stringify(this.contractInvoice))) {
      this.confirmationService.confirm({
        message: 'Invoice will not be added, Do you want to Continue?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.calcelInvoiceButtonClick.emit();
          this.messageService.add({
            key: 'tc',
            severity: 'info',
            summary: 'Invoice not added',
            detail: '',
          });
        },
        reject: () => {
        },
      });
    }
    else {
      this.calcelInvoiceButtonClick.emit();
    }


  }

  engagementId:any;
  GetContractDetails(){
    this._contractService.GetContractDtailsById(this.ContractId).subscribe(
      (res:any) => {
        this.engagementId=res.data.engagementId;
        this.GetEngagementDataById(this.engagementId);
      });
  }

  EngagementName:any;
  GetEngagementDataById(engagementId: any) {
    this.engagementService.GetEngagementById(engagementId).subscribe(
        (res:any) => {
            this.EngagementName=res.data.engagementName;
        }
    );
}


  saveInvoiceData() {


    this.submitted = true;
    this.isSaveBtnDisable = true;
    setTimeout(() => {
      this.isSaveBtnDisable = false;
    }, 3000);

    this.loaderService.showLoader();

    const invoiceNumber=this.contractInvoice.InvoiceNumber?.replace(/-/g, '~');
       
    //checkInvoiceNoExistence
    this._financeInvoiceService.checkInvoiceNoExistence(this.engagementId,0,invoiceNumber).subscribe(
      (res:any) => {
        if(res.data==1){
          this.isInvoiceNoExist=true;
          this.loaderService.hideLoader();
          this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Invoice Number already exists with in selected Engagement. Please enter a unique Number.', detail: '' });
        }
        else{
          this.isInvoiceNoExist=false;
          if (this.myContractInvoiceForm.controls['paymentDate']) {
            if (this.selectedStatus !== this.statusCodes.Paid) {
              this.myContractInvoiceForm.controls['paymentDate'].clearValidators();
              this.myContractInvoiceForm.controls['paymentAmount'].clearValidators();
              this.myContractInvoiceForm.controls['paymentDate'].updateValueAndValidity();
              this.myContractInvoiceForm.controls['paymentAmount'].updateValueAndValidity();
            }
          }
      
         
          if(this.myContractInvoiceForm.valid && !this.isInvoiceNoExist){
          if (this.contractInvoice.DaysToDueDate !== 'NA') {
          this.contractInvoice.DaysToDueDate = this.contractInvoice.DaysToDueDate.toString();
          }
        this.uploadInvoiceFile();
          } 
       else{
          this.loaderService.hideLoader();
          this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Please enter required information', detail: '' });
          }
        }
      });

    
  }


  
  
  isInvoiceNoExist=false;
  checkInvoiceNoExistence(){
    if(this.contractInvoice.InvoiceNumber != undefined && this.contractInvoice.InvoiceNumber != null)
      {
        const invoiceNumber=this.contractInvoice.InvoiceNumber?.replace(/-/g, '~');
        //checkInvoiceNoExistence
        this._financeInvoiceService.checkInvoiceNoExistence(this.engagementId,0,invoiceNumber).subscribe(
          (res:any) => {
            if(res.data==1){
              this.isInvoiceNoExist=true;
            }
            else{
              this.isInvoiceNoExist=false;
            }
          });
     
    }
  }





  _oldContractInvoice: any
  formOldContractInvoice() {
    setTimeout(() => {
      this._oldContractInvoice = JSON.stringify(this.contractInvoice);
    }, 2000);
  }

  changedContractInvoiceBasicInfo:any;
oldContractInvoiceData:any;
compareModels() {
  this.changedContractInvoiceBasicInfo = this.auditLogService.compareModels(JSON.parse(this.oldContractInvoiceData),this.contractInvoice,this.auditEntities.ContractInvoices,this.auditIdentifierDetails.ContractInvoices,this.auditOperations.Create,this.auditModule.FinanceService);
}
getFormattedValue(value: number, currencyName: string): string {
  return this.currencyService.getFormattedValue(value, currencyName);
}
}
