import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SponserService } from '../../Services/sponser.service';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { MenuItem } from 'primeng/api';
import { breadcrumnsCodes } from 'src/app/shared/constants/global.constants';

@Component({
  selector: 'app-codelist',
  templateUrl: './codelist.component.html',
  styleUrls: ['./codelist.component.css']
})

export class CodelistComponent  implements OnInit  {
  constructor(private router: Router,private service: SponserService,private breadcrumbService: BreadcrumbService){}

  codelists: any[] = []
  _enumBreadcrumb :any;

  
  ngOnInit() {
    this._enumBreadcrumb=breadcrumnsCodes;
    this.generateBreadcrumb();
    this.GetAllCodeLists();
  }

GetAllCodeLists() {
  this.service.GetAllCodeLists()
  .subscribe(res=>{
   this.codelists = res.data;
  })
}
generateBreadcrumb()
{
  this.breadcrumbService.generateBreadcrumb(this._enumBreadcrumb.CL);
}
getBreadcrumbs(): MenuItem[] {
  return this.breadcrumbService.getBreadcrumbs();
}

}
