import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, shareReplay } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CurrencyType, CurruncyFormat } from '../shared/constants/global.constants';
import { AuthCommonService } from '../shared/Authentication/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  apiURL = environment._sponserApiBaseURl;
  constructor(private http: HttpClient,private authCommonService: AuthCommonService) { }
   httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
  GetConversionRates=this.http.get(this.apiURL +  '/JobScheduler/GetConversionRates',this.httpOptions).pipe(
  shareReplay(1)
);
  getExchangeRate(exchangeRates:any,baseCurrency: string, targetCurrency: string): Observable<number> {
    
    const baseCurrencyAmount = exchangeRates['usd'];
    const targetToBaseRate = (baseCurrencyAmount / exchangeRates[baseCurrency.toLowerCase()]) /
                              (baseCurrencyAmount / exchangeRates[targetCurrency.toLowerCase()]);
  
    return of(targetToBaseRate); 
    
  }
  
  getCurrencyType(currencyName: string): CurrencyType | undefined {
    switch (currencyName) {
      case 'United States Dollar':
        return CurrencyType.USD;
      case 'Indian Rupee':
        return CurrencyType.INR;
      case 'Chinese Yuan':
        return CurrencyType.CYN;
      case 'Japanese Yen':
        return CurrencyType.JPY;
      default:
        return undefined;
    }
  }
  currencyType:any;
  getFormattedValue(value: number, currencyName: string): string {
    if(currencyName!=undefined)
    {
    switch (currencyName.toUpperCase()) {
      case 'USD':
        this.currencyType = CurrencyType.USD;
        break;
      case 'INR':
        this.currencyType = CurrencyType.INR;
        break;
      case 'JPY':
        this.currencyType = CurrencyType.JPY;
        break;
      case 'CNY':
        this.currencyType = CurrencyType.CYN;
        break;
      default:
        this.currencyType = this.getCurrencyType(currencyName);
    }
    if(value==undefined){
      value=0;
      }
    switch (this.currencyType) {
      case CurrencyType.USD:
        return this.formatCurrency(value, CurruncyFormat.US, CurrencyType.USD);
      case CurrencyType.INR:
        return this.formatCurrency(value, CurruncyFormat.IN, CurrencyType.INR);
      case CurrencyType.CYN:
        return this.formatCurrency(value, CurruncyFormat.CY, CurrencyType.CNY);
      case CurrencyType.JPY:
        return this.formatCurrency(value, CurruncyFormat.JP, CurrencyType.JPY);
      default:
        return value.toString(); 
    }
  }
  else
  {
    return "";
  }
  }
  
  private formatCurrency(value: number, locale: string, currencyCode: string): string {
    return new Intl.NumberFormat(locale, { style: 'currency', currency: currencyCode }).format(value);
  }
    
    
  




}
