import { Injectable } from '@angular/core';
import { AuditLogModel, FieldChangeForAuditModel } from './audit.log.response.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { AuditOperations, Dateformat } from '../constants/global.constants';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthCommonService } from '../Authentication/auth.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';

@Injectable({
  providedIn: 'root'
})

export class AuditLogService {
  readonly _administratorsApiBaseURl:any;
  readonly _commonServiceApiBaseURl?:string="";
  auditOperations:any;

  excludeColumnList: string[] = ['CreatedBy','createdBy', 'CreatedDate','createdDate', 'UpdatedBy','updatedBy', 'updatedDate','UpdatedDate','id','Id','localId','suppliersMasterServiceAgreements','ChangeOrderDetails','AddendumDetails','status','Status','ContractInvoiceSentDate', 'ContractInvoiceRaisedDate','ContractInvoiceDueDate','ContractInvoicePaymentDate','ContractInvoiceApprovedDate','ContractDetailId','kpiDataCollection','engagementMasterServiceAgreements','paymentTerm','msaStatus','uploadedMSAFile','supplierContacts','sponsorContacts','kpiStartDateYear','kpiEndDateYear','kpiReportingFrequencyCode','frequencyValueforStartDate','frequencyValueforEndDate','EngagementDataCollectionList'];
  
    constructor(private http: HttpClient, private datepipe: DatePipe, private authService: AuthCommonService, private privilegeService: PrivilegeService){
    this._administratorsApiBaseURl=environment._administratorsApiBaseURl;
    this._commonServiceApiBaseURl=environment?._commonServiceApiBaseURl;
    }
 
    _dateformart:any;
  compareModels(oldModel: any, newModel: any, entityName:string, entityIdentifier:string,operationType:string,fromService:string): any {
    this.auditOperations=AuditOperations;
    var listOfAuditLogModel:FieldChangeForAuditModel[]=[];
    var auditLogModel= new FieldChangeForAuditModel();
    var auditModelDetail=new AuditLogModel();
    this._dateformart=Dateformat; 
    var variableToIterate;
    if(Object.keys(newModel).length > 0)
    {
      variableToIterate=newModel;
    }
    else
    {
      variableToIterate=oldModel;
    }
  for (const key in variableToIterate)
   {
    if(oldModel[key] == undefined){
      oldModel[key]=null;
    }
    if(newModel[key] == undefined){
      newModel[key]=null;
    }

    if((oldModel[key] != null && Array.isArray(oldModel[key])) || (newModel[key] != null && Array.isArray(newModel[key]))){
      if (JSON.stringify(oldModel[key]) !== JSON.stringify((newModel[key])) && !this.excludeColumnList.includes(key))  
      {
        auditLogModel= new FieldChangeForAuditModel();
        auditLogModel.fieldName=key;
        auditLogModel.oldValue=oldModel[key];
        auditLogModel.newValue=newModel[key];
        listOfAuditLogModel.push(auditLogModel);
       
      }
    }
else//it is not the array field
{
  if(!(oldModel[key] == null && newModel[key] == ""))
  {
    if (oldModel[key] !== newModel[key] && !this.excludeColumnList.includes(key))  
    {
      auditLogModel= new FieldChangeForAuditModel();
      auditLogModel.fieldName=key;
      auditLogModel.oldValue=oldModel[key];
      auditLogModel.newValue=newModel[key];
      listOfAuditLogModel.push(auditLogModel);
     
    }
  }
  }
  }

  //return listOfAuditLogModel;
 //Create Audit Model
 if(listOfAuditLogModel.length>0){
 
 auditModelDetail.operationType=operationType;
 //We need to get last identifier value from common service then we will add 1 in next entityIdentifier
 auditModelDetail.entityIdentifier=entityIdentifier;
 auditModelDetail.entityName=entityName;
 auditModelDetail.fromService=fromService;

 auditModelDetail.operationDate= this.datepipe.transform(new Date(),this._dateformart.PayloadDateTime); //new Date();

 auditModelDetail.entityCreatedDate= this.datepipe.transform(new Date(),this._dateformart.PayloadDateTime); //new Date();
 auditModelDetail.entityModifiedDate= this.datepipe.transform(new Date(),this._dateformart.PayloadDateTime); //new Date();

let operatorName=this.privilegeService.getUserName(this.privilegeService.getLoginUserId());
auditModelDetail.entityCreatedByName=operatorName;
auditModelDetail.operatorUsername=operatorName;
auditModelDetail.entityModifiedByName=operatorName;


 auditModelDetail.fieldModifications=listOfAuditLogModel;

//Call save Audit api here 
console.log(auditModelDetail);
this.PostAuditDetails(auditModelDetail).subscribe(
  data => {
    var response = data.data;
  }
);

 }
}

public PostAuditDetails(model:any){
  var token=this.authService.getCookieValue('LOGIN_TOKEN');
  var httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json',"Authorization": "Bearer "+token}) };
  
  //var url='https://csapidev.freyrkontractpro.com/audit/system/audit-log';
  var url = this._commonServiceApiBaseURl+"audit/system/audit-log";

  return this.http.post<any>(url, model,httpOptions);
}


public GetAuditDetailById(): Observable<any> {
  
  var token=this.authService.getCookieValue('LOGIN_TOKEN');
  var httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json',"Authorization": "Bearer "+token}) };
  
  //var url='https://csapidev.freyrkontractpro.com/audit/log-record/one/modification-info?auditId=10000029';
  var url = this._commonServiceApiBaseURl+"audit/log-record/one/modification-info?auditId=10000029";
  return this.http.get<any>(url,httpOptions);

}

// public GetAuditLogDetails_Gateway_Post(): Observable<any> {
//   var getAuditDetailsPayload={
//     "pageSize": 40,
//     "pageNum": 1
   
//   };
//   var token=this.authService.getCookieValue('LOGIN_TOKEN');
//   var httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json',"Authorization": "Bearer "+token}) };
  
//   var url='https://devapi.freyrkontractpro.com/Api/Gateway/audit/log-record/list';
 
//   return this.http.post<any>(url, getAuditDetailsPayload,httpOptions);
  

// }



// public GetAuditLogDetails_Gateway_Get(): Observable<any> {
 
//   var token=this.authService.getCookieValue('LOGIN_TOKEN');
//   var httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json',"Authorization": "Bearer "+token}) };
  
//   var url='https://devapi.freyrkontractpro.com/Api/Gateway/audit/log-record/one/modification-info?auditId=10000029';
 
//   return this.http.get<any>(url,httpOptions);
// }

}
