<!DOCTYPE html>
<html lang="en">
<head>
  <title> Engagement Audit List </title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>
    <p-tabView class="fr-tabview__header__custom">
      <p-tabPanel>
        <div class="flex-box m-t-70"> 
        <div class="fr-flex__col-12"> 
        <div class="fr-flex__container">
          <div class="fr-flex__col-8">
            <span class="number_of_records"> <em> ({{ filteredRecordCount > -1 ? filteredRecordCount : totalRecordCount }} of {{ totalRecordCount }} Records) </em> </span>
          </div>
          <div class="fr-flex__col-4">
            <a *ngIf="createPrivilege" class="btn btn-primary pull-right" (click)="addEngagementAudit()"> Add Audit</a>
            <button *ngIf="ExportPrivilage" class="btn btn-outline-primary pull-right" [disabled]="isChecked" (click)="ExportEngagementAudits()"><i class="pi pi-download"></i> Export</button>
          </div>
      </div>
    </div>
  </div>
    <div class="fr-flex__col-12"> 
      <div class="inside-grid-pad"> 
    <p-table id="engAuditListTableName" class="primeng-table-custom custom-cells__sup-audit-list" [resizableColumns]="true" columnResizeMode="expand"
    [scrollable]="true" styleClass="p-datatable-striped p-datatable-gridlines"
    [value]="_engagementAuditListData" 
    [(selection)]="selectedEngagementAudit"
    editMode="row"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Entries"
    [rowsPerPageOptions]="[10, 20, 50, 100, 200]"
     [tableStyle]="{ 'min-width': '50rem' }">
      <ng-template pTemplate="header">
          <tr>   
            <th id="engAuditListTHCheckbox" pFrozenColumn class="first-child-width">
             <p-tableHeaderCheckbox (click)="CheckboxEnabled()"></p-tableHeaderCheckbox>
            </th>              
              <th id="engListAuditTitle" pFrozenColumn pSortableColumn="auditTitle" pResizableColumn>Audit Title <p-sortIcon field="auditTitle"></p-sortIcon></th>
              <th id="engListAuditNo" pSortableColumn="auditNumber" pResizableColumn>Audit No <p-sortIcon field="auditNumber"></p-sortIcon></th>
              <th id="engListAuditStartDate" pSortableColumn="auditStartDate" pResizableColumn>Start Date <p-sortIcon field="auditStartDate"></p-sortIcon></th>
              <th id="engListAuditEndDate" pSortableColumn="auditEndDate" pResizableColumn>End Date <p-sortIcon field="auditEndDate"></p-sortIcon></th>
              <th id="engListAuditStatus" pSortableColumn="auditStatus" pResizableColumn>Audit Status <p-sortIcon field="auditStatus"></p-sortIcon></th>
              <th id="engListAuditObservationsCount" pSortableColumn="observationsCount" pResizableColumn>Observations <p-sortIcon field="observationsCount"></p-sortIcon></th>
              <th id="engListAuditAuditor" pSortableColumn="auditor" pResizableColumn>Auditor <p-sortIcon field="auditor"></p-sortIcon></th>
              
              <th id="engListAuditEdit" *ngIf="editPrivilege" class="last-child-width">Edit</th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-engagementAudit> 
          <tr>
            <td id="engAuditListTDCheckbox" pFrozenColumn class="first-child-width">
                <p-tableCheckbox (click)="CheckboxEnabled()" [value]="engagementAudit"></p-tableCheckbox>
            </td>
              <td id="engAuditListHyperLink" pFrozenColumn [pTooltip]="engagementAudit.auditTitle.length > 10 ? engagementAudit.auditTitle : ''" tooltipPosition="top" routerLinkActive="active" > 
                <a *ngIf="viewPrivilege; else disabledLink" (click)="viewEngagementAudit(engagementAudit.id)" class="table-active-link">{{ engagementAudit.auditTitle }}</a>
                <ng-template #disabledLink >
                  <span>{{ engagementAudit.auditTitle }}  </span>
                </ng-template>
              </td>
              
              <td> <span>{{ engagementAudit.auditNumber }} </span></td>
              <td> <span>{{ engagementAudit.auditStartDate | date:_dateformart.GridDateFormat}}</span></td>
              <td> <span>{{ engagementAudit.auditEndDate | date:_dateformart.GridDateFormat}}</span></td>
              <td> <span>{{ engagementAudit.auditStatus }}</span></td>
              <td> <span *ngIf="engagementAudit.observationsCount > 0">{{ engagementAudit.observationsCount }}</span> <span class="ellipsis-content" *ngIf="engagementAudit.observationsCount == 0 || engagementAudit.observationsCount == null">--</span></td>
              
              <td [pTooltip]="engagementAudit.auditor.length > 10 ? engagementAudit.auditor : ''" tooltipPosition="top"> {{ engagementAudit.auditor }}</td>
              
              <td id="engAuditListEdit" *ngIf="editPrivilege" class="grid-icon-center"> <span (click)="editEngagementAudit(engagementAudit.id)"> <i class="pi pi-pencil"> </i> </span> </td>
              
          </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
        <td colspan="8" class="text-center"> No Records</td>
        </tr>
        </ng-template>
  </p-table>
</div>
  </div>
  </p-tabPanel>
  </p-tabView>
  </body>
</html>
