<!DOCTYPE html>
<html lang="en">
<head>
  <title> Contract Forecast </title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>

  <p-tabView class="fr-tabview__header__custom"> 
    <p-tabPanel> 
      <div class="flex-box m-t-70"> 
      <div class="fr-flex__col-12"> 
      <div class="fr-flex__container">
        <div class="fr-flex__col-8">
        </div>
        <div class="fr-flex__col-4">
          <button *ngIf="ExportPrivilage" class="btn btn-outline-primary pull-right" [disabled]="isChecked" (click)="exportContractForecast()"><i class="pi pi-download"></i> Export</button>
        </div>
    </div>
  </div>
</div>
    <div class="fr-flex__col-12">
      <div class="inside-grid-pad"> 
    <p-table class="primeng-table-custom custom-cells_forecast"
        [scrollable]="true" [resizableColumns]="true" columnResizeMode="expand" styleClass="p-datatable-striped p-datatable-gridlines"
        [value]="_contractForecastListData" 
        [(selection)]="selectedContractForecast"
        editMode="row"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Entries"
        [rowsPerPageOptions]="[10, 20, 50, 100, 200]"
         [tableStyle]="{ 'min-width': '50rem' }">
          <ng-template pTemplate="header">
              <tr>   
                <th id="contractForecastListTHCheckbox" class="first-child-width">
                  <p-tableHeaderCheckbox (click)="checkboxEnabled()"></p-tableHeaderCheckbox>
              </th>             
                <th id="conForecastListMonth" pSortableColumn="forecastDate" pResizableColumn>Month <p-sortIcon field="forecastDate"></p-sortIcon></th>
                <th id="conForecastListAmount" pSortableColumn="forecastedAmount" pResizableColumn>Forecast Amount <p-sortIcon field="forecastedAmount"></p-sortIcon></th>
                <th class="last-child-width" id="conForecastListRemarks" pSortableColumn="remarks" pResizableColumn>Remarks <p-sortIcon field="remarks"></p-sortIcon></th>
                <th class="last-child-width" id="conForecastListEdit" *ngIf="editPrivilege" class="last-child-width">Edit</th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-contractForecast> 
              <tr>
                <td id="contractForecastListTDCheckbox" class="first-child-width">
                  <p-tableCheckbox (click)="checkboxEnabled()" [value]="contractForecast"></p-tableCheckbox>
              </td>
                <td id="contractForecastListHyperLink" routerLinkActive="active" >
                   <a class="table-active-link" *ngIf="viewPrivilege; else disabledLink" (click)="viewForecast(contractForecast.id,contractForecast)"> {{ contractForecast.forecastDate | date:'MMMM yyyy' }} </a>
                   <ng-template #disabledLink>
                    {{ contractForecast.forecastDate | date:'MMMM yyyy'}}
                  </ng-template>
                  </td>
                <td> <span *ngIf="contractForecast.updatedBy !=null">  {{ getFormattedValue(contractForecast.forecastedAmount, currency) }} </span> <span *ngIf="contractForecast.updatedBy ==null">--</span></td>
                <td class="grid-icon-center"><i class="pi pi-info-circle"  [pTooltip]="(contractForecast.remarks!=null&& contractForecast.remarks.length >0)?contractForecast.remarks:'Not Available'"></i></td>
                <td class="grid-icon-center" id="ContractforecastListEdit" *ngIf="editPrivilege" class="grid-icon-center"><span><i class="pi pi pi-pencil" (click)="editForecast(contractForecast)"></i></span></td>

              </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
            <td colspan="4" class="text-center"> No Records</td>
            </tr>
            </ng-template>
      </p-table>
    </div>
  </div>
    </p-tabPanel>
    </p-tabView>
    </body>
    </html>
  <!-- Forecast List End -->
  