<!DOCTYPE html>
<html lang="en">
<head>
    <title> Code List </title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>
<div class="card flex justify-content-center">
  <p-breadcrumb class="max-w-full" [model]="getBreadcrumbs()"></p-breadcrumb>
</div>
<div class="container page-title">
    <div class="flex-box"> 
  <div class="fr-flex__container">
      <div class="fr-flex__col-8">
          <h1>Code Lists</h1>
      </div>
  </div>
</div>
              <p-table class="primeng-table-custom" [resizableColumns]="true" columnResizeMode="expand" styleClass="p-datatable-striped p-datatable-gridlines" [value]="codelists" [scrollable]="true" [paginator]="true" [rows]="10" [rowsPerPageOptions]="[10,20,50,100,200]" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Entries">
                  <ng-template pTemplate="header">
                      <tr>
                          <th id="MasterDataCodeListName" pSortableColumn="name" pResizableColumn>Code List Name <p-sortIcon field="name"></p-sortIcon></th>
                          <th id="MasterDataNoofRecords" pSortableColumn="count" pResizableColumn>No. of Records <p-sortIcon field="count"></p-sortIcon></th>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-codelists>
                      <tr>
                          <td> <a class="table-active-link" routerLinkActive="active">{{ codelists.name}}</a> </td>
                          <td> {{ codelists.count }} </td>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                    <tr>
                    <td colspan="2" class="text-center"> No Records</td>
                    </tr>
                    </ng-template>
              </p-table>
      
  
</div>
</body>
</html>