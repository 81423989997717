import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import {CodeList, CodeListStatusTypes, ActiveInActiveStatus,StatusCodes,Dateformat,breadcrumnsCodes, CurrencyType, CurruncyFormat } from 'src/app/shared/constants/global.constants';
import { FinanceService } from 'src/app/Finance/Services/invoice.service';
import { LoaderService } from 'src/app/Loader/loader.service';
import { CurrencyService } from 'src/app/Reusable/currency.service';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { MessageService } from 'primeng/api';
import { Privileges } from 'src/app/shared/constants/privileges.constants';
import { TabAndStateService } from 'src/app/shared/TabAndStateService/TabAndState.Service';


@Component({
  selector: 'app-con-summary-view',
  templateUrl: './con-summary-view.component.html',
  styleUrls: ['./con-summary-view.component.css']
})
export class ConSummaryViewComponent  {

  @Input() contractId:any;
  @Input() PaymentTermName:any;
  codeListValues:any;
  codeListStatusTypes: any;
  editFYBudgetPrivilege=false;
  isEditBtnVisible=true;
  visibleBillingsTypes:boolean = false;
  _dateformart :any;
  currencyformat:any;
  
  constructor(
    private _financeInvoiceService:FinanceService,private loaderService: LoaderService
    ,private currencyService:CurrencyService
    ,private masterDataService: SponserService
  ,private privilegeService: PrivilegeService,
  private messageService: MessageService,
  private tabAndStateService: TabAndStateService,private datePipe: DatePipe) {
  }
  ngOnInit() {
    this.codeListValues=CodeList;
    this.codeListStatusTypes=CodeListStatusTypes;
    this._dateformart=Dateformat;
    this.currencyformat=CurrencyType;
    this.GetContractSummaryDetails(this.contractId);
    this.GetInvoiceStatus(this.codeListValues.Tbl_Status, this.codeListStatusTypes.INVSTS);
    this.GetFiscalYearDataByContractId();
    this.BindPrivileges();
    this.findCurrentFY();
  }

  BindPrivileges(){
    const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);
    this.editFYBudgetPrivilege = hasPermission(Privileges.EditFYBudget);

    }

  contractSummarydata:any;
  selectedCurrency:any;
  GetContractSummaryDetails(ContractId:number){
    this.loaderService.showLoader();
    this._financeInvoiceService.GetContractSummaryDetailsById(ContractId).subscribe(
      res => {
        if (res.data) {
          this.contractSummarydata = res.data;
          this.selectedCurrency= this.contractSummarydata.currency
         
          this.loaderService.hideLoader();
        }
      }
    );
  }
  ShowBillingTypesDialog() {
    this.visibleBillingsTypes = !this.visibleBillingsTypes
  }
  getFormattedValue(value: number, currencyName: string): string {
    return this.currencyService.getFormattedValue(value, currencyName);
  }


  //Code for Fiscal Year
  fiscalYearDetails:any;
oldFiscalYearData:any;
  GetFiscalYearDataByContractId(){
    this.loaderService.showLoader();
    this._financeInvoiceService.GetFiscalYearDataByContractId(this.contractId).subscribe(
      res => {
        if (res.data) {
          this.oldFiscalYearData=res.data;
          this.oldFiscalYearData.forEach((detail:any) => {
            detail.updatedUserName = this.privilegeService.getUserName(detail.updatedBy);
          });

          this.oldFiscalYearData=JSON.stringify(this.oldFiscalYearData);

          this.fiscalYearDetails = res.data;

          this.fiscalYearDetails.forEach((detail:any) => {
            detail.updatedUserName = this.privilegeService.getUserName(detail.updatedBy);
          });

         

          this.GetAllInvoicesByContractId();
        }
      }
    );
  }

  

  saveFiscalYearData(){
    this._financeInvoiceService.UpdateFiscalYearData(this.fiscalYearDetails).subscribe(
      res => {
        if (res.data) {
          var result = res.data;
          this.isReadOnly=true;
          this.isEditBtnVisible=true;
          this.GetFiscalYearDataByContractId();
          this.messageService.add({
            key: 'tc',
            severity: 'success',
            summary: 'Fiscal Year Data has been saved',
            detail: '',
        });
          
        }
      }
    );
    
  }


  isReadOnly=true;
  editFiscalYearData()
  {
    this.isReadOnly=false;
    this.isEditBtnVisible=false;
  }

  deleteFiscalYearData(){
    //this.fiscalYearDetails=JSON.parse(this.oldFiscalYearData);
    this.isReadOnly=true;
    this.isEditBtnVisible=true;
    this.GetFiscalYearDataByContractId();
  }


  invoiceData:any;
  filteredInvoicesData:any;
  GetAllInvoicesByContractId(){
    this._financeInvoiceService.GetFinanceInvoiceId(this.contractId).subscribe(
      res => {
        if (res.data) {
          this.invoiceData = res.data;

          //filter by status(need only paid and Raised)
          this.filteredInvoicesData = this.invoiceData.filter((invoice: any) => 
            this.invoiceStatusData.some((status: any) => 
              status.id === invoice.statusMappingId && (status.name === "Raised" || status.name === "Paid")
            )
          );
          


          this.processInvoices(this.fiscalYearDetails,this.filteredInvoicesData);             
                              
          this.loaderService.hideLoader();
            
        }
      }
    );
  }


  
  invoiceStatusData:any;
  GetInvoiceStatus(enumId: number, type: string) { //Get Meeting Status's from code list for the Meetings

    this.masterDataService.getCodeListData(enumId, type).subscribe(
      res => {
        if (res.data.length > 0) {
          this.invoiceStatusData = res.data;
            
        }
      }
    );
  }



  processInvoices(FiscalYearData: any, filteredInvoicesData: any): any {
    FiscalYearData.forEach((fiscalYear:any) => {
      // Parse the financial year
      const dates = fiscalYear.fiscalYear.split(' - ');
      const startDate = this.parseDate(dates[0]);
      const endDate = this.parseDate(dates[1]);

      // Adjust end date to include the whole month of March
      const adjustedEndDate = new Date(endDate);
      adjustedEndDate.setHours(23, 59, 59, 999);

      // Calculate the sum of InvoiceValue where RaisedDate is within the financial year
      const totalInvoiceValue = this.filteredInvoicesData
      .filter((fi: any) => {
        const raisedDate = this.parseDate(fi.invoiceRaisedDate);
        return raisedDate >= startDate && raisedDate <= adjustedEndDate;
      })
      .reduce((sum: number, fi: any) => sum + fi.invoiceValue, 0);

      // Update the ActualBilled columns
      fiscalYear.actualBilledFY = totalInvoiceValue;

      if(fiscalYear.budgetFY != null && fiscalYear.budgetFY != undefined)
      fiscalYear.budgetBalanceFY=fiscalYear.budgetFY-fiscalYear.actualBilledFY;
    else
    fiscalYear.budgetBalanceFY=0-fiscalYear.actualBilledFY;

    if(fiscalYear.budgetFY==null && fiscalYear.actualBilledFY == 0){
      fiscalYear.budgetBalanceFY=null;
    }
    });
     if(FiscalYearData!=null)
     {
      const sortedBudgets = FiscalYearData.find((FY: { fiscalYear: any }) => FY.fiscalYear ===this._currentFY);
         if(sortedBudgets!=undefined)
         {
          this.tabAndStateService.setFYBalance(sortedBudgets.fiscalYear,sortedBudgets.budgetBalanceFY);
         }
         else{
          this.tabAndStateService.setFYBalance('',0);
         }
     }
    return FiscalYearData;
  }
  parseDate(dateString: string): Date {
    return new Date(dateString);
  }
  onBudgetChange(event:any,fiscalData:any){

    fiscalData.budgetFY=event;
    var bdgtBal=0;
    if(fiscalData.budgetFY != null && fiscalData.budgetFY != undefined)
      bdgtBal=fiscalData.budgetFY-fiscalData.actualBilledFY;
    else
    bdgtBal=fiscalData.actualBilledFY;

    //Update DataSource
  

    fiscalData.budgetBalanceFY=bdgtBal;
    if(fiscalData.budgetFY==null){
      fiscalData.budgetBalanceFY=null;
    }

    if(event != null){
    fiscalData.updatedBy=this.privilegeService.getLoginUserId();
    fiscalData.updatedUserName=this.privilegeService.getUserName(fiscalData.updatedBy);
    fiscalData.updatedDate=new Date();
    }
    else{
      fiscalData.updatedBy=null;
      fiscalData.updatedDate=null;
    }

  }
_currentFY:any;
findCurrentFY()
{
  const startDate = new Date();
    startDate.setMonth(3);  // April (0-based index)
    const endDate = new Date();
    endDate.setFullYear(endDate.getFullYear() + 1);
    endDate.setMonth(2);  // March (0-based index)
    const startYear = this.datePipe.transform(startDate, 'MMMM yyyy');
    const endYear = this.datePipe.transform(endDate, 'MMMM yyyy');
    this._currentFY = `${startYear} - ${endYear}`;
}
}
