import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { TabAndStateService } from '../TabAndStateService/TabAndState.Service';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  
  private breadcrumbs: MenuItem[] = [];
  constructor(private tabAndStateService: TabAndStateService,) { }

generateBreadcrumb(componentName?: string, recordName?: string, tabName?: string, childRecordname?: string, recordId?: any, subTabname?: string): void {
  switch (componentName) {
    // Home
    case "Home":
      this.breadcrumbs = [
        { label: 'Home', routerLink: ['/home'] },
      ];
      break;

    // Dashboards
    case "Dashboards":
      this.breadcrumbs = [
        { label: 'Home', routerLink: ['/home'] },
        { label: 'Dashboards', routerLink: ['#'] },
      ];
      break;
    case "KPITrendDashboard":
    case "FinanceDashboard":
    case "TeamsDashboard":
      this.breadcrumbs = [
        { label: 'Home', routerLink: ['/home'] },
        { label: 'Dashboards', routerLink: ['/Dashboards'] },
        { label: componentName === "KPITrendDashboard" ? 'KPI Trend' : componentName === "FinanceDashboard" ? 'Finance' : 'Teams', routerLink: ['#'] },
      ];
      break;

    // CodeList
    case "CodeList":
      this.breadcrumbs = [
        { label: 'Home', routerLink: ['/home'] },
        { label: 'Master Data', routerLink: ['#'] },
        { label: 'Code Lists' },
      ];
      break;
 //sponsor
 case "SponsorList":
   this.breadcrumbs = [
     { label: 'Home', routerLink: ['/home'] },
     { label: 'Master Data', routerLink: ['/sponsor'] },
     { label: 'Sponsors' },
   ];
   break;
   case "Createsponsor":
     this.breadcrumbs = [
       { label: 'Home', routerLink: ['/home'] },
       { label: 'Master Data',routerLink: ['#'] },
       { label: 'Sponsors', routerLink: ['/sponsor']},
       { label: 'Create Sponsor'},
     ];
     break;
     case "EditAndViewSponser":
     this.breadcrumbs = [
       { label: 'Home', routerLink: ['/home'] },
       { label: 'Master Data',routerLink: ['#'] },
       { label: 'Sponsors', routerLink: ['/sponsor']},
       { label: recordName},
     ];
     break;
    // Suppliers
    case "SuppliersList":
      this.breadcrumbs = [
        { label: 'Home', routerLink: ['/home'] },
        { label: 'Suppliers' },
      ];
      break;
    case "SupplierContractlist":
      this.breadcrumbs = [
        { label: 'Home', routerLink: ['/home'] },
        { label: 'Suppliers', routerLink: ['/Suppliers'] },
        { label: recordName, routerLink: ['#'] },
        { label: 'Contacts' },
      ];
      break;
    case "CreateSupplier":
      this.breadcrumbs = [
        { label: 'Home', routerLink: ['/home'] },
        { label: 'Suppliers', routerLink: ['/Suppliers'] },
        { label: 'Create Supplier' },
      ];
      break;
    case "EditAndViewSupplier":
      this.breadcrumbs = [
        { label: 'Home', routerLink: ['/home'] },
        { label: 'Suppliers', routerLink: ['/Suppliers'] },
        { label: recordName, routerLink: ['#'] },
        { label: tabName },
      ];
      break;

    // Engagements
    case "EngagementsList":
      this.breadcrumbs = [];
      break;
    case "EngagementContractlist":
      this.breadcrumbs = [
        { label: 'Home', routerLink: ['/home'] },
        { label: recordName, routerLink: ['/ViewEngagement', recordId] },
        { label: "Contracts", routerLink: ['#'] },
        { label: "Create Contract", routerLink: ['#'] },
      ];
      break;
    case "CreateEngagement":
      this.breadcrumbs = [
        { label: 'Home', routerLink: ['/home'] },
        { label: 'Create Engagement' },
      ];
      break;
    case "EditAndViewEngagement":
      this.breadcrumbs = [
        { label: 'Home', routerLink: ['/home'] },
        { label: recordName, routerLink: ['#'] },
        { label: tabName },
      ];
      break;
    case "ViewEditcontractfromEngagement":
      this.breadcrumbs = [
        { label: 'Home', routerLink: ['/home'] },
        { label: recordName, routerLink: ['/ViewEngagement', recordId] },
        { label: tabName, routerLink: ['/ViewEngagement', recordId] },
        { label: childRecordname, routerLink: ['#'] },
        { label: subTabname, routerLink: ['#'] },
      ];
      break;

    // Contracts
    case "Contractlist":
      this.breadcrumbs = [
        { label: 'Home', routerLink: ['/home'] },
        { label: 'Contracts', routerLink: ['#'] },
      ];
      break;
    case "Createcontract":
      this.breadcrumbs = [
        { label: 'Home', routerLink: ['/home'] },
        { label: 'Contracts', routerLink: ['/contracts'] },
        { label: 'Create Contract' },
      ];
      break;
    case "EditAndViewContract":
      this.breadcrumbs = [
        { label: 'Home', routerLink: ['/home'] },
        { label: 'Contracts', routerLink: ['/contracts'] },
        { label: recordName },
        { label: tabName },
      ];
      break;
    case "CreatecontractfromSupplier":
      this.breadcrumbs = [
        { label: 'Home', routerLink: ['/home'] },
        { label: 'Suppliers', routerLink: ['/Suppliers'] },
        { label: recordName, routerLink: ['/ViewSupplier', recordId] },
        { label: "Create Contract", routerLink: ['#'] },
      ];
      break;
    case "ViewEditcontractfromSupplier":
      this.breadcrumbs = [
        { label: 'Home', routerLink: ['/home'] },
        { label: 'Suppliers', routerLink: ['/Suppliers'] },
        { label: recordName, routerLink: ['/ViewSupplier', recordId] },
        { label: tabName, routerLink: ['/ViewSupplier', recordId] },
        { label: childRecordname, routerLink: ['#'] },
        { label: subTabname, routerLink: ['#'] },
      ];
      break;

    // KPI
    case "SupplierKPICreate":
    case "KPIViewSupplier":
      this.breadcrumbs = [
        { label: 'Home', routerLink: ['/home'] },
        { label: 'Suppliers', routerLink: ['/Suppliers'] },
        { label: recordName, routerLink: ['/ViewSupplier', recordId] },
        { label: 'Governance', routerLink: ['/ViewSupplier', recordId] },
        { label: 'KPIs', routerLink: ['#'] },
      ];
      break;
    case "ContractKPICreate":
      this.breadcrumbs = [
        { label: 'Home', routerLink: ['/home'] },
        { label: 'Contracts', routerLink: ['/contracts'] },
        { label: recordName, routerLink: ['#'] },
        { label: 'Governance', routerLink: ['#'] },
        { label: 'KPIs', routerLink: ['#'] },
      ];
      break;

    // User Management
    case "UserList":
      this.breadcrumbs = [
        { label: 'Home', routerLink: ['/home'] },
        { label: 'Admin', routerLink: ['#'] },
        { label: 'Authorization', routerLink: ['#'] },
        { label: 'Users', routerLink: ['#'] },
      ];
      break;
    case "UpdateUser":
    case "ViewUser":
      this.breadcrumbs = [
        { label: 'Home', routerLink: ['/home'] },
        { label: 'Admin', routerLink: ['#'] },
        { label: 'Authorization', routerLink: ['#'] },
        { label: 'Users', routerLink: ['/UserRoleMapping'] },
        { label: componentName === "UpdateUser" ? 'Edit User' : 'View User', routerLink: ['#'] },
      ];
      break;
    case "UserProfile":
      this.breadcrumbs = [
        { label: 'Home', routerLink: ['/home'] },
        { label: 'Profile', routerLink: ['#'] },
      ];
      break;
    case "RolesList":
      this.breadcrumbs = [
        { label: 'Home', routerLink: ['/home'] },
        { label: 'Admin', routerLink: ['#'] },
        { label: 'Authorization', routerLink: ['#'] },
        { label: 'Roles & Privileges', routerLink: ['#'] },
      ];
      break;
    case "ViewRole":
    case "EditRole":
    case "AddRole":
      this.breadcrumbs = [
        { label: 'Home', routerLink: ['/home'] },
        { label: 'Admin', routerLink: ['#'] },
        { label: 'Authorization', routerLink: ['/Roles/RolesList'] },
        { label: 'Roles & Privileges', routerLink: ['/Roles/RolesList'] },
        { label: componentName === "AddRole" ? 'Add Role' : recordName },
      ];
      break;

    // Master Supplier
    case "ViewMasterSupplier":
    case "EditMasterSupplierData":
      this.breadcrumbs = [
        { label: 'Home', routerLink: ['/home'] },
        { label: 'Master Data', routerLink: ['#'] },
        { label: 'Suppliers', routerLink: ['/SuppliersListMasterData'] },
        { label: componentName === "ViewMasterSupplier" ? recordName : 'Edit Supplier' },
      ];
      break;
    case "SuppliersListMasterData":
      this.breadcrumbs = [
        { label: 'Home', routerLink: ['/home'] },
        { label: 'Master Data', routerLink: ['#'] },
        { label: 'Suppliers', routerLink: ['/SuppliersListMasterData'] },
      ];
      break;
    case "AddMasterSupplierData":
      this.breadcrumbs = [
        { label: 'Home', routerLink: ['/home'] },
        { label: 'Master Data', routerLink: ['#'] },
        { label: 'Suppliers', routerLink: ['/SuppliersListMasterData'] },
        { label: 'Create Supplier' },
      ];
      break;
  }
}


  getBreadcrumbs(): MenuItem[] {
    return this.breadcrumbs;
  }

  setBreadcrumbs(breadcrumbs: MenuItem[]): void {
    this.breadcrumbs = breadcrumbs;
  }

  
  
}
