
export class UserGridModel
{
    id?:number;
    FirstName?:string;
    LastName?:string;
    EmailId?:string;
    UserName?:string;
    AssignedRoles?:string;
    GroupType?:string;
    Group?:string;
    Remarks?:string;
 }

export class UserViewModel
{
        id?:number;
        FirstName?:string;
        LastName?:string;
        EmailId?:string;
        UserName?:string;
        AssignedRoles?:string;
        GroupType?:string;
        Group?:string;
        Remarks?:string;
        createdBy?:string="System";
        createdDate?:Date;
        updatedBy?:string;
        updatedDate?:Date;
        isDeleted?:boolean;
}
export class UserRoles {
        id: number=0;
        userId?: string;
        firstName?: string;
        lastName?: string;
        emailId?: string;
        userName?:string;
        remarks?: string;
        groupType?:number;
        group?: number[];
        roles?: number[];
        createdBy?:string;
        createdDate?:Date;
        updatedBy?:string;
        updatedDate?:Date;
      }
export class CodeList{
        name: string="";
        id: number=0;
        code:string="";
        type:number=0;
        relationId:number=0;
      }

      export class UserData {
        id: number=0;
        userId?: string;
        firstName?: string;
        lastName?: string;
        emailId?: string;
        userName?:string;
        groupTypeCode?:string;
       
        groupType?:number;
        group?: number[];
        sponsers?: number[];
        suppliers?: number[];
        engagements?: number[];
        

       
      }
      export interface UsersInfo{
        id: number;
        firstName?: string;
        lastName?: string;
        name?: string;
        emailId?: string;
      }

export class UserGroupModel
{
  userId?:number;
  groupId?:number;
  email?:string;
  groupTypeCode?:string;
 }

