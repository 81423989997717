<!DOCTYPE html>
<html lang="en">
<head>
   <title> Contract Create Invoice </title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>
<div class="card-pad">
   <div class="fr-flex__container sticky-sub-header">
      <div class="fr-flex__col-8">
         <h3>Add Invoice</h3>
      </div>
   </div>
   
   <div class="scroll-start-here-sub-tabs">
         <form [formGroup]="myContractInvoiceForm">
            <div class="fr-flex__container">

               <div class="fr-flex__col-4">
                  <div class="fr-form__group">
                     <label> Invoice Number​ <em> * </em><span class="max-length">  </span></label>
                     <input type="text" formControlName="invoicenumber" [maxlength]="15" class="form-control" [(ngModel)]="contractInvoice.InvoiceNumber" (blur)="checkInvoiceNoExistence()" trimSpace/>
                     <small class="p-error" *ngIf="submitted && myContractInvoiceForm?.get('invoicenumber')?.hasError('required')">  This field is required. </small>
                     <small class="p-error" *ngIf="submitted && myContractInvoiceForm?.get('invoicenumber')?.hasError('maxlength')">   Maximum length is 15 characters. </small>
                     <small class="p-error" *ngIf="myContractInvoiceForm.get('invoicenumber')?.hasError('onlySpaces')">Only spaces not allowed.</small>
                     <small class="p-error" *ngIf="submitted && myContractInvoiceForm?.get('invoicenumber')?.hasError('pattern')">Spaces not allowed.</small>
                     <small class="p-error" *ngIf="isInvoiceNoExist">Invoice Number already exists with in selected Engagement. Please enter a unique Number.</small>
                  </div>
               </div> 
               <div class="fr-flex__col-4">
                  <div class="fr-form__group">
                     <label>Invoice Value   <em> * </em> <span class="max-length"> </span> </label>
                       <p-inputNumber formControlName="invoicevalue" inputId="minmaxfraction" mode="decimal" [minFractionDigits]="2" class="form-control-inputNumber" type="text" [maxlength]="15" [(ngModel)]="contractInvoice.InvoiceValue"  trimSpace/>
                     <small class="p-error" *ngIf="submitted && myContractInvoiceForm?.get('invoicevalue')?.hasError('required')">  This field is required. </small>
                     <small class="p-error" *ngIf="submitted && myContractInvoiceForm?.get('invoicevalue')?.hasError('invalidNumber')"> Invalid number format - Only numbers and 2 decimal places allowed. </small>
                     <small class="p-error" *ngIf="submitted && myContractInvoiceForm?.get('invoicevalue')?.hasError('maxlength')">   Maximum length is 15 characters. </small>
                  </div>
               </div>
               <div class="fr-flex__col-4">
                  <div class="fr-form__group">
                     <label>Draft Invoice Sent Date  <em> </em> </label>
                     <p-calendar appendTo="body" formControlName="draftInvoicesentdDate" class="form-control" [showIcon]="true" [(ngModel)]="contractInvoice.ContractInvoiceSentDate" [dateFormat]=_dateformart.CalenderDateFormat (ngModelChange)="onInvoiceSentDateChange($event)" [readonlyInput]="true"></p-calendar>
                     <small class="p-error" *ngIf="submitted && myContractInvoiceForm?.get('draftInvoicesentdDate')?.hasError('required')">  This field is required. </small>
                  </div>
               </div>

            
            </div>

            <div class="fr-flex__container">
               <div class="fr-flex__col-4">
                  <div class="fr-form__group">
                     <label>Draft Invoice Approval Date <i  pInputText pTooltip="Should be on or after Draft Invoice Sent Date" tooltipPosition="top" class="pi pi-info-circle"> </i> </label>
                     
                     <p-calendar appendTo="body" formControlName="draftInvoiceApprovedDate" class="form-control" [showIcon]="true" [(ngModel)]="contractInvoice.ContractInvoiceApprovedDate" [minDate]="invoiceApprovalMinDate" [dateFormat]=_dateformart.CalenderDateFormat (ngModelChange)="onInvoiceApprovalDateChange($event)" [readonlyInput]="true"></p-calendar>
                  </div>
               </div>
               <div class="fr-flex__col-4">
                  <div class="fr-form__group">
                  <label>Invoice Raised Date  <em> * </em> <i  pInputText pTooltip="Should be on or after Draft Invoice Approved Date" tooltipPosition="top" class="pi pi-info-circle"> </i> </label>
                  <p-calendar appendTo="body" formControlName="invoiceRaisedDate" class="form-control" [showIcon]="true" [(ngModel)]="contractInvoice.ContractInvoiceRaisedDate" [minDate]="invoiceRaisedMinDate" [dateFormat]=_dateformart.CalenderDateFormat (ngModelChange)="onInvoiceRaisedDateChange($event)" [readonlyInput]="true"></p-calendar>
                  
                  <small class="p-error" *ngIf="submitted && myContractInvoiceForm?.get('invoiceRaisedDate')?.hasError('required')">  This field is required. </small>
               </div>
               </div>
               <div class="fr-flex__col-4">
                  <div class="fr-form__group">
                     <label>Due Date   <em> * </em> <i  pInputText pTooltip="Should be on or after Invoice Raised Date" tooltipPosition="top" class="pi pi-info-circle"> </i></label>
                     <p-calendar appendTo="body" formControlName="dueDate" class="form-control" [showIcon]="true" [(ngModel)]="contractInvoice.ContractInvoiceDueDate" [minDate]="contractInvoice.ContractInvoiceRaisedDate || getDefaultMinDate()" [dateFormat]=_dateformart.CalenderDateFormat (ngModelChange)="onDueDateChange($event)" [readonlyInput]="true"></p-calendar>
                     <small class="p-error" *ngIf="submitted && myContractInvoiceForm?.get('dueDate')?.hasError('required')">  This field is required. </small>
                  </div>
               </div>
               </div>
         
            <div class="fr-flex__container">
               <div class="fr-flex__col-4">
                  <div class="fr-form__group">
                     <label>Status <em> * </em></label>
                     <p-dropdown appendTo="body" [filter]="true" formControlName="statusMappingId" [options]="statusData" [(ngModel)]="contractInvoice.StatusMappingId" optionLabel="name" optionValue="id" placeholder="Select Status" (onChange)="onStatusChange($event)"></p-dropdown>
                     <small class="p-error" *ngIf="submitted && myContractInvoiceForm?.get('statusMappingId')?.hasError('required')">  This field is required. </small>
                  </div>
               </div>
               <div class="fr-flex__col-4">
                  <div class="fr-form__group">
                     <label> Attachment <i  pInputText pTooltip="Only 1 Attachment allowed." tooltipPosition="top" class="pi pi-info-circle"> </i> </label>
                     <div *ngIf="uploadedInvoiceFileName==''">
                     <input class="form-control" type="file"  accept=".pdf, .xls, .xlsx,.doc,.docx"  formControlName="invoicefileSource" id="invoiceFileAttachment" (change)="onInvoiceFileChange($any($event.target).files)" >
                     
                     </div>
                     <div *ngIf="uploadedInvoiceFileName!=''">
                        <a class="attachment-active" routerLinkActive="active"> 
                           <span [pTooltip]="uploadedInvoiceFileName.length > 40 ? uploadedInvoiceFileName : ''" tooltipPosition="top"> 
                        <em> {{uploadedInvoiceFileName | truncate:39}} </em> </span> 
                        <i pInputText pTooltip="Delete" tooltipPosition="top" class="pi pi-trash"  (click)="deleteInvoiceFile()"> </i></a>
                     </div>
                  </div>
               </div>
               <div class="fr-flex__col-4">
                  <div class="fr-form__group">
                     <label>Days to Due Date  </label>
                     <input formControlName="daystoDueDate" type="text" class="form-control read-only" [readOnly]="true" [ngModel]="contractInvoice.DaysToDueDate" trimSpace />
                     <small class="p-error" *ngIf="submitted && myContractInvoiceForm?.get('daystoDueDate')?.hasError('required')">  This field is required. </small>
                  </div>
               </div>
            </div>
            <div class="fr-flex__container">
               <div class="fr-flex__col-4">
                  <div class="fr-form__group">
                     <label> Payment Amount  <em *ngIf="contractInvoice.Status === statusCodes.Paid"> * </em></label>
                     <p-inputNumber formControlName="paymentAmount" inputId="minmaxfraction" mode="decimal" [minFractionDigits]="2" class="form-control-inputNumber" type="text" [ngClass]="{'read-only-value-condication': ispaymentAmountEnable}"  [(ngModel)]="contractInvoice.PaymentAmount" (input)="onPaymentAmountInput($event)" trimSpace/>
                     <div *ngIf="contractInvoice.Status === statusCodes.Paid">
                     <small class="p-error" *ngIf="submitted && myContractInvoiceForm?.get('paymentAmount')?.hasError('required')">  This field is required. </small>
                     <small class="p-error" *ngIf="submitted && myContractInvoiceForm?.get('paymentAmount')?.hasError('invalidNumber')"> Invalid number format - Only numbers and 2 decimal places allowed. </small>
                     <small class="p-error" *ngIf="submitted && myContractInvoiceForm?.get('paymentAmount')?.hasError('maxlength')">   Maximum length is 15 characters. </small>
                  </div>
                  </div>
               </div>
               <div class="fr-flex__col-4">
                  <div class="fr-form__group">
                     <label>Payment Date   <em *ngIf="contractInvoice.Status === statusCodes.Paid"> * </em></label>
                     <p-calendar  appendTo="body" formControlName="paymentDate" class="form-control read-only-calendar-condication" [ngClass]="{'read-only-calendar-condication': !isPaymentDateEditable}" [showIcon]="true" [(ngModel)]="contractInvoice.ContractInvoicePaymentDate" [minDate]="contractInvoice.ContractInvoiceRaisedDate || getDefaultMinDate()" [dateFormat]=_dateformart.CalenderDateFormat [disabled]="!isPaymentDateEditable" [readonlyInput]="true"></p-calendar>
                     <div *ngIf="contractInvoice.Status === statusCodes.Paid">
                        <small class="p-error" *ngIf="submitted && myContractInvoiceForm?.get('paymentDate')?.hasError('required')">  This field is required. </small>
                     </div>
                     
                  </div>
               </div>
            
            </div>
            
            <div class="fr-flex__container">
               <div class="fr-flex__col-12">
                  <div class="fr-form__group">
                     <label> Remarks  <span class="max-length"> {{contractInvoice.Remarks?.length || 0}}/300 </span></label>
                     <textarea formControlName="remarks" class="form-control" [maxlength]="300" pInputTextarea [(ngModel)]="contractInvoice.Remarks" trimSpace></textarea>
                     <small class="p-error" *ngIf="submitted && myContractInvoiceForm?.get('remarks')?.hasError('maxlength')">   Maximum length is 300 characters. </small>
                  </div>
               </div>
            </div>
            <div class="fr-flex__container">
               <div class="fr-flex__col-12">
                  <div class="fr-form__group">
                     <label> Description  <span class="max-length"> {{contractInvoice.InvoiceDescription?.length || 0}}/300 </span></label>
                     <textarea formControlName="description" class="form-control" [maxlength]="300" pInputTextarea [(ngModel)]="contractInvoice.InvoiceDescription" trimSpace></textarea>
                     <small class="p-error" *ngIf="submitted && myContractInvoiceForm?.get('description')?.hasError('maxlength')">   Maximum length is 300 characters. </small>
                  </div>
               </div>
            </div>

         </form>
         </div>
               <div class="fr-flex__container footer_btns">
                  <div class="fr-flex__col-12"> 
                     <a  type="button" class="btn btn-primary btn-save pull-right" [class.disabled]="isSaveBtnDisable" (click)="isSaveBtnDisable ? null : saveInvoiceData()"> Save </a>
                     <a type="button" class="btn btn-secondary pull-right" (click)="onCancelClick()"> Cancel </a>
               </div>
               </div>
</div>
</body>
</html>