export class MasterSupplierProfile {
  id: number=0;
  name?: string="";
  supplierId: string="";
  website: string="";
  headquartersAddress: string="";
  notesAndComments: string="";
  createdBy: number=0;
  createdDate?: Date;
  updatedBy: number=0;
  updatedDate?: Date;
  isDeleted: boolean=false;
  supplierServices?: number[];
  supplierLocations?: number[];
  supplierContacts?: SupplierContact[];
  statusMappingId?:number[];
  
}
export class SupplierContact {
  id: number=0;
  contactName?: string="";
  countryCode: string="";
  phoneNumber: string="";
  email: string="";
  designation: string="";
  supplierProfileId: number=0;
}


export class GetSupplierFilter {
  SupplierIdOrName !: String;
  Services:any;
  NoOfcontracts!: string;
  EngageStrtDate:any;
  EngageEndDate:any;
  IsActive!: string;
  selectedSuplierStatus:any;
  }
export class CodeListValues {
  name: string="";
  id: number=0;
  code:string="";
  sub_code="";
  type:number=0;
}

export class SponsorContact {
  id?:number;
  name?:string;
  countryCode?:string;
  phoneNumber?:string;
  email?:string;
  sponserId?:number;
}

export class PaymentTermsDropdownBinding
{
  name?: string;
  label?:string;
  id: number=0;;
}

export class KpiChildComponents
{
  supKpiList:number=1;
  supKpiAdd:number=2;
  supKpiEdit:number=3;
  supKpiView:number=4;
}


export class AuditChildComponents
{
  supAuditList:number=1;
  supAuditAdd:number=2;
  supAuditEdit:number=3;
  supAuditView:number=4;
}

export class MeetingChildComponents
{
  supMeetingList:number=1;
  supMeetingAdd:number=2;
  supMeetingEdit:number=3;
  supMeetingView:number=4;
  supMeetingEditRecYes:number=5;
}

export class SupplierProfile {
  id: number=0;
  name?: string="";
  supplierId: string="";
  website: string="";
  headquartersAddress: string="";
  notesAndComments: string="";
  createdBy: number=0;
  createdDate?: Date;
  updatedBy: number=0;
  updatedDate?: Date;
  isDeleted: boolean=false;
  supplierServices?: number[];
  supplierLocations?: number[];
  supplierContacts?: SupplierContact[];
  StatusMappingId?:number[];
}
