import { Component, EventEmitter, Input, Output, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EngagementAddMeetingOccurenceActions, EngagementMeetingDetails, EngagementMeetingOccurrences, EngagementMeetingAttendees, EngagementMeetingAbsentees, EngagementMeetingOccurrenceAttachments, EngagementAddMultipleOccurrences, EngagementMeetingContacts } from '../../Modals/engagementmeeting';
import { ConfirmationService, MessageService, MenuItem } from 'primeng/api';
import { breadcrumnsCodes, Dateformat, FileExtension,CodeList, CodeListStatusTypes, AuditIdentifier, AuditLogEntities, AuditOperations, AuditLogModule } from 'src/app/shared/constants/global.constants';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';

import { DatePipe, formatDate } from '@angular/common';
import { GovernanceService } from '../../Services/governance.service';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { Observable } from 'rxjs';
import { LoaderService } from 'src/app/Loader/loader.service';
import { ChecknulloremptyComponent } from 'src/app/shared/checknullorempty/checknullorempty.component';
import { AssociateService } from 'src/app/Team/teams/Services/associate.service';
import { AuditLogService } from 'src/app/shared/ModelComparison/audit.log.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { EngagementService } from 'src/app/Engagement/Services/engagement.service';
import { MasterSupplierService } from 'src/app/MasterData/supplier/Services/master.supplier.service';
import { FileUploadService } from 'src/app/Engagement/Services/fileupload.service';

@Component({
  selector: 'app-eng-edit-meeting',
  templateUrl: './eng-edit-meeting.component.html',
  styleUrls: ['./eng-edit-meeting.component.css']
})
export class EngEditMeetingComponent {
  isSaveBtnDisable = false;
  @Input() engagementId: any;
  @Input() meetingId: any;
  @Input() supplierName: any;
  @Output() cancelMeetingButtonClick = new EventEmitter<void>();
  @Output() saveMeetingButtonClick = new EventEmitter<void>();
  @Output() viewMeetingButtonClick: EventEmitter<any> = new EventEmitter<any>();
  isRecurringNo: boolean = false;
  AddMultipleOccurrences: any[] = [];
  value!: string;
  addMeetingOccurenceDate: Date | undefined;
  organizerOptions: any[] = [];
  contactData: any;
  DisplayAddOccurrencesPopUP: boolean = false;
  EngagementId: any;
  engagementMeetingId: any;
  attendeesValues: any = '';
  absenteesValues: any = '';
  meetingStatus: any[] = [];
  _dateformart: any;
  addMeetingActionsData: any[] = [];
  uploadedFiles: File[] = [];
  _uploadFileForEachOccurrences: File[] = [];
  maxFileSize: any
  timeStamp: any;
  addMeetingSaved = false;
  currentFileSize: any;
  multiMeetingFileData: any[] = [];
  uploadedFileName: any;
  allowedExtensions: any;
  uploadedMeetingFile: any;
  newAttachment: any;
  submitted: boolean = false;
  attndees: any;
  absentees:any
  MeetingData: any[] = [];
  uploadedFileNames: string = "";
  _allAttendees: string[] = [];
  _finalAttendees:any;
  _enumBreadcrumb: any;
  codeListValues:any;
  codeListStatusTypes:any;
  _addActionsFieldsMandatory: boolean = false;
  formData = new FormData();
  engagementAddMeetingForm: FormGroup;
  myContractDetailsForm: FormGroup;

  _engagementmeetingActions = new EngagementAddMeetingOccurenceActions();
  engagementMeetingDetails = new EngagementMeetingDetails();
 _engagementMeetingOccurrences = new EngagementMeetingOccurrences();
 _engagementMeetingAttendees = new EngagementMeetingAttendees();
 _engagementMeetingAbsentees = new EngagementMeetingAbsentees();
 _engagementMeetingOccurrenceAttachments = new EngagementMeetingOccurrenceAttachments();
 _engagementMeetingOccurrenceDetails: EngagementMeetingOccurrences[] = [];
 _engagementMeetingAttendeesDetails: EngagementMeetingAttendees[] = [];
 _engagementMeetingAbsenteesDetails: EngagementMeetingAbsentees[] = [];
 _engagementMeetingOccurrenceAttachmentsDetails: EngagementMeetingOccurrenceAttachments[] = [];
 _engagementMeetingActionsDetails: EngagementAddMeetingOccurenceActions[] = [];

   _engagmentAddMultipleOccurrences: EngagementAddMultipleOccurrences[] = [];
   _engagementMeetingAbsenteesarray: EngagementMeetingAbsentees[] = [];
  isUploadButtonDisabled:boolean= false;
  meetingCreatedByUsername:string='';
  meetingUpdatedByUsername:string='';
  contactFormGroup:FormGroup;
  contactName: string = '';
  constructor(
    private governanceService: GovernanceService,
    private messageService: MessageService,
    private loaderService: LoaderService,
    private masterDataService: SponserService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private _fileService: FileUploadService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private breadcrumbService: BreadcrumbService,
    private checkNullOrEmptyValuesService: ChecknulloremptyComponent,
    private _associateService:AssociateService,
    private auditLogService: AuditLogService,
    private privilegeService:PrivilegeService,
    private engagementService: EngagementService,
    private _mastersupplierService: MasterSupplierService,
  ) {
    this.myContractDetailsForm = this.fb.group({

    });
    this.engagementAddMeetingForm = this.fb.group({
      addMeetingTitle: ['', [Validators.required, Validators.maxLength(50), Validators.minLength(3), this.noOnlySpacesValidator]],
      addMeetingOrganizer: ['', [Validators.required]],
      addMeetingRecurring: ['', [Validators.required]],
      addMeetingOccurenceDate: ['', [Validators.required]],
      addMeetingFileSource: [''], addMeetingFileName: '',
      addMeetingAttachments: [''],
      addMeetingAgenda: ['', [Validators.required]],
      addMeetinMeetingNotes: [''],
      addMeetingDecisions: [''],
      addMeetingAttendees:[''],
      addMeetingAbsentees:[''],
      _attendent: [''],
      _absent: [''],
    });
    this.contactFormGroup=this.fb.group({
      contractName:['',[Validators.required,Validators.maxLength(30),Validators.minLength(3),Validators.pattern(/^[A-Za-z- ]{3,30}$/)]],
    });
  }

  auditIdentifierDetails:any;
  auditEntities:any;
  auditOperations:any;
  auditModule:any;

  ngOnInit() {
    this._dateformart = Dateformat;
    this.EngagementId =this.engagementId ;
    this.engagementMeetingId = this.meetingId;
    this._enumBreadcrumb=breadcrumnsCodes;
    this.codeListValues=CodeList;
    this.codeListStatusTypes=CodeListStatusTypes
    this.GetEngagementDetailsById();
    this.GetEngagementMeetingById(this.engagementMeetingId);
    this.GetMeetingStatus(this.codeListValues.Tbl_Status, this.codeListStatusTypes.MASTS);
    this.formOldEngagementMeeting();
    this.auditIdentifierDetails= AuditIdentifier;
    this.auditEntities=AuditLogEntities;
    this.auditOperations=AuditOperations;
    this.auditModule=AuditLogModule;

   
  }
  sponserId:number=0;
  engagementData:any;
  GetEngagementDetailsById() {
    this.loaderService.showLoader();
    this.engagementService.GetEngagementById(this.EngagementId)
      .subscribe(res => {    
        this.engagementData = res.data;
        this.sponserId=this.engagementData.sponsorId;
       this.GetSupplierContacts(res.data.supplierId);
        this.GetSponserDetailsById();
      });
  }
GetSupplierContacts(Id:number){
  this._mastersupplierService.getSupplierContactsDataById(Id)
  .subscribe(res=>{
    this.contactData = res.data;
  })
}
  sponserData:any;
sponsorContactData:any;
  GetSponserDetailsById(){
   this.masterDataService.getSponserData(this.sponserId)
   .subscribe(res=>{
     this.sponserData=res.data.sponserInfo;
     this.sponsorContactData=res.data.sponserContacts;
     this.GetTeamAssociatesList(this.engagementId,2)
   
   })
  }
  associateData:any;
  GetTeamAssociatesList(conractIdOrSupplierId:any,teamType:number){
   
    this._associateService.GetTeamAssociatesListByEngagementId(conractIdOrSupplierId,teamType).subscribe((res:any)=>{
      if(res.status){
    
      this.associateData=res.data;
      this.updateOrganizerOptions();
      }
    });
  }



  updateOrganizerOptions() {
    if (this.engagementData !== undefined && this.contactData !== undefined) {
      const engagementOptions = this.contactData.map((contact: { contactName: any; }) => `${contact.contactName} ( ${this.supplierName} )`);
      if (!this.organizerOptions) {
        this.organizerOptions = [];
      }

      if(this.organizerOptions!=undefined){
        this.organizerOptions = this.organizerOptions.concat(engagementOptions);
        }
        if(this.attndees!=undefined){
          this.attndees=this.attndees.concat(engagementOptions);
        }
        if(this.absentees!=undefined){
          this.absentees=this.absentees.concat(engagementOptions);
        }
      this._allAttendees=this._allAttendees.concat(engagementOptions);
      
        if(this.attndees!=undefined){
      this.attndees = this.attndees.split(',').map((item: string) => item.trim());
        }
        if(this.absentees!=undefined){
      this.absentees = this.absentees.split(',').map((item: string) => item.trim());
        }
    }
    if (this.sponserData !== undefined && this.sponsorContactData !== undefined) {
      const sponsorOptions = this.sponsorContactData.map((contact: { name: any; }) => `${contact.name} ( ${this.sponserData.name} )`);
      if (!this.organizerOptions) {
        this.organizerOptions = [];
      }
      if(this.organizerOptions!=undefined){
        this.organizerOptions = this.organizerOptions.concat(sponsorOptions);
        }
        if(this.attndees!=undefined){
          this.attndees=this.attndees.concat(sponsorOptions);
        }
        if(this.absentees!=undefined){
          this.absentees=this.absentees.concat(sponsorOptions);
        }
      this._allAttendees=this._allAttendees.concat(sponsorOptions);
    }
     //added by vittal for associates
     if (this.associateData !== undefined && this.associateData !== null) {
      const associateOptions = this.associateData.map((contact: { associateName: any; }) => `${contact.associateName} ( Associate )`);
      if (!this.organizerOptions) {
        this.organizerOptions = [];
      }
      if(this.organizerOptions!=undefined){
      this.organizerOptions = this.organizerOptions.concat(associateOptions);
      }
      if(this.attndees!=undefined){
      this.attndees=this.attndees.concat(associateOptions);
      }
      if(this.absentees!=undefined){
      this.absentees=this.absentees.concat(associateOptions);
      }
      if(this._allAttendees!=undefined){
      this._allAttendees=this._allAttendees.concat(associateOptions);
      }
    }
           //added by nagaraju for ContractMeetingContacts
           if (this.meetingContacts !== undefined && this.meetingContacts !== undefined) {
            const meetingContactsOptions = this.meetingContacts.map((contact: { contactName: any; }) => `${contact.contactName}`);
            if (!this.organizerOptions) {
              this.organizerOptions = [];
            }
            if(this.organizerOptions!=undefined){
              this.organizerOptions = this.organizerOptions.concat(meetingContactsOptions);
              
              }
              if(this.attndees!=undefined){
                this.attndees=this.attndees.concat(meetingContactsOptions);
              }
              if(this.absentees!=undefined){
                this.absentees=this.absentees.concat(meetingContactsOptions);
              }
            this._allAttendees=this._allAttendees.concat(meetingContactsOptions);
            this.loaderService.hideLoader();
            this.loaderService.hidefinalLoader();
          }
    let attendeesObjects: { name: string; code: number }[] = [];
    if (this._allAttendees.length > 0) {
        attendeesObjects = this._allAttendees.map((attendee, index) => ({
            name: attendee,
            code: index + 1, 
        }));
  }
  this._finalAttendees=attendeesObjects;
  this._allAttendees = attendeesObjects.map((attendee) => attendee.name);

  if (this.attendeesValues.length > 0) {
    this.attendeesValues = this._finalAttendees.filter((attendee: { name: any; }) => this.attendeesValues.includes(attendee.name));
  } 

  if (this.absenteesValues.length > 0) {
    this.absenteesValues = this._finalAttendees.filter((absentee: { name: any; }) => this.absenteesValues.includes(absentee.name));
  }
  if (!this.organizerOptions.includes(this.engagementMeetingDetails.Organizer)) {
    this.organizerOptions.push(this.engagementMeetingDetails.Organizer);
  }

  
}

OrganizeOptionsList(existingValues: string[], options: string[]) {
  existingValues.forEach(value => {
    if (!options.includes(value)) {
      options.push(value);
    }
  });
}

engagementsList: string[] = [];
updateEngagementOptions(options: string[]) {
  const allOptions = new Set(options);
  
  this.attndees.forEach((attendee: string) => allOptions.add(attendee));
  this.absentees.forEach((absentee: string) => allOptions.add(absentee));
  this.engagementsList = Array.from(allOptions);
}

  attachments:any;
  oldattendees:any;
  oldabsentees:any;
  oldactions:any;
  meetingContacts:any;
  GetEngagementMeetingById(engaegmentMeetingId: any) {
    this.governanceService.getEngagementMeetingsById(engaegmentMeetingId)
      .subscribe(res => {
        if (res != null) {
          this.engagementMeetingDetails.Title = res.data.title;
           this.engagementMeetingDetails.Organizer = res.data.organizer;
          this.engagementMeetingDetails.Recurring = res.data.recurring;
          if (this.engagementMeetingDetails.Recurring == false) {
            this.isRecurringNo = true
          }
          this.meetingContacts=res.data.engagementMeetingOccurrences[0].engagementMeetingContacts;
          this._engagementMeetingOccurrences.OccurrenceDate = new Date(res.data.engagementMeetingOccurrences[0].occurrenceDate);
          this.engagementMeetingDetails.Agenda = res.data.agenda;
          this.engagementMeetingDetails.CreatedDate=res.data.createdDate;
          this.meetingCreatedByUsername=this.privilegeService.getUserName(res.data.createdBy);
          this.meetingUpdatedByUsername=this.privilegeService.getUserName(res.data.updatedBy);
         
          this.engagementMeetingDetails.UpdatedDate=res.data.updatedDate;
          
          if( res.data.engagementMeetingOccurrences[0].engagementMeetingAttendees[0]!=undefined){
            this._engagementMeetingAttendees.Attendees = res.data.engagementMeetingOccurrences[0].engagementMeetingAttendees[0].attendees.split(',');
            this.attndees = res.data.engagementMeetingOccurrences[0].engagementMeetingAttendees[0].attendees.split(',').join(',');
            this._engagementMeetingAttendees.Id = res.data.engagementMeetingOccurrences[0].engagementMeetingAttendees[0].id;
            this.attendeesValues=this.attndees;

            this.attendeesValues=this.attendeesValues ? this.attendeesValues.split(',') : [];
            this.oldattendees=this.attendeesValues;
            


          }
          if( res.data.engagementMeetingOccurrences[0].engagementMeetingAbsentees[0]!=undefined){
            this._engagementMeetingAbsentees.Absentees = res.data.engagementMeetingOccurrences[0].engagementMeetingAbsentees[0].absentees.split(',');
            this.absentees = res.data.engagementMeetingOccurrences[0].engagementMeetingAbsentees[0].absentees.split(',').join(',');
            this._engagementMeetingAbsentees.Id = res.data.engagementMeetingOccurrences[0].engagementMeetingAbsentees[0].id;
            this.absenteesValues=this.absentees  
            this.absenteesValues=this.absenteesValues ? this.absenteesValues.split(',') : [];
            this.oldabsentees=this.absenteesValues;

          }
          this.myaction= res.data.engagementMeetingOccurrences[0].engagementMeetingOccurrenceActions;
          setTimeout(() => {
            this.ExistingActions();
           }, 2000);
          this.oldactions=this.myaction;
          this._engagementMeetingOccurrences.MeetingNotes = res.data.engagementMeetingOccurrences[0].meetingNotes;
          this._engagementMeetingOccurrences.Decisions = res.data.engagementMeetingOccurrences[0].decisions;
          this._engagementMeetingOccurrences.CreatedDate=res.data.engagementMeetingOccurrences[0].createdDate;
          this._engagementMeetingOccurrences.EngagementMeetingId=res.data.engagementMeetingOccurrences[0].engagementMeetingid;
          this._engagementMeetingOccurrences.Id = res.data.engagementMeetingOccurrences[0].id;
          this._engagementMeetingOccurrences.EngagementMeetingId =res.data.engagementMeetingOccurrences[0].engagementMeetingId ;

     
          if(res.data.engagementMeetingOccurrences[0].engagementMeetingOccurrenceAttachments.length>0){
            this._engagementMeetingOccurrenceAttachments.Id = res.data.engagementMeetingOccurrences[0].engagementMeetingOccurrenceAttachments[0].id;
            this._engagementMeetingOccurrenceAttachmentsDetails= res.data.engagementMeetingOccurrences[0].engagementMeetingOccurrenceAttachments;
            this.uploadedFiles = res.data.engagementMeetingOccurrences[0].engagementMeetingOccurrenceAttachments.map((attachment: { fileName: any; }) => {
              return new File([attachment.fileName], attachment.fileName);
            });
            this.uploadedFileNames = this.uploadedFiles.map(file => file.name).join(', ');
          if(this.uploadedFiles.length >= 3)   
          {     
            this.isUploadButtonDisabled = true; 
          }
          }
          
        }
              
          this.oldEngagementMeetingData = JSON.stringify(this.engagementMeetingDetails);
      this.engagementMeetingDetailBeforeUpdate = JSON.stringify(this.engagementMeetingDetails);

        this.oldEngagementMeetingData = JSON.stringify({
       ...JSON.parse(this.oldEngagementMeetingData),
        EngagementMeetingOccurrences: this._engagementMeetingOccurrences
      });

        this.engagementMeetingDetailBeforeUpdate = JSON.stringify({
        ...JSON.parse(this.engagementMeetingDetailBeforeUpdate),
        EngagementMeetingOccurrences: this._engagementMeetingOccurrences
        });
              
      })
      
  }
    
  options: any[] = [
    { name: 'Yes', value: true }, // Adjust these options as needed
    { name: 'No', value: false },
  ];
  onRecurringChange(event: any) {
    this.engagementMeetingDetails.Recurring = event.value;
  }
  

  onFileChange(event: any) {
    const files: FileList = event.target.files;
    if (files.length <= 1) {
      this.maxFileSize = this._fileService._fileSize; // 20 MB (value Bytes in binary)
      if (files.length > 0) {
        this.currentFileSize = files[0].size;
        this.uploadedFileName = files[0].name;
        this.allowedExtensions = [FileExtension.DOC, FileExtension.DOCX, FileExtension.PDF, FileExtension.XLS, FileExtension.XLSX,FileExtension.PPT,FileExtension.PPTX];
        var uploadedExtension = this.uploadedFileName.split('.').pop().trim().toLowerCase();
      }
      // Check if the uploaded file's extension is allowed
      if (this.allowedExtensions.includes(uploadedExtension)) {
        if (this.currentFileSize <= this.maxFileSize) {
          if (this.uploadedFiles.length + files.length <= 3) {
            for (let i = 0; i < files.length; i++) {
              this.uploadedFiles.push(files[i]);
              this.engagementAddMeetingForm.patchValue({
                addMeetingAttachments: files[0].name,
              });
              this.uploadedMeetingFile = files[0];
              this.multiMeetingFileData.push(this.uploadedMeetingFile);
             this.timeStamp = "";
             var utcDateTimeNow = new Date().toISOString();
             this.timeStamp = formatDate(utcDateTimeNow, this._dateformart.FiletimeStamp, this._dateformart.TimeStampZone);
             //this.formData.append("files[]", file, fileName);
              this.formData.append("file", this.uploadedMeetingFile, "Governance/EngagementMeeting/" + this.timeStamp + "_" + this.uploadedMeetingFile.name);
              this._engagementMeetingOccurrenceAttachments.FileName = this.uploadedMeetingFile.name;
              this._engagementMeetingOccurrenceAttachments.FilePath = "Governance/EngagementMeeting/" + this.timeStamp + "_" + this._engagementMeetingOccurrenceAttachments.FileName;
             
              this.newAttachment = {
                      FileName: this._engagementMeetingOccurrenceAttachments.FileName,
                      FilePath: this._engagementMeetingOccurrenceAttachments.FilePath
                    };
              this._engagementMeetingOccurrenceAttachmentsDetails.push(this.newAttachment);
            }
          } else {
            this.engagementAddMeetingForm.patchValue({
              addMeetingFileName: '',
              addMeetingFileSource: ''

            });

            this.messageService.add({ key: 'tc', severity: 'error', summary: 'Maximum 3 files allowed.', detail: '' });
          }
        }
        else {
          this.engagementAddMeetingForm.patchValue({
            addMeetingFileName: '',
            addMeetingFileSource: ''
          });
          this.messageService.add({ key: 'tc', severity: 'warn', summary: 'File Size limited to 20 Mb', detail: '' });
        }
      }
      else {
        this.engagementAddMeetingForm.patchValue({
          addMeetingFileName: '',
          addMeetingFileSource: ''
        });
        this.messageService.add({ key: 'tc', severity: 'error', summary: 'Invalid file type. Only .pdf, .xls, .xlsx, .doc, .docx files are allowed.', detail: '' });
      }

    }
    else {
      this.engagementAddMeetingForm.patchValue({
        addMeetingFileName: '',
        addMeetingFileSource: ''
      });
      this.messageService.add({ key: 'tc', severity: 'error', summary: 'Select and upload 1 file at a time.', detail: '' });

    }
    if(this.uploadedFiles.length >= 3)
    {
      this.isUploadButtonDisabled = true;
    }
    else{
      this.isUploadButtonDisabled = false;
    }
  }
  deleteUploadedFiles: any[] = [];
  removeFile(file: File) {
    const index = this.uploadedFiles.indexOf(file);
    if (index !== -1) {
      this.uploadedFiles.splice(index, 1);

      const attachmentIndex = this._engagementMeetingOccurrenceAttachmentsDetails.findIndex(attachment => attachment.FileName === file.name);
      if (attachmentIndex !== -1) {
        this._engagementMeetingOccurrenceAttachmentsDetails.splice(attachmentIndex, 1);
      }
      this._engagementMeetingOccurrenceAttachmentsDetails = this._engagementMeetingOccurrenceAttachmentsDetails.map(e => {
        return this.convertKeysToUppercase(e);
    });
    var isOldOccurrenceAttachement = this._engagementMeetingOccurrenceAttachmentsDetails.some(item => item.hasOwnProperty('Id'));
    if(isOldOccurrenceAttachement){
      const editOccurremceAttachment= this._engagementMeetingOccurrenceAttachmentsDetails.findIndex(s => s.FileName == file.name && s.Id != 0);
      if (editOccurremceAttachment !== -1) {    
        var filedetails = {
          "fileName":  this._engagementMeetingOccurrenceAttachmentsDetails[editOccurremceAttachment].FileName,
          "filePath":  this._engagementMeetingOccurrenceAttachmentsDetails[editOccurremceAttachment].FilePath
        };
        if (filedetails.fileName != "" && filedetails.filePath != "") {                    
              this.deleteUploadedFiles.push(filedetails);    
              this._engagementMeetingOccurrenceAttachmentsDetails.splice(editOccurremceAttachment,1)                          
          }
      }   
    }
    else{
      if(this._engagementMeetingOccurrenceAttachmentsDetails)
      {
        const index = this._engagementMeetingOccurrenceAttachmentsDetails.findIndex(attachment => attachment.FileName === file.name);
        if (index !== -1) {      
          this._engagementMeetingOccurrenceAttachmentsDetails.splice(index,1)
      }     
      }
    }

    }
    if(this.uploadedFiles.length >= 3)
    {
      this.isUploadButtonDisabled = true;
    }
    else{
      this.isUploadButtonDisabled = false;
    }
  }
  
  _engagementAddOccurrences = new EngagementAddMultipleOccurrences();
  
    convertKeysToUppercase(obj: any): any {
      const convertedObject: any = {};
    
      for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
              const uppercaseKey = key.charAt(0).toUpperCase() + key.slice(1);
              convertedObject[uppercaseKey] = obj[key];
          }
      }
    
      return convertedObject;
    }

  UpdateMeetingDetails()//click on save button in edit meeting screen
  {
    this.loaderService.showLoader();
    this.addMeetingSaved=true;
    this.isSaveBtnDisable = true;
    setTimeout(() => {
      this.isSaveBtnDisable = false;
    }, 3000);

    if (this.deleteUploadedFiles.length > 0 ) {
      this.deleteOccurrenceAttachment();
    }
    this._addActionsFieldsMandatory = this.addMeetingActionsData.some((Actions) => {
      if (
          this.checkNullOrEmptyValuesService.isNullOrUndefinedOrEmpty(Actions.actionName) || this.checkNullOrEmptyValuesService.isNullOrUndefinedOrEmpty(Actions.statusMappingId) || (Actions.statusMappingId.code === "CLD" &&
              (this.checkNullOrEmptyValuesService.isNullOrUndefinedOrEmpty(Actions.closureDate) || this.checkNullOrEmptyValuesService.isNullOrUndefinedOrEmpty(Actions.remarks)))
      ) {
          return true;
      }
      return false;
  }); 
    if (this.engagementAddMeetingForm.valid && !this._addActionsFieldsMandatory) { 
      this.executeCanExist=false;
    if (this.multiMeetingFileData.length > 0) {
      this._fileService.UploadMultipleFile(this.formData).subscribe((response: any) => {
        if (response) {
          this.updateMeetingData();
        }
        else {
          this.loaderService.hideLoader();
          this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Add Meeting File Failed to save', detail: '' });
        }
      })
    }
    else {
      this.updateMeetingData();
    }
  }else {
    this.loaderService.hideLoader();
    this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Please enter required information', detail: '' });
  }
  }

  deleteOccurrenceAttachment(){
    this._fileService.DeleteFile(this.deleteUploadedFiles).subscribe(
      res => {
    
        this.updateMeetingData();  
      }
    );
    }

  _PostAddActionsMeetingData:any;
  updateMeetingData() {
    this.loaderService.showLoader();
     this.myaction = this.addMeetingActionsData.map(action => ({
      id: action.Id,
      actionName: action.actionName,
      owner: action.owner,
      targetDate: action.targetDate,
      statusMappingId: action.statusMappingId,
      closureDate: action.closureDate,
      remarks: action.remarks,
      engagementMeetingOccurrenceId: action.engagementMeetingOccurrenceId,
      isDeleted: action.isDeleted,
      createdDate:action.createdDate ? action.createdDate : new Date(),
      createdBy:action.CreatedBy =this.privilegeService.getLoginUserId(),
     
    }));
   
    this.myaction.forEach((record:any) => {
      record.statusMappingId = record.statusMappingId.id;
      record.targetDate = this.datePipe.transform(record.targetDate, "MM/dd/yyyy");
      record.closureDate = this.datePipe.transform(record.closureDate, "MM/dd/yyyy");
      this._engagementMeetingActionsDetails.push(record);
    }, this); 


    this.engagementMeetingDetails.Id = this.engagementMeetingId;
    this.engagementMeetingDetails.EngagementId = this.EngagementId;
     this.engagementMeetingDetails.UpdatedBy =this.privilegeService.getLoginUserId(),
     this._engagementMeetingOccurrences.OccurrenceDate = this.datePipe.transform(this._engagementMeetingOccurrences.OccurrenceDate, "MM/dd/yyyy");

    if (this.attendeesValues !== "") {
      this._engagementMeetingAttendees.Attendees = this.attendeesValues.map((attendee: { name: any; }) => attendee.name).join(',');
      this._engagementMeetingAttendeesDetails.push(this._engagementMeetingAttendees);
    }

    if (this.absenteesValues !== "") {
      this. _engagementMeetingAbsentees.Absentees = this.absenteesValues.map((absentees: { name: any; }) => absentees.name).join(',');
      this. _engagementMeetingAbsenteesDetails.push(this. _engagementMeetingAbsentees);
    }

    this._engagementmeetingActions=this.myaction;
    this.engagementMeetingDetails.EngagementMeetingOccurrences = this._engagementMeetingOccurrenceDetails;
    
    this._engagementMeetingOccurrences.EngagementMeetingOccurrenceActions= this.myaction;
    this._engagementMeetingOccurrenceDetails.push(this._engagementMeetingOccurrences);
    this._engagementMeetingActionsDetails.push(this._engagementmeetingActions)
    
    this.engagementMeetingDetails.EngagementMeetingOccurrences = this._engagementMeetingOccurrenceDetails;
    this._engagementMeetingOccurrences.EngagementMeetingAbsentees= this._engagementMeetingAbsenteesDetails;
    this._engagementMeetingOccurrences.EngagementMeetingAttendees = this._engagementMeetingAttendeesDetails;
    this._engagementMeetingOccurrences.EngagementMeetingOccurrenceAttachments = this._engagementMeetingOccurrenceAttachmentsDetails;
    this._engagementMeetingOccurrences.EngagementMeetingOccurrenceActions = this.myaction;

    this.governanceService.EngagementUpdateMeetingData(this.engagementMeetingDetails).subscribe(
      res => {
        this.loaderService.hideLoader();
        this.messageService.add({
          key: 'tc',
          severity: 'success',
          summary: 'Changes have been saved',
          detail: '',
        });
       this.compareModels();
       const _dataResult = {
        engagementId:this.engagementId,
        meetingId:this.meetingId,
      };
      this.viewMeetingButtonClick.emit(_dataResult);

      }

    );
    
  }

  generateBreadcrumb(component?: string, recordName?: string, tabName?: string, childRecName?: string, recordId?: number) {
    this.breadcrumbService.generateBreadcrumb(component, recordName, tabName, childRecName, recordId);
  }

  getBreadcrumbs(): MenuItem[] {
    return this.breadcrumbService.getBreadcrumbs();
  }
  downloadAttachemnt(fileName: any, filePath: any) { // download Uploaded Attachemnets 
    var filedetail = {
      "fileName": fileName,
      "filePath": filePath
    };
    this._fileService.DownloadFile(filedetail).subscribe(
      res => {
        var test = res;
        var blobData = this._fileService.dataURItoBlob(res);
        this._fileService.downloadBase64File(blobData, filedetail.fileName);
      }
    );

  }
  noOnlySpacesValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value && control.value.trim().length === 0) {
      return { onlySpaces: true };
    }
    return null;
  }
  executeCanExist: boolean = true;
  _oldEngagementMeeting: any
  canExist(): boolean | Promise<boolean> | Observable<boolean> { 
  
    if (this.executeCanExist && ((!(this._oldEngagementMeeting==JSON.stringify(this.engagementMeetingDetails))) || (!(this._oldEngagementOccurences==JSON.stringify(this._engagementMeetingOccurrences))) || (!(this._oldEngagementAttendees==JSON.stringify(this._engagementMeetingAttendees))) || (!(this._oldEngagementAbsentees==JSON.stringify(this._engagementMeetingAbsentees))) || (!(this._oldEngagementAttachments==JSON.stringify(this._engagementMeetingOccurrenceAttachments))) || (!(this._oldEngagementActions==JSON.stringify(this._engagementmeetingActions))) )) {
      return new Promise<boolean>((resolve) => {
        this.confirmationService.confirm({
          message: 'Changes will not be saved, Do you want to Continue?',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.messageService.add({
              key: 'tc',
              severity: 'info',
              summary: 'Changes Not Saved',
              detail: '',
            });
            resolve(true);
          },
          reject: () => {
            resolve(false);
          },
        });
      });
    }
    else {
      return true;
    }

  }
  onCancelEngagementMeeting()
  {
    const dataResult = {
      engagementId:this.engagementId,
      meetingId:this.meetingId,
    };
    if((!(this._oldEngagementMeeting==JSON.stringify(this.engagementMeetingDetails))) || (!(this._oldEngagementOccurences==JSON.stringify(this._engagementMeetingOccurrences))) || (!(this._oldEngagementAttendees==JSON.stringify(this._engagementMeetingAttendees))) || (!(this._oldEngagementAbsentees==JSON.stringify(this._engagementMeetingAbsentees))) || (!(this._oldEngagementAttachments==JSON.stringify(this._engagementMeetingOccurrenceAttachments))) || (!(this._oldEngagementActions==JSON.stringify(this._engagementmeetingActions))) )
     {
    
      this.confirmationService.confirm({
        message: 'Changes will not be saved, Do you want to Continue?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.cancelMeetingButtonClick.emit();
          this.messageService.add({
            key: 'tc',
            severity: 'info',
            summary: 'Changes not saved',
            detail: '',
        });
           },
           reject: () => {
           },
         });
     }
     else{
       this.cancelMeetingButtonClick.emit();
     }
  }
  _oldEngagementOccurences:any;
  _oldEngagementAttendees:any;
  _oldEngagementAbsentees:any;
  _oldEngagementAttachments:any;
  _oldEngagementActions:any;

    formOldEngagementMeeting(){   
    setTimeout(() => {
      this._oldEngagementMeeting=JSON.stringify(this.engagementMeetingDetails);
    this._oldEngagementOccurences=JSON.stringify(this._engagementMeetingOccurrences);
    this._oldEngagementAttendees=JSON.stringify(this._engagementMeetingAttendees);
    this._oldEngagementAbsentees=JSON.stringify(this._engagementMeetingAbsentees);
    this._oldEngagementAttachments=JSON.stringify(this._engagementMeetingOccurrenceAttachments);
    this._oldEngagementActions=JSON.stringify(this._engagementmeetingActions);


    }, 2000);
  }
  handleAddActionClick(event: Event) {
    event.preventDefault();
    this.AddMeetingActions();
  }
myaction:any;
ExistingActions(){
  if (this.addMeetingActionsData.length === 0) {
    for (const action of this.myaction) {  
     if(action.closureDate!=null)
     {
        action.closureDate = new Date(action.closureDate);
     }
     if(action.targetDate!=null)
     {
        action.targetDate = new Date(action.targetDate);
     }
     this.OrganizeOptionsList([action.owner], this.organizerOptions);
      action.statusMappingId=this.meetingStatus.find(status => status.id === action.statusMappingId);
      this.addMeetingActionsData.push({
        Id: action.id,
        actionName: action.actionName,
        owner: action.owner,
        targetDate: action.targetDate,
        statusMappingId: action.statusMappingId,
        closureDate: action.closureDate,
        remarks: action.remarks,
        engagementMeetingOccurrenceId: action.engagementMeetingOccurrenceId,
        isDeleted: false,
        createdDate:action.createdDate,
        createdBy:action.createdBy,
        updatedBy:action.updatedBy
        
      });
      
    }
  }
}
  AddMeetingActions() {
      // If the array is not empty, add a new empty row
      this.addMeetingActionsData.unshift({
        Id: 0,
        actionName: '',
        owner: '',
        targetDate: null,
        statusMappingId: '',
        closureDate: null,
        remarks: '',
        engagementMeetingOccurrenceId: 0,
        isDeleted: false,
        createdDate:null,
        updatedBy:null,
      });
   
  }
  

  deleteAction(currentActionIndex: number) {  
    this.confirmationService.confirm({
         header: 'Confirm',
         message: 'Are you sure you want to delete this action?',//+" "+ addmeeting.ActionName + '?',
         accept: () => {
         this.addMeetingActionsData.splice(currentActionIndex, 1);
         this.messageService.add({ severity: 'success', summary: 'Meeting Action Deleted', detail: '', });
         }
       });
     }
     GetMeetingStatus(enumId: number, type: string) { //Get Meeting Status's from code list for the Meetings
      this.masterDataService.getCodeListData(enumId, type).subscribe(
        res => {      
          if (res.data.length > 0) {
            this.meetingStatus = res.data;
            
          }
        }
      );
    };

      changedEngagementMeetingBasicInfo:any;
      oldEngagementMeetingData:any;
      engagementMeetingDetailBeforeUpdate:any
      compareModels() {
       this.engagementMeetingDetailBeforeUpdate=JSON.parse(this.engagementMeetingDetailBeforeUpdate)
       this.engagementMeetingDetailBeforeUpdate.EngagementMeetingOccurrences.OccurrenceDate=new DatePipe('en-US').transform(new Date( this.engagementMeetingDetailBeforeUpdate.EngagementMeetingOccurrences.OccurrenceDate), this._dateformart.AuditLogFormat);
       this.engagementMeetingDetailBeforeUpdate.EngagementId=this.engagementMeetingDetails.EngagementId;
       this.engagementMeetingDetailBeforeUpdate.EngagementMeetingOccurrences.EngagementMeetingAbsentees=this.oldabsentees;
       this.engagementMeetingDetailBeforeUpdate.EngagementMeetingOccurrences.EngagementMeetingAttendees=this.oldattendees;
       this.engagementMeetingDetailBeforeUpdate.EngagementMeetingOccurrences.EngagementMeetingOccurrenceActions=this.oldactions;

       const actions = this.engagementMeetingDetailBeforeUpdate.EngagementMeetingOccurrences.EngagementMeetingOccurrenceActions;

       actions.forEach((item: { closureDate: string | number | Date | null; targetDate: string | number | Date | null; }) => {
        if (item.closureDate) {
          item.closureDate = new DatePipe('en-US').transform(new Date(item.closureDate), this._dateformart.AuditLogFormat);
        }
        if (item.targetDate) {
          item.targetDate = new DatePipe('en-US').transform(new Date(item.targetDate), this._dateformart.AuditLogFormat);
        }
      });
      
       this.engagementMeetingDetailBeforeUpdate.EngagementMeetingOccurrences.EngagementMeetingOccurrenceAttachments=this.uploadedFileNames;

       this.engagementMeetingDetailBeforeUpdate=JSON.stringify(this.engagementMeetingDetailBeforeUpdate)
        this.changedEngagementMeetingBasicInfo = this.auditLogService.compareModels(JSON.parse(this.engagementMeetingDetailBeforeUpdate),this.engagementMeetingDetails,this.auditEntities.EngagementMeetings,this.auditIdentifierDetails.EngagementMeetings,this.auditOperations.Update, this.auditModule.GovernanceService);
      }

      get _attendees() {
        if(this._finalAttendees!=undefined)
        return this._finalAttendees.filter((a: any) => !this.absenteesValues.includes(a));
      else
      return [];
      }
      get _absentees() {
        if(this._finalAttendees!=undefined)
        return this._finalAttendees.filter((a: any) => !this.attendeesValues.includes(a));
      else
      return [];
      
      }
      contactHeader='';
contactSubmitted=false;
displayContractGroupDialog:boolean=false;
contractGroup:any;
currentField: any;
openContractModel(field: string) {
  this.contactName='';
  this.contactHeader = 'Add Contact';
  this.currentField = field;
  this.contactFormGroup.reset();
  Object.keys(this.contactFormGroup.controls).forEach(key => {
    this.contactFormGroup?.get(key)?.setErrors(null);
  });
  this.contactSubmitted = false;
  this.contractGroup = { name: "" };
  this.displayContractGroupDialog = true;
}
  validateOnlyNumberandSpecialCharecters(control: AbstractControl): ValidationErrors | null{
    const alphaRegex = /[a-zA-Z]/; // Ensure at least one alphabetic character
    if (control.value && !alphaRegex.test(control.value)) {
      return { invalidNumberSpecialCharecters: true };
    }
    return null;
  }
newlyAddedContacts: any[] = [];
SaveMeetingContact() {
  this.contactSubmitted = true;

  if (this.contactFormGroup.valid) {
    const newContactName = this.contactFormGroup.value.contractName;
    const newCode = this._finalAttendees.length > 0 ? Math.max(...this._finalAttendees.map((contact: { code: any; }) => contact.code || 0)) + 1 : 1;
    const newContact: EngagementMeetingContacts = {
      id: 0, 
      contactName: newContactName,
      designation: "",
      countryCode: "",
      phoneNumber: "",
      email: "",
      meetingId: this.engagementMeetingDetails.Id ?? 0 
    };

    const isDuplicate= this.checkNameExists(newContactName)
    if (!isDuplicate) {
      switch (this.currentField) {
        case 'organizer':
          this.organizerOptions = [...this.organizerOptions, { label: newContact.contactName, value: newContact.contactName }];
          this.engagementMeetingDetails.Organizer = newContact.contactName;
          const Attendee = { name: newContact.contactName, code: newCode };
          this._finalAttendees = [...this._finalAttendees, Attendee];
          this._attendees.push(Attendee);
          const Absentee = { name: newContact.contactName, code: newCode };
          this._attendees.push(Absentee);
          break;
        case 'attendees':
          const newAttendee = { name: newContact.contactName, code: newCode };
          this._finalAttendees = [...this._finalAttendees, newAttendee];
          this.attendeesValues = [...this.attendeesValues, newAttendee];
          this._attendees.push(newAttendee);
          this.organizerOptions = [...this.organizerOptions, { label: newContact.contactName, value: newContact.contactName }];
          break;
        case 'absentees':
          const newAbsentee = { name: newContact.contactName, code: newCode };
          this._finalAttendees = [...this._finalAttendees, newAbsentee];
          this.absenteesValues = [...this.absenteesValues, newAbsentee];
          this._attendees.push(newAbsentee);
          this.organizerOptions = [...this.organizerOptions, { label: newContact.contactName, value: newContact.contactName }];
          break;
        case 'owner':
          this.organizerOptions = [...this.organizerOptions, { label: newContact.contactName, value: newContact.contactName }];
          this.addMeetingActionsData[0].Owner = newContact.contactName;
          const Attendees = { name: newContact.contactName, code: newCode };
          this._finalAttendees = [...this._finalAttendees, Attendees];
          this._attendees.push(Attendees);
          const Absentees = { name: newContact.contactName, code: newCode };
          this._attendees.push(Absentees);
          break;
        default:
          break;
      }
      this.newlyAddedContacts.push(newContact);
      this.engagementMeetingDetails.EngagementMeetingContacts = [...this.engagementMeetingDetails.EngagementMeetingContacts ?? [], newContact];
    } else {
      this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Contact Name already exists. Please enter a unique Name', detail: '' });
    }
    this.displayContractGroupDialog = false;
  }
}

closeContactDialog(){
  this.displayContractGroupDialog = false;
  this.messageService.add({
    key: 'tc',
    severity: 'info',
    summary: 'Contact Information has not been saved',
    detail: '',
});
  
}
checkNameExists(name: string): boolean {
  return this._finalAttendees.some((item: { name: string; }) => item.name === name);
}
}
