import { inject } from '@angular/core';
import {  CanActivateFn, Router } from '@angular/router';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';

export const privilegeGuard: CanActivateFn = (route, state) => {

   let privileges =inject(PrivilegeService).getPrivileges();
  
    let SelectedPrivileges=route.data["privileges"];
   let hasPrivilege= SelectedPrivileges.some((privilege:any) => privileges.includes(privilege));
   if(hasPrivilege){

   }
   else{
    return inject(Router).createUrlTree(['/home']);
   }
  
  
  return true;
};
//export const canActivateChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => privilegeGuard(route, state);
