import { Component, OnInit } from '@angular/core';
import { SponserService } from '../../Services/sponser.service';
import {ISponserData,ITblSponser,ITbl_SponserContacts} from '../../Models/sponser-data'
import { Router } from '@angular/router';
import { CodeListValues } from '../../Models/country-data';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Observable, } from 'rxjs';
import { } from '../../Services/canDeActivateGuard';
import { } from '@angular/common';
import { IDeactivateGuard } from 'src/app/shared/Guards/can-deactivate-guard.service';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { AuditIdentifier, AuditLogEntities, AuditLogModule, AuditOperations, breadcrumnsCodes } from 'src/app/shared/constants/global.constants';
import { AuditLogService } from '../../../shared/ModelComparison/audit.log.service';
import { ActiveInActiveStatus } from '../../../shared/constants/global.constants';
import { AuditLogModel } from '../../../shared/ModelComparison/audit.log.response.model';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { UserService } from 'src/app/Admin/Authorization/UserRoleMapping/Services/user.service';
import { UserData, UserGroupModel } from 'src/app/Admin/Authorization/UserRoleMapping/Models/user';

@Component({
  selector: 'app-createsponsor',
  templateUrl: './createsponsor.component.html',
  styleUrls: ['./createsponsor.component.css']
})
export class CreatesponsorComponent implements OnInit, IDeactivateGuard {
  unsavedChanges: boolean = false;
  displayDialog:boolean=false;
  cardData: any;
  myForm: FormGroup;
  dialogFormGroup: FormGroup;
localConatctId:number=0;
editContactFlag=false;
sponserData:ISponserData={};

oldSponserContact:any;
oldSponserData:any;
  sponserInfo:ITblSponser={};
  sponserContact:ITbl_SponserContacts={};
 sponserContactList:ITbl_SponserContacts[]=[];
 countryCodes: CodeListValues[] | undefined;
 submitted = false;
 isSaveBtnDisable = false;
 contactSubmitted=false;
 isDisabled: boolean = true;
 public weightConfirmed: boolean = false;
 executeCanExist: boolean = true;
 _enumBreadcrumb :any;
 changedSponsorProperties:any[] = [];
 changedSponsorContactsProperties:any[] = [];
 statusData : any[] = [];
  noOfSuppliersAreLinkedwithSponserId: any;
  selectedOption: string="";
  selectedsponserstatus: any[]=[];
  filterselectedsponserstatus: any[] =[];
  defaultstatus: any[]=[];
  contactHeader='Add Contact'
  usergroup=new UserGroupModel();
  auditIdentifierDetails:any;
  userData: UserData = new UserData();
  uniqueSponsorContactNameMessage=false;
  uniqueSponsorNameMessage=false;
  allSponsordetails: any[] = [];
constructor(private _service:SponserService,private router:Router,
  private confirmationService: ConfirmationService,
  private messageService: MessageService,
  private fb: FormBuilder,private breadcrumbService: BreadcrumbService,
  private auditLogService:AuditLogService,
  private privilegeService: PrivilegeService,
  private _userService: UserService)
  {

    this.myForm = this.fb.group({
       sponserName: ['', [Validators.required, Validators.maxLength(100), Validators.minLength(3),Validators.pattern(/^(?=(?:.*[a-zA-Z]){3,}).*$/),this.notOnlySpacesValidator]],
      sponserAddress: ['', [Validators.maxLength(300)]],
      sponserId:[''],
      sponserNotesAndComments:['', [Validators.maxLength(300)]],
    
    });

    this.dialogFormGroup = this.fb.group({
      contactName: ['', [Validators.required,Validators.maxLength(30),Validators.pattern(/^[a-zA-Z\s]{1,30}$/),this.notOnlySpacesValidator]],
      contactDesignation:['', [Validators.maxLength(100),Validators.minLength(3),this.notOnlySpacesValidator]],
      countryCode: [''],
      phoneNumber:['',[Validators.minLength(7),Validators.maxLength(15)]],
      email:['',[Validators.required,Validators.maxLength(100)]]
    });
    this.isDisabled=true;
  }

  notOnlySpacesValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value && control.value.trim() === '') {
       return { 'onlySpaces': true };
     }
     return null;
   }

   validateOnlyNumberandSpecialCharecters(control: AbstractControl): ValidationErrors | null{
    const alphaRegex = /[a-zA-Z]/; // Ensure at least one alphabetic character
    if (control.value && !alphaRegex.test(control.value)) {
      return { invalidNumberSpecialCharecters: true };
    }
    return null;
  }
  validateAlphaCharacters(control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    
    const pattern = /^[a-zA-Z\s]*$/;

    if (value && !pattern.test(value)) {
      return { invalidAlphaCharacters: true };
    }

    return null;
  }
  auditEntities:any;
  auditOperations:any;
  auditModule:any;
  oldSponsorInfo:any;
  oldSponsorContactInfo:any;
  ngOnInit(): void {
    this.userData=this.privilegeService.getUserData();
      this.usergroup.userId=this.userData.id;
      this.usergroup.email=this.userData.emailId;
      this.usergroup.groupTypeCode=this.userData.groupTypeCode;
    this._enumBreadcrumb=breadcrumnsCodes;
    this.auditIdentifierDetails=AuditIdentifier; 
    this.auditEntities=AuditLogEntities;
    this.auditOperations=AuditOperations;
    this.auditModule=AuditLogModule;

    this.generateBreadcrumb();
    localStorage.setItem('LoggedInUserName', 'TestUser');
    this._service.getCodeListData(0,'').subscribe(
      data=>{
        this.countryCodes=data.data;
        
      }
    );
    this.GetsponserStatus();
    this.oldSponserData=JSON.stringify(this.sponserData);
    this.oldSponserContact=JSON.stringify(this.sponserContact);

    setTimeout(() => {
      this.oldSponsorInfo=JSON.stringify(this.sponserInfo);
      this.oldSponsorContactInfo=JSON.stringify(this.sponserContact);
    }, 1000);
    this.GetAllSponsors();
  }

  updateUserGroup(GroupId:number)
  {
    this.usergroup.groupId=GroupId;
    this._userService.UpdateUserGroup(this.usergroup).subscribe(
      data=>{
        this.privilegeService.setGroup(GroupId,1);
       
      }
    );
  }
  saveSponserData(){
    this.submitted = true;
    this.isSaveBtnDisable = true;
    setTimeout(() => {
      this.isSaveBtnDisable = false;
    }, 3000);
    if (this.myForm.valid && !this.uniqueSponsorNameMessage) {
      this.executeCanExist = false;
    this.sponserInfo.createdBy=this.privilegeService.getLoginUserId();
    this.sponserInfo.createdDate=new Date();
    this.sponserInfo.sponserId='';
    if(this.sponserInfo.address == undefined){
      this.sponserInfo.address="";
    }
    if(this.sponserInfo.notesAndComments == undefined){
      this.sponserInfo.notesAndComments="";
    }

    this.sponserData["sponserInfo"]=this.sponserInfo;

    this.sponserData["sponserContacts"]=this.sponserContactList;


    this._service.saveSponserData(this.sponserData).subscribe(
      data=>{
        if(this.usergroup.groupTypeCode=='SPO')
        {
          this.updateUserGroup(data.data)
        }
        this.messageService.add({
          key: 'tc',
          severity: 'success',
          summary: 'Sponsor Information has been saved',
          detail: '',
      });
        this.localConatctId=0;

        //This line was used for Audit log
        this.compareModels();

        this.router.navigate(['/viewsponsor',data.data]);
      }
    );
    }
    else{
      this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Please enter required information', detail: '' });
    }
  }


      changedSponsorBasicInfo:any;
      changedSponsorContactInfo:any;
      listChangedSponsorBasicContactInfo:any;
      auditModelForSponser=new AuditLogModel();
      auditModelForSponserContact=new AuditLogModel();
  compareModels() {
            //This code is for testing point of view
  
//Get Method Direct URL
    this.auditLogService.GetAuditDetailById().subscribe(
      data => {

      }
    );

  

        //Getting Changes Properties Array for Sponsor
        this.changedSponsorBasicInfo = this.auditLogService.compareModels(JSON.parse(this.oldSponserData),this.sponserInfo,this.auditEntities.SponsorDetails,this.auditIdentifierDetails.SponsorDetails,this.auditOperations.Create, this.auditModule.Sponsorservice);
       
      //Getting Changes Properties Array for Sponsor Contacts
        this.sponserContactList.forEach((item:any) => {
          this.changedSponsorContactInfo=this.auditLogService.compareModels(JSON.parse(this.oldSponserContact),item,this.auditEntities.SponsorContacts,this.auditIdentifierDetails.SponsorContacts,this.auditOperations.Create,this.auditModule.Sponsorservice);
        });
        }

  openContactModel(){
    this.uniqueSponsorContactNameMessage=false;
    this.currentSponsorContactIndex=-1;
      this.dialogFormGroup.reset();
      Object.keys(this.dialogFormGroup.controls).forEach(key => {
          this.dialogFormGroup?.get(key)?.setErrors(null);
          
      });
      this.contactSubmitted=false;
      this.sponserContact.name="";
      this.sponserContact.designation="";
      this.sponserContact.countryCode="";
      this.sponserContact.phoneNumber="";
      this.sponserContact.email="";
      this.displayDialog = true;
      this.contactHeader='Add Contact';
      this.iscontactExist=false;

  }
  isSavePopupBtnDisable = false;
  SaveContact(){
    this.isSavePopupBtnDisable = true;
    setTimeout(() => {
      this.isSavePopupBtnDisable = false;
    }, 3000);
    this.contactSubmitted=true;
    this.trimInput();
    if (this.dialogFormGroup.valid && ! this.uniqueSponsorContactNameMessage && !this.uniqueSponsorNameMessage) {
      this.contactSubmitted=false;
    this.sponserContact.createdBy= this.privilegeService.getLoginUserId();
    this.sponserContact.createdDate=new Date();
    this.sponserContact.updatedBy=this.privilegeService.getLoginUserId();
    this.sponserContact.updatedDate=new Date();
    this.sponserContact.isDeleted=false;
    this.sponserContact.id=0;

    if(!this.editContactFlag){
    this.sponserContact.localId=this.localConatctId;
    this.editContactFlag=false;
    }
   
    const index = this.sponserContactList.findIndex(model => model.localId === this.sponserContact.localId);
    if (index !== -1) 
    {
      this.sponserContactList[index] = this.sponserContact;
  
    } 
  else
  {
    this.sponserContactList.push(this.sponserContact);
  }
   
    
    this.sponserContact={};
    this.localConatctId=this.localConatctId+1;
    this.displayDialog = false;
    this.messageService.add({
      key: 'tc',
      severity: 'success',
      summary: 'Contact Information has been saved',
      detail: '',
  });
    
    }
    this.editContactFlag=false;
  }

  trimInput() {
    const control = this.dialogFormGroup.get('contactName');
    if (control && control.value) {
      control.setValue(control.value.trim());
    }
  }

  editContact(cardData:any,rowIndex:any){
    this.uniqueSponsorContactNameMessage=false;
    this.sponserContact.name=cardData?.name;
    this.sponserContact.designation=cardData?.designation;
    this.sponserContact.countryCode=cardData?.countryCode;
    this.sponserContact.phoneNumber=cardData?.phoneNumber;
    this.sponserContact.email=cardData?.email;
    this.sponserContact.localId=cardData?.localId;
    this.editContactFlag=true;
    this.displayDialog = true;
    this.contactHeader='Edit Contact';
    this.currentSponsorContactIndex=rowIndex;
  }

  deleteContact(contactId: any){
    this.confirmationService.confirm({
      header:'Confirm',
      message: 'Are you sure you want to delete this contact from this Sponsor?',
      accept: () => {
        
        const index = this.sponserContactList.findIndex(model => model.localId === contactId);
        if (index !== -1) {
          this.sponserContactList.splice(index, 1);
          this.messageService.add({  key: 'tc',severity: 'success', summary: 'Contact has been deleted', detail: '' });
        } 
      },
      

    });
  }


  closeDialog(){
    this.displayDialog = false;
    this.editContactFlag=false;
    this.messageService.add({
      key: 'tc',
      severity: 'info',
      summary: 'Contact Information has not been saved',
      detail: '',
  });
    
  }



  BackToListPage(){
    setTimeout(() => {
      this.router.navigate(['/sponsor']);
    }, 1000);
   
  }

  creSponcheckSponsorContactNameExist(contactDetails:any)
    {
      if(this.sponserContactList.length>0){
        if (this.isNameRepeated(contactDetails)) {
          this.uniqueSponsorContactNameMessage=true;
        } else {
          this.uniqueSponsorContactNameMessage=false;
        }
      }
    }
    currentSponsorContactIndex=-1;
    isNameRepeated(contactDetails: any): boolean {

      this.uniqueSponsorContactNameMessage=false;
          let nameCount = 0;
          const contactName = contactDetails.name.toLowerCase();
          if(this.currentSponsorContactIndex==-1) 
          {
            this.sponserContactList.forEach((obj: any, index: any) => {
              if (obj.name.toLowerCase() === contactName) {
                  nameCount++;
              }
          });
          }
          else if(this.currentSponsorContactIndex>=0){
            this.sponserContactList.forEach((obj: any, index: any) => {
              if (obj.name.toLowerCase() === contactName &&  index!=this.currentSponsorContactIndex) {
                  nameCount++;
              }
          });
          }
    
          return nameCount > 0;
      }
  canExist(): boolean | Promise<boolean> | Observable<boolean> {
    if (this.executeCanExist && ((this.oldSponsorInfo!=JSON.stringify(this.sponserInfo)) || (this.sponserContactList.length>0)))
    {
      return new Promise<boolean>((resolve) => {
        this.confirmationService.confirm({
          message: 'Sponsor will not be saved, Do you want to continue?',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.messageService.add({
              key: 'tc',
              severity: 'info',
              summary: 'Sponsor not saved',
              detail: '',
          });
            resolve(true); 
          },
          reject: () => {
            resolve(false); 
          },
        });
      });
    }
    else{
      return true;
    }
    
  }
  generateBreadcrumb()
  {
    this.breadcrumbService.generateBreadcrumb(this._enumBreadcrumb.CSP);
  }
  getBreadcrumbs(): MenuItem[] {
    return this.breadcrumbService.getBreadcrumbs();
  }
  GetsponserStatus(){
    this._service.getCodeListData(3,'Sponser').subscribe(
      data=>{
        this.statusData=data.data;
        this.defaultstatus=this.statusData.filter((x:any)=>x.name==ActiveInActiveStatus.Active);
        
      }
    );
   
  }
  contactUniqueMessage:string="";
  iscontactExist=false;
IsCheckDuplicateContact(name: any): void {
  this.contactUniqueMessage="Contact Name already exists in this Sponsor. Please enter a unique Name";
  this.iscontactExist= this.sponserContactList.some(obj=>obj.name?.toLowerCase()===name.toLowerCase())

}
  onOptionChange(event:any): void { 
  
  }
  //get all suppliers data
GetAllSponsors() {
  this._service.GetAllSponsers().subscribe(
    (    res: { data: any[]; })=>{
      this.allSponsordetails=res.data;
    } 
 );
};
  checkSponsorNameExist()
  {
    this.uniqueSponsorNameMessage=false;
  const contactIndex = this.allSponsordetails.findIndex(item => item.name.toLowerCase() === (this.sponserInfo.name?.toLocaleLowerCase()));
   if(contactIndex !== -1)
   {
      this.uniqueSponsorNameMessage=true;
   }
    else{
      this.uniqueSponsorNameMessage=false;
    }
  }
  
}
