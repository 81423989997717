export class AuditLogModel{
  operatorUsername?:string;
  operationType?: string;
  entityIdentifier?: string;
  entityName?: string;
  fromService?:  string;
  operationDate?:  any;
  entityCreatedByName?:  string;
  entityCreatedDate?:any;
  entityModifiedByName?:  string;
  entityModifiedDate?:  any;
  fieldModifications?: FieldChangeForAuditModel[]
}



export class FieldChangeForAuditModel {

    fieldName?:string;

    oldValue?: string;

    newValue?:string;

  }