<!DOCTYPE html>
<html lang="en">
<head>
    <title> Master Supplier </title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>
<div class="card flex justify-content-center">
  <p-breadcrumb class="max-w-full" [model]="getBreadcrumbs()"></p-breadcrumb>
</div>
<div class="container page-title">
    <div class="flex-box"> 
  <div class="fr-flex__container">
      <div class="fr-flex__col-8">
          <h1>Suppliers</h1>
      </div>
      <div class="fr-flex__col-4"> 
        <a *ngIf="createPrivilege"  routerLinkActive="active" routerLink="/AddMasterSupplierData" class="btn btn-primary pull-right"> Create Supplier </a>
         <button *ngIf="ExportPrivilege" class="btn btn-outline-primary pull-right" [disabled]="isChecked" (click)="ExportSuppliers()"><i class="pi pi-download"></i> Export</button> 
         <button class="btn btn-outline-primary pull-right" id="contractListFilter" (click)="showHideControls()" [ngClass]="{'active-button': showHideFiltersControls}"> <i class="pi pi-filter"></i> </button>
         </div>
  </div>
</div>
  <div> 
  <div class="card">
    <div class="fr-flex__col-12">
        <div class="filter-bg" *ngIf="showHideFiltersControls">
            <div class="fr-flex__container filter_multiselect-width">
            <div class="fr-flex__col-3">
                <div class="fr-form__group margin-zero"> 
                 <span class="p-input-icon-left"> 
                    <i class="pi pi-search"></i> 
                    <input type="text" placeholder="Search by Name, ID" class="form-control key-search filter-text search-active" [class.search-active]="enterSupplierIdOrName !== undefined && enterSupplierIdOrName !== null && enterSupplierIdOrName !== ''" [(ngModel)]="enterSupplierIdOrName" (input)="finalFilter()" trimSpace/> </span> 
                </div>
            </div>
             <div class="fr-flex__col-1 custom-pad-left-zero">
                <p-multiSelect appendTo="body" [filter]="true" class="multiSelect-active muultiselect_header_hide" [class.multiSelect-active]="SelectedServices !== undefined && SelectedServices !== null" name="services" [options]="SerivicesName" [(ngModel)]="SelectedServices" optionLabel="label" placeholder="Services" (onChange)="onServiceChange($event)"></p-multiSelect>
            </div>
            <a class="btn btn-primary-link" (click)="ClearFilters()"> Clear All Filters </a>
            </div>
        </div>
            </div>
            </div>
        </div>
  
 
      
          
              <p-table class="primeng-table-custom" [resizableColumns]="true" columnResizeMode="expand" styleClass="p-datatable-striped p-datatable-gridlines" [scrollable]="true" [value]="supplierdata" [(selection)]="selectedSuppliers" editMode="row" [paginator]="true" [rows]="10" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Entries" [rowsPerPageOptions]="[10, 20, 50, 100, 200]">
                  <ng-template pTemplate="header">
                      <tr>
                          <th id="masterSupplierListCheckbox" class="first-child-width">
                              <p-tableHeaderCheckbox (click)="CheckboxEnabled()"></p-tableHeaderCheckbox>
                          </th>
                          <th id="MasterDataSupplierListName" pSortableColumn="name" pResizableColumn>Supplier Name <p-sortIcon field="name"></p-sortIcon></th>
                          <th id="MasterDataSupplierListSupplierID" pSortableColumn="supplierId" pResizableColumn>Supplier ID <p-sortIcon field="supplierId"></p-sortIcon></th>
                          <th id="MasterDataSupplierListSupplierServices" pSortableColumn="supplierServices" pResizableColumn>Services <p-sortIcon field="supplierServices"></p-sortIcon></th>
                          <th id="MasterDataSupplierListSupplierLocations" pSortableColumn="supplierLocations" pResizableColumn>Locations <p-sortIcon field="supplierLocations"></p-sortIcon></th>
                          <th id="masterSupplierListEdit" *ngIf="editPrivilege" class="last-child-width">Edit</th>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData let-cols="columns" let-supplierdata let-editing="editing" let-ri="rowIndex">
                      <tr>
                          <td id="masterSupplierListCheckboxTD" class="first-child-width">
                              <p-tableCheckbox (click)="CheckboxEnabled()" [value]="supplierdata"></p-tableCheckbox>
                          </td>
                          <td [pTooltip]="supplierdata.name.length > 30 ? supplierdata.name : ''" tooltipPosition="top"> <a class="table-active-link" *ngIf="viewPrivilege; else disabledLink" routerLinkActive="active" (click)="ViewSupplier(supplierdata)"> {{ supplierdata.name}} </a>
                            <ng-template #disabledLink>
                                {{ supplierdata.name}}
                              </ng-template>
                        </td>
                          <td> {{supplierdata.supplierId }} </td>
                          <td [pTooltip]="supplierdata.supplierServices.length > 35 ? supplierdata.supplierServices : ''" tooltipPosition="top"> {{ supplierdata.supplierServices }} </td>
                          <td [pTooltip]="supplierdata.supplierLocations.length > 35 ? supplierdata.supplierLocations : ''" tooltipPosition="top"> {{ supplierdata.supplierLocations }} </td>
                        <td *ngIf="editPrivilege" id="masterSupplierListEditTD" class="grid-icon-center"> <span *ngIf="!editing" class="pi pi-pencil" (click)="onRowEditInit(supplierdata)"></span> </td>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                    <tr>
                        <td [attr.colspan]="editPrivilege ? 6 : 5" class="text-center">No Records</td>
                    </tr>
                    </ng-template>
              </p-table>
</div>
</body>
</html>