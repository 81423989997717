
export class ContractForecast
{
    id?:number;
    forecastDate?: any;
    forecastedAmount?:number;
    remarks?: string;
    createdBy?: number;
    createdDate?: Date;
    updatedBy?: number;
    updatedDate?: Date;
    isDeleted?: Boolean;
    financeContractForecastAttachments?:ForecastAttachments[];
}

  
    
export class ForecastAttachments {
    id: number=0;
    fileName?:string='';
    filePath?:string='';
    financeContractAccrualId: number=0;
    createdBy?: number;
    ceatedDate?: Date;
    updatedBy?: number;
    updatedDate?: Date;
    isDeleted?: Boolean;
    isPrevFileDelete?:Boolean=false;
  }
  
  export class ForecastChildComponents
  {
      contForecastList:number=1;
      contForecastEdit:number=2;
      contForecastView:number=3;
  }

  export class ContracDetails {
    contractDetailId?:number;
    contractStartDate?:any;
    contractEndDate?: any;
  }

  
export class CodeListValues {

    Name?: string;
    Id?: number;
    Code?:string;
    PhoneCode?:string;
  }

