<!DOCTYPE html>
<html lang="en">
<head>
    <title> Contract Summary </title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>
    <p-tabView class="fr-tabview__header__custom">
        <p-tabPanel>
            <div class="fr-flex__container"></div> 
    <div class="card-pad-bottom-zero m-t-70">  
    <div class="fr-flex__container">
        <div class="fr-flex__col-4">
            <div class="fr-form__view">
                <label> Currency​ </label>
                <span> <em> {{contractSummarydata?.currency? contractSummarydata?.currency : '--'}} </em></span>
            </div>
            </div>
            <div class="fr-flex__col-4">
                <div class="fr-form__view">
                    <label> Payment Terms​ </label>
                   <span> <em> {{contractSummarydata.paymentTerms? contractSummarydata.paymentTerms : '--'}} </em></span>
                    
                </div>
                </div>
                <div class="fr-flex__col-4">
                    <div class="fr-form__view">
                        <label> Billing Type </label>                    
                            <ul class="services_ul" *ngFor="let billing of contractSummarydata?.billingType.split(','); let selectedBillingTypesIndexAfterSplit = index">
                                <li *ngIf="selectedBillingTypesIndexAfterSplit == 0">  {{billing ? billing : '--'}} </li>
                              </ul>
                              <span *ngIf="contractSummarydata?.billingType.split(',').length > 1"><em>  <p-button (onClick)="ShowBillingTypesDialog()" [ngClass]="'blue-button'" [label]="'+'+(contractSummarydata?.billingType.split(',').length - 1).toString()"></p-button> </em></span>
                              <p-dialog appendTo="body" header="Billing Type" [modal]="true" [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false" [closable]="true"  [(visible)]="visibleBillingsTypes">
                                <div class="dialog-ul" *ngFor="let billings of contractSummarydata?.billingType.split(','); let selectedBillingTypesIndexAfterSplits = index">
                                   <span *ngIf="selectedBillingTypesIndexAfterSplits > 0">                                       
                                    <em> {{billings?.trim()}}</em>                     
                                   </span>
                                </div>
                                </p-dialog>
                                                
                    </div>
                    </div>
        </div>
        <div class="fr-flex__container">
            <div class="fr-flex__col-4">
                <div class="fr-form__view">
                    <label> PO Number </label>
                    <span> <em>{{contractSummarydata?.poNumber ? contractSummarydata?.poNumber : '--'}}</em></span>
                </div>
                </div>
                <div class="fr-flex__col-4">
                    <div class="fr-form__view">
                        <label> PO Value </label>
                        <span> <em>
                            {{ getFormattedValue(contractSummarydata?.poValue, selectedCurrency) }}
                        </em></span>
                    </div>
                    </div>
                    <div class="fr-flex__col-4">
                        <div class="fr-form__view">
                            <label> Total Value <i  pInputText pTooltip="Includes Change Orders" tooltipPosition="top" class="pi pi-info-circle"> </i> </label>
                            <span> <em>  {{ getFormattedValue(contractSummarydata?.totalValue, selectedCurrency) }} </em></span>
                        </div>
                        </div>
            </div>
            <div class="fr-flex__container">
                <div class="fr-flex__col-4">
                    <div class="fr-form__view">
                        <label> Actual Billed Till Date </label>
                        <span> <em>
                            {{ getFormattedValue(contractSummarydata?.actualBilledYTD, selectedCurrency) }}
                        </em></span>
                    </div>
                    </div>
                    <div class="fr-flex__col-4">
                        <div class="fr-form__view">
                            <label> PO Balance <i  pInputText pTooltip="PO Value - Actual Billed Till Date" tooltipPosition="top" class="pi pi-info-circle"> </i> </label>
                            <span> <em> 
                                {{ getFormattedValue(contractSummarydata?.poBalance, selectedCurrency) }}
                            </em></span>
                        </div>
                        </div>
                        <div class="fr-flex__col-4">
                            <div class="fr-form__view">
                                <label> Total Value Balance <i  pInputText pTooltip="Total Value - Actual Billed Till Date" tooltipPosition="top" class="pi pi-info-circle"> </i> </label>
                                <span> <em>
                                    {{ getFormattedValue(contractSummarydata?.balance, selectedCurrency) }}
                                </em></span>
                            </div>
                            </div>
                        
                </div>
                <div class="fr-flex__container">
                    <div class="fr-flex__col-4">
                        <div class="fr-form__view">
                            <label> Payment Till Date </label>
                            <span> <em>
                                {{ getFormattedValue(contractSummarydata?.paymentYTD, selectedCurrency) }}
                                
                             </em></span>
                        </div>
                        </div>
                        <div class="fr-flex__col-4">
                            <div class="fr-form__view">
                                <label> PO Value Utilization <i  pInputText pTooltip="Actual Billed Till Date*100/PO Value" tooltipPosition="top" class="pi pi-info-circle"> </i> </label>
                                <span> <em>{{contractSummarydata?.poValueUtilization ? contractSummarydata?.poValueUtilization : '--'}} % </em></span>
                            </div>
                            </div>
                            <div class="fr-flex__col-4">
                                <div class="fr-form__view">
                                    <label> Total Value Utilization <i  pInputText pTooltip="Actual Billed Till Date*100 /Total Value" tooltipPosition="top" class="pi pi-info-circle"> </i></label>
                                    <span> <em> {{contractSummarydata?.totalValueUtilization ? contractSummarydata?.totalValueUtilization : '--'}} % </em></span>
                                </div>
                                </div>
                            
                </div>
                <div class="fr-flex__container">
                    <div class="fr-flex__col-4">
                        <div class="fr-form__view">
                            <label> Outstanding Amount <i  pInputText pTooltip="Actual Billed Till Date - Payment Till Date" tooltipPosition="top" class="pi pi-info-circle"> </i> </label>
                            <span> <em>
                                {{ getFormattedValue(contractSummarydata?.outstandingAmount, selectedCurrency) }}
                                 </em></span>
                        </div>
                        </div>
                    <div class="fr-flex__col-4">
                        <div class="fr-form__view">
                            <label> Forecasted Billing Till Date </label>
                            <span> <em> 
                                {{ getFormattedValue(contractSummarydata?.forecastedBillingYTD, selectedCurrency) }}
                            </em></span>
                        </div>
                        </div>  
                </div>

<!-- added By Namita start-->
<div class="fr-flex__container">
    <div class="fr-flex__col-4">
        <h3>Fiscal Year Budget</h3>
    </div>
    <div class="fr-flex__col-8"> 
        <a *ngIf="editFYBudgetPrivilege && isEditBtnVisible" class="btn btn-outline-primary pull-right" (click)="editFiscalYearData()"> Edit  </a>
    </div>
</div>

    <div class="fr-flex__col-12"> 
    <p-table id="fiscalYearDataGrid" class="primeng-table-custom custom-cells__contractsummary" [resizableColumns]="true" columnResizeMode="expand" styleClass="p-datatable-striped p-datatable-gridlines" [scrollable]="true" [value]="fiscalYearDetails" editMode="row">
        <ng-template pTemplate="header">
            <tr>                
                <th id="conSummaryFiscalYear" pSortableColumn="fiscalYear" pResizableColumn>Fiscal Year <p-sortIcon field="fiscalYear"></p-sortIcon> </th>
                <th id="conSummaryMrpBudget" pSortableColumn="budgetFY" pResizableColumn>MRP Approved Budget <p-sortIcon field="budgetFY"></p-sortIcon></th>
                <th id="conSummaryActualBilled" pSortableColumn="actualBilledFY" pResizableColumn>Actual Billed <p-sortIcon field="actualBilledFY"></p-sortIcon></th>
                <th id="conSummaryBudgetBalance" pSortableColumn="budgetBalanceFY" pResizableColumn>Budget Balance <p-sortIcon field="budgetBalanceFY"></p-sortIcon></th>
                <th id="conSummaryModifiedBy" pSortableColumn="updatedBy" pResizableColumn>Modified By <p-sortIcon field="updatedBy"></p-sortIcon></th>
                <th id="conSummaryModifiedDateTime" pSortableColumn="updatedDate" pResizableColumn>Modified Date and Time<p-sortIcon field="updatedDate"></p-sortIcon></th>
                
                
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-fiscalData> 
            <tr>
                <td>{{ fiscalData.fiscalYear}}</td>
                <td>
                    <p-inputNumber (ngModelChange)="onBudgetChange($event, fiscalData);" [(ngModel)]="fiscalData.budgetFY" 
                                   [disabled]="isReadOnly" inputId="minmaxfraction" class="form-control" mode="decimal" 
                                   [minFractionDigits]="2" [maxlength]="15" type="text" pInputText [prefix]="getFormattedValue(0, selectedCurrency).slice(0, 1)">
                      
                    </p-inputNumber>
                  </td>
                <td>{{(fiscalData.actualBilledFY==null||fiscalData.actualBilledFY=="")?'--':getFormattedValue(fiscalData.actualBilledFY, selectedCurrency)}}</td>
                
                <td>{{((fiscalData.budgetBalanceFY==null||fiscalData.budgetBalanceFY=="") && fiscalData.budgetBalanceFY!=0)?'--':getFormattedValue(fiscalData.budgetBalanceFY, selectedCurrency)}}</td>
                <td>{{(fiscalData.updatedUserName==null||fiscalData.updatedUserName=="")?'--':fiscalData.updatedUserName}}</td>
                <td>{{(fiscalData.updatedDate==null||fiscalData.updatedDate=="")?'--':fiscalData.updatedDate | date:_dateformart.GridDateFormat}}</td>                
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
            <td colspan="6" class="text-center"> No Records</td>
            </tr>
            </ng-template>
    </p-table>
</div>
<div class="fr-flex__container footer_btns">
    <div class="fr-flex__col-12 p-b-20">
        <a *ngIf="editFYBudgetPrivilege && !isEditBtnVisible" class="btn btn-primary pull-right" (click)="saveFiscalYearData()"> Save  </a>
        <a *ngIf="editFYBudgetPrivilege && !isEditBtnVisible" class="btn btn-secondary pull-right" (click)="deleteFiscalYearData()"> Cancel  </a>
    </div>
</div>
</div>
<!--end -->


            </p-tabPanel>
            </p-tabView>
</body>
</html>
