import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { SponserService } from "src/app/MasterData/Services/sponser.service";
import { environment } from "src/environments/environment";
import { ContractKpi } from "../Model/contractKpi";
import { AuthCommonService } from "src/app/shared/Authentication/auth.service";
import { Parser } from 'expr-eval';
import { evaluate } from 'mathjs';
@Injectable({

    providedIn: 'root'
  
  })

export class ContractKPIService
{
    readonly _governanceKpiApiBaseURl?:string="";
    
    constructor(private http: HttpClient,private _masterDataService:SponserService,private authCommonService: AuthCommonService) { 
        this._governanceKpiApiBaseURl=environment?._governanceApiBaseURl;
      }

      public GetAllContractKpis(contractDetailId:number): Observable<any> {
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
        return this.http.get<any>(this._governanceKpiApiBaseURl +  '/GovernanceKpis/GetAllContractKpis?contractDetailId=' + contractDetailId, httpOptions);
      }

      AddContractKpi(contractkpi: ContractKpi): Observable<any> {
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
        return this.http.post<any>(this._governanceKpiApiBaseURl + '/GovernanceKpis/AddContractKpi', contractkpi, httpOptions);
      }
      

  public CalculateKPI(formulaId: number, attribute1Value: number, attribute2Value: number, attribute3Value: number, kpiType: string): Promise<number> {
  return new Promise((resolve, reject) => {
    this._masterDataService.getCodeListData(13, kpiType).subscribe(
      data => {
        this.formulaList = data.data;
        const formulaDetail = this.formulaList.find((aa: any) => aa.id == formulaId);
        formulaDetail.code = formulaDetail.code.replace(/Attribute1/g, attribute1Value.toString());
        formulaDetail.code = formulaDetail.code.replace(/Attribute2/g, attribute2Value.toString());
        formulaDetail.code = formulaDetail.code.replace(/Attribute3/g, attribute3Value.toString());
        formulaDetail.code = formulaDetail.code.replace(/Abs/g, 'Math.abs');
        // const calculatedKPI = Math.round(eval(formulaDetail.code));
        const parser = new Parser();
        const calculatedKPI = Math.round(parser.evaluate(formulaDetail.code));
        resolve(calculatedKPI);
      },
      error => {
        reject(error); 
      }
    );

  });

}


formulaList:any;
calculatedKPI:any;
public CalculateKPI_New(formulaId: number, attribute1Value: any, attribute2Value: any, attribute3Value: any, kpiType: string,inputType:string): Promise<number> {
return new Promise((resolve, reject) => {
  this._masterDataService.getCodeListData(13, kpiType).subscribe(
    data => {
      this.formulaList = data.data;
      const formulaDetail = this.formulaList.find((aa: any) => aa.id == formulaId);
      
      
      if(inputType=='INT'){
      formulaDetail.code = formulaDetail.code.replace(/Attribute1/g, attribute1Value ? attribute1Value.toString(): null);
      formulaDetail.code = formulaDetail.code.replace(/Attribute2/g, attribute2Value ? attribute2Value.toString(): null);
      formulaDetail.code = formulaDetail.code.replace(/Attribute3/g, attribute3Value ? attribute3Value.toString(): null);
      formulaDetail.code = formulaDetail.code.replace(/Abs/g, 'Math.abs');
       //this.calculatedKPI = Math.round(eval(formulaDetail.code));
       this.calculatedKPI = parseFloat(evaluate(formulaDetail.code)).toFixed(2);
      }
      else//means date type
      {
        formulaDetail.code = formulaDetail.code.replace(/Attribute1/g, attribute1Value ? attribute1Value.getTime().toString(): null);
        formulaDetail.code = formulaDetail.code.replace(/Attribute2/g, attribute2Value ? attribute2Value.getTime().toString(): null);
        formulaDetail.code = formulaDetail.code.replace(/Attribute3/g, attribute3Value ? attribute3Value.getTime().toString() : null);
        formulaDetail.code = formulaDetail.code.replace(/Abs/g, 'Math.abs');
        const result = evaluate(formulaDetail.code);
        this.calculatedKPI= Math.floor(result / (1000 * 60 * 60 * 24));
      }
      resolve(this.calculatedKPI);
    },
    error => {
      reject(error); 
    }
  );

});

}

UpdateContractKpi(contractkpi: ContractKpi): Observable<any> {
  const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
  return this.http.put<any>(this._governanceKpiApiBaseURl + '/GovernanceKpis/UpdateContractKpi', contractkpi, httpOptions);
}
GetContractKpiById(contactKpiId:number){
  const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
  return this.http.get<any>(this._governanceKpiApiBaseURl + '/GovernanceKpis/GetContractKpiById?contractKpiId=' +contactKpiId, httpOptions);
}
GetContractKPIDetailsById(id: any)
{
 const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
  return this.http.get<any>(this._governanceKpiApiBaseURl + '/GovernanceKpis/GetContractKPIDetailsById?contractKPIId='+id, httpOptions);

}

GetContractKPIDetailsByIdForViewPage(id: any)
  {
   const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
    return this.http.get<any>(this._governanceKpiApiBaseURl + '/GovernanceKpis/GetContractKPIDetailsByIdForViewPage?contractKPIId='+id, httpOptions);

  }
  GetContracKPIDetailsByIdForExport(KpiIds:string){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'ApiKey': this.authCommonService.getAuthenticationToken() 
  });
    const params = { kpiIds: KpiIds };   
    const options = { params: params, headers: headers }; 
    return this.http.get<any>(this._governanceKpiApiBaseURl + '/GovernanceKpis/GetContractKPIDetailsByIdForExport',options)
    .pipe(map((res:any)=>{
      return res;
   }));
  }

      DeleteContractKpi(contractKpiId: any): Observable<any> {
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'ApiKey': this.authCommonService.getAuthenticationToken() }) };
      return this.http.put<any>(`${this._governanceKpiApiBaseURl}/GovernanceKpis/DeleteContractKpiById?contractKpiId=${contractKpiId}`, {}, httpOptions);
    }
 
}
