import configData from '../environments/variables.production.json'

export const environment = {
    production: false,
   _supplierApiBaseURl:configData[0].WS_SUPPLIER_API_BASE_URL,
   _sponserApiBaseURl :configData[1].WS_SPONSOR_API_BASE_URL,
   _contractApiBaseURl :configData[2].WS_CONTRACT_API_BASE_URL,
   _fileApiBaseURl :configData[3].WS_FILE_API_BASE_URL,
   _fileSize:configData[4].WS_FILE_SIZE,
   _finacneApiBaseURl :configData[5].WS_FINANCE_API_BASE_URL,
   _governanceApiBaseURl :configData[6].WS_GOVERNANCE_API_BASE_URL,
   _administratorsApiBaseURl :configData[7].WS_ADMINISTRATORS_API_BASE_URL,
   _notificationApiBaseURl: configData[8].WS_NOTIFICATION_API_BASE_URL,
   _poUtilization:configData[9].WS_PO_UTILIZATION_CONFIG_VALUE,
   _adminCenterAppURl:configData[10].WS_ADMIN_CENTER_APP_URL,
   _kontractProAppURl:configData[11].WS_KONTRACTPRO_APP_URL,
   _loginPortalAppURl:configData[12].WS_LOGIN_PORTAL_APP_URL,
   _commonAuthApiBaseURl:configData[13].WS_COMMON_AUTH_SERVICE_API_BASE_URL,
   _commonUAMApiBaseURl:configData[14].WS_COMMON_UAM_SERVICE_API_BASE_URL,
   _commonReportingServiceApiBaseURL:configData[15].WS_COMMON_REPORTING_SERVICE_API_BASE_URL,
   _commonServiceApiBaseURl:configData[16].WS_COMMON_SERVICE_API_BASE_URL,
   _engagementDashboardId:configData[17].WS_ENGAGEMENT_DASHBOARD_ID,
   _contractDashboardId:configData[18].WS_CONTRACT_DASHBOARD_ID,
   _appDomainName:configData[19].WS_DOMAIN_NAME,
   _kpiPerPageForDashboard:configData[20].WS_KPIPERPAGE_DASHBOARD,
   _engagementApiBaseURl:configData[21].WS_ENGAGEMENTS_API_BASE_URL,
   _contractGroupDashboardId:configData[22].WS_CONTRACTGROUP_DASHBOARD_ID,
   _engagementHomePageDashboardId:configData[23].WS_ENGAGEMENT_HOMEPAGE_DASHBOARD_ID,
   _engagementTeamDashboardId:configData[24].WS_ENGAGEMENT_TEAM_DASHBOARD_ID,
   _financeTillDateDashboardId:configData[25].WS_FINANCE_TILLDATE_DASHBOARD_ID,
   _financeFiscalYearDashboardId:configData[26].WS_FINANCE_FISCALYEAR_DASHBOARD_ID,
   _financeEngagementTillDateDashboardId:configData[27].WS_FINANCE_ENGAGEMENT_TILLDATE_DASHBOARD_ID,
   _financeEngagementFiscalYearDashboardId:configData[28].WS_FINANCE_ENGAGEMENT_FISCALYEAR_DASHBOARD_ID,
};