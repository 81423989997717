<div class="card flex justify-content-center">
    <p-breadcrumb class="max-w-full" [model]="getBreadcrumbs()"></p-breadcrumb>
  </div>
 <div class="container page-title"> 
   <div class="flex-box-inside-pages">
      <div class="fr-flex__container">
         <div class="fr-flex__col-12">
             <h1>Profile</h1>
         </div>
     </div>
   </div>
       <p-tabView class="fr-tabview__header__custom"> 
          <p-tabPanel> 
            <div id="userProfileForm" class="card-pad">
 <div class="fr-flex__container">
     <div class="fr-flex__col-4">
        <div class="fr-form__view">
           <label> First Name </label>
           <span [pTooltip]="user.firstName!.length > 30 ? user.firstName! : ''" tooltipPosition="top"> <em> {{user.firstName}}  </em></span>
        </div>
     </div>
     <div class="fr-flex__col-4">
        <div class="fr-form__view">
           <label> Last Name  </label>
           <span [pTooltip]="user.lastName!.length > 30 ? user.lastName! : ''" tooltipPosition="top"> <em> {{user.lastName}}  </em></span>
        </div>
     </div>
     <div class="fr-flex__col-4">
         <div class="fr-form__view">
            <label> Email ID  </label>
            <span [pTooltip]="user.emailId!.length > 30 ? user.emailId! : ''" tooltipPosition="top"> <em> {{user.emailId}}   </em></span>
         </div>
      </div>
  </div>
  <div class="fr-flex__container">
   
     <div class="fr-flex__col-4">
        <div class="fr-form__view">
           <label> Group Type </label>
           <span [pTooltip]="user.groupType!.length > 30 ? user.groupType! : ''" tooltipPosition="top"> <em> {{user.groupType}}   </em></span>
        </div>
     </div>
     <div class="fr-flex__col-4">
       <div class="fr-form__view">
          <label> Group </label>
          <span [pTooltip]="user.group!.length > 30 ? user.group! : ''" tooltipPosition="top"> <em> {{user.group}}   </em></span>
       </div>
    </div>
    <div class="fr-flex__col-4">
      <div class="fr-form__view">
         <label> Assigned Role(s)  </label>
         <span id="UserProfileassignedRoles" [pTooltip]="user.assignedRoles!.length > 30 ? user.assignedRoles! : ''" tooltipPosition="top"> <em>  {{user.assignedRoles}}   </em></span>
      </div>
   </div>
    
  </div>
 
 
  <div class="fr-flex__container">
 
    <div class="fr-flex__col-4">
       <div class="fr-form__view">
          <label> Modified By</label>
          <span id="UserProfileModiBy"> <em> {{updatedByUserName!=''?updatedByUserName:'--'}}</em></span>
 
       </div>
    </div>
    <div class="fr-flex__col-4">
       <div class="fr-form__view">
          <label> Modified Date & Time </label>
          <span id="UserProfileModiDateTime" *ngIf="updatedByUserName!=''"> <em> {{user?.updatedDate | date: _dateformart.DateTimeWithZone }} {{_dateformart.DisplayUTC}}</em></span>
          <span id="UserProfileHypen" *ngIf="updatedByUserName==''"> <em> --</em></span>
         </div>
    </div>
 </div>
 </div>
 </p-tabPanel>
 </p-tabView>
 
 </div>
 
 