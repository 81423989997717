<!DOCTYPE html>
<html lang="en">
<head>
   <title>Create Contract</title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>
<p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
<div class="card flex justify-content-center">
   <p-breadcrumb class="max-w-full" [model]="getBreadcrumbs()"></p-breadcrumb>
  </div>

  <div class="container page-title">
   <div class="flex-box-inside-pages position-fixed-heading"> 
   <div class="fr-flex__container">
    <div class="fr-flex__col-8">
       <h1>Create Contract</h1>
    </div>
 </div>
</div>
   <p-tabView class="fr-tabview__header fixed-positon-tabs">
      <p-tabPanel header="Details">
        <form class="card-pad" [formGroup]="myContractDetailsForm">
         <div id="creContrScrollDiv" class="scroll-start-here"> 
         <div class="fr-flex__container">
            <div class="fr-flex__col-4">
              <div class="fr-form__group">
                <label>Title   <em> * </em> <span class="max-length"> {{contractDetails.Title?.length || 0}}/100 </span></label>
                <input Id="ConTitle" formControlName="title" type="text" [maxlength]="100" class="form-control" pInputText [(ngModel)]="contractDetails.Title" trimSpace />
                <small class="p-error" *ngIf="submitted && myContractDetailsForm?.get('title')?.hasError('required')">  This field is required. </small>
                <small class="p-error" *ngIf="submitted && myContractDetailsForm?.get('title')?.hasError('maxlength')">   Maximum length is 100 characters. </small>
                <small class="p-error" *ngIf="submitted && myContractDetailsForm?.get('title')?.hasError('invalidAlphaCharacters')">   Only alphabetic characters are allowed. </small>
              </div>
            </div>
            <div class="fr-flex__col-4">
              <div class="fr-form__group">
                <label>Sponsor Contract ID   <em> * </em> <span class="max-length"> {{contractDetails.ContractId?.length || 0}}/15 </span></label>
                <input Id="SponId" formControlName="contractId" type="text" class="form-control" [maxlength]="15" pInputText [(ngModel)]="contractDetails.ContractId" (blur)="isContractIdExist($event)" trimSpace/>
                <small class="p-error" *ngIf="uniqueMessage">Contract ID already exists. Please enter a unique ID.</small>
                <small class="p-error" *ngIf="submitted && myContractDetailsForm?.get('contractId')?.hasError('required')">  This field is required. </small>
                <small class="p-error" *ngIf="submitted && myContractDetailsForm?.get('contractId')?.hasError('maxlength')">   Maximum length is 15 characters. </small>
              </div>
            </div>
            <div class="fr-flex__col-4">
              <div class="fr-form__group">
                <label>Status   <em> * </em></label>
                <p-dropdown appendTo="body" [filter]="true" appendTo="body" formControlName="statusMappingId" [options]="statusData" [(ngModel)]="contractDetails.StatusMappingId" optionLabel="name" optionValue="id" placeholder="Select Status"></p-dropdown>
                <small class="p-error" *ngIf="submitted && myContractDetailsForm?.get('statusMappingId')?.hasError('required')">  This field is required. </small>
              </div>
            </div>
          </div>
          <div class="fr-flex__container">
            <div class="fr-flex__col-4">
              <div class="fr-form__group">
                <label>Engagement <em> * </em></label>
                <p-dropdown appendTo="body" [class.read-only-dropdown]="isEngagementDisabled" [filter]="true" formControlName="engagementId" [options]="engagementDropdownBindingDataList" [(ngModel)]="contractDetails.EngagementId" optionLabel="Label" optionValue="Id" placeholder="Select Engagement" [readonly]="isEngagementDisabled"  (onChange)="onEngagementDropdownChange($event)"></p-dropdown>
                <small class="p-error" *ngIf="submitted && myContractDetailsForm?.get('engagementId')?.hasError('required')">  This field is required. </small>
              </div>
            </div>
            <div class="fr-flex__col-4">
              <div class="fr-form__group">
                <label>MSA   <em> * </em></label>
                <p-dropdown appendTo="body" [filter]="true" formControlName="supplierMasterServiceAgreementId" [options]="supplierMSAdata" [(ngModel)]="contractDetails.SupplierMasterServiceAgreementId" optionLabel="title" optionValue="id" placeholder="Select MSA"></p-dropdown>
                <small class="p-error" *ngIf="submitted && myContractDetailsForm?.get('supplierMasterServiceAgreementId')?.hasError('required')">  This field is required. </small>
              </div>
            </div>           
            <div class="fr-flex__col-4"> 
               <div class="fr-form__group">   
                  <label>Billing Type   <em> * </em></label>
               <p-multiSelect appendTo="body" formControlName="billingTypeId" [options]="BillingTypeData" [(ngModel)]="_contractBillingTypes.BillingTypeId" placeholder="Select BillingType" optionValue="id" optionLabel="name" display="chip" [maxSelectedLabels]="2" required></p-multiSelect><br>
               <small class="p-error" *ngIf="submitted && _contractBillingTypes.BillingTypeId == 0"> Please select at least one billing type.</small>
               <small class="p-error" *ngIf="myContractDetailsForm?.get('billingTypeId')?.hasError('maxSelectionsExceeded')">
                  You can select up to 2 billing types. 
              </small>                    
               </div>
               </div>
          </div>
           
            
            <div class="fr-flex__container">
              <div class="fr-flex__col-4">
                <div class="fr-form__group">
                  <label>Start Date <em> * </em></label>
                  <div class="small-calendar">
                    <p-calendar appendTo="body" formControlName="startDate" class="form-control" [showIcon]="true" [(ngModel)]="contractDetails.StartDate" (ngModelChange)="onStartDateChange($event)" [dateFormat]=_dateformart.CalenderDateFormat [readonlyInput]="true"></p-calendar>
                    <small class="p-error" *ngIf="submitted && myContractDetailsForm?.get('startDate')?.hasError('required')">  This field is required. </small>
                  </div>
                </div>
              </div>
              <div class="fr-flex__col-4">
                <div class="fr-form__group">
                  <label>End Date   <em> * </em></label>
                  <div class="small-calendar">
                    <p-calendar appendTo="body" formControlName="endDate" class="form-control" [(ngModel)]="contractDetails.EndDate" [showIcon]="true" (ngModelChange)="onEndDateChange($event)" [dateFormat]=_dateformart.CalenderDateFormat [minDate]="contractDetails.StartDate || getDefaultMinDate()" [readonlyInput]="true"></p-calendar>
                    <small class="p-error" *ngIf="submitted && myContractDetailsForm?.get('endDate')?.hasError('required')">  This field is required. </small>
                  </div>
                </div>
              </div>
              <div class="fr-flex__col-4">
                <div class="fr-form__group">
                  <label>Term  </label>
                  <input formControlName="term" class="form-control read-only" type="text" pInputText [(ngModel)]="contractDetails.Term"  [readonly]="true" (ngModelChange)="calculateValuePerYearOnTermChange($event)" trimSpace/>

                </div>
              </div>
            </div>


            
            <div class="fr-flex__container">
              <div class="fr-flex__col-4">
                <div class="fr-form__group">
                  <label>Value <em> * </em> <i  pInputText pTooltip="This is the Base Contract/SOW Value" tooltipPosition="top" class="pi pi-info-circle"> </i></label>
                  <p-inputNumber Id="Value" formControlName="value" inputId="minmaxfraction" mode="decimal" [minFractionDigits]="2" class="form-control-inputNumber" [maxlength]="15"  type="text" pInputText [(ngModel)]="contractDetails.Value" (ngModelChange)="onValueChange($event)" trimSpace/>
                  <small class="p-error" *ngIf="submitted && myContractDetailsForm?.get('value')?.hasError('required')">  This field is required. </small>
                  <small class="p-error" *ngIf="submitted && myContractDetailsForm?.get('value')?.hasError('invalidNumber')"> Invalid number format - Only numbers and 2 decimal places allowed. </small>
                  <small class="p-error" *ngIf="submitted && myContractDetailsForm?.get('value')?.hasError('maxlength')">   Maximum length is 15 characters. </small>
               </div>
              </div>
              <div class="fr-flex__col-4">
                <div class="fr-form__group">
                  <label> Total Value  <i  pInputText pTooltip="This is the total value including any change orders." tooltipPosition="top" class="pi pi-info-circle"> </i></label>
                  <p-inputNumber Id="TotalVal" formControlName="totalValue" class="form-control-inputNumber read-only-value-condication" type="text" pInputText  [readonly]="true" [(ngModel)]="contractDetails.TotalValue" (ngModelChange)="calculateValuePerYearOnValueChange($event)" trimSpace/>
                  <small class="p-error" *ngIf="submitted && myContractDetailsForm?.get('totalValue')?.hasError('required')">  This field is required. </small>
                  <small class="p-error" *ngIf="submitted && myContractDetailsForm?.get('totalValue')?.hasError('invalidNumber')"> Invalid number format. </small>
                </div>
              </div>
              <div class="fr-flex__col-4">
               <div class="fr-form__group">
                 <label>Total Value/Year <span class="max-length"> </span></label>
                 <p-inputNumber formControlName="valuePerYear" [(ngModel)]="contractDetails.ValuePerYear" class="form-control-inputNumber read-only-value-condication" [readonly]="true" type="text" pInputText trimSpace/>
                 <small class="p-error" *ngIf="submitted && myContractDetailsForm?.get('valuePerYear')?.hasError('required')">  This field is required. </small>
               </div>
             </div>
            </div>           
            <div class="fr-flex__container">
               <div class="fr-flex__col-4">
                  <div class="fr-form__group">
                     <label> Currency    <em> * </em></label>
                     <p-dropdown Id="Curruncy" appendTo="body" [filter]="true" formControlName="currencyId" [options]="currenciesData" [(ngModel)]="contractDetails.CurrencyId" optionLabel="name" optionValue="id" placeholder="Select Currency" (ngModelChange)="updateChangeOrderCurrency($event)" [readonly]="true"></p-dropdown>
                     <small class="p-error" *ngIf="submitted && myContractDetailsForm?.get('currencyId')?.hasError('required')">  This field is required. </small>
                    
                    </div>
               </div>
               <div class="fr-flex__col-4">
                  <div class="fr-form__group">
                    <label>Function  <em> * </em> <a class="btn btn-primary-link pull-right line-height-zero" (click)="openContractGroupModel()" >Add New</a> </label>
                    <p-dropdown Id="Function" appendTo="body" [filter]="true" [pTooltip]="selectedContractGroup!.length > 35 ? selectedContractGroup!: ''" formControlName="ownerFunctionId" [options]="contractGroupData" [(ngModel)]="contractDetails.OwnerFunctionId" optionLabel="name" optionValue="id" placeholder="Select Function" (onChange)="ContractGroupText()"></p-dropdown>
                  </div>
                </div>   
               <div class="fr-flex__col-4">
                  <div class="fr-form__group">
                    <label> SOW Attachment <i  pInputText pTooltip="Only 1 Attachment allowed." tooltipPosition="top" class="pi pi-info-circle"> </i> </label>
                  
                    
                    <div *ngIf="uploadedFileName==''">
                    <input class="form-control" type="file"  accept=".pdf, .xls, .xlsx,.doc,.docx"  formControlName="sowfileSource" id="sowFileAttachment"  (change)="onSowFileChange($any($event.target).files)">
                    </div>
  
                    
                    <div *ngIf="uploadedFileName!=''">
                       <a class="attachment-active" routerLinkActive="active"> 
                          <span [pTooltip]="uploadedFileName.length > 40 ? uploadedFileName : ''" tooltipPosition="top"> 
                       <em> {{uploadedFileName | truncate:39}} </em> </span> 
                       <i pInputText pTooltip="Delete" tooltipPosition="top" class="pi pi-trash"  (click)="deleteSOWFile()"> </i></a>
                    </div>
                 
                 
                 </div>
              </div>
              
            </div>

            

            <div class="fr-flex__container">
              <div class="fr-flex__col-12">
                <div class="fr-form__group">
                  <label> Description <span class="max-length"> {{contractDetails.Description?.length || 0}}/300 </span> </label>
                  <textarea formControlName="description" class="form-control" [maxlength]="300" pInputTextarea [(ngModel)]="contractDetails.Description" trimSpace></textarea>
                  <small class="p-error" *ngIf="submitted && myContractDetailsForm?.get('description')?.hasError('maxlength')">   Maximum length is 300 characters. </small>
                </div>
              </div>
            </div>
            <div class="fr-flex__container m-b-10"> 
               <div class="fr-flex__col-12"> 
               <div class="fr-flex">
                  <h3>Purchase Order</h3>
              </div>
            </div>
            </div>
            <div class="fr-flex__container">
              <div class="fr-flex__col-4">
                <div class="fr-form__group">
                  <label>PO Number   <em> * </em> <span class="max-length"> {{contractDetails.PONumber?.length || 0}}/15 </span></label>
                  <input formControlName="ponumber" type="text" [maxlength]="15" class="form-control" pInputText [(ngModel)]="contractDetails.PONumber" trimSpace />
                  <small class="p-error" *ngIf="submitted && myContractDetailsForm?.get('ponumber')?.hasError('required')">  This field is required. </small>
                  <small class="p-error" *ngIf="submitted && myContractDetailsForm?.get('ponumber')?.hasError('maxlength')">   Maximum length is 15 characters. </small>
                  <small class="p-error" *ngIf="myContractDetailsForm.get('ponumber')?.hasError('onlySpaces')">Only spaces not allowed.</small>
                  <small class="p-error" *ngIf="submitted && myContractDetailsForm?.get('ponumber')?.hasError('pattern')">Spaces not allowed.</small>
                </div>
              </div>
              <div class="fr-flex__col-4">
                <div class="fr-form__group">
                  <label>PO Value   <em> * </em> <span class="max-length"> </span> </label>
                  <p-inputNumber formControlName="povalue" inputId="minmaxfraction" mode="decimal" [minFractionDigits]="2" class="form-control-inputNumber" type="text" [maxlength]="15" pInputText [(ngModel)]="contractDetails.POValue" trimSpace />
                  <small class="p-error" *ngIf="submitted && myContractDetailsForm?.get('povalue')?.hasError('required')">  This field is required. </small>
                  <small class="p-error" *ngIf="submitted && myContractDetailsForm?.get('povalue')?.hasError('invalidNumber')"> Invalid number format - Only numbers and 2 decimal places allowed. </small>
                  <small class="p-error" *ngIf="submitted && myContractDetailsForm?.get('povalue')?.hasError('maxlength')">   Maximum length is 15 characters. </small>
                </div>
              </div>

            </div>
            <div class="fr-flex__container">
              <div class="fr-flex__col-4">
                <div class="fr-form__group">
                  <label>PO Start Date   <em> * </em></label>
                  <div class="small-calendar">
                    <p-calendar appendTo="body" formControlName="postartDate" class="form-control" [showIcon]="true" [(ngModel)]="contractDetails.POStartDate" [minDate]="contractDetails.StartDate || getDefaultMinDate()" [dateFormat]=_dateformart.CalenderDateFormat (ngModelChange)="onPODateChange($event)" [readonlyInput]="true"></p-calendar>
                    <small class="p-error" *ngIf="submitted && myContractDetailsForm?.get('postartDate')?.hasError('required')">  This field is required. </small>
                  </div>
                </div>
              </div>
              <div class="fr-flex__col-4">
                <div class="fr-form__group">
                  <label>PO End Date   <em> * </em></label>
                  <div class="small-calendar">
                    <p-calendar Id="EndDate" appendTo="body" formControlName="poendDate" class="form-control" [showIcon]="true" [(ngModel)]="contractDetails.POEndDate" [minDate]="contractDetails.POStartDate || getDefaultMinDate()" [dateFormat]=_dateformart.CalenderDateFormat [readonlyInput]="true"></p-calendar>
                    <small class="p-error" *ngIf="submitted && myContractDetailsForm?.get('poendDate')?.hasError('required')">  This field is required. </small>
                  </div>               </div>
              </div>
               <div class="fr-flex__col-4">
               <div class="fr-form__group">
                 <label> PO Attachment <i  pInputText pTooltip="Only 1 Attachment allowed." tooltipPosition="top" class="pi pi-info-circle"> </i> </label>
                 <div *ngIf="uploadedPOFileName==''">
                  <input class="form-control" type="file"  accept=".pdf, .xls, .xlsx,.doc,.docx"  formControlName="pofileSource" id="poFileAttachment" (change)="onPOFileChange($any($event.target).files)"  >
                  
                 </div>

                 <div *ngIf="uploadedPOFileName!=''">
                    <a class="attachment-active" routerLinkActive="active"> 
                       <span [pTooltip]="uploadedPOFileName.length > 40 ? uploadedPOFileName : ''" tooltipPosition="top"> 
                    <em> {{uploadedPOFileName | truncate:39}} </em> </span> 
                    <i pInputText pTooltip="Delete" tooltipPosition="top" class="pi pi-trash"  (click)="deletePOFile()"> </i></a>
                 </div>
              
              </div>
           </div>

            </div>

            <div class="fr-flex__container">
              <div class="fr-flex__col-12">
                <div class="fr-form__group">
                  <label> PO Remarks <span class="max-length"> {{contractDetails.PORemarks?.length || 0}}/300 </span> </label>
                  <textarea formControlName="poremarks" class="form-control" [maxlength]="300" pInputTextarea [(ngModel)]="contractDetails.PORemarks" trimSpace></textarea>
                  <small class="p-error" *ngIf="submitted && myContractDetailsForm?.get('poremarks')?.hasError('maxlength')">   Maximum length is 300 characters. </small>
                </div>
              </div>
            </div>
         </div>
            <div class="fr-flex__container m-b-10"> 
               <div class="fr-flex__col-12"> 
               <div class="fr-flex">
                  <h3>Contract Stakeholders</h3>
              </div>
            </div>
            </div>

            <div class="fr-flex__container">            
               <div class="fr-flex__col-4">
                 <div class="fr-form__group">
                   <label>Supplier Contract Owner  <em> * </em>   <a class="btn btn-primary-link pull-right line-height-zero" (click)="openContactModel(1,'Supplier')" >Add New</a></label>
                   <p-dropdown id="creContrSupContrOwner" appendTo="body" [filter]="true" formControlName="ownerSupplierContactId" [options]="filteredStakholderSupplierContacts" [(ngModel)]="contractDetails.OwnerSupplierContactId" optionLabel="contactName" optionValue="id" placeholder="Select Supplier Contract Owner" (onChange)="onSupplierContactChange($event)"></p-dropdown>
                   <small class="p-error" *ngIf="submitted && myContractDetailsForm?.get('ownerSupplierContactId')?.hasError('required')">  This field is required. </small>
                   <!-- Changed to hyperlink -->
                 </div>
                 <div *ngIf="!dropdownValueChanged">
                 <div class="" *ngFor="let objSupplierContact of supplierContactList; let i = index">
                  <div class="fr-card__custom">
                     <p-card>
                        <ng-template pTemplate="content">
                           <p id="contractCreateSupplierContractOwnerTitle" class="fr-card__contact--title">  {{ objSupplierContact.contactName }} </p>
                           <div class="fr-card__view">
                              <label> Designation  </label>  
                              <span [pTooltip]="objSupplierContact.designation!.length > 29 ? objSupplierContact.designation : ''" tooltipPosition="top" class="valueSelector"> <em class="designation_width"> {{ objSupplierContact.designation? objSupplierContact.designation : '--'  }}  </em> </span>
                           </div>
                           <div class="fr-card__view">
                              <label> Country Code  </label>  
                              <span Id="CountryCode" class="valueSelector"> {{ objSupplierContact.countryCode? objSupplierContact.countryCode : '--'  }} </span>
                           </div>
                           <div class="fr-card__view">
                              <label> Phone Number </label> 
                              <span Id="phoneNumber" class="valueSelector"> {{ objSupplierContact.phoneNumber? objSupplierContact.phoneNumber : '--'  }} </span>
                           </div>
                           <div class="fr-card__view">
                              <label> Email <em> * </em> </label> 
                              <span Id="Email" [pTooltip]="objSupplierContact.email!.length > 29 ? objSupplierContact.email : ''" tooltipPosition="top" class="valueSelector"> <em class="email_width"> {{ objSupplierContact.email}} </em>  </span>
                           </div>
            
                        </ng-template>
                      
                        <ng-template pTemplate="footer">
                        </ng-template>
                     </p-card>
                     
                  </div>
               </div>
            </div>
               </div>
               <div class="fr-flex__col-4">
                <div class="fr-form__group">
                   <label>Sponsor Contract Owner  <em> * </em> <a class="btn btn-primary-link pull-right line-height-zero" (click)="openContactModel(2,'Sponsor')" >Add New</a></label>
                   
                   <p-dropdown id="creContrSponContrOwner" appendTo="body" [filter]="true" formControlName="SponserContactId" [options]="filteredStakholderSponsorContacts" [(ngModel)]="contractDetails.SponserContactId" optionLabel="name" optionValue="id" placeholder="Select Sponsor Contract Owner" (onChange)="onSponsorContactChange($event)"></p-dropdown>
                   <small class="p-error" *ngIf="submitted && myContractDetailsForm?.get('SponserContactId')?.hasError('required')">  This field is required. </small>
                  </div>
                  <div *ngIf="!dropdownValueChanged"> 
                  <div class="" *ngFor="let objContact of sponserContactList; let i = index">
                     <div class="fr-card__custom">
                        <p-card>
                           <ng-template pTemplate="content">
                              <p id="contractCreateSponsorContractOwnerTitle" class="fr-card__contact--title">  {{ objContact.name }} </p>
                              <div class="fr-card__view">
                                 <label> Designation  </label>  
                                 <span Id="Designation" [pTooltip]="objContact.designation!.length > 29 ? objContact.designation : ''" tooltipPosition="top" class="valueSelector"> <em class="designation_width"> {{ objContact.designation? objContact.designation : '--'  }} </em>  </span>
                              </div>
                              <div class="fr-card__view">
                                 <label> Country Code  </label>  
                                 <span class="valueSelector"> {{ objContact.countryCode? objContact.countryCode : '--'  }} </span>
                              </div>
                              <div class="fr-card__view">
                                 <label> Phone Number </label> 
                                 <span Id="phoneNumber" class="valueSelector"> {{ objContact.phoneNumber? objContact.phoneNumber : '--'  }} </span>
                              </div>
                              <div class="fr-card__view">
                                 <label> Email <em> * </em> </label> 
                                 <span Id="Email" [pTooltip]="objContact.email!.length > 29 ? objContact.email : ''" tooltipPosition="top" class="valueSelector"> <em class="email_width"> {{ objContact.email}} </em>  </span>
                              </div>
               
                           </ng-template>
                         
                           <ng-template pTemplate="footer">
                           </ng-template>
                        </p-card>
                        
                     </div>
                  </div>
               </div>

                  
             </div>
             </div>


            <p-dialog appendTo="body" header="{{contactHeader}}" [(visible)]="displayDialog" [modal]="true" [style]="{ width: '40vw' }" [resizable]="true" [closable]="false">
            <ng-template pTemplate="content">
               <form [formGroup]="dialogFormGroup">
                  <div class="fr-flex__container">
                     <div class="fr-flex__col-12">
                        <div class="fr-form__group">
                           <label for="name" Id="conname">Name<em> * </em> <span class="max-length"> {{sponserContact.name?.length || 0}}/30 </span> </label>
                           <input type="text" id="contactName" class="form-control" formControlName="contactName" [maxlength]="30" pInputText [(ngModel)]="sponserContact.name" (blur)="creContcheckSponsorContactNameExist(sponserContact)" trimSpace />
                           <small id="creContRequired" class="p-error" *ngIf="(contactSubmitted || dialogFormGroup?.get('contactName')?.touched) && dialogFormGroup?.get('contactName')?.hasError('required')"> This field is required. </small>
                           <small id="creContMax" class="p-error" *ngIf="(contactSubmitted || dialogFormGroup?.get('contactName')?.touched) && dialogFormGroup?.get('contactName')?.hasError('maxlength')">  Maximum length is 30 characters. </small>
                           <small id="creContAlphaAllowed" class="p-error" *ngIf="(contactSubmitted || dialogFormGroup?.get('contactName')?.touched) && dialogFormGroup?.get('contactName')?.hasError('pattern')">Only alpha characters and spaces are allowed.</small>
                           <small id="creContSpeaceAllowed" class="p-error" *ngIf="(contactSubmitted || dialogFormGroup?.get('contactName')?.touched) && dialogFormGroup?.get('contactName')?.hasError('onlySpaces')">This field cannot contain only spaces.</small>
                           <small id="creContSpeaceAllowed" class="p-error" *ngIf="uniqueSponsorContactNameMessage">{{existSponsorContact}}</small>
                        </div>
                     </div>
                  </div>
            
                  <div class="fr-flex__container">
                     <div class="fr-flex__col-12">
                        <div class="fr-form__group">
                           <label Id="condesignation" for="name">Designation <span class="max-length"> {{sponserContact.designation?.length || 0}}/100 </span> </label>
                           <input type="text" id="creContrcontactDesignation" class="form-control" formControlName="contactDesignation" [maxlength]="100" [minlength]="3" pInputText [(ngModel)]="sponserContact.designation" trimSpace/>
                           <small class="p-error" *ngIf="(contactSubmitted || dialogFormGroup?.get('contactDesignation')?.touched) && dialogFormGroup?.get('contactDesignation')?.hasError('maxlength')">  Maximum length is 100 characters. </small>
                           <small class="p-error" *ngIf="(contactSubmitted || dialogFormGroup?.get('contactDesignation')?.touched) && dialogFormGroup?.get('contactDesignation')?.hasError('minlength')">  Minimum length is 3 characters.</small>
                           <small class="p-error" *ngIf="(contactSubmitted || dialogFormGroup?.get('contactDesignation')?.touched) && dialogFormGroup?.get('contactDesignation')?.hasError('onlySpaces')">This field cannot contain only spaces.</small>
                        </div>
                     </div>
                  </div>
            
                  <div class="display-flex">
                     <div class="fr-flex__col-2">
                        <div class="fr-form__group">
                           <label>Country Code</label>
                           <p-dropdown Id="conCode" appendTo="body" [filter]="true" [options]="countryCodes" formControlName="countryCode" [(ngModel)]="sponserContact.countryCode" optionLabel="sub_Code" optionValue="sub_Code" placeholder="Select Country Code"></p-dropdown>
                        </div>
                     </div>
                     <div class="fr-flex__col-8">
                        <div class="fr-form__group">
                           <label>Phone Number <span class="max-length"> {{sponserContact.phoneNumber?.length || 0}}/15 </span> </label>
                           <input Id="conPhone" class="form-control" type="text" pInputText [maxlength]="15" id="phoneNumber" formControlName="phoneNumber" [(ngModel)]="sponserContact.phoneNumber"  pattern="^[0-9]+$" trimSpace/>
                           <small class="p-error" *ngIf="(contactSubmitted || dialogFormGroup?.get('phoneNumber')?.touched) && dialogFormGroup?.get('phoneNumber')?.hasError('minlength')"> Minimum length is 7 characters. </small>
                           <small class="p-error" *ngIf="(contactSubmitted || dialogFormGroup?.get('phoneNumber')?.touched) && dialogFormGroup?.get('phoneNumber')?.hasError('maxlength')"> Maximum length is 15 characters. </small>
                           <small class="p-error" *ngIf="(contactSubmitted || dialogFormGroup?.get('phoneNumber')?.touched) && dialogFormGroup?.get('phoneNumber')?.hasError('pattern')">Please enter only numeric numbers.</small>
                        </div>
                     </div>
                  </div>
                  <div class="fr-flex__container">
                     <div class="fr-flex__col-12">
                        <div class="fr-form__group">
                           <label Id="contEmanil">Email<em> * </em> <span class="max-length"> {{sponserContact.email?.length || 0}}/100 </span></label>
                           <input Id="conEmail" class="form-control" type="text" pInputText id="email" [maxlength]="100" formControlName="email" [(ngModel)]="sponserContact.email" required pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}" trimSpace/>
                           <small class="p-error" *ngIf="(contactSubmitted || dialogFormGroup?.get('email')?.touched) && dialogFormGroup?.get('email')?.hasError('required')"> This field is required. </small>
                           <small class="p-error" *ngIf="(contactSubmitted || dialogFormGroup?.get('email')?.touched) && dialogFormGroup?.get('email')?.hasError('maxlength')"> Maximum length is 100 characters. </small>
                           <small class="p-error" *ngIf="(contactSubmitted || dialogFormGroup?.get('email')?.touched) && dialogFormGroup?.get('email')?.hasError('pattern')">Please enter valid email</small>
                        </div>
                     </div>
                  </div>
               </form>
            </ng-template>
            <!-- Dialog Footer Start -->
            <ng-template pTemplate="footer">
               <button  type="button" class="btn btn-primary pull-right" [class.disabled]="isSavePopupBtnDisable" (click)="isSavePopupBtnDisable ? null : SaveContact()"> Save </button>
               <button type="button" class="btn btn-secondary pull-right" (click)="closeDialog()"> Cancel </button>
            </ng-template>
            <!-- Dialog Footer End -->
            </p-dialog>

           

            <p-dialog appendTo="body" id="creContrFunctionPopup" header="{{contactHeader}}" [(visible)]="displayContractGroupDialog" [modal]="true" [style]="{ width: '40vw' }" [resizable]="true" [closable]="false">
               <ng-template pTemplate="content">
                  <form [formGroup]="contactFormGroup" id="contFormGroup">
                     <div class="fr-flex__container" id="contGroupContainer">
                        <div class="fr-flex__col-12">
                           <div class="fr-form__group">
                              <label for="name" id="contFun">Function<em> * </em> <span class="max-length"> {{contractGroup.name?.length || 0}}/50 </span> </label>
                              <input Id="conFun" type="text" id="contractGroupName" class="form-control" formControlName="contractGroupName" [maxlength]="50" pInputText [(ngModel)]="contractGroup.name" trimSpace/>
                              <small class="p-error" *ngIf="(contractGroupSubmitted || contactFormGroup?.get('contractGroupName')?.touched) && contactFormGroup?.get('contractGroupName')?.hasError('required')"> This field is required. </small>
                              <small class="p-error" *ngIf="(contractGroupSubmitted || contactFormGroup?.get('contractGroupName')?.touched) && contactFormGroup?.get('contractGroupName')?.hasError('maxlength')">  Maximum length is 50 characters. </small>
                              <small class="p-error" *ngIf="(contractGroupSubmitted || contactFormGroup?.get('contractGroupName')?.touched) && contactFormGroup?.get('contractGroupName')?.hasError('minlength')">  Minimum length is 3 characters. </small>
                              <small class="p-error" *ngIf="(contractGroupSubmitted || contactFormGroup?.get('contractGroupName')?.touched) && contactFormGroup?.get('contractGroupName')?.hasError('onlySpaces')"> Only spaces not allowed.</small>
                              <small class="p-error" *ngIf="(contractGroupSubmitted || contactFormGroup?.get('contractGroupName')?.touched) && contactFormGroup?.get('contractGroupName')?.hasError('invalidNumberSpecialCharecters')"> Only spaces, only numeric characters and only special characters are not allowed </small>
                           </div>
                        </div>
                     </div>
                  
                  </form>
               </ng-template>
               <!-- Dialog Footer Start -->
               <ng-template pTemplate="footer">
                  <button Id="saveCon"  type="button" class="btn btn-primary pull-right" (click)="SaveContractGroup()"> Save </button>
                  <button Id="cancelCon" type="button" class="btn btn-secondary pull-right" (click)="closeContractGroupDialog()"> Cancel </button>
               </ng-template>
               <!-- Dialog Footer End -->
               </p-dialog>
               <div class="fr-flex__container">
                  <div class="fr-flex__col-12">
                    <h4 class="note-display m-b-0"> <span> Note : </span> A contact can either be selected as an owner or a stakeholder. The same contact cannot be selected as both (owner as well as stakeholder)</h4>
                  </div>
                  </div>
   
</form>

<!-- Stakeholder Sponsor Contact Grid Start -->
<div class="card-pad pad contact_pad_custom"> 
<div class="fr-flex__container">
   <div class="fr-flex__col-8">
       <h3>Stakeholders (Sponsor)  </h3>
   </div>
   <div class="fr-flex__col-4">
      <a  class="btn btn-primary pull-right" (click)="AddStakeholderSponsorContact()"> Add Row </a>
   </div>
</div>

<div class="fr-flex__col-12 m-b-20">
   <p-table id="createContractStakeholdersSponsorContactListTableName" class="primeng-table-custom addEditStakeholders_Contact" [scrollable]="true" [value]="stakeholdercontactData" editMode="row">
     <ng-template pTemplate="header">
       <tr>
         <th id="createContractStakeholderSponsorName">Name </th>
         <th id="createContractStakeholderSponsorDesignation">Designation</th>
         <th id="createContractStakeholderSponsorEmail">Email</th>
         <th id="createContractStakeholderSponsorCountryCode">Country Code </th>
         <th id="createContractStakeholderSponsorPhoneNumber">Phone Number</th>
         <th id="createContractStakeholderSponsorsendNotifications">Send Notifications </th>
         <th id="createContractStakeholderSponsorDelete">Delete</th>
         
       </tr>
     </ng-template>
     <ng-template pTemplate="body" let-stakeholdercontactData let-ContactIndex="rowIndex">

       <tr>
         <td>
           <div style="display: flex;align-items: center;margin-right: 10px;">
             <p-dropdown Id="conSponName" appendTo="body" id="createContractStakeholdersSponsorContactListName" [filter]="true" [options]="filteredSponsorContacts" appendTo="body" [(ngModel)]="stakeholdercontactData.id" placeholder="Select" optionLabel="name" optionValue="id" (onChange)="sponsorContactDetails($event, ContactIndex)" pInputText [pTooltip]="stakeholderSponsorContacts[ContactIndex]?.name.length > 10 ? stakeholderSponsorContacts[ContactIndex]?.name : ''"   tooltipPosition="top"></p-dropdown>
             <a class="btn btn-primary-link pull-right" (click)="openContactModel(2, 'StakeholderSponsor')">Add New</a>
           </div>
         </td>
         <td id="createContractStakeholderSponsorDesignationValue" [pTooltip]="stakeholderSponsorContacts[ContactIndex]?.designation.length > 20 ? stakeholderSponsorContacts[ContactIndex]?.designation : ''" tooltipPosition="top" [(ngModel)]="stakeholdercontactData.designation" id="ee">
           {{ stakeholderSponsorContacts[ContactIndex]?.designation || '--' }} 
         </td>
         <td id="createContractStakeholderSponsorEmailValue" [pTooltip]="stakeholderSponsorContacts[ContactIndex]?.email.length > 20 ? stakeholderSponsorContacts[ContactIndex]?.email : ''" tooltipPosition="top">
           {{ stakeholderSponsorContacts[ContactIndex]?.email || '--' }} 
         </td>
         <td id="createContractStakeholderSponsorCountryCodeValue" [pTooltip]="stakeholderSponsorContacts[ContactIndex]?.countryCode.length > 15 ? stakeholderSponsorContacts[ContactIndex]?.countryCode : ''" tooltipPosition="top"> 
           {{ stakeholderSponsorContacts[ContactIndex]?.countryCode || '--' }}
         </td>
         <td id="createContractStakeholderSponsorPhoneNumberValue" [pTooltip]="stakeholderSponsorContacts[ContactIndex]?.phoneNumber.length > 10 ? stakeholderSponsorContacts[ContactIndex]?.phoneNumber : ''" tooltipPosition="top">
           {{ stakeholderSponsorContacts[ContactIndex]?.phoneNumber || '--' }} 
         </td>
         <td>
           
           <p-multiSelect appendTo="body" id="createContractStakeholdersSponsorContactListSendNotification"  [options]="notificationOptionsDataSource" [(ngModel)]="stakeholdercontactData.sendNotificationOptions" placeholder="Select" optionValue="id" optionLabel="name" ></p-multiSelect><br>
         </td>
         <td id="createContractStakeholdersSponsorContactListDelete" class="grid-icon-center"><span class="pi pi-trash" (click)="deleteSponsorContact(ContactIndex,stakeholdercontactData.id)"></span></td>
       </tr>
     </ng-template>
     <ng-template pTemplate="emptymessage">
      <tr>
      <td colspan="7" class="text-center"> No Records</td>
      </tr>
      </ng-template>
   </p-table>
   
 </div>
<!-- Stakeholder Sponsor Contact Grid End -->

<!-- Stakeholder Supplier Contact Grid Start -->
<div class="fr-flex__container">
   <div class="fr-flex__col-8">
       <h3>Stakeholders (Supplier)  </h3>
   </div>
   <div class="fr-flex__col-4">
      <a  class="btn btn-primary pull-right" (click)="AddStakeholderSupplierContact()"> Add Row </a>
   </div>
</div>

<div class="fr-flex__col-12 m-b-20">
   <p-table id="createContractStakeholdersSupplierContactListTableName" class="primeng-table-custom addEditStakeholders_Contact" [scrollable]="true" [value]="stakeholdersuppliercontactData" editMode="row">
     <ng-template pTemplate="header">
       <tr>
         <th id="createContractStakeholderSupplierName">Name </th>
         <th id="createContractStakeholderSupplierDesignation">Designation</th>
         <th id="createContractStakeholderSupplierEmail">Email</th>
         <th id="createContractStakeholderSupplierCountryCode">Country Code </th>
         <th id="createContractStakeholderSupplierPhoneNumber">Phone Number</th>
         <th id="createContractStakeholderSupplierSendNotifications">Send Notifications </th>
         <th id="createContractStakeholderSupplierDelete">Delete</th>
       </tr>
     </ng-template>
     <ng-template pTemplate="body" let-stakeholdersuppliercontactData let-ContactIndex="rowIndex">

       <tr>
         <td>
           <div style="display: flex;align-items: center;margin-right: 10px;">
             <p-dropdown Id="conSupName" appendTo="body" id="createContractStakeholdersSupplierContactListName" [filter]="true" [options]="filteredSupplierContacts" appendTo="body" [(ngModel)]="stakeholdersuppliercontactData.id" placeholder="Select" optionLabel="contactName" optionValue="id" (onChange)="supplierContactDetails($event, ContactIndex)" pInputText [pTooltip]="stakeholderSupplierContacts[ContactIndex]?.contactName.length > 10 ? stakeholderSupplierContacts[ContactIndex]?.contactName : ''"   tooltipPosition="top"></p-dropdown>
             <a class="btn btn-primary-link pull-right" (click)="openContactModel(1,'StakeholderSupplier')">Add New</a>
           </div>
         </td>
         <td [pTooltip]="stakeholderSupplierContacts[ContactIndex]?.designation.length > 20 ? stakeholderSupplierContacts[ContactIndex]?.designation : ''" tooltipPosition="top" [(ngModel)]="stakeholdersuppliercontactData.designation">
          {{ stakeholderSupplierContacts[ContactIndex]?.designation || '--' }} 
         </td>
         <td [pTooltip]="stakeholderSupplierContacts[ContactIndex]?.email.length > 20 ? stakeholderSupplierContacts[ContactIndex]?.email : ''" tooltipPosition="top">
          {{ stakeholderSupplierContacts[ContactIndex]?.email || '--' }} 
         </td>
         <td [pTooltip]="stakeholderSupplierContacts[ContactIndex]?.countryCode.length > 15 ? stakeholderSupplierContacts[ContactIndex]?.countryCode : ''" tooltipPosition="top">
           {{ stakeholderSupplierContacts[ContactIndex]?.countryCode || '--' }}
         </td>
         <td [pTooltip]="stakeholderSupplierContacts[ContactIndex]?.phoneNumber.length > 10 ? stakeholderSupplierContacts[ContactIndex]?.phoneNumber : ''" tooltipPosition="top">
           {{ stakeholderSupplierContacts[ContactIndex]?.phoneNumber || '--' }}
         </td>
         <td>
           
           <p-multiSelect appendTo="body" id="createContractStakeholdersSupplierContactListSendNotification" appendTo="body"  [options]="notificationOptionsDataSource" [(ngModel)]="stakeholdersuppliercontactData.sendNotificationOptions" placeholder="Select" optionValue="id" optionLabel="name" ></p-multiSelect><br>
         </td>
         <td id="createContractStakeholdersSupplierContactListDelete" class="grid-icon-center"><span class="pi pi-trash" (click)="deleteSupplierContact(ContactIndex,stakeholdersuppliercontactData.id)"></span></td>
       </tr>
     </ng-template>
     <ng-template pTemplate="emptymessage">
      <tr>
      <td colspan="7" class="text-center"> No Records</td>
      </tr>
      </ng-template>
   </p-table>
 </div>
</div>
<!-- Stakeholder Sponsor Contact Grid End -->

         <div class="card-pad"> 
         <div class="fr-flex__container">
            <div class="fr-flex__col-8">
              <div class="sub-page-title"> 
                 <h1>Change Order</h1>
              </div>
               
            </div>
            <div class="fr-flex__col-4">
               <button  type="button" (click)="showDialog()" [disabled]="((contractDetails.EndDate == undefined) || (contractDetails.StartDate == undefined))" label="Show" routerLinkActive="" class="btn btn-primary pull-right"> Add Change Order </button>
               <i  pInputText pTooltip="The button will be enabled when contract dates have been selected" tooltipPosition="top" class="pi pi-info-circle pull-right m-r-10 m-t-10"> </i>
            </div>
         </div>
         <div class="fr-flex__container">
           <div class="fr-flex__col-12"> 
            <div class="card-norecord"> 
            <div class="card flex justify-content-center" *ngIf="_changeOrders.length==0">
            <p-card header="">
               <img src="../../../../assets/images/empty-box.svg" alt="" width="100">
               <p class="m-0">
                  No Records
               </p>
            </p-card>
         </div>
      </div>
         </div>
         </div>
          <!-- Add Change Order Information Start -->
          <div class="fr-flex__container">
            <div class="fr-flex__col-4" *ngFor="let objChangeOrder of _changeOrders; let i = index"> 
               <div id="AddContractChangeOrderCard" class="fr-card__custom"> 
              <p-card>
               <ng-template pTemplate="content">
                <div class="fr-card__view">
                   <span class="valueSelector"> <a [pTooltip]="objChangeOrder.title!.length > 15 ? objChangeOrder.title!: ''" tooltipPosition="top"> {{ objChangeOrder.title }} </a></span>
                   <span class="valueSelector success"> {{ objChangeOrder.status }}  </span>
                </div>
                  <div class="fr-card__view">
                     <span class="valueSelector"> {{ objChangeOrder.StartDate | date : _dateformart.GridDateFormat }} - {{ objChangeOrder.EndDate | date : _dateformart.GridDateFormat}} </span>
                     <span class="valueSelector" > <span class="right_align" [pTooltip]="objChangeOrder.value!.length > 10 ? objChangeOrder.value!: ''" tooltipPosition="top">  {{ objChangeOrder.value | currency:getCurrencyType(selectedCurrency)}} </span>  </span>
                  </div>
                  <div class="fr-card__view">
                   <span class="valueSelector">
                       <a *ngIf="objChangeOrder.fileName != ''" class="form-list-active change-order-attachment custom-m-b-zero"> 
                         <span [pTooltip]="objChangeOrder.fileName!.length > 15 ? objChangeOrder.fileName!: ''" tooltipPosition="top"> {{ objChangeOrder.fileName }} </span> 

                       </a>
                       <a class="form-list-active create-changeorder" *ngIf="!objChangeOrder.fileName || objChangeOrder.fileName == ''">--</a>
                       </span>
                     <span class="valueSelector">  {{ objChangeOrder.currency || '--'  }} </span>
                  </div>
                  <div class="fr-card__view">
                     <span class="valueSelector"> Description <i pInputText
                           pTooltip={{objChangeOrder.description}} tooltipPosition="top"
                           class="pi pi-info-circle"> </i> </span>
                  </div>
               </ng-template>
               <ng-template pTemplate="footer">
                  <a class="btn btn-outlined-primary" (click)="editChangeOrder(objChangeOrder)"> Edit </a>
               </ng-template>
               </p-card>
               </div>
            </div>
              
            </div>
            <!-- Add Change Order Information End -->
      
          
           
 
<div class="card flex justify-content-center">
  <!--Add ChangeOrder Modal Popup Starts-->
 <p-dialog appendTo="body" header="{{ChangeOrderTitle}}" [(visible)]="displayChangeOrderDialog" [modal]="true" [style]="{ width: '80vw' }" [draggable]="false" [resizable]="false" [closable]="false">
   <ng-template pTemplate="content">
   <form [formGroup]="contractsChangeOrderForm">
     <div class="fr-flex__container">
        <div class="fr-flex__col-4">
           <div class="fr-form__group">
              <label>Title <em> * </em> <span class="max-length"> {{_changeOrder.title?.length || 0}}/100 </span> </label>
              <input Id="creConTitle" type="text" class="form-control" [maxlength]="100" pInputText  formControlName="_changeOrderTitle" [(ngModel)]="_changeOrder.title" trimSpace />
              <small class="p-error" *ngIf="changeOrderSaved && contractsChangeOrderForm?.get('_changeOrderTitle')?.hasError('required')">This field is required.</small>
              <small class="p-error" *ngIf="changeOrderSaved && contractsChangeOrderForm?.get('_changeOrderTitle')?.hasError('maxlength')">Max length is 100 characters.</small>
              <small class="p-error" *ngIf="changeOrderSaved && contractsChangeOrderForm?.get('_changeOrderTitle')?.hasError('pattern')">Only alpha characters are allowed.</small>
              <small class="p-error" *ngIf="contractsChangeOrderForm.get('_changeOrderTitle')?.hasError('onlySpaces')">Only spaces not allowed.</small>
           </div>
        </div>
        <div class="fr-flex__col-4">
           <div class="fr-form__group">
              <label>Start Date <em> * </em></label>
              <p-calendar Id="conStartDate" appendTo="body" class="form-control" formControlName="_changeOrderStartDate"  [dateFormat]=_dateformart.CalenderDateFormat [showIcon]="true"  [(ngModel)]="_changeOrder.StartDate" (ngModelChange)="onChangeOrderDateChange($event)" [readonlyInput]="true"></p-calendar>
              <small class="p-error" *ngIf="changeOrderSaved && contractsChangeOrderForm?.get('_changeOrderStartDate')?.hasError('required')">This field is required.</small>
           </div>
        </div>
        <div class="fr-flex__col-4">
           <div class="fr-form__group">
              <label>End Date   <em> * </em></label>
              <p-calendar Id="conEndDate" appendTo="body" class="form-control"  formControlName="_changeOrderEndDate"  [dateFormat]=_dateformart.CalenderDateFormat [showIcon]="true" [(ngModel)]="_changeOrder.EndDate" [minDate]="_changeOrder.StartDate || getDefaultMinDate()" [readonlyInput]="true"></p-calendar>
              <small class="p-error" *ngIf="changeOrderSaved && contractsChangeOrderForm?.get('_changeOrderEndDate')?.hasError('required')">This field is required.</small>
           </div>
        </div>
     </div>
     <div class="fr-flex__container">
        <div class="fr-flex__col-4">
           <div class="fr-form__group">
              <label>Value <em> * </em> <span class="max-length"> {{_changeOrder.value?.length || 0}}/15 </span></label>
              <p-inputNumber Id="conVal" class="form-control" inputId="minmaxfraction" mode="decimal" [minFractionDigits]="2" type="text" class="form-control-inputNumber" [maxlength]="15" formControlName="_changeOrderValue" [(ngModel)]="_changeOrder.value" pInputText trimSpace />
              <small class="p-error" *ngIf="changeOrderSaved && contractsChangeOrderForm?.get('_changeOrderValue')?.hasError('required')">This field is required.</small>
              <small class="p-error" *ngIf="changeOrderSaved && contractsChangeOrderForm?.get('_changeOrderValue')?.hasError('invalidNumber')"> Invalid number format.Only numbers and 2 decimal places allowed. </small>
              <small class="p-error" *ngIf="changeOrderSaved && contractsChangeOrderForm?.get('_changeOrderValue')?.hasError('maxlength')">   Maximum length is 15 characters. </small>

           </div>
        </div>
        <div class="fr-flex__col-4">
           <div class="fr-form__group">
              <label>Currency </label>
              <input id="conCurruncy"  type="text" Id="ChangeOrderCurrency"  class="form-control read-only" formControlName="_changeOrderCurrency" [(ngModel)]="_changeOrder.currency" pInputText readonly trimSpace />
           </div>
        </div>
        <div class="fr-flex__col-4">
           <div class="fr-form__group">
              <label>Attachment <i  pInputText pTooltip="Only 1 Attachment allowed." tooltipPosition="top" class="pi pi-info-circle"> </i> </label>
              <div *ngIf="showAttachmentButton">
               <input Id="conAttachment"  type="file"  accept=".pdf, .xls, .xlsx,.doc,.docx"  formControlName="_changeOrderfileSource" class="form-control custom-file-input" id="changeOrderFileAttachment" [(ngModel)]="_changeOrder.fileName"  (change)="onChangeOrderFileChange($any($event.target).files)"> 
               
            </div> 
             <div *ngIf="showAttachedFile">
                <a class="attachment-active" routerLinkActive="active"> 
                  <span [pTooltip]="_changeOrder.fileName!.length > 40 ? _changeOrder.fileName! : ''" tooltipPosition="top">
                     <em> {{ _changeOrder.fileName}} </em>  </span>
                     <i pInputText pTooltip="Delete" tooltipPosition="top" class="pi pi-trash" (disabled)="showAttachedFile" (click)="DeletChangeOrderFile(_changeOrder.fileName,_changeOrder.filePath)"> </i> 
                  </a>
                
             </div>
            </div>
        </div>
     </div>
     <div class="fr-flex__container">
        <div class="fr-flex__col-4">
           <div class="fr-form__group">
              <label>Status  <em> * </em> </label>
              <p-dropdown appendTo="body" [filter]="true" appendTo="body" formControlName="_changeOrderStatus" [options]="changeOrderStatusDataSource"  [(ngModel)]="_changeOrder.statusMappingId" optionLabel="name" optionValue="id" placeholder="Select Status"></p-dropdown>
             
              <small class="p-error" *ngIf="changeOrderSaved && _changeOrder?.statusMappingId==0 ">This field is required.</small>

           </div>
        </div>
     </div>
     <div class="fr-flex__container">
        <div class="fr-flex__col-12">
           <div class="fr-form__group">
              <label>Description <span class="max-length"> {{_changeOrder.description?.length || 0}}/300 </span> </label>
              <textarea class="form-control" [maxlength]="300" formControlName="_changeOrderDescription" [(ngModel)]="_changeOrder.description" trimSpace></textarea>
              <small class="p-error" *ngIf="changeOrderSaved && contractsChangeOrderForm?.get('_changeOrderDescription')?.hasError('maxlength')">Max length is 300 characters.</small>
           </div>
        </div>
     </div>
  </form>
    </ng-template>
    <!-- Dialog Footer Start -->
    <ng-template pTemplate="footer">
     <button  type="button" class="btn btn-primary pull-right" [class.disabled]="isSaveChangeOrderPopupBtnDisable" (click)="isSaveChangeOrderPopupBtnDisable ? null : saveChangeOrderData()"> Save </button>
     <button type="button" class="btn btn-secondary pull-right" (click)="cancelDialog(true,'Change Order')"> Cancel </button>
  </ng-template>
   <!-- Dialog Footer End -->
 </p-dialog>
</div>
<!--Add ChangeOrder Modal Popup End-->


         <div class="fr-flex__container m-t-10">
            <div class="fr-flex__col-8">
              <div class="sub-page-title"> 
                 <h1>Addendum </h1>
              </div>
            </div>
            <div class="fr-flex__col-4">
               <button  type="button" class="btn btn-primary pull-right" [disabled]="((contractDetails.EndDate == undefined) || (contractDetails.StartDate == undefined))" (click)="showDialogAdendum()"> Add Addendum </button>
               <i  pInputText pTooltip="The button will be enabled when contract dates have been selected" tooltipPosition="top" class="pi pi-info-circle pull-right m-r-10 m-t-10"> </i>
            </div>
         </div>
         <div class="fr-flex__container">
           <div class="fr-flex__col-12"> 
            <div class="card-norecord"> 
          <div class="card flex justify-content-center" *ngIf="_addendumDetails.length==0">
            <p-card header="">
               <img src="../../../../assets/images/empty-box.svg" alt="" width="100">
               <p class="m-0">
                  No Records
               </p>
            </p-card>
         </div>
         </div>
      </div>
         </div>

         
   <!-- Add Addendum Information Start -->
   <div class="fr-flex__container">
      <div class="fr-flex__col-4" *ngFor="let objAddendumDetails of _addendumDetails; let i = index"> 
         <div id="addContractAddendumCard" class="fr-card__custom"> 
        <p-card>
         <ng-template pTemplate="content">
            <div class="fr-card__view"> 
            <span class="valueSelector"> <a [pTooltip]="objAddendumDetails.title!.length > 15 ? objAddendumDetails.title!: ''" tooltipPosition="top"> {{ objAddendumDetails.title }} </a></span>
            <span class="valueSelector success"> {{ objAddendumDetails.status }} </span>
         </div>
            <div class="fr-card__view">
               <span class="valueSelector"> {{ objAddendumDetails.StartDate | date : _dateformart.GridDateFormat }} - {{ objAddendumDetails.EndDate | date : _dateformart.GridDateFormat}} </span>
            </div>
            <div class="fr-card__view">
               <span class="valueSelector-full-width">
                  <a *ngIf="objAddendumDetails.fileName != ''" class="form-list-active custom-m-b-zero"> 
                     <span [pTooltip]="objAddendumDetails.fileName!.length > 15 ? objAddendumDetails.fileName!: ''" tooltipPosition="top"> {{ objAddendumDetails.fileName! | truncate:45 }}</span> 

                  </a>
                  <a class="form-list-active create-addendum" *ngIf="!objAddendumDetails.fileName || objAddendumDetails.fileName == ''">--</a>
               </span>
            </div>
            <div class="fr-card__view">
               <span class="valueSelector"> Description <i pInputText
                     pTooltip={{objAddendumDetails.description}} tooltipPosition="top"
                     class="pi pi-info-circle"> </i> </span>
            </div>
         </ng-template>
         <ng-template pTemplate="footer">
            <a class="btn btn-outlined-primary"   (click)="EditAddendumTile(objAddendumDetails)"> Edit </a>
         </ng-template>
         </p-card>
         </div>
      </div>
        
      </div>

      <!-- Add Addendum Information End -->
         <div class="card flex justify-content-center">
           <!--Add Addendum Modal Popup Starts-->
          <p-dialog appendTo="body" header="{{AddendumTitle}}" [(visible)]="displayAdendumDialog" [modal]="true" [style]="{ width: '80vw' }" [draggable]="false" [resizable]="false" [closable]="false">
            <ng-template pTemplate="content">
               <form [formGroup]="contractsAddendumDetailsForm">
                  <div class="fr-flex__container">
                     <div class="fr-flex__col-4">
                        <div class="fr-form__group">
                           <label>Title   <em> * </em> <span class="max-length"> {{_addendumDetail.title?.length || 0}}/100 </span></label>
                           <input type="text" class="form-control" pInputText  formControlName="_addendumDetailTitle" [maxlength]="100" [(ngModel)]="_addendumDetail.title" trimSpace />
                           <small class="p-error" *ngIf="addendumDetailsSaved && contractsAddendumDetailsForm?.get('_addendumDetailTitle')?.hasError('required')">This field is required.</small>
                           <small class="p-error" *ngIf="addendumDetailsSaved && contractsAddendumDetailsForm?.get('_addendumDetailTitle')?.hasError('maxlength')">Max length is 100 characters.</small>
                           <small class="p-error" *ngIf="addendumDetailsSaved && contractsAddendumDetailsForm?.get('_addendumDetailTitle')?.hasError('pattern')">Only alpha characters are allowed.</small>
                           <small class="p-error" *ngIf="contractsAddendumDetailsForm.get('_addendumDetailTitle')?.hasError('onlySpaces')">Only spaces not allowed</small>
                        </div>
                     </div>
                     <div class="fr-flex__col-4">
                        <div class="fr-form__group">
                           <label>Start Date <em> * </em></label>
                           <p-calendar appendTo="body" class="form-control" formControlName="_addendumDetailStartDate"  [dateFormat]=_dateformart.CalenderDateFormat [showIcon]="true"  [(ngModel)]="_addendumDetail.StartDate" (ngModelChange)="onChangeOrderDateChange($event)" [maxDate]="_addendumDetail.EndDate || getDefaultMinDate()" [readonlyInput]="true"></p-calendar>
                           <small class="p-error" *ngIf="addendumDetailsSaved && contractsAddendumDetailsForm?.get('_addendumDetailStartDate')?.hasError('required')">This field is required.</small>
                        </div>
                     </div>
                     <div class="fr-flex__col-4">
                        <div class="fr-form__group">
                           <label>End Date   <em> * </em></label>                      
                           <p-calendar appendTo="body" class="form-control non-clickable-calendar"  formControlName="_addendumDetailEndDate"  [dateFormat]="_dateformart.CalenderDateFormat" [showIcon]="true" [disabled]="true" [(ngModel)]="_addendumDetail.EndDate" [minDate]="_addendumDetail.StartDate || getDefaultMinDate()" [readonlyInput]="true"></p-calendar>  
                           <small class="p-error" *ngIf="addendumDetailsSaved && contractsAddendumDetailsForm?.get('_addendumDetailEndDate')?.hasError('required')">This field is required.</small>
                        </div>
                     </div>
                  </div>
                  <div class="fr-flex__container">
                     <div class="fr-flex__col-4">
                        <div class="fr-form__group">
                           <label>Attachment  <i  pInputText pTooltip="Only 1 Attachment allowed." tooltipPosition="top" class="pi pi-info-circle"> </i></label>                                              
                        <div *ngIf="addAddendumShowAttachmentButton">
                           <input   type="file"  accept=".pdf, .xls, .xlsx,.doc,.docx"  formControlName="_addendumDetailfileSource" class="form-control" id="addendumFileAttachment" [(ngModel)]="_addendumDetail.fileName"  (change)="onAddendumFileChange($any($event.target).files)">                                 
                          
                         </div>
                         <div *ngIf="addAddendumShowAttachedFile">      
                            <a class="attachment-active" routerLinkActive="active">
                              <span [pTooltip]="_addendumDetail.fileName!.length > 40 ? _addendumDetail.fileName : ''" tooltipPosition="top">
                                 <em> {{ _addendumDetail.fileName}} </em>  </span>
                                 <i class="pi pi-trash" pInputText pTooltip="Delete" tooltipPosition="top" class="pi pi-trash" (disabled)="showAttachedFile" (click)="DeletAddendumFile(_addendumDetail.fileName,_addendumDetail.filePath)"> </i>     
                              </a>      
                         </div>
                        </div> 
                     </div>
                     <div class="fr-flex__col-4">
                        <div class="fr-form__group">
                           <label>Status  <em> * </em> </label>
                           <p-dropdown appendTo="body" [filter]="true" appendTo="body" formControlName="_addendumDetailStatus" [options]="addendumDetailStatusDataSource" optionLabel="name" optionValue="id" placeholder="Select Status" [(ngModel)]="_addendumDetail.statusMappingId"></p-dropdown>
                           <small class="p-error" *ngIf="addendumDetailsSaved && _addendumDetail.statusMappingId==0 ">This field is required.</small>
                        </div>
                     </div>
                  </div>
                  <div class="fr-flex__container">
                     <div class="fr-flex__col-12">
                        <div class="fr-form__group">
                           <label>Description <span class="max-length"> {{_addendumDetail.description?.length || 0}}/300 </span></label>
                           <textarea class="form-control" [maxlength]="300" formControlName="_addendumDetailDescription" [(ngModel)]="_addendumDetail.description" trimSpace></textarea>
                           <small class="p-error" *ngIf="addendumDetailsSaved && contractsAddendumDetailsForm?.get('_addendumDetailDescription')?.hasError('maxlength')">Max length is 300 characters.</small>
                        </div>
                     </div>
                  </div>
                  
               </form>
             </ng-template>
             <!-- Dialog Footer Start -->
             <ng-template pTemplate="footer">
              <button  type="button" class="btn btn-primary pull-right" [class.disabled]="isSaveAddendumDataPopupBtnDisable" (click)="isSaveAddendumDataPopupBtnDisable ? null : saveAddendumData()"> Save </button>
              <button type="button" Id="cancelChangeOrder" class="btn btn-secondary pull-right" (click)="cancelDialog(true,'Adendum')"> Cancel </button>
           </ng-template>
            <!-- Dialog Footer End -->
          </p-dialog>
        </div>
        <!--Add Addendum Modal Popup End-->
        
        
            <div class="fr-flex__container footer_btns">
               <div class="fr-flex__col-12"> 
               <a  type="button" class="btn btn-primary btn-save pull-right" [class.disabled]="isSaveBtnDisable"  (click)="isSaveBtnDisable ? null : saveContractData()"> Save </a>
               <a type="button" class="btn btn-secondary pull-right" (click)="BackToListPage()"> Cancel </a>
            </div>
            </div>
        
         </div>
     
        
      </p-tabPanel>
      <p-tabPanel [disabled]="true" header="Governance">
         <p-tabView>
            <p-tabPanel header="KPIs">  <app-con-kpi-list> </app-con-kpi-list> </p-tabPanel>
            <p-tabPanel header="Meetings"> Meetings  </p-tabPanel>
            <p-tabPanel header="Audits">  </p-tabPanel>
        </p-tabView>
       </p-tabPanel>
       <p-tabPanel [disabled]="true" header="Finance">
       
       </p-tabPanel>
       <p-tabPanel [disabled]="true" header="Team">
          <app-teams> </app-teams>
       </p-tabPanel>
    </p-tabView>
   </div>

</body>
</html>
 

 


