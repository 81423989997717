import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  IsShowLoader=false;
  showLoader() {
    this.IsShowLoader=true;
  }
  hideLoader() {
    this.IsShowLoader=false;
  }

  hidefinalLoader() {
      setTimeout(() => {
        this.IsShowLoader=false;
      }, 4000);
    }
  }
  
