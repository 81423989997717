
<!DOCTYPE html>
<html lang="en">
<head>
   <title> Master View Supplier </title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>
<div class="card flex justify-content-center">
   <p-breadcrumb class="max-w-full" [model]="getBreadcrumbs()"></p-breadcrumb>
</div>

<div class="container page-title">
   <div class="flex-box-inside-pages"> 
   <div class="fr-flex__container">
      <div class="fr-flex__col-12">
         <h1 id="masterViewSupplierViewTitle"> View Supplier_{{supplierdata.supplierId}} </h1>
         
      </div>
   </div>
</div>
   <p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
      <p-tabView class="fr-tabview__header__custom">
         <p-tabPanel> 
            <div class="card-pad">  
            <div class="fr-flex__container"> 
            <div class="fr-flex__col-12">
               <div class="fr-flex__col-8">
                  <h3> </h3>
               </div>
            <div class="fr-flex__col-4">
               <select class="form-control selectpicker pull-right" [(ngModel)]="selectedOption" (change)="onOptionChange()">
                  <option disabled selected> Actions </option>
                  <option *ngIf="editPrivilege"> Edit </option>
               </select>
               <div class="fr-flex__col-6">  
                  <div class="preview_section pull-right">
                     </div>
                  </div>
                  <a class="btn btn-primary-link backtolist pull-right" (click)="BackToList()">  <i class="pi pi-arrow-left"></i> Back to list </a>
            </div>
       
         </div>
         
            <div class="fr-flex__container m-t-20">
               <div class="fr-flex__col-4">
                  <div class="fr-form__view">
                     <label> Supplier Name <em> * </em></label>
                     <span [pTooltip]="supplierdata.name!.length > 30 ? supplierdata.name! : ''" tooltipPosition="top"> <em> {{supplierdata.name}} </em></span>
                  </div>
               </div>
            
            
               <div class="fr-flex__col-4">
                  <div class="fr-form__view">
                  <label> Supplier ID<em> * </em></label>
                  <span> <em> {{supplierdata.supplierId}} </em></span>
                  </div>
               </div>
            
                <div class="fr-flex__col-4">
                <div class="fr-form__view">
                   <label> Services<em> * </em></label>
                   <ul class="services_ul" *ngFor="let singleService of services; let i = index">
                      <li> {{singleService}} </li>
                   </ul>
                   <span><em> <p-button (onClick)="showServiceDialog()" [hidden]="ShowServicesButton"
                            [ngClass]="'blue-button'" [label]="buttonLabel"></p-button> </em></span>
                            <p-dialog appendTo="body" header="Services" [modal]="true" [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false" [closable]="true" [(visible)]="visible">
                              <div class="dialog-ul" *ngFor="let service of servicesToArray; let i = index">
                                 <span *ngIf="i > 1">                                       
                                    <em [pTooltip]="service.length > 30 ? service : ''" tooltipPosition="top"> {{service}}</em>                    
                                 </span>
                              </div>
                              </p-dialog>
                   




                </div>
             </div>
            </div>
            <div class="fr-flex__container">
              <div class="fr-flex__col-4">
                  <div class="fr-form__view">
                     <label> Website </label>
                     <span> <em> {{supplierdata.website? supplierdata.website :"--"}} </em></span>
                  </div>
               </div>
         
            
               <div class="fr-flex__col-4">
                  <div class="fr-form__view">
                     <label> Locations <em> * </em></label>
                     <ul class="services_ul" *ngFor="let singlelocation of locations; let i = index">
                        <li> {{singlelocation}} </li>
                     </ul>
                     <span><em> <p-button (onClick)="showlocationsDialog()" [hidden]="ShowlocationsButton"
                              [ngClass]="'blue-button'" [label]="buttonlocationsLabel"></p-button> </em></span>
                              <p-dialog appendTo="body" header="Locations" [modal]="true" [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false" [closable]="true" [(visible)]="locationsvisible">
                                 <div class="dialog-ul" *ngFor="let location of locationsToArray; let i = index">
                                    <span *ngIf="i > 1">                                       
                                       <em [pTooltip]="location.length > 30 ? location : ''" tooltipPosition="top"> {{location}}</em>
                                    </span>
                                 </div>
                                 </p-dialog>
                  </div>
               </div>
              
            </div>

            <div class="fr-flex__container">
               <div class="fr-flex__col-12">
                  <div class="fr-form__view">
                     <label> Headquarters Address </label>
                     <span class="full-width" *ngIf="headquartersAddress  == null || headquartersAddress == '' ">--</span> <span class="full-width" [innerHTML]="headquartersAddress" *ngIf="headquartersAddress  != null"> {{headquartersAddress }} </span>
                  </div>
               </div>
            </div>
            
            <div class="fr-flex__container">
               <div class="fr-flex__col-12">
                  <div class="fr-form__view">
                     <label> Notes and Comments</label>
                     <span class="full-width" *ngIf="notes  == null || notes == '' ">--</span> <span class="full-width" [innerHTML]="notes" *ngIf="notes  != null"> {{notes }} </span>
                  </div>
               </div>
            </div>
            <div class="fr-flex__container">
               <div class="fr-flex__col-3">
                  <div class="fr-form__view">
                     <label> Created By </label>
                     <span> <em> {{createdBy? createdBy :"--"}} </em> </span>
                  </div>
               </div>
            
               <div class="fr-flex__col-3">
                  <div class="fr-form__view">
                     <label> Created Date & Time</label>
                     <span> <em> {{supplierdata.createdDate | date: _dateformat.DateTimeWithZone}} {{_dateformat.DisplayUTC}}</em> </span>
                  </div>
               </div>
            
               <div class="fr-flex__col-3">
                  <div class="fr-form__view">
                     <label> Modified By</label>
                     <span> <em> {{updatedBy? updatedBy:"--"}} </em> </span>
                  </div>
               </div>
               <div class="fr-flex__col-3">
                  <div class="fr-form__view">
                     <label> Modified Date & Time</label>
                     <span *ngIf="supplierdata?.updatedBy!=null"> <em> {{ supplierdata.updatedDate| date: _dateformat.DateTimeWithZone}} {{_dateformat.DisplayUTC}} </em> </span>
                     <span *ngIf="supplierdata?.updatedBy==null"> <em> -- </em></span>
                  </div>
               </div>
            </div>
           

            <div class="fr-flex__container">
               <div class="fr-flex__col-8">
                  <div class="sub-page-title">
                     <h3>Contacts</h3>
                  </div>
               </div>
            </div>


            <!-- grid code -->

           
               <div class="fr-flex__col-12">
                     <p-table class="primeng-table-custom masterdata_contacts_th5" [resizableColumns]="true" columnResizeMode="expand" styleClass="p-datatable-striped p-datatable-gridlines" [value]="supplierContacts" [scrollable]="true" >
                     <ng-template pTemplate="header">
                           <tr>
                               
                               <th id="masterViewSupplierContactName" pSortableColumn="contactName" pResizableColumn>Name <p-sortIcon field="contactName"></p-sortIcon></th>
                               <th id="masterViewSupplierDesignation" pSortableColumn="designation" pResizableColumn>Designation <p-sortIcon field="designation"></p-sortIcon></th>
                               <th id="masterViewSupplierEmail" pSortableColumn="email" pResizableColumn>Email <p-sortIcon field="email"></p-sortIcon></th>
                               <th id="masterViewSupplierCountryCode" pSortableColumn="countryCode" pResizableColumn>Country Code <p-sortIcon field="countryCode"></p-sortIcon></th>
                               <th id="masterViewSupplierphoneNumber" pSortableColumn="phoneNumber" pResizableColumn>Phone Number <p-sortIcon field="phoneNumber"></p-sortIcon></th>
                              
                           </tr>
                       </ng-template>
                       <ng-template pTemplate="body" let-contact>
                           <tr>
                            
                               <td> {{ contact.contactName? contact.contactName : '--' }} </td>
                               <td [pTooltip]="contact.designation.length > 20 ? contact.designation : ''" tooltipPosition="top"> {{ contact.designation? contact.designation : '--' }} </td>
                               <td [pTooltip]="contact.email.length > 20 ? contact.email : ''" tooltipPosition="top"> {{ contact.email }} </td>
                               <td> {{ contact.countryCode? contact.countryCode :"--" }} </td>
                               <td> {{ contact.phoneNumber? contact.phoneNumber :"--"  }} </td>
                               
                           </tr>
                       </ng-template>
                       <ng-template pTemplate="emptymessage">
                        <tr>
                        <td colspan="5" class="text-center"> No Records</td>
                        </tr>
                        </ng-template>
                   </p-table>
                   
               </div>
           




<!--------------------- previous code start ------------------------------------------>

          





<!--------------------- previous code end ------------------------------------------>
   </div>
         </div>
    </p-tabPanel>
      </p-tabView>
</div>
</body>
</html>

