
export class EngagementMeetingDetails
{
    Id?:number;
    Title?: string;
    Recurring?: Boolean;
    Organizer?:any;
    EngagementId?:number;
    CreatedBy?: number;
    CreatedDate?: Date;
    UpdatedBy?: number;
    UpdatedDate?: Date;
    IsDeleted?: Boolean;
    Agenda?:string;
    EngagementMeetingOccurrences?:EngagementMeetingOccurrences[];
    EngagementMeetingContacts?: EngagementMeetingContacts[];
   

}
  export class EngagementMeetingOccurrences {
    Id: number=0;
    OccurrenceDate?: any;
    MeetingNotes?:string;
    Decisions?:string;
    EngagementMeetingId: number=0;
    TotalActionsCount:number=0;
    OpenActionsCount:number=0;
    CreatedBy?: number;
    CreatedDate?: Date;
    UpdatedBy?: number;
    UpdatedDate?: Date;
    IsDeleted?: Boolean;
    EngagementMeetingAttendees?:EngagementMeetingAttendees[];
    EngagementMeetingAbsentees?:EngagementMeetingAbsentees[];
    EngagementMeetingOccurrenceAttachments?:EngagementMeetingOccurrenceAttachments[];
    EngagementMeetingOccurrenceActions?:EngagementAddMeetingOccurenceActions[];
    
  
  }

  export class EngagementMeetingAttendees {
    Id: number=0;
    Attendees?:any=[];
    EngagementMeetingOccurrenceId: number=0;
    CreatedBy?: number;
    CreatedDate?: Date;
    UpdatedBy?: number;
    UpdatedDate?: Date;
    IsDeleted?: Boolean;
  
  }
  export class EngagementMeetingAbsentees {
    Id: number=0;
    Absentees?:any=[];
    EngagementMeetingOccurrenceId: number=0;
    CreatedBy?: number;
    CreatedDate?: Date;
    UpdatedBy?: number;
    UpdatedDate?: Date;
    IsDeleted?: Boolean;
  
  }

  export class EngagementMeetingOccurrenceAttachments {
    Id: number=0;
    FileName?:string;
    FilePath?:string;
    EngagementMeetingOccurrenceId: number=0;
    CreatedBy?: number;
    CreatedDate?: Date;
    UpdatedBy?: number;
    UpdatedDate?: Date;
    IsDeleted?: Boolean;
  
  }
  export class EngagementAddMeetingOccurenceActions {   
    ActionName?: string;
    Owner?:string; 
    TargetDate?:Date;
    ClosureDate?:Date;
    Remarks?:string;
    EngagementMeetingOccurrenceId?:number;
    StatusMappingId?:number;
    IsDeleted?:boolean;
    CreatedBy?: number;
    CreatedDate?: Date;
    UpdatedBy?: number;
    UpdatedDate?: Date;
  }
   export class EngagementAddMultipleOccurrences {   
    EngagementMeetingAddOccurrenceDetails?:EngagementMeetingOccurrences[];
    EngagementMeetingAddOccurrenceAttendees?:EngagementMeetingAttendees[];
    EngagementMeetingAddOccurrenceAbsentees?:EngagementMeetingAbsentees[];
    EngagementMeetingAddOccurrenceOccurrenceAttachments?:EngagementMeetingOccurrenceAttachments[];
    EngagementMeetingAddOccurrenceActions?:EngagementAddMeetingOccurenceActions[];
  }
  export class EngagementMeetingContacts{
    id: number=0;
    contactName?: string="";
    designation?: string="";
    countryCode?: string="";
    phoneNumber?: string="";
    email?: string="";
    meetingId: number=0;
   }
 
 

